const Command = PS.Models.Command;
const Events = PS.Models.Diligence.Events;

class DeselectDocumentType extends Command {
  execute() {
    this.state.currentType = undefined;

    return Promise.resolve();
  }
}

class SelectDocumentType extends Command {
  execute() {
    const sameTypeSelected =
      this.state.currentType && this.state.currentType.name === this.event.documentType.name;
    if (sameTypeSelected) {
      return Promise.resolve();
    }

    this.state.currentType = this.event.documentType;

    return Command.broadcastEvent(
      Events.Document.LoadingRequested,
      PS.Services.MessageBusService.diligenceBus,
      {
        documentType: this.event.documentType,
      }
    );
  }
}

class RequestBlockUI extends Command {
  execute() {
    Command.broadcastEvent(
      Events.Checklist.RequestBlockUI,
      PS.Services.MessageBusService.diligenceBus
    );
  }
}

class BlockUI extends Command {
  execute() {
    this.state.uiIsBlocked = true;
  }
}

class RequestUnblockUI extends Command {
  execute() {
    Command.broadcastEvent(
      Events.Checklist.RequestUnblockUI,
      PS.Services.MessageBusService.diligenceBus
    );
  }
}

class UnblockUI extends Command {
  execute() {
    this.state.uiIsBlocked = false;
  }
}

PS.Models.Diligence.Commands.Checklist = {
  DeselectDocumentType: DeselectDocumentType,
  SelectDocumentType: SelectDocumentType,
  BlockUI,
  UnblockUI,
  RequestBlockUI,
  RequestUnblockUI,
};
