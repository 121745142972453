<template>
  <div class="asset-management">
    <div class="asset-management-segmented-control-container">
      <segmented-control
        :options="tabsList"
        :active-option="currentTab"
        @option-selected="changeCurrentTab"
      />
    </div>
    <workflows-section
      v-if="currentTab === 'tasks'"
    />
    <div v-if="currentTab === 'documentation'">
      <loan-overview
        v-if="purchasedLoan"
        :form="form"
        :details="details"
        @backup-form="saveToBackupForm"
        @input="updateForm"
        @reset-form="resetForm"
        @save="updateForeclosureState"
      />
      <workout-case-summary v-if="purchasedLoan" />
      <loan-resolution
        v-if="purchasedLoan"
        :form="form"
        @backup-form="saveToBackupForm"
        @input="updateForm"
        @reset-form="resetForm"
        @save="updateForeclosureState"
      />
      <loan-modifications v-if="showLoanModifications" />
      <foreclosure-status
        v-if="purchasedLoan"
        :form="form"
        @backup-form="saveToBackupForm"
        @input="updateForm"
        @reset-form="resetForm"
        @save="updateForeclosureState"
      />
      <reo-status
        v-if="purchasedLoan"
        :form="form"
        @backup-form="saveToBackupForm"
        @input="updateForm"
        @reset-form="resetForm"
        @save="updateForeclosureState"
      />
      <foreclosure-document-section
        v-if="purchasedLoan"
        :loan-ps-id="loanPsId"
      />
    </div>
  </div>
</template>

<script>
import LoanOverview from "components/manage/asset_management/loan_overview";
import WorkoutCaseSummary from "components/manage/asset_management/workout_case_summary";
import LoanResolution from "components/manage/asset_management/loan_resolution";
import ForeclosureStatus from "components/manage/asset_management/foreclosure_status";
import ReoStatus from "components/manage/asset_management/reo_status";
import ForeclosureDocumentSection from "components/manage/asset_management/foreclosure_document/foreclosure_document_section";
import LoanModifications from "components/manage/asset_management/loan_modifications";
import WorkflowsSection from "components/manage/asset_management/workflows_section";
import SegmentedControl from "components/segmented_control";
import AjaxService from "services/ajax_service";
import IdleSessionTimeout from "utils/idle_session_timeout";

import get from "lodash/get";
import snakeCase from "lodash/snakeCase";

const serializePayload = payload => {
  return Object.keys(payload).reduce(
    (accum, key) => ({ ...accum, [snakeCase(key)]: payload[key] }),
    {}
  );
};
const genericErrorMessage = "Unable to update foreclosure";

export default {
  components: {
    LoanOverview,
    WorkoutCaseSummary,
    LoanResolution,
    ForeclosureStatus,
    ReoStatus,
    ForeclosureDocumentSection,
    LoanModifications,
    WorkflowsSection,
    SegmentedControl,
  },
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      backupForm: null,
      form: PSData.loanForeclosure || {},
      details: PSData.loanDetails || {},
    };
  },
  computed: {
    loanPsId: () => PSData.loan.toParam,
    purchasedLoan: () => PSData.loanHasBeenPurchased,
    canEditLoanModifications: () => PSData.canEditLoanModifications,
    showLoanModifications() {
      return this.canEditLoanModifications || this.purchasedLoan;
    },
    tabsList() {
      return [
        {
          name: "tasks",
          label: "Tasks",
        },
        {
          name: "documentation",
          label: "Documentation",
        },
      ];
    },
    currentTab() {
      return this.params.view || "documentation";
    },
  },
  mounted() {
    this.idleSessionManager = new IdleSessionTimeout();
    this.idleSessionManager.run();
  },
  beforeDestroy() {
    if (this.idleSessionManager) {
      this.idleSessionManager.teardown();
    }
    this.idleSessionManager = null;
  },
  methods: {
    refreshForm(serverData) {
      Object.keys(serverData).forEach(k => {
        if (serverData[k] !== this.form[k]) {
          this.updateForm(k, serverData[k]);
        }
      });
    },
    resetForm() {
      this.form = this.backupForm;
      this.backupForm = null;
    },
    saveToBackupForm() {
      this.backupForm = { ...this.form };
    },
    updateForm(field, value) {
      Vue.set(this.form, field, value);
    },
    async updateForeclosureState(payload, callback) {
      try {
        const response = await AjaxService.withNotifications({
          onSuccess: "Foreclosure was succesfully updated",
          onError: this.errorMessage,
        }).patchJSON(
          `/manage/loans/${this.loanPsId}/asset_management/upsert`,
          serializePayload(payload)
        );
        this.refreshForm(response.foreclosure);
      } catch (error) {
        Bugsnag.notify(
          { name: "Request Error", message: genericErrorMessage },
          { metaData: { xhr: error } }
        );
      } finally {
        this.backupForm = null;
        if (typeof callback === "function") callback();
      }
    },
    errorMessage: function(resp) {
      const message = get(resp, "data.error") || genericErrorMessage;
      return { message, status: "error" };
    },
    changeCurrentTab(tab) {
      this.$emit("change-view", tab);
    },
  },
};
</script>
