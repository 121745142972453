class Role {
  constructor(role, user) {
    this.raw = {};
    this.userId = user.id;
    this.value = role.value;
    this.name = role.name;

    this.raw.enabled = user.roles.indexOf(role.name) >= 0;

    this.reset();
  }

  get isChanged() {
    return this.raw.enabled !== this.enabled;
  }

  reset() {
    this.enabled = this.raw.enabled;
  }
}

window.PS.Models.Permission.Role = Role;
