import { LoanProduct } from "../enums";
import * as PricingResponse from "./pricing_response";

export default class PricingResponseBuilder {
  static build(pricingResponse, scenario, payload, isCustom) {
    let ResponseClass = {
      [LoanProduct.residentialBridge.value]: PricingResponse.ResidentialBridgeResponse,
      [LoanProduct.residentialRentalLongTerm.value]: PricingResponse.ResidentialForRentResponse,
    }[scenario.loanProduct];

    const pricingObject = new ResponseClass(pricingResponse, scenario, payload, isCustom);

    return pricingObject.toHash();
  }
}
