<template>
  <diligence-document-field :document="document" :field="field">
    <div class="col-5">
      <div
        v-for="(option, index) in field.collection"
        :key="index"
        class="radio-control radio-control-inline js-radio-input"
      >
        <input
          :id="optionId(index)"
          v-model="field.value"
          type="radio"
          :disabled="disabled"
          :value="option.value"
        />
        <label :for="optionId(index)">
          <span class="control-toggle"></span>
          {{ option.name }}
        </label>
      </div>
    </div>
  </diligence-document-field>
</template>

<script>
import NonTextField from "./base/non_text_field";

const RadioField = NonTextField.extend({
  methods: {
    optionId: function(index) {
      return `${this.field.id}_${index}`;
    },
  },
});

export default Vue.component("diligence-document-radio-field", RadioField);
</script>
