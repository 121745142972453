<template>
  <base-icon
    v-if="enabled"
    @click.native="explain"
    title="View Details"
    icon="search"
    class="lps-explain"
  />
</template>

<script>
import LoanCalculatorService from "services/loan_calculator_service";

export default {
  name: "lpc-explain",
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    evaluationId: {
      type: String,
      required: true,
    },
  },
  methods: {
    explain() {
      LoanCalculatorService.explainEvaluationResults(this.evaluationId);
    },
  },
};
</script>

<style scoped>
.lps-explain {
  cursor: pointer;
  color: #d8dbdc;
  height: 14px;
  position: relative;
  top: 2px;
}
</style>
