<template>
  <div class="container saved-scenarios">
    <base-loading :show="isLoading"/>
    <div class="columns is-vcentered is-mobile">
      <div class="column">
        <h3>Scenarios</h3>
      </div>
      <div class="column" v-show="hasSavedScenarios && !isLoading">
        <router-link
          :to="{ name: 'new_loan_scenario' }"
          class="base-button button is-primary is-pulled-right"
        >
          Add New Scenario
        </router-link>
      </div>
    </div>
    <div v-show="!hasSavedScenarios && !isLoading" class="build-scenario-container">
    <div class="scenario-icon"/>
      <h3>Build your first scenario</h3>
      <p>Explore scenarios and check out how different loan terms affect your pricing</p>
      <router-link
        :to="{ name: 'new_loan_scenario' }"
        class="base-button button is-primary"
      >
        Build a Scenario
      </router-link>
    </div>
    <base-table
      v-show="hasSavedScenarios && !isLoading"
      hoverable
      :data="scenarios"
      :paginated="true"
      :per-page="25"
      :pagination-simple="true"
      :pagination-position="`both`"
      @click="editLoanScenario"
      class="drafts-table"
    >
      <base-column
        v-slot="props"
        field="updatedAt"
        label="Date Modified"
      >
        {{ formatDateTime(props.row.updatedAt) }}
      </base-column>
      <base-column
        v-slot="props"
        field="loanProduct"
        label="Loan Product"
      >
        {{ LoanProduct.titles[props.row.loanProduct] }}
      </base-column>
      <base-column
        v-slot="props"
        field="address"
        label="Property Address"
        cell-class="property-address"
      >
        <post-address :address="props.row" multiline />
      </base-column>
      <base-column
        v-slot="props"
        field="loanPurpose"
        label="Loan Purpose"
      >
        {{ LoanPurpose.titles[props.row.loanPurpose] }}
      </base-column>
      <base-column
        v-slot="props"
        field="asIsValue"
        label="As Is Value"
        numeric
      >
        {{ formatCurrency(props.row.asIsValue) }}
      </base-column>
      <base-column
        v-slot="props"
        field="ficoScore"
        label="FICO"
        numeric
      >
        {{ props.row.ficoScore }}
      </base-column>
      <base-column
        v-slot="props"
        field="psLoanId"
        label=""
      >
        <a
          v-if="props.row.psLoanId"
          :href="loanUrl(props.row.psLoanId)"
          @click.stop
          target="_blank"
        >
          View Loan
        </a>
      </base-column>
    </base-table>
  </div>
</template>

<script>
import { formatCurrency, formatDateTime } from "./utils/formatter";
import { LoanProduct, LoanPurpose } from "./enums";
import PostAddress from "./post_address";

export default {
  name: "scenarios-list",
  components: { PostAddress },
  inject: ["api"],
  props: {
    featureFlag: Object,
  },
  data() {
    return {
      LoanProduct,
      LoanPurpose,
      scenarios: [],
      loading: false,
      abortController: null,
    };
  },
  async mounted() {
    await this.loadScenarios();
  },
  watch: {
    $route: "loadScenarios",
  },
  methods: {
    formatCurrency,
    formatDateTime,

    async loadScenarios() {
      this.loading = true;
      try {
        this.scenarios = await this.api.draft.list();
      } finally {
        this.loading = false;
      }
    },

    editLoanScenario(row) {
      this.$router.push({
        name: "edit_loan_scenario",
        params: { id: row.psId },
      });
    },

    loanUrl(id) {
      if (window.location.pathname.startsWith("/manage")) {
        return `/manage/loans/${id}`;
      } else {
        return `/loans/${id}`;
      }
    },
  },
  computed: {
    isLoading() {
      return this.loading;
    },

    hasSavedScenarios() {
      return this.scenarios.length > 0;
    },
  },
};
</script>

<style scoped>
.scenarios .container.saved-scenarios {
  padding-bottom: 32px;
  width: auto;
}

.saved-scenarios > .is-vcentered {
  padding-top: 26px;
}

.saved-scenarios h3,
.saved-scenarios .button {
  margin-bottom: var(--space-unit-md);
}

.saved-scenarios h3 {
  color: var(--color-grey-86);
  font-size: 20px;
  font-weight: 600;
}

.saved-scenarios .button {
  font-size: var(--font-size);
  font-weight: 600;
}

.button.is-focused:not(:active),
.button:focus:not(:active) {
  box-shadow: none;
}

.saved-scenarios .button:hover {
  background: var(--color-blue-hover);
  text-decoration: none;
}

.build-scenario-container {
  display: flex;
  flex-direction: column;
  max-width: 464px;
  text-align: center;
}

.build-scenario-container .scenario-icon {
  background: url("./images/scenario_icon.svg") 0 0 / contain no-repeat;
  height: 210px;
  width: 210px;
}

.build-scenario-container p {
  font-size: var(--font-size-md);
}

.build-scenario-container .button {
  width: 172px;
  height: 48px;
  margin-top: var(--space-unit-md);
  font-size: var(--font-size-md);
}

.build-scenario-container,
.build-scenario-container .scenario-icon,
.build-scenario-container .button {
  margin-left: auto;
  margin-right: auto;
}
</style>
<style>
.drafts-table .table thead th,
.drafts-table .table thead td {
  color: var(--color-grey-86);
}
.drafts-table .table-wrapper,
.drafts-table .top.level {
  margin-bottom: var(--space-unit-md);
}

.drafts-table .table.is-hoverable tbody tr:not(.is-selected):hover {
  background-color: var(--color-grey-06);
  cursor: pointer;
}

.drafts-table .property-address {
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
