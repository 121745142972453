<template>
  <div class="institution-detail document-tracking-header-col">
    <div class="col-label"> Allocated To: </div>
    <div class="institution-allocator col-value">{{allocatedToText}}</div>
    <div class="col-label"> Current Owner: </div>
    <div class="institution-owner col-value">{{currentOwnerText}}</div>
    <div v-if="buyer" class="col-label"> Buyer: </div>
    <div v-if="buyer" class="institution-owner col-value">{{buyer}}</div>
  </div>
</template>

<script>
import { missingValue } from "filters";

export default {
  name: "institution-details",
  props: {
    loan: {
      type: Object,
    },
  },
  computed: {
    allocatedToText() {
      return missingValue(this.loan.allocatedToText);
    },
    currentOwnerText() {
      return missingValue(this.loan.currentOwnerText);
    },
    buyer() {
      return this.loan.buyerName;
    },
  },
};
</script>
