<template>
  <div class="details-row row">
    <div class="col-12">
      <h4>Overview</h4>
    </div>
    <div class="col-4">
      <div class="editable-field-container">
        <div class="form-group">
          <label class="editable-field-label control-label">Statement Type</label>
          <select
            v-model="statement.statementType"
            class="form-control"
            @change="onStatementTypeChange"
          >
            <option />
            <option v-for="(label, name) in statementTypes" :key="name" :value="name">
              {{ label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="editable-field-container">
        <div class="form-group">
          <label class="editable-field-label control-label">Reason for Sale</label>
          <select
            :disabled="estimatePayoffQuoteSelected"
            v-model="statement.payoffReasonType"
            class="form-control"
          >
            <option />
            <option v-for="(label, name) in payoffReasonTypes" :key="name" :value="name">
              {{ label }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "resale-statement-edit-overview",
  props: ["payoffReasonTypes", "statementTypes", "statement", "overviewType"],
  methods: {
    onStatementTypeChange() {
      if (this.statement.statementType != "third_party_sale") {
        this.statement.priceDiscountPremiumAmount = 0;
      }
      if (this.statement.statementType === "estimate_payoff_quote") {
        this.statement.payoffReasonType = null;
      }
      this.$emit("sync-interest");
    },
  },
  computed: {
    estimatePayoffQuoteSelected() {
      return this.statement.statementType === "estimate_payoff_quote";
    },
  },
};
</script>
