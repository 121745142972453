<template>
  <view-panel>
    <template slot="header">
      <editable-view-panel-header
        title="ACH Details"
        :status="status"
        :can-edit="canEdit"
        :on-edit="openEditModal"
      />
    </template>
    <table class="view-table">
      <tbody>
        <tr>
          <td class="view-table-cell-label">Tax ID</td>
          <td class="view-table-cell-value">{{ taxId }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Tax ID Type</td>
          <td class="view-table-cell-value">{{ taxIdType }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Address</td>
          <td class="view-table-cell-value">{{ address1 }}<br>{{ address2 }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Account Holder Name</td>
          <td class="view-table-cell-value">{{ accountHolderName }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Account Nickname</td>
          <td class="view-table-cell-value">{{ accountNickname }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Account Type</td>
          <td class="view-table-cell-value">{{ accountType }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Account Number</td>
          <td class="view-table-cell-value">{{ accountNumber }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Routing Number</td>
          <td class="view-table-cell-value">{{ routingNumber }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Primary Contact Name</td>
          <td class="view-table-cell-value">{{ primaryContactName }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Tax Email</td>
          <td class="view-table-cell-value">{{ primaryContactEmail }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Distribution Email</td>
          <td class="view-table-cell-value">{{ distributionEmail }}</td>
        </tr>
      </tbody>
    </table>
    <a-c-h-details-form-modal
      :show="isEditModalOpened"
      :entity="achDetailsToSave"
      @close="closeEditModal"
      @ach-details-updated="updateEntity"
    />
  </view-panel>
</template>
<script>
import ACHDetails from "models/lender_legal_entities/ach_details";
import ViewPanel from "components/view_panel.vue";
import ACHDetailsFormModal from "./form_modal";
import EditableViewPanelHeader from "components/lender_legal_entities/shared/editable_view_panel_header";

export default {
  name: "ach-details-panel",
  components: {
    ViewPanel,
    ACHDetailsFormModal,
    EditableViewPanelHeader,
  },
  props: {
    achDetails: {
      required: true,
      type: ACHDetails,
    },
    policy: {
      required: true,
      type: Object,
    },
  },
  data: function() {
    return {
      achDetailsToSave: this.achDetails.copy,
      isEditModalOpened: false,
    };
  },
  computed: {
    accountHolderName() {
      return "Obfuscated";
    },
    accountNickname() {
      if (!this.achDetails.accountNickname) {
        return "";
      }

      return this.achDetails.accountNickname;
    },
    accountNumber() {
      if (!this.achDetails.partialAccountNumber) {
        return "XXXXXX";
      }

      return `XXXXXX${this.achDetails.partialAccountNumber}`;
    },
    accountType() {
      const type = this.achDetails.accountCategoryDisplay || "";

      return type.charAt(0).toUpperCase() + type.slice(1);
    },
    address1() {
      return this.achDetails.address;
    },
    address2() {
      if (!this.achDetails.city || !this.achDetails.state || !this.achDetails.postalCode) {
        return;
      }

      return `${this.achDetails.city}, ${this.achDetails.state} ${this.achDetails.postalCode}`;
    },
    canEdit() {
      return this.policy.canEditAch;
    },
    primaryContactEmail() {
      return this.achDetails.email;
    },
    primaryContactName() {
      if (!this.achDetails.firstName || !this.achDetails.lastName) {
        return;
      }

      return `${this.achDetails.firstName} ${this.achDetails.lastName}`;
    },
    distributionEmail() {
      return this.achDetails.distributionEmail;
    },
    routingNumber() {
      if (!this.achDetails.partialRoutingNumber) {
        return "XXXXXX";
      }

      return `XXXXXX${this.achDetails.partialRoutingNumber}`;
    },
    status() {
      return this.achDetails.status?.toUpperCase();
    },
    taxId() {
      return "XXX-XX-XXXX";
    },
    taxIdType() {
      return this.achDetails.tinType?.toUpperCase();
    },
  },
  methods: {
    openEditModal() {
      this.isEditModalOpened = true;
    },
    closeEditModal() {
      this.isEditModalOpened = false;
      this.achDetailsToSave.reset();
    },
    updateEntity(updatedEntity) {
      this.achDetails.update(updatedEntity);
      this.achDetailsToSave.update(updatedEntity);
    },
  },
};
</script>
