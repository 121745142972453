<template>
  <div>
    <table v-if="borrowers.length" class="additional-info-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Mailing Address</th>
          <th>SSN/EIN</th>
          <th>FICO</th>
          <th></th>
        </tr>
      </thead>

      <borrower-row
        v-for="borrower in borrowerList"
        :key="borrower.id"
        :borrower="borrower"
        :can-delete-borrower="canDeleteBorrower(borrower)"
        :can-edit-borrower="canEditBorrower(borrower)"
        class="borrower-info"
        @on-borrower-edit="openEditBorrowerModal"
        @on-borrower-delete="deleteBorrower"
      />

      <tfoot>
        <tr>
          <td colspan="5">
            <a @click.prevent="openAddBorrowerModal" href="#" title="Add borrower" v-show="canUpdateBorrowerData">
              {{addBorrowerCopy}}
            </a>
          </td>
        </tr>
      </tfoot>
    </table>

    <borrower-add-modal
      :show="showBorrowerAddModal"
      :borrower-residency="borrowerResidency"
      :borrower-types="borrowerTypes"
      :us-states="usStates"
      :genders="genders"
      @borrower-created="borrowerCreated"
      @close="closeBorrowerAddModal"
    />

    <borrower-edit-modal
      :show="showBorrowerEditModal"
      :is-first-borrower="isFirstBorrower(borrowerToEdit)"
      :borrower-to-edit="borrowerToEdit"
      :borrower-residency="borrowerResidency"
      :borrower-types="borrowerTypes"
      :can-update-borrower-data="canUpdateBorrowerData"
      :us-states="usStates"
      :genders="genders"
      @borrower-updated="borrowerUpdated"
      @close="closeBorrowerEditModal"
    />
  </div>
</template>

<script>
import Borrower from "models/borrower";
import AjaxService from "services/ajax_service";
import BorrowerAddModal from "components/borrower/add_modal";
import BorrowerEditModal from "components/borrower/edit_modal";
import BorrowerRow from "components/borrower/borrower_row";

export default {
  name: "borrower-info-table",
  components: {
    BorrowerAddModal,
    BorrowerEditModal,
    BorrowerRow,
  },
  props: {
    borrowers: {
      type: Array,
      default: () => [],
    },
    borrowerResidency: {
      type: Object,
      default: () => ({}),
    },
    borrowerTypes: {
      type: Object,
      default: () => ({}),
    },
    usStates: {
      type: Object,
      default: () => ({}),
    },
    genders: {
      type: Object,
      default: () => ({}),
    },
    canUpdateBorrowerData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      routes: PSData.routes || {},
      showBorrowerAddModal: false,
      showBorrowerEditModal: false,
      borrowerToEdit: {},
      isDeletingBorrower: this.borrowers.reduce((isDeletingBorrower, borrower) => {
        isDeletingBorrower[borrower.id] = false;
        return isDeletingBorrower;
      }, {}),
    };
  },
  computed: {
    addBorrowerCopy() {
      if (this.borrowers.length > 0) {
        return "+ Add Additional Borrower";
      } else {
        return "+ Add Borrower";
      }
    },
    borrowerList() {
      return this.borrowers.map(b => new Borrower(b));
    },
  },
  methods: {
    canEditBorrower(borrower) {
      return this.canUpdateBorrowerData || borrower.isIndividual;
    },
    canDeleteBorrower(borrower) {
      return !this.isFirstBorrower(borrower) && this.canUpdateBorrowerData;
    },
    isFirstBorrower(borrower) {
      return this.borrowerList.indexOf(borrower) === 0;
    },
    openAddBorrowerModal() {
      this.showBorrowerAddModal = true;
    },
    borrowerCreated(borrower) {
      this.$emit("borrower-created", borrower);
    },
    openEditBorrowerModal(borrower) {
      this.borrowerToEdit = borrower;
      this.showBorrowerEditModal = true;
    },
    borrowerUpdated(borrower) {
      this.$emit("borrower-updated", borrower);
    },
    deleteBorrower(borrower) {
      if (this.isDeletingBorrower[borrower.id]) return;
      if (!window.confirm("Are you sure?")) return;

      this.isDeletingBorrower[borrower.id] = true;

      const url = `${this.routes.loanBorrowers}/${borrower.id}`;

      AjaxService.delete(url)
        .then(() => this.$emit("borrower-deleted", borrower))
        .finally(() => (this.isDeletingBorrower[borrower.id] = false));
    },
    closeBorrowerAddModal() {
      this.showBorrowerAddModal = false;
    },
    closeBorrowerEditModal() {
      this.showBorrowerEditModal = false;
    },
  },
};
</script>
