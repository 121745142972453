<template>
  <span>
    {{ title }}&nbsp;
    <a href="#" @click.prevent="onEdit" v-if="canEdit">
      <i class="fas fa-pencil-alt" />
    </a>
    <span class="float-right">{{ status }}</span>
  </span>
</template>

<script>
export default {
  name: "editable-view-panel-header",
  props: {
    title: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: false,
    },
    canEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    onEdit: {
      type: Function,
      required: true,
    },
  },
};
</script>
