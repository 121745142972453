$(() => {
  $(window).on("scroll", function() {
    var $pageContainer = $(".manage-container");

    var offset = $(".sticky-element").data("offset");
    if (!offset) {
      return;
    }

    if ($(window).scrollTop() > offset) {
      $pageContainer.addClass("sticky-container");
    } else {
      $pageContainer.removeClass("sticky-container");
    }
  });
});
