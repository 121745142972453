<template>
  <div class="previous-value-field-container" v-if="showPreviousValue">
    <label class="loan-attribute-label control-label">
      Previous Value
    </label>
    <div class="previous-field-value">
      {{displayValue}}
    </div>
  </div>
</template>

<script>
import toNumber from "lodash/toNumber";

export default {
  name: "previous-value",
  props: {
    currency: Boolean,
    field: String,
    settlementStatement: Object,
  },
  computed: {
    currentValue() {
      return this.settlementStatement[this.field];
    },
    previousValue() {
      return this.settlementStatement.pastStatement[this.field];
    },
    displayValue() {
      if (this.currency) {
        return this.$options.filters.accounting(this.previousValue);
      }

      return this.previousValue;
    },
    showPreviousValue() {
      if (this.currency) {
        return toNumber(this.previousValue) !== toNumber(this.currentValue);
      } else {
        return this.previousValue !== this.currentValue;
      }
    },
  },
};
</script>
