<template>
  <div class="row">
    <editable-select
      :options="usersExtended"
      :editing="editing"
      :value="value"
      :disabled="disabled"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import EditableSelect from "../editable_select";

export default {
  components: {
    EditableSelect,
  },
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    disabled: Boolean,
    editing: Boolean,
    users: Array,
    value: [Number, String],
  },
  computed: {
    usersExtended() {
      if (!this.model.assignedToId) {
        return this.users;
      }
      const user = this.users.find(u => u.id == this.model.assignedToId);
      if (user) {
        return this.users;
      }
      const additionalUser = {
        id: this.model.assignedToId,
        text: this.model.assignedToName,
        optionDisabled: true,
      };
      return [additionalUser].concat(this.users);
    },
  },
};
</script>
