<template>
  <div class="tasklist-rule">
    <operator
      v-if="mapping.logicOperator"
      :disabled="disabled"
      :rule="mapping"
    />
    <div class="condition">
      <base-text-field
        v-if="disabled"
        :value="contextAddedLabel"
        disabled
        class="condition-component condition-field"
      />
      <base-select-field
        v-else
        v-model="mapping.field"
        :options="fieldOptions"
        class="condition-component condition-field"
        rules="required"
        @input="onFieldChange"
      />
      <base-field
        v-if="disabled"
        class="condition-component condition-operator"
      >
        <div :disabled="disabled" class="input">
          {{ mapping.operator }}
        </div>
      </base-field>
      <base-select-field
        v-else
        v-model="mapping.operator"
        :options="operatorOptions"
        rules="required"
        class="condition-component condition-operator"
      />
      <base-text-field
        v-if="disabled"
        v-model="mapping.value"
        :disabled="disabled"
        class="condition-component condition-value"
      />
      <template v-else>
        <base-text-field
          v-if="valueType === 'string'"
          v-model="mapping.value"
          rules="required"
          class="condition-component condition-value"
        />
        <base-number-field
          v-if="valueType === 'number'"
          v-model="numericValue"
          rules="required"
          class="condition-component condition-value"
        />
        <base-currency-field
          v-if="valueType === 'currency'"
          v-model="numericValue"
          rules="required"
          class="condition-component condition-value"
        />
        <base-select-field
          v-if="valueType === 'select'"
          v-model="mapping.value"
          :options="valueOptions"
          rules="required"
          class="condition-component condition-value"
        />
        <base-select-field
          v-if="valueType === 'boolean'"
          v-model="mapping.value"
          :options="booleanOptions"
          rules="required"
          class="condition-component condition-value"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { OPERATORS, SELECT_OPERATORS } from "./definitions_mapping";
import Operator from "./operator";
import { store } from "../template_revision/store";

export default {
  components: {
    Operator,
  },
  props: {
    mapping: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    usedFields: {
      type: Array,
      default: () => [],
    },
    filterByContext: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      definitions: store.state.rulesDefinitions,
      fieldDefinition: {},
    };
  },
  computed: {
    contextAddedLabel() {
      return this.definitions[this.mapping.definitionName].contextAddedLabel;
    },
    fieldOptions() {
      return Object.keys(this.definitions)
        .filter(definitionKey => {
          if (this.filterByContext) {
            return this.definitions[definitionKey].contextName === this.filterByContext;
          } else {
            return true;
          }
        })
        .filter(
          definitionKey =>
            definitionKey == this.mapping.field || !this.usedFields.includes(definitionKey)
        )
        .map(definitionKey => {
          return [this.definitions[definitionKey].contextAddedLabel, definitionKey];
        })
        .sort();
    },
    valueOptions() {
      return this.fieldDefinition?.values || [];
    },
    valueType() {
      return this.fieldDefinition?.type || "string";
    },
    operatorOptions() {
      if (this.valueType === "select" || this.valueType === "boolean") {
        return this.prepareOptions(SELECT_OPERATORS);
      }
      return this.prepareOptions(OPERATORS);
    },
    booleanOptions() {
      return [["Yes", true], ["No", false]];
    },
    numericValue: {
      get() {
        return this.mapping.value;
      },
      set(newValue) {
        const input = this.$el?.querySelector(".condition-value input");

        if (input && input._vCleave) {
          this.mapping.value = parseFloat(input._vCleave.getRawValue());
        } else {
          this.mapping.value = newValue;
        }
      },
    },
  },
  created() {
    if (this.mapping.field == "state") {
      this.mapping.value = this.mapping.value.toUpperCase();
    }
    this.fieldDefinition = this.definitions[this.mapping.field];
  },
  methods: {
    onFieldChange() {
      this.fieldDefinition = this.definitions[this.mapping.field];
    },
    prepareOptions(operators) {
      return Object.keys(operators).map(operatorKey => [OPERATORS[operatorKey], operatorKey]);
    },
  },
};
</script>
<style scoped>
.condition {
  display: flex;
}

.tasklist-rule .condition-component {
  padding: 0;
}

.condition-field,
.condition-value {
  flex: 2;
}

.condition-operator {
  flex: 1;
  margin: 0 var(--space-unit);
}

.condition-operator .input,
.condition-logic-operator .input {
  justify-content: center;
}
</style>
