<template>
  <mfe-connect v-bind="settings" />
</template>

<script>
import MfeConnect from "components/mfe_connector";

export default {
  components: {
    MfeConnect,
  },

  created() {
    this.settings = {
      serviceUrl: `${window.location.protocol}//${PSData.loanTradingServiceHost}`,
      applicationName: "adminTrading",
      props: {
        baseUrl: PSData.loanTradingAdminBaseUrl,
      },
    };
  },
};
</script>
