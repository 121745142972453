/*
  This mixin includes functionality for Google Places Autocomplete to your Vue
  component. This allows for seamless integration without needing
  to pollute your component with noisy events or sub-components.

  When including this mixin, be sure to define the #handleAutocompleteSelected() method in your Vue
  component.

  Example Usage:
    ```js
    <template>
      <input v-model="address" placeholder="" ref="address-input"/>
    </template>

    <script>
    require GoogleAutocompleteMixin from "./google_autocomplete_mixin"

    export default {
      name: "some-component",
      data: function() {
        return {
          address: null,
        }
      }
      mixins: [
        GoogleAutocompleteMixin,
      ],
      mounted() {
        this.attachAutocompleteToElement(this.$refs["address-input"])
      },
      methods: {
        handleAutocompleteSelected(googleAddress) {
          this.address = googleAddress.fullStreetName;
        },
      },
    }
    </script>
    ```
*/
import requireGoogleMap from "google_maps";

const GOOGLE_ADDRESS_MAPPING = {
  administrative_area_level_1: "state",
  locality: "city",
  sublocality_level_1: "city",
  postal_code: "zipCode",
  route: "streetName",
  street_number: "streetNumber",
};

class GoogleAddress {
  city;
  state;
  streetName;
  streetNumber;
  zipCode;

  constructor(addressComponents = []) {
    addressComponents.forEach(addressComponent => {
      const componentType = addressComponent.types[0];
      const fieldName = GOOGLE_ADDRESS_MAPPING[componentType];

      if (fieldName) {
        this[fieldName] = addressComponent.short_name;
      }
    });
  }

  get fullStreetName() {
    return [this.streetNumber, this.streetName].filter(partial => partial).join(" ");
  }
}

function findInputElement(element) {
  if (element) {
    return element.tagName.toLowerCase() === "input" ? element : element.querySelector("input");
  }
}

function ensureBrowserAutofillOff(element) {
  // Attaching the Google places widget to the input element will override
  // the 'autocomplete' attribute to 'off', however, Chrome ignores this setting
  // and still shows the browser-level autofill menu if the label for the input contains any
  // permutation of 'address' or similar.  Keying the autocomplete value to 'nope' will ensure
  // that the browser autofill stays off.
  // For more info, see: https://crbug.com/587466
  const autocompleteMutationObserver = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (mutation.target.getAttribute("autocomplete") !== "nope") {
        mutation.target.setAttribute("autocomplete", "nope");
      }
    });
  });

  autocompleteMutationObserver.observe(element, {
    attributeFilter: ["autocomplete"],
  });
}

function attachGoogleAutocomplete(element, googleApi) {
  const options = {
    componentRestrictions: { country: ["us"] },
    fields: ["address_components"],
    types: ["address"],
  };

  return new googleApi.maps.places.Autocomplete(element, options);
}

export default {
  data() {
    return {
      googleAutocomplete: {
        api: null,
        autocomplete: null,
        inputElement: null,
      },
    };
  },

  beforeDestroy() {
    if (this.googleAutocomplete.api) {
      this.googleAutocomplete.api.maps.event.clearInstanceListeners(
        this.googleAutocomplete.autocompleteElement
      );
    }
  },

  methods: {
    attachAutocompleteToElement(element) {
      requireGoogleMap().then(() => {
        const inputElement = findInputElement(element);

        if (!inputElement) {
          throw new Error("Given element must be an <input> or contain one");
        }

        ensureBrowserAutofillOff(inputElement);
        const autocomplete = attachGoogleAutocomplete(inputElement, google);

        autocomplete.addListener("place_changed", () => {
          const placeData = this.googleAutocomplete.autocomplete.getPlace();
          const googleAddress = new GoogleAddress(placeData.address_components);
          inputElement.value = googleAddress.fullStreetName;
          this.handleAutocompleteSelected(googleAddress);
        });

        this.googleAutocomplete.api = google;
        this.googleAutocomplete.inputElement = inputElement;
        this.googleAutocomplete.autocomplete = autocomplete;
      });
    },

    handleAutocompleteSelected(_googleAddress) {
      throw new Error(
        "Override #handleAutocompleteSelected() to handle autocomplete suggestions being selected"
      );
    },
  },
};
