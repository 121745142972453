<template>
  <div class="reserve-draw-request-readonly-fields">
    <div class="details-row no-border row">
      <div class="col-3">
        <h3 class="row-header">Draw Amount</h3>
      </div>
      <div class="fields-container col-9">
        <div class="fields-row readonly-row">
          <div class="col-4">
            <h4 class="readonly-property">Draw Number</h4>
            <span class="readonly-value">{{ reserveDrawRequest.drawRequestNumber }}</span>
          </div>
          <div class="col-4 net-draw-amount-column">
            <h4 class="readonly-property">Net Draw Amount</h4>
            <span class="readonly-value">{{ reserveDrawRequest.approvedDrawAmountNumber() | money }}</span>
          </div>
        </div>
        <div class="fields-row readonly-row">
          <div class="col-4">
            <h4 class="readonly-property">Request Amount</h4>
            <span class="readonly-value">{{ reserveDrawRequest.requestedDrawAmount | money }}</span>
          </div>
        </div>
        <div class="fields-row readonly-row">
          <div class="col-4">
            <h4 class="readonly-property">Approved Reserve Draw</h4>
            <span class="readonly-value">{{ reserveDrawRequest.approvedDrawAmount | money }}</span>
            <span v-if="reserveDrawRequest.reimburseLenderDrawAmount" class="readonly-value"> (Reimburse Lender)</span>
          </div>
        </div>
        <div class="fields-row readonly-row">
          <div class="col-4">
            <h4 class="readonly-property">Borrower Contribution Amount</h4>
            <span class="readonly-value">{{ reserveDrawRequest.borrowerContributedAmount() | money }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="details-row no-border row">
      <div class="col-3">
        <h3 class="row-header">Fees &amp; Reimbursements</h3>
      </div>
      <div class="fields-container col-9">
        <div class="fields-row readonly-row">
          <div class="col-8">
            <h4 class="readonly-property">Insp. Fee</h4>
            <span class="readonly-value">{{ reserveDrawRequest.inspectionFee | money }}</span>
            <span v-if="reserveDrawRequest.reimburseLenderInspectionFee" class="readonly-value"> (Reimburse Lender)</span>
          </div>
          <div class="col-4">
            <h4 class="readonly-property">Net Wire Amount</h4>
            <span class="readonly-value">{{ reserveDrawRequest.netWireAmount() | money }}</span>
          </div>
        </div>
        <div class="fields-row readonly-row">
          <div class="col-4">
            <h4 class="readonly-property">Admin. Fee</h4>
            <span class="readonly-value">{{ reserveDrawRequest.administrationFee | money }}</span>
            <span v-if="reserveDrawRequest.reimburseLenderAdministrationFee" class="readonly-value"> (Reimburse Lender)</span>
          </div>
        </div>
        <div class="fields-row readonly-row">
          <div class="col-4">
            <h4 class="readonly-property">Setup Fee</h4>
            <span class="readonly-value">{{ reserveDrawRequest.setupFee | money }}</span>
            <span v-if="reserveDrawRequest.reimburseLenderSetupFee" class="readonly-value"> (Reimburse Lender)</span>
          </div>
        </div>
        <div class="fields-row readonly-row">
          <div class="col-4">
            <h4 class="readonly-property">Other Fee</h4>
            <span class="readonly-value">{{ reserveDrawRequest.otherFee | money }}</span>
            <span v-if="reserveDrawRequest.reimburseLenderOtherFee" class="readonly-value"> (Reimburse Lender)</span>
          </div>
        </div>
      </div>
    </div>
    <div class="details-row no-border row">
      <div class="col-3">
        <h3 class="row-header">Additional Notes / Comments</h3>
      </div>
      <div class="fields-container col-9">
        <div class="fields-row readonly-row">
          <div class="notes-column col-8">
            <h4 class="readonly-property">Notes</h4>
            <span class="readonly-value">{{ reserveDrawRequest.notes }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reserve-draw-request-readonly-fields",
  props: {
    reserveDrawRequest: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
