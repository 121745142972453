<template>
  <div class="view-panel">
    <div class="view-panel-header">
      <slot name="header" />
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "view-panel",
};
</script>
