export function setupComponents(mapping) {
  document.addEventListener("DOMContentLoaded", () => {
    Object.entries(mapping).forEach(([selector, component]) => {
      Array.from(document.querySelectorAll(selector)).forEach(element => {
        new Vue({
          el: element,
          render: h => h(component),
        });
      });
    });
  });
}
