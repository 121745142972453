<template>
  <table class="table table-hover review-table">
    <thead>
      <tr>
        <th scope="col">
          Distribution ID
        </th>
        <th scope="col">
          Cash Receipt Item Detail ID
        </th>
        <th scope="col">
          Loan ID
        </th>
        <th scope="col">
          Disbursement Type
        </th>
        <th scope="col">
          Series Id
        </th>
        <th scope="col">
          Amount
        </th>
        <th scope="col">
          Particiant Type
        </th>
        <th scope="col">
          Status
        </th>
        <th scope="col">
          Creation Type
        </th>
        <th scope="col">
          Updated At
        </th>
        <th />
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.id">
        <td>
          {{ item.id }}
        </td>
        <td>
          {{ item.cashReceiptItemDetailId }}
        </td>
        <td>
          {{ item.loanId }}
        </td>
        <td>
          {{ item.disbursementTypeText }}
        </td>
        <td>
          --
        </td>
        <td>
          {{ formatMoney(item.amount) }}
        </td>
        <td>
          {{ item.participantTypeText }}
        </td>
        <td>
          {{ item.statusText }}
        </td>
        <td>
          {{ item.creationTypeText }}
        </td>
        <td>
          {{ item.updatedAt }}
        </td>
        <td>
          <base-button @click="updateItem(item.id)" v-if="canUpdate(item.amount)">Update</base-button>
          <base-button @click="deleteItem(item.id)" v-if="canDelete(item.amount)">Delete</base-button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import AjaxService, { ERROR_STATUS, SUCCESS_STATUS } from "services/ajax_service";
import NumberFormatter from "models/formatters/number_formatter";
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    updateItem(id) {
      const url = `/manage/distributions/${id}/update_series`;
      this.postItem(url, id);
    },
    deleteItem(id) {
      const url = `/manage/distributions/${id}/delete`;
      this.postItem(url, id);
    },
    postItem(url, id) {
      this.loading = true;
      AjaxService.patch(url, {}, { "content-type": "application/json" })
        .then(data => {
          const message = data.message;
          AjaxService.sendNotification(message, SUCCESS_STATUS);
          this.removeItem(id);
        })
        .catch(e => {
          const message = e.data.errors || e;
          AjaxService.sendNotification(message, ERROR_STATUS);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeItem(id) {
      this.$emit("remove-item", id);
    },
    canDelete(amount) {
      return amount == 0;
    },
    canUpdate(amount) {
      return amount > 0;
    },
    formatMoney(amount) {
      return NumberFormatter.formatMoney(amount);
    },
  },
};
</script>
