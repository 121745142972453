<template>
  <markdown-view :value="value" />
</template>

<script>
import MarkdownView from "components/shared/markdown_view";

export default {
  components: {
    MarkdownView,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
};
</script>
