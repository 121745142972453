import { SETTINGS_CONFIG } from "services/user_settings/config";
import AjaxService from "services/ajax_service";
import GlobalBusService from "services/global_bus_service";
import merge from "lodash/merge";

const USER_SETTINGS_ROUTE = "/user/settings";
export const USER_SETTINGS_UPDATED_EVENT = "user-settings-updated";

class UserSettingsService {
  constructor() {
    this.state = this.defaultSettings;
    (this.initialSettingsFromServer = {}), (this.loading = false);
    this.loaded = false;
  }

  get defaultSettings() {
    return SETTINGS_CONFIG;
  }

  setItem(key, value, skipNotification = false) {
    const settingsToSave = { [key]: value };

    const messages = skipNotification ? { onSuccess: () => null, onError: () => null } : {};

    return new Promise((resolve, reject) => {
      return AjaxService.withNotifications(messages)
        .postJSON(USER_SETTINGS_ROUTE, { settings: settingsToSave })
        .then(response => {
          this.updateState(response);
          return resolve(response);
        })
        .catch(err => reject(err));
    });
  }

  getItem(key) {
    if (this.initialSettingsFromServer[key]) {
      return merge({}, this.initialSettingsFromServer[key] || {}, this.state[key]);
    }

    return this.state[key];
  }

  loadSettings() {
    if (this.loading) {
      return;
    }

    if (this.loaded) {
      GlobalBusService.$emit(USER_SETTINGS_UPDATED_EVENT, this.state);
      return;
    }

    this.loading = true;

    AjaxService.getJSON(USER_SETTINGS_ROUTE)
      .then(response => {
        this.updateState(response);
        this.loaded = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  updateState(newState) {
    this.state = merge({}, SETTINGS_CONFIG, newState);

    GlobalBusService.$emit(USER_SETTINGS_UPDATED_EVENT, this.state);
  }

  updateInitialSettings(key, value) {
    const settingsToUpdate = { [key]: value };
    this.initialSettingsFromServer = merge({}, this.initialSettingsFromServer, settingsToUpdate);
  }

  subscribe(callback) {
    GlobalBusService.$on(USER_SETTINGS_UPDATED_EVENT, callback);
  }

  unsubscribe(callback) {
    GlobalBusService.$off(USER_SETTINGS_UPDATED_EVENT, callback);
  }
}

export default new UserSettingsService();
