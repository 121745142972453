<template>
  <div
    v-if="loan"
    class="messages-section analyst"
    :class="{ collapsed: collapsed }"
    @mouseenter="$_preventBodyScroll"
    @mouseleave="$_enableBodyScroll"
  >
    <div class="activity-feed-events-section-component analyst invisibility-wrapper" :class="{ 'invisibility-reset': !collapsed }">
      <div class="activity-feed-header" :class="{ 'sla-header': isSlaWorklist }">
        <div v-if="isSlaWorklist" class="sla-info">
          <span class="loan-address">{{loan.fullAddress}}</span>
          <span class="loan-status" :class="'loan-' + loan.purchasedGroup">{{loan.groupName}}</span>
          <span class="loan-lender">{{loan.lenderName}}</span>
        </div>
        <a href="#" @click.prevent="collapsed = true" class="collapse-section"></a>
        <tabs ref="activity-feed-tabs-component" :tabs="visibleTabs" @tab-selected="changeTab" />
      </div>
      <div class="invisibility-wrapper activity-feed-content" :class="{ 'invisibility-reset': !collapsed && showEventsSection }">
        <events-section
          ref="events-section-component"
          :render-text-area="sectionTabs.messages.active"
          :display-for="displayFor"
          :loan="loan"
          :events="events"
          :can-update-external-comments="canUpdateExternalComments"
          :can-update-internal-comments="canUpdateInternalComments"
          @activity-update="messageActivityUpdate"
        />
      </div>
      <loan-tasks
        v-if="!hideLoanTaskSection"
        v-show="showLoanTasksSection"
        ref="loan-tasks-component"
        :documents="documents"
        :loan-id="this.loanId"
        :upload-settings="uploadSettings"
        :users="users"
        :can-update-loan-task-data="canUpdateLoanTaskData"
        :can-update-external-comments="canUpdateExternalComments"
        :can-update-internal-comments="canUpdateInternalComments"
        class="activity-feed-content"
        @activity-update="taskActivityUpdate"
      />

      <peerstreet-loan-followers
        :routes="routes"
        :followers="loanFollowers"
        :follower-candidates="loanFollowerCandidates"
        :lender-followers="loanLenderFollowers"
        :can-update-loan-followers-data="canUpdateLoanTaskData"
        :can-add-lender-followers="canAddLenderFollowers"
        :lender-candidates="loanLenderCandidates"
      />
    </div>

    <div class="activity-feed-events-section-collapsed" v-show="collapsed">
      <div class="expand-section-container">
        <a href="#" class="expand-section" @click.prevent="collapsed = false"></a>
      </div>
      <a href="#" class="expand-messages" @click.prevent="expandAndOpen('messages')"></a>
      <a href="#" class="expand-tasks" @click.prevent="expandAndOpen('loanTasks')"></a>
    </div>
  </div>
</template>

<script>
import EventsSection from "components/activity_feed_events/events_section";
import LoanTasks from "components/activity_feed_events/manage/loan_tasks";
import Tabs from "components/shared/tabs";
import MainLoanSectionFixer from "utils/main_loan_section_fixer";
import Vue from "vue";
import ActivityFeedPosition from "components/activity_feed_events/mixins/activity_feed_position_mixin";
import AjaxService from "services/ajax_service";
import MessageBusService from "services/message_bus_service";
import DocumentRules from "models/loan/rules/document_rules";
import sizeAdjuster from "utils/size_adjuster";
import GlobalBusService from "services/global_bus_service";
import LoanFollowersService from "services/loan_followers_service";
import PeerstreetLoanFollowers from "components/activity_feed_events/manage/loan_followers";

export default {
  name: "activity-feed-events-section",
  mixins: [ActivityFeedPosition],
  components: {
    EventsSection,
    Tabs,
    LoanTasks,
    PeerstreetLoanFollowers,
  },
  props: {
    displayFor: String,
    loanId: {
      type: String,
      required: true,
    },
    isSlaWorklist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const collapsed =
      PS.Services.LocalStorageService.getItem("manageActivityFeedCollapsed") || false;

    return {
      routes: PSData.routes || {},
      loan: null,
      events: [],
      documents: [],
      displayTask: null,
      collapsed: collapsed,
      additionalHeightOffset: 0,
      rulebook: undefined,
      sectionTabs: {
        messages: {
          name: "Messages",
          active: !this.isActive(),
          hidden: false,
          hasActivity: !this.isServicingLoan() && !!this.hasUnreadMessages,
        },
        loanTasks: {
          name: "Tasks",
          active: this.isActive(),
          hidden: this.isServicingLoan(),
          hasActivity: undefined,
        },
      },
      uploadSettings: {},
      users: [],
      hasUnreadMessages: false,
      messageViewsRoute: undefined,
      loanFollowers: [],
      loanLenderFollowers: [],
      loanFollowerCandidates: [],
      loanTaskPolicy: {},
      loanLenderCandidates: [],
      canAddLenderFollowers: false,
    };
  },
  computed: {
    canUpdateInternalComments() {
      return this.loanTaskPolicy.update_internal_task_comment.allowed;
    },
    canUpdateExternalComments() {
      return this.loanTaskPolicy.update_external_task_comment.allowed;
    },
    canUpdateLoanTaskData() {
      return this.loanTaskPolicy.update_loan_tasks_data.allowed;
    },
    loanFollowersService() {
      return new LoanFollowersService(this.routes.loanFollowers);
    },
    messageBus() {
      return MessageBusService.loanBus;
    },
    hideLoanTaskSection() {
      return this.isServicingLoan();
    },
    showEventsSection() {
      return this.sectionTabs.messages.active;
    },
    showLoanTasksSection() {
      return this.sectionTabs.loanTasks.active;
    },
    messagesWidget() {
      return this.$refs["events-section-component"].$refs["messages-input-container"];
    },
    activityFeedTabs() {
      return this.$refs["activity-feed-tabs-component"].$el;
    },
    loanTasksComponent() {
      return this.$refs["loan-tasks-component"].$refs["loan-tasks-container"];
    },
    visibleTabs() {
      return Object.keys(this.sectionTabs)
        .filter(k => !this.sectionTabs[k].hidden)
        .reduce((acc, k) => ({ ...acc, [k]: this.sectionTabs[k] }), {});
    },
    activityFeedEventsRoute() {
      return `/manage/loans/${this.loanId}/activity_feed_events?type=${this.displayFor}`;
    },
  },
  watch: {
    collapsed(newVal) {
      PS.Services.LocalStorageService.setItem("manageActivityFeedCollapsed", newVal);
      Vue.nextTick(() => this.adjustMainLoanSection());
    },
    loanId() {
      this.loadData();
    },
    hasUnreadMessages(newVal) {
      if (!this.isServicingLoan()) {
        this.sectionTabs.messages.hasActivity = newVal;
      }
    },
    "sectionTabs.messages.active": function(newVal) {
      if (newVal) {
        if (this.sectionTabs.messages.hasActivity) {
          this.markMessagesAsRead();
        }
      }
    },
  },
  mounted() {
    this.rulebook = new DocumentRules(this);
    this.messageBus.attachRulebook(this.rulebook);
    this.loadData();
    this.loadLoanFollowers();
    this.loadPolicy();

    if (this.sectionTabs.messages.active && this.sectionTabs.messages.hasActivity) {
      this.markMessagesAsRead();
    }
  },
  methods: {
    loadData() {
      AjaxService.getJSON(this.activityFeedEventsRoute).then(data => {
        this.loan = data.loan || {};
        this.documents = data.documents;
        this.events = data.events;
        PSData.uploadSettings = data.uploadSettings;
        this.uploadSettings = data.uploadSettings;
        this.users = data.analysts;
        this.hasUnreadMessages = data.hasUnreadMessages;
        this.messageViewsRoute = data.messageViewsRoute;
        this.loanTaskPolicy = data.loanTaskPolicy;

        Vue.nextTick(() => {
          sizeAdjuster(this.$_adjustActivityFeedHeight);

          if (this.collapsed) {
            this.adjustMainLoanSection();
          }
        });
      });
    },
    loadLoanFollowers() {
      this.loanFollowersService.load(this).then(() => {
        this.$emit("loan-followers-loaded", {
          lenderFollowers: this.loanLenderFollowers,
        });
      });
    },
    changeTab(tab) {
      const { sectionTabs } = this;
      Object.keys(sectionTabs).forEach(key => {
        sectionTabs[key].active = key === tab;
      });
    },
    adjustMainLoanSection() {
      new MainLoanSectionFixer().fixPosition();
    },
    expandAndOpen(tab) {
      this.collapsed = false;
      this.changeTab(tab);
    },
    isServicingLoan() {
      return this.displayFor === "servicing_loan";
    },
    isActive() {
      return !!(PSData.displayTask || !this.isServicingLoan());
    },
    markMessagesAsRead() {
      AjaxService.post(this.messageViewsRoute).then(() => this.messageActivityUpdate(false));
    },
    messageActivityUpdate(value) {
      this.sectionTabs.messages.hasActivity = value;
      GlobalBusService.$emit("loan-message-activity", value);
    },
    taskActivityUpdate(value) {
      this.sectionTabs.loanTasks.hasActivity = value;
      GlobalBusService.$emit("loan-task-activity", value);
    },
    loadPolicy() {
      const loanPolicy = new PS.Policies.EditLoanFormPolicy(PSData.loanPolicy);
      this.canAddLenderFollowers = loanPolicy.canAddLenderFollowers();
    },
  },
};
</script>
