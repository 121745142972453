<template>
  <div>
    <h4 class="document-tracking-section-header">
      Tracking Documents
    </h4>
    <table class="table document-tracking-table">
      <thead>
        <tr>
          <th>Document</th>
          <th class="document-description" >Description</th>
          <th>Status</th>
          <th>Notes</th>
          <th>Last Updated</th>
        </tr>
      </thead>
      <tbody class="document-tracking-table-body">
        <tr v-for="document in trackingDocuments" :key="document.name">
          <td>{{document.name}}</td>
          <td>{{document.description}}</td>
          <td><DocumentStatus :document="document"/></td>
          <td class="document-note">{{document.note}}</td>
          <td>{{document.lastUpdatedAt}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DocumentStatus from "components/document_tracking/document_status.vue";

export default {
  name: "document-tracking-show",
  components: { DocumentStatus },
  props: {
    trackingDocuments: {
      type: Array,
      required: true,
    },
  },
};
</script>
