<template>
  <div class="editable-field-container">
    <div v-if="editing" class="form-group">
      <label class="control-label editable-field-label">Days Owned</label>
      <input
        class="form-control"
        type="number"
        min="0"
        :value="value"
        @input="updateValue"
      />
    </div>

    <label v-if="!editing" class="control-label loan-attribute-label">
      Days Owned
    </label>
    <div v-if="!editing" class="editable-field-value">
      {{ value }}
    </div>
  </div>
</template>
<script>
export default {
  name: "DaysOwnedField",
  props: { editing: Boolean, value: [Number, String] },
  methods: {
    updateValue(event) {
      let parsedValue = event.target.valueAsNumber;
      if (parsedValue < 0) parsedValue = 0;
      this.$emit("input", parsedValue);
    },
  },
};
</script>
