import CommentsForm from "components/activity_feed_events/loan_tasks/comments/form";
import CommentsList from "components/activity_feed_events/loan_tasks/comments/list";
import Ajax from "services/ajax_service";

export default {
  components: { CommentsForm, CommentsList },
  props: {
    task: Object,
  },
  data() {
    return {
      comments: [],
      isLoading: false,
    };
  },
  mounted() {
    if (!window.__ps_test_mode) {
      this.loadComments();
    }
  },
  methods: {
    onBackButton() {
      this.$emit("back");
    },
    onNewComment(comment) {
      this.comments.push(comment);
      this.$emit("task-commented", comment);
    },
    completeTask() {
      this.task.isOpen = false;
      this.task.isPending = true;
      this.$emit("task-completed", this.task.id);
      this.$emit("back");
    },
    moveTaskToPending() {
      this.task.isOpen = false;
      this.task.isPending = true;
      this.$emit("task-completed", this.task.id);
    },
    confirmTask() {
      this.task.isOpen = false;
      this.task.isConfirmed = true;
      this.task.isPending = false;
      this.$emit("task-confirmed", this.task.id);
      this.$emit("back");
    },
    reopenTask() {
      this.task.isOpen = true;
      this.task.isPending = false;
      this.$emit("task-reopened", this.task.id);
    },
    deleteTask() {
      this.$emit("task-deleted", this.task.id);
      this.$emit("back");
    },
    updateTask(data) {
      data.id = this.task.id;

      this.$emit("task-updated", data);
      this.hideEditForm();
    },
    loadComments() {
      this.isLoading = true;

      return Ajax.get(this.task.commentsPath).then(data => {
        this.isLoading = false;

        if (data.error) {
          Ajax.sendNotification(data.error, "error");
        } else {
          this.comments = data.comments;
        }
      });
    },
  },
};
