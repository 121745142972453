<template>
  <modal-view :show="show" :on-close="onClose" class="base-modal funded-details-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          {{title}}
          <div v-show="isLoading" class="ps-loader ps-loader-small"></div>
          <a class="modal-close-icon" @click.prevent="onClose" href="#close">&times;</a>
        </h4>
      </div>
      <div class="modal-body">
        <h5>{{account.name}}</h5>
        <table class="table">
          <thead>
            <tr>
              <th>Loan</th>
              <th>Series</th>
              <th>Bank</th>
              <th class="account-group-amount">Amount</th>
              <th>Tranche</th>
              <th>Target Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="singleSeries in series" :key="singleSeries.psId">
              <td>{{singleSeries.loanId}}</td>
              <td>{{singleSeries.id}}</td>
              <td>Wells Fargo</td>
              <td class="account-group-amount">{{singleSeries.amount | accounting}}</td>
              <td>{{singleSeries.tranche | uppercase}}</td>
              <td>{{singleSeries.targetDate | formatDate}}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td></td>
              <td></td>
              <td class="account-group-amount">{{seriesTotal | accounting}}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button class="btn btn-action-cta" @click.prevent="onClose">
          Ok
        </button>
      </div>
    </div>
  </modal-view>
</template>

<script>
import AjaxService from "services/ajax_service";
import ModalView from "components/modal_view";
import sum from "lodash/sum";
import Series from "models/bank_center/series";

const typeTitle = {
  funded: "Funded",
  earmarked: "Earmarked",
};

export default {
  name: "series-details-modal",
  components: { ModalView },
  props: {
    type: String,
    account: Object,
    show: Boolean,
  },
  data: function() {
    return {
      routes: PSData.routes,
      series: [],
      isLoading: false,
    };
  },
  mounted: function() {
    this.getSeries();
  },
  computed: {
    seriesTotal() {
      return sum(this.series.map(series => series.amount));
    },
    title() {
      return typeTitle[this.type];
    },
  },
  filters: {
    uppercase: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.toUpperCase();
    },
  },
  methods: {
    getSeries() {
      this.isLoading = true;
      const url = this.account.getSeriesUrl(this.routes, this.type);

      return AjaxService.get(url).then(({ series }) => {
        this.series = [...series]
          .map(a => ({ ...a, _targetDate: new Date(a.targetDate || 0) }))
          .sort((a, b) => a._targetDate - b._targetDate)
          .map(a => new Series(a));
        this.isLoading = false;
      });
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
