<template>
  <div class="institution-sales-tracking-table">
    <table class="table">
      <thead>
        <tr>
          <th class="loan-id-column">Loan ID</th>
          <th class="property-type-column">Property Type</th>
          <th class="ps-allocation-column amount">PS Allocation</th>
          <th class="investor-rate-column amount">Investor Rate</th>
          <th class="ltv-column amount">LTV</th>
          <th class="days-allocated-column amount">Days Allocated</th>
          <th class="offer-review-column">Review</th>
          <th>Notes</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="offering in loanOfferings" :key="offering.id">
          <td class="loan-id-column"><a :href="`/manage/loans/${offering.loanPsId}`">{{offering.loanPsId}}</a></td>
          <td class="property-type-column">{{offering.loanPropertyTypeText}}</td>
          <td class="ps-allocation-column amount">{{offering.psAllocation | money}}</td>
          <td class="investor-rate-column amount">{{offering.loanInvestorRate | percent}}</td>
          <td class="ltv-column amount">{{offering.loanLtv | percent(0)}}</td>
          <td class="days-allocated-column amount">{{offering.daysAllocated | notAvailable}}</td>
          <td class="offer-review-column"><reviews-list :reviews="offering.review"></reviews-list></td>
          <td>{{offering.notes}}</td>
          <td class="dropdown-column">
            <action-button
              v-if="canUpdateBatch"
              v-on="$listeners"
              :batch="batch"
              :offering="offering"
            ></action-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import ActionButton from "./action_button";
import ReviewsList from "./reviews_list";

export default {
  components: {
    ActionButton,
    ReviewsList,
  },
  props: {
    loanOfferings: Array,
    batch: Object,
    canUpdateBatch: Boolean,
  },
};
</script>
