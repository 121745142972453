<template>
  <div :class="computedStyles">
    <div class="editable-field-container">
      <label class="loan-attribute-label control-label">{{ label }}</label>
      <div>{{ value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: String,
    },
    total: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedStyles() {
      return {
        total: this.total,
        "dark-border-row": !this.last && this.total,
        "border-row tab": !this.total,
      };
    },
  },
};
</script>
