<template>
  <div class="worklist" v-cloak="">
    <div class="worklist-wrapper">
      <div class="report-top-controls sticky-element" data-offset="91">
        <div class="row filter-container">
          <div class="col-2">
            <h3 class="worklist-title">SLA Worklist</h3>
            <div class="ps-loader ps-loader-small" v-if="!dataLoaded"></div>
          </div>

          <div class="col-10">
            <div class="float-right worklist-filter">
              <label>Filter:</label>
              <div>
                <users-dropdown
                  :users="analysts"
                  :role="`analyst`"
                  :current-selection="filters.analystId"
                  @filter-options="filterByAnalyst"
                  v-if="mounted"
                ></users-dropdown>
              </div>

              <div>
                <users-dropdown
                  :users="analysts"
                  :role="`processor`"
                  :current-selection="filters.processorId"
                  @filter-options="filterByProcessor"
                  v-if="mounted"
                ></users-dropdown>
              </div>

              <div>
                <users-dropdown
                  :users="lenders"
                  :role="`lender`"
                  :current-selection="filters.lenderId"
                  @filter-options="filterByLender"
                  v-if="mounted"
                ></users-dropdown>
              </div>
            </div>
          </div>
        </div>

        <div class="row filter-container">
          <div class="offset-5 col-7 checkbox-filters">
            <checkbox-filter
              :title="'Open Tasks'"
              :value="filters.openTasks"
              :total="openTasksSize"
              @filter-options="filterByOpenTasks"
            />

            <checkbox-filter
              :title="'Action on Analyst'"
              :value="filters.actionOnAnalyst"
              :total="actionOnAnalystItemSize"
              @filter-options="filterByActionOnAnalyst"
            />

            <checkbox-filter
              :title="'Follow Up Required'"
              :value="filters.followUpsRequired"
              :total="followUpsItemSize"
              @filter-options="filterByFollowUps"
            />

            <checkbox-filter
              :title="'Active SLAs'"
              :value="filters.activeSla"
              :total="activeSlaItemSize"
              @filter-options="filterByActiveSla"
            />
          </div>
        </div>
      </div>

      <div class="worklist-table" :class="sortingClass">
        <div class="worklist-row worklist-row-header">
          <div class="worklist-cell status">
            <sorting-header
                :title="'Loan Status'"
                :order="orderByLoanStatus"
                @on-change-direction="sortByLoanStatus"
            />
          </div>
          <div class="worklist-cell days-in-status">Days in Status</div>
          <div class="worklist-cell analyst">Analyst</div>
          <div class="worklist-cell processor">Processor</div>
          <div class="worklist-cell lender">Lender</div>
          <div class="worklist-cell address">Address</div>
          <div class="worklist-cell countdown">
            <sorting-header
                :title="'SLA Countdown'"
                :order="orderBySLA"
                @on-change-direction="sortBySLA"
            />
          </div>
          <div class="worklist-cell confidence">Confidence Week/ Month</div>
          <div class="worklist-cell forecast-date">
            <sorting-header
                :title="'Rate Lock Expiration Date'"
                :order="orderByForecastDate"
                @on-change-direction="sortByForecastDate"
            />
          </div>
          <div class="worklist-cell progress-update">Progress Update</div>
          <div class="worklist-cell open-tasks">
            <sorting-header
                :title="'Open Tasks'"
                :order="orderByOpenTasks"
                @on-change-direction="sortByOpenTasks"
            />
          </div>
          <div class="worklist-cell quick-preview"></div>
        </div>

        <div v-if="dataLoaded && !filteredLoans.length" class="worklist-empty">
          <span>Worklist is empty</span>
        </div>

        <template v-show="dataLoaded && filteredLoans.length">
          <worklist-row
            v-for="(loan, index) in filteredLoans"
            v-show="loan.shown"
            :loan="loan"
            :key="'workitem-' + loan.loanId"
            :index="index"
            :users="analysts"
            :server-time="serverTime"
            :loan-ids-with-task-activity="loanIdsWithTaskActivity"
            :loan-ids-with-message-activity="loanIdsWithMessageActivity"
            :can-update-worklist-data="canUpdateWorklistData"
            @processor-selected="assignProcessor"
            @analyst-selected="assignAnalyst"
            @mark-task-completed="handleMarkTaskCompleted"
            @open-activity-feed-events="openQuickPreview"
          />
        </template>
      </div>
    </div>

    <div class="worklist-preview">
      <activity-feed-events-section
        v-if="selectedLoan"
        :display-for="`analyst_loan`"
        :isSlaWorklist="true"
        :loan-id="selectedLoan.loanId"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";
import kebabCase from "lodash/kebabCase";
import {
  prepareLoansForDisplay,
  activeSlaItemSize,
  actionOnAnalystItemSize,
  followUpsItemSize,
  openTasksSize,
  orderDirectionByLoanStatus,
  orderDirectionBySLA,
  orderDirectionByForecastDate,
  orderDirectionByOpenTasks,
  detectLoanVisibility,
  sortByLoanStatus,
  sortBySLA,
  sortByForecastDate,
  SortBy,
  sortByOpenTasks,
} from "collections/manage/worklist";

import WorklistRow from "components/reports/worklist_row";
import UsersDropdown from "components/reports/users_dropdown";
import CheckboxFilter from "components/reports/worklist/checkbox_filter";
import UrlParamsService from "services/url_params_service";
import LocationService from "services/location_service";
import { error } from "services/notification";
import AjaxService from "services/ajax_service";
import SortingHeader from "components/shared/sorting_header";
import ActivityFeedEventsSection from "components/activity_feed_events/manage/section";
import GlobalBusService from "services/global_bus_service";

export default {
  name: "worklist",
  components: {
    WorklistRow,
    UsersDropdown,
    CheckboxFilter,
    SortingHeader,
    ActivityFeedEventsSection,
  },
  props: {
    canUpdateWorklistData: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const currentUserId = PSData.currentUserId.toString();
    return {
      analysts: (cloneDeep(PSData.analysts) || []).sort((a, b) => a.name.localeCompare(b.name)),
      lenders: (PSData.lenders || []).sort((a, b) => a.name.localeCompare(b.name)),
      endpoint: PSData.worklistEndpoint,
      persistedParams: UrlParamsService.defineService({
        analystId: { type: Array, itemType: String, default: [currentUserId] },
        processorId: { type: Array, itemType: String, default: undefined },
        lenderId: { type: Array, itemType: String, default: undefined },
        activeSla: { type: Boolean, default: false },
        actionOnAnalyst: { type: Boolean, default: false },
        followUpsRequired: { type: Boolean, default: false },
        openTasks: { type: Boolean, default: false },
        orderBy: { type: String, default: undefined },
        orderWay: { type: Number, default: undefined },
      }),
      filters: {},
      filteredLoans: [],
      serverTime: 0,
      mounted: false,
      dataLoaded: false,
      selectedLoan: null,
      loanIdsWithTaskActivity: PSData.loanIdsWithTaskActivity,
      loanIdsWithMessageActivity: PSData.loanIdsWithMessageActivity,
    };
  },
  mounted() {
    this.filters = this.persistedParams.load();
    this.persistedParams.save();

    this.getWorklist();
    GlobalBusService.$on("loan-task-activity", this.loanTaskActivity);
    GlobalBusService.$on("loan-message-activity", this.loanMessageActivity);

    this.mounted = true;
  },
  watch: {
    filteredLoans: {
      immediate: true,
      deep: true,
      handler() {
        Vue.nextTick(() => $(".task-tooltip").tooltip());
      },
    },
  },
  computed: {
    actionOnAnalystItemSize() {
      return actionOnAnalystItemSize(this.filteredLoans);
    },
    activeSlaItemSize() {
      return activeSlaItemSize(this.filteredLoans);
    },
    followUpsItemSize() {
      return followUpsItemSize(this.filteredLoans);
    },
    openTasksSize() {
      return openTasksSize(this.filteredLoans);
    },
    orderByLoanStatus() {
      return orderDirectionByLoanStatus(
        this.persistedParams.orderBy,
        this.persistedParams.orderWay
      );
    },
    orderBySLA() {
      return orderDirectionBySLA(this.persistedParams.orderBy, this.persistedParams.orderWay);
    },
    orderByForecastDate() {
      return orderDirectionByForecastDate(
        this.persistedParams.orderBy,
        this.persistedParams.orderWay
      );
    },
    orderByOpenTasks() {
      return orderDirectionByOpenTasks(this.persistedParams.orderBy, this.persistedParams.orderWay);
    },
    sortingClass() {
      if (this.persistedParams.orderBy) {
        return `sort-by-${kebabCase(this.persistedParams.orderBy)}`;
      } else {
        return undefined;
      }
    },
  },
  methods: {
    assignAnalyst(options) {
      this.filteredLoans[options.index].updatingAnalyst = true;
      this.updateAnalyst(options);
    },
    assignProcessor(options) {
      this.filteredLoans[options.index].updatingProcessor = true;
      this.updateProcessor(options);
    },
    filterByAnalyst(id) {
      this.persistedParams.analystId = id;
      this.getWorklist();
    },
    filterByProcessor(id) {
      this.persistedParams.processorId = id;
      this.getWorklist();
    },
    filterByLender(id) {
      this.persistedParams.lenderId = id;
      this.getWorklist();
    },
    filterByActionOnAnalyst(actionOnAnalyst) {
      this.persistedParams.actionOnAnalyst = actionOnAnalyst;
      this.applyFilters();
    },
    filterByActiveSla(activeSla) {
      this.persistedParams.activeSla = activeSla;
      this.applyFilters();
    },
    filterByFollowUps(followUpsRequired) {
      this.persistedParams.followUpsRequired = followUpsRequired;
      this.applyFilters();
    },
    filterByOpenTasks(openTasks) {
      this.persistedParams.openTasks = openTasks;
      this.applyFilters();
    },
    applyFilters() {
      detectLoanVisibility(this.filteredLoans, this.persistedParams, (loan, index) => {
        Vue.set(this.filteredLoans, index, loan);
      });
    },
    sortByLoanStatus(order) {
      this.persistedParams.orderBy = SortBy.LoanStatus;
      this.persistedParams.orderWay = order;

      this.filteredLoans = sortByLoanStatus(this.filteredLoans, order);
    },
    sortBySLA(order) {
      this.persistedParams.orderBy = SortBy.SLA;
      this.persistedParams.orderWay = order;

      this.filteredLoans = sortBySLA(this.filteredLoans, order);
    },
    sortByForecastDate(order) {
      this.persistedParams.orderBy = SortBy.ForecastDate;
      this.persistedParams.orderWay = order;

      this.filteredLoans = sortByForecastDate(this.filteredLoans, order);
    },
    sortByOpenTasks(order) {
      this.persistedParams.orderBy = SortBy.OpenTasks;
      this.persistedParams.orderWay = order;

      this.filteredLoans = sortByOpenTasks(this.filteredLoans, order);
    },
    applySorting() {
      switch (this.persistedParams.orderBy) {
        case SortBy.LoanStatus:
          return this.sortByLoanStatus(this.persistedParams.orderWay);
        case SortBy.SLA:
          return this.sortBySLA(this.persistedParams.orderWay);
        case SortBy.ForecastDate:
          return this.sortByForecastDate(this.persistedParams.orderWay);
        case SortBy.OpenTasks:
          return this.sortByOpenTasks(this.persistedParams.orderWay);
      }
    },
    getWorklist() {
      this.dataLoaded = false;
      return AjaxService.get(this.endpoint + LocationService.search)
        .then(data => {
          if (data.loans.length === 0) {
            this.filteredLoans = [];
            return;
          }

          this.filteredLoans = prepareLoansForDisplay(data.loans);
          this.serverTime = data.serverTime;
        })
        .finally(() => {
          this.applyFilters();
          this.applySorting();

          this.dataLoaded = true;
        });
    },
    updateProcessor(payload) {
      let url = `/manage/loans/${payload.loanId}/processor`;
      let data = { processor_id: payload.assignedProcessorId };

      let messages = {
        onSuccess: "Processor was successfully assigned",
        onError: ({ response, data }) => {
          if (response.status === 422) {
            return {
              message: `${data.error}`,
              status: "error",
            };
          } else {
            error("Couldn't assign processor to the loan");
          }
        },
      };

      return AjaxService.withNotifications(messages)
        .post(url, data)
        .then(data => {
          let loan = this.filteredLoans[payload.index];
          let processor = {
            processor: data,
          };
          let updatedLoan = Object.assign({}, loan, processor);
          Vue.set(this.filteredLoans, payload.index, updatedLoan);
        })
        .finally(() => {
          this.filteredLoans[payload.index].updatingProcessor = false;
        });
    },
    updateAnalyst(payload) {
      const url = `/manage/loans/${payload.loanId}/analyst`;
      const data = { analyst_id: payload.assignedAnalystId };

      const messages = {
        onSuccess: "Analyst was successfully assigned",
        onError: ({ response, data }) => {
          if (response.status === 422) {
            return {
              message: `${data.error}`,
              status: "error",
            };
          } else {
            error("Couldn't assign analyst to the loan");
          }
        },
      };

      return AjaxService.withNotifications(messages)
        .post(url, data)
        .then(data => {
          const loan = this.filteredLoans[payload.index];
          const analyst = {
            analyst: data,
          };
          const updatedLoan = Object.assign({}, loan, analyst);
          Vue.set(this.filteredLoans, payload.index, updatedLoan);
        })
        .finally(() => {
          this.filteredLoans[payload.index].updatingAnalyst = false;
        });
    },
    handleMarkTaskCompleted(index, taskName, callback) {
      const loan = this.filteredLoans[index];
      const task = find(loan.tasks, { name: taskName });
      const url = PSData.completeTaskEndpoint
        .replace(":loanId", loan.loanId)
        .replace(":taskId", task.taskId);

      const messages = {
        onSuccess: "Task successfully marked as completed.",
        onError: "Failed to mark task as completed. Please try again later.",
      };

      AjaxService.withNotifications(messages)
        .patch(url)
        .then(tasks => {
          const updatedLoan = Object.assign({}, loan, { tasks });
          Vue.set(this.filteredLoans, index, updatedLoan);
        })
        .then(callback);
    },
    openQuickPreview(index) {
      const loan = this.filteredLoans[index];
      this.selectedLoan = loan;
      this.highlight(loan.loanId);
    },
    highlight(loanId) {
      Array.from(document.querySelectorAll(".worklist-row-content")).forEach(div => {
        if (div.dataset.loanId === loanId) {
          div.classList.add("loan-selected");
        } else {
          div.classList.remove("loan-selected");
        }
      });
    },
    loanMessageActivity(value) {
      if (!value) {
        Vue.delete(
          this.loanIdsWithMessageActivity,
          this.loanIdsWithMessageActivity.indexOf(this.selectedLoan.loanId)
        );
      }
    },
    loanTaskActivity(value) {
      if (!value) {
        Vue.delete(
          this.loanIdsWithTaskActivity,
          this.loanIdsWithTaskActivity.indexOf(this.selectedLoan.loanId)
        );
      }
    },
  },
};
</script>
