export function camelToSnakeCase(str) {
  return str.replace(/[A-Z]/g, character => `_${character.toLowerCase()}`);
}

export function snakeToCamelCase(str) {
  return str.replace(/_(\w)/g, (_, character) => character.toUpperCase());
}

export function titleize(str) {
  return str
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
}

export function formatPercent(value, precision = 3, numberOnly = false) {
  if ((numberOnly && typeof value !== "number") || value === null || value === undefined) {
    return;
  }
  return parseFloat(value || 0).toFixed(precision) + "%";
}

export function formatCurrency(value, precision = 2, numberOnly = false) {
  if ((numberOnly && typeof value !== "number") || value === null || value === undefined) {
    return;
  }
  return "$" + formatNumber(value, precision);
}

export function formatNumber(value, precision = 2, numberOnly = false) {
  if ((numberOnly && typeof value !== "number") || value === null || value === undefined) {
    return;
  }

  return parseFloat(value || 0)
    .toFixed(precision)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const APP_TIME_ZONE = "America/Los_Angeles";

const SHORT_DATE_TIME_FORMATTER = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  timeZone: APP_TIME_ZONE,
});

export function formatDateTime(value) {
  if (value === null || value === undefined) {
    return;
  }
  // Since .format() adds comma between date and time: 05/10/2021, 04:13:36 AM
  // use .formatToParts() as a workaround to remove comma: 05/10/2021 04:13:36 AM
  const parts = SHORT_DATE_TIME_FORMATTER.formatToParts(new Date(value));
  return parts.map(({ value }) => (value === ", " ? " " : value)).join("");
}
