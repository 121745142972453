<template>
  <base-icon
    v-if="enabled"
    @click.native="explain"
    title="View Details"
    icon="search"
    class="lps-explain"
  />
</template>

<script>
import GlobalBusService from "services/global_bus_service";

export default {
  name: "lps-explain",
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
      required: true,
    },
  },
  methods: {
    explain() {
      GlobalBusService.$emit("lps-explain", this.context);
    },
  },
};
</script>

<style scoped>
.lps-explain {
  cursor: pointer;
  color: #d8dbdc;
  height: 14px;
  left: -2px;
  position: relative;
  top: 2px;
}
</style>
