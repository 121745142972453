class ScoreCalculator {
  static calculatedCreditScore(array) {
    const numbers = numberValues(array);

    switch (numbers.length) {
      case 3:
        return this.median(numbers);
      case 2:
        return this.min(numbers);
      case 1:
        return numbers[0];
      case 0:
        return undefined;
    }
  }

  static median(array) {
    const numbers = numberValues(array).sort((a, b) => a - b);
    const isEvenLength = numbers.length % 2 == 0;
    const middleIndex = Math.floor(numbers.length / 2);
    let median;

    if (isEvenLength) {
      median = (numbers[middleIndex] + numbers[middleIndex - 1]) / 2;
    } else {
      median = numbers[middleIndex];
    }

    return !isFinite(median) ? undefined : median;
  }

  static max(array) {
    const numbers = numberValues(array);
    const max = Math.max(...numbers);
    return !isFinite(max) ? undefined : max;
  }

  static min(array) {
    const numbers = numberValues(array);
    const min = Math.min(...numbers);
    return !isFinite(min) ? undefined : min;
  }
}

function numberValues(array) {
  return array.filter(value => !isNaN(parseFloat(value)));
}

PS.Models.Diligence.ScoreCalculator = ScoreCalculator;
