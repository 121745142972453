<template>
  <div class="details-row no-border row first-row">
    <component :is="headerComponent" :payoff-statement="payoffStatement" />
  </div>
</template>

<script>
import StatementHeader from "./statement_header";
import EstimatePayoffQuoteStatementHeader from "components/manage/payoff_statement/estimate_payoff_quote/statement_header";

export default {
  components: {
    StatementHeader,
    EstimatePayoffQuoteStatementHeader,
  },
  props: ["payoffStatement"],
  computed: {
    headerComponent() {
      if (this.payoffStatement.statementType === "estimate_payoff_quote") {
        return "estimate-payoff-quote-statement-header";
      }

      return "statement-header";
    },
  },
};
</script>
