<template>
  <div :class="topLevelClass">
    <div class="search-container">
      <h2 v-if="bannerMode" class="title">
        ZIP Code Report
      </h2>
      <div class="zip-search">
        <input
          v-model="zip"
          v-mask:zip=""
          class="search-bar"
          placeholder="Enter a ZIP Code"
          type="input"
          @keydown.enter="zipSearch"
        />
        <a class="btn btn-action-search search-button" @click="zipSearch">
          <i class="fas fa-search"></i>
        </a>
      </div>
    </div>
    <div v-show="showCustomError" class="error">
      {{ errorMessage }}
    </div>
    <div v-show="showZipError" class="error">
      Please provide a valid ZIP Code
    </div>
  </div>
</template>

<script>
export default Vue.component("market-data-zip-search", {
  data: function() {
    return {
      showCustomError: undefined,
      showZipError: undefined,

      zip: this.predefinedZip,
      zipWas: undefined,
    };
  },
  props: {
    predefinedZip: undefined,
    errorMessage: undefined,
    bannerMode: Boolean,
  },
  watch: {
    errorMessage: function(val) {
      this.showZipError = false;
      this.showCustomError = true;
    },
  },
  computed: {
    topLevelClass: function() {
      if (this.bannerMode) {
        return "market-data-zip-search";
      } else {
        return "market-data-zip-search-input-mode";
      }
    },
  },
  methods: {
    zipSearch: function() {
      if (!this.zip) {
        return;
      }

      if (this.zip.length !== 5) {
        this.showCustomError = false;
        this.showZipError = true;

        return;
      } else {
        this.showZipError = false;
      }

      if (this.zip === this.zipWas && this.errorMessage) {
        this.showCustomError = true;
      }

      if (this.zip === this.zipWas) return;

      this.zipWas = this.zip;
      this.$emit("zip-search", this.zip);
    },
  },
});
</script>
