const Source = PS.Models.MarketData.Charts.Source;

class Terminus extends Source {
  pointType(point) {
    return point.prediction ? "predicted" : "observed";
  }

  _month(point) {
    // ignore the day part to align points from different data sets for comparison
    return point[this.dateKey].replace(/\d\d$/, "01");
  }
}

PS.Models.MarketData.Charts.Terminus = Terminus;
