<template>
  <mfe-connect :service-url="serviceUrl" @ready="mountApp">
    <div ref="entryPoint" />
  </mfe-connect>
</template>
<script>
import MfeConnect from "components/micro_frontend_connect";
export default {
  components: {
    MfeConnect,
  },
  data() {
    return {
      serviceUrl: PSData.global.feeTrackerUrl,
    };
  },
  methods: {
    mountApp() {
      LoanFeesTrackerApp.mount({
        element: this.$refs.entryPoint,
        initialRoute: `/file_uploader`,
      });
    },
  },
};
</script>
