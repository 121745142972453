<template>
  <div class="rule-item">{{ condition }} / {{ adjustment }}<template v-if="!last">,</template></div>
</template>

<script>
import { formatPercent, formatCurrency } from "./utils/formatter";
import { OPERATORS } from "./utils/rule";

export default {
  name: "rule",
  props: {
    rule: {
      type: Object,
      required: true,
    },
    last: {
      type: Boolean,
    },
  },
  computed: {
    condition() {
      switch (this.rule.attr) {
        case "loan_amount":
          const conditionTitle = OPERATORS.find(op => op[1] === this.rule.condition)[0];
          const conditionAmount = formatCurrency(this.rule.value);
          return `${conditionTitle} ${conditionAmount}`;
        case "state":
          return this.rule.value.join(", ");
      }
    },
    adjustment() {
      return formatPercent(this.rule.adjustment);
    },
  },
};
</script>
<style>
.rule-item {
  padding-bottom: 6px;
}
</style>
