const FIELD_TYPES = {
  CHECKBOX: "checkbox",
  DATE: "date",
  TEXT: "text",
  TEXTAREA: "textarea",
  NUMBER: "number",
  INTEGER: "integer",
  RADIO_BUTTONS: "radio",
  YES_NO_RADIO_BUTTONS: "yesNo",
  CURRENCY: "currency",
  PERCENT: "percentage",
  SELECT: "select",
  US_STATES_SELECT: "usStates",
  MULTIPLE_FIELDSET: "multipleFieldset",
  FIELDSET: "fieldset",
  VIRTUAL: "virtual",
  AUTOCOMPLETE: "autocomplete",
  MEMO: "memo",
};

class Field {
  static buildField(element) {
    const FIELDS = PS.Models.Fields;

    switch (element.as) {
      case FIELD_TYPES.TEXT:
        return new FIELDS.TextField(element);

      case FIELD_TYPES.NUMBER:
        return new FIELDS.NumberField(element);

      case FIELD_TYPES.INTEGER:
        return new FIELDS.IntegerField(element);

      case FIELD_TYPES.PERCENT:
        return new FIELDS.PercentageField(element);

      case FIELD_TYPES.DATE:
        return new FIELDS.DateField(element);

      case FIELD_TYPES.CURRENCY:
        return new FIELDS.CurrencyField(element);

      case FIELD_TYPES.CHECKBOX:
        return new FIELDS.CheckboxField(element);

      case FIELD_TYPES.TEXTAREA:
        return new FIELDS.TextareaField(element);

      case FIELD_TYPES.SELECT:
        return new FIELDS.SelectField(element);

      case FIELD_TYPES.US_STATES_SELECT:
        return new FIELDS.USStatesSelectField(element);

      case FIELD_TYPES.RADIO_BUTTONS:
        return new FIELDS.RadioField(element);

      case FIELD_TYPES.YES_NO_RADIO_BUTTONS:
        return new FIELDS.YesNoRadioField(element);

      case FIELD_TYPES.FIELDSET:
        return new FIELDS.Fieldset(element);

      case FIELD_TYPES.MULTIPLE_FIELDSET:
        return new FIELDS.EditableFieldset(element);

      case FIELD_TYPES.VIRTUAL:
        return new FIELDS.VirtualField(element);

      case FIELD_TYPES.AUTOCOMPLETE:
        return new FIELDS.AutoComplete(element);

      case FIELD_TYPES.MEMO:
        return new FIELDS.MemoField(element);

      default:
        throw new Error(`Unknown document field type: ${element.as}`);
    }
  }

  static expandCollection(collection) {
    return collection.map(entry => {
      return { name: entry[0], value: entry[1] };
    });
  }

  constructor(element) {
    this.type = element.as;
    this.label = element.label || element.name;
    this.id = element.id;
    this.name = element.name;
    this.formula = element.formula;
    this.value = this.valueFromServer(element.value);
    this.rawValue = this.value;
    this.note = element.note;
    this.validated = !!element.validatedAt;
    this.disabled = !!element.disabled;
    this.hidden = !!element.hidden;
    this.tooltip = element.tooltip;
    this.isPreview = element.isPreview;

    this.mask = element.mask || {};
    if (element.mask) {
      this.mask.type = Object.keys(element.mask)[0];
      this.mask.options = Object.values(element.mask)[0];
    }
  }

  valueFromServer(value) {
    return value;
  }

  valueToServer() {
    return this.value;
  }

  fieldsCount() {
    if (this.isVirtual()) {
      return 0;
    } else {
      return 1;
    }
  }

  validatedCount() {
    if (this.hasAutocalculation()) {
      return 1;
    } else if (this.isVirtual()) {
      return 0;
    } else {
      return this.validated ? 1 : 0;
    }
  }

  notedCount() {
    return !!this.note && this.note.length > 0 ? 1 : 0;
  }

  updateElement(scope) {}

  get hasChanges() {
    if (this.value === "" && this.rawValue === undefined) {
      return false;
    } else {
      return this.value != this.rawValue;
    }
  }

  syncChanges() {
    this.rawValue = this.value;
  }

  isText() {
    return this instanceof PS.Models.Fields.TextField;
  }

  isDate() {
    return this instanceof PS.Models.Fields.DateField;
  }

  isNumber() {
    return this instanceof PS.Models.Fields.NumberField;
  }

  isInteger() {
    return this instanceof PS.Models.Fields.IntegerField;
  }

  isPercentage() {
    return this instanceof PS.Models.Fields.PercentageField;
  }

  isCurrency() {
    return this instanceof PS.Models.Fields.CurrencyField;
  }

  isTextarea() {
    return this instanceof PS.Models.Fields.TextareaField;
  }

  isCheckbox() {
    return this instanceof PS.Models.Fields.CheckboxField;
  }

  isRadio() {
    return this instanceof PS.Models.Fields.RadioField;
  }

  isSelect() {
    return this instanceof PS.Models.Fields.SelectField;
  }

  isFieldset() {
    return this instanceof PS.Models.Fields.Fieldset;
  }

  isVirtual() {
    return this instanceof PS.Models.Fields.VirtualField;
  }

  isMemo() {
    return this instanceof PS.Models.Fields.MemoField;
  }

  hasAutocalculation() {
    return !!this.formula;
  }

  get isHidden() {
    return this.hidden;
  }

  toComponent() {
    switch (true) {
      case this.isText():
        return "diligence-document-text-field";

      case this.isDate():
        return "diligence-document-date-field";

      case this.isNumber():
        return "diligence-document-number-field";

      case this.isInteger():
        return "diligence-document-integer-field";

      case this.isPercentage():
        return "diligence-document-percentage-field";

      case this.isCurrency():
        return "diligence-document-currency-field";

      case this.isCheckbox():
        return "diligence-document-checkbox-field";

      case this.isTextarea():
        return "diligence-document-textarea-field";

      case this.isSelect():
        return "diligence-document-select-field";

      case this.isRadio():
        return "diligence-document-radio-field";

      default:
        return `diligence-document-${this.type}-field`;
    }
  }

  isEmpty() {
    const value = this.valueToServer();
    const isNil = value === null || value === undefined;
    return isNil || value.toString().trim().length === 0;
  }
}

PS.Models.Field = Field;
PS.Models.Fields = {};
