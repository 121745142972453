var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"provider",attrs:{"name":"Loan CSV","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Loan CSV")]),_vm._v(" "),_c('input',{staticClass:"form-control-file form-control",class:_vm.validationClass(valid),attrs:{"placeholder":"Select a CSV file","name":"loanCsv","type":"file","accept":".csv","disabled":_vm.disabled},on:{"input":function($event){_vm.handleInput($event) || validate($event)}}}),_vm._v(" "),(valid === false)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n      "+_vm._s(errors.map(function (ref) {
	var msg = ref.msg;

	return msg;
}).join(", "))+"\n    ")]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }