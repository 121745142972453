<template>
  <transition name="modal">
    <div class="vue-modal" v-show="show">
      <div v-if="type === 'container'" class="modal-container">
        <slot></slot>
      </div>
      <div v-if="type === 'dialog'" class="modal">
        <div class="modal-dialog" role="document">
          <slot></slot>
        </div>
      </div>
      <div @click="close" class="modal-mask"></div>
    </div>
  </transition>
</template>

<script>
const KEY_ESC = 27;
const BODY_CLASS_NAME = "body-modal-view";

export default {
  name: "modal-view",
  props: {
    show: Boolean,
    onClose: Function,
    type: {
      type: String,
      default: "dialog",
    },
  },
  methods: {
    close() {
      this.onClose();
    },
    handleEscKey(e) {
      if (e.keyCode === KEY_ESC) {
        this.close();
      }
    },
    showModal() {
      document.addEventListener("keydown", this.handleEscKey);
      document.body.classList.add(BODY_CLASS_NAME);
    },
    hideModal() {
      document.removeEventListener("keydown", this.handleEscKey);
      document.body.classList.remove(BODY_CLASS_NAME);
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.showModal();
      } else {
        this.hideModal();
      }
    },
  },
  beforeDestroy() {
    this.hideModal();
  },
  mounted() {
    if (this.show) {
      this.showModal();
    }
  },
};
</script>
