<template>
  <div class="payoff-statement-details">
    <overview
      :statement-types="statementTypes"
      :payoff-reason-types="payoffReasonTypes"
      :statement="resaleStatement"
      @sync-interest="syncInterest"
    />
    <estimated-net-wire
      :is-editable="isEditable"
      :statement="resaleStatement"
      @sync-interest="syncInterest"
    />
    <dates
      :is-editable="isEditable"
      :statement="resaleStatement"
      @sync-interest="syncInterest"
    />
    <principal
      :is-editable="isEditable"
      :statement="resaleStatement"
      @sync-interest="syncInterest"
    />
    <div class="details-row">
      <interest
        :is-editable="isEditable"
        :statement="resaleStatement"
      />
      <note-interest-calculation
        :statement="resaleStatement"
        :is-editable="isEditable"
        @sync-interest="syncInterest"
      />
      <interest-credits
        :statement="resaleStatement"
        :is-editable="isEditable"
      />
    </div>
    <fees
      :is-editable="isEditable"
      :statement="resaleStatement"
      :fee-types="feeTypes"
      @add-fee="addFee"
      @remove-fee="removeFee"
      @sync-interest="syncInterest"
    />
    <credits
      :statement="resaleStatement"
      :is-editable="isEditable"
      @sync-interest="syncInterest"
    />
    <comments
      :statement="resaleStatement"
      @remove-comment="removeComment"
      @add-comment="addComment"
    />
    <buttons
      v-if="!isEdit"
      @cancel="cancel"
      @save-draft="saveDraft"
      @submit="submit"
    />
  </div>
</template>

<script>
import Principal from "components/manage/payoff_statement/resale/edit/principal";
import Interest from "components/manage/payoff_statement/resale/edit/interest";
import InterestCredits from "components/manage/payoff_statement/resale/edit/interest_credits";
import Credits from "components/manage/payoff_statement/resale/edit/credits";
import Overview from "components/manage/payoff_statement/resale/edit/overview";
import NoteInterestCalculation from "components/manage/payoff_statement/edit/note_interest_calculation";
import EstimatedNetWire from "components/manage/payoff_statement/edit/estimated_net_wire";
import Dates from "components/manage/payoff_statement/edit/dates";
import Fees from "components/manage/payoff_statement/edit/fees";
import Comments from "components/manage/payoff_statement/edit/comments";
import Buttons from "components/manage/payoff_statement/edit/buttons";
import EditMixin from "components/manage/payoff_statement/edit_mixin";

export default {
  name: "resale-statement-details",
  mixins: [EditMixin],
  components: {
    EstimatedNetWire,
    Overview,
    Dates,
    Principal,
    Interest,
    InterestCredits,
    NoteInterestCalculation,
    Fees,
    Credits,
    Comments,
    Buttons,
  },
  props: [
    "resaleStatement",
    "fees",
    "comments",
    "isEditable",
    "isEdit",
    "payoffReasonTypes",
    "feeTypes",
    "statementTypes",
  ],
};
</script>
