export default class FundingEntity {
  constructor(account = {}) {
    this.account = account;
  }

  get id() {
    return this.account.id;
  }

  get name() {
    return this.account.name;
  }

  get capacity() {
    return parseFloat(this.account.capacity);
  }

  get funded() {
    return parseFloat(this.account.funded) * -1;
  }

  get balance() {
    return parseFloat(this.account.balance);
  }

  get dryPowder() {
    const result = this.capacity + this.funded;
    if (result < 0) {
      return 0;
    } else {
      return result;
    }
  }

  get inTransit() {
    return parseFloat(this.account.inTransit);
  }

  get earmarked() {
    return parseFloat(this.account.earmarked) * -1;
  }

  get available() {
    const result = this.capacity + this.funded + this.earmarked + this.inTransit;
    if (result < 0) {
      return 0;
    } else {
      return result;
    }
  }

  get dailyActivity() {
    return parseFloat(this.account.dailyActivity);
  }

  get throughput() {
    return parseFloat(this.account.throughput);
  }

  _getShowUrl(routes) {
    return `${routes.manageBankCenterFundingEntities}/${this.account.id}`;
  }

  getWireRequestsUrl(routes) {
    return `${this._getShowUrl(routes)}/wire_requests`;
  }

  getDailyWireRequestsUrl(routes) {
    return `${this._getShowUrl(routes)}/daily_wire_requests`;
  }

  getSeriesUrl(routes, type) {
    const params = new URLSearchParams({ type });
    return `${this._getShowUrl(routes)}/series?${params.toString()}`;
  }
}
