<template>
  <div>
    <div class="step-header py-2">
      <span class="step-header-name pr-1">
        <i class="fas fa-check-circle pr-1 step-header-name-icon" />
        {{ item.name }}
      </span>
      (completed by {{ item.completedBy.name }} on {{ item.completedAt }})
    </div>
    <div class="columns">
      <div class="column is-half">
        <user-input-item
          v-for="userInput in getUserInputsFor(item)"
          :key="userInput.id"
          :user-input="userInput"
          :user-inputs="item.inputs"
        />
      </div>
    </div>
  </div>
</template>
<script>
import UserInputItem from "./user_input_item";

export default {
  components: {
    UserInputItem,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getUserInputsFor(item) {
      return item.inputs.filter(input => !input.parentVariableInstanceId);
    },
  },
};
</script>
<style scoped>
.step-header {
  display: flex;
  align-items: center;
}

.step-header-name {
  font-size: var(--font-size-md);
  font-weight: 600;
}

.step-header-name-icon {
  color: var(--color-green);
}
</style>
