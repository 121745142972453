<template>
  <diligence-document-field :document="document" :field="field">
    <div class="col-5 with-datepicker">
      <i class="far fa-calendar-alt"></i>
      <input
        :id="field.id"
        v-model="field.value"
        v-datepicker=""
        v-mask:date=""
        class="form-control datepicker"
        type="text"
        :disabled="disabled"
        @blur="saveValue"
      />
    </div>
  </diligence-document-field>
</template>

<script>
import Field from "./base/field";

const DateField = Field.extend({
  methods: {
    saveValue() {
      if (this.field.value.length === 0 || !isNaN(Date.parse(this.field.value))) {
        this.saveValueImmediately();
      }
    },
  },
});

export default Vue.component("diligence-document-date-field", DateField);
</script>
