var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"servicing-section"},[_c('div',{staticClass:"servicing-section-header"},[_c('div',[_c('base-header',{attrs:{"size":"medium"}},[_vm._v("Charge-Offs")]),_vm._v(" "),_c('div',{staticClass:"section-subheader",attrs:{"data-test":"charge-offs-subheader"}},[_c('span',{attrs:{"date-test":"charge-offs-total"}},[_vm._v(_vm._s(_vm._f("pluralize")(_vm.chargeOffsTotal,"Charge-Off")))]),_vm._v(" "),_c('span',[_vm._v("•")]),_vm._v(" "),(_vm.hasRequests)?_c('span',{attrs:{"data-test":"charge-offs-last-updated"}},[_vm._v("Last updated "+_vm._s(_vm.lastUpdatedAt))]):_vm._e()])],1),_vm._v(" "),(_vm.canCreateChargeOff)?_c('div',[_c('base-dropdown',{attrs:{"data-test":"charge-offs-action-button","type":"none","position":"left","label":"Actions"}},[_c('base-dropdown-item',{attrs:{"data-test":"charge-offs-new-charge-off-button"},on:{"click":_vm.openNewChargeOff}},[_vm._v("\n          Generate Charge-Off Statement\n        ")])],1)],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"charge-off-table"},[_c('base-table',{attrs:{"data-test":"charge-offs-table","data":_vm.chargeOffStatements},on:{"click":_vm.openChargeOffStatementDetails},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v("\n        No Charge-Offs\n      ")]},proxy:true}])},[_c('base-column',{attrs:{"label":"Last Updated"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.formatUpdatedAt(row.updatedAt)))]}}])}),_vm._v(" "),_c('base-column',{attrs:{"label":"Effective Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("formatDate")(row.effectiveDate)))]}}])}),_vm._v(" "),_c('base-column',{attrs:{"label":"Amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("accounting")(row.amount)))]}}])}),_vm._v(" "),_c('base-column',{attrs:{"label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('badge',{attrs:{"status":row.status}})]}}])}),_vm._v(" "),_c('base-column',{attrs:{"label":"Notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.comment))]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }