<template>
  <div>
    <report-loans
      :group="'wired'"
      :loans="groupedLoans.wired"
      :state="loadingStates.wired"
      :lender-block-status-displayable="lenderBlockStatusDisplayable"
      :broker-tag-displayable="brokerTagDisplayable"
      :initial-sort="{ field: 'initialPurchaseDate', fieldType: 'date', direction: 1 }"
      :apply-settings="true"
    />
    <report-loans
      :group="'purchasing'"
      :loans="groupedLoans.purchasing"
      :state="loadingStates.purchasing"
      :lender-block-status-displayable="lenderBlockStatusDisplayable"
      :broker-tag-displayable="brokerTagDisplayable"
      :initial-sort="{ field: 'targetPurchaseDate', fieldType: 'date', direction: 1 }"
      :apply-settings="true"
    />
    <report-loans
      :group="'diligenced'"
      :loans="groupedLoans.diligenced"
      :state="loadingStates.diligenced"
      :lender-block-status-displayable="lenderBlockStatusDisplayable"
      :broker-tag-displayable="brokerTagDisplayable"
      :initial-sort="{ field: 'forecastPurchaseDate', fieldType: 'date', direction: 1 }"
      :apply-settings="true"
    />
    <report-loans
      :group="'diligencing'"
      :loans="groupedLoans.diligencing"
      :state="loadingStates.diligencing"
      :lender-block-status-displayable="lenderBlockStatusDisplayable"
      :broker-tag-displayable="brokerTagDisplayable"
      :initial-sort="{ field: 'forecastPurchaseDate', fieldType: 'date', direction: 1 }"
      :apply-settings="true"
    />
    <report-loans
      :group="'uploadFiles'"
      :loans="groupedLoans.uploadFiles"
      :state="loadingStates.uploadFiles"
      :lender-block-status-displayable="lenderBlockStatusDisplayable"
      :broker-tag-displayable="brokerTagDisplayable"
      :initial-sort="{ field: 'forecastPurchaseDate', fieldType: 'date', direction: 1 }"
      :apply-settings="true"
    />
    <report-loans
      :group="'qualified'"
      :loans="groupedLoans.qualified"
      :state="loadingStates.qualified"
      :lender-block-status-displayable="lenderBlockStatusDisplayable"
      :initial-sort="{ field: 'forecastPurchaseDate', fieldType: 'date', direction: 1 }"
      :apply-settings="true"
    />
    <report-loans
      :group="'evaluating'"
      :loans="groupedLoans.evaluating"
      :state="loadingStates.evaluating"
      :lender-block-status-displayable="lenderBlockStatusDisplayable"
      :broker-tag-displayable="brokerTagDisplayable"
      :initial-sort="{ field: 'daysInCurrentStatus', fieldType: 'date', direction: -1 }"
      :apply-settings="true"
    />
    <report-loans
      :group="'review'"
      :loans="groupedLoans.review"
      :state="loadingStates.review"
      :lender-block-status-displayable="lenderBlockStatusDisplayable"
      :broker-tag-displayable="brokerTagDisplayable"
      :initial-sort="{ field: 'forecastPurchaseDate', fieldType: 'date', direction: 1 }"
      :apply-settings="true"
    />
    <report-loans
      :group="'draft'"
      :loans="groupedLoans.draft"
      :state="loadingStates.draft"
      :lender-block-status-displayable="lenderBlockStatusDisplayable"
      :broker-tag-displayable="brokerTagDisplayable"
      :initial-sort="{ field: 'lender.name', fieldType: 'string', direction: 1 }"
      :apply-settings="true"
    />
  </div>
</template>

<script>
import { LOADING_STATUSES } from "models/reports/loan";
import StatusFetcher, {
  HOTLIST_UNDERWRITING_STATUSES,
} from "models/reports/hotlist_loans/status_fetcher";
import HotlistLoansRule from "models/reports/hotlist_loans/rules/hotlist_loans_rule";
import { Updated } from "models/reports/hotlist_loans/events/hotlist_loans_events";
import { RequestUpdate } from "models/reports/hotlist_loans/commands/hotlist_loans_commands";
import MessageBusService from "services/message_bus_service";
import Loans from "collections/manage/loans";
import FilterParams from "models/hotlist/filter_params";
import ReportLoans from "components/reports/loans";

export default {
  components: { ReportLoans },
  data: () => ({
    groupedLoans: Loans.emptyGroup,
    filterOptions: { ...FilterParams.instance.loadParams() },
    analysts: [],
    lenders: [],
    documents: [],
    serverTime: undefined,
    loadingStates: HOTLIST_UNDERWRITING_STATUSES.reduce(
      (states, state) => ({
        ...states,
        [state]: LOADING_STATUSES.loading,
      }),
      {}
    ),
    ruleBook: undefined,
    messageBus: MessageBusService.hotlistBus,
    statusFetcher: undefined,
    propertyTypes: PSData.propertyTypes,
    loanPrograms: PSData.loanPrograms,
    accountTypes: PSData.accountTypes,
    lenderBlockStatusDisplayable: true,
    brokerTagDisplayable: true,
  }),
  watch: {
    groupedLoans: {
      immediate: true,
      deep: true,
      handler: function() {
        Vue.nextTick(() => $(".analyst-notes a").tooltip());
      },
    },
  },
  mounted: function() {
    this.ruleBook = new HotlistLoansRule(this);
    this.messageBus.attachRulebook(this.ruleBook);

    new RequestUpdate(new Updated(PSData), this).execute();

    this.statusFetcher = new StatusFetcher(this);
    this.statusFetcher.fetchAll();
  },
  beforeDestroy: function() {
    this.messageBus.detachRulebook(this.ruleBook);
  },
};
</script>
