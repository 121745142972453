import filter from "lodash/filter";
import sumBy from "lodash/sumBy";

export const getTotalForDisbursementTypes = (details, types) => {
  const filtered = filter(details, ({ disbursementType }) => {
    return types.includes(disbursementType);
  });

  return sumBy(filtered, detail => {
    return detail.revisedAmount || detail.amount;
  });
};
