<template>
  <span :class="statusClass">
    <span v-if="showStatusIcon" class="fa-icon">
      <i :class="iconClass"/>
    </span>
    {{ document.statusText }}
  </span>
</template>

<script>
export default {
  name: "document-status",
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statusClass() {
      return {
        [this.document.status]: true,
        "doc-tracking-status": true,
      };
    },
    iconClass() {
      if (this.document.status === "accepted") {
        return "fas fa-check";
      } else if (this.document.status === "incomplete") {
        return "fas fa-times";
      }
      return "";
    },
    showStatusIcon() {
      return !!this.iconClass;
    },
  },
};
</script>
