<template>
  <div class="action-button btn-group">
    <button
      type="button"
      class="btn btn-secondary-action-cta dropdown-toggle"
      data-toggle="dropdown"
      :disabled="isLoading"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Actions
      <i class="fa fa-angle-down fa-lg"></i>
    </button>

    <ul class="dropdown-menu">
      <li v-for="option in enabledOptions" :key="option.label" :class="option.class" :role="option.role">
        <a v-if="option.url" :href="option.url" target="_blank">{{ option.label }}</a>
        <a v-else-if="option.emit" href="#" @click.prevent="option.emit" :class="option.class">
          {{ option.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "reserve-draw-request-action-button",
  props: [
    "reserveDrawRequest",
    "reserveDrawRequestPolicy",
    "isLoading",
    "downloadReserveDrawRequestRoute",
  ],
  computed: {
    enabledOptions() {
      return this.options.filter(option => option.enabled);
    },
    options() {
      return [
        {
          label: "Approve",
          enabled: this.isApprovable,
          emit: this.approveDrawRequest,
        },
        {
          label: "Complete Draw Reversal",
          enabled: this.isCompletable,
          emit: this.completeDrawReversal,
        },
        {
          label: "Submit for Approval",
          enabled: this.isSubmittable,
          emit: this.submitDrawRequest,
        },
        {
          label: "Repair",
          enabled: this.isRepairable,
          emit: this.repairDrawRequest,
        },
        {
          label: "Download PDF",
          enabled: this.isDownloadable,
          url: this.downloadReserveDrawRequestRoute,
        },
        {
          label: "Draw Wire Instructions",
          enabled: this.isDrawWireable,
          emit: this.updateDrawWireInstruction,
        },
        {
          label: "Reimbursement Wire Instructions",
          enabled: this.isFeeWireable,
          emit: this.updateFeeWireInstruction,
        },
        {
          role: "separator",
          class: "dropdown-divider",
          enabled: this.isShowSeparator,
        },
        {
          label: "Generate Draw Reversal",
          enabled: this.isReversible,
          emit: this.reverseDrawRequest,
        },
        {
          label: "Cancel",
          enabled: this.isCancellable,
          emit: this.cancelDrawRequest,
          class: { disabled: !this.isCancellable },
        },
      ];
    },
    isShowSeparator() {
      return this.isCancellable || this.isReversible;
    },
    isApprovable() {
      return this.reserveDrawRequestPolicy.canApprove();
    },
    isCompletable() {
      return this.reserveDrawRequestPolicy.canCompleteReversal();
    },
    isCancellable() {
      return (
        this.reserveDrawRequestPolicy.canCancel() ||
        this.reserveDrawRequestPolicy.canCancelReversal()
      );
    },
    isSubmittable() {
      return this.reserveDrawRequestPolicy.canSubmit();
    },
    isRepairable() {
      return this.reserveDrawRequestPolicy.canRepair();
    },
    isReversible() {
      return this.reserveDrawRequestPolicy.canReverse();
    },
    isDrawWireable() {
      return this.reserveDrawRequestPolicy.canSubmitWireInstruction();
    },
    isFeeWireable() {
      return this.isDrawWireable && this.reserveDrawRequest.isReimbursementRequired();
    },
    isDownloadable() {
      return this.reserveDrawRequestPolicy.canDownload();
    },
  },
  methods: {
    approveDrawRequest() {
      if (this.isApprovable) {
        this.$emit("progress-draw-request", "approve");
      }
    },
    completeDrawReversal() {
      if (this.isCompletable) {
        this.$emit("progress-draw-request", "completeReversal");
      }
    },
    cancelDrawRequest() {
      if (this.isCancellable) {
        this.$emit("progress-draw-request", "cancel");
      }
    },
    submitDrawRequest() {
      if (this.isSubmittable) {
        this.$emit("progress-draw-request", "submit");
      }
    },
    repairDrawRequest() {
      if (this.isRepairable) {
        this.$emit("progress-draw-request", "repair");
      }
    },
    reverseDrawRequest() {
      if (this.isReversible) {
        this.$emit("progress-draw-request", "reverse");
      }
    },
    updateDrawWireInstruction() {
      if (this.isDrawWireable) {
        this.$emit("wire-instruction", "draw");
      }
    },
    updateFeeWireInstruction() {
      if (this.isFeeWireable) {
        this.$emit("wire-instruction", "fee");
      }
    },
  },
};
</script>
