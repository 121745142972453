<template>
  <form v-if="cashReceipts.length > 0" @submit.prevent="reconcile">
    <div class="cash-receipts-table-actions">
      <div class="select-all-rows">
        <check-box :partial="selectedSome" :value="selectedAll" @change="toggleSelectAll" />
      </div>
      <div class="buttons">
        <button type="submit" class="btn btn-primary" :disabled="selectedNone || reconcileInFlight">
          Reconcile Selected
        </button>
      </div>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th class="min" />
          <th>
            Receipt ID
          </th>
          <th>Servicer / Lender</th>
          <th>Investor Account</th>
          <th class="text-right">
            Bank Account
          </th>
          <th class="text-right">
            Account Deposits
          </th>
          <th class="text-right">
            Check Date
          </th>
          <th class="text-right">
            Check Number
          </th>
          <th class="min">
            <i class="fa fa-download" />
          </th>
          <th class="text-right">
            Check Amount
          </th>
          <th>Revised Amount</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="cashReceipt in cashReceipts" :key="cashReceipt.id">
          <td class="min">
            <check-box :value="selected(cashReceipt)" @change="toggleSelect(cashReceipt)" />
          </td>
          <td>
            {{ cashReceipt.id }}
          </td>
          <td>
            {{ cashReceipt.distributedBy && cashReceipt.distributedBy.label }}
          </td>
          <td>
            {{ cashReceipt.bankAccount && cashReceipt.bankAccount.name }}
          </td>
          <td class="text-right">
            {{ cashReceipt.bankAccount && cashReceipt.bankAccount.accountNumber }}
          </td>
          <td class="text-right">
            {{ cashReceipt.dailyDepositAmount | money }}
          </td>
          <td class="text-right">
            {{ cashReceipt.receivedDate }}
          </td>
          <td class="text-right">
            {{ cashReceipt.checkNumber }}
          </td>
          <td class="min">
            <a :href="disbursementsDataURL(cashReceipt)"><i class="fa fa-download" /></a>
          </td>
          <td class="text-right">
            {{ cashReceipt.receivedAmount | money }}
          </td>
          <td>
            <input
              v-mask:currency=""
              class="form-control"
              :disabled="!selected(cashReceipt)"
              :value="revisedAmount(cashReceipt)"
              @input="setRevisedAmount(cashReceipt, $event.target.value)"
            >
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</template>

<script>
import CheckBox from "components/shared/check_box";

export default {
  components: { CheckBox },
  props: {
    cashReceipts: {
      type: Array,
      required: true,
    },
    selectedSome: Boolean,
    selectedNone: Boolean,
    selectedAll: Boolean,
    revisedAmount: {
      type: Function,
      required: true,
    },
    setRevisedAmount: {
      type: Function,
      required: true,
    },
    selected: {
      type: Function,
      required: true,
    },
    toggleSelect: {
      type: Function,
      required: true,
    },
    toggleSelectAll: {
      type: Function,
      required: true,
    },
    reconcile: {
      type: Function,
      required: true,
    },
    reconcileInFlight: Boolean,
    disbursementsDataURL: {
      type: Function,
      required: true,
    },
  },
};
</script>
