<template>
  <div class="sla-cell">
    <sla-qualification v-bind="qualificationProps" v-on="qualificationListeners" />
    <sla-valuation v-bind="valuationProps" v-on="valuationListeners" />
  </div>
</template>

<script>
import SlaQualification from "./qualification";
import SlaValuation from "./valuation";
import { selectTaskDeadline, selectTaskCompleted, canCompleteTask } from "collections/manage/sla";

export default {
  name: "worklist-sla-table",
  components: { SlaQualification, SlaValuation },
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    qualificationProps() {
      const { tasks } = this;

      return {
        countdown: {
          deadline: selectTaskDeadline(tasks, "qualification_sla"),
          completed: selectTaskCompleted(tasks, "qualification_sla"),
        },
        background: {
          approved: selectTaskCompleted(tasks, "approve_background_review"),
          canApprove: canCompleteTask(tasks, "approve_background_review"),
        },
        credit: {
          reviewed: selectTaskCompleted(tasks, "review_credit_report"),
          approved: selectTaskCompleted(tasks, "approve_credit_review"),
          canReview: canCompleteTask(tasks, "review_credit_report"),
          canApprove: canCompleteTask(tasks, "approve_credit_review"),
        },
      };
    },
    valuationProps() {
      const { tasks } = this;

      return {
        countdown: {
          deadline: selectTaskDeadline(tasks, "valuation_sla"),
          completed: selectTaskCompleted(tasks, "valuation_sla"),
        },
        valuation: {
          reviewed: selectTaskCompleted(tasks, "review_value_report"),
          approved: selectTaskCompleted(tasks, "approve_value_report"),
          canReview: canCompleteTask(tasks, "review_value_report"),
          canApprove: canCompleteTask(tasks, "approve_value_report"),
        },
      };
    },
    qualificationListeners() {
      const { markTaskCompleted } = this;

      return {
        "background-approved": markTaskCompleted("approve_background_review"),
        "credit-reviewed": markTaskCompleted("review_credit_report"),
        "credit-approved": markTaskCompleted("approve_credit_review"),
      };
    },
    valuationListeners() {
      const { markTaskCompleted } = this;

      return {
        "valuation-reviewed": markTaskCompleted("review_value_report"),
        "valuation-approved": markTaskCompleted("approve_value_report"),
      };
    },
  },
  methods: {
    markTaskCompleted(taskName) {
      return cb => this.$emit("mark-task-completed", taskName, cb);
    },
  },
};
</script>
