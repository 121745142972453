<template>
  <div class="dropdown sla-task-dropdown" :class="{ open: open }">
    <a v-if="show" class="dropdown-toggle sla-task-dropdown-toggle" @click.stop="open = true">
      <slot />
      <span class="fas fa-caret-down"></span>
    </a>
    <slot v-else />

    <div class="sla-task-dropdown-backdrop" v-if="open" @click.stop="open = false" />

    <ul class="dropdown-menu dropdown-menu-right sla-task-dropdown-menu" role="menu" v-if="open">
      <li v-if="reviewed === false">
        <sla-task-todo text="Reviewed" @checked="handleReviewed" :canComplete="canReview" />
      </li>

      <li v-if="approved === false">
        <sla-task-todo text="Approved" @checked="handleApproved" :canComplete="canApprove" />
      </li>
    </ul>
  </div>
</template>

<script>
import SlaTaskTodo from "./todo";

export default {
  name: "sla-task-dropdown",
  components: { SlaTaskTodo },
  data: () => ({
    open: false,
  }),
  props: {
    reviewed: Boolean,
    approved: Boolean,
    canApprove: Boolean,
    canReview: Boolean,
  },
  computed: {
    show() {
      return this.reviewed === false || this.approved === false;
    },
  },
  methods: {
    handleReviewed(cb) {
      this.$emit("reviewed", () => {
        cb();
        this.open = false;
      });
    },
    handleApproved(cb) {
      this.$emit("approved", () => {
        cb();
        this.open = false;
      });
    },
  },
};
</script>
