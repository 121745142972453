class BasePopulation {
  constructor(area) {
    this._areaName = area.name;
    let estimates = area.estimates.slice(-2);
    this._years = estimates.map(estimate => estimate.year);
    this._numbers = estimates.map(estimate => estimate.population);
  }
  get areaName() {
    return this._areaName;
  }
  get years() {
    return this._years;
  }
  get numbers() {
    return this._numbers;
  }
  get percentageChange() {
    return this._percentageChange;
  }
}

class Population extends BasePopulation {
  constructor(area) {
    super(area);
    this._percentageChange = (100 * (this._numbers[1] - this._numbers[0])) / this._numbers[0];
  }
}

class NullPopulation extends BasePopulation {
  constructor(area) {
    let currentYear = new Date().getFullYear();
    super({ estimates: [{ year: currentYear - 2 }, { year: currentYear - 1 }] });
    this._percentageChange = undefined;
  }
}

window.PS.Models.MSA.Population = Population;
window.PS.Models.MSA.NullPopulation = NullPopulation;
window.PS.Models.MSA.buildPopulation = function(area) {
  let model = area ? Population : NullPopulation;
  return new model(area);
};
