<template>
  <div :class="{ 'has-error': errorMessages }">
    <slot></slot>
    <span v-if="errorMessages" class="error control-label">
      {{errorMessages.join(", ")}}
    </span>
  </div>
</template>

<script>
import snakeCase from "lodash/snakeCase";

export default {
  name: "with-errors",
  props: {
    on: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  computed: {
    errorMessages() {
      return this.errors[this.errorKey];
    },
    // We use camelized property names in JS but error keys from server are not camelized.
    // Convert "address.postalCode" to "address.postal_code" to avoid spreading snake case names.
    errorKey() {
      return this.on
        .split(".")
        .map(p => snakeCase(p))
        .join(".");
    },
  },
};
</script>
