<template>
  <div class="settings-item checkbox-control">
    <input type="checkbox" :id="id" :disabled="readonly" v-model="role.enabled" @change="update">
    <label :for="id" :class="{changed: role.isChanged}">
      <span class="control-toggle"></span>
      <span class="role-name">{{role.name}}</span>
    </label>
  </div>
</template>

<script>
const Commands = PS.Models.Permission.Commands;
const Event = PS.Models.Event;

export default {
  props: {
    role: Object,
    readonly: Boolean,
  },
  computed: {
    id: function() {
      return `${this.role.value}-${this.role.userId}`;
    },
  },
  methods: {
    update: function() {
      new Commands.SendRoleUpdate(new Event(), { role: this.role }).execute();
    },
  },
};
</script>
