<template>
  <div class="note-control js-note-input">
    <div
      class="note-control analyst-note"
      data-placement="bottom"
      data-toggle="tooltip"
      :class="iconClass"
      :data-original-title="title"
    ></div>
  </div>
</template>

<script>
export default Vue.component("note-icon", {
  props: {
    tooltip: String,
  },
  mounted: function() {
    Vue.nextTick(() => {
      $(".analyst-note", this.$el).tooltip();
    });
  },
  computed: {
    title: function() {
      return this.tooltip ? this.tooltip.trim() : "";
    },
    iconClass: function() {
      return this.title.length > 0 ? "noted" : "empty";
    },
  },
  destroyed: function() {
    $(this.$el).tooltip("dispose");
  },
});
</script>
