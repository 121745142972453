import AjaxService from "services/ajax_service";
import useScenarioBuilderStore from "../store";

const ROUTES = {
  PRICING: "/loan_scenarios/pricing",
  CREDITBOX: "/loan_scenarios/creditbox",
  CONSTRAINTS: "/loan_scenarios/constraints",
};

class ServiceResponseGroup {
  constructor(group) {
    this._group = group;
  }

  get results() {
    return this._group.results;
  }

  get isValid() {
    return !!this.results;
  }

  findValueByResultName(name) {
    return this.results?.find(result => result.name === name)?.value;
  }
}

class PricingGroup extends ServiceResponseGroup {
  get noteRate() {
    return (this._noteRate = this._noteRate || this.findValueByResultName("note_rate"));
  }

  get dscr() {
    return (this._dscr = this._dscr || this.findValueByResultName("dscr"));
  }

  get monthlyPayment() {
    return (this._monthlyPayment =
      this._monthlyPayment || this.findValueByResultName("monthly_payment"));
  }

  get maxLeverageLoanAmount() {
    return (this._maxLeverageLoanAmount =
      this._maxLeverageLoanAmount || this.findValueByResultName("max_leverage_loan_amount"));
  }

  get bestRateLoanAmount() {
    return (this._bestRateLoanAmount =
      this._bestRateLoanAmount || this.findValueByResultName("best_rate_loan_amount"));
  }

  get minDscr() {
    return (this._minDscr = this._minDscr || this.findValueByResultName("min_dscr"));
  }

  get purchaseRate() {
    return (this._purchaseRate = this._purchaseRate || this.findValueByResultName("purchase_rate"));
  }
}

class ConstraintsGroup extends ServiceResponseGroup {
  get minLoanAmount() {
    return (this._minLoanAmount =
      this._minLoanAmount || this.findValueByResultName("min_loan_amount"));
  }

  get maxLoanAmount() {
    return (this._maxLoanAmount =
      this._maxLoanAmount || this.findValueByResultName("max_loan_amount"));
  }

  get minAssetValue() {
    return (this._minAssetValue =
      this._minAssetValue || this.findValueByResultName("min_asset_value"));
  }

  get minCreditScore() {
    return (this._minCreditScore =
      this._minCreditScore || this.findValueByResultName("min_credit_score"));
  }
}

class CreditboxGroup extends ServiceResponseGroup {
  get errors() {
    return (this._errors = this._errors || this.results?.filter(rule => rule.name === "error"));
  }

  get warnings() {
    return (this._warnings =
      this._warnings || this.results?.filter(rule => rule.name === "warning"));
  }
}

const GROUP_NAME_TO_CLASS_MAPPING = {
  pricing: PricingGroup,
  constraints: ConstraintsGroup,
  creditbox: CreditboxGroup,
};

class ServiceResponse {
  constructor(response) {
    this.response = response;
  }

  get creditbox() {
    return (this._creditbox = this._creditbox || this.findGroupByName("creditbox"));
  }

  get pricing() {
    return (this._pricing = this._pricing || this.findGroupByName("pricing"));
  }

  get constraints() {
    return (this._constraints = this._constraints || this.findGroupByName("constraints"));
  }

  get maxLeverageRatios() {
    return (this._maxLeverageRatios =
      this._maxLeverageRatios || this.findGroupByName("max_leverage_ratios"));
  }

  get hasSuccessfulEvaluationStatus() {
    return this.response.loan_program?.evaluation_status === "success";
  }

  findGroupByName(name) {
    const GroupClass = GROUP_NAME_TO_CLASS_MAPPING[name] || ServiceResponseGroup;
    return new GroupClass(this.response.groups?.find(group => group.name === name) || {});
  }
}

export default class ServiceClient {
  constructor(id) {
    this.id = id;
    this.abortController = new AbortController();
  }

  async requestCreditbox(payload) {
    const serviceResponse = await this.post(ROUTES.CREDITBOX, payload);

    return serviceResponse.creditbox;
  }

  async requestPricing(payload, isCustom = false) {
    const meta = isCustom ? { is_custom_amount: true } : {};
    const serviceResponse = await this.post(ROUTES.PRICING, payload, true, meta);

    return serviceResponse.pricing;
  }

  async requestConstraints(payload) {
    const serviceResponse = await this.post(ROUTES.CONSTRAINTS, payload, false);

    return serviceResponse.constraints;
  }

  async post(url, payload, isAbortable = true, meta = {}) {
    const store = useScenarioBuilderStore();
    const options = [
      url,
      {
        id: this.id,
        session_id: store.evaluationSessionId,
        group_id: store.evaluationGroupId,
        ...meta,
        loan_scenario: { loan: payload },
      },
      {},
    ];

    if (isAbortable) {
      options.push(this.abortController.signal);
    }

    let response = {};

    try {
      response = await AjaxService.postJSON(...options);
    } catch (e) {
      Bugsnag.notify(e);
    } finally {
      const data = Array.isArray(response.data) ? response.data[0] : response.data;

      return new ServiceResponse(data || {});
    }
  }

  abortPendingRequests() {
    this.abortController.abort();

    this.abortController = new AbortController();
  }

  buildCreditboxGroup(data) {
    const serviceResponse = new ServiceResponse(data);

    return serviceResponse.creditbox;
  }
}
