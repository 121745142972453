<template>
  <div class="dropdown attach-dropdown task-attachments-dropdown">
    <button v-if="canUploadFiles"
            class="attach-link btn-link dropdown-toggle"
            id="attachButton"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
    >
      + Attach files
    </button>

    <div class="dropdown-menu" aria-labelledby="attachButton">
      <js-upload class="dropdown-item"
                 :initial-options="uploadSettings"
                 :metadata="metadata"
                 @file-picker-opened="fileUploadClick"
      >
        Upload...
      </js-upload>

      <button v-show="documentsExist"
              class="dropdown-item"
              data-toggle="modal"
              data-backdrop="false"
              data-target="#documents-attachment-modal"
              :data-task-id="task.id"
              @click="documentAttachmentClick"
      >
        Attach from Documents
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "task-attachments-dropdown",
  props: {
    task: Object,
    documents: Array,
    uploadSettings: {
      type: Object,
      required: true,
    },
    canUploadFiles: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    metadata() {
      return { taskId: this.task.id };
    },
    availableDocuments() {
      return this.documents.filter(document => {
        return document.visibility === "public" || document.taskIds.includes(this.task.id);
      });
    },
    documentsExist() {
      return this.availableDocuments.length > 0;
    },
  },
  methods: {
    fileUploadClick() {
      this.$emit("file-upload-click", { task: this.task });
    },
    documentAttachmentClick() {
      this.$emit("document-attachment-click", { task: this.task });
    },
  },
};
</script>
