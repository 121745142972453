<template>
  <div class="location-marker-map-container">
    <svg class="location-marker-map"></svg>
  </div>
</template>

<script>
import { event, select } from "d3";
import { geoPath, geoAlbersUsa } from "d3-geo";

import Calculator from "models/market_data/charts/calculator";
import svgMap from "us_map.json";

export default {
  props: {
    mapLocations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      projection: this.setProjection(),
      locations: undefined,
      markersLoaded: undefined,
      mapLoaded: undefined,
    };
  },
  watch: {
    mapIsLoaded: function(newVal) {
      if (newVal) {
        this.$emit("map-loaded");
      }
    },
    mapLocations: function(newLocations) {
      if (!this.projection) {
        return;
      }

      this.locations = newLocations.map(location => {
        let coordinates = this.projection([location.longitude, location.latitude]);

        return {
          latitude: coordinates[0],
          longitude: coordinates[1],
          value: 1,
          radius: 3,
        };
      });

      this.setupMarkers();
    },
  },
  mounted() {
    this.setupMap();
  },
  methods: {
    setupMap() {
      let vm = this;
      let path = geoPath().projection(this.projection);
      let svg = select(".location-marker-map");
      let states = svg.append("g");

      states
        .selectAll("path")
        .data(svgMap.features)
        .enter()
        .append("path")
        .attr("class", "states")
        .attr("d", path);

      vm.mapLoaded = true;
    },
    setupMarkers() {
      let svg = select(".location-marker-map");
      let markers = svg.append("g");

      let tooltip = select("body")
        .append("div")
        .attr("class", "tooltip location-marker-map-tooltip")
        .style("display", "none");

      markers
        .selectAll("circle")
        .data(this.aggregatePoints(this.locations))
        .enter()
        .append("circle")
        .attr("class", "markers")
        .attr("cx", function(d) {
          return d.latitude;
        })
        .attr("cy", function(d) {
          return d.longitude;
        })
        .attr("r", function(d) {
          return d.radius;
        })

        .on("mouseover", function(d) {
          tooltip
            .html(
              `<h5>Investors</h5><div class='totals'><span>Total</span><span>${
                d.value
              }</span></div>`
            )
            .style("left", event.pageX + "px")
            .style("top", event.pageY + 10 + "px")
            .transition()
            .duration(100)
            .style("opacity", 1)
            .style("display", "block");
        })
        .on("mouseout", function() {
          tooltip
            .transition()
            .duration(100)
            .style("opacity", 0)
            .style("display", "none");
        });

      this.markersLoaded = true;
    },
    setProjection() {
      return geoAlbersUsa()
        .scale(730)
        .translate([280, 200]);
    },
    calculateMarkers(location) {
      return `translate(${this.projection([location.longitude, location.latitude])})`;
    },
    aggregatePoints(list) {
      if (list.length < 2) {
        return list;
      } else {
        let [cluster, ...unmerged] = list;
        let remainders = [];
        let merged = false;

        unmerged.forEach(point => {
          let distance = Calculator.pointDistance(
            point.latitude,
            point.longitude,
            cluster.latitude,
            cluster.longitude
          );

          let radiusCriteria = point.radius * 0.4;
          let mergeable = distance < cluster.radius + radiusCriteria;

          if (mergeable) {
            cluster = this.mergePoints(cluster, point);
            merged = true;
          } else {
            remainders.push(point);
          }
        });

        let result = merged
          ? this.aggregatePoints([...remainders, cluster])
          : [cluster, ...this.aggregatePoints(remainders)];

        return result;
      }
    },
    scaleRadius(value) {
      let maxRadius = 19;
      let minRadius = 3;
      let maxValue = 264;

      let newRadius = minRadius + value * ((maxRadius - minRadius) / maxValue);

      return newRadius >= maxRadius ? maxRadius : newRadius;
    },
    mergePoints(a, b) {
      let center = a.value > b.value ? a : b;
      let combinedValue = a.value + b.value;
      let radius = this.scaleRadius(combinedValue);

      return {
        value: combinedValue,
        latitude: center.latitude,
        longitude: center.longitude,
        radius: radius,
      };
    },
  },
  computed: {
    mapIsLoaded() {
      return this.mapLoaded && this.markersLoaded;
    },
  },
};
</script>
