<template>
  <div class="servicer-loan-boarding-table">
    <div class="servicer-loan-boarding-subheader">
      <h3>{{ label }} ({{ count }})</h3>
      <i class="fa" :class="angleClass" @click="show = !show"></i>
    </div>
    <div v-if="show">
      <slot name="guardrail"></slot>
      <slot name="actions"></slot>
      <table class="table">
        <slot name="header"></slot>
        <tbody v-if="hasItems" class="not-empty-table">
          <slot name="body"></slot>
        </tbody>
        <tbody v-else>
          <tr>
            <td></td>
            <td>No items</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "manage-servicer-loan-boarding-table",
  props: {
    count: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return { show: true };
  },
  computed: {
    hasItems() {
      return this.count !== 0;
    },
    angleClass() {
      return this.show ? "fa-angle-up" : "fa-angle-down";
    },
  },
};
</script>
