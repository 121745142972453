class SettingChanges {
  constructor() {
    this.reset();
  }

  add(command) {
    this.commands[command.item] = this.commands[command.item] || {};

    let currentAction = this.commands[command.item][command.value];
    switch (true) {
      case currentAction === undefined:
        {
          this.commands[command.item][command.value] = command.action;
          this.count += 1;
        }
        break;

      case currentAction !== command.action:
        {
          this.commands[command.item][command.value] = undefined;
          this.count -= 1;
        }
        break;
    }
  }

  reset() {
    this.commands = {};
    this.count = 0;
  }

  hasChanges() {
    return this.count > 0;
  }
}

window.PS.Models.SettingChanges = SettingChanges;
