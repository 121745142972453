<template>
  <div class="row responsible-role-row">
    <with-errors on="responsible_role_definition_id" class="form-group control-wrapper col-4" :errors="errors">
      <label :for="`roleType-${index}`" class="control-label">
        Role
        <a href="#" class="remove-link" @click.prevent="$emit('remove-role', role)">
          remove
        </a>
      </label>
      <select
        :id="`roleType-${index}`"
        v-model="role.definition"
        name="roleType"
        class="form-control"
      >
        <option />
        <option
          v-for="definition in roleDefinitions"
          :key="definition.id"
          :value="definition"
          :disabled="isRoleOptionDisabled(definition)"
        >
          {{ definition.label }}
        </option>
      </select>
    </with-errors>
    <with-errors
      v-if="showBorrowerAssignment"
      on="reference_id"
      class="form-group control-wrapper col-4"
      :errors="errors"
    >
      <label :for="`referenceId-${index}`" class="control-label">Borrower</label>
      <select
        :id="`referenceId-${index}`"
        v-model="role.referenceId"
        class="form-control"
        name="referenceId"
      >
        <option disabled>Select Borrower</option>
        <option v-for="{name, id} in borrowers" :key="id" :value="id">
          {{ name }}
        </option>
      </select>
    </with-errors>
    <component
      :is="attributeType"
      v-if="showRoleAdditionInput"
      :id="`attribute_value-${index}`"
      :schema="role.attributeSchema"
      :errors="errors"
      :initial-value="role.attributeValue"
      attribute-name="attribute_value"
      wrapper-class="form-group control-wrapper col-4"
      label-class="control-label"
      input-class="form-control"
      @on-field-update="updateAttributeValue"
    />
  </div>
</template>

<script>
import TextInput from "./text_input";
import NumberInput from "./number_input";
import WithErrors from "components/shared/with_errors";

import isEqual from "lodash/isEqual";

export default {
  name: "ResponsibleRoleFormRow",
  components: { TextInput, NumberInput, WithErrors },
  props: {
    role: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    borrowers: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    roleDefinitions: {
      type: Array,
      required: true,
    },
    isEntity: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showBorrowerAssignment() {
      return this.role.definition && this.role.definition.name !== "guarantor";
    },
    showRoleAdditionInput() {
      return !isEqual(this.role.attributeSchema, {});
    },
    attributeType() {
      return this.role.attributeSchema.filedType;
    },
  },
  watch: {
    "role.definition": {
      deep: true,
      handler(newDefinition, oldDefinition) {
        this.role.resetDefinitionFields(newDefinition || {}, oldDefinition || {});
        this.assignDefaultBorrower();
      },
    },
  },
  methods: {
    isRoleOptionDisabled(role) {
      return this.isEntity && !role.entity;
    },
    updateAttributeValue(value) {
      this.role.updateAttributeValue(value);
    },
    assignDefaultBorrower() {
      const firstBorrower = this.borrowers[0];

      if (this.showBorrowerAssignment && !this.role.referenceId) {
        this.role.referenceId = firstBorrower && firstBorrower.id;
      }
    },
  },
};
</script>
