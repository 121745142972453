<template>
  <div>
    <div class="summary-chart-header">
      <entity-summary-report-legend v-if="reportHasData" />
      <filters
        v-if="reportHasData"
        :states="statesOptions"
        :funding-types="fundingTypeOptions"
        @on-states-select="updateStatesFilter"
        @on-funding-types-select="updateFundingTypesFilter"
      />
    </div>
    <div v-if="reportHasData" class="purchasing-permissions-container">
      <table class="purchasing-permissions">
        <thead>
          <tr>
            <th>State</th>
            <th>SFR</th>
            <th>SFR Construction <br>Dutch</th>
            <th>SFR Construction <br>Non-Dutch</th>
            <th>SFR Rental</th>
            <th>MFR</th>
            <th>MFR Construction <br>Dutch</th>
            <th>MFR Construction <br>Non-Dutch</th>
            <th>CRE</th>
            <th>CRE Construction <br>Dutch</th>
            <th>CRE Construction <br>Non-Dutch</th>
          </tr>
        </thead>
        <tbody class="force-scrollbars">
          <tr v-for="state in statesSummary" :key="state.id" :data-path="lenderLegalEntityStatePath(state.id)">
            <td>{{ state.state }}</td>

            <loan-type-cell
              v-for="loanType in state.loanTypes"
              :key="`${state.id}-${loanType.type}`"
              :funding-types="filterFundingTypes(loanType.fundingTypes)"
            />
          </tr>
        </tbody>
      </table>
      <p class="pari-passu-note">
        {{ entityReport.pariPassuStatus }}
      </p>
    </div>
    <h2 v-else class="no-states-onboarded-block">
      No States Onboarded Yet
    </h2>
  </div>
</template>

<script>
import EntitySummaryReportLegend from "./legend";
import Filters from "./filters";
import LoanTypeCell from "./cell";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";

import { US_STATES_OPTIONS } from "models/constants";

export default {
  name: "entity-summary-report",
  components: {
    LoanTypeCell,
    EntitySummaryReportLegend,
    Filters,
  },
  data: function() {
    return {
      entityReport: PSData.lenderLegalEntityReport || { states: [] },
      filters: {
        states: [],
        fundingTypes: [],
      },
      reportOptions: PSData.summaryReportOptions,
      routes: PSData.routes,
    };
  },
  computed: {
    availableStates() {
      return this.entityReport.states.map(state => state.state);
    },
    statesSummary() {
      return this.entityReport.states.filter(state => {
        return isEmpty(this.filters.states) || includes(this.filters.states, state.state);
      });
    },
    reportHasData() {
      return !!this.entityReport.states.length;
    },
    statesOptions() {
      return US_STATES_OPTIONS.filter(option => includes(this.availableStates, option.value));
    },
    fundingTypeOptions() {
      return this.reportOptions.fundingTypes;
    },
  },
  mounted() {
    $(".purchasing-permissions-container").tooltip({
      selector: ".condition",
      boundary: "viewport",
    });
  },
  beforeDestroy() {
    $(".purchasing-permissions-container").tooltip("dispose");
  },
  methods: {
    lenderLegalEntityStatePath(stateId) {
      return `${this.routes.lenderLegalEntityStates}/${stateId}`;
    },
    updateStatesFilter(states) {
      Object.assign(this.filters, { states });
    },
    updateFundingTypesFilter(fundingTypes) {
      Object.assign(this.filters, { fundingTypes });
    },
    filterFundingTypes(fundingTypes) {
      if (isEmpty(this.filters.fundingTypes)) return fundingTypes;

      return fundingTypes.filter(type => includes(this.filters.fundingTypes, type.noteOrigination));
    },
  },
};
</script>
