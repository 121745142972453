<template>
  <div class="price-status">
    <template v-if="lenderOnly">
      <div v-for="(value, key) in attrs" class="loan-details-row">
        <div>{{ value }}</div>
        <div v-if="isLoading" class="ps-loader ps-loader-small"></div>
        <div v-else>{{ attrValue(key) }}</div>
      </div>
    </template>

    <template v-if="analystOnly && isRental">
      <div v-for="(value, key) in attrs" class="unit">
        <div class="loan-attribute-label">{{ value }}</div>
        <div v-if="isLoading" class="loan-attribute-value ps-loader ps-loader-small"></div>
        <div v-else class="loan-attribute-value">{{ attrValue(key) }}</div>
      </div>
    </template>

    <template v-if="analystOnly && !isRental">
      <li v-for="(value, key) in attrs">
        <div class="loan-attribute-label">{{ value }} </div>
        <div v-if="isLoading" class="loan-attribute-value ps-loader ps-loader-small"></div>
        <div v-else class="loan-attribute-value">{{ attrValue(key) }}</div>
      </li>
    </template>
  </div>
</template>

<script>
import PriceStatusService from "services/price_status_service";
import NumberFormatter from "models/formatters/number_formatter";
import { PROGRAM_CLASSIFICATION } from "models/lender_form/program";
import transform from "lodash/transform";

const NOTE_RATE = { noteRate: "Note Rate" };
const LENDER_SPREAD = { lenderSpread: "Lender Spread" };
const PURCHASE_RATE = { purchaseRate: "Purchase Rate" };
const PS_SPREAD = { psSpread: "PeerStreet Spread" };

const PRICE_ATTRIBUTES = {
  [PROGRAM_CLASSIFICATION.RESIDENTIAL_BRIDGE]: {
    lender: {
      ...LENDER_SPREAD,
      ...PURCHASE_RATE,
    },
    analyst: {
      ...LENDER_SPREAD,
      ...PURCHASE_RATE,
      ...PS_SPREAD,
    },
  },
  [PROGRAM_CLASSIFICATION.RESIDENTIAL_RENTAL_LONG_TERM]: {
    lender: {
      ...NOTE_RATE,
    },
    analyst: {
      ...NOTE_RATE,
    },
  },
  [PROGRAM_CLASSIFICATION.COMMERCIAL_MULTIFAMILY]: {
    lender: {
      ...LENDER_SPREAD,
      ...PURCHASE_RATE,
    },
    analyst: {
      ...LENDER_SPREAD,
      ...PURCHASE_RATE,
      ...PS_SPREAD,
    },
  },
};

export default {
  name: "price-status",
  props: {
    estimated: {
      type: Boolean,
      default: false,
    },
    displayFor: String,
    loanProgram: String,
  },
  data: () => ({
    loan: undefined,
    url: PSData.routes.loanPriceStatus,
    isLoading: true,
  }),
  mounted() {
    this.getPrice();
  },
  computed: {
    lenderOnly() {
      return this.displayFor === "lender";
    },
    analystOnly() {
      return this.displayFor === "analyst";
    },
    isRental() {
      return this.loanProgram === PROGRAM_CLASSIFICATION.RESIDENTIAL_RENTAL_LONG_TERM;
    },
    attrs() {
      const loanProgramAttributes = PRICE_ATTRIBUTES[this.loanProgram];
      const viewSpecificAttributes = (loanProgramAttributes || {})[this.displayFor];

      if (!viewSpecificAttributes) {
        return;
      }

      return this.estimated
        ? this.convertToEstimatedAttributes(viewSpecificAttributes)
        : viewSpecificAttributes;
    },
  },
  methods: {
    attrValue(attr) {
      const value = this.loan[attr];
      if (value === null || value === undefined) return "--";

      return NumberFormatter.formatPercent(parseFloat(value), 3, true) || "--";
    },
    getPrice() {
      PriceStatusService.getPrice(this.url)
        .then(response => {
          this.loan = response;
          this.isLoading = false;
        })
        .catch(result => {
          Bugsnag.notify({ name: "PriceStatusService Error", message: result.error.message });
        });
    },
    convertToEstimatedAttributes(attributes) {
      // { someKey: "Some Value" } -> { estimatedSomeKey: "Estimated Some Value" }
      return transform(attributes, (result, value, key) => {
        const newKey = `estimated${key.charAt(0).toUpperCase() + key.slice(1)}`;
        result[newKey] = `Estimated ${value}`;
      });
    },
  },
};
</script>
