import Form from "models/lender_form/form";
import CreditBoxSidebarGuide from "components/credit_box/credit_box_sidebar_guide";
import SegmentService from "services/segment_service";
import LoanProgramParams from "models/loan_program_params";
import PricingGuide from "components/pricing_estimator/index";
import LoanGuide from "components/loan_guide/index";
import AjaxService from "services/ajax_service";
import debounce from "utils/debounce";
import LocationService from "services/location_service";
import LoanProgramSelector from "components/loan_program_selector/loan_program_selector";
import LoanStatusTracker from "components/loan_status_tracker";
import GlobalBusService from "services/global_bus_service";
import MidasService from "services/midas_service";
import toPlainObject from "utils/to_plain_object";
import Location from "services/location_service";
import PropertyDetailsClient from "components/loan_scenarios/api/property_details_client";
import GoogleAutocompleteMixin from "mixins/google_autocomplete_mixin";
import LoanProgramsService from "services/loan_programs_service";

const SUBMIT_TARGET = {
  NONE: "NONE",
  SAVE_AND_EXIT: "SAVE_AND_EXIT",
  SUBMIT_FOR_EVALUATION: "SUBMIT_FOR_EVALUATION",
  SAVE_AND_ANALYZE: "SAVE_AND_ANALYZE",
  SAVE_AND_CONTINUE: "SAVE_AND_CONTINUE",
};

$(() => {
  if (!document.querySelector(".v-edit-loan-controller")) {
    return;
  }

  new Vue({
    el: document.querySelector(".v-edit-loan-controller"),
    components: {
      CreditBoxSidebarGuide,
      PricingGuide,
      LoanGuide,
      LoanProgramSelector,
      LoanStatusTracker,
    },
    mixins: [GoogleAutocompleteMixin],
    data: function() {
      let loan = PSData.loan || {};
      let loanProgramId = PSData.loan.loanProgramId || undefined;
      let programType = PSData.loan.programType || undefined;
      let form = new Form(loan);
      let underwritingStatus = PSData.loan.underwritingStatus || undefined;
      let designatedRentalIncome = PSData.designatedRentalIncome || undefined;
      let tasks = PSData.tasks || [];
      let isBrokerAccount = PSData.isBrokerAccount;

      return {
        submittedTo: SUBMIT_TARGET.NONE,
        creditBoxResults: [],
        programTypes: PSData.programTypes,
        loanStrategyNamesMapping: PSData.loanStrategyNamesMapping,
        loan,
        loanProgramId,
        programType,
        underwritingStatus,
        form,
        pricing: {},
        programOptions: [],
        hasErrorsLoadingCreditbox: false,
        propertyDetailsClient: new PropertyDetailsClient(),
        priceParams: {},
        loanParams: {},
        isEstimating: false,
        routes: PSData.routes,
        designatedRentalIncome,
        abortController: null,
        abortDefaultsController: null,
        clearPricing: false,
        zipHasChanged: false,
        addressHasChanged: false,
        tasks,
        viewLpsResults: PSData.loanPolicy.viewLpsResults.allowed,
        showLoanTypeSelection: false,
        isBrokerAccount,
      };
    },
    mounted() {
      GlobalBusService.$on("lps-explain", this.lpsExplain);
      GlobalBusService.$on("program-type-selected", this.onSetProgramType);
      if (this.$refs["address-input"]) {
        this.attachAutocompleteToElement(this.$refs["address-input"]);
      }
      this.setBrokerDefaults();
      this.setDefaults();
    },
    watch: {
      "form.fields": {
        deep: true,
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.zipHasChanged = false;
            this.form.update(newValue);
            this.getLoanOptions();
          }
        },
      },
      "form.fields.zip.value": {
        handler(newValue, previousValue) {
          this.addressHasChanged = this.zipHasChanged = previousValue !== newValue;
        },
      },
      "form.fields.address.value": {
        handler(newValue, previousValue) {
          this.addressHasChanged = previousValue !== newValue;
        },
      },
      "form.fields.city.value": {
        handler(newValue, previousValue) {
          this.addressHasChanged = previousValue !== newValue;
        },
      },
      "form.fields.state.value": {
        handler(newValue, previousValue) {
          this.addressHasChanged = previousValue !== newValue;
        },
      },
      "form.fields.loanStrategyId.value": {
        handler(newValue) {
          this.form.fields.loanStrategy.value = this.loanStrategyNamesMapping[newValue];
        },
      },
      "form.fields.lenderNoteStatus.value": {
        handler(newValue) {
          if (newValue !== "funded") {
            this.form.fields.totalPrincipalPayments.value = 0;
            this.form.fields.totalPrincipalPayments.required = false;
            this.clearPricing = false;
          } else {
            this.form.fields.totalPrincipalPayments.value = null;
            this.form.fields.totalPrincipalPayments.required = true;
            this.clearPricing = true;
          }
        },
      },
      isFundingIntoOrigination(isFIO, previouslyFIO) {
        if (!isFIO && previouslyFIO) {
          this.form.fields.lenderEntityLegalEntityId.value = "";
        }
      },
      "form.fields.programType.value": {
        handler(newValue) {
          this.clearPricing = true;

          if (newValue === "residential_rental_long_term") {
            this.form.capitalSection.disableSection();
          }
        },
      },
    },
    methods: {
      onSaveAndExit() {
        this.onSubmit(SUBMIT_TARGET.SAVE_AND_EXIT);
      },
      onSubmitForEvaluation(loanProgram) {
        this.loanProgramId = loanProgram.id;
        this.onSubmit(SUBMIT_TARGET.SUBMIT_FOR_EVALUATION);
      },
      onCancelWithoutChanges() {
        Location.href = this.routes.loan;
      },
      onSaveAndAnalyze() {
        this.onSubmit(SUBMIT_TARGET.SAVE_AND_ANALYZE);
        SegmentService.track("clickedCreditBoxEvaluateButton");
      },
      onSetProgramType(program) {
        this.programType = program;
        this.form.fields.programType.value = program;
        this.onSaveAndContinue();
      },
      onSaveAndContinue() {
        this.onSubmit(SUBMIT_TARGET.SAVE_AND_CONTINUE);
      },
      onSubmit(target) {
        if (this.uiBlocked) {
          return;
        }

        this.submittedTo = target;

        Vue.nextTick(() => {
          this.$el.querySelector("form").submit();
        });
      },
      onFeesChange(name, value) {
        this.form.manualFieldChange(name, value);
      },
      initializeForm() {
        return new Form(this.loan);
      },
      isNewLoan() {
        return LocationService.pathname === "/loans/new";
      },
      getLoanOptions() {
        if (this.form.isReadyForCreditbox && !this.isNewLoan()) {
          const medianHomePriceInZip = { medianHomePriceInZip: this.loan.medianHomePriceInZip };
          const propertyDetails = {
            cbsaType: this.loan.propertyCbsaType,
            cbsaName: this.loan.propertyCbsaName,
            cbsaCode: this.loan.propertyCbsaCode,
          };
          const designatedRentalIncome = { designatedRentalIncome: this.designatedRentalIncome };
          const programType = { programType: this.loan.programType };
          const params = {
            ...this.form.fields,
            ...medianHomePriceInZip,
            ...propertyDetails,
            ...designatedRentalIncome,
            ...programType,
          };

          this.loanParams = new LoanProgramParams(params);
          this.requestProgramOptions();
        } else {
          this.programOptions = [];
        }
      },
      handleAutocompleteSelected(newAddress) {
        const { address, zip, state, city } = this.form.fields;

        address.value = newAddress.fullStreetName;
        zip.value = newAddress.zipCode;
        state.value = newAddress.state;
        city.value = newAddress.city;
      },
      async setDefaults() {
        // temporary optimization until we'll need defaults for non broker accounts
        if (!this.isBrokerAccount) return;
        if (this.isNewLoan()) return;

        if (this.abortDefaultsController) {
          this.abortDefaultsController.abort();
        }
        this.abortDefaultsController = new AbortController();
        const signal = this.abortDefaultsController.signal;
        const urlParams = new URLSearchParams({ program_type: this.programType });
        const url = [this.routes.loanDefaults, "?", urlParams.toString()].join("");

        let response;
        try {
          response = await AjaxService.getJSON(url, {}, signal);
          this.form.setDefaults(response);
        } catch (e) {
          Bugsnag.notify(new Error("Unable to get loan defaults"), {
            metaData: { custom: { response: toPlainObject(response) } },
          });
        }
      },
      async getMedianHomePriceInZip() {
        const isMedianHomePriceSet = !!this.loan.medianHomePriceInZip;
        const needsMedianHomePriceSet = ["draft", "evaluating", "qualified"].includes(
          this.loan.underwritingStatus
        );
        const zipHasChanged = this.zipHasChanged;

        if (!needsMedianHomePriceSet || (!zipHasChanged && isMedianHomePriceSet)) {
          return;
        }

        let response, payload;

        try {
          payload = { zip: this.form.fields.zip.value };
          response = await MidasService.zipMedianHomePrice(payload.zip);
          this.loanParams.medianHomePriceInZip = response.data.zipMedianHomePrice?.value;
        } catch (e) {
          Bugsnag.notify(new Error("Unable to get Median Home Price for ZIP Code from Midas"), {
            metaData: { custom: { response: toPlainObject(response.data), payload } },
          });
        }
      },
      async getPropertyDetails() {
        const hasSavedPropertyDetails = !!this.loanParams.propertyCbsaCode;
        if (!this.addressHasChanged && hasSavedPropertyDetails) return;

        const { zip: zipCode, city, address, state } = this.loanParams.toHash();
        const propertyDetails = await this.propertyDetailsClient.fetchDetails({
          address,
          city,
          state,
          zipCode,
        });
        this.loanParams.propertyCbsaType = propertyDetails.cbsaType;
        this.loanParams.propertyCbsaCode = propertyDetails.cbsaCode;
        this.loanParams.propertyCbsaName = propertyDetails.cbsaName;
        this.addressHasChanged = false;
      },
      async getProgramOptions() {
        if (this.abortController) {
          this.abortController.abort();
        }

        this.isEstimating = true;
        this.abortController = new AbortController();
        this.programOptions = [];
        const signal = this.abortController.signal;
        const url = this.routes.loanPrograms;
        const payload = {
          loan: { ps_id: this.loan.toParam, ...this.loanParams.toHash() },
        };

        try {
          const result = await AjaxService.postJSON(url, payload, {}, signal);
          this.lpsEvaluationId = result.id;
          this.programOptions = result.options;
          this.hasErrorsLoadingCreditbox = false;
        } catch (e) {
          this.hasErrorsLoadingCreditbox = true;
        } finally {
          this.isEstimating = false;
        }
      },
      lpsExplain(context) {
        LoanProgramsService.explainEvaluationResults(this.lpsEvaluationId, context);
      },
      requestProgramOptions: debounce(async function() {
        await this.getPropertyDetails();
        await this.getMedianHomePriceInZip();
        await this.getProgramOptions();
      }, 350),
      toggleLoanTypeSelection() {
        this.showLoanTypeSelection = !this.showLoanTypeSelection;
      },
      setBrokerDefaults() {
        if (this.isBrokerAccount && this.isDraft) {
          return this.form.loanSection.resetInterestRate;
        }
      },
    },
    computed: {
      loanTypeSectionComplete() {
        return this.form.loanTypeSection.isComplete;
      },
      propertySectionComplete() {
        return this.form.propertySection.isComplete;
      },
      propertyDetailsSectionComplete() {
        return this.form.propertyDetailsSection.isComplete;
      },
      loanDetailsComplete() {
        return this.form.loanSection.isComplete;
      },
      capitalDetailsComplete() {
        return this.isResiRental || this.form.capitalSection.isComplete;
      },
      borrowerDetailsComplete() {
        return this.form.borrowerSection.isComplete;
      },
      pricingDetailsComplete() {
        return this.isFunded || this.form.pricingSection.isComplete;
      },
      summaryDetailsComplete() {
        return this.form.summarySection.isComplete;
      },
      completedPercentage() {
        return this.form.completedPercentage;
      },
      dateLabelPrefix() {
        return this.form.loanSection.dateLabelPrefix;
      },
      uiBlocked() {
        return this.submittedTo !== SUBMIT_TARGET.NONE;
      },
      multifamilyBridgeEnabled() {
        return PSData.featureFlag.multifamilyBridge;
      },
      saveAndExitCaption() {
        return this.submittedTo === SUBMIT_TARGET.SAVE_AND_EXIT
          ? "Saving..."
          : "Save Draft and Exit";
      },
      saveAndAnalyzeCaption() {
        return this.submittedTo === SUBMIT_TARGET.SAVE_AND_ANALYZE ? "Evaluating..." : "Evaluate";
      },
      submittedForEvaluation() {
        return this.submittedTo === SUBMIT_TARGET.SUBMIT_FOR_EVALUATION;
      },
      savedForAnalyze() {
        return this.submittedTo === SUBMIT_TARGET.SAVE_AND_ANALYZE;
      },
      isAcquisition() {
        return this.form.loanSection.isAcquisition;
      },
      savedToContinue() {
        return this.submittedTo === SUBMIT_TARGET.SAVE_AND_CONTINUE;
      },
      isChanged() {
        return this.form.isChanged();
      },
      isFundingIntoOrigination() {
        return this.form.loanSection.isFundingIntoOrigination;
      },
      showCema() {
        return this.form.loanSection.showCema;
      },
      isFunded() {
        return this.form.loanSection.isFunded;
      },
      borrowerTrackRecordLabel() {
        return this.form.borrowerSection.borrowerTrackRecordLabel;
      },
      isRefinanceLoanPurpose() {
        return this.form.propertySection.isRefinanceLoanPurpose;
      },
      isIndividualBorrower() {
        return this.form.borrowerSection.isIndividualBorrower;
      },
      isConstructionReserveDisabled() {
        return this.form.capitalSection.isConstructionReserveDisabled;
      },
      isResiBridge() {
        return this.form.programTypeSection.isResiBridge;
      },
      isResiRental() {
        return this.form.programTypeSection.isResiRental;
      },
      isDraft() {
        return this.underwritingStatus === "draft";
      },
      isEvaluating() {
        return this.underwritingStatus === "evaluating";
      },
      isMultifamilyBridge() {
        return this.form.programTypeSection.isMultifamilyBridge;
      },
      shouldShowNewForm() {
        return this.isResiBridge || this.isResiRental;
      },
      shouldHideMonthlyGrossRentalIncome() {
        return (
          this.isResiBridge ||
          this.isMultifamilyBridge ||
          this.form.propertySection.isRentalAcquisitionAndNotLeased
        );
      },
      hasConstructionReserve() {
        return this.form.fields.constructionReserve.convertedValue > 0;
      },
      isGroundUpConstruction() {
        return this.form.programTypeSection.isGroundUpConstruction;
      },
      groundUpConstructionEnabled() {
        return PSData.featureFlag.groundUpConstruction;
      },
    },
  });
});
