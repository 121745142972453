<template>
  <div class="loan-task-view" :class="{ 'inprogress-task': task.isPending }">
    <component
      :is="taskHeaderComponent"
      :task="task"
      @on-back-btn="onBackButton"
      @on-complete-btn="completeTask"
    />
    <div class="loan-task-events">
      <div class="loan-task-content">
        <div class="loan-task-view-title">{{ task.title }}</div>
        <div
          v-if="task.description"
          class="loan-task-view-description content"
          v-html="task.description"
        />

        <borrower-tasks
          :borrower-tasks-path="task.borrowerTasksPath"
        />

        <task-attachments-section
          :task="task"
          :documents="documents"
          :upload-settings="uploadSettings"
          :can-upload-files="true"
          @file-upload-click="fileUploadClick"
          @document-attachment-click="documentAttachmentClick"
        />

        <div v-show="isLoading" class="loan-task-loader">
          <div class="ps-loader ps-loader-small" />
        </div>
        <comments-list :comments="comments" />
      </div>
      <div class="messages-widget">
        <comments-form :task="task" @created="onNewComment" />
      </div>
    </div>
  </div>
</template>
<script>
import DetailsMixin from "components/activity_feed_events/loan_tasks/details_mixin";
import TaskAttachmentsSection from "components/activity_feed_events/loan_tasks/task_attachments_section";
import TaskHeader from "./task_header";
import PayAdminFeeHeader from "./pay_admin_fee/task_header";
import BorrowerTasks from "./borrower_tasks";

export default {
  name: "loan-tasks-details",
  mixins: [DetailsMixin],
  components: {
    TaskAttachmentsSection,
    TaskHeader,
    PayAdminFeeHeader,
    BorrowerTasks,
  },
  props: {
    documents: Array,
  },
  data() {
    return {
      uploadSettings: PSData.uploadSettings,
    };
  },
  computed: {
    taskHeaderComponent() {
      if (this.task.definition.systemName === "pay_admin_fee") {
        return "PayAdminFeeHeader";
      } else {
        return "TaskHeader";
      }
    },
  },
  methods: {
    fileUploadClick(event) {
      this.$emit("file-upload-click", event);
    },
    documentAttachmentClick(event) {
      this.$emit("document-attachment-click", event);
    },
  },
};
</script>
