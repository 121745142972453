import Field from "./field";
import LoanTypeSection from "./sections/loan_type_section";
import PropertySection from "./sections/property_section";
import PropertyDetailsSection from "./sections/property_details_section";
import LoanSection from "./sections/loan_section";
import BorrowerSection from "./sections/borrower_section";
import PricingSection from "./sections/pricing_section";
import SummarySection from "./sections/summary_section";
import CapitalSection from "./sections/capital_section";
import ProgramTypeSection from "./sections/program_type_section";
import { isEqual, cloneDeep } from "lodash";

export const PROPERTY_CLASSIFICATION = {
  NONE: "NONE",
  SINGLE_FAMILY: "SINGLE_FAMILY",
  MULTIFAMILY: "MULTIFAMILY",
  COMMERCIAL: "COMMERCIAL",
};

export default class Form {
  constructor(rawData) {
    this.fields = Field.createFields(rawData);
    this.isChanged = false;
    this.previousFields = null;

    this.programTypeSection = new ProgramTypeSection(this.fields);
    this.loanTypeSection = new LoanTypeSection(this.fields);
    this.propertySection = new PropertySection(this.fields);
    this.propertyDetailsSection = new PropertyDetailsSection(this.fields);
    this.loanSection = new LoanSection(this.fields);
    this.summarySection = new SummarySection(this.fields);
    this.borrowerSection = new BorrowerSection(this.fields);
    this.pricingSection = new PricingSection(this.fields);
    this.capitalSection = new CapitalSection(this.fields);

    this.sections = [
      this.programTypeSection,
      this.loanTypeSection,
      this.propertySection,
      this.propertyDetailsSection,
      this.loanSection,
      this.borrowerSection,
      this.pricingSection,
      this.summarySection,
      this.capitalSection,
    ];
  }

  update(fields) {
    const changes = !isEqual(this.previousFields, fields);

    if (changes) {
      this.isChanged = true;
      this.sections.forEach(section => section.update());
      this.previousFields = cloneDeep(this.fields);
    }
  }

  setDefaults(defaults) {
    Object.keys(defaults).forEach(key => {
      if (this.fields[key] && this.fields[key].value !== defaults[key]) {
        this.fields[key].value = defaults[key];
      }
    });
    this.update();
  }

  manualFieldChange(name, value) {
    if (value === null) {
      this.fields[name].disabled = true;
    } else {
      this.fields[name].disabled = false;
      this.fields[name].value = value;
    }

    this.update();
  }

  get isComplete() {
    return this.sections.every(section => section.isComplete);
  }

  get completedPercentage() {
    const sum = (total, current) => total + current;
    const totalFields = this.sections.map(s => s.totalFields).reduce(sum, 0);
    if (totalFields === 0) {
      return totalFields;
    }
    const completedFields = this.sections.map(s => s.completedFields).reduce(sum, 0);

    return (completedFields / totalFields).toFixed(2) * 100;
  }

  getValues() {
    return Object.values(this.fields).map(field => field.value);
  }

  get isReadyForCreditbox() {
    return [
      this.loanTypeSection,
      this.propertySection,
      this.propertyDetailsSection,
      this.loanSection,
      this.capitalSection,
      this.borrowerSection,
    ].every(section => section.isComplete);
  }

  get propertyClassification() {
    switch (this.fields.propertyType.value) {
      case "sfr":
      case "condo":
      case "duplex":
      case "triplex":
      case "fourplex":
        return PROPERTY_CLASSIFICATION.SINGLE_FAMILY;

      case "multifamily":
        return PROPERTY_CLASSIFICATION.MULTIFAMILY;

      case "mixed_use":
      case "office":
      case "retail":
      case "industrial_warehouse":
      case "land":
      case "mobile_home_park":
      case "self_storage":
      case "hospitality":
      case "special_purpose":
        return PROPERTY_CLASSIFICATION.COMMERCIAL;

      default:
        return PROPERTY_CLASSIFICATION.NONE;
    }
  }
}
