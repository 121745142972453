<template>
  <div>
    <entities-filter
      :current-user-id="currentUser.id"
      :initial-filter="filters.assigneeId"
      :labels="{ my_entities: 'My Tasks' }"
      :options="workflowUsersOptions"
      @filter="filterBy('assigneeId', $event)"
    />
    <div class="pagination-info">
      Showing {{ tasksCount }} of {{ tasksTotal }} Tasks
    </div>
    <div class="mb-5">
      <tasks-table :loading="isSearching" :tasks="tasks" @sort="sortBy" />
    </div>
    <div v-if="showLoadMoreButton" class="pagination mb-5">
      <base-button size="large" @click="search">
        Load 100 More
      </base-button>
    </div>
  </div>
</template>

<script>
import AjaxService, { formatParams, ERROR_MESSAGE } from "services/ajax_service";
import { objectAndValuesToSnakeCase } from "utils/object_to_snake_case";
import EntitiesFilter from "../portfolio/entities_filter";
import TasksTable from "./table";

export default {
  components: {
    EntitiesFilter,
    TasksTable,
  },
  data() {
    const currentUser = PSData.currentUser;
    return {
      tasks: [],
      filters: {
        assigneeId: currentUser.id,
      },
      sortingParams: {
        sortColumn: undefined,
        sortDirection: undefined,
      },
      errors: [],
      tasksTotal: 0,
      isSearching: false,
      currentUser,
    };
  },
  computed: {
    routes: () => PSData.routes,
    workflowUsers: () => PSData.workflowUsers,
    workflowUsersOptions() {
      return this.workflowUsers
        .filter(user => user.id !== this.currentUser.id)
        .map(user => [user.name, user.id]);
    },
    tasksCount() {
      return this.tasks.length;
    },
    showLoadMoreButton() {
      return this.tasksCount < this.tasksTotal;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.search({ resetOffset: true });
      },
    },
    sortingParams: {
      deep: true,
      handler() {
        this.search({ resetOffset: true });
      },
    },
  },
  created() {
    this.search();
  },
  methods: {
    filterBy(param, value) {
      this.$set(this.filters, param, value);
    },
    sortBy({ field, order }) {
      this.sortingParams = {
        sortColumn: field,
        sortDirection: order,
      };
    },
    prepareSearchParams(resetOffset = false) {
      const offset = resetOffset ? 0 : this.tasks.length;
      const offsetParams = { offset };
      const params = {
        ...offsetParams,
        ...this.filters,
        ...this.sortingParams,
      };
      return objectAndValuesToSnakeCase(params);
    },
    handleResponse(response, resetOffset = false) {
      if (resetOffset) {
        this.tasks = response.tasks;
      } else {
        this.tasks = this.tasks.concat(response.tasks);
      }

      this.tasksTotal = response.available;
    },
    async search({ resetOffset = false } = {}) {
      const formattedParams = formatParams(this.prepareSearchParams(resetOffset));
      const url = `${this.routes.taskPortfolio}.json?${formattedParams}`;
      this.isSearching = true;
      try {
        const response = await AjaxService.getJSON(url);
        this.handleResponse(response, resetOffset);
      } catch ({ data, response }) {
        if (Array.isArray(data.errors)) {
          this.errors = data.errors.join(". ");
        } else {
          this.errors = [ERROR_MESSAGE];
        }
      } finally {
        this.isSearching = false;
      }
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  justify-content: center;
}
.pagination-info {
  font-weight: 600;
  font-size: var(--font-size-lg);
  line-height: var(--line-height);
  padding: var(--space-unit) 0;
}
</style>
