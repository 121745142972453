// Calculates exponential backoff delays.
//
// const delay = backoff(200);
// delay(); // 200
// delay(); // 400
// delay(); // 800
export function backoff(initialDelay, factor = 2) {
  let callCount = 0;

  return () => initialDelay * factor ** callCount++;
}

// Sleep for specified amount of milliseconds.
//
// await sleep(200);
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
