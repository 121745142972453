<template>
  <div class="tab-container loan-transaction-details" v-if="shouldRender" v-cloak>
    <div class="row">
      <div class="col-6 earnings-and-holdbacks">
        <h4 class="header">PeerStreet Earning Asset</h4>
        <table class="table">
          <thead>
          <tr>
            <th class="date"></th>
            <th>Starting Balance</th>
            <th>Net Change</th>
            <th>Outstanding</th>
          </tr>
          </thead>
          <tbody>
          <tr class="summary">
            <td class="totals">Principal</td>
            <td>{{balances.startingPrincipalBalance | money}}</td>
            <td>{{balances.principalNetChange | accounting }}</td>
            <td class="totals">{{balances.outstandingPrincipal | money}}</td>
          </tr>
          </tbody>
        </table>

        <h4 class="header">Holdbacks</h4>
        <table class="table">
          <thead>
          <tr>
            <th class="date"></th>
            <th>Starting Balance</th>
            <th>Drawn</th>
            <th>Remaining</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Construction</td>
            <td></td>
            <td></td>
            <td class="totals"></td>
          </tr>
          <tr>
            <td class="offset">On Platform</td>
            <td>{{balances.constructionStartingBalance | money}}</td>
            <td>{{balances.constructionDrawn | money}}</td>
            <td class="totals">{{balances.constructionRemaining | money}}</td>
          </tr>
          <tr>
            <td class="offset">Off Platform</td>
            <td>{{balances.constructionStartingBalanceOffPlatform | money}}</td>
            <td>{{balances.constructionDrawnOffPlatform | money}}</td>
            <td class="totals">{{balances.constructionRemainingOffPlatform | money}}</td>
          </tr>
          <tr>
            <td>Interest</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="offset">On Platform</td>
            <td>{{balances.interestStartingBalance | money}}</td>
            <td>{{balances.interestDrawn | money}}</td>
            <td class="totals">{{balances.interestRemaining | money}}</td>
          </tr>
          <tr>
            <td class="offset">Off Platform</td>
            <td>{{balances.interestStartingBalanceOffPlatform | money}}</td>
            <td>{{balances.interestDrawnOffPlatform | money}}</td>
            <td class="totals">{{balances.interestRemainingOffPlatform | money}}</td>
          </tr>
          <tr>
            <td>Escrow</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="offset">On Platform</td>
            <td>{{balances.escrowStartingBalance | money}}</td>
            <td>{{balances.escrowDrawn | money}}</td>
            <td class="totals">{{balances.escrowRemaining | money}}</td>
          </tr>
          <tr>
            <td class="offset">Off Platform</td>
            <td>{{balances.escrowStartingBalanceOffPlatform | money}}</td>
            <td>{{balances.escrowDrawnOffPlatform | money}}</td>
            <td class="totals">{{balances.escrowRemainingOffPlatform | money}}</td>
          </tr>
          <tr>
            <td>Other</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="offset">On Platform</td>
            <td>{{balances.otherStartingBalance | money}}</td>
            <td>{{balances.otherDrawn | money}}</td>
            <td class="totals">{{balances.otherRemaining | money}}</td>
          </tr>
          <tr>
            <td class="offset">Off Platform</td>
            <td>{{balances.otherStartingBalanceOffPlatform | money}}</td>
            <td>{{balances.otherDrawnOffPlatform | money}}</td>
            <td class="totals">{{balances.otherRemainingOffPlatform | money}}</td>
          </tr>
          <tr class="summary totals">
            <td>Totals</td>
            <td>{{balances.totalStartingHoldbacks | money}}</td>
            <td>{{balances.totalHoldbacksDrawn | money}}</td>
            <td>{{balances.totalHoldbacksRemaining | money}}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="col-6 receivable-and-earnings">
        <h4 class="header">Receivables</h4>
        <table class="table">
          <thead>
          <tr>
            <th class="date"></th>
            <th></th>
            <th></th>
            <th>Balance</th>
          </tr>
          </thead>
          <tbody>
          <tr class="summary">
            <td class="totals">Principal</td>
            <td></td>
            <td></td>
            <td class="totals">{{balances.principalReceivable | accounting}}</td>
          </tr>

          <tr class="summary">
            <td class="totals">Interest</td>
            <td></td>
            <td></td>
            <td class="totals">{{balances.interestReceivable | accounting}}</td>
          </tr>
          </tbody>
        </table>

        <h4 class="header">Earnings</h4>
        <table class="table">
          <thead>
          <tr>
            <th class="date"></th>
            <th></th>
            <th></th>
            <th>Balance</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Inspection Fees</td>
            <td></td>
            <td></td>
            <td class="totals">{{balances.inspectionFees | accounting}}</td>
          </tr>
          <tr>
            <td>Wire Fees</td>
            <td></td>
            <td></td>
            <td class="totals">{{balances.wireFees | accounting}}</td>
          </tr>
          <tr>
            <td>Setup Fees</td>
            <td></td>
            <td></td>
            <td class="totals">{{balances.setupFees | accounting}}</td>
          </tr>
          <tr>
            <td>Other Fees</td>
            <td></td>
            <td></td>
            <td class="totals">{{balances.otherFees | accounting}}</td>
          </tr>
          <tr class="summary totals">
            <td>Totals</td>
            <td></td>
            <td></td>
            <td>{{balances.totalFees | accounting}}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>

    <div class="entries">
      <div class="row">
        <div class="col-6">
          <h4 class="header">Transactions</h4>
        </div>
        <div class="col-6 right">
          <button v-if="canCreateTransaction" class="btn-action-cta" @click="openModal()">New Transaction</button>
        </div>
      </div>
      <ul class="transactions-header">
        <li class="transactions-date">Date</li>
        <li class="transactions-id">Entry ID</li>
        <li class="transactions-type">Entry</li>
        <li class="transactions-note">Note</li>
        <li class="transactions-account">Account<span>Amount</span></li>
      </ul>
      <ul v-for="entry in entries" :key="entry.id" class="transactions-item">
        <li class="transactions-date">{{entry.date | formatDate}}</li>
        <li class="transactions-id">{{entry.id}}</li>
        <li class="transactions-type">{{entry.type}}</li>
        <li class="transactions-note"><note-icon v-if="entry.comment" v-bind:tooltip="entry.comment" /></li>
        <li class="transactions-account">
          <table>
            <tbody>
              <tr v-for="record in entry.amounts" :key="record.description">
                <td>{{record.description}}</td>
                <td class="transactions-detailed-total">{{ record.amount | accounting }}</td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
    </div>
    <transaction-modal :show="showModal" @close="closeModal" @created="update" :balances="balances"
                       :loan="loan"></transaction-modal>
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";

import AjaxService from "services/ajax_service";
import TransactionModal from "components/transaction/modal.vue";
import LenderTagsMixin from "../../mixins/manage/lenders/tags";

export default {
  components: { TransactionModal },
  mixins: [LenderTagsMixin],
  data: function() {
    return {
      policy: PSData.loanPolicy || {},
      loan: PSData.loan || {},
      loading: false,
      showModal: false,
      entries: PSData.entries,
      balances: PSData.balances,
    };
  },
  computed: {
    canCreateTransaction() {
      return this.policy.createTransaction.allowed;
    },
    shouldRender() {
      return !isEmpty(this.entries) && !isEmpty(this.balances);
    },
  },
  methods: {
    update({ entries, balances }) {
      this.entries = entries;
      this.balances = balances;
    },
    closeModal() {
      this.showModal = false;
    },
    openModal() {
      this.showModal = true;
    },
  },
};
</script>
