<template>
  <div v-if="borrower">
    <view-panel class="loan-details-view-panel">
      <template slot="header">
        Details
      </template>
      <div class="row loan-details-view-panel-row">
        <div class="col-6 loan-details-view-panel-column">
          <details-list :data="detailsRows1" />
          <div class="view-panel-field">
            <div class="view-panel-label vesting-clause">
              Vesting Clause
              <vesting-clause-tooltip />
            </div>
            <div class="view-panel-value">{{ vestingClause }}</div>
          </div>
          <div class="view-panel-field">
            <div class="view-panel-label">Legal Name</div>
            <div class="view-panel-value">{{ legalName }}</div>
          </div>
        </div>
        <div class="col-6 loan-details-view-panel-column">
          <details-list :data="detailsRows2" />
        </div>
      </div>
    </view-panel>
    <view-panel v-if="showBorrowerFinancials" class="loan-details-view-panel">
      <template slot="header">
        Borrower Financials
      </template>
      <div class="row loan-details-view-panel-row">
        <div class="col-6 loan-details-view-panel-column">
          <details-list :data="financialsRows" />
        </div>
      </div>
    </view-panel>
    <view-panel class="loan-details-view-panel">
      <template slot="header">
        Borrower Background Summary
      </template>
      <div class="row loan-details-view-panel-row">
        <div class="col-6 loan-details-view-panel-column">
          <details-list :data="summaryRows1" />
        </div>
        <div class="col-6 loan-details-view-panel-column">
          <details-list :data="summaryRows2" />
        </div>
      </div>
    </view-panel>
    <view-panel class="loan-details-view-panel" v-if="borrower.supportHMDA">
      <template slot="header">
        Demographic Information
      </template>
      <div class="row loan-details-view-panel-row">
        <div class="col-6 loan-details-view-panel-column pb-4" v-for="group in summaryHMDAData" :key="group.groupId">
          <details-list :data="group.data" class="hmda-list" />
        </div>
      </div>
    </view-panel>
    <background-review-view :party="borrower" />
  </div>
</template>

<script>
import ViewPanel from "components/view_panel.vue";
import Borrower from "models/borrower";
import DetailsList from "components/loan_details/list";
import BorrowerMixin from "../borrower_mixin";
import BackgroundReviewView from "components/shared/background_review/view";
import VestingClauseTooltip from "../vesting_clause_tooltip.vue";

export default {
  name: "loan-borrower-details",
  components: {
    ViewPanel,
    DetailsList,
    BackgroundReviewView,
    VestingClauseTooltip,
  },
  mixins: [BorrowerMixin],
  props: {
    borrower: {
      type: Borrower,
      required: true,
    },
  },
  data() {
    return {
      notEnoughInfoMessage: "Not enough information to generate",
    };
  },
  computed: {
    detailsRows1() {
      return [
        { label: "Name", value: this.borrower.name, show: true },
        { label: "Type", value: this.borrower.borrowerTypeLabel, show: true },
        {
          label: "Is borrower a single purpose entity?",
          value: this.borrower.singlePurposeEntityYNLabel,
          show: this.showSinglePurposeEntity,
        },
        { label: "Email", value: this.borrower.email, show: true },
        { label: "Phone", value: this.borrower.phone, show: true },
        {
          label: "Marital Status",
          value: this.borrower.maritalStatusLabel,
          show: this.showMaritalStatus,
        },
        {
          label: "Subject property is his/her sole and separate property",
          value: this.borrower.solePropertyLabel,
          show: this.showSoleProperty,
        },
        {
          label: "Formation State",
          value: this.borrower.entityStateLabel,
          show: this.showEntityState,
        },
        {
          label: "Secretary of State ID",
          value: this.borrower.secretaryOfStateId,
          show: this.showSecretaryOfStateId,
        },
      ];
    },
    detailsRows2() {
      return [
        { label: this.borrower.tinFieldLabel, value: this.borrower.tinLast4, show: true },
        { label: "Residency Status", value: this.borrower.residencyLabel, show: true },
        { label: "Gender", value: this.borrower.genderLabel, show: this.showGender },
        { label: "Date of Birth", value: this.borrower.dateOfBirth, show: this.showDateOfBirth },
        { label: "Mailing Address", value: this.borrower.fullAddress, show: true },
      ];
    },
    financialsRows() {
      return [
        { label: "Net Worth", value: this.borrower.netWorth, show: true },
        { label: "Net Worth as of Date", value: this.borrower.netWorthAsOfDate, show: true },
        { label: "Liquidity", value: this.borrower.liquidity, show: true },
        { label: "Liquidity as of Date", value: this.borrower.liquidityAsOfDate, show: true },
      ];
    },
    summaryRows1() {
      return [
        { label: "FICO", value: this.borrower.fico, show: true },
        { label: "Track Record", value: this.borrower.guarantorTrackRecordLabel, show: true },
        { label: "Reserve Ratio", value: this.borrower.reserveRatio, show: this.showReserveRatio },
      ];
    },
    summaryRows2() {
      return [
        { label: "Personal Guaranty", value: this.borrower.personalGuarantyLabel, show: true },
      ];
    },
    summaryHMDAData() {
      return PSData.hmdaInfoOptionGroups.map(group => {
        const data = this.borrower.hmdaInfoGroups.find(info => info.groupId === group.id) || {};
        return {
          groupId: `group_${group.id}`,
          data: [
            {
              label: group.groupName,
              value: this.groupAnswers(group, data),
              show: true,
            },
            {
              label: "Based on Visual Observation or Surname",
              value: this.isBasedOnObservationValue(data.isBasedOnObservation),
              show: true,
            },
          ],
        };
      });
    },
    vestingClause() {
      return this.borrower.vestingClause || this.notEnoughInfoMessage;
    },
    legalName() {
      return this.borrower.legalName || this.notEnoughInfoMessage;
    },
  },
  methods: {
    groupAnswers(group, data) {
      const answers = (data.hmdaInfos || []).reduce((result, info) => {
        const option = group.options.find(optionData => optionData.id === info.optionId);

        if (option.parentOptionId) {
          const parentOption = group.options.find(
            optionData => optionData.id === option.parentOptionId
          );
          const freeFormValue = info.freeformValue ? ` - ${info.freeformValue}` : "";
          result[option.id] = `${parentOption.optionName} - ${option.optionName}${freeFormValue}`;
          delete result[parentOption.id];
        } else {
          result[option.id] = option.optionName + (result[option.id] || "");
        }

        return result;
      }, {});

      return Object.values(answers).reduce((result, answer) => {
        result = (result && result + "\n") + answer;
        return result;
      }, "");
    },
    isBasedOnObservationValue(isBasedOnObservation) {
      if (isBasedOnObservation === true) {
        return "Yes";
      }
      if (isBasedOnObservation === false) {
        return "No";
      }

      return "";
    },
  },
};
</script>
<style scope>
.hmda-list .view-table-cell-value {
  white-space: pre-line;
}
</style>
