<template>
  <state-provider
    v-slot="{ batch,
              form,
              errors,
              isValidating,
              isSelling,
              validationPassed,
              handleReset,
              handleValidate,
              handleSell,
              handleFormUpdate }"
  >
    <div class="container-layout-vert-padding loan-sales">
      <h3 class="loan-sales-page-heading">
        Sales Automation
      </h3>

      <error-messages title="Error!" :errors="errors" />

      <div class="loan-sales-content-wrapper">
        <aside class="loan-sales-form">
          <h4 class="loan-sales-page-sub-heading">
            Add Loans
          </h4>

          <loan-sale-batch-form
            :disabled="isValidating || validationPassed"
            :form="form"
            @validate="handleValidate"
            @update="handleFormUpdate"
          />
        </aside>

        <loan-sale-detail
          :batch="batch"
          :form="form"
          :disabled="isSelling"
          @reset="handleReset"
          @sell="handleSell"
          @update="handleFormUpdate"
        />
      </div>
    </div>
  </state-provider>
</template>

<script>
import ErrorMessages from "components/shared/error_messages";
import LoanSaleBatchForm from "./form";
import LoanSaleDetail from "./detail";
import StateProvider from "./state_provider";

export default {
  name: "NewLoanSaleBatch",
  components: {
    StateProvider,
    LoanSaleBatchForm,
    LoanSaleDetail,
    ErrorMessages,
  },
};
</script>
