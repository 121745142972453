<template>
  <div>
    <p class="grecaptcha-disclaimer">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a>
      and
      <a href="https://policies.google.com/terms">Terms of Service</a>
      apply.
    </p>
    <button class="btn-primary-cta btn-block" type="submit" :disabled="submitDisabled">
      {{submitText}}
    </button>

    <div class="g-recaptcha"
         :data-sitekey="recaptchaKey"
         data-callback="_psOnRecaptchaSubmit"
         data-size="invisible"
         data-badge="bottomleft"></div>
  </div>
</template>

<script>
export default {
  name: "submit-with-recaptcha",
  data() {
    return {
      submitText: "Submit",
      submitDisabled: false,
      recaptchaKey: PSData.global.recaptchaSiteKey,
    };
  },
  mounted() {
    window._psOnRecaptchaSubmit = () => {
      this.disableButton();
      document.querySelector("form.validate").submit();
    };

    const script = document.createElement("script");
    script.setAttribute("async", "true");
    script.setAttribute("src", "https://www.google.com/recaptcha/api.js");
    this.$el.appendChild(script);
  },
  methods: {
    disableButton() {
      this.submitText = "Submitting...";
      this.submitDisabled = true;
    },
  },
};
</script>
