import Event from "models/event";

class FilePickerEvent extends Event {
  get metadata() {
    return this.options.metadata;
  }
}

export class FilePickerRequested extends FilePickerEvent {}
export class DropFileRequested extends FilePickerEvent {}

PS.Models.File.Events.Picker = {
  FilePickerRequested: FilePickerRequested,
  DropFileRequested: DropFileRequested,
};
