<template>
  <div class="details-row row no-gutters section-content">
    <section-header
      :editing="editing"
      :updating="updating"
      :subheading="subheading"
      :actions-hidden="actionsHidden"
      :disabled="disabled"
      v-on="$listeners"
    >
      <h4> {{ headerTitle }}</h4>
    </section-header>
    <div class="w-100" v-if="isApplicable">
      <slot />
    </div>
    <div v-else>
      <ul class="loan-block">
        <li>
          <span class="no-status-state not-applicable-section">Not applicable</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SectionHeader from "./section_header";

export default {
  name: "editable-section",
  components: {
    SectionHeader,
  },
  props: {
    headerTitle: {
      type: String,
      required: true,
    },
    subheading: {
      type: String,
      required: true,
    },
    actionsHidden: {
      type: Boolean,
      required: false,
    },
    editing: {
      type: Boolean,
    },
    updating: {
      type: Boolean,
    },
    isApplicable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
};
</script>
