<template>
  <shared-loan-tab :default-filters="filters">
    <template v-slot:filters="props">
      <entities-filter
        :current-user-id="currentUser.id"
        :initial-filter="filters.workflowAssigneeId"
        :labels="{ my_entities: 'My Workflows' }"
        :options="workflowUsersOptions"
        @filter="props.handleFilter('workflowAssigneeId', $event)"
      />
    </template>
    <template v-slot:table="props">
      <loans-table :loading="props.isSearching" :loans="props.loans" :workflow-assignee-id="props.filters.workflowAssigneeId" />
    </template>
  </shared-loan-tab>
</template>
<script>
import SharedLoanTab from "../loan_portfolio/shared_tab";
import LoansTable from "../loan_portfolio/table";
import EntitiesFilter from "../portfolio/entities_filter";

export default {
  components: {
    SharedLoanTab,
    EntitiesFilter,
    LoansTable,
  },
  data() {
    const currentUser = PSData.currentUser;
    return {
      filters: {
        workflowAssigneeId: currentUser.id,
      },
      currentUser,
    };
  },
  computed: {
    workflowUsers: () => PSData.workflowUsers,
    workflowUsersOptions() {
      return this.workflowUsers
        .filter(user => user.id !== this.currentUser.id)
        .map(user => [user.name, user.id]);
    },
  },
};
</script>
