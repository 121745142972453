<template>
  <span v-if="date" class="badge badge-success">{{ date | formatDate }}</span>
</template>
<script>
export default {
  props: {
    date: String,
  },
};
</script>
