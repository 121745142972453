import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";
import LoanSponsor from "./loan_sponsor";

export default class ResponsibleBorrower extends LoanSponsor {
  static deserialize(rawBorrower) {
    return new ResponsibleBorrower(rawBorrower);
  }

  get type() {
    return this.borrowerType;
  }

  set type(value) {
    this.borrowerType === value;
  }

  update(borrower) {
    this._raw = borrower;
    this.id = borrower.id;
    this.name = borrower.name;
    this.borrowerType = borrower.borrowerType;
    this.tin = borrower.tin;
    this.residency = borrower.residency;
  }

  toPlainObject({ toSnakeCase } = { toSnakeCase: false }) {
    const borrower = {
      id: this.id,
      name: this.name,
      borrowerType: this.borrowerType,
      tin: (this.tin || "").replace(/[^\d]/g, ""),
      residency: this.residency,
    };

    if (toSnakeCase) return mapKeys(borrower, (_, key) => snakeCase(key));

    return borrower;
  }
}
