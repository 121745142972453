const Event = PS.Models.Event;

class FieldEvent extends Event {
  prepareData(options) {
    this.field = options.field;
    this.fieldset = options.fieldset;
    this.document = options.document;
  }
}

class ValueChanged extends FieldEvent {
  prepareData(options) {
    super.prepareData(options);

    this.value = this.field.valueToServer();
  }
}

class NoteChanged extends FieldEvent {
  prepareData(options) {
    super.prepareData(options);

    this.note = this.field.note ? this.field.note.trim() : "";
  }
}

class ValidationChanged extends FieldEvent {
  prepareData(options) {
    super.prepareData(options);

    this.validatedAt = this.field.validated ? new Date().toISOString() : null;
  }
}

class FieldEnableRequested extends FieldEvent {}

class FieldDisableRequested extends FieldEvent {}

class ToggleDisabledFieldsRequested extends FieldEvent {}

class FormulaRecalculationRequested extends FieldEvent {}

class ValueResendingRequested extends FieldEvent {}

class FieldValueClarified extends FieldEvent {}

class ValueChangeRequested extends Event {
  prepareData(options) {
    this.value = options.value;
  }
}

PS.Models.Diligence.Events.Field = {
  ValueChanged: ValueChanged,
  NoteChanged: NoteChanged,
  ValidationChanged: ValidationChanged,
  FormulaRecalculationRequested: FormulaRecalculationRequested,
  ValueResendingRequested: ValueResendingRequested,
  FieldValueClarified: FieldValueClarified,
  ValueChangeRequested: ValueChangeRequested,
  FieldEnableRequested: FieldEnableRequested,
  FieldDisableRequested: FieldDisableRequested,
  ToggleDisabledFieldsRequested: ToggleDisabledFieldsRequested,
};
