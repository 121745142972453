<template>
  <div>
    <h3>Transactions</h3>

    <table class="table">
      <thead>
        <tr>
          <th class="text-left">
            Transaction ID
          </th>
          <th class="text-right">
            Loan ID
          </th>
          <th class="text-left">
            Owner
          </th>
          <th class="text-right">
            Check Date
          </th>
          <th class="text-right">
            Period Start
          </th>
          <th class="text-right">
            Period End
          </th>
          <th class="text-right">
            Principal
          </th>
          <th class="text-right">
            Supplemental Payment
          </th>
          <th class="text-right">
            Interest
          </th>
          <th class="text-right">
            Default Interest
          </th>
          <th class="text-right">
            Deferred Interest
          </th>
          <th class="text-right">
            Fees
          </th>
          <th class="text-right">
            Review / Reclassify
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="item in itemsWithGroupedDistributions">
          <tr v-for="(owner, i) in Object.keys(item.distributionGroups)" :key="`${item.id}-${owner}`">
            <td v-if="i == 0" class="text-left" :rowspan="getRowSize(item)">
              {{ item.id }}
              <span v-if="isReversed(item)" class="badge badge-danger">REVERSED</span>
            </td>

            <td v-if="i == 0" class="text-right" :rowspan="getRowSize(item)">
              <a :href="`/manage/loans/${item.loan.id}`">
                {{ item.loan.id }}
                <span v-if="isModified(item)" class="badge badge-warning float-left">MODIFIED</span>
              </a>
            </td>

            <td class="text-left">
              {{ owner }} <span v-if="isDistributed(item, owner)" class="badge badge-success">DIST</span>
            </td>

            <td class="text-right">
              {{ item.cashReceipt.receivedDate | formatDate }}
            </td>

            <td class="text-right">
              {{ item.periodStartDate | formatDate }}
            </td>
            <td class="text-right">
              {{ item.periodEndDate | formatDate }}
            </td>
            <td class="text-right">
              {{ getTotal(item, owner, "principal") | accounting }}
            </td>
            <td class="text-right">
              {{ getTotal(item, owner, "supplemental_payment") | accounting }}
            </td>
            <td class="text-right">
              {{ getTotalForDisbursementTypes(item.distributionGroups[owner], ["interest", "prepaid_interest"]) | accounting }}
            </td>
            <td class="text-right">
              {{ getTotal(item, owner, "default_interest") | accounting }}
            </td>
            <td class="text-right">
              {{ getTotal(item, owner, "deferred_interest") | accounting }}
            </td>
            <td class="text-right">
              <a v-if="getTotal(item, owner, 'fee') != 0" href="#" @click.prevent="showFeesDetailsPopup(item, owner)">
                {{ getTotal(item, owner, "fee") | accounting }}
              </a>
              <span v-else>{{ getTotal(item, owner, "fee") | accounting }}</span>
            </td>
            <td v-if="i == 0" class="text-right" :rowspan="getRowSize(item)">
              <div class="align-top">
                <a href="#" @click.prevent="$emit('review', item)">
                  <icon icon="edit" />
                </a>
                <template v-if="!anyDistributed(item)">
                  <base-dropdown position="left">
                    <template #trigger>
                      <base-icon icon="ellipsis-h" icon-pack="fas" size="is-small" />
                    </template>
                    <base-dropdown-item
                      aria-role="menu-item"
                      :focusable="false"
                      custom
                      paddingless
                    >
                      <div class="p-2">
                        <router-link :to="{ name: 'reallocate_amounts', params: { id: item.id }}">Reallocate Amounts</router-link>
                      </div>
                    </base-dropdown-item>
                  </base-dropdown>
                </template>
                <template v-else>
                  <template v-if="!isReversed(item)">
                    <base-dropdown position="left">
                      <template #trigger>
                        <base-icon icon="ellipsis-h" icon-pack="fas" size="is-small" />
                      </template>
                      <b-dropdown-item aria-role="listitem" close-on-click @click="toggleReverseTransactionModal(item)">
                        Reverse Transaction
                      </b-dropdown-item>
                    </base-dropdown>
                  </template>
                </template>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <fees-details-popup
      v-if="isFeesDetailsPopupShown"
      :item="currentItem"
      :owner="currentOwner"
      @close="hideFeesDetailsPopup"
    />

    <reverse-transaction-modal
      v-if="showReverseModal"
      :item="reverseItem"
      @close="toggleReverseTransactionModal"
    />
  </div>
</template>

<script>
import map from "lodash/map";
import flatMap from "lodash/flatMap";
import groupBy from "lodash/groupBy";
import sumBy from "lodash/sumBy";

import Icon from "components/shared/icon";
import FeesDetailsPopup from "./fees_details";
import { getTotalForDisbursementTypes } from "../../shared/calculation_helper";
import ReverseTransactionModal from "./reverse_transaction_modal";
import { isReversed } from "../../shared/is_reversed";

export default {
  components: {
    Icon,
    FeesDetailsPopup,
    ReverseTransactionModal,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isFeesDetailsPopupShown: false,
      reverseItem: null,
      showReverseModal: false,
      currentItem: undefined,
      currentOwner: undefined,
    };
  },
  computed: {
    itemsWithGroupedDistributions() {
      return map(this.items, item => this.groupByTransactionOwner(item));
    },
  },
  methods: {
    getTotalForDisbursementTypes,
    isReversed,
    showFeesDetailsPopup(item, owner) {
      this.isFeesDetailsPopupShown = true;
      this.currentItem = item;
      this.currentOwner = owner;
    },
    hideFeesDetailsPopup() {
      this.isFeesDetailsPopupShown = false;
      this.currentItem = null;
      this.currentOwner = null;
    },
    toggleReverseTransactionModal(item) {
      this.reverseItem = item;
      this.showReverseModal = !this.showReverseModal;
    },
    groupByTransactionOwner(item) {
      const distributions = flatMap(item.details, "distributions");
      const distributionGroups = groupBy(
        distributions,
        ({ legalOwner, lenderName, participantType }) => {
          if (participantType === "lender") {
            return lenderName;
          }

          if (participantType === "master_servicer" || !legalOwner) {
            return "PS Funding Inc";
          }

          if (legalOwner.name === "ps_funding_inc") {
            return "Retail";
          }

          return legalOwner.label;
        }
      );

      return {
        ...item,
        distributionGroups,
      };
    },
    getTotal(item, owner, targetDisbursementType) {
      const distributions = item.distributionGroups[owner];

      return sumBy(
        distributions,
        ({ amount, disbursementType }) => (disbursementType === targetDisbursementType ? amount : 0)
      );
    },
    getRowSize(item) {
      return Object.keys(item.distributionGroups).length;
    },
    isModified(item) {
      return item.details.some(detail => {
        return detail.distributions.some(
          distribution => distribution.creationType === "reallocated"
        );
      });
    },
    isDistributed(item, owner) {
      const distributions = item.distributionGroups[owner];

      return distributions.some(d => d.status === "distributed");
    },
    anyDistributed(item) {
      const values = Object.values(item.distributionGroups).flat();
      return values.some(d => d.status === "distributed");
    },
  },
};
</script>
