const Command = PS.Models.Command;
const Events = PS.Models.Diligence.Events;

class StartLoading extends Command {
  execute() {
    this.state.loading = true;
    this.state.report = undefined;

    return Promise.resolve();
  }
}

class LoadFromServer extends Command {
  execute() {
    return PS.Services.DiligenceReportService.get(this.state.loan).then(report => {
      this.state.report = new PS.Models.Diligence.Report(report);
    });
  }
}

class EndLoading extends Command {
  execute() {
    this.state.loading = false;

    return Promise.resolve();
  }
}

class ReloadReport extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Report.ReloadReportRequested,
      PS.Services.MessageBusService.diligenceBus,
      {}
    );
  }
}

class UpdateMLD extends Command {
  execute() {
    return PS.Services.DiligenceReportService.update(this.state.loan, this.state.corrections)
      .then(() => {
        this.state.errors = [];
      })
      .catch(({ data }) => {
        this.state.errors = data.errors;
      });
  }
}

class ResetReport extends Command {
  execute() {
    this.state.corrections = {};

    return Promise.resolve();
  }
}

class RequestUpdateMLD extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Report.UpdateMLDRequested,
      PS.Services.MessageBusService.diligenceBus,
      {}
    );
  }
}

class RequestValueChange extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Report.ChangeValueRequested,
      PS.Services.MessageBusService.diligenceBus,
      {
        value: this.state.value,
        elementName: this.state.elementName,
      }
    );
  }
}

class SaveCorrection extends Command {
  execute() {
    this.state.corrections[this.event.elementName] = this.event.value;

    return Promise.resolve();
  }
}

PS.Models.Diligence.Commands.Report = {
  StartLoading: StartLoading,
  LoadFromServer: LoadFromServer,
  EndLoading: EndLoading,
  ReloadReport: ReloadReport,
  SaveCorrection: SaveCorrection,
  RequestValueChange: RequestValueChange,
  UpdateMLD: UpdateMLD,
  ResetReport: ResetReport,
  RequestUpdateMLD: RequestUpdateMLD,
};
