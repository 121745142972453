const SIGNED_OUT_COOKIE_NAME = "ps_just_signed_out";

const EVENTS = {
  clickAMEmailLink: "Send AM Email Link",
  searchMsaReport: "Searched Msa Report",
  searchMsaReportLink: "Searched MSA Report Link",
  searchMsaReportBanner: "Searched MSA Report Image",
  searchMarketDataReportLink: "Searched Market Data Link",
  searchMarketDataReportBanner: "Searched Market Data Image",
  clickedCreditBoxEvaluateButton: "Clicked Credit Box Evaluate Button",
  editedEmailPreferences: "Edited Email Preferences",
  clickedEmailPreferences: "Clicked Email Preferences",
  clickedActiveFundedSubnavs: "Clicked Active/Funded Subnavs",
  clickedRequestsDropdownOption: "Clicked Requests Dropdown Option",
  clickedRequestsDropdown: "Clicked Requests Dropdown",
  clickedActiveFundedFilters: "Clicked Active/Funded Filters",
  clickedActiveFundedTab: "Clicked Active/Funded Tab",
  clickedWithdrawnDeclinedTab: "Clicked Withdrawn/Declined Tab",
  clickedSubmittedDateDropdown: "Clicked Submitted Date Dropdown on Withdrawn/Declined",
  clickedLoanOnWithdrawnDeclined: "Clicked Loan on Withdrawn/Declined",
  dropToTaskList: "File Upload / Drop to task list",
  dropToTaskDetails: "File Upload / Drop to task details",
  attachFile: "File Upload / Attach file",
  associateFile: "File Upload / Associate file",
};

class SegmentService {
  static deleteSignedOutCookie() {
    document.cookie = `${SIGNED_OUT_COOKIE_NAME}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  static load() {
    if (!window.analytics) {
      return;
    }

    window.analytics.load(PSData.global.segmentApiKey);
    window.analytics.ready(this.onReadyCallback);
    if (!PSData.page.disableAutoTracking) {
      window.analytics.page(PSData.page.title);
    }
  }

  static onReadyCallback() {
    let justSignedOut = document.cookie.indexOf(SIGNED_OUT_COOKIE_NAME) > -1;

    if (PSData.user.signedIn === "yes") {
      window.analytics.identify(PSData.user.id, PSData.user.properties);
    } else if (justSignedOut) {
      // Synchronicity issue between Intercom and ready()
      // ready() does not wait until Intercom object is fully loaded
      // setTimeout keeps shutdown off execution stack until loaded
      setTimeout(() => window.Intercom("shutdown"), 0);
    }

    SegmentService.deleteSignedOutCookie();
  }

  static track(event, properties) {
    if (!EVENTS[event]) {
      throw {
        name: "Segment Analytics Error",
        message: "event is not defined",
      };
    }

    window.analytics.track(
      EVENTS[event],
      Object.assign(
        {
          user: PSData.user.id,
          event: EVENTS[event],
        },
        properties
      )
    );
  }

  static page(name, properties = {}) {
    const details = {
      url: window.location.href,
      path: window.location.pathname + window.location.hash,
      ...properties,
    };
    window.analytics.page(name, details);
  }
}

window.PS.Services.SegmentService = SegmentService;
export default SegmentService;
