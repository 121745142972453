class Report {
  constructor(data) {
    this.sections = (data.documents || []).map(item => {
      return this.buildSection(item);
    });
  }

  buildSection(data) {
    var section = {};
    section.documentType = data.documentType;
    section.label = data.label;
    section.elements = (data.elements || []).map(item => {
      return this.buildElement(item);
    });

    return section;
  }

  buildElement(data) {
    if (data.as === "fieldset") {
      return new PS.Models.Diligence.FieldsetElement(data);
    } else {
      return new PS.Models.Diligence.ReportElement(data);
    }
  }
}

PS.Models.Diligence = PS.Models.Diligence || {};
PS.Models.Diligence.Report = Report;
