<template>
  <div>
    <base-checkbox
      v-model="input.value.value"
      :native-value="input.name"
      :disabled="readOnly"
    >
      {{ input.name }}
    </base-checkbox>
  </div>
</template>

<script>
import Input from "models/workflow_engine/input";

// import VariableComment from "../variable_comment";

export default Vue.extend({
  name: "checkbox-input-component",
  components: {
    // VariableComment,
  },
  props: {
    input: {
      type: Input,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.input.value = { value: this.input.value.value };
  },
});
</script>
