<template>
  <div class="row">
    <div class="col-sm-12">
      <h4 class="my-4">
        Approved Transactions
        <icon v-if="loading" icon="spinner" spin />
        <span v-else>({{ total }})</span>
      </h4>
    </div>

    <div class="col-sm-2 bg-light py-4">
      <filters v-bind="filters" @filter="$listeners.filter" />
    </div>

    <div v-if="loading" class="col-sm-10">
      <base-loading :show="true" :is-full-page="false"/>
    </div>

    <div v-else class="col-sm-10">
      <base-message v-if="invalidItems.length" title="Invalid Disbursements" type="danger">
        {{ invalidItems }}
      </base-message>
      <div v-if="items.length > 0">
        <overview-header
          :items="items"
          :total="total"
          :filters="filters"
          @clear-loan-id-filter="clearLoanId"
          @approve="$listeners.approve"
          @distribute="$listeners.distribute"
        />
        <overview-list
          :legal-owners="legalOwners"
          :grouped-items="groupedItems"
          :total="total"
          @show="$listeners.show"
        />
      </div>

      <empty
        v-if="!loading && items.length === 0"
        text="No Transactions Found"
      />
    </div>
  </div>
</template>

<script>
import flatMap from "lodash/flatMap";
import uniqBy from "lodash/uniqBy";

import Empty from "components/shared/empty";
import Icon from "components/shared/icon";
import OverviewHeader from "./header";
import OverviewList from "./list";
import Filters from "../filters";

export default {
  components: {
    OverviewHeader,
    Filters,
    OverviewList,
    Empty,
    Icon,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    legalOwners: {
      type: Array,
      required: true,
    },
    groupedItems: {
      type: Object,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    invalidItems: {
      type: String,
      default: () => "",
    },
    loading: Boolean,
  },
  computed: {
    items() {
      return uniqBy(flatMap(this.groupedItems), "id");
    },
  },
  methods: {
    clearLoanId() {
      this.$emit("filter", { ...this.filters, loanId: "" });
    },
  },
};
</script>
