export default class BaseScenario {
  _isValuePresent(value) {
    if (typeof value === "number") {
      return !isNaN(value);
    } else {
      return ["", null, undefined].every(invalidValue => invalidValue !== value);
    }
  }

  hasValidProperties(...propertyNames) {
    return propertyNames.every(propertyName => this._validationMap[propertyName]() === true);
  }

  validateScenarioRequest() {
    return this.hasValidProperties(...Object.keys(this._validationMap));
  }
}
