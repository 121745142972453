export default function runningSum(monthlyStats, monthProperty = "month") {
  if (!monthlyStats.length) return [];

  const summable = Object.keys(monthlyStats[0]).filter(k => k !== monthProperty);

  let current = {};
  for (let key of summable) {
    current[key] = 0;
  }

  return monthlyStats.reduce((result, stats) => {
    let running = Object.assign({}, current, { [monthProperty]: stats[monthProperty] });

    for (let key of summable) {
      running[key] += parseFloat(stats[key]);
    }

    result.push(running);
    Object.assign(current, running);

    return result;
  }, []);
}
