export const MANAGE_LOAN_TAPES_BATCHES_ROUTE = "/manage/settings/loan_tapes_batches";

export default class LoanTapesBatch {
  constructor(record = {}) {
    this.update(record);
  }

  get objectToSave() {
    return {
      id: this.id,
      archived: this.archived,
      lenderId: this.lenderId,
      sellingChannel: this.sellingChannel,
    };
  }

  get batchURL() {
    return `${MANAGE_LOAN_TAPES_BATCHES_ROUTE}/${this.id}`;
  }

  update(record) {
    this._raw = record;
    Object.assign(this, record);
  }
}
