<template>
  <div class="last-request-popover-container" v-if="request">
    <span @click.stop tabindex=-1 v-popover="{'container': '#bridge-loans-list'}" class="with-field-tooltip">
      {{ request.requestDays }}d Ago
    </span>
    <div class="popover-content hidden">
      <p><strong>Requests Type:</strong> {{ request.requestTypeText }}</p>
      <p><strong>Requests Date:</strong> {{ request.creationDate }}</p>
      <p v-if="request.requestDetails && request.requestDetails.desiredPayoffDate">
        <strong>Desired Payoff Date:</strong> {{ request.requestDetails.desiredPayoffDate }}
      </p>
      <p><strong>Requester:</strong> {{ request.requester.name }}</p>
      <a :href="lastRequestPath">View Request Details</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "last-request-popover",
  props: {
    request: Object,
    lastRequestPath: String,
  },
};
</script>
