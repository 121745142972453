<template>
  <div v-if="results.length" class="credit-box-info">
    <template v-if="newLoanForm">
      <div class="results-toggler" role="button" data-toggle="collapse" :data-target="'#creditbox-option' + optionId" aria-expanded="true" aria-controls="collapseResults">
        <span>Credit Box Advisor</span>
        <i class="fas fa-caret-down" />
        <i class="fas fa-caret-up" />
      </div>

      <div class="collapse show" :id="'creditbox-option' + optionId">
        <credit-box-results :results="results" :show-mask="showMask" :view-lps-results="viewLpsResults"/>
      </div>
    </template>

    <template v-else>
      <credit-box-results v-show="hasIssues" :results="issues" :view-lps-results="viewLpsResults">
        <h5>Rule Violations</h5>
      </credit-box-results>

      <credit-box-results v-show="hasAdjustments && hasIssues" :results="adjustments" :view-lps-results="viewLpsResults">
        <h5>Rule Adjustments</h5>
      </credit-box-results>
    </template>

    <p v-if="draftResults" class="credit-box-rules-note" :class="{ 'submition-note': newLoanForm }">
      <template v-if="newLoanForm">
        <span>Note:</span> You can submit your loan regardless of credit box fit.
      </template>

      <template v-else>
        <span>Note:</span> You can submit your loan regardless of credit box feedback.
        Please reach out to your relationship manager with any questions.
      </template>
    </p>
  </div>
</template>

<script>
import {
  prepareRules,
  uniqResults,
  hasIssues,
  hasAdjustments,
  uniqIssues,
  uniqAdjustments,
} from "models/credit_box/credit_box_rules";
import CreditBoxResults from "components/credit_box/credit_box_results";

const MAX_RESULTS = 4;

export default {
  components: {
    CreditBoxResults,
  },
  props: {
    creditBoxResults: Array,
    newLoanForm: {
      type: Boolean,
      default: false,
    },
    optionId: Number,
    draftResults: {
      type: Boolean,
      default: false,
    },
    viewLpsResults: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    results() {
      return uniqResults(prepareRules(this.creditBoxResults));
    },
    hasIssues() {
      return hasIssues(this.results);
    },
    hasAdjustments() {
      return hasAdjustments(this.results);
    },
    issues() {
      return uniqIssues(this.results);
    },
    adjustments() {
      return uniqAdjustments(this.results);
    },
    showMask() {
      return this.results.length > MAX_RESULTS;
    },
  },
};
</script>
