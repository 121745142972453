<template>
  <div class="form-group">
    <label>{{ label }}</label>
    <date-picker
      v-if="!readonly"
      input-class-name="form-control"
      :value="value"
      :options="{ static: false, dateFormat: 'm/d/Y', allowInput: true }"
      @click.native.stop
      @input="handleInput"
    />
    <div v-else>
      {{ value }}
    </div>
  </div>
</template>

<script>
import DatePicker from "components/shared/date_picker";

export default {
  components: {
    DatePicker,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    readonly: Boolean,
  },
  methods: {
    handleInput(value) {
      this.$emit("filter", this.name, value);
    },
  },
};
</script>
