<template>
  <div>
    <h3>
      Review / Reclassify Transaction
    </h3>

    <form>
      <table class="table">
        <tbody>
          <tr style="border-top:hidden;">
            <td class="font-weight-bold">Transaction ID</td>
            <td class="text-right">
              {{ item.id }}
            </td>
          </tr>

          <tr>
            <td class="font-weight-bold">Loan ID</td>
            <td class="text-right">
              <a :href="`/manage/loans/${item.loan.id}`">
                {{ item.loan.id }}
              </a>
            </td>
          </tr>

          <tr>
            <td class="font-weight-bold">Date Received</td>
            <td class="text-right">
              <date-field
                :value="item.cashReceipt.receivedDate"
                plaintext
                name="receivedDate"
              />
            </td>
          </tr>

          <tr>
            <td class="font-weight-bold">Date Due</td>
            <td class="text-right">
              <date-field
                :value="item.paymentDueDate"
                plaintext
                name="paymentDueDate"
              />
            </td>
          </tr>

          <tr>
            <td class="font-weight-bold">Accrued Interest</td>
            <td class="text-right">
              <accrued-interest-field
                :from="item.periodStartDate"
                :to="item.periodEndDate"
                :initial-value="item.accrualInterest"
                :loan-id="item.loan.id"
                :refresh-on-mount="refreshAccruedInterest"
              />
            </td>
          </tr>

          <tr>
            <td class="font-weight-bold">Effective Date</td>
            <td class="text-right">
              <date-field
                :value="item.effectiveDate"
                plaintext
                name="effectiveDate"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="form-group row">
        <label class="offset-sm-3 col-sm-4  col-form-label">Original</label>
        <label class="col-sm-4 col-form-label">Reclassified</label>
      </div>

      <detail-field
        v-for="(detail,i) in item.details"
        :key="i"
        :ref="`details.${i}`"
        v-model="item.details[i]"
        :index="i"
        @delete="handleDelete(i)"
        @input="handleDetailUpdate"
      />

      <div class="form-group">
        <a href="#" @click.prevent="handleAddFee">
          + Add Fees
        </a>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">
          Uncategorized Fees
        </label>

        <amount-input
          name="uncategorizedAmount"
          :value="uncategorizedAmount"
          class="offset-sm-4 col-sm-4"
          readonly
        />
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">
          Total
        </label>

        <amount-input
          ref="totalOriginalAmount"
          v-validate="'required'"
          name="totalOriginalAmount"
          :value="totalOriginalAmount"
          class="col-sm-4"
          readonly
          data-vv-as="Original Amount"
        />

        <amount-input
          ref="totalRevisedAmount"
          v-validate="'required|confirmed:totalOriginalAmount'"
          name="totalRevisedAmount"
          :value="totalRevisedAmount"
          class="col-sm-4"
          readonly
          data-vv-as="Original Amount"
        />
      </div>

      <div class="form-group row pt-4">
        <div class="col-11 d-flex">
          <div class="spacer" />

          <button
            type="button"
            class="btn btn-link mx-1"
            @click="handleBack"
          >
            Cancel
          </button>

          <div v-if="useHoldActions">
            <button
              type="button"
              class="btn btn-outline-secondary mx-1"
              :disabled="isSaving"
              @click="handleOverride"
            >
              Override
            </button>

            <button
              type="button"
              class="btn btn-primary mx-1"
              :disabled="isSaving"
              @click="handleReprocess"
            >
              Reprocess
            </button>
          </div>

          <div v-else>
            <button
              v-if="item.status === 'pending'"
              type="button"
              class="btn btn-outline-secondary mx-1"
              :disabled="isSaving || !canUpdate"
              @click="handleUpdate"
            >
              Save and Close
            </button>

            <button
              type="button"
              class="btn btn-outline-secondary mx-1"
              :disabled="isSaving || !canReview || isDistributed(item)"
              @click="handleManualHold"
            >
              Mark as Hold
            </button>

            <button
              type="button"
              v-if="item.status === 'ready'"
              class="btn btn-primary mx-1"
              :disabled="isSaving || !canReview || isDistributed(item)"
              @click="handleMarkAsReviewed"
            >
              Regenerate
            </button>

            <button
              type="button"
              v-else
              class="btn btn-primary mx-1"
              :disabled="isSaving || !canReview || isDistributed(item)"
              @click="handleMarkAsReviewed"
            >
              Mark as Reviewed
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import moment from "moment";
import filter from "lodash/filter";
import find from "lodash/find";
import sumBy from "lodash/sumBy";
import forEach from "lodash/forEach";
import omit from "lodash/omit";

import DateField from "./date_field";
import DetailField from "./detail_field";
import AmountInput from "./amount_input";
import AccruedInterestField from "./accrued_interest_field";
import { isDistributed } from "../../shared/is_distributed";
import AjaxService, { ERROR_MESSAGE, ERROR_STATUS, SUCCESS_STATUS } from "services/ajax_service";

const formatDate = date => date && moment(date).format("YYYY-MM-DD");
const parseDate = date => date && moment(date).toDate();

export default {
  inject: ["$validator"],
  $_veeValidate: {
    validator: "new",
  },
  components: {
    DateField,
    DetailField,
    AmountInput,
    AccruedInterestField,
  },
  props: {
    refreshAccruedInterest: Boolean,
    useHoldActions: { type: Boolean, default: false },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    const {
      periodStartDate,
      periodEndDate,
      effectiveDate,
      paymentDueDate,
      details,
      cashReceipt,
      deferrals,
      isPayoff,
      reviewFlags,
    } = this.value;

    const supplementalPayments = () => {
      const data = filter(details, { disbursementType: "supplemental_payment" });
      if (data.length > 0 || !reviewFlags.includes("discounted")) return data;
      return [
        {
          disbursementType: "supplemental_payment",
          distributions: [],
          originalAmount: 0,
          revisedAmount: 0,
          periodStartDate: effectiveDate,
          periodEndDate: effectiveDate,
        },
      ];
    };

    const interests = filter(details, { disbursementType: "interest" });

    if (interests.length === 0) {
      interests.push({
        disbursementType: "interest",
        distributions: [],
        originalAmount: 0,
        revisedAmount: 0,
        periodStartDate,
        periodEndDate,
      });
    }

    const defaultInterests = filter(details, { disbursementType: "default_interest" });

    if (defaultInterests.length === 0) {
      defaultInterests.push({
        disbursementType: "default_interest",
        distributions: [],
        originalAmount: 0,
        revisedAmount: 0,
        periodStartDate,
        periodEndDate,
      });
    }

    const deferredInterests = filter(details, {
      disbursementType: "deferred_interest",
    });

    if (deferredInterests.length == 0 && isPayoff) {
      deferrals.forEach(({ startDate, endDate }) => {
        deferredInterests.push({
          disbursementType: "deferred_interest",
          distributions: [],
          originalAmount: 0,
          revisedAmount: 0,
          periodStartDate: startDate,
          periodEndDate: endDate,
        });
      });
    }

    const item = {
      ...this.value,

      cashReceipt: {
        ...cashReceipt,
        receivedDate: parseDate(cashReceipt.receivedDate),
      },

      periodStartDate: parseDate(periodStartDate),
      periodEndDate: parseDate(periodEndDate),
      effectiveDate: parseDate(effectiveDate),
      paymentDueDate: parseDate(paymentDueDate),
      details: [
        ...filter(details, { disbursementType: "principal" }),
        ...filter(details, { disbursementType: "prepaid_interest" }),
        ...supplementalPayments(),
        ...interests,
        ...defaultInterests,
        ...deferredInterests,
        ...filter(details, { disbursementType: "fee" }),
      ],
    };

    return {
      item,
      isSaving: false,
    };
  },
  computed: {
    canReview() {
      return this.formValid;
    },
    canUpdate() {
      const { formValid, errors } = this;
      return formValid || (errors.has("totalRevisedAmount") && errors.count() === 1);
    },
    formValid() {
      return !this.errors.any();
    },
    totalOriginalAmount() {
      return this.item.totalAmount;
    },
    totalRevisedAmount() {
      return +sumBy(
        filter(this.item.details, ({ deleted }) => !deleted),
        ({ revisedAmount }) => revisedAmount
      ).toFixed(2);
    },
    uncategorizedAmount() {
      return +(this.totalOriginalAmount - this.totalRevisedAmount).toFixed(2);
    },
    payload() {
      const payload = {
        effective_date: formatDate(this.item.effectiveDate),
        period_start_date: formatDate(this.item.periodStartDate),
        period_end_date: formatDate(this.item.periodEndDate),
      };

      payload.details = this.item.details.map(
        ({
          disbursementType,
          revisedAmount,
          feeType,
          periodStartDate,
          periodEndDate,
          deleted,
        }) => ({
          disbursement_type: disbursementType,
          amount: deleted ? 0 : revisedAmount,
          fee_type: feeType,
          period_start_date: periodStartDate,
          period_end_date: periodEndDate,
        })
      );

      return payload;
    },
  },
  watch: {
    item: {
      deep: true,
      handler(v) {
        this.$emit("input", v);
      },
    },
  },
  created() {
    this.$validator.extend("unique_fee_type", {
      validate: async feeType => {
        await this.$nextTick();

        const existing = filter(
          this.item.details,
          ({ disbursementType, feeType: existingFeeType, deleted }) =>
            disbursementType === "fee" && feeType === existingFeeType && !deleted
        );

        return existing.length < 2;
      },
      getMessage: field => `${field} must be unique`,
    });

    this.$validator.extend("not_zero", {
      validate: async v => {
        await this.$nextTick();

        return v != 0;
      },
      getMessage: field => `${field} cannot be zero`,
    });
  },
  methods: {
    handleDetailUpdate({ disbursementType, revisedAmount }) {
      if (disbursementType !== "supplemental_payment") {
        return;
      }

      const principalDetail = find(this.item.details, { disbursementType: "principal" });

      if (!principalDetail) {
        return;
      }

      principalDetail.revisedAmount = principalDetail.originalAmount - revisedAmount;
    },
    isDistributed,
    async handleMarkAsReviewed() {
      await this.$validator.validateAll();

      if (!this.canReview) {
        return;
      }

      this.isSaving = true;

      try {
        const modifiedItem = await AjaxService.patch(
          `/manage/cash_receipt_items/${this.item.id}/review`,
          this.payload,
          { "content-type": "application/json" }
        );

        if (this.item.status === "pending") {
          AjaxService.sendNotification("Transaction marked as reviewed.", SUCCESS_STATUS);
        } else {
          AjaxService.sendNotification("Transaction regenerated.", SUCCESS_STATUS);
        }

        this.item = modifiedItem;

        this.handleBack();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isSaving = false;
      }
    },
    async handleUpdate() {
      await this.$validator.validateAll();

      if (!this.canUpdate) {
        return;
      }

      this.isSaving = true;

      try {
        const modifiedItem = await AjaxService.patch(
          `/manage/cash_receipt_items/${this.item.id}`,
          this.payload,
          { "content-type": "application/json" }
        );
        this.item = modifiedItem;
        AjaxService.sendNotification("Transaction saved.", SUCCESS_STATUS);
        this.handleBack();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isSaving = false;
      }
    },
    async handleManualHold() {
      try {
        this.isSaving = true;
        const modifiedItem = await AjaxService.patch(
          `/manage/cash_receipt_items/${this.item.id}/manual_hold`,
          { "content-type": "application/json" }
        );
        this.item = modifiedItem;
        AjaxService.sendNotification("Transaction marked as hold.", SUCCESS_STATUS);
        this.handleBack();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isSaving = false;
      }
    },
    async handleOverride() {
      if (!confirm("Override transaction?")) {
        return;
      }

      this.isSaving = true;

      const headers = { "content-type": "application/json" };
      const body = { items: [this.item.id] };

      try {
        await AjaxService.patch("/manage/cash_receipt_items/override", body, headers);
        AjaxService.sendNotification("Transaction hold override successful.", SUCCESS_STATUS);
        this.handleBack();
      } catch (e) {
        AjaxService.sendNotification(e, ERROR_STATUS);
      } finally {
        this.isSaving = false;
      }
    },
    async handleReprocess() {
      this.isSaving = true;

      const headers = { "content-type": "application/json" };
      const body = { items: [this.item.id] };

      try {
        await AjaxService.patch("/manage/cash_receipt_items/reprocess", body, headers);
        AjaxService.sendNotification("Successfully reprocessed transaction.", SUCCESS_STATUS);
        this.handleBack();
      } catch (e) {
        AjaxService.sendNotification(e, ERROR_STATUS);
      } finally {
        this.isSaving = false;
      }
    },
    handleDelete(i) {
      const detail = this.item.details[i];

      if (detail.id) {
        this.item.details.splice(i, 1, { ...detail, deleted: true });
      } else {
        this.item.details.splice(i, 1);
      }
    },
    handleAddFee() {
      this.item.details = [...this.item.details, this.newDetail()];
    },
    handleError(error) {
      if (!error.response) {
        throw error;
      }

      const {
        response,
        data: { errors },
      } = error;

      if (response.status === 422) {
        this.handleServerValidationErrors(errors);
      } else {
        AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
      }
    },
    handleServerValidationErrors(errors) {
      forEach(omit(errors, "details"), (messages, field) =>
        forEach(messages, msg => this.errors.add({ msg, field }))
      );
    },
    handleBack() {
      this.$emit("back");
      location.reload();
    },
    newDetail() {
      return {
        id: null,
        disbursementType: "fee",
        distributions: [],
        feeType: null,
        originalAmount: 0,
        revisedAmount: this.uncategorizedAmount,
      };
    },
  },
};
</script>
