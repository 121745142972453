class PayoffStatementPolicy {
  constructor(payoffStatementPolicy) {
    this.payoffStatementPolicy = payoffStatementPolicy || {};
  }

  canModify() {
    return this.payoffStatementPolicy.modify.allowed;
  }

  canCreate() {
    return this.payoffStatementPolicy.create.allowed;
  }

  canDuplicate() {
    return this.payoffStatementPolicy.duplicate.allowed;
  }

  canUpdate() {
    return this.payoffStatementPolicy.update.allowed;
  }

  canCancel() {
    return this.payoffStatementPolicy.cancel.allowed;
  }

  canApprove() {
    return this.payoffStatementPolicy.approve.allowed;
  }

  canMarkAsSoldOrWired() {
    return this.payoffStatementPolicy.sale.allowed;
  }

  canPurchase() {
    return this.payoffStatementPolicy.purchase.allowed;
  }
}

export default PayoffStatementPolicy;
