<template>
  <base-modal-form
    :show="show"
    title="Edit Notes"
    @close="onClose"
    @save="onSave"
  >
    <base-text-field
      v-model="loan.peerstreetNotes"
      placeholder="PeerStreet Notes"
      type="textarea"
    />
  </base-modal-form>
</template>

<script>
import AjaxService from "services/ajax_service";
import { getManageReportsLoanNotesRoute } from "models/reports/loan";

export default {
  name: "EditNotesModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    loan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saveDisabled: false,
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onSave() {
      this.saveDisabled = true;

      const messages = {
        onError: ({ response }) => {
          if (response.status === 403) {
            return { message: "Unauthorized", status: "error" };
          } else {
            return { message: response.error, status: "error" };
          }
        },
      };
      AjaxService.withNotifications(messages)
        .patchJSON(getManageReportsLoanNotesRoute(this.loan.toParam), {
          loan: { peerstreet_notes: this.loan.peerstreetNotes },
        })
        .then(response => {
          this.$emit("save", response.peerstreet_notes, this.loan);
        })
        .finally(() => {
          this.saveDisabled = false;
        });
    },
  },
};
</script>
