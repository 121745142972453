<template>
  <th class="hotlist-sortable" @click="handleClick">
    <div class="hotlist-sortable-wrapper">
      <div>
        <slot />
      </div>
      <div class="hotlist-sort-direction" :class="caretClass">
        <i class="fas fa-caret-up" />
        <i class="fas fa-caret-down" />
      </div>
    </div>
  </th>
</template>

<script>
export default {
  name: "hotlist-th",
  props: {
    field: {
      type: String,
      required: true,
    },
    fieldType: {
      type: String,
      default: "string",
    },
    direction: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    active() {
      return this.direction !== 0;
    },
    caretClass() {
      if (this.direction === 0) {
        return null;
      } else if (this.direction > 0) {
        return "hotlist-sort-direction-up";
      } else {
        return "hotlist-sort-direction-down";
      }
    },
  },
  methods: {
    handleClick() {
      const { direction, field, fieldType, active } = this;
      let newDirection;

      if (active) {
        newDirection = direction * -1;
      } else {
        newDirection = 1;
      }

      this.$emit("sort", newDirection, field, fieldType);
    },
  },
};
</script>
