<template>
  <base-form
    save-button-name="Import"
    :show-cancel="false"
    :save-disabled="saveDisabled"
    @save="onSave"
  >
    <base-autocomplete
      ref="lenderAutocomplete"
      id="lender"
      v-model="batchData.lenderId"
      field="name"
      placeholder="Lender Name (type ahead)"
      label="Lender"
      :options="lenders"
      rules="required"
      hint="*required"
      @blur="onLenderFieldBlur"
    />
    <base-select-field
      id="selling-channel"
      v-model="batchData.sellingChannel"
      label="Selling Channel"
      :options="sellingChannels"
      rules="required"
      hint="*required"
    />
    <base-checkbox
      v-model="batchData.archived"
      name="archived"
      native-value="archived"
    >
      Archived?
    </base-checkbox>
    <base-text-field
      id="google-sheet-id"
      v-model="batchData.googleSheetId"
      label="Google Sheet Id"
      rules="required"
      hint="*required"
    />
  </base-form>
</template>

<script>
import AjaxService from "services/ajax_service";
import { MANAGE_LOAN_TAPES_BATCHES_ROUTE } from "models/loan_tapes_batch";
import isEmpty from "lodash/isEmpty";

export default {
  props: {
    sellingChannels: {
      type: Array,
      default: () => [],
    },
    lenders: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    loading: false,
    batchData: {},
  }),
  computed: {
    saveDisabled() {
      return (
        this.loading ||
        this.batchData.lenderId === null ||
        this.batchData.sellingChannel == null ||
        isEmpty(this.batchData.googleSheetId)
      );
    },
  },
  methods: {
    onSave() {
      this.save();
    },
    save() {
      const messages = { onSuccess: "Batch is imported", onError: this.onError };

      this.loading = true;

      return this.createLoanTape(messages)
        .then(data => {
          if (data && !data.error) {
            this.$emit("import", data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createLoanTape(messages) {
      var data = {
        lender_id: this.batchData.lenderId,
        selling_channel: this.batchData.sellingChannel,
        archived: !!this.batchData.archived,
        spreadsheet_id: this.batchData.googleSheetId,
      };

      return AjaxService.withNotifications(messages)
        .post(MANAGE_LOAN_TAPES_BATCHES_ROUTE, data)
        .catch(() => {}); // stub; catch is performing in [onError])
    },
    onLenderFieldBlur() {
      if (!this.batchData.lenderId) {
        this.$refs.lenderAutocomplete.updateInnerValue("");
      }
    },
    onError({ data }) {
      return {
        message: data.error,
        status: "error",
      };
    },
  },
};
</script>
