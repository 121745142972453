<template>
  <loan-sales :sales="quarterlySales" :goal="quarterlyGoal" />
</template>

<script>
import { formatDate } from "filters";
import LoanSales from "components/loan_sales";

export default {
  name: "quarterly-loan-sales",
  components: {
    LoanSales,
  },
  props: {
    sales: {
      type: Object,
      default: () => ({}),
    },
    goal: Number,
  },
  computed: {
    quarterlySales() {
      return {
        period: `Q${formatDate(this.sales.month, "Q YYYY")}`,
        amount: this.sales.amount,
        count: this.sales.count,
      };
    },
    quarterlyGoal() {
      return {
        title: "Quarterly Goal",
        amount: this.goal,
      };
    },
  },
};
</script>
