import AjaxService from "services/ajax_service";

const TASK_ATTACHMENT_CREATION_ROUTE = "/uploaded_files/:document_id/task_attachments.json";
const TASK_ATTACHMENT_DELETION_ROUTE =
  "/uploaded_files/:document_id/task_attachments/:task_attachment_id.json";

export default class TaskAttachmentsService {
  static create(documentId, taskId) {
    const taskAttachmentCreationRoute = `${AjaxService.homePath()}${TASK_ATTACHMENT_CREATION_ROUTE}`;
    const url = taskAttachmentCreationRoute.replace(":document_id", documentId);
    return AjaxService.withNotifications({
      onSuccess: "Document attached to Task",
    }).post(url, {
      loan_task_id: taskId,
    });
  }

  static destroy(documentId, taskAttachmentId) {
    const taskAttachmentDeletionRoute = `${AjaxService.homePath()}${TASK_ATTACHMENT_DELETION_ROUTE}`;
    const url = taskAttachmentDeletionRoute
      .replace(":document_id", documentId)
      .replace(":task_attachment_id", taskAttachmentId);

    return AjaxService.withNotifications({
      onSuccess: "Document removed from Task",
    }).delete(url);
  }
}
