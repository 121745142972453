<template>
  <div>
    <loan-boarding-pipeline
      :collection="currentCollection"
      :routes="routes"
      :options="options"
      @updated="updateCollections" />

    <loan-boarding-transfer-pipeline
      :collection="currentTransferCollection"
      :routes="routes.transfer"
      @updated="updateTransferCollections" />
  </div>
</template>
<script>
import LoanBoardingSectionMixin from "components/servicer/loan_boarding/mixin/section";
import LoanBoardingPipeline from "components/servicer/loan_boarding/pipeline";
import LoanBoardingTransferPipeline from "components/servicer/loan_boarding_transfer/pipeline";

export default {
  components: { LoanBoardingPipeline, LoanBoardingTransferPipeline },
  mixins: [LoanBoardingSectionMixin],
  name: "manage-servicer-load-boarding-sections-pipeline",
  computed: {
    lastUpdatedAt() {
      return this.getLastUpdatedAt(this.currentCollection);
    },
    currentCollection() {
      let collection = this.collections.pending;
      return this.filterCollectionForServicer(collection);
    },
    currentTransferCollection() {
      let collection = this.transferCollections.pending;
      return this.filterCollectionForServicer(collection);
    },
    count() {
      return this.currentCollection.length + this.currentTransferCollection.length;
    },
  },
  created() {
    this.emitSectionData();
  },
  methods: {
    emitSectionData() {
      this.$emit("loaded", {
        section: "pipeline",
        count: this.count,
        lastUpdatedAt: this.lastUpdatedAt,
      });
    },
  },
};
</script>
