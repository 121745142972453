<template>
  <base-modal-form
    :show="show"
    title="Create Subordinate Piece Payment Transaction"
    :save-disabled="isSaving"
    :show-close="showClose"
    @close="close"
    @save="save"
  >
    <div class="pb-3">
      <base-date-field
        v-model="date"
        label="Transaction Date"
        :min-date="loanPurchasedDate"
        :max-date="today"
        :errors="errors.date"
        rules="required"
      />
    </div>

    <div class="pb-5">
      <h3>Total Unpaid Subordinate Balance</h3>
      <p>{{ totalUnpaidSubordinateBalance | accounting }}</p>
    </div>

    <div class="pb-3">
      <base-currency-field
        v-model="totalSubordinatePaymentAmt"
        :errors="errors.amount"
        label="Transaction Amount"
        :rules="`max_number_value: ${totalUnpaidSubordinateBalance}`"
      />
    </div>
  </base-modal-form>
</template>

<script>
import Ajax, { ERROR_STATUS, ERROR_MESSAGE } from "services/ajax_service";
export default {
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      date: new Date(),
      isSaving: false,
      showClose: true,
      loanPurchasedDate: PSData.loan.initialPurchaseDate,
      today: new Date(),
      totalSubordinatePaymentAmt: 0,
      errors: {},
      totalUnpaidSubordinateBalance: Number.parseFloat(
        PSData.totalUnpaidSubordinateBalance
      ).toFixed(2),
    };
  },
  mounted() {
    this.setDefaultSubordinatePaymentAmt();
  },
  methods: {
    setDefaultSubordinatePaymentAmt() {
      this.totalSubordinatePaymentAmt = this.totalUnpaidSubordinateBalance;
    },
    close() {
      this.date = this.today;
      this.errors = [];
      this.setDefaultSubordinatePaymentAmt();
      this.$emit("close");
    },
    async save() {
      this.isSaving = true;
      try {
        await Ajax.postJSON(PSData.routes.processSubordinatePayment, {
          date: this.date,
          amount: this.totalSubordinatePaymentAmt,
        });
        location.reload();
      } catch ({ data }) {
        if (data && data.errors) {
          this.errors = data.errors;
        } else {
          Ajax.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
        }
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style scoped>
.base-date-field,
.base-currency-field {
  width: 200px;
}
</style>
