<template>
  <per-diem-fields
    :value="showValue"
    :label="label"
    :editing="editing"
    :days-owned="daysOwned"
    :hide-per-diem="true"
    @input="update"
  ></per-diem-fields>
</template>

<script>
import PerDiemFields from "components/manage/settlement_statement/per_diem_fields";

export default {
  name: "PerDiemFieldsReconciliation",
  components: { PerDiemFields },
  props: {
    label: String,
    editing: Boolean,
    value: [Number, String],
    previous: [Number, String],
    total: [Number, String],
    daysOwned: [Number, String],
    disabled: Boolean,
  },
  data() {
    return {
      showValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      if (!this.editing) {
        this.showValue = newValue;
      }
    },
    editing(isEditing) {
      if (isEditing) {
        this.showValue = this.total;
      } else {
        this.showValue = this.value;
      }
    },
  },
  methods: {
    update(value) {
      if (this.editing) {
        const amount = value - this.previous;

        this.showValue = value;
        this.$emit("input", amount);
      }
    },
  },
};
</script>
