<template>
  <modal-view
    :show="show"
    :on-save="submit"
    :on-close="close"
    class="base-modal"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          {{ createMode ? "Add" : "Edit" }} Document Details
          <a href="#close" @click.prevent="close">&times;</a>
        </h4>
        <span class="subheader">Track formal notice demanding action with a deadline</span>
      </div>

      <div class="modal-body">
        <form @keypress.enter="submit">
          <div class="row">
            <div class="col-12 form-group">
              <with-errors on="documentTypeId" :errors="errors">
                <label>Type</label>
                <select
                  :value="documentToEdit.documentTypeId"
                  class="form-control"
                  @input="e => updateDocument('documentTypeId', e.target.value)"
                >
                  <option value="" />
                  <option v-for="{id, text } in workoutDocumentTypes" :key="id" :value="id">
                    {{ text }}
                  </option>
                </select>
              </with-errors>
            </div>
          </div>

          <div class="row">
            <div class="col-6 control-wrapper">
              <with-errors on="dateSent" :errors="errors">
                <label>Sent To Borrower Date</label>
                <input
                  v-datepicker
                  v-mask:date
                  class="form-control foreclosure-input"
                  :value="documentToEdit.dateSent"
                  @input="updateDocument('dateSent', $event.target.value)"
                >
              </with-errors>
            </div>
            <div class="col-6 control-wrapper">
              <with-errors on="expirationDate" :errors="errors">
                <label>Expiration Deadline Date</label>
                <input
                  v-datepicker
                  v-mask:date
                  class="form-control foreclosure-input"
                  :value="documentToEdit.expirationDate"
                  @input="updateDocument('expirationDate', $event.target.value)"
                >
              </with-errors>
            </div>
          </div>

          <div class="row">
            <div class="col-6 control-wrapper">
              <label>Tracking Number<span class="optional-label">&nbsp;(optional)</span></label>
              <input
                :value="documentToEdit.trackingNumber"
                type="text"
                class="form-control"
                placeholder="9999 9999 9999"
                @input="e => updateDocument('trackingNumber', e.target.value)"
              >
            </div>
            <div class="col-6 control-wrapper">
              <label>Carrier<span class="optional-label">&nbsp;(optional)</span></label>
              <input
                :value="documentToEdit.carrier"
                type="text"
                class="form-control"
                placeholder="USPS, FedEx, UPS"
                @input="e => updateDocument('carrier', e.target.value)"
              >
            </div>
          </div>

          <div class="row">
            <div class="col-12 form-group">
              <label>Note<span class="optional-label">&nbsp;(optional)</span></label>
              <textarea
                :value="documentToEdit.note"
                class="form-control"
                placeholder="Optional description here"
                @input="e => updateDocument('note', e.target.value)"
              />
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button class="btn btn-link" @click.prevent="close">
          Cancel
        </button>
        <button
          :class="{ disabled: inFlight }"
          class="btn btn-action-cta"
          @click="submit"
        >
          Save
        </button>
      </div>
    </div>
  </modal-view>
</template>

<script>
import ModalView from "components/modal_view";
import WithErrors from "components/shared/with_errors";

export default {
  name: "foreclosure-document-tracking-modal",
  components: {
    ModalView,
    WithErrors,
  },
  props: {
    createMode: {
      type: Boolean,
      default: true,
    },
    documentToEdit: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
    inFlight: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      required: true,
    },
    workoutDocumentTypes: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("save");
    },
    updateDocument(fieldName, fieldValue) {
      this.$emit("input", fieldName, fieldValue);
    },
  },
};
</script>
