<template>
  <div v-if="showAlert" class="alert alert-warning loan-constraints-violation-alert notification-message">
    <div>
      <i class="fas fa-exclamation-triangle fa-lg"/>
    </div>
    <div>
      <h3>PeerStreet Restriction</h3>
      <p v-for="(message, index) in violationMessages" :key="index">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "usury-violation-warning",
  props: {
    rules: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    showAlert() {
      return this.violationMessages.length > 0;
    },
    violationMessages() {
      return this.rules.map(rule => rule.message);
    },
  },
};
</script>
