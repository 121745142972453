import get from "lodash/get";

export default class EditBorrowerFormPolicy {
  constructor(borrowerPolicy) {
    this.borrowerPolicy = borrowerPolicy || {};
  }

  canUpdateBorrowerData() {
    return get(this.borrowerPolicy, "updateBorrowerData.allowed", false);
  }

  canDeleteBorrower() {
    return get(this.borrowerPolicy, "deleteBorrower.allowed", false);
  }
}
