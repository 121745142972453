<template>
  <span
    :class="{'done': done}"
    :title="title"
    data-toggle="tooltip"
    data-placement="bottom"
    class="status task-tooltip"
    :disabled="true"
  ></span>
</template>

<script>
export default {
  name: "sla-task-status",
  props: {
    title: {
      type: String,
      required: true,
    },
    done: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
