const Source = PS.Models.MarketData.Source;

class MsaAtGlance extends Source {
  get name() {
    return "MSA at Glance";
  }

  success(midasData) {
    if (midasData && midasData.msaCrossSection) {
      let marketData = midasData.msaCrossSection;

      this.income = marketData.income;
      this.incomeStatePercentile = marketData.incomeStatePercentile * 100;
      this.investmentIncome = marketData.investmentIncome;
      this.investmentIncomeStatePercentile = marketData.investmentIncomeStatePercentile * 100;
      this.retirementIncome = marketData.retirementIncome;
      this.retirementIncomeStatePercentile = marketData.retirementIncomeStatePercentile * 100;
      this.poverty = marketData.poverty * 100;
      this.povertyStatePercentile = marketData.povertyStatePercentile * 100;
      this.education = marketData.education * 100;
      this.educationStatePercentile = marketData.educationStatePercentile * 100;
      this.highTech = marketData.highTech * 100;
      this.highTechStatePercentile = marketData.highTechStatePercentile * 100;
      this.homeValue = marketData.homeValue;
      this.homeValueStatePercentile = marketData.homeValueStatePercentile * 100;
      this.monthlyCostOwn = marketData.monthlyCostOwn;
      this.monthlyCostOwnStatePercentile = marketData.monthlyCostOwnStatePercentile * 100;
    }

    super.success();
  }
}

PS.Models.MarketData.Tables.MsaAtGlance = MsaAtGlance;
