<template>
  <base-sidebar
    :open="opened"
    fullheight
    right
    custom
    position="absolute"
    type="white"
    class="revisions-bar"
  >
    <div class="revisions-bar-header">
      <base-header size="small">
        Template History
      </base-header>
      <base-button type="icon" icon="times" @click="$emit('close')" />
    </div>
    <div @click="$emit('close')">
      <router-link
        v-for="revision in revisions"
        :key="revision.id"
        :to="revisionRoute(revision.id)"
        class="revisions-bar-item"
      >
        <revision-status :revision="revision" class="revision-status-item is-justify-content-space-between" />
      </router-link>
    </div>
  </base-sidebar>
</template>

<script>
import RevisionStatus from "./revision_status";
import { tasklistTemplateRevisionRoute } from "models/tasklist_template_revision";

export default {
  components: {
    RevisionStatus,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    templateId: {
      type: String,
      required: true,
    },
    revisions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    revisionRoute(revisionId) {
      return tasklistTemplateRevisionRoute(this.templateId, revisionId);
    },
  },
};
</script>
<style scoped>
.revisions-bar >>> .sidebar-content {
  border-left: 1px solid var(--color-grey-16);
  width: 380px;
}

.revisions-bar-header {
  align-items: center;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  padding: calc(var(--space-unit) * 1.5) var(--space-unit) calc(var(--space-unit) * 1.5)
    calc(var(--space-unit) * 2);
}

.revisions-bar-item {
  background: none;
  border-top: 1px solid var(--color-grey-16);
  border-bottom: 1px solid var(--color-grey-16);
  display: block;
  padding: var(--space-unit) calc(var(--space-unit) * 1.5);
  position: relative;
  text-decoration: none;
  top: -1px;
}

.revisions-bar-item.router-link-active {
  background: var(--color-blue-8);
  border-top: 1px solid var(--color-blue);
  border-bottom: 1px solid var(--color-blue);
}

.revision-status-item >>> .revision-loans-count {
  text-align: right;
}
</style>
