<template>
  <div>
    <base-table :data="tasks" :row-class="rowClass" @sort="onSort" class="todolist-loan-tasks-table">
      <base-column
        sortable
        :searchable="isSearchable"
        width="800"
        field="title"
        label="Task name"
        header-class="todolist-loan-task-header"
      >
        <template v-slot:header="{ column }">
          <span class="is-relative flex-row">
            <base-icon
              pack="fas"
              icon="caret-up"
              size="small"
              class="sort-icon"
              :class="{ 'is-desc': !isAsc, 'is-invisible': !isSortedByTitle }"
            />
            <span>
              {{ column.label }}
            </span>
            <span role="button" @click.stop="isSearchable = !isSearchable">
              <base-icon
                class="position-static sort-icon"
                icon="filter"
                pack="fas"
                size="small"
                :class="{ 'is-invisible': !isSearchable }"
              />
            </span>
          </span>
        </template>
        <template v-show="isSearchable" #searchable="props">
          <base-text-field
            v-model="props.filters['title']"
            class="task-name-filter"
            placeholder="Enter task name..."
            tabindex="0"
            @input="onFilterChange"
            @keydown.esc.native="isSearchable = false"
          />
        </template>
        <template v-slot="props">
          {{ props.row.title }}
        </template>
      </base-column>
      <base-column
        v-slot="props"
        field="taskType"
        sortable
        header-class="todolist-loan-task-header"
        label="Task type"
      >
        {{ props.row.taskType }}
      </base-column>
      <base-column
        v-slot="props"
        field="assignee"
        sortable
        header-class="todolist-loan-task-header"
        label="Assignee"
      >
        {{ props.row.assignee }}
      </base-column>
      <base-column
        v-slot="props"
        field="milestoneGroupName"
        sortable
        header-class="todolist-loan-task-header"
        label="Milestone Group"
      >
        {{ props.row.milestoneGroupName }}
      </base-column>
      <base-column
        v-slot="props"
        field="lastActivityDate"
        sortable
        header-class="todolist-loan-task-header"
        label="Last Activity"
      >
        {{ props.row.lastActivityDate }}
      </base-column>
      <base-column
        v-slot="props"
        sortable
        field="state"
        header-class="todolist-loan-task-header"
        label="Status"
      >
        {{ props.row.state }}
      </base-column>
      <template v-slot:empty>
        No tasks
      </template>
    </base-table>
    <div v-if="!showAll && !isSearchInitiated">
      <a href="#" class="todolist-tasks-expand-link" @click.prevent="expandTasks">
        + {{ remainingTasksCount }} more
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showAll: false,
      isFilterInputed: false,
      isAsc: true,
      isSortedByTitle: false,
      isSearchable: false,
    };
  },
  computed: {
    remainingTasksCount() {
      return this.tasks.length - 5;
    },
    isSearchInitiated() {
      return this.isSearchable && this.isFilterInputed;
    },
  },
  watch: {
    tasks: {
      immediate: true,
      handler(tasks) {
        this.showAll = tasks.length <= 5;
      },
    },
  },
  methods: {
    expandTasks() {
      this.showAll = true;
    },
    rowClass(row, index) {
      if (this.showAll || this.isSearchInitiated) {
        return "";
      }

      return index > 4 ? "is-hidden" : "";
    },
    onFilterChange(value) {
      this.isFilterInputed = value.length > 0;
    },
    onSort(field, order) {
      this.isAsc = order === "asc";
      this.isSortedByTitle = field === "title";
    },
  },
};
</script>
<style>
.todolist-loan-task-header {
  background-color: var(--color-grey-06);
}
.todolist-tasks-expand-link {
  padding-left: 28px;
}
.task-name-filter {
  padding-bottom: 0 !important;
}
.todolist-loan-tasks-table td {
  border-bottom-width: 1px !important;
}
</style>
