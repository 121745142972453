<template>
  <drop-file-upload
    v-if="!uploadDisabled"
    :initial-options="options"
    @on-upload-success="saveFiles"
    @on-upload-error="showError"
    @on-upload-started="uploadStarted"
  >
    <template slot="dropzone">
      <slot name="dropzone" />
    </template>
    <slot />
  </drop-file-upload>

  <div v-else>
    <slot />
  </div>
</template>

<script>
import DropFileUpload from "components/drop_file_upload";
import { SaveDocuments } from "models/file/commands/picker_commands";
import { DropFileRequested } from "models/file/events/picker_events";
import { error } from "services/notification";

export default {
  name: "loan-documents-drop-to-upload",
  components: {
    DropFileUpload,
  },
  props: {
    metadata: {
      type: Object,
      default: () => ({}),
    },
    uploadDisabled: {
      type: Boolean,
      default: false,
    },
    initialOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filesUploaded: [],
      filesFailed: [],
      options: PSData.uploadSettings,
    };
  },
  methods: {
    saveFiles(filesUploaded, filesFailed) {
      this.filesUploaded = filesUploaded;
      this.filesFailed = filesFailed;

      new SaveDocuments(new DropFileRequested({ metadata: this.metadata }), this)
        .execute()
        .then(() => {
          this.$emit("on-upload-success", filesUploaded, filesFailed);
        });
    },
    showError(errorMessage) {
      error(errorMessage);
    },
    uploadStarted(event) {
      this.$emit("on-upload-started", event);
    },
  },
};
</script>
