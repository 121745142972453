import { deepMapKeys, camelToSnakeCase } from "../utils/casing.js";
import AjaxService from "services/ajax_service";
import RequestError from "components/loan_scenarios/utils/request_error";

const ROUTES = {
  PROPERTY_DETAILS: "/property_details",
};

export default class PropertyDetailsClient {
  constructor() {
    this.abortController = new AbortController();
  }

  async fetchDetails(payload) {
    this.abortController.abort();
    this.abortController = new AbortController();

    const urlParams = new URLSearchParams(deepMapKeys(payload, camelToSnakeCase));
    const url = [ROUTES.PROPERTY_DETAILS, "?", urlParams.toString()].join("");
    let response;

    try {
      response = await AjaxService.getJSON(url, {}, this.abortController.signal);
    } catch (e) {
      if (response) {
        RequestError.notify({ message: "Unable to retrieve property details", response, payload });
      }
    } finally {
      response = response || {};
    }

    return response;
  }
}
