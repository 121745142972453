import { SectionType } from "models/lender_form/fields";
import Section from "models/lender_form/section";

const PRICING_FIELD_PAIRS = [
  ["totalOriginationFeesAmount", "totalOriginationPoints"],
  ["psOriginationFeesAmount", "psOriginationPoints"],
  ["thirdPartyOriginationFeesAmount", "thirdPartyOriginationPoints"],
];

export default class PricingSection extends Section {
  constructor(fields) {
    super(SectionType.Pricing, fields);

    this.disableInvisibleFields();
  }

  update() {
    this.checkLenderNoteStatus();

    super.update();
  }

  checkLenderNoteStatus() {
    if (this.isFunded) {
      this.disableSection();
    } else {
      this.disableInvisibleFields();
    }
  }

  disableInvisibleFields() {
    for (let [amount, points] of PRICING_FIELD_PAIRS) {
      if (this.fields[amount].isComplete) {
        this.fields[amount].disabled = false;
        this.fields[points].disabled = true;
      } else {
        this.fields[amount].disabled = true;
        this.fields[points].disabled = false;
      }
    }
  }

  disableSection() {
    for (let [amount, points] of PRICING_FIELD_PAIRS) {
      this.fields[amount].disabled = true;
      this.fields[points].disabled = true;
    }
  }

  get isFunded() {
    return this.fields.lenderNoteStatus.value === "funded";
  }

  get isBroker() {
    return PSData.isBrokerAccount;
  }
}
