import isEqual from "lodash/isEqual";

export default class FictitiousName {
  static initFictitiousNames(rawNames) {
    return rawNames.map(rawName => new FictitiousName(rawName));
  }

  constructor(rawFictitiousName = { id: null, name: "", notes: "", states: [] }) {
    this.rawFictitiousName = rawFictitiousName;
    this.id = rawFictitiousName.id;
    this.reset();
  }

  reset() {
    this.name = this.rawFictitiousName.name;
    this.states = [...this.rawFictitiousName.states];
    this.notes = this.rawFictitiousName.notes;
  }

  clone() {
    return new FictitiousName(this.rawFictitiousName);
  }

  changed() {
    return !isEqual(this.toPlainObject(), this.rawFictitiousName);
  }

  persisted() {
    return !!this.id;
  }

  toPlainObject() {
    return {
      id: this.id,
      name: this.name,
      states: [...this.states],
      notes: this.notes,
    };
  }
}
