<template>
  <div>
    <div v-if="editing">
      <modification-form
        v-for="item in sortedItems"
        :key="item.id"
        :item="item"
        :option-number="item.sequenceNumber"
        @change="editModification(item.id, $event)"
      />
    </div>
    <div v-else>
      <list-item v-for="item in sortedItems" :item="item" :key="item.id" />
    </div>
  </div>
</template>
<script>
import ListItem from "./list_item";
import sortBy from "lodash/sortBy";
import ModificationForm from "./modification_form";

export default {
  components: {
    ListItem,
    ModificationForm,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    editing: Boolean,
  },
  methods: {
    editModification(modificationId, data) {
      this.$emit("change", modificationId, data);
    },
  },
  computed: {
    sortedItems() {
      return sortBy(this.items, item => -item.sequenceNumber);
    },
  },
};
</script>
