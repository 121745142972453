const DEFAULT_OPTIONS = {
  placement: "bottom",
  trigger: "hover",
};

export const Tooltip = {
  inserted(el) {
    Vue.nextTick(function() {
      $(el).tooltip(DEFAULT_OPTIONS);
    });
  },
  unbind(el) {
    $(el).tooltip("dispose");
  },
};

Vue.directive("tooltip", Tooltip);
