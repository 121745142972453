<template>
  <time-series-comparison-chart
    :chart="chart"
    :sources="sources"
    class="home-index-chart">
  </time-series-comparison-chart>
</template>

<script>
import TimeSeriesComparisonChart from "components/market_data/time_series_comparison_chart";

export default {
  name: "msa-home-index-chart",
  components: {
    TimeSeriesComparisonChart,
  },
  props: {
    sources: Object,
  },
  data: function() {
    return {
      chart: {
        title: "Home Price Index",
        columns: {
          hcNational: {
            color: "#66cc33",
            observed: {
              data: "National",
              labels: "hc-national-observed",
            },
            predicted: {
              data: "National (forecast)",
              labels: "hc-national-predicted",
            },
          },
          hcMsa: {
            color: "#5b6770",
            observed: {
              data: "MSA",
              labels: "hc-msa-observed",
            },
            predicted: {
              data: "MSA (forecast)",
              labels: "hc-msa-predicted",
            },
          },
          hcMsaTrend: {
            color: "#a9b2bd",
            observed: {
              data: "MSA Trend",
              labels: "hc-msa-trend-observed",
            },
            predicted: {
              data: "MSA Trend (forecast)",
              labels: "hc-msa-trend-predicted",
            },
          },
        },
      },
    };
  },
};
</script>
