import TagList from "components/tags/tag_list";
import BrokerTag from "components/tags/broker";

$(() => {
  const BusService = PS.Services.MessageBusService;
  const Lender = PS.Models.Lenders.Lender;

  new Vue({
    el: document.querySelector(".v-lender-tag-controller"),
    components: {
      BrokerTag,
      TagList,
    },
    data() {
      return {
        messageBus: BusService.lenderTagBus,
        resourceId: new Lender(PSData.lender).id,
        lenderTags: PSData.lenderTags,
      };
    },
  });
});
