<template>
  <div class="worklist-cell confidence">
    <span v-if="confidenceWeekText" @click.stop="toggleDropdown('week')">
      {{ confidenceWeekText }} /
      <i v-if="worklistDropdownEnabled" class="fas fa-caret-down" :class="classForCaret('week')" />
    </span>
    <span v-else @click.stop="toggleDropdown('week')">
      -- /
      <i v-if="worklistDropdownEnabled" class="fas fa-caret-down" :class="classForCaret('week')" />
    </span>

    <span v-if="confidenceMonthText" @click.stop="toggleDropdown('month')">
      {{ confidenceMonthText }}
      <i v-if="worklistDropdownEnabled" class="fas fa-caret-down" :class="classForCaret('month')" />
    </span>
    <span v-else @click.stop="toggleDropdown('month')">
      --
      <i v-if="worklistDropdownEnabled" class="fas fa-caret-down" :class="classForCaret('month')" />
    </span>
    <div v-if="worklistDropdownEnabled" @click.stop="hideDropdown" class="worklist-cell-overlay"></div>
    <div v-if="worklistDropdownEnabled" class="worklist-cell confidence-dropdown">
      <ul>
        <li v-for="(label, type) in dropdownList" :key="type" @click.stop="onClick(type)">
          {{ label }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Ajax from "services/ajax_service";
import { error } from "services/notification";
import camelCase from "lodash/camelCase";

export default {
  name: "worklist-confidence-cell",
  props: {
    loan: {
      type: Object,
      required: true,
    },
    canUpdateWorklistData: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      confidenceWeekText: this.loan.confidenceWeekText,
      confidenceMonthText: this.loan.confidenceMonthText,
      dropdown: false,
      dropdownList: {
        0: "--",
        25: "25%",
        50: "50%",
        75: "75%",
        90: "90%",
      },
      type: undefined,
    };
  },
  methods: {
    onClick(value) {
      const messages = {
        onSuccess: "Confidence was successfully updated",
        onError: ({ response, data }) => {
          if (response.status === 422) {
            return {
              message: data.error,
              status: "error",
            };
          } else {
            error("Couldn't update Confidence");
          }
        },
      };

      const preparedValue = value === "0" ? null : value;
      const params = {
        resource: { [this.confidenceAttribute]: preparedValue },
      };

      Ajax.withNotifications(messages)
        .patch(this.loan.routes.updatePath, params)
        .then(() => {
          const formattedValue = preparedValue !== null ? preparedValue + "%" : preparedValue;

          this[camelCase(`${this.confidenceAttribute}_text`)] = formattedValue;
        })
        .catch(() => {})
        .finally(this.hideDropdown);
    },
    toggleDropdown(type) {
      if (this.dropdown) {
        return this.hideDropdown();
      }

      this.dropdown = true;
      this.type = type;
    },
    hideDropdown() {
      this.dropdown = false;
      this.type = undefined;
    },
    classForCaret(type) {
      if (this.dropdown && this.type === type) {
        return "open";
      }
    },
  },
  computed: {
    confidenceAttribute() {
      return `confidence_${this.type}`;
    },
    worklistDropdownEnabled() {
      return this.dropdown && this.canUpdateWorklistData;
    },
  },
};
</script>
