import OpenAuctionSearch from "components/open_auction/search";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-open-auction-controller"),
    components: {
      OpenAuctionSearch,
    },
  });
});
