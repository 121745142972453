<template>
  <base-message
    :title="upload.title"
    :has-icon="true"
    :closable="closable"
    :icon="icon"
    :class="`upload-banner upload-banner-${upload.status}`"
    :type="type"
    @close="close"
  >
    <p>{{ upload.message }}</p>
    <br>
    <p>{{ upload.fileNameText }}</p>
    <p>{{ upload.submissionVendorIdText }}</p>
    <p>{{ upload.statusText }}</p>
  </base-message>
</template>

<script>
const Commands = PS.Models.Diligence.Commands;
const Events = PS.Models.Diligence.Events;

export default {
  name: "UploadBanner",
  props: {
    upload: Object,
  },
  computed: {
    closable() {
      return !["processing", "supervision"].includes(this.upload.status);
    },
    icon() {
      return (
        {
          success: "check-circle",
          processing: "spinner",
          failed: "times-circle",
          supervision: "exclamation-triangle",
        }[this.upload.status] || ""
      );
    },
    type() {
      return (
        {
          success: "success",
          processing: "info",
          failed: "danger",
          supervision: "warning",
        }[this.upload.status] || ""
      );
    },
  },
  methods: {
    close() {
      new Commands.Form.ClearUploadNotification(
        new Events.Form.ClearUploadNotificationRequested({
          uploadId: this.upload.id,
        })
      ).execute();
    },
  },
};
</script>
