<template>
  <loan-boarding-table label="Boarding Reconciliations" :count="count">
    <template #guardrail>
      <loan-boarding-guardrails :collection="collection"></loan-boarding-guardrails>
    </template>
    <template #actions>
      <div class="servicer-loan-boarding-table-actions">
        <div v-if="!isReadonly" class="select-all-rows">
          <check-box v-model="allSelected" :partial="isPartiallySelected" @change="toggleAll"></check-box>
        </div>
        <div v-if="isSubmit" class="buttons">
          <button
            type="button"
            class="btn btn-action-cta"
            @click="submit"
            :disabled="isNoneSelected()">
            Submit to Subservicer
          </button>
          <button
            type="button"
            class="btn btn-outline-grey"
            @click="moveToReconcile"
            :disabled="isNoneSelected()">
            Move Back to Reconcile
          </button>
        </div>
        <div v-else-if="isTab" class="buttons">
          <button
            type="button"
            class="btn btn-action-cta"
            @click="moveToReady"
            :disabled="isNoneSelected()">
            Move to Ready
          </button>
        </div>
      </div>
    </template>
    <template #header>
      <thead>
        <th class="checkbox-header" v-if="!isReadonly"></th>
        <th>Submitted/<br>BWL Days</th>
        <th>BWL<br>Date</th>
        <th>Subservicer<br>ID</th>
        <th>Loan ID</th>
        <th class="address">Address</th>
        <th v-if="!isReadonly">Review Flags</th>
        <th>Subservicer<br>Comments</th>
        <th>Reconcile<br>Reason</th>
        <th>Responsible<br>Party</th>
        <th>Notes To Subservicer</th>
        <th>PS Internal Notes</th>
        <th>Pipeline Date</th>
        <th>Ready To Submit Date</th>
      </thead>
    </template>
    <template #body>
      <tr v-for="item in collection" :key="item.id" :id="item.id">
        <td v-if="!isReadonly" class="checkbox-column">
          <check-box v-model="item.checked" @change="toggleItem"></check-box>
        </td>
        <td>{{ item.submittedDate }}<br>{{ item.bwlDays }}</td>
        <td>{{ item.welcomeLetterReceivedDate }}</td>
        <td>{{ item.servicerLoanCode }}</td>
        <td>{{ item.psLoanId }}<br>
          <loan-boarding-details-popup :item="item"></loan-boarding-details-popup>
        </td>
        <td class="address">
          <loan-boarding-truncated-text :value="item.address"></loan-boarding-truncated-text>
        </td>
        <td v-if="!isReadonly" class="dropdown-cell">
          <loan-boarding-review-flags :record="item"></loan-boarding-review-flags>
        </td>
        <td>
          <loan-boarding-truncated-text :value="item.flaggedComments" />
        </td>
        <td class="dropdown-cell">
          <editable-cell
            type="dropdown"
            :options="options.flaggedReasonType"
            v-model="item.flaggedReasonType"
            @input="onDropdownUpdate(item, 'flaggedReasonType')"
          ></editable-cell>
        </td>
        <td>
          <editable-cell
            type="dropdown"
            :options="options.flaggedResponsibeType"
            v-model="item.flaggedResponsibeType"
            @input="onDropdownUpdate(item, 'flaggedResponsibeType')"
          ></editable-cell>
        </td>
        <td class="note-cell">
          <loan-boarding-notes-to-subservicer :record="item">
          </loan-boarding-notes-to-subservicer>
        </td>
        <td class="note-cell">
          <loan-boarding-ps-internal-notes :record="item">
          </loan-boarding-ps-internal-notes>
        </td>
        <td>{{ item.addedToPipelineAt }}</td>
        <td>{{ item.approvedAt }}</td>
      </tr>
    </template>
  </loan-boarding-table>
</template>
<script>
import LoanBoardingTruncatedText from "components/servicer/loan_boarding/truncated_text";
import LoanBoardingDetailsPopup from "components/servicer/loan_boarding/details_popup";
import LoanBoardingNotesToSubservicer from "components/servicer/loan_boarding/notes_to_subservicer";
import LoanBoardingPsInternalNotes from "components/servicer/loan_boarding/ps_internal_notes";
import LoanBoardingListMixin from "components/servicer/loan_boarding/mixin/list";
import LoanBoardingUpdateStatusMixin from "components/servicer/loan_boarding/mixin/update_status";
import LoanBoardingUpdateDropdownMixin from "components/servicer/loan_boarding/mixin/update_dropdown";
import LoanBoardingReviewFlags from "components/servicer/loan_boarding/review_flags";
import LoanBoardingGuardrails from "components/servicer/loan_boarding/guardrails";
import LoanBoardingTable from "components/servicer/loan_boarding/table";

import EditableCell from "components/shared/editable_cell";
import CheckBox from "components/shared/check_box";
import AjaxService from "services/ajax_service";
import { success, error } from "services/notification";
import snakeCase from "lodash/snakeCase";

const MODES = {
  submit: "submit",
  readonly: "readonly",
  tab: "tab",
};

export default {
  mixins: [LoanBoardingListMixin, LoanBoardingUpdateStatusMixin, LoanBoardingUpdateDropdownMixin],
  name: "manage-servicer-loan-boarding-reconcile",
  components: {
    LoanBoardingTruncatedText,
    LoanBoardingNotesToSubservicer,
    LoanBoardingPsInternalNotes,
    LoanBoardingReviewFlags,
    LoanBoardingDetailsPopup,
    LoanBoardingListMixin,
    LoanBoardingGuardrails,
    LoanBoardingTable,
    EditableCell,
    CheckBox,
  },
  props: {
    collection: Array,
    routes: Object,
    options: Object,
    mode: {
      type: String,
      default: "tab",
    },
  },
  methods: {
    moveToReady() {
      this.updateStatus(this.checkedItems(), "repaired").then(collections =>
        this.$emit("updated", collections)
      );
    },
    moveToReconcile() {
      this.updateStatus(this.checkedItems(), "flagged").then(collections =>
        this.$emit("updated", collections)
      );
    },
    submit() {
      this.submitBatch(this.checkedItems(), "resubmitted", this.routes.reconciliation.submit).then(
        collections => this.$emit("updated", collections)
      );
    },
  },
  computed: {
    isSubmit() {
      return this.mode === MODES.submit;
    },
    isTab() {
      return this.mode === MODES.tab;
    },
    isReadonly() {
      return this.mode === MODES.readonly;
    },
  },
};
</script>
