import isEmpty from "lodash/isEmpty";
import uniqueId from "lodash/uniqueId";

const NumberFormatter = PS.Models.NumberFormatter;

export default class PayoffStatement {
  constructor(payoffStatement = {}) {
    this.payoffStatement = payoffStatement;
    this.versionNumber = payoffStatement.versionNumber;
    this.id = payoffStatement.id;
    this.loanId = payoffStatement.loanId;
    this.statementType = payoffStatement.statementType;
    this.statementTypeText = payoffStatement.statementTypeText;
    this.status = payoffStatement.status;
    this.statusLabel = payoffStatement.statusLabel;
    this.lastUpdated = payoffStatement.lastUpdated;
    this.payoffEstimatedDate = payoffStatement.payoffEstimatedDate;
    this.maturityDate = payoffStatement.maturityDate;
    this.servicingReleased = payoffStatement.servicingReleased;
    this.paidToDate = payoffStatement.paidToDate;
    this.correctedPaidToDate = payoffStatement.correctedPaidToDate;
    this.payoffReason = payoffStatement.payoffReason;
    this.payoffReasonType = payoffStatement.payoffReasonName;
    this.principalAtPurchaseAmount = payoffStatement.principalAtPurchaseAmount;
    this.principalAdjustmentsAmount = payoffStatement.principalAdjustmentsAmount;
    this.principalPaymentsAmount = payoffStatement.principalPaymentsAmount;
    this.unfundedCommitmentAmount = payoffStatement.unfundedCommitmentAmount;
    this.unpaidPrincipalAmount = payoffStatement.unpaidPrincipalAmount;
    this.priceDiscountPremiumAmount = payoffStatement.priceDiscountPremiumAmount;
    this.totalPrincipal = payoffStatement.totalPrincipal;
    this.interestRate = payoffStatement.interestRate;
    this.lenderRate = payoffStatement.lenderRate;
    this.defaultRate = payoffStatement.defaultRate;
    this.interestAccrualMethod = payoffStatement.interestAccrualMethod;
    this.interestCalculationMethod = payoffStatement.interestCalculationMethod;
    this.interestAccrualMethodText = payoffStatement.interestAccrualMethodText;
    this.interestCalculationMethodText = payoffStatement.interestCalculationMethodText;
    this.bPieceAmount = payoffStatement.bPieceAmount;
    this.constructionReserveAmount = payoffStatement.constructionReserveAmount;
    this.otherReserveAmount = payoffStatement.otherReserveAmount;
    this.payoffExpirationDate = payoffStatement.payoffExpirationDate;
    this.defaultDate = payoffStatement.defaultDate;

    this.interestDueFromBorrowerAmount = payoffStatement.interestDueFromBorrowerAmount;
    this.defaultInterestDueFromBorrowerAmount =
      payoffStatement.defaultInterestDueFromBorrowerAmount;
    this.interestLenderSpreadAmount = payoffStatement.interestLenderSpreadAmount;
    this.interestBPieceAmount = payoffStatement.interestBPieceAmount;
    this.defaultInterestBPieceAmount = payoffStatement.defaultInterestBPieceAmount;
    this.wireTargetDateAmount = payoffStatement.wireTargetDateAmount;
    this.waivedPrincipalInterestFeesAmount = payoffStatement.waivedPrincipalInterestFeesAmount;

    this.waivedUnpaidPrincipalAmount = payoffStatement.waivedUnpaidPrincipalAmount;
    this.waivedInterestDueFromBorrowerAmount = payoffStatement.waivedInterestDueFromBorrowerAmount;
    this.waivedDefaultInterestDueFromBorrowerAmount =
      payoffStatement.waivedDefaultInterestDueFromBorrowerAmount;
    this.payoffStatementCreditAmount = payoffStatement.payoffStatementCreditAmount;
    this.totalInterest = payoffStatement.totalInterest;
    this.wireDate = payoffStatement.wireDate;
    this.wireAmount = payoffStatement.wireAmount;
    this.wireExpectedAmount = payoffStatement.wireExpectedAmount;
    this.federalReferenceId = payoffStatement.federalReferenceId;
    this.unpaidInterestDueFromBorrowerAmount = payoffStatement.unpaidInterestDueFromBorrowerAmount;
    this.waivedUnpaidInterestDueFromBorrowerAmount =
      payoffStatement.waivedUnpaidInterestDueFromBorrowerAmount;
    this.deferredInterestAmount = payoffStatement.deferredInterestAmount;
    this.waivedDeferredInterestAmount = payoffStatement.waivedDeferredInterestAmount;

    this.discountPremiumValue = NumberFormatter.formatPercentAndCurrency(
      this.priceDiscountPremiumAmount,
      this.totalPrincipal,
      1
    );

    this.waivedPrincipalValue = NumberFormatter.formatPercentAndCurrency(
      this.waivedUnpaidPrincipalAmount,
      this.totalPrincipal
    );
    this.waivedDeferredInterestValue = NumberFormatter.formatPercentAndCurrency(
      this.waivedDeferredInterestAmount,
      this.deferredInterestAmount
    );
    this.waivedUnpaidInterestValue = NumberFormatter.formatPercentAndCurrency(
      this.waivedUnpaidInterestDueFromBorrowerAmount,
      this.unpaidInterestDueFromBorrowerAmount
    );
    this.netPrincipalValue = payoffStatement.netPrincipalValue;
    this.totalDefaultInterest = payoffStatement.totalDefaultInterest;
    this.netInterest = payoffStatement.netInterest;
    this.netDefaultInterest = payoffStatement.netDefaultInterest;

    this.comments = PayoffStatement.getComments(payoffStatement.comments);
    this.fees = PayoffStatement.getFees(payoffStatement.fees);

    this.totalFees = payoffStatement.totalFees;
    this.totalWaivedFees = payoffStatement.totalWaivedFees;
    this.netFees = payoffStatement.netFees;
    this.totalCredits = payoffStatement.totalCredits;

    this.routes = payoffStatement.routes;

    this.correctedUnpaidPrincipalAmount = payoffStatement.correctedUnpaidPrincipalAmount;
    this.correctedPrincipalReason = payoffStatement.correctedPrincipalReason;
    this.correctedPrincipalReasonType = payoffStatement.correctedPrincipalReasonName;
    this.correctedInterestDueFromBorrowerAmount =
      payoffStatement.correctedInterestDueFromBorrowerAmount;
    this.correctedDefaultInterestDueFromBorrowerAmount =
      payoffStatement.correctedDefaultInterestDueFromBorrowerAmount;

    this.seller = payoffStatement.seller;
    this.investorLoanAmount = payoffStatement.investorLoanAmount;
    this.psServicingSpread = payoffStatement.psServicingSpread;

    this.investorRate = payoffStatement.investorRate;
    this.payoffStatementCreditAmount = payoffStatement.payoffStatementCreditAmount;
    this.legalOwnerId = payoffStatement.legalOwnerId;
    this.investorUnpaidPrincipalAmount = payoffStatement.investorUnpaidPrincipalAmount;
    this.feeTypeRecoverability = payoffStatement.feeTypeRecoverability;
    this.suspenseBalance = payoffStatement.suspenseBalance;
    this.escrowReserveAmount = payoffStatement.escrowReserveAmount;

    this.currentInterestDue = payoffStatement.currentInterestDue;
    this.currentDefaultInterestDue = payoffStatement.currentDefaultInterestDue;
    this.currentPrincipalBalance = payoffStatement.currentPrincipalBalance;
  }

  get waivedInterestValue() {
    return NumberFormatter.formatPercentAndCurrency(
      this.waivedInterestDueFromBorrowerAmount,
      this.currentInterestDueFromBorrowerAmount
    );
  }

  get waivedDefaultInterestValue() {
    return NumberFormatter.formatPercentAndCurrency(
      this.waivedDefaultInterestDueFromBorrowerAmount,
      this.currentDefaultInterestDueFromBorrowerAmount
    );
  }

  get currentInterestDueFromBorrowerAmount() {
    return this.correctedInterestDueFromBorrowerAmount || this.interestDueFromBorrowerAmount;
  }

  get currentDefaultInterestDueFromBorrowerAmount() {
    return (
      this.correctedDefaultInterestDueFromBorrowerAmount ||
      this.defaultInterestDueFromBorrowerAmount
    );
  }

  update(data) {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  }

  toHash() {
    return {
      b_piece_amount: this.bPieceAmount,
      construction_reserve_amount: this.constructionReserveAmount,
      default_rate: this.defaultRate,
      interest_accrual_method: this.interestAccrualMethod,
      interest_calculation_method: this.interestCalculationMethod,
      servicing_released: this.servicingReleased,
      interest_rate: this.interestRate,
      lender_rate: this.lenderRate,
      payoff_estimated_date: this.payoffEstimatedDate,
      payoff_expiration_date: this.payoffExpirationDate,
      default_date: this.defaultDate,
      maturity_date: this.maturityDate,
      other_reserve_amount: this.otherReserveAmount,
      paid_to_date: this.paidToDate,
      corrected_paid_to_date: this.correctedPaidToDate,
      payoff_reason_type: this.payoffReasonType,
      principal_adjustments_amount: this.principalAdjustmentsAmount,
      principal_at_purchase_amount: this.principalAtPurchaseAmount,
      principal_payments_amount: this.principalPaymentsAmount,
      unfunded_commitment_amount: this.unfundedCommitmentAmount,
      unpaid_principal_amount: this.unpaidPrincipalAmount,
      price_discount_premium_amount: this.priceDiscountPremiumAmount,
      interest_due_from_borrower_amount: this.interestDueFromBorrowerAmount,
      default_interest_due_from_borrower_amount: this.defaultInterestDueFromBorrowerAmount,
      waived_default_interest_due_from_borrower_amount: this
        .waivedDefaultInterestDueFromBorrowerAmount,
      waived_interest_due_from_borrower_amount: this.waivedInterestDueFromBorrowerAmount,
      waived_unpaid_principal_amount: this.waivedUnpaidPrincipalAmount,
      interest_lender_spread_amount: this.interestLenderSpreadAmount || 0.0,
      interest_b_piece_amount: this.interestBPieceAmount || 0.0,
      default_interest_b_piece_amount: this.defaultInterestBPieceAmount || 0.0,
      wire_target_date_amount: this.wireTargetDateAmount,
      waived_principal_interest_fees_amount: this.waivedPrincipalInterestFeesAmount,
      payoff_statement_credit_amount: this.payoffStatementCreditAmount,
      corrected_unpaid_principal_amount: this.correctedUnpaidPrincipalAmount,
      corrected_principal_reason_type: this.correctedPrincipalReasonType,
      investor_loan_amount: this.investorLoanAmount,
      investor_rate: this.investorRate,
      legal_owner_id: this.legalOwnerId,
      loan_id: this.loanId,
      statement_type: this.statementType,
      fee_type_recoverability: this.feeTypeRecoverability,
      suspense_balance: this.suspenseBalance,
      escrow_reserve_amount: this.escrowReserveAmount,
      unpaid_interest_due_from_borrower_amount: this.unpaidInterestDueFromBorrowerAmount,
      waived_unpaid_interest_due_from_borrower_amount: this
        .waivedUnpaidInterestDueFromBorrowerAmount,
      deferred_interest_amount: this.deferredInterestAmount,
      waived_deferred_interest_amount: this.waivedDeferredInterestAmount,
      corrected_interest_due_from_borrower_amount: this.correctedInterestDueFromBorrowerAmount,
      corrected_default_interest_due_from_borrower_amount: this
        .correctedDefaultInterestDueFromBorrowerAmount,
      fees: this.fees.map(fee => {
        return {
          name: fee.name,
          fee_type: fee.feeName,
          amount: fee.amount || 0,
          waived_amount: fee.waivedAmount || 0,
          incurred_date: fee.incurredDate,
          incurred_amount: fee.incurredAmount,
          fee_tracker_fee_id: fee.feeTrackerFeeId,
        };
      }),
      comments: this.comments.filter(comment => comment.comment).map(comment => {
        return { comment: comment.comment };
      }),
    };
  }

  static getComments(comments) {
    return isEmpty(comments) ? PayoffStatement.getEmptyComment() : comments;
  }

  static getFees(fees) {
    return isEmpty(fees) ? PayoffStatement.getEmptyFee() : fees;
  }

  static getEmptyComment() {
    return [
      {
        id: uniqueId(),
        comment: "",
      },
    ];
  }

  static getEmptyFee() {
    return [
      {
        id: uniqueId(),
        feeLabel: null,
        feeName: null,
        feeType: null,
        amount: "",
        waivedAmount: "",
      },
    ];
  }
}
