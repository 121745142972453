import BrokerTag from "components/tags/broker";

$(() => {
  if (!document.querySelector(".v-manage-lenders-list-controller")) {
    return;
  }

  new Vue({
    el: document.querySelector(".v-manage-lenders-list-controller"),
    components: {
      BrokerTag,
    },
  });
});
