<template>
  <base-tag size="large" :type="type">{{ status }}</base-tag>
</template>
<script>
const BADGE_TYPE = {
  draft: "default",
  processed: "success",
};
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    type() {
      return BADGE_TYPE[this.status];
    },
  },
};
</script>
