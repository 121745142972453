<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content">
      <div class="modal-header">
        <h4>Add Progress Update</h4>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group control-wrapper"
               v-bind:class="{'has-error': errors.has('currentStatus')}">
            <label>Current Status</label>
            <textarea class="form-control"
                      v-model="newProgressUpdate.currentStatus"
                      v-validate="'required'"
                      name="currentStatus" />
            <span class="error control-label"
                  v-show="errors.has('currentStatus')">
              Current status is required.
            </span>
          </div>
          <div class="form-group control-wrapper"
               v-bind:class="{'has-error': errors.has('nextSteps')}">
            <label>Analyst Next Steps</label>
            <textarea class="form-control"
                      v-model="newProgressUpdate.nextSteps"
                      v-validate="'required'"
                      name="nextSteps" />
            <span class="error control-label"
                  v-show="errors.has('nextSteps')">
              Analyst's next steps are required.
            </span>
          </div>
          <div class="form-group control-wrapper"
               v-bind:class="{'has-error': errors.has('lenderNextSteps')}">
            <label>Lender Next Steps</label>
            <textarea class="form-control"
                      v-model="newProgressUpdate.lenderNextSteps"
                      v-validate="'required'"
                      name="lenderNextSteps" />
            <span class="error control-label"
                  v-show="errors.has('lenderNextSteps')">
              Lender's next steps are required.
            </span>
          </div>
          <div class="form-group control-wrapper">
            <label>Follow-up Date</label>
            <input
              name="date"
              type="text"
              class="date-picker form-control"
              v-model="newProgressUpdate.followUpDate"
              v-datepicker
              v-mask:date />
          </div>
          <div class="form-group control-wrapper optional checkbox-group">
            <div class="checkbox-control">
              <input
                :id="'turn-off-action-on-analyst-' + loanId"
                v-model="turnOffActionOnAnalyst"
                type="checkbox"
              />
              <label :for="'turn-off-action-on-analyst-' + loanId">
                <span class="control-toggle"></span>
                Turn off "Action on Analyst"
              </label>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="btn btn-outline-dark mr-auto" @click="clearAll">
          Clear All
        </div>
        <div class="btn btn-cancel-cta"
             @click="onClose">
          Cancel
        </div>
        <div class="btn btn-action-cta" @click="save">Save</div>
      </div>
    </div>
  </modal-view>
</template>

<script>
import Ajax from "services/ajax_service";
import ModalView from "components/modal_view.vue";

export default {
  name: "new-loan-progress-update-modal",
  $_veeValidate: {
    validator: "new",
  },
  components: {
    ModalView,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    endpoint: String,
    loanId: String,
    lastProgressUpdate: Object,
  },
  data() {
    return {
      newProgressUpdate: Object.assign({}, this.lastProgressUpdate),
      isCreating: false,
      turnOffActionOnAnalyst: false,
    };
  },
  methods: {
    onClose() {
      this.$validator.reset();
      this.newProgressUpdate = Object.assign({}, this.lastProgressUpdate);
      this.turnOffActionOnAnalyst = false;
      this.$emit("close");
    },
    save() {
      if (this.isCreating) return;

      this.$validator.validateAll().then(result => {
        if (!result) return;
        this.createLoanProgressUpdate();
      });
    },
    clearAll() {
      this.newProgressUpdate = {};
      this.turnOffActionOnAnalyst = false;
    },
    createLoanProgressUpdate() {
      if (this.endpoint) {
        const messages = { onSuccess: "Loan progress update created", onError: this.onError };

        this.isCreating = true;

        return Ajax.withNotifications(messages)
          .post(this.endpoint, this.apiParams)
          .then(data => {
            this.$emit("progress-update-saved", data, this.turnOffActionOnAnalyst);
            this.onClose();
            this.isCreating = false;
          });
      }
    },
    onError({ data }) {
      return {
        message: data.error,
        status: "error",
      };
    },
  },
  computed: {
    apiParams() {
      return {
        loan_progress_update: {
          current_status: this.newProgressUpdate.currentStatus,
          follow_up_date: this.newProgressUpdate.followUpDate,
          next_steps: this.newProgressUpdate.nextSteps,
          lender_next_steps: this.newProgressUpdate.lenderNextSteps,
          turn_off_action_on_analyst: this.turnOffActionOnAnalyst,
        },
      };
    },
  },
  watch: {
    lastProgressUpdate(newValue) {
      this.newProgressUpdate = Object.assign({}, newValue);
    },
  },
};
</script>
