<template>
  <div class="block review-rates is-relative" :class="{ 'has-alert': showPreviewAlert }">
    <h1 class="title is-5">Results ({{adjustments.length}})</h1>

    <base-table v-if="hasAdjustments" :data="adjustments" hoverable paginated per-page="10" pagination-simple class="mb-5">
      <b-table-column v-slot="props" field="prevRate" label="Prev Rate">
        {{ formatPercent(props.row.prevRate) }}
      </b-table-column>
      <b-table-column v-slot="props" field="newRate" label="New Rate">
        {{ formatPercent(props.row.newRate) }}
      </b-table-column>
      <b-table-column v-slot="props" field="rateChange" label="Rate Change">
        {{ formatPercent(props.row.rateChange) }}
      </b-table-column>
      <b-table-column v-slot="props" field="status" label="Status">
        {{ underwritingStatuses[props.row.status] }}
      </b-table-column>
      <b-table-column v-slot="props" field="loanType" label="Loan Type">
        {{ programTypes[props.row.loanType] }}
      </b-table-column>
      <b-table-column v-slot="props" field="fico" label="FICO" :visible="hasFicoCondition">
        {{ props.row.fico }}
      </b-table-column>
      <b-table-column v-slot="props" field="ltv" label="LTV" :visible="hasLtvCondition">
        {{ formatPercent(props.row.ltv) }}
      </b-table-column>
      <b-table-column v-slot="props" field="state" label="State" :visible="hasStateCondition">
        {{ props.row.state }}
      </b-table-column>
      <b-table-column v-slot="props" field="loanAmount" label="Loan Amount" :visible="hasLoanAmountCondition">
        {{ formatCurrency(props.row.loanAmount) }}
      </b-table-column>
      <b-table-column v-slot="props" field="psId" label="PS ID">
        <a :href="`/manage/loans/${props.row.psId}`">{{ props.row.psId }}</a>
      </b-table-column>
    </base-table>

    <div v-else class="criteria-box">
      There are no results. Please run a query to the left to preview new interest rates.
    </div>

    <b-notification
      v-if="showPreviewAlert && !isPreviewLoading"
      aria-close-label="Close"
      @close="onAlertClose"
      class="preview-alert"
    >
      You’ve made modifications to your Search Criteria since the last preview was run. Please click "Preview" to update your results below.
    </b-notification>

    <div class="buttons is-pulled-right">
      <base-button type="primary" :disabled="!hasAdjustments" @click="downloadCsv(previewId)">
        Download CSV
      </base-button>
      <base-button type="primary" :disabled="!hasAdjustments" @click="confirmUpdate">
        Update Rates
      </base-button>
    </div>
  </div>
</template>

<script>
import { programTypes, underwritingStatuses, states } from "./utils/mappings";
import { formatPercent, formatCurrency } from "./utils/formatter";
import usePreviewStore from "./stores/preview";
import useLtvFicoGridStore from "./stores/ltv_fico_grid";
import useRuleStore from "./stores/rule";
import { mapState, mapActions } from "pinia";

export default {
  name: "preview",
  data: () => ({}),
  computed: {
    underwritingStatuses: () => underwritingStatuses,
    programTypes: () => programTypes,
    states: () => states,

    ...mapState(usePreviewStore, [
      "adjustments",
      "hasAdjustments",
      "showPreviewAlert",
      "isPreviewLoading",
    ]),
    ...mapState(usePreviewStore, { previewId: "id" }),
    ...mapState(useLtvFicoGridStore, [
      "hasFicoCondition",
      "hasLtvCondition",
      "hasStateCondition",
      "hasLoanAmountCondition",
    ]),
    ...mapState(useRuleStore, ["hasStateCondition", "hasLoanAmountCondition"]),
  },
  methods: {
    formatPercent,
    formatCurrency,

    ...mapActions(usePreviewStore, ["applyAdjustments", "downloadCsv", "hideAlert"]),

    confirmUpdate() {
      this.$buefy.dialog.confirm({
        title: "Update rates",
        message: `Are you sure?  By doing so, you’ll be modifying ${
          this.adjustments.length
        } active loans.`,
        confirmText: "Yes, Update Rates",
        type: "is-primary",
        focusOn: "cancel",
        onConfirm: () => {
          this.applyAdjustments();
        },
      });
    },
    onAlertClose() {
      this.hideAlert();
      this.$emit("close");
    },
  },
};
</script>

<style>
.dialog.modal:not(.base-modal-form):not(.base-modal) .animation-content {
  min-width: 600px;
  position: absolute;
  text-align: left;
}
.dialog.modal:not(.base-modal-form):not(.base-modal) .modal-card-body {
  padding: 30px 24px;
}
.dialog.modal:not(.base-modal-form):not(.base-modal) .modal-card-foot .button {
  font-size: 14px;
}
.review-rates .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  padding-bottom: 8px;
}
.has-alert .b-table.base-table:after {
  background: var(--color-white);
  content: "";
  display: block;
  height: calc(100% - 30px);
  opacity: 0.8;
  position: absolute;
  top: 36px;
  width: 100%;
}
.preview-alert {
  background: #ffefc2 !important;
  border-radius: 4px;
  color: var(--color-yellow-warning-dark);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  height: 89px;
  left: 245px;
  line-height: 19px;
  padding: 16px !important;
  position: absolute !important;
  top: 55px;
  width: 400px;
}
.preview-alert .media {
  width: 342px;
}
.preview-alert .delete {
  background: var(--color-yellow-warning-dark);
  max-height: 13px;
  max-width: 13px;
  min-height: 13px;
  min-width: 13px;
  right: 18px !important;
  top: 18px !important;
}
</style>
