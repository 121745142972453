const Autocomplete = PS.Models.Autocomplete;
const Datasource = Autocomplete.Datasource;
const Suggestion = Autocomplete.Suggestion;
const TextHighlighter = PS.Utils.TextHighlighter;

class Dummy extends Datasource {
  runAutocomplete(query) {
    this.applySuggestions([
      new Suggestion(
        query + " Fake 1",
        TextHighlighter.extractSubstitutions(query + " Fake 1", query)
      ),
      new Suggestion(
        query + " Fake 2",
        TextHighlighter.extractSubstitutions(query + " Fake 2", query)
      ),
    ]);
  }
}

PS.Models.Autocomplete.Datasources.Dummy = Dummy;
