<template>
  <div>
    <base-header size="large" class="pt-3 container">
      Task List Configuration
    </base-header>
    <base-hr />
    <base-tabs
      v-model="activeTab"
      :animated="false"
      size="medium"
      bordered
      class="container pb-5"
    >
      <base-tab-item label="Task List Templates">
        <template #content>
          <tasklist-templates />
        </template>
      </base-tab-item>
      <base-tab-item label="Tasks">
        <template #content>
          <loan-task-definitions />
        </template>
      </base-tab-item>
    </base-tabs>
  </div>
</template>

<script>
import LoanTaskDefinitions from "components/loan_task_definitions";
import TasklistTemplates from "components/tasklist/templates";

export default {
  components: {
    LoanTaskDefinitions,
    TasklistTemplates,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>
<style>
.manage-loan_tasks {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.manage-loan_tasks .manage-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.settings-loan-tasks {
  flex: 1;
}

.settings-loan-tasks-view {
  height: 100%;
}
</style>
