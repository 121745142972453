import PortalVue from "portal-vue";
import VueRouter from "vue-router";
import { PiniaVuePlugin } from "pinia";
import VueCompositionAPI from "@vue/composition-api"; // Pinia needs it

Vue.use(VueCompositionAPI); // Important: has to be installed before VueRouter (Pinia needs it)
Vue.use(PiniaVuePlugin);
Vue.use(PortalVue);
Vue.use(VueRouter);

// Error helpers from Vue.js to generate error messages for Bugsnag.
var classifyRE = /(?:^|[-_])(\w)/g;
var classify = function(str) {
  return str
    .replace(classifyRE, function(c) {
      return c.toUpperCase();
    })
    .replace(/[-_]/g, "");
};
var formatComponentName = function(vm, includeFile) {
  if (vm.$root === vm) {
    return "<Root>";
  }
  var name =
    typeof vm === "string"
      ? vm
      : typeof vm === "function" && vm.options
        ? vm.options.name
        : vm._isVue
          ? vm.$options.name || vm.$options._componentTag
          : vm.name;

  var file = vm._isVue && vm.$options.__file;
  if (!name && file) {
    var match = file.match(/([^/\\]+)\.vue$/);
    name = match && match[1];
  }

  return (
    (name ? "<" + classify(name) + ">" : "<Anonymous>") +
    (file && includeFile !== false ? " at " + file : "")
  );
};
var repeat = function(str, n) {
  var res = "";
  while (n) {
    if (n % 2 === 1) {
      res += str;
    }
    if (n > 1) {
      str += str;
    }
    n >>= 1;
  }
  return res;
};
var generateComponentTrace = function(vm) {
  if (vm._isVue && vm.$parent) {
    var tree = [];
    var currentRecursiveSequence = 0;
    while (vm) {
      if (tree.length > 0) {
        var last = tree[tree.length - 1];
        if (last.constructor === vm.constructor) {
          currentRecursiveSequence++;
          vm = vm.$parent;
          continue;
        } else if (currentRecursiveSequence > 0) {
          tree[tree.length - 1] = [last, currentRecursiveSequence];
          currentRecursiveSequence = 0;
        }
      }
      tree.push(vm);
      vm = vm.$parent;
    }
    return (
      "\n\nfound in\n\n" +
      tree
        .map(function(vm, i) {
          return (
            "" +
            (i === 0 ? "---> " : repeat(" ", 5 + i * 2)) +
            (Array.isArray(vm)
              ? formatComponentName(vm[0]) + "... (" + vm[1] + " recursive calls)"
              : formatComponentName(vm))
          );
        })
        .join("\n")
    );
  } else {
    return "\n\n(found in " + formatComponentName(vm) + ")";
  }
};
// End of Vue.js code

Vue.config.productionTip = false;
Vue.config.devtools = PSData.global.devTools;
if (PSData.global.captureErrors) {
  Vue.config.errorHandler = errorHandler;
}

function errorHandler(error, vm, info) {
  let location = vm ? `: ${generateComponentTrace(vm)}` : "";
  Bugsnag.notify(error, {
    severity: "error",
    metaData: {
      custom: {
        errorInfo: `Error in ${info}${location}`,
        component: vm ? vm.$options.name || vm.$options._componentTag || "app root" : undefined,
        props: vm ? vm.$options.propsData : undefined,
      },
    },
  });
}
