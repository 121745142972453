<template>
  <div class="dropdown dropdown-button requests-dropdown">
    <span
      ref="requestsDropdownLink"
      class="dropdown-toggle"
      data-toggle="dropdown"
      role="button"
      aria-haspopup="true"
      aria-expanded="false"
      @click="logToggleClick"
    >
      Requests
      <i class="fa fa-angle-down" />
    </span>

    <div class="dropdown-menu" :class="positionClass">
      <span v-for="link in activeLinks" :key="link.id">
        <a v-if="link.url" class="dropdown-item" :href="link.url" target="_blank" @click.stop="logMenuClick(link.name)">
          {{ link.name }}
        </a>
        <span v-else-if="link.emit">
          <a class="dropdown-item" href="#" @click.prevent.stop="emitDropdownAction(link.emit)">
            {{ link.name }} <br />
            <small class="last-request">Last Request: {{ link.lastRequest && link.lastRequest(loan) | missingValue }}</small>
          </a>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { isLoanResidentialBridge } from "models/lender_form/program";
import Segment from "services/segment_service";

const SERVICER_FCI = "FCI";

export default {
  name: "requests-dropdown",
  props: {
    loan: {
      type: Object,
      required: true,
    },
    isLender: Boolean,
    positionClass: String,
  },
  computed: {
    links() {
      return [
        {
          id: "payoff",
          name: "Payoff",
          emit: this.payoffRequestModal,
          show: this.showPayoff,
          lastRequest: this.lastPayoffRequest,
        },
        {
          id: "refinance",
          name: "Refinance",
          url: "https://drive.google.com/file/d/14Zh3E5nFgRYuVKN9olr5vesTOes4eQ3D/view?usp=sharing",
          show: true,
        },
        {
          id: "construction_draw",
          name: "Construction Draw",
          url: "https://drive.google.com/file/d/1jgykg6mOfvMI45-p9UHQXLXhtg5xe7Je/view?usp=sharing",
          show: this.showConstructionDraw,
        },
        {
          id: "modification",
          name: "Loan Modification",
          url: "https://peerstreet-lenders.formstack.com/forms/modification_request",
          show: this.showModification,
        },
      ];
    },
    showPayoff() {
      return false; // remove Payoff button from dropdown
      // return this.loan.servicerName === SERVICER_FCI;
    },
    showConstructionDraw() {
      return isLoanResidentialBridge(this.loan) && this.loan.constructionReserveRemaining > 0;
    },
    activeLinks() {
      return this.links.filter(link => link.show);
    },
    showModification() {
      return this.loan.isLoanModificationAllowed;
    },
  },
  methods: {
    closeDropdown() {
      $(this.$refs.requestsDropdownLink).dropdown("toggle");
    },
    emitDropdownAction(emitAction) {
      emitAction();
      this.closeDropdown();
    },
    logMenuClick(name) {
      if (this.isLender) {
        Segment.track("clickedRequestsDropdownOption", {
          loan_id: this.loan.psId,
          option_selected: name.toLowerCase(),
        });
      }
    },
    logToggleClick() {
      if (this.isLender) {
        Segment.track("clickedRequestsDropdown", {
          loan_id: this.loan.psId,
        });
      }
    },
    payoffRequestModal() {
      this.$emit("show-payoff-request-modal", this.loan);
    },
    modificationRequestModal() {
      this.$emit("show-modification-request-modal", this.loan);
    },
    lastModificationRequest(loan) {
      return loan.lastModificationRequest && loan.lastModificationRequest.creationDate;
    },
    lastPayoffRequest(loan) {
      return loan.lastPayoffRequest && loan.lastPayoffRequest.creationDate;
    },
  },
};
</script>
