<template>
  <div>
    <donut-comparison-chart
      :chart="chart"
      :sources="sources">
    </donut-comparison-chart>
  </div>
</template>

<script>
import DonutComparisonChart from "components/market_data/donut_comparison_chart";
import NumberFormatter from "models/formatters/number_formatter";

export default {
  name: "rent-versus-ownership-chart",
  components: {
    DonutComparisonChart,
  },
  props: {
    sources: Object,
  },
  data() {
    return {
      chart: {
        title: this.sources.name,
        columns: {
          hcRent: {
            color: "#5b6770",
            label: "Rent",
          },
          hcOwnership: {
            color: "#66cc33",
            label: "Owned",
          },
        },
        format: this.format,
      },
    };
  },
  methods: {
    format(value, ratio) {
      ratio = ratio * 100;
      return NumberFormatter.formatPercent(ratio);
    },
  },
};
</script>
