<template>
  <purchased group="paidOff" paid-off-only />
</template>

<script>
import Purchased from "./purchased";

export default {
  components: { Purchased },
};
</script>
