<template>
  <div class="analystsanalysts-dropdown">
    <multi-select2
      class="form-control"
      v-model="selectedTaskAssigneeIds"
      :after-render="onRendered"
      :options="{ data: orderedTaskAssignees, placeholder: 'Task Assignee' }"
    />
  </div>
</template>

<script>
import { RequestFilterLoansByTaskAssignee } from "models/reports/hotlist_loans/commands/hotlist_loans_commands";
import Event from "models/event";
import FilterParams from "models/hotlist/filter_params";
import MultiSelect2 from "components/shared/multi_select2";

const ALL_ASSIGNEES_ITEM = { id: "all", text: "Any Task Assignee" };
const UNASSIGNED_ITEM = { id: "0", text: "Unassigned" };

export default {
  name: "task-assignees-dropdown",
  components: {
    MultiSelect2,
  },
  props: {
    taskAssignees: Array,
  },
  data() {
    return {
      selectedTaskAssigneeIds: [],
    };
  },
  watch: {
    selectedTaskAssigneeIds(taskAssigneeIds) {
      new RequestFilterLoansByTaskAssignee(new Event(), {
        selectedTaskAssigneeIds: taskAssigneeIds,
      }).execute();
    },
  },
  methods: {
    onRendered() {
      this.selectedTaskAssigneeIds = FilterParams.instance.loadParams().taskAssigneeIds;
    },
  },
  computed: {
    orderedTaskAssignees() {
      return [ALL_ASSIGNEES_ITEM, UNASSIGNED_ITEM].concat(
        this.taskAssignees
          .map(a => ({ id: a.id, text: a.name }))
          .sort((a, b) => a.text.localeCompare(b.text))
      );
    },
  },
};
</script>
