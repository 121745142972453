<template>
  <div class="asset-management-attribute">
    <label class="asset-management-attribute-label">
      <with-tooltip v-if="tooltip">
        {{ label }}
        <template slot="content">
          {{ tooltip }}
        </template>
      </with-tooltip>
      <div v-else>
        {{ label }}
      </div>
    </label>
    <div class="asset-management-attribute-value">
      <slot />
    </div>
  </div>
</template>
<script>
import WithTooltip from "components/shared/with_tooltip";

export default {
  components: {
    WithTooltip,
  },
  props: {
    label: String,
    tooltip: String,
  },
};
</script>
