class CheckboxField extends PS.Models.Field {
  valueFromServer(value) {
    return value === "true";
  }

  valueToServer() {
    return this.value.toString();
  }
}

PS.Models.Fields.CheckboxField = CheckboxField;
