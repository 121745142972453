export default {
  props: {
    history: {
      type: Object,
      required: true,
    },
  },
  provide() {
    const { history } = this;

    return { history };
  },
  render() {
    return this.$scopedSlots.default({});
  },
};
