import enumerize from "../utils/enumerize";

const LoanPurpose = enumerize({
  acquisition: {
    value: "acquisition",
    title: "Acquisition",
  },
  cashOutRefinance: {
    value: "cash_out_refinance",
    title: "Cash Out Refinance",
  },
  refinance: {
    value: "refinance",
    title: "Refinance",
  },
});

export default LoanPurpose;
