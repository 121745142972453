<template>
  <banner-alert
    v-if="show"
    title="Prepay Penalty set to No"
    description="The prepay penalty for this Residential for Rent loan is set to No."
  />
</template>

<script>
import BannerAlert from "components/banners/alert";

export default {
  name: "prepay-penalty-terms-alert",
  components: {
    BannerAlert,
  },
  props: {
    show: Boolean,
  },
};
</script>
