import indefiniteArticle from "indefinite";
import toString from "lodash/toString";
import selectOptionsToMapping from "utils/select_options_to_mapping";
import LoanSponsor from "./loan_sponsor";

export default class Borrower extends LoanSponsor {
  constructor(borrower = {}, options = {}) {
    super(borrower);
    this.update(borrower);

    this.borrowerTypes = selectOptionsToMapping(options.borrowerTypes || []);
    this.states = selectOptionsToMapping(options.states || []);
    this.residencies = selectOptionsToMapping(options.residencies || []);
    this.genders = selectOptionsToMapping(options.genders || []);
    this.guarantorTrackRecords = selectOptionsToMapping(options.guarantorTrackRecords || []);
    this.maritalStatuses = selectOptionsToMapping(options.maritalStatuses || []);
  }

  get type() {
    return this.borrowerType;
  }

  set type(value) {
    this.borrowerType === value;
  }

  get fullAddress() {
    let { address, city, state, postalCode } = this.address;
    let combined = [address, city, state].filter(v => v).join(", ");

    return [combined, postalCode].filter(v => v).join(" ");
  }

  get borrowerTypeLabel() {
    return this.borrowerTypes[this.borrowerType];
  }

  get residencyLabel() {
    return this.residencies[this.residency];
  }

  get genderLabel() {
    return this.genders[this.gender];
  }

  get singlePurposeEntityYNLabel() {
    return this.yesNoOptions[this.singlePurposeEntityYN];
  }

  get personalGuarantyLabel() {
    return this.yesNoOptions[this.personalGuaranty];
  }

  get guarantorTrackRecordLabel() {
    return this.guarantorTrackRecords[this.guarantorTrackRecord];
  }

  get maritalStatusLabel() {
    return this.maritalStatuses[this.maritalStatus];
  }

  get solePropertyLabel() {
    return this.yesNoOptions[this.soleProperty];
  }

  get entityStateLabel() {
    return this.states[this.entityState];
  }

  get yesNoOptions() {
    return {
      true: "Yes",
      false: "No",
    };
  }

  get supportHMDA() {
    return this.borrowerType === "individual";
  }

  get requiredBorrowerVestingClauseFields() {
    if (this.isIndividual) {
      return ["name", "gender", "maritalStatus", "soleProperty"];
    } else if (this.isTrust) {
      return ["name", "signatories"];
    } else if (this.isEntity) {
      return ["name", "entityState"];
    } else {
      return ["borrowerType"];
    }
  }

  get hasRequiredBorrowerVestingClauseFields() {
    return this.requiredBorrowerVestingClauseFields.every(field => {
      if (Array.isArray(this[field])) {
        return this[field].length > 0;
      }

      return !!toString(this[field]);
    });
  }

  get hasRequiredSignatoryVestingClauseFields() {
    return this.signatories.slice(0, 2).every(signatory => {
      return ["name", "title"].every(field => !!signatory[field]);
    });
  }

  get isVestingClauseRefreshable() {
    if (this.isTrust) {
      return (
        this.hasRequiredBorrowerVestingClauseFields && this.hasRequiredSignatoryVestingClauseFields
      );
    }
    return this.hasRequiredBorrowerVestingClauseFields;
  }

  get vestingClauseGender() {
    const genderMap = {
      male: "man",
      female: "woman",
    };
    return genderMap[this.gender];
  }

  get vestingClauseSoleProperty() {
    const solePropertyMap = {
      male: ", as his sole and separate property",
      female: ", as her sole and separate property",
    };

    return solePropertyMap[this.gender];
  }

  get vestingClauseEntity() {
    const entityMap = {
      llc: "limited liability company",
      corporation: "corporation",
      limited_partnership: "limited partnership",
    };
    return entityMap[this.borrowerType];
  }

  get freshIndividualVestingClause() {
    let clause = "";
    clause += `${indefiniteArticle(this.maritalStatus)} ${this.vestingClauseGender}`;
    if (toString(this.soleProperty) === "true") {
      clause += `${this.vestingClauseSoleProperty}`;
    }
    return clause;
  }

  get freshTrustVestingClause() {
    const signatory_clauses = this.signatories.slice(0, 2).map(s => `${s.name} as ${s.title}`);
    return `${signatory_clauses.join(" and ")} of ${this.name}`;
  }

  get freshEntityVestingClause() {
    return `${indefiniteArticle(this.entityStateLabel)} ${this.vestingClauseEntity}`;
  }

  get freshVestingClause() {
    if (this.isIndividual) {
      return this.freshIndividualVestingClause;
    } else if (this.isTrust) {
      return this.freshTrustVestingClause;
    } else if (this.isEntity) {
      return this.freshEntityVestingClause;
    } else {
      return null;
    }
  }

  get freshLegalName() {
    if (!this.vestingClause) {
      return null;
    } else if (this.isTrust) {
      return this.vestingClause.toUpperCase();
    } else if (this.isIndividual || this.isEntity) {
      return `${this.name.toUpperCase()}, ${this.vestingClause}`;
    } else {
      return null;
    }
  }

  get objectToSave() {
    const borrowerToSave = this.toHash();

    return Object.assign(borrowerToSave, {
      id: this.id,
      single_purpose_entity_y_n: this.singlePurposeEntityYN,
      residency: this.residency,
      net_worth: this.netWorth,
      net_worth_as_of_date: this.netWorthAsOfDate,
      liquidity: this.liquidity,
      liquidity_as_of_date: this.liquidityAsOfDate,
      fico: this.fico,
      guarantor_track_record: this.guarantorTrackRecord,
      reserve_ratio: this.reserveRatio,
      personal_guaranty: this.personalGuaranty,
      bankruptcy_date: this.bankruptcyDate,
      foreclosure_date: this.foreclosureDate,
      marital_status: this.maritalStatus,
      sole_property: this.soleProperty,
      entity_state: this.entityState,
      secretary_of_state_id: this.secretaryOfStateId,
      vesting_clause: this.vestingClause,
      legal_name: this.legalName,
    });
  }

  refreshVestingClause() {
    if (this.isVestingClauseRefreshable) {
      this.vestingClause = this.freshVestingClause;
    } else {
      this.vestingClause = null;
    }
  }

  refreshLegalName() {
    this.legalName = this.freshLegalName;
  }

  toHash() {
    return {
      name: this.name,
      borrower_type: this.borrowerType,
      tin: (this.tin || "").replace(/[^\d]/g, ""),
      phone: this.phone,
      email: this.email,
      gender: this.gender,
      date_of_birth: this.dateOfBirth,
      residency: this.residency,
      address: {
        address: this.address.address,
        city: this.address.city,
        state: this.address.state,
        postal_code: this.address.postalCode,
      },
    };
  }

  update(borrower) {
    this._raw = borrower;
    this.hmdaInfoGroups = borrower.hmdaInfoGroups;
    this.id = borrower.id;
    this.name = borrower.name;
    this.borrowerType = borrower.borrowerType;
    this.tin = borrower.tin;
    this.phone = borrower.phone;
    this.gender = borrower.gender;
    this.dateOfBirth = borrower.dateOfBirth;

    this.email = borrower.email;

    this.singlePurposeEntityYN = borrower.singlePurposeEntityYN;
    this.residency = borrower.residency;
    this.netWorth = borrower.netWorth;
    this.netWorthAsOfDate = borrower.netWorthAsOfDate;
    this.liquidity = borrower.liquidity;
    this.liquidityAsOfDate = borrower.liquidityAsOfDate;
    this.fico = borrower.fico;
    this.guarantorTrackRecord = borrower.guarantorTrackRecord;
    this.reserveRatio = borrower.reserveRatio;
    this.personalGuaranty = borrower.personalGuaranty;
    this.isMarriedToOther = null;
    this.currentSpouse = null;

    this.bankruptcyDate = borrower.bankruptcyDate;
    this.foreclosureDate = borrower.foreclosureDate;

    this.entityState = borrower.entityState;
    this.legalName = borrower.legalName;
    this.maritalStatus = borrower.maritalStatus;
    this.vestingClause = borrower.vestingClause;
    this.secretaryOfStateId = borrower.secretaryOfStateId;
    this.soleProperty = borrower.soleProperty;
    this.signatories = borrower.signatories;

    this.address = { ...borrower.address };
  }

  reset() {
    this.update(this._raw);
  }
}
