<template>
  <base-modal-form
    :show="show"
    :save-disabled="isSaving"
    title="Confirm Reversal"
    class="reversal-modal"
    save-button-name="Reverse"
    @close="onClose"
    @save="submit"
  >
    Select a reason and confirm. Confirming will reverse the original transaction.

    <div class="row">
      <div class="col-6 form-group">
        <base-select-field
          id="reversal-reason-select"
          v-model="reason"
          v-validate="'required'"
          name="reasons-dropdown"
          rules="required"
          label="Reversal reason"
          :options="reasons"
          show-empty
          class="column"
        />
      </div>

      <div class="col-6 form-group">
        <template v-if="reason == 0">
          <base-text-field
            key="other-reason-input"
            v-model="otherReason"
            v-validate="{ required: isOtherReasonRequired }"
            :errors="errors.first('otherReason')"
            name="otherReason"
            label="Other reason"
            class="column"
          />
        </template>
      </div>
    </div>
  </base-modal-form>
</template>

<script>
import Ajax from "services/ajax_service";

export default {
  inject: ["$validator"],
  $_veeValidate: {
    validator: "new",
  },
  components: {},
  props: ["owner", "item"],
  data() {
    return {
      show: true,
      isSaving: false,
      reason: null,
      reasons: [["Other", 0]],
      otherReason: "",
    };
  },
  computed: {
    isOtherReasonRequired() {
      return this.reason != null;
    },
  },
  created() {
    this.loadReasons();
  },
  methods: {
    loadReasons() {
      Ajax.get("/manage/modification_reasons?type=ReversalReason")
        .then(data => {
          this.updateReasons(data);
        })
        .catch(error => {
          Ajax.sendNotification(error, "error");
        });
    },
    updateReasons(data) {
      this.reasons = data.map(item => [item.description, item.id]);
      this.reasons.push(["Other", 0]);
    },
    async createOtherReason() {
      let otherReasonId;
      let payload = {
        type: "ReversalReason",
        description: this.otherReason,
      };

      try {
        let response = await Ajax.post("/manage/modification_reasons", payload);
        otherReasonId = response.id;
      } catch (error) {
        Ajax.sendNotification(error, "error");
      }

      return otherReasonId;
    },
    onClose() {
      this.$emit("close");
    },
    async handleReason() {
      let reasonId;
      if (this.reason == 0) {
        reasonId = await this.createOtherReason();
      } else {
        reasonId = this.reason;
      }
      return reasonId;
    },
    async createReversal() {
      this.isSaving = true;
      let reasonId = await this.handleReason();

      const messages = {
        onError: ({ response }) => {
          if (response.status === 403) {
            return { message: "Unauthorized", status: "error" };
          } else {
            return { message: response.error, status: "error" };
          }
        },
      };

      Ajax.withNotifications(messages)
        .patchJSON(
          `/manage/cash_receipt_items/${this.item.id}/reverse?modification_reason_id=${reasonId}`
        )
        .then(item => {
          let redirectPath = `/manage/cash_receipts/ready?dateType=today&loanId=${
            this.item.loan.id
          }`;
          const ownerName = item.primaryOwners[0].name;

          if (ownerName) {
            redirectPath += `&transactionId=${item.id}&ownerName=${ownerName}`;
          }

          window.location.href = redirectPath;
        })
        .finally(() => {
          this.isSaving = false;
          this.show = false;
        });
    },
    async submit() {
      try {
        let validated = await this.$validator.validateAll();
        if (validated) {
          await this.createReversal();
        }
      } catch (error) {
        Ajax.sendNotification(error, "error");
      }
    },
  },
};
</script>

<style scoped>
.is-primary {
  background-color: #ee4444;
  opacity: 0.4;
}
</style>
