<template>
  <div class="creditbox-settings-rules">
    <h3 class="card-title">
      <label>Rules</label>
    </h3>
    <draggable :list="ruleset">
      <div v-for="(ruleId, index) in ruleset" :key="ruleId" class="list-group-item creditbox-settings-rule-list-item">
        <div class="creditbox-settings-rule-name">
          {{ rules.find(rule => rule.id == ruleId).name }}
        </div>
        <div class="creditbox-settings-rule-actions">
          <button
            title="Remove rule"
            type="button"
            class="btn btn-secondary btn-sm"
            @click="deleteRule(index)"
          >
            <i class="fas fa-trash-alt" />
          </button>
          <button
            v-if="index > 0"
            type="button"
            title="Move up"
            class="btn btn-secondary btn-sm"
            @click="moveUp(index)"
          >
            <i class="fas fa-arrow-up" />
          </button>
          <button
            v-if="index < ruleset.length - 1"
            title="Move down"
            type="button"
            class="btn btn-secondary btn-sm"
            @click="moveDown(index)"
          >
            <i class="fas fa-arrow-down" />
          </button>
        </div>
      </div>
    </draggable>
    <div class="list-group-item">
      <div class="input-group">
        <input v-model="ruleToAdd" v-text-autocomplete="rulesNames" class="form-control" placeholder="Enter rule name..." />
        <div class="input-group-append">
          <button
            type="button"
            title="Add rule"
            class="btn btn-secondary btn-sm"
            @click="addRule"
          >
            <i class="fas fa-plus-square" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
  name: "creditbox-settings-rules",
  components: { draggable },
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    ruleset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rulesNames: this.rules.filter(rule => !this.ruleset.includes(rule.id)).map(rule => rule.name),
      ruleToAdd: "",
    };
  },
  methods: {
    addRule() {
      const rule = this.rules.find(rule => rule.name === this.ruleToAdd);

      if (rule) {
        this.ruleset.push(rule.id);
        this.ruleToAdd = "";
      }
    },
    deleteRule(index) {
      const confirmMessage = "Are you sure you want to delete this rule?";

      if (window.confirm(confirmMessage)) {
        Vue.delete(this.ruleset, index);
      }
    },
    moveUp(index) {
      const ruleId = this.ruleset[index];

      Vue.set(this.ruleset, index, this.ruleset[index - 1]);
      Vue.set(this.ruleset, index - 1, ruleId);
    },
    moveDown(index) {
      const ruleId = this.ruleset[index];

      Vue.set(this.ruleset, index, this.ruleset[index + 1]);
      Vue.set(this.ruleset, index + 1, ruleId);
    },
  },
};
</script>
