<template>
  <div>
    <header v-if="browserIsOutdated" class="lender-header browser-update">
      <div class="container">
        <div class="col-md-12">
          <h1>
            <template v-if="browserIsAccepted">
              <strong>
                Your
                <span class="browser-name">{{ currentBrowserName }}</span>
                web browser is out of date. Update your browser for the best experience on this
                site.
              </strong>
              <a
                class="update-btn download-button btn-action-cta"
                :href="suggestedBrowser.updateUrl"
              >
                Update Browser
              </a>
            </template>
            <template v-if="!browserIsAccepted">
              <strong>
                For the best user experience on this site, please use the Chrome web browser.
              </strong>
              <a
                class="update-btn download-button btn-action-cta"
                rel="noopener noreferrer"
                target="_blank"
                :href="suggestedBrowser.downloadUrl"
              >
                Download Chrome
              </a>
            </template>
            <button class="update-btn btn-cancel-cta" @click="postponeNotification">
              Ignore
            </button>
          </h1>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import moment from "moment";

const STORAGE_KEY = "PS::BROWSER_UPDATE::NOTIFY_ME_AFTER";
const POSTPONE_PERIOD_DAYS = 30;

export default Vue.component("browser-update", {
  data() {
    const notifyMeAfter = PS.Services.LocalStorageService.getItem(STORAGE_KEY) || "2017-01-01";
    const notifyMeAfterDate = new Date(notifyMeAfter);

    return {
      showNotification: new Date() > notifyMeAfterDate,
    };
  },
  methods: {
    postponeNotification() {
      const notifyMeAfter = moment()
        .add(POSTPONE_PERIOD_DAYS, "days")
        .format();
      PS.Services.LocalStorageService.setItem(STORAGE_KEY, notifyMeAfter);

      this.showNotification = false;
    },
  },
  computed: {
    currentBrowserName() {
      return PS.Services.BrowserUpdateService.currentBrowserName;
    },
    currentBrowserVersion() {
      return PS.Services.BrowserUpdateService.currentBrowserVersion;
    },
    browserIsOutdated() {
      return this.showNotification && PS.Services.BrowserUpdateService.browserIsOutdated;
    },
    suggestedBrowser() {
      return PS.Services.BrowserUpdateService.suggestedBrowser;
    },
    browserIsAccepted() {
      return PS.Services.BrowserUpdateService.browserIsAccepted;
    },
  },
});
</script>
