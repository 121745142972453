<template>
  <div>
    <loan-boarding-reconcile
      :collection="currentCollection"
      :routes="routes"
      :options="options"
      @updated="updateCollections"
    />

    <loan-boarding-post-boarding
      :collection="currentPostBoardedCollection"
      :routes="routes"
      :options="options"
      @updated="updateCollections"
    />
  </div>
</template>
<script>
import LoanBoardingSectionMixin from "components/servicer/loan_boarding/mixin/section";
import LoanBoardingReconcile from "components/servicer/loan_boarding/reconcile";
import LoanBoardingPostBoarding from "components/servicer/loan_boarding/post_boarding";

export default {
  name: "ManageServicerLoadBoardingSectionsReconcile",
  components: { LoanBoardingReconcile, LoanBoardingPostBoarding },
  mixins: [LoanBoardingSectionMixin],
  computed: {
    lastUpdatedAt() {
      return this.getLastUpdatedAt(this.currentCollection);
    },
    currentCollection() {
      let collection = this.collections.flagged;
      return this.filterCollectionForServicer(collection);
    },
    currentPostBoardedCollection() {
      let collection = this.collections.postBoarded;
      return this.filterCollectionForServicer(collection);
    },
    count() {
      return this.currentCollection.length + this.collections.postBoarded.length;
    },
  },
  created() {
    this.emitSectionData();
  },
  methods: {
    emitSectionData() {
      this.$emit("loaded", {
        section: "reconcile",
        count: this.count,
        lastUpdatedAt: this.lastUpdatedAt,
      });
    },
  },
};
</script>
