export default class DocumentUpload {
  constructor(upload) {
    this.status = upload.status;
    return this.uploadStatusType(upload);
  }

  uploadStatusType(upload) {
    if (this.status == "processing") {
      return new DocumentUploadProcessing(upload);
    } else if (this.status == "success") {
      return new DocumentUploadSuccess(upload);
    } else if (this.status == "failed") {
      return new DocumentUploadFailed(upload);
    } else if (this.status == "supervision") {
      return new DocumentUploadSupervision(upload);
    } else {
      return new DocumentUploadBaseStatus(upload);
    }
  }
}

class DocumentUploadBaseStatus {
  constructor(upload) {
    this.id = upload.id;
    this.status = upload.status;
    this.submissionVendorId = upload.submissionVendorId;
    this.updatedAt = upload.updatedAt;
    this.uploadedAt = upload.uploadedAt;
    this.fileName = upload.fileName;
  }

  get title() {
    return "";
  }

  get message() {
    return "";
  }

  get statusText() {
    return "";
  }

  get fileNameText() {
    return `Filename: ${this.fileName}`;
  }

  get submissionVendorIdText() {
    return `Hyperscience Submission ID: ${this.submissionVendorId}`;
  }
}

class DocumentUploadSuccess extends DocumentUploadBaseStatus {
  get title() {
    return "Data successfully extracted";
  }

  get message() {
    return "Hyperscience successfully completed data extraction from document upload.";
  }

  get statusText() {
    return `Completed at ${this.updatedAt}`;
  }
}

class DocumentUploadFailed extends DocumentUploadBaseStatus {
  get title() {
    return "Data extraction failed";
  }

  get message() {
    return "Hyperscience failed to complete data extraction from document upload.";
  }

  get statusText() {
    return `Failed at ${this.updatedAt}`;
  }
}

class DocumentUploadSupervision extends DocumentUploadBaseStatus {
  get title() {
    return "Needs supervision";
  }

  get message() {
    return "Hyperscience needs human supervision for the document to be extracted. Please go to Hyperscience and complete the tasks for this submission.";
  }

  get statusText() {
    return `Updated at ${this.updatedAt}`;
  }
}

class DocumentUploadProcessing extends DocumentUploadBaseStatus {
  constructor(upload) {
    super(upload);
    this.status = "processing";
  }

  get title() {
    return "Data is being extracted";
  }

  get message() {
    return "Hyperscience is extracting data from an uploaded document and will populate fields once complete. Please do not enter values until processing is completed.";
  }

  get statusText() {
    return `Uploaded at ${this.uploadedAt}`;
  }

  get submissionVendorIdText() {
    return "";
  }
}
