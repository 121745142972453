<template>
  <div class="nav-menu clearfix">
    <ul class="nav-underlined">
      <li v-for="(label, key) in tabs" :key="key" :class="classObject(key)">
        <a href="#" @click.prevent="handleTabChange(key)">
          {{ label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
const TABS = {
  pipeline: "Pipeline",
  purchased: "Purchased",
  withdrawn: "Withdrawn/Declined",
  paidOff: "Paid Off",
};

export default {
  props: {
    tabs: {
      type: Object,
      default: () => TABS,
    },
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    classObject(key) {
      return {
        active: this.value === key,
      };
    },
    handleTabChange(key) {
      this.$emit("input", key);
    },
  },
};
</script>
