<template>
  <div>
    <restriction-header
      :name="alias"
      :restrictionsRoute="restrictionsRoute"
    />
    <restriction-loan-list
      :details="details"
    />
  </div>
</template>

<script>
import RestrictionHeader from "components/manage/restriction/header";
import RestrictionLoanList from "components/manage/restriction/loan_list";
import get from "lodash/get";

export default {
  name: "restriction-details",
  components: {
    RestrictionHeader,
    RestrictionLoanList,
  },
  data() {
    return {
      details: PSData.details,
      restrictionsRoute: get(PSData, "routes.restrictions"),
      alias: PSData.alias,
    };
  },
};
</script>
