import moment from "moment";

export default {
  props: {
    collections: {
      type: Object,
      required: true,
    },
    transferCollections: {
      type: Object,
      required: true,
    },
    filterForServicerId: {
      type: String,
      required: false,
    },
    routes: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sections: {
        pipeline: "pipeline",
        reconcile: "reconcile",
        readyToSubmit: "readyToSubmit",
        submitted: "submitted",
      },
    };
  },
  computed: {},
  watch: {
    collections: {
      handler() {
        this.emitSectionData();
      },
      deep: true,
    },
    transferCollections: {
      handler() {
        this.emitSectionData();
      },
      deep: true,
    },
  },
  methods: {
    emitSectionData() {
      // Override this method for each section
    },
    updateCollections(collections) {
      this.$emit("updated", collections);
    },
    updateTransferCollections(collections) {
      this.$emit("transfer-updated", collections);
    },
    getLastUpdatedAt(collection) {
      if (collection.length === 0) return;

      const lastUpdatedAt = collection.map(item => new Date(item.updatedAt)).sort((a, b) => {
        return a - b;
      })[0];

      return moment(lastUpdatedAt).format("MM/DD/YYYY hh:mm");
    },
    filterCollectionForServicer(collection) {
      if (this.filterForServicerId) {
        return collection.filter(item => item.servicerEntityId == this.filterForServicerId);
      }

      return collection;
    },
  },
};
