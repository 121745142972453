<template>
  <loan-boarding-table label="Assignment Transfers" :count="count">
    <template #actions>
      <div class="servicer-loan-boarding-table-actions">
        <div class="select-all-rows">
          <check-box v-model="allSelected" :partial="isPartiallySelected" @change="toggleAll"></check-box>
        </div>
        <div class="buttons">
          <button
            type="button"
            class="btn btn-action-cta"
            @click="moveToReady"
            :disabled="isNoneSelected()">
            Move to Ready
          </button>
        </div>
        <loan-boarding-review-flag-filter :enabled="reviewFlagFilterEnabled" @changed="toggleReviewFlagFilter" />
      </div>
    </template>
    <template #guardrail>
      <loan-boarding-guardrails :collection="collection"></loan-boarding-guardrails>
    </template>
    <template #header>
      <thead>
        <th class="checkbox-header"></th>
        <th>Loan ID</th>
        <th>Subservicer ID</th>
        <th class="address">Address</th>
        <th>Review Flags</th>
        <th>Assignment<br>Date</th>
        <th>Originator</th>
        <th>Borrower</th>
        <th class="amount">Total UPB</th>
        <th class="amount">Transfer UPB</th>
        <th class="amount">Note<br>Rate</th>
        <th class="amount">Transfer<br>Rate</th>
        <th class="amount">Originator<br>Rate</th>
        <th>Paid To Date<br>At Purchase</th>
        <th>Pipeline Date</th>
      </thead>
    </template>
    <template #body>
      <tr v-for="item in collection" :key="item.id" :id="item.id">
        <td class="checkbox-column">
          <check-box v-model="item.checked" @change="toggleItem"></check-box>
        </td>
        <td>{{ item.psLoanId }}</td>
        <td>{{ item.subservicerId }}</td>
        <td class="address">
          <loan-boarding-truncated-text :value="item.address"></loan-boarding-truncated-text>
        </td>
        <td>
          <loan-boarding-review-flags :record="item"></loan-boarding-review-flags>
        </td>
        <td>{{ item.assignmentDate }}</td>
        <td>
          <loan-boarding-truncated-text :value="item.originator"></loan-boarding-truncated-text>
        </td>
        <td>
          <loan-boarding-truncated-text :value="item.borrower"></loan-boarding-truncated-text>
        </td>
        <td class="amount">{{ item.totalPrincipalBalanceAtOrigination | accounting }}</td>
        <td class="amount">{{ item.unpaidPrincipalBalance | accounting }}</td>
        <td class="amount">{{ item.noteRate | percent(4) }}</td>
        <td class="amount">{{ item.transferRate | percent(4) }}</td>
        <td class="amount">{{ item.originatorRate | percent(4) }}</td>
        <td>{{ item.paidToDateAtPurchase }}</td>
        <td>{{ item.addedToPipelineAt }}</td>
      </tr>
    </template>
  </loan-boarding-table>
</template>
<script>
import LoanBoardingTruncatedText from "components/servicer/loan_boarding/truncated_text";
import LoanBoardingListMixin from "components/servicer/loan_boarding/mixin/list";
import LoanBoardingUpdateStatusMixin from "components/servicer/loan_boarding/mixin/update_status";
import LoanBoardingReviewFlags from "components/servicer/loan_boarding/review_flags";
import LoanBoardingReviewFlagFilter from "components/servicer/loan_boarding/review_flag_filter";
import LoanBoardingGuardrails from "components/servicer/loan_boarding/guardrails";
import LoanBoardingTable from "components/servicer/loan_boarding/table";
import CheckBox from "components/shared/check_box";

export default {
  mixins: [LoanBoardingListMixin, LoanBoardingUpdateStatusMixin],
  name: "manage-servicer-loan-boarding-transfer-pipeline",
  components: {
    LoanBoardingTruncatedText,
    LoanBoardingReviewFlags,
    LoanBoardingGuardrails,
    LoanBoardingReviewFlagFilter,
    LoanBoardingTable,
    CheckBox,
  },
  props: {
    collection: Array,
    routes: Object,
  },
  watch: {
    collection() {
      this.toggleReviewFlagFilter(false);
    },
  },
  methods: {
    moveToReady() {
      this.updateStatus(this.checkedItems(), "approved").then(collections =>
        this.$emit("updated", collections)
      );
    },
  },
};
</script>
