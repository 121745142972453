<template>
  <div>
    <h4>
      Distribution Calculator
    </h4>

    <form class="form-inline" :disabled="loading" @submit.prevent="handleSubmit">
      <label class="form-label mb-2 mr-sm-2">Cash Receipt Item ID: </label>
      <input v-model="itemId" class="form-control mb-2 mr-sm-2">
      <button type="submit" class="btn btn-primary mb-2">
        Calculate
      </button>
    </form>

    <items-table v-if="item" :items="[item]" />
  </div>
</template>

<script>
import AjaxService, { ERROR_STATUS, ERROR_MESSAGE } from "services/ajax_service";
import ItemsTable from "./ready_to_distribute/details/items";

export default {
  components: {
    ItemsTable,
  },
  data() {
    return { loading: false, itemId: null, item: null };
  },
  computed: {
    url() {
      return `/manage/cash_receipt_items/${this.itemId}/calculate`;
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true;

      try {
        this.item = await AjaxService.getJSON(this.url);
      } catch ({ response, data }) {
        if (response.status === 422) {
          AjaxService.sendNotification(data.errors.join("\n"), ERROR_STATUS);
        } else {
          AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
