import format from "utils/format";

const Source = PS.Models.MarketData.Source;

class RowDetailsSource extends Source {
  constructor() {
    super();

    this.raw = {};
  }

  get formatted() {
    return Object.keys(this.raw).reduce((result, detail) => {
      result[detail] = format(this.raw[detail]);
      return result;
    }, {});
  }
}

PS.Models.MarketData.Tables.RowDetailsSource = RowDetailsSource;
