export default class Command {
  static broadcastEvent(eventClass, eventBus, eventBody = {}) {
    return new Promise((resolve, _) => {
      Vue.nextTick(() => {
        let updateEvent = new eventClass(eventBody);
        eventBus.broadcast(updateEvent);

        resolve();
      });
    });
  }

  constructor(event = {}, state = {}) {
    this.event = event;
    this.state = state;
  }

  execute() {}
}

PS.Models.Command = Command;
