<template>
  <div v-if="loading" class="ps-loader" />
  <table v-else class="table loan-associations-table">
    <thead>
      <tr>
        <th>ID</th>
        <th>Legal Owner</th>
        <th>Wire Date</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Actions</th>
      </tr>
    </thead>
    <template v-for="(ownership, key) in sortedOwnerships">
      <loan-legal-ownerships-item
        :key="key"
        :bus="bus"
        :owners="options.owners"
        :ownership="ownership"
        :disabled="disabled"
      />
    </template>
  </table>
</template>

<script>
export default Vue.component("loan-legal-ownerships-table", {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      loan: PSData.loan,
      options: {},
      newOwnership: {},
      ownerships: [],
      loading: false,
      bus: new Vue(),
      addInProcess: false,
    };
  },
  created: function() {
    this.bus.$on("update-ownership-note", this.updateNote);
  },
  beforeDestroy: function() {
    this.bus.$off();
  },
  mounted: function() {
    this.$nextTick(() => {
      this.load();
    });
  },
  computed: {
    sortedOwnerships: function() {
      return this.ownerships.sort(PS.Models.LegalOwnership.dateSort);
    },
  },
  methods: {
    load: function() {
      this.loading = true;
      PS.Services.LegalOwnershipService.all(this.loan)
        .then(data => {
          this.ownerships = data.legalOwnerships.map(el => {
            return new PS.Models.LegalOwnership(el);
          });
          this.options = data.options;
          this.newOwnership = new PS.Models.LegalOwnership({
            ownerId: this.options.owners[0].ownerId,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update: function(ownership, reload = true) {
      PS.Services.LegalOwnershipService.update(ownership)
        .then(data => {
          var updatedOwnership = new PS.Models.LegalOwnership(data);
          this.replaceInList(ownership, updatedOwnership);
          if (reload) {
            this.reloadPage();
          }
        })
        .catch(() => {
          ownership.reset();
        });
    },
    updateNote: function(ownership) {
      this.update(ownership, false);
    },
    addToList: function(ownership) {
      this.ownerships.push(ownership);
    },
    replaceInList: function(ownership, newOwnership) {
      var index = this.ownerships.indexOf(ownership);
      Vue.set(this.ownerships, index, newOwnership);
    },
    reloadPage: function() {
      // some browser will not reload page if anchor exists
      let url = location.href.split("#")[0];
      location = url;
    },
  },
});
</script>
