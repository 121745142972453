const Source = PS.Models.MarketData.Analytics.Source;

class MidasSource extends Source {
  success(crossSection, timeSeries) {
    crossSection = crossSection || {};
    timeSeries = timeSeries || {};

    this.completedAt = crossSection.recordedAt;

    this.forecastedCumulativeReturn["YoY"] = crossSection.returnsYoy;
    this.forecastedCumulativeReturn["12"] = crossSection.returns12Month;
    this.forecastedCumulativeReturn["24"] = crossSection.returns24Month;
    this.forecastedCumulativeReturn["36"] = crossSection.returns36Month;

    this.maxRollingDecline["3"] = timeSeries.rolling3MaxDecline;
    this.maxRollingDecline["6"] = timeSeries.rolling6MaxDecline;
    this.maxRollingDecline["9"] = timeSeries.rolling9MaxDecline;
    this.maxRollingDecline["12"] = timeSeries.rolling12MaxDecline;
    this.maxRollingDecline["15"] = timeSeries.rolling15MaxDecline;
    this.maxRollingDecline["18"] = timeSeries.rolling18MaxDecline;
    this.maxRollingDecline["21"] = timeSeries.rolling21MaxDecline;
    this.maxRollingDecline["24"] = timeSeries.rolling24MaxDecline;
    this.maxRollingDecline["27"] = timeSeries.rolling27MaxDecline;
    this.maxRollingDecline["30"] = timeSeries.rolling30MaxDecline;
    this.maxRollingDecline["33"] = timeSeries.rolling33MaxDecline;
    this.maxRollingDecline["36"] = timeSeries.rolling36MaxDecline;

    this.forecastedCumulativeReturn = this.toPercent(this.forecastedCumulativeReturn);
    this.maxRollingDecline = this.toPercent(this.maxRollingDecline);

    super.success();
  }

  toPercent(dataSet) {
    return Object.keys(dataSet).reduce((acc, key) => {
      acc[key] = dataSet[key] * 100;
      return acc;
    }, {});
  }
}

PS.Models.MarketData.Analytics.MidasSource = MidasSource;
