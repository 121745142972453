<template>
  <select-input
    v-model="innerValue"
    label="Interest Calculation"
    name="interestCalculationMethod"
    rules="required"
    :options="$options.selectOptions"
    :disabled="disabled"
  />
</template>

<script>
import find from "lodash/find";
import SelectInput from "./select_input";

export default {
  components: {
    SelectInput,
  },
  props: {
    legalOwnerId: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    disabled: Boolean,
  },
  data() {
    return { innerValue: this.value };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.innerValue = val;
      },
    },
    innerValue: {
      immediate: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
    legalOwnerId: {
      immediate: true,
      handler(val) {
        const owner = find(PSData.legalOwners, { id: parseInt(val, 10) });
        if (owner) {
          this.innerValue = owner.servicingReleasedInterestCalculation || "";
        }
      },
    },
  },
  selectOptions: [
    { id: "", text: "Select a method..." },
    { id: "thirty_360", text: "Thirty / 360" },
    { id: "actual_360", text: "Actual / 360" },
  ],
};
</script>
