<template>
  <div class="alert alert-danger loan-constraints-violation-alert notification-message" v-if="showAlert">
    <div><i class="fas fa-times-circle fa-lg"></i></div>
    <div>
      <h3>PeerStreet Restriction. Do not buy/fund.</h3>
      <p v-for="message in violationMessages">{{message}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "usury-violation-alert",
  props: {
    rules: Array,
  },
  computed: {
    showAlert() {
      return this.violationMessages.length > 0;
    },
    violationMessages() {
      return this.rules.map(rule => rule.message);
    },
  },
};
</script>
