$(() => {
  const BusService = PS.Services.MessageBusService;
  const Commands = PS.Models.Loan.Commands.Periscope;
  const Event = PS.Models.Event;

  new Vue({
    el: document.querySelector(".v-manage-dashboard-controller"),
    data: function() {
      return {
        bus: BusService.loanBus,
        rulebook: undefined,

        periscopeSettings: {
          border: "off",
          visible: ["daterange", "analyst"],
          aggregation: "monthly",
          dashboard: "loans_dashboard",
          filters: [{ name: "analyst", value: `${PSData.user.name}` }],
        },
      };
    },
    mounted: function() {
      const event = new Event(this.periscopeSettings);
      new Commands.RequestDashboard(event, {}).execute();
    },
  });
});
