<template>
  <modal-form
    class="doc-tracking-modal"
    title="Upload Document"
    save-button-name="Upload"
    :save-disabled="saveDisabled"
    :show="modalOpen"
    @on-close="$emit('close-modal')"
    @on-save="$emit('upload-form-submitted', formData)"
  >
    <form>
      <div class="form-group control-wrapper">
        <label for="file">File</label>
        <div id="file">{{ filename }}</div>
      </div>
      <div class="form-group control-wrapper">
        <label for="document">Document</label>
        <select
          id="document"
          v-model="formData.documentId"
          class="form-control"
        >
          <option disabled selected value="">
            Select a Document Type...
          </option>
          <option v-for="doc in uploadableDocs" :key="doc.id" :value="doc.id">
            {{ doc.name }}
          </option>
        </select>
      </div>

      <div class="form-group control-wrapper">
        <label for="suffix">Append to File Name</label>
        <input id="suffix" v-model="formData.suffix" class="form-control"></input>
      </div>
    </form>
  </modal-form>
</template>

<script>
import ModalForm from "components/modal_form.vue";

export default {
  name: "UploadModal",
  components: {
    ModalForm,
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: true,
    },
    filename: {
      type: String,
      default: "Missing File",
    },
  },
  data() {
    return {
      documents: PSData.trackingDocuments || [],
      formData: {
        documentId: "",
        suffix: "",
      },
    };
  },
  computed: {
    uploadableDocs() {
      if (!this.documents) return [];

      return this.documents.filter(doc => doc.canUpload);
    },
    saveDisabled() {
      return !this.formData.documentId;
    },
  },
};
</script>
