<template>
  <div class="asset-management-table">
    <table class="table">
      <thead>
        <tr>
          <th>Modification</th>
          <th>Old</th>
          <th>New</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <row
          v-for="modification in modificationsList"
          :key="modification.keyId"
          :item="modification"
          :is-exercised="isExercised"
          :selected-options="selectedOptions"
          :default-values="defaultValues"
          @change="updateValue(modification.keyId, $event)"
          @delete="deleteRow(modification.keyId)"
        />
        <tr v-if="canAddNewModifications">
          <td colspan="4">
            <span class="action-link" @click="addNewModification">
              <span :class="{'action-control': noItems}">
                + Add Modification
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Row from "./modification_options_table_form/row";
import uniqueId from "lodash/uniqueId";
import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";

const AVAILABLE_MODIFICATION_TYPES = ["maturityDate", "paidToDate", "paymentAmount"];

const prefixedKey = (prefix, key) => camelCase(`${prefix}_${snakeCase(key)}`);

export default {
  components: {
    Row,
  },
  inject: ["$validator"],
  $_veeValidate: {
    value() {
      return this.modificationsList.filter(m => m.typeId);
    },
    events: "",
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isExercised: Boolean,
  },
  data() {
    return {
      modificationsList: this.extractList(),
      defaultValues: {
        maturityDate: PSData.loan.extendedMaturityDate,
        paymentAmount: PSData.loan.totalMonthlyPayment,
      },
    };
  },
  methods: {
    addNewModification() {
      this.modificationsList.push({ keyId: uniqueId() });
    },
    extractList() {
      const values = AVAILABLE_MODIFICATION_TYPES.map(type => ({
        keyId: uniqueId(),
        typeId: type,
        toValue: this.item[prefixedKey("new", type)],
        fromValue: this.item[prefixedKey("old", type)],
      }));
      return values.filter(value => value.toValue || value.fromValue);
    },
    updateValue(keyId, value) {
      const modificationIndex = this.modificationsList.findIndex(m => m.keyId === keyId);
      if (modificationIndex !== -1) {
        this.$set(this.modificationsList, modificationIndex, value);
      }
    },
    deleteRow(keyId) {
      const modificationIndex = this.modificationsList.findIndex(m => m.keyId === keyId);
      this.modificationsList.splice(modificationIndex, 1);
    },
  },
  watch: {
    modificationsList: {
      handler(newList) {
        AVAILABLE_MODIFICATION_TYPES.forEach(type => {
          const modification = newList.find(modification => modification.typeId === type);
          let fromValue = null;
          let toValue = null;
          if (modification) {
            fromValue = modification.fromValue;
            toValue = modification.toValue;
          }
          this.$emit("change", prefixedKey("old", type), fromValue);
          this.$emit("change", prefixedKey("new", type), toValue);
        });
      },
      deep: true,
    },
  },
  computed: {
    canAddNewModifications() {
      return this.modificationsList.length < 3;
    },
    noItems() {
      return this.modificationsList.length === 0;
    },
    selectedOptions() {
      const isUsed = type => this.modificationsList.find(m => m.typeId === type);
      return AVAILABLE_MODIFICATION_TYPES.filter(isUsed);
    },
  },
};
</script>
