import MSAService from "services/msa_service";

const Autocomplete = PS.Models.Autocomplete;
const Datasource = Autocomplete.Datasource;
const Suggestion = Autocomplete.Suggestion;
const TextHighlighter = PS.Utils.TextHighlighter;
const AjaxService = PS.Services.AjaxService;

const Events = PS.Models.Reports.Events;
const Commands = PS.Models.Reports.Commands;

class MsaList extends Datasource {
  constructor(query = "", suggestions = []) {
    super(query, suggestions);

    this.hasPendingRequests = false;
    this.lastQuery = undefined;
  }

  runAutocomplete(query) {
    this.hasPendingRequests = true;
    this.lastQuery = query;

    return MSAService.listSearch(query).then(
      result => {
        if (this.hasPendingRequests && this.lastQuery !== query) {
          // improve request handling from slow MSA service — ignore all results except last one
          return;
        }
        const edges = result.data.search.edges || [];
        const nodes = edges.slice(0, 5).map(edge => edge.node);
        const suggestions = this.prepareSuggestions(nodes, query);

        this.hasPendingRequests = false;
        this.applySuggestions(suggestions);
      },
      () => {
        this.hasPendingRequests = false;
        this.applySuggestions([]);
      }
    );
  }

  prepareSuggestions(nodes, query) {
    return nodes.map(node => {
      return new Suggestion(
        node.msaName,
        TextHighlighter.extractSubstitutions(node.msaName, query || node.msaName),
        { msa: node.msa }
      );
    });
  }

  validateSuggestion(suggestion) {
    if (suggestion.options.msa !== undefined) {
      const event = new Events.MsaReport.MsaReportRequested({
        geoId: suggestion.options.msa,
        geoType: "msa",
        source: "/tools/msa_report",
      });

      new Commands.MsaReport.RequestReportLoading(event).execute();
    }
  }

  transferToReport(index) {
    const locationService = PS.Services.LocationService;
    const msa = this.suggestions[index].options.msa;
    const url = `${locationService.origin}/tools/msa_report?msa=${msa}`;

    locationService.assign(url);
  }
}

PS.Models.Autocomplete.Datasources.MsaList = MsaList;
