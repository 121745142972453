export default class Buyer {
  constructor(buyer = {}) {
    this.buyer = { ...buyer };
    this.buyer.isValidLpsUrl = true;
  }

  get id() {
    return this.buyer.id;
  }

  get label() {
    return this.buyer.label;
  }

  get externalId() {
    return this.buyer.externalId;
  }

  get lpsUrl() {
    return this.buyer.lpsUrl;
  }

  get lpsBaseUrl() {
    return this.buyer.lpsBaseUrl;
  }

  get isValidLpsUrl() {
    return this.buyer.isValidLpsUrl;
  }

  set isValidLpsUrl(value) {
    this.buyer.isValidLpsUrl = value;
  }

  get monthlyVolume() {
    return this.buyer.monthlyVolume;
  }

  get monthlyTarget() {
    return this.buyer.monthlyTarget;
  }

  get monthlyWeight() {
    return this.buyer.monthlyWeight;
  }

  get active() {
    return this.buyer.active;
  }

  setExternalId(value) {
    this.buyer.externalId = value;
  }

  setLpsUrl(value) {
    this.buyer.lpsUrl = value;
  }

  setMonthlyTarget(value) {
    this.buyer.monthlyTarget = value;
  }

  setActive(value) {
    this.buyer.active = value;
  }

  toHash() {
    return {
      id: this.buyer.id,
      external_id: this.externalId,
      monthly_target: this.monthlyTarget,
      active: this.active,
    };
  }
}
