<template>
  <div class="action-button btn-group" v-if="hasStatement">
    <button
      type="button"
      class="btn btn-secondary-action-cta dropdown-toggle"
      data-toggle="dropdown"
      :disabled="!isActionable"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Actions
      <i class="fas fa-angle-down"></i>
    </button>

    <ul class="dropdown-menu">
      <li v-for="option in enabledOptions" :key="option.label">
        <a :data-name="option.id" v-if="option.url" :href="option.url" :target="option.target || '_self'">{{ option.label }}</a>
        <a :data-name="option.id" v-else-if="option.emit" href="#" @click.prevent="option.emit" :class="option.class">
          {{ option.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import PayoffStatementActionButtonMixin, {
  STATUSES,
} from "components/manage/payoff_statement/action_button_mixin";
import { mapState } from "pinia";
import useResaleStatementStore from "controllers/manage/store/resaleStatement";

export default {
  name: "manage-resale-statement-action-button",
  mixins: [PayoffStatementActionButtonMixin],
  computed: {
    ...mapState(useResaleStatementStore, ["statement"]),
    buttonsOrdering() {
      return {
        [STATUSES.draft]: [
          this.buttons.submitForReview.id,
          this.buttons.editStatement.id,
          this.buttons.downloadPdf.id,
          this.buttons.cancelStatement.id,
        ],
        [STATUSES.pending]: [
          this.buttons.markAsReviewed.id,
          this.buttons.skipToApproved.id,
          this.buttons.refreshStatement.id,
          this.buttons.editStatement.id,
          this.buttons.downloadPdf.id,
          this.buttons.cancelStatement.id,
        ],
        [STATUSES.reviewed]: [
          this.buttons.approveStatement.id,
          this.buttons.editStatement.id,
          this.buttons.refreshStatement.id,
          this.buttons.downloadPdf.id,
          this.buttons.cancelStatement.id,
        ],
        [STATUSES.approved]: [
          this.buttons.markAsWired.id,
          this.buttons.downloadPdf.id,
          this.buttons.cancelStatement.id,
        ],
        [STATUSES.sold]: [
          this.buttons.markAsWiredShort.id,
          this.buttons.editWireDetails.id,
          this.buttons.downloadPdf.id,
        ],
        [STATUSES.wiredShort]: [
          this.buttons.generateReconciliation.id,
          this.buttons.markAsSold.id,
          this.buttons.editWireDetails.id,
          this.buttons.downloadPdf.id,
        ],
        [STATUSES.expired]: [
          this.buttons.duplicateResale.id,
          this.buttons.markAsWired.id,
          this.buttons.downloadPdf.id,
        ],
      };
    },
    buttons() {
      return {
        submitForReview: {
          id: "submitForReview",
          label: "Submit for Review",
          enabled: this.isSubmittable,
          emit: this.submit,
        },
        editStatement: {
          id: "editStatement",
          label: "Edit Statement",
          enabled: this.isEditable,
          url: this.statement.routes.edit,
        },
        editWireDetails: {
          id: "editWireDetails",
          label: "Edit Wire Details",
          enabled: this.isWireDetailsEditable,
          emit: this.editWireDetails,
        },
        downloadPdf: {
          id: "downloadPdf",
          label: "Download PDF",
          enabled: true,
          url: this.statement.routes.downloadUrl,
          target: "_blank",
        },
        cancelStatement: {
          id: "cancelStatement",
          label: "Cancel Statement",
          enabled: this.isCancelable,
          emit: this.cancel,
        },
        refreshStatement: {
          id: "refreshStatement",
          label: "Refresh Statement",
          enabled: this.isRefreshable,
          emit: this.refresh,
        },
        markAsReviewed: {
          id: "markAsReviewed",
          label: "Mark as Reviewed",
          enabled: this.isMarkableAsReviewed,
          emit: this.markAsReviewed,
        },
        markAsWired: {
          id: "markAsWired",
          label: "Mark as Wired",
          enabled: this.isMarkableAsWired,
          emit: this.markAsWired,
        },
        markAsSold: {
          id: "markAsSold",
          label: "Mark as Sold",
          enabled: this.isMarkableAsSold,
          emit: this.markAsSold,
        },
        markAsWiredShort: {
          id: "markAsWiredShort",
          label: "Mark as Wired-Short",
          enabled: this.isMarkableAsWiredShort,
          emit: this.markAsWiredShort,
        },
        approveStatement: {
          id: "approveStatement",
          label: "Approve Statement",
          enabled: this.isApprovable,
          emit: this.approve,
        },
        skipToApproved: {
          id: "skipToApproved",
          label: "Skip to Approved",
          enabled: this.isSkippable,
          emit: this.skipToApproved,
        },
        generateReconciliation: {
          id: "generateReconciliation",
          label: "Generate Reconciliation",
          enabled: this.canGenerateReconciliation,
          emit: this.generateReconciliation,
        },
        duplicateResale: {
          id: "duplicateResale",
          label: "Duplicate Loan Sale",
          enabled: this.isDuplicatable,
          url: this.statement.routes.duplicate,
        },
      };
    },
    isWireDetailsEditable() {
      return (
        [STATUSES.sold, STATUSES.wiredShort].includes(this.statement.status) &&
        this.statementPolicy.canMarkAsSoldOrWired()
      );
    },
    isMarkableAsWired() {
      return (
        [STATUSES.approved, STATUSES.expired].includes(this.statement.status) && this.isMarkable
      );
    },
    isMarkableAsWiredShort() {
      return STATUSES.sold === this.statement.status && this.isMarkable;
    },
    canGenerateReconciliation() {
      return STATUSES.wiredShort === this.statement.status && this.isModifiable;
    },
    isMarkableAsSold() {
      return STATUSES.wiredShort === this.statement.status && this.isMarkable;
    },
    isModifiable() {
      return this.statementPolicy.canModify();
    },
    isMarkable() {
      return this.statementPolicy.canMarkAsSoldOrWired();
    },
    isCancelable() {
      return this.statementPolicy.canCancel();
    },
    isSubmittable() {
      return this.statement.status === STATUSES.draft && this.isModifiable;
    },
    isRefreshable() {
      return (
        [STATUSES.pending, STATUSES.reviewed].includes(this.statement.status) &&
        this.statementPolicy.canApprove()
      );
    },
  },
  methods: {
    markAsWired() {
      if (this.isMarkableAsWired) {
        this.$emit("mark-as-wired");
      }
    },
    editWireDetails() {
      if (this.isWireDetailsEditable) {
        this.$emit("mark-as-wired");
      }
    },
    markAsWiredShort() {
      if (this.isMarkableAsWiredShort) {
        this.$emit("transition", STATUSES.wiredShort);
      }
    },
    markAsSold() {
      if (this.isMarkableAsSold) {
        this.$emit("transition", STATUSES.sold);
      }
    },
    generateReconciliation() {
      if (this.canGenerateReconciliation) {
        this.$emit("generate-reconciliation");
      }
    },
  },
};
</script>
