<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content payoff-statement-modal">
      <div class="modal-header">
        <h4>
          {{ modalLabel }}
          <span class="ps-loader ps-loader-small" v-if="isLoading"></span>
        </h4>
      </div>
      <div class="alert alert-danger" v-if="hasErrors">
        <ul>
          <li v-for="error in errors['base']" :key="error">{{ error }}</li>
        </ul>
      </div>
      <div class="modal-body">
        <form>
          <div :class="['form-group', 'control-wrapper', {'has-error': hasError('wire_date')}]">
            <editable-datepicker
              label="Wire Date"
              :value="state.wireDate"
              :callback="updateEditableField(state, 'wireDate', true)"
              :editing="true"
              :disabled="isDateDisabled"
            />
            <span class="error control-label" v-for="error in errorsFor('wire_date')" :key="error">
              Wire Date {{ error }}
            </span>
          </div>

          <div :class="['form-group', 'control-wrapper', {'has-error': hasError('wire_amount')}]">
            <div class="form-group">
              <label>Wire Amount</label>
              <div class="input-group flex-nowrap">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  class="form-control"
                  type="currency"
                  v-model="state.wireAmount"
                  v-mask:currency
                />
              </div>
              <span class="error control-label" v-for="error in errorsFor('wire_amount')" :key="error">
                Wire Amount {{ error }}
              </span>
            </div>
          </div>

          <div :class="['form-group', 'control-wrapper', {'has-error': hasError('federal_reference_id')}]">
            <div class="form-group">
              <label>Federal Reference # (optional)</label>
              <div class="input-group flex-nowrap">
                <input
                  class="form-control"
                  v-model="state.reference"
                />
              </div>
              <span class="error control-label" v-for="error in errorsFor('federal_reference_id')" :key="error">
                Federal Reference {{ error }}
              </span>
            </div>
          </div>
          <div class="form-group control-wrapper wires-list">
            <div class="wires-item">
              <span class="wires-item-label">Expected Wire</span>
              <span class="wires-item-amount">{{ expectedWireAmount | accounting(2, true) | missingValue }}</span>
            </div>
            <div class="wires-item bordered">
              <span class="wires-item-label">Wire Shortfall</span>
              <span class="wires-item-amount">{{ wireShortfallValue | accounting(2, true) | missingValue }}</span>
            </div>
            <div class="wires-item">
              <span class="wires-item-label">Above Min. Threshold</span>
              <span class="wires-item-bool">{{ aboveMinThresholdValue | yesNo | missingValue }}</span>
            </div>
          </div>

          <div v-if="statement.statementType !== 'peerstreet_repurchase'" class="form-group control-wrapper">
            <div class="radio-control js-radio-input">
              <input id="payoff-statement-status-wired"
                      v-model="state.status"
                      value="sold"
                      type="radio" />
              <label for="payoff-statement-status-wired">
                Mark as Sold
                <span class="control-toggle"></span>
              </label>

              <input id="payoff-statement-status-wired-short"
                      v-model="state.status"
                      value="wired_short"
                      type="radio" />
              <label for="payoff-statement-status-wired-short">
                Mark as Wired-Short
                <span class="control-toggle"></span>
              </label>
          </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="btn btn-cancel-modal" @click="close(false)">
          Cancel
        </div>
        <div class="btn btn-action-cta" @click="persist">
          Save
        </div>
      </div>
    </div>
  </modal-view>
</template>

<script>
import ModalView from "components/modal_view";
import EditableDatepicker from "components/shared/editable_datepicker";
import Ajax from "services/ajax_service";
import debounce from "utils/debounce";
import isEmpty from "lodash/isEmpty";
import NumberFormatter from "models/formatters/number_formatter";
import { STATUSES } from "components/manage/payoff_statement/action_button_mixin";

const unmask = value => NumberFormatter.unmaskNumber(value);
const DELAY_MS = 350;
const THRESHOLDS = { minAmount: 100 };
const isCorrectStatus = status =>
  [STATUSES.sold, STATUSES.wiredShort, STATUSES.purchased].includes(status);
const RECALCULATE_FIELDS = ["wireDate"];

export default {
  name: "manage-payoff-statement-completed-popup",
  components: { ModalView, EditableDatepicker },
  props: {
    show: Boolean,
    onClose: Function,
    statement: {
      type: Object,
      required: true,
    },
  },
  data() {
    let initialData = {
      status: this.initialStatus(),
      expectedWireAmount: undefined,
      wireDate: undefined,
      wireAmount: undefined,
      loanId: this.statement.loanId,
      correctedUnpaidPrincipalAmount: this.statement.correctedUnpaidPrincipalAmount,
      priceDiscountPremiumAmount: this.statement.priceDiscountPremiumAmount,
      payoffReasonTypeId: this.statement.payoffReasonTypeId,
    };

    if (isCorrectStatus(this.statement.status)) {
      initialData = {
        status: this.statement.status,
        wireDate: this.statement.wireDate,
        wireAmount: this.statement.wireAmount,
        expectedWireAmount: this.statement.wireExpectedAmount,
        reference: this.statement.federalReferenceId,
      };
    }

    return {
      errors: {},
      isLoading: false,
      state: initialData,
    };
  },
  computed: {
    isDateDisabled() {
      return STATUSES.purchased == this.statement.status;
    },
    modalLabel() {
      return this.statement.statementType == "peerstreet_repurchase"
        ? "Mark as Purchased"
        : "Mark as Wired";
    },
    expectedWireAmount() {
      if (!this.state.expectedWireAmount) {
        return undefined;
      }

      return parseFloat(this.state.expectedWireAmount);
    },
    wireShortfallValue() {
      if (!this.state.expectedWireAmount) {
        return undefined;
      }

      return unmask(this.state.expectedWireAmount).toFixed(2) - unmask(this.state.wireAmount || 0);
    },
    aboveMinThresholdValue() {
      if (!this.wireShortfallValue && this.wireShortfallValue != 0) {
        return undefined;
      }

      const upbFraction = unmask(this.statement.unpaidPrincipalAmount) / 100;
      const threshold = Math.min(upbFraction, THRESHOLDS.minAmount);

      return this.wireShortfallValue < threshold;
    },
    hasErrors() {
      return !isEmpty(this.errors);
    },
  },
  watch: {
    "statement.status": function(newVal) {
      if (isCorrectStatus(newVal)) {
        this.state.status = newVal;
      }
    },
  },
  methods: {
    initialStatus() {
      return this.statement.statementType == "peerstreet_repurchase"
        ? STATUSES.purchased
        : STATUSES.sold;
    },
    close(notify) {
      this.onClose(notify);
    },
    hasError(field) {
      return this.errorsFor(field).length > 0;
    },
    errorsFor(field) {
      return this.errors[field] || [];
    },
    updateEditableField(object, prop, nulifyEmptyString = false) {
      return debounce(value => {
        if (nulifyEmptyString && value === "") {
          value = null;
        }

        object[prop] = value;

        if (RECALCULATE_FIELDS.includes(prop)) {
          this.recalculate();
        }
      }, DELAY_MS);
    },
    async recalculate() {
      if (this.isLoading) return;

      this.isLoading = true;
      const url = this.statement.routes.calculateWire;
      const result = await Ajax.post(
        url,
        { payoff_estimated_date: this.state.wireDate },
        { "Content-Type": "application/json" }
      );

      if (result.interestData) {
        this.state.expectedWireAmount = result.interestData.wireTargetDateAmount;
      }

      this.isLoading = false;
    },
    async persist() {
      if (this.isLoading) return;

      this.isLoading = true;
      const url = this.statement.routes.wire;
      const resource = {
        wire_date: this.state.wireDate,
        wire_amount: this.state.wireAmount,
        wire_expected_amount: this.state.expectedWireAmount || 0,
        federal_reference_id: this.state.reference,
        status: this.state.status,
        loan_id: this.state.loanId,
        corrected_unpaid_principal_amount: this.state.correctedUnpaidPrincipalAmount,
        price_discount_premium_amount: this.state.priceDiscountPremiumAmount,
        payoff_reason_type_id: this.statement.payoffReasonTypeId,
      };
      const result = await Ajax.post(url, { resource }, { "Content-Type": "application/json" });

      this.isLoading = false;

      if (result.errors) {
        this.errors = result.errors;
      } else {
        this.errors = {};

        const data = {
          wireAmount: result.statement.wireAmount,
          wireDate: result.statement.wireDate,
          wireExpectedAmount: result.statement.wireExpectedAmount,
          federalReferenceId: result.statement.federalReferenceId,
          status: result.statement.status,
          statusLabel: result.statement.statusLabel,
        };

        this.statement.update(data);
        this.close(true);
      }
    },
  },
};
</script>
