export default class ClosingAgent {
  constructor(agent = {}) {
    this.id = agent.id;
    this.companyName = agent.companyName;
    this.firstName = agent.firstName;
    this.lastName = agent.lastName;
    this.phone = agent.phone;
    this.email = agent.email;
    this.orderNumber = agent.orderNumber;
    this.agentType = agent.agentType;
  }

  get contactName() {
    return [this.firstName, this.lastName].filter(v => v).join(" ");
  }

  toHash() {
    return {
      company_name: this.companyName,
      first_name: this.firstName,
      last_name: this.lastName,
      phone: this.phone,
      email: this.email,
      order_number: this.orderNumber,
      agent_type: this.agentType,
    };
  }
}
