class FormulaVariable {
  static parseValue(value) {
    if (value === undefined || value === null || (value.trim && value.trim() === "")) {
      return undefined;
    } else if (isNaN(Number(value))) {
      return value;
    } else {
      return Number(value);
    }
  }

  constructor(expectedUpdate) {
    this.field = expectedUpdate.field;
    this.value = undefined;
  }

  update(fieldUpdate) {
    if (this.match(fieldUpdate)) {
      this.value = FormulaVariable.parseValue(fieldUpdate.field.valueToServer());
    }
    return this.value;
  }

  match(fieldUpdate) {
    return fieldUpdate.field.name === this.field.name;
  }

  toContext() {
    var context = {};

    context[this.field.name] = this.value;
    return context;
  }

  reset() {
    this.value = undefined;
  }
}

class FormulaVariableFieldset extends FormulaVariable {
  constructor(expectedUpdate) {
    super(expectedUpdate);
    this.fieldset = expectedUpdate.fieldset;
    this.hashValue = {};
  }

  update(fieldUpdate) {
    if (!this.match(fieldUpdate)) {
      return this.value;
    }

    switch (this.fieldset.modifier) {
      case "all":
        {
          // calculate sum
          this.hashValue[fieldUpdate.fieldset.id] = fieldUpdate.field.valueToServer();

          let hashValues = Object.values(this.hashValue)
            .filter(value => value !== null)
            .map(value => Number(value) || 0);

          if (hashValues.length === 0) {
            this.value = null;
          } else {
            this.value = hashValues.reduce((total, current) => total + current, 0);
          }
        }
        break;

      case "$collection":
        {
          const fieldsetId = fieldUpdate.fieldset.id;
          const fieldName = fieldUpdate.field.name;
          const fieldValue = fieldUpdate.field.valueToServer();
          const defaultValue = {
            id: fieldsetId,
            $index: Object.keys(this.hashValue).length,
          };

          this.hashValue[fieldsetId] = this.hashValue[fieldsetId] || defaultValue;
          this.hashValue[fieldsetId][fieldName] = fieldValue;
          this.value = this.hashValue;
        }
        break;

      default:
        this.value = FormulaVariable.parseValue(fieldUpdate.field.valueToServer());
    }

    return this.value;
  }

  match(fieldUpdate) {
    if (!this.matchFieldset(fieldUpdate)) {
      return false;
    }

    if (this.fieldset.modifier === "$collection") {
      return true;
    }

    return fieldUpdate.field.name === this.field.name;
  }

  matchFieldset(fieldUpdate) {
    if (!this.fieldset) {
      return false;
    }
    if (!fieldUpdate.fieldset) {
      return false;
    }

    if (fieldUpdate.fieldset.name === this.fieldset.name) {
      switch (this.fieldset.modifier) {
        case "current":
          return fieldUpdate.fieldset.id === this.fieldset.id;

        case "all":
        case "$collection":
          return true;

        default:
          return false;
      }
    } else {
      return false;
    }
  }

  toContext() {
    var context = {};
    const fieldName = this.field.name;
    const fieldsetId = this.fieldset.id;
    const fieldsetModifier = this.fieldset.modifier;
    const fieldsetName = this.fieldset.name;

    switch (fieldsetModifier) {
      case "$collection":
        {
          const collection = this.value || {};
          const collectionValues = Object.values(collection);

          if (fieldName) {
            let fieldValue;

            if (fieldName === "$index") {
              fieldValue = (collectionValues.find(item => item.id === fieldsetId) || {})[fieldName];
            } else {
              fieldValue = collectionValues.map(item => item[fieldName]);
            }

            context[fieldsetName] = {};
            context[fieldsetName][fieldsetModifier] = {};
            context[fieldsetName][fieldsetModifier][fieldName] = fieldValue;
          } else {
            context[fieldsetName] = { $collection: collectionValues };
          }
        }
        break;

      default:
        context[this.fieldset.name] = {};
        context[this.fieldset.name][this.fieldset.modifier] = {};
        context[this.fieldset.name][this.fieldset.modifier][this.field.name] = this.value;
    }

    return context;
  }

  reset() {
    super.reset();
    this.hashValue = {};
  }
}

PS.Models.FormulaVariable = FormulaVariable;
PS.Models.FormulaVariableFieldset = FormulaVariableFieldset;
