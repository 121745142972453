<template>
  <div class="msa-search-button">
    <template v-if="bannerMode">
      <a
        v-if="zip"
        class="report-link"
        rel="noopener noreferrer"
        target="_blank"
        title="View MSA Report"
        :href="msaReportLink"
        @click="trackMsaReportBanner"
      >
        <img
          class="report-item-banner"
          :src="require('images/banners/lender_tools/graphic-msa@2x.png')"
        />
        <p class="caption">
          View MSA Report
        </p>
      </a>
    </template>
    <template v-if="linkMode">
      <a
        class="report-link"
        rel="noopener noreferrer"
        target="_blank"
        title="View MSA Report"
        :href="msaReportLink"
        @click="trackMsaReportLink"
      >
        MSA Report
        <img class="chart-tool" :src="require('images/icons/chart-tool.svg')" />
      </a>
    </template>
    <template v-if="tabMode">
      <a
        class="report-link"
        title="View MSA Report"
        :href="msaReportLink"
        @click="trackMsaReportLink"
      >
        MSA Report
      </a>
    </template>
  </div>
</template>

<script>
const MessageBusService = PS.Services.MessageBusService;
const MsaReportRule = PS.Models.Reports.MsaReportRule;
const Commands = PS.Models.Reports.Commands.MsaReport;
const Events = PS.Models.Reports.Events.MsaReport;

export default Vue.component("msa-search-button", {
  props: {
    zip: String,
    mode: String,
  },
  data() {
    return {
      bus: MessageBusService.lenderBus,
      msaReportRulebook: undefined,
    };
  },
  mounted() {
    this.msaReportRulebook = new MsaReportRule(this);
    this.bus.attachRulebook(this.msaReportRulebook);
  },
  beforeDestroy() {
    this.bus.detachRulebook(this.msaReportRulebook);
  },
  computed: {
    msaReportLink() {
      return `/tools/msa_report?zip=${this.zip}`;
    },
    linkMode() {
      return this.mode === "link";
    },
    bannerMode() {
      return this.mode === "banner";
    },
    tabMode() {
      return this.mode === "tab";
    },
  },
  methods: {
    trackMsaReportLink() {
      const event = new Events.TrackMsaReportLinkRequested({
        zip: this.zip,
      });

      new Commands.TrackMsaReportLink(event, {}).execute();
    },
    trackMsaReportBanner() {
      const event = new Events.TrackMsaReportLinkRequested({
        zip: this.zip,
      });

      new Commands.TrackMsaReportBanner(event, {}).execute();
    },
  },
});
</script>
