<template>
  <activity-events-section
    v-if="loan"
    :documents="documents"
    :routes="routes"
    :loan="loan"
    :events="events"
    :has-unread-messages="hasUnreadMessages">
  </activity-events-section>
  <div v-else class="loan-quick-preview">
    <span class="quick-preview"></span>

    <h4>Quick Preview</h4>

    <p>
      Preview open tasks and recent
      <br>
      messages directly from this page
    </p>

    Click <span class="open-quick-preview"></span> to preview
  </div>
</template>

<script>
import ActivityEventsSection from "components/activity_feed_events/section";

export default {
  name: "loan-quick-preview",
  components: {
    ActivityEventsSection,
  },
  props: {
    routes: {
      type: Object,
      default: {},
    },
    loan: {
      type: Object,
      default: null,
    },
    events: {
      type: Array,
      default: [],
    },
    documents: {
      type: Array,
      default: [],
    },
    hasUnreadMessages: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
