<template>
  <div>
    <div class="mb-4 mt-3" data-test="charge-offs-breadcrumbs">
      <base-breadcrumb data-test="charge-offs-breadcrumbs" :items="breadcrumbs"/>
    </div>
    <div class="is-flex is-flex-direction-row mb-4 is-justify-content-space-between">
      <div>
        <div class="is-flex is-flex-direction-row">
          <div class="mr-3">
            <base-header size="medium">Charge-Off</base-header>
          </div>
          <badge :status="chargeOffStatement.status" />
        </div>
        <div class="section-subheader">
          <span data-test="charge-offs-updated-at">Updated {{ formatUpdatedAt(chargeOffStatement.updatedAt) }}</span>
        </div>
      </div>
      <base-dropdown data-test="charge-offs-action-button" v-if="showActionButton" type="none" position="left" label="Actions">
        <base-dropdown-item v-if="canProcessChargeOff" @click="generateTransactions">
          Generate Transactions
        </base-dropdown-item>
        <base-dropdown-item v-if="canModifyChargeOff" @click="switchToEdit">
          Edit
        </base-dropdown-item>
        <base-dropdown-item v-if="canReverseChargeOff" @click="showModal('reverseConfirmation')">
          Reverse
        </base-dropdown-item>
      </base-dropdown>
    </div>
    <errors-banner :errors="errors.base" :full-width="showMode" @close="errors.base = []" />
    <charge-off-details
      v-if="showMode"
      :loan-details="loanDetails"
      :charge-off-statement="chargeOffStatement"
    />
    <charge-off-edit
      v-else
      :loan-details="loanDetails"
      :charge-off-statement="chargeOffStatement"
      :errors="errors"
      :disable-save="isSaveDisabled"
      :disable-process="isProcessDisabled"
      @process="process"
      @save="save"
      @cancel="switchToShow"
    />
    <base-modal
      :show="modals.reverseConfirmation"
      title="Reverse Charge-Off"
      save-button-name="Confirm"
      @save="reverse"
      @close="hideModals"
    >
      <div class="pt-2 pb-6">Are you sure you want to reverse this charge-off?</div>
    </base-modal>
  </div>
</template>
<script>
import AjaxService, { SUCCESS_STATUS } from "services/ajax_service";
import RequestMixin from "./request_mixin";
import ChargeOffDetails from "./details";
import moment from "moment";
import ChargeOffEdit from "./edit";
import Badge from "./badge";
import ErrorsBanner from "./errors_banner";

const SHOW_MODE = "show";
const EDIT_MODE = "edit";

export default {
  mixins: [RequestMixin],
  components: {
    ChargeOffDetails,
    ChargeOffEdit,
    Badge,
    ErrorsBanner,
  },
  data() {
    return {
      mode: SHOW_MODE,
      chargeOffStatement: PSData.chargeOffStatement,
      chargeOffStatementPolicy: PSData.chargeOffStatementPolicy,
      errors: {},
      modals: {},
      isLoading: false,
    };
  },
  methods: {
    showModal(modalName) {
      this.$set(this.modals, modalName, true);
    },
    hideModals() {
      this.modals = {};
    },
    formatUpdatedAt(date) {
      return moment(new Date(date)).format("MM/DD/YYYY hh:mmA");
    },
    switchToEdit() {
      this.mode = EDIT_MODE;
    },
    switchToShow() {
      this.mode = SHOW_MODE;
    },
    handleResponse(response) {
      const { chargeOffStatement, chargeOffStatementPolicy } = response;
      this.chargeOffStatement = chargeOffStatement;
      this.chargeOffStatementPolicy = chargeOffStatementPolicy;
      AjaxService.sendNotification("Charge-off request was updated successfully!", SUCCESS_STATUS);
      this.hideModals();
      this.switchToShow();
    },
    generateTransactions() {
      return this.process({
        id: this.chargeOffStatement.id,
        effectiveDate: this.chargeOffStatement.effectiveDate,
        comment: this.chargeOffStatement.comment,
      });
    },
    save(chargeOffForm) {
      this.saveChargeOff(chargeOffForm);
    },
    process(chargeOffForm) {
      this.processChargeOff(chargeOffForm);
    },
    reverse() {
      this.reverseChargeOff({ id: this.chargeOffStatement.id });
    },
  },
  computed: {
    canModifyChargeOff() {
      return this.chargeOffStatementPolicy.modify.allowed;
    },
    canProcessChargeOff() {
      return this.chargeOffStatementPolicy.process.allowed;
    },
    canReverseChargeOff() {
      return this.chargeOffStatementPolicy.reverse.allowed;
    },
    showActionButton() {
      if (!this.showMode) {
        return false;
      }
      if (!this.isDraft && !this.isProcessed) {
        return false;
      }
      return this.canModifyChargeOff || this.canProcessChargeOff || this.canReverseChargeOff;
    },
    isDraft() {
      return this.chargeOffStatement.status === "draft";
    },
    isProcessed() {
      return this.chargeOffStatement.status === "processed";
    },
    isProcessDisabled() {
      return this.isLoading || !this.canProcessChargeOff;
    },
    isSaveDisabled() {
      return this.isLoading || !this.canModifyChargeOff;
    },
    showMode() {
      return this.mode === SHOW_MODE;
    },
    loanDetails() {
      return PSData.loanDetails;
    },
    servicingUrl() {
      return PSData.routes.servicing;
    },
    breadcrumbs() {
      return [{ link: this.servicingUrl, name: "Servicing" }, { name: "Charge-Off" }];
    },
  },
};
</script>
<style scoped>
.section-subheader {
  color: var(--color-grey-60);
  font-size: var(--font-size);
  font-weight: 400;
}
</style>
