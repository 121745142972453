const EVENT_KEY = "update";

class Bus {
  constructor() {
    this.bus = new Vue();
    this.subscribedRulebooks = {};
  }

  broadcast(event) {
    this.bus.$emit(EVENT_KEY, event);
  }

  subscribe(callback) {
    this.bus.$on(EVENT_KEY, callback);
  }

  unsubscribe(callback) {
    this.bus.$off(EVENT_KEY, callback);
  }

  attachRulebook(rulebook) {
    let subscriber = event => rulebook.checkAndApply(event);

    this.subscribedRulebooks[rulebook.uid] = subscriber;
    this.subscribe(subscriber);
  }

  detachRulebook(rulebook) {
    let subscriber = this.subscribedRulebooks[rulebook.uid];

    this.unsubscribe(subscriber);
    delete this.subscribedRulebooks[rulebook.uid];
  }
}

const BUSES = {
  AUTOCOMPLETE: new Bus(),
  DILIGENCE: new Bus(),
  ENTITY: new Bus(),
  FUNDING: new Bus(),
  HOTLIST: new Bus(),
  LENDER: new Bus(),
  LENDER_TAGS: new Bus(),
  LOAN: new Bus(),
  SERVICING: new Bus(),
  SETTINGS: new Bus(),
};

export default class MessageBusService {
  static get diligenceBus() {
    return BUSES.DILIGENCE;
  }

  static get loanBus() {
    return BUSES.LOAN;
  }

  static get hotlistBus() {
    return BUSES.HOTLIST;
  }

  static get settingsBus() {
    return BUSES.SETTINGS;
  }

  static get autocompleteBus() {
    return BUSES.AUTOCOMPLETE;
  }

  static get lenderBus() {
    return BUSES.LENDER;
  }

  static get servicingBus() {
    return BUSES.SERVICING;
  }

  static get fundingBus() {
    return BUSES.FUNDING;
  }

  static get lenderTagBus() {
    return BUSES.LENDER_TAGS;
  }

  static get entityBus() {
    return BUSES.ENTITY;
  }
}

PS.Services.MessageBusService = MessageBusService;
