<template>
  <div>
    <header class="instant-search-header">
      {{ title }}
    </header>
    <div class="instant-search-list">
      <div class="instant-search-list-header instant-search-list-columns">
        <div class="instant-search-list-column instant-search-list-column-header action">Action</div>
        <div
          v-for="column in resultColumns"
          :key="column.name"
          class="instant-search-list-column instant-search-list-column-header"
          :class="column.name"
        >
          {{ column.label }}
        </div>
      </div>
      <div v-if="resultData.length" class="instant-search-list-body">
        <a
          v-for="rowData in resultData"
          :key="rowData.id"
          :href="rowData.path"
          class="instant-search-list-columns"
        >
          <div class="instant-search-list-column action" @click.prevent>
            <span class="instant-search-list-column-header ">Action</span>
            <base-button type="primary" size="small" @click.prevent="markLoanAsSold(rowData)">
              Mark as Sold
            </base-button>
          </div>
          <div
            v-for="column in resultColumns"
            :key="column.name"
            class="instant-search-list-column"
            :class="column.name"
          >
            <span class="instant-search-list-column-header">{{ column.label }}</span>
            {{ rowData[column.name] }}
          </div>
        </a>
      </div>
      <div v-else class="instant-search-list-no-results">
        No <span>{{ title }}</span> found for "{{ searchQuery }}".
      </div>
    </div>
  </div>
</template>

<script>
import GlobalBusService from "services/global_bus_service";

export default {
  name: "open-auction-search-result",
  props: {
    searchQuery: {
      type: String,
      default: "",
    },
    searchResult: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    title() {
      return this.searchResult.index.label;
    },
    resultData() {
      return this.searchResult.data || [];
    },
    resultColumns() {
      return this.searchResult.properties || [];
    },
  },
  methods: {
    markLoanAsSold(rowData) {
      GlobalBusService.$emit("open-auction-open-mark-as-sold-modal", rowData);
    },
  },
};
</script>
