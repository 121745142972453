const Event = PS.Models.Event;

class FieldsetEvent extends Event {
  prepareData(options) {
    this.fieldset = options.fieldset;
  }
}

class CreateRequested extends FieldsetEvent {}

class DeleteRequested extends FieldsetEvent {}

class ClearRequested extends FieldsetEvent {}

class ValidateRequested extends FieldsetEvent {}

class DeleteAllFieldsetsRequested extends FieldsetEvent {}

class FormulaInvalidationRequested extends FieldsetEvent {}

class FieldsetCreationAnnounced extends FieldsetEvent {}

PS.Models.Diligence.Events.Fieldset = {
  CreateRequested: CreateRequested,
  DeleteRequested: DeleteRequested,
  ClearRequested: ClearRequested,
  ValidateRequested: ValidateRequested,
  DeleteAllFieldsetsRequested: DeleteAllFieldsetsRequested,
  FormulaInvalidationRequested: FormulaInvalidationRequested,
  FieldsetCreationAnnounced: FieldsetCreationAnnounced,
};
