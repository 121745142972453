<template>
  <div
    class="activity-feed-events-section-component lender"
    @mouseenter="$_preventBodyScroll"
    @mouseleave="$_enableBodyScroll"
  >
    <div class="activity-feed-header">
      <tabs ref="activity-feed-tabs-component" :tabs="sectionTabs" @tab-selected="changeTab" />
    </div>
    <div class="invisibility-wrapper activity-feed-content" :class="{ 'invisibility-reset': showEventsSection }">
      <events-section
        ref="events-section-component"
        :render-text-area="sectionTabs.messages.active"
        :display-for="'lender_loan'"
        :loan="loan"
        :events="events"
        :can-update-external-comments="true"
        :can-update-internal-comments="false"
        @activity-update="messageActivityUpdate"
      />
    </div>
    <loan-tasks
      v-show="showLoanTasksSection"
      ref="loan-tasks-component"
      :documents="documents"
      :routes="routes"
      :loan="loan"
      :upload-settings="uploadSettings"
      :can-update-loan-task-data="true"
      class="activity-feed-content"
      @activity-update="taskActivityUpdate"
    />

    <lender-loan-followers
      :routes="routes"
      :followers="loanFollowers"
      :follower-candidates="loanFollowerCandidates" />
  </div>
</template>

<script>
import EventsSection from "components/activity_feed_events/events_section";
import LoanTasks from "components/activity_feed_events/loan_tasks";
import Tabs from "components/shared/tabs";
import ActivityFeedPosition from "components/activity_feed_events/mixins/activity_feed_position_mixin";
import Ajax from "services/ajax_service";
import GlobalBusService from "services/global_bus_service";
import sizeAdjuster from "utils/size_adjuster";
import LoanFollowersService from "services/loan_followers_service";
import LenderLoanFollowers from "components/activity_feed_events/loan_followers";

export default {
  name: "activity-feed-events-section",
  components: {
    EventsSection,
    Tabs,
    LoanTasks,
    LenderLoanFollowers,
  },
  mixins: [ActivityFeedPosition],
  props: {
    documents: Array,
    routes: Object,
    loan: Object,
    events: Array,
    hasUnreadMessages: Boolean,
  },
  data() {
    return {
      additionalHeightOffset: 0,
      sectionTabs: {
        messages: { name: "Messages", active: false, hasActivity: this.hasUnreadMessages },
        loanTasks: { name: "Tasks", active: true, hasActivity: undefined },
      },
      loanFollowers: [],
      loanFollowerCandidates: [],
      uploadSettings: PSData.uploadSettings,
    };
  },
  computed: {
    loanFollowersService() {
      return new LoanFollowersService(this.routes.loanFollowers);
    },
    showEventsSection() {
      return this.sectionTabs.messages.active;
    },
    showLoanTasksSection() {
      return this.sectionTabs.loanTasks.active;
    },
    messagesWidget() {
      return this.$refs["events-section-component"].$refs["messages-input-container"];
    },
    activityFeedTabs() {
      return this.$refs["activity-feed-tabs-component"].$el;
    },
    loanTasksComponent() {
      return this.$refs["loan-tasks-component"].$refs["loan-tasks-container"];
    },
  },
  watch: {
    hasUnreadMessages(newVal) {
      this.sectionTabs.messages.hasActivity = newVal;
    },
    loan() {
      this.loadLoanFollowers();
      this.sectionTabs.messages.active = false;
      this.sectionTabs.loanTasks.active = true;
    },
    "sectionTabs.messages.active": function(newVal) {
      if (newVal) {
        if (this.sectionTabs.messages.hasActivity) {
          this.markMessagesAsRead();
        }
      }
    },
  },
  mounted() {
    this.loadLoanFollowers();
    this.getAdditionalHeightOffset();
    sizeAdjuster(this.$_adjustActivityFeedHeight);

    if (this.sectionTabs.messages.active && this.sectionTabs.messages.hasActivity) {
      this.markMessagesAsRead();
    }
  },
  methods: {
    loadLoanFollowers() {
      this.loanFollowersService.load(this);
    },
    messageActivityUpdate(value) {
      this.sectionTabs.messages.hasActivity = value;
      GlobalBusService.$emit("loan-message-activity", value);
    },
    taskActivityUpdate(value) {
      this.sectionTabs.loanTasks.hasActivity = value;
      GlobalBusService.$emit("loan-task-activity", value);
    },
    markMessagesAsRead() {
      Ajax.post(this.routes.messageViews).then(() => this.messageActivityUpdate(false));
    },
    changeTab(tab) {
      const { sectionTabs } = this;

      Object.keys(sectionTabs).forEach(key => {
        sectionTabs[key].active = key === tab;
      });
    },
    getAdditionalHeightOffset() {
      const details = document.querySelector(".loan-details-container");
      let offset = parseInt(window.getComputedStyle(details).marginTop);

      const navMenu = document.querySelector(".nav-menu");
      if (navMenu) {
        offset += parseInt(navMenu.offsetHeight);
      }

      const lenderHeading = document.querySelector(".lender-heading h3");
      if (lenderHeading) {
        offset += parseInt(lenderHeading.offsetHeight);
        offset += parseInt(window.getComputedStyle(lenderHeading).marginTop);
        offset += parseInt(window.getComputedStyle(lenderHeading).marginBottom);
      }

      this.additionalHeightOffset = offset;
    },
  },
};
</script>
