<template>
  <base-modal
    :show="show"
    title="View Task"
    class="task-mapping-preview"
    :show-save="false"
    cancel-button-name="Done"
    @close="onClose"
  >
    <div v-if="definition" class="pb-3">
      <base-header size="medium" class="pb-4">
        {{ definition.label }}
      </base-header>
      <base-tabs
        bordered
        :animated="false"
        class="task-mapping-preview-tabs"
      >
        <base-tab-item label="Details">
          <template v-slot:content>
            <details-preview :task-mapping="taskMapping" />
          </template>
        </base-tab-item>
        <base-tab-item label="Description">
          <template v-slot:content>
            <description-preview :task-mapping="taskMapping" />
          </template>
        </base-tab-item>
      </base-tabs>
    </div>
  </base-modal>
</template>
<script>
import DetailsPreview from "./details_preview";
import DescriptionPreview from "./description_preview";

export default {
  components: {
    DetailsPreview,
    DescriptionPreview,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    taskMapping: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    definition() {
      return this.taskMapping && this.taskMapping.taskDefinition;
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.task-mapping-preview >>> .modal-card {
  min-width: 600px;
  max-width: 600px;
}

.task-mapping-preview-tabs >>> .tabs {
  margin: 0 calc(var(--space-unit) * -3);
}

.task-mapping-preview-tabs >>> .tabs ul {
  padding: 0 calc(var(--space-unit) * 3);
}
</style>
