<template>
  <div class="breadcrumb-navigation">
    <div class="item" v-for="(item, index) in items" :key="index">
      <span class="separator" v-if="index">/</span>
      <a v-if="item.link" :href="item.link">{{ item.name }}</a>
      <span v-else>{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "breadcrumb-navigation",
  props: {
    items: {
      type: Array,
      required: true,
      default: [],
    },
  },
};
</script>
