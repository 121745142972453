<template>
  <div id="user-edit-modal" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 v-if="addMode">Add User</h4>
          <h4 v-else="">Edit User</h4>
        </div>
        <div class="modal-body">
          <form>
            <div
              class="form-group control-wrapper"
              :class="{ 'has-error': errors.has('first-name') }"
            >
              <label>
                First Name
              </label>
              <input
                v-model="user.firstName"
                v-validate="'required'"
                class="form-control"
                name="first-name"
              >
              <span v-show="errors.has('first-name')" class="error control-label">
                This field is required.
              </span>
            </div>
            <div
              class="form-group control-wrapper"
              :class="{ 'has-error': errors.has('last-name') }"
            >
              <label>
                Last Name
              </label>
              <input
                v-model="user.lastName"
                v-validate="'required'"
                class="form-control"
                name="last-name"
              >
              <span v-show="errors.has('last-name')" class="error control-label">
                This field is required.
              </span>
            </div>
            <div class="form-group control-wrapper" :class="{ 'has-error': errors.has('email') }">
              <label>
                Email
              </label>
              <input
                v-model="user.email"
                v-validate="'required|email'"
                class="form-control"
                name="email"
              >
              <span v-show="errors.has('email')" class="error control-label">
                {{ user.email ? "Please enter a valid email address." : "This field is required." }}
              </span>
            </div>
            <div class="form-group control-wrapper tel optional">
              <label>
                Phone Number
              </label>
              <input v-model="user.phone" v-mask:phone="" class="form-control">
            </div>
            <div class="form-group control-wrapper string optional">
              <label>
                Role
              </label>
              <input v-model="user.jobPosition" class="form-control">
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <div class="btn btn-cancel-cta" data-dismiss="modal">
            Cancel
          </div>
          <div class="btn btn-action-cta" @click="save">
            Save
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "user-edit-modal",
  $_veeValidate: {
    validator: "new",
  },
  props: {
    user: Object,
  },
  computed: {
    addMode: function() {
      return !this.user.id;
    },
  },
  mounted: function() {
    $(this.$el).on("hide.bs.modal", this.reset);
  },
  beforeDestroy: function() {
    $(this.$el).off("hide.bs.modal", this.reset);
  },
  methods: {
    reset: function() {
      this.user.reset();
      this.errors.clear();
    },
    save: function() {
      this.$validator.validateAll().then(result => {
        if (!result) return;

        if (this.addMode) {
          this.$emit("user-add", this.user);
        } else {
          this.$emit("user-update", this.user);
        }
      });
    },
    show: function() {
      $(this.$el).modal("show");
    },
    hide: function() {
      this.$validator.reset();
      $(this.$el).modal("hide");
    },
  },
};
</script>
