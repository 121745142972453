<template>
  <view-panel>
    <template slot="header">
      <editable-view-panel-header
        title="Legal Entity Information"
        :can-edit="canEdit"
        :on-edit="openEditModal"
      />
    </template>
    <table class="view-table">
      <tbody>
        <tr>
          <td class="view-table-cell-label">Entity Legal Name</td>
          <td class="view-table-cell-value">{{ entity.name }}</td>
        </tr>
        <tr v-if="showState">
          <td class="view-table-cell-label">Formation State</td>
          <td class="view-table-cell-value">{{ entity.stateLabel }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Entity Type</td>
          <td class="view-table-cell-value">{{ entity.entityTypeLabel }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Vesting Clause</td>
          <td class="view-table-cell-value">{{ entity.vestingClause }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Full Legal Name</td>
          <td class="view-table-cell-value">{{ entity.fullName }}</td>
        </tr>
        <tr>
          <td class="view-table-cell-label">Notes</td>
          <td class="view-table-cell-value note-value">{{ entity.notes }}</td>
        </tr>
      </tbody>
    </table>
    <lender-legal-entity-information-form-modal
      :type="formType"
      :show="isEditModalOpened"
      :entity="entityToSave"
      @close="closeEditModal"
      @lender-legal-entity-saved="updateEntity"
    />
  </view-panel>
</template>
<script>
import LegalEntity from "models/lender_legal_entities/entity";
import ViewPanel from "components/view_panel.vue";
import LenderLegalEntityInformationFormModal, { FORM_TYPES } from "./form_modal";
import EditableViewPanelHeader from "components/lender_legal_entities/shared/editable_view_panel_header";

export default {
  name: "lender-legal-entity-information-panel",
  components: {
    ViewPanel,
    LenderLegalEntityInformationFormModal,
    EditableViewPanelHeader,
  },
  props: {
    entity: {
      required: true,
      type: LegalEntity,
    },
    policy: {
      required: true,
      type: Object,
    },
  },
  data: function() {
    return {
      entityToSave: this.entity.copy,
      isEditModalOpened: false,
      formType: FORM_TYPES.edit,
    };
  },
  computed: {
    canEdit() {
      return this.policy.canManage;
    },
    showState() {
      return !this.entity.isEntityTypeIndividual;
    },
  },
  methods: {
    openEditModal() {
      this.isEditModalOpened = true;
    },
    closeEditModal() {
      this.isEditModalOpened = false;
      this.entityToSave.reset();
    },
    updateEntity(updatedEntity) {
      this.entity.update(updatedEntity);
      this.entityToSave.update(updatedEntity);
    },
  },
};
</script>
