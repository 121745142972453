<template>
  <div class="creditbox-settings-container">
    <creditbox-settings-form
      v-if="editMode"
      :setting="selectedSetting"
      :options="options.loanPrograms"
      :routes="routes"
      @on-cancel="cancelEditing"
      @on-update="updateSettings"
    />
    <creditbox-settings-list-table
      v-else
      :creditbox-settings="settings"
      @add-new-config="addNewConfig"
      @on-setting-edit="editSetting"
    />
  </div>
</template>

<script>
import CreditboxSettingsForm from "./form";
import CreditboxSettingsListTable from "./list";

import CreditboxSetting from "models/creditbox_settings";

export default {
  name: "creditbox-settings-list",
  components: { CreditboxSettingsForm, CreditboxSettingsListTable },
  data() {
    return {
      options: PSData.options,
      settings: PSData.settings,
      selectedSetting: undefined,
      routes: PSData.routes,
    };
  },
  computed: {
    editMode() {
      return !!this.selectedSetting;
    },
  },
  methods: {
    addNewConfig() {
      this.selectedSetting = CreditboxSetting.buildEmpty();
    },
    cancelEditing() {
      this.selectedSetting = null;
    },
    editSetting(setting) {
      this.selectedSetting = CreditboxSetting.deserialize(setting);
    },
    updateSettings(settings) {
      this.settings = settings;
      this.cancelEditing();
    },
  },
};
</script>
