<template>
  <div class="dropdown-button">
    <button
      type="button"
      class="btn-outline-grey-sm btn-actions dropdown-toggle"
      data-toggle="dropdown"
      :disabled="disabled"
    >
      <i class="fa fa-ellipsis-h" />
    </button>
    <ul class="dropdown-menu">
      <slot />
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    disabled: Boolean,
  },
};
</script>
