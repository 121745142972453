<template>
  <textarea @input="handleChange" :placeholder="placeholder" :value="value"></textarea>
</template>
<script>
import autosize from "autosize";

export default {
  props: {
    value: String,
    placeholder: {
      type: String,
      default: "",
    },
  },
  mounted() {
    autosize(this.$el);
  },
  updated() {
    autosize.update(this.$el);
  },
  destroyed() {
    let evt = document.createEvent("Event");
    evt.initEvent("autosize:destroy", true, false);

    this.$el.dispatchEvent(evt);
  },
  methods: {
    handleChange(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>
