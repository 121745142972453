const Formatter = PS.Models.NumberFormatter;

class NumericField extends PS.Models.Field {
  valueToServer() {
    return Formatter.unmaskNumber(this.value);
  }

  get hasChanges() {
    return Formatter.unmaskNumber(this.value) !== Formatter.unmaskNumber(this.rawValue);
  }
}

class NumberField extends NumericField {}
class IntegerField extends NumericField {}
class PercentageField extends NumericField {}
class CurrencyField extends NumericField {}

PS.Models.Fields.NumberField = NumberField;
PS.Models.Fields.IntegerField = IntegerField;
PS.Models.Fields.PercentageField = PercentageField;
PS.Models.Fields.CurrencyField = CurrencyField;
