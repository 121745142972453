<template>
  <div>
    <button
      class="btn-outline-grey-sm"
      @click="showModal"
    >
      + New Workflow
    </button>
    <modal
      v-if="isModalShown"
      :is-saving="isSaving"
      @save="onSave"
      @close="closeModal"
    />
  </div>
</template>
<script>
import Modal from "./modal";
import AjaxService from "services/ajax_service";
import objectToSnakeCase from "utils/object_to_snake_case";
import useAssetManagementStore from "controllers/manage/store/asset_management";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isModalShown: false,
      isSaving: false,
      errors: null,
      routes: PSData.routes || {},
    };
  },
  methods: {
    createWorkflow(formData) {
      const url = this.routes.upsertManageLoanWorkflows;
      const newWorkflowParams = objectToSnakeCase(formData);
      return AjaxService.withNotifications({
        onSuccess: "New workflow was created successfully",
        onError: "Failed to create a new workflow",
      }).patchJSON(url, newWorkflowParams);
    },
    async onSave(formData) {
      const store = useAssetManagementStore();
      try {
        this.isSaving = true;
        const workflow = await this.createWorkflow(formData);
        store.addWorkflow({ workflow });
        this.closeModal();
      } catch (err) {
        const { response, data } = err;
        if (response.status === 422) {
          this.errors = data.errors;
        }
      } finally {
        this.isSaving = false;
      }
    },
    showModal() {
      this.isModalShown = true;
    },
    closeModal() {
      this.isModalShown = false;
    },
  },
};
</script>
