<template>
  <div>
    <h1 class="section-header">
      Loan Notes
    </h1>
    <div class="history-section">
      <history-list
        :records="filteredRecords"
        :total="totalRecordsCount"
        :full-history-url="fullHistoryUrl"
      />
      <base-tabs v-if="showNoteTabs" v-model="activeTab" :animated="false" class="pt-2 note-tabs">
        <base-tab-item v-if="canAddNote" label="Servicing Note">
          <template v-slot:content>
            <comment-form
              :resource-url="routes.assetManagementLoanComments"
            />
          </template>
        </base-tab-item>
        <base-tab-item v-if="canEdit" label="Communication">
          <template v-slot:content>
            <new-outreach />
          </template>
        </base-tab-item>
      </base-tabs>
    </div>
  </div>
</template>
<script>
import AjaxService, { ERROR_STATUS, ERROR_MESSAGE } from "services/ajax_service";
import moment from "moment";
import GlobalBusService from "services/global_bus_service";
import HistoryList from "./history_section/history_list";
import CommentForm from "./comment/form";
import NewOutreach from "./history_section/new_outreach";
import useAssetManagementStore from "controllers/manage/store/asset_management";

const NUMBER_OF_LAST_RECORDS = 5;
const NUMBER_OF_DAYS_AGO = 30;

export default {
  components: {
    HistoryList,
    NewOutreach,
    CommentForm,
  },
  data() {
    return {
      activeTab: 0,
      records: [],
      routes: PSData.routes || {},
      numberOfLastRecords: NUMBER_OF_LAST_RECORDS,
      numberOfDaysAgo: NUMBER_OF_DAYS_AGO,
    };
  },
  created() {
    this.fetchLoanHistory();

    GlobalBusService.$on("comment-created", this.fetchLoanHistory);
    GlobalBusService.$on("outreach-created", this.fetchLoanHistory);
  },
  methods: {
    async fetchLoanHistory() {
      const url = `${this.routes.assetManagementLoanHistory}.json`;
      try {
        this.records = await AjaxService.get(url);
      } catch ({ data }) {
        if (data && data.errors) {
          AjaxService.sendNotification(data.errors.join("\n"), ERROR_STATUS);
        } else {
          AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
        }
      }
    },
  },
  computed: {
    fullHistoryUrl() {
      return `${this.routes.assetManagementLoanHistory}.html`;
    },
    canEdit() {
      return PSData.assetManagementCanEdit;
    },
    canAddNote() {
      const store = useAssetManagementStore();
      return store.canUpdateWorkflows;
    },
    showNoteTabs() {
      return this.canEdit || this.canAddNote;
    },
    totalRecordsCount() {
      return this.records.length;
    },
    recentRecords() {
      const daysAgo = moment().subtract(this.numberOfDaysAgo, "days");
      return this.records.filter(r => moment(r.commentDate).isSameOrAfter(daysAgo), "day");
    },
    lastRecords() {
      return this.records.slice(-this.numberOfLastRecords);
    },
    filteredRecords() {
      if (this.recentRecords.length >= this.numberOfLastRecords) {
        return this.recentRecords;
      }
      return this.lastRecords;
    },
  },
};
</script>
<style>
.section-header {
  font-size: var(--font-size-lg);
  font-weight: 600;
  line-height: 1.5em;
  margin: var(--space-unit-lg) 0 var(--space-unit-md);
}

.history-section {
  background-color: var(--color-grey-06);
  border-radius: var(--border-radius);
  padding: var(--space-unit-md);
}
</style>
