<template>
  <modal-view :show="show" :on-close="onClose" class="base-modal institution-sales-tracking-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          Critical Hold
        </h4>
      </div>
      <div class="modal-body">
        <offering-details :offering="offering"></offering-details>
        <form>
          <div class="form-group">
            <label>Reason</label>
            <select v-model="offeringForm.loanHoldReason" class="form-control">
              <option value></option>
              <option v-for="[name, label] in holdReasons" :key="name" :value="name">
                {{label}}
              </option>
            </select>
          </div>
          <div class="institution-sales-tracking-modal-usernote">
            You are about to place an indefinite hold on this loan. This can only be done by removing the hold reason in the loans details
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div></div>
        <div>
          <button class="btn btn-cancel-cta" @click="onClose">
            Cancel
          </button>
          <button class="btn btn-action-cta" @click="onSave">
            Confirm
          </button>
        </div>
      </div>
    </div>
  </modal-view>
</template>
<script>
import ModalView from "components/modal_view";
import OfferingDetails from "./offering_details";

export default {
  name: "critical-hold-modal",
  components: {
    ModalView,
    OfferingDetails,
  },
  props: {
    show: Boolean,
    offering: Object,
    holdReasons: Array,
  },
  data() {
    return {
      offeringForm: {
        nextAction: "remove",
        state: "not_offered",
        loanHoldReason: null,
      },
    };
  },
  methods: {
    onSave() {
      this.$emit("save", this.offering, this.offeringForm);
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
