<template>
  <validation-provider
    ref="provider"
    v-slot="{ errors, valid, ...rest }"
    :name="label"
    :rules="rules"
    slim
  >
    <div class="form-group">
      <label>{{ label }}</label>
      <select2
        v-model="innerValue"
        :name="name"
        class="form-control"
        :custom-options="customOptions(valid)"
        :options="{width: width, data: options}"
        :disabled="disabled"
      />

      <div v-if="valid === false" class="invalid-feedback">
        {{ errors.map(({ msg }) => msg).join(", ") }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

import Select2 from "components/shared/select2";

export default {
  components: {
    select2: Select2,
    ValidationProvider,
  },
  props: {
    rules: {
      type: [String, Object],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    width: {
      type: String,
      default: "100%",
    },
    disabled: Boolean,
  },
  data: () => ({ innerValue: "" }),
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.innerValue = val;
      },
    },
    innerValue: {
      immediate: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    customOptions(valid) {
      return { extraClasses: this.validationClass(valid) };
    },
    validationClass: valid => (valid === null ? null : valid ? "is-valid" : "is-invalid"),
  },
};
</script>
