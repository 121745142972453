<template>
  <editable-section
    header-title="Estimated Loan Resolution"
    subheading=""
    :editing="editing"
    :actions-hidden="!canEdit"
    @edit="handleEdit"
    @cancel="handleCancel"
    @update="handleUpdate"
  >
    <div class="col-6">
      <ul class="loan-block">
        <li>
          <editable-datepicker
            label="Estimated Resolution Date"
            :value="form.resolutionDate"
            :editing="editing"
            @input="value => updateForm('resolutionDate', value)"
          />
        </li>
        <li>
          <editable-currency
            label="Estimated Recoverable Value"
            :tooltip="tooltipObject('recoverableValue')"
            :value="form.recoverableValue"
            :editing="editing"
            @input="value => updateForm('recoverableValue', value)"
          />
        </li>
        <li>
          <editable-currency
            label="Estimated Recoverable Value (Manager Override)"
            :tooltip="tooltipObject('recoverableValueOverride')"
            :value="form.recoverableValueOverride"
            :editing="editing"
            @input="value => updateForm('recoverableValueOverride', value)"
          />
        </li>
      </ul>
    </div>
  </editable-section>
</template>

<script>
import EditableSection from "./editable_section";
import EditableCurrency from "./editable_currency";
import EditableDatepicker from "./editable_date";
import BaseForeclosureSection from "./base_foreclosure_section.js";

import moment from "moment";

export default {
  name: "loan-resolution",
  components: {
    EditableSection,
    EditableCurrency,
    EditableDatepicker,
  },
  extends: BaseForeclosureSection,
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formattedTime(timestamp) {
      return moment(timestamp).format("MM/DD/YYYY hh:mmA");
    },
    lastUpdatedValue(attribute) {
      const property = attribute + "LastUpdate";
      return this.form[property];
    },
    tooltipObject(attribute) {
      const lastUpdate = this.lastUpdatedValue(attribute);
      if (!lastUpdate) return null;
      const formattedLastUpdate = this.formattedTime(lastUpdate);
      return { content: `<p>Last Update: ${formattedLastUpdate}</p>` };
    },
  },
};
</script>
