<template>
  <div
    class="broker-tag"
    title="Wholesale"
    data-toggle="tooltip"
    data-placement="bottom"
    data-trigger="hover"
  >
    <div id="broker-tag-tooltip" class="broker-tag-tooltip" />
  </div>
</template>

<script>
export default {
  name: "broker-tag",
  mounted() {
    $(this.$el).tooltip({ container: "#broker-tag-tooltip" });
  },
};
</script>
