<template>
  <div class="loan-doc-checklist">
    <h3>
      Loan checklist
    </h3>
    <ul>
      <li v-for="type in orderedDocumentTypes" :key="type.id" :class="documentTypeStatus(type)">
        <span class="count">
          {{ documentCount[type.documentType] || 0 }}
        </span>
        <span class="document-name">
          {{ type.text }}
        </span>
        <span v-if="type.clarification" class="clarification">
          {{ type.clarification }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
const DocumentTypes = PS.Collections.DocumentTypes;

export default Vue.component("analyst-documents-checklist", {
  props: {
    types: Array,
    documents: Array,
    loan: Object,
  },
  data() {
    return {
      currentType: undefined,
      documentTypes: new DocumentTypes(this.types.slice()),
    };
  },
  computed: {
    orderedDocumentTypes() {
      return this.documentTypes
        .whenLoanIsEntity(this.loan.isEntity)
        .whenPropertyTypeForCommercialLoans(this.loan.propertyType)
        .whenLoanHasNoAssignments(this.loan.lenderNoteStatus).types;
    },
    documentCount() {
      let documentCount = {};

      this.documents.forEach(document => {
        documentCount[document.documentType] = documentCount[document.documentType] + 1 || 1;
      });

      return documentCount;
    },
  },
  methods: {
    documentTypeStatus(type) {
      let classes = [];

      if (this.currentType && this.currentType.name === type.name) {
        classes.push("selected");
      }

      if (this.documentCount[type.documentType] === undefined) {
        classes.push("file-missing");
      }

      return classes;
    },
  },
});
</script>
