$(() => {
  $(document).on("click", "[data-path]", e => {
    const MOUSE_WHEEL_PRESSED = 2;
    e.preventDefault();

    var element = $(e.target).closest("[data-path]");
    if (!element) {
      return;
    }

    var path = element.data("path");

    if (e.metaKey || e.ctrlKey || e.which == MOUSE_WHEEL_PRESSED) {
      var newWnd = window.open(path, "_blank");
      newWnd.opener = null;
    } else {
      window.location = path;
    }
  });
});
