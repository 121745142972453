<template>
  <div class="funding-entities">
    <div class="lender-sections-container">
      <div class="lender-section-title">
        <h2>Funding Transfer List</h2>
      </div>
    </div>
    <div class="alert alert-danger" v-if="errors">
      <ul>
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
    </div>
    <div class="row">
      <div class="col-5">
        <bank-account-list
          :bankAccounts="bankAccounts"
          :fundingEntitiesByName="fundingEntitiesByName"
        />
        <div class="funding-entities_action-buttons">
          <button
            type="button"
            class="btn btn-secondary-action-cta"
            :disabled="isLoading"
            @click="refreshFundingEntitiesList"
          >
            Refresh List
          </button>
          <button
            type="button"
            class="btn btn-action-cta"
            :disabled="isInitiatingTransfer"
            @click="initiateTransfer"
          >
            Initiate Transfer
          </button>
        </div>
      </div>
      <div class="col-7">
        <loans-list :loans="loans" />
      </div>
    </div>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import Event from "models/event";
import {
  SendLoanUpdate,
  InitiateTransfer,
  InitiateRefresh,
} from "models/funding_entities/commands";
import BankAccountList from "components/manage/funding_entities/bank_account_list";
import LoansList from "components/manage/funding_entities/loans_list";
import SeriesCollection from "collections/manage/series_collection";
import FundingEntitiesCollection from "collections/manage/funding_entities_collection";
import FundingEntitiesRule from "models/funding_entities/rules";
import MessageBusService from "services/message_bus_service";
import keyBy from "lodash/keyBy";

function selectLoansByDefault(loans) {
  return loans.map(loan => ({ ...loan, checked: true }));
}

export default {
  data: function() {
    const loans = selectLoansByDefault(SeriesCollection.groupedByLoan(PSData.series));
    const fundingEntitiesByName = keyBy(PSData.fundingEntities, "name");
    const bankAccounts = FundingEntitiesCollection.pickBankAccounts(PSData.fundingEntities);
    return {
      fundingEntitiesByName: fundingEntitiesByName,
      bankAccounts: bankAccounts,
      isLoading: false,
      isInitiatingTransfer: false,
      wireRequests: PSData.wireRequests,
      loans: loans,
      rulebook: undefined,
      messageBus: MessageBusService.settingsBus,
      errors: undefined,
    };
  },
  created: function() {
    this.rulebook = new FundingEntitiesRule(this);
    this.messageBus.attachRulebook(this.rulebook);
  },
  mounted: function() {
    new SendLoanUpdate(new Event(), {}).execute();
  },
  methods: {
    refreshFundingEntitiesList() {
      new InitiateRefresh(new Event(), {}).execute();
    },

    initiateTransfer() {
      new InitiateTransfer(new Event(), {}).execute();
    },
  },
  components: {
    BankAccountList,
    LoansList,
  },
};
</script>
