<template>
  <div class="row">
    <div class="col-12"><h5>Interest Credits</h5></div>
    <div class="col-4">
      <editable-field
        label="Lender Spread Interest Credit"
        :editing="isEditable"
        :value="statement.interestLenderSpreadAmount"
        currency
      />
    </div>
    <div class="col-8" />
    <div class="col-4">
      <editable-field
        label="Lender B-Piece Interest Credit"
        :editing="isEditable"
        :value="statement.interestBPieceAmount"
        currency
      />
    </div>
    <div class="col-4">
      <editable-field
        label="Lender B-Piece Default Interest Credit"
        :editing="isEditable"
        :value="statement.defaultInterestBPieceAmount"
        currency
      />
    </div>
    <div class="col-4" />
  </div>
</template>

<script>
import EditableField from "components/shared/editable_field.vue";

export default {
  name: "resale-statement-edit-interest",
  components: {
    EditableField,
  },
  props: ["isEditable", "statement"],
};
</script>
