<template>
  <div>
    <select2
      ref="quality-assurance-task-definition-id"
      v-model="selectedDefinitionId"
      :options="select2Options"
      @on-value-change="onValueChange"
      class="form-input"
    >
      <option
        v-for="option in definitions"
        :key="'loan-task-definition-' + option.id"
        :value="option.id"
      >
        {{ option.label }}
      </option>
    </select2>

    <div class="loan-task-buttons-container loan-task-buttons">
      <button @click="cancel" class="btn btn-link loan-task-btn">
        Cancel
      </button>
      <button
        :disabled="formIsDisabled"
        @click="submitForm"
        class="btn btn-action-cta loan-task-btn"
      >
        <slot name="submit-text"></slot>
      </button>
    </div>
  </div>
</template>

<script>
import Select2 from "components/shared/select2";

export default {
  name: "loan-tasks-quality-assurance-form",
  components: {
    Select2,
  },
  props: {
    definitions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedDefinitionId: undefined,
    };
  },
  methods: {
    onValueChange() {
      this.$emit("input", this.selectedDefinitionId);
    },
    submitForm() {
      this.$emit("form-submitted", {
        definition_id: this.selectedDefinitionId,
      });
    },
    cancel() {
      this.$emit("form-canceled");
    },
  },
  computed: {
    select2Options() {
      return {
        placeholder: "Select QA Task",
      };
    },
    formIsDisabled() {
      return this.definitions.length === 0;
    },
  },
};
</script>
