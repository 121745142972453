<template>
  <div>
    <template v-if="showPrice">
      <ul v-if="showRentalPrice">
        <li>
          Estimated Note Rate:
          <span class="price">{{noteRate}}</span>
          <LpsExplain :context="{ resultId: noteRateId }" :enabled="viewNoteRate" />
        </li>
      </ul>

      <ul v-else>
        <li>
          Estimated PeerStreet Purchase Rate:
          <span class="price">{{purchaseRate}}</span>
          <LpsExplain :context="{ resultId: purchaseRateId }" :enabled="viewPurchaseRate" />
        </li>
        <li>
          Estimated Lender Spread:
          <span class="price">{{lenderSpread}}</span>
          <LpsExplain :context="{ resultId: lenderSpreadId }" :enabled="viewLenderSpread" />
        </li>
        <li v-if="showPsSpread">
          Estimated PeerStreet Spread:
          <span class="price">{{psSpread}}</span>
          <LpsExplain :context="{ resultId: psSpreadId }" :enabled="viewPsSpread" />
        </li>
      </ul>

      <p class="price-note">
        <span>Note:</span> Estimated pricing may change prior to loan approval and remains subject to PeerStreet's due diligence.
      </p>
    </template>

    <p v-else class="price-validation">
      To receive a price estimate for this option, please submit this loan to PeerStreet.
    </p>
  </div>
</template>

<script>
import NumberFormatter from "models/formatters/number_formatter";
import { PROGRAM_CLASSIFICATION } from "models/lender_form/program";
import LpsExplain from "components/lps_explain";

export default {
  name: "pricing-results",
  components: {
    LpsExplain,
  },
  props: {
    viewLpsResults: {
      type: Boolean,
      default: false,
    },
    pricing: Object,
    displayFor: String,
    programClassification: String,
  },
  computed: {
    showPrice() {
      return !Object.values(this.pricingResults).every(v => v === null);
    },
    showPsSpread() {
      return this.displayFor === "analyst";
    },
    showRentalPrice() {
      return this.isRentalPrice();
    },
    viewPurchaseRate() {
      return this.viewLpsResults && this.purchaseRateId !== null;
    },
    purchaseRateId() {
      return this.pricing.purchaseRateId;
    },
    purchaseRate() {
      if (this.pricing.purchaseRate === null) return "--";

      return NumberFormatter.formatPercent(parseFloat(this.pricing.purchaseRate), 3, true) || "--";
    },
    viewLenderSpread() {
      return this.viewLpsResults && this.lenderSpreadId !== null;
    },
    lenderSpreadId() {
      return this.pricing.lenderSpreadId;
    },
    lenderSpread() {
      if (this.pricing.lenderSpread === null) return "--";

      return NumberFormatter.formatPercent(parseFloat(this.pricing.lenderSpread), 3, true) || "--";
    },
    viewNoteRate() {
      return this.viewLpsResults && this.noteRateId !== null;
    },
    noteRateId() {
      return this.pricing.noteRateId;
    },
    noteRate() {
      if (this.pricing.noteRate === null) return "--";

      return NumberFormatter.formatPercent(parseFloat(this.pricing.noteRate), 3, true) || "--";
    },
    viewPsSpread() {
      return this.viewLpsResults && this.psSpreadId !== null;
    },
    psSpreadId() {
      return this.pricing.psSpreadId;
    },
    psSpread() {
      if (this.pricing.psSpread === null) return "--";

      return NumberFormatter.formatPercent(parseFloat(this.pricing.psSpread), 3, true) || "--";
    },
    pricingResults() {
      switch (true) {
        case this.isRentalPrice():
          return this.rentalPricing();
        case this.isBridgePrice():
          return this.bridgePricing();
        default:
          return {};
      }
    },
  },
  methods: {
    isRentalPrice() {
      return this.programClassification === PROGRAM_CLASSIFICATION.RESIDENTIAL_RENTAL_LONG_TERM;
    },
    isBridgePrice() {
      return (
        this.programClassification === PROGRAM_CLASSIFICATION.RESIDENTIAL_BRIDGE ||
        this.programClassification === PROGRAM_CLASSIFICATION.COMMERCIAL_MULTIFAMILY
      );
    },
    rentalPricing() {
      return _.pick(this.pricing, ["noteRate"]);
    },
    bridgePricing() {
      if (this.showPsSpread) {
        return _.pick(this.pricing, ["lenderSpread", "purchaseRate", "psSpread"]);
      }

      return _.pick(this.pricing, ["lenderSpread", "purchaseRate"]);
    },
  },
};
</script>
