<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>
            Loan ID
          </th>
          <th>
            Sale Type
          </th>
          <th>
            Sale Date
          </th>
          <th>
            Legal Owner
          </th>
          <th class="text-right">
            Investor Rate
          </th>
          <th class="text-right">
            Initial Balance
          </th>
          <th class="text-right">
            Sale Amount
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="loanSale in loanSales"
          :key="loanSale.loanPsId"
        >
          <td>
            <a :href="loanLink(loanSale.loan.id)">
              {{ loanSale.loan.id }}
            </a>
          </td>
          <td>
            {{ saleTypeText }}
          </td>
          <td>
            {{ batch.saleDate }}
          </td>
          <td>
            {{ batch.legalOwner.label }}
          </td>
          <td class="text-right">
            {{ loanSale.investorSpread | percent }}
          </td>
          <td class="text-right">
            {{ loanSale.initialAmount | money }}
          </td>
          <td class="text-right">
            {{ loanSale.saleAmount | money }}
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <th :colspan="6">
            Recording Fees ({{ recordCount }} x {{ unitRecordingFee | money }})
          </th>
          <th class="text-right">
            {{ totalRecordingFee | money }}
          </th>
        </tr>
        <tr>
          <th :colspan="6">
            Total
          </th>
          <th class="text-right">
            {{ total | money }}
          </th>
        </tr>
      </tfoot>
    </table>

    <loan-sale-detail-actions :reset-disabled="disabled" :sell-disabled="disabled" v-on="$listeners" />
  </div>
</template>

<script>
import sumBy from "lodash/sumBy";
import camelCase from "lodash/camelCase";
import sortBy from "lodash/sortBy";

import LoanSaleDetailActions from "./actions";

export default {
  name: "LoanSaleDetail",
  components: {
    LoanSaleDetailActions,
  },
  props: {
    batch: {
      type: Object,
      required: true,
    },
    disabled: Boolean,
  },
  data: () => ({
    routes: PSData.routes || {},
  }),
  computed: {
    saletypes() {
      return PSData.saleTypes;
    },
    recordCount() {
      return this.batch.loanSales.length;
    },
    unitRecordingFee() {
      return this.batch.legalOwner.loanRecordingFee;
    },
    total() {
      const totalUnpaid = sumBy(this.batch.loanSales, "saleAmount");

      return totalUnpaid + this.totalRecordingFee;
    },
    totalRecordingFee() {
      return this.recordCount * this.unitRecordingFee;
    },
    saleTypeText() {
      return PSData.saleTypes[camelCase(this.batch.saleType)];
    },
    loanSales() {
      return sortBy(this.batch.loanSales, ({ loan: { id } }) => id);
    },
  },
  methods: {
    loanLink(id) {
      return `${this.routes.loansUrl}/${id}`;
    },
  },
};
</script>
