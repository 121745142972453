<template>
  <div class="incomplete-task">
    <h4 class="tracking-numbers-section-header">
      Loan Packet Tracking Information
      <span v-if="isLoadingTrackingNumbers" class="ps-loader ps-loader-small tracking-numbers-loader"></span>
    </h4>

    <div>
      <table class="tracking-numbers-section-table">
        <thead>
          <tr>
            <th>Sending To</th>
            <th class="tracking-number-header">Tracking Number</th>
            <th>Carrier Status</th>
            <th>Carrier</th>
            <th>Added</th>
            <th class="tracking-number-actions-header"></th>
          </tr>
        </thead>

        <tbody class="tracking-numbers-section-info">
          <tr v-for="number in trackingNumbers" :key="number.id">
            <td class="required">{{number.sendingTo}}</td>
            <td class="required">
              <a :href="number.trackingUrl" target="_blank" v-if="number.trackingUrl">
                {{number.trackingNumber}}
              </a>
              <div v-else>{{number.trackingNumber}}</div>
            </td>
            <td>{{number.carrierStatus}}</td>
            <td class="required">{{number.carrierText}}</td>
            <td class="required">{{number.addedOn}}</td>
            <td class="tracking-number-actions">
              <div v-if="canEdit">
                <a class="tracking-number-update-button" @click.prevent="openUpdateModal(number)" href="#" title="Edit tracking number information">
                  <i class="fas fa-pencil-alt"></i>
                </a>
                <a class="tracking-number-delete-button" @click.prevent="deleteTrackingNumber(number)" href="#" title="Delete tracking number">
                  <i class="fas fa-trash-alt"></i>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5" v-if="canEdit">
              <span
                class="tracking-number-add-button"
                href="#"
                title="Add Tracking Number"
                @click.prevent="openAddModal"
              >
                {{ addTrackingNumberCopy }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <physical-docs-tracking-numbers-modal
      class="tracking-number-update-modal"
      :show="showEditModal"
      :tracking-number="selectedTrackingNumber"
      :errors="updateErrors"
      :loading="isUpdatingTrackingNumber"
      @close="closeUpdateModal"
      @submit="updateTrackingNumber"
    >
      <template slot="header">
        Edit Tracking Information
      </template>
    </physical-docs-tracking-numbers-modal>

    <physical-docs-tracking-numbers-modal
      class="tracking-number-create-modal"
      :show="showAddModal"
      :errors="createErrors"
      :loading="isCreatingTrackingNumber"
      @close="closeAddModal"
      @submit="createTrackingNumber"
    >
      <template slot="header">
        Add Tracking Information
      </template>
    </physical-docs-tracking-numbers-modal>
  </div>
</template>

<script>
import Ajax from "services/ajax_service";
import PhysicalDocsTrackingNumbersModal from "components/physical_docs_tracking_numbers/modal";
import TrackingNumberModel from "models/physical_docs_tracking_number";
import { error } from "services/notification";
import Vue from "vue";

export default {
  name: "physical-docs-tracking-numbers-section",
  components: {
    PhysicalDocsTrackingNumbersModal,
  },
  props: {
    canEdit: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      trackingNumbers: [],
      selectedTrackingNumber: undefined,
      createErrors: {},
      updateErrors: {},
      showAddModal: false,
      showEditModal: false,
      isCreatingTrackingNumber: false,
      isUpdatingTrackingNumber: false,
      isLoadingTrackingNumbers: true,
      isDeletingTrackingNumber: {},
    };
  },
  computed: {
    addTrackingNumberCopy() {
      if (this.trackingNumbers.length > 0) {
        return "+ Add Additional Tracking Number";
      } else {
        return "+ Add Tracking Number";
      }
    },
  },
  created() {
    const { routes } = PSData;

    if (routes && routes.physicalDocsTrackingNumbers) {
      this.loadTrackingNumbers();
    }
  },
  methods: {
    loadTrackingNumbers() {
      const url = PSData.routes.physicalDocsTrackingNumbers;

      Ajax.get(url)
        .then(result => {
          if (result) {
            result.forEach(r => {
              this.trackingNumbers.push(new TrackingNumberModel(r));
            });
            this.$emit("load-tracking-numbers", this.trackingNumbers);
          }
        })
        .finally(() => (this.isLoadingTrackingNumbers = false));
    },
    openAddModal() {
      this.showAddModal = true;
    },
    closeAddModal() {
      this.createErrors = {};
      this.showAddModal = false;
    },
    openUpdateModal(trackingNumber) {
      this.selectedTrackingNumber = trackingNumber;
      this.showEditModal = true;
    },
    closeUpdateModal() {
      this.updateErrors = {};
      this.selectedTrackingNumber = undefined;
      this.showEditModal = false;
    },
    createTrackingNumber(trackingNumber) {
      this.isCreatingTrackingNumber = true;
      this.createErrors = {};

      const url = PSData.routes.physicalDocsTrackingNumbers;
      const payload = { tracking_number: trackingNumber.toHash() };

      return Ajax.post(url, payload)
        .then(data => {
          this.trackingNumbers.push(new TrackingNumberModel(data.tracking_number));
          this.$emit("set-tracking-numbers", this.trackingNumbers);
          this.closeAddModal();
        })
        .catch(({ response, data }) => {
          response.status === 422 ? (this.createErrors = data.errors) : error();
        })
        .finally(() => (this.isCreatingTrackingNumber = false));
    },
    updateTrackingNumber(trackingNumber) {
      const url = `${PSData.routes.physicalDocsTrackingNumbers}/${trackingNumber.id}`;
      const payload = { tracking_number: trackingNumber.toHash() };

      this.isUpdatingTrackingNumber = true;
      this.updateErrors = {};

      return Ajax.patch(url, payload)
        .then(data => {
          const trackingNumber = new TrackingNumberModel(data.tracking_number);
          const index = this.trackingNumbers.findIndex(number => {
            return number.id === trackingNumber.id;
          });
          Vue.set(this.trackingNumbers, index, trackingNumber);
          this.$emit("update-docs-tracking-number", trackingNumber, this.trackingNumbers);
          this.closeUpdateModal();
        })
        .catch(({ response, data }) => {
          if (response.status === 422) {
            const customError = data.errors.base;

            this.updateErrors = data.errors;
            if (customError) error(customError[0]);
          }
          response.status === 422 ? (this.updateErrors = data.errors) : error();
        })
        .finally(() => (this.isUpdatingTrackingNumber = false));
    },
    deleteTrackingNumber(trackingNumber) {
      if (this.isDeletingTrackingNumber[trackingNumber.id]) return;
      if (!window.confirm("Are you sure?")) return;

      this.isDeletingTrackingNumber[trackingNumber.id] = true;

      const url = `${PSData.routes.physicalDocsTrackingNumbers}/${trackingNumber.id}`;

      Ajax.delete(url)
        .then(() => {
          this.trackingNumbers = this.trackingNumbers.filter(t => {
            return t.id !== trackingNumber.id;
          });

          this.$emit("delete-docs-tracking-number", trackingNumber, this.trackingNumbers);
        })
        .finally(() => (this.isDeletingTrackingNumber[trackingNumber.id] = false));
    },
  },
};
</script>
