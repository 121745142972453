<template>
  <div>
    <categorized-bar-chart
      class="housing-stock-value-chart"
      v-bind:chart="chart"
      v-bind:sources="sources"
    >
      <template slot="header">
        <slot name="header"></slot>
      </template>
    </categorized-bar-chart>
  </div>
</template>

<script>
import { format } from "d3";

import CategorizedBarChart from "components/market_data/categorized_bar_chart";

export default {
  components: {
    CategorizedBarChart,
  },
  props: {
    sources: Object,
  },
  data() {
    return {
      chart: {
        title: this.sources.name,
        showLegend: true,

        axisYTickFormat: format("$,.2s"),
        tooltipFormatValue: format("$,.2f"),

        sizeHeight: 360,

        labels: this.sources.labels,

        columns: {
          submissions: {
            color: "#66cc33",
            label: "Submissions",
          },
          sales: {
            color: "#5b6770",
            label: "Loans Funded",
          },
        },
      },
    };
  },
  watch: {
    sources: {
      deep: true,
      handler(newValue) {
        this.chart.title = newValue.name;
        this.chart.labels = newValue.labels;
      },
    },
  },
};
</script>
