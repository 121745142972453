<template>
  <dropdown-button :disabled="isDisabled">
    <li v-for="action in enabledActions" :key="action.label">
      <a href="#" @click.prevent="$emit(action.event)">
        {{ action.label }}
      </a>
    </li>
  </dropdown-button>
</template>
<script>
import DropdownButton from "./dropdown_button";

export default {
  components: {
    DropdownButton,
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },
  computed: {
    isDisabled() {
      return !this.hasEnabledActions || this.disabled;
    },
    hasEnabledActions() {
      return this.enabledActions.length > 0;
    },
    enabledActions() {
      return this.actions.filter(a => a.enabled);
    },
  },
};
</script>
