<template>
  <div>
    <icon :icon="icon" :class="classes" />
    <span v-for="flag in flags" :key="flag" class="mr-2">
      {{ $options.flagLabels[flag] }}
    </span>
  </div>
</template>

<script>
import Icon from "components/shared/icon";

const FLAG_LABELS = {
  allocation_changes: "Allocation Changes",
  future_date: "Future Payment",
  delinquent: "Late 90+",
  effective_date_missing: "Effective Date Missing",
  full_loan_payoff: "Payoff",
  interest_overpay: "Interest Overpay",
  lender_fee: "Lender Fee",
  missing_borrower_payment: "Missing Borrower Payment",
  negative_upb: "Negative UPB",
  payoff: "Payoff",
  prepaid: "Prepaid Interest",
  reo_sale_payoff: "REO Sale",
  short_loan_payoff: "Short Sale",
  short_sale_payoff: "Short Sale",
  unallocated_principal: "Unallocated Principal",
  uncategorized: "Uncategorized",
  discounted: "Discounted",
  construction_reserve: "Construction Reserve",
  construction_reserve_reversal: "Construction Reserve Reversal",
};

export default {
  components: {
    Icon,
  },
  props: {
    flags: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasFlags() {
      return this.flags.length > 0;
    },
    icon() {
      return this.hasFlags ? "exclamation-triangle" : "check-circle";
    },
    classes() {
      const { hasFlags } = this;

      return {
        "text-success": !hasFlags,
        "text-warning": hasFlags,
      };
    },
  },
  flagLabels: FLAG_LABELS,
};
</script>
