export default class Source {
  constructor(name, points, dateKey, valueKey) {
    this.name = name;
    this.points = points || [];
    this.dateKey = dateKey;
    this.valueKey = valueKey;

    this.startDate = this.points[0] && new Date(this._month(this.points[0]));
    this.endDate =
      this.points[this.points.length - 1] &&
      new Date(this._month(this.points[this.points.length - 1]));

    this._clearData();
  }

  data(startDate, endDate) {
    this._clearData();

    startDate = new Date(startDate);
    endDate = new Date(endDate);

    this.points.forEach(point => {
      let month = this._month(point);
      let date = new Date(month);

      if (date >= startDate && date <= endDate) {
        let value = parseFloat(this._value(point));

        if (!isNaN(value)) {
          let region = this._region(point);

          region.labels.push(month);
          region.data.push(value);
        }
      }
    });

    return this._data;
  }

  pointType(point) {
    throw new Error(
      "NotImplemented: Please override Source#pointType to return 'predicted' or 'observed'"
    );
  }

  _clearData() {
    this._data = {
      observed: {
        labels: [],
        data: [],
      },
      predicted: {
        labels: [],
        data: [],
      },
    };
  }

  _region(point) {
    // this._data.observed or this._data.predicted
    return this._data[this.pointType(point)];
  }

  _month(point) {
    return point[this.dateKey];
  }

  _value(point) {
    return point[this.valueKey];
  }
}

PS.Models.MarketData.Charts.Source = Source;
