<template>
  <div class="credit-box-inline-guide">
    <h4 v-if="showSubheader" class="creditbox-header">Credit Box Advisor</h4>

    <div v-show="!isReady" class="ps-loader ps-loader-small"></div>

    <template v-if="!hasErrors">
    <credit-box-info v-show="!showSuccess"
                     :view-lps-results="viewLpsResults"
                     :credit-box-results="creditBoxResults"
                     :new-loan-form="newLoanForm"
                     :option-id="optionId"
                     :draft-results="lenderDraft"/>

    <credit-box-success v-show="showSuccess" :group-id="creditBoxGroupId" :view-lps-results="viewLpsResults" />

    <p v-if="isReady && noIssuesNote">No credit box issues based on the loan data provided so far.</p>
    </template>

    <base-message v-show="hasErrors" type="danger" title="Error" has-icon icon="times-circle" :closable="false">
      There was an error loading the credit box. Please try again later.
    </base-message>

    <credit-box-guidelines v-if="lenderOnly" :program-classification="programClassification" :is-loan-guide="isLoanGuide" />
  </div>
</template>

<script>
import { prepareRules, uniqResults, hasIssues } from "models/credit_box/credit_box_rules";
import CreditBoxGuidelines from "components/credit_box/credit_box_guidelines.vue";
import CreditBoxSuccess from "components/credit_box/credit_box_success.vue";
import CreditBoxInfo from "components/credit_box/credit_box_info.vue";

export default {
  name: "credit-box-inline-guide",
  components: {
    CreditBoxGuidelines,
    CreditBoxInfo,
    CreditBoxSuccess,
  },
  props: {
    creditBoxResults: Array,
    programClassification: String,
    propertyClassification: String,
    underwritingStatus: String,
    displayFor: String,
    optionId: Number,
    newLoanForm: {
      type: Boolean,
      default: false,
    },
    isLoanGuide: {
      type: Boolean,
      default: false,
    },
    isFormCompleted: {
      type: Boolean,
      default: false,
    },
    isReady: {
      type: Boolean,
      default: true,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    viewLpsResults: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      viewDetails: PSData.viewDetails || undefined,
    };
  },
  computed: {
    lenderOnly() {
      return this.displayFor === "lender";
    },
    analystOnly() {
      return !this.lenderOnly;
    },
    results() {
      return uniqResults(prepareRules(this.creditBoxResults));
    },
    hasIssues() {
      return hasIssues(this.results);
    },
    creditBoxIssuesCount() {
      return this.results.filter(rule => rule.type === "validation").length;
    },
    showSuccessLenderOnly() {
      return !this.hasIssues && this.lenderOnly;
    },
    showSuccessAnalystOnly() {
      return !this.hasIssues && this.analystOnly && this.isFormCompleted;
    },
    creditBoxGroupId() {
      // return group name for now, we only have 1 group per program
      return "creditbox";
    },
    showSuccess() {
      return (this.showSuccessLenderOnly || this.showSuccessAnalystOnly) && this.isReady;
    },
    isDraft() {
      return this.underwritingStatus === "draft";
    },
    lenderDraft() {
      return this.lenderOnly && this.isDraft;
    },
    showSubheader() {
      return this.lenderDraft && !this.newLoanForm;
    },
    noIssuesNote() {
      return !this.hasIssues && this.analystOnly && !this.isFormCompleted;
    },
  },
};
</script>

<style scoped>
.credit-box-inline-guide .base-message {
  border: none;
}
.credit-box-inline-guide .base-message >>> .icon .svg-inline--fa {
  font-size: 24px;
}
</style>
