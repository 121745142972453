<template>
  <base-select-field
    v-model="input.value.value"
    :label="input.name"
    :options="options"
    :disabled="readOnly"
    show-empty
  />
</template>

<script>
import Input from "models/workflow_engine/input";

// import VariableComment from "../variable_comment";

export default Vue.extend({
  name: "select-input-component",
  components: {
    // VariableComment,
  },
  props: {
    input: {
      type: Input,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    options() {
      return this.input.settings.options.map(option => [option.name, option.value]);
    },
  },
});
</script>
