<template>
  <tr
    v-if="loan"
    class="loan-details"
    :data-group="group"
    :data-id="loan.id"
    :data-obfuscated-id="loan.toParam"
    :data-path="loanDetailsPath(loan)"
  >
    <td v-if="columns.loanStatus.show" class="loan-status-wrapper">
      <span class="loan-status" :class="'loan-' + loan.purchasedGroup">
        {{ loan.groupName }}
      </span>
    </td>
    <td v-if="columns.age.show" class="loan-age" :class="activeSortClass('daysInCurrentStatus')">
      {{ loan.daysInCurrentStatus || 0 }}
      <span>{{ loan.daysSinceLastUpdate || 0 }}</span>
    </td>
    <td v-if="columns.analyst.show" class="loan-analyst" :class="activeSortClass('analyst.name')">
      <div v-if="hasGravatar(loan)" class="loan-analyst-image" :class="analystAvatarClass(loan)">
        <img :src="analystGravatarUrl(loan)">
      </div>
    </td>
    <td v-if="columns.qcApprover.show" class="loan-analyst" :class="activeSortClass('qcApprover.name')">
      <div v-if="qcApproverGravatarUrl(loan)" class="loan-analyst-image">
        <img :src="qcApproverGravatarUrl(loan)">
      </div>
    </td>
    <td v-if="columns.loanId.show" class="loan-lender" :class="activeSortClass('lender.name')">
      <report-lender-name
        :lender="loan.lender"
        :lender-block-status-displayable="lenderBlockStatusDisplayable"
        :broker-tag-displayable="brokerTagDisplayable"
      />
      <div v-if="showNoFlyBadge" class="report-lender-name">
        <span>
          <a href="#" tabindex=-1, v-popover>
            <i class="fas fa-times-circle fa-lg"></i>
            {{ loan.toParam }}
          </a>
          <div class="popover-content hidden">
            <p>Loan progress has been suspended due to suspicious activity detected.</p>
          </div>
        </span>
      </div>
      <span v-else>
        {{ loan.toParam }}
      </span>
    </td>
    <td v-if="columns.address.show" class="loan-address">
      <span class="loan-street">
        {{ loan.address }}
      </span>
      <div class="loan-address-details">
        <span class="address">{{ loan.city }}, {{ loan.state }} {{ loan.zip }}</span>
        <pieces-of-collateral :properties="loan.properties" />
      </div>
    </td>
    <td v-if="columns.propertyType.show" class="loan-property-type" :class="activeSortClass('propertyType')">
      {{ loan.propertyType }}
    </td>
    <td v-if="columns.rate.show" class="loan-rate">
      <template v-if="loan.interestRate">
        {{ loan.interestRate | percent(3) }}
      </template>
      <span v-if="loan.investorRate">{{ loan.investorRate | percent(3) }}</span>
    </td>
    <td v-if="columns.ltvArv.show" class="loan-ltv">
      <template v-if="loan.ltv > 0">
        {{ loan.ltv | percent(3) }}
      </template>
      <span v-if="loan.ltarv > 0">
        {{ loan.ltarv | percent(3) }}
      </span>
      <span v-else="">--</span>
    </td>
    <td v-if="columns.term.show" class="loan-term">
      {{ loan.originalLoanTermFormat }}
      <span>{{ loan.extensionTerm }}</span>
    </td>
    <td v-if="columns.psAllocation.show" class="loan-amount" :class="activeSortClass('peerstreetAllocation')">
      <template v-if="loan.peerstreetAllocation >= 0">
        {{ loan.peerstreetAllocation | money(0) }}
      </template>
      <span v-if="loan.bPieceAmount">{{ loan.bPieceAmount | money(0) }}</span>
    </td>
    <td v-if="columns.noteStatus.show" class="loan-note-status" :class="activeSortClass('lenderNoteStatusText')">
      {{ loan.lenderNoteStatusText }}
      <span>{{ loan.originationDate }}</span>
    </td>
    <td v-if="columns.docs.show" class="loan-docs">
      {{ loan.document.count }}
    </td>
    <td v-if="columns.valuation.show" class="loan-valuation">
      {{ loan.valuationStatusText }}
    </td>
    <td v-if="columns.purchaseDate.show" class="loan-purchase-date" :class="activeSortClass('initialPurchaseDate')">
      <span>{{ loan.initialPurchaseDate }}</span>
    </td>
    <td v-if="columns.forecastPurchaseDate.show" class="loan-forecast-purchase-date" :class="activeSortClass('forecastPurchaseDate')">
      <span :class="targetDateClass(loan.daysToForecastPurchaseDate)">{{ loan.forecastPurchaseDate }}</span>
    </td>
    <td v-if="columns.targetPurchaseDate.show" class="loan-target-date" :class="activeSortClass('targetPurchaseDate')">
      <span :class="targetDateClass(loan.daysToTargetPurchaseDate)">{{ loan.targetPurchaseDate }}</span>
    </td>
    <td v-if="columns.qcApprovalTask.show" class="loan-qc-approval-task" :class="activeSortClass('qcApprovalTask')">
      <span> {{ loan.qcApprovalTaskText || "--" }}</span>
    </td>
    <td v-if="columns.notes.show" class="analyst-notes" @click.stop="$emit('edit-notes', loan)">
      <base-tooltip
        :label="loan.peerstreetNotes"
        multilined
      >
        <a href="#" onClick="return false;">
          {{ loan.peerstreetNotes }}
        </a>
      </base-tooltip>
    </td>
    <td v-if="columns.openInternalTasks.show">
      {{ loan.openInternalTasks }}
    </td>
    <td v-if="columns.openLenderTasks.show">
      {{ loan.openLenderTasks }}
    </td>
    <td v-if="columns.currentProgressUpdate.show" class="progress-update-data" :class="activeSortClass('currentProgressUpdate.shortDate')">
      <progress-update-tooltip
        :progress-update="loan.currentProgressUpdate"
        :loan-suspension="loan.currentSuspension"
      />
    </td>
    <td v-if="columns.qcDate.show" class="loan-qc-data">
      <span v-if="loan.qcData">
        <qc-data name="Diligence" :data="loan.qcData.diligence" />
        <qc-data name="DAP" :data="loan.qcData.dap" />
      </span>
      <span v-else>
        --
      </span>
    </td>
    <td v-if="columns.openQcTasks.show">
      {{ loan.openQcTasks }}
    </td>
    <td v-if="columns.lenderTargetPurchaseDate.show" class="lender-loan-target-date" :class="activeSortClass('lenderTargetPurchaseDate')">
      {{ loan.lenderTargetPurchaseDate || "--" }}
    </td>
    <td />
  </tr>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import ProgressUpdateTooltip from "./loan_progress_update_tooltip";
import ReportLenderName from "./lender_name";
import QcData from "./qc_data";
import PiecesOfCollateral from "components/tags/pieces_of_collateral";

export default Vue.component("report-loan", {
  components: {
    "progress-update-tooltip": ProgressUpdateTooltip,
    "report-lender-name": ReportLenderName,
    "qc-data": QcData,
    PiecesOfCollateral,
  },
  props: {
    loan: Object,
    group: String,
    lenderBlockStatusDisplayable: {
      type: Boolean,
      default: false,
    },
    brokerTagDisplayable: {
      type: Boolean,
      default: false,
    },
    sortColumn: Object,
    columns: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showNoFlyBadge() {
      return this.loan.noFlySuspensions > 0;
    },
  },
  methods: {
    loanDetailsPath: function(loan) {
      return `/manage/loans/${loan.toParam}`;
    },
    loanEditPath: function(loan) {
      return `/manage/loans/${loan.toParam}#edit`;
    },
    hasGravatar(loan) {
      return !isEmpty(loan.analyst.emailHash);
    },
    analystGravatarUrl: function(loan) {
      return `https://www.gravatar.com/avatar/${loan.analyst.emailHash}?d=mm&&s=40`;
    },
    qcApproverGravatarUrl: function(loan) {
      return loan.qcApprover.emailHash
        ? `https://www.gravatar.com/avatar/${loan.qcApprover.emailHash}?d=mm&&s=40`
        : "";
    },
    analystAvatarClass: function(loan) {
      if (loan.attentionRequired) {
        return "attention-indicator";
      }
    },
    boxClass: function(loan) {
      if (loan.allocatedToText) {
        return loan.allocatedToText.toLowerCase() + "-allocated";
      }
    },
    allocationClass: function(loan, box) {
      if (box.status == "pending") {
        return [box.name, box.status];
      } else {
        return [this.boxClass(loan), box.name, box.fit];
      }
    },
    targetDateClass: function(daysToTargetDate) {
      switch (true) {
        case daysToTargetDate < 0:
          return "badge badge-danger";
        case daysToTargetDate >= 0 && daysToTargetDate <= 2:
          return "badge badge-warning";
        case daysToTargetDate > 2 && daysToTargetDate <= 7:
          return "badge badge-success";
      }
    },
    activeSortClass(column) {
      if (this.sortColumn && this.sortColumn.field === column) {
        return "hotlist-active-column";
      } else {
        return "";
      }
    },
  },
});
</script>
