import enumerize from "../utils/enumerize";

const ResidencyStatus = enumerize({
  usCitizen: {
    value: "us_citizen",
    title: "US Citizen",
  },
  foreignNational: {
    value: "foreign_national",
    title: "Foreign National",
  },
  permanentResident: {
    value: "permanent_resident",
    title: "Permanent Resident",
  },
});

export default ResidencyStatus;
