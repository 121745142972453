import { Client } from "peerstreet-hermes-js";
import AreaQuery from "./msa/area_query.graphql";
import SearchQuery from "./msa/search_query.graphql";

class MSAService {
  static get client() {
    return new Client({
      uri: PSData.global.msaUrl,
      requestIdGenerator: () => PSData.requestId,
    });
  }

  static find(zip) {
    return this.client.query({
      query: AreaQuery,
      variables: {
        zip: zip,
      },
    });
  }

  static listSearch(query) {
    return this.client.query({
      query: SearchQuery,
      variables: {
        query: query,
      },
    });
  }
}

export default MSAService;
window.PS.Services.MSAService = MSAService;
