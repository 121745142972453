<template>
  <div>
    <div class="is-flex py-4 add-property-section">
      <span class="add-property-section-header">{{ input.name }}</span>
      <base-button
        v-if="!readOnly"
        title="Add"
        type="iconBlue"
        icon="plus"
        class="ml-2"
        @click="$emit('on-input-add')"
      />
      <base-button
        v-if="!readOnly"
        title="Remove"
        type="icon"
        icon="trash-alt"
        class="ml-2"
        @click="$emit('on-input-delete')"
      />
    </div>

    <input-component v-for="input in inputs" :key="input.id" :input="input" :meta="meta" :read-only="input.readonly" />
  </div>
</template>

<script>
import Input from "models/workflow_engine/input";

export default Vue.extend({
  name: "entity-input",
  components: {
    InputComponent: () => import("components/workflows/input"),
  },
  props: {
    input: {
      type: Input,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    meta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    inputs() {
      return this.meta.inputs.filter(inp => inp.parentVariableInstanceId === this.input.id);
    },
  },
});
</script>
<style scoped>
.add-property-section {
  align-items: center;
}

.add-property-section-header {
  font-size: var(--font-size-md);
  font-weight: 600;
}
</style>
