<template>
  <div class="portfolio-breakdown-group">
    <with-tooltip class="panel-header">
      {{ data.label }}
      <template slot="content">
        Represents all Single Family Residential, Multifamily, and Commercial bridge loans
        funded by PeerStreet that have a loan servicing status of "Current" and are not in
        default or matured.
      </template>
    </with-tooltip>
    <div class="projected-revenues">
      <div class="projected-revenue current">
        <div class="title">
          Projected Monthly
          <with-tooltip>
            Spread Revenue
            <template slot="content">
              = the total unpaid principal balance for all active current bridge loans with all
              payments current x (1/12) x the weighted average lender spread of those current loans.
              This value is only a projection and may not represent the actual amount that you will
              receive at the end of the month for all active bridge loans.
            </template>
          </with-tooltip>
        </div>
        <div class="value">
          {{ data.projectedMonthlySpreadRevenue }}
        </div>
      </div>
    </div>
    <div class="breakdown-summary">
      <with-tooltip>
        Total Volume
        <template slot="content">
          The sum of the total unpaid principal balance for all active current Single Family
          Residential, Multifamily, and Commercial bridge loans.
        </template>
      </with-tooltip>
      <span class="summary-value">{{ data.totalVolume }} ({{ data.loansCountLabel }})</span>
    </div>
    <div class="breakdown-summary">
      <with-tooltip>
        Average Lender Spread
        <template slot="content">
          The weighted average lender spread for all active current Single Family Residential,
          Multifamily, and Commercial bridge loans.
        </template>
      </with-tooltip>
      <span class="summary-value">{{ data.averageLenderSpread }}</span>
    </div>
  </div>
</template>

<script>
import WithTooltip from "components/shared/with_tooltip";

export default {
  name: "current-loans-breakdown",
  components: {
    WithTooltip,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
