<template>
  <div class="worklist-cell forecast-date" @click.stop="showDatePicker">
    <div :class="[forecastPurchaseDateClass, 'forecast-date-label']">{{ formattedForecastPurchaseDate }}</div>
    <i v-if="canUpdateWorklistData" class="fas fa-caret-down" :class="{ open: datepicker }" />
    <div v-if="isPickerEnabled" @click.stop="hideDatePicker" class="worklist-cell-overlay"></div>
    <date-picker
      class-name="worklist-cell forecast-datepicker"
      v-if="isPickerEnabled"
      v-model="formattedForecastPurchaseDate"
      :options="{ static: false, dateFormat: 'm/d/Y', allowInput: true, inline: true }"
      :disabled="!canUpdateWorklistData"
      @click.native.stop
      @input="onUpdate"
    >
    </date-picker>
  </div>
</template>
<script>
import moment from "moment";
import Ajax from "services/ajax_service";
import { error } from "services/notification";
import DatePicker from "components/shared/date_picker";

export default {
  name: "worklist-forecast-purchase-date-cell",
  props: {
    loan: {
      type: Object,
      required: true,
    },
    canUpdateWorklistData: {
      type: Boolean,
      required: true,
    },
  },
  components: { DatePicker },
  data() {
    const { forecastPurchaseDate } = this.loan;

    return {
      forecastPurchaseDate,
      formattedForecastPurchaseDate:
        PS.Models.DateFormatter.formatDate(forecastPurchaseDate) || "--",
      datepicker: false,
    };
  },
  methods: {
    onUpdate(value) {
      const date = moment(value, "L").format("YYYY-MM-DD");

      const messages = {
        onSuccess: "Rate Lock Expiration Date was successfully updated",
        onError: ({ response, data }) => {
          if (response.status === 422) {
            return {
              message: data.error,
              status: "error",
            };
          } else {
            error("Couldn't update Rate Lock Expiration Date");
          }
        },
      };

      const params = {
        resource: { forecast_purchase_date: date },
      };

      Ajax.withNotifications(messages)
        .patch(this.loan.routes.updatePath, params)
        .catch(() => {})
        .finally(this.hideDatePicker);
    },
    showDatePicker() {
      if (this.datepicker) {
        return (this.datepicker = false);
      }

      this.datepicker = true;
    },
    hideDatePicker() {
      this.datepicker = false;
    },
  },
  computed: {
    isPickerEnabled() {
      return this.datepicker && this.canUpdateWorklistData;
    },
    forecastPurchaseDateClass() {
      if (!this.forecastPurchaseDate) {
        return;
      }

      const date = moment(this.formattedForecastPurchaseDate);
      const days = date.diff(moment(), "days");

      switch (true) {
        case days < 0:
          return "badge badge-danger";
        case days >= 0 && days <= 2:
          return "badge badge-warning";
        case days > 2:
          return "badge badge-success";
      }
    },
  },
};
</script>
