<template>
  <div>
    <div v-if="canAddAssignee">
      <dropdown class="form-input" ref="assignee" :value="selectedUser.userId" @input="assignLoanTask">
        <option v-for="(user, i) in users" :key="'user-select2-' + i" :value="user.id">
          {{ user.name }}
        </option>
      </dropdown>
      <div class="loan-task-assignee-buttons">
        <button class="btn btn-link" @click="hideAddSection">
          Cancel
        </button>
      </div>
    </div>
    <loan-task-field-container v-if="!canAddAssignee" label="Assigned To">
      <template v-if="hasAssignees">
        <loan-task-editable-field
          v-for="assignee in task.assignees"
          :key="`assignees-${assignee.id}`"
          :disabled="!canEditAssignee"
          :is-loading="inFlight"
          :deletable="isDeleteable"
          @edit="editAssignee(assignee.id)"
          @delete="deleteAssignee(assignee.id)"
        >
          <assignee :assignee="assignee" />
        </loan-task-editable-field>
      </template>
      <loan-task-editable-field
        v-else
        :disabled="!canEditAssignee"
        :deletable="false"
        :is-loading="inFlight"
        @edit="showAddSection"
      >
        <assignee />
      </loan-task-editable-field>
    </loan-task-field-container>
  </div>
</template>

<script>
import Dropdown from "components/shared/select2";
import Ajax from "services/ajax_service";
import Assignee from "components/activity_feed_events/manage/loan_tasks/task_assignment/assignee";
import LoanTaskEditableField from "components/activity_feed_events/manage/loan_tasks/editable_field";
import LoanTaskFieldContainer from "components/activity_feed_events/manage/loan_tasks/field_container";
import { error } from "services/notification";

export default {
  components: {
    Dropdown,
    Assignee,
    LoanTaskEditableField,
    LoanTaskFieldContainer,
  },
  props: {
    task: {
      type: Object,
      default: () => ({
        assignees: [],
      }),
    },
    users: {
      type: Array,
      required: true,
    },
    canEditAssignee: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    canAddAssignee: false,
    canDelete: false,
    isAssigning: false,
    isUnassigning: false,
    inFlight: false,
    selectedUser: {},
  }),
  watch: {
    canAddAssignee(show) {
      if (show) {
        this.$nextTick(() => {
          this.$refs["assignee"].open();
        });
      }
    },
  },
  methods: {
    assignLoanTask(userId) {
      this.canAddAssignee = false;

      if (this.isAssigning) return;

      const payload = { user_id: userId };

      this.isAssigning = true;
      this.inFlight = true;

      return Ajax.post(this.task.assigneesPath, payload)
        .then(data => {
          this.$emit("task-assigned", data.assignee);
        })
        .catch(() => error())
        .finally(() => {
          this.isAssigning = false;
          this.inFlight = false;
        });
    },
    deleteAssignee(id) {
      if (!this.canEditAssignee) return;
      if (this.isUnassigning) return;

      const assignee = this.task.assignees.find(a => a.id === id);
      const url = `${this.task.assigneesPath}/${id}`;

      this.inFlight = true;

      return Ajax.delete(url)
        .then(() => {
          this.$emit("assignee-deleted", assignee);
        })
        .catch(() => error())
        .finally(() => {
          this.isUnassigning = false;
          this.inFlight = false;
        });
    },
    editAssignee(id) {
      const assignee = this.task.assignees.find(a => a.id === id);

      this.selectedUser = assignee;
      this.canAddAssignee = this.canEditAssignee;
    },
    showAddSection() {
      this.canAddAssignee = this.canEditAssignee;
    },
    hideAddSection() {
      this.selectedUser = {};
      this.canAddAssignee = false;
    },
  },
  computed: {
    hasAssignees() {
      return this.task.assignees.length > 0;
    },
    isDeleteable() {
      return this.canEditAssignee;
    },
  },
};
</script>
