<template>
  <div class="user-settings-wrapper">
    <user-settings-button @click="openSettingsModal" />
    <user-settings-modal
      v-if="showSettingsModal"
      :show="true"
      :setting="userSetting"
      @cancel="closeSettingsModal"
      @save="saveSettings"
    />
  </div>
</template>

<script>
import UserSettingsButton from "./button";
import UserSettingsModal from "./modal";
import UserSettingsService from "services/user_settings/user_settings_service";

export default {
  name: "user-settings",
  components: {
    UserSettingsButton,
    UserSettingsModal,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showSettingsModal: false,
      userSetting: UserSettingsService.getItem(this.name),
    };
  },
  methods: {
    openSettingsModal() {
      this.userSetting = UserSettingsService.getItem(this.name);
      this.showSettingsModal = true;
      this.$emit("open");
    },
    closeSettingsModal() {
      this.showSettingsModal = false;
      this.$emit("close");
    },
    saveSettings(setting) {
      UserSettingsService.setItem(this.name, { items: setting }).finally(() =>
        this.closeSettingsModal()
      );
    },
  },
};
</script>
