const Command = PS.Models.Command;
const Events = PS.Models.Loan.Events;

class LoadDashboard extends Command {
  execute() {
    const searchParams = new URLSearchParams({
      settings: JSON.stringify(this.state.settings),
    });
    const url = `/manage/periscope/dashboard?${searchParams.toString()}`;

    return PS.Services.AjaxService.get(url).then(data => {
      this.state.iframeSrc = data.src;
    });
  }
}

class SetDashboardSettings extends Command {
  execute() {
    Object.assign(this.state.settings, this.event.options);

    this.state.defaultSettings = Object.assign(
      {},
      {
        ...this.state.settings,
        daterange: {},
      }
    );

    return Promise.resolve();
  }
}

class SetDrilldownSettings extends Command {
  execute() {
    const settings = this.state.settings;
    const { dashboard, filters } = this.event;

    const filterNames = filters.reduce((names, filter) => {
      switch (filter.name) {
        case "aggregation":
          settings.aggregation = filter.value;
          break;
        case "custom_range_start_date":
          settings.daterange.start = filter.value;
          break;
        case "custom_range_end_date":
          settings.daterange.end = filter.value;
          break;
        case "date_range":
          settings.daterange.start = filter.value;
          settings.daterange.end = filter.value;
          break;
        default:
          if (!this.state.defaultSettings.visible.includes(filter.name)) {
            names.push(filter.name);
          }
      }

      return names;
    }, []);

    Object.assign(this.state.settings, {
      dashboard: dashboard,
      filters: filters,
      visible: [...this.state.defaultSettings.visible, ...filterNames],
    });

    return Promise.resolve();
  }
}

class ResizeDashboard extends Command {
  execute() {
    const height = this.event.height < 600 ? 600 : this.event.height;

    this.state.iframeStyle.height = `${height}px`;

    return Promise.resolve();
  }
}

class ResetDashboard extends Command {
  execute() {
    this.state.settings = Object.assign(
      {},
      {
        ...this.state.defaultSettings,
        daterange: {},
      }
    );

    return Promise.resolve();
  }
}

class RequestDashboard extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Periscope.DashboardRequested,
      PS.Services.MessageBusService.loanBus,
      this.event.options
    );
  }
}

class RequestDashboardResize extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Periscope.DashboardResizeRequested,
      PS.Services.MessageBusService.loanBus,
      this.event.options
    );
  }
}

class RequestDrilldown extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Periscope.DrilldownRequested,
      PS.Services.MessageBusService.loanBus,
      this.event.options
    );
  }
}

PS.Models.Loan.Commands.Periscope = {
  LoadDashboard: LoadDashboard,
  SetDashboardSettings: SetDashboardSettings,
  SetDrilldownSettings: SetDrilldownSettings,
  ResetDashboard: ResetDashboard,
  RequestDashboard: RequestDashboard,
  RequestDashboardResize: RequestDashboardResize,
  RequestDrilldown: RequestDrilldown,
  ResizeDashboard: ResizeDashboard,
};
