<template>
  <div class="loan-documents">
    <div class="row">
      <div :class="fileListClass">
        <table v-if="isDocumentsPresent()">
          <thead>
            <tr>
              <th class="document-name">
                Name
              </th>
              <th class="document-modified-date">
                Modified
              </th>
              <th />
              <th v-if="isAnalyst">
                Document Type
              </th>
              <th v-if="isLender" class="document-task-tags">
                Task
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="document in orderedDocuments" :key="document.id">
              <base-tooltip
                :always="false"
                position="is-top"
                :label="document.originalFileName"
              >
                <td class="document-name">
                  <a target="_blank" :href="document.url">
                    {{ document.fullName }}
                  </a>
                </td>
              </base-tooltip>
              <td>
                {{ document.lastModifiedAtText }}
              </td>
              <td class="file-actions">
                <a v-if="isAnalyst" href="#" @click.prevent="editDocument(document)">
                  <img alt="Edit file" :src="require('images/icons/icon_pencil.svg')">
                </a>
                <a :href="document.url">
                  <img alt="Download file" :src="require('images/icons/icon_download.svg')">
                </a>
                <diligence-document-files-list-delete-button
                  :document="document"
                  @deleteFile="deleteDocument"
                />
              </td>

              <td v-if="isAnalyst" class="filetype-list">
                <select2
                  v-model="document.loanDocumentTypeId"
                  class="form-control v-filetype-list"
                  :options="{ placeholder: 'Select a File Type...' }"
                  @input="updateDocument(document)"
                >
                  <option v-for="type in orderedDocumentTypes" :key="type.id" :value="type.id">
                    {{ type.text }}
                  </option>
                </select2>
              </td>

              <td v-if="isLender">
                <task-selector
                  :document="document"
                  :tasks="tasks"
                  @task-attached="onTaskAttached"
                  @task-detached="onTaskDetached"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="isInternalDocumentsPresent()" class="loan-internal-documents">
          <h4 class="section-title">
            Internal Documents
          </h4>

          <table>
            <thead>
              <tr>
                <th class="document-name">
                  Name
                </th>
                <th class="document-modified-date">
                  Modified
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr v-for="document in internalDocuments" :key="document.id">
                <td class="document-name">
                  <a target="_blank" :href="document.url">
                    {{ document.fullName }}
                  </a>
                </td>
                <td>
                  {{ document.createdAtText }}
                </td>
                <td class="file-actions">
                  <a v-if="isAnalyst" href="#" @click.prevent="editDocument(document)">
                    <img alt="Edit file" :src="require('images/icons/icon_pencil.svg')">
                  </a>
                  <a :href="document.url">
                    <img alt="Download file" :src="require('images/icons/icon_download.svg')">
                  </a>
                  <diligence-document-files-list-delete-button
                    :document="document"
                    @deleteFile="deleteDocument"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="isAnalyst" class="col-4 checklist-container">
        <analyst-documents-checklist
          :documents-info="[]"
          :documents="publicDocuments"
          :loan="loan"
          :types="checklistTypes"
        />
      </div>
    </div>

    <diligence-document-edit-modal
      :document="targetDocument"
      :on-update="updateDocument"
      :show="showEditModal"
      @hide-modal="cancelEditDocument()"
    />
  </div>
</template>

<script>
import TaskSelector from "components/diligence_document/task_selector";
import DocumentTypes from "collections/document_types";
import Documents from "collections/documents";
import AnalystDocumentsChecklist from "components/loans/analyst_documents_checklist";
import SegmentService from "services/segment_service";

import {
  RequestDocumentRemoval,
  RequestOneDocumentUpdate,
  AttachTaskToDocument,
  DetachTaskFromDocument,
} from "models/loan/commands/document_commands";

import {
  DocumentDeleted,
  OneDocumentUpdated,
  TaskAttachmentChanged,
} from "models/loan/events/document_events";

export default Vue.component("diligence-document-files-list", {
  components: {
    TaskSelector,
    AnalystDocumentsChecklist,
  },
  props: {
    checklistTypes: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Array,
      default: () => [],
    },
    loan: Object,
    role: String,
    documents: Array,
    tasks: Array,
  },
  data() {
    return {
      showEditModal: false,
      targetDocument: undefined,
    };
  },
  computed: {
    internalDocuments() {
      return this.documents.filter(doc => doc.visibility === "internal");
    },
    publicDocuments() {
      return this.documents.filter(doc => doc.visibility === "public");
    },
    orderedDocumentTypes() {
      return DocumentTypes.orderByName(this.types);
    },
    orderedDocuments() {
      if (this.isAnalyst) {
        return new Documents(this.publicDocuments, this.types).orderedByTypeAndName();
      } else {
        return new Documents(this.publicDocuments, this.types).orderedByNameAndDate();
      }
    },
    isAnalyst() {
      return this.role === "analyst";
    },
    isLender() {
      return !this.isAnalyst;
    },
    fileListClass() {
      if (this.isAnalyst) {
        return "loan-documents-table col-8";
      } else {
        return "loan-documents-table col-12";
      }
    },
  },
  methods: {
    editDocument(document) {
      this.targetDocument = document;
      this.showEditModal = true;
    },
    cancelEditDocument() {
      this.showEditModal = false;
    },
    deleteDocument(deletedDocument) {
      new RequestDocumentRemoval(new DocumentDeleted({ document: deletedDocument })).execute();
    },
    updateDocument(document) {
      new RequestOneDocumentUpdate(new OneDocumentUpdated({ document })).execute();
    },
    isDocumentsPresent() {
      return this.publicDocuments.length > 0;
    },
    isInternalDocumentsPresent() {
      return this.isAnalyst && this.internalDocuments.length > 0;
    },
    onTaskAttached({ document, taskId }) {
      const task = this.tasks.find(task => task.id == taskId);
      if (task) {
        SegmentService.track("associateFile", {
          taskSystemName: task.definition.systemName,
          loanId: this.loan.toParam,
        });
      }

      new AttachTaskToDocument(new TaskAttachmentChanged({ document, taskId })).execute();
    },
    onTaskDetached({ document, taskAttachmentId }) {
      new DetachTaskFromDocument(
        new TaskAttachmentChanged({
          document,
          taskAttachmentId,
        })
      ).execute();
    },
  },
});
</script>
