<template>
  <a
    v-if="hasServicerUrl"
    :href="url"
    rel="noopener noreferrer"
    target="_blank"
  >{{ id }}</a>
  <span v-else>{{ id }}</span>
</template>

<script>
import isEmpty from "lodash/isEmpty";

export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  computed: {
    hasServicerUrl() {
      return !isEmpty(this.url);
    },
  },
};
</script>
