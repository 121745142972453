import BaseResponse from "./base_response";

export default class ResidentialForRentResponse extends BaseResponse {
  constructor(...args) {
    super(...args);

    this.dscr = parseFloat(this.response.dscr);
    this.minDscr = parseFloat(this.response.minDscr);
    this.interestRate = parseFloat(this.response.noteRate);
    this.cbFlag = !this.interestRate || this.dscr < this.minDscr ? true : false;
  }

  toHash() {
    return {
      bestRateLoanAmount: this.bestRateLoanAmount,
      cbFlag: this.cbFlag,
      custom: this.custom,
      dscr: this.dscr,
      interestRate: this.interestRate,
      loanAmount: this.loanAmount,
      ltv: this.ltv,
      maxLeverageLoanAmount: this.maxLeverageLoanAmount,
      minDscr: this.minDscr,
      monthlyPayment: parseFloat(this.response.monthlyPayment),
    };
  }
}
