import {
  SETTINGS_KEYS as HOTLIST_SETTINGS_KEYS,
  SETTINGS_CONFIG as HOTLIST_SETTINGS_CONFIG,
} from "./hotlist_config";
import { SETTINGS_CONFIG as LOAN_DETAILS_TABS_SETTINGS_CONFIG } from "./loan_nav_tabs_config";
import { DEFAULT_TASKS_FILTER } from "./loan_tasks_filter_config";

export const SETTINGS_KEYS = {
  hotlist: HOTLIST_SETTINGS_KEYS,
  loanNavTabs: "loanNavTabs",
  loanTasksFilter: "loanTasksFilter",
};

export const SETTINGS_CONFIG = {
  ...HOTLIST_SETTINGS_CONFIG,
  [SETTINGS_KEYS.loanNavTabs]: LOAN_DETAILS_TABS_SETTINGS_CONFIG,
  [SETTINGS_KEYS.loanTasksFilter]: DEFAULT_TASKS_FILTER,
};
