import Input from "./input";
import Preview from "./preview";

export default class Step {
  static deserialize({
    id,
    uuid,
    workflow_id,
    name,
    description,
    inputs,
    previews,
    metadata,
    completed_at,
  } = {}) {
    return new Step(
      id,
      name,
      description,
      inputs.map(inp => Input.deserialize(inp)),
      previews.map(prw => Preview.deserialize(prw)),
      completed_at,
      uuid,
      workflow_id,
      metadata
    );
  }

  constructor(
    id,
    name,
    description,
    inputs,
    previews,
    completedAt,
    uuid = "",
    workflowId = "",
    metadata = {}
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.inputs = inputs;
    this.previews = previews;
    this.completedAt = completedAt;
    this.uuid = uuid;
    this.workflowId = workflowId;
    this.completedBy = metadata.completed_by || {};
  }

  toServer() {
    return {
      id: this.id,
      name: this.name,
      inputs: this.inputs.map(inp => inp.toServer()),
    };
  }
}
