import ChartSource from "models/market_data/charts/source";

export default class MonthlyStats extends ChartSource {
  constructor(...args) {
    super(...args);
  }

  pointType(point) {
    return "observed";
  }
}
