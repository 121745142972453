<template>
  <div :class="className">
    <flat-pickr
      ref="flatPickr"
      v-mask:date
      :class="inputClassName"
      :value="value"
      :config="datePickerOptions"
      :disabled="disabled"
      @blur="onBlur"
    />
  </div>
</template>
<script>
import FlatPickr from "vue-flatpickr-component";
import { Mask } from "directives/inputs";

export default {
  name: "date-picker",
  components: { FlatPickr },
  directives: {
    Mask,
  },
  props: {
    value: [Date, String],
    className: String,
    options: {
      type: Object,
      default: () => ({}),
    },
    inputClassName: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    datePickerOptions() {
      return {
        onChange: (_selectedDates, _dateStr, instance) => {
          this.$emit("input", instance.element.value);
        },
        enableTime: false,
        static: true,
        ...this.options,
      };
    },
  },
  methods: {
    onBlur(event) {
      if (!this.$refs.flatPickr.fp.isOpen) {
        this.$refs.flatPickr.fp.setDate(event, true);
      }
    },
  },
};
</script>
