import moment from "moment";

const current = {
  moment: moment(),
};

setInterval(() => {
  current.moment = moment();
}, 1000);

export default current;
