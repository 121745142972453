<template>
  <span class="fieldset-preview">{{ fieldPreview }}</span>
</template>

<script>
import { isEmpty } from "lodash";
export default Vue.component("fieldset-preview", {
  props: {
    fields: Array,
  },
  computed: {
    fieldPreview() {
      const field = this.fields.find(field => field.isPreview);
      if (isEmpty(field)) {
        return null;
      }
      if (field.type == "select") {
        return field.collection.find(option => option.value == field.value)?.name;
      } else {
        return field.value;
      }
    },
  },
});
</script>
