<template>
  <div class="user-input-item">
    <div class="user-input" :class="{'user-input-subsection': childInputs.length}">
      <div class="user-input-label">
        {{ userInput.name }}
      </div>
      <div class="user-input-value">
        <a
          v-if="isUserInputFile"
          :href="userInput.value.url"
          target="_blank"
        >
          {{ userInput.value.name }}
        </a>
        <span v-else>{{ presentValue(userInput.value.value) }}</span>
      </div>
    </div>

    <user-input-item
      v-for="input in childInputs"
      :key="input.id"
      :user-input="input"
      :user-inputs="userInputs"
    />
  </div>
</template>

<script>
import moment from "moment";

const DATE_FORMAT = "MM/DD/YYYY";

export default {
  name: "step-user-input-component",
  components: {
    UserInputItem: () => import("components/manage/workflows/user_input_item.vue"),
  },
  props: {
    userInput: {
      type: Object,
      required: true,
    },
    userInputs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isUserInputFile() {
      return this.userInput.variableType === "file";
    },
    isUserInputDate() {
      return this.userInput.variableType === "date";
    },
    childInputs() {
      return this.userInputs.filter(input => input.parentVariableInstanceId === this.userInput.id);
    },
  },
  methods: {
    presentValue(value) {
      if (this.isUserInputDate) {
        return value ? moment(value).format(DATE_FORMAT) : null;
      } else {
        return value;
      }
    },
  },
};
</script>

<style scoped>
.user-input-item {
  border-bottom: 1px solid var(--color-grey-16);
}

.user-input-item:last-child {
  border: 0 none;
}

.user-input {
  display: flex;
  justify-content: space-between;
  padding: var(--space-unit-sm) 0;
}

.user-input-subsection {
  padding: calc(var(--space-unit) * 2) 0 var(--space-unit) 0;
}

.user-input-value {
  font-weight: 600;
  text-align: right;
}

.user-input-label {
  font-weight: normal;
}

.user-input-subsection .user-input-label {
  font-weight: 600;
}
</style>
