const Charts = PS.Models.MarketData.Charts;
const MarketDataSource = PS.Models.MarketData.Source;
const MultiSource = PS.Models.MarketData.Charts.MultiSource;
const Calculator = PS.Models.MarketData.Charts.Calculator;

class MidasJobsTimeSeries extends MarketDataSource {
  constructor() {
    super();

    this.msaJobGrowth = [];
    this.nationalJobGrowth = [];
  }

  get name() {
    return "Job Growth";
  }

  success(data) {
    this.msaJobGrowth = data.msaJobGrowth;
    this.nationalJobGrowth = data.nationalJobGrowth;

    super.success();
  }

  get sources() {
    return [
      new Charts.Midas("msaJobGrowth", this.msaJobGrowth, "date", "value"),
      new Charts.Midas("nationalJobGrowth", this.nationalJobGrowth, "date", "value"),
    ];
  }
}

class JobGrowth extends MultiSource {
  constructor() {
    super();

    this._sources = {
      midas: new MidasJobsTimeSeries(),
    };
  }

  get name() {
    return "Job Growth";
  }

  get msaMostRecent() {
    let value = Calculator.mostRecent(this, "msaJobGrowth") * 100;

    return Number.isNaN(value) ? undefined : value;
  }

  get nationalMostRecent() {
    let value = Calculator.mostRecent(this, "nationalJobGrowth") * 100;

    return Number.isNaN(value) ? undefined : value;
  }
}

PS.Models.MarketData.Charts.JobGrowth = JobGrowth;
