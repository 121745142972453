<template>
  <div v-if="show" class="blocked-party-banner">
  <base-message title=" " type="warning">
    <p>Upon further review and analysis of the information and documentation provided, PeerStreet cannot move forward with the requested loan transaction. PeerStreet appreciates the opportunity to work with you and looks forward to working with you in the future. Please feel free to contact your RM with any questions.</p>
  </base-message>
  </div>
</template>

<script>
export default {
  name: "blocked-party-detected-alert",
  props: {
    show: Boolean,
  },
};
</script>
<style scoped>
.blocked-party-banner {
  margin: 24px 0;
}
</style>
