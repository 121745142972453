import VueRouter from "vue-router";
import SegmentService from "services/segment_service";
import useScenarioBuilderStore from "./store";
import LoanScenariosDraft from "./draft";
import LoanScenariosList from "./list";
// when extracted from LP needs this too:
// import VueCompositionAPI from "@vue/composition-api";
// import { PiniaVuePlugin } from "pinia";
// Vue.use(VueCompositionAPI); // Important: has to be installed before VueRouter (Pinia needs it)
// Vue.use(PiniaVuePlugin);
// import Vue from "vue";
// Vue.use(VueRouter);

const routes = [
  {
    name: "loan_scenarios",
    path: "/",
    component: LoanScenariosList,
    meta: {
      title: "Scenarios",
    },
    props: { ...accountType() },
  },
  {
    name: "new_loan_scenario",
    path: "/new",
    component: LoanScenariosDraft,
    props: { id: "new", ...accountType() },
    meta: {
      title: "New Scenario",
    },
  },
  {
    name: "edit_loan_scenario",
    path: "/:id",
    component: LoanScenariosDraft,
    props: route => ({ id: route.params.id, ...accountType() }),
    meta: {
      title: route => `Scenario ${route.params.id}`,
    },
  },
];

function accountType() {
  return { lenderAccountType: PSData.lenderAccountType };
}

function pageTitleFrom({ route }) {
  let routeTitle = route.meta.title;
  let pageTitle = typeof routeTitle === "function" ? routeTitle(route) : routeTitle;

  return `${pageTitle} | PeerStreet Lender Platform`;
}

function isScenarioBuilder({ route }) {
  return ["new_loan_scenario", "edit_loan_scenario"].includes(route.name);
}

export default function createRouter({ base }) {
  let router = new VueRouter({ routes, base });

  router.afterEach((to, from) => {
    let store = useScenarioBuilderStore();

    if (isScenarioBuilder({ route: to })) {
      store.startEvaluationSession();
    } else {
      store.endEvaluationSession();
    }

    let title = pageTitleFrom({ route: to });
    let pageDetails = { title };
    // We have to distinguish the use of Scenario Builder on lender vs internal pages.
    // This code does not belong here if Scenario Builder is to be extracted into micro-frontend.
    let pageName = window.location.pathname.startsWith("/manage") ? `manage_${to.name}` : to.name;
    if (from.name) {
      pageDetails.referrer = window.location.origin + router.resolve(from).href;
    }

    window.document.title = title;
    SegmentService.page(pageName, pageDetails);
  });

  return router;
}
