<template>
  <div class="task-group">
    <sla-task-dropdown
      :reviewed="reviewed"
      :approved="approved"
      :canReview="canReview"
      :canApprove="canApprove"
      @reviewed="$emit('reviewed', $event)"
      @approved="$emit('approved', $event)"
    >
      <span class="name">{{name}}</span>
      <sla-task-status v-if="needReview" title="Reviewed" :done="reviewed" />
      <sla-task-status title="Approved" :done="approved" />
    </sla-task-dropdown>
  </div>
</template>

<script>
import SlaTaskStatus from "./task_status";
import SlaTaskDropdown from "./task_dropdown";

export default {
  name: "sla-task-group",
  components: { SlaTaskStatus, SlaTaskDropdown },
  props: {
    name: {
      type: String,
      required: true,
    },
    canReview: {
      type: [Boolean, null],
      default: null,
    },
    canApprove: {
      type: [Boolean, null],
      default: null,
    },
    reviewed: {
      type: [Boolean, null],
      default: null,
    },
    approved: {
      type: [Boolean, null],
      default: null,
    },
    needReview: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
