<template>
  <div class="details-row row no-gutters section-content">
    <div class="col-12 section-header">
      <div class="section-header-col">
        <div>
          <h4>Documents</h4>
        </div>
        <span class="subheader">Document templates are generated and edited using Google Docs</span>
      </div>
      <div v-if="canEdit" class="section-header-col loan-actions">
        <button
          class="btn btn-action-cta"
          @click="handleModalOpen()"
        >
          Generate Template
        </button>
      </div>
    </div>
    <div class="col-12">
      <notification-group
        :title="'Foreclosure Document Operation Error'"
        :message-groups="errors"
        :dismissible="true"
        @dismissed="clearErrors"
      />
    </div>
    <div v-if="emptyDocumentList" class="col-12">
      <div class="empty-foreclosure-document-table">
        <span class="no-foreclosure-documents">No documents added</span>
      </div>
    </div>
    <div v-else class="col-12 incomplete-task-section">
      <div class="incomplete-task foreclosure-document-table">
        <table class="col-12">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Document Type</th>
              <th>Create Date</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <foreclosure-document-list-item
              v-for="foreclosureDocument in foreclosureDocuments"
              :key="foreclosureDocument.id"
              :foreclosure-document="foreclosureDocument"
              :can-edit="canEdit"
              @delete="handleDelete"
            />
          </tbody>
        </table>
      </div>
    </div>

    <foreclosure-document-modal
      :show="showTemplateDocumentModal"
      @close="handleModalClose"
      @generate="handleSubmit"
    />
  </div>
</template>

<script>
import ForeclosureDocumentListItem from "./foreclosure_document_list_item";
import ForeclosureDocumentModal from "./foreclosure_document_modal";
import NotificationGroup from "components/shared/notification_group";

import toPlainObject from "utils/to_plain_object";
import AjaxService from "services/ajax_service";

export default {
  name: "foreclosure-document-section",
  components: {
    ForeclosureDocumentListItem,
    ForeclosureDocumentModal,
    NotificationGroup,
  },
  props: {
    loanPsId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errors: [],
      foreclosureDocuments: PSData.loanForeclosureDocuments || [],
      showTemplateDocumentModal: false,
    };
  },
  computed: {
    baseUrl() {
      return `/manage/loans/${this.loanPsId}/asset_management/foreclosure_documents`;
    },
    canEdit: () => PSData.assetManagementCanEdit,
    emptyDocumentList() {
      return this.foreclosureDocuments.length === 0;
    },
  },
  methods: {
    clearErrors() {
      this.errors = [];
    },
    createPendingDocumentRecord() {
      const pendingRecord = {
        type: "Generating...",
        pending: true,
        createdAt: new Date(),
      };
      this.foreclosureDocuments.unshift(pendingRecord);
    },
    errorMessage() {
      const message = "Unable to create/delete foreclosure document at this time";
      return { message, status: "error" };
    },
    handleModalOpen() {
      this.showTemplateDocumentModal = true;
    },
    handleModalClose() {
      this.showTemplateDocumentModal = false;
    },
    async handleDelete(documentId) {
      try {
        await AjaxService.withNotifications({
          onSuccess: "Foreclosure Document was succesfully deleted",
          onError: this.errorMessage,
        }).delete(`${this.baseUrl}/${documentId}`);
        this.foreclosureDocuments = this.foreclosureDocuments.filter(({ id }) => id !== documentId);
      } catch ({ data, response }) {
        if (data && data.error) {
          this.errors = [data.error];
        } else {
          this.errors = ["Unable to delete foreclosure document"];
        }
      }
    },
    async handleSubmit(templateId) {
      try {
        this.handleModalClose();
        this.createPendingDocumentRecord();
        const { document } = await AjaxService.withNotifications({
          onSuccess: "Foreclosure Document was succesfully created",
          onError: this.errorMessage,
        }).post(this.baseUrl, { document_type: templateId });
        this.updatePendingDocumentRecord(document);
      } catch ({ data, response }) {
        this.removePendingDocumentRecord();
        if (data && data.errors) {
          this.errors = [data.errors];
        } else {
          this.errors = ["Unable to create foreclosure document"];
        }
      }
    },
    removePendingDocumentRecord() {
      this.foreclosureDocuments = this.foreclosureDocuments.filter(({ pending }) => !pending);
    },
    updatePendingDocumentRecord(document) {
      this.removePendingDocumentRecord();
      this.foreclosureDocuments.unshift(document);
    },
  },
};
</script>
