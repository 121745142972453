<template>
  <div class="loan-task-buttons dropdown-button btn-group" role="group">
    <button
      :disabled="confirmDisabled"
      class="btn btn-action-cta loan-task-btn"
      @click="$emit('confirm')"
    >
      Mark Complete
    </button>

    <template>
      <button
        :disabled="confirmDisabled"
        class="btn btn-action-cta loan-task-btn dropdown-toggle dropdown-toggle-split"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="fas fa-caret-down" />
      </button>
      <div class="dropdown-menu">
        <button
          :disabled="completeDisabled"
          class="dropdown-item loan-task-btn"
          @click="$emit('complete')"
        >
          Move to Pending
        </button>
        <button
          :disabled="reopenDisabled"
          class="dropdown-item loan-task-btn"
          @click="$emit('reopen')"
        >
          Reopen
        </button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "task-transit-actions",
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    confirmDisabled() {
      return !this.tasks.length;
    },
    completeDisabled() {
      return this.tasks.some(task => !task.isOpen || !task.isLender);
    },
    reopenDisabled() {
      return this.tasks.some(task => task.isOpen || !task.isLender);
    },
  },
};
</script>
