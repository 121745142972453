export default function(object, shouldIncludeProperty = (obj, key) => true) {
  let result = {};

  for (let propertyName in object) {
    if (shouldIncludeProperty(object, propertyName)) {
      result[propertyName] = object[propertyName];
    }
  }

  return result;
}
