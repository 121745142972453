import GlobalBusService from "services/global_bus_service";
import MessageBusService from "services/message_bus_service";
import DocumentRules from "models/loan/rules/document_rules";
import AjaxService from "services/ajax_service";
import { error } from "services/notification";
import LoanQuickPreview from "components/loans/loan_quick_preview";
import OpenQuickPreview from "components/loans/open_quick_preview";
import FollowingOnlyLoansToggle from "components/loans/following_only_loans_toggle";
import LoanStatusTracker from "components/loan_status_tracker";

$(() => {
  if (!document.querySelector(".v-loans-controller")) {
    return;
  }

  new Vue({
    components: {
      LoanQuickPreview,
      OpenQuickPreview,
      FollowingOnlyLoansToggle,
      LoanStatusTracker,
    },
    el: document.querySelector(".v-loans-controller"),
    data: {
      documents: [],
      events: [],
      loan: undefined,
      loanFollowers: [],
      lenderUsers: [],
      routes: {
        ...PSData.routes,
        loanTask: undefined,
        messageViews: undefined,
        loanFollowers: undefined,
      },
      hasUnreadMessages: PSData.hasUnreadMessages,
      messageBus: MessageBusService.loanBus,
      ruleBook: undefined,
      loanIdsWithTaskActivity: PSData.loanIdsWithTaskActivity,
      loanIdsWithMessageActivity: PSData.loanIdsWithMessageActivity,
      selectedLoanId: undefined,
    },
    mounted() {
      this.rulebook = new DocumentRules(this);
      this.messageBus.attachRulebook(this.rulebook);

      GlobalBusService.$on("open-quick-preview", this.openQuickPreview);
      GlobalBusService.$on("loan-task-activity", this.loanTaskActivity);
      GlobalBusService.$on("loan-message-activity", this.loanMessageActivity);
    },
    methods: {
      loanMessageActivity(value) {
        if (!value) {
          Vue.delete(
            this.loanIdsWithMessageActivity,
            this.loanIdsWithMessageActivity.indexOf(this.selectedLoanId)
          );
        }
      },
      loanTaskActivity(value) {
        if (!value) {
          Vue.delete(
            this.loanIdsWithTaskActivity,
            this.loanIdsWithTaskActivity.indexOf(this.selectedLoanId)
          );
        }
      },
      highlight(loanId) {
        Array.from(document.querySelectorAll(".loans-list-table-body tr")).forEach(tr => {
          if (tr.dataset.loanId == loanId) {
            this.selectedLoanId = loanId;
            tr.classList.add("loan-selected");
          } else {
            tr.classList.remove("loan-selected");
          }
        });
      },
      openQuickPreview(loanId) {
        const url = `${this.routes.loans}/${loanId}/preview`;

        return AjaxService.get(url)
          .then(data => {
            this.highlight(loanId);

            this.documents = data.documents;
            this.events = data.events;
            this.loan = data.loan;
            this.hasUnreadMessages = data.hasUnreadMessages;

            // for task-attachments-dropdown & js-upload components
            PSData.uploadSettings = data.uploadSettings;
            PSData.loan = data.loan;

            this.routes.loanTask = data.routes.loanTask;
            this.routes.messageViews = data.routes.messageViews;
            this.routes.loanFollowers = data.routes.loanFollowers;
          })
          .catch(() => {
            this.loan = undefined;

            error();
          });
      },
    },
  });
});
