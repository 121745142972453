<template>
  <div class="creditbox-settings-form">
    <div class="creditbox-settings-header">
      {{ title }}
    </div>
    <div class="row">
      <with-errors on="loan_program_id" :errors="errors" class="col-6 control-wrapper form-group">
        <label for="loanProgramId">Loan Program</label>
        <select
          id="loanProgram"
          v-model="creditboxSettings.loanProgramId"
          name="loanProgram"
          class="form-control"
        >
          <option v-for="[label, name] in options" :key="name" :value="name">
            {{ label }}
          </option>
        </select>
      </with-errors>
    </div>
    <div class="row">
      <div class="col-6">
        <div ref="configEditor" />
      </div>
      <div class="col-6">
        <creditbox-settings-rules
          :rules="rules"
          :ruleset="creditboxSettings.creditboxRuleIds"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <button class="btn-action-cta-sm" :disabled="saveDisabled" @click="save">
          Save
        </button>
        <button class="btn btn-cancel-cta-sm" @click="cancel">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { JSONEditor } from "@json-editor/json-editor";
import AjaxService from "services/ajax_service";
import CreditboxSetting from "models/creditbox_settings";
import WithErrors from "components/shared/with_errors";
import CreditboxSettingsRules from "./rules";
import isEmpty from "lodash/isEmpty";

export default {
  name: "creditbox-settings-form",
  components: { WithErrors, CreditboxSettingsRules },
  props: {
    setting: {
      type: CreditboxSetting,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    routes: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editor: null,
      schema: this.schema,
      errors: {},
      creditboxSettings: this.setting.clone(),
      rules: PSData.rules,
    };
  },
  computed: {
    saveDisabled() {
      return !this.editor;
    },
    title() {
      if (this.creditboxSettings.isPersisted()) {
        return "Edit Config";
      } else {
        return "Add New Config";
      }
    },
  },
  watch: {
    "creditboxSettings.loanProgramId": {
      handler(newLoanProgramId, oldLoanProgramId) {
        this.handleLoanProgramChange(newLoanProgramId, oldLoanProgramId);
      },
    },
  },
  mounted() {
    if (this.creditboxSettings.loanProgramId) {
      this.handleLoanProgramChange(this.creditboxSettings.loanProgramId);
    }
  },
  methods: {
    handleLoanProgramChange(newLoanProgramId, oldLoanProgramId) {
      if (newLoanProgramId) {
        this.loadConfigSchema(newLoanProgramId).then(data => {
          this.schema = data;
          this.destroyEditor();
          if (oldLoanProgramId) {
            this.creditboxSettings.settings = {};
          }
          this.mountEditor();
        });
      }
    },
    loadConfigSchema(loanProgramId) {
      const url = `/manage/creditbox_settings/new?loan_program_id=${encodeURIComponent(
        loanProgramId
      )}`;

      return AjaxService.getJSON(url);
    },
    destroyEditor() {
      if (this.editor) {
        this.editor.destroy();
      }
    },
    mountEditor() {
      this.editor = new JSONEditor(this.$refs.configEditor, {
        theme: "bootstrap4",
        iconlib: "fontawesome5",
        schema: this.schema,
        disable_edit_json: true,
        no_additional_properties: true,
        remove_button_labels: true,
        show_errors: "always",
        ...(!isEmpty(this.creditboxSettings.settings) && {
          startval: this.creditboxSettings.settings,
        }),
      });
    },
    cancel() {
      this.$emit("on-cancel");
    },
    save() {
      if (!this.editor) return;

      const errors = this.editor.validate();
      if (errors.length) return;

      const creditboxSettings = this.creditboxSettings.toPlainObject({ toSnakeCase: true });

      const params = {
        creditbox_settings: Object.assign(creditboxSettings, { settings: this.editor.getValue() }),
      };

      if (this.creditboxSettings.isPersisted()) {
        this.update(params);
      } else {
        this.create(params);
      }
    },
    create(params) {
      const messages = { onSuccess: "Config is successfully created", onError: this.onError };
      const url = "/manage/creditbox_settings";

      AjaxService.withNotifications(messages)
        .postJSON(url, params)
        .then(data => {
          this.$emit("on-update", data);
        })
        .catch(() => {});
    },
    update(params) {
      const messages = { onSuccess: "Config is successfully updated", onError: this.onError };
      const url = `/manage/creditbox_settings/${this.creditboxSettings.id}`;

      AjaxService.withNotifications(messages)
        .patchJSON(url, params)
        .then(data => {
          this.$emit("on-update", data);
        })
        .catch(() => {});
    },
    onError({ response, data }) {
      if (response.status === 422) {
        this.errors = data.errors;
      } else if (response.status === 403) {
        return {
          message: "You're not allowed to manage creditbox settings",
          status: "error",
        };
      } else {
        Bugsnag.notify(new Error(`Unable to save creditbox config: ${response.statusText}`));
      }
    },
  },
};
</script>
