$(function() {
  class NavTabs {
    constructor(element) {
      if (window.location.hash.length > 0) {
        element.find(`a[href="${location.hash}"]`).tab("show");
      }

      element.find("a").on("click", function() {
        window.location.hash = $(this).attr("href");
      });
    }
  }

  new NavTabs($(".loan-tabs"));
});
