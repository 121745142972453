const Event = PS.Models.Event;
const Rule = PS.Models.Rule;
const Events = PS.Models.EmailSubscription.Events;
const Commands = PS.Models.EmailSubscription.Commands;

class ItemRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.AllSubscriptionUpdated:
        return this.whenAllSubscriptionUpdated(event);
    }

    return super.check(event);
  }

  whenAllSubscriptionUpdated(event) {
    let isDifferentCategory = event.subscription.category !== this.state.subscription.category;
    let isAllSubscriptionEnabled = event.subscription.enabled;

    if (isDifferentCategory || !isAllSubscriptionEnabled) {
      return [];
    }

    return [new Commands.Item.DisableSubscription(new Event(), this.state)];
  }
}

PS.Models.EmailSubscription.ItemRule = ItemRule;
