<template>
  <select-input
    label="Sale Type"
    name="saleType"
    rules="required"
    :value="value"
    :options="options"
    :disabled="disabled"
    v-on="$listeners"
  />
</template>

<script>
import map from "lodash/map";

import SelectInput from "./select_input";

const saleTypeOptions = () => map(PSData.saleTypes, (text, id) => ({ text, id }));

export default {
  components: {
    SelectInput,
  },
  props: {
    options: {
      type: Array,
      default: () => saleTypeOptions(),
    },
    value: {
      type: String,
      default: () => saleTypeOptions()[0].id,
    },
    disabled: Boolean,
  },
};
</script>
