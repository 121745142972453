<template>
  <table class="view-table">
    <tbody>
      <template v-for="item in data">
        <tr v-if="showRow(item)" :key="item.label">
          <td class="view-table-cell-label">
            {{ item.label }}
          </td>
          <td class="view-table-cell-value">{{ item.value }}</td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "loan-details-list",
  props: {
    data: {
      type: Array,
      required: true,
    },
    hideEmpty: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showRow(item) {
      return item.show && (!this.hideEmpty || item.value);
    },
  },
};
</script>
