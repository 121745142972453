<template>
  <table class="percentile">
    <tr>
      <td>
        <b>
          {{value | percent(0, true) | notAvailable}}
        </b>
      </td>
      <td v-for="bar in bars" :style="'width:' + (90 / segments) + '%'">
        <div class="percentile-bar">
          <div class="progressbar">
            <div role="progressbar" :style="'width:' + bar + '%'" :aria-valuenow="bar" aria-valuemin="0" aria-valuemax="100" class="progress-bar">
              &nbsp;
            </div>
          </div>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
const SEGMENT_FULL_WIDTH = 100;

export default {
  name: "percentile-bar",
  props: {
    value: Number,
    segments: {
      type: Number,
      default: 4,
    },
  },
  data() {
    const segmentSize = 100 / this.segments;
    const percentValue = this.value || 0;

    return {
      bars: Array(this.segments)
        .fill(0)
        .map((_, index) => {
          const remainValue = percentValue - segmentSize * index;

          if (remainValue < 0) {
            return 0;
          }

          if (remainValue > segmentSize) {
            return SEGMENT_FULL_WIDTH;
          }

          return (remainValue / segmentSize) * SEGMENT_FULL_WIDTH;
        }),
    };
  },
};
</script>
