<template>
  <base-field v-if="taskMapping.useGlobalDescription" label="Global Description" class="py-1">
    <div
      class="content"
      v-html="definition.defaultDescription"
    />
  </base-field>
  <description-rules v-else :task-mapping="taskMapping" :editable="false" />
</template>
<script>
import LoanTaskDefiniton from "models/loan_task_definition";
import DescriptionRules from "./description_rules";

export default {
  components: {
    DescriptionRules,
  },
  props: {
    taskMapping: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    definition() {
      return new LoanTaskDefiniton(this.taskMapping && this.taskMapping.taskDefinition);
    },
  },
};
</script>
