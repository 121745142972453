import omit from "lodash/omit";

export default class FundingEntitiesCollection {
  static pickBankAccounts(fundingEntitiesCollection) {
    const bankAccountByAccountName = fundingEntitiesCollection.reduce((result, fundingEntity) => {
      const bankAccountName = fundingEntity.bank.name;
      result[bankAccountName] = result[bankAccountName] || fundingEntity.bank;
      result[bankAccountName].fundingEntitiesNames =
        result[bankAccountName].fundingEntitiesNames || [];
      result[bankAccountName].fundingEntitiesNames.push(fundingEntity.name);
      return result;
    }, {});
    return Object.values(bankAccountByAccountName);
  }
}
