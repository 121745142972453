import requireGoogleMap from "google_maps";

const Datasource = PS.Models.Autocomplete.Datasource;
const Autocomplete = PS.Models.Autocomplete;
const Suggestion = Autocomplete.Suggestion;

const Address = PS.Models.Address;

const GOOGLE_OK = "OK";

class GoogleAddress extends Datasource {
  constructor(query, suggestions = [], addressInfo) {
    super(query, suggestions);

    this.address = new Address({
      city: addressInfo.city,
      latitude: addressInfo.latitude,
      longitude: addressInfo.longitude,
      state: addressInfo.state,
      streetName: addressInfo.streetName,
      streetNumber: addressInfo.streetNumber,
      zipcode: addressInfo.zipcode,
    });
  }

  setupAutocomplete() {
    requireGoogleMap().then(() => {
      this._autocompleteService = new google.maps.places.AutocompleteService();
      this._geocoder = new google.maps.Geocoder();

      super.setupAutocomplete();

      if (this.address && this.query) {
        this.approveAddress(this.address, this.query);
      }
    });
  }

  resetState() {
    this.address = new Address();
    super.resetState();
  }

  onAutocompleteFinished(suggestions) {
    this.applySuggestions(this.prepareSuggestions(suggestions || []));
  }

  prepareSuggestions(suggestions) {
    return suggestions.map(googleSuggestion => {
      return new Suggestion(googleSuggestion.description, googleSuggestion.matched_substrings, {
        placeId: googleSuggestion.place_id,
      });
    });
  }

  runAutocomplete(query) {
    this._autocompleteService.getPlacePredictions(
      { input: query, componentRestrictions: { country: "US" } },
      this.onAutocompleteFinished.bind(this)
    );
  }

  validateAddress(address) {
    const streetNameIsMissing = !address.streetName;
    const streetNumberIsMissing = !address.streetNumber;
    const zipcodeIsMissing = !address.zipcode;

    if (streetNameIsMissing || streetNumberIsMissing || zipcodeIsMissing) {
      this.errors = ["Please provide a property address"];
    }

    return this.errors.length === 0;
  }

  approveAddress(address, query) {
    new Autocomplete.Commands.ApproveAddress(
      new Autocomplete.Events.AddressApproved({
        address: address,
        query: query,
        sourceId: this.sourceId,
      })
    ).execute();
  }

  onGeocodingFinished(response, status) {
    if (status !== GOOGLE_OK) {
      Bugsnag.notify(new Error("Cannot geocode address"), { metaData: { response, status } });
      return;
    }

    const googleAddress = response[0] || {};
    const addressPresent = "address_components" in googleAddress && "geometry" in googleAddress;
    if (!addressPresent) {
      Bugsnag.notify(new Error("Cannot geocode address"), { metaData: { response, status } });
      return;
    }

    const address = Address.fromGoogleAddress(
      googleAddress.address_components,
      googleAddress.geometry
    );

    if (this.validateAddress(address)) {
      this.approveAddress(address, this.query);
    }
  }

  validateSuggestion(suggestion) {
    this._geocoder.geocode(
      { placeId: suggestion.options.placeId },
      this.onGeocodingFinished.bind(this)
    );
  }
}

PS.Models.Autocomplete.Datasources.GoogleAddress = GoogleAddress;
