<template>
  <div class="settlement-statement">
    <div class="settlement-statement-header no-border">
      <h3>
        Settlement Statement
        <span :class="statusClass">{{ settlementStatement.status }}</span>
        <div class="ps-loader ps-loader-small" v-if="isLoading"></div>
      </h3>

      <action-button
        v-if="showActionButton"
        :statement="settlementStatement"
        :settlement-statement-policy="settlementStatementPolicy"
        :loan-policy="loanPolicy"
        :show-previous-values="showPreviousValues"
        :is-loading="isLoading"
        @generate-statement="generateStatement"
        @toggle-previous-values="togglePreviousValues"
        @approve-statement="approveStatement"
        @reverse-statement="reverseStatement"
        @submit-statement="submitStatement"
        @refresh-statement="refreshStatement"
        @purchase-statement="purchaseStatement"
        @cancel-statement="openCancelStatementModal"
        @edit-statement="enableEditMode"
        @repair-statement="openRepairStatementModal"
        @wire-instruction="openWireInstructionModal"
      ></action-button>

      <div v-if="editMode" class="edit-button-group">
        <button
          class="btn btn-cancel-cta"
          v-on:click="cancelEdit"
          :disabled="isLoading">
          Cancel
        </button>
        <button
          class="btn btn-action-cta"
          v-on:click="updateStatement"
          :disabled="isLoading">
          Update
        </button>
      </div>
    </div>
    <small class="settlement-statement-data">
      <b>{{ revision }}</b> •
      <span class="text-capitalize">{{ type }}</span> •
      <span>{{ lastUpdatedAt }}</span>
    </small>
    <errors :type="errorType" :errors="errors"></errors>

    <settlement-statement-details
      :settlement-statement="settlementStatement"
      :loan="loan"
      :editMode="editMode"
      :showPreviousValues="showPreviousValues"
    ></settlement-statement-details>

    <comments
      :comments="settlementStatement.comments"
      :editMode="editMode"
      @update-comments="updateComments"
      @delete-comment="deleteComment"
    ></comments>

    <source-of-capital
      :series="settlementStatement.series"
      :checkLineAmount="settlementStatement.checkLineAmount"
      :previousAmount="settlementStatement.pastStatement.fundingSeriesAmount"
      :showPreviousAmount="showPreviousValues"
    ></source-of-capital>

    <cancel-settlement-statement-modal v-if="showCancelSettlementStatementModal"
                                       :on-close="closeCancelStatementModal"
                                       :show="showCancelSettlementStatementModal"
                                       :settlement-statement="settlementStatement"
                                       @statement-cancelled="goToListPage" />

    <repair-settlement-statement-modal
      v-if="showRepairSettlementStatementModal"
      :on-close="closeRepairStatementModal"
      :show="showRepairSettlementStatementModal"
      :settlement-statement="settlementStatement"
      :loading-callback="toggleLoader"
      @statement-repaired="goToEditNewStatement"
    ></repair-settlement-statement-modal>

    <wire-instruction-modal
      :settlement-statement="settlementStatement"
      :wire-instruction="wireInstruction"
      :show="showWireInstructionModal"
      @close="closeWireInstructionModal"
      @updated="updateWireInstruction"
      v-if="showWireInstructionModal"
    ></wire-instruction-modal>
  </div>
</template>

<script>
import SettlementStatementDetails from "components/manage/settlement_statement/details.vue";
import Comments from "components/manage/settlement_statement/comments.vue";
import SourceOfCapital from "components/manage/settlement_statement/source_of_capital.vue";
import ActionButton from "components/manage/settlement_statement/action_button.vue";
import Errors from "components/manage/settlement_statement/errors.vue";
import AjaxService from "services/ajax_service";
import CancelSettlementStatementModal from "components/manage/settlement_statement/cancel_modal";
import RepairSettlementStatementModal from "components/manage/settlement_statement/repair_modal";
import WireInstructionModal from "components/manage/settlement_statement/wire_instruction_modal";
import BaseMixin from "controllers/manage/settlement_statement/base_mixin";
import Location from "services/location_service";
import isEmpty from "lodash/isEmpty";

export default {
  name: "manage-settlement-statement-details-controller",
  components: {
    SettlementStatementDetails,
    Comments,
    SourceOfCapital,
    ActionButton,
    Errors,
    CancelSettlementStatementModal,
    RepairSettlementStatementModal,
    WireInstructionModal,
  },
  mixins: [BaseMixin],
  data() {
    const params = new URLSearchParams(Location.search);

    return {
      viewState: params.get("state"),
      editMode: false,
      showCancelSettlementStatementModal: false,
      showRepairSettlementStatementModal: false,
      showWireInstructionModal: false,
      showPreviousValues: false,
      nextComments: [],
      wireInstruction: PSData.wireInstruction,
      loan: PSData.loan,
    };
  },
  mounted() {
    if (this.showEditMode()) {
      this.enableEditMode();
      this.clearEditModeFlag();
    }
  },
  computed: {
    hasSettlementStatement() {
      return !isEmpty(this.settlementStatement);
    },
    showSettlementStatementDetails() {
      return this.hasSettlementStatement && !this.isPurchased;
    },
    showActionButton() {
      return !this.editMode;
    },
    statusClass() {
      if (!this.settlementStatement.isLatestStatement) {
        return [`finops-status`, `finops-status-disabled`];
      } else {
        return [`finops-status`, `finops-status-${this.settlementStatement.status}`];
      }
    },
    revision() {
      return `Revision ${this.settlementStatement.versionNumber}`;
    },
    type() {
      return this.settlementStatement.settlementStatementType;
    },
    status() {
      return this.settlementStatement.status;
    },
    lastUpdatedAt() {
      return `Updated ${this.settlementStatement.lastUpdatedAt}`;
    },
  },
  methods: {
    goToListPage() {
      Location.assign(`/manage/loans/${this.loan.toParam}/servicing`);
    },
    refreshStatement() {
      const messages = {
        onSuccess: "Settlement Statement was successfully refreshed",
        onError: "Can't refresh Settlement Statement this time",
      };
      const url = `/manage/settlement_statements/${this.settlementStatement.id}/refresh`;

      this.makeAjaxCall(url, messages, {
        errorsScope: "loan",
      });
    },
    updateStatement() {
      this.isLoading = true;
      const params = this.getUpdateParams(this.settlementStatement);

      AjaxService.withNotifications({
        onSuccess: "Settlement Statement was succesfully updated",
        onError: "Can't update Settlement Statement this time",
      })
        .patchJSON(`/manage/settlement_statements/${this.settlementStatement.id}`, params)
        .then(({ settlementStatement }) => {
          this.errorType = undefined;
          this.errors = [];
          this.settlementStatement = settlementStatement;
          this.editMode = false;
        })
        .catch(({ data }) => {
          this.errors = data.errors.base;
          this.errorType = data.type;
        })
        .finally(() => (this.isLoading = false));
    },
    togglePreviousValues() {
      this.showPreviousValues = !this.showPreviousValues;
    },
    enableEditMode() {
      this.beforeEditSettlementStatement = { ...this.settlementStatement };
      this.editMode = true;
    },
    cancelEdit() {
      this.settlementStatement = this.beforeEditSettlementStatement;
      this.nextComments = [];
      this.editMode = false;
      this.errors = [];
      this.errorType = undefined;
    },
    updateComments(comments) {
      this.nextComments = comments;
    },
    deleteComment(comment) {
      this.commentsToDelete.push(comment.id);
    },
    openCancelStatementModal() {
      this.showCancelSettlementStatementModal = true;
    },
    closeCancelStatementModal() {
      this.showCancelSettlementStatementModal = false;
    },
    openRepairStatementModal() {
      this.showRepairSettlementStatementModal = true;
    },
    closeRepairStatementModal() {
      this.showRepairSettlementStatementModal = false;
    },
    openWireInstructionModal() {
      this.showWireInstructionModal = true;
    },
    closeWireInstructionModal() {
      this.showWireInstructionModal = false;
    },
    getUpdateParams() {
      return {
        comments: this.nextComments.filter(comment => comment.comment).map(comment => ({
          ...comment,
          actionable_date: comment.actionableDate,
        })),
        gross_interest_revenue: this.settlementStatement.grossInterestRevenue,
        originator_interest_expense: this.settlementStatement.originatorInterestExpense,
        b_piece_interest_expense: this.settlementStatement.bPieceInterestExpense,
        days_owned: this.settlementStatement.daysOwned,
        pre_paid_interest: this.settlementStatement.prePaidInterest,
        purchase_date: this.settlementStatement.purchaseDate,
      };
    },
    updateWireInstruction(wireInstruction) {
      this.wireInstruction = wireInstruction;
    },
  },
};
</script>
