<template>
  <mfe-connect v-bind="lenderRatesSettings" />
</template>

<script>
import MfeConnect from "components/mfe_connector";

export default {
  components: {
    MfeConnect,
  },

  created() {
    this.lenderRatesSettings = {
      serviceUrl: `${window.location.protocol}//${PSData.loanScenariosServiceHost}`,
      applicationName: "lender_rates_settings",
      props: {
        brandingSettingsUrl: `${PSData.brandingSettingsUrl}`,
      },
    };
  },
};
</script>
