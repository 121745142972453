import CreditReportScore from "./credit_report_score";
import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";

export default class CreditReport {
  static deserialize({
    id = null,
    requestedDate = null,
    type = null,
    typeLabel = null,
    requestedBy = null,
    requestedByLabel = null,
    scores = [],
  }) {
    return new CreditReport(
      id,
      requestedDate,
      type,
      typeLabel,
      requestedBy,
      requestedByLabel,
      scores.map(score => CreditReportScore.deserialize(score))
    );
  }

  static buildEmpty(vendors) {
    const scores = vendors.map(([label, name]) => new CreditReportScore(null, name, label, null));

    return new CreditReport(null, null, null, null, null, null, scores);
  }

  constructor(id, requestedDate, type, typeLabel, requestedBy, requestedByLabel, scores = []) {
    this.id = id;
    this.requestedDate = requestedDate;
    this.type = type;
    this.typeLabel = typeLabel;
    this.requestedBy = requestedBy;
    this.requestedByLabel = requestedByLabel;
    this.scores = scores;
  }

  addNewScore() {
    if (this.canAddNewScore) {
      this.scores = [...this.scores, new CreditReportScore()];
    }
  }

  removeScore(score) {
    this.scores.splice(this.scores.indexOf(score), 1);
  }

  get canAddNewScore() {
    return this.scores.length < 3;
  }

  get canRemoveScores() {
    return this.scores.length > 1;
  }

  get isPersisted() {
    return !!this.id;
  }

  toPlainObject({ toSnakeCase } = { toSnakeCase: false }) {
    const creditReport = {
      id: this.id,
      requestedDate: this.requestedDate,
      requestType: this.type,
      requestedBy: this.requestedBy,
      scores: this.scores.map(score => score.toPlainObject({ toSnakeCase: true })),
    };

    if (toSnakeCase) return mapKeys(creditReport, (_, key) => snakeCase(key));

    return creditReport;
  }
}
