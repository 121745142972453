<template>
  <div class="container-layout-vert-padding loan-sales">
    <h3 class="loan-sales-page-heading">
      Sales Automation
    </h3>

    <loan-sale-batch-status :batch="batch" />

    <div class="loan-sales-table">
      <loan-sale-batch-table :batch="batch" />

      <div class="float-right">
        <a
          class="btn btn-primary loan-sales-action-sell"
          href="/manage/loan_sale_batches/new"
        >
          New Batch
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import some from "lodash/some";

import LoanSaleBatchStatus from "./status";
import LoanSaleBatchTable from "./table";
import AjaxService from "services/ajax_service";

const DEFAULT_FETCH_INTERVAL = 3000;

export default {
  name: "ShowLoanSaleBatch",
  components: {
    LoanSaleBatchStatus,
    LoanSaleBatchTable,
  },
  props: {
    interval: {
      type: Number,
      default: DEFAULT_FETCH_INTERVAL,
    },
  },
  data: () => ({
    batch: null,
    timer: null,
  }),
  mounted() {
    this.startPolling();
  },
  destroyed() {
    this.stopPolling();
  },
  methods: {
    startPolling() {
      const poll = this.poll();

      if (poll) {
        this.timer = setInterval(this.poll, this.interval);
      }
    },
    stopPolling() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    shouldPoll() {
      if (!this.batch) {
        return true;
      }

      return some(
        this.batch.loanSales,
        ({ status, retailStatus }) => status === "pending" || retailStatus === "pending"
      );
    },
    async poll() {
      await this.fetchBatch();

      const poll = this.shouldPoll();

      if (!poll) {
        this.stopPolling();
      }

      return poll;
    },
    async fetchBatch() {
      try {
        const url = `${window.location.href}.json`;
        this.batch = await AjaxService.getJSON(url);
      } catch (_) {
        AjaxService.sendNotification("Something Went Wrong", "error");
      }
    },
  },
};
</script>
