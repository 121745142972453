<template>
  <div class="accounts-dashboard">
    <account-group
      v-for="accountType in filteredAccountTypes"
      :key="accountType.name"
      :account-type="accountType"
      :account-group="accountGroups[accountType.name]"
      :problem-wire-requests="problemWireRequests"
    >
    </account-group>
  </div>
</template>

<script>
import AccountGroup from "./account_group";

export default {
  props: {
    accountTypes: Array,
    accountGroups: Object,
    problemWireRequests: Array,
  },
  components: {
    AccountGroup,
  },
  computed: {
    filteredAccountTypes() {
      return this.accountTypes.filter(accountType => accountType.isSelected);
    },
  },
};
</script>
