<template>
  <div class="program-option">
    <h4 v-if="loanForm">
      {{ option.loanProgram.name }}
      <span v-show="isEstimating" class="ps-loader ps-loader-small"></span>
    </h4>

    <div v-else class="flex-header">
      <h4>{{ option.loanProgram.name }}</h4>

      <template v-if="!loanForm && lenderOnly">
        <template v-if="isLenderOnboarding">
          <base-tooltip label="Complete sign up to submit loans">
            <input
              type="button"
              class="btn-action-cta-sm"
              :value="submitCaption"
              disabled
              @click="onSubmit"/>
          </base-tooltip>
        </template>
        <template v-else>
          <input
            type="button"
            class="btn-action-cta-sm"
            :value="submitCaption"
            :disabled="!isSubmittable"
            @click="onSubmit"/>
        </template>
      </template>
    </div>

    <pricing-guide :pricing="option.pricing"
                   :view-lps-results="viewLpsResults"
                   :is-estimating="isEstimating"
                   :program-classification="option.loanProgram.programType"
                   :new-loan-form="loanForm"
                   :display-for="displayFor"/>

    <credit-box-inline-guide :credit-box-results="option.creditboxResults"
                             :view-lps-results="viewLpsResults"
                             :program-classification="option.loanProgram.programType"
                             :underwriting-status="underwritingStatus"
                             :display-for="displayFor"
                             :new-loan-form="loanForm"
                             :option-id="optionId"
                             :is-form-completed="isFormCompleted"
                             :is-ready="true"
                             is-loan-guide/>
      <template v-if="loanForm && lenderOnly">
        <template v-if="isLenderOnboarding">
          <base-tooltip label="Complete sign up to submit loans">
            <input
              type="button"
              class="btn-action-cta-sm"
              :value="submitCaption"
              disabled
              @click="onSubmit"/>
          </base-tooltip>
        </template>
        <template v-else>
          <input
            type="button"
            class="btn-action-cta-sm"
            :value="submitCaption"
            :disabled="!isSubmittable"
            @click="onSubmit"/>
        </template>
      </template>

    <a v-if="isEvaluating"
       @click.prevent="onCancel"
       href="#"
       class="btn-link cancel-without-changes">
      Cancel without changes
    </a>
  </div>
</template>

<script>
import PricingGuide from "components/pricing_estimator/index";
import CreditBoxInlineGuide from "components/credit_box/credit_box_inline_guide.vue";

export default {
  name: "program-option",
  components: {
    PricingGuide,
    CreditBoxInlineGuide,
  },
  props: {
    option: Object,
    isEstimating: Boolean,
    loanForm: Boolean,
    optionId: Number,
    underwritingStatus: String,
    loan: Object,
    submitToEvaluationDisabled: Boolean,
    uiBlocked: Boolean,
    displayFor: String,
    isFormCompleted: Boolean,
    viewLpsResults: Boolean,
  },
  data() {
    return {
      isLoanSubmitInitiator: false,
    };
  },
  watch: {
    uiBlocked(isBlocked) {
      if (!isBlocked) {
        this.isLoanSubmitInitiator = false;
      }
    },
  },
  computed: {
    submitCaption() {
      if (this.uiBlocked && this.isLoanSubmitInitiator) {
        return "Submitting...";
      } else {
        return this.isEvaluating ? "Resubmit Loan" : "Submit as " + this.option.loanProgram.name;
      }
    },
    submitDisabled() {
      return this.uiBlocked;
    },
    lenderOnly() {
      return this.displayFor === "lender";
    },
    isEvaluating() {
      return this.underwritingStatus === "evaluating";
    },
    isLenderOnboarding() {
      return PSData.isLenderOnboarding ?? false;
    },
    isSubmittable() {
      return [this.submitToEvaluationDisabled, this.submitDisabled, this.isLenderOnboarding].every(
        condition => !condition
      );
    },
  },
  methods: {
    onSubmit() {
      this.isLoanSubmitInitiator = true;
      this.$emit("select");
    },
    onCancel() {
      this.isLoanSubmitInitiator = true;
      this.$emit("cancel");
    },
  },
};
</script>
<style scoped>
.base-tooltip {
  display: flex;
}
</style>
