import AjaxService from "services/ajax_service";

export default class LoanFollowersService {
  constructor(url) {
    this.url = url;
  }
  load(context) {
    return AjaxService.get(this.url).then(data => {
      context.loanFollowers = data.loanFollowers;
      context.loanFollowerCandidates = data.loanFollowerCandidates;

      if (data.loanLenderFollowers) {
        context.loanLenderFollowers = data.loanLenderFollowers;
      }

      if (data.loanLenderCandidates) {
        context.loanLenderCandidates = data.loanLenderCandidates;
      }
    });
  }
}
