export default class MainLoanSectionFixer {
  constructor() {
    this.mainLoanSection = document.querySelector(".details-section");
    this.messagesSection = document.querySelector(".messages-section");
  }

  fixPosition() {
    this.fixMainLoanSection();
  }

  // Private

  fixMainLoanSection() {
    if (!this.mainLoanSection || !this.messagesSection) {
      return;
    }

    if (!this.messagesSection.classList.contains("collapsed")) {
      this.mainLoanSection.classList.remove("expanded");
    } else {
      this.mainLoanSection.classList.add("expanded");
    }
  }
}
