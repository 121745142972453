import autoComplete from "js-autocomplete";

class TextField extends PS.Models.Field {}

class AutoComplete extends TextField {
  constructor(element) {
    super(element);

    var reducedCollection = element.collection.map(entry => entry[0]);
    this.collection = [...new Set(reducedCollection)].sort((a, b) => {
      return a.localeCompare(b, { sensitivity: "case" });
    });
  }

  updateElement(scope) {
    let input = scope.querySelector("input");

    $(input).on("blur", forceChangeEvent);

    new autoComplete({
      selector: input,
      minChars: 2,
      source: this.filterResults.bind(this),
      onSelect: forceChangeEvent,
    });
  }

  filterResults(term, suggest) {
    term = term.toLocaleLowerCase().replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    var collection = this.collection.filter(entry => {
      return entry.toLocaleLowerCase().match(term);
    });

    return suggest(collection);
  }
}

function forceChangeEvent(e) {
  let event = document.createEvent("HTMLEvents");
  event.initEvent("input", true, true);
  e.currentTarget.dispatchEvent(event);
}

PS.Models.Fields.TextField = TextField;
PS.Models.Fields.AutoComplete = AutoComplete;
