<template>
  <button class="delete-action" @click="deleteFile">
    <slot>
      <img alt="Delete file" :src="require('images/icons/icon_trash.svg')" />
    </slot>
  </button>
</template>

<script>
import DocumentService from "services/document_service";

export default Vue.component("diligence-document-files-list-delete-button", {
  props: {
    document: Object,
  },
  data() {
    return {
      isDeleting: false,
      confirmationMessage: "Are you sure you want to delete this document?",
    };
  },
  methods: {
    deleteFile() {
      if (this.isDeleting || !window.confirm(this.confirmationMessage)) {
        return;
      }

      this.$emit("beforeDelete", this.document);
      this.isDeleting = true;

      DocumentService.destroy(this.document)
        .then(() => {
          this.$emit("deleteFile", this.document);
          this.isDeleting = false;
        })
        .catch(() => (this.isDeleting = false));
    },
  },
});
</script>
