<template>
  <base-field :label="preview.name">
    <div v-if="isEmbedUrlLoaded" class="preview-container">
      <iframe
        allowfullscreen=""
        frameborder="0"
        msallowfullscreen=""
        :src="boxEmbedUrl"
        webkitallowfullscreen=""
        @load="restoreFocus"
        width="100%"
        style="height: 500px"
      ></iframe>
    </div>
    <base-loading v-else show />
  </base-field>
</template>

<script>
import Vue from "vue";
import Preview from "models/workflow_engine/preview";

export default Vue.extend({
  name: "file-preview-component",
  props: {
    preview: {
      type: Preview,
      required: true,
    },
  },
  data() {
    return {
      documentEmbedUrl: undefined,
    };
  },
  computed: {
    isEmbedUrlLoaded() {
      return !!this.boxEmbedUrl;
    },
    boxEmbedUrl() {
      if (!this.documentEmbedUrl) {
        this.loadEmbedUrl();
      }
      return this.documentEmbedUrl;
    },
  },
  methods: {
    loadEmbedUrl() {
      const url = `/manage/embed_documents/${this.preview.value.value}`;

      PS.Services.AjaxService.getJSON(url)
        .then(data => {
          this.documentEmbedUrl = data.url;
        })
        .catch(result => {
          if (result.response.status === 403) {
            PS.Services.LocationService.assign(
              `/users/auth/box_oauth2?redirect=${PS.Services.LocationService.pathname}`
            );
          }
        });
    },
    restoreFocus() {
      const focusedElement = document.activeElement;

      setTimeout(() => {
        focusedElement.focus();
      }, 1200);
    },
  },
});
</script>
