<template>
  <div class="market-data-analytics">
    <h3 class="details-section-title">
      Price Forecast
      <div v-show="isLoading" class="ps-loader ps-loader-small"></div>
    </h3>
    <div class="details-section-body">
      <table class="analytics-table">
        <thead>
        <tr>
          <th>
            &nbsp;
          </th>
          <th class="top-header header-line">
            &nbsp;
          </th>
          <th :colspan="forecastedCumulativeReturnValues.length - 1" class="top-header header-line">
            Forecasted Cumulative Return</th>
          <th class="space">
            &nbsp;
          </th>
          <th :colspan="maxRollingDeclineValues.length" class="top-header header-line">
            Max Rolling Decline
          </th>
        </tr>
        <tr class="header-line">
          <th>
            &nbsp;
          </th>
          <th v-for="value in forecastedCumulativeReturnValues" :key="'fcr' + value.term" class="text-nowrap">
            {{value.label}}
          </th>
          <th class="space">
            &nbsp;
          </th>
          <th v-for="value in maxRollingDeclineValues" :key="'mrd' + value.term" :class="highlightClass(value.term)">
            {{value.label}}
          </th>
        </tr>
        </thead>
        <tbody v-for="analytics in analyticsSources" :key="analytics.name">
          <tr v-if="analytics.isLoading" class="analytics-source">
            <td class="analytics-name text-nowrap">
                <span class="name">
                  {{analytics.name}}
                </span>
            </td>
            <td :colspan="forecastedCumulativeReturnValues.length">
              &nbsp;</td>
            <td class="space">
              &nbsp;
            </td>
            <td :colspan="maxRollingDeclineValues.length">
              &nbsp;
            </td>
          </tr>
          <tr v-if="analytics.isFailed" class="analytics-source">
            <td class="analytics-name text-nowrap">
              <span class="name">
                {{analytics.name}}
              </span>
            </td>
            <td :colspan="valuesColumnsCount" class="error">
              {{analytics.errorMessage}}
            </td>
          </tr>
          <tr v-if="analytics.isSuccessful" class="analytics-source">
            <td class="analytics-name text-nowrap">
              <span class="name">
                {{analytics.name}}
              </span>
              <span v-if="showDates" class="date">
                {{analytics.completedAt | formatDate("MMMM D, YYYY")}}
              </span>
            </td>
            <td v-for="value in forecastedCumulativeReturnValues" :key="'fcr' + value.term">
              <span v-if="analytics.forecastedCumulativeReturn[value.term]" class="available">
                {{analytics.forecastedCumulativeReturn[value.term] | percent}}
              </span>
              <span v-else class="not-available">
                N/A
              </span>
            </td>
            <td class="space">
              &nbsp;
            </td>
            <td v-for="value in maxRollingDeclineValues" :key="'mrd' + value.term" :class="highlightClass(value.term)">
              <span v-if="analytics.maxRollingDecline[value.term]" class="available">
                {{analytics.maxRollingDecline[value.term] | percent}}
              </span>
              <span v-else class="not-available">
                N/A
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "analytics-table",
  template: "#v-analytics-table-template",
  props: {
    analytics: Object,
    showDates: Boolean,
  },
  methods: {
    highlightClass(term) {
      if (
        this.analytics.riskAnalysis &&
        this.analytics.riskAnalysis.relevantMaxDecreaseMonth === parseInt(term)
      ) {
        return "rolling-term-highlighted";
      }
    },
  },
  computed: {
    isLoading() {
      return this.analyticsSources.some(s => s.isLoading);
    },
    analyticsSources() {
      return Object.values(this.analytics).filter(item => !!item);
    },
    forecastedCumulativeReturnValues() {
      return PS.Const.MarketData.Analytics.FORECASTED_CUMULATIVE_RETURN_VALUES;
    },
    maxRollingDeclineValues() {
      return PS.Const.MarketData.Analytics.MAX_ROLLING_DECLINE_VALUES;
    },
    valuesColumnsCount() {
      return this.forecastedCumulativeReturnValues.length + 1 + this.maxRollingDeclineValues.length;
    },
  },
};
</script>
