const Events = PS.Models.Diligence.Events;
const Commands = PS.Models.Diligence.Commands;
const Rule = PS.Models.Rule;

class DocumentRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.Form.Updated:
        return this.whenFormUpdated(event);

      case Events.Document.LoadingRequested:
        return this.whenDocumentLoadingRequested(event);

      case Events.Document.ReportGenerationRequested:
        return this.whenReportGenerationRequested(event);
    }

    return super.check(event);
  }

  whenFormUpdated(event) {
    return [new Commands.Document.UpdateChecklistInfo(event, this.state)];
  }

  whenDocumentLoadingRequested(event) {
    return [
      new Commands.Document.SetDiligenceMode(event, this.state),
      new Commands.Document.LoadDocumentInfo(event, this.state),
    ];
  }

  whenReportGenerationRequested(event) {
    return [
      new Commands.Document.SetReportingMode(event, this.state),
      new Commands.Document.RequestDeselectDocument(event, this.state),
      new Commands.Document.LoadReport(event, this.state),
    ];
  }
}

PS.Models.Diligence.DocumentRule = DocumentRule;
