<template>
  <base-modal-form
    :show="show"
    title="Prepare Initial Disclosures"
    :save-disabled="isSaving"
    class="disclosure-modal"
    @close="onClose"
    @save="onSave"
    saveButtonName="Next"
    :cancel-options="cancelOptions"
  >
    <p class="mb-4">
      Verify the following information so we can prepare the correct documents.
      <a :href="link" target="_blank">Learn more.</a>
    </p>
    <form>
      <div class="columns">
        <base-select-field
          id="lender-note-status"
          v-model="record.lenderNoteStatus"
          label="Note Origination"
          rules="required"
          :options="record.lenderNoteStatusOptions"
          :errors="errors.lender_note_status"
          :disabled="isDisabled"
          showEmpty
          class="column is-half"
        />
        <base-select-field
          v-show="!isFio"
          id="legal-entity"
          v-model="record.lenderEntityLegalEntityId"
          label="Current Lender Entity"
          :rules="legalEntityValidation"
          :options="record.lenderLegalEntities"
          :errors="errors.legal_entity"
          :disabled="isDisabled"
          showEmpty
          class="column is-half"
        />
        <base-select-field
          v-show="isFio"
          id="ps-legal-entity"
          v-model="record.lenderEntityLegalEntityId"
          label="Current Lender Entity"
          :rules="fioEntityValidation"
          :options="record.psLegalEntities"
          :errors="errors.legal_entity"
          disabled
          class="column is-half"
        />
      </div>
      <div class="columns is-flex-wrap-wrap">
        <base-select-field
          v-show="isFio"
          id="brokering-entity"
          v-model="record.brokeringEntityId"
          label="Brokering Entity"
          :rules="fioEntityValidation"
          :options="record.brokeringLegalEntityOptions"
          :errors="errors.brokering_entity"
          :disabled="isDisabled"
          showEmpty
          class="column is-half"
        />
        <base-text-field
          v-if="showField"
          id="broker-phone"
          v-model="record.phone"
          rules="required|phone"
          :label="record.phoneLabel"
          :errors="errors.broker_phone"
          type="phone"
          name="phone"
          class="column is-half"
          with-tooltip
          tooltip-label="This phone number will be displayed in the initial disclosures for borrowers to contact."
        />
        <base-text-field
          v-if="showField"
          id="broker-email"
          v-model="record.email"
          rules="required|email"
          :label="record.emailLabel"
          :errors="errors.broker_email"
          type="text"
          name="email"
          class="column is-half"
          with-tooltip
          tooltip-label="This email address will be displayed in the initial disclosures for borrowers to contact."
        />
      </div>
    </form>
  </base-modal-form>
</template>

<script>
import AjaxService from "services/ajax_service";
import GlobalBusService from "services/global_bus_service";
import { DisclosureInfo } from "models/disclosures";

const HELP_LINK = "https://peerstreet.zendesk.com/hc/en-us/articles/360055350452";
const DRAFT = "draft";

export default {
  name: "prepare-disclosure-modal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    loan: {
      type: Object,
      default: {},
    },
    disclosureOptions: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      routes: PSData.routes,
      record: new DisclosureInfo(this.loan, this.disclosureOptions),
      errors: {},
      isSaving: false,
      cancelOptions: ["escape", "x"],
    };
  },
  computed: {
    link() {
      return HELP_LINK;
    },
    isFio() {
      return this.record.lenderNoteStatus === "funding_into_origination";
    },
    showField() {
      return this.record.lenderNoteStatus !== "";
    },
    fioEntityValidation() {
      const { isFio } = this;
      return {
        required: isFio,
      };
    },
    legalEntityValidation() {
      const { isFio } = this;
      return {
        required: !isFio,
      };
    },
    isDisabled() {
      return this.loan.underwritingStatus !== DRAFT;
    },
  },
  watch: {
    isFio(newVal, oldVal) {
      if (!newVal && oldVal) {
        return (this.record.lenderEntityLegalEntityId = null);
      }

      if (newVal) {
        return (this.record.lenderEntityLegalEntityId = this.record.psLegalEntities[0][1]);
      }
    },
  },
  methods: {
    onClose() {
      this.errors = {};
      this.record.reset();
      this.$emit("close");
    },
    onSave() {
      if (this.record.changed()) {
        this.save();
      } else {
        this.onClose();
        GlobalBusService.$emit("open-send-disclosure");
      }
    },
    save() {
      const messages = { onSuccess: "Information successfully updated", onError: this.onError };

      this.isSaving = true;

      return this.updateRecord(messages)
        .then(data => {
          GlobalBusService.$emit("disclosure-prepared", data.loan);
          this.onClose();
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    updateRecord(messages) {
      return AjaxService.withNotifications(messages).patch(
        this.routes.prepareLoan,
        this.record.toHash()
      );
    },
    onError({ data }) {
      this.errors = data.errors || {};
      return null;
    },
  },
};
</script>
<style scoped>
.disclosure-modal >>> .modal-card {
  min-width: 960px;
}

.disclosure-modal >>> .modal-card-body {
  padding-bottom: 16px;
}

.disclosure-modal >>> .modal-card-foot .base-button.is-text {
  color: var(--color-grey-72);
}
</style>
