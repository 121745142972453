const Charts = PS.Models.MarketData.Charts;
const MarketDataSource = PS.Models.MarketData.Source;
const MultiSource = PS.Models.MarketData.Charts.MultiSource;

class MidasPriceIndex extends MarketDataSource {
  constructor() {
    super();

    this.zipTimeSeries = [];
    this.msaTimeSeries = [];
  }

  get name() {
    return "Affordability: Cost of Homeownership";
  }

  success(data) {
    this.zipTimeSeries = data.zipTimeSeries;
    this.msaTimeSeries = data.msaTimeSeries;

    super.success();
  }

  get sources() {
    return [
      new Charts.Midas("zipAffordability", this.zipTimeSeries, "month", "affordPmt"),
      new Charts.Midas("zipAffordabilityAverage", this.zipTimeSeries, "month", "averageAffordPmt"),
      new Charts.Midas("msaAffordability", this.msaTimeSeries, "month", "affordPmt"),
    ];
  }
}

class CostOfHomeownership extends MultiSource {
  constructor() {
    super();

    this._sources = {
      midas: new MidasPriceIndex(),
    };
  }

  get data() {
    return this._comparison.data;
  }

  calculateAverageAffordabilityByZip() {
    const zipTimeSeries = this._sources.midas.zipTimeSeries;

    const average =
      zipTimeSeries.reduce((total, timeSeries) => {
        return timeSeries.affordPmt + total;
      }, 0) / zipTimeSeries.length;

    zipTimeSeries.forEach(timeSeries => {
      timeSeries.averageAffordPmt = average;
    });
  }

  success(data) {
    super.success(data);
    this.calculateAverageAffordabilityByZip();
  }
}

PS.Models.MarketData.Charts.CostOfHomeownership = CostOfHomeownership;
