import enumerize from "../utils/enumerize";

const NoteOrigination = enumerize({
  funded: {
    value: "funded",
    title: "Funded",
  },
  tableFunding: {
    value: "table_funding",
    title: "Table Funding",
  },
  fundingIntoOrigination: {
    value: "funding_into_origination",
    title: "Funding Into Origination",
  },
});

export default NoteOrigination;
