export default function debounce(func, timeout = 750) {
  let timeoutId;

  return function(...args) {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      func.apply(null, args);
    }, timeout);
  };
}
