<template>
  <div v-show="hasTasks" class="task-list-category-container">
    <div class="task-list-category-container-header">
      <div class="workflow-fold">
        <i v-show="!visible" class="fa fa-caret-right" @click="onShow" />
        <i v-show="visible" class="fa fa-caret-down" @click="onHide" />
      </div>
      <h5>{{ category.text }}&nbsp;Tasks</h5>
    </div>
    <div v-show="visible">
      <task-list-item
        v-for="task in tasks"
        :key="`${task.assetManagementTaskTemplateId}-${task.id || 0}`"
        :task="task"
        :workflow="workflow"
        :edit-disabled="editDisabled"
        @update-task="$listeners['update-task']"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import TaskListItem from "./task_list_item";
import foldableMixin from "mixins/foldable_mixin";

export default {
  mixins: [foldableMixin],
  components: {
    TaskListItem,
  },
  props: {
    editDisabled: Boolean,
    tasks: Array,
    category: Object,
    workflow: Object,
  },
  data() {
    return {
      visible: this.category.name === "open",
    };
  },
  computed: {
    hasTasks() {
      return this.tasks.length > 0;
    },
  },
};
</script>
