import Vue from "vue";
import { markRaw } from "@vue/composition-api";
import { createPinia } from "pinia";
import createAxios from "components/loan_scenarios/utils/axios";
import CrudApi from "components/loan_scenarios/api/crud";
import createRouter from "./router";
import locale from "./locales/en";
import App from "./app.vue";
import PreviewsClient from "./api/previews_client";

export default function(el) {
  if (!el) return;

  const csrfToken = $?.rails?.csrfToken();
  if (!csrfToken) throw new Error("Failed to read CSRF token.");

  const client = createAxios({ baseURL: "", csrfToken });
  const router = createRouter();
  const pinia = createPinia();
  pinia.use(({ store }) => {
    store.$api = {
      previews: markRaw(new PreviewsClient({ client, path: PSData.routes.previewUrl })),
      applications: markRaw(new CrudApi({ client, path: PSData.routes.applyUrl })),
      runs: markRaw(new CrudApi({ client, path: PSData.routes.runsUrl })),
      statuses: markRaw(new CrudApi({ client, path: PSData.routes.statusesUrl })),
    };
  });
  pinia.use(({ store }) => {
    store.$router = markRaw(router);
  });

  const Instance = new Vue({
    provide: {
      locale,
    },
    pinia,
    router,
    render: h =>
      h(App, {
        props: {},
      }),
  });

  Instance.$mount(el);
}
