<template>
  <div class="container">
    <div>
      <div class="lender-sections-container">
        <div class="lender-section-title">
          <h2>Permissions</h2>
        </div>
        <ul class="nav nav-tabs loan-nav loan-tabs">
          <li :class="{active: isActiveTab('users')}">
            <a href="#" @click.prevent="currentTab = 'users'">Users</a>
          </li>
          <li :class="{active: isActiveTab('roles')}">
            <a href="#" @click.prevent="currentTab = 'roles'">Roles</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active">
            <UsersTab v-if="isActiveTab('users')"
                      :users="users"
                      :readonly="readonly"
                      :updating="updating"/>
            <RolesTab v-if="isActiveTab('roles')"
                      :roles="sortedRoles"
                      :readonly="readonly"
                      :updating="updating"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsersTab from "components/permissions/users/table";
import RolesTab from "components/permissions/roles";

const Commands = PS.Models.Permission.Commands;
const Event = PS.Models.Event;

export default {
  data: function() {
    return {
      currentTab: "users",
      roles: PSData.options.roles,
      readonly: PSData.options.readonly,
      userData: PSData.users,
      users: [],
      changes: new PS.Models.SettingChanges(),
      updating: false,
      rulebook: undefined,
      messageBus: PS.Services.MessageBusService.settingsBus,
    };
  },
  created: function() {
    this.rulebook = new PS.Models.Permission.Rule(this);
    this.messageBus.attachRulebook(this.rulebook);
  },
  mounted: function() {
    new Commands.RequestDataSetup(new Event(), {}).execute();

    $(".settings-header .fa-question-circle", this.$el).tooltip();
  },
  beforeDestroy: function() {
    this.messageBus.detachRulebook(this.rulebook);
  },
  components: { UsersTab, RolesTab },
  computed: {
    sortedRoles: function() {
      return this.roles.sort((a, b) => {
        return a.value.localeCompare(b.value);
      });
    },
    hasChanges: function() {
      return this.changes.hasChanges();
    },
  },
  methods: {
    isActiveTab(tab) {
      return tab === this.currentTab;
    },
  },
};
</script>
