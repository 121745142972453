<template>
  <modal-form
    :show="show"
    :title="title"
    class="credit-report-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <form>
      <div class="row">
        <with-errors on="requested_date" :errors="errors" class="col-4 control-wrapper form-group">
          <label for="requestedDate">Requested Date</label>
          <input
            id="requestedDate"
            v-model="report.requestedDate"
            v-datepicker
            type="text"
            class="form-control"
          >
        </with-errors>
        <with-errors on="request_type" :errors="errors" class="col-4 control-wrapper form-group">
          <label for="type">Request Type</label>
          <select
            id="type"
            v-model="report.type"
            name="type"
            class="form-control"
          >
            <option value />
            <option v-for="[label, name] in reportTypes" :key="name" :value="name">
              {{ label }}
            </option>
          </select>
        </with-errors>
        <with-errors on="requested_by" :errors="errors" class="col-4 control-wrapper form-group">
          <label for="requestedBy">Requested By</label>
          <select
            id="requestedBy"
            v-model="report.requestedBy"
            name="requestedBy"
            class="form-control"
          >
            <option value />
            <option v-for="[label, name] in reportOriginatorTypes" :key="name" :value="name">
              {{ label }}
            </option>
          </select>
        </with-errors>
      </div>
      <div class="scores">
        <span class="title">Scores</span>
        <div class="row">
          <div class="col-4 label-col">Vendor</div>
          <div class="col-4 label-col">Score</div>
        </div>
        <div v-for="(score, index) in report.scores" :key="score.id" class="row credit-score-row">
          <with-errors on="vendor" :errors="getScoreErrors(index)" class="col-4">
            <select
              v-model="score.vendor"
              name="vendor"
              class="form-control"
            >
              <option value />
              <template v-for="[label, name] in creditScoreVendors">
                <option
                  v-if="isVendorSelected(score, name)"
                  :key="name"
                  :value="name"
                  selected
                >
                  {{ label }}
                </option>
                <option v-if="isVendorAvailable(name)" :key="name" :value="name">
                  {{ label }}
                </option>
              </template>
            </select>
          </with-errors>
          <with-errors on="score" :errors="getScoreErrors(index)" class="col-4">
            <input v-model="score.score" v-mask:fico="" class="form-control">
          </with-errors>
          <div class="col-4 credit-score-actions">
            <a v-if="canRemoveScores" href="#" @click.prevent="removeScore(score)">remove</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <a v-if="canAddScores" href="#" @click.prevent="addNewScore">+ Add Score</a>
        </div>
      </div>
    </form>
  </modal-form>
</template>

<script>
import ModalForm from "components/modal_form";
import CreditReport from "models/credit_report";
import AjaxService from "services/ajax_service";
import WithErrors from "components/shared/with_errors";

import get from "lodash/get";

export default {
  name: "add-or-update-credit-report",
  components: { ModalForm, WithErrors },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    report: {
      type: CreditReport,
      required: true,
    },
    creditScoreVendors: {
      type: Array,
      required: true,
    },
    reportTypes: {
      type: Array,
      required: true,
    },
    reportOriginatorTypes: {
      type: Array,
      required: true,
    },
    routes: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
    };
  },
  computed: {
    title() {
      return `${this.report.isPersisted ? "Edit" : "Add"} Credit Report`;
    },
    availableVendors() {
      return this.creditScoreVendors.filter(([, name]) => {
        return !this.report.scores.find(score => score.vendor === name);
      });
    },
    canAddScores() {
      return this.report.canAddNewScore;
    },
    canRemoveScores() {
      return this.report.canRemoveScores;
    },
  },
  watch: {
    report: {
      deep: true,
      handler() {
        this.resetErrors();
      },
    },
  },
  methods: {
    addNewScore() {
      this.report.addNewScore();
    },
    removeScore(score) {
      this.report.removeScore(score);
    },
    isVendorAvailable(vendor) {
      return this.availableVendors.find(([, name]) => name === vendor);
    },
    isVendorSelected(score, vendor) {
      return !!score.vendor && score.vendor === vendor;
    },
    getScoreErrors(index) {
      const scoreErrors = get(this.errors, "scores", [])[index];

      return scoreErrors || {};
    },
    resetErrors() {
      this.errors = {};
    },
    onSave() {
      const params = { credit_report: this.report.toPlainObject({ toSnakeCase: true }) };

      this.report.isPersisted ? this.updateReport(params) : this.createReport(params);
    },
    onClose() {
      this.$emit("on-close");
    },
    createReport(params) {
      const messages = { onSuccess: "Report is successfully created", onError: this.onError };

      AjaxService.withNotifications(messages)
        .postJSON(this.routes.creditReports, params)
        .then(reports => {
          this.$emit("on-save", reports);
        })
        .catch(() => {}); // stub; catch is performing in [onError])
    },
    updateReport(params) {
      const url = `${this.routes.creditReports}/${this.report.id}`;
      const messages = { onSuccess: "Report is successfully updated", onError: this.onError };

      AjaxService.withNotifications(messages)
        .patchJSON(url, params)
        .then(reports => {
          this.$emit("on-save", reports);
        })
        .catch(() => {}); // stub; catch is performing in [onError])
    },
    onError({ response, data }) {
      if (response.status === 422) {
        this.errors = data.errors;
      } else {
        Bugsnag.notify(new Error(`Unable to save credit report: ${response.statusText}`));

        return {
          message: "Something went wrong",
          status: "error",
        };
      }
    },
  },
};
</script>
