<template>
  <div>
    <h6 class="form-label">Loan Purpose*</h6>
    <div 
      class="radio-wrapper"
      data-cy="loan-purpose-radio"
    >
      <base-radio v-model="scenario.loanPurpose"
        class="radio-element"
        :native-value="LoanPurpose.acquisition.value">
        {{ LoanPurpose.acquisition.title }}
      </base-radio>
      <base-radio v-model="scenario.loanPurpose"
        class="radio-element"
        id="cash-out-refinance"
        :native-value="LoanPurpose.cashOutRefinance.value">
        {{ LoanPurpose.cashOutRefinance.title }}
      </base-radio>
      <base-radio v-model="scenario.loanPurpose"
        class="radio-element"
        id="refinance"
        :native-value="LoanPurpose.refinance.value">
        {{ LoanPurpose.refinance.title }}
      </base-radio>
    </div>
    <div class="columns">
      <base-select-field
        data-cy="property-type-dropdown"
        class="column is-full"
        label="Property Type"
        id="property-type"
        v-model="scenario.propertyType"
        :options="PropertyType.options"
        show-empty
      />
    </div>
    <div class="columns">
      <base-zip-field
        data-cy="zip-code-zip"
        ref="form"
        id="zip-code-field"
        v-model="scenario.zipCode"
        label="Zip Code*"
        class="column is-half"
      />
      <base-currency-field
        id="as-is-value"
        data-cy="designated-value-currency"
        label="Designated Value*"
        class="column is-half"
        v-model="scenario.asIsValue"
        @input="calculateLoanAmount"
        rules="exclusive_min_number_value:0"
        :with-tooltip="true"
        :tooltip-label="locale.tooltip.assetValue + `${minAssetValueFormatted}`"
      />
    </div>
    <div class="columns is-multiline">
      <base-fico-field
        id="fico"
        data-cy="credit-score-integer"
        class="column is-half"
        label="Designated Credit Score*"
        v-model="scenario.ficoScore"
        :with-tooltip="true"
        :tooltip-label="locale.tooltip.designatedCreditScore + `${minCreditScore}`"
      />
      <div 
        class="column is-half">
        <h6 class="form-label absolute-tooltip">
          <span>Professionally Managed by 3rd Party?*</span>
        </h6>
        <div 
          class="radio-wrapper"
          data-cy="professionally-managed-radio"
        >
          <base-radio
            class="column is-half"
            id="currently-leased-yes"
            v-model="scenario.professionallyManaged"
            native-value="yes"
            style="padding-right: 20px;"
          >
            Yes
          </base-radio>
          <base-radio
            class="column is-half"
            id="currently-leased-no"
            v-model="scenario.professionallyManaged"
            native-value="no"
          >
            No
          </base-radio>
        </div>
      </div>
      <base-select-field
        :disabled="isProfessionallyManaged"
        id="track-record"
        data-cy="track-record-dropdown"
        class="column is-half"
        label="Designated Track Record*"
        :with-tooltip="true"
        :tooltip-label="locale.tooltip.designatedTrackRecord"
        :options="TrackRecord.options"
        show-empty
        v-model="scenario.trackRecord"
      />
      <base-select-field
        id="residency-status"
        data-cy="residency-status-dropdown"
        class="column is-half"
        label="Residency Status*"
        :options="ResidencyStatus.options"
        show-empty
        v-model="scenario.residencyStatus"
      />
    </div>
    <div class="columns">
      <div 
        class="column is-half">
        <h6 class="form-label absolute-tooltip">
          <span>Property Currently Leased?*</span>
          <base-tooltip :label="locale.tooltip.propertyCurrentlyLeased" position="is-top" multilined>
            <base-icon pack="fas" size="is-small" icon="question-circle" type="hint"/>
          </base-tooltip>
        </h6>
        <div 
          class="radio-wrapper"
          data-cy="currently-leased-radio"
        >
          <base-radio
            class="column is-half"
            id="currently-leased-yes"
            v-model="scenario.currentlyLeased"
            native-value="yes"
            style="padding-right: 20px;"
          >
            Yes
          </base-radio>
          <base-radio
            class="column is-half"
            id="currently-leased-no"
            v-model="scenario.currentlyLeased"
            native-value="no"
          >
            No
          </base-radio>
        </div>
      </div>
      <div 
        class="column is-half"
        data-cy="rental-income-currency"
      >
        <h6 class="form-label">
          <span>Designated Rental Income*</span>
          <base-tooltip position="is-top" multilined>
            <base-icon pack="fas" size="is-small" icon="question-circle" type="hint"/>
            <template v-slot:content>
              <div v-html="monthlyRentalIncomeTooltip"></div>
            </template>
          </base-tooltip>
        </h6>
        <base-currency-field
          id="monthly-rental-income"
          v-model="scenario.monthlyRentalIncome"
          rules="exclusive_min_number_value:0"
        />
      </div>
    </div>
    <div class="columns">
      <base-currency-field
        id="yearly-taxes-owed"
        data-cy="yearly-taxes-owed-currency"
        v-model="scenario.yearlyTaxesOwed"
        label="Yearly Taxes Owed*"
        :with-tooltip="true"
        :tooltip-label="locale.tooltip.yearlyTaxesOwed"
        class="column is-half"
      />
      <base-currency-field
        id="yearly-insurance-premium"
        data-cy="insurance-premium-currency"
        v-model="scenario.yearlyInsurancePremium"
        label="Yearly Insurance Premium*"
        :with-tooltip="true"
        :tooltip-label="locale.tooltip.yearlyInsurancePremium"
        class="column is-half"
      />
    </div>
    <div class="columns">
      <base-currency-field
        class="column is-half"
        data-cy="monthly-hoa-currency"
        id="monthly-hoa-dues"
        v-model="scenario.monthlyHoa"
        label="Monthly HOA Dues"
      />
    </div>
  </div>
</template>


<script>
import { LoanProduct, LoanPurpose, PropertyType, ResidencyStatus, TrackRecord } from "../enums";
import { formatCurrency } from "../utils/formatter";
import { RENTAL_MIN_ASSET_VALUE, RENTAL_MIN_CREDIT_SCORE } from "../utils/helpers";

export default {
  name: "residential-for-rent-fieldset",
  inject: ["locale"],
  props: {
    scenario: Object,
    constraints: Object,
  },
  watch: {
    "scenario.professionallyManaged": {
      handler(_value) {
        if (this.isProfessionallyManaged) {
          this.scenario.trackRecord = null;
        }
      },
    },
  },
  computed: {
    monthlyRentalIncomeTooltip() {
      if (this.scenario.currentlyLeased === undefined) {
        return this.locale.tooltip.monthlyRentalIncome;
      }

      return this.scenario.currentlyLeased === "yes"
        ? this.locale.tooltip.monthlyRentalIncomeLeased
        : this.locale.tooltip.monthlyRentalIncomeUnleased;
    },
    minAssetValue() {
      return this.constraints.minAssetValue || RENTAL_MIN_ASSET_VALUE;
    },
    minCreditScore() {
      return this.constraints.minCreditScore || RENTAL_MIN_CREDIT_SCORE;
    },
    minAssetValueFormatted() {
      return formatCurrency(this.minAssetValue);
    },
    isProfessionallyManaged() {
      return this.scenario.professionallyManaged === "yes";
    },
  },
  data() {
    return {
      LoanProduct,
      LoanPurpose,
      PropertyType,
      ResidencyStatus,
      TrackRecord,
    };
  },
  methods: {
    calculateLoanAmount(_) {
      this.scenario.loanAmount = this.scenario.calculateLoanAmount(this.scenario.ltv);
    },
  },
};
</script>
