import selectOptionsToMapping from "utils/select_options_to_mapping";
import snakeCase from "lodash/snakeCase";

export default class ACHDetails {
  constructor(entity = {}, options = {}) {
    this._rawEntity = entity;
    this._rawOptions = options;

    this._states = selectOptionsToMapping(options.states || []);
    this._taxIdTypes = selectOptionsToMapping(options.taxIdTypes || []);
    this._accountTypes = selectOptionsToMapping(options.accountTypes || []);

    if (!entity.achDetails && !entity.contact) {
      return;
    }

    this.bankAccountId = entity.achDetails?.id;
    this.contactId = entity.contact?.id;

    this.update(entity);

    this.lenderEntityKeys = ["tin", "tinType", "distributionEmail"];
    this.bankAccountKeys = [
      "accountCategory",
      "beneficiaryName",
      "routingNumber",
      "accountNumber",
      "name",
    ];
    this.contactKeys = ["address", "city", "state", "postalCode", "email", "firstName", "lastName"];
  }

  update(entity) {
    this._rawEntity = entity;
    this._tinType = entity.tinType;
    this._accountNickname = entity.achDetails?.name;
    this._partialAccountNumber = entity.achDetails?.partialAccountNumber;
    this._partialRoutingNumber = entity.achDetails?.partialRoutingNumber;
    this._accountCategoryDisplay = entity.achDetails?.accountCategory;
    this._address = entity.contact?.address;
    this._city = entity.contact?.city;
    this._state = entity.contact?.state;
    this._postalCode = entity.contact?.postalCode;
    this._email = entity.contact?.email;
    this._firstName = entity.contact?.firstName;
    this._lastName = entity.contact?.lastName;
    this._status = entity.achDetails?.status;
    this._distributionEmail = entity.distributionEmail;

    this._tin = "";
    this._rawEntity.tin = "";
    this._accountNumber = "";
    this._rawEntity.achDetails.accountNumber = "";
    this._routingNumber = "";
    this._rawEntity.achDetails.routingNumber = "";
    this._beneficiaryName = "";
    this._rawEntity.achDetails.beneficiaryName = "";
    this._name = "";
    this._rawEntity.achDetails.name = "";
    this._accountCategory = "";
    this._rawEntity.achDetails.accountCategory = "";
  }

  reset() {
    this.update(this._rawEntity);
  }

  get states() {
    return this._states;
  }

  get taxIdTypes() {
    return this._taxIdTypes;
  }

  get accountTypes() {
    return this._accountTypes;
  }

  get address() {
    return this._address;
  }

  set address(address) {
    this._address = address;
  }

  get city() {
    return this._city;
  }

  set city(city) {
    this._city = city;
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;
  }

  get postalCode() {
    return this._postalCode;
  }

  set postalCode(postalCode) {
    this._postalCode = postalCode;
  }

  get email() {
    return this._email;
  }

  set email(email) {
    this._email = email;
  }

  get distributionEmail() {
    return this._distributionEmail;
  }

  set distributionEmail(email) {
    this._distributionEmail = email;
  }

  get firstName() {
    return this._firstName;
  }

  set firstName(firstName) {
    this._firstName = firstName;
  }

  get lastName() {
    return this._lastName;
  }

  set lastName(lastName) {
    this._lastName = lastName;
  }

  get tinType() {
    return this._tinType;
  }

  set tinType(tinType) {
    this._tinType = tinType;
  }

  get tin() {
    return this._tin;
  }

  set tin(tin) {
    this._tin = tin;
  }

  get beneficiaryName() {
    return this._beneficiaryName;
  }

  set beneficiaryName(beneficiaryName) {
    this._beneficiaryName = beneficiaryName;
  }

  get partialAccountNumber() {
    return this._partialAccountNumber;
  }

  set partialAccountNumber(partialAccountNumber) {
    this._partialAccountNumber = partialAccountNumber;
  }

  get partialRoutingNumber() {
    return this._partialRoutingNumber;
  }

  set partialRoutingNumber(partialRoutingNumber) {
    this._partialRoutingNumber = partialRoutingNumber;
  }

  get accountNickname() {
    return this._accountNickname;
  }

  get accountNumber() {
    return this._accountNumber;
  }

  set accountNumber(accountNumber) {
    this._accountNumber = accountNumber;
  }

  get accountCategoryDisplay() {
    return this._accountCategoryDisplay;
  }

  get accountCategory() {
    return this._accountCategory;
  }

  set accountCategory(accountCategory) {
    this._accountCategory = accountCategory;
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
  }

  get routingNumber() {
    return this._routingNumber;
  }

  set routingNumber(routingNumber) {
    this._routingNumber = routingNumber;
  }

  get status() {
    return this._status;
  }

  get copy() {
    return new ACHDetails(this._rawEntity, this._rawOptions);
  }

  get changed() {
    return this.contactInfoChanged || this.bankAccountChanged || this.lenderLegalEntityChanged;
  }

  get contactInfoChanged() {
    return this.sectionChanged(this.contactKeys, this._rawEntity.contact);
  }

  get bankAccountChanged() {
    return this.sectionChanged(this.bankAccountKeys, this._rawEntity.achDetails);
  }

  get lenderLegalEntityChanged() {
    return this.sectionChanged(this.lenderEntityKeys, this._rawEntity);
  }

  sectionChanged(keys, rawObject) {
    return (
      keys.filter(key => {
        return this.valueChanged(key, rawObject);
      }).length !== 0
    );
  }

  valueChanged(key, rawObject) {
    return this[key] !== rawObject[key];
  }

  get objectToSend() {
    const newObject = {};

    if (this.contactInfoChanged) {
      newObject.contact = this.buildChangedObject(this.contactKeys, this._rawEntity.contact);
      newObject.contact.id = this.contactId;
    }

    if (this.bankAccountChanged) {
      newObject.bank_account = {};
      newObject.bank_account.id = this.bankAccountId;
      this.bankAccountKeys.forEach(key => {
        newObject.bank_account[snakeCase(key)] = this[key];
      });
    }

    if (this.lenderLegalEntityChanged) {
      newObject.lender_legal_entity = this.buildChangedObject(
        this.lenderEntityKeys,
        this._rawEntity
      );
    }

    return newObject;
  }

  buildChangedObject(keys, rawEntity, changedObject = {}) {
    keys.forEach(key => {
      if (this.valueChanged(key, rawEntity)) {
        changedObject[snakeCase(key)] = this[key];
      }
    });

    return changedObject;
  }
}
