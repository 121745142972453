export default {
  methods: {
    $_adjustActivityFeedHeight() {
      const container = document.querySelector(".activity-feed-events-section-component");
      const newHeight = window.innerHeight - container.getBoundingClientRect().top;
      container.style.height = `${newHeight}px`;
    },
    $_preventBodyScroll() {
      document.body.classList.add("no-scroll");
    },
    $_enableBodyScroll() {
      document.body.classList.remove("no-scroll");
    },
  },
};
