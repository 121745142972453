<template>
  <tbody class="borrower-info">
    <tr>
      <td class="required">{{ borrower.name }}</td>
      <td class="required">{{ borrower.fullAddress }}</td>
      <td class="required tin">{{ borrower.tinLast4 }}</td>
      <td class="required">{{ borrower.fico }}</td>
      <td class="actions">
        <a
          @click.prevent="editBorrower"
          href="#"
          v-show="canEditBorrower"
          title="Edit borrower's information"
        >
          <i class="fas fa-pencil-alt"></i>
        </a>
        <a
          v-if="canDeleteBorrower"
          @click.prevent="deleteBorrower"
          href="#"
          title="Delete borrower"
        >
          <i class="fas fa-trash-alt"></i>
        </a>
      </td>
    </tr>
  </tbody>
</template>

<script>
import Borrower from "models/borrower";

export default {
  name: "borrower-row",
  components: {},
  props: {
    borrower: {
      type: Borrower,
      required: true,
    },
    canEditBorrower: {
      type: Boolean,
      required: true,
    },
    canDeleteBorrower: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    editBorrower() {
      this.$emit("on-borrower-edit", this.borrower);
    },
    deleteBorrower() {
      this.$emit("on-borrower-delete", this.borrower);
    },
  },
};
</script>
