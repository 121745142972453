<template>
  <div :class="containerClass">
    <div v-if="editing" class="form-group d-flex">
      <label v-if="label" class="control-label editable-field-label">
        <with-tooltip v-if="tooltip">
          {{ label }}
          <template slot="content">
            {{ tooltip }}
          </template>
        </with-tooltip>
        <div v-else>
          {{ label }}
        </div>
      </label>
      <slot v-if="editing" name="edit">
        <input
          v-if="mask"
          v-mask:[mask]=""
          v-bind="$props"
          class="form-control foreclosure-input"
          v-on="$listeners"
          :placeholder="placeholder"
          :name="name"
        />
        <input
          v-else
          v-bind="$props"
          class="form-control foreclosure-input"
          v-on="$listeners"
          :placeholder="placeholder"
          :name="name"
        />
      </slot>
    </div>

    <template v-else>
      <label v-if="label" class="control-label loan-attribute-label">
        {{ label }}
      </label>
      <div class="editable-field-value" :class="fieldValueClass">
        <slot name="show">
          {{ value }}
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
import WithTooltip from "components/shared/with_tooltip";

export default {
  name: "editable-input",
  components: {
    WithTooltip,
  },
  props: {
    editing: Boolean,
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  computed: {
    containerClass() {
      return {
        "editable-field-container": this.label,
        "col-12": !this.label,
      };
    },
    fieldValueClass() {
      return {
        "foreclosure-output": this.label,
      };
    },
  },
};
</script>
