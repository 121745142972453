const Event = PS.Models.Event;

class FormEvent extends Event {
  prepareData(options) {
    this.form = options.form;
  }
}

class Updated extends FormEvent {
  prepareData(options) {
    super.prepareData(options);

    this.documentInfo = {
      documentType: this.form.document.type,
      validatedFields: this.form.validatedCount(),
      totalFields: this.form.fieldsCount(),
      notedFields: this.form.notedCount(),
    };
  }
}

class RearrangeFieldsRequested extends FormEvent {
  prepareData(options) {
    this.insertAfter = options.insertAfter;
    this.name = options.name;
  }
}

class ShowFieldsRequested extends FormEvent {
  get fields() {
    return this.options.fields;
  }

  get fieldset() {
    return this.options.fieldset;
  }
}

class HideFieldsRequested extends ShowFieldsRequested {}

class ClearUploadNotificationRequested extends FormEvent {
  prepareData(options) {
    this.uploadId = options.uploadId;
  }
}

PS.Models.Diligence.Events.Form = {
  Updated: Updated,
  RearrangeFieldsRequested: RearrangeFieldsRequested,
  ShowFieldsRequested: ShowFieldsRequested,
  HideFieldsRequested: HideFieldsRequested,
  ClearUploadNotificationRequested: ClearUploadNotificationRequested,
};
