const Event = PS.Models.Event;
const Rule = PS.Models.Rule;
const Events = PS.Models.EmailSubscription.Events;
const Commands = PS.Models.EmailSubscription.Commands;

class TableRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.LendersSetupRequested:
        return this.whenLendersSetupRequested(event);
      case Events.AllSubscriptionUpdated:
        return this.whenSubscriptionUpdated(event);
      case Events.SubscriptionUpdated:
        return this.whenSubscriptionUpdated(event);
      case Events.ResetChangesRequested:
        return this.whenResetChangesRequested(event);
      case Events.SaveChangesRequested:
        return this.whenSaveChangesRequested(event);
    }

    return super.check(event);
  }

  whenLendersSetupRequested(event) {
    return [new Commands.Table.PrepareLenders(event, this.state)];
  }

  whenSubscriptionUpdated(event) {
    return [new Commands.Table.AddChange(event, this.state)];
  }

  whenResetChangesRequested(event) {
    return [
      new Commands.Table.ResetChanges(event, this.state),
      new Commands.Table.ResetLenders(event, this.state),
    ];
  }

  whenSaveChangesRequested(event) {
    if (this.state.updating || !this.state.hasChanges) {
      return [];
    }

    return [
      new Commands.Table.StartUpdating(event, this.state),
      new Commands.Table.SendToServer(event, this.state),
      new Commands.Table.FinishUpdating(event, this.state),
      new Commands.Table.ResetChanges(event, this.state),
      new Commands.Table.PrepareLenders(event, this.state),
    ];
  }
}

PS.Models.EmailSubscription.TableRule = TableRule;
