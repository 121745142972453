<template>
  <div>
    <div class="header">{{ header }}</div>
    <div v-if="items.length" class="list">
      <div
        v-for="item in items"
        :key="item[keyField]"
        class="list-item"
      >
        <slot name="item" :item="item" />
      </div>
    </div>
    <div v-else>no items</div>
  </div>
</template>
<script>
export default Vue.extend({
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    keyField: {
      type: String,
      default: "id",
    },
    header: {
      type: String,
      default: "",
    },
  },
});
</script>
<style scoped>
.header {
  font-size: var(--font-size-lg);
  font-weight: 600;
  padding: var(--space-unit-lg) 0;
}

.list-item {
  padding: calc(var(--space-unit) * 1.5) 0;
}

.list-item:first-child {
  border-top: 1px solid var(--color-grey-16);
}

.list-item:last-child {
  border-bottom: 1px solid var(--color-grey-16);
}
</style>
