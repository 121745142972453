import enumerize from "../utils/enumerize";

const LoanProduct = enumerize({
  residentialRentalLongTerm: {
    value: "residential_rental_long_term",
    title: "Residential for Rent",
  },
  residentialBridge: {
    value: "residential_bridge",
    title: "Residential Bridge",
  },
});

export default LoanProduct;
