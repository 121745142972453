import { isLoanCommercialOrMultifamily, isLoanResiForRent } from "models/lender_form/program";

export default {
  data: function() {
    return {
      loan: PSData.loan || {},
    };
  },
  computed: {
    typeCommercialOrMultifamily() {
      return isLoanCommercialOrMultifamily(this.loan);
    },
    showBorrowerFinancials() {
      return this.typeCommercialOrMultifamily;
    },
    showReserveRatio() {
      return isLoanResiForRent(this.loan);
    },
    showGender() {
      return this.borrower.isIndividual;
    },
    showDateOfBirth() {
      return this.borrower.isIndividual;
    },
    showMaritalStatus() {
      return this.borrower.isIndividual;
    },
    showSoleProperty() {
      return this.borrower.isIndividual;
    },
    showSinglePurposeEntity() {
      return !this.borrower.isIndividual;
    },
    showEntityState() {
      return this.borrower.isEntity;
    },
    showSecretaryOfStateId() {
      return this.borrower.isEntity;
    },
  },
};
