<template>
  <div class="settlement-statement-details">
    <div class="details-row row">
      <div class="col-12">
        <h4 class="section-title">Source of Capital</h4>
      </div>
      <div class="col-6">
        <table class="source-of-capital">
          <thead>
            <tr>
              <th>Source</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="s in series">
              <td>{{ s.sourceOfCapital }}</td>
              <td>{{ s.amount | accounting }}</td>
            </tr>
            <tr v-if="showPreviousAmount" class="previous-value">
              <td>Previous Value</td>
              <td>{{ displayPreviousAmount }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Check Line</td>
              <td :class="{ 'text-danger': checkLineMismatch }">
                {{ checkLineAmount | accounting }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SettlementStatementSourceOfCapital",
  props: ["series", "checkLineAmount", "previousAmount", "showPreviousAmount"],
  computed: {
    checkLineMismatch() {
      return this.checkLineAmount !== "0.0";
    },
    displayPreviousAmount() {
      return this.$options.filters.accounting(this.previousAmount);
    },
  },
};
</script>
