<template>
  <div>
    <base-header size="large" class="pt-3 container">
      Import Loans
    </base-header>
    <base-hr />
    <div class="container">
      <div v-if="canImport" class="columns">
        <loan-tapes-batches-form
          class="column is-half"
          :lenders="lenders"
          :selling-channels="sellingChannels"
          @import="onImport"
        />
      </div>
      <loan-tapes-batches-list :batches="batches" :loading="loading" />
    </div>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import LoanTapesBatch, { MANAGE_LOAN_TAPES_BATCHES_ROUTE } from "models/loan_tapes_batch";
import LoanTapesBatchesList from "./list";
import LoanTapesBatchesForm from "./form";
import Location from "services/location_service";

export default {
  components: {
    LoanTapesBatchesList,
    LoanTapesBatchesForm,
  },
  data: () => ({
    loading: false,
    batches: [],
    lenders: [],
    sellingChannels: [],
    canImport: false,
  }),
  mounted() {
    this.loading = true;
    AjaxService.getJSON(MANAGE_LOAN_TAPES_BATCHES_ROUTE)
      .then(data => {
        this.lenders = data.lender_options || [];
        this.sellingChannels = data.selling_channel_options || [];
        this.canImport = data.can_import || false;
        this.updateRecords(data.items || []);
      })
      .catch(() => {
        this.batches = [];
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    updateRecords(updatedRecords = []) {
      this.batches = updatedRecords.map(record => new LoanTapesBatch(record));
    },
    onImport(data) {
      let batch = new LoanTapesBatch(data);
      this.batches.push(batch);
      setTimeout(() => {
        Location.assign(batch.batchURL);
      }, 1000);
    },
  },
};
</script>
