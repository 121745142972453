import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";

export class FicoRange {
  constructor(id, from = null, to = null) {
    this.from = from;
    this.to = to;
    this.id = id;
  }

  toPlainObject({ toSnakeCase } = { toSnakeCase: false }) {
    const range = {
      from: this.from,
      to: this.to,
      id: this.id,
    };

    if (toSnakeCase) return mapKeys(range, (_, key) => snakeCase(key));

    return range;
  }
}

export class LtvRange extends FicoRange {}
