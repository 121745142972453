import debounce from "utils/debounce";

const Commands = PS.Models.Diligence.Commands;
const Event = PS.Models.Event;

export default Vue.extend({
  props: {
    document: Object,
    field: Object,
    fieldset: {
      type: Object,
      required: false,
    },
    parentDisabled: Boolean,
  },
  data: function() {
    return {
      compiledFormula: undefined,
      ruleBook: undefined,
      messageBus: PS.Services.MessageBusService.diligenceBus,
    };
  },
  mounted: function() {
    Vue.nextTick(() => {
      this.field.updateElement(this.$el);
    });

    this.ruleBook = new PS.Models.Diligence.FieldRule(this);
    this.messageBus.attachRulebook(this.ruleBook);
  },
  beforeDestroy: function() {
    this.messageBus.detachRulebook(this.ruleBook);
  },
  watch: {
    "field.value": "saveValue",
    "field.validated": "saveValidation",
  },
  computed: {
    disabled: function() {
      return this.field.disabled || this.field.validated || this.parentDisabled;
    },
    validateId: function() {
      return `validated_${this.field.id}`;
    },
    fieldId: function() {
      return `field-${this.field.id}`;
    },
    fieldIdSelector: function() {
      return `#field-${this.field.id}`;
    },
  },
  methods: {
    saveValueDebounced: debounce(function() {
      this.saveValueImmediately();
    }, 1500),
    saveValueImmediately: function() {
      if (!this.field.hasChanges) {
        return;
      }

      new Commands.Field.RequestValueSaving(new Event(), this).execute();
    },
    saveValidation: function() {
      new Commands.Field.RequestValidationSaving(new Event(), this).execute();
    },
  },
});
