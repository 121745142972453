export default class ReportLoansService {
  static visibleLoans(loans) {
    return loans.filter(loan => loan.visibleAsFiltered);
  }

  static totalAllocation(loans) {
    return loans
      .map(loan => Number(loan.peerstreetAllocation || 0))
      .reduce((total, current) => total + current, 0);
  }

  static averageAllocation(loans, totalAllocation) {
    if (loans.length > 0) {
      return totalAllocation / loans.length;
    } else {
      return 0;
    }
  }
}
