<template>
  <div class="columns">
    <div class="column is-half">
      <charge-off-form
        :loan-details="loanDetails"
        :charge-off="chargeOffStatement"
        :errors="errors"
        :disable-save="disableSave"
        :disable-process="disableProcess"
        v-on="$listeners"
      />
    </div>
  </div>
</template>
<script>
import ChargeOffForm from "./form";

export default {
  components: {
    ChargeOffForm,
  },
  props: {
    chargeOffStatement: {
      type: Object,
      default: () => ({}),
    },
    loanDetails: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disableSave: {
      type: Boolean,
      default: () => false,
    },
    disableProcess: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
