const MarketDataSource = PS.Models.MarketData.Source;

const FORECASTED_CUMULATIVE_RETURN_VALUES = [
  { label: "YoY Chg", term: "YoY", initial: undefined },
  { label: "12 Mo.", term: "12", initial: undefined },
  { label: "24 Mo.", term: "24", initial: undefined },
  { label: "36 Mo.", term: "36", initial: undefined },
];

const MAX_ROLLING_DECLINE_VALUES = [
  { label: "3 Mo.", term: "3", initial: undefined },
  { label: "6 Mo.", term: "6", initial: undefined },
  { label: "9 Mo.", term: "9", initial: undefined },
  { label: "12 Mo.", term: "12", initial: undefined },
  { label: "15 Mo.", term: "15", initial: undefined },
  { label: "18 Mo.", term: "18", initial: undefined },
  { label: "21 Mo.", term: "21", initial: undefined },
  { label: "24 Mo.", term: "24", initial: undefined },
  { label: "27 Mo.", term: "27", initial: undefined },
  { label: "30 Mo.", term: "30", initial: undefined },
  { label: "33 Mo.", term: "33", initial: undefined },
  { label: "36 Mo.", term: "36", initial: undefined },
];

class Source extends MarketDataSource {
  constructor() {
    super();

    this.forecastedCumulativeReturn = {};
    FORECASTED_CUMULATIVE_RETURN_VALUES.forEach(value => {
      this.forecastedCumulativeReturn[value.term] = value.initial;
    });

    this.maxRollingDecline = {};
    MAX_ROLLING_DECLINE_VALUES.forEach(value => {
      this.maxRollingDecline[value.term] = value.initial;
    });

    this.completedAt = undefined;
  }
}

PS.Models.MarketData.Analytics.Source = Source;

PS.Const.MarketData = {
  Analytics: {
    FORECASTED_CUMULATIVE_RETURN_VALUES: FORECASTED_CUMULATIVE_RETURN_VALUES,
    MAX_ROLLING_DECLINE_VALUES: MAX_ROLLING_DECLINE_VALUES,
  },
};
