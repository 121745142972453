import { detect as detectBrowser } from "detect-browser";

const UNKNOWN_BROWSER_INFO = { browsers: {} };
const UNKNOWN_CURRENT_BROWSER = { os: "unknown", name: "unknown", version: "0.0" };

class BrowserUpdateService {
  constructor(browsersInfo, currentBrowser) {
    this._browsersInfo = browsersInfo || UNKNOWN_BROWSER_INFO;
    this._currentBrowser = currentBrowser || UNKNOWN_CURRENT_BROWSER;

    this.supportedOs = this._detectSupportedOS(this._browsersInfo.browsers);
    this.acceptedBrowsers = this._detectAcceptedBrowsers(this._browsersInfo.browsers);
  }

  _detectSupportedOS(browsers) {
    return [
      ...Object.keys(browsers)
        .map(name => browsers[name].os)
        .reduce((set, osList) => {
          osList.forEach(os => set.add(os));
          return set;
        }, new Set()),
    ];
  }

  _detectAcceptedBrowsers(browsers) {
    return Object.keys(browsers)
      .filter(name => browsers[name].supported)
      .map(name => browsers[name].id);
  }

  get currentBrowserName() {
    return this._currentBrowser.name;
  }

  get currentBrowserVersion() {
    return parseInt(this._currentBrowser.version.match(/^\d*/));
  }

  get currentOS() {
    return this._currentBrowser.os;
  }

  get osIsSupported() {
    return this.supportedOs.includes(this.currentOS);
  }

  get browsers() {
    return this._browsersInfo.browsers;
  }

  get browserVersionIsTooLow() {
    const browser = this.browsers[this.currentBrowserName];
    return browser.minimumVersion > this.currentBrowserVersion;
  }

  get browserIsOutdated() {
    if (!this.osIsSupported) {
      return false;
    }

    if (this.browserIsAccepted) {
      return this.browserVersionIsTooLow;
    } else {
      return true;
    }
  }

  get browserIsAccepted() {
    return this.acceptedBrowsers.includes(this.currentBrowserName);
  }

  get suggestedBrowser() {
    if (this.browserIsAccepted) {
      return this.browsers[this.currentBrowserName];
    } else {
      return this.browsers[this.acceptedBrowsers[0]];
    }
  }

  get availableBrowsers() {
    return Object.keys(this.browsers)
      .filter(browserName => {
        return (
          this.browsers[browserName].enabled &&
          this.browsers[browserName].os.includes(this.currentOS)
        );
      })
      .map(browserName => {
        return this.browsers[browserName];
      });
  }
}

PS.Services.BrowserUpdateService = new BrowserUpdateService(
  PSData.global.browserUpdate,
  detectBrowser()
);
