import times from "lodash/times";

import { RequestPartialUpdate } from "models/reports/hotlist_loans/commands/hotlist_loans_commands";
import { PartialUpdateRequested } from "models/reports/hotlist_loans/events/hotlist_loans_events";

export const HOTLIST_UNDERWRITING_STATUSES = Object.freeze([
  "wired",
  "purchasing",
  "diligenced",
  "diligencing",
  "uploadFiles",
  "qualified",
  "evaluating",
  "review",
  "draft",
]);

const PARALLEL_QUERY_COUNT = 3;

export default class StatusFetcher {
  constructor(context) {
    this.context = context;
    this.availableStatuses = [...HOTLIST_UNDERWRITING_STATUSES];
  }

  fetchAll() {
    times(PARALLEL_QUERY_COUNT, () => this.fetchOne());
  }

  fetchOne() {
    const nextStatus = this.availableStatuses.shift();

    if (nextStatus) {
      return new RequestPartialUpdate(
        new PartialUpdateRequested({ underwritingStatus: nextStatus }),
        this.context
      ).execute();
    } else {
      return Promise.resolve();
    }
  }
}
