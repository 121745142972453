<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content base-modal loan-details-actions-modal">
      <div class="modal-header">
        <h4>
          {{ title }}
          <a class="modal-close-icon" @click.prevent="onClose" href="#close">&times;</a>
        </h4>
        <span>{{ subtitle }}</span>
      </div>
      <div class="modal-body" :style="{ visibility: syncInProgress ? 'hidden' : undefined }">
        <p class="loan-address">
          <span>Lender Platform automatically syncs with Salesforce every 15 minutes.</span>
        </p>
        <p class="loan-address">
          Property Address: <span>{{ loanAddress }}</span>
          <br>
          Last Synced:
          <span v-if="updatingLastSynced" class="ps-loader ps-loader-small loan-task-assignee-loader"></span>
          <span v-if="!updatingLastSynced">{{ lastSynced }}</span>
        </p>
      </div>
      <div class="modal-footer">
        <button v-if="!syncInProgress" @click.prevent="onClose" class="btn btn-outline-grey">
          Cancel
        </button>
        <button v-if="!syncInProgress" @click.prevent="onSync" :disabled="syncInProgress" class="btn btn-action-cta">
          Sync Now
        </button>
        <button v-if="syncInProgress" @click.prevent="onClose" class="btn btn-action-cta">
          OK
        </button>
      </div>
    </div>
  </modal-view>
</template>

<script>
import moment from "moment";
import ModalView from "components/modal_view";
import AjaxService from "services/ajax_service";

export default {
  name: "sync-to-salesforce-modal",
  components: { ModalView },
  props: {
    show: Boolean,
    loanAddress: String,
    loanSalesforceSyncsUrl: String,
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.updateLastSynced();
      } else {
        this.clearLastSynced();
      }
    },
  },
  data() {
    return {
      lastSyncedAt: undefined,
      updatingLastSynced: false,
      syncInProgress: false,
    };
  },
  computed: {
    title() {
      if (this.syncInProgress) {
        return "Sync Initiated";
      } else {
        return "Sync Data to Salesforce";
      }
    },
    subtitle() {
      if (this.syncInProgress) {
        return "Syncing can take up to a few minutes to complete.";
      } else {
        return "Manual Sync will overwrite existing Salesforce Data.";
      }
    },
    lastSynced() {
      if (this.lastSyncedAt) {
        return moment.parseZone(this.lastSyncedAt).fromNow();
      }
    },
  },
  methods: {
    onSync() {
      this.syncInProgress = true;
      AjaxService.post(this.loanSalesforceSyncsUrl);
    },
    onClose() {
      this.syncInProgress = false;
      this.$emit("close");
    },
    updateLastSynced() {
      this.updatingLastSynced = true;
      AjaxService.get(this.loanSalesforceSyncsUrl).then(data => {
        this.updatingLastSynced = false;
        return (this.lastSyncedAt = data.last_synced_at);
      });
    },
    clearLastSynced() {
      this.lastSyncedAt = undefined;
    },
  },
};
</script>
