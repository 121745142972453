<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content base-modal fees-details">
      <div class="modal-header">
        <h4>
          {{ title }}
          <i v-if="loading" class="ps-loader ps-loader-small"></i>
        </h4>
        <span>All fields are required</span>
      </div>
      <div class="modal-body">
        <form @keypress.enter="onSave">
          <div class="form-group controll-wrapper">
            <form-input
              v-if="isDefaultInterest"
              v-model="defaultInterestLabel"
              label="Distribution Fee"
              field=""
              :errors="{}"
              :inline="false"
              :disabled="true"
            />
            <form-select
              v-else
              v-model="resource.feeType"
              label="Distribution Fee"
              :field="feeTypeField"
              :options="feeTypeOptions"
              :errors="errors"
              :inline="false"
              @input="errors = {}"
            />
          </div>
          <div class="form-group controll-wrapper">
            <form-input
              v-if="resource.feeType == 'new'"
              v-model.trim="resource.feeTypeName"
              label="Distribution Fee Name"
              field="feeType"
              :errors="errors"
              :inline="false"
            />
          </div>
          <div class="form-group controll-wrapper">
            <form-select
              v-model="resource.splitType"
              label="Split Type"
              field="spread_factor"
              :options="splitTypeOptions"
              :errors="errors"
              :inline="false"
              @input="handleSplitTypeUpdate"
            />
          </div>
          <div class="form-group controll-wrapper">
            <form-input
              v-if="resource.splitType == 'split'"
              v-model="resource.investorFactor"
              label="% to Entity"
              field="investor_factor"
              :errors="errors"
              :inline="false"
            />
          </div>
          <check-box
            v-if="rule"
            v-model="resource.disabled"
            label="Disable Rule"
          />
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-grey" @click="onClose">Cancel</button>
        <button class="btn btn-action-cta" @click="onSave">
          Save
        </button>
      </div>
    </div>
  </modal-view>
</template>
<script>
import FormSelect from "components/shared/form/form_select";
import FormInput from "components/shared/form/form_input";
import CheckBox from "components/shared/check_box";
import ModalView from "components/modal_view";
import AjaxService from "services/ajax_service";

import { buildDistributionFees } from "./../fees";

export default {
  components: { ModalView, FormSelect, FormInput, CheckBox },
  props: {
    rule: [Object, undefined],
    currentInvestor: {
      type: Object,
      required: true,
    },
  },
  data() {
    const resource = {};

    if (this.rule) {
      resource.disabled = this.rule.status === "disabled";
      resource.feeType = this.rule.feeType?.name;
      resource.splitType = this.rule.spreadFactor ? "spread" : "split";
      resource.investorFactor = this.rule.investorFactor * 100;
    }

    return {
      loading: false,
      show: true,
      errors: {},
      resource,
    };
  },
  computed: {
    defaultInterestLabel() {
      return "Default Interest";
    },
    isDefaultInterest() {
      return this.rule?.disbursementType === "default_interest";
    },
    title() {
      if (this.rule) {
        return "Edit Distribution Rule";
      } else {
        return "Add Distribution Rule";
      }
    },
    splitTypeOptions() {
      return {
        split: "Split",
        spread: "Spread",
      };
    },
    feeTypeOptions() {
      return { new: "Add New", ...this.fees };
    },
    feeTypeField() {
      const { feeType, feeTypeName } = this.resource;

      if (feeType !== "new" && !feeTypeName) {
        return "fee_type";
      } else {
        return "fee_type_dummy";
      }
    },
    fees() {
      return buildDistributionFees(PSData.feeTypes);
    },
  },
  methods: {
    handleSplitTypeUpdate() {
      this.errors = {};
      this.resource.investorFactor = undefined;
    },
    onClose() {
      this.$emit("close");
    },
    onSave() {
      const { splitType, investorFactor, feeType, feeTypeName, disabled } = this.resource;

      const data = {
        spread_factor: splitType ? splitType !== "split" : null,
        investor_factor: parseFloat(investorFactor) / 100,
        fee_type_new: feeType === "new",
        fee_type: feeType === "new" ? feeTypeName || "" : this.fees[feeType],
        investor_id: this.currentInvestor.id,
        enabled: !disabled,
      };

      this.save(data);
    },
    async save(resource) {
      this.loading = true;

      try {
        const action = this.rule ? "patch" : "post";
        const data = await AjaxService[action](
          `/manage/cash_receipt_rules/${this.rule?.id || ""}`,
          { resource },
          { "content-type": "application/json" }
        );

        this.$emit("saved", data);
      } catch ({ response, data }) {
        if (response.status === 422) {
          this.errors = data;
        } else {
          console.log("Error");
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
