export const store = {
  state: {
    draggingRow: {},
    draggingRowIndex: 0,
    draggingMilestone: {},
    rulesDefinitions: {},
    triggerStatuses: [],
  },
  setTriggerStatuses(triggerStatuses) {
    this.state.triggerStatuses = triggerStatuses;
  },
  setRulesDefinitions(rulesDefinitions) {
    this.state.rulesDefinitions = rulesDefinitions;
  },
};
