<template>
  <div class="activity-feed-system-event">
    <base-tooltip 
      :always="false"
      position="is-top"
      :label="event.originalFileName"
      v-if="event.originalFileName"
    >
      <p v-html="event.message" />
    </base-tooltip>
    <p v-else v-html="event.message" />
    <span>{{ event.timeAgoPublished }} ago</span>
  </div>
</template>

<script>
export default {
  name: "ActivityFeedSystemEvent",
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>
