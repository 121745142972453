const Charts = PS.Models.MarketData.Charts;
const MarketDataSource = PS.Models.MarketData.Source;
const MultiSource = PS.Models.MarketData.Charts.MultiSource;
const Calculator = PS.Models.MarketData.Charts.Calculator;

class UnemploymentRateTimeSeries extends MarketDataSource {
  constructor() {
    super();

    this.msaUnemployment = [];
    this.nationalUnemployment = [];
  }

  get name() {
    return "Unemployment Rate";
  }

  success(data) {
    this.msaUnemployment = data.msaUnemployment;
    this.nationalUnemployment = data.nationalUnemployment;

    super.success();
  }

  get sources() {
    return [
      new Charts.Midas("msaUnemployment", this.msaUnemployment, "date", "value"),
      new Charts.Midas("nationalUnemployment", this.nationalUnemployment, "date", "value"),
    ];
  }
}

class UnemploymentRate extends MultiSource {
  constructor() {
    super();

    this._sources = {
      midas: new UnemploymentRateTimeSeries(),
    };
  }

  get name() {
    return "Unemployment Rate";
  }

  get msaMostRecent() {
    let value = Calculator.mostRecent(this, "msaUnemployment") * 100;

    return Number.isNaN(value) ? undefined : value;
  }

  get nationalMostRecent() {
    let value = Calculator.mostRecent(this, "nationalUnemployment") * 100;

    return Number.isNaN(value) ? undefined : value;
  }
}

PS.Models.MarketData.Charts.UnemploymentRate = UnemploymentRate;
