<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content base-modal">
      <div class="modal-header">
        <h4>
          Add a New Document
        </h4>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group control-wrapper">
            <label for="document-type">Select Document Type</label>
            <select id="document-type" v-model="documentTypeId" class="form-control">
              <option disabled selected value="">
                Select
              </option>
              <option v-for="documentType in documentTypes" :key="documentType.documentType" :value="documentType.id">
                {{ documentType.name }}
              </option>>
            </select>
          </div>
          <div class="form-group control-wrapper">
            <label for="description">Description</label>
            <textarea
              id="description"
              v-model="description"
              maxlength="255"
              class="form-control"
            />
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-grey" :disabled="cancelDisabled" @click.prevent="onClose">
          Cancel
        </button>
        <button class="btn btn-action-cta" :disabled="addDisabled" @click="onSave">
          Add Document
        </button>
      </div>
    </div>
  </modal-view>
</template>

<script>
import ModalView from "components/modal_view";

const Ajax = PS.Services.AjaxService;

export default {
  name: "new-tracking-document-modal",
  components: { ModalView },
  props: {
    show: Boolean,
  },
  data() {
    return {
      loanId: PSData.documentTrackingLoanId,
      documentTypeId: null,
      description: null,
      documentTypes: PSData.trackingDocumentTypes,
      updating: false,
    };
  },
  computed: {
    cancelDisabled() {
      return this.updating;
    },
    addDisabled() {
      return !this.documentTypeId || this.updating;
    },
  },
  methods: {
    onSave() {
      if (!this.documentTypeId) return;

      this.updating = true;

      const document = {
        description: this.description,
        document_type_id: this.documentTypeId,
      };

      const messages = {
        onSuccess: "Document created.",
        onError: this.onError,
      };

      Ajax.withNotifications(messages)
        .post(`/manage/loans/${this.loanId}/document_tracking`, document, {
          "Content-Type": "application/json",
        })
        .then(response => {
          this.$emit("on-save", response);
          this.reset();
        })
        .catch(error => {
          Bugsnag.notify(
            { name: "Request Error", message: "Unable to create tracking document" },
            { metaData: { xhr: error } }
          );
        })
        .finally(() => {
          this.updating = false;
        });
    },
    onError({ error }) {
      return {
        message: error,
        status: "error",
      };
    },
    onClose() {
      this.$emit("on-close");
      this.reset();
    },
    reset() {
      this.description = null;
      this.documentTypeId = null;
    },
  },
};
</script>
