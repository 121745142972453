<template>
  <div :class="{ 'has-error': hasError }">
    <slot></slot>
    <span
      v-if="hasError"
      class="error control-label">
      {{allErrors}}
    </span>
  </div>
</template>
<script>
export default {
  name: "transaction-error",
  props: ["errors", "field"],
  computed: {
    hasError() {
      return this.errors[this.field] !== undefined;
    },
    allErrors() {
      const errors = this.errors[this.field];

      if (errors instanceof Array) {
        return errors.join(", ");
      }
    },
  },
};
</script>
