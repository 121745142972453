const Charts = PS.Models.MarketData.Charts;
const Source = PS.Models.MarketData.Source;

class RentVersusOwnership extends Source {
  constructor() {
    super();

    this.rent = [];
    this.ownership = [];
  }

  get name() {
    return "Rent vs Ownership";
  }

  get data() {
    return {
      hcRent: this.rent,
      hcOwnership: this.ownership,
    };
  }

  get hasData() {
    return Object.values(this.data).some(data => {
      return data.length > 0;
    });
  }

  success(data) {
    const zipData = data.midas.zipCrossSection;

    if (zipData) {
      this.rent.push(zipData.renterHh);
      this.ownership.push(zipData.ownerHh);
    }

    super.success();
  }
}

PS.Models.MarketData.Charts.RentVersusOwnership = RentVersusOwnership;
