<template>
  <div class="alert alert-danger notification-message">
    <div><i class="fas fa-times-circle fa-lg"></i></div>
    <div>
      <h3>{{ title }}</h3>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "banner-alert",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>
