<template>
  <div class="time-series-comparison-chart">
    <h3 class="title">
      {{chart.title}}
      <div v-if="sources.isLoading" class="ps-loader ps-loader-small"></div>
    </h3>
    <div class="body">
      <div class="chart" ref="chart"></div>
      <div class="legend">
        <span
          v-for="(column, key) in sources.data"
          :key="key"
          :class="legendClasses[key]"
          @mouseover="onMouseOver(key)"
          @mouseout="onMouseOut(key)"
          @click="onClick(key)"
          class="legend-item">
            <span :style="{ backgroundColor: chart.columns[key].color }"></span>
            {{chart.columns[key].label}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import c3 from "c3";
import MarketDataChartMixin from "components/market_data/market_data_chart_mixins";

export default {
  name: "donut-comparison-chart",
  mixins: [MarketDataChartMixin],
  data() {
    return {
      options: {
        donut: {
          label: {
            format: this.chart.format,
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          format: {
            value: this.chart.format,
          },
        },
      },
      legendClasses: this.prepareLegendClasses(),
    };
  },
  methods: {
    render() {
      this.legendClasses = this.prepareLegendClasses();
      this.options.data = this.prepareData();
      this.c3 = c3.generate({
        ...this.options,
        bindto: this.$refs.chart,
      });
    },
    prepareData: function() {
      const data = this.sources.data;
      const chartKeys = Object.keys(data);

      return {
        empty: {
          label: {
            text: this.chartMessage,
          },
        },
        columns: chartKeys.reduce((columns, key) => {
          const column = [this.findColumnName(key), ...data[key]];
          return [...columns, column];
        }, []),
        colors: chartKeys.reduce((colors, key) => {
          colors[this.findColumnName(key)] = this.chart.columns[key].color;
          return colors;
        }, {}),
        type: "donut",
      };
    },
    prepareLegendClasses() {
      return Object.keys(this.chart.columns).reduce((obj, key) => {
        obj[key] = {
          fade: false,
          off: false,
        };

        return obj;
      }, {});
    },
    onMouseOver(key) {
      if (this.legendClasses[key].off) return;

      this.c3.focus(this.findColumnName(key));

      Object.keys(this.legendClasses).forEach(k => {
        this.legendClasses[k].fade = k !== key;
      });
    },
    onMouseOut() {
      this.c3.revert();

      Object.keys(this.legendClasses).forEach(k => {
        this.legendClasses[k].fade = false;
      });
    },
    onClick(key) {
      this.c3.toggle(this.findColumnName(key));

      Object.keys(this.legendClasses).forEach(k => {
        this.legendClasses[k].fade = false;
      });
      this.legendClasses[key].off = !this.legendClasses[key].off;
    },
    findColumnName: function(key) {
      return this.chart.columns[key].label;
    },
  },
};
</script>
