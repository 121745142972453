<template>
  <button
    v-if="!$options.canDistribute"
    class="btn btn-primary"
    :disabled="disabled"
    @click="handleApprove"
  >
    Approve {{ count }} Transactions
  </button>
</template>

<script>
import map from "lodash/map";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { approving: false };
  },
  computed: {
    disabled() {
      return this.approving || this.count < 1;
    },
    count() {
      return this.items.length;
    },
  },
  methods: {
    handleApprove() {
      this.approving = true;
      const ids = map(this.items, "id");
      this.$emit("approve", ids, this.handleSuccess);
    },
    handleSuccess() {
      this.approving = false;
    },
  },
  canDistribute: PSData.canDistribute,
};
</script>
