const Event = PS.Models.Event;
const Events = PS.Models.Diligence.Events;
const Commands = PS.Models.Diligence.Commands;
const Rule = PS.Models.Rule;

class FieldRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.Field.FormulaRecalculationRequested:
        return this.whenFormulaRecalculationRequested(event);

      case Events.Field.ValueResendingRequested:
        return this.whenValueResendingRequested(event);

      case Events.Fieldset.FormulaInvalidationRequested:
        return this.whenFormulaInvalidationRequested(event);

      case Events.Field.ValueChanged:
        return this.whenFieldValueChanged(event);

      case Events.Field.FieldDisableRequested:
        return this.whenFieldDisableRequested(event);

      case Events.Field.FieldEnableRequested:
        return this.whenFieldEnableRequested(event);

      case Events.Field.ToggleDisabledFieldsRequested:
        return this.whenToggleDisabledFieldsRequested(event);
    }

    return super.check(event);
  }

  whenFormulaRecalculationRequested(event) {
    return [new Commands.Field.RecalculateFormula(event, this.state)];
  }

  whenValueResendingRequested(event) {
    return [new Commands.Field.ResendFieldValues(event, this.state)];
  }

  whenFormulaInvalidationRequested(event) {
    return [new Commands.Field.InvalidateFormula(event, this.state)];
  }

  whenFieldValueChanged(event) {
    const isBPieceHolder =
      this.state.document.type === "note" && this.state.field.name === "b_piece_holder";
    const isPrepayPenalty =
      this.state.document.type === "note" && event.field.name === "pre_pay_penalty_y_n";

    const commands = [];

    switch (true) {
      case isPrepayPenalty:
        commands.push(
          new Commands.Field.RequestToggleDisabledFields(new Event(), { field: event.field })
        );
      case isBPieceHolder:
        commands.push(...this.changeBPieceHolder(event));
    }

    return commands;
  }

  whenToggleDisabledFieldsRequested(event) {
    const prepayPenalty =
      this.state.document.type === "note" && event.field.name === "pre_pay_penalty_y_n";

    switch (true) {
      case prepayPenalty: {
        const args = [event, { field: { name: "prepay_penalty_terms" } }];

        if (event.field.value === "0") {
          return [new Commands.Field.RequestFieldDisable(...args)];
        } else {
          return [new Commands.Field.RequestFieldEnable(...args)];
        }
      }

      default: {
        return [];
      }
    }
  }

  changeBPieceHolder(event) {
    const isValueSet = this.state.field.value !== undefined;
    const isBPieceAmountChanged =
      event.document.type === "note" && event.field.name === "b_piece_amount";
    const isBPieceAmountPositive = event.value > 0;
    const isChangeNeeded = isBPieceAmountChanged && isBPieceAmountPositive;

    if (isValueSet || !isChangeNeeded) {
      return [];
    }

    return [
      new Commands.Field.ChangeValue(
        new Events.Field.ValueChangeRequested({ value: "originator" }),
        this.state
      ),
    ];
  }

  whenFieldDisableRequested(event) {
    if (this.isToggleFieldAllowed(event)) {
      return [new Commands.Field.DisableField(event, this.state)];
    } else {
      return [];
    }
  }

  whenFieldEnableRequested(event) {
    if (this.isToggleFieldAllowed(event)) {
      return [new Commands.Field.EnableField(event, this.state)];
    } else {
      return [];
    }
  }

  isToggleFieldAllowed(event) {
    if (this.state.field.name !== event.field.name) {
      return false;
    }

    const missingFieldset = event.fieldset && !this.state.fieldset;
    if (missingFieldset) {
      return false;
    }

    const wrongFieldset = event.fieldset && this.state.fieldset.id !== event.fieldset.id;
    if (wrongFieldset) {
      return false;
    }

    return true;
  }
}

PS.Models.Diligence.FieldRule = FieldRule;
