<template>
  <modal-form
    class="doc-tracking-modal"
    title="Ignore Exceptions"
    save-button-name="Ignore"
    :show="true"
    @on-close="close"
    @on-save="ignore"
  >
    <span>
      Are you sure you want to ignore {{ count }} items?
    </span>
    <template v-slot:footer>
      <div class="main-actions">
        <div class="btn btn-cancel-modal" @click="close">Cancel</div>
        <div class="btn btn-alt-modal" :disabled="itemsResolving" @click="ignore">Ignore</div>
      </div>
    </template>
  </modal-form>
</template>

<script>
import ModalForm from "components/modal_form.vue";

export default {
  name: "ignore-items-modal",
  components: {
    ModalForm,
  },
  props: {
    itemsResolving: {
      type: Boolean,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("on-close");
    },
    ignore() {
      this.$emit("ignore");
    },
  },
};
</script>
