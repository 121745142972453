<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content base-modal fees-details">
      <div class="modal-header">
        <h4>Fees details</h4>
      </div>
      <div class="modal-body">
        <table class="table">
          <tbody>
            <tr>
              <td class="column">
                Loan
              </td>
              <td class="text-right">
                {{ item.loan.id }}
              </td>
            </tr>
            <tr>
              <td class="column">
                Owner
              </td>
              <td class="text-right">
                {{ owner }}
              </td>
            </tr>
            <tr>
              <td class="column">
                Check Date
              </td>
              <td class="text-right">
                {{ item.cashReceipt.receivedDate | formatDate }}
              </td>
            </tr>
            <tr>
              <td class="column">
                Fee Total
              </td>
              <td class="text-right">
                {{ totalFees | accounting }}
              </td>
            </tr>
            <tr v-for="fee in fees" :key="fee.id">
              <td class="column">
                {{ fee.feeLabel }}
              </td>
              <td class="text-right">
                {{ fee.revisedAmount | accounting }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button class="btn btn-action-cta" @click="$emit('close')">
          Close
        </button>
      </div>
    </div>
  </modal-view>
</template>
<script>
import sumBy from "lodash/sumBy";
import ModalView from "components/modal_view";

export default {
  components: { ModalView },
  props: {
    item: {
      type: Object,
      required: true,
    },
    owner: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    fees() {
      return this.item.details.filter(detail => detail.disbursementType == "fee");
    },
    totalFees() {
      const distributions = this.item.distributionGroups[this.owner];

      return sumBy(
        distributions,
        ({ amount, disbursementType }) => (disbursementType === "fee" ? amount : 0)
      );
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
