<template>
  <div>
    <div v-show="!editPropertyView" class="loan-property">
      <breadcrumb-navigation :items="breadcrumbItems" />

      <h3 class="loan-details-view-header clearfix">
        {{ property.fullAddressCalculated }}

        <base-group class="is-pulled-right">
          <base-group-control>
            <base-button type="primary" :disabled="actionDisabled" @click="onEdit">
              Edit
            </base-button>
          </base-group-control>
          <base-group-control v-if="propertyDeleteable">
            <base-dropdown type="primary" :disabled="actionDisabled" position="left">
              <base-dropdown-item @click="onDelete">
                Delete this Property
              </base-dropdown-item>
            </base-dropdown>
          </base-group-control>
        </base-group>
      </h3>

      <loan-property-details :property="property" />
      <div v-if="valuationPresent">
        <button class="button is-primary float-right" v-show="!editValuationView" @click="onEditValuation">Edit</button>
        <loan-valuation-details v-show="!editValuationView" :valuation="valuation" />
        <loan-valuation-form
          v-show="editValuationView"
          :valuation="valuation"
          @on-cancel="onValuationEditCancel"
          @on-save="onValuationSave"
        />
      </div>
      <div v-if="validationPresent">
        <button class="button is-primary float-right" v-show="!editValidationView" @click="onEditValidation">Edit</button>
        <loan-valuation-details v-show="!editValidationView" :valuation="validation" />
        <loan-valuation-form
          v-show="editValidationView"
          :valuation="validation"
          @on-cancel="onValidationEditCancel"
          @on-save="onValidationSave"
        />
      </div>
      <loan-property-variance v-if="showValuationEnabled" />
      <loan-property-tax-and-insurance v-if="showTaxAndInsurance" :property="property" />
    </div>
    <loan-property-form
      v-show="editPropertyView"
      :property="property"
      @on-cancel="onPropertyEditCancel"
      @on-save="onPropertySave"
    />
  </div>
</template>

<script>
import BreadcrumbNavigation from "../shared/breadcrumb_navigation";
import LoanPropertyDetails from "./details/show.vue";
import LoanValuationDetails from "./valuation/show.vue";
import LoanValuationForm from "./valuation/form.vue";
import LoanPropertyForm from "./property_form.vue";
import LoanPropertyVariance from "./variance.vue";
import LoanPropertyTaxAndInsurance from "./tax_and_insurance/show.vue";
import Property from "models/lender_form/property";
import PropertyValuation from "models/property_valuation";
import { isLoanResiForRent } from "models/lender_form/program";
import AjaxService, { ERROR_STATUS, ERROR_MESSAGE } from "services/ajax_service";

export default {
  name: "loan-property",
  components: {
    BreadcrumbNavigation,
    LoanPropertyDetails,
    LoanValuationDetails,
    LoanPropertyForm,
    LoanValuationForm,
    LoanPropertyVariance,
    LoanPropertyTaxAndInsurance,
  },
  data() {
    return {
      property: new Property(PSData.property, PSData.propertyOptions, PSData.loan),
      valuation: PSData.valuation ? new PropertyValuation(PSData.valuation) : undefined,
      validation: PSData.validation ? new PropertyValuation(PSData.validation) : undefined,
      loan: PSData.loan || {},
      routes: PSData.routes || {},
      editPropertyView: false,
      editValuationView: false,
      editValidationView: false,
      valuationPresent: PSData.valuation ? true : false,
      validationPresent: PSData.validation ? true : false,
      actionDisabled: false,
      propertyDeleteable: PSData.propertyDeleteable || false,
      showValuationEnabled: PSData.featureFlag.showValuationOnProperty,
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          name: "Details",
          link: this.routes.loan,
        },
        {
          name: "Properties",
          link: this.routes.properties,
        },
        {
          name: this.property.fullAddressCalculated,
        },
      ];
    },
    showTaxAndInsurance() {
      return isLoanResiForRent(this.loan);
    },
  },
  methods: {
    onEdit() {
      this.editPropertyView = true;
    },
    onEditValuation() {
      this.editValuationView = true;
    },
    onEditValidation() {
      this.editValidationView = true;
    },
    onDelete() {
      const message = `Are you sure you want to delete the property ${
        this.property.fullAddressCalculated
      } from this loan?`;

      if (!window.confirm(message)) {
        return;
      }

      this.actionDisabled = true;

      return AjaxService.delete(this.routes.property)
        .then(() => {
          window.location = this.routes.properties;
        })
        .catch(() => {
          AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
          this.actionDisabled = false;
        });
    },
    onPropertySave(updatedProperty) {
      this.property.update(updatedProperty);
      this.editPropertyView = false;
      location.reload();
    },
    onPropertyEditCancel() {
      this.editPropertyView = false;
      this.property.reset();
    },
    onValuationSave(updatedValuation) {
      this.editValuationView = false;
      this.valuation.update(updatedValuation);
      location.reload();
    },
    onValuationEditCancel() {
      this.editValuationView = false;
      this.valuation.reset();
    },
    onValidationSave(updatedValidation) {
      this.editValidationView = false;
      this.validation.update(updatedValidation);
      location.reload();
    },
    onValidationEditCancel() {
      this.editValidationView = false;
      this.validation.reset();
    },
  },
};
</script>
