<template>
  <div>
    <h3><slot name="title" /></h3>
    <table class="table import-items-group">
      <thead>
        <tr>
          <th>Loan ID</th>
          <th>Address</th>
          <th>Doc Name</th>
          <th>To</th>
          <th>From</th>
          <th>Description</th>
          <th>Old Status</th>
          <th>Skipped Status</th>
          <th>Resolved By</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td :class="getItemClassName(item)"><loan-link :item="item"/></td>
          <td>{{ item.address }}</td>
          <td>{{ item.docName }}</td>
          <td>{{ item.toAssignment }}</td>
          <td>{{ item.fromAssignment }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.oldDocStatusText }}</td>
          <td>{{ item.newDocStatusText }}</td>
          <td>{{ item.resolvedBy }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import LoanLink from "./loan_link.vue";

export default {
  name: "physical-doc-tracking-import-skipped-docs-group",
  components: {
    LoanLink,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  methods: {
    getItemClassName(item) {
      if (item.statusTypeChanged) return "attention-required-import-item";

      return "";
    },
  },
};
</script>
