<template>
  <table class="table first-month-calcs">
    <thead>
      <tr>
        <th></th>
        <th>Per Diem</th>
        <th>Amount</th>
      </tr>
    </thead>
    <tbody>
      <component
        label="Gross Interest Revenue"
        v-if="isFunded"
        v-model="settlementStatement.grossInterestRevenue"
        :is="perDiemFieldsComponent"
        :previous="settlementStatement.pastStatement.grossInterestRevenue"
        :total="settlementStatement.totalStatement.grossInterestRevenue"
        :editing="editModeEnabled"
        :days-owned="settlementStatement.daysOwned"
      ></component>

      <component
        label="Gross Interest Revenue"
        v-if="!isFunded"
        v-model="settlementStatement.prePaidInterest"
        :is="perDiemFieldsComponent"
        :previous="settlementStatement.pastStatement.prePaidInterest"
        :total="settlementStatement.totalStatement.prePaidInterest"
        :editing="editModeEnabled"
        :days-owned="settlementStatement.daysOwned"
      ></component>

      <component
        label="Originator Interest Expense"
        v-model="settlementStatement.originatorInterestExpense"
        :is="perDiemFieldsComponent"
        :previous="settlementStatement.pastStatement.originatorInterestExpense"
        :total="settlementStatement.totalStatement.originatorInterestExpense"
        :editing="editModeEnabled"
        :days-owned="settlementStatement.daysOwned"
        :disabled="!isFunded"
      ></component>

      <component
        label="B-Piece Interest Expense"
        v-model="settlementStatement.bPieceInterestExpense"
        :is="perDiemFieldsComponent"
        :previous="settlementStatement.pastStatement.bPieceInterestExpense"
        :total="settlementStatement.totalStatement.bPieceInterestExpense"
        :editing="editModeEnabled"
        :days-owned="settlementStatement.daysOwned"
        :disabled="!isFunded"
      ></component>
    </tbody>
  </table>
</template>

<script>
import PerDiemFields from "./per_diem_fields";
import PerDiemFieldsReconciliation from "./per_diem_fields_reconciliation";

const FUNDED_TYPE = "Funded";

export default {
  name: "SettlementStatementRevenueExpenseTable",
  components: { PerDiemFields, PerDiemFieldsReconciliation },
  props: ["settlementStatement", "editModeEnabled"],
  computed: {
    isFunded() {
      return this.settlementStatement.origination === FUNDED_TYPE;
    },
    perDiemFieldsComponent() {
      if (this.settlementStatement.settlementStatementType === "reconciliation") {
        return "PerDiemFieldsReconciliation";
      } else {
        return "PerDiemFields";
      }
    },
  },
};
</script>
