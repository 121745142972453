<template>
  <div class="loan-suspension" v-if="!isDismissed">
    <div class="loan-suspension-details">
      <div class="loan-suspension-details-header">{{ suspension.statusName }}</div>
      <div class="loan-suspension-details-row">
        <div class="loan-suspension-details-reasons">
          <h4>Reasons</h4>
          <ul>
            <li v-for="reason in suspension.loanSuspensionReasons" :key="reason.id">
              {{ reason.label }}
            </li>
          </ul>
        </div>
        <div class="loan-suspension-details-description">
          <h4>Description</h4>
          <span>{{ suspension.description }}</span>
        </div>
      </div>
      <div v-if="!isApproved" class="loan-suspension-buttons">
        <span class="loan-suspension-edit-button" @click="showPopup = true">Edit</span>
        <button @click=dismiss
                :disabled="isSubmitting"
                tabindex=-1
                type="button"
                class="btn btn-secondary-action-cta loan-suspension-dismiss-button">
          Dismiss Suspension
        </button>
        <button @click="approve"
                :disabled="isSubmitting"
                type="button"
                class="btn btn-action-cta loan-suspension-approve-button">
          Approve Suspension
        </button>
      </div>
    </div>
    <loan-suspend-modal :suspension="suspension"
                        v-if="showPopup"
                        @saved="onUpdate"
                        @closed="onClose" />
  </div>
</template>
<script>
import Ajax from "services/ajax_service";
import LocationService from "services/location_service";
import WindowService from "services/window_service";
import LoanSuspendModal from "components/manage/loan_suspension/modal";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "manage-loan-suspension-details",
  components: { LoanSuspendModal },
  data() {
    return {
      showPopup: false,
      originalSuspension: cloneDeep(PSData.loanSuspension),
      suspension: cloneDeep(PSData.loanSuspension),
      isSubmitting: false,
      endpoints: {
        approve: PSData.routes.loanSuspensionApprove,
        dismiss: PSData.routes.loanSuspensionDismiss,
      },
    };
  },
  methods: {
    onClose() {
      this.showPopup = false;
      this.suspension = cloneDeep(this.originalSuspension);
    },
    onUpdate(data) {
      this.suspension = data;
      this.originalSuspension = cloneDeep(data);
      this.showPopup = false;
    },
    approve() {
      if (this.isConfirmed()) {
        this.request("approve");
      }
    },
    dismiss() {
      if (this.isConfirmed()) {
        this.request("dismiss");
      }
    },
    request(endpoint) {
      this.isSubmitting = true;

      Ajax.withNotifications({ onError: this.onError })
        .patch(this.endpoints[endpoint])
        .then(data => {
          this.suspension = data;
          LocationService.reload();
        })
        .finally(() => (this.isSubmitting = false));
    },
    onError({ response, data }) {
      if (response.status === 403) {
        return {
          message: data.error,
          status: "error",
        };
      }
    },
    isConfirmed() {
      return WindowService.confirm("Are you sure?");
    },
  },
  computed: {
    isApproved() {
      return this.suspension.isApproved;
    },
    isDismissed() {
      return this.suspension.isDismissed;
    },
  },
};
</script>
