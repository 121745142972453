export default function enumerize(mapping) {
  const sortedMappingKeys = Reflect.ownKeys(mapping);

  mapping.options = sortedMappingKeys.map(key => [mapping[key].title, mapping[key].value]);

  mapping.titles = sortedMappingKeys.reduce((titles, key) => {
    titles[mapping[key].value] = mapping[key].title;
    return titles;
  }, {});

  return mapping;
}
