import Rule from "models/rule";

import {
  GetOneStatusFromServer,
  RequestNextStatusFromServer,
  LoadOneStatusFromServer,
  FilterOneStatusBy,
  UpdateFilters,
  FilterBy,
  LoadFromServer,
  SaveHotlistFilters,
  RestoreHotlistFilters,
  GetPurchasedFromServer,
} from "models/reports/hotlist_loans/commands/hotlist_loans_commands";

import {
  Updated,
  FilterLoansByAnalystRequested,
  FilterLoansByTaskAssigneeRequested,
  FilterLoansByPropertyTypeRequested,
  FilterLoansByLoanProgramRequested,
  FilterLoansByAccountTypeRequested,
  FilterLoansByIntervalRequested,
  PartialUpdateRequested,
} from "models/reports/hotlist_loans/events/hotlist_loans_events";

export default class HotlistLoansRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Updated:
        return this.whenHotlistUpdated(event);

      case FilterLoansByAnalystRequested:
      case FilterLoansByTaskAssigneeRequested:
      case FilterLoansByPropertyTypeRequested:
      case FilterLoansByLoanProgramRequested:
      case FilterLoansByAccountTypeRequested:
        return this.whenHotlistFilterRequested(event);

      case FilterLoansByIntervalRequested:
        return this.whenFilterLoansByIntervalRequested(event);

      case PartialUpdateRequested:
        return this.whenPartialUpdateRequested(event);
    }

    return super.check(event);
  }

  whenHotlistUpdated(event) {
    return [
      new LoadFromServer(event, this.state),
      new RestoreHotlistFilters(event, this.state),
      new SaveHotlistFilters(event, this.state),
      new FilterBy(event, this.state),
    ];
  }

  whenHotlistFilterRequested(event) {
    return [
      new UpdateFilters(event, this.state),
      new SaveHotlistFilters(event, this.state),
      new FilterBy(event, this.state),
    ];
  }

  whenPartialUpdateRequested(event) {
    return [
      new GetOneStatusFromServer(event, this.state),
      new LoadOneStatusFromServer(event, this.state),
      new FilterOneStatusBy(event, this.state),
      new RequestNextStatusFromServer(event, this.state),
    ];
  }

  whenFilterLoansByIntervalRequested(event) {
    return [
      new UpdateFilters(event, this.state),
      new SaveHotlistFilters(event, this.state),
      new GetPurchasedFromServer(event, this.state),
      new LoadOneStatusFromServer(event, this.state),
      new FilterOneStatusBy(event, this.state),
    ];
  }
}
