<template>
  <ul v-cloak class="ajax-notification">
    <li
      v-for="(notification, key) in notifications"
      :key="key"
      transition="v-expand"
      :class="notification.status"
    >
      <span class="ajax-message">
        {{ notification.message }}
      </span>
    </li>
  </ul>
</template>

<script>
const MAX_VISIBLE_NOTIFICATIONS = 5;
const NOTIFICATION_TIMEOUT_MSEC = 3500;

export default {
  data: () => ({
    notifications: [],
    notificationTimers: [],
  }),
  mounted: function() {
    this.$nextTick(() => {
      $(window.PS).on("ajaxNotification.add", (e, notification) => {
        this.add(notification);
      });
    });
  },
  methods: {
    add: function(notification) {
      if (this.notifications.length + 1 > MAX_VISIBLE_NOTIFICATIONS) {
        // force hide for the first visible message
        clearTimeout(this.notificationTimers.pop());
        this.notifications.pop();
      }

      this.notifications.unshift(notification);

      // hide message after N seconds
      var timer = setTimeout(() => {
        Vue.delete(this.notifications, this.notifications.indexOf(notification));
      }, NOTIFICATION_TIMEOUT_MSEC);
      this.notificationTimers.unshift(timer);
    },
  },
};
</script>
