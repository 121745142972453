<template>
  <div>
    <ul class="nav nav-tabs loan-nav">
      <li :class="{ active: isStatusActive }">
        <a href="#" @click="showStatusSummary">By Status</a>
      </li>
      <li :class="{ active: !isStatusActive }">
        <a href="#" @click="showFundingEntitySummary">By Source of Capital</a>
      </li>
    </ul>
    <div v-if="loading" class="ps-loader" />
    <div v-if="!loading" class="loan-series-summary">
      <table v-show="isStatusActive" class="table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Count</th>
            <th class="number-cell">Amount</th>
            <th class="number-cell">Percentage of Allocation</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(summary, index) in statusSummaries" :key="index">
            <td>{{ summary.statusText }}</td>
            <td>{{ summary.count }}</td>
            <td class="number-cell">{{ summary.amount | money }}</td>
            <td class="number-cell" :class="allocationStatus(summary)">
              {{ summary.allocation | percent }}
            </td>
          </tr>
        </tbody>
      </table>
      <table v-show="!isStatusActive" class="table">
        <thead>
          <tr>
            <th>Source of Capital</th>
            <th class="number-cell">Amount</th>
            <th class="number-cell">Percentage</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="hasFundingEntitySummaries">
            <tr v-for="(summary, index) in fundingEntitySummaries" :key="index">
              <td>{{ summary.fundingEntityText }}</td>
              <td class="number-cell">{{ summary.amount | money }}</td>
              <td class="number-cell">{{ summary.percentage | percent }}</td>
            </tr>
          </template>
          <template v-if="!hasFundingEntitySummaries">
            <tr>
              <td>
                No data
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
const Commands = PS.Models.Loan.Commands;
const Event = PS.Models.Event;

export default Vue.component("loan-series-summary", {
  data: function() {
    return {
      loan: PSData.loan,
      loading: false,
      isStatusActive: true,
      statusSummaries: [],
      fundingEntitySummaries: [],
      messageBus: window.PS.Services.MessageBusService.loanBus,
      rulebook: undefined,
    };
  },
  mounted: function() {
    this.rulebook = new PS.Models.Loan.SeriesSummaryRule(this);
    this.messageBus.attachRulebook(this.rulebook);

    this.loadSummaries();
  },
  beforeDestroy: function() {
    this.messageBus.detachRulebook(this.rulebook);
  },
  methods: {
    showStatusSummary: function(event) {
      event.preventDefault();
      this.isStatusActive = true;
    },
    showFundingEntitySummary: function(event) {
      event.preventDefault();
      this.isStatusActive = false;
    },
    hasFundingEntitySummaries: function() {
      return this.fundingEntitySummaries.length > 0;
    },
    loadSummaries: function() {
      new Commands.SeriesSummary.RequestRefresh(new Event(), {}).execute();
    },
    allocationStatus: function(summary) {
      if (summary.partiallyAllocated) {
        return "partially-allocated";
      }
    },
  },
});
</script>
