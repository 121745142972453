<template>
  <div class="float-right">
    <button
      type="button"
      class="btn btn-link loan-sales-action-reset"
      :disabled="resetDisabled"
      @click="$emit('reset')"
    >
      Clear List
    </button>

    <button
      type="button"
      class="btn btn-primary loan-sales-action-sell"
      :disabled="sellDisabled"
      @click="$emit('sell')"
    >
      Sell
    </button>
  </div>
</template>

<script>
export default {
  props: {
    resetDisabled: Boolean,
    sellDisabled: Boolean,
  },
};
</script>
