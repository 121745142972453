<template>
  <view-panel class="loan-details-view-panel">
    <template slot="header">
      Variance
    </template>
      <div class="row loan-details-view-panel-row">
        <div class="col-6 loan-details-view-panel-column">
          <details-list :data="varianceRows" />
        </div>
      </div>
  </view-panel>
</template>

<script>
import ViewPanel from "components/view_panel.vue";
import DetailsList from "components/loan_details/list";

export default {
  name: "loan_property_variance",
  components: {
    ViewPanel,
    DetailsList,
  },
  data() {
    return {
      variancePurchasePrice: PSData.variancePurchasePrice,
      varianceValidation: PSData.varianceValidation,
    };
  },
  computed: {
    varianceRows() {
      return [
        {
          label: "Variance From Purchase Price %",
          value: this.variancePurchasePrice || "--",
          show: true,
        },
        {
          label: "Variance between Value and Validation Amounts (%)",
          value: this.varianceValidation || "--",
          show: true,
        },
      ];
    },
  },
};
</script>
