<template>
  <div class="allocation">
    <error-messages :errors="errors" />
    <h2 class="lender-section-title">
      Loan Allocation
      <span v-if="allocationInProgress" class="badge badge-warning">Allocation is in progress</span>
      <div v-show="isLoading" class="ps-loader ps-loader-small" />
    </h2>
    <div v-if="isLoaded" class="loan-secondary-navigation">
      <ul class="nav nav-tabs loan-nav loan-tabs">
        <li class="nav-item">
          <a
            href="#"
            class="nav-link"
            :class="{ active: isActiveTab('loans') }"
            @click.prevent="switchTab('loans')"
          >
            Loans
          </a>
        </li>
        <li class="nav-item">
          <a
            href="#"
            class="nav-link"
            :class="{ active: isActiveTab('buyers') }"
            @click.prevent="switchTab('buyers')"
          >
            Buyers
          </a>
        </li>
      </ul>
    </div>
    <div v-if="isLoaded">
      <buyers-tab v-if="isActiveTab('buyers')" :buyers="buyers" @save="saveBuyers" />
      <loans-tab
        v-if="isActiveTab('loans')"
        :batch="batch"
        :batch-loans="batch.batchLoans"
        :buyers="buyers"
        :is-importing="isImporting"
        :is-saving="isSaving"
        :is-allocating="isAllocating"
        :is-validating="isValidating"
        :allocation-in-progress="allocationInProgress"
        @import-loans="importLoans"
        @save="saveBatchLoans"
        @validate="validateBatchLoans"
        @cancel="clearErrors"
        @allocate="allocateBatch"
      />
    </div>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import ErrorMessages from "components/shared/error_messages";
import WindowService from "services/window_service";
import BuyersTab from "./buyers_tab";
import LoansTab from "./loans_tab";

const representBatchLoan = batchLoan => ({
  id: batchLoan.id,
  eligible_buyers: batchLoan.eligibleBuyers,
  ineligible_buyers: batchLoan.ineligibleBuyers,
  allocation_buyers: batchLoan.allocationBuyers,
  excluded_buyers: batchLoan.excludedBuyers,
  loan_id: batchLoan.loan.psId,
  reallocate: batchLoan.reallocate,
  destroy: batchLoan.destroy,
});

const representBatch = batch => ({
  id: batch.id,
  batch_loans: batch.batchLoans.map(representBatchLoan),
});

export default {
  name: "allocation-index",
  components: {
    BuyersTab,
    LoansTab,
    ErrorMessages,
  },
  data: function() {
    return {
      isLoading: false,
      isLoaded: false,
      isImporting: false,
      isSaving: false,
      isAllocating: false,
      isValidating: false,
      currentTab: "loans",
      buyers: [],
      batch: {
        batchLoans: [],
      },
      errors: [],
      routes: PSData.routes,
      allocationInProgress: PSData.allocationInProgress,
    };
  },
  computed: {
    showErrors() {
      return this.errors.length > 0;
    },
  },
  mounted: function() {
    this.getAllocationData();
  },
  methods: {
    switchTab(tab) {
      this.clearErrors();
      this.currentTab = tab;
    },

    isActiveTab(tab) {
      return this.currentTab === tab;
    },

    async getAllocationData() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await Promise.all([this.getBuyers(), this.getBatch()]);
      this.isLoading = false;
      this.isLoaded = true;
    },

    getBuyers() {
      const url = this.routes.manageAllocationsBuyers;

      return AjaxService.get(url).then(res => {
        this.buyers = res.buyerPrograms;
      });
    },

    saveBuyers(buyers, cb) {
      const url = this.routes.manageAllocationsBuyers;
      const buyersParams = buyers.map(buyer => buyer.toHash());
      return AjaxService.withNotifications({ onError: this.onError })
        .patchJSON(url, { buyer_programs: buyersParams })
        .then(res => {
          this.buyers = res.buyerPrograms;
          cb();
        });
    },

    async getBatch() {
      const url = this.routes.manageAllocationsPendingBatch;
      try {
        const res = await AjaxService.get(url);
        this.batch = res.batch;
      } catch (err) {
        console.log(err);
      }
    },

    async importLoans(cb) {
      if (this.isImporting) {
        return;
      }
      this.isImporting = true;
      const url = this.routes.wiredManageAllocationsLoans;
      try {
        const res = await AjaxService.get(url);
        const batchLoans = res.loans.map(loan => ({
          loan: loan,
        }));
        cb(batchLoans);
      } finally {
        this.isImporting = false;
      }
    },

    async saveBatchLoans(batchLoans, cb) {
      if (this.isSaving) {
        return;
      }
      this.isSaving = true;
      const batch = {
        ...this.batch,
        batchLoans: batchLoans,
      };
      try {
        const res = await this.upsertBatch(batch);
        this.batch = res.batch;
        this.clearErrors();
        cb();
      } catch (err) {
        console.log(err);
        this.errors = err.data.errors;
      } finally {
        this.isSaving = false;
      }
    },

    async validateBatchLoans(batchLoans, cb) {
      if (this.isValidating) {
        return;
      }
      this.isValidating = true;
      const batch = {
        ...this.batch,
        batchLoans: batchLoans,
      };
      try {
        const res = await this.validateBatch(batch);
        this.clearErrors();
        cb(res.batch.batchLoans);
      } catch (err) {
        cb(err.data.batch.batchLoans);
        this.errors = err.data.errors;
      } finally {
        this.isValidating = false;
      }
    },

    validateBatch(batch) {
      const url = this.routes.validateManageAllocationsPendingBatch;
      const batchParams = representBatch(batch);
      return AjaxService.withNotifications({ onSuccess: "Batch is validated" }).postJSON(
        url,
        batchParams
      );
    },

    upsertBatch(batch) {
      const url = this.routes.upsertManageAllocationsPendingBatch;
      const batchParams = representBatch(batch);
      return AjaxService.withNotifications().postJSON(url, batchParams);
    },

    async allocateBatch() {
      if (this.isAllocating) {
        return;
      }
      const url = this.routes.allocateManageAllocationsPendingBatch;
      this.isAllocating = true;
      try {
        this.clearErrors();
        this.allocationInProgress = true;
        await AjaxService.withNotifications({ onSuccess: "Batch allocated." }).postJSON(url);
        WindowService.location = `${this.routes.manageAllocations}/${this.batch.id}`;
      } catch (err) {
        this.errors = err.data.errors;
      } finally {
        this.isAllocating = false;
      }
    },

    onError({ data }) {
      return {
        message: data.error.join(". "),
        status: "error",
      };
    },

    clearErrors() {
      this.errors = [];
    },
  },
};
</script>
