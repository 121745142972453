<template>
  <div class="details-row row">
    <div class="col-12">
      <h4>Dates</h4>
    </div>
    <div class="col-6 border-row">
      <editable-field label="Paid to Date" :value="payoffStatement.paidToDate" :editing="false" />
    </div>
    <div v-if="isPeerstreetRepurchaseStatement" class="col-6 border-row">
      <editable-field
        label="Corrected Paid to Date"
        :value="payoffStatement.correctedPaidToDate"
        :editing="false"
      />
    </div>
    <div class="col-6">
      <editable-field
        label="Maturity Date"
        :value="payoffStatement.maturityDate"
        :editing="false"
      />
    </div>
    <div class="col-6">
      <editable-datepicker
        label="Default Date"
        :value="payoffStatement.defaultDate"
        :editing="false"
      />
    </div>
  </div>
</template>

<script>
import EditableField from "components/shared/editable_field.vue";
import EditableDatepicker from "components/shared/editable_datepicker";

export default {
  name: "payoff-statement-show-dates",
  props: ["payoffStatement"],
  components: {
    EditableField,
    EditableDatepicker,
  },
  computed: {
    isPeerstreetRepurchaseStatement() {
      return this.payoffStatement.statementType === "peerstreet_repurchase";
    },
  },
};
</script>
