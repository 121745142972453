import { PaymentStructure, LoanProduct, NoteOrigination, AccountType } from "../../enums";
import BaseScenario from "./base_scenario";

export default class ResidentialForRentScenario extends BaseScenario {
  loanProduct = LoanProduct.residentialRentalLongTerm.value;
  noteOrigination;
  propertyType;
  loanPurpose;
  zipCode;
  asIsValue;
  ficoScore;
  trackRecord;
  residencyStatus;
  currentlyLeased;
  monthlyRentalIncome;
  yearlyTaxesOwed;
  yearlyInsurancePremium;
  monthlyHoa;
  professionallyManaged;
  fipsCode;
  propertyState;
  loanAmount;
  ltv;
  convertedLtvRate;
  customRate;
  psLoanId;
  cbsaType;
  cbsaCode;
  lenderAccountType;

  _validationMap = {
    loanPurpose: () => {
      return this._isValuePresent(this.loanPurpose);
    },
    zipCode: () => {
      return this._isValuePresent(this.zipCode);
    },
    asIsValue: () => {
      return this._isValuePresent(this.asIsValue);
    },
    ficoScore: () => {
      return this._isValuePresent(this.ficoScore);
    },
    trackRecord: () => {
      return this.professionallyManaged === "yes" || this._isValuePresent(this.trackRecord);
    },
    residencyStatus: () => {
      return this._isValuePresent(this.residencyStatus);
    },
    currentlyLeased: () => {
      return this._isValuePresent(this.currentlyLeased);
    },
    monthlyRentalIncome: () => {
      return this._isValuePresent(this.monthlyRentalIncome);
    },
    yearlyTaxesOwed: () => {
      return this._isValuePresent(this.yearlyTaxesOwed);
    },
    yearlyInsurancePremium: () => {
      return this._isValuePresent(this.yearlyInsurancePremium);
    },
    professionallyManaged: () => {
      return this._isValuePresent(this.professionallyManaged);
    },
  };

  _isBroker() {
    return (
      this._isValuePresent(this.lenderAccountType) &&
      this.lenderAccountType === AccountType.broker.value
    );
  }

  calculateLoanAmount(ltv) {
    let loanAmount = null;

    if (this.hasValidProperties("asIsValue")) {
      loanAmount = parseFloat(((parseFloat(ltv) / 100) * parseFloat(this.asIsValue)).toFixed(2));
    }

    return loanAmount;
  }

  calculateLtv(loanAmount) {
    let ltv = null;

    if (this.hasValidProperties("asIsValue")) {
      ltv = parseFloat(((parseFloat(loanAmount) / parseFloat(this.asIsValue)) * 100).toFixed(3));
    }

    return ltv;
  }

  toHash() {
    return {
      program_type: this.loanProduct,
      loan_purpose: this.loanPurpose,
      ...(this._isBroker() && {
        funding_strategy: NoteOrigination.fundingIntoOrigination.value,
      }),
      as_is_valuation: parseFloat(this.asIsValue),
      yearly_taxes_owed: parseFloat(this.yearlyTaxesOwed),
      yearly_insurance_premium: parseFloat(this.yearlyInsurancePremium),
      payment_structure: PaymentStructure.amortizing.value,
      professionally_managed: this.professionallyManaged === "yes" ? true : false,
      ...(this._isValuePresent(this.medianHomePriceInZip) && {
        median_home_price_in_zip: parseFloat(this.medianHomePriceInZip),
      }),
      borrowers: [
        {
          fico_score: parseFloat(this.ficoScore),
          ...(this.professionallyManaged === "no" && {
            guarantor_track_record: this.trackRecord,
          }),
          residency: this.residencyStatus,
        },
      ],
      properties: [
        {
          ...(this._isValuePresent(this.propertyType) && {
            property_type: this.propertyType,
          }),
          property_leased: this.currentlyLeased === "yes" ? true : false,
          monthly_market_rent: parseFloat(this.monthlyRentalIncome),
          postal_code: this.zipCode,
          monthly_gross_rental_income: parseFloat(this.monthlyRentalIncome),
          ...(this.propertyState && {
            state: this.propertyState,
          }),
          ...(this.fipsCode && {
            fips_codes: [this.fipsCode],
          }),
          ...(this._isValuePresent(this.cbsaType) && {
            cbsa_type: this.cbsaType,
          }),
          ...(this._isValuePresent(this.cbsaCode) && {
            cbsa_code: this.cbsaCode,
          }),
          ...(this._isValuePresent(this.monthlyHoa) && {
            monthly_hoa_dues: parseFloat(this.monthlyHoa),
          }),
        },
      ],
      original_term: 360,
      ...(this._isValuePresent(this.lenderAccountType) && {
        lender_account_type: this.lenderAccountType,
      }),
    };
  }

  toLoanPayload() {
    return {
      program_type: this.loanProduct,
      ...(this._isBroker() && {
        lender_note_status: NoteOrigination.fundingIntoOrigination.value,
      }),
      loan_purpose: this.loanPurpose,
      ...(this._isValuePresent(this.propertyType) && {
        property_type: this.propertyType,
      }),
      zip: this.zipCode,
      draft_as_is_valuation: parseFloat(this.asIsValue),
      borrower_fico: parseFloat(this.ficoScore),
      professionally_managed: this.professionallyManaged === "yes" ? "true" : "false",
      ...(this.professionallyManaged === "no" && {
        borrower_guarantor_track_record: this.trackRecord,
      }),
      borrower_residency: this.residencyStatus,
      property_leased: this.currentlyLeased === "yes" ? "true" : "false",
      yearly_taxes_owed: parseFloat(this.yearlyTaxesOwed),
      yearly_premium: parseFloat(this.yearlyInsurancePremium),
      monthly_hoa_dues: parseFloat(this.monthlyHoa),
      payment_structure: PaymentStructure.amortizing.value,
    };
  }
}
