<template>
  <div class="lender-legal-entities-list">
    <h3 class="lender-entities-header clearfix">
      <span>Entities ({{ lenderEntitiesCount }})</span>
      <button class="btn btn-action-cta-sm float-right" @click="openNewEntityModal" v-if="canAddEntities">
        + New Entity
      </button>
    </h3>
    <div class="entities-list">
      <div class="item" v-for="(entity, index) in lenderEntities" :key="index">
        <a class="item-link" :href="entityLink(entity)">{{ entity.fullName }}</a>
      </div>
    </div>
    <entity-form-modal
      :type="formType"
      :show="isNewEntityModalOpened"
      :entity="entityToSave"
      @close="closeNewEntityModal"
      @lender-legal-entity-saved="openEntity"
    />
  </div>
</template>

<script>
import LegalEntity from "models/lender_legal_entities/entity";
import EntityFormModal, { FORM_TYPES } from "./entity/form_modal.vue";
import Location from "services/location_service";

export default Vue.component("lender-legal-entities-list", {
  components: { EntityFormModal },
  data: function() {
    return {
      formType: FORM_TYPES.create,
      isNewEntityModalOpened: false,
      lenderEntities: PSData.lenderLegalEntities.map(entity => new LegalEntity(entity)),
      entityToSave: new LegalEntity({}, PSData.lenderLegalEntityOptions),
      routes: PSData.routes || {},
      policy: PSData.policy,
    };
  },
  computed: {
    canAddEntities() {
      return this.policy.canManage;
    },
    lenderEntitiesCount() {
      return this.lenderEntities.length;
    },
  },
  methods: {
    entityLink(entity) {
      return `${this.routes.lenderLegalEntity}/${entity.id}`;
    },
    openNewEntityModal() {
      this.isNewEntityModalOpened = true;
    },
    closeNewEntityModal() {
      this.isNewEntityModalOpened = false;
      this.entityToSave.reset();
    },
    openEntity(entity) {
      Location.href = this.entityLink(entity);
    },
  },
});
</script>
