import AjaxService, { ERROR_MESSAGE, ERROR_STATUS } from "services/ajax_service";
import objectToSnakeCase from "utils/object_to_snake_case";

export default {
  methods: {
    async executeRequest(requestFunction) {
      this.isLoading = true;
      try {
        await requestFunction();
        this.errors = {};
      } catch (error) {
        const { data } = error;
        if (data && data.errors) {
          this.errors = data.errors;
        } else {
          Bugsnag.notify(error);
        }
        AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
      } finally {
        this.isLoading = false;
      }
    },
    async saveChargeOff(chargeOffForm) {
      return this.executeRequest(async () => {
        const url = PSData.routes.createChargeOff;
        const params = objectToSnakeCase(chargeOffForm);
        const response = await AjaxService.patch(url, params);
        this.handleResponse(response);
      });
    },
    async processChargeOff(chargeOffForm) {
      return this.executeRequest(async () => {
        const url = PSData.routes.processChargeOff;
        const params = objectToSnakeCase(chargeOffForm);
        const response = await AjaxService.patch(url, params);
        this.handleResponse(response);
      });
    },
    async reverseChargeOff(chargeOffForm) {
      return this.executeRequest(async () => {
        const url = PSData.routes.reverseChargeOff;
        const params = objectToSnakeCase(chargeOffForm);
        const response = await AjaxService.patch(url, params);
        this.handleResponse(response);
      });
    },
  },
};
