import { Client } from "peerstreet-hermes-js";
import ZipReportQuery from "./midas/zip_report_query.graphql";
import MsaReportQuery from "./midas/msa_report_query.graphql";
import ZipMedianHomePriceQuery from "./midas/zip_median_home_price_query.graphql";
import introspectionQueryResultData from "./midas/fragmentTypes.json";

const isValidDelayedResponse = response => {
  return (
    typeof response === "object" &&
    "data" in response &&
    "propertyReport" in response.data &&
    "id" in response.data.propertyReport &&
    "status" in response.data.propertyReport &&
    response.data.propertyReport.status === "PENDING"
  );
};

const pusherFetch = (url, options) => {
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(response => {
        response
          .clone()
          .json()
          .then(body => {
            if (isValidDelayedResponse(body)) {
              let id = body.data.propertyReport.id;

              PS.Services.PusherService.listenOnce(id, "valueReportUpdated").then(() => {
                fetch(url, options).then(response => {
                  resolve(response);
                });
              });
            } else {
              resolve(response);
            }
          });
      })
      .catch(reject);
  });
};

class MidasService {
  static createClient(timeout = 10000, fetch) {
    return new Client({
      uri: PSData.global.midasUrl,
      timeout: timeout,
      fetch: fetch,
      csrfToken: $.rails.csrfToken(),
      headersStorage: PS.Services.LocalStorageService.storage,
      introspectionQueryResultData: introspectionQueryResultData,
      requestIdGenerator: () => PSData.requestId,
    });
  }

  static get delayedClient() {
    return this.createClient(60000, pusherFetch);
  }

  static get client() {
    return this.createClient();
  }

  static zipReport(zip) {
    return this.client.query({
      query: ZipReportQuery,
      variables: {
        zip: zip,
      },
    });
  }

  static msaReport(geoType, geoId) {
    return this.client.query({
      query: MsaReportQuery,
      variables: {
        geoType: geoType.toUpperCase(),
        geoId: geoId,
      },
    });
  }

  static zipMedianHomePrice(zip) {
    return this.client.query({
      query: ZipMedianHomePriceQuery,
      variables: {
        zip: zip,
      },
    });
  }
}

export default MidasService;
