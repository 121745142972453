<template>
  <view-panel
    :class="{ 'view-panel-edit': editMode }"
    class="state-loan-document-panel"
  >
    <template slot="header">
      <editable-view-panel-header :title="document.documentTypeLabel" :on-edit="onEdit" :can-edit="canEdit" />
    </template>
    <div v-if="editMode">
      <select v-model="document.status" class="loan-document-satus form-control">
        <option disabled value="">
          Select Status
        </option>
        <option v-for="(label, value) in statusesOptions" :key="value" :value="value">
          {{ label }}
        </option>
      </select>
      <textarea
        class="form-control loan-document-notes-textarea"
        v-model="document.notes"
        placeholder="Notes"
      />
      <div class="loan-document-actions">
        <div class="btn btn-cancel-cta" @click="onCancel">
          Cancel
        </div>
        <div class="btn btn-action-cta" :disabled="saveDisabled" @click="onSave">
          Save
        </div>
      </div>
    </div>
    <div v-else>
      <status class="loan-document-satus" :value="document.status" :label="document.statusLabel" />
      <div v-if="document.notes" class="loan-document-notes">{{ document.notes }}</div>
    </div>
  </view-panel>
</template>
<script>
import AjaxService from "services/ajax_service";
import ViewPanel from "components/view_panel.vue";
import Status from "./status.vue";
import EditableViewPanelHeader from "components/lender_legal_entities/shared/editable_view_panel_header";

export default {
  name: "lender-legal-entity-loan-documents-panel",
  components: {
    Status,
    ViewPanel,
    EditableViewPanelHeader,
  },
  props: {
    document: {
      required: true,
      type: Object,
    },
    statusesOptions: {
      required: true,
      type: Object,
    },
    policy: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      editMode: false,
      routes: PSData.routes || {},
      saveDisabled: false,
    };
  },
  computed: {
    stateLoanDocumentsLink: function() {
      return `${this.routes.stateLoanDocuments}/${this.document.id}`;
    },
    canEdit() {
      return this.policy.canManage;
    },
  },
  methods: {
    onEdit: function() {
      this.editMode = true;
    },
    onCancel: function() {
      this.document.reset();
      this.editMode = false;
    },
    onSave: function() {
      this.saveDisabled = true;

      const messages = {
        onError: ({ response }) => {
          if (response.status === 403) {
            return { message: "Unauthorized", status: "error" };
          } else {
            return { message: "Something went wrong", status: "error" };
          }
        },
      };

      AjaxService.withNotifications(messages)
        .patch(this.stateLoanDocumentsLink, this.document.objectToSave)
        .then(data => {
          this.document.update(data);
          this.editMode = false;
        })
        .finally(() => {
          this.saveDisabled = false;
        });
    },
  },
};
</script>
