<template>
  <div class="document-report-content">
    <div v-if="loading" class="ps-loader">
      &nbsp;
    </div>
    <template v-if="report">
      <h1>
        Exceptions Report
        <a v-if="canEdit" class="btn btn-action-cta btn-save" @click="save">
          <i class="fas fa-download" />
          Save to Master Loan Detail
        </a>
      </h1>
      <div v-for="(error, key) in errors" :key="key" class="report-errors error-notification">
        <ul>
          <li>{{ error }}</li>
        </ul>
      </div>
      <div class="row">
        <div class="col-8">
          <template v-for="(element, index) in elements">
            <diligence-report-element :key="'element-' + index" :element="element" />
          </template>
        </div>
      </div>
      <div class="report-footer">
        <a v-if="canEdit" class="btn btn-action-cta btn-save" @click="save">
          <i class="fas fa-download" />
          Save to Master Loan Detail
        </a>
      </div>
    </template>
  </div>
</template>

<script>
const Commands = PS.Models.Diligence.Commands;

export default Vue.component("diligence-report", {
  props: {
    loan: Object,
  },
  data: function() {
    return {
      report: undefined,
      loading: false,
      corrections: {},
      errors: [],
      bus: PS.Services.MessageBusService.diligenceBus,
      rulebook: undefined,
      canEdit: undefined,
    };
  },
  computed: {
    elements() {
      let fields = {};
      let elements = [];
      this.report.sections.forEach(section => {
        section.elements.forEach(element => {
          if (element.isFieldset()) {
            element.elements.forEach(subElement => {
              this.filterElement(fields, elements, subElement);
            });
          } else {
            this.filterElement(fields, elements, element);
          }
        });
      });
      return elements;
    },
  },
  created: function() {
    this.rulebook = new PS.Models.Diligence.ReportRule(this);
    this.canEdit = new PS.Policies.EditLoanFormPolicy(PSData.loanPolicy).canEdit();
    this.bus.attachRulebook(this.rulebook);
  },
  beforeDestroy: function() {
    this.bus.detachRulebook(this.rulebook);
  },
  methods: {
    filterElement(fields, elements, element) {
      if (element.matched || !element.group) {
        return;
      }
      if (!fields[element.group]) {
        fields[element.group] = true;
        elements.push(element);
      }
    },
    save: function() {
      new Commands.Report.RequestUpdateMLD(new PS.Models.Event(), {}).execute();
    },
  },
});
</script>
