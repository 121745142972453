<template>
  <with-errors :on="field" :errors="errors" class="form-group" :class="inline ? 'form-group-inline' : ''">
    <label>{{ label }}</label>
    <div class="input-group">
      <slot name="prepend" />
      <input
        class="form-control"
        :value="value"
        :name="field"
        :disabled="disabled"
        v-mask:[mask]=""
        @input="$emit('input', $event.target.value)"
      />
      <slot name="append" />
    </div>
  </with-errors>
</template>

<script>
import WithErrors from "components/shared/with_errors";

export default {
  name: "form-input",
  components: {
    WithErrors,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    field: {
      type: String,
      required: true,
    },
    mask: {
      type: String,
      default: "",
    },
    errors: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
