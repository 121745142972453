<template>
  <div class="loan-requests-history">
    <div class="loan-requests" v-if="requestLoan">
      <requests-dropdown :loan="requestLoan"
                         :is-lender="allowRequests"
                         positionClass="dropdown-menu-left"
                         @show-modification-request-modal="openModificationRequestModal"
                         @show-payoff-request-modal="openPayoffRequestModal"/>
    </div>

    <table class="table requests-history">
      <thead>
      <tr>
        <th>Request Date</th>
        <th>Request Type</th>
        <th>Requester</th>
        <th>Details</th>
      </tr>
      </thead>

      <tbody v-if="isLoading">
      <tr>
        <td colspan="4">Loading...</td>
      </tr>
      </tbody>

      <tbody v-if="isLoaded">
      <tr v-for="request in sortedRequests" :key="request.createdAtTime" class="request-details">
        <td class="requests-history-date">{{request.creationDate}}</td>
        <td class="requests-history-type">{{request.requestTypeText}}</td>
        <td class="requests-history-requester">
          <span>{{request.requester.name}}</span>
          <span>{{request.requester.email}}</span>
        </td>
        <td class="requests-history-notes">
          <payoff-details v-if="request.requestType === RequestType.Payoff"
                          :request="request"
                          :loan="requestLoan"
          />
          <modification-details v-if="request.requestType === RequestType.Modification"
                          :request="request"
                          :loan="requestLoan"
          />
        </td>
      </tr>
      </tbody>
    </table>

    <ul class="help-center-links">
      <li v-for="link in helpLinks">
        <a rel="noreferrer noopener" target="_blank" :href="link.url">
          {{link.name}}
        </a>
      </li>
    </ul>

    <payoff-request-modal
        :show="showPayoffRequestModal"
        :loan="requestLoan"
        :is-lender="allowRequests"
        @close="closePayoffRequestModal"
        @payoff-request-sent="updatePayoffInfo"
    />

    <modification-request-modal
        :show="showModificationRequestModal"
        :loan="requestLoan"
        :is-lender="allowRequests"
        @close="closeModificationRequestModal"
        @modification-request-sent="updateModificationInfo"
    />
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import { RequestType } from "models/loan_requests";

import RequestsDropdown from "components/active_portfolio/requests_dropdown";
import PayoffRequestModal from "components/active_portfolio/payoff_request_modal";
import ModificationRequestModal from "components/active_portfolio/modification_request_modal";
import LastRequestPopover from "components/active_portfolio/last_request_popover";
import PayoffDetails from "components/loans/loan_request_details/payoff";
import ModificationDetails from "components/loans/loan_request_details/modification";

const STATES = { loading: "loading", loaded: "loaded" };

const HELP_LINKS = [
  {
    name: "Payoff Request Process",
    url: "https://peerstreet.zendesk.com/hc/en-us/articles/360020292091",
  },
  {
    name: "Construction Draw Request Process",
    url: "https://drive.google.com/file/d/1jgykg6mOfvMI45-p9UHQXLXhtg5xe7Je/view?usp=sharing",
  },
  {
    name: "Refinance Request Process",
    url: "https://peerstreet.zendesk.com/hc/en-us/articles/360038228832",
  },
];

export default {
  name: "loan-requests-history",
  components: {
    RequestsDropdown,
    PayoffRequestModal,
    ModificationRequestModal,
    LastRequestPopover,
    PayoffDetails,
    ModificationDetails,
  },
  props: {
    requests: {
      type: Array,
      default: () => [],
    },
    loan: {
      type: Object,
      required: false,
    },
    allowRequests: {
      type: Boolean,
      required: true,
    },
    asyncLoading: {
      type: Boolean,
      required: true,
    },
    requestsPath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loanRequests: this.requests,
      requestLoan: this.loan,
      showPayoffRequestModal: false,
      showModificationRequestModal: false,
      state: this.asyncLoading ? STATES.loading : STATES.loaded,
      helpLinks: HELP_LINKS,
      RequestType: RequestType,
    };
  },
  mounted() {
    if (this.asyncLoading) {
      AjaxService.get(this.requestsPath).then(data => {
        this.loanRequests = data.requests;
        this.requestLoan = data.loan;
        this.state = STATES.loaded;
      });
    }
  },
  computed: {
    sortedRequests() {
      return this.loanRequests.sort((r1, r2) => {
        return r1.createdAtTime - r2.createdAtTime;
      });
    },
    isLoading() {
      return this.state === STATES.loading;
    },
    isLoaded() {
      return this.state === STATES.loaded;
    },
  },
  methods: {
    openPayoffRequestModal() {
      this.showPayoffRequestModal = true;
    },
    closePayoffRequestModal() {
      this.showPayoffRequestModal = false;
    },
    openModificationRequestModal() {
      this.showModificationRequestModal = true;
    },
    closeModificationRequestModal() {
      this.showModificationRequestModal = false;
    },
    updatePayoffInfo(_, request) {
      this.requestLoan.lastPayoffRequest = request;
      this.loanRequests.push(request);
    },
    updateModificationInfo(_, request) {
      this.requestLoan.lastModificationRequest = request;
      this.loanRequests.push(request);
    },
  },
};
</script>
