import WindowService from "services/window_service";

export default {
  methods: {
    openTab: url => {
      const isTextHighlighting =
        window
          .getSelection()
          .toString()
          .trim() !== "";
      if (!isTextHighlighting) {
        WindowService.open(url, "_blank");
      }
    },
  },
};
