const Event = PS.Models.Event;

class RoleEvent extends Event {
  prepareData(options) {
    this.role = options.role;
  }
}

class RoleUpdated extends RoleEvent {}

class ResetChangesRequested extends Event {}
class SaveChangesRequested extends Event {}
class UsersSetupRequested extends Event {}
class RolesSetupRequested extends Event {}
class DataSetupRequested extends Event {}

PS.Models.Permission.Events = {
  DataSetupRequested: DataSetupRequested,
  ResetChangesRequested: ResetChangesRequested,
  RoleUpdated: RoleUpdated,
  SaveChangesRequested: SaveChangesRequested,
  RolesSetupRequested: RolesSetupRequested,
  UsersSetupRequested: UsersSetupRequested,
};
