import AjaxService from "services/ajax_service";

const ROUTES = {
  COUNTIES: "/loan_scenarios/counties",
};

export default class CountiesClient {
  constructor() {
    this.abortController = new AbortController();
  }

  async fetchCountyByZipCode(zipCode) {
    this.abortController.abort();
    this.abortController = new AbortController();

    const urlParams = new URLSearchParams({ zip: zipCode });
    const url = [ROUTES.COUNTIES, "?", urlParams.toString()].join("");
    let response;

    try {
      response = await AjaxService.getJSON(url, {}, this.abortController.signal);
    } catch (e) {
      Bugsnag.notify(e);
    } finally {
      return response || {};
    }
  }
}
