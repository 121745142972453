$(function() {
  class PasswordToggler {
    constructor(element, passwordField) {
      this.element = element;
      this.passwordField = passwordField;

      this.element.on("click", e => {
        e.preventDefault();

        this.toggle();
      });
    }

    toggle() {
      if (this.passwordField.attr("type") == "password") {
        this.show();
      } else {
        this.hide();
      }
    }

    show() {
      this.passwordField.attr("type", "text");
      this.element.text("hide");
    }

    hide() {
      this.passwordField.attr("type", "password");
      this.element.text("show");
    }
  }

  new PasswordToggler($(".toggle-password"), $(".password-field"));
});
