$(() => {
  const MarketData = PS.Models.MarketData;
  const Event = PS.Models.Event;
  const LocationService = PS.Services.LocationService;

  new Vue({
    el: document.querySelector(".v-market-data"),
    data: function() {
      return {
        bus: PS.Services.MessageBusService.lenderBus,
        marketDataRulebook: undefined,

        predefinedZip: undefined,
        zip: undefined,
        source: undefined,

        errorMessage: undefined,
      };
    },
    mounted: function() {
      this.marketDataRulebook = new PS.Models.MarketData.MarketDataRule(this);
      this.bus.attachRulebook(this.marketDataRulebook);

      const event = new Event();
      new PS.Models.MarketData.Commands.SetPredefinedZip(event, this).execute();
      new PS.Models.MarketData.Commands.RequestCheckForPredefinedSearch(event, {}).execute();
    },
    beforeDestroy: function() {
      this.bus.detachRulebook(this.marketDataRulebook);
    },
    computed: {
      zipPresent() {
        return this.zip !== undefined && this.zip !== "";
      },
    },
    methods: {
      zipSearch: function(zip) {
        const event = new Event({ zip: zip, source: LocationService.pathname });

        new PS.Models.MarketData.Commands.RequestLenderMarketDataLoading(event, {}).execute();
      },
    },
  });
});
