<template>
  <with-errors :on="field" :errors="errors" class="form-group form-group-inline">
    <label>{{ label }}</label>
    <input
      v-datepicker
      class="form-control"
      :value="value"
      :name="field"
      :disabled="disabled"
      v-mask:date
      @input="$emit('input', $event.target.value)"
    />
  </with-errors>
</template>

<script>
import WithErrors from "components/shared/with_errors";

export default {
  name: "form-input",
  components: {
    WithErrors,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    field: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
