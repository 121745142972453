<template>
  <modal-form
    :confirm="showConfirmation"
    :save-disabled="saveDisabled"
    :show="show"
    :title="title"
    class="lender-legal-entity-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <form>
      <div class="row no-gutters" v-for="doc in documents" :key="doc.documentType">
        <with-errors :on="doc.documentType" :errors="errors" class="col-4 form-group status-field">
          <label>{{ doc.formStatusLabel }} <span>Status</span></label>
          <select class="form-control" v-model="doc.status">
            <option disabled value="">Select Status</option>
            <option v-for="(label, value) in statusesOptions" :key="value" :value="value">{{ label }}</option>
          </select>
        </with-errors>
        <with-errors on="state" :errors="errors" class="col-8 form-group notes-field">
          <label>Notes</label>
          <textarea
            class="form-control"
            v-model="doc.notes"
            name="notes"
            placeholder="Notes" />
        </with-errors>
      </div>
    </form>
  </modal-form>
</template>

<script>
import AjaxService from "services/ajax_service";
import ModalForm from "components/modal_form.vue";
import WithErrors from "components/shared/with_errors";
import MessageBusService from "services/message_bus_service";

export default {
  name: "documents-form-modal",
  components: {
    ModalForm,
    WithErrors,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    documents: {
      required: true,
      type: Array,
    },
    show: {
      required: true,
      type: Boolean,
    },
    statusesOptions: {
      required: true,
      type: Object,
    },
    route: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      errors: {},
      saveDisabled: false,
      bus: MessageBusService.entityBus,
    };
  },
  computed: {
    docsToSave() {
      const docsToSave = this.documents.map(doc => doc.objectToSave);

      return { docs: docsToSave };
    },
    showConfirmation() {
      return this.documents.some(doc => doc.changed);
    },
  },
  methods: {
    onClose() {
      this.errors = {};
      this.$emit("close");
    },
    onSave() {
      this.save();
    },
    save() {
      const messages = { onSuccess: "Lender legal entity docs are saved", onError: this.onError };
      this.saveDisabled = true;

      return AjaxService.withNotifications(messages)
        .postJSON(this.route, this.docsToSave)
        .then(data => {
          this.$emit("lender-legal-entity-docs-updated", data);
          this.bus.broadcast("entity-updated");
          this.onClose();
        })
        .finally(() => {
          this.saveDisabled = false;
        });
    },
    onError({ response, data }) {
      if (response.status === 403) {
        return { message: "Unauthorized", status: "error" };
      } else {
        this.errors = data.errors;
        return null;
      }
    },
  },
};
</script>
