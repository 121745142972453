<template>
  <base-table
    :data="tasks"
    :loading="loading"
    backend-sorting
    class="task-portfolio-table"
    @sort="onSort"
    @click="(row) => openTab(taskUrl(row))"
  >
    <base-column
      field="psLoanId"
    >
      <template v-slot:header>
        <div>PS Loan ID/</div><div>Servicer ID</div>
      </template>
      <template v-slot="props">
        <div>{{ props.row.psLoanId }}</div>
        <div @click.stop="">
          <servicer-loan-info
            :id="props.row.servicerLoanId"
            :url="props.row.servicerLoanUrl"
          />
        </div>
      </template>
    </base-column>
    <base-column
      v-slot="props"
      field="loanTitle"
      label="Loan"
      width="300"
      sortable
    >
      <div class="loan-details">
        {{ loanAddress(props.row.loanTitle) }}
      </div>
      <div class="loan-details">
        {{ loanStateDetails(props.row.loanTitle) }}
      </div>
    </base-column>
    <base-column
      v-slot="props"
      field="taskLabel"
      label="Task"
      sortable
    >
      {{ props.row.taskLabel }}
    </base-column>
    <base-column
      v-slot="props"
      field="taskDueDate"
      label="Due"
      width="90"
      sortable
    >
      <span :class="{ overdue: isOverdue(props.row.taskDueDate) }">
        {{ props.row.taskDueDate | formatDate("MMM D", "MM/DD/YYYY") }}
      </span>
    </base-column>
    <base-column
      v-slot="props"
      field="workflowLabel"
      label="Workflow"
      width="190"
      sortable
    >
      {{ props.row.workflowLabel }}
    </base-column>
    <base-column
      v-slot="props"
      field="openWorkflowCount"
      label="Open Workflows"
      width="110"
      numeric
    >
      <base-tooltip
        type="is-white"
      >
        <template v-slot:content>
          <div v-for="(w, index) in sortLabels(props.row.openWorkflows)" :key="`${w}${index}`">
            {{ w }}
          </div>
        </template>
        {{ props.row.openWorkflowCount }}
      </base-tooltip>
    </base-column>
    <base-column
      v-slot="props"
      field="unpaidPrincipalBalance"
      label="Unpaid Principal"
      numeric
      sortable
    >
      {{ props.row.unpaidPrincipalBalance | money }}
    </base-column>
    <base-column
      v-slot="props"
      field="borrowerPaymentDaysPastDue"
      label="Days Past Due"
      numeric
      sortable
      width="95"
    >
      {{ props.row.borrowerPaymentDaysPastDue }}
    </base-column>
    <base-column
      v-slot="props"
      field="loanOwner"
      label="Owner"
      sortable
      width="140"
    >
      {{ props.row.loanOwner }}
    </base-column>
    <template v-slot:empty>
      No tasks
    </template>
  </base-table>
</template>
<script>
import moment from "moment";
import sortBy from "lodash/sortBy";
import ServicerLoanInfo from "../components/servicer_loan_info.vue";
import OpenTabMixin from "mixins/open_tab_mixin";

export default {
  components: {
    ServicerLoanInfo,
  },
  mixins: [OpenTabMixin],
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    loanAddress(loanTitle) {
      const addressComponents = loanTitle.split(", ");
      return addressComponents[0];
    },
    loanStateDetails(loanTitle) {
      const addressComponents = loanTitle.split(", ");
      return addressComponents.slice(1).join(", ");
    },
    isOverdue(dueDate) {
      const dueDateMoment = moment(dueDate, "MM-DD-YYYY");
      return dueDateMoment.isBefore(moment().startOf("day"));
    },
    onSort(field, order) {
      this.$emit("sort", { field, order });
    },
    sortLabels(workflows) {
      return sortBy(workflows);
    },
    taskUrl(row) {
      return `/manage/loans/${row.psLoanId}/asset_management?view=tasks&view_workflow_id=${
        row.workflowId
      }`;
    },
  },
};
</script>
<style scoped>
.loan-details {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 260px;
}
.overdue {
  color: var(--color-red);
}

>>> .tooltip-content {
  text-align: left;
  font-weight: 600;
}
</style>

<style>
.task-portfolio-table tbody tr {
  cursor: pointer;
}
</style>
