<template>
  <clipboard-copy
    :value="value"
    class="copy-to-clipboard"
    :title="title"
    data-toggle="tooltip"
    data-placement="bottom"
    data-trigger="hover"
  >
    <slot></slot>
    <div class="copy-to-clipboard-icon" :class="{ 'highlight': highlight }">
      <i class="copy-icon" :class="copyIconClass" />
      <i class="check-icon fas fa-check" />
    </div>
  </clipboard-copy>
</template>

<script>
const HIGHLIGHT_DELAY = 1000;

export default {
  name: "copy-to-clipboard",
  props: {
    value: {
      required: true,
      type: String,
    },
    title: {
      type: String,
      default: "Copy to clipboard",
    },
    copyIconClass: {
      type: String,
      default: "far fa-copy",
    },
  },
  data() {
    return {
      highlight: false,
    };
  },
  mounted() {
    document.addEventListener("copy", this.onCopy);
    $(this.$el).tooltip();
  },
  beforeDestroy() {
    document.removeEventListener("copy", this.onCopy);
    $(this.$el).tooltip("destroy");
  },
  methods: {
    onCopy(event) {
      if (event.target === this.$el) {
        this.beginHighlight();
        setTimeout(this.endHighlight, HIGHLIGHT_DELAY);
      }
    },
    beginHighlight() {
      this.highlight = true;
    },
    endHighlight() {
      this.highlight = false;
    },
  },
};
</script>
