<template>
  <li
      class="tag"
      :class="{ 'tag--active': active, 'tag--editable': editable }"
      @click="handleClick"
    >
      {{ name }}
    <span v-if="activeAndEditable" class="fas fa-times"></span>
  </li>
</template>

<script>
export default {
  name: "tag",
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleClick() {
      if (this.editable) {
        this.$emit("update", { name });
      }
    },
  },
  computed: {
    activeAndEditable() {
      return this.active && this.editable;
    },
  },
};
</script>
