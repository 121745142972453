function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"provider",attrs:{"name":_vm.label,"rules":_vm.rules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var rest$1 = objectWithoutProperties( ref, ["errors", "valid"] );
var rest = rest$1;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('select2',{staticClass:"form-control",attrs:{"name":_vm.name,"custom-options":_vm.customOptions(valid),"options":{width: _vm.width, data: _vm.options},"disabled":_vm.disabled},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}}),_vm._v(" "),(valid === false)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n      "+_vm._s(errors.map(function (ref) {
	var msg = ref.msg;

	return msg;
}).join(", "))+"\n    ")]):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }