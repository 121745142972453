<template>
  <div class="documents-modal">
    <div id="documents-attachment-modal" class="modal fade" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4>
              <span>Attach from Documents</span>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </h4>
          </div>
          <div class="modal-body">
            <div
              v-for="checkbox in documentCheckboxes"
              :key="checkbox.document.id"
              class="checkbox-control"
            >
              <input
                :id="checkbox.document.id"
                v-model="selectedDocuments"
                type="checkbox"
                :value="checkbox.document.id"
              />
              <label :for="checkbox.document.id">
                <span class="control-toggle"></span>
                {{ checkbox.label }}
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-cancel-cta btn-link" data-dismiss="modal">
              Cancel
            </button>
            <button class="btn btn-action-cta" @click="updateTask" data-dismiss="modal">
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AttachTaskToDocument,
  DetachTaskFromDocument,
} from "models/loan/commands/document_commands";
import { TaskAttachmentChanged } from "models/loan/events/document_events";
import difference from "lodash/difference";
import Documents from "collections/documents";

export default {
  name: "documents-attachment-modal",
  props: {
    documents: Array,
  },
  data() {
    return {
      taskId: undefined,
      selectedDocuments: [],
      originalSelectedDocuments: [],
    };
  },
  mounted() {
    $("#documents-attachment-modal").on("show.bs.modal", event => {
      this.taskId = $(event.relatedTarget).data("task-id");
      this.selectedDocuments = this.detectSelectedDocuments();
      this.originalSelectedDocuments = [...this.selectedDocuments];
    });
  },
  computed: {
    documentCheckboxes() {
      return this.orderedDocuments.map(document => {
        const checked = document.taskIds.includes(this.taskId);
        return {
          document: document,
          label: document.fullName,
          checked: checked,
        };
      });
    },
    orderedDocuments() {
      return new Documents(this.filteredDocuments, []).orderedByNameAndDate();
    },
    filteredDocuments() {
      return this.documents.filter(document => {
        return document.visibility === "public" || document.taskIds.includes(this.taskId);
      });
    },
    documentsHash() {
      const hash = {};
      this.filteredDocuments.forEach(document => {
        hash[document.id] = document;
      });

      return hash;
    },
  },
  methods: {
    detectSelectedDocuments() {
      const selectedDocuments = [];
      this.filteredDocuments.forEach(document => {
        const checked = document.taskIds.includes(this.taskId);
        if (checked) {
          selectedDocuments.push(document.id);
        }
      });

      return selectedDocuments;
    },
    updateTask() {
      const deletedItems = difference(this.originalSelectedDocuments, this.selectedDocuments);
      const createdItems = difference(this.selectedDocuments, this.originalSelectedDocuments);

      deletedItems.forEach(id => {
        const document = this.documentsHash[id];
        const taskAttachment = document.loanTaskAttachments.find(taskAttachment => {
          return (
            taskAttachment.uploadedFileId === document.id &&
            taskAttachment.loanTaskId === this.taskId
          );
        });

        if (document && taskAttachment) {
          new DetachTaskFromDocument(
            new TaskAttachmentChanged({
              document,
              taskAttachmentId: taskAttachment.id,
            })
          ).execute();
        }
      });

      createdItems.forEach(id => {
        const document = this.documentsHash[id];
        new AttachTaskToDocument(
          new TaskAttachmentChanged({
            document,
            taskId: this.taskId,
          })
        ).execute();
      });
    },
  },
};
</script>
