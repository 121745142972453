<template>
  <div class="withdrawn-metrics-container">
    <div class="metric-title">
      {{ title }}
    </div>
    <div class="metric-subtitle">
      Funding Rates
    </div>
    <category-bar-chart :chart-options="chartOptions" :column-data="columnData" />
    <div v-for="(metric, index) in metrics" :key="index" class="inline-metric">
      <div>
        <span>{{ metric.metricLabel }} Funded</span>
        <i v-popover class="fas fa-question-circle form-tooltip"/>
        <span class="popover-content hidden" v-html="metric.description"/>
      </div>
      <div class="metrics">
        <span :style="{ color: metric.fontColor }" class="conversion-rate">{{ metric.conversionRate }}</span>
        <span> - {{ metric.purchasedLoansCount }} of {{ metric.totalLoansCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "d3";
import CategoryBarChart from "components/charts/category_bar_chart";

const sizeHeight = 175;

const CATEGORIES = {
  submitted: {
    metricLabel: "Submitted Loans",
    label: "Submitted Loans",
    graphColor: "#319aff",
    fontColor: "#297acc",
  },
  qualified: {
    metricLabel: "Qualified Loans",
    label: "Qualified Loans Only",
    graphColor: "#66cb33",
    fontColor: "#52a329",
  },
};

export default {
  components: {
    CategoryBarChart,
  },
  props: {
    title: String,
    chartData: Object,
    isLoading: Boolean,
  },
  data() {
    return {
      metrics: this.prepareMetrics(),
      columnData: this.prepareChart(),
      chartOptions: {
        showLegend: false,
        axisYTickFormat: format(",.2p"),
        sizeHeight: sizeHeight,
        categories: this.prepareCategories(),
        appendLabel: "Funded",
      },
    };
  },
  watch: {
    chartData() {
      this.columnData = this.prepareChart();
      this.metrics = this.prepareMetrics();
    },
  },
  methods: {
    prepareChart() {
      const data = this.prepareColumns();

      return {
        columns: data,
        colors: this.prepareColors(),
        allValuesZero: this.prepareAllValuesZero(),
        isLoading: true,
      };
    },
    prepareColumns() {
      const data = Object.keys(CATEGORIES).map(key => [this.chartData[key].conversionRate]);

      return [["rates", ...data]];
    },
    prepareColors() {
      return Object.keys(CATEGORIES).map(category => CATEGORIES[category].graphColor);
    },
    prepareAllValuesZero() {
      const data = Object.keys(CATEGORIES).map(key => this.chartData[key].conversionRate);

      const total = data.reduce((mem, value) => {
        mem + (parseFloat(value) || 0);
        return mem;
      });

      return total <= 0;
    },
    prepareMetrics() {
      const categories = CATEGORIES;
      const chartData = this.chartData;

      return Object.keys(categories).reduce((mem, key) => {
        mem[key] = {};
        mem[key]["fontColor"] = categories[key].fontColor;
        mem[key]["label"] = categories[key].label;
        mem[key]["metricLabel"] = categories[key].metricLabel;
        mem[key]["conversionRate"] = format(",.0%")(chartData[key].conversionRate);
        mem[key]["purchasedLoansCount"] = chartData[key].purchasedLoansCount;
        mem[key]["totalLoansCount"] = chartData[key].totalLoansCount;
        mem[key]["description"] = chartData[key].description;
        return mem;
      }, {});
    },
    prepareCategories() {
      return Object.keys(CATEGORIES).map(category => CATEGORIES[category].label);
    },
    tooltipText(uw_status, program_type) {},
  },
};
</script>
