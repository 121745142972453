<template>
  <button
    v-if="$options.canDistribute"
    class="btn btn-primary"
    :disabled="disabled"
    @click="handleDistribute"
  >
    Distribute {{ count }} {{ transactionCopy }}
  </button>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { distributing: false };
  },
  computed: {
    disabled() {
      return this.count < 1;
    },
    count() {
      return this.items.length;
    },
    singular() {
      return this.count == 1;
    },
    transactionCopy() {
      return this.singular ? "Transaction" : "Transactions";
    },
  },
  methods: {
    handleDistribute() {
      this.$emit("showDistributeConfirmation");
    },
  },
  canDistribute: PSData.canDistribute,
};
</script>
