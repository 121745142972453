const Event = PS.Models.Event;

class DashboardResizeRequested extends Event {
  get height() {
    return this.options.height;
  }
}

class DashboardRequested extends Event {
  get aggregation() {
    return this.options.aggregation;
  }

  get border() {
    return this.options.border;
  }

  get dashboard() {
    return this.options.dashboard;
  }

  get daterange() {
    return this.options.daterange;
  }

  get filters() {
    return this.options.filters;
  }

  get visible() {
    return this.options.visible;
  }
}

class DrilldownRequested extends PS.Models.Event {
  get dashboard() {
    return this.options.dashboard;
  }

  get filters() {
    const filters = this.options.filters;

    if (!filters) {
      return;
    }

    return filters.map(filter => {
      return {
        name: filter.filter_name,
        value: filter.column_value,
      };
    });
  }
}

PS.Models.Loan.Events.Periscope = {
  DashboardRequested: DashboardRequested,
  DashboardResizeRequested: DashboardResizeRequested,
  DrilldownRequested: DrilldownRequested,
};
