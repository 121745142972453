<template>
  <div class="details-row row no-gutters">
    <section-header
      :actions-hidden="actionsHidden"
      subheading="Additional information regarding this workout case"
    >
      <h4>Additional Notes</h4>
    </section-header>
    <editable-textarea
      :value="value"
      :editing="editing"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import SectionHeader from "../section_header";
import EditableTextarea from "../editable_textarea";

export default {
  name: "workout-case-notes",
  components: {
    EditableTextarea,
    SectionHeader,
  },
  props: EditableTextarea.props,
  computed: {
    actionsHidden: () => true,
  },
};
</script>
