import PropertyValuation from "models/property_valuation";

export default class PropertyValuationCollection {
  constructor(valuations) {
    this.designationChanges = false;
    this.items = valuations.map(e => {
      return new PropertyValuation(e);
    });
  }

  allowApplyDesignations(lenderSubmittedValuations, orderedValuations) {
    let lenderDesignations = {};
    lenderSubmittedValuations.forEach(valuation => {
      if (valuation.designated) {
        lenderDesignations.designated = true;
      }
      if (valuation.designatedArv) {
        lenderDesignations.designatedArv = true;
      }
      if (valuation.designatedDeferredMaintenance) {
        lenderDesignations.designatedDeferredMaintenance = true;
      }
    });
    if (Object.keys(lenderDesignations).length == 3) {
      return true;
    }
    let propertyDesignations = {};
    orderedValuations.forEach(valuation => {
      if (!propertyDesignations[valuation.propertyId]) {
        propertyDesignations[valuation.propertyId] = {};
      }
      if (valuation.designated) {
        propertyDesignations[valuation.propertyId]["designated"] = true;
      }
      if (valuation.designatedArv) {
        propertyDesignations[valuation.propertyId]["designatedArv"] = true;
      }
      if (valuation.designatedDeferredMaintenance) {
        propertyDesignations[valuation.propertyId]["designatedDeferredMaintenance"] = true;
      }
    });
    return Object.values(propertyDesignations).every(value => {
      return Object.keys(value).length == 3 - Object.keys(lenderDesignations).length;
    });
  }

  cancelDesignations() {
    this.items.forEach(i => {
      i.resetDesignations();
    });
    this.designationChanges = false;
  }

  designations() {
    return this.items.map(item => {
      return {
        id: item.id,
        designated: item.designated,
        designated_arv: item.designatedArv,
        designated_deferred_maintenance: item.designatedDeferredMaintenance,
      };
    });
  }

  designateDeferredMaintenance(item) {
    item.designateDeferredMaintenance();
    this.items.forEach(i => {
      if (this.shouldUnDesignate(i, item)) {
        i.unDesignateDeferredMaintenance();
      }
    });
    this.designationChanges = true;
  }

  designateArv(item) {
    item.designateArv();
    this.items.forEach(i => {
      if (this.shouldUnDesignate(i, item)) {
        i.unDesignateArv();
      }
    });
    this.designationChanges = true;
  }

  designateItem(item) {
    item.designate();
    this.items.forEach(i => {
      if (this.shouldUnDesignate(i, item)) {
        i.unDesignate();
      }
    });
    this.designationChanges = true;
  }

  shouldUnDesignate(i, item) {
    if (item.lenderSubmitted) {
      return i != item;
    } else {
      return (i != item && i.propertyId == item.propertyId) || i.lenderSubmitted;
    }
  }
}
