<template>
  <div class="time-series-comparison-chart stacked-bar-chart">
    <div class="chart-title-container">
      <h4 class="chart-title">
        {{ chart.title }}
        <div class="ps-loader ps-loader-small" v-show="sources.isLoading"></div>
      </h4>
      <a v-if="link" :href="link">View All Loans in Pipeline</a>
    </div>

    <div class="body">
      <chart-legend
        v-if="chart.customLegendPosition === 'top'"
        :columns="chart.columns"
        :classes="legendClasses"
        :subtext="chart.legendSubtext"
        :display="sources.display"
        @legend-mouse-over="onMouseOver"
        @legend-mouse-out="onMouseOut"
        @legend-click="onClick"
      >
        <template slot="legend-prefix-content">
          <div class="legend-group">
            <div>
              <h5 class="legend-item total-title">
                Total
              </h5>
            </div>
            <div>
              <span class="totals grand-total">{{ sources.totals.all | money }}</span>
            </div>
          </div>
        </template>
      </chart-legend>

      <div class="chart" ref="chart"></div>

      <chart-legend
        v-if="!chart.customLegendPosition"
        :columns="chart.columns"
        :classes="legendClasses"
        :subtext="chart.legendSubtext"
        :display="sources.display"
        @legend-mouse-over="onMouseOver"
        @legend-mouse-out="onMouseOut"
        @legend-click="onClick"
      />
    </div>
  </div>
</template>

<script>
import { format } from "d3";
import c3 from "c3";
import chartLegendComponent from "./chart_legend";
import MarketDataChartMixin from "components/market_data/market_data_chart_mixins";

export default {
  mixins: [MarketDataChartMixin],
  props: {
    link: String,
  },
  components: {
    "chart-legend": chartLegendComponent,
  },
  data() {
    return {
      legendClasses: this.prepareLegendClasses(),
      options: {
        axis: this.chart.axis,
        bar: {
          label: {
            format: this.chart.format,
          },
        },
        legend: this.chart.legend,
        grid: this.chart.grid,
        tooltip: {
          contents: function(data, defaultTitleFormat, defaultValueFormat, color) {
            let totals = data.reduce((acc, total) => {
              if (total.value) {
                acc += total.value;
              }

              return acc;
            }, 0);

            let point = Object.assign({}, data[0], {
              id: "Total",
              name: "Total",
              value: totals,
            });

            data.push(point);

            return this.getTooltipContent(data, defaultTitleFormat, defaultValueFormat, color);
          },
          format: {
            value: format("$,.2f"),
          },
        },
      },
    };
  },
  methods: {
    render() {
      this.legendClasses = this.prepareLegendClasses();
      this.options.data = this.prepareData();
      this.c3 = c3.generate({
        ...this.options,
        bindto: this.$refs.chart,
      });
    },
    prepareData() {
      const data = this.sources.data;
      const chartKeys = Object.keys(this.chart.columns);
      const options = {
        empty: {
          label: {
            text: this.chartMessage,
          },
        },
        columns: chartKeys.reduce((columns, key) => {
          const column = [this.findColumnName(key), ...data[key]];
          return [...columns, column];
        }, []),
        colors: chartKeys.reduce((colors, key) => {
          colors[this.findColumnName(key)] = this.chart.columns[key].color;
          return colors;
        }, {}),
        type: "bar",
        groups: this.chart.groups,
      };

      return options;
    },
    prepareLegendClasses() {
      return Object.keys(this.chart.columns).reduce((obj, key) => {
        obj[key] = {
          fade: false,
          off: false,
        };

        return obj;
      }, {});
    },
    onMouseOver(key) {
      if (this.legendClasses[key].off) return;

      this.c3.focus(this.findColumnName(key));

      Object.keys(this.legendClasses).forEach(k => {
        this.legendClasses[k].fade = k !== key;
      });
    },
    onMouseOut() {
      this.c3.revert();

      Object.keys(this.legendClasses).forEach(k => {
        this.legendClasses[k].fade = false;
      });
    },
    onClick(key) {
      this.c3.toggle(this.findColumnName(key));

      Object.keys(this.legendClasses).forEach(k => {
        this.legendClasses[k].fade = false;
      });
      this.legendClasses[key].off = !this.legendClasses[key].off;
    },
    findColumnName(key) {
      return this.chart.columns[key].label;
    },
  },
};
</script>
