import { SectionType } from "models/lender_form/fields";
import Section from "models/lender_form/section";

const PROPERTY_DETAILS_FIELD_NAMES = ["projectedRehabBudget"];
const MULTIFAMILY_ONLY_FIELDS = [
  "draftValuationMethod",
  "draftValuationDate",
  "propertyOccupancyPercentage",
  "propertyGroundLease",
  "propertyEnvironmentalIssuesIdentified",
];
export default class PropertyDetailsSection extends Section {
  constructor(fields) {
    super(SectionType.PropertyDetails, fields);
  }

  update() {
    this.checkProgramType();
    this.checkProjectedRehabBudget();
    this.handleExistingLoanAmount();

    super.update();
  }

  checkProjectedRehabBudget() {
    const hasProjectedRehabBudget = this.fields.projectedRehabBudget.convertedValue > 0;

    this.fields.draftArv.disabled = this.isResiRental || !hasProjectedRehabBudget;
  }

  handleExistingLoanAmount() {
    if (this.isFunded || (!this.isFunded && !this.isRefinanceLoanPurpose)) {
      this.fields.existingLoanAmount.disabled = true;
      this.fields.existingLoanAmount.value = null;
    } else {
      this.fields.existingLoanAmount.disabled = false;
    }
  }

  get isRefinanceLoanPurpose() {
    return ["cash_out_refinance", "refinance"].includes(this.fields.loanPurpose.convertedValue);
  }

  get isFunded() {
    return this.fields.lenderNoteStatus.value === "funded";
  }

  get isResiRental() {
    return this.fields.programType.value === "residential_rental_long_term";
  }

  get isMultifamilyBridge() {
    return this.fields.programType.value === "commercial_multifamily";
  }

  disableFields(fieldNames) {
    fieldNames.forEach(fieldName => {
      if (this.fields[fieldName]) this.fields[fieldName].disabled = true;
    });
  }

  checkProgramType() {
    if (this.isResiRental) {
      this.disableFields(PROPERTY_DETAILS_FIELD_NAMES);
    }

    if (!this.isMultifamilyBridge) {
      this.disableFields(MULTIFAMILY_ONLY_FIELDS);
    }
  }
}
