import Rule from "models/rule";
import {
  SendMessageRequested,
  EventsUpdateRequested,
  ComponentLoadingRequested,
  ErrorDisplayRequested,
} from "models/activity_feed_events/events";

import {
  SendMessage,
  UpdateMessages,
  BlockUI,
  ClearMessageInput,
  UnblockUI,
  ScrollToLastMessage,
  DisplayErrors,
} from "models/activity_feed_events/commands";

class ActivityFeedEventsRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case ComponentLoadingRequested:
        return this.whenComponentLoadingRequested(event);
      case SendMessageRequested:
        return this.whenSendMessageRequested(event);
      case EventsUpdateRequested:
        return this.whenEventsUpdateRequested(event);
      case ErrorDisplayRequested:
        return this.whenErrorDisplayRequested(event);
    }

    return super.check(event);
  }

  whenComponentLoadingRequested(event) {
    return [new ScrollToLastMessage(event, this.state)];
  }

  whenSendMessageRequested(event) {
    return [new BlockUI(event, this.state), new SendMessage(event, this.state)];
  }

  whenEventsUpdateRequested(event) {
    return [
      new ClearMessageInput(event, this.state),
      new UpdateMessages(event, this.state),
      new ScrollToLastMessage(event, this.state),
      new UnblockUI(event, this.state),
    ];
  }

  whenErrorDisplayRequested(event) {
    return [new DisplayErrors(event, this.state), new UnblockUI(event, this.state)];
  }
}

export default ActivityFeedEventsRule;
