<template>
  <div class="loan-task-view-header">
    <div class="loan-task-view-header-back" @click="$emit('on-back-btn')">
      <img
        class="loan-task-view-header-back-icon"
        :src="require('images/icons/icon_left_arrow_task.svg')"
      />
      Back
    </div>
    <div v-if="showPayButton" class="loan-task-buttons-container custom-loan-task-buttons">
      <div class="loan-task-buttons">
        <button
          :disabled="checkoutInProgress"
          class="btn btn-action-cta loan-task-btn"
          @click="payAdminFee"
        >
          Checkout
        </button>
      </div>
    </div>
    <div v-if="task.isConfirmed" class="loan-task-buttons-container custom-loan-task-buttons-completed">
      <i class="fas fa-check"></i>
      Completed
    </div>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";

export default {
  name: "pay-admin-fee-task-header",
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkoutInProgress: false,
    };
  },
  computed: {
    showPayButton() {
      return this.task.isOpen;
    },
  },
  methods: {
    payAdminFee() {
      this.checkoutInProgress = true;

      const URL = this.task.adminFeeCheckoutPath;

      AjaxService.post(URL, {})
        .then(data => {
          const stripe = Stripe(PSData.global.stripeApiPublishableKey);

          stripe
            .redirectToCheckout({
              sessionId: data.session_id,
            })
            .then(result => {
              Bugsnag.notify({ name: "Stripe checkout error", message: result.error.message });
            });
        })
        .finally(() => (this.checkoutInProgress = false));
    },
  },
};
</script>
