import qs from "qs";

import hostName from "utils/host_name";

const SUCCESS_MESSAGE = "Changes saved!";
export const ERROR_MESSAGE = "Something went wrong";
export const SUCCESS_STATUS = "success";
export const ERROR_STATUS = "error";

export const formatParams = params => qs.stringify(params, { arrayFormat: "brackets" });

class AjaxServiceFetchError extends Error {
  constructor(message, response, data) {
    super(message);
    this.name = this.errorName();
    this.response = response;
    this.data = data;
  }

  errorName() {
    return "AjaxServiceFetchError";
  }
}

class AjaxServiceUnauthorizedError extends AjaxServiceFetchError {
  errorName() {
    return "AjaxServiceUnauthorizedError";
  }
}

class AjaxServiceServerError extends AjaxServiceFetchError {
  errorName() {
    return "AjaxServiceServerError";
  }
}

class AjaxServiceNotFoundError extends AjaxServiceFetchError {
  errorName() {
    return "AjaxServiceNotFoundError";
  }
}

class AjaxServiceUnprocessableError extends AjaxServiceFetchError {
  errorName() {
    return "AjaxServiceUnprocessableError";
  }
}

const HTTP_STATUS_ERROR_MAPPING = {
  401: AjaxServiceUnauthorizedError,
  404: AjaxServiceNotFoundError,
  422: AjaxServiceUnprocessableError,
  500: AjaxServiceServerError,
};

class AjaxServiceWithNotifications {
  constructor(messages = {}) {
    this.messages = {
      onSuccess: messages.onSuccess || SUCCESS_MESSAGE,
      onError: messages.onError || ERROR_MESSAGE,
    };
  }

  ajax(url, data, method, headers, signal) {
    const promise = AjaxService.ajax(url, data, method, headers, signal);
    return this.attachNotifications(promise, this.messages);
  }

  get(url, headers, signal) {
    const promise = AjaxService.get(url, headers, signal);
    return this.attachNotifications(promise, this.messages);
  }

  post(url, data, headers, signal) {
    const promise = AjaxService.post(url, data, headers, signal);
    return this.attachNotifications(promise, this.messages);
  }

  postJSON(url, data, headers = {}, signal) {
    return this.post(url, data, { ...headers, "content-type": "application/json" }, signal);
  }

  patch(url, data, headers, signal) {
    const promise = AjaxService.patch(url, data, headers, signal);
    return this.attachNotifications(promise, this.messages);
  }

  patchJSON(url, data, headers = {}, signal) {
    return this.patch(url, data, { ...headers, "content-type": "application/json" }, signal);
  }

  delete(url, data, headers, signal) {
    const promise = AjaxService.delete(url, data, headers, signal);
    return this.attachNotifications(promise, this.messages);
  }

  getJSON(url, signal) {
    const promise = AjaxService.getJSON(url, signal);
    return this.attachNotifications(promise, this.messages);
  }

  attachNotifications(promise, messages) {
    let successMessage = messages.onSuccess;
    let errorMessage = messages.onError;

    return promise
      .then(data => {
        if ($.isFunction(successMessage)) {
          let successNotification = successMessage(data);

          if (successNotification) {
            this.sendNotification(successNotification.message, successNotification.status);
          }
        } else {
          this.sendNotification(successMessage, SUCCESS_STATUS);
        }

        return data;
      })
      .catch(result => {
        if ($.isFunction(errorMessage)) {
          let errorNotification = errorMessage(result);

          if (errorNotification) {
            this.sendNotification(errorNotification.message, errorNotification.status);
          }
        } else {
          this.sendNotification(errorMessage, ERROR_STATUS);
        }

        throw result;
      });
  }

  sendNotification(message, status) {
    $(window.PS).trigger("ajaxNotification.add", { message: message, status: status });
  }
}

export default class AjaxService {
  static withNotifications(messages = {}) {
    return new AjaxServiceWithNotifications(messages);
  }

  static get(url, headers, signal) {
    return this.ajax(url, "GET", {}, headers, signal);
  }

  static post(url, data, headers, signal) {
    return this.ajax(url, "POST", data, headers, signal);
  }

  static postJSON(url, data, headers = {}, signal) {
    return this.ajax(url, "POST", data, { ...headers, "content-type": "application/json" }, signal);
  }

  static patch(url, data, headers, signal) {
    return this.ajax(url, "PATCH", data, headers, signal);
  }

  static delete(url, data, headers, signal) {
    return this.ajax(url, "DELETE", data, headers, signal);
  }

  static getJSON(url, headers = {}, signal) {
    headers = { ...headers, Accept: "application/json; charaset=UTF-8" };
    return this.get(url, headers, signal);
  }

  static ajax(url, method = "GET", data, headers = {}, signal) {
    const headersContainer = new Headers(headers);

    headersContainer.set("X-CSRF-Token", $.rails.csrfToken());
    headersContainer.set("X-Requested-With", "XMLHttpRequest");

    return new Promise((resolve, reject) => {
      let body = this.encode(method, data, headersContainer);

      if (body instanceof URLSearchParams) {
        // Fix for IE11 + fetch.js
        body = body.toString();

        const hasContentType = !!headersContainer.get("Content-Type");

        if (!hasContentType) {
          // Fix for IE Edge https://github.com/jerrybendy/url-search-params-polyfill/issues/18
          headersContainer.set("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        }
      }

      let credentials = "same-origin";

      const requestHostName = hostName(url);
      const isRelativeRequestUrl = /^\/\w/.test(url);
      const isSecureHost = window.location.hostname === PSData.secureHostname;
      const isRequestToSecureHost = requestHostName === PSData.secureHostname;
      const isRequestToDefaultHost = requestHostName === PSData.defaultHostname;

      // Handle CORS requests from secure host to default host and vice versa.
      if (isSecureHost && isRelativeRequestUrl) {
        credentials = "include";
        url = this.defaultHost() + url;
      } else if (
        (isSecureHost && isRequestToDefaultHost) ||
        (!isSecureHost && isRequestToSecureHost)
      ) {
        credentials = "include";
      }

      const options = {
        credentials: credentials,
        method: method,
        body: body,
        headers: headersContainer,
      };

      if (signal) {
        options.signal = signal;
      }

      fetch(url, options)
        .then(response => {
          this.decode(response).then(body => {
            if (response.status === 201) {
              const location = response.headers.get("Location");
              resolve({ location });
            } else if (response.status >= 200 && response.status < 300) {
              resolve(body);
            } else {
              Bugsnag.leaveBreadcrumb("ajax_service:fetch", { response: body });
              const errorClass =
                HTTP_STATUS_ERROR_MAPPING[response.status] || AjaxServiceFetchError;

              reject(new errorClass(`Request returned ${response.status} status`, response, body));
            }
          });
        })
        .catch(error => {
          if (error.name === "AbortError") {
            // The Ajax call is cancelled by the client, do nothing.
            return;
          }

          reject({ response: Response.error(), error: error });
        });
    });
  }

  static encode(method, data, headers) {
    if (["GET", "HEAD"].includes(method) || !data) {
      return;
    }

    if (this.type(headers) === "json") {
      return JSON.stringify(data);
    } else {
      if (typeof data === "string") {
        return data;
      } else {
        return this.toParams(data);
      }
    }
  }

  static decode(response) {
    if (this.type(response.headers) === "json") {
      return response.json();
    } else {
      return response.text();
    }
  }

  static type(headers) {
    const header = headers.get("Content-Type");
    const isString = typeof header === "string";

    if (isString && header.includes("application/json")) {
      return "json";
    } else {
      return "text";
    }
  }

  static toParams(obj) {
    const properties = this.convertObject(obj, []);
    const hasFile = properties.some(([, value]) => value instanceof File);

    return properties.reduce((acc, [key, value]) => {
      acc.append(key, value);
      return acc;
    }, hasFile ? new FormData() : new URLSearchParams());
  }

  static convertObject(obj, params, namespace) {
    let key;

    for (let property in obj) {
      if (namespace) {
        key = namespace + "[" + property + "]";
      } else {
        key = property;
      }

      const value = obj[property];
      const isFile = value instanceof File;
      const isNull = value === null;
      const coercedValue = isNull ? "" : value;

      // if the property is an object, but not a File, use recursivity.
      if (value instanceof Date) {
        params.push([key, value.toISOString()]);
      } else if (typeof value === "object" && !isNull && !isFile) {
        this.convertObject(value, params, key);
      } else if (value !== undefined) {
        params.push([key, coercedValue]);
      }
    }

    return params;
  }

  static sendNotification(message, status) {
    $(window.PS).trigger("ajaxNotification.add", { message: message, status: status });
  }

  static defaultHost() {
    return PSData.defaultHostname ? `//${PSData.defaultHostname}` : "";
  }

  static secureHost() {
    return PSData.secureHostname ? `//${PSData.secureHostname}` : "";
  }

  static homePath() {
    return location.pathname.startsWith("/manage") ? "/manage" : "";
  }
}

PS.Services.AjaxService = AjaxService;
PS.Services.AjaxServiceWithNotifications = AjaxServiceWithNotifications;
