<template>
  <base-currency-field
    v-model="input.value.value"
    :label="input.name"
    :disabled="readOnly"
  />
</template>

<script>
import Input from "models/workflow_engine/input";

export default Vue.extend({
  name: "workflow-currency-input-component",
  props: {
    input: {
      type: Input,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
