function alwaysFalseFilter(_) {
  return false;
}

export default class FilterHelper {
  constructor(filterOptions, filters = {}, visibleField = "visible") {
    this.filterOptions = filterOptions;
    this.filters = {};
    this.visibleField = visibleField;

    Object.keys(this.filterOptions).forEach(key => {
      this.filters[key] = filters[key] || false;
    });
  }

  updateFilter(filterName, value) {
    this.filters[filterName] = value;
  }

  filteredCount(filterName, collection) {
    const filterFunction = this.filterOptions[filterName].on || alwaysFalseFilter;
    return collection.filter(filterFunction).length;
  }

  applyFilters(collection) {
    collection.forEach(item => {
      item[this.visibleField] = true;
    });

    Object.keys(this.filters).forEach(filterName => {
      const filterEnabled = this.filters[filterName];
      if (filterEnabled) {
        collection.forEach(item => {
          if (item[this.visibleField]) {
            const filterFunction = this.filterOptions[filterName].on || alwaysFalseFilter;
            item[this.visibleField] = filterFunction(item);
          }
        });
      }
    });

    return collection;
  }
}
