const LOADING_STATUS = {
  inProgress: "inProgress",
  successful: "successful",
  failed: "failed",
};

class Source {
  constructor() {
    this.errorMessage = undefined;
    this.loadingStatus = LOADING_STATUS.inProgress;
  }

  get name() {
    throw "NotImplemented: Please override Source#name";
  }

  success() {
    this.errorMessage = undefined;
    this.loadingStatus = LOADING_STATUS.successful;
  }

  fail(errorMessage) {
    this.errorMessage = errorMessage;
    this.loadingStatus = LOADING_STATUS.failed;
  }

  get isLoading() {
    return this.loadingStatus === LOADING_STATUS.inProgress;
  }

  get isSuccessful() {
    return this.loadingStatus === LOADING_STATUS.successful;
  }

  get isFailed() {
    return this.loadingStatus === LOADING_STATUS.failed;
  }
}

export default Source;
PS.Models.MarketData.Source = Source;
