<template>
  <div>
    <slot>
      <div ref="entryPoint" />
    </slot>
  </div>
</template>

<script>
import { camelCase, upperFirst } from "lodash";

export default {
  props: {
    serviceUrl: { type: String, required: true },
    applicationName: { type: String, default: null },
    props: { type: Object, default: () => ({}) },
  },
  computed: {
    mountedName() {
      return `MFE${upperFirst(camelCase(this.applicationName))}`;
    },
  },
  async mounted() {
    await this.loadScript();
    this.$emit("ready");
  },
  methods: {
    fetchSkipCache(url) {
      return fetch(url, { cache: "no-store", credentials: "include" });
    },
    mountApp() {
      if (!this.applicationName || !this.$refs.entryPoint) return;

      window[this.mountedName].mount({
        element: this.$refs.entryPoint,
        ...this.props,
      });
    },
    async loadScript() {
      const response = await this.fetchSkipCache(`${this.serviceUrl}/packs/manifest.json`);
      const manifest = await response.json();
      const appKey = `${this.applicationName || "injector"}.js`;
      const appUrl = `${this.serviceUrl}${manifest[appKey]}`;
      await import(/* webpackIgnore: true */ appUrl);
      this.mountApp();
    },
  },
};
</script>
