<template>
  <div class="withdrawn-declined-loans-index">
    <div class="time-frame-wrapper">
      <label>Submitted Date</label>
      <select2
        id="time-frame"
        ref="timeFrame"
        class="form-control"
        :options="timeOptions"
        v-model="selectedTime"
        :disabled="isLoading"
        @on-value-change="fetchLoans"
      />
    </div>
    <div class="metric-graphs">
      <withdrawn-declined-metrics title="All Loans" :chart-data="metricsAll" class="panel-bordered" />
      <withdrawn-declined-metrics title="Bridge (SFR, MFR &amp; CRE)" :chart-data="metricsBridge" class="panel-bordered" />
      <withdrawn-declined-metrics title="Residential for Rent" :chart-data="metricsRent" class="panel-bordered" />
    </div>
    <withdrawn-declined-loans-list :loans="loans" :loans-route="loansRoute" class="panel-bordered" />
  </div>
</template>

<script>
import Ajax from "services/ajax_service";
import WithdrawnDeclinedMetrics from "components/withdrawn_declined/metrics";
import WithdrawnDeclinedLoansList from "components/withdrawn_declined/loans_list";
import Select2 from "components/shared/select2";
import Segment from "services/segment_service";

export default {
  components: {
    WithdrawnDeclinedMetrics,
    WithdrawnDeclinedLoansList,
    Select2,
  },
  data() {
    return {
      isLoading: false,
      loans: PSData.loans || [],
      loansRoute: PSData.routes.loans,
      getLoansRoute: PSData.routes.withdrawnDeclinedLoans,
      selectedTime: "year_to_date",
      metricsAll: PSData.metrics.metricsAll,
      metricsRent: PSData.metrics.metricsRent,
      metricsBridge: PSData.metrics.metricsBridge,
      timeOptions: { data: PSData.timeFrameOptions },
    };
  },
  computed: {
    fetchUrl() {
      return `${this.getLoansRoute}?time_frame=${this.selectedTime}`;
    },
  },
  methods: {
    parseTimeFrameOptions(options) {
      options.map(option => {
        return { id: option["options"], text: option["label"] };
      });
    },
    fetchLoans() {
      this.isLoading = true;

      Segment.track("clickedSubmittedDateDropdown", {
        option_selected: this.selectedTime,
      });

      Ajax.get(this.fetchUrl)
        .then(data => {
          this.loans = data.loans;
          this.metricsAll = data.metrics.metricsAll;
          this.metricsBridge = data.metrics.metricsBridge;
          this.metricsRent = data.metrics.metricsRent;
        })
        .catch(() => {
          this.loans = [];
          this.metricsAll = {};
          this.metricsBridge = {};
          this.metricsRent = {};
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
