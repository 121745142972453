<template>
  <div class="with-tooltip">
    <slot />
    <i v-if="isCircle" v-popover class="fas fa-question-circle form-tooltip" />
    <div v-else v-popover class="with-field-tooltip" >
      <slot name="trigger" />
    </div>
    <span class="popover-content hidden">
      <slot name="content" />
    </span>
  </div>
</template>

<script>
const variants = Object.freeze({ FIELD: "field", CIRCLE: "circle" });

export default {
  name: "with-tooltip",
  props: {
    variant: {
      type: String,
      default: variants.CIRCLE
    },
  },
  computed: {
    isCircle() {
      return this.variant === variants.CIRCLE;
    }
  }
};
</script>
