<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content base-modal fees-details">
      <div class="modal-header">
        <h4>
          {{ title }}
          <i v-if="loading" class="ps-loader ps-loader-small"></i>
        </h4>
      </div>
      <div class="modal-body">
        <form @keypress.enter="onSave">
          <div class="form-group controll-wrapper">
            <form-input
              v-model.trim="resource.feeName"
              label="Distribution Fee Name"
              field="fee_name"
              :errors="errors"
              :inline="false"
              :disabled="!!fee"
            />
          </div>
          <check-box
            v-if="fee"
            v-model="resource.disabled"
            label="Disable Fee"
          />
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-grey" @click="onClose">Cancel</button>
        <button class="btn btn-action-cta" @click="onSave">
          Save
        </button>
      </div>
    </div>
  </modal-view>
</template>
<script>
import FormInput from "components/shared/form/form_input";
import CheckBox from "components/shared/check_box";
import ModalView from "components/modal_view";
import AjaxService from "services/ajax_service";

export default {
  components: { ModalView, FormInput, CheckBox },
  props: {
    fee: [Object, undefined],
  },
  data() {
    const resource = {};

    if (this.fee) {
      resource.disabled = this.fee.status === "disabled";
      resource.feeName = this.fee.label;
    }

    return {
      loading: false,
      show: true,
      errors: {},
      resource,
    };
  },
  computed: {
    title() {
      if (this.fee) {
        return "Edit Distribution Fee";
      } else {
        return "Add Distribution Fee";
      }
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onSave() {
      const { feeName, disabled } = this.resource;

      const data = {
        fee_name: feeName,
        enabled: !disabled,
      };

      this.save(data);
    },
    async save(resource) {
      this.loading = true;

      try {
        const action = this.fee ? "patch" : "post";
        const data = await AjaxService[action](
          `/manage/cash_receipt_distribution_fees/${this.fee?.id || ""}`,
          { resource },
          { "content-type": "application/json" }
        );

        this.$emit("saved", data);
      } catch ({ response, data }) {
        if (response.status === 422) {
          this.errors = data;
        } else {
          console.log("Error");
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
