<template>
  <div>
    <div class="columns">
      <div class="column is-half">
        <base-currency-field
          data-test="charge-offs-form-upb-field"
          label="Unpaid Principal Balance"
          :disabled="true"
          :value="loanDetails.unpaidPrincipalBalance"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <base-currency-field
          data-test="charge-offs-form-amount-field"
          label="Charge-Off Amount"
          :disabled="true"
          :value="chargeOffAmount"
        />
      </div>
      <div class="column">
        <base-date-field
          data-test="charge-offs-form-effective-date-field"
          label="Effective Date"
          v-model="form.effectiveDate"
          :errors="errors.effective_date"
          :max-date="todayDate"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <base-currency-field
          data-test="charge-offs-form-remaining-upb-field"
          label="Remaining Unpaid Principal Balance"
          :disabled="true"
          :value="remainingUnpaidPrincipalBalance"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <base-text-field
          data-test="charge-offs-form-notes-field"
          label="Note"
          type="textarea"
          v-model="form.comment"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-clearfix">
        <div class="is-pulled-right">
          <div class="is-inline-block">
            <base-button data-test="charge-offs-form-cancel" @click="cancel" type="text">Cancel</base-button>
          </div>
          <div class="is-inline-block" >
            <base-button data-test="charge-offs-form-save" @click="save" :disabled="disableSave">Save Draft</base-button>
          </div>
          <div class="is-inline-block">
            <base-button data-test="charge-offs-form-submit" @click="process" type="primary" :disabled="disableProcess">Generate Transactions</base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  props: {
    chargeOff: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disableSave: {
      type: Boolean,
      default: () => false,
    },
    disableProcess: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      form: {
        id: this.chargeOff.id,
        effectiveDate: this.chargeOff.effectiveDate || moment().format("YYYY-MM-DD"),
        comment: this.chargeOff.comment,
      },
    };
  },
  methods: {
    process() {
      this.$emit("process", this.form);
    },
    save() {
      this.$emit("save", this.form);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  computed: {
    loanDetails() {
      return PSData.loanDetails;
    },
    chargeOffAmount() {
      return this.loanDetails.unpaidPrincipalBalance * -1;
    },
    remainingUnpaidPrincipalBalance() {
      return "0.0";
    },
    todayDate() {
      return new Date();
    },
  },
};
</script>
<style scoped>
::v-deep .columns {
  margin-top: 0;
  margin-bottom: 0;
}

::v-deep .column {
  padding-top: 0;
  padding-bottom: 0;
}

.is-clearfix {
  clear: both;
}
</style>
