import LenderBlockStatuses from "components/lender_block_statuses";
import TagManager from "components/tags/tag_manager";
import get from "lodash/get";
import EditLenderFormPolicy from "policies/edit_lender_form_policy";

$(() => {
  const BusService = PS.Services.MessageBusService;
  const Commands = PS.Models.Lenders.Commands;
  const Event = PS.Models.Event;
  const Lender = PS.Models.Lenders.Lender;
  const UpdateSettingsRulebook = PS.Models.Lenders.UpdateSettingsRule;

  new Vue({
    el: document.querySelector(".v-manage-edit-lender-info-controller"),
    components: {
      LenderBlockStatuses,
      TagManager,
    },
    data: function() {
      const active = get(PSData, "tags.active");
      const inactive = get(PSData, "tags.inactive");
      const lender = new Lender(PSData.lender);

      return {
        existingAnalysts: PSData.existingAnalysts || [],
        defaultServicers: PSData.availableServicers || [],
        defaultLenderServicers: PSData.availableLenderServicers || [],
        constructionDrawVendors: PSData.constructionDrawVendors || [],
        isLenderAdmin: get(PSData, "user.isLenderAdmin"),
        isUpdatingTags: false,
        initialTags: { active, inactive },
        lender: lender,
        messageBus: BusService.lenderBus,
        tagMessageBus: BusService.lenderTagBus,
        resourceId: lender.id,
        rulebook: undefined,
        updating: false,
        lenderTags: PSData.lenderTags,
        lenderTagOptions: PSData.lenderTagOptions,
        lenderEditingDisabled: false,
      };
    },
    computed: {
      isDisabled: function() {
        return this.updating || !this.lender.isChanged;
      },
    },
    methods: {
      loadPolicy() {
        const formPolicy = new EditLenderFormPolicy(PSData.lenderPolicy);
        this.lenderEditingDisabled = !formPolicy.canUpdateLenderData();
      },
      save: function() {
        new Commands.UpdateSettings.SendSettingsUpdate(new Event(), {}).execute();
      },
    },
    created: function() {
      this.rulebook = new UpdateSettingsRulebook(this);
      this.messageBus.attachRulebook(this.rulebook);
    },
    beforeDestroy: function() {
      this.messageBus.detachRulebook(this.rulebook);
    },
    mounted() {
      this.loadPolicy();
    },
  });
});
