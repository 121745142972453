<template>
  <div class="allocation">
    <h2 class="lender-section-title">
      Loan Allocation
      <span v-if="isLoaded && isNotCompleted" class="badge badge-warning">Allocation is in progress</span>
      <div v-show="isLoading" class="ps-loader ps-loader-small"></div>
    </h2>
    <loans-table
      v-if="isLoaded"
      :is-validating="false"
      :batch-loans="orderedLoans"
      :buyers="buyers"
      :edit-mode="false"
      :show-mode="!isNotCompleted"
    ></loans-table>
    <div class="float-right" v-if="isLoaded">
      <a class="btn btn-action-cta" :href="routes.manageAllocations" :disabled="isNotCompleted">New Allocation</a>
    </div>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import LoansTable from "./loans_table";

const DEFAULT_FETCH_INTERVAL = 3000;

export default {
  name: "allocation-show",
  components: {
    LoansTable,
  },
  props: {
    interval: {
      type: Number,
      default: DEFAULT_FETCH_INTERVAL,
    },
  },
  data: function() {
    return {
      isLoading: false,
      isLoaded: false,
      batch: {
        batchLoans: [],
      },
      buyers: [],
      routes: PSData.routes,
      timer: null,
    };
  },
  async mounted() {
    await this.getBatchData();
    this.startPolling();
  },
  destroyed() {
    this.stopPolling();
  },
  methods: {
    async getBatchData() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      try {
        await Promise.all([this.getBuyers(), this.getBatch()]);
      } finally {
        this.isLoading = false;
        this.isLoaded = true;
      }
    },

    async getBuyers() {
      const url = this.routes.manageAllocationsBuyers;
      const res = await AjaxService.get(url);
      this.buyers = res.buyerPrograms;
    },

    async getBatch() {
      const url = this.routes.manageAllocationsBatch;
      const res = await AjaxService.get(url);
      this.batch = res.batch;
    },

    startPolling() {
      if (this.shouldPoll()) {
        this.timer = setInterval(this.poll, this.interval);
      }
    },

    stopPolling() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },

    shouldPoll() {
      if (this.isNotCompleted) {
        return true;
      }
      return false;
    },

    async poll() {
      await this.getBatch();
      const poll = this.shouldPoll();
      if (!poll) {
        this.stopPolling();
      }
    },
  },
  computed: {
    orderedLoans() {
      return this.batch.batchLoans.sort((a, b) => a.loan.psId.localeCompare(b.loan.psId));
    },

    isNotCompleted() {
      return !this.batch.completedAt;
    },
  },
};
</script>
