<template>
  <div class="lender-legal-entity-state">
    <breadcrumb-navigation :items="breadcrumbItems()"></breadcrumb-navigation>
    <h3 class="lender-entities-header">
      {{ stateTitle }}
    </h3>
    <entity-registration-license-panel class="view-panel-row" :policy="policy" />
    <state-license-panel :routes="routes.stateLicenses" :state="lenderLegalEntityState" :policy="policy" />
    <state-loan-documents-list class="view-panel-row" :policy="policy" />
  </div>
</template>

<script>
import AjaxService, { SUCCESS_STATUS } from "services/ajax_service";
import Location from "services/location_service";
import LegalEntityState from "models/lender_legal_entities/state";
import BreadcrumbNavigation from "../shared/breadcrumb_navigation";
import EntityRegistrationLicensePanel from "./registration_license/show_panel";
import StateLicensePanel from "./state_license/show_panel";
import StateLoanDocumentsList from "./loan_documents/list";

export default Vue.component("lender-legal-entity-state", {
  components: {
    BreadcrumbNavigation,
    EntityRegistrationLicensePanel,
    StateLoanDocumentsList,
    StateLicensePanel,
  },
  data: function() {
    return {
      lenderLegalEntityState: new LegalEntityState(PSData.lenderLegalEntityState),
      routes: PSData.routes || {},
      policy: PSData.policy,
    };
  },
  computed: {
    stateTitle() {
      return this.lenderLegalEntityState.stateLabel;
    },
  },
  mounted() {
    if (Location.hash === "#new") {
      Location.hash = "";
      AjaxService.sendNotification(
        `${this.lenderLegalEntityState.stateLabel} Successfully Added`,
        SUCCESS_STATUS
      );
    }
  },
  methods: {
    breadcrumbItems() {
      return [
        {
          name: "Entities",
          link: this.routes.lenderLegalEntities,
        },
        {
          name: this.lenderLegalEntityState.entityName,
          link: this.routes.lenderLegalEntity,
        },
        {
          name: this.stateTitle,
        },
      ];
    },
  },
});
</script>
