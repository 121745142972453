class ChecklistEvent extends PS.Models.Event {}

class DeselectDocumentTypeRequested extends ChecklistEvent {}

class SelectDocumentTypeRequested extends ChecklistEvent {
  prepareData(options) {
    this.documentType = options.documentType;
  }
}

class RequestBlockUI extends ChecklistEvent {}

class RequestUnblockUI extends ChecklistEvent {}

PS.Models.Diligence.Events.Checklist = {
  DeselectDocumentTypeRequested: DeselectDocumentTypeRequested,
  SelectDocumentTypeRequested: SelectDocumentTypeRequested,
  RequestBlockUI,
  RequestUnblockUI,
};
