import { defineStore } from "pinia";
import PayoffStatement from "models/payoff_statement";

const useResaleStatementStore = defineStore("resaleStatement", {
  state: () => ({
    statement: new PayoffStatement(PSData.statement || {}),
  }),
  actions: {
    setStatement(result) {
      this.statement = new PayoffStatement(result.statement);
    },
    setStatus(statement) {
      this.statement.status = statement.status;
      this.statement.statusLabel = statement.statusLabel;
    },
  },
});
export default useResaleStatementStore;
