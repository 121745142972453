<template>
  <diligence-document-field :document="document" :field="field">
    <div class="col-5">
      <input
        :id="field.id"
        v-model="field.value"
        v-mask:integer="field.mask.options"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @blur="saveValueImmediately"
      />
    </div>
  </diligence-document-field>
</template>

<script>
import TextField from "./base/text_field";

const IntegerField = TextField.extend({});

export default Vue.component("diligence-document-integer-field", IntegerField);
</script>
