<template>
  <a class="js-upload" href="#" @click.prevent="picker.open">
    <slot />
  </a>
</template>

<script>
import {
  SOURCES,
  PROVIDERS,
  getFilestackClient,
  generateFilePickerOptions,
} from "services/filestack_service";
import { generateUploadedFilesPayload } from "services/file_upload_service";

export default {
  name: "file-upload",
  props: {
    initialOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apiKey: PSData.global.filepickerApiKey,
      options: this.initialOptions,
      sources: SOURCES,
      provider: PROVIDERS.filestack,
      filesUploaded: [],
      filesFailed: [],
    };
  },
  computed: {
    client() {
      return getFilestackClient(this.apiKey, this.options);
    },
    picker() {
      return this.client.picker({ ...this.pickerOptions, onUploadDone: this.uploadFiles });
    },
    pickerOptions() {
      return generateFilePickerOptions(this.options, this.sources);
    },
  },
  methods: {
    uploadFiles(blobs) {
      const payload = generateUploadedFilesPayload(this.provider, blobs);

      if (payload) {
        this.$emit("on-upload-success", payload.filesUploaded, payload.filesFailed);
      } else {
        this.$emit("on-upload-error", "Upload failed. Please try again.");
      }
    },
  },
};
</script>
