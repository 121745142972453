const Source = PS.Models.MarketData.Source;

class ZipAtGlance extends Source {
  get name() {
    return "ZIP at Glance";
  }

  success(midasData) {
    if (midasData && midasData.zipCrossSection) {
      let marketData = midasData.zipCrossSection;

      this.income = marketData.income;
      this.incomeMsaMedian = marketData.incomeMsaMedian;
      this.incomeMsaPercentile = marketData.incomeMsaPercentile * 100;
      this.investmentIncome = marketData.investmentIncome;
      this.investmentIncomeMsaMedian = marketData.investmentIncomeMsaMedian;
      this.investmentIncomeMsaPercentile = marketData.investmentIncomeMsaPercentile * 100;
      this.retirementIncome = marketData.retirementIncome;
      this.retirementIncomeMsaMedian = marketData.retirementIncomeMsaMedian;
      this.retirementIncomeMsaPercentile = marketData.retirementIncomeMsaPercentile * 100;
      this.poverty = marketData.poverty * 100;
      this.povertyMsaMedian = marketData.povertyMsaMedian * 100;
      this.povertyMsaPercentile = marketData.povertyMsaPercentile * 100;
      this.unemployment = marketData.unemployment * 100;
      this.unemploymentMsaMedian = marketData.unemploymentMsaMedian * 100;
      this.unemploymentMsaPercentile = marketData.unemploymentMsaPercentile * 100;
      this.education = marketData.education * 100;
      this.educationMsaMedian = marketData.educationMsaMedian * 100;
      this.educationMsaPercentile = marketData.educationMsaPercentile * 100;
      this.highTech = marketData.highTech * 100;
      this.highTechMsaMedian = marketData.highTechMsaMedian * 100;
      this.highTechMsaPercentile = marketData.highTechMsaPercentile * 100;
      this.homeValue = marketData.homeValue;
      this.homeValueMsaMedian = marketData.homeValueMsaMedian;
      this.homeValueMsaPercentile = marketData.homeValueMsaPercentile * 100;
      this.monthlyCostOwn = marketData.monthlyCostOwn;
      this.monthlyCostOwnMsaMedian = marketData.monthlyCostOwnMsaMedian;
      this.monthlyCostOwnMsaPercentile = marketData.monthlyCostOwnMsaPercentile * 100;
    }

    super.success();
  }
}

PS.Models.MarketData.Tables.ZipAtGlance = ZipAtGlance;
