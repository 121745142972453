<template>
  <documents-show-panel
    :panel-title="panelTitle"
    :form-title="formTitle"
    :route="routes.stateRegistrationLicenseDocuments"
    :policy="policy"
    class="registration-license-panel"
  />
</template>
<script>
import LegalEntityState from "models/lender_legal_entities/state";
import DocumentsShowPanel from "components/lender_legal_entities/shared/documents_show_panel";

export default {
  name: "lender-legal-entity-registration-license-panel",
  components: {
    DocumentsShowPanel,
  },
  props: {
    policy: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      routes: PSData.routes || {},
      lenderLegalEntityState: new LegalEntityState(PSData.lenderLegalEntityState),
    };
  },
  computed: {
    panelTitle() {
      return `${this.lenderLegalEntityState.stateLabel} Registration & License`;
    },
    formTitle() {
      return `Edit ${this.lenderLegalEntityState.stateLabel} Registration & License Status`;
    },
  },
};
</script>
