export const QA_DEFINITION_TASK_TYPE = Object.freeze("quality_assurance");
export const INTERNAL_DEFINITION_TASK_TYPES = Object.freeze([
  "underwriter",
  "sla",
  "closer",
  QA_DEFINITION_TASK_TYPE,
]);

export default class LoanTask {
  constructor(task = {}) {
    this.id = task.id;
    this.title = task.title;
    this.description = task.description;
    this.groupId = task.groupId;
    this.isOpen = task.isOpen;
    this.isConfirmed = task.isConfirmed;
    this.isPending = task.isPending;
    this.isCustom = task.isCustom;
    this.isCustomInternal = task.isCustomInternal;
    this.isQuestion = task.isQuestion;
    this.isSla = task.isSla;
    this.isQA = task.isQa;
    this.canComplete = task.canComplete;
    this.documentsUploadDisabled = task.documentsUploadDisabled;
    this.updatedAgo = task.updatedAgo;
    this.updatedBy = task.updatedBy;
    this.startedAt = task.startedAt;
    this.expectedAt = task.expectedAt;
    this.completedAt = task.completedAt;

    this.definition = task.definition || {};
    this.commentsPath = task.commentsPath;
    this.viewsPath = task.viewsPath;
    this.assigneesPath = task.assigneesPath;
    this.loanTaskCommentsCount = task.loanTaskCommentsCount;
    this.assignees = task.assignees || [];
    this.adminFeeCheckoutPath = task.adminFeeCheckoutPath;
    this.borrowerTasksPath = task.borrowerTasksPath;
  }

  get isInternal() {
    return INTERNAL_DEFINITION_TASK_TYPES.includes(this.definition.taskType);
  }

  get isLender() {
    return this.definition.taskType === "lender";
  }

  get isLenderQuestion() {
    return this.definition.taskType === "lender" && this.isQuestion;
  }

  get isUpload() {
    return this.definition.actionType === "upload";
  }

  get isAdditionalInfo() {
    return this.definition.actionType === "additional_info";
  }

  get statusText() {
    if (this.isPending) {
      return "Pending";
    }

    if (this.isConfirmed) {
      return "Completed";
    }

    return "";
  }
}
