<template>
  <with-tooltip variant="field">
    <template slot="trigger">{{ model.totalFee | money }}</template>
    <template slot="content">
      <div class="fees-popover">
        <div class="fees-popover-row fees-popover-header">
          <p>UPB at Extension</p>
          <p><strong>{{ model.unpaidPrincipalBalance | money }}</strong></p>
        </div>

        <div>
          <div class="fees-popover-row fees-popover-line-item">
            <p>Modification Fee</p>
            <p>{{ model.modificationFee | money }}</p>
          </div>
          <div
            v-for="fee in sortedFees"
            :key="fee.id"
            class="fees-popover-row fees-popover-line-item"
          >
            <p>{{ fee.label }}</p>
            <p>{{ fee.amount | money }}</p>
          </div>
        </div>

        <div class="fees-popover-row fees-popover-total">
          <p>Total Fees and Costs</p>
          <p>{{ model.totalFee | money }}</p>
        </div>
      </div>
    </template>
  </with-tooltip>
</template>

<script>
import WithTooltip from "components/shared/with_tooltip";
import { groupBy, sumBy } from "lodash";

export default {
  components: {
    WithTooltip,
  },
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      feeTypes: PSData.loanModificationFeeTypes || [],
    };
  },
  computed: {
    fees() {
      return Object.entries(this.feesGroupedByTypeId).map(([feeTypeId, fees]) => {
        const amount = sumBy(fees, fee => parseFloat(fee.amount));
        const label = this.feeTypes.find(fee => feeTypeId == fee.feeTypeId).label;

        return { feeTypeId, amount, label };
      });
    },
    sortedFees() {
      return this.fees.sort((a, b) => a.label.localeCompare(b.label));
    },
    feeList() {
      return this.model.feeList;
    },
    feesGroupedByTypeId() {
      return groupBy(this.feeList, "feeTypeId");
    },
  },
};
</script>
