<template>
  <base-modal
    :show="show"
    title="Preview"
    :save-disabled="false"
    save-button-name="Edit"
    class="loan-task-definition-preview"
    :show-save="canManage"
    cancel-button-name="Close"
    @close="onClose"
    @save="onEdit"
  >
    <div v-if="definition" class="pb-2">
      <div class="loan-task-label pb-2">
        {{ definition.label }}
      </div>
      <div class="pb-1">
        <span class="has-text-weight-semibold pr-1">Assigned To:</span>
        <span>Unassigned</span>
      </div>
      <base-hr class="loan-task-definition-preview-section-hr" />
      <div v-html="definition.defaultDescription" />
    </div>
  </base-modal>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    definition: {
      type: Object,
      default: () => ({}),
    },
    canManage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onEdit() {
      this.$emit("edit");
    },
  },
};
</script>
<style scoped>
.loan-task-definition-preview >>> .modal-card {
  min-width: 600px;
  max-width: 600px;
}

.loan-task-definition-preview >>> .modal-card-body {
  background: var(--color-grey-06);
}

.loan-task-label {
  font-size: var(--font-size-lg);
  font-weight: 600;
}

.loan-task-definition-preview .loan-task-definition-preview-section-hr {
  margin: calc(var(--space-unit) * 2) calc(var(--space-unit) * -3);
}
</style>
