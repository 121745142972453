<template>
  <div class="form-group">
    <label>{{ label }}</label>

    <div v-if="!readonly">
      <div
        v-for="(optionLabel, optionValue) in $options.types"
        :key="optionValue"
        class="form-check"
      >
        <input
          class="form-check-input"
          type="radio"
          :name="name"
          value="all"
          :checked="value === optionValue"
          @input="$emit('filter', name, optionValue)"
        >
        <label class="form-check-label font-weight-normal">
          {{ optionLabel }}
        </label>
      </div>
    </div>

    <div v-else>
      {{ $options.types[value] }}
    </div>
  </div>
</template>

<script>
const TRANSACTION_TYPES = {
  all: "All",
  distributedOnly: "Distributed Only",
  undistributedOnly: "Undistributed Only",
};

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    readonly: Boolean,
  },
  types: TRANSACTION_TYPES,
};
</script>
