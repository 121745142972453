<template>
  <div class="scenarios">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "loan-scenarios-app",
};
</script>

<style scoped>
.scenarios {
  color: var(--color-grey-86);
  font-family: var(--font-family);
  font-size: var(--font-size);
  width: auto;
}
</style>
