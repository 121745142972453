<template>
  <modal-form
    :confirm="showConfirmation"
    :save-disabled="saveDisabled"
    :show="show"
    :title="modalTitle"
    class="lender-legal-entity-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <form>
      <h2>Tax Info</h2>
      <div class="row">
        <with-errors on="tin" :errors="errors" class="col-6 form-group">
          <label>Tax ID</label>
          <input
            v-model="entity.tin"
            class="form-control"
            name="tin"
            :placeholder="[[ safeTin ]]"
          />
        </with-errors>
        <with-errors on="tinType" :errors="errors" class="col-6 form-group">
          <label>Tax ID Type</label>
          <select v-model="entity.tinType" class="form-control">
            <option disabled value="" />
            <option v-for="(label, value) in entity.taxIdTypes" :key="value" :value="value">{{ label }}</option>
          </select>
        </with-errors>
      </div>
      <h2>Address</h2>
      <div class="row">
        <with-errors on="address" :errors="errors" class="col-12 form-group">
          <label>Street Address</label>
          <input
            v-model="entity.address"
            class="form-control"
            name="address"
          />
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="city" :errors="errors" class="col-6 form-group">
          <label>City</label>
          <input
            v-model="entity.city"
            class="form-control"
            name="city"
          />
        </with-errors>
        <with-errors on="state" :errors="errors" class="col-6 form-group">
          <label>State</label>
          <select v-model="entity.state" class="form-control">
            <option disabled value=""></option>
            <option v-for="(label, value) in entity.states" :key="value" :value="value">{{ label }}</option>
          </select>
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="postalCode" :errors="errors" class="col-6 form-group">
          <label>Zip</label>
          <input
            v-model="entity.postalCode"
            class="form-control"
            name="postal-code"
          />
        </with-errors>
      </div>
      <h2>Account</h2>
      <p>Bank Account updates require all fields to be filled in</p>
      <div class="row">
        <with-errors on="beneficiaryName" :errors="errors" class="col-6 form-group">
          <label>Account Holder Name</label>
          <input
            v-model="entity.beneficiaryName"
            class="form-control"
            name="beneficiary-name"
          />
        </with-errors>
        <with-errors on="accountCategory" :errors="errors" class="col-6 form-group">
          <label>Account Type</label>
          <select v-model="entity.accountCategory" class="form-control">
            <option disabled value="" />
            <option v-for="(label, value) in entity.accountTypes" :key="value" :value="value">
              {{ label }}
            </option>
          </select>
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="accountNumber" :errors="errors" class="col-6 form-group">
          <label>Account Number</label>
          <input
            v-model="entity.accountNumber"
            class="form-control"
            name="accountNumber"
          >
        </with-errors>
        <with-errors on="routingNumber" :errors="errors" class="col-6 form-group">
          <label>Routing Number</label>
          <input
            v-model="entity.routingNumber"
            class="form-control"
            name="routingNumber"
          />
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="name" :errors="errors" class="col-6 form-group">
          <label>Account Nickname</label>
          <input
            v-model="entity.name"
            class="form-control"
            name="accountNumber"
          >
        </with-errors>
      </div>
      <h2>Primary Contact</h2>
      <div class="row">
        <with-errors on="firstName" :errors="errors" class="col-6 form-group">
          <label>Contact First Name</label>
          <input
            v-model="entity.firstName"
            class="form-control"
            name="firstName"
          />
        </with-errors>
        <with-errors on="lastName" :errors="errors" class="col-6 form-group">
          <label>Contact Last Name</label>
          <input
            v-model="entity.lastName"
            class="form-control"
            name="lastName"
          />
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="email" :errors="errors" class="col-6 form-group">
          <label>Tax Email</label>
          <input
            v-model="entity.email"
            class="form-control"
            name="email"
          />
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="distributionEmail" :errors="errors" class="col-6 form-group">
          <label>Distribution Email</label>
          <input
            v-model="entity.distributionEmail"
            class="form-control"
            name="distributionEmail"
          />
        </with-errors>
      </div>
    </form>
  </modal-form>
</template>

<script>
import ACHDetails from "models/lender_legal_entities/ach_details";
import AjaxService from "services/ajax_service";
import ModalForm from "components/modal_form.vue";
import WithErrors from "components/shared/with_errors";
import MessageBusService from "services/message_bus_service";

export default {
  name: "ach-details-form-modal",
  components: {
    ModalForm,
    WithErrors,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    entity: {
      required: true,
      type: ACHDetails,
    },
  },
  data() {
    return {
      errors: {},
      routes: PSData.routes || {},
      saveDisabled: false,
      bus: MessageBusService.entityBus,
    };
  },
  computed: {
    modalTitle() {
      return "Edit ACH Details";
    },
    safeTin() {
      return "XXX-XX-XXXX";
    },
    showConfirmation() {
      return this.entity.changed;
    },
  },
  methods: {
    onClose() {
      this.errors = {};
      this.$emit("close");
    },
    onSave() {
      this.save();
    },
    save() {
      if (this.entity.changed) {
        const messages = { onSuccess: "ACH Details have been updated", onError: this.onError };

        this.saveDisabled = true;

        return this.updateACHDetails(messages, this.entity)
          .then(data => {
            this.bus.broadcast("entity-updated");
            this.$emit("ach-details-updated", data);
            this.onClose();
          })
          .finally(() => {
            this.saveDisabled = false;
          });
      } else {
        this.onClose();
      }
    },
    updateACHDetails(messages, entity) {
      return AjaxService.withNotifications(messages).patch(
        this.routes.achDetails,
        entity.objectToSend
      );
    },
    onError({ response, data }) {
      if (response.status === 403) {
        return { message: "Unauthorized", status: "error" };
      } else {
        this.errors = data.errors || {};
        return null;
      }
    },
  },
};
</script>
