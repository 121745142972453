import DocGenRequestAccessButton from "components/doc_gen/request_access_button";

$(() => {
  let docGenInvitation = { inProgress: false, sent: false };

  Array.from(document.querySelectorAll(".v-docgen-controller")).forEach(element => {
    new Vue({
      el: element,
      components: {
        DocGenRequestAccessButton,
      },
      data: {
        docGenInvitation: docGenInvitation,
        docGenUserStatus: PSData.docGenUserStatus,
        accountManager: PSData.accountManager,
      },
    });
  });
});
