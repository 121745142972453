<template>
  <base-tooltip
    multilined
    position="is-right"
  >
    <template v-slot:content>
      Documents flagged in this column are outside of the standard audit for this note origination type (Funded/TF/FIO). Additional research may be required<br/>
    </template>
    <base-icon
      icon="question-circle"
      type="hint"
    />
  </base-tooltip>
</template>

<script>
export default {
  name: "standard-doc-tooltip",
};
</script>
