<template>
  <div>
    <base-header size="medium" class="restrictions-header">No Fly List</base-header>
    <breadcrumb-navigation :items="breadcrumbItems" />
    <base-header size="small" class="restrictions-header">{{ name }} </base-header>
  </div>
</template>

<script>
import BreadcrumbNavigation from "components/shared/breadcrumb_navigation";

export default {
  name: "restriction-header",
  components: {
    BreadcrumbNavigation,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    restrictionsRoute: {
      type: String,
      default: "",
    },
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          name: "No Fly List",
          link: this.restrictionsRoute,
        },
        {
          name: this.name,
        },
      ];
    },
  },
};
</script>

<style scoped>
.restrictions-header {
  padding: 15px 0;
}
</style>
