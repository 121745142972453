import get from "lodash/get";
import FundingEntity from "models/bank_center/funding_entity";

export default class ProblemWireRequest {
  constructor(wireRequest = {}) {
    this.wireRequest = wireRequest;
  }

  get id() {
    return this.wireRequest.id;
  }

  get bankProviderName() {
    return get(this.wireRequest, "bankProvider.name");
  }

  get fundingEntityId() {
    return get(this.wireRequest, "series.fundingEntityId");
  }

  get bankAccountId() {
    return this.wireRequest.sourceBankAccountId;
  }

  get seriesId() {
    return get(this.wireRequest, "series.psId") || "N/A";
  }

  get loanId() {
    return get(this.wireRequest, "loan.psId") || "N/A";
  }

  get referenceType() {
    return get(this.wireRequest, "referenceType") || "N/A";
  }

  get requestType() {
    return this.wireRequest.requestType;
  }

  get requestAmount() {
    return this.wireRequest.requestAmount;
  }

  get responseErrors() {
    return this.wireRequest.responseErrors;
  }

  get accountName() {
    return this.wireRequest.accountName;
  }

  static filterByAccounts(wireRequests, accounts) {
    return wireRequests.filter(w => w.getAssociatedAccount(accounts));
  }

  getAssociatedAccount(accounts) {
    const account = accounts.find(a => {
      const accountId = this._getAssociatedId(a);
      return accountId === a.id;
    });
    return account;
  }

  _getAssociatedId(account) {
    if (account instanceof FundingEntity) {
      return this.fundingEntityId;
    } else {
      return this.bankAccountId;
    }
  }
}
