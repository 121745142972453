<template>
  <div class="category-bar-chart" :class="{ 'show-labels': showLabels }">
    <slot name="header"></slot>
    <div class="body">
      <div class="chart" ref="chart"></div>
    </div>
  </div>
</template>

<script>
import { format } from "d3";
import c3 from "c3";

export default {
  name: "category-bar-chart",
  props: {
    columnData: Object,
    chartOptions: Object,
  },
  data() {
    return {
      options: {
        padding: {
          left: 40,
          right: 20,
        },
        axis: {
          x: {
            type: "category",
            tick: {
              outer: false,
            },
          },
          y: {
            padding: { top: 0, bottom: 0 },
            max: 1.1,
            min: 0,
            tick: {
              outer: false,
              format: this.chartOptions.axisYTickFormat,
              values: [0, 0.5, 1],
            },
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          show: false,
        },
        bar: {
          width: {
            ratio: 0.75,
          },
        },
        size: {
          height: undefined,
        },
      },
    };
  },
  computed: {
    showLabels() {
      return this.columnData.allValuesZero || false;
    },
  },
  methods: {
    render() {
      this.options.size.height = this.chartOptions.sizeHeight;
      this.options.axis.x.categories = Object.values(this.chartOptions.categories);
      this.options.data = this.prepareData();
      this.c3 = c3.generate({
        ...this.options,
        bindto: this.$refs.chart,
      });
    },
    prepareData() {
      const columns = this.columnData.columns;
      const colors = this.columnData.colors;
      const label = this.chartOptions.appendLabel;

      return {
        type: "bar",
        labels: {
          format: function(value) {
            return format(",.0%")(value) + (label ? " " + label : "");
          },
        },
        columns: columns,
        color: function(color, d) {
          return colors[d.index];
        },
      };
    },
    destroy() {
      if (this.c3) {
        this.c3.destroy();
        this.c3 = null;
      }
    },
  },
  mounted() {
    this.render();
  },
  destroyed() {
    this.destroy();
  },
  watch: {
    columnData: {
      deep: true,
      handler() {
        this.destroy();
        this.render();
      },
    },
  },
};
</script>
