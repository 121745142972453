import { MANAGE_TASKLIST_TEMPLATE_ROUTE } from "models/tasklist_template";
import DateFormatter, { DATE_TIME_FORMAT } from "models/formatters/date_formatter";

export const MANAGE_TASKLIST_MAPPING_ROUTE = "/manage/settings/task_mappings";
export const MANAGE_TASKLIST_MAPPINGS_ROUTE = "/manage/settings/task_mappings/bulk_update";
export const MANAGE_TASKLIST_MILESTONE_ROUTE = "/manage/settings/milestones";

export const manageTasklistTemplateRevisionPath = (templateId, revisionId) => {
  return `${MANAGE_TASKLIST_TEMPLATE_ROUTE}/${templateId}/revisions/${revisionId}`;
};

export const manageTasklistMilestonePath = milestoneId => {
  return `${MANAGE_TASKLIST_MILESTONE_ROUTE}/${milestoneId}`;
};

export const tasklistTemplateRevisionRoute = (templateId, revisionId) => {
  return `/templates/${templateId}/revisions/${revisionId}`;
};

export const manageTasklistTemplateTaskMappingPath = taskMappingId => {
  return `${MANAGE_TASKLIST_MAPPING_ROUTE}/${taskMappingId}`;
};

export default class TasklistTemplateRevision {
  constructor(record = {}) {
    this.update(record);
  }

  get revisionNumberLabel() {
    return this.revisionNumber ? `V${this.revisionNumber}` : "Draft";
  }

  get publishedAtFormatted() {
    return DateFormatter.formatTime(this.publishedAt, DATE_TIME_FORMAT);
  }

  get objectToSave() {
    return {
      id: this.id,
    };
  }

  update(record) {
    this._raw = record;
    Object.assign(this, record);
  }

  reset() {
    this.update(this._raw);
  }

  get copy() {
    return new TasklistTemplateRevision(this._raw);
  }
}
