import DocumentTypes from "collections/document_types";

export default class Documents {
  constructor(documents, documentTypes) {
    this.documents = [...documents];
    this.documentTypeMap = DocumentTypes.wrapToMap(documentTypes);
  }

  sortByDocumentTypeAndName(a, b) {
    const order = DocumentTypes.sortByName(
      this.documentTypeMap[a.documentType],
      this.documentTypeMap[b.documentType]
    );

    if (order === 0) {
      return a.fullName.localeCompare(b.fullName);
    } else {
      return order;
    }
  }

  sortByDocumentTypeUntypedFirst(a, b) {
    const order = DocumentTypes.sortByNameUntypedFirst(
      this.documentTypeMap[a.documentType],
      this.documentTypeMap[b.documentType]
    );

    if (order === 0) {
      return a.fullName.localeCompare(b.fullName);
    } else {
      return order;
    }
  }

  sortByNameAndDate(a, b) {
    const order = a.fullName.localeCompare(b.fullName);
    if (order === 0) {
      return b.createdAt - a.createdAt;
    } else {
      return order;
    }
  }

  orderedByTypeAndName() {
    return this.documents.sort(this.sortByDocumentTypeAndName.bind(this));
  }

  orderedByNameAndDate() {
    return this.documents.sort(this.sortByNameAndDate);
  }

  orderedByDocumentType() {
    return this.documents.sort(this.sortByDocumentTypeUntypedFirst.bind(this));
  }
}
