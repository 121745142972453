<template>
  <base-form
    v-if="editMode"
    :save-disabled="saveDisabled"
    @save="onSave"
    @cancel="onCancel"
  >
    <base-text-field
      v-model="notes"
      placeholder="PeerStreet Notes"
      type="textarea"
    />
  </base-form>
  <div v-else class="notes-content">
    {{ value }}
  </div>
</template>
<script>
import AjaxService from "services/ajax_service";

export default {
  name: "LoanPeerstreetNotes",
  props: {
    value: {
      type: String,
      default: "",
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      required: true,
    },
  },
  data: function() {
    return {
      saveDisabled: false,
      notes: this.value,
    };
  },
  methods: {
    onCancel: function() {
      this.$emit("cancel");
    },
    onSave: function() {
      this.saveDisabled = true;

      const messages = {
        onError: ({ response }) => {
          if (response.status === 403) {
            return { message: "Unauthorized", status: "error" };
          } else {
            return { message: response.error, status: "error" };
          }
        },
      };
      AjaxService.withNotifications(messages)
        .patchJSON(this.path, {
          loan: { peerstreet_notes: this.notes },
        })
        .then(response => {
          this.$emit("input", response.peerstreet_notes);
          this.$emit("save");
        })
        .finally(() => {
          this.saveDisabled = false;
        });
    },
  },
};
</script>
<style scoped>
.notes-content {
  white-space: pre-line;
}
</style>
