<template>
  <div class="borrowers-info">
    <div v-for="borrower in borrowers" :key="borrower.name" class="borrower-info">
      <p class="name"><strong>Borrower Name:</strong> {{ borrower.formattedName | missingValue }}</p>
      <p class="address"><strong>Borrower Address:</strong> {{ borrower.address | missingValue }}</p>
      <p class="phone"><strong>Borrower Phone:</strong> {{ borrower.phone | missingValue }}</p>
      <p class="email"><strong>Borrower Email:</strong> {{ borrower.email | missingValue }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "borrowers-info",
  props: {
    borrowers: Array,
  },
};
</script>
