<template>
  <div class="institution-sales-tracking-section-header batch-form-parent">
    <h3>
      Move Loans to the Latest Batch?
    </h3>
    <div class="batch-form-child">
      <h3>Select a buyer</h3>
      <select
        id="buyers-list"
        v-model="buyerName"
        class="form-control required form-control-md"
      >
        <option
          value=""
          selected
        >
          Select from dropdown
        </option>
        <option
          v-for="buyer in buyerPrograms"
          :key="buyer.name"
          :value="buyer.name"
        >
          {{ buyer.label }}
        </option>
      </select>
    </div>
    <div class="batch-form-child">
      <h3>Loan IDs</h3>
      <textarea
        id="loan-ids"
        v-model="loanIds"
        class="batch-loan-ids"
        rows="20"
        @keypress="isNumber"
      ></textarea>
    </div>
    <button
      id="move-button"
      class="btn-primary-cta loans-button"
      :disabled="isInvalidForm"
      @click="moveLoans"
    >
      Move Loans
    </button>
    <button
      id="cancel-button"
      class="btn-cancel-cta loans-button"
      @click="cancel"
      :disabled="isMoving"
    >
      Cancel
    </button>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";

import { error } from "services/notification";

export default {
  name: "BatchForm",
  props: {
    buyerPrograms: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      buyerName: "",
      loanIds: "",
      routes: PSData.routes,
      isMoving: false,
    };
  },
  computed: {
    buyerNames() {
      return this.buyerPrograms.map(buyer => buyer.name);
    },
    isInvalidForm() {
      return !(this.isValidBuyer && this.isValidLoanIds) || this.isMoving;
    },
    isValidBuyer() {
      return this.buyerNames.includes(this.buyerName);
    },
    isValidLoanIds() {
      return this.loanIds !== "" && /^(?: *\d+ *(?:\n|$))+$/.test(this.loanIds);
    },
  },
  methods: {
    isNumber(e) {
      if (!/\d/.test(e.key) && e.code !== "Enter") {
        return e.preventDefault();
      }
    },
    async cancel() {
      this.$emit("cancel-move-loans");
    },
    async moveLoans() {
      this.isMoving = true;

      const sanitizedLoanIds = this.loanIds
        .replace(/ /g, "")
        .trim()
        .split("\n");

      const requestBody = {
        buyer_name: this.buyerName,
        loan_ids: sanitizedLoanIds,
      };

      const url = this.routes.manageInstitutionLoansBatch;
      await AjaxService.postJSON(url, requestBody)
        .then(res => {
          window.location.assign(`institution_sales_trackings/${res.batch_id}`);
        })
        .catch(res => {
          error(res.data.errors);
          this.isMoving = false;
        });
    },
  },
};
</script>
