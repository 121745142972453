import CopyToClipboard from "components/copy_to_clipboard";
import LenderTagsMixin from "../../mixins/manage/lenders/tags";
import VueRouter from "vue-router";

import WorkflowsIndexPage from "components/manage/workflows/index";
import WorkflowOverview from "components/manage/workflows/show";
import Step from "components/workflows/step";
import PiecesOfCollateral from "components/tags/pieces_of_collateral";

$(() => {
  if (!document.querySelector(".v-manage-workflows-controller")) {
    return;
  }

  const routes = [
    { path: "/", component: WorkflowsIndexPage, props: { loan: PSData.loan } },
    { path: "/workflows/:id", component: WorkflowOverview },
    { path: "/pending_steps/:id", component: Step },
  ];
  const router = new VueRouter({
    routes,
  });

  new Vue({
    router,
    el: document.querySelector(".v-manage-workflows-controller"),
    components: {
      CopyToClipboard,
      PiecesOfCollateral,
    },
    mixins: [LenderTagsMixin],
    data: {
      loan: PSData.loan,
    },
  });
});
