<template>
  <div class="institution-sales-tracking-table">
    <table class="table">
      <thead>
        <tr>
          <th>Batch ID</th>
          <th class="amount">Sale No. (Month)</th>
          <th>Start Date</th>
          <th>Target Offer</th>
          <th class="amount">Days Left to Offer</th>
          <th>Offered / In-Review</th>
          <th>Target Close</th>
          <th class="amount">Days to Close</th>
          <th>Wired</th>
          <th>Sales Docs Done</th>
          <th class="amount">Batch Amount ($)</th>
          <th class="amount">Batch Amount (units)</th>
          <th class="amount">Closed Sale</th>
          <th class="amount">
            <div v-popover="{placement: 'top'}">Passed On Rate</div>
            <div class="hidden">The percentage of loans rejected by the institution by loan volume in this batch</div>
          </th>
          <th class="amount">
            <div v-popover="{placement: 'top'}">Avg. Rate</div>
            <div class="hidden">The loan value weighted average net investor rate of the accepted loans in the batch</div>
          </th>
          <th class="amount">
            <div v-popover="{placement: 'top'}">Avg. LTV</div>
            <div class="hidden">The loan value weighted average LTV of the accepted loans in the batch</div>
          </th>
          <th class="amount">
            <div v-popover="{placement: 'top'}">Avg. Loan Amount</div>
            <div class="hidden">The average loan value of the offered loans in the batch</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ batch.displayName}} {{ batch.displayNumber }}</td>
          <td class="amount">{{ batch.salesNumber }}</td>
          <td><badge :date="batch.startDate"></badge></td>
          <td>
            <set-date-button
              v-on="$listeners"
              :can-update-batch="canUpdateBatch"
              :field-name="'targetOfferDate'"
              :batch="batch"
            ></set-date-button>
          </td>
          <td class="amount">{{ daysToOffer }}</td>
          <td>
            <set-date-button
              v-on="$listeners"
              :can-update-batch="canUpdateBatch"
              :field-name="'offerDate'"
              :batch="batch"
            ></set-date-button>
          </td>
          <td><badge :date="batch.targetCloseDate"></badge></td>
          <td class="amount">{{ daysToTargetClose }}</td>
          <td>
            <set-date-button
              v-on="$listeners"
              :can-update-batch="canUpdateBatch"
              :field-name="'wireDate'"
              :batch="batch"
            ></set-date-button>
          </td>
          <td>
            <set-date-button
              v-on="$listeners"
              :can-update-batch="canUpdateBatch"
              :field-name="'salesDocsCompletedDate'"
              :batch="batch"
            ></set-date-button>
          </td>
          <td class="amount">{{ batch.batchAmount | money }}</td>
          <td class="amount">{{ batch.loanOfferingsCount }}</td>
          <td class="amount">{{ batch.closedSale | money }}</td>
          <td class="amount">{{ batch.passOnRate * 100 | percent(0) }}</td>
          <td class="amount">{{ batch.averageRate * 100 | percent }}</td>
          <td class="amount">{{ batch.averageLtv * 100 | percent(0) }}</td>
          <td class="amount">{{ batch.averageLoanAmount | money }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Badge from "./badge";
import SetDateButton from "./set_date_button";

export default {
  components: {
    Badge,
    SetDateButton,
  },
  props: {
    batch: Object,
    canUpdateBatch: Boolean,
  },
  computed: {
    daysToOffer() {
      if (this.batch.daysToOffer !== null && this.batch.daysToOffer !== undefined) {
        return this.batch.daysToOffer;
      } else {
        return "N/A";
      }
    },
    daysToTargetClose() {
      if (this.batch.daysToTargetClose !== null && this.batch.daysToTargetClose !== undefined) {
        return this.batch.daysToTargetClose;
      } else {
        return "N/A";
      }
    },
  },
};
</script>
