export default class Policy {
  constructor(policy) {
    this.policy = policy || {};
  }

  canAddRule() {
    return this.policy.addRule.allowed;
  }

  canAddDistributionFee() {
    return this.policy.addDistributionFee.allowed;
  }

  canAddSubservicerFee() {
    return this.policy.addSubservicerFee.allowed;
  }
}
