<template>
  <modal-view :show="show" :on-close="onClose" class="base-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          {{ operation == "create" ? "Add" : "Edit" }} Closing Agent
          <a @click.prevent="onClose" href="#close">&times;</a>
        </h4>
      </div>

      <div class="modal-body">
        <form @keypress.enter="submit">
          <div class="row">
            <div class="col-12 form-group">
              <with-errors on="companyName" :errors="errors">
                <label>Company</label>
                <input v-model="agent.companyName" type="text" class="form-control" />
              </with-errors>
            </div>
          </div>

          <div class="row">
            <div class="col-6 control-wrapper">
              <with-errors on="firstName" :errors="errors">
                <label>First Name</label>
                <input v-model="agent.firstName" type="text" class="form-control" />
              </with-errors>
            </div>
            <div class="col-6 control-wrapper">
              <with-errors on="lastName" :errors="errors">
                <label>Last Name</label>
                <input v-model="agent.lastName" type="text" class="form-control" />
              </with-errors>
            </div>
          </div>

          <div class="row">
            <div class="col-6 control-wrapper">
              <with-errors on="phone" :errors="errors">
                <label>Phone Number</label>
                <input v-model="agent.phone" v-mask:phone type="text" class="form-control" />
              </with-errors>
            </div>
            <div class="col-6 control-wrapper">
              <with-errors on="email" :errors="errors">
                <label>Email</label>
                <input v-model="agent.email" type="text" class="form-control" />
              </with-errors>
            </div>
          </div>

          <div class="row">
            <div class="col-6 control-wrapper">
              <with-errors on="agentType" :errors="errors">
                <label>Type</label>
                <select v-model="agent.agentType" class="form-control">
                  <option value></option>
                  <option v-for="(label, value) in closingAgentTypes" :key="value" :value="value">
                    {{ label }}
                  </option>
                </select>
              </with-errors>
            </div>
            <div class="col-6 control-wrapper">
              <with-errors on="orderNumber" :errors="errors">
                <label>Order ID</label>
                <input v-model="agent.orderNumber" type="text" class="form-control" />
              </with-errors>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button @click.prevent="onClose" class="btn btn-link">
          Cancel
        </button>
        <button @click.prevent="submit" :class="{ disabled: inFlight }" class="btn btn-action-cta">
          Save
        </button>
      </div>
    </div>
  </modal-view>
</template>

<script>
import ClosingAgent from "models/closing_agent";
import ModalView from "components/modal_view";
import WithErrors from "components/shared/with_errors";
import { Mask } from "directives/inputs";
import AjaxService from "services/ajax_service";
import { error } from "services/notification";

export default {
  name: "closing-agent-modal",
  components: {
    ModalView,
    WithErrors,
  },
  directives: {
    Mask,
  },
  props: {
    show: {
      required: true,
    },
    closingAgentTypes: {
      type: Object,
      default: {},
    },
    agentToEdit: {
      type: Object,
      default: {},
    },
    operation: {
      type: String,
      default: "create",
    },
  },
  data() {
    return {
      agent: new ClosingAgent(this.agentToEdit),
      routes: PSData.routes || {},
      inFlight: false,
      errors: {},
    };
  },
  computed: {},
  watch: {
    agentToEdit(value) {
      this.agent = new ClosingAgent(value);
    },
  },
  methods: {
    submit() {
      if (this.inFlight) return;

      this.inFlight = true;

      return this.action()
        .then(data => this.onComplete(data))
        .catch(({ response, data }) => {
          if (response.status === 422) {
            this.errors = data.errors;
          } else {
            error("Something went wrong");
          }
        })
        .finally(() => (this.inFlight = false));
    },
    action() {
      if (this.operation === "create") {
        return AjaxService.post(this.actionUrl(), this.agent.toHash());
      }
      return AjaxService.patch(this.actionUrl(), this.agent.toHash());
    },
    actionUrl() {
      const baseUrl = this.routes.loanClosingAgents;

      if (this.operation === "create") {
        return baseUrl;
      }
      return `${baseUrl}/${this.agent.id}`;
    },
    onComplete(agent) {
      if (this.operation === "create") {
        this.$emit("closing-agent-created", agent);
      } else {
        this.$emit("closing-agent-updated", agent);
      }
      this.onClose();
    },
    onClose() {
      this.$emit("close");
      this.agent = new ClosingAgent(this.agentToEdit);
      this.errors = {};
    },
  },
};
</script>
