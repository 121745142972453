<template>
  <div>
    <div class="py-4 is-flex">
      <span class="has-text-weight-semibold pr-2">{{ recordName }}</span>
      <base-button type="icon" size="small" icon="pencil-alt" @click="editRecord" />
      <base-button type="icon" size="small" icon="trash-alt" @click="deleteRecord" />
    </div>
    <div class="row loan-details-view-panel-row">
      <div class="col-6 loan-details-view-panel-column">
        <details-list :data="detailsList1" hide-empty />
      </div>
      <div class="col-6 loan-details-view-panel-column">
        <details-list :data="detailsList2" hide-empty />
      </div>
    </div>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import DetailsList from "components/loan_details/list";
import TrackRecordDeal from "models/track_record_deal";
import format from "utils/format";

export default {
  components: {
    DetailsList,
  },
  props: {
    record: {
      type: TrackRecordDeal,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data: function() {
    return {
      routes: PSData.routes,
      showModal: false,
      deleteDisabled: false,
    };
  },
  computed: {
    detailsList1() {
      return [
        { label: "Property Address", value: this.record.propertyAddress, show: true },
        { label: "City", value: this.record.propertyCity, show: true },
        { label: "State", value: this.record.propertyStateLabel, show: true },
        { label: "ZIP Code", value: this.record.propertyZip, show: true },
        { label: "Acquisition Date", value: this.record.formattedAcquisitionDate, show: true },
        {
          label: "Acquisition Price",
          value: this.formatCurrency(this.record.acquisitionPrice),
          show: true,
        },
        { label: "Disposition Date", value: this.record.formattedDispositionDate, show: true },
        {
          label: "Disposition Price",
          value: this.formatCurrency(this.record.dispositionPrice),
          show: true,
        },
      ];
    },
    detailsList2() {
      return [
        { label: "Owner Listed on Title", value: this.record.owner, show: true },
        { label: "Ownership", value: this.record.ownership, show: true },
        {
          label: "Renovation Funds Used",
          value: this.formatCurrency(this.record.renovationFundsUsed),
          show: true,
        },
        {
          label: "Gross Rental Income",
          value: this.formatCurrency(this.record.grossRentalIncome),
          show: true,
        },
        {
          label: "Current Cost Basis",
          value: this.formatCurrency(this.record.currentCostBasis),
          show: true,
        },
        {
          label: "Present Market Value",
          value: this.formatCurrency(this.record.presentMarketValue),
          show: true,
        },
        {
          label: "Current Amount of Mortgages and Liens",
          value: this.formatCurrency(this.record.mortgagesAndLiensAmount),
          show: true,
        },
        { label: "Loan Strategy", value: this.record.loanStrategyText, show: true },
      ];
    },
    recordName() {
      const strategyName = this.record.loanStrategyText || "Track Record Deal";
      return `${strategyName} #${this.index + 1}`;
    },
  },
  methods: {
    editRecord() {
      this.$emit("edit", this.record.copy);
    },
    deleteRecord() {
      if (!window.confirm("Delete record?")) return;

      this.deleteDisabled = true;
      return AjaxService.withNotifications()
        .delete(`${this.routes.trackRecordDeals}/${this.record.id}`)
        .then(data => {
          this.$emit("delete", data);
        })
        .finally(() => {
          this.deleteDisabled = false;
        });
    },
    formatCurrency(value) {
      return value && format({ type: "money", value });
    },
  },
};
</script>
<style scoped>
.action-button {
  padding-left: var(--space-unit-sm);
  padding-right: var(--space-unit-sm);
}
</style>
