<template>
  <div v-if="showBanner" class="disclosure-banner">
    <base-message customHeader @close="onClose">
      <template v-slot:header>
        Send e-sign requests for Initial Disclosures
      </template>

      <p>
        Simplify the wholesale compliance process by sending e-signature requests for initial disclosures to borrowers.
        <br>
        Once e-signed, the disclosures will be added to this loan's documents tab.
      </p>

      <div class="controls">
        <base-button @click="openHelp">Learn More</base-button>
        <base-button type="primary" @click="openModal">Prepare Disclosures</base-button>
      </div>
    </base-message>
  </div>
</template>

<script>
import GlobalBusService from "services/global_bus_service";
import LocalStorageService from "services/local_storage_service";

const HELP_LINK = "https://peerstreet.zendesk.com/hc/en-us/articles/360055350452";
const HIDE_BANNER = "ps.disclosure_banner.hide";

export default {
  name: "disclosure-banner",
  props: {
    loanId: {
      type: String,
      required: true,
    },
  },
  data() {
    const storage = new LocalStorageService();

    return {
      storage,
      settings: storage.getItem(HIDE_BANNER) || [],
    };
  },
  computed: {
    showBanner() {
      return !this.settings.find(el => el === this.loanId);
    },
  },
  methods: {
    openHelp() {
      window.open(HELP_LINK, "_blank");
    },
    openModal() {
      GlobalBusService.$emit("open-prepare-disclosure");
    },
    onClose() {
      this.settings.push(this.loanId);
      this.storage.setItem(HIDE_BANNER, this.settings);
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.disclosure-banner {
  margin: 24px 0;
}

.disclosure-banner .base-tag {
  margin: 0;
  position: relative;
  top: -2px;
}

.disclosure-banner .controls {
  padding: 16px 0 0;
}

.disclosure-banner .controls .button {
  margin-right: 16px;
}
.disclosure-banner >>> .base-button.is-normal:not(.is-primary) {
  color: var(--color-grey-72);
}
</style>
