<template>
  <div class="analysts-dropdown">
    <multi-select2
      class="form-control"
      v-model="selectedAnalystIds"
      :after-render="onRendered"
      :options="{ data: orderedAnalysts, placeholder: 'Analyst' }"
    />
  </div>
</template>

<script>
import { RequestFilterLoansByAnalyst } from "models/reports/hotlist_loans/commands/hotlist_loans_commands";
import Event from "models/event";
import FilterParams from "models/hotlist/filter_params";
import MultiSelect2 from "components/shared/multi_select2";

const ALL_ANALYSTS_ITEM = { id: "all", text: "All Analysts" };
const UNASSIGNED_ITEM = { id: "0", text: "Unassigned" };

export default {
  name: "analysts-dropdown",
  components: {
    MultiSelect2,
  },
  props: {
    analysts: Array,
  },
  data() {
    return {
      selectedAnalystIds: [],
    };
  },
  watch: {
    selectedAnalystIds(analystIds) {
      new RequestFilterLoansByAnalyst(new Event(), {
        selectedAnalystIds: analystIds,
      }).execute();
    },
  },
  methods: {
    onRendered() {
      this.selectedAnalystIds = FilterParams.instance.loadParams().analystIds;
    },
  },
  computed: {
    orderedAnalysts() {
      return [ALL_ANALYSTS_ITEM, UNASSIGNED_ITEM].concat(
        this.analysts
          .map(a => ({ id: a.id, text: a.name }))
          .sort((a, b) => a.text.localeCompare(b.text))
      );
    },
  },
};
</script>
