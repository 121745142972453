<template>
  <div class="loan-task-assignee">
    <div v-if="assignee.nameAbbreviation" class="loan-task-assignee-abbreviation">
      {{ assignee.nameAbbreviation }}
    </div>
    <i v-else class="far fa-lg fa-user-circle loan-task-assignee-add-icon" aria-hidden="true" />

    <span class="loan-task-assignee-name">{{ assignee.name || "Unassigned" }}</span>
  </div>
</template>

<script>
export default {
  props: {
    assignee: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
