<template>
  <tr>
    <td>
      <a href="#" @mouseover="getRelatedRequests" v-popover.dynamic>
        {{wireRequest.responseErrors}}
      </a>
      <div class="hidden">
        <div class="related-requests">
          <div class="ps-loader ps-loader-small related-requests-loader" v-show="isLoading"></div>
          <div class="related-requests-list">
            <div class="related-requests-group" v-for="wireRequest in wireRequests" :key="wireRequest.id">
              <div><span class="related-requests-row-title">Source of capital: </span>{{wireRequest.accountName}}</div>
              <div><span class="related-requests-row-title">Bank: </span>{{wireRequest.bankProviderName}}</div>
              <div><span class="related-requests-row-title">Amount: </span>{{wireRequest.requestAmount | accounting}}</div>
              <div><span class="related-requests-row-title">Bank Response: </span>{{wireRequest.responseErrors}}</div>
            </div>
            <div v-if="wireRequests.length === 0">
              No dependencies.
            </div>
          </div>
        </div>
      </div>
    </td>
    <td>{{wireRequest.accountName}}</td>
    <td>{{wireRequest.bankProviderName}}</td>
    <td>{{wireRequest.referenceType}}</td>
    <td>{{wireRequest.requestType}}</td>
    <td>{{wireRequest.loanId}}</td>
    <td>{{wireRequest.seriesId}}</td>
    <td>{{wireRequest.requestAmount | accounting}}</td>
  </tr>
</template>
<script>
import AjaxService from "services/ajax_service";
import ProblemWireRequest from "models/bank_center/problem_wire_request";

export default {
  props: {
    wireRequest: Object,
  },
  data() {
    return {
      isLoading: false,
      isLoaded: false,
      wireRequests: [],
    };
  },
  methods: {
    getRelatedRequests() {
      if (this.isLoading || this.isLoaded) {
        return;
      }
      this.isLoading = true;
      const url = `/manage/bank_center/problem_wire_requests/${
        this.wireRequest.id
      }/dependent_wire_requests`;
      return AjaxService.get(url).then(({ wireRequests }) => {
        this.wireRequests = wireRequests.map(w => new ProblemWireRequest(w));
        this.isLoading = false;
        this.isLoaded = true;
      });
    },
  },
};
</script>
