<template>
  <div class="wrapper">
    <div class="double-column header-column">
      <h3>Loan Information</h3>
      <h6>* Required</h6>
    </div>
    <h6 class="form-label">Loan Product*</h6>
    <div
      class="radio-wrapper"
      data-cy="loan-product-radio"
    >
      <base-radio
        class="radio-element"
        id="residential-bridge"
        :value="scenario.loanProduct"
        :native-value="LoanProduct.residentialBridge.value"
        @input="updateLoanProduct"
        >
        {{ LoanProduct.residentialBridge.title }}
      </base-radio>
      <base-tooltip :label="locale.tooltip.disabledRental" position="is-top" multilined>
        <base-radio
          class="radio-element"
          id="residential-rental-long-term"
          :value="scenario.loanProduct"
          :native-value="LoanProduct.residentialRentalLongTerm.value"
          @input="updateLoanProduct"
          :disabled="isRentalDisabled"
          >
          {{ LoanProduct.residentialRentalLongTerm.title }}
        </base-radio>
      </base-tooltip>
    </div>
    <component :is="currentFieldsetComponent" :scenario="scenario" :constraints="constraints"/>
  </div>
</template>

<script>
import { LoanProduct, LoanPurpose, PropertyType, ResidencyStatus, TrackRecord } from "./enums";
import { ResidentialBridgeFieldset, ResidentialForRentFieldset } from "./fieldsets";

export default {
  name: "scenario-form",
  components: {
    ResidentialBridgeFieldset,
    ResidentialForRentFieldset,
  },
  inject: ["locale"],
  props: {
    scenario: Object,
    constraints: Object,
    isRentalDisabled: Boolean,
  },
  data() {
    return {
      LoanProduct,
      LoanPurpose,
      PropertyType,
      ResidencyStatus,
      TrackRecord,
      errors: {},
      currentFieldsetComponent: this.fieldsetComponent(this.scenario.loanProduct),
    };
  },
  methods: {
    fieldsetComponent(loanProduct) {
      switch (loanProduct) {
        case LoanProduct.residentialBridge.value:
          return ResidentialBridgeFieldset;
        case LoanProduct.residentialRentalLongTerm.value:
          return ResidentialForRentFieldset;
      }
    },
    updateLoanProduct(loanProduct) {
      this.currentFieldsetComponent = this.fieldsetComponent(loanProduct);
      this.$emit("loanProductUpdated", loanProduct);
    },
  },
};
</script>

<style scoped>
::v-deep .form-label {
  color: var(--color-grey-86);
  font-size: var(--font-size);
  font-weight: 600;
  line-height: var(--line-height);
  margin-bottom: var(--space-unit-sm);
}

::v-deep .form-label .tooltip-trigger .has-text-ps-hint-icon {
  height: auto;
  width: auto;
}

::v-deep .double-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
::v-deep .radio-wrapper {
  display: flex;
  flex-direction: row;
}
::v-deep .radio-element {
  padding-right: 15px;
}
::v-deep .header-column h3 {
  color: var(--color-grey-86);
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 14px;
}
::v-deep .header-column h6 {
  line-height: 36px;
  margin: 0;
  padding: 0;
}
::v-deep .absolute-tooltip,
.absolute-tooltip > span:first-child {
  position: relative;
}
::v-deep .absolute-tooltip .base-tooltip {
  position: absolute;
}
::v-deep .absolute-tooltip .base-tooltip .tooltip-trigger .has-text-ps-hint-icon {
  height: auto;
  margin-left: 8px;
  top: 2px;
  width: auto;
}
</style>
