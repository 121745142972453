<template>
  <div class="container">
    <h4 class="loan-progress row">
      Loan Progress
    </h4>
    <div class="row manage-loan-status-tracker">
      <div class="col-sm">
        <h3>Overall (Combined) Tasks</h3>
        <loan-status-tracker :tasks="tasks" completed-color="loan-status-tracker-overall-completed" />
      </div>
      <div class="col-sm">
        <h3>Internal Tasks</h3>
        <loan-status-tracker :tasks="internalTasks" completed-color="loan-status-tracker-internal-completed" />
      </div>
      <div class="col-sm">
        <h3>Lender Tasks</h3>
        <loan-status-tracker :tasks="lenderTasks" />
      </div>
    </div>
  </div>
</template>

<script>
import LoanStatusTracker from "components/loan_status_tracker";

export default {
  name: "manage-loan-status-tracker",
  components: {
    LoanStatusTracker,
  },
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    lenderTasks() {
      return this.tasks.filter(task => task.definition.taskType === "lender");
    },
    internalTasks() {
      return this.tasks.filter(task => task.definition.taskType !== "lender");
    },
  },
};
</script>
<style scoped>
h3 {
  font-weight: 600;
  text-align: center;
}

.loan-progress {
  font-size: 16px;
  font-weight: 600;
}

.container {
  margin-top: 40px;
  padding-left: 0 !important;
}

.manage-loan-status-tracker {
  border-radius: 3px;
  border: 1px solid #ededed;
  padding: 20px;
}
</style>
<style>
.loan-status-tracker-overall-completed {
  background-color: #3d7a1f !important;
}

.loan-status-tracker-internal-completed {
  background-color: #52a329 !important;
}
</style>
