<template>
  <view-panel class="loan-details-view-panel property-management-section">
    <div v-show="!inEdit">
      <h3 class="property-management-header clearfix">
        Property Management
        <button class="btn btn-action-modal float-right" @click="onEdit">
          Edit
        </button>
      </h3>
      <div class="row loan-details-view-panel-row">
        <div class="col-6 loan-details-view-panel-column">
          <details-list :data="columnRows" />
        </div>
      </div>
    </div>
    <management-form
      v-show="inEdit"
      :propertyManagement="propertyManagement"
      @on-cancel="onCancel"
      @on-save="onSave"
    />
  </view-panel>
</template>

<script>
import ViewPanel from "components/view_panel.vue";
import DetailsList from "components/loan_details/list";
import ManagementForm from "./form";
import PropertyManagement from "models/property_management";

export default {
  name: "management",
  components: {
    ViewPanel,
    DetailsList,
    ManagementForm,
  },
  data() {
    return {
      inEdit: false,
      propertyManagement: new PropertyManagement(PSData.propertyManagement, PSData.propertyOptions),
    };
  },
  computed: {
    columnRows() {
      return [
        {
          label: "Is the property managed by a 3rd party?",
          value: this.propertyManagement.agreementPresentYN,
          show: true,
        },
        {
          label: "Name",
          value: this.propertyManagement.name,
          show: this.propertyManagement.agreementPresent,
        },
        {
          label: "Address",
          value: this.propertyManagement.address,
          show: this.propertyManagement.agreementPresent,
        },
        {
          label: "City",
          value: this.propertyManagement.city,
          show: this.propertyManagement.agreementPresent,
        },
        {
          label: "State",
          value: this.propertyManagement.state,
          show: this.propertyManagement.agreementPresent,
        },
        {
          label: "ZIP Code",
          value: this.propertyManagement.zipCode,
          show: this.propertyManagement.agreementPresent,
        },
        {
          label: "Phone #",
          value: this.propertyManagement.phone,
          show: this.propertyManagement.agreementPresent,
        },
        {
          label: "Email Address",
          value: this.propertyManagement.email,
          show: this.propertyManagement.agreementPresent,
        },
        {
          label: "Name of Management Agreement",
          value: this.propertyManagement.agreementName,
          show: this.propertyManagement.agreementPresent,
        },
        {
          label: "Agreement Date",
          value: this.propertyManagement.agreementDate,
          show: this.propertyManagement.agreementPresent,
        },
        {
          label: "Management Fees",
          value: this.managementFeesDisplay,
          show: this.propertyManagement.agreementPresent,
        },
      ];
    },
    managementFeesDisplay() {
      if (this.managementFeesAmountDisplay === "--" && this.managementFeesPointsDisplay === "--") {
        return null;
      } else {
        return `${this.managementFeesPointsDisplay} | ${this.managementFeesAmountDisplay}`;
      }
    },
    managementFeesAmountDisplay() {
      const amount = this.propertyManagement.managementFeesAmount;
      return amount ? `$${amount}` : "--";
    },
    managementFeesPointsDisplay() {
      const points = this.propertyManagement.managementFeesPoints;
      return points ? `${points}%` : "--";
    },
  },
  methods: {
    onEdit() {
      this.inEdit = true;
    },
    onCancel() {
      this.inEdit = false;
      this.propertyManagement.reset();
    },
    onSave(data) {
      this.propertyManagement.update(data);
      this.inEdit = false;
    },
  },
};
</script>
