<template>
  <tr :data-path="batch.routes.details">
    <td>{{ batch.buyerName }}</td>
    <td>{{ batch.displayName}} {{ batch.displayNumber }}</td>
    <td class="amount">{{ batch.salesNumber }}</td>
    <td class="amount">{{ batch.loanOfferingsCount }}</td>
    <td><badge :date="batch.startDate"></badge></td>
    <td><badge :date="batch.targetOfferDate"></badge></td>
    <td class="amount">{{ daysToOffer }}</td>
    <td><badge :date="batch.offerDate"></badge></td>
    <td><badge :date="batch.targetCloseDate"></badge></td>
    <td class="amount">{{ daysToTargetClose }}</td>
    <td><badge :date="batch.wireDate"></badge></td>
    <td><badge :date="batch.salesDocsCompletedDate"></badge></td>
  </tr>
</template>
<script>
import Badge from "./badge";
export default {
  components: {
    Badge,
  },
  props: {
    batch: Object,
  },
  computed: {
    daysToOffer() {
      if (this.batch.daysToOffer !== null && this.batch.daysToOffer !== undefined) {
        return this.batch.daysToOffer;
      } else {
        return "N/A";
      }
    },
    daysToTargetClose() {
      if (this.batch.daysToTargetClose !== null && this.batch.daysToTargetClose !== undefined) {
        return this.batch.daysToTargetClose;
      } else {
        return "N/A";
      }
    },
  },
};
</script>
