<template>
  <div>
    <dl class="loan-sales-detail-summary">
      <dt>Sale Date</dt>
      <dd>{{ batch.saleDate }}</dd>
      <dt>Participation Funding Entity</dt>
      <dd>{{ batch.fundingEntity.label }}</dd>
    </dl>
    <h4 class="loan-sales-page-sub-heading">
      Loans
    </h4>
    <div>
      <table class="table">
        <thead>
          <tr>
            <th>
              Loan ID
            </th>
            <th>
              Current Funding
            </th>
            <th>
              Allocation Amount
            </th>
            <th>
              Unpaid Principal Balance
            </th>
            <th>
              Pending Sales
            </th>
            <th>
              Remaining Amount
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="loanSale in loanSales"
            :key="loanSale.loan.id"
          >
            <td class="align-top">
              <a :href="loanLink(loanSale.loan.id)">
                {{ loanSale.loan.id }}
              </a>
            </td>
            <td class="align-top">
              {{ currentFundingEntity(loanSale) }}
            </td>
            <td class="align-top">
              <div class="form-group input-group form-row align-items-center has-validation">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  v-model="loanSale.allocationAmount"
                  v-mask:currency=""
                  class="form-control"
                  type="text"
                  @input="(amount) => handleAllocationAmountUpdate(loanSale, amount.target.value)"
                />
              </div>
              <div v-if="displayAllocationError(loanSale)" class="alert alert-warning allocation-error-message">
                Allocation amount must be greater than 0 and less than or equal to remaining amount
              </div>
            </td>
            <td class="align-top">
              {{ loanSale.unpaidPrincipalAmount | money }}
            </td>
            <td class="align-top">
              {{ loanSale.pendingInvestorSeriesAmount | money }}
            </td>
            <td class="align-top">
              {{ loanSale.remainingSellableAmount | money }}
            </td>
          </tr>
        </tbody>
      </table>
      <loan-sale-detail-actions
        :sell-disabled="isSellDisabled()"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import LoanSaleDetailActions from "./actions";
import NumberFormatter from "models/formatters/number_formatter";

export default {
  name: "LoanSaleParticipationDetail",
  components: {
    LoanSaleDetailActions,
  },
  props: {
    batch: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    routes: {
      type: Object,
      default: () => PSData.routes,
    },
  },
  data() {
    this.batch.loanSales.forEach(loanSale => {
      loanSale.allocationAmount = 0;
      loanSale.isAllocationAmountValid = false;
      loanSale.dirty = false;
    });
    return {
      loanSales: sortBy(this.batch.loanSales, ({ loan: { id } }) => id),
    };
  },
  methods: {
    isSellDisabled() {
      return !this.loanSales.every(loanSale => loanSale.isAllocationAmountValid);
    },
    currentFundingEntity(loanSale) {
      return get(loanSale, "currentFundingEntity.label", "Unknown");
    },
    loanLink(id) {
      return `${this.routes.loansUrl}/${id}`;
    },
    unMaskedCurrency(value) {
      return NumberFormatter.unmaskNumber(value);
    },
    handleAllocationAmountUpdate(loanSale, value) {
      const loanPsId = loanSale.loan.id;
      const index = findIndex(this.form.loanSales, { loanPsId });
      if (index === -1) {
        return;
      }
      const allocationAmount = this.unMaskedCurrency(value);
      loanSale.isAllocationAmountValid =
        0 < allocationAmount && allocationAmount <= loanSale.remainingSellableAmount;
      loanSale.dirty = true;
      this.$emit("update", `loanSales[${index}].allocationAmount`, allocationAmount);
    },
    displayAllocationError(loanSale) {
      return loanSale.dirty && !loanSale.isAllocationAmountValid;
    },
  },
};
</script>
<style scoped>
.allocation-error-message {
  position: absolute;
  margin-top: -25px;
  padding: 0.5rem 1rem;
}
</style>
