<template>
  <span :class="classes" />
</template>

<script>
import includes from "lodash/includes";

const STATUSES = ["pending", "success", "warning", "failure"];

export default {
  name: "status-indicator",
  props: {
    status: {
      type: String,
      required: true,
      default: "pending",
      validator: value => includes(STATUSES, value),
    },
  },
  computed: {
    classes() {
      return ["status-indicator", `status-indicator-${this.status}`];
    },
  },
};
</script>
