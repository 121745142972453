<template>
  <div class="settlement-statement-details">
    <div class="details-row no-border row">
      <div class="col-6">
        <ul class="loan-block">
          <li>
            <editable-field
              label="This Wire Amount"
              :value="settlementStatement.wireAmount"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li v-if="isReconciliation">
            <editable-field
              label="Previously Wired Amount"
              :value="settlementStatement.previousWireAmount"
              :editing="editModeEnabled"
              currency
            />
          </li>
        </ul>

        <ul class="loan-block">
          <li>
            <editable-field
              label="Total Purchase Amount"
              :value="settlementStatement.totalPurchaseAmount"
              :editing="editModeEnabled"
              currency
            />
          </li>
        </ul>
      </div>
    </div>

    <div class="details-row no-border row">
      <div class="col-6">
        <h5>Loan Details</h5>

        <ul class="loan-block">
          <li>
            <editable-field
              label="Loan Origination Date"
              :value="settlementStatement.originationDate"
              :editing="editModeEnabled"
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value :settlementStatement="settlementStatement" field="originationDate" />
          </li>
          <li>
            <editable-field
              label="Loan Maturity Date"
              :value="settlementStatement.maturityDate"
              :editing="editModeEnabled"
            />
          </li>
          <li>
            <editable-datepicker
              label="PS Purchase Date"
              v-model="settlementStatement.purchaseDate"
              :editing="editModeEnabled"
              :minDate="minPurchaseDate"
              @input="updateDaysOwned"
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value :settlementStatement="settlementStatement" field="purchaseDate" />
          </li>
          <li>
            <editable-field
              label="Note Interest Calc Method"
              :value="settlementStatement.interestCalculationMethod"
              :editing="editModeEnabled"
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="interestCalculationMethod"
            />
          </li>
          <li>
            <editable-field
              label="Note Interest Accrual Method"
              :value="settlementStatement.interestAccrualMethod"
              :editing="editModeEnabled"
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="interestAccrualMethod"
            />
          </li>
          <li>
            <editable-field
              label="New Servicer"
              :value="settlementStatement.servicerName"
              :editing="editModeEnabled"
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value :settlementStatement="settlementStatement" field="servicerName" />
          </li>
          <li>
            <editable-field
              label="Originator"
              :value="settlementStatement.originatorName"
              :editing="editModeEnabled"
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value :settlementStatement="settlementStatement" field="originatorName" />
          </li>
          <li>
            <editable-field
              label="Interest Rate"
              :value="settlementStatement.interestRate"
              :editing="editModeEnabled"
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value :settlementStatement="settlementStatement" field="interestRate" />
          </li>
          <li>
            <editable-field
              label="Originator Servicing Spread"
              :value="settlementStatement.lenderSpread"
              :editing="editModeEnabled"
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value :settlementStatement="settlementStatement" field="lenderSpread" />
          </li>
          <li>
            <editable-field
              label="Net to PS"
              :value="settlementStatement.peerstreetSpread"
              :editing="editModeEnabled"
            />
          </li>
        </ul>
      </div>

      <div class="col-6">
        <h5>PeerStreet Asset</h5>

        <ul class="loan-block">
          <li>
            <editable-field
              label="Loan Principal"
              :value="settlementStatement.totalPrincipalBalance"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="totalPrincipalBalance"
              currency
            />
          </li>
        </ul>

        <ul class="loan-block">
          <li>
            <editable-field
              label="Participation — Pari-Passu"
              :value="settlementStatement.pariPassuAmount"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="pariPassuAmount"
              currency
            />
          </li>

          <li>
            <editable-field
              label="PeerStreet Asset"
              :value="settlementStatement.peerstreetAsset"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="peerstreetAsset"
              currency
            />
          </li>
        </ul>
      </div>
    </div>

    <div class="details-row row">
      <div class="col-12">
        <h4 class="section-title">Wire Calculation</h4>
      </div>
      <div class="col-6">
        <ul class="loan-block">
          <li>
            <editable-field
              label="PeerStreet Purchase Price"
              :value="settlementStatement.peerstreetPurchasePrice"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="peerstreetPurchasePrice"
              currency
            />
          </li>
        </ul>
      </div>
    </div>

    <div class="details-row no-border row">
      <div class="col-6">
        <h5>Assets Earning PS Spread</h5>

        <ul class="loan-block">
          <li>
            <div class="loan-attribute-label">
              Liabilities &ndash; to Originator Off Platform
            </div>
            <div class="loan-attribute-value"></div>
          </li>
          <li>
            <editable-field
              label="Originator B-Piece Amount"
              :value="settlementStatement.bPieceAmount"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="bPieceAmount"
              currency
            />
          </li>
          <li>
            <editable-field
              label="On Platform Total"
              :value="settlementStatement.onPlatformTotal"
              :editing="editModeEnabled"
              currency
            />
          </li>
        </ul>

        <h5>Revenue/Expenses</h5>

        <ul class="loan-block">
          <li>
            <editable-field
              label="Gross Interest Revenue"
              :value="settlementStatement.grossInterestRevenue"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="grossInterestRevenue"
              currency
            />
          </li>
          <li>
            <editable-field
              label="Originator Interest Expense"
              :value="settlementStatement.originatorInterestExpense"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="originatorInterestExpense"
              currency
            />
          </li>
          <li>
            <editable-field
              label="B-Piece Interest Expense"
              :value="settlementStatement.bPieceInterestExpense"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="bPieceInterestExpense"
              currency
            />
          </li>
          <li>
            <editable-field
              label="Marketplace Fee"
              :value="settlementStatement.documentPreparationFee"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="documentPreparationFee"
              currency
            />
          </li>
          <li>
            <editable-field
              label="PeerStreet Origination Fee"
              :value="settlementStatement.peerstreetOriginationFee"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="peerstreetOriginationFee"
              currency
            />
          </li>
          <li>
            <editable-field
              label="Loan Servicer Setup Fee"
              :value="settlementStatement.servicerSetupFee"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li>
            <editable-field
              label="BPO/Appraisal Fee"
              :value="settlementStatement.bpoAppraisalFee"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="bpoAppraisalFee"
              currency
            />
          </li>
          <li>
            <editable-field
              :label="otherFeesType"
              :value="settlementStatement.otherFees"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value :settlementStatement="settlementStatement" field="otherFees" currency />
          </li>
          <li class="total">
            <editable-field
              label="Total Revenue"
              :value="settlementStatement.totalRevenue"
              :editing="editModeEnabled"
              currency
            />
          </li>
        </ul>
      </div>

      <div class="col-6">
        <h5>PeerStreet Holdbacks</h5>

        <ul class="loan-block">
          <li>
            <editable-field
              label="Construction Reserve"
              :value="settlementStatement.constructionReserveAmount"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="constructionReserveAmount"
              currency
            />
          </li>
          <li>
            <editable-field
              label="Interest Reserve"
              :value="settlementStatement.interestReserveAmount"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="interestReserveAmount"
              currency
            />
          </li>
          <li>
            <editable-field
              label="Escrow Balance"
              :value="settlementStatement.escrowReserveAmount"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="escrowReserveAmount"
              currency
            />
          </li>
          <li>
            <editable-field
              label="Other Reserve"
              :value="settlementStatement.otherReserveAmount"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="otherReserveAmount"
              currency
            />
          </li>
          <li>
            <editable-field
              label="Pre-Paid Interest"
              :value="settlementStatement.prePaidInterest"
              :editing="editModeEnabled"
              currency
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="prePaidInterest"
              currency
            />
          </li>
          <li class="total">
            <editable-field
              label="Subtotal Holdbacks"
              :value="settlementStatement.subTotalHoldbacks"
              :editing="editModeEnabled"
              currency
            />
          </li>
        </ul>

        <h5>First Month Interest Calcs</h5>

        <ul class="loan-block">
          <li>
            <editable-field
              label="Note Origination"
              v-model="settlementStatement.origination"
              :editing="editModeEnabled"
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value :settlementStatement="settlementStatement" field="origination" />
          </li>
          <li>
            <editable-field
              label="Next Payment Date Due to PS"
              v-model="settlementStatement.nextPaymentDate"
              :editing="editModeEnabled"
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value :settlementStatement="settlementStatement" field="nextPaymentDate" />
          </li>
          <li>
            <editable-field
              label="Partial Month Interest Calculation"
              v-model="settlementStatement.partialMonthInterestCalculation"
              :editing="editModeEnabled"
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="partialMonthInterestCalculation"
            />
          </li>
          <li>
            <editable-field
              label="Partial Month Interest Collected"
              v-model="settlementStatement.partialMonthInterestCollected"
              :editing="editModeEnabled"
            />
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value
              :settlementStatement="settlementStatement"
              field="partialMonthInterestCollected"
            />
          </li>
          <li>
            <days-owned-field
              v-model="settlementStatement.daysOwned"
              :editing="editModeEnabled"
              @input="updatePurchaseDate"
            ></days-owned-field>
          </li>
          <li class="previous-value" v-if="showPreviousValues">
            <previous-value :settlementStatement="settlementStatement" field="daysOwned" />
          </li>
        </ul>

        <revenue-expense-table
          :settlement-statement="settlementStatement"
          :edit-mode-enabled="editModeEnabled"
        >
        </revenue-expense-table>
      </div>
    </div>
  </div>
</template>

<script>
import PreviousValue from "./previous_value.vue";
import DaysOwnedField from "./days_owned_field";
import RevenueExpenseTable from "./revenue_expense_table";
import EditableField from "components/shared/editable_field.vue";
import EditableDatepicker from "components/shared/editable_datepicker";
import moment from "moment";

const FUNDED_TYPE = "Funded";
const TRUE_VALUE = "Yes";
const DATE_FORMAT = "MM/DD/YYYY";
const DEFAULT_OTHER_FEES_TYPE = "Other Adjustment";

export default {
  name: "SettlementStatementDetails",
  components: {
    EditableField,
    PreviousValue,
    RevenueExpenseTable,
    DaysOwnedField,
    EditableDatepicker,
  },
  props: ["settlementStatement", "editMode", "showPreviousValues", "loan"],
  computed: {
    editModeEnabled() {
      return this.editMode && this.isPartialMonthInterestCollected;
    },
    isFunded() {
      return this.settlementStatement.origination === FUNDED_TYPE;
    },
    isPartialMonthInterestCollected() {
      return this.settlementStatement.partialMonthInterestCollected === TRUE_VALUE;
    },
    isReconciliation() {
      return this.settlementStatement.settlementStatementType === "reconciliation";
    },
    minPurchaseDate() {
      if (!this.isReconciliation) {
        return;
      }
      return this.settlementStatement.firstPurchased.purchaseDate;
    },
    otherFeesType() {
      if (this.settlementStatement.otherFeesType) {
        return this.settlementStatement.otherFeesType;
      }

      return DEFAULT_OTHER_FEES_TYPE;
    },
  },
  methods: {
    updatePurchaseDate(value) {
      if (this.isReconciliation || !value) {
        return;
      }
      this.settlementStatement.purchaseDate = moment(this.loan.paidToDateAtPurchase, DATE_FORMAT)
        .subtract(value, "days")
        .format(DATE_FORMAT);
    },
    updateDaysOwned(value) {
      if (this.isReconciliation || !value) {
        return;
      }
      const daysOwned = moment(this.loan.paidToDateAtPurchase, DATE_FORMAT).diff(
        moment(value, DATE_FORMAT),
        "days"
      );

      if (daysOwned < 0) {
        this.settlementStatement.daysOwned = 0;
      } else {
        this.settlementStatement.daysOwned = daysOwned;
      }
    },
  },
};
</script>
