<template>
  <editable-input
    :value="value"
    :editing="editing"
  >
    <template #edit>
      <div class="form-group loan-task-comments-form workout-case">
        <textarea
          v-bind="$props"
          class="form-control"
          :placeholder="placeholder"
          :name="name"
          v-on="$listeners"
          :disabled="disabled"
        />
      </div>
    </template>
    <template #show>
      <span :class="valueClass"> {{ displayText }}</span>
    </template>
  </editable-input>
</template>

<script>
import EditableInput from "./editable_input";

export default {
  name: "editable-textarea",
  components: {
    EditableInput,
  },
  props: {
    ...EditableInput.props,
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "Write note here"
    },
    name: {
      type: String,
      default: ""
    }
  },
  computed: {
    valueClass() {
      return { "no-status-state": !this.value };
    },
    displayText() {
      return this.value || "No notes";
    },
  },
};
</script>
