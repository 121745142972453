<template>
  <div>
    <dl class="loan-sales-detail-summary">
      <dt>Sale Date</dt>
      <dd>{{ batch.saleDate }}</dd>
      <dt>Transfer Funding Entity</dt>
      <dd>{{ batch.fundingEntity.label }}</dd>
    </dl>

    <h4 class="loan-sales-page-sub-heading">
      Sale Summary
    </h4>

    <source-of-capital-table :batch="batch" :form="form" />

    <h4 class="loan-sales-page-sub-heading">
      Loans
    </h4>

    <validation-observer ref="observer" v-slot="{ invalid, dirty }">
      <div>
        <table class="table">
          <thead>
            <tr>
              <th>
                Loan ID
              </th>
              <th>
                Current Funding
              </th>
              <th>
                Eligibility
              </th>
              <th>
                Allocation
              </th>
              <th v-if="isCreditSuisse">
                Allocation Amounts
              </th>
              <th v-else>
                Primary Funding Entity Max Allocation
              </th>
              <th class="text-right">
                Unpaid Principal Balance
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="loanSale in loanSales"
              :key="loanSale.loan.id"
            >
              <td class="align-top">
                <a :href="loanLink(loanSale.loan.id)">
                  {{ loanSale.loan.id }}
                </a>
              </td>
              <td class="align-top">
                {{ currentFundingEntity(loanSale) }}
              </td>
              <td class="align-top">
                {{ loanSale.eligibility }}
              </td>
              <td class="align-top">
                <funding-entity-allocation-select
                  :value="fundingEntityAllocationId(loanSale)"
                  :options="fundingEntityAllocationOptionsWithEmpty"
                  @input="(val) => handleUpdate(loanSale.loan.id, 'fundingEntityAllocationId', val)"
                />
              </td>
              <td v-if="isCreditSuisse" class="align-top">
                <credit-suisse-override
                  :loan-sale="loanSale"
                  @overrideUpdate="overrideUpdate"
                />
              </td>
              <td v-else class="align-top">
                <div v-if="showMaxAllocationField(loanSale)">
                  <max-allocation-amount
                    v-model="loanSale.fundingEntityMaxAllocationAmount"
                    :upb="loanSale.saleAmount"
                    @input="(val) => handleUpdate(loanSale.loan.id, 'fundingEntityMaxAllocationAmount', val)"
                  />
                </div>
                <div v-else>
                  {{ defaultValue(loanSale) | money }}
                </div>
              </td>
              <td class="align-top text-right">
                {{ loanSale.saleAmount | money }}
              </td>
            </tr>
          </tbody>
        </table>

        <loan-sale-detail-actions
          :reset-disabled="isDisabled(invalid, dirty)"
          :sell-disabled="isDisabled(invalid, dirty) || overrideIsDisabled()"
          v-on="$listeners"
        />
      </div>
    </validation-observer>
  </div>
</template>

<script>
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import find from "lodash/find";
import sum from "lodash/sum";
import { ValidationObserver } from "vee-validate";

import FundingEntityAllocationSelect from "./funding_entity_allocation_select";
import SourceOfCapitalTable from "./source_of_capital_table";
import LoanSaleDetailActions from "./actions";
import MaxAllocationAmount from "./max_allocation_amount";
import CreditSuisseOverride from "./credit_suisse_override";
import sumBy from "lodash/sumBy";
import toNumber from "lodash/toNumber";

export default {
  name: "LoanSaleTransferDetail",
  components: {
    ValidationObserver,
    FundingEntityAllocationSelect,
    SourceOfCapitalTable,
    LoanSaleDetailActions,
    MaxAllocationAmount,
    CreditSuisseOverride,
  },
  props: {
    batch: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    routes: {
      type: Object,
      default: () => PSData.routes,
    },
    disabled: Boolean,
  },
  computed: {
    loanSales() {
      const records = sortBy(this.batch.loanSales, ({ loan: { id } }) => id);
      records.forEach(
        loanSale => (loanSale.fundingEntityMaxAllocationAmount = this.defaultValue(loanSale))
      );
      return records;
    },
    fundingEntityAllocationOptions() {
      return PSData.fundingEntityAllocationOptions[this.batch.fundingEntity.id.toString()];
    },
    fundingEntityAllocations() {
      return PSData.fundingEntityAllocations;
    },
    fundingEntityAllocationMultipliers() {
      return PSData.fundingEntityAllocationMultipliers;
    },
    fundingEntityAllocationOptionsWithEmpty() {
      return [
        { id: "", text: "Please Select an allocation..." },
        ...this.fundingEntityAllocationOptions,
      ];
    },
    isCreditSuisse() {
      return this.batch.fundingEntity.id == 3;
    },
  },
  methods: {
    overrideUpdate(loanSale, overrides) {
      const index = findIndex(this.form.loanSales, ls => ls.loanPsId == loanSale.loan.id);
      this.overridesValid =
        sumBy(overrides, override => toNumber(override.amount)) == loanSale.saleAmount;
      this.$emit("update", `loanSales[${index}].overrides`, overrides);
    },
    isDisabled(invalid, dirty) {
      if (dirty) {
        return invalid;
      } else {
        return false;
      }
    },
    overrideIsDisabled() {
      return this.isCreditSuisse && !this.overridesValid;
    },
    defaultValue(loanSale) {
      const fundingEntityAllocationId = this.fundingEntityAllocationId(loanSale);
      const fundingEntityId = this.batch.fundingEntity.id;

      if (fundingEntityAllocationId && fundingEntityId) {
        const multipliers = this.fundingEntityAllocationMultipliers
          .filter(
            record =>
              record.fundingEntityAllocationId == fundingEntityAllocationId &&
              record.fundingEntityId == fundingEntityId
          )
          .map(record => parseFloat(record.multiplier));

        if (multipliers.length === 0) {
          return loanSale.saleAmount;
        } else {
          return multipliers[0] * loanSale.saleAmount;
        }
      }
    },
    showMaxAllocationField(loanSale) {
      const fundingEntityAllocationId = this.fundingEntityAllocationId(loanSale);

      if (fundingEntityAllocationId) {
        return find(this.fundingEntityAllocations, { id: parseInt(fundingEntityAllocationId) })
          .allowPrimaryPartialAllocation;
      } else {
        return false;
      }
    },
    currentFundingEntity(loanSale) {
      return get(loanSale, "currentFundingEntity.label", "Unknown");
    },
    fundingEntityAllocationId(loanSale) {
      const {
        loan: { id: loanPsId },
      } = loanSale;

      let { fundingEntityAllocationId: allocationId } = find(this.form.loanSales, { loanPsId });

      if (this.fundingEntityAllocationOptions.length === 1) {
        allocationId = allocationId || this.fundingEntityAllocationOptions[0].id;
      }

      return allocationId ? allocationId.toString() : null;
    },
    loanLink(id) {
      return `${this.routes.loansUrl}/${id}`;
    },
    handleUpdate(loanPsId, name, value) {
      const index = findIndex(this.form.loanSales, { loanPsId });

      if (index === -1) {
        return;
      }

      if (name === "fundingEntityAllocationId") {
        const loanSale = find(this.loanSales, { loan: { id: loanPsId } });

        loanSale.fundingEntityMaxAllocationAmount = this.defaultValue(loanSale);
      }

      this.$emit("update", `loanSales[${index}].${name}`, value);
    },
    customOptions(valid) {
      return { extraClasses: this.validationClass(valid) };
    },
    validationClass: valid => (valid === null ? null : valid ? "is-valid" : "is-invalid"),
  },
};
</script>
