<template>
  <div class="details-row columns is-multiline">
    <div class="column is-full">
      <h4>Fees</h4>
    </div>
    <div class="column is-full" v-if="failedToLoadFees">
      <base-message type="warning" title="Request error" :closable="false">Can't preload loan's fees at the moment</base-message>
    </div>
    <div class="column is-full" v-if="successfullyLoadedFees">
      <base-message type="success" title="Success" :closable="false">Successfully loaded {{feesCount}} fees</base-message>
    </div>
    <div v-if="!isPeerstreetRepurchaseStatement" class="column is-full">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <recoverability-filter v-model="recoverable" :disabled="isExistingStatement" />
          </div>
        </div>
      </div>
    </div>
    <div class="column is-full">
      <totals :payoff-statement="statement" />
    </div>
    <div class="column is-full">
      <base-table
        :loading="loading"
        class="payoff-fees-edit-table"
        :data="statement.fees"
        :scrollable="true"
      >
        <base-column label="Fee Type" v-slot="{ row }">
          <base-select-field v-model="row.feeName">
            <optgroup
              v-for="optionGroup in feeTypeOptions"
              :label="optionGroup.label"
              :key="optionGroup.label"
            >
              <option v-for="option in optionGroup.options" :key="option[1]" :value="option[1]">
                {{ option[0] }}
              </option>
            </optgroup>
          </base-select-field>
          <editable-field
            v-if="row.feeName == 'other_fees'"
            label="Name"
            :editing="isEditable"
            :disabled="false"
            :value="row.name"
            :callback="updateEditableField('name', row)"
          />
        </base-column>
        <base-column label="Incurred Date" v-slot="{ row }">
          <base-date-field v-model="row.incurredDate" />
        </base-column>
        <base-column label="Incurred Amount" v-slot="{ row }">
          <base-currency-field v-model="row.incurredAmount" disabled />
        </base-column>
        <base-column label="Amount" v-slot="{ row }">
          <base-currency-field v-model="row.amount" @input="updateAmount" />
        </base-column>
        <base-column label="Waived Amount" v-slot="{ row }">
          <div class="editable-field-container">
            <waived-input
              :value="row.waivedAmount"
              :amount="row.amount"
              :data-change="updateEditableField('waivedAmount', row)"
            />
          </div>
        </base-column>
        <base-column v-slot="{ row }">
          <base-button
            class="editable-action"
            type="iconBlue"
            icon="trash-alt"
            @click="removeFee(row)"
          />
        </base-column>
      </base-table>
    </div>
    <div class="column is-full">
      <a @click="addFee">+ Add Fees</a>
    </div>
  </div>
</template>

<script>
import EditableField from "components/shared/editable_field.vue";
import WaivedInput from "components/manage/payoff_statement/waived_input";
import EditPartialMixin from "components/manage/payoff_statement/edit/edit_partial_mixin";
import { DELAY_MS } from "./edit_partial_mixin";
import debounce from "lodash/debounce";
import uniqueId from "lodash/uniqueId";
import RecoverabilityFilter from "./fees_recoverability_filter";
import Ajax from "services/ajax_service";
import PayoffStatement from "models/payoff_statement";
import Totals from "./fees/totals";
import { orderByIncurredDateAndId } from "../utils/sorts";

export default {
  name: "payoff-statement-edit-fees",
  components: {
    WaivedInput,
    RecoverabilityFilter,
    EditableField,
    Totals,
  },
  mixins: [EditPartialMixin],
  props: ["statement", "feeTypes", "isEditable"],
  data() {
    return {
      recoverable: this.statement.feeTypeRecoverability || "all_fees_costs",
      availableFees: [],
      statementType: this.statement.statementType,
      loading: false,
      feesRequestState: "pending",
    };
  },
  computed: {
    feeTypeOptions() {
      const feeTypes = PSData.feeTypes;
      return feeTypes.map(group => {
        const groupOptions = group.options.map(opt => [opt.label, opt.name]);
        return { label: group.label, options: groupOptions };
      });
    },
    isExistingStatement() {
      return this.statement.id !== null;
    },
    isPeerstreetRepurchaseStatement() {
      return this.statement.statementType === "peerstreet_repurchase";
    },
    failedToLoadFees() {
      return this.feesRequestState === "failed";
    },
    successfullyLoadedFees() {
      return this.feesRequestState === "success";
    },
    feesCount() {
      return this.availableFees.length;
    },
  },
  watch: {
    recoverable: function(value) {
      const filteredFees = this.availableFees.filter(fee => {
        if (value === "all_fees_costs") {
          return true;
        } else if (value === "all_recoverable") {
          return fee.investorRecoverable || fee.borrowerRecoverable;
        } else if (value === "borrower_recoverable") {
          return fee.borrowerRecoverable;
        } else if (value === "investor_recoverable") {
          return fee.investorRecoverable;
        } else {
          return false;
        }
      });
      this.statement.update({
        fees: filteredFees,
        feeTypeRecoverability: value,
      });
    },
  },
  created() {
    this.updateAmount = debounce(this.syncInterest, DELAY_MS);

    this.loadFees();

    this.statement.fees.sort(orderByIncurredDateAndId);
  },
  methods: {
    syncInterest() {
      this.$emit("sync-interest");
    },
    addFee() {
      this.$emit("add-fee");
    },
    removeFee(fee) {
      this.$emit("remove-fee", fee);
    },
    prepareFees(fees) {
      return fees
        .map(fee => ({ ...fee, id: uniqueId(), waivedAmount: 0 }))
        .sort(orderByIncurredDateAndId);
    },
    async loadFees() {
      if (this.isExistingStatement || this.isPeerstreetRepurchaseStatement) {
        return;
      }

      this.loading = true;
      const fetchFeesUrl = this.statement.routes.fetchFees;
      try {
        const result = await Ajax.getJSON(fetchFeesUrl);
        this.availableFees = this.prepareFees(result);
        this.statement.update({ fees: PayoffStatement.getFees([...this.availableFees]) });
        this.syncInterest();
        this.feesRequestState = "success";
      } catch (error) {
        this.feesRequestState = "failed";
        throw error;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.payoff-fees-edit-table .table td {
  padding-bottom: 0px;
  padding-right: 26px;
}

.payoff-fees-edit-table .percentage {
  display: flex !important;
}

.fee-row {
  margin-bottom: 12px;
}
</style>
