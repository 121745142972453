<template>
  <tr>
    <td>
      <div class="row no-gutters">
        <editable-select
          :value="form.feeTypeId"
          :options="options"
          :editing="true"
          @input="updateType($event)"
        />
      </div>
    </td>
    <td>
      <div class="row no-gutters">
        <editable-currency
          :allow-minus="false"
          :value="form.amount"
          :editing="true"
          :disabled="!feeTypeSelected"
          @input="updateAmount($event)"
        />
      </div>
    </td>
    <td>
      <i class="far fa-trash-alt fa-lg" @click="deleteFee" />
    </td>
  </tr>
</template>

<script>
import EditableCurrency from "../../editable_currency";
import EditableSelect from "../../editable_select";

export default {
  components: {
    EditableCurrency,
    EditableSelect,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const { amount, feeTypeId, id } = this.item;
    const form = { amount, feeTypeId, id };

    return { form };
  },
  watch: {
    form: {
      handler(value) {
        this.$emit("change", value);
      },
      deep: true,
    },
  },
  computed: {
    feeTypeSelected() {
      return !!this.item.feeTypeId;
    },
  },
  methods: {
    updateAmount(value) {
      this.form.amount = value;
    },
    updateType(event) {
      const feeTypeId = Number(event.target.value);
      const option = this.options.find(option => option.id === feeTypeId);
      const amount = option ? option.defaultAmount : 0;

      this.form.feeTypeId = feeTypeId;
      this.form.amount = amount;
    },
    deleteFee() {
      this.$emit("delete");
    },
  },
};
</script>
