<template>
  <div class="inline-date-picker-wrapper">
    <label :for="inputId">{{label}}:
      <span>{{ inputValue }}</span>
      <input
        :id="inputId"
        :name="label"
        v-datepicker=""
        v-model="inputValue"
        @input="update"
        class="datepicker"
        type="text"
        :disabled="!canEdit"
        readonly
      />
      <i v-show="canEdit" class="fas fa-pencil-alt" />
    </label>
  </div>
</template>

<script>
export default {
  name: "inline-date-picker",
  props: {
    field: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputId: `${this.field}-datepicker`,
      inputValue: this.value,
    };
  },
  methods: {
    update(event) {
      if (event.target.value === "") return;

      this.$emit("input", event.target.value);
    },
  },
};
</script>
