<template>
  <div>
    <button class="btn btn-action-modal float-right" @click="onSave" :disabled="saveDisabled">Save</button>
    <button class="btn btn-cancel-modal float-right" @click="onCancel">Cancel</button>
    <form>
      <view-panel class="loan-details-view-panel">
        <div class="view-panel-header loan-details-valuation-header">
          {{ valuation.reportTypeText }} Report
        </div>
        <div class="row loan-details-view-panel-row">
          <div class="col-6 loan-details-view-panel-column">
            <form-select label="Type of Valuation" field="valuationMethod" v-model="fields.valuationMethod" :options="valuationOptions()" :errors="errors" placeholder="" ref="propertyAddress" />
            <form-select label="Party Completing Valuation" field="appraiser" v-model="fields.appraiser" :options="appraiserOptions()" :errors="errors" />
            <form-select label="Commissioner" field="comissioner" v-model="fields.commissioner" :options="commissionerOptions()" :errors="errors" placeholder="" ref="propertyAddress" />
            <form-date-input label="Date of Valuation" field="date" v-model="fields.date" :errors="errors" />
            <form-input label="As-Is Value($)" field="asIsAmount" v-model="fields.asIsAmount" mask="positive-number" :errors="errors" />
            <form-input label="ARV($)" field="arvAmount" v-model="fields.arvAmount" mask="positive-number" :errors="errors" />
            <form-input label="Monthly Market Rent($)" field="monthlyMarketRent" v-model="fields.monthlyMarketRent" mask="positive-number" :errors="errors" />
          </div>
          <div class="col-6 loan-details-view-panel-column">
            <form-input label="Deferred Maintenance" field="deferredMaintenanceAmount" v-model="fields.deferredMaintenanceAmount" mask="positive-number" :errors="errors" />
            <form-input label="Appraiser Name" field="appraiserName" v-model="fields.appraiserName" :errors="errors" />
            <form-input label="Appraiser Company" field="appraiserCompany" v-model="fields.appraiserCompany" :errors="errors" />
            <form-input label="License Number" field="licenseNumber" v-model="fields.licenseNumber" :errors="errors" />
            <form-select label="License State" field="licenseState" v-model="fields.licenseState" :options="states" :errors="errors" />
          </div>
        </div>
      </view-panel>
    </form>
  </div>
</template>

<script>
import ViewPanel from "components/view_panel.vue";
import FormInput from "components/shared/form/form_input";
import FormSelect from "components/shared/form/form_select";
import FormDateInput from "components/shared/form/form_date_input";
import PropertyValuation from "models/property_valuation";
import selectOptionsToMapping from "utils/select_options_to_mapping";

export default {
  name: "loan-valuation-form",
  components: {
    ViewPanel,
    FormInput,
    FormSelect,
    FormDateInput,
  },
  props: {
    valuation: {
      type: PropertyValuation,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      routes: PSData.routes || {},
      saveDisabled: false,
      valuationMethods: PSData.valuationOptions.valuationMethods,
      appraisers: PSData.valuationOptions.appraisers,
      commissioners: PSData.valuationOptions.commissioners,
      states: selectOptionsToMapping(PSData.propertyOptions.states || []),
    };
  },
  computed: {
    fields() {
      return this.valuation;
    },
  },
  methods: {
    valuationOptions() {
      var options = {};
      this.valuationMethods.forEach(item => (options[item.name] = item.value));
      return options;
    },
    appraiserOptions() {
      var options = {};
      this.appraisers.forEach(item => (options[item.name] = item.value));
      return options;
    },
    commissionerOptions() {
      var options = {};
      this.commissioners.forEach(item => (options[item.name] = item.value));
      return options;
    },
    onCancel() {
      this.$emit("on-cancel");
    },
    onSave() {
      this.save();
    },
    save() {
      this.saveDisabled = true;
      var url =
        this.valuation.reportType == "validation" ? this.routes.validation : this.routes.valuation;

      return PS.Services.AjaxService.withNotifications()
        .patch(url, { loan_valuation: this.valuation.toHash() })
        .then(data => {
          this.saveDisabled = false;
          this.$emit("on-save", data);
        });
    },
  },
};
</script>
