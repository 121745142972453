<template>
  <validation-provider
    ref="provider"
    v-slot="{ errors, valid }"
    name="Loan CSV"
    rules="required"
    slim
  >
    <div class="form-group">
      <label>Buyer</label>
      <input
        v-model="innerValue"
        class="form-control"
        :class="validationClass(valid)"
        type="text"
        name="buyer"
        :disabled="disabled"
      >

      <div v-if="valid === false" class="invalid-feedback">
        {{ errors.map(({ msg }) => msg).join(", ") }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import find from "lodash/find";
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    legalOwnerId: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    disabled: Boolean,
  },
  data() {
    return { innerValue: this.value };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.innerValue = val;
      },
    },
    innerValue: {
      immediate: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
    legalOwnerId: {
      immediate: true,
      handler(val) {
        const owner = find(PSData.legalOwners, { id: parseInt(val, 10) });
        if (owner) {
          this.innerValue = owner.label;
        }
      },
    },
  },
  methods: {
    validationClass: valid => (valid === null ? null : valid ? "is-valid" : "is-invalid"),
  },
};
</script>
