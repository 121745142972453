<template>
  <div class="draft-container">
    <div class="columns scenario">
      <div class="column is-full has-text-centered draft-header">
        <router-link :to="{ name: 'loan_scenarios' }" class="scenarios-link">
          <i class="fas fa-chevron-left"></i> All Scenarios
        </router-link>
        <h3>Scenario Builder</h3>
        <p v-if="isNew">
          Explore scenarios and check out how different<br>loan terms affect your pricing.
        </p>
        <p v-else class="edit-scenario">
          &nbsp;
          <a @click="openSaveDraftModal" :class="{ disabled: hasConvertedScenario || loanCreationInProgress || (isRental && isRentalDisabled)}">
            <template v-if="!isDraftLoading">
              {{ propertyAddress.fullAddress }}
              <b-icon v-if="!hasConvertedScenario && !loanCreationInProgress" pack="fas" size="is-small" icon="pencil-alt" />
            </template>
          </a>
        </p>
      </div>
      <validation-observer class="column is-full" ref="observer" tag="form">
        <fieldset :disabled="hasConvertedScenario || loanCreationInProgress || (isRental && isRentalDisabled)">
          <div class="columns is-desktop">
            <div class="column is-5-desktop is-full-tablet form">
              <div class="form-content">
                <scenario-form
                  :scenario="scenario"
                  :constraints="currentConstraints"
                  @loanProductUpdated="handleLoanProductUpdated"
                  @input.native="debouncedFormUpdateHandler"
                  :is-rental-disabled="isRentalDisabled"
                />
              </div>

              <div class="is-flex mb-5">
                <base-tooltip
                  :active="isRental && isRentalDisabled"
                  :label="locale.tooltip.disabledRental"
                  position="is-bottom"
                  multilined
                >
                  <base-button
                    :disabled="hasConvertedScenario || (isRental && isRentalDisabled)"
                    @click="createOrUpdateDraft"
                    class="is-pulled-left"
                  >
                    Save Scenario
                  </base-button>
                </base-tooltip>
              </div>
            </div>

            <div class="column estimates">
              <div class="estimates-content">
                <div class="estimates-header is-flex is-justify-content-space-between">
                  <h3>Estimates</h3>
                  <div v-if="hasConvertedScenario">
                    <base-tag type="success" size="large">Converted to Loan Application</base-tag>
                    <p class="mb-0 text-right">
                      <span>{{ convertedAt }}</span>
                      <a :href="loanUrl" target="_blank">View Loan</a>
                    </p>
                  </div>
                </div>

                <estimated-tabs
                  :creditbox-results="creditboxResults"
                  :constraints="currentConstraints"
                  :price-results="pricingScenarios"
                  :scenario="scenario"
                  :converted-scenario="convertedScenario"
                  :loan-creation-in-progress="loanCreationInProgress"
                  @input.native="debouncedCustomScenarioUpdateHandler"
                  @scenarioRowSelected="handleScenarioRowSelected"
                />

                <p class="help"><a :href="helpUrl" target="_blank">View Credit Box, Pricing Sheets, and Diligence Requirements</a></p>

                <base-loading
                  :show="inProgress || loanCreationInProgress"
                  :is-full-page="false"
                  class="scenario-rates-spinner"
                />
              </div>

              <div class="is-flex is-justify-content-space-between is-align-items-center">
                <p class="note mb-0">Estimates are not a commitment to fund and may not reflect general market pricing.</p>

                <base-tooltip
                  :active="showCreateLoanTooltip || (isRental && isRentalDisabled)"
                  :label="createLoanTooltip"
                  position="is-bottom"
                  multilined
                >
                  <base-button
                    :disabled="isCreateLoanDisabled || (isRental && isRentalDisabled)"
                    type="primary"
                    @click="createLoan"
                    class="is-pulled-right convert-secnario-btn"
                  >
                    Convert to Application
                  </base-button>
                </base-tooltip>
              </div>
            </div>
          </div>
        </fieldset>
      </validation-observer>
    </div>

    <save-draft-modal
      :show="showSaveDraftModal"
      :id="id"
      :scenario="scenario"
      :property-address="propertyAddress"
      :creditbox-results="creditboxResults"
      :price-results="pricingScenarios"
      :selected-scenario="selectedScenario"
      :evaluation-in-progress="inProgress"
      :loan-creation-in-progress="loanCreationInProgress"
      @scenario-draft-saved="onScenarioDraftSaved"
      @scenario-address-updated="onScenarioAddressUpdated"
      @convert-scenario-to-loan="onConvertToDraftLoan"
      @close="closeSaveDraftModal"
    ></save-draft-modal>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import useScenarioBuilderStore from "./store";
import ScenarioBuilder from "./models/scenario_builder";
import PostAddress from "./models/post_address";
import ScenarioForm from "./form";
import EstimatedTabs from "./tabs";
import SaveDraftModal from "./save_draft_modal";
import { PROGRAMS } from "./utils/helpers";
import Notification from "./utils/notification";
import ServiceClient from "./api/service_client";
import CountiesClient from "./api/counties_client";
import PropertyDetailsClient from "./api/property_details_client";
import { LoanProduct, AccountType, NoteOrigination } from "./enums";
import MidasService from "services/midas_service";
import toPlainObject from "./utils/to_plain_object";
import toPayload from "./utils/to_payload";
import { EvaluateScenario, FetchConstraints, FetchCustomPricingScenario } from "./services";
import debounce from "./utils/debounce";
import { ValidationObserver } from "vee-validate";
import { formatDateTime } from "./utils/formatter";
import LocationService from "services/location_service";
import { isEmpty, mapKeys } from "lodash";
import { camelToSnakeCase } from "./utils/casing.js";
import DraftLoansClient from "./api/draft_loans_client";

const HELP_URL = "https://peerstreet.zendesk.com/hc/en-us/categories/360005971071-Transact";
const DEFAULT_PROGRAM_TYPE = LoanProduct.residentialBridge.value;

export default {
  name: "scenario-draft",
  components: {
    ScenarioForm,
    EstimatedTabs,
    SaveDraftModal,
    ValidationObserver,
  },
  inject: ["api", "locale"],
  props: {
    id: {
      type: String,
      required: true,
    },
    featureFlag: Object,
    lenderAccountType: String,
  },
  data() {
    return {
      programType: DEFAULT_PROGRAM_TYPE,
      scenario: ScenarioBuilder.build(DEFAULT_PROGRAM_TYPE),
      propertyAddress: new PostAddress(),
      inProgress: false,
      isDraftLoading: true,
      pricingScenarios: [],
      creditboxResults: {},
      constraints: {},
      showSaveDraftModal: false,
      serviceClient: new ServiceClient(this.id),
      countiesClient: new CountiesClient(),
      convertedAt: undefined,
      convertedScenario: {},
      selectedScenario: {},
      loanCreationInProgress: false,
      propertyDetailsClient: new PropertyDetailsClient(),
      proceedWithCreation: false,
      draftLoansClient: new DraftLoansClient(),
    };
  },
  created() {
    this.debouncedFormUpdateHandler = debounce(() => {
      this.startEvaluationGroup();
      this.handleScenarioUpdate();
    });
    this.debouncedCustomScenarioUpdateHandler = debounce(async () => {
      this.startEvaluationGroup();
      await this.handleCustomScenarioUpdate();
      this.endEvaluationGroup();
    });
  },
  async mounted() {
    this.startEvaluationGroup();
    await this.getConstraints();
    await this.loadScenario();
  },
  watch: {
    $route() {
      this.startEvaluationGroup();
      this.loadScenario();
    },
    propertyAddress: {
      deep: true,
      handler(newAddress) {
        if (this.loanCreationInProgress) return;
        this.scenario.zipCode = newAddress.zipCode;
        this.startEvaluationGroup();
        this.handleScenarioUpdate();
      },
    },
  },
  methods: {
    ...mapActions(useScenarioBuilderStore, ["startEvaluationGroup", "endEvaluationGroup"]),

    async handleScenarioUpdate() {
      if (this.hasConvertedScenario || this.loanCreationInProgress) return;

      if (!(await this.isScenarioValid())) {
        // clear results if invalid
        this.pricingScenarios = [];
        this.creditboxResults = {};
        return;
      }

      await this.evaluateScenario();
    },
    async handleCustomScenarioUpdate() {
      if (this.hasConvertedScenario || this.loanCreationInProgress) return;

      if (!(await this.isScenarioValid())) {
        return;
      }

      this.inProgress = true;

      try {
        const customPricingScenarioResponse = await FetchCustomPricingScenario.call(
          this.scenario,
          this.serviceClient
        );

        const existingCustomPricingScenarioIdx = this.pricingScenarios.findIndex(
          pricingScenario => pricingScenario.custom
        );

        if (existingCustomPricingScenarioIdx !== -1) {
          // if there's an existing pricing scenario replace it
          this.pricingScenarios.splice(existingCustomPricingScenarioIdx, 1);
        }

        // push it onto the scenarios
        this.pricingScenarios.push(customPricingScenarioResponse);
      } finally {
        this.inProgress = false;
      }
    },
    async evaluateScenario() {
      if (this.hasConvertedScenario || this.loanCreationInProgress) return;

      this.inProgress = true;

      try {
        await Promise.all([
          this.requestPropertyDetails(),
          this.requestMedianHomePriceInzip(),
          this.requestCountyInfo(),
        ]);
        const evaluationResponse = await EvaluateScenario.call(this.scenario, this.serviceClient);
        this.creditboxResults = evaluationResponse.creditbox;
        this.pricingScenarios = evaluationResponse.pricing;
      } finally {
        this.endEvaluationGroup();
        this.inProgress = false;
      }
    },
    async isScenarioValid() {
      return (await this.$refs.observer.validate()) && this.scenario.validateScenarioRequest();
    },
    async requestMedianHomePriceInzip() {
      let response, payload;
      try {
        payload = { zip: this.scenario.zipCode };
        response = await MidasService.zipMedianHomePrice(payload.zip);
        this.scenario.medianHomePriceInZip = response.data.zipMedianHomePrice?.value;
      } catch (e) {
        Bugsnag.notify(new Error("Unable to get Median Home Price for ZIP Code from Midas"), {
          metaData: { custom: { response: toPlainObject(response?.data), payload } },
        });
      }
    },
    async requestCountyInfo() {
      let county;

      try {
        county = await this.countiesClient.fetchCountyByZipCode(this.scenario.zipCode);
        this.scenario.fipsCode = county.fipsCode;

        if (this.propertyAddress.state && this.propertyAddress.state !== "") {
          this.scenario.propertyState = this.propertyAddress.state;
        } else {
          this.scenario.propertyState = county.state;
        }
      } catch (e) {
        Bugsnag.notify(new Error("Unable to retrieve county info from provided ZIP"));
      }
    },
    async requestPropertyDetails() {
      const { cbsaCode, cbsaType } = await this.propertyDetailsClient.fetchDetails({
        ...toPayload(this.propertyAddress),
        zipCode: this.scenario.zipCode,
      });
      Object.assign(this.scenario, { cbsaCode, cbsaType });
    },
    async loadScenario() {
      if (this.isNew) {
        this.scenario = ScenarioBuilder.build(this.programType);
        this.setBrokerDefaults();
        this.propertyAddress = new PostAddress();
        this.convertedScenario = {};
        this.pricingScenarios = [];
        this.creditboxResults = {};
      } else {
        this.isDraftLoading = true;
        try {
          const draft = await this.api.draft.read(this.id);
          this.programType = draft.details.loanProduct;
          const details = Object.assign({}, draft.details, { psLoanId: draft.psLoanId });
          this.setConvertedScenarioDetails(draft);
          this.scenario = ScenarioBuilder.build(this.programType, details);
          if (!draft.psLoanId) {
            this.setBrokerDefaults();
          }
          this.propertyAddress = new PostAddress(draft);
        } catch (error) {
          if (error.response.status === 404) {
            this.$router.push({ name: "loan_scenarios" });
          }
          Notification.error(error.response.data.errors);
        } finally {
          this.isDraftLoading = false;
          this.handleScenarioUpdate();
        }
      }
    },
    async createOrUpdateDraft() {
      if (this.isNew) {
        this.openSaveDraftModal();
      } else {
        let estimates = {};
        let details = this.scenario;

        if (this.loanCreationInProgress) {
          estimates = {
            pricing: this.pricingScenarios,
            creditbox: this.creditboxResults,
          };
          details = {
            ...details,
            convertedLtvRate: this.selectedScenario.ltv,
            customRate: this.selectedScenario.custom,
          };
        }

        const payload = {
          ...this.propertyAddress,
          zipCode: this.scenario.zipCode,
          details: details,
          estimate: estimates,
        };

        await this.updateDraft(payload);
        await this.onScenarioDraftSaved();
      }
    },
    async updateDraft(payload) {
      return await this.api.draft.update(this.id, payload);
    },
    async onScenarioDraftSaved() {
      if (this.loanCreationInProgress) return;

      await this.$router.push({ name: "loan_scenarios" });
      Notification.success("Scenario saved");
    },
    onScenarioAddressUpdated(newAddress) {
      this.propertyAddress = new PostAddress(newAddress);
      Notification.success("Address updated");
    },
    openSaveDraftModal() {
      if (this.hasConvertedScenario || (this.isRental && this.isRentalDisabled)) return;

      this.showSaveDraftModal = true;
    },
    closeSaveDraftModal() {
      if (this.loanCreationInProgress && !this.proceedWithCreation) {
        this.loanCreationInProgress = false;
      }

      this.showSaveDraftModal = false;
    },
    async getConstraints() {
      const programs = Object.values(PROGRAMS);

      this.constraints = await FetchConstraints.call(programs, this.serviceClient);
    },
    handleLoanProductUpdated(loanProduct) {
      if (this.scenario.loanProduct !== loanProduct) {
        this.programType = loanProduct;
        this.scenario = ScenarioBuilder.build(loanProduct);
        this.setBrokerDefaults();
      }
    },
    setConvertedScenarioDetails(draft) {
      if (!!draft.psLoanId) {
        this.convertedAt = formatDateTime(draft.convertedAt);
        this.convertedScenario = {
          custom: draft.details.customRate,
          ltv: draft.details.convertedLtvRate,
        };
        this.pricingScenarios = draft.estimate.pricing;
        let results = [];
        let creditbox = mapKeys(draft.estimate.creditbox, (v, k) => {
          return camelToSnakeCase(k);
        });
        results.push(creditbox._group);
        this.creditboxResults = this.serviceClient.buildCreditboxGroup({ groups: results });
      }
    },
    async createLoan() {
      this.loanCreationInProgress = true;

      if (this.isNew) {
        this.showSaveDraftModal = true;
      } else {
        await this.createOrUpdateDraft();
        await this.convertToDraftLoan();
      }
    },
    async convertToDraftLoan(id = null) {
      let result;
      let draftId = id || this.id;
      const payload = {
        ...this.propertyAddress,
        ...this.scenario.toLoanPayload(),
        total_principal_balance_at_origination: this.selectedScenario.loanAmount,
        interest_rate: this.selectedScenario.interestRate,
      };

      try {
        result = await this.draftLoansClient.post(draftId, payload);
        if (result?.psLoanId) {
          let url = this.createLoanUrl(result.psLoanId);
          Notification.success("Scenario successfully converted to draft loan");
          LocationService.assign(url);
        } else {
          Notification.error("Something went wrong");
        }
      } finally {
        this.loanCreationInProgress = false;
        this.proceedWithCreation = false;
      }
    },
    async onConvertToDraftLoan(id, newAddress) {
      this.proceedWithCreation = true;
      this.propertyAddress = new PostAddress(newAddress);
      this.scenario.zipCode = newAddress.zipCode;
      await this.convertToDraftLoan(id);
    },
    handleScenarioRowSelected(row) {
      this.selectedScenario = row;
    },
    createLoanUrl(id) {
      if (window.location.pathname.startsWith("/manage")) {
        return `/manage/loans/${id}/#edit`;
      } else {
        return `/loans/${id}/edit`;
      }
    },
    setBrokerDefaults() {
      this.scenario.lenderAccountType = this.lenderAccountType;

      if (this.lenderAccountType === AccountType.broker.value) {
        this.scenario.noteOrigination = NoteOrigination.fundingIntoOrigination.value;
        if (this.scenario.loanProduct === LoanProduct.residentialBridge.value) {
          this.scenario.lenderSpread = 0;
        }
      }
    },
  },
  computed: {
    isNew() {
      return this.id === "new";
    },
    helpUrl() {
      return HELP_URL;
    },
    currentConstraints() {
      return this.constraints[this.programType] || {};
    },
    loanUrl() {
      if (window.location.pathname.startsWith("/manage")) {
        return `/manage/loans/${this.scenario.psLoanId}`;
      } else {
        return `/loans/${this.scenario.psLoanId}`;
      }
    },
    isCreateLoanDisabled() {
      return (
        this.hasConvertedScenario || this.loanCreationInProgress || isEmpty(this.selectedScenario)
      );
    },
    showCreateLoanTooltip() {
      return (
        !this.hasConvertedScenario &&
        isEmpty(this.selectedScenario) &&
        this.pricingScenarios.length > 0
      );
    },
    hasConvertedScenario() {
      return !isEmpty(this.convertedScenario);
    },
    isRentalDisabled() {
      return true;
    },
    isRental() {
      return this.scenario.loanProduct === LoanProduct.residentialRentalLongTerm.value;
    },
    createLoanTooltip() {
      const msg =
        this.isRental && this.isRentalDisabled
          ? this.locale.tooltip.disabledRental
          : this.locale.tooltip.createLoan;
      return msg;
    },
  },
};
</script>

<style scoped>
.draft-container {
  background: url("./images/draft_bg.png") 0 0 / contain no-repeat;
}

.scenario {
  background-color: transparent;
  color: var(--color-grey-86);
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1400px;
  padding: 31px 8px 40px;
  position: relative;
  width: auto;
}

.scenario .draft-header {
  color: var(--color-white);
  padding-bottom: 23px;
}

.draft-header h3,
.draft-header p,
.draft-header .scenarios-link {
  color: var(--color-white);
}

.draft-header .scenarios-link {
  left: 15px;
  position: absolute;
  top: 34px;
}

.draft-header .scenarios-link:hover {
  cursor: pointer;
  text-decoration: none;
}

.scenarios-link .fas {
  padding-right: 4px;
}

.edit-scenario {
  padding: 9px 0 10px 0;
}

.edit-scenario a {
  color: var(--color-white);
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--color-grey-16);
}

.edit-scenario a.disabled {
  cursor: not-allowed;
}
.edit-scenario a.disabled .icon {
  display: none;
}

.edit-scenario .icon {
  color: var(--color-blue);
  padding-left: 4px;
}

.scenario h3 {
  font-size: 48px;
  font-weight: 600;
  line-height: 35px;
  margin-bottom: var(--space-unit-md);
}

.scenario p {
  font-size: var(--font-size-md);
  line-height: 20px;
  margin-bottom: var(--space-unit-md);
}

.scenario .form-content,
.scenario .estimates-content {
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-16);
  border-radius: var(--border-radius);
  height: fit-content;
  margin-bottom: var(--space-unit-md);
}

.scenario .form-content {
  padding: 24px;
}

@media (min-width: 1024px) {
  .scenario .form {
    margin-right: var(--space-unit-md);
    padding: 0;
  }
}
@media (min-width: 1024px) and (max-width: 1599px) {
  .scenario .form {
    max-width: 520px;
  }
}

@media (min-width: 1600px) {
  .scenario .form {
    max-width: 548px;
  }
}

.scenario .estimates {
  min-height: fit-content;
  position: relative;
  padding: 0;
}

.estimates-content {
  padding: 24px 24px 8px;
}

.estimates-header h3 {
  color: var(--color-grey-86);
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 24px;
}

.estimates-header div,
.estimates-header p {
  font-size: var(--font-size-sm);
}

.estimates-header .base-tag.is-large {
  font-size: var(--font-size-sm);
  height: 24px;
  line-height: var(--line-height-sm);
  margin-bottom: var(--space-unit-sm);
  padding: var(--space-unit-sm) var(--space-unit);
  text-transform: initial;
}

.estimates-header a {
  margin-left: var(--space-unit-sm);
}

.estimates-header p {
  color: var(--color-grey-60);
}

.estimates > div {
  flex-basis: 100%;
  position: relative;
}

.estimates .note {
  color: var(--color-grey-60);
  font-size: 14px;
  line-height: 19px;
  padding-left: 4px;
}

.estimates .help {
  color: var(--color-blue);
  font-size: 14px;
  line-height: 19px;
  margin-bottom: var(--space-unit-md);
}
</style>
