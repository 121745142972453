<template>
  <div class="tag-manager">
    <header class="form-header">Tag Lender ({{ activeTotal }})</header>

    <div class="tag-list-group">
      <ul class="tag-list">
        <tag
          v-for="tag in sortedActive"
          :active="true"
          :editable="canEditTags"
          :name="tag.name"
          :key="tag.name"
          @update="handleTagDelete(tag.name)"
        />
      </ul>

      <ul class="tag-list">
        <tag
          v-for="tag in sortedInactive"
          :active="false"
          :editable="canEditTags"
          :name="tag.name"
          :key="tag.name"
          @update="handleTagCreate(tag.name)"
        />
      </ul>
    </div>

    <button class="btn btn-action-cta" @click="updateTags" :disabled="isDisabled">
      {{ saveLabel }}
    </button>
  </div>
</template>

<script>
import get from "lodash/get";
import Tag from "./tag";
import TagService from "services/tag_service";

const STATUS = Object.freeze({
  active: "active",
  inactive: "inactive",
});

export default {
  name: "tag-manager",
  components: {
    Tag,
  },
  props: {
    initialTags: {
      type: Array,
      default: () => [],
    },
    messageBus: {
      type: Object,
      required: true,
    },
    tagOptions: {
      type: Array,
      default: () => [],
    },
    resource: {
      type: String,
      required: true,
    },
    resourceId: {
      required: true,
    },
    canEditTags: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      active: this.createActiveTags(this.initialTags),
      inactive: this.createInactiveTags(this.initialTags),
      isUpdatingTags: false,
    };
  },
  computed: {
    allTags() {
      return this.active.concat(this.inactive);
    },
    activeTotal() {
      return this.active.length;
    },
    isDirty() {
      return !this.allTags.every(tag => tag.initial === tag.current);
    },
    isDisabled() {
      return this.isUpdatingTags || !this.isDirty;
    },
    tagsToCreate() {
      return this.allTags.filter(
        tag => tag.initial !== tag.current && tag.current === STATUS.active
      );
    },
    tagsToDelete() {
      return this.allTags.filter(
        tag => tag.initial !== tag.current && tag.current === STATUS.inactive
      );
    },
    saveLabel() {
      return this.isUpdatingTags ? "Saving..." : "Save";
    },
    sortedActive() {
      return TagService.sortAlphabetically(this.active);
    },
    sortedInactive() {
      return TagService.sortAlphabetically(this.inactive);
    },
  },
  methods: {
    decorateTag(tag, state) {
      return { ...tag, initial: state, current: state };
    },
    handleTagServiceResponse({ tags }) {
      this.active = this.createActiveTags(tags);
      this.inactive = this.createInactiveTags(tags);

      this.messageBus.broadcast({ tags });
    },
    handleTagCreate(name) {
      const tag = this.inactive.find(tag => tag.name === name);

      this.inactive = this.inactive.filter(tag => tag.name !== name);
      this.active.push(this.toggleTagState(tag));
    },
    handleTagDelete(name) {
      const tag = this.active.find(tag => tag.name === name);

      this.active = this.active.filter(tag => tag.name !== name);
      this.inactive.push(this.toggleTagState(tag));
    },
    toggleTagState(tag) {
      return { ...tag, current: tag.current === "active" ? "inactive" : "active" };
    },
    updateTags() {
      const { tagsToCreate, tagsToDelete, resource, resourceId } = this;
      const tags = { create: tagsToCreate, delete: tagsToDelete };

      this.isUpdatingTags = true;

      TagService.update(tags, resource, resourceId)
        .then(this.handleTagServiceResponse)
        .finally((this.isUpdatingTags = false));
    },
    createActiveTags(active) {
      return active.map(tag => this.decorateTag(tag, STATUS.active));
    },
    createInactiveTags(active) {
      return this.tagOptions
        .filter(option => !active.find(tag => tag.name === option.name))
        .map(option => this.decorateTag(option, STATUS.inactive));
    },
  },
};
</script>
