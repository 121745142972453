<template>
  <div class="bulk-assign">
    <div class="table-container">
      <div v-show="showServerErrors">
        <error-messages title="Error!" :errors="serverErrors" :dismissable="true" />
      </div>

      <div class="horizontal-input-group">
        <div>
          <label>Asset Manager</label>
          <editable-select
            name="asset-manager"
            class="bulk-input"
            no-status-label=""
            :value="form.assetManagerId"
            :options="assetManagerOptions"
            :editing="editing"
            :disabled="skipped.assetManagerId"
            v-validate="{ required: isBulkParamRequired }"
            @input="e => updateForm('assetManagerId', e.target.value)"
          />
        </div>
        <div class="checkbox-control checkbox-control-blue">
          <input
            id="asset-manager-not-applicable"
            type="checkbox"
            name="skip-asset-manager"
            :checked="skipped.assetManagerId"
            v-validate="{ required: isAssetManagerBlank }"
            @input="e => skipField('assetManagerId', e.target.checked)"
          >
          <label for="asset-manager-not-applicable">
            <span class="control-toggle" />
            <span>Not Applicable</span>
          </label>
        </div>
      </div>

      <div class="horizontal-input-group">
        <div>
          <label>Loan Action</label>
          <editable-select
            name="loan-action"
            class="bulk-input"
            no-status-label="No Action"
            :value="form.statusId"
            :options="foreclosureStatusTypes"
            :editing="editing"
            :disabled="skipped.statusId"
            v-validate="{ required: isBulkParamRequired }"
            @input="e => updateForm('statusId', e.target.value)"
          />
        </div>
        <div class="checkbox-control checkbox-control-blue">
          <input
            id="loan-action-not-applicable"
            type="checkbox"
            name="skip-loan-action"
            :checked="skipped.statusId"
            v-validate="{ required: isLoanActionBlank }"
            @input="e => skipField('statusId', e.target.checked)"
          >
          <label for="loan-action-not-applicable">
            <span class="control-toggle" />
            <span>Not Applicable</span>
          </label>
        </div>
      </div>

      <label>Loan Ids</label>
      <editable-text-area
        class="loan-id-textarea-wrapper"
        placeholder="Copy and Paste Loan IDs"
        :editing="true"
        name="notes"
        :value="form.loanIds"
        v-validate="rules"
        @input="e => updateForm('loanIds', e.target.value)"
      />

      <div class="bulk-actions">
        <button
          class="btn btn-action-cta"
          type="submit"
          @click="save"
          :disabled="!validForm"
        >
          Assign Loans
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { pluralize } from "filters";
import ErrorMessages from "components/shared/error_messages";
import EditableSelect from "../editable_select";
import EditableTextArea from "../editable_textarea";
import AjaxService from "services/ajax_service";
import compact from "lodash/compact";
import { error, success } from "services/notification";

const LOAN_ID_PREFIX = PSData.loanIdPrefix || "10";
const VALID_LOAN_IDS_FORMAT = new RegExp(`^((\\s|;|,)*(?:${LOAN_ID_PREFIX})?\\d{10}(\\s|;|,)*)+$`);
const HEADERS = { "Content-Type": "application/json" };
const UNPROCESSABLE_ENTITY_STATUS = 422;

export default {
  name: "bulk-assign-tab",
  inject: ["$validator"],
  components: {
    EditableSelect,
    EditableTextArea,
    ErrorMessages,
  },
  data() {
    const loanIds = PSData.loanIds || "";

    return {
      form: {
        loanIds,
        assetManagerId: null,
        statusId: null,
      },
      skipped: {
        assetManagerId: false,
        statusId: false,
      },
      valid: false,
      disabled: false,
      editing: true,
      serverErrors: [],
      foreclosureStatusTypes: PSData.loanForeclosureStatusTypes || [],
    };
  },
  computed: {
    assetManagerOptions() {
      const assetManagers = PSData.assetManagers || [];
      return assetManagers.map(u => ({ id: u.id, text: u.name }));
    },
    rules() {
      return { required: true, regex: VALID_LOAN_IDS_FORMAT };
    },
    splitIds() {
      const loanIdsList = this.form.loanIds.replace(/[,;\n]/g, ",").split(",");
      return compact(loanIdsList).map(loanId => loanId.trim());
    },
    showServerErrors() {
      return this.serverErrors.length;
    },
    isBulkParamRequired() {
      return !this.form.statusId && !this.form.assetManagerId;
    },
    isAssetManagerBlank() {
      return !this.form.assetManagerId;
    },
    isLoanActionBlank() {
      return !this.form.statusId;
    },
    validForm() {
      return this.valid && (!this.skipped.assetManagerId || !this.skipped.statusId);
    },
  },
  methods: {
    updateForm(field, value) {
      this.$set(this.form, field, value);

      Vue.nextTick(() => {
        this.$validator.validateAll().then(result => (this.valid = result));
      });
    },
    skipField(field, value) {
      this.$set(this.skipped, field, value);

      if (this.skipped[field]) {
        this.$set(this.form, field, "");
      }

      Vue.nextTick(() => {
        this.$validator.validateAll().then(result => (this.valid = result));
      });
    },
    prepareParams() {
      let params = { loan_ids: this.splitIds };
      if (!this.skipped.assetManagerId) {
        params["asset_manager_id"] = this.form.assetManagerId;
      }
      if (!this.skipped.statusId) {
        params["status_id"] = this.form.statusId;
      }
      return params;
    },
    async save() {
      const params = this.prepareParams();

      this.serverErrors = [];
      this.showUpdatedRecords = false;

      try {
        const response = await AjaxService.patch(PSData.routes.bulkAssign, params, HEADERS);

        this.form.loanIds = "";
        this.form.assetManagerId = null;
        this.form.statusId = null;

        Vue.nextTick(() => {
          this.$validator.validateAll().then(result => (this.valid = result));
        });

        success(`${pluralize(response.loans.length, "loans")} updated`);
      } catch ({ response, data }) {
        if (response.status === UNPROCESSABLE_ENTITY_STATUS) {
          const { loans } = data;
          this.serverErrors = loans.map(loan => `${loan} - Invalid loan`);
        } else {
          error(`Something went wrong. Error: ${response.statusText} ${response.status}`);
        }
      }
    },
  },
};
</script>
