<template>
  <div v-if="show" class="loan-suspension">
    <div class="loan-suspension-header">
      {{ suspension.statusName }}
    </div>
    <div class="loan-suspension-list-header">
      Reasons
    </div>
    <ul>
      <li v-for="reason in suspension.loanSuspensionReasons" :key="reason.id">
        {{ reason.label }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "loan-suspension-details",
  data() {
    return {
      suspension: PSData.loanSuspension,
    };
  },
  computed: {
    show() {
      return this.suspension && this.suspension.loanSuspensionReasons.length;
    },
  },
};
</script>
