import pluralizer from "pluralize";

import DateFormatter from "models/formatters/date_formatter";
import NumberFormatter from "models/formatters/number_formatter";
import ValueFormatter from "models/formatters/value_formatter";

export const formatDate = DateFormatter.formatDate;
Vue.filter("formatDate", formatDate);

export const formatTime = DateFormatter.formatTime;
Vue.filter("formatTime", formatTime);

export const fromNow = DateFormatter.fromNow;
Vue.filter("fromNow", fromNow);

export function money(value, precision = 2, numberOnly) {
  return NumberFormatter.formatMoney(value, precision, numberOnly);
}
Vue.filter("money", money);

export function percent(value, precision = 2, numberOnly) {
  return NumberFormatter.formatPercent(value, precision, numberOnly);
}
Vue.filter("percent", percent);

export function number(value, precision = 2, numberOnly) {
  return NumberFormatter.formatNumber(value, precision, numberOnly);
}
Vue.filter("number", number);

export function round(value, precision = 2) {
  return parseFloat(value).toFixed(precision);
}
Vue.filter("round");

export function ceil(value) {
  return Math.ceil(value);
}
Vue.filter("ceil");

export function floor(value) {
  return Math.floor(value);
}
Vue.filter("floor", floor);

export function pluralize(value, word, inclusive = true) {
  return pluralizer(word, value, inclusive);
}
Vue.filter("pluralize", pluralize);

export function phoneNumber(value) {
  return ValueFormatter.formatPhoneNumber(value);
}
Vue.filter("phoneNumber", phoneNumber);

export function accounting(value, precision = 2, numberOnly = false) {
  return NumberFormatter.formatAccounting(value, precision, numberOnly);
}
Vue.filter("accounting", accounting);

export function missingValue(value) {
  return ValueFormatter.formatInvalidValue(value, "--");
}
Vue.filter("missingValue", missingValue);

export function notAvailable(value) {
  return ValueFormatter.formatInvalidValue(value, "N/A");
}
Vue.filter("notAvailable", notAvailable);

export function yesNo(value) {
  return ValueFormatter.formatYesNoValue(value);
}
Vue.filter("yesNo", yesNo);
