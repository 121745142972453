<template>
  <editable-input :value="value" :label="label" :editing="editing">
    <template #edit>
      <input
        v-datepicker
        v-mask:date
        class="form-control foreclosure-input"
        :value="value"
        :disabled="disabled"
        @input="$listeners.input($event.target.value)"
      >
    </template>
  </editable-input>
</template>

<script>
import EditableInput from "./editable_input";

export default {
  name: "editable-date",
  components: {
    EditableInput,
  },
  props: EditableInput.props,
};
</script>
