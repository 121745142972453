<template>
  <span>
    <span v-if="isTruncated" v-popover.dynamic>
      {{ truncatedValue }}
    </span>
    <span :class="{ 'hidden':isTruncated }" v-html="html" />
  </span>
</template>
<script>
import truncate from "lodash/truncate";

const MAX_LENGTH = 15;

export default {
  name: "manage-servicer-loan-boardings-truncated-text",
  props: {
    value: {
      type: String,
    },
    maxLength: {
      type: Number,
      default: MAX_LENGTH,
    },
  },
  computed: {
    html() {
      if (this.value) {
        return this.value.replace(/(?:\r\n|\r|\n)/g, "<br>");
      }
    },
    isTruncated() {
      return (this.value || "").length > this.maxLength;
    },
    truncatedValue() {
      return truncate(this.value, { length: this.maxLength });
    },
  },
};
</script>
