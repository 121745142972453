<template>
  <div>
    <time-series-comparison-chart
      :chart="chart"
      :sources="sources">
    </time-series-comparison-chart>

    <div class="row listing-stats-explanation-section">
      <div class="col-md-5">
        Most Recent
      </div>
      <div class="col-md-1 value-section">
        <span v-if="sources.isLoading">
          &nbsp;
        </span>
        <span v-if="sources.isSuccessful">
          {{ sources.mostRecent | number(0, true) | notAvailable }}
        </span>
      </div>
      <div class="col-md-5">
        One Year Ago
      </div>
      <div class="col-md-1 value-section">
        <span v-if="sources.isLoading">
          &nbsp;
        </span>
        <span v-if="sources.isSuccessful">
          {{ sources.oneYearAgo | number(0, true) | notAvailable }}
        </span>
      </div>
    </div>

  </div>
</template>

<script>
import { format, timeFormat } from "d3";

import TimeSeriesComparisonChart from "components/market_data/time_series_comparison_chart";

export default {
  name: "listing-stats-chart",
  components: {
    TimeSeriesComparisonChart,
  },
  props: {
    sources: Object,
  },
  data() {
    return {
      chart: {
        title: this.sources.name,
        columns: {
          hcMarketIndex: {
            color: "#66cc33",
            observed: {
              data: this.sources.legend,
              labels: "hc-zip-observed",
            },
            predicted: {
              data: this.sources.legend + " (forecast)",
              labels: "hc-zip-predicted",
            },
          },
        },
        tooltipTitleFormat: timeFormat("%d %b %Y"),
        tickFormat: format(",.0f"),
      },
    };
  },
};
</script>
