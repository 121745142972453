<template>
  <div class="outreach-form">
    <div class="columns">
      <div class="column">
        <base-date-field
          :rules="'required'"
          label="Date"
          name="outreachDate"
          :value="outreachForm.outreachDate"
          @input="updateField('outreachDate', $event)"
        />
      </div>
      <div class="column">
        <base-select-field
          :rules="'required'"
          label="Contact"
          name="contact"
          show-empty
          :options="contactOptions"
          :value="outreachForm.contact"
          @input="updateField('contact', $event)"
        />
        <base-text-field
          :rules="'required'"
          name="otherContactName"
          label="Contact Name"
          placeholder="Name"
          :disabled="!contactSelected"
          :value="outreachForm.otherContactName"
          @input="updateField('otherContactName', $event)"
        />
      </div>
      <div class="column">
        <base-select-field
          :rules="'required'"
          name="category"
          label="Category"
          show-empty
          :options="outreachCategoriesOptions"
          :value="outreachForm.category"
          @input="updateField('category', $event)"
        />
        <base-select-field
          :rules="'required'"
          label="Incoming/Outgoing"
          name="outgoing"
          :options="outgoingOptions"
          :value="outreachForm.outgoing"
          @input="updateField('outgoing', $event)"
        />
      </div>
      <div class="column">
        <base-select-field
          :rules="'required'"
          label="Method"
          name="contactMethod"
          show-empty
          :options="contactMethodOptions"
          :value="outreachForm.contactMethod"
          @input="updateField('contactMethod', $event)"
        />
        <base-text-field
          v-show="emailMethodSelected"
          :rules="emailValidations"
          label="Email"
          name="email"
          :disabled="isContactMethodDisabled"
          :placeholder="emailPlaceholder"
          :value="outreachForm.email"
          @input="updateField('email', $event)"
        />
        <base-text-field
          v-show="!emailMethodSelected"
          :rules="phoneValidations"
          label="Phone"
          name="phone"
          :disabled="isContactMethodDisabled"
          :placeholder="phonePlaceholder"
          :value="outreachForm.phone"
          @input="updateField('phone', $event)"
        />
      </div>
      <div class="column">
        <base-select-field
          :rules="'required'"
          name="outreachTypeId"
          label="Outreach Status"
          show-empty
          :options="outreachTypesOptions"
          :value="outreachForm.outreachTypeId"
          @input="updateField('outreachTypeId', $event)"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <base-text-field
          type="textarea"
          label="Notes"
          name="notes"
          :rules="'required'"
          :value="outreachForm.notes"
          @input="updateField('notes', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from "vee-validate";

export default {
  props: {
    borrower: {
      type: Object,
      required: true,
    },
    outreachTypes: {
      type: Array,
      required: true,
    },
    outreachCategories: {
      type: Array,
      required: true,
    },
    guarantor: {
      type: Object,
      required: true,
    },
    lender: {
      type: Object,
      required: true,
    },
    initialOutreach: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contactOptions: [
        ["Attorney", "attorney"],
        ["Borrower", "borrower"],
        ["Guarantor", "guarantor"],
        ["Lender", "lender"],
        ["Other", "other"],
      ],
      contactMethodOptions: [["Phone", "phone"], ["Email", "email"], ["Text", "text"]],
      outgoingOptions: [["Incoming", false], ["Outgoing", true]],
      outreachForm: { ...this.initialOutreach },
    };
  },
  computed: {
    outreachCategoriesOptions() {
      return this.outreachCategories.map(({ id, text }) => [text, id]);
    },
    outreachTypesOptions() {
      return this.outreachTypes.map(({ id, text }) => [text, id]);
    },
    contactMethodSelected() {
      return !!this.outreachForm.contactMethod;
    },
    emailMethodSelected() {
      return this.outreachForm.contactMethod === "email";
    },
    phoneMethodSelected() {
      return (
        this.outreachForm.contactMethod === "text" || this.outreachForm.contactMethod === "phone"
      );
    },
    emailPlaceholder() {
      return this.contactMethodSelected ? "Email" : "";
    },
    phonePlaceholder() {
      return this.contactMethodSelected ? "Phone" : "";
    },
    contactSelected() {
      return !!this.outreachForm.contact;
    },
    isContactMethodDisabled() {
      return !this.contactSelected || !this.contactMethodSelected;
    },
    otherContactSelected() {
      return this.outreachForm.contact === "other";
    },
    emailValidations() {
      const { emailMethodSelected } = this;
      return {
        required: emailMethodSelected,
        email: emailMethodSelected,
      };
    },
    phoneValidations() {
      const { phoneMethodSelected } = this;
      return {
        required: phoneMethodSelected,
        phone: phoneMethodSelected,
      };
    },
    outreachType() {
      return this.outreachTypes.find(type => type.id === this.outreachForm.outreachTypeId);
    },
    outreachTypeText() {
      return this.outreachType ? this.outreachType.text : "";
    },
  },
  created() {
    Validator.extend("phone", {
      validate: value => {
        const phoneNumberLength = (value.match(/\d/g) || []).length;
        return phoneNumberLength > 8 && phoneNumberLength < 12;
      },
      getMessage: () => "must provide a valid phone number",
    });
  },
  watch: {
    initialOutreach(value) {
      this.outreachForm = { ...value };
    },
    "outreachForm.contact": function(value) {
      this.setDefaultPhoneEmail(value);
      this.setDefaultOtherContactName(value);
      this.$emit("update-form", this.outreachForm);
    },
    "outreachForm.contactMethod": function() {
      this.setDefaultPhoneEmail(this.outreachForm.contact);
      this.$emit("update-form", this.outreachForm);
    },
  },
  methods: {
    defaultValueFor(contact, fieldName) {
      if (
        ["borrower", "lender", "guarantor"].includes(contact) &&
        ["email", "name", "phone"].includes(fieldName)
      ) {
        return this[contact][fieldName];
      }

      return "";
    },
    setDefaultPhoneEmail(contact) {
      const email =
        contact && this.contactMethodSelected ? this.defaultValueFor(contact, "email") : "";
      const phone =
        contact && this.contactMethodSelected ? this.defaultValueFor(contact, "phone") : "";

      this.$set(this.outreachForm, "email", email);
      this.$set(this.outreachForm, "phone", phone);
    },
    setDefaultOtherContactName(contact) {
      const otherContactName = this.defaultValueFor(contact, "name");

      this.$set(this.outreachForm, "otherContactName", otherContactName);
    },
    updateField(name, value) {
      const updatedValue = typeof value == "string" ? value.trim() : value;
      this.$set(this.outreachForm, name, updatedValue);
      this.$emit("update-form", this.outreachForm);
    },
  },
};
</script>
<style scoped>
.outreach-form {
  padding: var(--space-unit-md);
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-grey-16);
  margin-bottom: var(--space-unit-md);
}

.outreach-form >>> textarea {
  font-weight: 400;
}
</style>
