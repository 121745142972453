import InstantSearch from "components/instant_search/instant_search";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-menu-controller"),
    components: {
      InstantSearch,
    },
  });
});
