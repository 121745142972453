<template>
  <div class="distribution-rules col-md-9">
    <div v-if="loading" class="ps-loader ps-loader-small"></div>
    <div v-else class="row">
      <Subservicers v-bind="{ subservicers, currentSubservicerId }"
        @clear="clearSubservicer"
        @details="setSubservicer"
      />
      <Fees
        v-if="currentSubservicer"
        v-bind="{ records: currentSubservicerRecords, currentSubservicer }"
        @updated="updateFee"
        @created="addFee"
      />
    </div>
  </div>
</template>
<script>
import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";
import find from "lodash/find";

import Subservicers from "./subservicers";
import Fees from "./fees";

import AjaxService from "services/ajax_service";
import { error } from "services/notification";

const VIEWS = {
  none: "none",
  fees: "fees",
};

export default {
  components: { Subservicers, Fees },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      view: VIEWS.list,
      currentSubservicerId: undefined,
      data: [],
    };
  },
  created() {
    this.requestData();
    this.initialize();
  },
  computed: {
    currentSubservicer() {
      return this.subservicers[this.currentSubservicerId];
    },
    currentSubservicerRecords() {
      return this.groupedBySubservicer[this.currentSubservicerId];
    },
    groupedBySubservicer() {
      return groupBy(this.data, "servicerEntity.id");
    },
    subservicers() {
      return uniq(this.data.map(record => record.servicerEntity), "id").reduce((acc, record) => {
        const recordsCount = this.groupedBySubservicer[record.id].length;
        acc[record.id] = { ...record, recordsCount };
        return acc;
      }, {});
    },
  },
  methods: {
    initialize() {
      let view, currentSubserviceId;

      if (this.filters.subservicerId) {
        currentSubserviceId = parseInt(this.filters.subservicerId);
        view = VIEWS.fees;
      }

      this.view = view;
      this.currentSubservicerId = currentSubserviceId;
    },
    clearSubservicer() {
      this.currentSubservicerId = null;
      this.view = VIEWS.none;
    },
    setSubservicer(subservicerId) {
      this.currentSubservicerId = subservicerId;
      this.view = VIEWS.fees;

      this.$emit("filter", { ...this.filters, subservicerId: subservicerId });
    },
    updateFee(fee) {
      Object.assign(find(this.data, { id: fee.id }), fee);
    },
    addFee(fee) {
      this.data.push(fee);
    },
    async requestData() {
      this.loading = true;

      try {
        this.data = await AjaxService.getJSON("/manage/cash_receipt/subservicer_fees");
      } catch {
        error("Something went wrong");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
