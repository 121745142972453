<template>
  <section>
    <b-field class="tag-input">
      <b-taginput
        v-model="mutableTags"
        :data="filteredTags"
        :autocomplete="true"
        :open-on-focus="true"
        field="label"
        placeholder="Add a tag"
        @typing="getFilteredTags"
        @add="clearText"
      >
        <template v-slot="props"> {{ props.option.label }} </template>
        <template #empty>Loading tag types...</template>
      </b-taginput>
    </b-field>
  </section>
</template>

<script>
export default {
  props: {
    tags: { type: Array, default: () => [] },
    options: { type: Array, default: () => [] },
  },
  data() {
    return {
      mutableTags: [],
      text: "",
    };
  },
  computed: {
    filteredTags() {
      return this.availableTags.filter(option => {
        return option.label.toLowerCase().indexOf(this.text.toLowerCase()) >= 0;
      });
    },
    availableTags() {
      return this.options.filter(option => {
        const tagAlreadySelected = this.mutableTags.includes(option);
        return !tagAlreadySelected;
      });
    },
  },
  watch: {
    mutableTags() {
      this.$emit("on-tag-change", this.mutableTags);
    },
    tags() {
      this.mutableTags = this.tags;
    },
  },
  methods: {
    clearText() {
      this.text = "";
    },
    getFilteredTags(text) {
      this.text = text;
    },
  },
};
</script>

<style>
.dropdown-item {
  text-decoration: none !important;
  color: unset !important;
}
.tag-input {
  padding-bottom: var(--space-unit-md);
}

.dropdown-content {
  overflow-y: auto;
}

.dropdown-content::-webkit-scrollbar {
  -webkit-appearance: none;
}

.dropdown-content::-webkit-scrollbar:vertical {
  width: 11px;
}

.dropdown-content::-webkit-scrollbar:horizontal {
  height: 11px;
}

.dropdown-content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
