import merge from "lodash/merge";

export const SETTINGS_KEYS = Object.freeze({
  wired: "hotlistWired",
  purchasing: "hotlistPurchasing",
  purchased: "hotlistPurchased",
  diligenced: "hotlistDiligenced",
  diligencing: "hotlistDiligencing",
  uploadFiles: "hotlistUploadFiles",
  qualified: "hotlistQualified",
  evaluating: "hotlistEvaluating",
  review: "hotlistReview",
  draft: "hotlistDraft",
  paidOff: "hotlistPaidOff",
  active: "hotlistActive",
  rejected: "hotlistRejected",
  canceled: "hotlistCanceled",
});

export const DEFAULT_COLUMNS_CONFIG = Object.freeze({
  address: {
    show: true,
    title: "Address",
    disabled: true,
  },
  age: {
    show: true,
    title: "Age / Last",
  },
  analyst: {
    show: true,
    title: "Analyst",
  },
  docs: {
    show: true,
    title: "Docs",
  },
  forecastPurchaseDate: {
    show: true,
    title: "Rate Lock Expiration Date",
  },
  loanId: {
    show: true,
    title: "Lender / Loan ID",
  },
  loanStatus: {
    show: true,
    title: "Loan Status",
  },
  ltvArv: {
    show: true,
    title: "LTV / ARV %",
  },
  rate: {
    show: true,
    title: "Note / Investor",
  },
  notes: {
    show: true,
    title: "Notes",
  },
  noteStatus: {
    show: true,
    title: "Note Status / Origin Date",
  },
  openInternalTasks: {
    show: true,
    title: "Open Internal Tasks",
  },
  openLenderTasks: {
    show: true,
    title: "Open Lender Tasks",
  },
  openQcTasks: {
    show: true,
    title: "Open QA Tasks",
  },
  currentProgressUpdate: {
    show: true,
    title: "Progress Update",
  },
  propertyType: {
    show: true,
    title: "Property Type",
  },
  psAllocation: {
    show: true,
    title: "PS Allocation / B-Piece",
  },
  purchaseDate: {
    show: true,
    title: "Purchase Date",
  },
  qcApprover: {
    show: true,
    title: "QA Approver",
  },
  qcDate: {
    show: true,
    title: "QA / Date",
  },
  qcApprovalTask: {
    show: true,
    title: "QC Approval",
  },
  targetPurchaseDate: {
    show: true,
    title: "Scheduled Wire Date",
  },
  term: {
    show: true,
    title: "Term / Ext",
  },
  valuation: {
    show: true,
    title: "Valuation",
  },
  lenderTargetPurchaseDate: {
    show: true,
    title: "Requested Wire Date",
  },
});

export const columnsVisibility = {
  docs: group => {
    return [
      "draft",
      "review",
      "evaluating",
      "qualified",
      "uploadFiles",
      "diligencing",
      "purchasing",
      "wired",
      "purchased",
      "active",
      "paidOff",
    ].includes(group);
  },
  purchaseDate: group => {
    return ["purchased", "wired", "active", "paidOff"].includes(group);
  },
  forecastPurchaseDate: group => {
    return [
      "draft",
      "review",
      "evaluating",
      "qualified",
      "uploadFiles",
      "diligencing",
      "diligenced",
    ].includes(group);
  },
  targetPurchaseDate: group => {
    return [
      "wired",
      "diligenced",
      "purchasing",
      "diligencing",
      "uploadFiles",
      "qualified",
    ].includes(group);
  },
  lenderTargetPurchaseDate: group => {
    return ["evaluating", "draft"].includes(group);
  },
  notes: group => {
    return !["purchasing", "wired"].includes(group);
  },
  currentProgressUpdate: group => {
    return !columnsVisibility.notes(group);
  },
  age: group => {
    return !["purchased", "paidOff"].includes(group);
  },
  qcApprovalTask: group => {
    return ["purchased"].includes(group);
  },
};

const getGroupColumnsConfig = group => {
  const columns = merge({}, DEFAULT_COLUMNS_CONFIG);

  Object.keys(columns).forEach(columnKey => {
    const hidden = columnsVisibility[columnKey] && !columnsVisibility[columnKey](group);
    columns[columnKey].hidden = hidden;
    columns[columnKey].show = !hidden;
  });

  return columns;
};

export const SETTINGS_CONFIG = {
  [SETTINGS_KEYS.wired]: {
    title: "Display Columns (Wired)",
    items: getGroupColumnsConfig("wired"),
  },
  [SETTINGS_KEYS.purchasing]: {
    title: "Display Columns (Purchasing)",
    items: getGroupColumnsConfig("purchasing"),
  },
  [SETTINGS_KEYS.purchased]: {
    title: "Display Columns (Purchased)",
    items: getGroupColumnsConfig("purchased"),
  },
  [SETTINGS_KEYS.diligenced]: {
    title: "Display Columns (Diligenced)",
    items: getGroupColumnsConfig("diligenced"),
  },
  [SETTINGS_KEYS.diligencing]: {
    title: "Display Columns (In Diligence)",
    items: getGroupColumnsConfig("diligencing"),
  },
  [SETTINGS_KEYS.uploadFiles]: {
    title: "Display Columns (Upload Files)",
    items: getGroupColumnsConfig("uploadFiles"),
  },
  [SETTINGS_KEYS.qualified]: {
    title: "Display Columns (Qualified)",
    items: getGroupColumnsConfig("qualified"),
  },
  [SETTINGS_KEYS.evaluating]: {
    title: "Display Columns (Evaluating)",
    items: getGroupColumnsConfig("evaluating"),
  },
  [SETTINGS_KEYS.review]: {
    title: "Display Columns (Review)",
    items: getGroupColumnsConfig("review"),
  },
  [SETTINGS_KEYS.draft]: {
    title: "Display Columns (Draft)",
    items: getGroupColumnsConfig("draft"),
  },
  [SETTINGS_KEYS.paidOff]: {
    title: "Display Columns (Paid Off)",
    items: getGroupColumnsConfig("paidOff"),
  },
};
