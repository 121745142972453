<template>
  <base-tooltip :label="name" position="is-top" type="is-white">
    <div class="user-image">
      <div>{{ initials }}</div>
    </div>
  </base-tooltip>
</template>

<script>
export default {
  name: "user",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    initials() {
      return this.name
        .split(" ")
        .map(part => part[0])
        .join("");
    },
  },
};
</script>
<style lang="scss">
.user {
  text-align: center;
  width: 80px;

  .user-image {
    align-items: center;
    background-color: var(--color-primary-green);
    border-radius: 50%;
    color: var(--color-white);
    display: inline-block;
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-style: normal;
    font-weight: 600;
    height: 32px;
    line-height: var(--line-height-sm);
    padding: 8px 0 8px 1px;
    text-align: center;
    width: 32px;
  }

  .base-tooltip {
    margin-left: 0;
  }
}
</style>
