<template>
  <div class="loan-suspension">
    <loan-suspend-modal v-if="show" @saved="onCreate" @closed="onClose"/>
  </div>
</template>
<script>
import LoanSuspendModal from "components/manage/loan_suspension/modal";
import LocationService from "services/location_service";
import WindowService from "services/window_service";

export default {
  name: "manage-loan-suspension-suspend",
  components: { LoanSuspendModal },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.$emit("closed");
    },
    onCreate() {
      this.$emit("closed");
      WindowService.scrollTo(0, 0);
      LocationService.reload();
    },
  },
};
</script>
