<template>
  <div class="categorized-bar-chart">
    <h3 class="title">
      {{chart.title}}
      <div v-if="sources.isLoading" class="ps-loader ps-loader-small"></div>
    </h3>
    <slot name="header"></slot>
    <div class="body">
      <div v-if="chart.axisYTitle" class="axes-title">
        {{chart.axisYTitle}}
      </div>
      <div class="chart" ref="chart"></div>
      <div v-if="chart.axisXTitle" class="axes-title text-center">
        {{chart.axisXTitle}}
      </div>
      <div v-if="chart.showLegend" class="legend">
        <span
          v-for="(column, key) in sources.data"
          :key="key"
          :class="legendClasses[key]"
          @mouseover="onMouseOver(key)"
          @mouseout="onMouseOut(key)"
          @click="onClick(key)"
          class="legend-item">
            <span :style="{ backgroundColor: chart.columns[key].color }"></span>
            {{chart.columns[key].label}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "d3";
import c3 from "c3";
import MarketDataChartMixin from "components/market_data/market_data_chart_mixins";

export default {
  name: "categorized-bar-chart",
  mixins: [MarketDataChartMixin],
  data() {
    return {
      options: {
        axis: {
          x: {
            type: "category",
            tick: {
              rotate: this.chart.axisXTickRotate,
              multiline: false,
            },
          },
          y: {
            tick: {
              format: this.chart.axisYTickFormat,
            },
          },
        },
        grid: {
          y: {
            show: true,
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          format: {
            value: this.chart.tooltipFormatValue || format(",.2f"),
          },
        },
        bar: {
          width: {
            ratio: 0.8,
          },
        },
        size: {
          height: undefined,
        },
      },
      legendClasses: this.prepareLegendClasses(),
    };
  },
  methods: {
    render() {
      this.options.size.height = this.chart.sizeHeight;
      this.options.axis.x.categories = Object.values(this.chart.labels);
      this.options.data = this.prepareData();
      this.legendClasses = this.prepareLegendClasses();
      this.c3 = c3.generate({
        ...this.options,
        bindto: this.$refs.chart,
      });
    },
    prepareData() {
      let data = this.sources.data;
      let columnNames = Object.keys(this.chart.columns);
      let categoryKeys = Object.keys(this.chart.labels);

      return {
        empty: {
          label: {
            text: this.chartMessage,
          },
        },
        type: "bar",
        columns: columnNames.map(columnName => {
          let columnData;
          if (this.sources.hasData) {
            columnData = categoryKeys
              .map(k => data[columnName][k])
              .filter(v => typeof v !== "undefined");
          } else {
            columnData = [];
          }

          return [this.findColumnName(columnName), ...columnData];
        }),
        colors: columnNames.reduce((colors, key) => {
          colors[this.findColumnName(key)] = this.chart.columns[key].color;
          return colors;
        }, {}),
        names: columnNames.reduce((names, key) => {
          names[key] = this.chart.columns[key].label;
          return names;
        }, {}),
      };
    },
    prepareLegendClasses() {
      return Object.keys(this.chart.columns).reduce((obj, key) => {
        obj[key] = {
          fade: false,
          off: false,
        };

        return obj;
      }, {});
    },
    onMouseOver(key) {
      if (this.legendClasses[key].off) return;

      this.c3.focus(this.findColumnName(key));

      Object.keys(this.legendClasses).forEach(k => {
        this.legendClasses[k].fade = k !== key;
      });
    },
    onMouseOut() {
      this.c3.revert();

      Object.keys(this.legendClasses).forEach(k => {
        this.legendClasses[k].fade = false;
      });
    },
    onClick(key) {
      this.c3.toggle(this.findColumnName(key));

      Object.keys(this.legendClasses).forEach(k => {
        this.legendClasses[k].fade = false;
      });
      this.legendClasses[key].off = !this.legendClasses[key].off;
    },
    findColumnName(key) {
      return this.chart.columns[key].label;
    },
  },
};
</script>
