import pluralizer from "pluralize";
import { NewDocumentsUploaded } from "models/loan/events/document_events";
import { RequestAddNewDocuments } from "models/loan/commands/document_commands";
import { getFilestackClient, generateFilePickerOptions } from "services/filestack_service";
import { success } from "services/notification";

const Command = PS.Models.Command;
const Events = PS.Models.File.Events;

export class InitClient extends Command {
  execute() {
    return new Promise(resolve => {
      this.state.client = getFilestackClient(this.state.apiKey, this.state.options);
      resolve();
    });
  }
}

export class RequestFilePicker extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Picker.FilePickerRequested,
      PS.Services.MessageBusService.loanBus,
      { metadata: this.event.metadata }
    );
  }
}

export class UploadFiles extends Command {
  execute() {
    const resolver = resolve => args => resolve(this.uploadFiles(args));

    return new Promise(resolve => {
      this.state.client
        .picker({
          ...this.pickerOptions,
          onUploadDone: resolver(resolve),
        })
        .open();
    });
  }

  uploadFiles = blobs => {
    Bugsnag.leaveBreadcrumb(
      "filestack:uploaded",
      blobs.filesUploaded.reduce((files, file, idx) => {
        files[idx] = `${file.originalPath} -> ${file.key}`;
        return files;
      }, {})
    );

    Bugsnag.leaveBreadcrumb(
      "filestack:failed",
      blobs.filesFailed.reduce((files, file, idx) => {
        files[idx] = file.originalPath;
        return files;
      }, {})
    );

    if (!blobs.filesUploaded || blobs.filesUploaded.length === 0) {
      Bugsnag.notify(
        {
          name: "FileStackError",
          message: "filesUploaded is blank",
        },
        {
          severity: "error",
          metaData: {
            custom: { filesUploaded: blobs.filesUploaded, filesFailed: blobs.filesFailed },
          },
        }
      );

      $(window.PS).trigger("ajaxNotification.add", {
        message: "Upload failed. Try again",
        status: "error",
      });
    }

    this.state.filesUploaded = blobs.filesUploaded.map(blob => {
      return { provider: this.state.provider, data: blob };
    });
    this.state.filesFailed = blobs.filesFailed.map(blob => {
      return { provider: this.state.provider, data: blob };
    });
  };

  get pickerOptions() {
    return generateFilePickerOptions(this.state.options, this.state.sources);
  }
}

const UPLOADED_FILE_CREATION_ROUTE = "/uploaded_files.json";

export class SaveDocuments extends Command {
  execute() {
    if (this.state.filesUploaded.length === 0) return Promise.resolve();

    const uploadedFileCreationRoute = `${PS.Services.AjaxService.homePath()}${UPLOADED_FILE_CREATION_ROUTE}`;
    const taskId = (this.event.metadata || {}).taskId;

    return PS.Services.AjaxService.post(uploadedFileCreationRoute, {
      files: this.state.filesUploaded,
      task_id: taskId,
    })
      .then(documents => {
        const receiver = this.state.options;
        if (receiver.type === "Loan" && receiver.folder === "LoanDocument") {
          new RequestAddNewDocuments(
            new NewDocumentsUploaded({
              documents: documents,
              metadata: this.event.metadata,
            })
          ).execute();

          if (taskId) {
            success(`${pluralizer("Document", this.state.filesUploaded.length)} attached to Task.`);
          }
        }
      })
      .catch(result => {
        Bugsnag.notify(
          { name: "FileStackError", message: "Error uploading file" },
          {
            severity: "error",
            metaData: {
              custom: {
                filesUploaded: this.state.filesUploaded,
                filesFailed: this.state.filesFailed,
                resultResponse: result.response,
                resultData: result.data,
                apiEndpoint: uploadedFileCreationRoute,
              },
            },
          }
        );
      });
  }
}

PS.Models.File.Commands.Picker = {
  InitClient: InitClient,
  RequestFilePicker: RequestFilePicker,
  UploadFiles: UploadFiles,
  SaveDocuments: SaveDocuments,
};
