<template>
  <loan-task-field-container label="Due Date">
    <flat-pickr
      v-show="editMode"
      ref="dueDate"
      v-model="taskDueDate"
      :config="datePickerConfig"
      class="form-input"
      @on-close="onDueDateUpdate"
    />
    <loan-task-editable-field
      v-show="!editMode"
      title="Set due date"
      :disabled="!canEdit"
      :deletable="!!task.expectedAt"
      :is-loading="updateInProgress"
      @edit="onDueDateEdit"
      @delete="onDueDateDelete"
    >
      {{ task.expectedAt || "No due date" }}
    </loan-task-editable-field>
  </loan-task-field-container>
</template>

<script>
import LoanTaskEditableField from "components/activity_feed_events/manage/loan_tasks/editable_field";
import LoanTaskFieldContainer from "components/activity_feed_events/manage/loan_tasks/field_container";
import FlatPickr from "vue-flatpickr-component";

export default {
  components: {
    LoanTaskEditableField,
    LoanTaskFieldContainer,
    FlatPickr,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    canUpdateLoanTaskData: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      updateInProgress: false,
      editMode: false,
      taskDueDate: this.task.expectedAt,
    };
  },
  computed: {
    datePickerConfig() {
      return {
        dateFormat: "m/d/Y h:i K",
        enableTime: true,
        defaultHour: 0,
      };
    },
    expectedAtValue() {
      return this.task.expectedAt || "";
    },
    canEdit() {
      return this.task.isOpen && this.canUpdateLoanTaskData;
    },
  },
  watch: {
    task() {
      this.updateInProgress = false;
    },
  },
  methods: {
    onDueDateEdit() {
      if (this.updateInProgress) return;

      this.openDueDateCalendar();
    },
    openDueDateCalendar() {
      this.taskDueDate = this.expectedAtValue;
      this.editMode = true;
      this.$nextTick(() => this.$refs.dueDate.fp.input.focus());
    },
    onDueDateUpdate(_date, dateString) {
      this.editMode = false;
      this.taskDueDate = dateString;

      if (this.taskDueDate !== this.expectedAtValue) {
        this.updateInProgress = true;
        this.updateDueDate(this.taskDueDate);
      }
    },
    onDueDateDelete() {
      this.updateDueDate(null);
    },
    updateDueDate(value) {
      this.$emit("task-updated", {
        title: this.task.title,
        expected_at: value,
      });
    },
  },
};
</script>
