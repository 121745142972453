const Events = PS.Models.Diligence.Events;
const Commands = PS.Models.Diligence.Commands;
const Rule = PS.Models.Rule;

class ReportRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.Report.LoadingRequested:
        return this.whenReportLoadingRequested(event);
      case Events.Report.ReloadReportRequested:
        return this.whenReportLoadingRequested(event);
      case Events.Report.UpdateMLDRequested:
        return this.whenUpdateMLDRequested(event);
      case Events.Report.ChangeValueRequested:
        return this.whenChangeValueRequested(event);
    }

    return super.check(event);
  }

  whenReportLoadingRequested(event) {
    return [
      new Commands.Report.StartLoading(event, this.state),
      new Commands.Report.LoadFromServer(event, this.state),
      new Commands.Report.EndLoading(event, this.state),
    ];
  }

  whenUpdateMLDRequested(event) {
    return [
      new Commands.Report.UpdateMLD(event, this.state),
      new Commands.Report.ResetReport(event, this.state),
      new Commands.Report.ReloadReport(event, this.state),
    ];
  }

  whenChangeValueRequested(event) {
    return [new Commands.Report.SaveCorrection(event, this.state)];
  }
}

PS.Models.Diligence.ReportRule = ReportRule;
