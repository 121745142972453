<template>
  <modal-view :show="true" :on-close="onClose">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Suspend Progress</h3>
      </div>
      <div class="modal-body loan-suspension-modal">
        <form>
          <div class="form-group control-wrapper">
            <div class="loan-suspension-statuses radio-control js-radio-input">
              <input id="loan-suspension-status-rejected"
                     @input="reset"
                     v-model="loanSuspension.underwritingStatus"
                     value="rejected"
                     type="radio" />
              <label for="loan-suspension-status-rejected">
                Declined by PeerStreet
                <span class="control-toggle"></span>
              </label>

              <input id="loan-suspension-status-canceled"
                     @input="reset"
                     v-model="loanSuspension.underwritingStatus"
                     value="canceled"
                     type="radio" />
              <label for="loan-suspension-status-canceled">
                Withdrawn by Lender
                <span class="control-toggle"></span>
              </label>
            </div>
            <p>Select 1-3 reasons (required)</p>
            <div class="loan-suspension-reasons">
              <div class="loan-suspension-chunk-categories" v-for="categories in chunkedCategories">
                <div class="loan-suspension-category" v-for="category in categories" :key="category">
                  <h4>{{ category }}</h4>
                  <loan-suspension-item
                    v-for="item in itemsForCategory(category)"
                    @choosed="onChoose"
                    :enabled="isEnabled(item)"
                    :item="item"
                    :key="item.id">
                  </loan-suspension-item>
                </div>
              </div>
            </div>
            <label for="description">Description (required)</label>
            <textarea class="form-control" v-model="loanSuspension.description"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="btn btn-cancel-cta" @click="onClose">
          Cancel
        </div>
        <div class="btn btn-action-cta"
            @click="onSave"
            :disabled="isDisabled">
          Save
        </div>
      </div>
    </div>
  </modal-view>
</template>
<script>
import Ajax from "services/ajax_service";
import toString from "lodash/toString";
import ModalView from "components/modal_view";
import LoanSuspensionItem from "components/manage/loan_suspension/item";
import cloneDeep from "lodash/cloneDeep";

const CATEGORIES_MAPPING = {
  rejected: [["lender", "property"], ["loan", "valuation"], ["borrower", "peerstreet"]],
  canceled: [["lender", "property"], ["borrower", "peerstreet"]],
};

export default {
  name: "manage-loan-suspend",
  components: { ModalView, LoanSuspensionItem },
  props: {
    suspension: {
      type: Object,
      default: () => {
        if (PSData.loanSuspension) {
          return cloneDeep(PSData.loanSuspension);
        }

        return { loanSuspensionReasonIds: [], underwritingStatus: "rejected" };
      },
    },
  },
  data() {
    return {
      showPopup: false,
      isSubmitting: false,
      loanSuspensionReasons: PSData.loanSuspensionReasons || [],
      loanSuspension: this.suspension,
      endpoint: PSData.routes.loanSuspensions,
    };
  },
  methods: {
    onChoose({ item, value }) {
      if (value) {
        this.loanSuspension.loanSuspensionReasonIds.push(item.id);
      } else {
        const index = this.loanSuspension.loanSuspensionReasonIds.indexOf(item.id);
        Vue.delete(this.loanSuspension.loanSuspensionReasonIds, index);
      }
    },
    onClose() {
      this.$emit("closed");
      this.reset();
    },
    onSave() {
      this.isSubmitting = true;
      const data = {
        description: this.loanSuspension.description,
        underwriting_status: this.loanSuspension.underwritingStatus,
        loan_suspension_reason_ids: this.loanSuspension.loanSuspensionReasonIds,
      };

      const handler = this.loanSuspension.id ? this.update : this.create;

      handler(data)
        .then(data => this.$emit("saved", data))
        .catch(() => this.reset)
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    itemsForCategory(category) {
      return this.reasons.filter(item => item.category === category);
    },
    create(data) {
      return Ajax.withNotifications({ onError: this.onError }).post(
        this.endpoint,
        { loan_suspension: data },
        { "Content-Type": "application/json" }
      );
    },
    update(data) {
      return Ajax.withNotifications({ onError: this.onError }).patch(
        `${this.endpoint}/${this.loanSuspension.id}`,
        { loan_suspension: data },
        { "Content-Type": "application/json" }
      );
    },
    onError({ response, data }) {
      if (response.status === 422 || response.status === 403) {
        return {
          message: data.error,
          status: "error",
        };
      }
    },
    reset() {
      this.loanSuspension.loanSuspensionReasonIds = [];
    },
    isEnabled(item) {
      return this.loanSuspension.loanSuspensionReasonIds.includes(item.id);
    },
  },
  computed: {
    chunkedCategories() {
      return CATEGORIES_MAPPING[this.status];
    },
    status() {
      return this.loanSuspension.underwritingStatus;
    },
    reasons() {
      return this.loanSuspensionReasons.filter(item =>
        this.status.includes(item.underwritingStatus)
      );
    },
    isDisabled() {
      return (
        this.isSubmitting ||
        toString(this.loanSuspension.description).trim().length === 0 ||
        this.loanSuspension.loanSuspensionReasonIds.length === 0 ||
        this.loanSuspension.loanSuspensionReasonIds.length > 3
      );
    },
  },
};
</script>
