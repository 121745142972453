const Autocomplete = PS.Models.Autocomplete;

let UNIQ_SOURCE_ID = 0;

class Datasource {
  constructor(query = "", suggestions = []) {
    this.query = query;
    this.suggestions = suggestions;
    this.errors = [];

    this.sourceId = ++UNIQ_SOURCE_ID;
  }

  setupAutocomplete() {
    new Autocomplete.Commands.RequestAutocompleteFinishing(
      new Autocomplete.Events.SetupAutocompleteFinished({ sourceId: this.sourceId })
    ).execute();
  }

  resetState() {
    this.suggestions = [];
    this.errors = [];
  }

  runAutocomplete(query) {}

  simulateAutocompleteValidation(suggestions, suggestionIndex = 0) {
    this.resetState();

    this.suggestions = suggestions || [];

    new Autocomplete.Commands.RequestSimulationOfAutocompleteValidation(
      new Autocomplete.Events.SimulateAutocompleteValidationRequested({
        suggestionIndex: suggestionIndex,
        sourceId: this.sourceId,
      })
    ).execute();
  }

  validateSuggestion(suggestion) {}

  prepareSuggestions(nodes) {}

  applySuggestions(suggestions) {
    this.suggestions = suggestions || [];

    if (this.suggestions.length === 0) {
      this.errors.push("Nothing found");
    }

    new Autocomplete.Commands.RequestSearchCompletion(
      new Autocomplete.Events.AutocompleteSearchFinished({
        suggestions: suggestions,
        sourceId: this.sourceId,
      })
    ).execute();
  }
}

PS.Models.Autocomplete.Datasource = Datasource;
