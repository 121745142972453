<template>
  <div class="worklist-row worklist-row-content" :data-loan-id="loan.loanId" v-cloak>
    <div class="worklist-cell status">
      <span class="loan-status" :class="'loan-' + loan.purchasedGroup">{{loan.groupName}}</span>
    </div>
    <div class="worklist-cell days-in-status">
      <span>{{daysInStatus}}</span>
    </div>
    <workitem-assignee assigneeType="analyst"
                             :avatarUrl="userGravatarUrl(loan.analyst)"
                             :users="users"
                             :updating="updatingAnalyst"
                             :assigneeId="loan.analyst && loan.analyst.id"
                             :attentionRequired="analystAttentionRequired"
                             :can-update-worklist-data="canUpdateWorklistData"
                             @assignee-selected="analystSelected"></workitem-assignee>

    <workitem-assignee assigneeType="processor"
                             :avatarUrl="userGravatarUrl(loan.processor)"
                             :users="users"
                             :updating="updatingProcessor"
                             :assigneeId="loan.processor && loan.processor.id"
                             :can-update-worklist-data="canUpdateWorklistData"
                             @assignee-selected="processorSelected"></workitem-assignee>

    <div class="worklist-cell lender">
      <report-lender-name
        :lender="loan.lender"
        :lenderBlockStatusDisplayable="true"
      />
    </div>
    <div :data-path="loanDetailsPath(loan)" class="worklist-cell address">
      <span>{{loan.loanProperty.street}}</span>
      <div class="loan-address-details">
        <span class="city-state-zip">{{loan.loanProperty.city}}, {{loan.loanProperty.state}} {{loan.loanProperty.postalCode}}</span>
        <pieces-of-collateral :properties="loan.properties" />
      </div>
      <span>({{loan.loanId}})</span>
    </div>
    <div class="worklist-cell countdown">
      <div class="sla-cell">
        <worklist-sla-table
          :tasks="loan.tasks"
          @mark-task-completed="handleMarkTaskCompleted"
        />
      </div>
    </div>
    <confidence-cell :loan="loan" :can-update-worklist-data="canUpdateWorklistData"></confidence-cell>
    <forecast-purchase-date-cell :loan="loan" :can-update-worklist-data="canUpdateWorklistData"></forecast-purchase-date-cell>
    <progress-update-cell :loan="loan" :can-update-worklist-data="canUpdateWorklistData"></progress-update-cell>
    <div class="worklist-cell open-tasks">
      {{ loan.openTasksCount }}
    </div>
    <div class="worklist-cell quick-preview">
      <a
        @click.stop.prevent="openActivityFeedEvents"
        title="Open quick preview"
        class="open-quick-preview"
        :class="{ 'has-activity': hasActivity }">
      </a>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import WorkitemAssignee from "components/reports/workitem_assignee";
import WorklistSlaTable from "components/reports/sla";

import ConfidenceCell from "components/reports/worklist/confidence_cell";
import ForecastPurchaseDateCell from "components/reports/worklist/forecast_purchase_date_cell";
import ProgressUpdateCell from "components/reports/worklist/progress_update_cell";
import ReportLenderName from "components/reports/lender_name";
import PiecesOfCollateral from "components/tags/pieces_of_collateral";

export default {
  name: "worklist-row",
  components: {
    WorkitemAssignee,
    WorklistSlaTable,
    ConfidenceCell,
    ForecastPurchaseDateCell,
    ProgressUpdateCell,
    ReportLenderName,
    PiecesOfCollateral,
  },
  props: {
    loan: Object,
    users: Array,
    index: Number,
    serverTime: Number,
    loanIdsWithTaskActivity: Array,
    loanIdsWithMessageActivity: Array,
    canUpdateWorklistData: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      assignedAnalystId: undefined,
      assignedProcessorId: undefined,
    };
  },
  computed: {
    updatingAnalyst() {
      return this.loan.updatingAnalyst == true;
    },
    updatingProcessor() {
      return this.loan.updatingProcessor == true;
    },
    analystAttentionRequired() {
      return this.loan.attentionRequired == true;
    },
    daysInStatus() {
      const serverDateTime = moment.unix(this.serverTime).utcOffset(0, true);
      const statusChangedAt = moment.unix(this.loan.statusChangedAt).utcOffset(0, true);
      return Math.ceil(serverDateTime.diff(statusChangedAt, "seconds") / 60 / 60 / 24);
    },
    hasActivity() {
      return (
        this.loanIdsWithTaskActivity.includes(this.loan.loanId) ||
        this.loanIdsWithMessageActivity.includes(this.loan.loanId)
      );
    },
  },
  methods: {
    loanDetailsPath(loan) {
      return `/manage/loans/${loan.loanId}`;
    },
    userGravatarUrl(user) {
      if (user && user.emailHash) {
        return `https://www.gravatar.com/avatar/${user.emailHash}?d=mm&&s=35`;
      }
    },
    analystSelected(analystId) {
      const options = {
        assignedAnalystId: analystId,
        loanId: this.loan.loanId,
        loan: this.loan,
        index: this.index,
      };

      this.$emit("analyst-selected", options);
    },
    processorSelected(processorId) {
      const options = {
        assignedProcessorId: processorId,
        loanId: this.loan.loanId,
        loan: this.loan,
        index: this.index,
      };

      this.$emit("processor-selected", options);
    },
    handleMarkTaskCompleted(taskName, cb) {
      this.$emit("mark-task-completed", this.index, taskName, cb);
    },
    openActivityFeedEvents(loan) {
      this.$emit("open-activity-feed-events", this.index);
    },
  },
};
</script>
