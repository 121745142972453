<template>
  <div>
    <page-header title="Asset Management">
      <template v-if="isActiveTab('loanTracker')" v-slot:header-menu>
        <span>As of {{ currentTimestamp }}</span>
      </template>
      <template v-slot:tabs>
        <tabs class="nav-menu" :tabs="tabs" @tab-selected="handleTabSelected" />
      </template>
    </page-header>
    <div class="container-layout">
      <loan-tracker-tab v-if="isActiveTab('loanTracker')" @update-data-timestamp="updateDataLoadTimestamp" />
      <loan-portfolio-tab v-if="isActiveTab('loanPortfolio')" />
      <workflow-portfolio-tab v-if="isActiveTab('workflowPortfolio')" />
      <task-portfolio-tab v-if="isActiveTab('taskPortfolio')" />
      <bulk-assign-tab v-if="isActiveTab('bulkAssign')" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import get from "lodash/get";
import PageHeader from "components/shared/page_header";
import Tabs from "components/shared/tabs";
import LoanTrackerTab from "./loan_tracker/tab";
import BulkAssignTab from "./bulk_assign/tab";
import TaskPortfolioTab from "./task_portfolio/tab";
import LoanPortfolioTab from "./loan_portfolio/tab";
import WorkflowPortfolioTab from "./workflow_portfolio/tab";

export default {
  components: {
    PageHeader,
    Tabs,
    LoanTrackerTab,
    BulkAssignTab,
    TaskPortfolioTab,
    LoanPortfolioTab,
    WorkflowPortfolioTab,
  },
  data() {
    return {
      currentTab: PSData.currentTab,
      currentTimestamp: moment().format("MM/DD/YY hh:mmA"),
    };
  },
  computed: {
    routes: () => PSData.routes,
    canBulkAssignLoans: () => PSData.canBulkAssignLoans,
    canViewWorkflows: () => PSData.canViewWorkflows,
    tabs() {
      let availableTabs = {};

      if (this.currentTab === "bulkAssign") {
        availableTabs.bulkAssign = {
          name: "Assign Loans",
          active: true,
        };
      } else {
        availableTabs.loanPortfolio = {
          name: "Loans",
          active: this.currentTab === "loanPortfolio",
        };
        availableTabs.workflowPortfolio = {
          name: "Workflows",
          active: this.currentTab === "workflowPortfolio",
        };
        availableTabs.taskPortfolio = {
          name: "Tasks",
          active: this.currentTab === "taskPortfolio",
        };
      }

      return availableTabs;
    },
  },
  methods: {
    isActiveTab(tab) {
      return get(this.tabs, [tab, "active"], false);
    },
    handleTabSelected(tabName) {
      if (this.currentTab === tabName) return;
      window.location = this.routes[tabName];
    },
    updateDataLoadTimestamp() {
      this.currentTimestamp = moment().format("MM/DD/YY hh:mmA");
    },
  },
};
</script>
