<template>
  <base-modal-form
    :show="show"
    :title="modalTitle"
    :save-disabled="isSaving"
    @close="onClose"
    @save="onSave"
  >
    <form>
      <div class="columns">
        <base-text-field
          id="property-address"
          v-model="record.propertyAddress"
          label="Property Address"
          :errors="errors.property_address"
          rules="required"
          class="column is-half"
        />
        <base-text-field
          id="property-city"
          v-model="record.propertyCity"
          label="City"
          :errors="errors.property_city"
          rules="required"
          class="column is-half"
        />
      </div>
      <div class="columns">
        <base-select-field
          id="property-state"
          v-model="record.propertyState"
          label="State"
          :options="record.propertyStates"
          :errors="errors.property_state"
          rules="required"
          class="column is-half"
        />
        <base-zip-field
          id="property-zip"
          v-model="record.propertyZip"
          label="ZIP Code"
          :errors="errors.property_zip"
          rules="required"
          class="column is-half"
        />
      </div>
      <div class="columns">
        <base-date-field
          id="acquisition-date"
          v-model="record.acquisitionDate"
          label="Acquisition Date"
          :errors="errors.acquisition_date"
          :max-date="record.dispositionDate"
          class="column is-half"
        />
        <base-currency-field
          id="acquisition-price"
          v-model="record.acquisitionPrice"
          label="Acquisition Price"
          class="column is-half"
        />
      </div>
      <div class="columns">
        <base-date-field
          id="disposition-date"
          v-model="record.dispositionDate"
          label="Disposition Date"
          :errors="errors.disposition_date"
          :min-date="record.acquisitionDate"
          class="column is-half"
        />
        <base-currency-field
          id="disposition-price"
          v-model="record.dispositionPrice"
          label="Disposition Price"
          class="column is-half"
        />
      </div>
      <div class="columns">
        <base-text-field
          id="owner"
          v-model="record.owner"
          label="Owner Listed on Title"
          :errors="errors.owner"
          class="column is-half"
        />
        <base-percent-field
          id="ownership"
          v-model="record.ownership"
          label="Ownership"
          :errors="errors.ownership"
          rules="between:1,100"
          class="column is-half"
        />
      </div>
      <div class="columns">
        <base-currency-field
          id="renovation-funds-used"
          v-model="record.renovationFundsUsed"
          label="Renovation Funds Used"
          class="column is-half"
        />
        <base-currency-field
          id="gross-rental-income"
          v-model="record.grossRentalIncome"
          label="Gross Rental Income"
          class="column is-half"
        />
      </div>
      <div class="columns">
        <base-currency-field
          id="current-cost-basis"
          v-model="record.currentCostBasis"
          label="Current Cost Basis"
          class="column is-half"
        />
        <base-currency-field
          id="present-market-value"
          v-model="record.presentMarketValue"
          label="Present Market Value"
          class="column is-half"
        />
      </div>
      <div class="columns">
        <base-currency-field
          id="mortgages-and-liens-amount"
          v-model="record.mortgagesAndLiensAmount"
          label="Current Amount of Mortgages and Liens"
          class="column is-half"
        />
      </div>
    </form>
  </base-modal-form>
</template>

<script>
import AjaxService from "services/ajax_service";

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    trackRecord: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      routes: PSData.routes,
      isSaving: false,
      record: this.trackRecord.copy,
      errors: {},
    };
  },
  computed: {
    isNew() {
      return !this.record.id;
    },
    modalTitle() {
      return this.isNew ? "Add Record" : "Edit Record";
    },
  },
  watch: {
    trackRecord() {
      this.record = this.trackRecord.copy;
    },
  },
  methods: {
    onClose() {
      this.errors = {};
      this.$emit("close");
    },
    onSave() {
      this.save();
    },
    save() {
      const messages = { onSuccess: "Record is saved", onError: this.onError };

      this.isSaving = true;

      return this.saveRecord(messages)
        .then(data => {
          this.$emit("save", data);
          this.onClose();
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    saveRecord(messages) {
      if (this.isNew) {
        return AjaxService.withNotifications(messages).post(
          this.routes.trackRecordDeals,
          this.record.objectToSave
        );
      } else {
        return AjaxService.withNotifications(messages).patch(
          `${this.routes.trackRecordDeals}/${this.record.id}`,
          this.record.objectToSave
        );
      }
    },
    onError({ data }) {
      this.errors = data.errors || {};
      return null;
    },
  },
};
</script>
