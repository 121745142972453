import { isLoanCommercialOrMultifamily, isLoanMultifamily } from "models/lender_form/program";

export default {
  data() {
    return {
      loan: PSData.loan || {},
    };
  },
  computed: {
    typeCommercialOrMultifamily() {
      return isLoanCommercialOrMultifamily(this.loan);
    },
    typeMultifamily() {
      return isLoanMultifamily(this.loan);
    },
    showCountry() {
      return !this.property.countryUS;
    },
    showStories() {
      return this.typeCommercialOrMultifamily || this.property.propertyTypeCondo;
    },
  },
};
