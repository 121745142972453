const Events = PS.Models.Reports.Events.ZipReport;
const Commands = PS.Models.Reports.Commands.ZipReport;
const Rule = PS.Models.Rule;

class ZipReportRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.ZipReportRequested:
        return this.whenZipReportRequested(event);
    }

    return super.check(event);
  }

  whenZipReportRequested(event) {
    return [
      new Commands.ClearZipReport(event, this.state),

      new Commands.LoadLenderTerminusData(event, this.state),
      new Commands.AddTerminusDataToHomeIndexChart(event, this.state),
      new Commands.AddTerminusDataToAnalytics(event, this.state),

      new Commands.LoadLenderMidasData(event, this.state),
      new Commands.AddMidasDataToAnalytics(event, this.state),
      new Commands.AddMidasDataToCharts(event, this.state),
      new Commands.AddMidasDataToTables(event, this.state),
    ];
  }
}

PS.Models.Reports.ZipReportRule = ZipReportRule;
