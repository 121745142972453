<template>
  <tr>
    <td>
      {{ label }}
    </td>

    <td>
      <div class="editable-field-container">
        <div v-if="editing" class="form-group currency">
          <div class="loan-attribute-value input-group">
            <div class="input-group flex-nowrap editable-field-input-wrapper">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                class="form-control"
                type="currency"
                :value="perDiemAmount"
                :disabled="isDisabled || hidePerDiem"
                v-mask:currency
                @input="updatePerDiemAmount"
              />
            </div>
          </div>
        </div>

        <div v-if="!editing && !hidePerDiem" class="editable-field-value">
          {{ perDiemAmount | accounting }}
        </div>
      </div>
    </td>

    <td>
      <div class="editable-field-container">
        <div v-if="editing" class="form-group currency">
          <div class="loan-attribute-value input-group">
            <div class="input-group flex-nowrap editable-field-input-wrapper">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                class="form-control"
                type="currency"
                :value="value"
                :disabled="isDisabled"
                v-mask:currency
                @input="updateTotalAmount"
              />
            </div>
          </div>
        </div>

        <div v-if="!editing" class="editable-field-value">
          {{ value | accounting }}
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import NumberFormatter from "models/formatters/number_formatter";
export default {
  name: "PerDiemFields",
  props: {
    label: String,
    editing: Boolean,
    hidePerDiem: {
      type: Boolean,
      default: false,
    },
    daysOwned: [Number, String],
    value: [Number, String],
    disabled: Boolean,
  },
  computed: {
    isDisabled() {
      return this.disabled || !this.daysOwned || this.daysOwned === 0 || this.daysOwned === "0";
    },
  },
  data() {
    return {
      perDiemAmount: this.calculatePerDiemAmount() || "0.0",
    };
  },
  methods: {
    calculatePerDiemAmount() {
      if (this.isDisabled) return "0.0";
      return NumberFormatter.unmaskNumber(this.value || 0) / parseInt(this.daysOwned, 10);
    },
    calculateTotalAmount() {
      if (this.isDisabled) return "0.0";
      return NumberFormatter.unmaskNumber(this.perDiemAmount || 0) * parseInt(this.daysOwned, 10);
    },
    updatePerDiemAmount(event) {
      this.perDiemAmount = NumberFormatter.unmaskNumber(event.target.value || 0);
      this.$emit("input", this.calculateTotalAmount());
    },
    updateTotalAmount(event) {
      const amount = NumberFormatter.unmaskNumber(event.target.value || 0);
      this.$emit("input", amount);
    },
  },
  watch: {
    // Don't change functions order!
    // Total amount should be recalculated first, then recalculate per-diem on Cancel/Update.
    value(amount) {
      // Keep last valid per-diem value for disabled input
      if (!this.isDisabled) {
        this.perDiemAmount = this.calculatePerDiemAmount();
      }
    },
    editing(isEnabled) {
      // Update per-diem value on Cancel/Update (even if input is disabled)
      if (!isEnabled) {
        this.perDiemAmount = this.calculatePerDiemAmount();
      }
    },
    daysOwned() {
      this.$emit("input", this.calculateTotalAmount());
    },
  },
};
</script>
