<template>
  <div class="payoff-statement-buttons-row">
    <div class="btn btn-outline-grey" @click="cancel">Cancel</div>
    <div class="btn btn-secondary-action-cta" @click="saveDraft">Save Draft</div>
    <div class="btn btn-action-cta" @click="submit">Submit for Review</div>
  </div>
</template>

<script>
export default {
  name: "payoff-statement-edit-buttons",
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    saveDraft() {
      this.$emit("save-draft");
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
