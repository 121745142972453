import moment from "moment";

const FULL_FORMAT = "MMMM YYYY";
const SHORT_FORMAT = "MM.YYYY";
const ISO_8601 = "YYYY-MM-DD";
const SEARCH_FORMAT = "MYYYY";
const START_DATE = [2014, 5, 1];

export default class Periods {
  static reportableMonths(start_date = START_DATE) {
    var initialDate = moment(start_date);
    var monthsPassed = moment().diff(initialDate, "months");
    var months = [];

    for (let month = 0; month <= monthsPassed; month++) {
      let startOfMonth = initialDate.clone().add(month, "months");

      months.push({
        fullFormat: startOfMonth.format(FULL_FORMAT),
        shortFormat: startOfMonth.format(SHORT_FORMAT),
        searchFormat: parseInt(startOfMonth.format(SEARCH_FORMAT)),
      });
    }

    return months.reverse();
  }

  static monthInterval(timeString, format = SHORT_FORMAT) {
    const timestamp = moment(timeString, format);
    const from = timestamp.startOf("month").format(ISO_8601);
    const to = timestamp.endOf("month").format(ISO_8601);

    return { from, to };
  }
}

PS.Models.Reports = PS.Models.Reports || {};
PS.Models.Reports.Periods = Periods;
