class SettlementStatementPolicy {
  constructor(settlementStatementPolicy) {
    this.settlementStatementPolicy = settlementStatementPolicy || {};
  }

  canApprove() {
    return this.settlementStatementPolicy.approve.allowed;
  }

  canCancel() {
    return this.settlementStatementPolicy.cancel.allowed;
  }

  canDownload() {
    return this.settlementStatementPolicy.download.allowed;
  }

  canPurchase() {
    return this.settlementStatementPolicy.purchase.allowed;
  }

  canManualPurchase() {
    return this.settlementStatementPolicy.manualPurchase.allowed;
  }

  canRefresh() {
    return this.settlementStatementPolicy.refresh.allowed;
  }

  canUpdate() {
    return this.settlementStatementPolicy.update.allowed;
  }

  canShowPrevious() {
    return this.settlementStatementPolicy.showPrevious.allowed;
  }

  canSubmit() {
    return this.settlementStatementPolicy.submit.allowed;
  }

  canRepair() {
    return this.settlementStatementPolicy.repair.allowed;
  }

  canReverse() {
    return this.settlementStatementPolicy.reverse.allowed;
  }

  canUpdateWireInstruction() {
    return this.settlementStatementPolicy.updateWireInstruction.allowed;
  }
}

export default SettlementStatementPolicy;
