import selectOptionsToMapping from "utils/select_options_to_mapping";
import objectToSnakeCase from "utils/object_to_snake_case";

export default class LoanTaxInstallment {
  static create(loanTaxInstallment, options) {
    return new LoanTaxInstallment(loanTaxInstallment, options);
  }

  constructor(loanTaxInstallment = {}, options = {}) {
    this.update(loanTaxInstallment);
    this._optionsRaw = options;
    this.types = selectOptionsToMapping(options.installmentTypes || []);
    this.paymentMethods = selectOptionsToMapping(options.paymentMethods || []);
  }

  get id() {
    return this.data.id;
  }

  get typeLabel() {
    return this.types[this.data.installmentType];
  }

  get type() {
    return this.data.installmentType;
  }

  get typeId() {
    return Object.keys(this.types).indexOf(this.data.installmentType);
  }

  set type(type) {
    this.data.installmentType = type;
  }

  get date() {
    return this.data.installmentDate;
  }

  set date(date) {
    this.data.installmentDate = date;
  }

  get amount() {
    return this.data.installmentAmount;
  }

  set amount(amount) {
    this.data.installmentAmount = amount;
  }

  get paymentMethodLabel() {
    return this.paymentMethods[this.data.paymentMethod];
  }

  get paymentMethod() {
    return this.data.paymentMethod;
  }

  set paymentMethod(paymentMethod) {
    this.data.paymentMethod = paymentMethod;
  }

  get payee() {
    return this.data.payee;
  }

  set payee(payee) {
    this.data.payee = payee;
  }

  get objectToSave() {
    return objectToSnakeCase(this.data);
  }

  update(updatedProperty) {
    this._rawData = Object.assign({}, updatedProperty);
    this.data = updatedProperty;
  }

  reset() {
    this.update(this._rawData);
  }
}
