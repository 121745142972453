import LocationService from "services/location_service";
import LocalStorageService from "services/local_storage_service";
import WindowService from "services/window_service";

import merge from "lodash/merge";
import snakeCase from "lodash/snakeCase";
import toNumber from "lodash/toNumber";

const STORAGE_KEY = "ps.worklist";

function isDefined(rawValue) {
  return (
    rawValue !== null && rawValue !== undefined && rawValue !== "null" && rawValue !== "undefined"
  );
}

function isEmptyStorage(storageData) {
  return !Object.values(storageData).some(value => isDefined(value));
}

const ValueConverter = {
  boolean(rawValue) {
    if (isDefined(rawValue)) {
      return rawValue === true || rawValue === "true";
    } else {
      return undefined;
    }
  },
  number(rawValue) {
    if (isDefined(rawValue)) {
      return toNumber(rawValue);
    } else {
      return undefined;
    }
  },
  string(rawValue) {
    if (isDefined(rawValue)) {
      return rawValue.toString();
    } else {
      return undefined;
    }
  },
  array(rawValue, itemType) {
    if (isDefined(rawValue) && isDefined(rawValue.length) && rawValue.length > 0) {
      const typeConverter = ValueConverter[itemType];
      const array = rawValue.split ? rawValue.split(",") : rawValue;

      return array.map(element => typeConverter(element));
    } else {
      return undefined;
    }
  },
};

export default class UrlParamsService {
  static get locationService() {
    return LocationService;
  }

  static get localStorageService() {
    return new LocalStorageService();
  }

  static get windowService() {
    return WindowService;
  }

  static defineService(paramsWithDefinitions) {
    const names = Object.keys(paramsWithDefinitions);
    const paramsWrapper = new UrlParamsService(paramsWithDefinitions);

    names.forEach(name => {
      Object.defineProperty(paramsWrapper, name, {
        get() {
          return paramsWrapper.getValue(name);
        },
        set(value) {
          paramsWrapper.setValue(name, value);
          paramsWrapper.save();
        },
      });
    });

    return paramsWrapper;
  }

  constructor(params) {
    this.params = {};

    Object.keys(params).forEach(name => {
      this.params[name] = {
        type: params[name].type.name.toLowerCase(),
        default: params[name].default,
      };

      if (params[name].itemType) {
        this.params[name].itemType = params[name].itemType.name.toLowerCase();
      }
    });

    this.values = {};
  }

  getValue(name) {
    return this.values[name];
  }

  setValue(name, value) {
    const definition = this.params[name];
    this.values[name] = ValueConverter[definition.type](value, definition.itemType);
  }

  save() {
    this.writeUrlStorage();
    this.writeLocalStorage();
  }

  load() {
    const urlStorageData = this.readUrlStorage();
    const localStorageData = this.readLocalStorage();

    if (isEmptyStorage(urlStorageData)) {
      if (isEmptyStorage(localStorageData)) {
        this.values = this.readDefaults();
      } else {
        this.values = localStorageData;
      }
    } else {
      this.values = urlStorageData;
    }

    return this.values;
  }

  readDefaults() {
    const result = {};
    Object.keys(this.params).forEach(name => {
      const definition = this.params[name];
      result[name] = definition.default;
    });

    return result;
  }

  readUrlStorage() {
    const storage = new URLSearchParams(UrlParamsService.locationService.search);
    const result = {};

    Object.keys(this.params).forEach(name => {
      const definition = this.params[name];
      result[name] = ValueConverter[definition.type](
        storage.get(snakeCase(name)),
        definition.itemType
      );
    });

    return result;
  }

  writeUrlStorage() {
    const values = {};
    Object.keys(this.values).forEach(name => {
      if (isDefined(this.values[name])) {
        values[snakeCase(name)] = this.values[name];
      }
    });

    const searchParams = new URLSearchParams(values).toString();
    const url =
      searchParams.length === 0
        ? UrlParamsService.locationService.pathname
        : UrlParamsService.locationService.pathname + "?" + searchParams.replace(/%2C/g, ",");

    UrlParamsService.windowService.history.replaceState({}, "", url);
  }

  readLocalStorage() {
    return UrlParamsService.localStorageService.getItem(STORAGE_KEY) || {};
  }

  writeLocalStorage() {
    UrlParamsService.localStorageService.setItem(STORAGE_KEY, this.values);
  }
}
