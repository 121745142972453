<template>
  <div class="following-only-loans-toggle">
    <label class="checkbox-toggle">
      Following Only
      <input v-model="showFollowingOnlyLoans" type="checkbox" />
      <div class="toggle-control"></div>
    </label>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import Location from "services/location_service";

export default {
  name: "following-only-loans-toggle",
  data() {
    return {
      disabled: false,
      showFollowingOnlyLoans: PSData.showFollowingOnlyLoans,
    };
  },
  watch: {
    showFollowingOnlyLoans() {
      if (this.disabled) return;

      this.disabled = true;

      AjaxService.post(PSData.routes.toggleFollowingOnlyLoans)
        .catch(() => {
          this.showFollowingOnlyLoans = !this.showFollowingOnlyLoans;
        })
        .finally(() => {
          this.disabled = false;
          Location.reload();
        });
    },
  },
};
</script>
