<template>
  <div v-if="hasRecords" class="am-history-list">
    <a v-if="showLink" :href="fullHistoryUrl" target="_blank">show all {{ total }}</a>
    <history-record
      v-for="record in displayedRecords"
      :key="record.id"
      :record="record"
    />
  </div>
  <div v-else class="am-history-list">
    This loan has no notes.
  </div>
</template>

<script>
import HistoryRecord from "./history_record";
export default {
  components: {
    HistoryRecord,
  },
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      required: true,
    },
    fullHistoryUrl: {
      type: String,
      default: "",
    },
  },
  computed: {
    displayedRecords() {
      return this.records.reverse();
    },
    hasRecords() {
      return this.numberOfVisibleRecords > 0;
    },
    showLink() {
      return this.total > this.numberOfVisibleRecords;
    },
    numberOfVisibleRecords() {
      return this.records.length;
    },
  },
};
</script>

<style scoped>
.am-history-list {
  padding-bottom: var(--space-unit-lg);
}
</style>
