<template>
  <div class="servicing-generate-section-header">
    <div>
      <base-header size="medium">
        Loan Balances
      </base-header>

      <div class="columns is-variable is-4">
        <!-- UNPAID PRINCIPAL BALANCE -->
        <div class="column">
          <ul>
            <li v-for="item in lineItems" :key="item.label" class="line-item">
              <div>{{ item.label }}</div>
              <div>{{ formatCurrency(item.value) }}</div>
            </li>
          </ul>

          <div class="bold line-item">
            <div>Unpaid Principal Balance</div>
            <div>{{ formatCurrency(unpaidPrincipalBalance) }}</div>
          </div>
        </div>
        <!-- LOAN BALANCES -->
        <div class="column loan-balances">
          <ul>
            <li class="line-item">
              <div>A-Piece Principal</div>
              <div>{{ formatCurrency(aPiecePrincipal) }}</div>
            </li>
            <li class="line-item">
              <div>Subordinate Principal</div>
              <div>{{ formatCurrency(subordinatePrincipal) }}</div>
            </li>
            <li class="line-item">
              <div>Construction Reserve Balance</div>
              <div>{{ formatCurrency(constructionReserveBalance) }}</div>
            </li>
            <li class="line-item">
              <div>Escrow Reserve Balance</div>
              <div>{{ formatCurrency(escrowReserveBalance) }}</div>
            </li>
            <li class="line-item">
              <div>Interest Reserve Balance</div>
              <div>{{ formatCurrency(interestReserveBalance) }}</div>
            </li>
            <li class="line-item last-loan-balance-item">
              <div>Other Reserve Balance</div>
              <div>{{ formatCurrency(otherReserveBalance) }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberFormatter from "models/formatters/number_formatter";

export default {
  data() {
    const {
      lineItems,
      unpaidPrincipalBalance,
      aPiecePrincipal,
      subordinatePrincipal,
      constructionReserveBalance,
      escrowReserveBalance,
      interestReserveBalance,
      otherReserveBalance,
    } = PSData.loanBalanceDetails;

    return {
      lineItems,
      unpaidPrincipalBalance,
      aPiecePrincipal,
      subordinatePrincipal,
      constructionReserveBalance,
      escrowReserveBalance,
      otherReserveBalance,
      interestReserveBalance,
    };
  },
  methods: {
    formatCurrency(value) {
      return NumberFormatter.formatAccounting(Number.parseFloat(value));
    },
  },
};
</script>

<style scoped>
.line-item {
  display: flex;
  justify-content: space-between;
}

li {
  width: 500px;
  padding: 10px 0px 10px 20px;
  border-bottom: 1px solid lightgray;
}

li:last-child {
  border-color: black;
}

.bold {
  font-weight: 600;
}

.servicing-generate-section-header {
  margin-bottom: 40px;
  padding-top: 40px;
}

.loan-balances .line-item:last-child {
  border-bottom: none;
}
</style>
