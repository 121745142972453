<template>
  <div class="modal fade permissions-roles-modal" id="edit-user-permissions-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Edit User Permissions</h4>
        </div>
        <div class="modal-body">
          <h5>Name</h5>
          <p class="user-name">{{user.name}}</p>
          <h5>Assign roles</h5>
          <div class="items">
            <PermissionsItem v-for="role in roles" :role="role" :readonly="readonly" :key="role.value"/>
          </div>
        </div>
        <div class="modal-footer">
          <div class="btn btn-cancel-cta"
               data-dismiss="modal"
               @click.prevent="cancel">
            Cancel
          </div>
          <div class="btn btn-action-cta" :class="{disabled: updating}" @click="save">Save</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PermissionsItem from "components/permissions/item";

const Commands = PS.Models.Permission.Commands;
const Event = PS.Models.Event;

export default {
  props: {
    readonly: Boolean,
    updating: Boolean,
    user: Object,
  },
  data() {
    const roles = PSData.options.roles
      .map(role => {
        return new PS.Models.Permission.Role(role, this.user);
      })
      .sort((a, b) => a.value.localeCompare(b.value));

    return { roles };
  },
  mounted() {
    $(this.$el).on("hidden.bs.modal", this.cancel);
  },
  watch: {
    updating(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.$emit("close-modal");
        this.hide();
      }
    },
  },
  components: { PermissionsItem },
  methods: {
    show() {
      $(this.$el).modal("show");
    },
    hide() {
      $(this.$el).modal("hide");
    },
    save() {
      new Commands.RequestSaveChanges(new Event(), {}).execute();
    },
    cancel() {
      new Commands.RequestResetChanges(new Event(), {}).execute();
      this.$emit("close-modal");
    },
  },
};
</script>
