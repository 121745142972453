import BannerAlert from "components/banners/alert";
import get from "lodash/get";

$(() => {
  new Vue({
    el: document.querySelector(".v-lender-banner-controller"),
    components: {
      BannerAlert,
    },
    data() {
      return {
        lenderBlockStatus: get(PSData, "lender.lastBlockStatus"),
      };
    },
  });
});
