<template>
  <base-modal
    class="welcome-modal"
    :cancel-options="cancelOptions"
    :show="isLenderOnboarding && !hasSeen"
    save-button-name="Complete Sign Up"
    cancel-button-name="Skip for now"
    @close="markAsSeen"
    @save="redirectToSignUp"
    title="Welcome"
  >
    Welcome to Lender Platform! Feel free to explore the platform,
    but you'll need to complete the sign up process before transacting with us.
  </base-modal>
</template>

<script>
import Location from "services/location_service";
import Cookies from "js-cookie";

const COOKIE_NAME = "ps_welcome_seen";

export default {
  name: "welcome-modal",
  props: {
    isLenderOnboarding: {
      type: Boolean,
      default: PSData.isLenderOnboarding ?? false,
    },
    completeSignUpPath: {
      type: String,
      default: PSData.completeSignUpPath,
    },
  },
  data() {
    return {
      cancelOptions: ["escape", "x"],
      hasSeen: this.checkIfSeen(),
    };
  },
  methods: {
    markAsSeen() {
      this.hasSeen = true;
      Cookies.set(COOKIE_NAME, "true", { expires: 30 });
      this.$emit("close");
    },
    redirectToSignUp() {
      this.markAsSeen();

      Location.href = this.completeSignUpPath;
    },
    checkIfSeen() {
      return Cookies.get(COOKIE_NAME) !== undefined;
    },
  },
};
</script>

<style scoped>
.welcome-modal >>> .modal-card {
  min-height: 360px;
}
</style>
