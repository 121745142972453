<template>  
  <show-document-tracking-status
      v-bind:tracking-documents="trackingDocuments"      
  />
</template>

<script>
import ShowDocumentTrackingStatus from "components/document_tracking/show.vue";
export default {
  name: "document-tracking-controller",
  components: { ShowDocumentTrackingStatus },
  data() {
    return {
      trackingDocuments: PSData.trackingDocuments || [],
      canEdit: PSData.documentTrackingCanEdit,
    };
  },
};
</script>
