<template>
  <criteria-selector
    :label="`Program Type`"
    :options="options"
    :tags="tags"
    @tag-added="onTagAdded"
    @tag-removed="onTagRemoved"
  />
</template>

<script>
import useCriteriaStore from "./stores/criteria";
import { programTypeOptions } from "./utils/mappings";
import CriteriaSelector from "./criteria_selector";

export default {
  name: "program-selector",
  components: {
    CriteriaSelector,
  },
  props: {},
  data() {
    const criteriaStore = useCriteriaStore();
    return {
      options: programTypeOptions,
      criteriaStore,
    };
  },
  computed: {
    tags() {
      return this.criteriaStore.programTypes.reduce((arr, v) => {
        const opt = this.options.find(o => o.value === v);
        if (opt) {
          arr.push(opt);
        }
        return arr;
      }, []);
    },
  },
  methods: {
    onTagAdded(tag) {
      this.criteriaStore.addProgramType(tag);
    },
    onTagRemoved(tag) {
      this.criteriaStore.deleteProgramType(tag);
    },
  },
};
</script>
