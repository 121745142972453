<template>
  <ul class="permissions-legend">
    <li><div class="permitted-deal type-funded"></div>Funded</li>
    <li><div class="permitted-deal type-funding_into_origination"></div>Funding Into Origination</li>
    <li><div class="permitted-deal type-table_funding"></div>Table Funding</li>
    <li><div class="permitted-deal type-plain restricted"></div>Restricted</li>
    <li><div class="permitted-deal type-plain condition"></div>Condition</li>
  </ul>
</template>

<script>
export default {
  name: "purchasing-permissions-legend",
};
</script>
