import NumberFormatter from "models/formatters/number_formatter";
import cloneDeep from "lodash/cloneDeep";

export default class LoanModification {
  constructor(loanModification = {}) {
    this.raw = cloneDeep(loanModification);
  }

  get sequenceNumber() {
    return parseInt(this.raw.sequenceNumber);
  }

  get ownerType() {
    return this.raw.ownerType;
  }

  get isModification() {
    return !!this.raw.modification;
  }

  get isExercised() {
    return !!this.raw.exercised;
  }

  get feeType() {
    return this.raw.feeType;
  }

  get feeValue() {
    return parseFloat(this.raw.feeValue || 0);
  }

  get peerstreetFeeAllocation() {
    return this.raw.peerstreetFeeAllocation;
  }

  get unpaidPrincipalBalance() {
    return parseFloat(this.raw.unpaidPrincipalBalance || 0);
  }

  get paymentOptionText() {
    const paymentUpfront = parseFloat(this.raw.paymentUpfrontPercent);
    const paymentPayoff = parseFloat(this.raw.paymentPayoffPercent);
    if (paymentUpfront === 100 && !paymentPayoff) {
      return "100.00% Upfront";
    }
    if (!paymentUpfront && paymentPayoff === 100) {
      return "100.00% Payoff";
    }
    const formattedUpfront = NumberFormatter.formatPercent(paymentUpfront, 2, false);
    const formattedPayoff = NumberFormatter.formatPercent(paymentPayoff, 2, false);
    return `${formattedUpfront} Upfront | ${formattedPayoff} Payoff`;
  }

  get totalFee() {
    const totalAdminFees = this.feeList.reduce((res, fee) => res + parseFloat(fee.amount), 0);
    return totalAdminFees + this.modificationFee;
  }

  get feeList() {
    return this.raw.fees || [];
  }

  get modificationFee() {
    if (this.feeType === "percentage") {
      return (this.unpaidPrincipalBalance * this.feeValue) / 100.0;
    } else {
      return this.feeValue;
    }
  }
}
