<template>
  <div v-if="!detail.deleted">
    <hr>
    <div class="form-group row align-items-start">
      <label
        v-if="!isNewFee"
        class="col-sm-3 col-form-label"
      >
        {{ label }}
      </label>

      <div v-else class="col-sm-3">
        <fee-type-select
          v-model="detail.feeType"
          v-validate.initial="'required|unique_fee_type'"
          data-vv-as="Fee Type"
          name="feeType"
        />
      </div>

      <amount-input
        v-model="detail.originalAmount"
        name="originalAmount"
        class="col-sm-4"
        readonly
      />

      <amount-input
        v-model="detail.revisedAmount"
        v-validate.initial="reviseValidation"
        data-vv-as="Amount"
        class="col-sm-4"
        name="revisedAmount"
        :readonly="!isRevisable"
        :initial-value="detail.revisedAmount || detail.originalAmount"
      />

      <button v-if="deletable" class="btn btn-xs" @click.prevent="handleDelete">
        <icon icon="trash-alt" />
      </button>
    </div>

    <div v-if="displayDateFields" class="row align-items-start">
      <div class="col-sm-8 offset-sm-3 card bg-light">
        <div class="row form-group pt-2">
          <date-field
            ref="periodStartDate"
            v-model="detail.periodStartDate"
            v-validate.initial="'required|before:periodEndDate,true'"
            class="col-sm-6"
            label="Start Date"
            name="periodStartDate"
            data-vv-as="Start Date"
            @input="delayedValidate('periodEndDate')"
          />

          <date-field
            ref="periodEndDate"
            v-model="detail.periodEndDate"
            v-validate.initial="'required|after:periodStartDate,true'"
            class="col-sm-6"
            label="End Date"
            name="periodEndDate"
            data-vv-as="End Date"
            @input="delayedValidate('periodStartDate')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import find from "lodash/find";
import Icon from "components/shared/icon";
import FeeTypeSelect from "./fee_type_select";
import AmountInput from "./amount_input";
import DateField from "./date_field";
import moment from "moment";

const feeTypeLabel = name => {
  const feeType = find(PSData.feeTypes, { name });

  if (!feeType) {
    throw `Unknown feeType: ${name}`;
  }

  return feeType.label;
};

const parseDate = date => date && moment(date).toDate();
const normalizeDetail = detail => ({
  ...detail,
  periodStartDate: parseDate(detail.periodStartDate),
  periodEndDate: parseDate(detail.periodEndDate),
});

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    FeeTypeSelect,
    Icon,
    AmountInput,
    DateField,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    const detail = normalizeDetail(JSON.parse(JSON.stringify(this.value)));
    return { detail };
  },
  computed: {
    displayDateFields() {
      return !["principal", "prepaid_interest", "fee", "supplemental_payment"].includes(
        this.detail.disbursementType
      );
    },
    isRevisable() {
      return this.detail.disbursementType !== "principal";
    },
    reviseValidation() {
      if (!this.isRevisable) return "";

      const rules = "required|decimal";
      const allow_zero = [
        "default_interest",
        "deferred_interest",
        "interest",
        "supplemental_payment",
      ].includes(this.detail.disbursementType);

      return allow_zero ? rules : rules + "|not_zero";
    },
    deletable() {
      return this.detail.disbursementType === "fee";
    },
    isNewFee() {
      return !this.detail.id && this.detail.disbursementType === "fee";
    },
    label() {
      const { disbursementType, feeType } = this.detail;

      switch (disbursementType) {
        case "principal":
          return "Principal";
        case "prepaid_interest":
          return "Prepaid Interest";
        case "interest":
          return "Interest";
        case "accrual_interest":
          return "Accrual Interest";
        case "default_interest":
          return "Default Interest";
        case "deferred_interest":
          return "Deferred Interest";
        case "fee":
          return feeTypeLabel(feeType);
        case "supplemental_payment":
          return "Supplemental Payment";
        default:
          throw "Unknown disbursementType";
      }
    },
  },
  watch: {
    detail: {
      deep: true,
      handler(v) {
        this.$emit("input", v);
      },
    },
    value: {
      deep: true,
      handler(v) {
        this.detail = v;
      },
    },
  },
  methods: {
    delayedValidate(field) {
      Vue.nextTick(() => this.$validator.validate(field));
    },
    handleDelete() {
      this.$emit("delete");
    },
  },
};
</script>
