<template>
  <diligence-document-field :document="document" :field="field">
    <div class="col-5">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          :id="field.id"
          v-model="field.value"
          v-mask:currency=""
          class="form-control"
          type="currency"
          :disabled="disabled"
          :readonly="field.hasAutocalculation()"
          @blur="saveValueImmediately"
        />
      </div>
    </div>
  </diligence-document-field>
</template>

<script>
import TextField from "./base/text_field";

const CurrencyField = TextField.extend({});

export default Vue.component("diligence-document-currency-field", CurrencyField);
</script>
