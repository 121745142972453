<template>
  <div class="comment-form">
    <base-text-field
      id="comment-text-area"
      v-model="commentValue"
      type="textarea"
      class="new-comment-field"
    />
    <tag-input id="tag-input" :options="tagOptions" :tags="tags" @on-tag-change="updateTags" />
    <div class="new-comment-buttons">
      <base-button type="text" @click="clearInputField"> Cancel </base-button>
      <base-button
        id="add-comment-button"
        class="add-comment-button"
        :disabled="isDisabled"
        type="primary"
        @click="submit"
      >
        <div v-if="inFlight">
          <i class="ps-loader ps-loader-small" />
        </div>
        <div v-else>Add Note</div>
      </base-button>
    </div>
  </div>
</template>
<script>
import AjaxService, { ERROR_STATUS, ERROR_MESSAGE } from "services/ajax_service";
import GlobalBusService from "services/global_bus_service";
import TagInput from "./tag_input";

export default {
  components: { TagInput },
  props: {
    resourceUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      commentValue: "",
      inFlight: false,
      tags: [],
      tagOptions: [],
    };
  },
  computed: {
    isDisabled() {
      return this.trimmedComment() === "" || this.inFlight;
    },
  },
  async created() {
    const response = await AjaxService.get(PSData.routes.commentTagOptions);
    this.tagOptions = response;
  },
  methods: {
    updateTags(tags) {
      this.tags = tags;
    },
    clearInputField() {
      this.tags = [];
      this.commentValue = "";
    },
    handleCreatedComment(createdComment) {
      this.clearInputField();
      this.$emit("comment-created", createdComment);
      GlobalBusService.$emit("comment-created");
    },
    trimmedComment() {
      return this.commentValue.trim();
    },
    async submit() {
      this.inFlight = true;
      try {
        const createdComment = await AjaxService.postJSON(this.resourceUrl, {
          comment: this.trimmedComment(),
          tags: this.tags.map(tag => {
            return { id: tag.id };
          }),
        });
        this.handleCreatedComment(createdComment);
      } catch ({ data }) {
        if (data && data.errors) {
          AjaxService.sendNotification(data.errors.join("\n"), ERROR_STATUS);
        } else {
          AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
        }
      } finally {
        this.inFlight = false;
      }
    },
  },
};
</script>
<style scoped>
.comment-form::after {
  display: block;
  content: "";
  clear: both;
}

.new-comment-buttons {
  float: right;
}
.add-comment-button {
  width: 100px;
  height: 36px;
}
.ps-loader {
  left: 2px;
}
.new-comment-field >>> textarea {
  font-size: var(--font-size);
  font-weight: 400;
  max-height: unset;
}
</style>
