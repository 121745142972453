<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content base-modal distribute-confirmation">
      <div class="modal-header">
        <h4>Confirm Distribution</h4>
      </div>
      <div class="modal-body">
        <div v-if="participantsEnabled" class="disbursable-participants">
          <p>
            You are about to initiate cash movement for the following participants:
          </p>
          <ul>
            <li v-if="canDisburseToLender">
              - Lenders
            </li>
            <li v-if="canDisburseToInvestorInst">
              - Institutional Investors
            </li>
            <li v-if="canDisburseToInvestorRetail">
              - Retail Investors
            </li>
            <li v-if="canDisburseToMasterServicer">
              - PeerStreet
            </li>
          </ul>
        </div>
        <p class="distributed-note">
          All these distribution records will be marked "Distributed". You will no longer be able to edit these records.
        </p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-link" @click="onClose">Cancel</button>
        <button
          class="btn btn-action-cta"
          @click="distribute"
          :disabled="disabled"
        >
          Confirm
        </button>
      </div>
    </div>
  </modal-view>
</template>
<script>
import map from "lodash/map";
import ModalView from "components/modal_view";

export default {
  components: { ModalView },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      show: true,
      distributing: false,
      canDisburseToMasterServicer: PSData.canDisburseToMasterServicer,
      canDisburseToLender: PSData.canDisburseToLender,
      canDisburseToInvestorInst: PSData.canDisburseToInvestorInst,
      canDisburseToInvestorRetail: PSData.canDisburseToInvestorRetail,
    };
  },
  computed: {
    disabled() {
      return this.distributing;
    },
    participantsEnabled() {
      return (
        this.canDisburseToMasterServicer ||
        this.canDisburseToLende ||
        this.canDisburseToInvestorInst ||
        this.canDisburseToInvestorRetail
      );
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    distribute() {
      this.distributing = true;
      const ids = map(this.items, "id");
      this.$emit("distribute", ids, this.handleSuccess);
    },
    handleSuccess() {
      this.distributing = false;
      this.$emit("close");
    },
  },
};
</script>
