export function titleize(str) {
  return str
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
}

export function formatNumber(value, precision = 2, numberOnly = false) {
  if ((numberOnly && typeof value !== "number") || value === null || value === undefined) {
    return;
  }

  return parseFloat(value || 0)
    .toFixed(precision)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function formatPercent(value, precision = 3, numberOnly = false) {
  if ((numberOnly && typeof value !== "number") || value === null || value === undefined) {
    return;
  }
  return parseFloat(value || 0).toFixed(precision) + "%";
}

export function formatCurrency(value, precision = 2, numberOnly = false) {
  if ((numberOnly && typeof value !== "number") || value === null || value === undefined) {
    return;
  }
  return "$" + formatNumber(value, precision);
}

const APP_TIME_ZONE = "America/Los_Angeles";

const SHORT_DATE_FORMATTER = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  timeZone: APP_TIME_ZONE,
});

export function formatDate(value) {
  if (value === null || value === undefined) {
    return;
  }

  return SHORT_DATE_FORMATTER.format(new Date(value));
}
