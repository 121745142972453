<template>
  <diligence-document-field :document="document" :field="field">
    <div v-if="field.isSelect()" class="col-5">
      <select :id="field.id" v-model="field.value" class="form-control" :disabled="disabled">
        <option v-for="option in field.collection" :key="option.value" :value="option.value">
          {{ option.name }}
        </option>
      </select>
    </div>
  </diligence-document-field>
</template>

<script>
import NonTextField from "./base/non_text_field";

const SelectField = NonTextField.extend({});

export default Vue.component("diligence-document-select-field", SelectField);
</script>
