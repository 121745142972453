import AjaxService from "services/ajax_service";
import { success } from "services/notification";
import snakeCase from "lodash/snakeCase";

export default {
  data() {
    return { inFlight: false };
  },
  methods: {
    async onDropdownUpdate(item, property) {
      if (this.inFlight) return;
      this.inFlight = true;

      const currentValue = item[property];
      const params = {
        servicer_loan_boarding: {
          [snakeCase(property)]: currentValue,
        },
      };

      const messages = {
        onSuccess: "Updated successfully",
      };

      try {
        await AjaxService.withNotifications(messages).patch(item.routes.update, params);
      } finally {
        this.inFlight = false;
      }
    },
  },
};
