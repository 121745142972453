import FetchPricing from "./fetch_pricing";
import FetchCustomPricingScenario from "./fetch_custom_pricing_scenario";
import FetchBestRate from "./fetch_best_rate";
import FetchMaxLeverage from "./fetch_max_leverage";
import { ltvIntervals } from "../utils/helpers";

export default class FetchPricingScenarios {
  static async call(scenario, serviceClient) {
    const loanProduct = scenario.loanProduct;
    let pricingRequests;

    pricingRequests = [
      ...[FetchBestRate.call(scenario, serviceClient)],
      ...[FetchMaxLeverage.call(scenario, serviceClient)],
      ...ltvIntervals(loanProduct).map(async ltv => {
        const pricingScenario = await FetchPricing.call(scenario, ltv, serviceClient);

        return pricingScenario;
      }),
    ];

    // when there is a user provided LTV
    // get the new pricing for the custom scenario
    if (scenario.ltv) {
      pricingRequests.push(FetchCustomPricingScenario.call(scenario, serviceClient));
    }

    const pricingResponses = await Promise.all(pricingRequests);

    return this._prepareResults(pricingResponses);
  }

  static _filterDuplicates(results) {
    return [...results].filter(
      (result, index, array) =>
        // if custom result do not filter, otherwise remove
        // any if this result overlaps any other result's LTV
        result.custom || index === array.findIndex(otherResult => otherResult.ltv === result.ltv)
    );
  }

  static _filterInvalidResults(results) {
    return [...results].filter(result => !result.custom && result.cbFlag === false);
  }

  static _findBestRateScenario(results) {
    return results.reduce((a, b) => {
      const hasLowerInterestRate = a.interestRate < b.interestRate;
      const hasEqualInterestRate = a.interestRate === b.interestRate;
      const hasHigherLtv = a.ltv > b.ltv;

      return hasLowerInterestRate || (hasEqualInterestRate && hasHigherLtv) ? a : b;
    }, {});
  }

  static _findMaxLeverageScenario(results) {
    const maxLeverageScenario = results.find(result => result.maxLeverage === true);

    return maxLeverageScenario || results.reduce((a, b) => (a.ltv > b.ltv ? a : b), {});
  }

  static _sortResultsByLtv(results) {
    return results.sort((a, b) => {
      let sortPriority;

      // custom rows need to always be sorted
      // at the end for the frontend to work properly
      if (a.custom) {
        sortPriority = 1;
      } else if (b.custom) {
        sortPriority = -1;
      } else {
        sortPriority = a.ltv - b.ltv;
      }

      return sortPriority;
    });
  }

  static _prepareResults(results) {
    const filteredResults = this._filterDuplicates(results);
    const validResults = this._filterInvalidResults(filteredResults);
    // assign bestRate and maxLeverage in case
    // LPS does not return a creditbox fit for these two
    this._findBestRateScenario(validResults).bestRate = true;
    this._findMaxLeverageScenario(validResults).maxLeverage = true;

    return this._sortResultsByLtv(filteredResults);
  }
}
