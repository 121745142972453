<template>
  <div class="form-group loan-task-comments-form">
    <autosize-textarea
      class="form-control"
      placeholder="Write comment here..."
      :disabled="uiIsBlocked"
      v-model="content"
    ></autosize-textarea>

    <input
      class="btn-action-cta-sm btn-add-comment"
      type="button"
      :value="submitCaption"
      :disabled="submitIsDisabled"
      @click="sendComment"
    >
  </div>
</template>
<script>
import AutosizeTextarea from "components/shared/autosize_textarea";
import Ajax from "services/ajax_service";

export default {
  name: "loan-tasks-comments-form",
  components: { AutosizeTextarea },
  props: {
    task: Object,
  },
  data() {
    return {
      content: "",
      uiIsBlocked: false,
    };
  },
  methods: {
    sendComment() {
      this.uiIsBlocked = true;

      return Ajax.post(this.task.commentsPath, { content: this.content }).then(data => {
        this.uiIsBlocked = false;

        if (data.error) {
          Ajax.sendNotification(data.error, "error");
        } else {
          this.content = "";
          this.$emit("created", data.comment);
        }
      });
    },
  },
  computed: {
    submitIsDisabled() {
      return this.uiIsBlocked || this.content.trim().length === 0;
    },
    submitCaption() {
      if (this.uiIsBlocked) {
        return "Commenting...";
      } else {
        return "Comment";
      }
    },
  },
};
</script>
