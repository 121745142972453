<template>
  <div id="reo-loans-list" class="active-portfolio-loans-list" v-cloak>
    <div class="portfolio-table-header">
      Showing {{ loans.length | pluralize("loan") }} totalling {{ totalUnpaidBalance | money(0) }}
    </div>

    <table class="portfolio-table">
      <thead>
        <tr>
          <th class="hotlist-sortable loan-origination-date">
            <sorting-header
              title="Origination Date"
              :order="findDirectionBy(SortingColumns.OriginationDate)"
              @on-change-direction="sortBy(SortingColumns.OriginationDate, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-payment-date">
            <sorting-header
              title="Foreclosure Sale Date"
              :order="findDirectionBy(SortingColumns.ForeclosureSaleDate)"
              @on-change-direction="sortBy(SortingColumns.ForeclosureSaleDate, ...arguments)"
            />
          </th>

          <th class="hotlist-sortable loan-full-address">
            <sorting-header
              title="Address"
              :order="findDirectionBy(SortingColumns.Address)"
              @on-change-direction="sortBy(SortingColumns.Address, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-program-type">
            <sorting-header
              title="Loan Program"
              :order="findDirectionBy(SortingColumns.LoanProgram)"
              @on-change-direction="sortBy(SortingColumns.LoanProgram, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-strategy">
            <sorting-header
              title="Loan Strategy"
              :order="findDirectionBy(SortingColumns.LoanStrategy)"
              @on-change-direction="sortBy(SortingColumns.LoanStrategy, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-unpaid-principal-balance">
            <sorting-header
              title="Unpaid Principal Bal"
              :order="findDirectionBy(SortingColumns.UnpaidPrincipalBalance)"
              @on-change-direction="sortBy(SortingColumns.UnpaidPrincipalBalance, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-b-piece">
            <sorting-header
              title="B-Piece"
              :order="findDirectionBy(SortingColumns.BPiece)"
              @on-change-direction="sortBy(SortingColumns.BPiece, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-interest-rate">
            <sorting-header
              title="Note Rate"
              :order="findDirectionBy(SortingColumns.NoteRate)"
              @on-change-direction="sortBy(SortingColumns.NoteRate, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-interest-rate">
            <sorting-header
              title="LTV"
              :order="findDirectionBy(SortingColumns.Ltv)"
              @on-change-direction="sortBy(SortingColumns.Ltv, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-interest-rate">
            <sorting-header
              title="FICO"
              :order="findDirectionBy(SortingColumns.Fico)"
              @on-change-direction="sortBy(SortingColumns.Fico, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-borrower-contact">
            <sorting-header
              title="Borrower"
              :order="findDirectionBy(SortingColumns.Borrower)"
              @on-change-direction="sortBy(SortingColumns.Borrower, ...arguments)"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="loan in loans" :key="loan.toParam" :data-path="loanPath(loan)">
          <td class="loan-origination-date" :class="activeSortClass(SortingColumns.OriginationDate)">
            <span>
              {{ loan.originationDate }}
            </span>
          </td>
          <td class="loan-payment-date" :class="activeSortClass(SortingColumns.ForeclosureSaleDate)">
            <span>{{ loan.foreclosureSaleDate | missingValue }}</span>
          </td>
          <td :class="activeSortClass(SortingColumns.Address)" class="loan-full-address">
            <span class="loan-street">
              {{ loan.address }}
            </span>
            <span>{{ loan.city }}, {{ loan.state }} {{ loan.zip }}</span>
          </td>
          <td :class="activeSortClass(SortingColumns.LoanProgram)" class="loan-program-type">
            {{ loan.loanProgramText }}
          </td>
          <td :class="activeSortClass(SortingColumns.LoanStrategy)" class="loan-strategy">
            {{ loan.loanStrategyText }}
          </td>
          <td
            :class="activeSortClass(SortingColumns.UnpaidPrincipalBalance)"
            class="loan-unpaid-principal-balance"
          >
            {{ loan.unpaidPrincipalBalance | money(2, true) | missingValue }}
          </td>
          <td :class="activeSortClass(SortingColumns.BPiece)" class="loan-b-piece">
            {{ loan.bPieceAmount | money(2, true) | missingValue }}
          </td>
          <td :class="activeSortClass(SortingColumns.NoteRate)" class="loan-interest-rate">
            <span v-popover="{'container': '#reo-loans-list'}" class="with-field-tooltip">
              {{ loan.interestRate | percent(3, true) | missingValue }}
            </span>
            <div class="popover-content hidden">
              <p><strong>Note Rate:</strong> {{ loan.interestRate | percent(3, true) | missingValue }}</p>
              <p><strong>Lender Spread:</strong> {{ loan.lenderSpread | percent(3, true) | missingValue }}</p>
              <p><strong>LTV:</strong> {{ loan.ltv | percent(3, true) | missingValue }}</p>
              <p><strong>LTARV:</strong> {{ loan.ltarv | percent(3, true) | missingValue }}</p>
            </div>
          </td>
          <td :class="activeSortClass(SortingColumns.Ltv)" class="loan-interest-rate">
            {{ loan.ltv | percent(3, true) | missingValue }}
          </td>
          <td :class="activeSortClass(SortingColumns.Fico)" class="loan-interest-rate">
            {{ loan.borrowerFico | missingValue }}
          </td>
          <td :class="activeSortClass(SortingColumns.Borrower)" class="loan-borrower-contact">
            <span
              v-popover="{'container': '#reo-loans-list'}"
              class="with-field-tooltip"
            >
              {{ loan.borrowers[0].formattedName }}
            </span>
            <div class="popover-content hidden">
              <borrowers-info :borrowers="loan.borrowers" />
            </div>
          </td>
        </tr>
        <tr v-if="loans.length === 0">
          <td class="loan-empty">No Loans</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SortingHeader from "components/shared/sorting_header";
import SortingHelper from "utils/sorting_helper";
import BorrowersInfo from "components/active_portfolio/borrowers_info";
import Ajax from "services/ajax_service";

const SortingColumns = {
  OriginationDate: "originationDate",
  ForeclosureSaleDate: "foreclosureSaleDate",
  LoanProgram: "loanProgramText",
  LoanStrategy: "loanStrategyText",
  UnpaidPrincipalBalance: "unpaidPrincipalBalance",
  BPiece: "bPieceAmount",
  NoteRate: "interestRate",
  Ltv: "ltv",
  Fico: "fico",
  Borrower: "firstBorrowerName",
  Address: "fullAddress",
};

export default {
  name: "reo-loans-list",
  components: {
    SortingHeader,
    BorrowersInfo,
  },
  props: {
    loansRoute: String,
  },
  data() {
    return {
      sortingDirection: 1,
      sortingColumn: SortingColumns.ForeclosureSaleDate,
      sorter: new SortingHelper({
        [SortingColumns.OriginationDate]: {},
        [SortingColumns.ForeclosureSaleDate]: {},
        [SortingColumns.LoanStrategy]: {},
        [SortingColumns.LoanProgram]: {},
        [SortingColumns.BPiece]: {},
        [SortingColumns.NoteRate]: {},
        [SortingColumns.Ltv]: {},
        [SortingColumns.Fico]: {},
        [SortingColumns.Borrower]: {},
        [SortingColumns.Address]: {},
        [SortingColumns.UnpaidPrincipalBalance]: {},
      }),
      SortingColumns,
      routes: PSData.routes || {},
      loans: [],
      group: "reo",
    };
  },
  computed: {
    totalUnpaidBalance() {
      return this.loans
        .map(loan => loan.unpaidPrincipalBalance || 0)
        .reduce((total, current) => total + current, 0);
    },
  },
  mounted() {
    Ajax.getJSON(this.routes.reoLoans)
      .then(data => {
        this.loans = data.loans;
        this.$emit("loaded", this.group, this.loans.length);
      })
      .catch(() => {
        this.loans = [];
      });
  },
  methods: {
    findDirectionBy(columnName) {
      return this.sorter.findDirection(columnName, this.sortingColumn, this.sortingDirection);
    },
    sortBy(columnName, direction) {
      this.sortingColumn = columnName;
      this.sortingDirection = direction;

      this.loans = this.loans.sort(this.sorter.sort(columnName, direction));
    },
    activeSortClass(column) {
      return this.sorter.activeSortClass(this.sortingColumn, column);
    },
    loanPath(loan) {
      return `${this.loansRoute}/${loan.toParam}`;
    },
  },
};
</script>
