<template>
  <div class="template-item d-flex" v-on="$listeners" :class="{ highlight: selected }">
    <i class="fa fa-file-alt document-template-icon" />
    <span>{{ label }}</span>
    <i v-show="selected" class="fa fa-check-circle selected-template-icon" />
  </div>
</template>

<script>
export default {
  name: "template-list-item",
  props: {
    label: {
      type: String,
      required: true,
    },
    selected: Boolean,
  },
};
</script>
