<template>
  <div class="reserve-draw-request-details">
    <div class="details-row no-border row">
      <div class="col-3">
        <h3 class="row-header">Loan Balance</h3>
      </div>
      <div class="col-3">
        <h4 class="loan-balance-category">Total Reserve</h4>
        <span class="readonly-value">{{ reserveDrawRequest.totalReserve | money }}</span>
      </div>
      <div class="col-3">
        <h4 class="loan-balance-category">Previous Draws</h4>
        <span class="readonly-value">{{ reserveDrawRequest.previousDraws | money }}</span>
      </div>
      <div class="col-3">
        <h4 class="loan-balance-category">Available</h4>
        <span class="readonly-value">{{ reserveDrawRequest.availableReserve | money }}</span>
      </div>
    </div>
    <reserve-draw-request-editable-fields
      v-if="isEditable"
      :reserve-draw-request="reserveDrawRequest"
      @update-draw-request="updateDrawRequest"
    />
    <reserve-draw-request-readonly-fields
      v-else
      :reserve-draw-request="reserveDrawRequest"
    />
  </div>
</template>

<script>
import ReserveDrawRequestEditableFields from "components/manage/reserve_draw_request/editable_fields";
import ReserveDrawRequestReadonlyFields from "components/manage/reserve_draw_request/readonly_fields";

export default {
  name: "reserve-draw-request-details",
  components: {
    ReserveDrawRequestEditableFields,
    ReserveDrawRequestReadonlyFields,
  },
  props: {
    reserveDrawRequest: {
      type: Object,
      default: () => ({}),
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updateDrawRequest(name, value) {
      this.$emit("update-draw-request", name, value);
    },
  },
};
</script>
