import FetchPricing from "./fetch_pricing";

const MAX_REQUESTS = 5;

export default class FetchPricingAtTargetLoanAmount {
  static async call(scenario, baselineLtv, serviceClient, targetLoanAmountName) {
    let lastTargetLoanAmount;
    let ltv = baselineLtv;
    let currentTargetLoanAmount = null;
    let requestCount = 0;
    let hasHitTargetLoanAmount = false;
    let lastPricingResponse = {};

    // DSCR affects note rate and note rate affects DSCR,
    // and changes in loan amount can also affect the note rate.
    // In order to accurately find the correct loan amount for a given DSCR
    // we need to make sure we're in the right "pricing tier"

    while (!hasHitTargetLoanAmount && requestCount <= MAX_REQUESTS && ltv > 0) {
      lastTargetLoanAmount =
        parseFloat(lastPricingResponse[targetLoanAmountName]) || scenario.calculateLoanAmount(ltv);

      lastPricingResponse = await FetchPricing.call(scenario, ltv, serviceClient, false, {
        total_principal_balance_at_origination: lastTargetLoanAmount,
      });

      currentTargetLoanAmount = parseFloat(lastPricingResponse[targetLoanAmountName]);
      ltv = scenario.calculateLtv(currentTargetLoanAmount);
      hasHitTargetLoanAmount = currentTargetLoanAmount === lastTargetLoanAmount;

      ++requestCount;
    }

    return lastPricingResponse;
  }
}
