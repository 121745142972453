export default {
  data() {
    return {
      editing: false,
      updating: false,
    };
  },
  computed: {
    canEdit: () => PSData.assetManagementCanEdit,
    foreclosureOutcomeTypes: () => PSData.loanForeclosureOutcomeTypes || [],
    workoutCases: () => PSData.foreclosureWorkoutCases || [],
    getForeclosureOutcomeType() {
      return this.foreclosureOutcomeTypes.find(el => el.text.toLowerCase() == "foreclosure");
    },
    hideActionButtons() {
      return !this.isApplicable() || !this.canEdit;
    },
  },
  methods: {
    updateForm(field, value) {
      this.$emit("input", field, value);
    },
    handleEdit() {
      this.editing = true;
      this.$emit("backup-form");
    },
    handleCancel() {
      this.editing = false;
      this.updating = false;
      this.$emit("reset-form");
    },
    postUpdate() {
      this.editing = false;
      this.updating = false;
    },
    handleUpdate() {
      this.updating = true;
      this.$emit("save", this.form, this.postUpdate);
    },
    hasForeclosureWorkoutCase() {
      const foreclosureWorkoutCase = this.workoutCases.find(
        wc => wc.foreclosureOutcomeId == this.getForeclosureOutcomeType.id
      );
      return foreclosureWorkoutCase !== undefined;
    },
  },
};
