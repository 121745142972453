<template>
  <div class="reserve-draw-request">
    <breadcrumb-navigation :items="breadcrumbItems"></breadcrumb-navigation>
    <div class="reserve-draw-request-header no-border">
      <div class="reserve-draw-request-title-container">
        <h3>{{ drawRequest.drawRequestTypeText}} Draw Request {{ drawRequest.drawRequestNumber }}</h3>
        <span :class="statusClass">{{ drawRequest.status }}</span>
        <span class="ps-loader ps-loader-small" v-if="isLoading"></span>
        <div class="draw-request-sub">
          <span>{{ drawRequest.onPlatform }}</span>
          <span v-if="hasId">• Last updated {{ drawRequest.updatedAt | fromNow(null) }}</span>
        </div>
      </div>
      <action-button
        v-if="showActionButton"
        :reserve-draw-request="drawRequest"
        :reserve-draw-request-policy="drawRequestPolicy"
        :is-loading="isLoading"
        :download-reserve-draw-request-route="routes.download"
        @progress-draw-request="progressDrawRequest"
        @wire-instruction="openWireInstructionModal"
      />
    </div>
    <notification-group
      :title="'Cannot save draw request'"
      :message-groups="errors"
      :dismissible="true"
      @dismissed="clearErrors"
    />
    <reserve-draw-request-details
      :reserve-draw-request="drawRequest"
      :is-editable="isEditable"
      @update-draw-request="updateDrawRequest"
    />
    <div v-if="isEditable" class="draw-request-buttons-row">
      <div class="btn btn-outline-grey" @click="cancel">Cancel</div>
      <div class="btn btn-secondary-action-cta" @click="saveDrawRequest">Save Draft Draw</div>
      <div class="btn btn-action-cta" @click="progressDrawRequest('submit')">Submit for Approval</div>
    </div>
    <wire-instruction-modal
      :reserve-draw-request="drawRequest"
      :wire-instruction="drawWireInstruction"
      :show="showDrawWireInstructionModal"
      :wire-type="'draw'"
      @close="closeWireInstructionModal"
      @updated="updateWireInstruction"
      v-if="showDrawWireInstructionModal"
    />
    <wire-instruction-modal
      :reserve-draw-request="drawRequest"
      :wire-instruction="feeWireInstruction"
      :show="showFeeWireInstructionModal"
      :wire-type="'fee'"
      @close="closeWireInstructionModal"
      @updated="updateWireInstruction"
      v-if="showFeeWireInstructionModal"
    />
  </div>
</template>

<script>
import Ajax from "services/ajax_service";
import DrawRequest from "models/reserve_draw_request";
import DrawRequestPolicy from "policies/reserve_draw_request_policy";
import get from "lodash/get";
import includes from "lodash/includes";
import ActionButton from "components/manage/reserve_draw_request/action_button";
import BreadcrumbNavigation from "components/shared/breadcrumb_navigation";
import ReserveDrawRequestDetails from "components/manage/reserve_draw_request/details";
import WireInstructionModal from "components/manage/reserve_draw_request/wire_instruction_modal";
import NotificationGroup from "components/shared/notification_group";
import Location from "services/location_service";

export default {
  name: "reserve-draw-request-show",
  components: {
    ActionButton,
    BreadcrumbNavigation,
    NotificationGroup,
    ReserveDrawRequestDetails,
    WireInstructionModal,
  },
  data() {
    return {
      drawRequest: new DrawRequest(PSData.reserveDrawRequest || {}),
      isLoading: false,
      drawRequestPolicy: new DrawRequestPolicy(PSData.reserveDrawRequestPolicy),
      errors: [],
      showDrawWireInstructionModal: false,
      showFeeWireInstructionModal: false,
      drawWireInstruction: PSData.drawWireInstruction,
      feeWireInstruction: PSData.feeWireInstruction,
      routes: {
        save: get(PSData, "routes.saveReserveDrawRequest"),
        submit: get(PSData, "routes.submitReserveDrawRequest"),
        approve: get(PSData, "routes.approveReserveDrawRequest"),
        completeReversal: get(PSData, "routes.completeDrawReversal"),
        cancel: get(PSData, "routes.cancelReserveDrawRequest"),
        repair: get(PSData, "routes.repairReserveDrawRequest"),
        reverse: get(PSData, "routes.reverseReserveDrawRequest"),
        download: get(PSData, "routes.downloadReserveDrawRequest"),
        servicing: get(PSData, "routes.servicing"),
      },
      successMessages: {
        save: "Reserve draw request saved.",
        submit: "Reserve draw request submitted.",
        approve: "Reserve draw request approved.",
        completeReversal: "Draw request reversal completed.",
        cancel: "Reserve draw request cancelled.",
        repair: "Reserve draw request repaired.",
        reverse: "Reserve draw request reversed.",
      },
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          name: "Servicing",
          link: this.routes["servicing"],
        },
        {
          name: `${this.drawRequest.drawRequestTypeText} Draw Request`,
        },
      ];
    },
    hasId() {
      return this.drawRequest.id;
    },
    isEditable() {
      return this.drawRequestPolicy.canSubmit();
    },
    statusClass() {
      return ["draw-request-status", `draw-request-status-${this.drawRequest.status}`];
    },
    showActionButton() {
      return (
        this.drawRequestPolicy.canApprove() ||
        this.drawRequestPolicy.canCompleteReversal() ||
        this.drawRequestPolicy.canCancel() ||
        this.drawRequestPolicy.canCancelReversal() ||
        this.drawRequestPolicy.canSubmit() ||
        this.drawRequestPolicy.canRepair() ||
        this.drawRequestPolicy.canReverse() ||
        this.drawRequestPolicy.canDownload() ||
        this.drawRequestPolicy.canSubmitWireInstruction()
      );
    },
  },
  methods: {
    cancel() {
      this.redirectToServicing();
    },
    saveDrawRequest() {
      if (this.drawRequest.id) {
        this.patchDrawRequest();
      } else {
        this.progressDrawRequest("save");
      }
    },
    progressDrawRequest(action) {
      this.isLoading = true;
      const route = this.routes[action];
      const successMessage = this.successMessages[action];
      const messages = { onSuccess: successMessage };
      Ajax.withNotifications(messages)
        .postJSON(route, this.drawRequest.toHash())
        .then(data => {
          this.isLoading = false;
          this.handleSuccessResponse(data, action);
        })
        .catch(resp => {
          this.isLoading = false;
          this.errors = get(resp, "data.error");
        });
    },
    patchDrawRequest() {
      this.isLoading = true;
      const route = this.routes["save"];
      const messages = { onSuccess: "Reserve draw request updated." };
      Ajax.withNotifications(messages)
        .patchJSON(route, this.drawRequest.toHash())
        .then(data => {
          this.isLoading = false;
          this.redirectToServicing();
        })
        .catch(resp => {
          this.isLoading = false;
          this.errors = get(resp, "data.error");
        });
    },
    handleSuccessResponse(data, action) {
      if (action === "repair") {
        this.drawRequest = new DrawRequest(data.reserveDrawRequest || {});
        this.drawRequestPolicy = new DrawRequestPolicy(data.reserveDrawRequestPolicy);
      } else {
        this.redirectToServicing();
      }
    },
    redirectToServicing() {
      Location.href = this.routes["servicing"];
    },
    updateDrawRequest(name, value) {
      this.drawRequest[name] = value;
    },
    clearErrors() {
      this.errors = [];
    },
    openWireInstructionModal(wireType) {
      if (wireType === "draw") {
        this.showDrawWireInstructionModal = true;
      } else {
        this.showFeeWireInstructionModal = true;
      }
    },
    closeWireInstructionModal(wireType) {
      if (wireType === "draw") {
        this.showDrawWireInstructionModal = false;
      } else {
        this.showFeeWireInstructionModal = false;
      }
    },
    updateWireInstruction(wireInstruction, wireType) {
      if (wireType === "draw") {
        this.drawWireInstruction = wireInstruction;
      } else {
        this.feeWireInstruction = wireInstruction;
      }
    },
  },
};
</script>
