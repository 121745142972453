import AjaxService from "services/ajax_service";

export default class WorkflowEngineClient {
  static get BASE_URL() {
    return "/workflow_engine";
  }

  static get CLIENT_ID() {
    return PSData.global.workflowEngineClientId;
  }

  listWorkflowTemplates(resourceId) {
    return AjaxService.getJSON(
      `${WorkflowEngineClient.BASE_URL}/workflow_templates?resource_id=${resourceId}`
    );
  }

  listWorkflows(resourceId) {
    return AjaxService.getJSON(
      `${WorkflowEngineClient.BASE_URL}/workflows?resource_id=${resourceId}`
    );
  }

  getWorkflow(id) {
    return AjaxService.getJSON(`${WorkflowEngineClient.BASE_URL}/workflows/${id}`);
  }

  getPendingSteps(workflowId) {
    return AjaxService.getJSON(
      `${WorkflowEngineClient.BASE_URL}/pending_steps?workflow_id=${workflowId}`
    );
  }

  getCompletedSteps(workflowId) {
    return AjaxService.getJSON(
      `${WorkflowEngineClient.BASE_URL}/completed_steps?workflow_id=${workflowId}`
    );
  }

  getPendingStep(stepId) {
    return AjaxService.getJSON(`${WorkflowEngineClient.BASE_URL}/pending_steps/${stepId}`);
  }

  startWorkflow(templateId, resourceId, props_list) {
    const params = {
      workflow_template_id: templateId,
      resource_id: resourceId,
      props_list,
    };

    return AjaxService.postJSON(`${WorkflowEngineClient.BASE_URL}/workflows`, params);
  }

  completeStep(stepId, data) {
    return AjaxService.postJSON(
      `${WorkflowEngineClient.BASE_URL}/pending_steps/${stepId}/complete`,
      { data }
    );
  }

  addInput(stepId, variableId) {
    const params = {
      step_id: stepId,
      variable_id: variableId,
    };

    return AjaxService.postJSON(`${WorkflowEngineClient.BASE_URL}/variable_instances`, params);
  }

  removeInput(stepId, instanceId) {
    const params = {
      step_id: stepId,
    };

    return AjaxService.delete(
      `${WorkflowEngineClient.BASE_URL}/variable_instances/${instanceId}`,
      params
    );
  }
}

//   class Client
//     def list_workflow_templates
//       return unless enabled?

//       get("/workflow_templates")
//     end

//     def get_workflow_template(id)
//       return unless enabled?

//       get("/workflow_templates/#{id}")
//     end

//     def get_pending_steps(loan)
//       return unless enabled?

//       get("/pending_steps", { resource_id: loan.ps_id })
//     end

//     def start_workflow(template_id, loan)
//       return unless enabled?

//       post("/workflows", { workflow_template_id: template_id, resource_id: loan.ps_id })
//     end

//     private

//     def get(endpoint, params = {})
//       default_params = { client_id: client_id }

//       response = RestClient.get("#{base_url}#{endpoint}", default_params.merge(params))
//       JSON.parse(response.body)
//     end

//     def post(endpoint, params = {})
//       default_params = { client_id: client_id }

//       response = RestClient.post("#{base_url}#{endpoint}", default_params.merge(params))
//       JSON.parse(response.body)
//     end

//     def enabled?
//       base_url.present? &&
//         client_id.present?
//     end

//     def base_url
//       ENV["WORKFLOW_ENGINE_URL"]
//     end

//     def client_id
//       ENV["WORKFLOW_ENGINE_CLIENT_ID"]
//     end
//   end
