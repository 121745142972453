<template>
  <div>
    <workflow-list :items="templates" header="Available Workflows:">
      <template v-slot:item="{ item }">
        <base-button size="small" type="primary" @click="runWorkflow(item)">Run</base-button>
        <span class="pl-2">
          {{ item.name }}
        </span>
      </template>
    </workflow-list>
    <workflow-list v-show="showRunningWorkflows" :items="runningWorkflows" header="Running Workflows:">
      <template v-slot:item="{ item }">
        <router-link :to="`/workflows/${item.id}`">
          {{ item.name }}
        </router-link>
      </template>
    </workflow-list>
    <workflow-list v-show="showCompletedWorkflows" :items="completedWorkflows" header="Completed Workflows:">
      <template v-slot:item="{ item }">
        <router-link :to="`/workflows/${item.id}`">
          {{ item.name }}
        </router-link>
      </template>
    </workflow-list>
  </div>
</template>

<script>
import WorkflowEngineClient from "services/workflow_engine_client";
import WorkflowList from "./list";

export default Vue.extend({
  name: "workflows-manage-index-page",
  components: {
    WorkflowList,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      templates: [],
      workflows: [],
    };
  },
  computed: {
    completedWorkflows() {
      return this.workflows.filter(w => w.completed);
    },
    runningWorkflows() {
      return this.workflows.filter(w => !w.completed);
    },
    showRunningWorkflows() {
      return this.runningWorkflows.length;
    },
    showCompletedWorkflows() {
      return this.completedWorkflows.length;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    runWorkflow(template) {
      const client = new WorkflowEngineClient();
      const inputs = template.inputs || [];

      client.startWorkflow(template.id, this.loan.toParam, inputs).then(() => {
        this.loadData();
      });
    },
    loadData() {
      const client = new WorkflowEngineClient();

      client.listWorkflowTemplates(this.loan.toParam).then(data => {
        this.templates = data;
      });

      client.listWorkflows(this.loan.toParam).then(data => {
        this.workflows = data;
      });
    },
  },
});
</script>
