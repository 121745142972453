<template>
  <div class="details-row row">
    <div class="col-12">
      <h4>Dates</h4>
    </div>
    <div class="col-4">
      <editable-field
        label="Paid to Date"
        :value="statement.paidToDate"
        :editing="true"
      />
    </div>
    <div v-if="isPeerstreetRepurchaseStatement" class="col-4">
      <editable-datepicker
        label="Corrected Paid to Date"
        :value="statement.correctedPaidToDate"
        :editing="true"
        :callback="updateEditableField('correctedPaidToDate', statement)"
      />
    </div>
    <div class="col-4">
      <editable-field
        label="Loan Maturity Date"
        :value="statement.maturityDate"
        :editing="true"
      />
    </div>
    <div class="col-12">
      <editable-datepicker
        label="Default Date"
        :value="statement.defaultDate"
        :editing="true"
        :disabled="!enableDefaultDate"
        :callback="updateEditableField('defaultDate', statement)"
      />
    </div>
  </div>
</template>

<script>
import EditableField from "components/shared/editable_field.vue";
import EditableDatepicker from "components/shared/editable_datepicker";
import EditPartialMixin from "components/manage/payoff_statement/edit/edit_partial_mixin";

export default {
  name: "dates-resale-statment",
  components: {
    EditableField,
    EditableDatepicker,
  },
  mixins: [EditPartialMixin],
  props: ["statement"],
  computed: {
    isPeerstreetRepurchaseStatement() {
      return this.statement.statementType === "peerstreet_repurchase";
    },
    enableDefaultDate() {
      if (PSData.useLoanAccrualsForDefaultInterest) {
        return this.isPeerstreetRepurchaseStatement;
      } else {
        return true;
      }
    },
  },
};
</script>
