<template>
  <div class="row">
    <div
      id="new-batch-form"
      v-if="showBatchForm"
      class="col-12"
    >
      <div
        class="col-3"
      >
        <batch-form
            :buyer-programs="buyerPrograms"
            @cancel-move-loans="cancelMoveLoans"
        />
      </div>
    </div>
    <div
      id="overview-tab"
      v-else
      class="col-12"
    >
      <div class="institution-sales-tracking-search-container">
        <search-box @search="search" :offerings="foundOfferings" :is-searching="isSearching">
        </search-box>
      </div>
      <div id="move-loans-button" v-if="canUpdateBatch">
        <button
          class="btn-primary-cta"
          @click="showMoveLoans"
        >
          Move Loans
        </button>
      </div>
      <div class="institution-sales-tracking-section-header">
        <h3>
          Open Batches
        </h3>
      </div>
      <div class="institution-sales-tracking-table">
        <table class="table">
          <thead>
            <tr>
              <th class="buyer-label">Buyer</th>
              <th class="buyer-id">Buyer Batch ID</th>
              <th class="sale-number">Sale No. (Month)</th>
              <th class="loan-offerings-count">Batch Amount (units)</th>
              <th class="date-column">Start Date</th>
              <th class="date-column">Target Offer</th>
              <th class="bus-days-number">Days Left to Offer</th>
              <th class="date-column">Offered / In-Review</th>
              <th class="date-column">Target Close</th>
              <th class="bus-days-number">Days to Close</th>
              <th class="date-column">Wired</th>
              <th class="date-column">Sales Docs Done</th>
            </tr>
          </thead>
          <tbody>
            <batch-table-row
              v-for="batch in batches"
              :key="batch.id"
              :batch="batch"
            >
            </batch-table-row>
          </tbody>
        </table>
      </div>
      <div class="institution-sales-tracking-section-header">
        <h3>
          Monthly Sales Summary
        </h3>
      </div>
      <div class="institution-sales-tracking-table">
        <table class="table">
          <thead>
            <tr>
              <th class="buyer-label">Buyer</th>
              <th class="sale-number amount">Closed Sales</th>
              <th class="amount">Closed Amount</th>
              <th class="amount">Monthly Target</th>
              <th class="amount"><div>Avg. Rate/</div><div>Avg. 3 Mo.</div></th>
              <th class="amount"><div>Avg. LTV/</div><div>Avg. 3 Mo.</div></th>
              <th class="amount"><div>Pass Rate/</div><div>Avg. 3 Mo.</div></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="buyerSummary in buyerSummaries"
              :key="buyerSummary.label"
            >
              <td>{{buyerSummary.label}}</td>
              <td class="amount">{{buyerSummary.monthlyClosedSales}}</td>
              <td class="amount">{{buyerSummary.closedAmount | money}}</td>
              <td class="amount">{{buyerSummary.monthlyTarget | money}}</td>
              <td class="amount">
                <div>
                  {{buyerSummary.averageInvestorRate | percent}}
                </div>
                <div>
                  {{buyerSummary.periodAverageInvestorRate | percent}}
                </div>
              </td>
              <td class="amount">
                <div>
                  {{buyerSummary.averageLtv | percent}}
                </div>
                <div>
                  {{buyerSummary.periodAverageLtv | percent}}
                </div>
              </td>
              <td class="amount">
                <div>
                  {{buyerSummary.averagePassOnRate | percent(0)}}
                </div>
                <div>
                  {{buyerSummary.periodAveragePassOnRate | percent(0)}}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import BatchTableRow from "./batch_table_row";
import SearchBox from "./search_box";
import BatchForm from "./batch_form";

export default {
  components: {
    BatchForm,
    BatchTableRow,
    SearchBox,
  },
  props: {
    batches: Array,
    buyerPrograms: Array,
    buyerSummaries: Array,
    foundOfferings: Array,
    isSearching: Boolean,
    canUpdateBatch: Boolean,
  },
  data() {
    return {
      showBatchForm: false,
    };
  },
  methods: {
    search(text) {
      this.$emit("search", text);
    },
    showMoveLoans() {
      this.showBatchForm = true;
    },
    cancelMoveLoans() {
      this.showBatchForm = false;
    },
  },
};
</script>
