export default class BaseResponse {
  constructor(pricingResponse, scenario, payload, isCustom) {
    this.response = pricingResponse;
    this.scenario = scenario;
    this.custom = isCustom;
    this.loanAmount = payload.total_principal_balance_at_origination;
    this.ltv = payload.ltv;
    this.bestRateLoanAmount = this.response.bestRateLoanAmount;
    this.maxLeverageLoanAmount = this.response.maxLeverageLoanAmount;
  }
}
