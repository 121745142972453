<template>
  <points-or-amount-input
    :label="label"
    :amount-value="unmaskedValue"
    :on-fees-change="onDataChange"
    amount-attribute="amount"
    points-attribute="points"
  />
</template>

<script>
import NumberFormatter from "models/formatters/number_formatter";

export default {
  name: "manage-payoff-statement-waived-input",
  props: {
    label: {
      type: String,
      default: () => "",
    },
    value: {
      type: [String, Number],
      default: () => "0.00",
    },
    amount: {
      type: [String, Number],
      default: () => "0.00",
    },
    dataChange: {
      type: Function,
      required: true,
      default: () => new Function(),
    },
  },
  computed: {
    unmaskedValue() {
      return NumberFormatter.unmaskNumber(this.value || 0).toFixed(2);
    },
    unmaskedAmount() {
      return NumberFormatter.unmaskNumber(this.amount || 0).toFixed(2);
    },
  },
  methods: {
    onDataChange(type, value) {
      if (type === "amount" && value !== null) {
        this.dataChange(NumberFormatter.unmaskNumber(value));
      } else {
        if (value !== null) {
          const amount = this.unmaskedAmount;
          const fraction = NumberFormatter.unmaskNumber(value);
          const newValue = (amount / 100) * fraction;

          this.dataChange(newValue);
        }
      }
    },
  },
};
</script>
