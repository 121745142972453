import Worklist from "components/reports/worklist";
import EditWorklistPolicy from "policies/edit_worklist_policy";

$(() => {
  new Vue({
    el: document.querySelector(".v-worklist-controller"),
    components: { Worklist },
    data: function() {
      return {
        canUpdateWorklistData: true,
      };
    },
    mounted: function() {
      this.loadPolicy();
    },
    methods: {
      loadPolicy() {
        const formPolicy = new EditWorklistPolicy(PSData.worklistPolicy);
        this.canUpdateWorklistData = formPolicy.canUpdateWorklistData();
      },
    },
  });
});
