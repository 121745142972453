<template>
  <view-panel class="loan-details-view-panel">
    <template slot="header">
      Background Review
    </template>
    <div class="row loan-details-view-panel-row">
      <div class="col-6 loan-details-view-panel-column">
        <details-list :data="backgroundData" />
      </div>
    </div>
  </view-panel>
</template>
<script>
import ViewPanel from "components/view_panel.vue";
import DetailsList from "components/loan_details/list";

export default {
  components: {
    ViewPanel,
    DetailsList,
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  computed: {
    backgroundData() {
      return [
        {
          label: "Most Recent Bankruptcy Date",
          value: this.party.formattedBankruptcyDate,
          show: true,
        },
        {
          label: "Most Recent Foreclosure Date",
          value: this.party.formattedForeclosureDate,
          show: true,
        },
      ];
    },
  },
};
</script>
