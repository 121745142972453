import Rule from "models/rule";

import { InitClient, UploadFiles, SaveDocuments } from "models/file/commands/picker_commands";
import { FilePickerRequested } from "models/file/events/picker_events";

class PickerRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case FilePickerRequested:
        return this.whenFilePickerRequested(event);
    }

    return super.check(event);
  }

  whenFilePickerRequested(event) {
    return [
      new InitClient(event, this.state),
      new UploadFiles(event, this.state),
      new SaveDocuments(event, this.state),
    ];
  }
}

PS.Models.File.PickerRule = PickerRule;
