<template>
  <div class="report-value">
    <template v-if="changeable">
      <div class="radio-control radio-control-inline">
        <input
          :disabled="!canEdit"
          :id="inputId"
          type="radio"
          :checked="checked"
          :name="groupName"
          :value="value"
          @click="onChange"
        />
        <label :for="inputId">
          <span class="control-toggle" />
          {{ label }}
        </label>
      </div>
    </template>
    <template v-else="">
      <label>{{ label }}</label>
      <span class="missing-validation" v-if="showMissingValidation">missing validation</span>
    </template>
  </div>
</template>

<script>
const Commands = PS.Models.Diligence.Commands;
var componentCount = 0;

export default Vue.component("diligence-report-value", {
  props: {
    origin: Object,
    element: Object,
    checked: Boolean,
  },
  data: function() {
    return {
      componentId: componentCount++,
      canEdit: undefined,
    };
  },
  computed: {
    changeable: function() {
      return this.isLoanField && this.elementDisparity && (this.valueExists || this.valueValidated);
    },
    isLoanField: function() {
      return this.element.master != undefined;
    },
    elementDisparity: function() {
      return !this.element.matched;
    },
    valueExists: function() {
      return this.value != undefined;
    },
    valueValidated: function() {
      return this.origin.validated;
    },
    label: function() {
      return this.origin.formattedValue();
    },
    value: function() {
      return this.origin.value;
    },
    groupName: function() {
      return `${this.element.documentType}_${this.element.name}`;
    },
    inputId: function() {
      return `${this.groupName}_${this.componentId}`;
    },
    showMissingValidation: function() {
      return !this.isLoanField && this.element.showDiff() && !this.valueValidated;
    },
  },
  methods: {
    onChange: function(event) {
      var value = event.target.value;
      // html default is 'on' when input value is not
      // given, so need to correct for this.
      if (value === "on") {
        value = null;
      }

      new Commands.Report.RequestValueChange(new PS.Models.Event(), {
        value: value,
        elementName: this.element.group,
      }).execute();
    },
  },
  created: function() {
    this.canEdit = new PS.Policies.EditLoanFormPolicy(PSData.loanPolicy).canEdit();
  },
});
</script>
