<template>
  <table v-if="items.length > 0" class="table mb-5 read-to-distribute-table">
    <thead>
      <tr>
        <th colspan="8" class="text-left border-0">
          {{ legalOwner.text }}
        </th>
      </tr>

      <tr>
        <td class="text-right border-top-0 border-bottom" />

        <td class="text-right border-top-0 border-bottom">
          Transactions
        </td>

        <td class="text-right border-top-0 border-bottom">
          Principal
        </td>

        <td class="text-right border-top-0 border-bottom">
          Supplemental Payment
        </td>

        <td class="text-right border-top-0 border-bottom">
          Interest
        </td>

        <td class="text-right border-top-0 border-bottom">
          Default Interest
        </td>

        <td class="text-right border-top-0 border-bottom">
          Deferred Interest
        </td>

        <td class="text-right border-top-0 border-bottom">
          Fees
        </td>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td class="font-weight-bold text-left border-0">
          Totals
        </td>

        <td class="text-right border-0">
          <a
            v-if="hasShow"
            href="#"
            @click.prevent="$emit('show', legalOwner.id)"
          >
            {{ items.length }}
          </a>
          <span v-else>
            {{ items.length }}
          </span>
        </td>

        <td class="text-right border-0">
          {{ principalTotal | accounting }}
        </td>

        <td class="text-right border-0">
          {{ supplementalPaymentTotal | accounting }}
        </td>

        <td class="text-right border-0">
          {{ getTotalForDisbursementTypes(details, ["interest", "prepaid_interest"]) | accounting }}
        </td>

        <td class="text-right border-0">
          {{ defaultInterestTotal | accounting }}
        </td>

        <td class="text-right border-0">
          {{ deferredInterestTotal | accounting }}
        </td>

        <td class="text-right border-0">
          {{ feeTotal | accounting }}
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td colspan="8" class="text-left border-top-0 border-bottom border-dark" />
      </tr>
    </tfoot>
  </table>
</template>

<script>
import has from "lodash/has";
import flatMap from "lodash/flatMap";
import filter from "lodash/filter";
import sumBy from "lodash/sumBy";

import { getTotalForDisbursementTypes } from "../../shared/calculation_helper";

export default {
  props: {
    legalOwner: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasShow() {
      return has(this.$listeners, "show");
    },
    details() {
      return flatMap(this.items, item => item.details);
    },
    principalTotal() {
      return this.getTotal("principal");
    },
    supplementalPaymentTotal() {
      return this.getTotal("supplemental_payment");
    },
    defaultInterestTotal() {
      return this.getTotal("default_interest");
    },
    deferredInterestTotal() {
      return this.getTotal("deferred_interest");
    },
    feeTotal() {
      return this.getTotal("fee");
    },
  },
  methods: {
    getTotalForDisbursementTypes,
    getTotal(type) {
      return sumBy(
        filter(this.details, ({ disbursementType }) => type === disbursementType),
        "revisedAmount"
      );
    },
  },
};
</script>
