<template>
  <div class="templates-group">
    <div class="is-flex is-justify-content-space-between is-align-items-center pb-4">
      <base-header size="medium">
        {{ name }}
      </base-header>
    </div>
    <base-table
      :data="templates"
      class="tasklist-templates-table"
      :row-class="(row, index) => row.activeRevisionNumber && 'published-template'"
      @click="onTemplateClick"
    >
      <base-column
        v-slot="props"
        field="name"
        label="Template name"
      >
        {{ props.row.name }}
        <base-tag :type="props.row.activeRevisionTagType" class="template-revision-tag ml-2">
          {{ props.row.activeRevisionLabel }}
        </base-tag>
      </base-column>
      <base-column
        v-slot="props"
        field="loansCount"
        label="Loans"
        width="100"
      >
        {{ props.row.loansCount }}
      </base-column>
      <base-column
        v-slot="props"
        field="publishedAt"
        label="Published"
        width="250"
      >
        {{ props.row.publishedAtFormatted }}
      </base-column>
      <base-column
        v-if="false"
        v-slot="props"
        label="Revisions"
        cell-class="revisions-cell"
        width="200"
      />
      <template v-slot:empty>
        No templates
      </template>
    </base-table>
  </div>
</template>

<script>
import { tasklistTemplateRevisionRoute } from "models/tasklist_template_revision";

export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    templates: {
      type: Array,
      default: () => [],
    },
    canManage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onTemplateClick(template) {
      if (!template.activeRevisionId) return;

      this.$router.push(tasklistTemplateRevisionRoute(template.id, template.activeRevisionId));
    },
  },
};
</script>
<style scoped>
.tasklist-templates-table >>> .published-template:hover {
  background: var(--color-grey-06);
  cursor: pointer;
}

.templates-group >>> .revisions-cell {
  padding-top: var(--space-unit);
  padding-bottom: var(--space-unit);
}

.template-revision-tag {
  min-width: 40px;
}
</style>
