<template>
  <div v-cloak class="servicing-section">
    <div class="servicing-section-header">
      <div>
        <base-header size="medium">Charge-Offs</base-header>
        <div class="section-subheader" data-test="charge-offs-subheader">
          <span date-test="charge-offs-total">{{ chargeOffsTotal | pluralize("Charge-Off") }}</span>
          <span>&bull;</span>
          <span v-if="hasRequests" data-test="charge-offs-last-updated">Last updated {{ lastUpdatedAt }}</span>
        </div>
      </div>
      <div v-if="canCreateChargeOff">
        <base-dropdown data-test="charge-offs-action-button" type="none" position="left" label="Actions">
          <base-dropdown-item data-test="charge-offs-new-charge-off-button" @click="openNewChargeOff">
            Generate Charge-Off Statement
          </base-dropdown-item>
        </base-dropdown>
      </div>
    </div>
    <div class="charge-off-table">
      <base-table data-test="charge-offs-table" :data="chargeOffStatements" @click="openChargeOffStatementDetails">
        <base-column label="Last Updated" v-slot="{ row }">{{ formatUpdatedAt(row.updatedAt) }}</base-column>
        <base-column label="Effective Date" v-slot="{ row }">{{ row.effectiveDate | formatDate }}</base-column>
        <base-column label="Amount" v-slot="{ row }">{{ row.amount | accounting }}</base-column>
        <base-column label="Status" v-slot="{ row }"><badge :status="row.status"/></base-column>
        <base-column label="Notes" v-slot="{ row }">{{ row.comment }}</base-column>
        <template v-slot:empty>
          No Charge-Offs
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import LocationService from "services/location_service";
import Badge from "./badge";

export default {
  components: {
    Badge,
  },
  methods: {
    openNewChargeOff() {
      LocationService.assign(PSData.routes.chargeOffStatementNew);
    },
    formatUpdatedAt(date) {
      return moment(new Date(date)).format("MM/DD/YYYY hh:mmA");
    },
    openChargeOffStatementDetails(row) {
      LocationService.assign(row.routes.show);
    },
  },
  computed: {
    canCreateChargeOff() {
      return PSData.chargeOffStatementPolicy.create.allowed;
    },
    chargeOffStatements() {
      return PSData.chargeOffStatements;
    },
    hasRequests() {
      return this.chargeOffsTotal > 0;
    },
    chargeOffsTotal() {
      return this.chargeOffStatements.length;
    },
    lastUpdatedAt() {
      const lastUpdatedDate = Math.max(...this.chargeOffStatements.map(r => new Date(r.updatedAt)));
      return moment(lastUpdatedDate).fromNow();
    },
  },
};
</script>
<style scoped>
.servicing-section {
  padding-bottom: calc(var(--space-unit) * 6);
}

.servicing-section-header {
  border-top: 1px solid var(--color-grey-38);
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-unit-lg);
  padding-top: calc(var(--space-unit) * 3);
}

.section-subheader {
  color: var(--color-grey-60);
  font-size: var(--font-size);
  font-weight: 400;
}

.charge-off-table ::v-deep tbody tr:not(.is-empty) {
  cursor: pointer;
  user-select: none;
}

.charge-off-table ::v-deep tbody tr:hover {
  background-color: var(--color-grey-06);
}
</style>
