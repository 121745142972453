import ActivityFeedEventsSection from "components/activity_feed_events/manage/section";
import LoanUsuryViolationAlert from "components/loans/usury_violation_alert";
import LoanUsuryViolationWarning from "components/loans/usury_violation_warning";
import LoanLicensingViolationAlert from "components/loans/licensing_violation_alert";
import NoLenderFollowersAlert from "components/loans/no_lender_followers_alert";
import NoFlyListHitAlert from "components/loans/no_fly_list_hit_alert";
import ClosingRestrictionsAlert from "components/loans/closing_restrictions_alert";
import PrepayPenaltyTermsAlert from "components/loans/prepay_penalty_terms_alert";
import LoanDuplicates from "components/loan_duplicates";
import LoanProgressUpdate from "components/loan_progress_updates/show";
import Form from "models/lender_form/form";
import CreditBoxInlineGuide from "components/credit_box/credit_box_inline_guide.vue";
import CopyToClipboard from "components/copy_to_clipboard";
import BorrowerInfoSection from "components/borrower/info_section";
import AjaxService from "services/ajax_service";
import LoanViewToggle from "components/loans/loan_view_toggle.vue";
import BannerAlert from "components/banners/alert";
import get from "lodash/get";
import every from "lodash/every";
import isEmpty from "lodash/isEmpty";
import SyncToSalesforceModal from "components/loans/sync_to_salesforce_modal.vue";
import PrefillFormstackModal from "components/loans/prefill_formstack_modal.vue";
import DropdownButton from "components/shared/dropdown_button.vue";
import NotificationGroup from "components/shared/notification_group";
import LoanFormMixin from "../../mixins/manage/loans/form";
import LoanSuspensionSuspend from "components/manage/loan_suspension/suspend";
import LoanSuspensionDetails from "components/manage/loan_suspension/details";
import Location from "services/location_service";
import WindowService from "services/window_service";
import LenderTagsMixin from "../../mixins/manage/lenders/tags";
import PricingEvaluation from "components/loans/pricing_evaluation.vue";
import LegalPurchasingAlert from "components/loans/legal_purchasing_alert";
import BrokerTag from "components/tags/broker";
import PiecesOfCollateral from "components/tags/pieces_of_collateral";
import EditBorrowerFormPolicy from "policies/edit_borrower_form_policy";
import ArchivedLoanAlert from "components/loans/archived_loan_alert";
import LoanGuide from "components/loan_guide/index";
import PriceStatus from "components/price_status/price_status";
import EligibleBuyersInput from "components/loans/eligibility_boxes/eligible_buyers_input";
import LoanPeerstreetNotes from "components/loans/loan_peerstreet_notes";
import LpcExplain from "components/lpc_explain";
import groupBy from "lodash/groupBy";
import MidasService from "services/midas_service";
import toPlainObject from "utils/to_plain_object";
import debounce from "utils/debounce";
import LoanStatusTracker from "components/manage/loan_status_tracker";
import LoanRestrictionsService from "services/loan_restrictions_service";
import PropertyDetailsClient from "components/loan_scenarios/api/property_details_client";
import GlobalBusService from "services/global_bus_service";
import LoanProgramsService from "services/loan_programs_service";

$(() => {
  if (!document.querySelector(".v-manage-edit-loan-controller")) {
    return;
  }

  new Vue({
    el: document.querySelector(".v-manage-edit-loan-controller"),
    mixins: [LoanFormMixin, LenderTagsMixin],
    components: {
      "activity-feed-events-section": ActivityFeedEventsSection,
      "loan-usury-violation-alert": LoanUsuryViolationAlert,
      "loan-licensing-violation-alert": LoanLicensingViolationAlert,
      "loan-duplicates": LoanDuplicates,
      "loan-progress-update": LoanProgressUpdate,
      BannerAlert,
      BrokerTag,
      NoLenderFollowersAlert,
      NoFlyListHitAlert,
      ClosingRestrictionsAlert,
      PrepayPenaltyTermsAlert,
      CreditBoxInlineGuide,
      CopyToClipboard,
      BorrowerInfoSection,
      LoanViewToggle,
      SyncToSalesforceModal,
      PrefillFormstackModal,
      DropdownButton,
      NotificationGroup,
      LoanSuspensionSuspend,
      LoanSuspensionDetails,
      PricingEvaluation,
      LegalPurchasingAlert,
      LoanUsuryViolationWarning,
      ArchivedLoanAlert,
      LoanGuide,
      PriceStatus,
      PiecesOfCollateral,
      EligibleBuyersInput,
      LoanPeerstreetNotes,
      LpcExplain,
      LoanStatusTracker,
    },
    data: function() {
      return {
        formstackUrls: PSData.formstackUrls || {},
        loanSalesforceSyncsUrl: PSData.routes.loanSalesforceSyncs,
        loan: PSData.loan || {},
        routes: PSData.routes || {},
        loanDuplicates: PSData.loanDuplicates || [],
        loanWas: Object.assign({}, PSData.loan),
        loanRestrictions: [],
        showEditForm: null,
        showActionDropdown: null,
        showEditButton: null,
        showSyncToSalesforceModal: false,
        showPrefillFormstackModal: false,
        showSuspendModal: false,
        usuryRules: PSData.usuryRules || [],
        licensingResults: PSData.licensingResults || [],
        form: new Form(PSData.loan || {}),
        creditBoxResults: [],
        isLoadingCreditbox: false,
        hasErrorsLoadingCreditbox: false,
        programOptions: [],
        isLoadingOptions: false,
        processingReminders: PSData.processingReminders,
        riskAnalysisDateDisabled: null,
        updateSubservicerDisabled: null,
        distributeToLenderDisabled: null,
        distributeToInvestorDisabled: null,
        loanEditingDisabled: null,
        canEditPeerStreetNotes: false,
        lenderBlockStatus: get(PSData, "lender.lastBlockStatus"),
        legalPurchasingResults: PSData.legalPurchasingResults || [],
        showNoLenderFollowersAlert: false,
        showNoFlyListHitAlert: false,
        showClosingRestrictionsAlert: false,
        lenderSpreadDisabled: null,
        psSpreadDisabled: null,
        completedPercentage: null,
        editNotes: false,
        peerstreetNotes: (PSData.loan || {}).peerstreetNotes,
        showSyncDocGen: null,
        viewLpsResults: false,
        zipHasChanged: false,
        loanTasks: PSData.tasks || [],
        propertyDetailsClient: new PropertyDetailsClient(),
        showPrepayPenaltyTermsAlert: false,
      };
    },
    watch: {
      isFundingIntoOrigination(isFIO, previouslyFIO) {
        if (!isFIO && previouslyFIO) {
          this.loan.lenderEntityLegalEntityId = "";
        }
      },
      showCema(curentCema) {
        if (!curentCema) {
          this.loan.cema = "";
        }
      },
      "loan.programType": {
        handler(newValue, oldValue) {
          if (newValue !== oldValue) {
            this.loan.loanProgramId = "";
          }
        },
      },
      "loan.zip": {
        handler(newValue, previousValue) {
          this.zipHasChanged = previousValue !== newValue;
          this.requestPropertyDetails();
          this.requestMedianHomePriceInZip();
        },
      },
      "loan.address"() {
        this.requestPropertyDetails();
      },
      "loan.city"() {
        this.requestPropertyDetails();
      },
      "loan.state"() {
        this.requestPropertyDetails();
      },
    },
    computed: {
      pricingResults() {
        let results = PSData.pricingResults || [];
        let loan = PSData.loan || {};

        if (loan.estimatedPsSpread !== loan.psSpread) {
          results.push({
            explanation: "PeerStreet Spread is not equal to Estimated PeerStreet Spread",
            type: "validation",
          });
        }

        if (loan.estimatedPurchaseRate !== loan.purchaseRate) {
          results.push({
            explanation: "Purchase Rate is not equal to Estimated Purchase Rate",
            type: "validation",
          });
        }

        return results;
      },
      propertyAddress() {
        const property = this.loan.loanProperties[0];

        if (
          property &&
          every([property.street, property.city, property.state, property.postal_code])
        ) {
          return `${property.street}, ${property.city}, ${property.state} ${property.postal_code}`;
        } else {
          return "";
        }
      },
      isAcquisition() {
        return this.loan.loanPurpose === "acquisition";
      },
      isFundingIntoOrigination() {
        return this.loan.lenderNoteStatus === "funding_into_origination";
      },
      groupedUsuryViolations() {
        return groupBy(this.usuryRules.filter(rule => !rule.fit), "severity");
      },
      usuryErrors() {
        return this.groupedUsuryViolations.error || [];
      },
      usuryWarnings() {
        return this.groupedUsuryViolations.warning || [];
      },
      showCema() {
        return this.loan.state === "NY";
      },
      showProgramOptions() {
        return this.loan.underwritingStatus === "draft";
      },
      showCreditbox() {
        return this.loan.showCreditbox;
      },
      isResiBridgeProgramType() {
        return this.loan.programType === "residential_bridge";
      },
      isRentalProgramType() {
        return this.loan.programType === "residential_rental_long_term";
      },
      isCommercialMultifamilyProgramType() {
        return this.loan.programType === "commercial_multifamily";
      },
      isExtendedBridgeProgramType() {
        return this.loan.programType === "extended_bridge";
      },
      isEmptyProgramType() {
        return (
          !this.isResiBridgeProgramType &&
          !this.isRentalProgramType &&
          !this.isCommercialMultifamilyProgramType
        );
      },
      loanRestrictionsService() {
        return new LoanRestrictionsService(this.routes.loanRestrictions);
      },
    },
    methods: {
      loadPolicy() {
        const formPolicy = new PS.Policies.EditLoanFormPolicy(PSData.loanPolicy);
        const borrowerPolicy = new EditBorrowerFormPolicy(PSData.borrowerPolicy);
        this.showEditForm = formPolicy.canUpdateLoanForm();
        this.showActionDropdown = formPolicy.canShowActionDropdown();
        this.showEditButton = formPolicy.canEditLoanForm();
        this.riskAnalysisDateDisabled = !formPolicy.canUpdateRiskAnalysisDate();
        this.updateSubservicerDisabled = !formPolicy.canUpdateSubservicer();
        this.loanEditingDisabled = !formPolicy.canUpdateLoanData();
        this.canEditPeerStreetNotes = formPolicy.canEditPeerStreetNotes();
        this.lenderSpreadDisabled = !formPolicy.canUpdateLenderSpread();
        this.psSpreadDisabled = !formPolicy.canUpdatePsSpread();
        this.distributeToLenderDisabled = !formPolicy.canUpdateDistributeToLender();
        this.distributeToInvestorDisabled = !formPolicy.canUpdateDistributeToInvestor();
        this.showSyncDocGen = formPolicy.canSyncDocGen();
        this.viewLpsResults = formPolicy.canViewLpsResults();
      },
      togglePrefillFormstackModal() {
        this.showPrefillFormstackModal = !this.showPrefillFormstackModal;
      },
      toggleSyncToSalesforceModal() {
        this.showSyncToSalesforceModal = !this.showSyncToSalesforceModal;
      },
      toggleSuspendModal() {
        this.showSuspendModal = !this.showSuspendModal;
      },
      edit: function() {
        this.showEditForm = true;
      },
      cancel: function() {
        if (PSData.formErrors && !!Object.keys(PSData.formErrors).length) {
          WindowService.history.replaceState(null, null, Location.href);
          Location.reload();
        }
        this.showEditForm = false;
        Object.assign(this.loan, this.loanWas);
      },
      loanFollowersLoaded(data) {
        this.showNoLenderFollowersAlert = isEmpty(data.lenderFollowers);
      },
      loanRestrictionsLoaded() {
        this.showNoFlyListHitAlert = !isEmpty(this.loanRestrictions);
      },
      anyClosingRestriction() {
        this.showClosingRestrictionsAlert =
          PSData.lenderTags.some(tag => tag.name == "Closing Restriction") &&
          ["funded", "not_funded"].includes(PSData.loan.lenderNoteStatus);
      },
      getCompletedPercentage() {
        this.form.update();
        this.completedPercentage = this.form.completedPercentage;
      },
      async getCreditboxResults() {
        if (this.isLoadingCreditbox) {
          return;
        }

        const url = this.routes.loanCreditbox;
        this.isLoadingCreditbox = true;

        try {
          const result = await AjaxService.getJSON(url);
          this.lpsEvaluationId = result.id;
          this.creditBoxResults = result.creditbox || [];
          this.hasErrorsLoadingCreditbox = false;
        } catch (e) {
          this.hasErrorsLoadingCreditbox = true;
        } finally {
          this.isLoadingCreditbox = false;
        }
      },
      requestMedianHomePriceInZip: debounce(async function() {
        await this.getMedianHomePriceInZip();
      }, 350),
      async getMedianHomePriceInZip() {
        if (!this.showEditForm) return;

        const isMedianHomePriceSet = !!this.loan.medianHomePriceInZip;
        const needsMedianHomePriceSet = ["draft", "evaluating", "qualified"].includes(
          this.loan.underwritingStatus
        );
        const zipHasChanged = this.zipHasChanged;

        if (!needsMedianHomePriceSet || (!zipHasChanged && isMedianHomePriceSet)) {
          return;
        }

        let response, payload;

        try {
          payload = { zip: this.loan.zip };
          response = await MidasService.zipMedianHomePrice(payload.zip);
          this.loan.medianHomePriceInZip = response.data.zipMedianHomePrice?.value;
        } catch (e) {
          Bugsnag.notify(new Error("Unable to get Median Home Price for ZIP Code from Midas"), {
            metaData: { custom: { response: toPlainObject(response.data), payload } },
          });
        }
      },
      requestPropertyDetails: debounce(async function() {
        await this.getPropertyDetails();
      }, 350),
      async getPropertyDetails() {
        const { zip: zipCode, city, address, state } = this.loan;
        const result = await this.propertyDetailsClient.fetchDetails({
          address,
          city,
          state,
          zipCode,
        });
        this.loan.propertyCbsaType = result.cbsaType;
        this.loan.propertyCbsaCode = result.cbsaCode;
        this.loan.propertyCbsaName = result.cbsaName;
      },
      async getProgramOptions() {
        if (this.isLoadingOptions) {
          return;
        }

        const url = this.routes.loanOptions;
        this.isLoadingOptions = true;

        try {
          const result = await AjaxService.getJSON(url);
          this.lpsEvaluationId = result.id;
          this.programOptions = result.options;
          this.hasErrorsLoadingCreditbox = false;
        } catch (e) {
          this.hasErrorsLoadingCreditbox = true;
        } finally {
          this.isLoadingOptions = false;
        }
      },
      lpsExplain(context) {
        LoanProgramsService.explainEvaluationResults(this.lpsEvaluationId, context);
      },
      async syncDocGen() {
        const messages = {
          onSuccess: "DocGen sync successful.",
          onError: ({ data }) => {
            return { message: data.error, status: "error" };
          },
        };
        const url = this.routes.docGenSync;

        AjaxService.withNotifications(messages).post(url);
      },
      loadLoanRestrictions() {
        this.loanRestrictionsService.load(this).then(() => {
          this.loanRestrictionsLoaded();
        });
      },
      checkPrepayPenalty() {
        this.showPrepayPenaltyTermsAlert =
          (!!this.isRentalProgramType || !!this.isExtendedBridgeProgramType) &&
          `${this.loan.prePayPenalty}` === "false";
      },
    },
    async mounted() {
      GlobalBusService.$on("lps-explain", this.lpsExplain);

      this.anyClosingRestriction();
      this.loadPolicy();
      this.getCompletedPercentage();
      await this.getMedianHomePriceInZip();
      if (this.showProgramOptions) {
        await this.getProgramOptions();
      } else if (this.showCreditbox) {
        await this.getCreditboxResults();
      }
      this.loadLoanRestrictions();
      this.checkPrepayPenalty();
    },
  });
});
