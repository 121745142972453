<template>
  <div class="credit-box-guide credit-box-section">
    <h3>Credit Box Advisor</h3>

    <div>
      <p>
        Complete all fields in Property, Loan, and Borrower sections to generate
        a credit box evaluation.
      </p>

      <div class="credit-box-progress">
        <progress max="100" :value="completedPercentage" :class="{complete: isComplete}"></progress>
        <span>
          {{completedPercentage | percent(0)}} Ready
        </span>
      </div>

      <div v-if="showFailure">
        <div class="credit-box-failure">
          <p>
            Credit Box feedback is below.
            You’re ready to finish and submit.
          </p>
        </div>
      </div>

      <div v-if="showSuccess">
        <credit-box-success />

        <p>
          Nice work!
          This loan appears to fit our Credit Box with no issues.
          Once you submit the loan, our team will begin our underwriting process.
        </p>
      </div>
    </div>

    <div class="credit-box-rules-issues">
      <credit-box-info :credit-box-results="creditBoxResults" :new-loan-form="true" :view-lps-results="viewLpsResults" />

      <input
        type="button"
        class="btn-action-cta credit-box-submit-button"
        :value="submitCaption"
        :disabled="uiBlocked"
        @click="onSubmit"
      >

      <span class="credit-box-note">
        We will save your draft.
      </span>
    </div>

    <credit-box-guidelines :program-classification="programClassification" />
  </div>
</template>

<script>
import { prepareRules, hasIssues } from "models/credit_box/credit_box_rules";
import CreditBoxGuidelines from "components/credit_box/credit_box_guidelines.vue";
import CreditBoxInfo from "components/credit_box/credit_box_info.vue";
import CreditBoxSuccess from "components/credit_box/credit_box_success.vue";
import LocationService from "services/location_service";

export default {
  name: "credit-box-sidebar-guide",
  components: {
    CreditBoxGuidelines,
    CreditBoxInfo,
    CreditBoxSuccess,
  },
  props: {
    completedPercentage: Number,
    viewLpsResults: Boolean,
    creditBoxResults: Array,
    submitCaption: String,
    onSubmit: Function,
    uiBlocked: Boolean,
    programClassification: String,
    isChanged: Boolean,
  },
  computed: {
    isComplete() {
      return this.completedPercentage === 100;
    },
    results() {
      return prepareRules(this.creditBoxResults);
    },
    hasIssues() {
      return hasIssues(this.results);
    },
    newLoan() {
      return LocationService.pathname === "/loans/new";
    },
    showSuccess() {
      return !this.newLoan && !this.hasIssues && this.isComplete && !this.isChanged;
    },
    showFailure() {
      return !this.newLoan && this.hasIssues && this.isComplete;
    },
  },
};
</script>
