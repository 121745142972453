import moment from "moment";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DATE_FORMAT = "MM/DD/YYYY";

export default class DateService {
  static currentMonthName(date) {
    return MONTHS[date.getMonth()];
  }

  static formatDate(date) {
    return date ? moment(date).format(DATE_FORMAT) : null;
  }
}

PS.Services.DateService = DateService;
