import CopyToClipboard from "components/copy_to_clipboard";
import LenderTagsMixin from "../../mixins/manage/lenders/tags";
import PiecesOfCollateral from "components/tags/pieces_of_collateral";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-loan-header-controller"),
    components: {
      CopyToClipboard,
      PiecesOfCollateral,
    },
    mixins: [LenderTagsMixin],
    data: {
      loan: PSData.loan || {},
    },
  });
});
