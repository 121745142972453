<template>
  <ValidationProvider>
    <base-modal-form
      :show="show"
      title="Create Loan Paid Off Date"
      :save-disabled="isSaving"
      :show-close="showClose"
      @close="close"
      @save="save"
    >
      <div class="pb-3">
        <base-date-field
          v-model="date"
          :min-date="loanPurchasedDate"
          :max-date="today"
          :errors="errors.paid_off_date"
        />
      </div>
    </base-modal-form>
  </ValidationProvider>
</template>

<script>
import Ajax, { ERROR_STATUS, ERROR_MESSAGE } from "services/ajax_service";
import { ValidationProvider } from "vee-validate";
export default {
  components: { ValidationProvider },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      date: "",
      fieldValue: "",
      isSaving: false,
      showClose: true,
      loanPurchasedDate: PSData.loan.initialPurchaseDate,
      today: new Date(),
      errors: [],
    };
  },
  methods: {
    close() {
      this.date = "";
      this.errors = [];
      this.$emit("close");
    },
    async save() {
      this.isSaving = true;
      try {
        await Ajax.postJSON(PSData.routes.processPayoff, { paid_off_date: this.date });
        location.reload();
      } catch ({ data }) {
        if (data && data.errors) {
          this.errors = data.errors;
        } else {
          Ajax.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
        }
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style scoped>
.base-date-field {
  width: 150px;
}
</style>
