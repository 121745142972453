<template>
  <div class="report-element" :class="showDiff()">
    <div class="row">
      <div class="col-12">
        <span class="report-unmatched">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        <h4>{{ element.fieldName }}:</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-6 report-diff-label">
        <p class="linked" @click="showRelatedDocument(element)">
          {{ element.label }}
          <span
            v-if="element.note"
            class="report-note"
            data-placement="bottom"
            data-toggle="tooltip"
            :data-original-title="element.note"
          />
        </p>
      </div>
      <div class="col-6">
        <diligence-report-value :element="element" :origin="element" />
      </div>
    </div>
    <template v-if="element.showDiff()">
      <div v-for="diff in element.diff" :key="diff.id" class="row">
        <div class="col-6 report-diff-label">
          <p class="linked" @click="showRelatedDocument(diff)">
            {{ diff.label }}
            <span
              v-if="diff.note"
              class="report-note"
              data-placement="bottom"
              data-toggle="tooltip"
              :data-original-title="diff.note"
            />
          </p>
        </div>
        <div class="col-6">
          <diligence-report-value :element="element" :origin="diff" />
        </div>
      </div>
    </template>
    <div v-if="element.showMaster()" class="row">
      <div class="col-6 report-master-label">
        <p>{{ element.master.label }}</p>
      </div>
      <div class="col-6">
        <diligence-report-value checked :element="element" :origin="element.master" />
      </div>
    </div>
  </div>
</template>

<script>
const Commands = PS.Models.Diligence.Commands;

export default Vue.component("diligence-report-element", {
  props: {
    element: Object,
  },
  mounted: function() {
    Vue.nextTick(() => {
      $(".report-note", this.$el).tooltip();
    });
  },
  methods: {
    showDiff: function() {
      if (this.element.showDiff() || this.element.showMaster()) {
        return "diff";
      }
    },
    showRelatedDocument: function(element) {
      new Commands.Document.RequestSelectDocument(new PS.Models.Event(), {
        documentType: { name: element.documentType },
      }).execute();
    },
  },
});
</script>
