<template>
  <base-field
    :label="label"
    class="base-editor"
  >
    <div class="base-editor-wrapper">
      <editor-menu-bar
        v-if="!disabled"
        v-slot="{ commands, isActive, getMarkAttrs }"
        :editor="editor"
      >
        <div class="base-editor-menubar">
          <base-button
            type="icon"
            icon="bold"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          />
          <base-button
            type="icon"
            icon="italic"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          />
          <base-button
            type="icon"
            icon="strikethrough"
            :class="{ 'is-active': isActive.strike() }"
            @click="commands.strike"
          />
          <base-button
            type="icon"
            icon="underline"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          />
          <base-button
            type="icon"
            icon="list-ul"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          />
          <base-button
            type="icon"
            icon="list-ol"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
          />
          <base-dropdown ref="linkDropdown" class="base-editor-link" position="left">
            <template v-slot:trigger>
              <base-button
                type="icon"
                icon="link"
                :class="{ 'is-active': isActive.link() }"
                @click="showLinkMenu(getMarkAttrs('link'))"
              />
            </template>
            <base-dropdown-item
              aria-role="menu-item"
              :focusable="false"
              custom
              paddingless
            >
              <base-group class="px-2 base-editor-link-form">
                <base-group-control>
                  <base-text-field ref="linkField" v-model="linkUrl" placeholder="https://" class="p-0" />
                </base-group-control>
                <base-group-control>
                  <base-button class="base-editor-link-action" :disabled="!linkUrl" @click="setLinkUrl(commands.link, linkUrl)">
                    <base-icon icon="link" />
                  </base-button>
                </base-group-control>
                <base-group-control>
                  <base-button class="base-editor-link-action" @click="setLinkUrl(commands.link, null)">
                    <base-icon icon="unlink" />
                  </base-button>
                </base-group-control>
              </base-group>
            </base-dropdown-item>
          </base-dropdown>
          <base-dropdown class="m-0" position="right">
            <template v-slot:trigger>
              <base-button type="text" label="{ }" class="base-editor-params-button is-icon" />
            </template>
            <template v-for="suggestion in suggestions">
              <base-dropdown-item
                :key="suggestion.id"
                @click="commands.mention({ id: suggestion.id, label: `%{${suggestion.id}}` })"
              >
                {{ suggestion.label }}
              </base-dropdown-item>
            </template>
          </base-dropdown>
          <base-button
            type="icon"
            icon="undo"
            @click="commands.undo"
          />
          <base-button
            type="icon"
            icon="redo"
            @click="commands.redo"
          />
        </div>
      </editor-menu-bar>
      <editor-content :editor="editor" class="base-editor-content content" />
    </div>
  </base-field>
</template>
<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  HardBreak,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Mention,
  Placeholder,
} from "tiptap-extensions";

export default {
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      suggestions: [
        {
          id: "loan_id",
          label: "Loan Id",
        },
        {
          id: "url",
          label: "DocGen URL",
        },
        {
          id: "doc_gen_landing",
          label: "DocGen Lending URL",
        },
        {
          id: "doc_gen_new",
          label: "DocGen URL for new loan",
        },
      ],
      editor: new Editor({
        editable: !this.disabled,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new HardBreak(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Mention({
            matcher: {
              char: "",
              allowSpaces: false,
              startOfLine: false,
            },
          }),
          new Placeholder({
            emptyEditorClass: "base-editor-empty",
            emptyNodeText: this.placeholder,
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
          }),
        ],
        content: this.value,
        onUpdate: editor => {
          this.$emit("input", editor.getHTML());
        },
      }),
      linkUrl: null,
    };
  },
  watch: {
    disabled() {
      this.editor.setOptions({
        editable: !this.disabled,
      });
    },
    value() {
      if (this.value !== this.editor.getHTML()) {
        this.editor.setContent(this.value);
      }
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.$nextTick(() => {
        this.$refs.linkField.$refs.input.focus();
      });
    },
    setLinkUrl(command, url) {
      command({ href: url });
      this.linkUrl = null;
      this.$refs.linkDropdown.$children[0].toggle();
    },
  },
};
</script>
<style scoped>
.base-editor-content {
  padding: calc(var(--space-unit) * 1.5);
}

.base-editor-content >>> [contenteditable]:focus {
  outline: none;
}

.base-editor >>> p.base-editor-empty:first-child::before {
  color: var(--color-grey-38);
  content: attr(data-empty-text);
  float: left;
  font-style: italic;
  height: 0;
  pointer-events: none;
}

.base-editor-wrapper {
  border: 1px solid var(--color-grey-16);
  border-radius: var(--border-radius);
}

.base-editor-menubar {
  border-bottom: 1px solid var(--color-grey-16);
  padding: calc(var(--space-unit) / 4);
}

.base-editor-link-form .button.base-editor-link-action {
  height: 100%;
}

.base-editor .base-editor-params-button span {
  font-weight: 700;
  position: relative;
  top: -1px;
}
</style>
