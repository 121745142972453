export default class PropertyValuation {
  constructor(valuation) {
    this.id = valuation.id;
    this.raw = valuation;
    this.reset();
  }

  static addressDateSort(a, b) {
    if (a.propertyId > b.propertyId) return 1;
    if (a.propertyId < b.propertyId) return -1;

    let date_a = a.date ? new Date(a.date).getTime() : Infinity;
    let date_b = b.date ? new Date(b.date).getTime() : Infinity;

    return date_a - date_b;
  }

  reset() {
    this.valuationMethod = this.raw.valuationMethod;
    this.appraiser = this.raw.appraiser;
    this.partyCompletingValuation = this.raw.partyCompletingValuation;
    this.appraiserName = this.raw.appraiserName;
    this.appraiserCompany = this.raw.appraiserCompany;
    this.apiAppraiser = this.raw.apiAppraiser;
    this.commissioner = this.raw.commissioner;
    this.propertyId = this.raw.propertyId;

    this.date = window.PS.Models.DateFormatter.formatDate(this.raw.date);
    this.orderedDate = window.PS.Models.DateFormatter.formatDate(this.raw.orderedDate);
    this.asIsAmount = this.raw.asIsAmount;
    this.arvAmount = this.raw.arvAmount;
    this.deferredMaintenanceAmount = this.raw.deferredMaintenanceAmount;
    this.note = this.raw.note;
    this.designatable = this.raw.designatable;
    this.readonly = this.raw.readonly;
    this.reportType = this.raw.reportType;
    this.licenseNumber = this.raw.licenseNumber;
    this.licenseState = this.raw.licenseState;
    this.monthlyMarketRent = this.raw.monthlyMarketRent;
    this.reportTypeText = this.raw.reportTypeText || "Valuation";

    this.editing = false;
    this.lenderSubmitted = this.raw.lenderSubmitted;
    this.resetDesignations();
  }

  resetDesignations() {
    this.designated = this.raw.designated;
    this.designatedArv = this.raw.designatedArv;
    this.designatedDeferredMaintenance = this.raw.designatedDeferredMaintenance;
  }

  unDesignate() {
    this.designated = false;
  }

  unDesignateArv() {
    this.designatedArv = false;
  }

  unDesignateDeferredMaintenance() {
    this.designatedDeferredMaintenance = false;
  }

  designate() {
    this.designated = true;
  }

  designateArv() {
    this.designatedArv = true;
  }

  designateDeferredMaintenance() {
    this.designatedDeferredMaintenance = true;
  }

  update(valuation) {
    this.raw = valuation;
    this.reset();
  }

  canDesignate() {
    return this.designatable;
  }

  canEdit() {
    return !this.readonly;
  }

  canDelete() {
    return !(
      this.readonly ||
      this.designated ||
      this.designatedArv ||
      this.designatedDeferredMaintenance
    );
  }

  isRequiredAttributesPresent() {
    return Boolean(this.appraiser && this.date && this.valuationMethod);
  }

  isPeerStreetCommissioner() {
    return this.commissioner == "peerstreet";
  }

  isLenderCommissioner() {
    return this.commissioner == "lender";
  }

  needReload() {
    return (
      this.designated ||
      this.designatedArv ||
      this.designatedDeferredMaintenance ||
      this.isLenderCommissioner()
    );
  }

  toHash() {
    return {
      date: this.date,
      as_is_amount: this.asIsAmount || 0,
      arv_amount: this.arvAmount || 0,
      appraiser: this.appraiser,
      appraiser_name: this.appraiserName,
      appraiser_company: this.appraiserCompany,
      deferred_maintenance_amount: this.deferredMaintenanceAmount || 0,
      valuation_method: this.valuationMethod,
      appraiser: this.appraiser,
      commissioner: this.commissioner,
      note: this.note,
      designated: this.designated,
      designated_arv: this.designatedArv,
      designated_deferred_maintenance: this.designatedDeferredMaintenance,
      license_number: this.licenseNumber,
      license_state: this.licenseState,
      monthly_market_rent: this.monthlyMarketRent,
      property_id: this.propertyId,
      report_type: this.reportType,
    };
  }
}
