import DDAsyncCheck from "models/diligence_document/dd_async_check";
import SyncFormParams from "models/diligence_document/sync_form_params";

const Command = PS.Models.Command;
const Event = PS.Models.Event;
const Rule = PS.Models.Rule;
const Commands = PS.Models.Diligence.Commands;
const Events = PS.Models.Diligence.Events;

import DocumentUpload from "models/diligence_document/document_upload";

class StartLoading extends Command {
  execute() {
    this.state.formLoading = true;
    this.state.syncErrors = undefined;
    this.state.form = undefined;

    return Promise.resolve();
  }
}

class EndLoading extends Command {
  execute() {
    this.state.formLoading = false;

    return Promise.resolve();
  }
}

class LoadFromServer extends Command {
  execute() {
    const url = `/manage/loans/${this.state.loan.toParam}/diligence/${
      this.event.documentType.name
    }`;

    return new Promise((resolve, reject) => {
      DDAsyncCheck.increment();
      const sameDocument = DDAsyncCheck.generate();

      PS.Services.AjaxService.getJSON(url).then(documentData => {
        if (sameDocument()) {
          this.state.form = new PS.Models.Form(documentData.document);
          resolve();
        } else {
          reject();
        }
      });
    });
  }
}

class LoadUploadStatuses extends Command {
  execute() {
    this.state.documentUploads = [];
    const url = `/manage/document_reader_uploads/?document_type=${
      this.event.documentType.name
    }&loan_id=${this.state.loan.toParam}`;

    return new Promise((resolve, reject) => {
      const sameDocument = DDAsyncCheck.generate();

      PS.Services.AjaxService.getJSON(url).then(uploadData => {
        if (sameDocument()) {
          this.state.documentUploads = uploadData.map(data => new DocumentUpload(data));
          resolve();
        } else {
          reject();
        }
      });
    });
  }
}

class BroadcastDocumentUpdate extends Command {
  execute() {
    return Command.broadcastEvent(Events.Form.Updated, PS.Services.MessageBusService.diligenceBus, {
      form: this.state.form,
    });
  }
}

class RemoveAllSimilarFieldsets extends Command {
  execute() {
    let fieldsets = this.state.form.fields.filter(field => {
      return field.isFieldset() && field.name == this.event.fieldset.name;
    });

    let commands = fieldsets
      .map(fieldset => {
        return new Commands.Fieldset.RequestFieldsetDeletion(new Event(), { fieldset: fieldset });
      })
      .reverse();

    return Rule.executeAll(commands);
  }
}

class RearrangeFields extends Command {
  execute() {
    let anchorElements = this.state.form.fields.filter(field => {
      return field.name == this.event.insertAfter;
    });

    let lastAnchorElement = anchorElements[anchorElements.length - 1];
    let insertAfterPosition = this.state.form.fields.indexOf(lastAnchorElement);

    let fieldsToMove = this.state.form.fields.filter(field => {
      return field.name == this.event.name;
    });

    fieldsToMove.forEach(field => {
      Vue.delete(this.state.form.fields, this.state.form.fields.indexOf(field));
    });

    this.state.form.fields.splice(insertAfterPosition + 1, 0, ...fieldsToMove);

    return Promise.resolve();
  }
}

class ShowOrHideFields extends Command {
  toggleFieldsVisibility(isVisible) {
    this.fields.forEach(field => {
      field.hidden = !isVisible;
    });
  }

  get fields() {
    return this.state.form.fields.filter(field => this.event.fields.includes(field.name));
  }
}

class ShowFields extends ShowOrHideFields {
  execute() {
    this.toggleFieldsVisibility(true);
    return Promise.resolve();
  }
}

class HideFields extends ShowOrHideFields {
  execute() {
    this.toggleFieldsVisibility(false);
    return Promise.resolve();
  }
}

class ClearUploadNotification extends Command {
  execute() {
    this.state.documentUploads = [];
    const url = `/manage/document_reader_uploads/${this.event.uploadId}/dismiss_notification`;

    return new Promise(resolve => {
      PS.Services.AjaxService.patch(url).then(() => {
        resolve();
      });
    });
  }
}

class SyncForm extends Command {
  execute() {
    this.state.formDisabled = true;
    const params = SyncFormParams.prepare(this.state);
    const url = `/manage/diligence_documents/${this.state.form.document.id}/sync`;
    this.state.syncErrors = undefined;

    return new Promise(resolve => {
      PS.Services.AjaxService.withNotifications({ onSuccess: "Successfully Synced." })
        .patch(url, params)
        .then(() => {
          resolve();
        })
        .catch(({ data }) => {
          this.state.syncErrors = data.error;
        })
        .finally(() => {
          this.state.formDisabled = false;
        });
    });
  }
}

class SetUpFormState extends Command {
  execute() {
    const commands = [];

    switch (this.event.documentType.name) {
      case "note":
        commands.push(...this.showOrHideJuniorLienFields());
        commands.push(...this.disableNoteFields());
        break;
      case "appraisal_or_bpo":
        commands.push(
          ...this.toggleZoningConfirmation({
            fieldsetName: "valuation",
            fieldName: "lender_zoning",
            confirmationFieldName: "lender_zoning_confirmation",
          })
        );
        break;
      case "environmental":
        commands.push(...this.disableEnvIssuesDetails(), ...this.disableHarmfulSubstanceDetails());
        break;
      case "loan_application":
        commands.push(...this.toogleBankruptcyForeclosureShortSaleDates());
        break;
    }

    return Rule.executeAll(commands);
  }

  showOrHideJuniorLienFields() {
    const fields = this.state.form.fields;
    const juniorLienFieldset = fields.find(fieldset => fieldset.name === "junior_liens");

    if (!juniorLienFieldset) return [];
    const juniorLien = juniorLienFieldset.fields.find(field => field.name === "junior_lien");

    if (!juniorLien || juniorLien.value === "1") return [];

    return [
      new Commands.Form.HideFields(
        new Events.Form.HideFieldsRequested({
          fields: ["junior_lien_total", "junior_lien_holders"],
        }),
        this.state
      ),
      new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
        field: { name: "promissory_note" },
      }),
      new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
        field: { name: "recorded_agreement" },
      }),
      new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
        field: { name: "inter_creditor_agreement" },
      }),
    ];
  }

  disableEnvIssuesDetails() {
    const fields = this.state.form.fields;
    const envIssuesIdentified = fields.find(
      field => field.name === "environmental_issues_identified"
    );

    if (envIssuesIdentified.value !== "1") {
      return [
        new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
          field: { name: "environmental_issues_resolved" },
        }),
      ];
    }

    return [];
  }

  disableHarmfulSubstanceDetails() {
    const fields = this.state.form.fields;
    const harmfulSubstance = fields.find(field => field.name === "harmful_substances");

    if (harmfulSubstance && harmfulSubstance.value !== "1") {
      return [
        new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
          field: { name: "condition_details" },
        }),
        new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
          field: { name: "operations_maintenance_program" },
        }),
      ];
    }

    return [];
  }

  disableNoteFields() {
    const fields = this.state.form.fields;
    const paymentDetails = fields.find(field => field.name === "payment_details");

    switch (true) {
      case !!paymentDetails: {
        const prepayPenalty = paymentDetails.fields.find(
          field => field.name === "pre_pay_penalty_y_n"
        );

        if (prepayPenalty) {
          return [
            new Commands.Field.RequestToggleDisabledFields(new Event(), { field: prepayPenalty }),
          ];
        }
      }
      default: {
        return [];
      }
    }
  }

  toggleZoningConfirmation({ fieldsetName, fieldName, confirmationFieldName }) {
    const fieldsets = this.state.form.fields.filter(field => {
      return field.isFieldset() && field.name === fieldsetName;
    });

    return fieldsets.map(valuationFieldset => {
      const zoning = valuationFieldset.fields.find(field => field.name === fieldName);
      if (zoning.isEmpty()) {
        return new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
          field: { name: confirmationFieldName },
          fieldset: valuationFieldset,
        });
      } else {
        return new Commands.Field.RequestFieldEnable(new Events.Field.FieldEnableRequested(), {
          field: { name: confirmationFieldName },
          fieldset: valuationFieldset,
        });
      }
    });
  }

  toogleBankruptcyForeclosureShortSaleDates() {
    const fieldsets = this.state.form.fields.filter(field => {
      return field.isFieldset() && field.name === "borrower_details";
    });

    return [].concat.apply(
      [],
      fieldsets.map(borrowerFieldset => {
        const bankruptcyOther = borrowerFieldset.fields.find(
          field => field.name === "bankruptcy_other"
        );
        const fields = [
          {
            field: { name: "bankruptcy_date" },
            fieldset: borrowerFieldset,
          },
          {
            field: { name: "foreclosure_date" },
            fieldset: borrowerFieldset,
          },
          {
            field: { name: "short_sale_date" },
            fieldset: borrowerFieldset,
          },
        ];
        const disable = bankruptcyOther.value !== "1";
        const command = disable
          ? Commands.Field.RequestFieldDisable
          : Commands.Field.RequestFieldEnable;
        const event = disable
          ? Events.Field.FieldDisableRequested
          : Events.Field.FieldEnableRequested;

        return fields.map(field => new command(new event(), field));
      })
    );
  }
}

PS.Models.Diligence.Commands.Form = {
  StartLoading: StartLoading,
  EndLoading: EndLoading,
  LoadFromServer: LoadFromServer,
  LoadUploadStatuses: LoadUploadStatuses,
  BroadcastDocumentUpdate: BroadcastDocumentUpdate,
  RemoveAllSimilarFieldsets: RemoveAllSimilarFieldsets,
  RearrangeFields: RearrangeFields,
  ShowFields: ShowFields,
  HideFields: HideFields,
  SetUpFormState: SetUpFormState,
  ClearUploadNotification: ClearUploadNotification,
  SyncForm: SyncForm,
};
