<template>
  <div class="loan-details-table">
    <div v-show="purchasedLoan">
      <breadcrumb-navigation :items="breadcrumbItems" />
      <workout-case-detail
        :loan-ps-id="loanPsId"
        :next-workout-case-number="caseNumber"
      />
    </div>
  </div>
</template>

<script>
import BreadcrumbNavigation from "components/shared/breadcrumb_navigation";
import WorkoutCaseDetail from "../../components/manage/asset_management/foreclosure_workout/workout_case_detail";

export default {
  name: "foreclosure-workout-controller",
  components: {
    BreadcrumbNavigation,
    WorkoutCaseDetail,
  },
  computed: {
    loanPsId: () => PSData.loan.toParam,
    caseNumber() {
      const workoutCase = PSData.foreclosureWorkoutCase || {};
      return workoutCase.caseNumber || PSData.nextWorkoutCaseNumber;
    },
    breadcrumbItems: function() {
      return [
        {
          name: "Asset Management",
          link: `/manage/loans/${this.loanPsId}/asset_management`,
        },
        {
          name: `Workout Case ${this.caseNumber}`,
        },
      ];
    },
    purchasedLoan: () => PSData.loanHasBeenPurchased,
  },
};
</script>
