<template>
  <div class="servicer-loan-boarding-notes">
    <loan-boarding-truncated-text :value="record.servicerComments"></loan-boarding-truncated-text>
    <span class="edit-note" @click="show = true">
      <i class="fa fa-pencil" aria-hidden="true"></i>
      Edit notes
    </span>
    <modal-view :show="show" :on-close="onClose">
      <div class="modal-content base-modal">
        <div class="modal-header">
          <h4>Notes To Subservicer</h4>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group control-wrapper">
              <label for="notes">Notes</label>
              <textarea id="notes" maxlength="255" class="form-control" v-model="resource.note" rows="5">
              </textarea>
            </div>
            <check-box v-model="resource.rushBoard" @change="onRushBoard" label="Rush Board"></check-box>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-grey" @click="onClose">Cancel</button>
          <button class="btn btn-action-cta" @click="onSubmit">
            Save
          </button>
        </div>
      </div>
    </modal-view>
  </div>
</template>
<script>
import LoanBoardingTruncatedText from "components/servicer/loan_boarding/truncated_text";
import ModalView from "components/modal_view";
import CheckBox from "components/shared/check_box";
import AjaxService from "services/ajax_service";
import trim from "lodash/trim";
import startsWith from "lodash/startsWith";
import replace from "lodash/replace";
import { success, error } from "services/notification";

const RUSH_BOARD_PLACEHOLDER = "Rush board";
const RUSH_BOARD_PLACEHOLDER_WITH_DASH = RUSH_BOARD_PLACEHOLDER + " – ";

export default {
  name: "manage-servicer-loan-boarding-notes-to-subservicer",
  components: { ModalView, CheckBox, LoanBoardingTruncatedText },
  props: {
    record: Object,
  },
  data() {
    return {
      show: false,
      inFlight: false,
      resource: {
        note: this.record.servicerComments || "",
        rushBoard: startsWith(this.record.servicerComments, RUSH_BOARD_PLACEHOLDER),
      },
    };
  },
  methods: {
    onRushBoard() {
      if (this.resource.rushBoard) {
        if (this.resource.note !== "") {
          this.resource.note = RUSH_BOARD_PLACEHOLDER_WITH_DASH + this.resource.note;
        } else {
          this.resource.note = RUSH_BOARD_PLACEHOLDER;
        }
      } else {
        this.resource.note = replace(this.resource.note, RUSH_BOARD_PLACEHOLDER_WITH_DASH, "");
        this.resource.note = replace(this.resource.note, RUSH_BOARD_PLACEHOLDER, "");
      }
    },
    onClose() {
      this.$emit("close");
      this.show = false;
      this.resource.note = this.record.servicerComments;
      this.resource.rushBoard = startsWith(this.record.servicerComments, RUSH_BOARD_PLACEHOLDER);
    },
    async onSubmit() {
      this.inFlight = true;
      const resource = { servicer_loan_boarding: { servicer_comments: this.resource.note } };
      const messages = {
        onSuccess: "Note was updated successfully",
      };

      const result = await AjaxService.withNotifications(messages).patch(
        this.record.routes.update,
        resource
      );

      this.record.servicerComments = result.servicerComments;
      this.onClose();
      this.inFlight = false;
    },
  },
};
</script>
