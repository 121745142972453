<template>
  <div class="app">
    <div class="app-container container">
      <h1 class="app-heading is-size-4 has-text-weight-semibold">
        PeerSuite Settings
      </h1>

      <base-tabs
        bordered
        class="app-tabs"
        size="medium"
        :value="currentTab"
        @input="onTabChange"
      >
        <base-tab-item
          v-for="app in apps"
          :key="app.routeName"
          :label="app.label"
        />
      </base-tabs>

      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>

<script>
const apps = [
  { label: "Branding", routeName: "branding" },
  { label: "Rates", routeName: "rates" },
  { label: "Notifications", routeName: "notifications" },
];

export default {
  name: "PeersuiteSettingsApp",

  computed: {
    currentTab() {
      const routeName = this.$route.name;

      return routeName ? this.apps.findIndex(a => a.routeName === routeName) : undefined;
    },
  },

  created() {
    this.apps = apps;
  },

  methods: {
    onTabChange(appIndex) {
      const app = this.apps[appIndex];

      app && this.$router.push(app.routeName);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins";
.app {
  padding: 32px 0;

  @include tablet {
    padding: 55px 0;
  }
}

.app-container {
  $gap: 15px;

  // NOTE: `box-sizing: content-box` is not used here because all child elements inherit it.
  max-width: 820px + $gap * 2 !important;
  padding-left: $gap;
  padding-right: $gap;
  width: auto;
}

.app-heading {
  margin-bottom: 24px;

  @include tablet {
    margin-bottom: 40px;
  }
}

.app-tabs {
  padding-top: 14px;

  &::v-deep .tab-content {
    // Note: this fixes the margin
    display: none;
  }
}
</style>
