import debounce from "utils/debounce";

export const DELAY_MS = 350;
const RECALCULATE_FIELDS = [
  "amount", // fee' amount
  "waivedAmount", // fee' waived amount
  "payoffEstimatedDate",
  "payoffExpirationDate",
  "wireTargetDateAmount",
  "defaultDate",
  "waivedUnpaidPrincipalAmount",
  "waivedInterestDueFromBorrowerAmount",
  "waivedDefaultInterestDueFromBorrowerAmount",
  "statementType",
  "constructionReserveAmount",
  "otherReserveAmount",
  "payoffStatementCreditAmount",
  "priceDiscountPremiumAmount",
  "correctedUnpaidPrincipalAmount",
  "suspenseBalance",
  "escrowReserveAmount",
  "unpaidInterestDueFromBorrowerAmount",
  "waivedUnpaidInterestDueFromBorrowerAmount",
  "deferredInterestAmount",
  "waivedDeferredInterestAmount",
  "correctedInterestDueFromBorrowerAmount",
  "correctedDefaultInterestDueFromBorrowerAmount",
  "correctedPaidToDate",
];

export default {
  methods: {
    updateEditableField(prop, object) {
      return debounce(value => {
        object[prop] = value;
        if (RECALCULATE_FIELDS.includes(prop)) {
          this.$emit("sync-interest");
        }
      }, DELAY_MS);
    },
  },
};
