<template>
  <multi-select2 class="form-control" v-model="selectedUsers" :options="{placeholder: role}">
    <option value="all">All</option>
    <option value="unassigned">Unassigned</option>
    <option v-for="user in users" :value="user.id" :key="user.id">{{ user.name }}</option>
  </multi-select2>
</template>

<script>
import MultiSelect2 from "components/shared/multi_select2";
import isEqual from "lodash/isEqual";

export default {
  name: "users-dropdown",
  components: { MultiSelect2 },
  props: {
    users: Array,
    role: String,
    currentSelection: Array,
  },
  data() {
    return {
      selectedUsers: this.currentSelection,
    };
  },
  created() {
    this.selectedUsers = this.currentSelection;
  },
  watch: {
    selectedUsers(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        if (newValue.length === 0) {
          this.$emit("filter-options", ["all"]);
        } else {
          this.$emit("filter-options", newValue);
        }
      }
    },
  },
};
</script>
