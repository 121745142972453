const Command = PS.Models.Command;
const Commands = PS.Models.Permission.Commands;
const Events = PS.Models.Permission.Events;

class RequestDataSetup extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.DataSetupRequested,
      PS.Services.MessageBusService.settingsBus,
      {}
    );
  }
}

class RequestResetChanges extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.ResetChangesRequested,
      PS.Services.MessageBusService.settingsBus,
      {}
    );
  }
}

class RequestSaveChanges extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.SaveChangesRequested,
      PS.Services.MessageBusService.settingsBus,
      {}
    );
  }
}

class SendRoleUpdate extends Command {
  execute() {
    return Command.broadcastEvent(Events.RoleUpdated, PS.Services.MessageBusService.settingsBus, {
      role: this.state.role,
    });
  }
}

class PrepareUsers extends Command {
  execute() {
    this.state.users = (this.state.userData || []).map(u => {
      return new PS.Models.Permission.User(u);
    });

    return Promise.resolve();
  }
}

class PrepareRoles extends Command {
  execute() {
    this.state.roles.forEach(role => {
      role.users = this.state.users.filter(user => {
        return user.roles.find(r => r === role.name);
      });
    });

    return Promise.resolve();
  }
}

class AddChange extends Command {
  execute() {
    let role = this.event.role;

    this.state.changes.add({
      item: role.userId,
      action: role.enabled ? "add" : "remove",
      value: role.value,
    });

    return Promise.resolve();
  }
}

class ResetChanges extends Command {
  execute() {
    this.state.changes.reset();

    return Promise.resolve();
  }
}

class StartUpdating extends Command {
  execute() {
    this.state.updating = true;

    return Promise.resolve();
  }
}

class FinishUpdating extends Command {
  execute() {
    this.state.updating = false;

    return Promise.resolve();
  }
}

class SendToServer extends Command {
  execute() {
    let payload = this.preparePayload(this.state.changes);
    let url = `/manage/permissions`;
    let data = { permission_changes: JSON.stringify(payload) };
    return PS.Services.AjaxService.withNotifications()
      .post(url, data)
      .then(data => {
        this.state.userData = data.users;
        this.state.roles = data.options.roles;
      })
      .catch(() => {
        return new FinishUpdating(this.event, this.state).execute();
      });
  }

  preparePayload(changes) {
    let commands = changes.commands;
    let payload = [];
    Object.keys(commands).forEach(item => {
      let itemChange = { user_id: item, changes: [] };

      Object.keys(commands[item]).forEach(value => {
        if (commands[item][value] !== undefined) {
          itemChange.changes.push({ action: commands[item][value], role: value });
        }
      });

      if (itemChange.changes.length > 0) payload.push(itemChange);
    });

    return payload;
  }
}

PS.Models.Permission.Commands = {
  AddChange: AddChange,
  FinishUpdating: FinishUpdating,
  PrepareUsers: PrepareUsers,
  PrepareRoles: PrepareRoles,
  RequestDataSetup: RequestDataSetup,
  RequestResetChanges: RequestResetChanges,
  RequestSaveChanges: RequestSaveChanges,
  ResetChanges: ResetChanges,
  SendRoleUpdate: SendRoleUpdate,
  SendToServer: SendToServer,
  StartUpdating: StartUpdating,
};
