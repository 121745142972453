const Event = PS.Models.Event;
const Rule = PS.Models.Rule;
const Events = PS.Models.Diligence.Events;
const Commands = PS.Models.Diligence.Commands;

class PreviewRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.Document.LoadingRequested:
        return this.whenSelectedDocumentsUpdateRequested(event);
      case Events.Preview.DocumentSelectionRequested:
        return this.whenDocumentSelectionRequested(event);
    }

    return super.check(event);
  }

  whenSelectedDocumentsUpdateRequested(event) {
    return [new Commands.Preview.UpdateSelectedDocuments(event, this.state)];
  }

  whenDocumentSelectionRequested(event) {
    return [
      new Commands.Preview.SelectDocument(event, this.state),
      new Commands.Preview.StartLoading(event, this.state),
      new Commands.Preview.LoadSelectedDocument(event, this.state),
      new Commands.Preview.FinishLoading(event, this.state),
    ];
  }
}

PS.Models.Diligence.PreviewRule = PreviewRule;
