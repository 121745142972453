<template>
  <div v-if="zip" class="market-data-zip-search-button">
    <template v-if="bannerMode">
      <a
        class="report-link"
        rel="noopener noreferrer"
        target="_blank"
        title="View ZIP Code Report"
        :href="zipReportLink"
        @click="trackZipReportBanner"
      >
        <img
          class="report-item-banner"
          :src="require('images/banners/lender_tools/graphic-zip@2x.png')"
        />
        <p class="caption">
          View ZIP Code Report
        </p>
      </a>
    </template>
    <template v-if="linkMode">
      <a
        class="report-link"
        rel="noopener noreferrer"
        target="_blank"
        title="View ZIP Code Report"
        :href="zipReportLink"
        @click="trackZipReportLink"
      >
        {{ displayText }}
      </a>
      <img class="chart-tool" :src="require('images/icons/chart-tool.svg')" />
    </template>
    <template v-if="tabMode">
      <a
        class="report-link"
        title="View ZIP Code Report"
        :href="zipReportLink"
        @click="trackZipReportLink"
      >
        {{ displayText }}
      </a>
    </template>
  </div>
</template>

<script>
const MessageBusService = PS.Services.MessageBusService;
const MarketDataRule = PS.Models.MarketData.MarketDataRule;
const Commands = PS.Models.MarketData.Commands;
const Events = PS.Models.MarketData.Events;
const LocationService = PS.Services.LocationService;

export default Vue.component("market-data-zip-search-button", {
  props: {
    zip: String,
    buttonText: String,
    mode: String,
  },
  data() {
    return {
      bus: MessageBusService.lenderBus,
    };
  },
  mounted() {
    this.marketDataRulebook = new MarketDataRule(this);
    this.bus.attachRulebook(this.marketDataRulebook);
  },
  beforeDestroy() {
    this.bus.detachRulebook(this.marketDataRulebook);
  },
  methods: {
    trackZipReportLink() {
      const trackEvent = new Events.TrackMarketDataReportLinkRequested({
        zip: this.zip,
      });

      new Commands.TrackMarketDataReportLink(trackEvent, {}).execute();
    },
    trackZipReportBanner() {
      const event = new Events.TrackMarketDataReportBannerRequested({
        zip: this.zip,
      });

      new Commands.TrackMarketDataReportBanner(event, {}).execute();
    },
  },
  computed: {
    zipReportLink() {
      return `/tools/zip_report?zip=${this.zip}`;
    },
    displayText() {
      return this.buttonText || this.zip;
    },
    linkMode() {
      return this.mode === "link";
    },
    bannerMode() {
      return this.mode === "banner";
    },
    tabMode() {
      return this.mode === "tab";
    },
  },
});
</script>
