<template>
  <div>
    <div v-if="!currentItem">
      <filters v-bind="filters" @filter="$listeners.filter" />
      <items
        :loading="loading"
        :items="items"
        :total="total"
        :focused="focused"
        @review="displayItemDetails"
      />
    </div>

    <item-details
      v-else
      :value="currentItem"
      :use-hold-actions=useHoldActions
      @back="displayItemList"
    />
  </div>
</template>

<script>
import get from "lodash/get";
import debounce from "lodash/debounce";

import Filters from "./filters";
import Items from "./items";
import ItemDetails from "./details";
import AjaxService, { formatParams } from "services/ajax_service";
import { previousWorkday, fromInput, toAPI } from "../shared/date_helper";

const DEBOUNCE_DELAY = 500;

const formatDate = (dateType, date) => {
  switch (dateType) {
    case "all":
      return null;
    case "today":
      return toAPI(previousWorkday());
    default:
      return toAPI(fromInput(date));
  }
};

export default {
  components: {
    Filters,
    Items,
    ItemDetails,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      items: [],
      total: 0,
      loading: false,
      currentItem: null,
    };
  },
  computed: {
    focused() {
      return get(this.filters, "loanId", "").length > 0;
    },
    params() {
      const {
        fromDate,
        toDate,
        loanId,
        dateType,
        legalOwnerName,
        subLenderNoteStatus,
      } = this.filters;
      const params = {};

      params.statuses = this.focused ? ["pending", "ready", "hold", "distributed"] : ["pending"];

      if (fromDate) {
        params.from = formatDate(dateType, fromDate);
      }

      if (toDate) {
        params.to = formatDate(dateType, toDate);
      }

      if (loanId) {
        params.loan_id = loanId;
      }

      if (legalOwnerName) {
        params.legal_owner_names = [legalOwnerName];
      }

      if (subLenderNoteStatus) {
        params.sub_lender_note_status = subLenderNoteStatus;
      }

      return params;
    },
    useHoldActions() {
      return this.currentItem.status === "hold";
    },
  },
  watch: {
    filters: {
      deep: true,
      handler: debounce(function() {
        this.fetchData();
      }, DEBOUNCE_DELAY),
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const url = `/manage/cash_receipt_items?${formatParams(this.params)}`;
        const { items, total } = await AjaxService.getJSON(url);

        this.items = items;
        this.total = total;
      } finally {
        this.loading = false;
      }
    },
    displayItemDetails(item) {
      this.currentItem = item;
    },
    displayItemList() {
      this.currentItem = null;
    },
  },
};
</script>
