<template>
  <div>
    <loan-boarding-submitted
      :collection="currentCollection"
      :routes="routes"
      :options="options"
      @updated="updateCollections" />

    <loan-boarding-reconcile
      mode="readonly"
      :collection="currentResubmittedCollection"
      :routes="routes"
      :options="options"
      @updated="updateCollections" />

    <loan-boarding-transfer-submitted
      :collection="currentTransferCollection"
      :routes="routes.transfer"
      @updated="updateTransferCollections" />
  </div>
</template>
<script>
import LoanBoardingSectionMixin from "components/servicer/loan_boarding/mixin/section";
import LoanBoardingSubmitted from "components/servicer/loan_boarding/submitted";
import LoanBoardingReconcile from "components/servicer/loan_boarding/reconcile";
import LoanBoardingTransferSubmitted from "components/servicer/loan_boarding_transfer/submitted";

export default {
  components: { LoanBoardingSubmitted, LoanBoardingReconcile, LoanBoardingTransferSubmitted },
  mixins: [LoanBoardingSectionMixin],
  name: "manage-servicer-load-boarding-sections-submitted",
  computed: {
    lastUpdatedAt() {
      return this.getLastUpdatedAt(this.currentCollection);
    },
    currentCollection() {
      let collection = this.collections.submitted;
      return this.filterCollectionForServicer(collection);
    },
    currentResubmittedCollection() {
      let collection = this.collections.resubmitted;
      return this.filterCollectionForServicer(collection);
    },
    currentTransferCollection() {
      let collection = this.transferCollections.submitted;
      return this.filterCollectionForServicer(collection);
    },
    count() {
      return [
        this.currentCollection.length,
        this.collections.resubmitted.length,
        this.currentTransferCollection.length,
      ].reduce((acc, value) => acc + value);
    },
  },
  created() {
    this.emitSectionData();
  },
  methods: {
    emitSectionData() {
      this.$emit("loaded", {
        section: "submitted",
        count: this.count,
        lastUpdatedAt: this.lastUpdatedAt,
      });
    },
  },
};
</script>
