<template>
  <div>
    <categorized-bar-chart :chart="chart" :sources="sources" class="housing-stock-value-chart"></categorized-bar-chart>
    <div class="row listing-stats-explanation-section">
      <div class="col-md-10">
        Median Value
      </div>
      <div class="col-md-2 value-section">
        <span v-show="sources.isLoading">
          &nbsp;
        </span>
        <span v-show="sources.isSuccessful">
          {{ sources.medianHomeValue | money(0, true) | notAvailable }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "d3";

import CategorizedBarChart from "components/market_data/categorized_bar_chart";

export default {
  name: "housing-stock-value-chart",
  components: {
    CategorizedBarChart,
  },
  props: {
    sources: Object,
  },
  data() {
    return {
      chart: {
        title: this.sources.name,

        axisXTitle: "Value of home (thousands of dollars)",
        axisXTickRotate: 90,

        axisYTitle: "Percent of households",
        axisYTickFormat: format(",.0%"),

        tooltipFormatValue: format(",.2%"),

        labels: {
          valLess100Pct: "< 100",
          val100149Pct: "100 - 149",
          val150199Pct: "150 - 199",
          val200299Pct: "200 - 299",
          val300499Pct: "300 - 499",
          val500999Pct: "500 - 999",
          valGreater1mPct: "1M+",
        },

        columns: {
          categories: {
            color: "#66cc33",
            label: "Percent of households",
          },
        },
      },
    };
  },
};
</script>
