<template>
  <div class="level">
    <div class="level-left">
      <div class="level-item mr-5">
        <div>
          <div class="is-size-6 has-text-weight-semibold mb-1">Total Fees</div>
          <div class="is-size-5 has-text-weight-normal">
            {{ totalFees | accounting}}
          </div>
        </div>
      </div>
      <div class="level-item mr-5">
        <div>
          <div class="is-size-6 has-text-weight-semibold mb-1">Total Waived</div>
          <div class="is-size-5 has-text-weight-normal">
            {{ totalWaivedFees | accounting }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sum from "lodash/sum";

export default {
  props: {
    payoffStatement: Object,
  },
  computed: {
    totalFees() {
      return sum(this.payoffStatement.fees.map(f => parseFloat(f.amount || 0)));
    },
    totalWaivedFees() {
      return sum(this.payoffStatement.fees.map(f => parseFloat(f.waivedAmount || 0)));
    },
  },
};
</script>
<style scoped>
.is-size-6-5 {
  font-size: 0.875rem;
}
.is-size-5-5 {
  font-size: 1.125rem;
}
</style>
