<template>
  <modal-form
    :show="show"
    :save-disabled="saveBtnDisabled"
    :confirm="changed"
    :title="title"
    save-btn-name="Save"
    class="lender-legal-entity-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <form>
      <div class="row">
        <with-errors on="name" :errors="errors" class="col-12 form-group">
          <label for="name">Name</label>
          <input
            id="name"
            v-model="fictitiousName.name"
            type="text"
            class="form-control"
          >
        </with-errors>
        <with-errors on="states" :errors="errors" class="col-12 form-group">
          <label for="states">States</label>
          <multi-select2
            id="states"
            v-model="fictitiousName.states"
            :options="{ templateSelection: formatSelectedStateName }"
            name="states"
            class="form-control"
          >
            <option
              v-for="state in states"
              :key="state.value"
              :value="state.value"
            >
              {{ state.name }}
            </option>
          </multi-select2>
        </with-errors>
        <with-errors on="notes" :errors="errors" class="col-12 form-group">
          <label for="notes">Notes</label>
          <input
            id="notes"
            v-model="fictitiousName.notes"
            type="text"
            class="form-control"
          >
        </with-errors>
      </div>
    </form>
  </modal-form>
</template>

<script>
import ModalForm from "components/modal_form";
import WithErrors from "components/shared/with_errors";
import AjaxService from "services/ajax_service";
import MultiSelect2 from "components/shared/multi_select2";
import MessageBusService from "services/message_bus_service";

import { US_STATES_OPTIONS } from "models/constants";

const FORM_MODES = {
  edit: "edit",
  create: "create",
};

export default {
  name: "lender-legal-entities-fictitious-name-form-modal",
  components: { ModalForm, WithErrors, MultiSelect2 },
  props: {
    fictitiousName: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    routes: {
      type: Object,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      saveInProgress: false,
      states: US_STATES_OPTIONS,
      bus: MessageBusService.entityBus,
    };
  },
  computed: {
    changed() {
      return this.fictitiousName.changed();
    },
    title() {
      if (this.formMode === FORM_MODES.edit) {
        return "Edit Fictitious Name (DBA)";
      } else {
        return "Add Fictitious Name (DBA)";
      }
    },
    saveBtnDisabled() {
      return this.saveInProgress || !this.changed;
    },
    formMode() {
      return this.fictitiousName.persisted() ? FORM_MODES.edit : FORM_MODES.create;
    },
  },
  methods: {
    onSave() {
      if (this.formMode === FORM_MODES.edit) {
        this.updateFictitiousName();
      } else {
        this.createFictitiousName();
      }
    },
    createFictitiousName() {
      this.saveInProgress = true;
      const messages = {
        onSuccess: "Fictitious Name (DBA) has been created",
        onError: this.onError,
      };

      AjaxService.withNotifications(messages)
        .post(this.routes.fictitiousNamesPath, this.fictitiousName.toPlainObject(), {
          "Content-Type": "application/json",
        })
        .then(data => {
          this.$emit("on-save", data);
          this.bus.broadcast("entity-updated");
        })
        .finally(() => (this.saveInProgress = false));
    },
    updateFictitiousName() {
      this.saveInProgress = true;
      const messages = {
        onSuccess: "Fictitious Name (DBA) has been updated",
        onError: this.onError,
      };

      AjaxService.withNotifications(messages)
        .patch(
          `${this.routes.fictitiousNamesPath}/${this.fictitiousName.id}`,
          this.fictitiousName.toPlainObject(),
          { "Content-Type": "application/json" }
        )
        .then(data => {
          this.$emit("on-save", data);
          this.bus.broadcast("entity-updated");
        })
        .finally(() => (this.saveInProgress = false));
    },
    onError({ response, data }) {
      if (response.status === 403) {
        return { message: "Unauthorized", status: "error" };
      } else {
        this.errors = data.errors;
        return null;
      }
    },
    formatSelectedStateName(state) {
      return state.id;
    },
  },
};
</script>
