<template>
  <documents-show-panel
    panel-title="Entity Docs"
    form-title="Edit Entity Docs"
    :route="routes.lenderLegalEntityDocuments"
    :policy="policy"
    class="legal-entity-docs-panel"
  >
  </documents-show-panel>
</template>
<script>
import DocumentsShowPanel from "../shared/documents_show_panel";

export default {
  name: "lender-legal-entity-docs-panel",
  components: {
    DocumentsShowPanel,
  },
  props: {
    policy: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      routes: PSData.routes || {},
    };
  },
};
</script>
