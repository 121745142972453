<template>
  <div class="market-data">
    <ul class="market-data-tiles">
      <li class="col-4 msa-area-name">
        <p>Metropolitan Statistical Area (MSA)</p>
        <span>{{ areaName }}</span>
      </li>
      <li class="col-2 msa-population0">
        <p>Population {{ years[0] }}</p>
        <span>{{ numbers[0] }}</span>
      </li>
      <li class="col-2 msa-population1">
        <p>Population {{ years[1] }}</p>
        <span>{{ numbers[1] }}</span>
      </li>
      <li class="col-3 msa-percentage-change">
        <p>% Change {{ years[0] }} to {{ years[1] }}</p>
        <span>{{ percentageChange }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
const NOT_FOUND = "Not Found";
const NOT_AVAILABLE = "--";

export default Vue.component("msa-population", {
  props: {
    area: Object,
  },
  computed: {
    population: function() {
      return PS.Models.MSA.buildPopulation(this.area);
    },
    years: function() {
      return this.population.years;
    },
    areaName: function() {
      return this.population.areaName === undefined ? NOT_FOUND : this.population.areaName;
    },
    numbers: function() {
      return this.population.numbers.map(number => {
        return number === undefined ? NOT_AVAILABLE : this.$options.filters.number(number, 0);
      });
    },
    percentageChange: function() {
      return this.population.percentageChange === undefined
        ? NOT_AVAILABLE
        : this.$options.filters.percent(this.population.percentageChange);
    },
  },
});
</script>
