<template>
  <modal-form
    :confirm="false"
    :save-disabled="!canSaveChanges"
    :show="show"
    title="Assign Document"
    class="assign-document-modal"
    alt-button-name="Ignore Item"
    @on-close="close"
    @on-save="save"
  >
    <span>Choose from list of available documents on this loan.</span>
    <div class="details">
      <div class="highlighted">
        <span>Loan ID:</span>
        <span><loan-link :item="reportItem"/></span>
      </div>
      <div>
        <span>Address:</span>
        <span>{{ reportItem.address }}</span>
      </div>
      <div>
        <span>Document Type:</span>
        <span>{{ reportItem.docName }}</span>
      </div>
      <div>
        <span>Old Status:</span>
        <span>Unknown</span>
      </div>
      <div>
        <span>New Status:</span>
        <span>{{ reportItem.newDocStatusText }}</span>
      </div>
      <div class="highlighted">
        <span>Exception:</span>
        <span>{{ exceptionMessage }}</span>
      </div>
      <div class="highlighted">
        <span>Doc Description:</span>
        <span>{{ reportItem.description}}</span>
      </div>
    </div>
    <div class="form">
      <div v-if="docsLoading" class="ps-loader"></div>
      <div v-else-if="availableDocuments.length">
        <div v-for="doc in availableDocuments" :key="doc.id" class="radio-control js-radio-input">
          <input
            :id="`doc-type-${doc.id}`"
            v-model="selectedDocument"
            type="radio"
            :value="doc.id"
          >
          <label :for="`doc-type-${doc.id}`">
            {{ documentName(doc) }}
            <span class="control-toggle" />
          </label>
        </div>
      </div>
      <span v-else class="no-docs-block">
        If you do not see the intended doc name here, add the document in
        <loan-link :item="reportItem">the doc tracking tab for this loan</loan-link>.
      </span>
    </div>
    <template v-slot:footer>
      <div class="alt-actions">
        <div class="btn btn-alt-modal" @click="ignore">Ignore Item</div>
      </div>
      <div class="main-actions">
        <div class="btn btn-cancel-modal" @click="close">Cancel</div>
        <div class="btn btn-action-modal" :disabled="!canSaveChanges" @click="save">Save</div>
      </div>
    </template>
  </modal-form>
</template>

<script>
import compact from "lodash/compact";
import LoanLink from "./loan_link.vue";
import ModalForm from "components/modal_form.vue";
import AjaxService from "services/ajax_service";

export default {
  name: "assign-document-modal",
  components: {
    LoanLink,
    ModalForm,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    reportItem: {
      type: Object,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedDocument: undefined,
      availableDocuments: [],
      docsLoading: false,
      itemResolving: false,
    };
  },
  computed: {
    canSaveChanges() {
      return this.selectedDocument && !this.itemResolving;
    },
    exceptionMessage() {
      return this.reportItem.statusCode.code
        ? `${this.reportItem.statusCode.description} (Code ${this.reportItem.statusCode.code})`
        : "Exception not found";
    },
  },
  mounted() {
    this.docsLoading = true;

    AjaxService.get(
      `/manage/physical_doc_tracking/report_import_items/${this.reportItem.id}/available_documents`
    )
      .then(response => {
        this.availableDocuments = response;
      })
      .finally(() => (this.docsLoading = false));
  },
  methods: {
    documentName(document) {
      return [
        compact([document.name, document.description]).join(", "),
        `(${document.statusText})`,
      ].join(" ");
    },
    ignore() {
      this.itemResolving = true;

      const url = "/manage/physical_doc_tracking/report_import_items/ignore_items";
      const payload = {
        report_import_id: this.reportId,
        report_import_items: [this.reportItem.id],
      };

      AjaxService.withNotifications({
        onSuccess: "1 item successfully ignored.",
        onError: res => ({ message: res.data.error, status: "error" }),
      })
        .post(url, payload, { "Content-Type": "application/json" })
        .then(data => {
          this.$emit("on-item-resolved", data.report);
        })
        .finally(() => {
          this.itemResolving = false;
        });
    },
    save() {
      this.itemResolving = true;

      AjaxService.withNotifications({
        onSuccess: "Report Item has been resolved.",
        onError: res => ({ message: res.data.error, status: "error" }),
      })
        .post(
          `/manage/physical_doc_tracking/report_import_items/${this.reportItem.id}/assign_document`,
          { document_id: this.selectedDocument },
          { "Content-Type": "application/json" }
        )
        .then(data => {
          this.$emit("on-item-resolved", data.report);
        })
        .finally(() => {
          this.itemResolving = false;
        });
    },
    close() {
      this.$emit("on-close");
    },
  },
};
</script>
