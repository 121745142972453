<template>
  <div id="bridge-loans-list" class="active-portfolio-loans-list" v-cloak>
    <div class="portfolio-table-header">
      Showing {{ currentLoansCount }} of {{ totalLoansCount }}
      totalling {{ totalUnpaidBalance | money(2) | missingValue }}

      <div class="filters">
        <span>Filters: </span>

        <checkbox-filter
          title="Past Due"
          :value="filters.pastDue"
          :total="filterTotal(Filters.PastDue)"
          @filter-options="applyFilter(Filters.PastDue, ...arguments)"
        />

        <checkbox-filter
          title="Maturing"
          :value="filters.maturing"
          :total="filterTotal(Filters.Maturing)"
          @filter-options="applyFilter(Filters.Maturing, ...arguments)"
        />
      </div>
    </div>

    <table class="portfolio-table">
      <thead>
        <tr>
          <th class="hotlist-sortable loan-status-wrapper">
            <sorting-header
              title="Status"
              :order="findDirectionBy(SortingColumns.Status)"
              @on-change-direction="sortBy(SortingColumns.Status, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-payment-date">
            <sorting-header
              title="Next Paymt Due Date"
              :order="findDirectionBy(SortingColumns.NextPaymentDueDate)"
              @on-change-direction="sortBy(SortingColumns.NextPaymentDueDate, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-maturity-date">
            <sorting-header
              title="Maturity Date"
              :order="findDirectionBy(SortingColumns.MaturityDate)"
              @on-change-direction="sortBy(SortingColumns.MaturityDate, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-full-address">
            <sorting-header
              title="Address"
              :order="findDirectionBy(SortingColumns.Address)"
              @on-change-direction="sortBy(SortingColumns.Address, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-program-type">
            <sorting-header
              title="Loan Program"
              :order="findDirectionBy(SortingColumns.LoanProgram)"
              @on-change-direction="sortBy(SortingColumns.LoanProgram, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-strategy">
            <sorting-header
              title="Loan Strategy"
              :order="findDirectionBy(SortingColumns.LoanStrategy)"
              @on-change-direction="sortBy(SortingColumns.LoanStrategy, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-unpaid-principal-balance">
            <sorting-header
              title="Unpaid Principal Bal"
              :order="findDirectionBy(SortingColumns.UnpaidPrincipalBalance)"
              @on-change-direction="sortBy(SortingColumns.UnpaidPrincipalBalance, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-b-piece">
            <sorting-header
              title="B-Piece"
              :order="findDirectionBy(SortingColumns.BPiece)"
              @on-change-direction="sortBy(SortingColumns.BPiece, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-construction-reserve-remaining">
            <sorting-header
              title="Constr Res Remaining"
              :order="findDirectionBy(SortingColumns.ConstructionReserve)"
              @on-change-direction="sortBy(SortingColumns.ConstructionReserve, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-interest-rate">
            <sorting-header
              title="Note Rate"
              :order="findDirectionBy(SortingColumns.NoteRate)"
              @on-change-direction="sortBy(SortingColumns.NoteRate, ...arguments)"
            />
          </th>
          <th class="hotlist-sortable loan-borrower-contact">
            <sorting-header
              title="Borrower"
              :order="findDirectionBy(SortingColumns.Borrower)"
              @on-change-direction="sortBy(SortingColumns.Borrower, ...arguments)"
            />
          </th>
          <th class="loan-requests"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="loan in orderedLoans"
          v-show="loan.visible"
          :key="loan.toParam"
          :data-path="loanPath(loan)"
        >
          <td class="loan-status-wrapper" :class="activeSortClass(SortingColumns.Status)">
            <span v-popover :class="loanStatusClass(loan.servicingPaymentStatus)">
              {{ loan.servicingPaymentStatusText }}
            </span>
            <p class="popover-content hidden">
              {{ loan.servicingPaymentStatusTooltip }}
            </p>
          </td>
          <td class="loan-payment-date" :class="activeSortClass(SortingColumns.NextPaymentDueDate)">
            <div>
              <span v-popover="{'container': '#bridge-loans-list'}">
                <span class="with-field-tooltip">
                  {{ loan.nextPaymentDueDate | missingValue }}
                </span>
                <span class="expired" v-if="loan.nextPaymentDueDatePast > 0">
                  {{ loan.nextPaymentDueDatePast }}d Past Due
                </span>
              </span>
              <div class="popover-content hidden">
                <p><strong>Next Payment Due Date:</strong> {{ loan.nextPaymentDueDate | missingValue }}</p>
                <p><strong>Paid to Date:</strong> {{ loan.paidToDate | missingValue }}</p>
                <p><strong>Days Past Due:</strong> {{ loan.nextPaymentDueDatePast | missingValue }}</p>
                <p><strong>Grace Period:</strong> {{ loan.gracePeriodDays | missingValue }}</p>
                <p><strong>Servicer Name:</strong> {{ loan.servicerName | missingValue }}</p>
                <p><strong>Servicer Loan ID:</strong> {{ loan.servicerLoanId | missingValue }}</p>
                <p><strong>Servicer Updated At:</strong> {{ loan.servicerUpdatedAt | missingValue }}</p>
              </div>
            </div>
          </td>
          <td class="loan-maturity-date" :class="activeSortClass(SortingColumns.MaturityDate)">
            <span>{{ loan.maturityDate | missingValue }}</span>
            <span :class="loan.maturityDateStatus">
              {{ maturityDate(loan.maturityDateStatus, loan.maturityDateRemaining) }}
            </span>
          </td>
          <td :class="activeSortClass(SortingColumns.Address)" class="loan-full-address">
            <span class="loan-street">
              {{ loan.address }}
            </span>
            <span>{{ loan.city }}, {{ loan.state }} {{ loan.zip }}</span>
          </td>
          <td :class="activeSortClass(SortingColumns.LoanProgram)" class="loan-program-type">
            {{ loan.loanProgramText }}
          </td>
          <td :class="activeSortClass(SortingColumns.LoanStrategy)" class="loan-strategy">
            {{ loan.loanStrategyText }}
          </td>
          <td
            :class="activeSortClass(SortingColumns.UnpaidPrincipalBalance)"
            class="loan-unpaid-principal-balance"
          >
            {{ loan.unpaidPrincipalBalance | money(2, true) | missingValue }}
          </td>
          <td :class="activeSortClass(SortingColumns.BPiece)" class="loan-b-piece">
            {{ loan.bPieceAmount | money(2, true) | missingValue }}
          </td>
          <td :class="activeSortClass(SortingColumns.ConstructionReserve)" class="loan-construction-reserve-remaining">
            <div>
              <span v-popover="{'container': '#bridge-loans-list'}" class="with-field-tooltip">
                {{ loan.constructionReserveAmount | money(2, true) | missingValue }}
              </span>
              <div class="popover-content hidden">
                <p><strong>Construction Reserve Remaining:</strong> {{ loan.constructionReserveAmount | money(2, true) | missingValue }}</p>
                <p><strong>Construction Reserve at Origination:</strong> {{ loan.constructionReserveAtOrigination | money(2, true) | missingValue }}</p>
              </div>
            </div>
          </td>
          <td :class="activeSortClass(SortingColumns.NoteRate)" class="loan-interest-rate">
            <div>
              <span v-popover="{'container': '#bridge-loans-list'}" class="with-field-tooltip">
                {{ loan.interestRate | percent(3, true) | missingValue }}
              </span>
              <div class="popover-content hidden">
                <p><strong>Note Rate:</strong> {{ loan.interestRate | percent(3, true) | missingValue }}</p>
                <p><strong>Lender Spread:</strong> {{ loan.lenderSpread | percent(3, true) | missingValue }}</p>
                <p><strong>LTV:</strong> {{ loan.ltv | percent(3, true) | missingValue }}</p>
                <p><strong>LTARV:</strong> {{ loan.ltarv | percent(3, true) | missingValue }}</p>
              </div>
            </div>
          </td>
          <td :class="activeSortClass(SortingColumns.Borrower)" class="loan-borrower-contact">
            <div>
              <span v-popover="{'container': '#bridge-loans-list'}" class="with-field-tooltip">
                {{ loan.borrowers[0].formattedName }}
              </span>
              <div class="popover-content hidden">
                <borrowers-info :borrowers="loan.borrowers" />
              </div>
            </div>
          </td>
          <td class="loan-requests">
            <requests-dropdown
              :loan="loan"
              :is-lender="isLender"
              positionClass="dropdown-menu-right"
              @show-payoff-request-modal="openPayoffRequestModal"
            />
            <last-request-popover v-if="loan.lastRequest" :lastRequestPath="lastRequestPath(loan)" :request="loan.lastRequest" />
          </td>
        </tr>
        <tr v-if="loans.length === 0">
          <td class="loan-empty" colspan="2">No Active Loans</td>
        </tr>
      </tbody>
    </table>
    <payoff-request-modal
      :show="showPayoffRequestModal"
      :loan="payoffRequestLoan"
      :is-lender="isLender"
      @close="closePayoffRequestModal"
      @payoff-request-sent="onRequestSent"
    />
  </div>
</template>

<script>
import SortingHeader from "components/shared/sorting_header";
import CheckboxFilter from "components/reports/worklist/checkbox_filter";
import BorrowersInfo from "components/active_portfolio/borrowers_info";
import SortingHelper from "utils/sorting_helper";
import FilterHelper from "utils/filter_helper";
import RequestsDropdown from "./requests_dropdown";
import Segment from "services/segment_service";
import snakeCase from "lodash/snakeCase";
import PayoffRequestModal from "./payoff_request_modal";
import LastRequestPopover from "./last_request_popover";

const StatusSortingOrder = {
  current: 0,
  late: 1,
  late_30: 2,
  late_60: 3,
  late_90: 4,
  late_120: 5,
  matured: 6,
  foreclosure: 7,
};

const SortingColumns = {
  Status: "servicingPaymentStatus",
  NextPaymentDueDate: "nextPaymentDueDateTime",
  MaturityDate: "maturityDateTime",
  LoanProgram: "loanProgramText",
  LoanStrategy: "loanStrategyText",
  UnpaidPrincipalBalance: "unpaidPrincipalBalance",
  BPiece: "bPieceAmount",
  ConstructionReserve: "constructionReserveAmount",
  NoteRate: "interestRate",
  Borrower: "firstBorrowerName",
  Address: "fullAddress",
};

const Filters = {
  PastDue: "PastDue",
  Maturing: "Maturing",
};

const MaturingStatuses = {
  Warning: "warning",
  Expiring: "expiring",
  Expired: "expired",
  None: "none",
};

function sortByUpdatedAt(a, b) {
  return a.updatedAtTime - b.updatedAtTime;
}

export default {
  name: "bridge-loans-list",
  components: {
    SortingHeader,
    CheckboxFilter,
    RequestsDropdown,
    BorrowersInfo,
    PayoffRequestModal,
    LastRequestPopover,
  },
  props: {
    loans: {
      type: Array,
      required: true,
    },
    loansRoute: String,
    isLender: Boolean,
  },
  data() {
    return {
      sortingDirection: -1,
      sortingColumn: SortingColumns.Status,
      sorter: new SortingHelper(
        {
          [SortingColumns.Status]: {
            sortBy: (a, b) => {
              return (
                StatusSortingOrder[a.servicingPaymentStatus] -
                StatusSortingOrder[b.servicingPaymentStatus]
              );
            },
          },
          [SortingColumns.NextPaymentDueDate]: {},
          [SortingColumns.MaturityDate]: {},
          [SortingColumns.LoanStrategy]: {},
          [SortingColumns.LoanProgram]: {},
          [SortingColumns.BPiece]: {
            sortBy: (a, b) => {
              return (a.bPieceAmount || -Infinity) - (b.bPieceAmount || -Infinity);
            },
          },
          [SortingColumns.ConstructionReserve]: {},
          [SortingColumns.NoteRate]: {},
          [SortingColumns.Borrower]: {},
          [SortingColumns.Address]: {},
          [SortingColumns.UnpaidPrincipalBalance]: {},
        },
        sortByUpdatedAt
      ),
      filterHelper: new FilterHelper({
        [Filters.PastDue]: {
          on: loan => {
            return loan.pastDueFlag === true;
          },
        },
        [Filters.Maturing]: {
          on: loan => {
            return loan.maturingFlag === true;
          },
        },
      }),
      orderedLoans: this.loans,
      payoffRequestLoan: null,
      modificationRequestLoan: null,
      filters: {
        pastDue: false,
        maturing: false,
      },
      SortingColumns,
      Filters,
    };
  },
  computed: {
    totalLoansCount() {
      return this.orderedLoans.length;
    },
    currentLoansCount() {
      return this.orderedLoans.filter(loan => loan.visible).length;
    },
    showPayoffRequestModal() {
      return !!this.payoffRequestLoan;
    },
    showModificationRequestModal() {
      return !!this.modificationRequestLoan;
    },
    totalUnpaidBalance() {
      return this.orderedLoans
        .filter(loan => loan.visible)
        .map(loan => loan.unpaidPrincipalBalance || 0)
        .reduce((total, current) => total + current, 0);
    },
  },
  beforeMount() {
    this.sortBy(this.sortingColumn, this.sortingDirection);
    this.applyFilters();
  },
  methods: {
    findDirectionBy(columnName) {
      return this.sorter.findDirection(columnName, this.sortingColumn, this.sortingDirection);
    },
    sortBy(columnName, direction) {
      this.sortingColumn = columnName;
      this.sortingDirection = direction;

      this.orderedLoans = this.orderedLoans.sort(this.sorter.sort(columnName, direction));
    },
    applyFilter(filterName, value) {
      this.filters[filterName] = value;
      this.filterHelper.updateFilter(filterName, value);

      if (this.isLender) {
        let action;

        if (value) {
          action = "selected_" + snakeCase(filterName);
        } else {
          action = "unselected_" + snakeCase(filterName);
        }

        Segment.track("clickedActiveFundedFilters", {
          action: action,
        });
      }

      this.applyFilters();
    },
    applyFilters() {
      this.filterHelper.applyFilters(this.orderedLoans).forEach((loan, index) => {
        Vue.set(this.orderedLoans, index, loan);
      });
    },
    filterTotal(filterName) {
      return this.filterHelper.filteredCount(filterName, this.orderedLoans);
    },
    maturityDate(status, days) {
      switch (status) {
        case MaturingStatuses.Warning:
          return `${days}d Left`;

        case MaturingStatuses.Expiring:
          return `${days}d Left`;

        case MaturingStatuses.Expired:
          return `${Math.abs(days)}d Ago`;

        default:
          return undefined;
      }
    },
    loanStatusClass(status) {
      return status ? `loan-status loan-${status}` : undefined;
    },
    activeSortClass(column) {
      return this.sorter.activeSortClass(this.sortingColumn, column);
    },
    loanPath(loan) {
      return `${this.loansRoute}/${loan.toParam}`;
    },
    lastRequestPath(loan) {
      return `${this.loanPath(loan)}#requests-history`;
    },
    openPayoffRequestModal(loan) {
      this.payoffRequestLoan = loan;
    },
    closePayoffRequestModal() {
      this.payoffRequestLoan = null;
    },
    closeModificationRequestModal() {
      this.modificationRequestLoan = null;
    },
    onRequestSent(loan, request) {
      const updatedLoan = Object.assign({}, loan);
      updatedLoan.lastRequest = Object.assign({}, request);

      Vue.set(this.loans, this.loans.findIndex(l => l.psId === loan.psId), updatedLoan);
    },
  },
};
</script>
