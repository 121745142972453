<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-form-content">
      <div class="modal-form-header">
        {{ title }}
        <a class="modal-close-icon" @click.prevent="onClose" href="#close">&times;</a>
      </div>
      <div class="modal-form-body">
        <p v-if="description" class="modal-form-description">
          {{ description }}
        </p>
        <slot></slot>
      </div>
      <div class="modal-form-footer">
        <slot name="footer">
          <button class="btn btn-cancel-modal" @click="onClose">{{ cancelButtonName }}</button>
          <button class="btn btn-action-modal" :disabled="saveDisabled" @click="onSave">{{ saveButtonName }}</button>
        </slot>
      </div>
    </div>
  </modal-view>
</template>

<script>
import ModalView from "components/modal_view.vue";
import WindowService from "services/window_service";

export default {
  name: "modal-form",
  components: {
    ModalView,
  },
  props: {
    saveDisabled: Boolean,
    show: {
      required: true,
      type: Boolean,
    },
    title: String,
    description: {
      type: String,
      default: undefined,
    },
    saveButtonName: {
      type: String,
      default: "Save",
    },
    cancelButtonName: {
      type: String,
      default: "Cancel",
    },
    confirm: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      if (this.confirm && !WindowService.confirm("Discard unsaved changes?")) return;
      this.$emit("on-close");
    },
    onSave() {
      this.$emit("on-save");
    },
  },
};
</script>
