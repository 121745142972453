<template>
  <div>
    <div
      v-for="override in overrides"
      :key="override.name"
      class="form-group input-group form-row align-items-center has-validation"
    >
      <div class="input-group-prepend">
        <span class="input-group-text">$</span>
      </div>
      <input
        :id="override.name"
        v-model.number="override.amount"
        type="text"
        class="form-control col"
      />
      <label class="col mb-0" :for="override.name">
        {{ override.label }}
      </label>
    </div>
    <div>
      <div v-if="!(overrideTotal == this.loanSale.saleAmount)" class="alert alert-warning">
        Override total must match unpaid principal balance
      </div>
    </div>
  </div>
</template>

<script>
import sumBy from "lodash/sumBy";
import toNumber from "lodash/toNumber";

export default {
  name: "CreditSuisseOverride",
  props: {
    loanSale: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      overrides: [
        {
          name: "loc_credit_suisse",
          label: "Credit Suisse (LOC)",
          amount: 0,
        },
        {
          name: "loc_corporate",
          label: "Corporate (LOC)",
          amount: 0,
        },
        {
          name: "loc_opp_fund_2_mezz",
          label: "Opportunity Fund 2 - MEZZ (LOC)",
          amount: 0,
        },
      ],
    };
  },
  computed: {
    overrideTotal: function() {
      return sumBy(this.overrides, override => toNumber(override.amount));
    },
  },
  watch: {
    overrides: {
      handler: function() {
        this.$emit("overrideUpdate", this.loanSale, this.overrides);
      },
      deep: true,
    },
  },
};
</script>
