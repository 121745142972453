<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content repair-settlement-statement-modal">
      <div class="modal-header">
        <h4>Repair Settlement Statement</h4>
      </div>

      <div class="modal-body">
        <p>
          Are you sure you want to repair the Settlement Statement? This cannot be undone.
        </p>
        <form>
          <div :class="['form-group', 'control-wrapper', {'has-error': errors.has('reason')}]">
            <label for="reason">Please specify reason below:</label>
            <select name="reason"
                    id="reason"
                    class="form-control"
                    v-model="repairData.reason"
                    v-validate="'required'">
              <option disabled selected value="">Select</option>
              <option value="Missing Data">Missing Data</option>
              <option value="Incorrect Data">Incorrect Data</option>
              <option value="Change to Days Owned/Interest">Change to Days Owned/Interest</option>
              <option value="Funding Incorrect">Funding Incorrect</option>
              <option value="other">Other (Specify in Notes)</option>
            </select>
            <span class="error control-label" v-show="errors.has('reason')">
              Reason is required.
            </span>
          </div>

          <div :class="['form-group', 'control-wrapper', {'has-error': errors.has('notes')}]">
            <label for="notes">Notes</label>
            <textarea name="notes"
                      id="notes"
                      cols="30"
                      rows="4"
                      :placeholder="notesPlaceholder"
                      class="form-control"
                      v-model="repairData.notes"
                      v-validate="{ required: isNotesValidationRequired }"/>
            <span class="error control-label" v-show="errors.has('notes')">
              Notes are required when the reason is "Other".
            </span>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <div class="btn btn-cancel-cta"
             @click="close"
             :disabled="isSubmitting">
          No, Don't Repair
        </div>
        <div class="btn btn-action-cta"
             @click="save"
             :disabled="isSubmitting">
          Yes, Repair
        </div>
      </div>
    </div>
  </modal-view>
</template>
<script>
import ModalView from "components/modal_view";
import Ajax from "services/ajax_service";

export default {
  name: "repair-settlement-statement-modal",
  $_veeValidate: {
    validator: "new",
  },
  components: { ModalView },
  props: {
    show: Boolean,
    onClose: Function,
    settlementStatement: Object,
    loadingCallback: Function,
  },
  data() {
    return {
      isSubmitting: false,
      repairData: {
        reason: "",
        notes: null,
      },
    };
  },
  computed: {
    apiParams() {
      return {
        settlement_statement: {
          cancel_repair_reason: this.repairData.reason,
          cancel_repair_comment: this.repairData.notes,
          id: this.settlementStatement.id,
        },
      };
    },
    isNotesValidationRequired() {
      return this.repairData.reason === "other";
    },
    notesPlaceholder() {
      return this.isNotesValidationRequired ? "" : "Optional";
    },
  },
  methods: {
    close() {
      this.$validator.reset();
      this.onClose();
    },
    save() {
      if (this.isSubmitting) return;

      this.$validator.validateAll().then(result => {
        if (!result) return;

        this.repairSettlementStatement();
      });
    },
    repairSettlementStatement() {
      this.isSubmitting = true;
      this.loadingCallback();

      return Ajax.withNotifications({
        onSuccess: "Settlement Statement returned to draft for repair.",
        onError: this.processError,
      })
        .post(`/manage/settlement_statements/${this.settlementStatement.id}/repair`, this.apiParams)
        .then(data => {
          this.$emit("statement-repaired", data);
          this.onClose();
        })
        .catch(() => {})
        .finally(() => {
          this.isSubmitting = false;
          this.loadingCallback();
        });
    },
    processError({ data, response }) {
      let human_error;

      if (response.status === 403) {
        human_error = "This action is forbidden.";
      } else if (data.errors && data.errors.loan) {
        human_error = data.errors.loan.join(". ");
      } else {
        human_error = data;
      }

      return {
        message: human_error,
        status: "error",
      };
    },
  },
};
</script>
