$(function() {
  class FormValidator {
    static suppressRailsValidationMessages() {
      $(".alert-danger, .help-block").addClass("hidden");
    }

    constructor(form, submitHandler) {
      const options = {
        errorClass: "error control-label",
        success: this.onSuccess,
        highlight: this.onHighlight,
      };

      if (submitHandler) {
        options.submitHandler = submitHandler;
      }

      $(form).each((__, element) => {
        $(element).validate(options);
      });
    }

    onSuccess(label, element) {
      FormValidator.suppressRailsValidationMessages();

      $(element).removeClass("error-control");
      if (label.closest(".form-group").find(".error-control").length == 0) {
        label
          .closest(".form-group")
          .removeClass("has-error")
          .addClass("has-success");

        label
          .prev(".form-group")
          .removeClass("has-error")
          .addClass("has-success");

        $(element)
          .next(".error.control-label")
          .remove();
      }
    }

    onHighlight(element) {
      FormValidator.suppressRailsValidationMessages();

      $(element)
        .addClass("error-control")
        .closest(".form-group")
        .removeClass("has-success")
        .addClass("has-error");
    }
  }

  new FormValidator($(".validate"));

  PS.Utils.FormValidator = FormValidator;
});
