<template>
  <notification
    v-if="hasErrors"
    :variant="variant"
    :title="title"
    :dismissible="dismissible"
    v-on="$listeners"
  >
    <ul>
      <li v-for="error in errors" :key="error">
        {{ error }}
      </li>
    </ul>
  </notification>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import Notification from "components/shared/notification";

export default {
  name: "error-messages",
  components: {
    Notification,
  },
  props: {
    /**
     * If `true`, then a close button will be rendered.
     * `dismissed` event will be triggered if button is clicked.
     */
    dismissible: Boolean,
    /**
     * The title to render. Optional.
     * Use named-slot `title` if customization is needed.
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Errors to render. If empty or null, this component is not rendered.
     */
    errors: {
      type: Array,
      default: null,
    },
    /**
     * Notification variant, defaults to danger.
     */
    variant: {
      type: String,
      default: "danger",
    },
  },
  computed: {
    hasErrors() {
      return !isEmpty(this.errors);
    },
  },
};
</script>
