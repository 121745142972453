<template>
  <ul class="creditbox-issues">
    <li v-for="(rule, index) in results" :class="'rule-' + rule.name">
       {{rule.value}}
    </li>
  </ul>
</template>

<script>
export default {
  name: "creditbox-results",
  props: {
    results: {
      type: Array,
      required: true,
    },
  },
};
</script>
