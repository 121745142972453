<template>
  <div>
    <base-checkbox
      v-model="preview.value.value"
      :native-value="preview.name"
      :disabled="true"
    >
      {{ preview.name }}
    </base-checkbox>
  </div>
</template>

<script>
import Preview from "models/workflow_engine/preview";

// import VariableComment from "../variable_comment";

export default Vue.extend({
  name: "checkbox-preview-component",
  components: {
    // VariableComment,
  },
  props: {
    preview: {
      type: Preview,
      required: true,
    },
  },
  mounted() {
    this.preview.value = { value: this.preview.value.value };
  },
});
</script>
