import EventsSection from "components/activity_feed_events/events_section";
import Form from "models/lender_form/form";
import CreditBoxInlineGuide from "components/credit_box/credit_box_inline_guide.vue";
import LoanHeader from "components/loans/header";
import ActivityEventsSection from "components/activity_feed_events/section";
import BorrowerInfoSection from "components/borrower/info_section";
import ClosingAgentInfoSection from "components/closing_agent/info_section";
import PhysicalDocsTrackingSection from "components/physical_docs_tracking_numbers/section";
import LoanSuspensionDetails from "components/loan_suspension/details";
import BlockedPartyAlert from "components/loans/blocked_party_detected_alert";
import MessageBusService from "services/message_bus_service";
import DocumentRules from "models/loan/rules/document_rules";
import LoanDocumentsTip from "components/loans/loan_documents_tip";
import InlineDatePicker from "components/loans/inline_date_picker";
import AjaxService from "services/ajax_service";
import LoanRequestsHistory from "components/loans/loan_requests_history";
import LoanGuide from "components/loan_guide/index";
import PriceStatus from "components/price_status/price_status";
import ShareTasksBanner from "components/loans/share_tasks_banner";
import ShareTasksButton from "components/loans/share_tasks_button";
import BorrowerInviteModal from "components/activity_feed_events/loan_tasks/borrower_invite_modal";
import DisclosureBanner from "components/disclosures/disclosure_banner";
import PrepareDisclosureModal from "components/disclosures/prepare_disclosure_modal";
import SendDisclosureModal from "components/disclosures/send_disclosure_modal";
import LoanStatusTracker from "components/loan_status_tracker";
import GlobalBusService from "services/global_bus_service";
import Location from "services/location_service";
import SignatureRequestsTable from "components/disclosures/signature_requests_table";
import LoanProgramsService from "services/loan_programs_service";

$(() => {
  if (!document.querySelector(".v-loan-controller")) {
    return;
  }

  new Vue({
    components: {
      CreditBoxInlineGuide,
      EventsSection,
      ActivityEventsSection,
      LoanHeader,
      BorrowerInfoSection,
      ClosingAgentInfoSection,
      PhysicalDocsTrackingSection,
      LoanSuspensionDetails,
      LoanDocumentsTip,
      InlineDatePicker,
      LoanRequestsHistory,
      LoanGuide,
      PriceStatus,
      BlockedPartyAlert,
      ShareTasksBanner,
      ShareTasksButton,
      BorrowerInviteModal,
      DisclosureBanner,
      PrepareDisclosureModal,
      SendDisclosureModal,
      SignatureRequestsTable,
      LoanStatusTracker,
    },
    el: document.querySelector(".v-loan-controller"),
    data: {
      form: new Form(PSData.loan || {}),
      documents: PSData.documents,
      loan: PSData.loan,
      loanProgramId: undefined,
      events: PSData.events,
      hasUnreadMessages: PSData.hasUnreadMessages,
      creditBoxResults: [],
      isLoadingCreditbox: false,
      programOptions: [],
      isLoadingOptions: false,
      routes: PSData.routes,
      taskIsIncomplete: PSData.taskIsIncomplete,
      tasks: PSData.tasks,
      payoffRequests: PSData.payoffRequests,
      messageBus: MessageBusService.loanBus,
      ruleBook: undefined,
      loading: false,
      showInviteBorrowerModal: false,
      showPrepareDisclosureModal: false,
      showSendDisclosureModal: false,
      showBlockedPartyAlert: PSData.nflHit,
      disclosureOptions: PSData.disclosureOptions,
      viewLpsResults: PSData.loanPolicy.viewLpsResults.allowed,
      hasErrorsLoadingCreditbox: false,
    },
    mounted() {
      this.form.update();

      this.rulebook = new DocumentRules(this);
      this.messageBus.attachRulebook(this.rulebook);

      if (this.form.isReadyForCreditbox) {
        if (this.showProgramOptions) {
          this.getProgramOptions();
        } else {
          this.getCreditboxResults();
        }
      }
      GlobalBusService.$on("open-invite-borrower", this.openInviteBorrower);
      GlobalBusService.$on("open-prepare-disclosure", this.openPrepareDisclosure);
      GlobalBusService.$on("open-send-disclosure", this.openSendDisclosure);
      GlobalBusService.$on("disclosure-prepared", this.onDisclosurePrepared);
      GlobalBusService.$on("disclosure-sent", this.onDisclosureSent);
      GlobalBusService.$on("lps-explain", this.lpsExplain);
    },
    computed: {
      uiBlocked() {
        return this.loanProgramId !== undefined;
      },
      multifamilyBridgeEnabled() {
        return PSData.featureFlag.multifamilyBridge;
      },
      allowSubmitToEvaluation() {
        return this.form.isComplete;
      },
      isAcquisition() {
        return this.loan.loanPurpose === "acquisition";
      },
      isFunded() {
        return this.loan.lenderNoteStatus === "funded";
      },
      isFundingIntoOrigination() {
        return this.loan.lenderNoteStatus === "funding_into_origination";
      },
      showCema() {
        return this.loan.state === "NY";
      },
      showProgramOptions() {
        return this.loan.underwritingStatus === "draft";
      },
      showPaidHardConstructionCosts() {
        return ["refinance", "cash_out_refinance"].includes(this.loan.loanPurpose);
      },
    },
    methods: {
      onSubmitForEvaluation(loanProgram) {
        this.loanProgramId = loanProgram.id;

        Vue.nextTick(() => {
          this.$el.querySelector("#edit_resource").submit();
        });
      },
      updateDocuments(documents) {
        this.documents = documents;
      },
      updateLenderTargetPurchaseDate(value) {
        if (this.loading) return;

        this.loading = true;

        const data = { lender_target_purchase_date: value };
        const url = `/loans/${this.loan.toParam}/target_purchase_date`;
        const headers = { "Content-Type": "application/json" };

        const messages = {
          onSuccess: "Requested Wire Date was successfully updated.",
          onError: ({ response, data }) => {
            if (response.status === 422) {
              return {
                message: data.errors,
                status: "error",
              };
            } else {
              return {
                message: "Couldn't update Requested Wire Date.",
                status: "error",
              };
            }
          },
        };

        return AjaxService.withNotifications(messages)
          .patch(url, data, headers)
          .catch(() => {})
          .finally((this.loading = false));
      },
      async getCreditboxResults() {
        if (this.isLoadingCreditbox) {
          return;
        }

        const url = this.routes.loanCreditbox;
        this.isLoadingCreditbox = true;

        try {
          const result = await AjaxService.getJSON(url);
          this.lpsEvaluationId = result.id;
          this.creditBoxResults = result.creditbox || [];
          this.hasErrorsLoadingCreditbox = false;
        } catch (e) {
          this.hasErrorsLoadingCreditbox = true;
        } finally {
          this.isLoadingCreditbox = false;
        }
      },
      async getProgramOptions() {
        if (this.isLoadingOptions) {
          return;
        }

        const url = this.routes.loanOptions;
        this.isLoadingOptions = true;

        try {
          const result = await AjaxService.getJSON(url);
          this.lpsEvaluationId = result.id;
          this.programOptions = result.options;
          this.hasErrorsLoadingCreditbox = false;
        } catch (e) {
          this.hasErrorsLoadingCreditbox = true;
        } finally {
          this.isLoadingOptions = false;
        }
      },
      lpsExplain(context) {
        LoanProgramsService.explainEvaluationResults(this.lpsEvaluationId, context);
      },
      openInviteBorrower() {
        this.$nextTick(() => {
          this.showInviteBorrowerModal = true;
        });
      },
      closeInviteBorrowerModal() {
        this.showInviteBorrowerModal = false;
      },
      openPrepareDisclosure() {
        this.$nextTick(() => {
          this.showPrepareDisclosureModal = true;
        });
      },
      closePrepareDisclosureModal() {
        this.showPrepareDisclosureModal = false;
      },
      onDisclosurePrepared(updatedLoan) {
        this.$set(this.loan, "lenderNoteStatus", updatedLoan.lenderNoteStatus);
        this.$set(this.loan, "lenderEntityLegalEntityId", updatedLoan.lenderEntityLegalEntityId);
        this.$set(this.loan, "brokeringEntityId", updatedLoan.brokeringEntityId);
        this.$set(this.loan, "lender", { ...updatedLoan.lender });
        this.$nextTick(() => {
          this.openSendDisclosure();
        });
      },
      openSendDisclosure() {
        this.showSendDisclosureModal = true;
      },
      closeSendDisclosureModal() {
        this.showSendDisclosureModal = false;
      },
      onDisclosureSent() {
        Location.reload();
      },
    },
  });
});
