const Events = PS.Models.Autocomplete.Events;
const Commands = PS.Models.Autocomplete.Commands;
const Rule = PS.Models.Rule;
const CONTROL_STATE = PS.Models.Autocomplete.Const.CONTROL_STATE;

class AutocompleteRule extends Rule {
  constructor(state) {
    super(state);

    if (state.dataSource === undefined) {
      throw "AutocompleteRule#constructor should be called with state including @dataSource";
    }
    this.sourceId = state.dataSource.sourceId;
  }

  check(event) {
    if (event.sourceId !== this.sourceId) {
      return super.check(event);
    }

    switch (event.constructor) {
      case Events.SetupRequested:
        return this.whenSetupRequested(event);

      case Events.SetupAutocompleteFinished:
        return this.whenSetupAutocompleteFinished(event);

      case Events.AutocompleteSearchRequested:
        return this.whenAutocompleteSearchRequested(event);

      case Events.AutocompleteSearchFinished:
        return this.whenAutocompleteSearchFinished(event);

      case Events.SuggestionSelectRequested:
        return this.whenSuggestionSelectRequested(event);

      case Events.SuggestionValidationRequested:
        return this.whenSuggestionValidationRequested(event);

      case Events.SelectResultRequested:
        return this.whenSelectResultRequested(event);

      case Events.NextSuggestionRequested:
        return this.whenNextSuggestionRequested(event);

      case Events.PrevSuggestionRequested:
        return this.whenPrevSuggestionRequested(event);

      case Events.ShowSuggestionsRequested:
        return this.whenShowSuggestionsRequested(event);

      case Events.HideSuggestionsRequested:
        return this.whenHideSuggestionsRequested(event);

      case Events.SimulateAutocompleteValidationRequested:
        return this.whenSimulateAutocompleteValidationRequested(event);
    }

    return super.check(event);
  }

  whenSetupRequested(event) {
    return [new Commands.SetupAutocomplete(event, this.state)];
  }

  whenSetupAutocompleteFinished(event) {
    return [new Commands.FinishSetupAutocomplete(event, this.state)];
  }

  whenSelectResultRequested(event) {
    const suggestionSelected = this.state.selectedPosition > -1;

    switch (this.state.controlState) {
      case CONTROL_STATE.EMPTY:
      case CONTROL_STATE.HIDE_SUGGESTIONS:
        return [
          new Commands.ApproveNextQueryUpdate(event, this.state),
          new Commands.RequestAutocomplete(event, this.state),
        ];

      case CONTROL_STATE.SHOW_SUGGESTIONS:
        if (suggestionSelected) {
          return [
            new Commands.HideSuggestions(event, this.state),
            new Commands.IgnoreNextQueryUpdate(event, this.state),
            new Commands.ApplyCurrentSuggestion(event, this.state),
            new Commands.RequestSuggestionValidation(event, this.state),
          ];
        } else {
          return [
            new Commands.ApproveNextQueryUpdate(event, this.state),
            new Commands.RequestAutocomplete(event, this.state),
          ];
        }
    }

    return [];
  }

  whenAutocompleteSearchRequested(event) {
    if (this.state.ignoreNextQueryUpdate) {
      return [new Commands.ApproveNextQueryUpdate(event, this.state)];
    }

    const allowNextSearch = event.query.trim().length >= this.state.minCharsToSearch;
    if (allowNextSearch) {
      return [
        new Commands.ResetState(event, this.state),
        new Commands.RunAutocomplete(event, this.state),
      ];
    } else {
      return [
        new Commands.ResetState(event, this.state),
        new Commands.ResetAutocomplete(event, this.state),
        new Commands.ResetSuggestions(event, this.state),
      ];
    }
  }

  whenAutocompleteSearchFinished(event) {
    return [
      new Commands.ResetSuggestions(event, this.state),
      new Commands.SaveAutocompleteResult(event, this.state),
      new Commands.CheckAutocompleteForErrors(event, this.state),
    ];
  }

  whenSuggestionValidationRequested(event) {
    return [new Commands.ValidateSuggestion(event, this.state)];
  }

  whenNextSuggestionRequested(event) {
    return [
      new Commands.SelectNextSuggestion(event, this.state),
      new Commands.IgnoreNextQueryUpdate(event, this.state),
      new Commands.ApplyCurrentSuggestion(event, this.state),
    ];
  }

  whenPrevSuggestionRequested(event) {
    return [
      new Commands.SelectPrevSuggestion(event, this.state),
      new Commands.IgnoreNextQueryUpdate(event, this.state),
      new Commands.ApplyCurrentSuggestion(event, this.state),
    ];
  }

  whenSuggestionSelectRequested(event) {
    return [
      new Commands.SelectSuggestion(event, this.state),
      new Commands.HideSuggestions(event, this.state),
      new Commands.IgnoreNextQueryUpdate(event, this.state),
      new Commands.ApplyCurrentSuggestion(event, this.state),
      new Commands.RequestSuggestionValidation(event, this.state),
    ];
  }

  whenShowSuggestionsRequested(event) {
    if (this.state.controlState !== CONTROL_STATE.EMPTY) {
      return [new Commands.ShowSuggestions(event, this.state)];
    }

    return [];
  }

  whenHideSuggestionsRequested(event) {
    if (this.state.controlState === CONTROL_STATE.SHOW_SUGGESTIONS) {
      return [new Commands.HideSuggestions(event, this.state)];
    }

    return [];
  }

  whenSimulateAutocompleteValidationRequested(event) {
    return [
      new Commands.SelectSuggestion(event, this.state),
      new Commands.HideSuggestions(event, this.state),
      new Commands.IgnoreNextQueryUpdate(event, this.state),
      new Commands.ApplyCurrentSuggestion(event, this.state),
    ];
  }
}

PS.Models.Autocomplete.Rule = AutocompleteRule;
