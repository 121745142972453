import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";

import Grid from "./pricing_grids/grid";

export default class PricingGrid {
  static deserialize({
    id,
    label,
    tier,
    loanProgramId,
    startedAt,
    gracePeriod,
    endedAt,
    maxLenderSpread,
    minPurchaseRate,
    grid,
  }) {
    return new PricingGrid(
      id,
      label,
      tier,
      loanProgramId,
      startedAt,
      gracePeriod,
      endedAt,
      maxLenderSpread,
      minPurchaseRate,
      Grid.deserialize(grid)
    );
  }

  static buildEmpty() {
    return new PricingGrid(null, null, null, null, null, null, null, null, null, new Grid());
  }

  constructor(
    id,
    label,
    tier,
    loanProgramId,
    startedAt,
    gracePeriod,
    endedAt,
    maxLenderSpread,
    minPurchaseRate,
    grid
  ) {
    this.id = id;
    this.label = label;
    this.tier = tier;
    this.loanProgramId = loanProgramId;
    this.startedAt = startedAt;
    this.gracePeriod = gracePeriod;
    this.endedAt = endedAt;
    this.maxLenderSpread = maxLenderSpread;
    this.minPurchaseRate = minPurchaseRate;
    this.grid = grid;
  }

  isPersisted() {
    return !!this.id;
  }

  copy() {
    return new PricingGrid(
      null,
      this.label,
      this.tier,
      this.loanProgramId,
      this.startedAt,
      this.gracePeriod,
      this.endedAt,
      this.maxLenderSpread,
      this.minPurchaseRate,
      this.grid.copy()
    );
  }

  toPlainObject({ toSnakeCase } = { toSnakeCase: false }) {
    const grid = {
      id: this.id,
      label: this.label,
      tier: this.tier,
      loanProgramId: this.loanProgramId,
      startedAt: this.startedAt,
      gracePeriod: this.gracePeriod,
      endedAt: this.endedAt,
      maxLenderSpread: this.maxLenderSpread,
      minPurchaseRate: this.minPurchaseRate,
      grid: this.grid.toPlainObject({ toSnakeCase }),
    };

    if (toSnakeCase) return mapKeys(grid, (_, key) => snakeCase(key));

    return grid;
  }
}
