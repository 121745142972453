<template>
  <div class="reserve-draw-request-editable-fields">
    <div class="details-row no-border row">
      <div class="col-3">
        <h3 class="row-header">Draw Amount</h3>
      </div>
      <div class="fields-container col-9">
        <div class="fields-row">
          <div class="col-4">
            <label>Draw Number <span>(sequential)</span></label>
            <input
              class="form-control"
              :value="reserveDrawRequest.drawRequestNumber"
              @input="e => updateDrawRequest('drawRequestNumber', e.target.value)"
            />
          </div>
          <div class="col-4 net-draw-amount-column">
            <label>Net Draw Amount</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" :value="reserveDrawRequest.approvedDrawAmountNumber()" v-mask:currency disabled />
            </div>
          </div>
        </div>
        <div class="fields-row">
          <div class="col-4">
            <label>Request Amount</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                class="form-control"
                :value="reserveDrawRequest.requestedDrawAmount"
                @input="e => updateDrawRequest('requestedDrawAmount', e.target.value)"
                v-mask:currency
              />
            </div>
          </div>
        </div>
        <div class="fields-row">
          <div class="col-4">
            <label>Approved Reserve Draw</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                class="form-control"
                :value="reserveDrawRequest.approvedDrawAmount"
                @input="e => updateDrawRequest('approvedDrawAmount', e.target.value)"
                v-mask:currency
              />
            </div>
          </div>
          <div class="col-4 reimburse-check">
            <div class="checkbox-control">
              <input
                id="reimburse-draw-amount-check"
                type="checkbox"
                :checked="reserveDrawRequest.reimburseLenderDrawAmount"
                @input="e => updateDrawRequest('reimburseLenderDrawAmount', e.target.checked)"
              />
              <label for="reimburse-draw-amount-check">
                <span class="control-toggle"></span>
                <span>Reimburse Lender</span>
              </label>
            </div>
          </div>
        </div>
        <div class="fields-row">
          <div class="col-4">
            <label>Borrower Contribution Amount</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                class="form-control"
                :value="reserveDrawRequest.borrowerContributedAmount()"
                v-mask:currency
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="details-row no-border row">
      <div class="col-3">
        <h3 class="row-header">Fees &amp; Reimbursements</h3>
      </div>
      <div class="fields-container col-9">
        <div class="fields-row">
          <div class="col-4">
            <label>Insp. Fee</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                class="form-control"
                :value="reserveDrawRequest.inspectionFee"
                @input="e => updateDrawRequest('inspectionFee', e.target.value)"
                v-mask:currency
              />
            </div>
          </div>
          <div class="col-4 reimburse-check">
            <div class="checkbox-control">
              <input
                id="reimburse-inspection-fee-check"
                type="checkbox"
                :checked="reserveDrawRequest.reimburseLenderInspectionFee"
                @input="e => updateDrawRequest('reimburseLenderInspectionFee', e.target.checked)"
              />
              <label for="reimburse-inspection-fee-check">
                <span class="control-toggle"></span>
                <span>Reimburse Lender</span>
              </label>
            </div>
          </div>
          <div class="col-4">
            <label>Net Wire Amount</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" :value="reserveDrawRequest.netWireAmount()" v-mask:currency disabled />
            </div>
          </div>
        </div>
        <div class="fields-row">
          <div class="col-4">
            <label>Admin. Fee</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                class="form-control"
                :value="reserveDrawRequest.administrationFee"
                @input="e => updateDrawRequest('administrationFee', e.target.value)"
                v-mask:currency
              />
            </div>
          </div>
          <div class="col-4 reimburse-check">
            <div class="checkbox-control">
              <input
                id="reimburse-admin-fee-check"
                type="checkbox"
                :checked="reserveDrawRequest.reimburseLenderAdministrationFee"
                @input="e => updateDrawRequest('reimburseLenderAdministrationFee', e.target.checked)"
              />
              <label for="reimburse-admin-fee-check">
                <span class="control-toggle"></span>
                <span>Reimburse Lender</span>
              </label>
            </div>
          </div>
        </div>
        <div class="fields-row">
          <div class="col-4">
            <label>Setup Fee</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                class="form-control"
                :value="reserveDrawRequest.setupFee"
                @input="e => updateDrawRequest('setupFee', e.target.value)"
                v-mask:currency
              />
            </div>
          </div>
          <div class="col-4 reimburse-check">
            <div class="checkbox-control">
              <input
                id="reimburse-setup-fee-check"
                type="checkbox"
                :checked="reserveDrawRequest.reimburseLenderSetupFee"
                @input="e => updateDrawRequest('reimburseLenderSetupFee', e.target.checked)"
              />
              <label for="reimburse-setup-fee-check">
                <span class="control-toggle"></span>
                <span>Reimburse Lender</span>
              </label>
            </div>
          </div>
        </div>
        <div class="fields-row">
          <div class="col-4">
            <label>Other Fee</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                class="form-control"
                :value="reserveDrawRequest.otherFee"
                @input="e => updateDrawRequest('otherFee', e.target.value)"
                v-mask:currency
              />
            </div>
          </div>
          <div class="col-4 reimburse-check">
            <div class="checkbox-control">
              <input
                id="reimburse-other-fee-check"
                type="checkbox"
                :checked="reserveDrawRequest.reimburseLenderOtherFee"
                @input="e => updateDrawRequest('reimburseLenderOtherFee', e.target.checked)"
              />
              <label for="reimburse-other-fee-check">
                <span class="control-toggle"></span>
                <span>Reimburse Lender</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="details-row no-border row">
      <div class="col-3">
        <h3 class="row-header">Additional Notes / Comments</h3>
      </div>
      <div class="fields-container col-9">
        <div class="fields-row">
          <div class="notes-column col-8">
            <label>Notes</label>
            <textarea
              class="form-control"
              :value="reserveDrawRequest.notes"
              @input="e => updateDrawRequest('notes', e.target.value)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reserve-draw-request-editable-fields",
  props: {
    reserveDrawRequest: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    updateDrawRequest(name, value) {
      this.$emit("update-draw-request", name, value);
    },
  },
};
</script>
