<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content cancel-settlement-statement-modal">
      <div class="modal-header">
        <h4>Cancel Settlement Statement</h4>
      </div>
      <div class="modal-body">
        <p>
          Are you sure you want to cancel the Settlement Statement? This cannot be undone.
        </p>
        <form>
          <div :class="['form-group', 'control-wrapper', {'has-error': errors.has('reason')}]">
            <label for="reason">Please specify reason below:</label>
            <select name="reason"
                    id="reason"
                    class="form-control"
                    v-model="settlementStatementCancellation.reason"
                    v-validate="'required'">
              <option disabled selected value="">Select</option>
              <option value="Statement created in error">Statement created in error</option>
              <option value="Loan will not be purchased">Loan will not be purchased</option>
              <option value="other">Other (Specify in Notes)</option>
            </select>
            <span class="error control-label" v-show="errors.has('reason')">
              Reason is required.
            </span>
          </div>
          <div :class="['form-group', 'control-wrapper', {'has-error': errors.has('notes')}]">
            <label for="notes">Notes</label>
            <textarea name="notes"
                      id="notes"
                      cols="30"
                      rows="4"
                      :placeholder="notesPlaceholder"
                      class="form-control"
                      v-model="settlementStatementCancellation.notes"
                      v-validate="{ required: isNotesValidationRequired }"/>
            <span class="error control-label" v-show="errors.has('notes')">
              Notes are required when the reason is "Other".
            </span>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="btn btn-cancel-cta"
             @click="close"
             :disabled="isSubmitting">
          No, Don't Cancel
        </div>
        <div class="btn btn-action-cta"
             @click="save"
             :disabled="isSubmitting">
          Yes, Cancel
        </div>
      </div>
    </div>
  </modal-view>
</template>

<script>
import ModalView from "components/modal_view";
import Ajax from "services/ajax_service";

export default {
  name: "cancel-settlement-statement-modal",
  $_veeValidate: {
    validator: "new",
  },
  components: { ModalView },
  props: {
    show: Boolean,
    onClose: Function,
    settlementStatement: Object,
  },
  data() {
    return {
      isSubmitting: false,
      settlementStatementCancellation: {
        reason: "",
      },
    };
  },
  computed: {
    apiParams() {
      return {
        settlement_statement: {
          cancel_repair_reason: this.settlementStatementCancellation.reason,
          cancel_repair_comment: this.settlementStatementCancellation.notes,
          id: this.settlementStatement.id,
        },
      };
    },
    isNotesValidationRequired() {
      return this.settlementStatementCancellation.reason === "other";
    },
    notesPlaceholder() {
      return this.isNotesValidationRequired ? "" : "Optional";
    },
  },
  methods: {
    close() {
      this.$validator.reset();
      this.onClose();
    },
    save() {
      if (this.isSubmitting) {
        return;
      }

      this.$validator.validateAll().then(result => {
        if (!result) {
          return;
        }

        this.cancelSettlementStatement();
      });
    },
    cancelSettlementStatement() {
      this.isSubmitting = true;

      const messages = {
        onSuccess: "Settlement Statement cancelled.",
        onError: this.onError,
      };

      return Ajax.withNotifications(messages)
        .post(`/manage/settlement_statements/${this.settlementStatement.id}/cancel`, this.apiParams)
        .then(data => {
          this.$emit("statement-cancelled");
          this.onClose();
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    onError({ data }) {
      return {
        message: data,
        status: "error",
      };
    },
  },
};
</script>
