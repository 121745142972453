<template>
  <base-date-field
    v-model="preview.value.value"
    :label="preview.name"
    disabled
  />
</template>

<script>
import Preview from "models/workflow_engine/preview";

export default Vue.extend({
  name: "workflow-date-preview-component",
  props: {
    preview: {
      type: Preview,
      required: true,
    },
  },
});
</script>
