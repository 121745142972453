<template>
  <h1 class="py-5 text-center font-weight-bold text-muted">
    {{ text }}
  </h1>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
