<template>
  <div class="loan-documents-tab">
    <div class="row loan-doc-checklist-container">
      <div class="col-8">
        <div class="external-documents">
          <h3>
            External Documents
            <upload-button :initial-options="uploadSettings">Upload</upload-button>
          </h3>

          <loans-documents-list
            :documents="externalDocuments"
            :selected-document="selectedDocument"
            :allow-edit="true"
            :blocked-document-ids="blockedDocumentIds"
            @on-edit="showEditorModal"
          />
        </div>

        <div class="internal-documents">
          <h3>Internal Documents</h3>

          <loans-documents-list
            :documents="internalDocuments"
            :selected-document="selectedDocument"
            :allow-edit="true"
            :blocked-document-ids="blockedDocumentIds"
            @on-edit="showEditorModal"
          />
        </div>
      </div>

      <div class="col-4 checklist-container">
        <analyst-documents-checklist
          v-show="displayChecklist"
          :documents="documents"
          :loan="loan"
          :types="primaryDocumentTypes"
        />

        <div class="sticky-element loan-documents-editor-container" data-offset="200">
          <loan-documents-editor
            v-if="displayEditor"
            :document="selectedDocument"
            :blocked-document-ids="blockedDocumentIds"
            :document-types="currentDocumentTypes"
            @on-close="checkModalVisibility"
            @on-save="blockUI"
            @on-document-deleted="unblockUI"
            @on-before-delete="blockUI"
          />
          <loans-documents-preview
            v-if="displayPreview"
            :document="selectedDocument"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoansDocumentsList from "components/loans/documents/list";
import LoanDocumentsEditor from "components/loans/documents/editor";
import LoansDocumentsPreview from "components/loans/documents/preview";
import AnalystDocumentsChecklist from "components/loans/analyst_documents_checklist";
import UploadButton from "components/js_upload";
import MessageBusService from "services/message_bus_service";
import { OneDocumentUpdated } from "models/loan/events/document_events";

const VISIBILITY = {
  PUBLIC: "public",
  INTERNAL: "internal",
};

const DISPLAY_MODE = {
  EDITOR: "EDITOR",
  CHECKLIST: "CHECKLIST",
};

export default {
  name: "loan-documents-tab",
  components: {
    LoansDocumentsList,
    AnalystDocumentsChecklist,
    UploadButton,
    LoanDocumentsEditor,
    LoansDocumentsPreview,
  },
  props: {
    documents: Array,
    loan: Object,
    documentTypes: Array,
    primaryDocumentTypes: Array,
    uploadSettings: Object,
  },
  data() {
    return {
      displayMode: DISPLAY_MODE.CHECKLIST,
      selectedDocument: undefined,
      blockedDocumentIds: [],
      externalDocumentTypes: PSData.loanDocumentTypes,
      internalDocumentTypes: PSData.internalDocumentTypes,
    };
  },
  mounted() {
    MessageBusService.loanBus.subscribe(event => {
      if (event.constructor === OneDocumentUpdated && !event.options.nonUserUpdate) {
        this.unblockUI(event.document);
        this.checkModalVisibility(event.document);
      }
    });
  },
  watch: {
    documents(newDocuments) {
      if (this.selectedDocument) {
        const newDocument = newDocuments.find(doc => doc.id === this.selectedDocument.id);
        if (newDocument) {
          this.selectedDocument = newDocument;
        }
      }
    },
  },
  computed: {
    externalDocuments() {
      return this.documents.filter(doc => doc.visibility === VISIBILITY.PUBLIC);
    },
    internalDocuments() {
      return this.documents.filter(doc => doc.visibility === VISIBILITY.INTERNAL);
    },
    displayChecklist() {
      return this.displayMode === DISPLAY_MODE.CHECKLIST;
    },
    displayEditor() {
      return this.displayMode === DISPLAY_MODE.EDITOR;
    },
    displayPreview() {
      return !!this.selectedDocument;
    },
    currentDocumentTypes() {
      switch ((this.selectedDocument || {}).visibility) {
        case VISIBILITY.INTERNAL:
          return this.internalDocumentTypes;

        case VISIBILITY.PUBLIC:
        default:
          return this.externalDocumentTypes;
      }
    },
  },
  methods: {
    showEditorModal(selectedDocument) {
      this.displayMode = DISPLAY_MODE.EDITOR;
      this.selectedDocument = selectedDocument;
    },
    blockUIAndHideModal(document) {
      this.blockUI(document);
      this.checkModalVisibility(document);
    },
    checkModalVisibility(document) {
      if (this.selectedDocument && this.selectedDocument.id === document.id) {
        this.cancelModal();
      }
    },
    cancelModal() {
      this.displayMode = DISPLAY_MODE.CHECKLIST;
      this.selectedDocument = undefined;
    },
    unblockUI(document) {
      Vue.delete(this.blockedDocumentIds, this.blockedDocumentIds.indexOf(document.id));
    },
    blockUI(document) {
      if (!this.blockedDocumentIds.includes(document.id)) {
        this.blockedDocumentIds.push(document.id);
      }
    },
  },
};
</script>
