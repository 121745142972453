<template>
  <div class="d-flex my-4">
    <div class="pr-3" style="width: 150px">
      <date-filter
        name="fromDate"
        :value="fromDate"
        label="From"
        @filter="handleFilter"
      />
    </div>
    <div class="pr-3" style="width: 150px">
      <date-filter
        name="toDate"
        :value="toDate"
        label="To"
        @filter="handleFilter"
      />
    </div>
  </div>
</template>

<script>
import DateFilter from "../../shared/filters/date_filter";

export default {
  components: {
    DateFilter,
  },
  props: {
    fromDate: {
      type: String,
      default: "",
    },
    toDate: {
      type: String,
      default: "",
    },
  },
  computed: {
    filters() {
      const { fromDate, toDate } = this;
      return {
        fromDate,
        toDate,
      };
    },
  },
  mounted() {
    this.$emit("filter", this.filters);
  },
  methods: {
    handleFilter(name, value) {
      this.$emit("filter", { ...this.filters, [name]: value });
    },
  },
};
</script>
