<template>
  <div
    v-if="isApproved"
    :class="className"
    :title="title"
    data-toggle="tooltip"
    data-placement="bottom"
    class="permitted-deal"
  ></div>
  <div v-else :class="className" class="permitted-deal"></div>
</template>

<script>
export default {
  name: "purchasing-permissions-funding-type",
  props: {
    fundingType: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  computed: {
    isApproved() {
      return this.fundingType.approved;
    },
    canTransact() {
      return this.fundingType.canTransact;
    },
    showMessageIcon() {
      return this.isApproved && !!this.fundingType.message;
    },
    className() {
      return {
        [`type-${this.fundingType.noteOrigination}`]: true,
        condition: this.showMessageIcon,
        restricted: !this.canTransact,
        disabled: !this.isApproved,
      };
    },
    title() {
      return this.fundingType.message;
    },
  },
};
</script>
