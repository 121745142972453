<template>
  <div>
    <action-button
      :actions="actions"
      :disabled="disabled"
      @complete="onComplete"
      @close="showModal('closeWorkflow')"
    />
    <close-workflow-modal
      v-if="modals.closeWorkflow"
      :closing-reasons="closingReasons"
      :is-saving="isSaving"
      @save="onClose"
      @close="closeModal('closeWorkflow')"
    />
  </div>
</template>
<script>
import ActionButton from "./action_button";
import AjaxService from "services/ajax_service";
import CloseWorkflowModal from "./close_workflow/modal";
import objectToSnakeCase from "utils/object_to_snake_case";
import useAssetManagementStore from "controllers/manage/store/asset_management";

export default {
  components: {
    ActionButton,
    CloseWorkflowModal,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      required: true,
    },
    closingReasons: {
      type: Array,
      default: () => [],
    },
    hasOpenTasks: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      modals: {
        closeWorkflow: false,
      },
      isSaving: false,
      loanPsId: PSData.loan.toParam,
    };
  },
  computed: {
    actions() {
      return [
        {
          label: "Mark as Complete",
          enabled: this.isCompletable,
          event: "complete",
        },
        {
          label: "Mark as Closed (Incomplete)",
          enabled: this.isOpen,
          event: "close",
        },
      ];
    },
    isOpen() {
      return !this.model.closedAt;
    },
    isCompletable() {
      return this.isOpen && !this.hasOpenTasks;
    },
  },
  methods: {
    showModal(modalName) {
      this.modals[modalName] = true;
    },
    closeModal(modalName) {
      this.modals[modalName] = false;
    },
    completeWorkflow() {
      const url = this.model.routes.complete;
      return AjaxService.withNotifications({
        onSuccess: "Workflow was marked as completed",
        onError: "Failed to complete workflow",
      }).patchJSON(url);
    },
    async onComplete() {
      try {
        const workflow = await this.completeWorkflow();

        const store = useAssetManagementStore();
        store.updateWorkflow({ id: this.model.id, data: workflow });
        this.$emit("collapse-workflow");
      } catch ({ response, data }) {
        if (response.status === 422) {
          this.errors = data.errors;
        }
      }
    },
    closeWorkflow(form) {
      const url = this.model.routes.close;
      const params = objectToSnakeCase(form);
      return AjaxService.withNotifications({
        onSuccess: "Workflow was marked as closed",
        onError: "Failed to close workflow",
      }).patchJSON(url, params);
    },
    async onClose(form) {
      try {
        this.isSaving = true;
        const workflow = await this.closeWorkflow(form);

        const store = useAssetManagementStore();
        store.updateWorkflow({ id: this.model.id, data: workflow });
        this.closeModal("closeWorkflow");
        this.$emit("collapse-workflow");
      } catch ({ response, data }) {
        if (response.status === 422) {
          this.errors = data.errors;
        }
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
