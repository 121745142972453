<template>
  <div class="container-layout" :class="classNames">
    <div class="page-header">
      <slot name="header-wrapper">
        <h2>{{ title }} </h2>
      </slot>
      <slot name="header-menu" />
    </div>
    <slot name="tabs" />
  </div>
</template>

<script>
export default {
  name: "page-header",
  props: {
    classNames: {
      type: [String, Array],
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
