<template>
  <div>
    <div class="row loan-details-view-panel-row">
      <div class="col-6 loan-details-view-panel-column">
        <credit-reports-table
          :reports="reports"
          @on-add-new-report="openNewReportModal"
          @on-report-edit="editReport"
          @on-report-delete="deleteReport"
        />
      </div>
    </div>
    <credit-report-modal
      v-if="showReportModal"
      :report="selectedReport"
      :show="showReportModal"
      :credit-score-vendors="creditScoreVendors"
      :report-types="reportTypes"
      :report-originator-types="reportOriginatorTypes"
      :routes="routes"
      @on-close="closeReportModal"
      @on-save="updateReports"
    />
  </div>
</template>

<script>
import CreditReportsTable from "../credit_reports/table";
import CreditReportModal from "../credit_reports/modal";
import CreditReport from "models/credit_report";
import AjaxService from "services/ajax_service";

export default {
  name: "credit-report-detials",
  components: {
    CreditReportsTable,
    CreditReportModal,
  },
  props: {
    wrapperClass: {
      type: String,
      default: "",
    },
    routes: {
      type: Object,
      required: true,
    },
    creditReportOptions: {
      type: Object,
      required: true,
    },
    creditReports: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      reports: [...this.creditReports],
      selectedReport: null,
    };
  },
  computed: {
    showReportModal() {
      return !!this.selectedReport;
    },
    reportTypes() {
      return this.creditReportOptions.creditReportTypes;
    },
    reportOriginatorTypes() {
      return this.creditReportOptions.creditReportOriginatorTypes;
    },
    creditScoreVendors() {
      return this.creditReportOptions.creditScoreVendors;
    },
  },
  methods: {
    closeReportModal() {
      this.selectedReport = null;
    },
    updateReports(reports) {
      this.reports = reports;
      this.closeReportModal();
    },
    editReport(report) {
      this.selectedReport = CreditReport.deserialize(report);
    },
    deleteReport(report) {
      const confirmMessage = "Are you sure you want to remove this credit report?";

      if (!window.confirm(confirmMessage)) return;

      const url = `${this.routes.creditReports}/${report.id}`;

      AjaxService.withNotifications()
        .delete(url)
        .then(reports => {
          this.reports = reports;
        })
        .catch(({ response, data }) => {
          if (response.status === 422) {
            this.errors = data.errors;
          } else {
            Bugsnag.notify(new Error(`Unable to delete credit report: ${response.statusText}`));
          }
        });
    },
    openNewReportModal() {
      this.selectedReport = CreditReport.buildEmpty(this.creditScoreVendors);
    },
  },
};
</script>
