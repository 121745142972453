<template>
  <loan-sales :sales="monthlySales" :goal="monthlyGoal" />
</template>

<script>
import { formatDate } from "filters";
import LoanSales from "components/loan_sales";

export default {
  name: "monthly-loan-sales",
  components: {
    LoanSales,
  },
  props: {
    sales: {
      type: Object,
      default: () => ({}),
    },
    goal: Number,
  },
  computed: {
    monthlySales() {
      return {
        period: `${formatDate(this.sales.month, "MMMM YYYY")}`,
        amount: this.sales.amount,
        count: this.sales.count,
      };
    },
    monthlyGoal() {
      return {
        title: "Monthly Goal",
        amount: this.goal,
      };
    },
  },
};
</script>
