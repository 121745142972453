<template>
  <validation-provider
    v-slot="{ errors, valid }"
    name="Loan IDs"
    :rules="rules"
    slim
  >
    <div class="form-group">
      <label>Loan IDs</label>
      <textarea
        v-model="innerValue"
        class="form-control loan-ids-input"
        :class="validationClass(valid)"
        placeholder="Copy and paste Loan IDs"
        name="loanIds"
        :disabled="disabled"
      />

      <div v-if="valid === false" class="invalid-feedback">
        {{ errors.map(({ msg }) => msg).join(", ") }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

const LOAN_ID_PREFIX = PSData.loanIdPrefix || "10";
const VALID_LOAN_IDS_FORMAT = new RegExp(`^((\\s|,)*(?:${LOAN_ID_PREFIX})?\\d{10}(\\s|,)*)+$`);

export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },
  data: () => ({ innerValue: "" }),
  computed: {
    rules() {
      return { required: true, regex: VALID_LOAN_IDS_FORMAT };
    },
  },
  watch: {
    innerValue: {
      immediate: true,
      handler(val) {
        this.$emit("input", this.formatValue(val));
      },
    },
  },
  methods: {
    validationClass: valid => (valid === null ? null : valid ? "is-valid" : "is-invalid"),
    formatValue: val => val.split(/,?\s+/).map(loanPsId => ({ loanPsId })),
  },
};
</script>
