<template>
  <div>
    <base-field label="Task Type" class="py-1">
      {{ formattedTaskType }}
    </base-field>
    <base-hr class="task-mapping-preview-hr" />
    <base-header size="small" class="pb-2">
      Creation
    </base-header>
    <base-select-field
      v-model="taskMapping.triggerStatus"
      :options="triggerStatuses"
      label="Trigger"
      placeholder="Select Trigger"
      :errors="errors.trigger_status"
      hint="The loan status that triggers the creation of this task."
    />
    <div v-if="!isConditionsEditable" class="pb=2">
      <base-message :closable="false" type="warning" title="Complex condtions editing is not supported yet">
        <p>Contact engineering team if you need to update this conditions</p>
      </base-message>
    </div>
    <base-field v-if="taskMapping.hasConditions" label="Conditions" class="pb-2">
      <rules-preview
        :disabled="!isConditionsEditable"
        :rules="taskMapping.conditions"
        class="description-preview-rules"
        @change="onRulesChange"
        :errors="errors.conditions"
        :filter-by-context="taskMapping.contextName"
      />
    </base-field>
    <div v-else class="is-flex is-align-items-center">
      <base-button type="link" class="p-0 pr-1 has-text-weight-semibold" @click="addConditions">
        Add Creation Conditions
      </base-button>
      <base-tooltip
        label="Add Custom Conditions."
        position="is-top"
        multilined
      >
        <b-icon
          pack="fas"
          size="is-small"
          icon="question-circle"
          type="is-ps-hint-icon"
        />
      </base-tooltip>
    </div>
  </div>
</template>
<script>
import LoanTaskDefiniton from "models/loan_task_definition";
import RulesPreview from "../rules/preview";
import startCase from "lodash/startCase";
import values from "lodash/values";
import isEmpty from "lodash/isEmpty";

export default {
  components: {
    RulesPreview,
  },
  props: {
    taskMapping: {
      type: Object,
      default: () => ({}),
    },
    triggerStatuses: {
      type: Array,
      default: () => [],
    },
    triggerRules: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    "taskMapping.triggerStatus": function() {
      if (this.errors.trigger_status) {
        this.$emit("clearTriggerStatusError");
      }
    },
  },
  computed: {
    definition() {
      return new LoanTaskDefiniton(this.taskMapping && this.taskMapping.taskDefinition);
    },
    formattedTaskType() {
      return startCase(this.definition.taskType);
    },
    isConditionsEditable() {
      return values(this.taskMapping.conditions).every(condition => {
        return values(condition).every(value => {
          return typeof value !== "object";
        });
      });
    },
  },
  methods: {
    addConditions() {
      this.$set(this.taskMapping, "hasConditions", true);
      this.$set(this.taskMapping, "conditions", { "": { "": "" } });
    },
    onRulesChange(rules) {
      if (isEmpty(rules)) {
        this.$set(this.taskMapping, "hasConditions", false);
      }
      this.$emit("updateRules", rules);
    },
  },
};
</script>
<style scoped>
.task-mapping-edit-form .task-mapping-preview-hr {
  margin: calc(var(--space-unit) * 2) calc(var(--space-unit) * -3);
}
</style>
