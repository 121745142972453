<template>
  <div>
    <categorized-bar-chart :chart="chart" :sources="sources" class="housing-stock-type-chart"></categorized-bar-chart>
    <div class="row listing-stats-explanation-section">
      <div class="col-md-10">
        <div>% Single Family Detached</div>
      </div>
      <div class="col-md-2 value-section">
        <span v-show="sources.isLoading">
          &nbsp;
        </span>
        <span v-show="sources.isSuccessful">
          {{ singleFamDetachedPct | percent(0, true) | notAvailable }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "d3";

import CategorizedBarChart from "components/market_data/categorized_bar_chart";

export default {
  name: "housing-stock-type-chart",
  components: {
    CategorizedBarChart,
  },
  props: {
    sources: Object,
  },
  data() {
    return {
      chart: {
        title: this.sources.name,

        axisXTickRotate: 90,

        axisYTitle: "Percent of households",
        axisYTickFormat: format(",.0%"),

        tooltipFormatValue: format(",.2%"),

        labels: {
          singleFamDetachedPct: "SFR detached",
          singleFamAttachedPct: "SFR attached",
          multiFam2UnitPct: "2 unit",
          multiFam34UnitPct: "3-4 unit",
          multiFam59UnitPct: "5-9 unit",
          multiFam1019UnitPct: "10-19 unit",
          multiFam2049UnitPct: "20-49 unit",
          multiFam50PlusUnitPct: "50+ unit",
        },

        columns: {
          categories: {
            color: "#66cc33",
            label: "Percent of households",
          },
        },
      },
    };
  },
  computed: {
    singleFamDetachedPct() {
      if (Number.isFinite(this.sources.singleFamDetachedPct)) {
        return this.sources.singleFamDetachedPct * 100;
      }
    },
  },
};
</script>
