import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";

export default class PurchaseRate {
  constructor(ficoId, ltvId, value = null) {
    this.ficoId = ficoId;
    this.ltvId = ltvId;
    this.value = value;
  }

  toPlainObject({ toSnakeCase } = { toSnakeCase: false }) {
    const rate = {
      value: this.value,
      ficoId: this.ficoId,
      ltvId: this.ltvId,
    };

    if (toSnakeCase) return mapKeys(rate, (_, key) => snakeCase(key));

    return rate;
  }
}
