<template>
  <div class="distribution-fees col-md-9">
    <div v-if="loading" class="ps-loader ps-loader-small"></div>
    <div v-else class="row">
      <div class="col-md-3"></div>
      <div class="rules col-md-9">
        <h2>Distribution Fees</h2>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th class="text-left">
                  Status
                </th>
                <th class="text-left">
                  Fee Name
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="fee in fees" :key="fee.id">
                <td class="text-left status" :class="fee.status">
                  <span class="status-block">{{ fee.status }}</span>
                </td>
                <td class="text-left">
                  {{ fee.label }}
                </td>
                <td v-if="policy.canAddDistributionFee()" @click="editFee(fee)" class="text-right" title="Edit">
                  <i class="fas fa-pencil-alt" />
                </td>
                <td v-else></td>
              </tr>
            </tbody>
          </table>
          <span v-if="policy.canAddDistributionFee()" @click="showModal" class="add-button">+ Add Distribution Fee</span>
        </div>
      </div>
    </div>
    <modal
      v-if="isModalShown"
      v-bind="{ fee }"
      @saved="onSave"
      @close="closeModal"
    />
  </div>
</template>
<script>
import AjaxService from "services/ajax_service";
import find from "lodash/find";
import { error } from "services/notification";

import Policy from "./../policy";
import Modal from "./modal";

export default {
  components: { Modal },
  data() {
    return {
      isModalShown: false,
      loading: false,
      fee: undefined,
      fees: [],
      policy: new Policy(PSData.policy),
    };
  },
  created() {
    this.requestData();
  },
  methods: {
    async requestData() {
      this.loading = true;

      try {
        this.fees = await AjaxService.getJSON("/manage/cash_receipt_distribution_fees");
      } catch {
        error("Something went wrong");
      } finally {
        this.loading = false;
      }
    },
    onSave(data) {
      if (this.fee) {
        Object.assign(find(this.fees, { id: data.id }), data);
      } else {
        this.fees.push(data);
      }

      this.closeModal();
    },
    editFee(fee) {
      this.fee = fee;
      this.showModal();
    },
    showModal() {
      this.isModalShown = true;
    },
    closeModal() {
      this.isModalShown = false;
      this.fee = undefined;
    },
  },
};
</script>
