export const MANAGE_TASK_DEFINITIONS_ROUTE = "/manage/settings/loan_task_definitions";
const DEFAULT_DURATION_TYPE = "civil";
const DEFAULT_REQUIRED_ROLE = "analyst";
const UPLOAD_ACTION_TYPE = "upload";

export default class LoanTaskDefiniton {
  constructor(
    record = {
      active: true,
      durationType: DEFAULT_DURATION_TYPE,
      requiredRole: DEFAULT_REQUIRED_ROLE,
    }
  ) {
    this.update(record);
  }

  get objectToSave() {
    const definition = {
      id: this.id,
      active: this.active,
      label: this.label,
      loan_document_type_id: this.loanDocumentTypeId,
      description: this.description,
      action_type: this.actionType,
      task_type: this.taskType,
      loan_task_group_id: this.loanTaskGroupId,
      loan_task_definition_id: this.mappedLoanTaskDefinitionId,
      required_role: this.requiredRole,
      duration: this.duration,
      duration_type: this.durationType,
    };

    if (this.isNew) {
      definition.system_name = this.systemName;
    }

    if (this.hasDetail) {
      definition.detail = {
        id: this.detail.id,
        category_tag: this.detail.categoryTag || "",
        data_checked: this.detail.dataChecked || "",
        rule_name_and_or_field_requirement: this.detail.ruleNameAndOrFieldRequirement || "",
        standard_referenced: this.detail.standardReferenced || "",
        error_code: this.detail.errorCode || "",
      };
    }

    return definition;
  }

  get isNew() {
    return !this.id;
  }

  get hasDetail() {
    return Object.values(this.detail).filter(value => !!value).length > 0;
  }

  get activeLabel() {
    return this.active ? "Yes" : "No";
  }

  get defaultDescription() {
    return this.description.default;
  }

  set defaultDescription(text) {
    this.description.default = text;
  }

  get descriptionDisabled() {
    return Object.keys(this.description).length > 1;
  }

  get uploadActionType() {
    return this.actionType === UPLOAD_ACTION_TYPE;
  }

  update(record) {
    this._raw = record;
    Object.assign(this, record);
    this.description = Object.assign({}, record.description);

    if (!this.detail) {
      this.detail = {};
    }
  }

  reset() {
    this.update(this._raw);
  }

  get copy() {
    return new LoanTaskDefiniton(this._raw);
  }

  copyToBorrower() {
    let task = {
      ...this._raw,
      mappedLoanTaskDefinitionId: this._raw.id,
      systemName: this._raw.systemName + "_borrower",
      taskType: "borrower",
      id: null,
    };
    return new LoanTaskDefiniton(task);
  }
}
