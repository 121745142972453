<template>
  <modal-form
    :confirm="formChanged"
    :save-disabled="saveDisabled"
    :show="show"
    :title="modalTitle"
    class="lender-legal-entity-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <form>
      <div class="row">
        <with-errors on="documentType" :errors="errors" class="col-12 form-group">
          <label>Master Agreement Type</label>
          <select
            v-model="newDocumentType"
            class="form-control"
            name="documentType"
          >
            <option disabled value="undefined">
              Select Document Type
            </option>
            <option v-for="(label, value) in documentTypes" :key="value" :value="value">
              {{ label }}
            </option>
          </select>
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="signedDate" :errors="errors" class="col-12 form-group">
          <label>Date Signed</label>
          <input
            v-model="newSignedDate"
            v-mask:date
            v-datepicker
            class="date-picker form-control"
            type="text"
            name="signedDate"
          >
        </with-errors>
      </div>
    </form>
  </modal-form>
</template>

<script>
import AjaxService from "services/ajax_service";
import ModalForm from "components/modal_form.vue";
import WithErrors from "components/shared/with_errors";
import MessageBusService from "services/message_bus_service";

export default {
  name: "master-agreements-edit-modal",
  components: {
    ModalForm,
    WithErrors,
  },
  props: {
    agreement: {
      type: Object,
      required: true,
    },
    documentTypes: {
      type: Object,
      required: true,
    },
    show: {
      required: true,
      type: Boolean,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      routes: PSData.routes || {},
      errors: {},
      bus: MessageBusService.entityBus,
      saveInProgress: false,
      newDocumentType: this.agreement.documentType,
      newSignedDate: this.agreement.signedDate,
    };
  },
  computed: {
    modalTitle() {
      return (this.editMode ? "Edit" : "Add") + " Master Agreement";
    },
    formChanged() {
      return (
        this.newDocumentType !== this.agreement.documentType ||
        this.newSignedDate !== this.agreement.signedDate
      );
    },
    saveDisabled() {
      return this.saveInProgress || !this.formChanged;
    },
    editMode() {
      return !!this.agreement.id;
    },
  },
  methods: {
    onSave() {
      if (this.editMode) {
        this.updateMasterAgreement();
      } else {
        this.createMasterAgreement();
      }
    },
    createMasterAgreement() {
      this.saveInProgress = true;
      const messages = {
        onSuccess: "Master Agreement has been created",
        onError: this.onError,
      };
      const payload = {
        document_type: this.newDocumentType,
        signed_date: this.newSignedDate,
      };

      AjaxService.withNotifications(messages)
        .postJSON(this.routes.masterAgreements, payload)
        .then(data => {
          this.$emit("on-save", data);
          this.bus.broadcast("entity-updated");
        })
        .finally(() => {
          this.saveInProgress = false;
        });
    },
    updateMasterAgreement() {
      this.saveInProgress = true;
      const messages = {
        onSuccess: "Master Agreement has been updated",
        onError: this.onError,
      };
      const payload = {
        document_type: this.newDocumentType,
        signed_date: this.newSignedDate,
      };

      AjaxService.withNotifications(messages)
        .patchJSON(`${this.routes.masterAgreements}/${this.agreement.id}`, payload)
        .then(data => {
          this.$emit("on-save", data);
          this.bus.broadcast("entity-updated");
        })
        .finally(() => {
          this.saveInProgress = false;
        });
    },
    onError({ response, data }) {
      if (response.status === 403) {
        return { message: "Unauthorized", status: "error" };
      } else {
        this.errors = data.errors;
        return null;
      }
    },
  },
};
</script>
