import PricingResponseBuilder from "./pricing_response_builder";

export default class FetchPricing {
  static async call(scenario, ltv, serviceClient, isCustom = false, additionalFields = {}) {
    const parsedLtv = parseFloat(ltv);
    const loanAmount = scenario.calculateLoanAmount(parsedLtv);

    const payload = {
      ...scenario.toHash(),
      ltv: parsedLtv,
      total_principal_balance_at_origination: loanAmount,
      ...additionalFields,
    };

    const response = await serviceClient.requestPricing(payload, isCustom);

    return PricingResponseBuilder.build(response, scenario, payload, isCustom);
  }
}
