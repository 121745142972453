<template>
  <time-series-comparison-chart
    :chart="chart"
    :sources="sources">
  </time-series-comparison-chart>
</template>

<script>
import { format } from "d3";
import TimeSeriesComparisonChart from "components/market_data/time_series_comparison_chart";

export default {
  name: "cost-of-homeownership-chart",
  components: {
    TimeSeriesComparisonChart,
  },
  props: {
    sources: Object,
  },
  data() {
    return {
      chart: {
        title: "Affordability: Cost of Homeownership",
        tickFormat: format(",.0%"),
        tooltipFormat: format(",.2%"),
        axes: {
          y: {
            title: "% of HH income consumed by median home payment",
          },
        },
        columns: {
          zipAffordability: {
            color: "#66cc33",
            observed: {
              data: "ZIP-Code Affordability",
              labels: "zip-code-affordability",
            },
            predicted: {
              data: "ZIP-Code Affordability (forecast)",
              labels: "zip-code-affordability-predicted",
            },
          },
          msaAffordability: {
            color: "#5b6770",
            observed: {
              data: "MSA Affordability",
              labels: "msa-affordability",
            },
            predicted: {
              data: "MSA Affordability (forecast)",
              labels: "msa-affordability-predicted",
            },
          },
          zipAffordabilityAverage: {
            color: "#a9b2bd",
            observed: {
              data: "ZIP Code Average",
              labels: "zip-code-average",
            },
            predicted: {
              data: "ZIP Code Average (forecast)",
              labels: "zip-code-average-predicted",
            },
          },
        },
      },
    };
  },
};
</script>
