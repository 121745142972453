class Form {
  constructor(document) {
    this.document = document;
    this.fields = [];

    (this.document.elements || []).forEach(element => {
      this.addField(element);
    });
  }

  fieldsCount() {
    return this.fields
      .map(field => field.fieldsCount())
      .reduce((total, current) => total + current, 0);
  }

  validatedCount() {
    return this.fields
      .map(field => field.validatedCount())
      .reduce((total, current) => total + current, 0);
  }

  completedPercentage() {
    return (this.validatedCount() / this.fieldsCount() || 0) * 100;
  }

  notedCount() {
    return this.fields
      .map(field => field.notedCount())
      .reduce((total, current) => total + current, 0);
  }

  addField(element) {
    var newField = PS.Models.Field.buildField(element);
    if (newField.isFieldset()) {
      this.attachFieldset(newField, element);
    } else {
      this.fields.push(newField);
    }

    return newField;
  }

  removeField(element) {
    Vue.delete(this.fields, this.fields.indexOf(element));

    if (element.isFieldset()) {
      let similarFieldsets = this.similarFieldsets(element);
      this.updateEditableStates(similarFieldsets);
    }
  }

  attachFieldset(newFieldset, element) {
    element.fields.forEach(fieldsetElement => {
      // attach inner fields to fieldset itself
      var fieldsetField = PS.Models.Field.buildField(fieldsetElement);
      newFieldset.fields.push(fieldsetField);
    });

    let endOfFieldsetGroup = this.fields.map(f => f.name).lastIndexOf(newFieldset.name);
    if (endOfFieldsetGroup > -1) {
      // attach current fieldset after last fieldset with the same name
      this.fields.splice(endOfFieldsetGroup + 1, 0, newFieldset);
    } else {
      this.fields.push(newFieldset);
    }

    let similarFieldsets = this.similarFieldsets(newFieldset);
    this.updateEditableStates(similarFieldsets);
  }

  similarFieldsets(fieldset) {
    return this.fields.filter(field => field.name == fieldset.name);
  }

  updateEditableStates(fieldsets) {
    if (fieldsets.length === 0) return;

    const firstFieldset = fieldsets[0];
    const lastFieldset = fieldsets[fieldsets.length - 1];

    fieldsets.forEach(fieldset => {
      fieldset.canBeCopied = false;
      fieldset.canBeRemoved = true;
    });

    if (fieldsets.length === 1) {
      firstFieldset.canBeCopied = true;
      firstFieldset.canBeRemoved = false;
    } else {
      lastFieldset.canBeCopied = true;
    }
  }
}

PS.Models.Form = Form;
PS.Models.Forms = {};
