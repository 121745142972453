<template>
    <div class="loan-followers">
      <div class="title">Followers</div>

      <div class="followers-list" v-popover="{ content: `#loan-followers-popover-${instanceCounter}` }">
        <div
          v-for="follower in firstFollowers"
          :key="`follower-${follower.id}`"
          class="loan-follower"
          :class="{ 'analyst-follower': follower.isAnalyst }"
        >
          {{follower.nameAbbreviation}}
        </div>
      </div>

      <div v-if="moreFollowers" class="more-followers">+{{moreFollowers}}</div>

      <div v-if="canUpdateLoanFollowersData" @click="openLoanFollowersModal" class="edit-followers">
        <i class="fas fa-pencil-alt" />
      </div>

      <portal to="page-modals">
        <loan-followers-modal
          class="loan-followers-modal"
          :routes="routes"
          :followers="editableFollowers"
          :follower-candidates="followerCandidates"
          :show="showLoanFollowersModal"
          :errors="updateLoanFollowersErrors"
          :loading="isUpdatingLoanFollowers"
          :can-add-lender-followers="canAddLenderFollowers"
          :lenders="editableLenders"
          :lender-candidates="lenderCandidates"
          @close="closeLoanFollowersModal"
          @on-save="updateLoanFollowers"
        >
          <div class="all-followers-list">
            <div class="group-title">Lender Followers</div>
            <div v-for="follower in sortedLenderFollowers" :key="`modal-lender-follower-${follower.id}`">
              {{follower.name}}
            </div>
          </div>
        </loan-followers-modal>
      </portal>

      <div :id="`loan-followers-popover-${instanceCounter}`" class="popover-content hidden">
        <div class="all-followers-list">
          <div class="group-title">PeerStreet Followers</div>
          <div v-for="follower in sortedFollowers" :key="`popover-ps-follower-${follower.id}`">
            {{follower.name}}
          </div>

          <div class="group-title">Lender Followers</div>
          <div v-for="follower in sortedLenderFollowers" :key="`popover-lender-follower-${follower.id}`">
            {{follower.name}}
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import take from "lodash/take";
import orderBy from "lodash/orderBy";
import partition from "lodash/partition";
import LoanFollowersModal from "components/activity_feed_events/manage/loan_followers/modal";

const FIRST_FOLLOWERS = 4;
let instanceCounter = 0;

export default {
  name: "peerstreet-loan-followers",
  components: {
    LoanFollowersModal,
  },
  props: {
    routes: Object,
    followers: Array,
    followerCandidates: Array,
    lenderFollowers: Array,
    canUpdateLoanFollowersData: {
      type: Boolean,
      required: true,
    },
    canAddLenderFollowers: Boolean,
    lenderCandidates: Array,
  },
  data() {
    return {
      instanceCounter: instanceCounter,
      editableFollowers: this.followers,
      showLoanFollowersModal: false,
      updateLoanFollowersErrors: {},
      isUpdatingLoanFollowers: false,
      editableLenders: this.lenderFollowers,
    };
  },
  created() {
    instanceCounter++;
  },
  watch: {
    followers(newValue) {
      this.editableFollowers = newValue;
    },
    lenderFollowers(newValue) {
      this.editableLenders = newValue;
    },
  },
  computed: {
    firstFollowers() {
      return take(this.sortedAllFollowers, FIRST_FOLLOWERS);
    },
    sortedFollowers() {
      let followers = orderBy(this.editableFollowers, "name");
      let [me, others] = partition(followers, u => u.name === PSData.user.name);
      return [...me, ...others];
    },
    sortedLenderFollowers() {
      return orderBy(this.editableLenders, "name");
    },
    sortedAllFollowers() {
      return [...this.sortedFollowers, ...this.sortedLenderFollowers];
    },
    moreFollowers() {
      const followersCount = this.sortedAllFollowers.length;
      return followersCount > FIRST_FOLLOWERS ? followersCount - FIRST_FOLLOWERS : undefined;
    },
  },
  methods: {
    openLoanFollowersModal() {
      this.showLoanFollowersModal = true;
    },
    closeLoanFollowersModal() {
      this.updateLoanFollowersErrors = {};
      this.showLoanFollowersModal = false;
    },
    updateLoanFollowers(followers) {
      this.editableFollowers = followers.users;
      if (this.canAddLenderFollowers) {
        this.editableLenders = followers.lenders;
      }
    },
  },
};
</script>
