const Event = PS.Models.Event;
const Events = PS.Models.Reports.Events.MsaReport;
const Commands = PS.Models.Reports.Commands.MsaReport;
const Rule = PS.Models.Rule;

class MsaReportRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.MsaReportRequested:
        return this.whenMsaReportRequested(event);

      case Events.MidasDataChangeRequested:
        return this.whenMidasDataChangeRequested(event);
    }

    return super.check(event);
  }

  whenMsaReportRequested(event) {
    const midasChange = new Event({ state: this.state });

    return [
      new Commands.ClearReport(event, this.state),
      new Commands.StartLoading(event, this.state),
      new Commands.TrackReport(event, this.state),
      new Commands.LoadMidasData(event, this.state),
      new Commands.AddMidasDataToCharts(event, this.state),
      new Commands.AddMidasDataToAnalytics(event, this.state),
      new Commands.AddMidasDataToTables(event, this.state),
      new Commands.EndLoading(event, this.state),
      new Commands.RequestMidasDataChange(midasChange, {}),
    ];
  }

  whenMidasDataChangeRequested(event) {
    const commands = [];
    const midasData = event.midasData;

    if (this.state.hasOwnProperty("dataSource") && midasData.msaCrossSection) {
      commands.push(new Commands.SetMsaInput(event, this.state));
    }

    return commands;
  }
}

PS.Models.Reports.MsaReportRule = MsaReportRule;
