<template>
  <div class="instant-search">
    <input
      v-model="searchValue"
      class="input instant-search-input"
      type="search"
      placeholder="Search for lenders, lender entities, loans, and series"
      @input="onInput"
    >
    <instant-search-results
      :search-query="searchQuery"
      :results="results"
      :loading="loading"
    />
  </div>
</template>

<script>
import InstantSearchResults from "./instant_search_results";
import Ajax from "services/ajax_service";
import { debounce } from "lodash";

const MIN_SEARCH_QUERY_LENGTH = 3;
const DEBOUNCE_WAIT = 500;

export default {
  name: "instant-search",
  components: {
    InstantSearchResults,
  },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchValue: "",
      results: [],
      loading: true,
    };
  },
  computed: {
    queryUrl() {
      const searchQuery = encodeURIComponent(this.searchQuery);

      return `${this.apiUrl}?query=${searchQuery}`;
    },
    searchQuery() {
      const searchValue = (this.searchValue || "").trim();

      return searchValue.length < MIN_SEARCH_QUERY_LENGTH ? "" : searchValue;
    },
  },
  methods: {
    onInput: debounce(function() {
      this.loadData();
    }, DEBOUNCE_WAIT),
    loadData: async function() {
      this.loading = true;

      if (!this.searchQuery) {
        return;
      }

      await this.$nextTick();

      this.results = await Ajax.getJSON(this.queryUrl);

      this.loading = false;
    },
  },
};
</script>
