const Source = PS.Models.MarketData.Analytics.Source;

class ClearCapitalZip extends Source {
  get name() {
    return "Clear Capital ZIP Code";
  }

  success(marketData) {
    this.completedAt = marketData.recordedAt;

    this.forecastedCumulativeReturn["YoY"] = marketData.yearOverYear;
    this.forecastedCumulativeReturn["12"] = marketData.projected12;
    this.forecastedCumulativeReturn["24"] = marketData.projected24;

    Object.keys(this.maxRollingDecline).forEach(key => {
      this.maxRollingDecline[key] = (
        marketData.maxDecreases.find(d => d.month === parseInt(key)) || {}
      ).value;
    });

    super.success();
  }
}

PS.Models.MarketData.Analytics.ClearCapitalZip = ClearCapitalZip;
