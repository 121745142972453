<template>
  <div class="columns" v-if="isErrorsPresent">
    <div class="column" :class="{'is-half': !fullWidth}">
      <base-message type="danger" title="Error" @close="$listeners.close">
        <div :key="error" v-for="error in errors">{{error}}</div>
      </base-message>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isErrorsPresent() {
      return this.errors && this.errors.length > 0;
    },
  },
};
</script>
