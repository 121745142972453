<template>
  <diligence-document-field :document="document" :field="field">
    <div class="col-5">
      <div class="input-group">
        <div class="input-group-append">
          <span class="input-group-text">%</span>
        </div>
        <input
          :id="field.id"
          v-model="field.value"
          v-mask:percentage="field.mask.options"
          class="form-control"
          type="text"
          :disabled="disabled"
          :readonly="field.hasAutocalculation()"
          @blur="saveValueImmediately"
        />
      </div>
    </div>
  </diligence-document-field>
</template>

<script>
import TextField from "./base/text_field";

const PercentageField = TextField.extend({});

export default Vue.component("diligence-document-percentage-field", PercentageField);
</script>
