<template>
  <div class="details-row no-border row">
    <div class="col-12">
      <editable-field
        label="Estimated Payoff Amount"
        :editing="isEditable"
        :value="statement.wireTargetDateAmount"
        currency
      />
    </div>
    <div class="col-4">
      <editable-datepicker
        :label="statementDateLabel"
        :value="statement.payoffEstimatedDate"
        :editing="isEditable"
        :disabled="!isEditable"
        :min-date="new Date().toLocaleDateString()"
        :callback="updateEditableField('payoffEstimatedDate', statement)"
      />
    </div>
    <div class="col-4">
      <editable-datepicker
        label="Statement Expiration Date"
        :value="statement.payoffExpirationDate"
        :editing="isEditable"
        :disabled="isPeerstreetRepurchaseStatement || !statement.payoffEstimatedDate"
        :min-date="statement.payoffEstimatedDate"
        :callback="updateEditableField('payoffExpirationDate', statement)"
      />
    </div>
  </div>
</template>

<script>
import EditableField from "components/shared/editable_field.vue";
import EditableDatepicker from "components/shared/editable_datepicker";
import EditPartialMixin from "components/manage/payoff_statement/edit/edit_partial_mixin";

export default {
  components: { EditableField, EditableDatepicker },
  mixins: [EditPartialMixin],
  props: ["isEditable", "statement"],
  computed: {
    isPeerstreetRepurchaseStatement() {
      return this.statement.statementType === "peerstreet_repurchase";
    },
    statementDateLabel() {
      return this.isPeerstreetRepurchaseStatement
        ? "Estimated Purchase Date"
        : "Estimated Payoff Date";
    },
  },
};
</script>
