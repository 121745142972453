<template>
  <div>
    <div class="grids-header">
      Pricing Grids

      <button class="btn btn-action-cta" @click="$emit('add-new-grid')">
        + Add New Grid
      </button>
    </div>
    <div class="grids-list-filter">
      Showing
      <select2
        class="form-control"
        :options="{ width: 250, disabled: true, placeholder: 'TBD' }"
      />
    </div>
    <table class="table pricing-grids-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Label</th>
          <th>Tier</th>
          <th>Loan Program</th>
          <th class="timestamps">
            Effective Date
          </th>
          <th class="timestamps">
            End Date
          </th>
          <th class="grace-period">
            Grace Period
          </th>
          <th class="status">
            Active
          </th>
          <th class="timestamps">
            Created At /<br>Updated At
          </th>
          <th class="min-purchase-rate">Minimum<br>Purchase Rate</th>
          <th class="max-lender-spread">Maximum<br>Lender Spread</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="grid in grids" :key="grid.id" :class="{ deprecated: !grid.isActive }">
          <td>{{ grid.id }}</td>
          <td>{{ grid.label }}</td>
          <td>{{ grid.tierLabel }}</td>
          <td>{{ grid.loanProgramLabel }}</td>
          <td class="timestamps">{{ grid.startedAt }}</td>
          <td class="timestamps">{{ grid.endedAt }}</td>
          <td class="grace-period">{{ grid.gracePeriod }}</td>
          <td class="status">
            <i class="fas" :class="getStatusClassFor(grid)" />
          </td>
          <td class="timestamps">
            {{ grid.createdAt }}<br>{{ grid.updatedAt }}
          </td>
          <td class="min-purchase-rate">{{ grid.minPurchaseRate }}</td>
          <td class="max-lender-spread">{{ grid.maxLenderSpread }}</td>
          <td class="actions">
            <i class="fas fa-pencil-alt" @click="editGrid(grid)" />
            <i class="fas fa-copy" @click="copyGrid(grid)" />
            <i v-if="grid.isActive" class="fas fa-trash-alt" @click="deprecateGrid(grid)" />
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <button class="btn btn-action-cta-sm" @click="$emit('add-new-grid')">
        + Add New Grid
      </button>
    </div>
  </div>
</template>

<script>
import Select2 from "components/shared/select2";
import AjaxService from "services/ajax_service";

export default {
  name: "pricing-grid-list",
  components: { Select2 },
  props: {
    grids: {
      type: Array,
      required: true,
    },
    routes: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deprecateGrid(grid) {
      const message = `Are you sure you want to deprecate this grid - ${grid.label}?`;
      const messages = { onSuccess: "Pricing Grid was deprecated", onError: this.onError };

      if (window.confirm(message)) {
        const url = `${this.routes.pricingGrids}/${grid.id}/deprecate`;
        const params = { grid: { id: grid.id } };

        AjaxService.withNotifications(messages)
          .postJSON(url, params)
          .then(data => {
            this.$emit("on-grids-update", data);
          })
          .catch(() => {}); // stub; catch is performing in [onError])
      }
    },
    onError({ response }) {
      if (response.status === 403) {
        return {
          message: "You're not allowed to manage pricing grids",
          status: "error",
        };
      } else {
        Bugsnag.notify(new Error(`Unable to save pricing grid: ${response.statusText}`));
      }
    },
    editGrid(grid) {
      this.$emit("on-grid-edit", grid);
    },
    copyGrid(grid) {
      this.$emit("on-grid-copy", grid);
    },
    getStatusClassFor(grid) {
      if (grid.isActive) {
        return "fa-check";
      } else {
        return "fa-times-circle";
      }
    },
  },
};
</script>
