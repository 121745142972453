<template>
  <modal-form
    :show="true"
    title="Add Workflow"
    description="Create a new Asset Management workflow from a template."
    save-button-name="Create"
    :save-disabled="submitDisabled"
    class="workflows-section-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <form @keypress.enter="onSave">
      <editable-select
        v-validate="'required'"
        label="Workflow Template"
        name="assetManagementWorkflowTemplateId"
        :options="workflowTemplates"
        :editing="true"
        :value="form.assetManagementWorkflowTemplateId"
        @input="change('assetManagementWorkflowTemplateId', $event.target.value)"
      />
    </form>
  </modal-form>
</template>
<script>
import ModalForm from "components/modal_form";
import EditableSelect from "../../editable_select";
import useAssetManagementStore from "controllers/manage/store/asset_management";
import { mapState } from "pinia";

export default {
  components: {
    ModalForm,
    EditableSelect,
  },
  $_veeValidate: {
    validator: "new",
  },
  props: {
    isSaving: Boolean,
  },
  data() {
    return {
      form: {
        assetManagementWorkflowTemplateId: null,
      },
      isValid: false,
    };
  },
  computed: {
    hasErrors() {
      return !this.isValid;
    },
    submitDisabled() {
      return this.hasErrors || this.isSaving;
    },
    ...mapState(useAssetManagementStore, ["workflowTemplates"]),
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    async change(fieldName, value) {
      this.$set(this.form, fieldName, value);
      await Vue.nextTick();
      this.isValid = await this.$validator.validateScopes();
    },
    onSave() {
      this.$emit("save", this.form);
    },
  },
};
</script>
