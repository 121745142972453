const MIN_DATE = new Date(0);

// Order a list of Fees by their incurredDate in descending order, nulls last
// ex: statement.fees.sort(orderByIncurredDate)
export const orderByIncurredDate = (feeA, feeB) => {
  let dateA = feeA.incurredDate ? new Date(feeA.incurredDate) : MIN_DATE;
  let dateB = feeB.incurredDate ? new Date(feeB.incurredDate) : MIN_DATE;

  return dateB - dateA;
};

// Order fees by Incurred Date in descending order, ordered by id in ascending order, nulls last
// ex: statement.fees.sort(orderByIncurredDateAndId)
export const orderByIncurredDateAndId = (feeA, feeB) => {
  let dateA = feeA.incurredDate ? new Date(feeA.incurredDate) : MIN_DATE;
  let dateB = feeB.incurredDate ? new Date(feeB.incurredDate) : MIN_DATE;

  if (dateA.toLocaleDateString() === dateB.toLocaleDateString()) {
    return feeA.id - feeB.id;
  }

  return dateB - dateA;
};
