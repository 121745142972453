<template>
  <div v-if="loans" class="active-portfolio-loans-list">
    <div class="portfolio-table-header">
      {{ loans.length | pluralize("loan") }} totalling {{ totalSum | money }}
    </div>
    <table class="portfolio-table">
      <thead>
        <tr>
          <th>Status</th>
          <th>ID</th>
          <th>Address</th>
          <th class="loan-amount">
            Amount
          </th>
          <th>Maturity Date</th>
          <th class="loan-custom-id">
            Custom ID
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="loan in loans" :key="loan.id" :data-path="loanPath(loan)">
          <td class="loan-status-wrapper">
            <span class="loan-status" :class="statusClass(loan)">{{ loan.groupName }}</span>
          </td>
          <td class="loan-name">
            {{ loan.toParam }}
          </td>
          <td class="loan-address">
            <div>{{ loan.address }}</div>
            <div>{{ loan.addressWithoutStreet }}</div>
          </td>
          <td class="loan-amount">
            {{ loan.totalPeerstreetHolding | money }}
          </td>
          <td class="loan-date">
            {{ loan.originalMaturityDate }}
          </td>
          <td class="loan-custom-id">
            {{ loan.customId }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Ajax from "services/ajax_service";

export default {
  name: "paid-off-loans",
  data() {
    return {
      routes: PSData.routes || {},
      loans: null,
      initialSort: { field: "initialPurchaseDate", fieldType: "date", direction: 1 },
      group: "paidOff",
      underwritingStatus: "purchased",
    };
  },
  computed: {
    totalSum() {
      return this.loans
        .map(loan => loan.totalPeerstreetHolding || 0)
        .reduce((sum, totalPeerstreetHolding) => sum + totalPeerstreetHolding, 0);
    },
  },
  mounted() {
    Ajax.getJSON(this.routes.paidOffLoans)
      .then(data => {
        this.loans = data.loans;
        this.$emit("loaded", this.group, this.loans.length);
      })
      .catch(() => {
        this.loans = [];
      });
  },
  methods: {
    statusClass(loan) {
      return `loan-${loan.purchasedGroup}`;
    },
    loanPath(loan) {
      return `${this.routes.loans}/${loan.toParam}`;
    },
  },
};
</script>
