<template>
  <loan-boarding-table label="New Loan Boarding" :count="count">
    <template #header>
      <thead>
        <th>Submitted/<br>BWL Days</th>
        <th>BWL<br>Date</th>
        <th>Subservicer<br>ID</th>
        <th>Loan ID</th>
        <th class="address">Address</th>
        <th>Notes To Subservicer</th>
        <th>PS Internal Notes</th>
        <th>Purchase Date/<br>Orig Date</th>
        <th class="amount">Orig UBP/<br>Current UBP</th>
        <th class="amount">P&I Pymts/<br>Impound Pymts</th>
        <th class="amount">Prepaid Int<br>& Payments</th>
        <th class="amount">Odd Days<br>Interest</th>
        <th class="amount">Escrow<br>Reserves</th>
        <th class="percent">Note/<br>Blended Lender</th>
        <th>Maturity<br>Date</th>
      </thead>
    </template>
    <template #body>
      <tr v-for="item in collection" :key="item.id" :id="item.id">
        <td>{{ item.submittedDate }}<br>{{ item.daysSinceWelcomeLetter }}</td>
        <td>{{ item.welcomeLetterReceivedDate }}</td>
        <td>{{ item.servicerLoanCode }}</td>
        <td>{{ item.psLoanId }}<br>
          <loan-boarding-details-popup :item="item"></loan-boarding-details-popup>
        </td>
        <td class="address">
          <loan-boarding-truncated-text :value="item.address"></loan-boarding-truncated-text>
        </td>
        <td class="note-cell">
          <loan-boarding-notes-to-subservicer :record="item">
          </loan-boarding-notes-to-subservicer>
        </td>
        <td class="note-cell">
          <loan-boarding-ps-internal-notes :record="item">
          </loan-boarding-ps-internal-notes>
        </td>
        <td>{{ item.purchaseDate }}<br>{{ item.originationDate }}</td>
        <td class="amount">{{ item.totalPrincipalBalanceAtOrigination | accounting }}<br>{{ item.unpaidPrincipalBalance | accounting }}</td>
        <td class="amount">{{ item.monthlyPaymentPrincipalAndInterest | accounting }}<br>{{ item.monthlyPaymentTaxesAndInsurance | accounting }}</td>
        <td class="amount">{{ item.interestReserveAmount | accounting }}</td>
        <td class="amount">{{ item.oddDaysInterest | accounting }}</td>
        <td class="amount">{{ item.escrowReserveAmount | accounting }}</td>
        <td class="percent">{{ item.interestRate | percent(4) }}<br>{{ item.lenderBlendedRate | percent(4) }}</td>
        <td>{{ item.maturityDate }}</td>
      </tr>
    </template>
  </loan-boarding-table>
</template>
<script>
import LoanBoardingTruncatedText from "components/servicer/loan_boarding/truncated_text";
import LoanBoardingNotesToSubservicer from "components/servicer/loan_boarding/notes_to_subservicer";
import LoanBoardingPsInternalNotes from "components/servicer/loan_boarding/ps_internal_notes";
import LoanBoardingDetailsPopup from "components/servicer/loan_boarding/details_popup";
import LoanBoardingListMixin from "components/servicer/loan_boarding/mixin/list";
import LoanBoardingUpdateStatusMixin from "components/servicer/loan_boarding/mixin/update_status";
import LoanBoardingTable from "components/servicer/loan_boarding/table";

export default {
  mixins: [LoanBoardingListMixin, LoanBoardingUpdateStatusMixin],
  name: "manage-servicer-loan-boarding-submitted",
  components: {
    LoanBoardingTruncatedText,
    LoanBoardingNotesToSubservicer,
    LoanBoardingDetailsPopup,
    LoanBoardingPsInternalNotes,
    LoanBoardingTable,
  },
  props: {
    collection: Array,
    routes: Object,
  },
};
</script>
