<template>
  <div>
    <p v-if="hasTitle" v-html="titleHtml" class="notification-group-message-group-title"></p>
    <ul class="notification-group-message-group-list">
      <li v-for="message in messages" :key="message.description + `-` + message.subtitle" class="notification-group-message-group-list-item">
        <span v-if="hasSubtitle(message)" class="notification-group-message-group-subtitle">
          {{ message.subtitle }}:
        </span>
        <span
          class="notification-group-message-group-description"
          v-html="message.description"
        ></span>
      </li>
    </ul>
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";

export default {
  name: "notification-group-message-group",
  props: {
    title: {
      type: String,
    },
    messages: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasTitle() {
      return !!this.title;
    },
    titleHtml() {
      const tag = "</a>";

      if (this.title.includes(tag)) {
        return this.title.replace(tag, `:` + tag);
      } else {
        return this.title + `:`;
      }
    },
  },
  methods: {
    hasSubtitle(message) {
      return !isEmpty(message.subtitle);
    },
  },
};
</script>
