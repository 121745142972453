class ZipReportRequested extends PS.Models.Event {
  get source() {
    return this.options.source;
  }

  get zip() {
    return this.options.zip;
  }
}

PS.Models.Reports.Events.ZipReport = {
  ZipReportRequested: ZipReportRequested,
};
