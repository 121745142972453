import qs from "qs";

const OPTIONS = { strictNullHandling: true, arrayFormat: "brackets" };
const DEFAULT_ENCODER = params => qs.stringify(params, OPTIONS);
const DEFAULT_DECODER = query => qs.parse(query.substring(1), OPTIONS);

export default {
  inject: ["history"],
  props: {
    action: {
      type: String,
      default: "replace",
      validator: v => v === "replace" || v === "push",
    },
    encodeParams: {
      type: Function,
      default: DEFAULT_ENCODER,
    },
    decodeParams: {
      type: Function,
      default: DEFAULT_DECODER,
    },
  },
  data() {
    const { location } = this.history;
    return { location };
  },
  created() {
    this.unlisten = this.history.listen(location => (this.location = location));
  },
  destroyed() {
    this.unlisten();
  },
  computed: {
    params() {
      return this.decodeParams(this.location.search);
    },
  },
  methods: {
    setParams(params) {
      const { history, encodeParams, action, location } = this;

      history[action]({ ...location, search: encodeParams(params) });
    },
  },
  render() {
    const { params, setParams } = this;
    return this.$scopedSlots.default({ params, setParams });
  },
};
