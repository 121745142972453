<template>
  <div>
    <base-table
      :data="scenariosResults"
      :mobile-cards="false"
      :bordered="false"
      :focusable="true"
      :selected.sync="selectedRow"
      @select="$emit('scenarioRowSelected', $event)"
      class="custom-scenarios"
      :row-class="(row, index) =>((row.custom && !showCustomRow && 'hidden-row') || (row.custom && 'custom-row') || ((row.cbFlag || row.isDefault) && 'disabled-scenario'))"
      :is-row-selectable="(row) => (canSelectRow(row.cbFlag, row.ltv))"
    >
      <base-column
        v-slot="props"
        field="ltv"
        label="LTV"
        cell-class="ltv-cell"
      >
        <template v-if="!props.row.custom">
          {{ formatPercent(props.row.ltv, 3) || "--" }}
        </template>

        <template v-if="props.row.custom && showCustomRow">
          <base-percent-field
            id="custom-ltv"
            label=""
            rules="min_number_value:1"
            @input.native="calculateLoanAmount"
            @click.native.stop
            v-model="scenario.ltv"
            :disabled="customInputDisabled"
          />
        </template>
      </base-column>
      <base-column
        v-slot="props"
        field="loanAmount"
        label="Loan Amount"
      >
        <template v-if="!props.row.custom">
          {{ formatCurrency(props.row.loanAmount) || "--" }}
        </template>

        <template v-if="props.row.custom && showCustomRow">
          <base-currency-field
            id="custom-loan-amount"
            label=""
            :rules="`min_number_value:${minLoanAmount}|max_number_value:${maxLoanAmount}`"
            @input.native="calculateLtv"
            @click.native.stop
            v-model="scenario.loanAmount"
            :disabled="customInputDisabled"
          />
        </template>
      </base-column>
      <base-column
        v-slot="props"
        field="interestRate"
        label="Interest Rate"
      >
        <template v-if="!props.row.custom">
          <span v-if="props.row.cbFlag" class="cb-flag"></span>

          <template v-else>
            <span v-if="props.row.bestRate" class="flag">Best Rate</span>
            {{ formatPercent(props.row.interestRate) || "--" }}
          </template>
        </template>

        <template v-if="props.row.custom && showCustomRow">
          <span v-if="props.row.cbFlag" class="cb-flag"></span>
          <template v-else>
            {{ formatPercent(props.row.interestRate) || "--" }}
          </template>
        </template>
      </base-column>
      <base-column
        v-slot="props"
        field="dscr"
        label="DSCR"
      >
        <template v-if="!props.row.custom">
          <span v-if="props.row.cbFlag" class="cb-flag fit-label"></span>
          <template v-else>
            {{ formatNumber(props.row.dscr, 3) || "--" }}
          </template>
        </template>

        <template v-if="props.row.custom && showCustomRow">
          <span v-if="props.row.cbFlag" class="cb-flag fit-label"></span>
          <template v-else>
            {{ formatNumber(props.row.dscr, 3) || "--" }}
          </template>
        </template>
      </base-column>
      <base-column
        v-slot="props"
        field="monthlyPayment"
        label="Monthly Payment"
      >
        <template v-if="!props.row.custom">
          <span v-if="props.row.cbFlag" class="cb-flag"></span>
          <template v-else>
            {{ formatCurrency(props.row.monthlyPayment) || "--" }}
          </template>
        </template>

        <template v-if="props.row.custom && showCustomRow">
          <span v-if="props.row.cbFlag" class="cb-flag"></span>
          <template v-else>
            {{ formatCurrency(props.row.monthlyPayment) || "--" }}
          </template>
        </template>
      </base-column>
      <base-column
        v-slot="props"
        :visible="showSelectColumn"
        field=""
        label=""
        cell-class="select-scenario-cell"
      >
        <span :class="['select-scenario-label', { 'disabled': !canSelectRow(props.row.cbFlag, props.row.ltv)}]">
          {{ selectScenarioLabel(selectedRow, props.row) }}
        </span>
      </base-column>
    </base-table>

    <a v-if="!showCustomRow" href="#" @click.prevent="toggleCustomScenario" class="custom-row-link">+ Custom amount</a>
  </div>
</template>

<script>
import {
  RENTAL_RICING_LTV_INTERVALS,
  RENTAL_MIN_LOAN_AMOUNT,
  RENTAL_MAX_LOAN_AMOUNT,
} from "../utils/helpers";
import Mixin from "./mixin";

export default {
  name: "residential-for-rent-estimates",
  inject: ["locale"],
  mixins: [Mixin],
  computed: {
    emptyCustom() {
      return {
        ltv: null,
        loanAmount: null,
        interestRate: null,
        dscr: null,
        monthlyPayment: null,
        custom: true,
      };
    },
    defaultResults() {
      return RENTAL_RICING_LTV_INTERVALS.map(i => {
        return {
          ltv: i,
          loanAmount: null,
          interestRate: null,
          dscr: null,
          monthlyPayment: null,
        };
      });
    },
    _minLoanAmount() {
      return RENTAL_MIN_LOAN_AMOUNT;
    },
    _maxLoanAmount() {
      return RENTAL_MAX_LOAN_AMOUNT;
    },
  },
};
</script>

<style scoped>
::v-deep .custom-scenarios .table .cb-flag.fit-label::after {
  left: -35%;
  min-width: calc(100% + 48px);
}
</style>
