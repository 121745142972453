<template>
  <div class="alert alert-danger" v-if="errorHeader">
    <strong>{{errorHeader}}</strong>

    <br v-if="hasErrors" />
    <br v-if="hasErrors" />

    <ul>
      <li v-for="error in errors" :key="error">{{ error }}</li>
    </ul>
  </div>
</template>
<script>
import isEmpty from "lodash/isEmpty";

export default {
  name: "SettlementStatementErrors",
  props: ["type", "errors"],
  data() {
    return {
      errorHeaders: {
        pending:
          "Differences between Settlement Statement and Loan Details must be resolved before Approval.",
        invalid_user: "Cannot Create and Approve a Settlement Statement",
        draft: "Refresh before submitting. Values have changed since last update.",
        approved:
          "Differences between Settlement Statement and Loan Details must be resolved before Purchase.",
        validation:
          "All Servicing fields must be complete before a Settlement Statement can be generated.",
        soc_funding: "There is a difference between the Loan Purchase and the SOC Funding.",
        purchase_loan: "Unable to mark loan as purchased due to the following:",
        failure: "Cannot perform operation",
      },
    };
  },
  computed: {
    errorHeader() {
      return this.errorHeaders[this.type];
    },
    hasErrors() {
      return !isEmpty(this.errors);
    },
  },
};
</script>
