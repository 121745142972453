<template>
  <div class="institution-sales-tracking">
    <div class="institution-sales-tracking-page-header">
      <h2>Institution Sales Tracking <div v-show="isLoading" class="ps-loader ps-loader-small"></div></h2>
    </div>
    <div class="nav-menu">
      <ul class="nav-underlined">
        <li :class="{active: isActiveTab('overview')}">
          <a href="#" @click.prevent="currentTab='overview'">
            Overview
          </a>
        </li>
        <li :class="{active: isActiveTab('portfolios')}">
          <a href="#" @click.prevent="currentTab='portfolios'" >
            Portfolios
          </a>
        </li>
      </ul>
    </div>
    <div>
      <overview-tab
        v-if="isActiveTab('overview')"
        :batches="batches"
        :buyer-summaries="buyerSummaries"
        :buyer-programs="buyerPrograms"
        :found-offerings="search.offerings"
        :is-searching="isSearching"
        :can-update-batch="canUpdateBatch"
        @search="searchOfferings"
      ></overview-tab>
    </div>
  </div>
</template>
<script>
import AjaxService from "services/ajax_service";
import WindowService from "services/window_service";
import OverviewTab from "./overview_tab";
import { error } from "services/notification";

let UNIQUE_SEARCH_REQUEST_ID = 0;
const isLatestRequest = requestId => UNIQUE_SEARCH_REQUEST_ID === requestId;

export default {
  components: {
    OverviewTab,
  },
  data() {
    return {
      currentTab: "overview",
      isLoading: false,
      isLoaded: false,
      isSearching: false,
      isSummaryLoading: false,
      isBuyerProgramsLoading: false,
      batches: [],
      buyerSummaries: [],
      buyerPrograms: [],
      routes: PSData.routes,
      canUpdateBatch: PSData.canUpdateBatch,
      search: {
        offerings: [],
      },
    };
  },
  mounted() {
    this.getBatches();
    this.getBuyerSummaries();
    this.getBuyerPrograms();
  },
  methods: {
    isActiveTab(tab) {
      return this.currentTab === tab;
    },

    async getBuyerSummaries() {
      if (this.isSummaryLoading) {
        return;
      }

      const url = this.routes.manageInstitutionSummaries;
      this.isSummaryLoading = true;
      try {
        const res = await AjaxService.get(url);
        this.buyerSummaries = res.summaries;
      } catch (err) {
        error();
      } finally {
        this.isSummaryLoading = false;
      }
    },

    async getBatches() {
      if (this.isLoading) {
        return;
      }
      const url = this.routes.manageInstitutionOpenLoanOfferingBatches;
      this.isLoading = true;

      try {
        const res = await AjaxService.get(url);
        this.batches = res.batches;
      } catch (err) {
        error();
      } finally {
        this.isLoading = false;
      }
    },

    async getBuyerPrograms() {
      if (!this.canUpdateBatch) {
        return;
      }
      if (this.isBuyerProgramsLoading) {
        return;
      }
      const url = this.routes.manageInstitutionBuyerPrograms;
      this.isBuyerProgramsLoading = true;

      try {
        const res = await AjaxService.get(url);
        this.buyerPrograms = res.buyerPrograms;
      } catch (err) {
        error();
      } finally {
        this.isBuyerProgramsLoading = false;
      }
    },

    async searchOfferings(loanId) {
      const requestId = ++UNIQUE_SEARCH_REQUEST_ID;
      this.isSearching = true;
      const searchParams = new URLSearchParams({ loan_id: loanId });
      const url = `${this.routes.searchManageInstitutionLoanOfferings}?${searchParams}`;
      try {
        const res = await AjaxService.get(url);
        if (isLatestRequest(requestId)) {
          this.search.offerings = res.loanOfferings;
        }
      } catch (err) {
        if (isLatestRequest(requestId)) {
          if (err.response.status === 404) {
            this.search.offerings = [];
          } else {
            error();
          }
        }
      } finally {
        if (isLatestRequest(requestId)) {
          this.isSearching = false;
        }
      }
    },
  },
};
</script>
