<template>
  <div>
    <div class="grids-header">
      {{ title }}
    </div>
    <form class="pricing-grid-form">
      <div class="row">
        <with-errors on="label" :errors="errors" class="col-3 control-wrapper form-group">
          <label for="label">Label</label>
          <input
            id="label"
            v-model="grid.label"
            type="text"
            class="form-control"
          >
        </with-errors>
        <with-errors on="tier" :errors="errors" class="col-3 control-wrapper form-group">
          <label for="tier">Tier</label>
          <select
            id="tier"
            v-model="grid.tier"
            name="tier"
            class="form-control"
          >
            <option value />
            <option v-for="[label, name] in tiers" :key="name" :value="name">
              {{ label }}
            </option>
          </select>
        </with-errors>
        <with-errors on="loan_program" :errors="errors" class="col-3 control-wrapper form-group">
          <label for="loan_program">Loan Program</label>
          <select
            id="loan_program"
            v-model="grid.loanProgramId"
            name="loan_program"
            class="form-control"
          >
            <option value />
            <option
              v-for="[id, label] in loanPrograms"
              :key="id"
              :value="id"
            >
              {{ label }}
            </option>
          </select>
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="started_at" :errors="errors" class="col-3 control-wrapper form-group">
          <label for="started_at">Effective Date</label>
          <flat-pickr
            id="started_at"
            v-model="grid.startedAt"
            :config="datePickerConfig"
            class="form-control"
            autocomplete="off"
          />
        </with-errors>
        <with-errors on="grace_period" :errors="errors" class="col-3 control-wrapper form-group">
          <label for="grace_period">Grace Period</label>
          <input
            id="grace_period"
            v-model="grid.gracePeriod"
            type="number"
            step="1"
            class="form-control"
          >
        </with-errors>
        <with-errors on="ended_at" :errors="errors" class="col-3 control-wrapper form-group">
          <label for="ended_at">End Date</label>
          <flat-pickr
            id="ended_at"
            v-model="grid.endedAt"
            :config="datePickerConfig"
            class="form-control"
            autocomplete="off"
          />
        </with-errors>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Purchase Rate Grid</label>
          <grid v-model="grid.grid" :editable="true" :grid="grid.grid" />
        </div>
      </div>
      <div class="row">
        <with-errors on="min_purchase_rate" :errors="errors" class="col-3 control-wrapper form-group">
          <label for="min_purchase_rate">Minimum Purchase Rate</label>
          <input
            id="min_purchase_rate"
            v-model="grid.minPurchaseRate"
            v-mask:percentage="minPurchaseRateInputOptions"
            class="form-control"
          >
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="max_lender_spread" :errors="errors" class="col-3 control-wrapper form-group">
          <label for="max_lender_spread">Maximum Lender Spread</label>
          <input
            id="max_lender_spread"
            v-model="grid.maxLenderSpread"
            v-mask:percentage="maxLenderSpreadInputOptions"
            class="form-control"
          >
        </with-errors>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="btn btn-action-modal" @click="save">
            Save
          </div>
          <div class="btn btn-cancel-modal" @click="$emit('on-cancel')">
            Cancel
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PricingGrid from "models/pricing_grid";
import AjaxService from "services/ajax_service";

import FlatPickr from "vue-flatpickr-component";
import WithErrors from "components/shared/with_errors";
import Grid from "./grid";

export default {
  name: "pricing-grid-form",
  components: { FlatPickr, WithErrors, Grid },
  props: {
    grid: {
      type: PricingGrid,
      required: true,
    },
    routes: {
      type: Object,
      required: true,
    },
  },
  data() {
    const maxLenderSpreadInputOptions = { minus: false };
    const minPurchaseRateInputOptions = { minus: false };

    return {
      errors: {},
      tiers: PSData.options.tiers,
      loanPrograms: PSData.options.loanPrograms,
      datePickerConfig: {
        dateFormat: "m/d/Y h:i K",
        enableTime: true,
        static: true,
        allowInput: true,
      },
      maxLenderSpreadInputOptions,
      minPurchaseRateInputOptions,
    };
  },
  computed: {
    title() {
      if (this.grid.isPersisted()) {
        return "Edit Pricing Grid";
      } else {
        return "Add New Pricing Grid";
      }
    },
  },
  watch: {
    grid: {
      deep: true,
      handler() {
        this.resetErrors();
      },
    },
  },
  methods: {
    save() {
      const params = { grid: this.grid.toPlainObject({ toSnakeCase: true }) };

      if (this.grid.isPersisted()) {
        this.update(params);
      } else {
        this.create(params);
      }
    },
    create(params) {
      const messages = { onSuccess: "Pricing Grid is successfully created", onError: this.onError };

      AjaxService.withNotifications(messages)
        .postJSON(this.routes.pricingGrids, params)
        .then(data => {
          this.$emit("on-grids-update", data);
        })
        .catch(() => {}); // stub; catch is performing in [onError])
    },
    update(params) {
      const url = `${this.routes.pricingGrids}/${this.grid.id}`;
      const messages = { onSuccess: "Pricing Grid is successfully updated", onError: this.onError };

      AjaxService.withNotifications(messages)
        .patchJSON(url, params)
        .then(data => {
          this.$emit("on-grids-update", data);
        })
        .catch(() => {}); // stub; catch is performing in [onError])
    },
    onError({ response, data }) {
      if (response.status === 422) {
        this.errors = data.errors;
      } else if (response.status === 403) {
        return {
          message: "You're not allowed to manage pricing grids",
          status: "error",
        };
      } else {
        Bugsnag.notify(new Error(`Unable to save pricing grid: ${response.statusText}`));
      }
    },
    resetErrors() {
      this.errors = {};
    },
  },
};
</script>
