<template>
  <div v-show="!field.isHidden" :field-id="field.id" class="diligence-row js-diligence-field">
    <div class="row">
      <div class="col-12">
        <label :id="fieldId" :for="field.id" class="diligence-control-label">
          <template v-if="field.tooltip">
            <span
              v-popover="{ placement: 'top', container: fieldIdSelector }"
              class="name with-popover"
            >
              {{ field.label }}
            </span>
            <div class="hidden" v-html="field.tooltip"></div>
            <span class="hint">
              {{ field.hint }}
            </span>
          </template>
          <template v-else="">
            <span class="name">
              {{ field.label }}
            </span>
            <span class="hint">
              {{ field.hint }}
            </span>
          </template>
        </label>
      </div>
      <div class="col-10">
        <textarea
          :id="field.id"
          v-model="field.value"
          :disabled="disabled"
          class="memo-field form-control"
          @blur="saveValueImmediately"
        ></textarea>
      </div>
      <div class="col-2">
        <div class="action-buttons">
          <div
            v-if="!field.hasAutocalculation()"
            class="radio-control radio-control-inline js-validate-checkbox"
          >
            <input
              :id="validateId"
              v-model="field.validated"
              :disabled="field.disabled"
              type="checkbox"
            />
            <label :for="validateId">
              <span class="control-toggle"></span>
            </label>
          </div>
          <div v-if="field.hasAutocalculation()" class="formula-icon">
            <i class="fas fa-magic" title="This field is automatically calculated"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextField from "./base/text_field";

const MemoField = TextField.extend({});

export default Vue.component("diligence-document-memo-field", MemoField);
</script>
