<template>
  <div>
    <div class="view-panel-header loan-documents-header">
      {{ panelTitle }}
    </div>
    <div class="view-panel-sub-header">
      The loan document statuses below are for Funded loans only. PeerStreet documents are required for Funding Into Origination and Table Funding loans.
    </div>
    <state-loan-documents-panel
      v-for="document in documents"
      :key="document.documentType"
      :document="document"
      :statuses-options="statusesOptions"
      :policy="policy"
      class="view-panel-row"
    />
  </div>
</template>
<script>
import AjaxService from "services/ajax_service";
import Document from "models/lender_legal_entities/document";
import LegalEntityState from "models/lender_legal_entities/state";
import selectOptionsToMapping from "utils/select_options_to_mapping";
import StateLoanDocumentsPanel from "./show_panel";

export default {
  name: "lender-legal-entity-state-loan-documents-list",
  components: {
    StateLoanDocumentsPanel,
  },
  props: {
    policy: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      documents: [],
      lenderLegalEntityState: new LegalEntityState(PSData.lenderLegalEntityState),
      routes: PSData.routes || {},
    };
  },
  computed: {
    panelTitle() {
      return `${this.lenderLegalEntityState.stateLabel} Loan Documents`;
    },
  },
  mounted() {
    AjaxService.getJSON(this.routes.stateLoanDocuments).then(data => {
      this.documents = Document.initDocuments(data.documents);
      this.statusesOptions = selectOptionsToMapping(data.options.statuses || []);
    });
  },
};
</script>
