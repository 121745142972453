import { LoanProduct } from "../enums";
import * as Scenarios from "./scenarios";

export default class ScenarioBuilder {
  static build(programType, scenarioData = {}) {
    let ScenarioClass;

    switch (programType) {
      case LoanProduct.residentialBridge.value:
        ScenarioClass = Scenarios.ResidentialBridgeScenario;
        break;
      case LoanProduct.residentialRentalLongTerm.value:
        ScenarioClass = Scenarios.ResidentialForRentScenario;
        break;
    }

    const scenarioObject = new ScenarioClass();
    this._assignDataToObject(scenarioObject, scenarioData);

    return scenarioObject;
  }

  static _assignDataToObject(scenarioObject, scenarioData) {
    // Assign all keys not prepended with an underscore
    // AKA "private" fields, as most JS engines still do not
    // properly support private class fields
    Object.keys(scenarioObject).forEach(key => {
      !key.startsWith("_") &&
        scenarioData.hasOwnProperty(key) &&
        (scenarioObject[key] = scenarioData[key]);
    });
  }
}
