<template>
  <report-loans
    enable-interval-filter
    :group="group"
    :interval-filter="intervalFilter"
    :loans="groupedLoans[group]"
    :state="loadingStates[group]"
    :lender-block-status-displayable="lenderBlockStatusDisplayable"
    :broker-tag-displayable="brokerTagDisplayable"
    :initial-sort="{ field: 'initialPurchaseDate', fieldType: 'date', direction: 1 }"
    :apply-settings="true"
  />
</template>

<script>
import { LOADING_STATUSES } from "models/reports/loan";
import HotlistLoansRule from "models/reports/hotlist_loans/rules/hotlist_loans_rule";
import MessageBusService from "services/message_bus_service";
import Loans from "collections/manage/loans";
import FilterParams from "models/hotlist/filter_params";
import ReportLoans from "components/reports/loans";

export default {
  components: { ReportLoans },
  props: {
    paidOffOnly: Boolean,
    group: {
      type: String,
      default: "purchased",
    },
  },
  data() {
    const {
      analystIds,
      propertyTypes,
      loanPrograms,
      accountTypes,
      interval,
      taskAssigneeIds,
    } = FilterParams.instance.loadParams();

    return {
      groupedLoans: Loans.emptyGroup,
      filterOptions: {
        analystIds: analystIds,
        propertyTypes: propertyTypes,
        loanPrograms: loanPrograms,
        accountTypes: accountTypes,
        interval: interval,
        taskAssigneeIds: taskAssigneeIds,
      },
      analysts: PSData.analysts,
      lenders: PSData.lenders,
      documents: PSData.documents,
      serverTime: PSData.serverTime,
      loadingStates: {
        [this.group]: LOADING_STATUSES.loading,
      },
      ruleBook: undefined,
      messageBus: MessageBusService.hotlistBus,
      propertyTypes: PSData.propertyTypes,
      loanPrograms: PSData.loanPrograms,
      accountTypes: PSData.accountTypes,
      lenderBlockStatusDisplayable: true,
      brokerTagDisplayable: true,
    };
  },
  computed: {
    intervalFilter() {
      return {
        value: this.filterOptions.interval,
        paidOffOnly: this.paidOffOnly,
      };
    },
  },
  watch: {
    groupedLoans: {
      immediate: true,
      deep: true,
      handler: () => {
        Vue.nextTick(() => $(".analyst-notes a").tooltip());
      },
    },
  },
  mounted() {
    this.ruleBook = new HotlistLoansRule(this);
    this.messageBus.attachRulebook(this.ruleBook);
  },
  beforeDestroy() {
    this.messageBus.detachRulebook(this.ruleBook);
  },
};
</script>
