<template>
  <div>
    <table class="table permissions-users-table">
      <thead>
      <tr>
        <th>Users ({{users.length}})</th>
        <th>Roles</th>
      </tr>
      </thead>
      <tbody>
        <UserItem v-for="user in sortedUsers"
                  :user="user"
                  :key="user.id"
                  :readonly="readonly"
                  @select-user="startRolesEditing"/>
      </tbody>
    </table>
    <EditRolesModal v-if="selectedUser"
                    :user="selectedUser"
                    :updating="updating"
                    @close-modal="resetSelectedUser"
                    ref="rolesModal"/>
  </div>
</template>

<script>
import UserItem from "components/permissions/users/item";
import EditRolesModal from "components/permissions/users/edit_roles_modal";

export default {
  components: { UserItem, EditRolesModal },
  props: {
    readonly: Boolean,
    updating: Boolean,
    users: Array,
  },
  data() {
    return {
      selectedUser: null,
    };
  },
  computed: {
    sortedUsers() {
      return this.users.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
  },
  methods: {
    startRolesEditing(user) {
      this.selectedUser = user;
      Vue.nextTick(() => {
        this.$refs.rolesModal.show();
      });
    },
    resetSelectedUser() {
      this.selectedUser = null;
    },
  },
};
</script>
