var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{staticClass:"loan-portfolio-table",attrs:{"data":_vm.loans,"loading":_vm.loading,"detailed":"","custom-detail-row":"","detail-key":"psLoanId","opened-detailed":_vm.loanIds,"show-detail-icon":false,"row-class":function (row) { return _vm.rowClass(row, 0); }},on:{"click":function (row) { return _vm.openTab(_vm.workflowsUrl(row)); }},scopedSlots:_vm._u([{key:"detail",fn:function(ref){
var props = ref.props;
return _vm._l((props.row.workflows.slice(1)),function(workflow,index){return _c('tr',{key:workflow.id,class:[_vm.rowClass(props.row, index + 1)],on:{"click":function($event){_vm.openTab(_vm.workflowsUrl(props.row))}}},[_c('td',{attrs:{"colspan":"7"}}),_vm._v(" "),_c('td',[_c('next-task-tooltip',{attrs:{"workflow":workflow}},[_c('div',{staticClass:"dimmable"},[_vm._v("\n            "+_vm._s(workflow.label)+"\n          ")])])],1),_vm._v(" "),_c('td',[_c('next-task-tooltip',{attrs:{"workflow":workflow}},[_c('div',{staticClass:"dimmable"},[_c('div',{staticClass:"workflow-progress"},[_c('base-progress',{attrs:{"value":workflow.progress,"type":"info","size":"small"}}),_vm._v("\n              "+_vm._s(workflow.progress)+"%\n            ")],1)])])],1),_vm._v(" "),_c('td',[_c('div',{staticClass:"dimmable"},[_c('span',{class:{ overdue: _vm.isOverdue(workflow.dueDate) }},[_vm._v("\n            "+_vm._s(_vm._f("formatDate")(workflow.dueDate,"MMM D", "MM/DD/YYYY"))+"\n          ")])])]),_vm._v(" "),_c('td',[_c('div',{staticClass:"dimmable"},[_vm._v("\n          "+_vm._s(workflow.age)+"d\n        ")])]),_vm._v(" "),_c('td',[_c('div',{staticClass:"dimmable"},[(workflow.delinquentTasksTotal > 0)?_c('span',{staticClass:"overdue-tasks"},[_vm._v("\n            "+_vm._s(workflow.delinquentTasksTotal)+" "),_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_c('span',[_vm._v("\n            -\n          ")])])])])})}},{key:"empty",fn:function(){return [_vm._v("\n    No loans\n  ")]},proxy:true}])},[_c('base-column',{attrs:{"field":"psLoanId"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("PS Loan ID/")]),_c('div',[_vm._v("Servicer ID")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.psLoanId))]),_vm._v(" "),_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('servicer-loan-info',{attrs:{"id":row.servicerLoanId,"url":row.servicerLoanUrl}})],1)]}}])}),_vm._v(" "),_c('base-column',{attrs:{"field":"loanTitle","label":"Loan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"loan-details"},[_vm._v("\n      "+_vm._s(_vm.loanAddress(row.loanTitle))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"loan-details"},[_vm._v("\n      "+_vm._s(_vm.loanStateDetails(row.loanTitle))+"\n    ")])]}}])}),_vm._v(" "),_c('base-column',{attrs:{"field":"assigneeName","label":"Asset Manager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(row.assigneeName)+"\n  ")]}}])}),_vm._v(" "),_c('base-column',{attrs:{"field":"unpaidPrincipalBalance","label":"Unpaid Principal","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(_vm._f("money")(row.unpaidPrincipalBalance))+"\n  ")]}}])}),_vm._v(" "),_c('base-column',{attrs:{"field":"borrowerPaymentDaysPastDue","label":"Days Past Due","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(row.borrowerPaymentDaysPastDue)+"\n  ")]}}])}),_vm._v(" "),_c('base-column',{attrs:{"field":"daysToMaturity","label":"Days to Maturity","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(row.daysToMaturity)+"\n  ")]}}])}),_vm._v(" "),_c('base-column',{attrs:{"field":"loanOwner","label":"Owner"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(row.loanOwner)+"\n  ")]}}])}),_vm._v(" "),_c('base-column',{attrs:{"field":"label","label":"Open Workflows"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('next-task-tooltip',{attrs:{"workflow":row.workflows[0]}},[_c('div',{staticClass:"dimmable"},[_vm._v("\n        "+_vm._s(row.workflows[0].label)+"\n      ")])])]}}])}),_vm._v(" "),_c('base-column',{attrs:{"field":"progress","label":"Progress","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('next-task-tooltip',{attrs:{"workflow":row.workflows[0]}},[_c('div',{staticClass:"dimmable"},[_c('div',{staticClass:"workflow-progress"},[_c('base-progress',{attrs:{"value":row.workflows[0].progress,"type":"info","size":"small"}}),_vm._v("\n          "+_vm._s(row.workflows[0].progress)+"%\n        ")],1)])])]}}])}),_vm._v(" "),_c('base-column',{attrs:{"field":"dueDate","label":"Due Date","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"dimmable"},[_c('span',{class:{ overdue: _vm.isOverdue(row.workflows[0].dueDate) }},[_vm._v("\n        "+_vm._s(_vm._f("formatDate")(row.workflows[0].dueDate,"MMM D", "MM/DD/YYYY"))+"\n      ")])])]}}])}),_vm._v(" "),_c('base-column',{attrs:{"field":"age","label":"Age"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"dimmable"},[_vm._v("\n      "+_vm._s(row.workflows[0].age)+"d\n    ")])]}}])}),_vm._v(" "),_c('base-column',{attrs:{"field":"delinquentTasksTotal","label":"Tasks Overdue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"dimmable"},[(row.workflows[0].delinquentTasksTotal > 0)?_c('span',{staticClass:"overdue-tasks"},[_vm._v("\n        "+_vm._s(row.workflows[0].delinquentTasksTotal)+" "),_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_c('span',[_vm._v("\n        -\n      ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }