<template>
  <div class="report-import-summary">
    <div class="back-to-imports">
      <a href="/manage/physical_doc_tracking_imports">&lt; Back to All Imports</a>
    </div>
    <report-header
      :report="report"
      :completed="isReportCompleted"
      :can-complete-report="canCompleteReport"
      :can-manage="canManage"
      @on-complete="markComplete"
    />
    <div class="report-details">
      <div class="report-details-nav">
        <tabs ref="doc-tracking-imports-tabs-component" :tabs="sectionTabs" @tab-selected="changeTab" />
      </div>
      <div v-if="showUnresolvedItems">
        <unresolved-items-group
          :items="unresolvedItems"
          :can-manage="canManage"
          @on-item-selected="itemSelected"
          @ignore-items="ignoreItems"
        />
      </div>
      <div v-if="showIgnoredItems">
        <ignored-items-group :items="ignoredItems" />
      </div>
      <div v-if="showSkippedItems">
        <skipped-items-group :items="skippedItems" />
      </div>
      <div v-if="showSuccessfulItems">
        <items-group :items="successfulItems" />
      </div>
      <div v-if="showFailedItems">
        <failed-items-group :items="failedItems" />
      </div>
    </div>
    <assign-document-modal
      v-if="showAssignDocumentModal"
      :show="showAssignDocumentModal"
      :report-item="selectedReportItem"
      :report-id="report.id"
      @on-item-resolved="assignDocumentUpdateReport"
      @on-close="closeAssignDocumentModal"
    />
    <ignore-items-modal
      v-if="showIgnoreItemsModal"
      :show="showIgnoreItemsModal"
      :count="itemsToIgnoreCount"
      :items-resolving="itemsResolving"
      @ignore="ignore"
      @on-close="closeIgnoreItemsModal"
    />
  </div>
</template>

<script>
import Tabs from "components/shared/tabs";
import ReportHeader from "./report_header.vue";
import ItemsGroup from "./items_group.vue";
import FailedItemsGroup from "./failed_items_group.vue";
import SkippedItemsGroup from "./skipped_items_group.vue";
import IgnoredItemsGroup from "./ignored_items_group.vue";
import UnresolvedItemsGroup from "./unresolved_items_group.vue";
import AssignDocumentModal from "./assign_document_modal.vue";
import IgnoreItemsModal from "./ignore_item_modal";

import AjaxService from "services/ajax_service";

export default {
  name: "physical-doc-tracking-report-import-details",
  components: {
    Tabs,
    ReportHeader,
    ItemsGroup,
    FailedItemsGroup,
    SkippedItemsGroup,
    IgnoredItemsGroup,
    UnresolvedItemsGroup,
    AssignDocumentModal,
    IgnoreItemsModal,
  },
  props: {
    reportImport: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      report: { ...this.reportImport },
      markCompleteInProgress: false,
      selectedReportItem: undefined,
      itemsToIgnore: undefined,
      canManage: PSData.policy.manage,
      activeTab: "unresolved",
      itemsResolving: false,
    };
  },
  computed: {
    sectionTabs() {
      return {
        unresolved: {
          name: this.itemsGroupTitle("Unresolved", this.report.unresolvedItems.length),
          active: this.activeTab == "unresolved",
          hasActivity: this.report.unresolvedItems.filter(item => item.statusTypeChanged).length,
        },
        ignored: {
          name: this.itemsGroupTitle("Ignored", this.report.ignoredItems.length),
          active: this.activeTab == "ignored",
        },
        skipped: {
          name: this.itemsGroupTitle("Skipped", this.report.skippedItems.length),
          active: this.activeTab == "skipped",
          hasActivity: this.report.skippedItems.filter(item => item.statusTypeChanged).length,
        },
        successful: {
          name: this.itemsGroupTitle("Success", this.report.successfulItems.length),
          active: this.activeTab == "successful",
        },
        failed: {
          name: this.itemsGroupTitle("Failed", this.report.failedItems.length),
          active: this.activeTab == "failed",
        },
      };
    },
    showUnresolvedItems() {
      return this.sectionTabs.unresolved.active;
    },
    showIgnoredItems() {
      return this.sectionTabs.ignored.active;
    },
    showSkippedItems() {
      return this.sectionTabs.skipped.active;
    },
    showSuccessfulItems() {
      return this.sectionTabs.successful.active;
    },
    showFailedItems() {
      return this.sectionTabs.failed.active;
    },
    unresolvedItems() {
      return this.report.unresolvedItems;
    },
    ignoredItems() {
      return this.report.ignoredItems;
    },
    failedItems() {
      return this.report.failedItems;
    },
    skippedItems() {
      return this.report.skippedItems;
    },
    successfulItems() {
      return this.report.successfulItems;
    },
    showAssignDocumentModal() {
      return !!this.selectedReportItem;
    },
    showIgnoreItemsModal() {
      return !!this.itemsToIgnore;
    },
    allItemsResolved() {
      return this.unresolvedItems.length === 0;
    },
    canCompleteReport() {
      return !this.isReportCompleted && this.allItemsResolved && !this.markCompleteInProgress;
    },
    isReportCompleted() {
      return !!this.report.completed;
    },
    itemsToIgnoreCount() {
      return this.itemsToIgnore.length;
    },
    ignoreMessage() {
      return `${this.itemsToIgnoreCount} item${
        this.itemsToIgnoreCount > 1 ? "s" : ""
      } successfully ignored.`;
    },
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
    itemsGroupTitle(groupName, itemsCount) {
      return `${groupName} (${itemsCount})`;
    },
    itemSelected(item) {
      this.selectedReportItem = item;
    },
    ignoreItems(items) {
      this.itemsToIgnore = items;
    },
    closeAssignDocumentModal() {
      this.selectedReportItem = undefined;
    },
    closeIgnoreItemsModal() {
      this.itemsToIgnore = undefined;
    },
    markComplete() {
      this.markCompleteInProgress = true;

      AjaxService.withNotifications({
        onSuccess: "Report Import has been marked as completed.",
        onError: res => ({ message: res.data.error, status: "error" }),
      })
        .post(`/manage/physical_doc_tracking_imports/${this.report.id}/mark_complete`)
        .then(data => {
          this.report = data.report;
        })
        .finally(() => {
          this.markCompleteInProgress = false;
        });
    },
    updateReport(report) {
      this.report = report;
    },
    assignDocumentUpdateReport(report) {
      this.updateReport(report);
      this.closeAssignDocumentModal();
    },
    ignoreItemsUpdateReport(report) {
      this.updateReport(report);
      this.closeIgnoreItemsModal();
    },
    ignore() {
      this.itemsResolving = true;
      const url = "/manage/physical_doc_tracking/report_import_items/ignore_items";
      const payload = {
        report_import_id: this.report.id,
        report_import_items: this.itemsToIgnore.map(item => item.id),
      };

      AjaxService.withNotifications({
        onSuccess: this.ignoreMessage,
        onError: res => ({ message: res.data.error, status: "error" }),
      })
        .post(url, payload, { "Content-Type": "application/json" })
        .then(data => {
          this.updateReport(data.report);
          this.closeIgnoreItemsModal();
        })
        .finally(() => {
          this.itemsResolving = false;
        });
    },
  },
};
</script>
