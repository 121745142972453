import selectOptionsToMapping from "utils/select_options_to_mapping";

export default class PropertyManagement {
  constructor(propertyManagement = {}, options = {}) {
    this.update(propertyManagement);
    this.setOptions(options);
  }

  get yesNoOptions() {
    return {
      true: "Yes",
      false: "No",
    };
  }

  get agreementPresentYN() {
    return this.yesNoOptions[this.agreementPresent];
  }

  get isFeesAmount() {
    return this.managementFeesType === "amount";
  }

  get isFeesPoints() {
    return this.managementFeesType === "points";
  }

  setOptions(options) {
    this.statesOptions = selectOptionsToMapping(options.states || []);
  }

  toHash() {
    return {
      name: this.name,
      address: this.address,
      city: this.city,
      state: this.state,
      zip_code: this.zipCode,
      email: this.email,
      phone: this.phone,
      agreement_present: this.agreementPresent,
      agreement_name: this.agreementName,
      agreement_date: this.agreementDate,
      management_fees_amount: this.isFeesAmount ? this.managementFeesAmount : null,
      management_fees_points: this.isFeesPoints ? this.managementFeesPoints : null,
    };
  }

  update(propertyManagement) {
    this._raw = propertyManagement;
    this.name = this.nullIfMissingAgreement(propertyManagement.name);
    this.address = this.nullIfMissingAgreement(propertyManagement.address);
    this.city = this.nullIfMissingAgreement(propertyManagement.city);
    this.state = this.nullIfMissingAgreement(propertyManagement.state);
    this.zipCode = this.nullIfMissingAgreement(propertyManagement.zipCode);
    this.email = this.nullIfMissingAgreement(propertyManagement.email);
    this.phone = this.nullIfMissingAgreement(propertyManagement.phone);
    this.agreementName = this.nullIfMissingAgreement(propertyManagement.agreementName);
    this.agreementDate = this.nullIfMissingAgreement(propertyManagement.agreementDate);
    this.agreementPresent = propertyManagement.agreementPresent;
    this.managementFeesAmount = this.nullIfMissingAgreement(
      propertyManagement.managementFeesAmount
    );
    this.managementFeesPoints = this.nullIfMissingAgreement(
      propertyManagement.managementFeesPoints
    );
    this.managementFeesType = this.nullIfMissingAgreement(propertyManagement.managementFeesType);
  }

  nullIfMissingAgreement(fields) {
    return this._raw.agreementPresent ? fields : null;
  }

  reset() {
    this.update(this._raw);
  }
}
