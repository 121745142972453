var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('state-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var batch = ref.batch;
            var form = ref.form;
            var errors = ref.errors;
            var isValidating = ref.isValidating;
            var isSelling = ref.isSelling;
            var validationPassed = ref.validationPassed;
            var handleReset = ref.handleReset;
            var handleValidate = ref.handleValidate;
            var handleSell = ref.handleSell;
            var handleFormUpdate = ref.handleFormUpdate;
return [_c('div',{staticClass:"container-layout-vert-padding loan-sales"},[_c('h3',{staticClass:"loan-sales-page-heading"},[_vm._v("\n      Sales Automation\n    ")]),_vm._v(" "),_c('error-messages',{attrs:{"title":"Error!","errors":errors}}),_vm._v(" "),_c('div',{staticClass:"loan-sales-content-wrapper"},[_c('aside',{staticClass:"loan-sales-form"},[_c('h4',{staticClass:"loan-sales-page-sub-heading"},[_vm._v("\n          Add Loans\n        ")]),_vm._v(" "),_c('loan-sale-batch-form',{attrs:{"disabled":isValidating || validationPassed,"form":form},on:{"validate":handleValidate,"update":handleFormUpdate}})],1),_vm._v(" "),_c('loan-sale-detail',{attrs:{"batch":batch,"form":form,"disabled":isSelling},on:{"reset":handleReset,"sell":handleSell,"update":handleFormUpdate}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }