<template>
  <loan-boarding-table label="New Loan Boarding" :count="count">
    <template #actions>
      <div class="servicer-loan-boarding-table-actions">
        <div class="select-all-rows">
          <check-box v-model="allSelected" :partial="isPartiallySelected" @change="toggleAll"></check-box>
        </div>
        <div class="buttons">
          <button
            type="button"
            class="btn btn-action-cta"
            @click="submit"
            :disabled="isNoneSelected() || inFlight">
            Submit to Subservicer
          </button>
          <button
            type="button"
            class="btn btn-outline-grey"
            @click="moveToPipeline"
            :disabled="isNoneSelected()">
            Move Back to Pipeline
          </button>
        </div>
      </div>
    </template>
    <template #guardrail>
      <loan-boarding-guardrails :collection="collection"></loan-boarding-guardrails>
    </template>
    <template #header>
      <thead>
        <th class="checkbox-header"></th>
        <th>Loan ID</th>
        <th class="address">Address</th>
        <th>Review Flags</th>
        <th>Notes To Subservicer</th>
        <th>PS Internal Notes</th>
        <th>Purchase Date/<br>Orig Date</th>
        <th class="amount">Orig UPB/<br>Current UPB</th>
        <th class="amount">P&I Pymts/<br>Impound Pymts</th>
        <th class="amount">Prepaid Int<br>& Payments</th>
        <th class="amount">Odd Days<br>Interest</th>
        <th class="amount">Escrow<br>Reserves</th>
        <th class="percent">Note/<br>Blended Lender</th>
        <th>Maturity<br>Date</th>
        <th>Ready To Submit Date</th>
      </thead>
    </template>
    <template #body>
      <tr v-for="item in collection" :key="item.id" :id="item.id">
        <td class="checkbox-column">
          <check-box v-model="item.checked" @change="toggleItem"></check-box>
        </td>
        <td>{{ item.psLoanId }}<br>
          <loan-boarding-details-popup :item="item"></loan-boarding-details-popup>
        </td>
        <td class="address">
          <loan-boarding-truncated-text :value="item.address"></loan-boarding-truncated-text>
        </td>
        <td>
          <loan-boarding-review-flags :record="item"></loan-boarding-review-flags>
        </td>
        <td class="note-cell">
          <loan-boarding-notes-to-subservicer :record="item">
          </loan-boarding-notes-to-subservicer>
        </td>
        <td class="note-cell">
          <loan-boarding-ps-internal-notes :record="item">
          </loan-boarding-ps-internal-notes>
        </td>
        <td>{{ item.purchaseDate }}<br>{{ item.originationDate }}</td>
        <td class="amount">{{ item.totalPrincipalBalanceAtOrigination | accounting }}<br>{{ item.unpaidPrincipalBalance | accounting }}</td>
        <td class="amount">{{ item.monthlyPaymentPrincipalAndInterest | accounting }}<br>{{ item.monthlyPaymentTaxesAndInsurance | accounting }}</td>
        <td class="amount">{{ item.interestReserveAmount | accounting }}</td>
        <td class="amount">{{ item.oddDaysInterest | accounting }}</td>
        <td class="amount">{{ item.escrowReserveAmount | accounting }}</td>
        <td class="percent">{{ item.interestRate | percent(4) }}<br>{{ item.lenderBlendedRate | percent(4) }}</td>
        <td>{{ item.maturityDate }}</td>
        <td>{{ item.approvedAt }}</td>
      </tr>
    </template>
  </loan-boarding-table>
</template>
<script>
import LoanBoardingTruncatedText from "components/servicer/loan_boarding/truncated_text";
import LoanBoardingDetailsPopup from "components/servicer/loan_boarding/details_popup";
import LoanBoardingNotesToSubservicer from "components/servicer/loan_boarding/notes_to_subservicer";
import LoanBoardingPsInternalNotes from "components/servicer/loan_boarding/ps_internal_notes";
import LoanBoardingListMixin from "components/servicer/loan_boarding/mixin/list";
import LoanBoardingUpdateStatusMixin from "components/servicer/loan_boarding/mixin/update_status";
import LoanBoardingReviewFlags from "components/servicer/loan_boarding/review_flags";
import LoanBoardingGuardrails from "components/servicer/loan_boarding/guardrails";
import LoanBoardingTable from "components/servicer/loan_boarding/table";
import CheckBox from "components/shared/check_box";

export default {
  mixins: [LoanBoardingListMixin, LoanBoardingUpdateStatusMixin],
  name: "manage-servicer-loan-boarding-ready-to-submit",
  components: {
    LoanBoardingDetailsPopup,
    LoanBoardingTruncatedText,
    LoanBoardingNotesToSubservicer,
    LoanBoardingPsInternalNotes,
    LoanBoardingReviewFlags,
    LoanBoardingGuardrails,
    LoanBoardingTable,
    CheckBox,
  },
  props: {
    collection: Array,
    routes: Object,
  },
  methods: {
    moveToPipeline() {
      this.updateStatus(this.checkedItems(), "pending").then(collections =>
        this.$emit("updated", collections)
      );
    },
    submit() {
      this.submitBatch(this.checkedItems()).then(collections => this.$emit("updated", collections));
    },
  },
};
</script>
