<template>
  <div class="sla-task">
    <span class="task">{{ name }}:</span>
    <span class="countdown" :class="statusClasses">
      {{ statusText }}
    </span>
  </div>
</template>

<script>
import current from "current";
import moment from "moment";

export default {
  name: "sla-countdown",
  props: {
    name: {
      type: String,
      required: true,
    },
    deadline: {
      type: [Date, String],
      default: null,
    },
    completed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      current: current,
    };
  },
  computed: {
    statusClasses() {
      const { completed, deadline, duration } = this;

      return {
        "sla-disabled": !deadline,
        "sla-success": deadline && completed,
        "sla-danger": deadline && !completed && duration.asHours() < 7,
      };
    },
    statusText() {
      const { deadline, completed, formattedDeadline } = this;

      if (!deadline) {
        return "Not Started";
      }

      if (completed) {
        return "Completed";
      }

      return formattedDeadline;
    },
    formattedDeadline() {
      const { duration } = this;
      const hoursLeft = duration.asHours();

      if (hoursLeft <= -1 || hoursLeft >= 2) {
        return `${parseInt(hoursLeft)}hrs`;
      }

      if (hoursLeft >= 1) {
        return ">1hr";
      }

      return `${parseInt(duration.minutes())}min`;
    },
    duration() {
      const {
        deadline,
        current: { moment: now },
      } = this;
      return moment.duration(moment(deadline).diff(now));
    },
  },
};
</script>
