<template>
  <div>
    <view-panel>
      <template slot="header">
        Fictitious Names (DBA)
      </template>
      <table class="view-table">
        <thead>
          <tr>
            <th>Fictitious Name</th>
            <th>States</th>
            <th>Notes</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="fictitiousName in fictitiousNames" :key="fictitiousName.id">
            <td class="view-table-cell-text">
              {{ fictitiousName.name }}
            </td>
            <td class="view-table-cell-text">
              {{ formatStates(fictitiousName.states) }}
            </td>
            <td class="view-table-cell-text">
              {{ fictitiousName.notes }}
            </td>
            <td class="actions" v-if="canManage">
              <a href="#" title="Edit fictitious name" @click.prevent="editFictitiousName(fictitiousName)">
                <i class="fas fa-pencil-alt"/>
              </a>
              <a href="#" title="Delete fictitious name" @click.prevent="deleteFictitiousName(fictitiousName)">
                <i class="fas fa-trash-alt"/>
              </a>
            </td>
          </tr>
        </tbody>

        <tfoot v-if="canManage">
          <tr>
            <td colspan="4">
              <a href="#" title="Add Fictitious Name" @click.prevent="addFictitiousName">
                + Add Fictitious Name
              </a>
            </td>
          </tr>
        </tfoot>
      </table>
    </view-panel>
    <upsert-fictitious-name-modal
      v-if="showUpsertFictitiousNameModal"
      :show="showUpsertFictitiousNameModal"
      :fictitious-name="selectedFictitiousName"
      :routes="routes"
      :on-close="resetSelectedFictitiousName"
      @on-save="onFictitiousNameSave"
    />
  </div>
</template>

<script>
import ViewPanel from "components/view_panel";
import UpsertFictitiousNameModal from "./form_modal";
import WindowService from "services/window_service";
import AjaxService from "services/ajax_service";
import MessageBusService from "services/message_bus_service";

import FictitiousName from "models/lender_legal_entities/fictitious_name";

export default {
  name: "lender-legal-entities-fictitious-names-panel",
  components: { ViewPanel, UpsertFictitiousNameModal },
  props: {
    routes: {
      type: Object,
      required: true,
    },
    policy: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedFictitiousName: undefined,
      fictitiousNames: [],
      bus: MessageBusService.entityBus,
    };
  },
  computed: {
    showUpsertFictitiousNameModal() {
      return !!this.selectedFictitiousName;
    },
    canManage() {
      return this.policy.canManage;
    },
  },
  mounted() {
    AjaxService.getJSON(this.routes.fictitiousNamesPath).then(data => {
      this.fictitiousNames = FictitiousName.initFictitiousNames(data);
    });
  },
  methods: {
    addFictitiousName() {
      this.selectedFictitiousName = new FictitiousName();
    },
    editFictitiousName(fictitiousName) {
      this.selectedFictitiousName = fictitiousName.clone();
    },
    deleteFictitiousName(fictitiousName) {
      if (!WindowService.confirm("Are you sure you want to remove this fictitious name?")) {
        return;
      }

      const messages = {
        onSuccess: "Fictitious Name (DBA) has been deleted",
        onError: ({ response }) => {
          if (response.status === 403) {
            return { message: "Unauthorized", status: "error" };
          } else {
            return { message: "Something went wrong", status: "error" };
          }
        },
      };

      AjaxService.withNotifications(messages)
        .delete(`${this.routes.fictitiousNamesPath}/${fictitiousName.id}`)
        .then(data => {
          this.fictitiousNames = FictitiousName.initFictitiousNames(data);
          this.bus.broadcast("entity-updated");
        });
    },
    formatStates(states) {
      return states.join(", ");
    },
    resetSelectedFictitiousName() {
      this.selectedFictitiousName = undefined;
    },
    onFictitiousNameSave(fictitiousNames) {
      this.fictitiousNames = FictitiousName.initFictitiousNames(fictitiousNames);
      this.selectedFictitiousName = undefined;
    },
  },
};
</script>
