<template>
  <base-modal
    class="grid-view-modal"
    :cancel-options="cancelOptions"
    :show="show"
    :show-cancel="false"
    :show-save="false"
    title="Custom pricing grid"
    can-cancel="true"
    @close="onClose"
  >
    <div v-if="grid" class="grid-pricing">
      <div class="grid-row">
        <div class="grid-cell empty"></div>
        <div v-for="(column, index) in grid.columns" :key="index" class="grid-cell fico">
          <template v-if="column.attr == 'residency'">
            {{ titleize(column.value) }}
          </template>
          <template v-else-if="column.condition == 'in'">
            <span>{{column.value[0]}}</span>
            <span class="dash">-</span>
            <span>{{column.value[1]}}</span>
          </template>
        </div>
      </div>
      <div v-for="(row, index) in grid.rows" :key="index" class="grid-row">
        <div class="grid-cell ltv">
          <template v-if="row.condition == 'in'">
            <span>{{row.value[0]}}</span>
            <span>%</span>
            <span class="dash">-</span>
            <span>{{row.value[1]}}</span>
            <span>%</span>
          </template>
        </div>
        <div v-for="(rate, rateInd) in grid.adjustments[index]" :key="index +`-`+ rateInd" class="grid-cell rate">
          <span>{{formatPercent(grid.adjustments[index][rateInd] || undefined)}}</span>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { titleize, formatPercent } from "./utils/formatter";

export default {
  name: "grid-view-modal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    grid: {
      required: true,
    },
  },
  data() {
    return {
      cancelOptions: ["escape", "x", "outside"],
    };
  },
  methods: {
    titleize,
    formatPercent,
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.grid-view-modal >>> .modal-card {
  min-height: 298px;
}
.grid-view-modal >>> .modal-card-body {
  padding-bottom: 24px;
  padding-top: 24px;
}
.grid-view-modal >>> .modal-card {
  min-width: 680px;
  width: auto;
}
.grid-view-modal >>> .modal-card-foot {
  display: none;
  height: 0;
}
.grid-view-modal >>> .animation-content {
  max-width: 1306px !important;
}
.grid-view-modal .grid-pricing,
.grid-view-modal .grid-row {
  display: flex;
}
.grid-view-modal .grid-pricing {
  flex-direction: column;
  overflow: scroll;
}
.grid-view-modal .grid-row:last-of-type .grid-cell {
  margin-top: 0;
}
.grid-view-modal .grid-cell {
  border-color: var(--color-grey-16);
  border-style: solid;
  border-width: 0 1px 2px 0;
  display: flex;
  height: 55px;
  padding-left: 12px;
  padding-right: 12px;
}
.grid-view-modal .grid-cell,
.grid-view-modal .empty {
  min-width: 100px;
  width: 100px;
}
.grid-view-modal .rate,
.grid-view-modal .fico {
  min-width: 128px;
}
.grid-view-modal .ltv + .rate,
.grid-view-modal .empty + .fico {
  border-left-width: 1px;
  min-width: 148px;
}
.grid-view-modal .fico,
.grid-view-modal .ltv {
  line-height: 16px;
}
.grid-view-modal .fico {
  border-top-width: 2px;
  flex: 0 0 auto;
  justify-content: left;
}
.grid-view-modal .fico,
.grid-view-modal .ltv {
  align-items: center;
  background: var(--color-grey-06);
  font-weight: 600;
  font-size: var(--font-size);
  line-height: 19px;
}
.grid-view-modal .ltv {
  border-left-width: 2px;
}
.grid-view-modal .rate {
  margin-bottom: 0;
}
.grid-view-modal .grid-cell .dash {
  padding: 0 4px;
}
</style>
