import get from "lodash/get";

function detectOrder(a, b, direction) {
  return direction === 1 ? [a, b] : [b, a];
}

export function defaultSorting(a, b) {
  switch (typeof a) {
    case "string":
      return a.localeCompare(b);
    case "number":
    case "boolean":
      return a - (b || 0);
    default:
      return 0;
  }
}

export default class SortingHelper {
  constructor(sortingOptions, keepConsistentWith) {
    this.sortingOptions = sortingOptions;
    this.keepConsistentWith = keepConsistentWith;
  }

  findDirection(expectedColumn, actualColumn, actualDirection) {
    if (expectedColumn === actualColumn) {
      return actualDirection;
    } else {
      return 0;
    }
  }

  sort(column, direction) {
    return (a, b) => {
      [a, b] = detectOrder(a, b, direction);
      const options = this.sortingOptions[column];

      const expectedOrder = options.sortBy
        ? options.sortBy(a, b)
        : defaultSorting(get(a, column), get(b, column));

      if (expectedOrder === 0 && this.keepConsistentWith) {
        return this.keepConsistentWith(a, b);
      } else {
        return expectedOrder;
      }
    };
  }

  activeSortClass(expectedColumn, actualColumn) {
    if (expectedColumn === actualColumn) {
      return "hotlist-active-column";
    } else {
      return "";
    }
  }
}
