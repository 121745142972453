class Comparison {
  constructor(...sources) {
    this.sources = [...sources];
  }

  get startDate() {
    let startDates = this.sources.map(source => source.startDate).filter(date => date);
    return new Date(Math.max.apply(null, startDates));
  }

  get endDate() {
    let endDates = this.sources.map(source => source.endDate).filter(date => date);
    return new Date(Math.min.apply(null, endDates));
  }

  get hasData() {
    return Object.values(this.data).some(source => {
      return source.observed.data.length > 0 || source.predicted.data.length > 0;
    });
  }

  get data() {
    if (!this._data) {
      this._data = Object.values(this.sources).reduce((data, source) => {
        data[source.name] = source.data(this.startDate, this.endDate);

        return data;
      }, {});
    }

    return this._data;
  }
}

PS.Models.MarketData.Charts.Comparison = Comparison;
