<template>
  <div class="form-group">
    <label>{{ label }}</label>
    <select
      :name="name"
      :value="value"
      class="form-control"
      @input="$emit('filter', name, $event.target.value)"
    >
      <option></option>
      <option v-for="item in subLenderNoteStatusOptions" :key="item.id" :value="item.id">
        {{ item.text }}
      </option>
    </select>
  </div>
</template>

<script>
const SUB_LENDER_NOTE_STATUS_OPTIONS = [{ id: "fast_funding_facility", text: "FFF" }];

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      innerValue: this.value,
      subLenderNoteStatusOptions: SUB_LENDER_NOTE_STATUS_OPTIONS,
    };
  },
  watch: {
    innerValue(v) {
      this.$emit("filter", this.name, v);
    },
  },
};
</script>
