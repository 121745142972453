<template>
  <view-panel class="loan-details-view-panel">
    <template slot="header">
      Roles
      <button class="btn btn-action-cta-sm float-right" name="edit-roles" @click="$emit('on-edit')">
        Edit Roles
      </button>
    </template>
    <div class="row loan-details-view-panel-row">
      <div class="col-6 loan-details-view-panel-column">
        <table class="view-table">
          <thead>
            <tr>
              <th>Role</th>
              <th>Borrower</th>
              <th>Additional Details</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="role in party.responsibleRoles" :key="role.id">
              <td>{{ role.definition.label }}</td>
              <td>{{ role.borrowerName }}</td>
              <td>{{ role.additionalDetails }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </view-panel>
</template>

<script>
import ViewPanel from "components/view_panel.vue";

export default {
  name: "responsible-roles-details-table",
  components: {
    ViewPanel,
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>
