const Events = PS.Models.MarketData.Events;
const Commands = PS.Models.MarketData.Commands;
const Rule = PS.Models.Rule;
const SessionStorageService = PS.Services.SessionStorageService;
const SOURCE_OF_SEARCH = PS.Models.MarketData.Const.SOURCE_OF_SEARCH;

class MarketDataRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.AllMarketDataRequested:
        return this.whenAllMarketDataRequested(event);

      case Events.LenderMarketDataSearchRequested:
        return this.whenLenderMarketDataSearchRequested(event);

      case Events.CheckForPredefinedSearch:
        return this.whenCheckForPredefinedSearch(event);
    }

    return super.check(event);
  }

  whenAllMarketDataRequested(event) {
    return [
      new Commands.LoadRiskAnalysisData(event, this.state),
      new Commands.LoadClearCapitalZipData(event, this.state),
      new Commands.LoadMidasData(event, this.state),
    ];
  }

  whenLenderMarketDataSearchRequested(event) {
    const commands = [];

    if (event.source === "/tools/zip_report") {
      commands.push(new Commands.LoadLenderMarketData(event, this.state));
    } else {
      commands.push(new Commands.TransferSearchToMarketDataPage(event, this.state));
    }

    return commands;
  }

  whenCheckForPredefinedSearch(event) {
    const commands = [];

    const searchParams = new URLSearchParams(PS.Services.LocationService.search);
    const zip = searchParams.get("zip");

    if (zip) {
      const source =
        SessionStorageService.removeItem(SOURCE_OF_SEARCH) || PS.Services.LocationService.pathname;

      event.source = source;
      event.zip = zip;

      commands.push(new Commands.LoadLenderMarketData(event, this.state));
    }

    return commands;
  }
}

PS.Models.MarketData.MarketDataRule = MarketDataRule;
