<template>
  <div>
    <div v-if="isNotEmpty" class="details-row row non-total-row">
      <div class="col-12"><h4>Fees</h4></div>
      <div class="interest-row">
        <div class="col-6 border-row tab" v-for="fee in fees" :key="fee.id">
          <editable-field
            :label="fee.feeLabel"
            :value="fee.amount"
            :editing="false"
          />
        </div>
      </div>
      <div class="col-6 dark-border-row dark-top-border-row total">
        <editable-field
          label="Total Fees"
          :value="payoffStatement.totalFees"
          :editing="false"
          currency
        />
      </div>
      <div class="col-6 total dark-top-border-row">
        <editable-field
          label="Waived Fees"
          :value="payoffStatement.totalWaivedFees"
          :editing="false"
          currency
        />
      </div>
      <div class="col-6 dark-border-row total">
        <editable-field
          label="Waived Fees"
          :value="payoffStatement.totalWaivedFees"
          :editing="false"
          currency
        />
      </div>
      <div class="col-6"></div>
      <div class="col-6 total">
        <editable-field
          label="Net Fees"
          :value="payoffStatement.netFees"
          :editing="false"
          currency
        />
      </div>
    </div>
    <div v-else class="details-row row non-total-row">
      <div class="col-12"><h4>Fees</h4></div>
      <div class="col-12 form-group">No Fees</div>
    </div>
  </div>
</template>
<script>
import EditableField from "components/shared/editable_field.vue";
import NumberFormatter from "models/formatters/number_formatter";

export default {
  name: "manage-payoff-statement-show-fees",
  components: {
    EditableField,
  },
  props: {
    payoffStatement: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isNotEmpty() {
      return this.payoffStatement.fees.some(record => record.amount);
    },
    fees() {
      return this.payoffStatement.fees.reduce((fees, fee) => {
        const waivedValue = NumberFormatter.formatPercentAndCurrency(-fee.waivedAmount, fee.amount);
        const amountValue = NumberFormatter.formatMoney(fee.amount);

        fees.push({ feeLabel: fee.feeLabel, amount: amountValue });
        fees.push({ feeLabel: fee.feeWaivedLabel, amount: waivedValue });

        return fees;
      }, []);
    },
  },
};
</script>
