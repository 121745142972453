<template>
  <table class="table import-data-table">
    <thead v-show="importData">
      <th v-for="header in headers" :key="header">{{ header }}</th>
    </thead>
    <tr v-for="(row, index) in rows" :key="index">
      <td v-for="(column, index) in row" :key="index">
        <div v-if="Array.isArray(column)">
          <div class="text-danger" v-for="(message, index) in column" :key="index"> {{ message }}</div>
        </div>
        <span v-else>{{ column }}</span>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "import-data-table",
  props: {
    importData: {
      type: Object,
      required: true,
      default: () => ({ headers: [], rows: [] }),
    },
  },
  computed: {
    headers() {
      return [...this.importData.headers, "errors"];
    },
    rows() {
      const rows = this.importData.rows;

      return rows.map(row => {
        return this.headers.map(header => {
          const value = row[header] || "";
          if (header === "errors") return [].concat(...value);
          return value;
        });
      });
    },
  },
};
</script>
