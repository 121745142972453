<template>
  <div class="row my-2">
    <div class="col-sm">
      <label>{{ label }}</label>
    </div>

    <div class="col-sm">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
