var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details-row columns is-multiline"},[_vm._m(0),_vm._v(" "),(_vm.failedToLoadFees)?_c('div',{staticClass:"column is-full"},[_c('base-message',{attrs:{"type":"warning","title":"Request error","closable":false}},[_vm._v("Can't preload loan's fees at the moment")])],1):_vm._e(),_vm._v(" "),(_vm.successfullyLoadedFees)?_c('div',{staticClass:"column is-full"},[_c('base-message',{attrs:{"type":"success","title":"Success","closable":false}},[_vm._v("Successfully loaded "+_vm._s(_vm.feesCount)+" fees")])],1):_vm._e(),_vm._v(" "),(!_vm.isPeerstreetRepurchaseStatement)?_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('recoverability-filter',{attrs:{"disabled":_vm.isExistingStatement},model:{value:(_vm.recoverable),callback:function ($$v) {_vm.recoverable=$$v},expression:"recoverable"}})],1)])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"column is-full"},[_c('totals',{attrs:{"payoff-statement":_vm.statement}})],1),_vm._v(" "),_c('div',{staticClass:"column is-full"},[_c('base-table',{staticClass:"payoff-fees-edit-table",attrs:{"loading":_vm.loading,"data":_vm.statement.fees,"scrollable":true}},[_c('base-column',{attrs:{"label":"Fee Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('base-select-field',{model:{value:(row.feeName),callback:function ($$v) {_vm.$set(row, "feeName", $$v)},expression:"row.feeName"}},_vm._l((_vm.feeTypeOptions),function(optionGroup){return _c('optgroup',{key:optionGroup.label,attrs:{"label":optionGroup.label}},_vm._l((optionGroup.options),function(option){return _c('option',{key:option[1],domProps:{"value":option[1]}},[_vm._v("\n              "+_vm._s(option[0])+"\n            ")])}),0)}),0),_vm._v(" "),(row.feeName == 'other_fees')?_c('editable-field',{attrs:{"label":"Name","editing":_vm.isEditable,"disabled":false,"value":row.name,"callback":_vm.updateEditableField('name', row)}}):_vm._e()]}}])}),_vm._v(" "),_c('base-column',{attrs:{"label":"Incurred Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('base-date-field',{model:{value:(row.incurredDate),callback:function ($$v) {_vm.$set(row, "incurredDate", $$v)},expression:"row.incurredDate"}})]}}])}),_vm._v(" "),_c('base-column',{attrs:{"label":"Incurred Amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('base-currency-field',{attrs:{"disabled":""},model:{value:(row.incurredAmount),callback:function ($$v) {_vm.$set(row, "incurredAmount", $$v)},expression:"row.incurredAmount"}})]}}])}),_vm._v(" "),_c('base-column',{attrs:{"label":"Amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('base-currency-field',{on:{"input":_vm.updateAmount},model:{value:(row.amount),callback:function ($$v) {_vm.$set(row, "amount", $$v)},expression:"row.amount"}})]}}])}),_vm._v(" "),_c('base-column',{attrs:{"label":"Waived Amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"editable-field-container"},[_c('waived-input',{attrs:{"value":row.waivedAmount,"amount":row.amount,"data-change":_vm.updateEditableField('waivedAmount', row)}})],1)]}}])}),_vm._v(" "),_c('base-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('base-button',{staticClass:"editable-action",attrs:{"type":"iconBlue","icon":"trash-alt"},on:{"click":function($event){return _vm.removeFee(row)}}})]}}])})],1)],1),_vm._v(" "),_c('div',{staticClass:"column is-full"},[_c('a',{on:{"click":_vm.addFee}},[_vm._v("+ Add Fees")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-full"},[_c('h4',[_vm._v("Fees")])])}]

export { render, staticRenderFns }