<template>
  <div class="form-block">
    <label>{{ label }}</label>
    <div class="radio-control-blue radio-control-inline">
      <div class="radio">
        <input
          type="radio"
          :id="yesRadioId"
          :checked="value === true"
          :value="true"
          @change="updateValue"
        >
        <label class="radio-value-label" :for="yesRadioId">
          Yes
        </label>
      </div>

      <div class="radio">
        <input
          type="radio"
          :id="noRadioId"
          :checked="value === false"
          :value="false"
          @change="updateValue"
        >
        <label class="radio-value-label" :for="noRadioId">
          No
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import uniqueId from "lodash/uniqueId";

export default {
  props: {
    label: String,
    value: Boolean,
  },
  data() {
    return {
      yesRadioId: uniqueId("radio"),
      noRadioId: uniqueId("radio"),
    };
  },
  methods: {
    updateValue(e) {
      this.$emit("input", e.target.value === "true");
    },
  },
};
</script>
