<template>
  <div class="loan-program-selector">
    <h1>What type of project do you a need a loan for?</h1>
    <div class="loan-program-tile-container">
      <div
        v-for="loanProgram in activeLoanPrograms"
        v-bind:key="loanProgram.programType" 
        :class="['loan-program-tile',  { disabled: disabled }]" 
        v-on:click="setProgramType(loanProgram.programType)"
        :id="`${loanProgram.programType}_tile`"
        >
        <img :src="loadLoanProgramIcon(loanProgram)" class="loan-program-icon loan-program-tile-item"/>
        <h2 class="loan-program-tile-item">{{ loanProgram.name }}</h2>
        <ul>
          <li v-for="tagline in loanProgram.taglines" v-bind:key="tagline">
            {{ tagline }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { PROGRAM_CLASSIFICATION } from "models/lender_form/program";
import GlobalBusService from "services/global_bus_service";

const LOAN_PROGRAMS = [
  {
    name: "Residential Bridge",
    programType: PROGRAM_CLASSIFICATION.RESIDENTIAL_BRIDGE,
    description: "Insert a description about this loan no more than two lines lorem ipsum.",
    taglines: ["Up to 12 months", "Up to 75% LTV"],
    enabled: true,
  },
  {
    name: "Residential for Rent",
    programType: PROGRAM_CLASSIFICATION.RESIDENTIAL_RENTAL_LONG_TERM,
    description: "Insert a description about this loan no more than two lines lorem ipsum.",
    taglines: ["Industry leading rates", "Up to 80% LTV"],
    enabled: true,
  },
  {
    name: "Multifamily Bridge",
    programType: PROGRAM_CLASSIFICATION.COMMERCIAL_MULTIFAMILY,
    description: "Insert a description about this loan no more than two lines lorem ipsum.",
    taglines: ["5+ units", "Up to 12 months", "Up to 70% LTV"],
    enabled: PSData.featureFlag.multifamilyBridge,
  },
  {
    name: "Ground Up Construction",
    programType: PROGRAM_CLASSIFICATION.GROUND_UP_CONSTRUCTION,
    description: "Insert a description about this loan no more than two lines lotem ipsum.",
    taglines: ["Fully entitled projects", "Up to 85% LTV"],
    enabled: PSData.featureFlag.groundUpConstruction,
  },
];

export default {
  name: "loan-program-selector",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeLoanPrograms: LOAN_PROGRAMS.filter(loanProgram => loanProgram.enabled),
    };
  },
  methods: {
    setProgramType(program) {
      if (this.disabled) return;

      this.isLoading = true;
      GlobalBusService.$emit("program-type-selected", program);
    },
    loadLoanProgramIcon(loanProgram) {
      return require(`./icons/${loanProgram.programType}.svg`);
    },
  },
};
</script>

<style scoped lang="scss">
.loan-program-selector {
  margin-bottom: 48px;
}

.loan-program-tile-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

.loan-program-tile-item {
  margin-bottom: 8px;
  justify-content: center;
  text-align: center;
}

.loan-program-selector h1 {
  display: flex;
  justify-content: center;
  font-size: 28px;
  margin: 32px 0px;
  font-weight: 600;
}

.loan-program-tile ul {
  font-weight: 600;
}

.loan-program-tile li {
  display: inline;
  &:not(:first-child):before {
    content: "\2022";
  }
}

.loan-program-tile h2 {
  font-size: 24px;
  font-weight: 600;
}

.loan-program-tile {
  align-items: center;
  background: white;
  border-radius: 4px;
  border: 1px solid var(--color-grey-16);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0px 24px 32px;
  width: 376px;

  &:hover {
    cursor: pointer;
    background-color: #eff7ff;
    border: 1px solid var(--color-blue);
  }
}

.loan-program-icon {
  width: 80px;
  margin: 32px 0px 16px 0;
}

.disabled {
  pointer-events: none;
}
</style>
