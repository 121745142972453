<template>
  <div class="loan-task-view">
    <div class="loan-task-view-header loan-task-view-section">
      <div class="loan-task-view-header-back" @click="onBackButton">
        <img
          class="loan-task-view-header-back-icon"
          :src="require('images/icons/icon_left_arrow_task.svg')" />
        Back
      </div>

      <div
        v-if="task.isConfirmed"
        class="loan-task-buttons-container custom-loan-task-buttons-completed">
        <template v-if="task.isLender">
          <div class="loan-task-buttons dropdown-button btn-group" role="group">
            <button class="btn btn-action-completed loan-task-btn" name="loan-task-confirm">
              Completed
            </button>

            <template v-if="task.isLender">
              <button type="button" class="btn btn-action-completed loan-task-btn dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-caret-down"></i>
              </button>
              <div class="dropdown-menu">
                <button
                  class="dropdown-item loan-task-btn"
                  name="loan-task-reopen"
                  @click="reopenTask"
                >
                  Reopen
                </button>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <i class="fas fa-check"></i>
          Completed
        </template>
      </div>

      <div v-else class="loan-task-buttons-container custom-loan-task-buttons">
        <div v-if="this.isEditable">
          <i class="fas fa-pencil-alt fa-lg" @click="showEditForm"></i>
          <i class="far fa-trash-alt fa-lg" @click="deleteTask"></i>
        </div>

        <div class="loan-task-buttons dropdown-button btn-group" role="group" v-if="canComplete">
          <button class="btn btn-action-cta loan-task-btn" name="loan-task-confirm" @click="confirmTask">
            Mark Complete
          </button>

          <template v-if="task.isLender">
            <button type="button" class="btn btn-action-cta loan-task-btn dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-caret-down"></i>
            </button>
            <div class="dropdown-menu">
              <button
                v-if="task.isPending"
                class="dropdown-item loan-task-btn"
                name="loan-task-reopen"
                @click="reopenTask"
              >
                Reopen
              </button>
              <button
                v-else
                class="dropdown-item loan-task-btn"
                name="loan-task-complete"
                @click="moveTaskToPending"
              >
                Move to Pending
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div v-if="shouldShowEditForm" class="form-task-section edit-task-section">
      <custom-task-form
        v-if="task.isCustom"
        :task="task"
        :task-type="task.definition.taskType"
        :task-groups="groups"
        @canceled="hideEditForm"
        @form-submitted="updateTask"
      >
        <template slot="submit-text">
          <span>Update</span>
        </template>
      </custom-task-form>

      <question-task-form
        v-if="task.isLenderQuestion"
        :task="task"
        :task-groups="groups"
        @form-submitted="updateTask"
        @form-canceled="hideEditForm"
      >
        <template slot="submit-text">
          <span>Update</span>
        </template>
      </question-task-form>
    </div>

    <div v-if="!shouldShowEditForm" class="loan-task-events">
      <div class="loan-task-content">
        <div class="loan-task-view-section">
          <div class="loan-task-view-title">
            {{ task.title }}
          </div>
          <div class="loan-task-view-section-content">
            <assignment-section
              :task="task"
              :users="users"
              :can-edit-assignee="canUpdateLoanTaskData"
              class="loan-task-assignment"
              @task-assigned="onTaskAssigned"
              @assignee-deleted="onAssigneeDeleted"
            />
            <internal-task-section
              v-if="task.isInternal"
              :task="task"
              :can-update-loan-task-data="canUpdateLoanTaskData"
              @task-updated="updateTask"
            />
          </div>
        </div>

        <div
          v-if="hasDescription"
          class="loan-task-view-description content"
          v-html="task.description"
        />

        <task-attachments-section
          :task="task"
          :documents="documents"
          :upload-settings="uploadSettings"
          :can-upload-files="canUpdateLoanTaskData"
        />

        <div v-show="isLoading" class="loan-task-loader">
          <div class="ps-loader ps-loader-small" />
        </div>
        <comments-list :comments="comments" />
      </div>
      <div class="messages-widget">
        <internal-comments-form
          :task="task"
          :can-pick-type="task.isLender"
          :can-update-external-comments="canUpdateExternalComments"
          :can-update-internal-comments="canUpdateInternalComments"
          @created="onNewComment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DetailsMixin from "components/activity_feed_events/loan_tasks/details_mixin";
import CustomTaskForm from "components/activity_feed_events/manage/loan_tasks/custom/form";
import QuestionTaskForm from "components/activity_feed_events/manage/loan_tasks/question/form";
import AssignmentSection from "components/activity_feed_events/manage/loan_tasks/task_assignment/section";
import InternalTaskSection from "components/activity_feed_events/manage/loan_tasks/internal/section";
import TaskAttachmentsSection from "components/activity_feed_events/loan_tasks/task_attachments_section";
import InternalCommentsForm from "components/activity_feed_events/loan_tasks/comments/manage/form";

export default {
  name: "manage-loan-tasks-details",
  components: {
    CustomTaskForm,
    QuestionTaskForm,
    AssignmentSection,
    InternalTaskSection,
    TaskAttachmentsSection,
    InternalCommentsForm,
  },
  mixins: [DetailsMixin],
  data: () => ({
    shouldShowEditForm: false,
  }),
  props: {
    documents: Array,
    uploadSettings: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      default: () => [],
    },
    canUpdateLoanTaskData: {
      type: Boolean,
      required: true,
    },
    canUpdateExternalComments: {
      type: Boolean,
      required: true,
    },
    canUpdateInternalComments: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    canComplete() {
      return this.task.canComplete;
    },
    isEditable() {
      const { isCustom, isQuestion, isConfirmed } = this.task;

      return !isConfirmed && (isCustom || isQuestion) && this.canUpdateLoanTaskData;
    },
    hasDescription() {
      const { description } = this.task;

      return !!description && description.trim().length > 0;
    },
  },
  methods: {
    showEditForm() {
      this.shouldShowEditForm = true;
    },
    hideEditForm() {
      this.shouldShowEditForm = false;
    },
    onTaskAssigned(assignee) {
      this.$emit("task-assigned", assignee);
    },
    onAssigneeDeleted(assignee) {
      this.$emit("assignee-deleted", assignee);
    },
  },
};
</script>
