<template>
  <div class="form-group">
    <label>{{ label }}</label>
    <select
      :name="name"
      :value="value"
      class="form-control"
      @input="$emit('filter', name, $event.target.value)"
    >
      <option></option>
      <option v-for="item in legalOwners" :key="item.id" :value="item.id">
        {{ item.text }}
      </option>
    </select>
  </div>
</template>

<script>
import sortBy from "lodash/sortBy";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    const legalOwners = PSData.legalOwners.filter(
      item => !["ps_funding_inc", "retail"].includes(item.id)
    );
    legalOwners.push({ id: "ps_funding_inc", text: "Retail" });

    return { innerValue: this.value, legalOwners: sortBy(legalOwners, "text") };
  },
  watch: {
    innerValue(v) {
      this.$emit("filter", this.name, v);
    },
  },
};
</script>
