<template>
  <div v-if="readonly" class="form-group">
    <label>{{ label }}</label>
    <div>{{ value }}</div>
  </div>
  <div v-else class="form-group">
    <label>{{ label }}</label>
    <input
      :name="name"
      :value="value"
      class="form-control"
      type="search"
      @input="$emit('filter', name, $event.target.value.trim())"
    >
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "loanId",
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "PS Loan ID",
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
