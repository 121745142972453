<template>
  <select-input
    label="Funding Entity"
    name="fundingEntityId"
    rules="required"
    :value="value"
    :options="options"
    :disabled="disabled || isDisabledSaleType"
    v-on="$listeners"
  />
</template>

<script>
import get from "lodash/get";
import find from "lodash/find";
import SelectInput from "./select_input";

const ENABLED_SALE_TYPES = ["transfer", "participation"];

export default {
  components: {
    SelectInput,
  },
  props: {
    saleType: {
      type: String,
      default: "",
    },
    legalOwnerId: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    disabled: Boolean,
  },
  computed: {
    legalOwnerOptions() {
      return PSData.legalOwnerOptions[this.saleType];
    },
    selectedLegalOwnerOption() {
      if (!this.legalOwnerId) {
        return null;
      }

      return find(this.legalOwnerOptions, { id: parseInt(this.legalOwnerId) });
    },
    options() {
      return get(this.selectedLegalOwnerOption, "fundingEntities", []);
    },
    isDisabledSaleType() {
      return !ENABLED_SALE_TYPES.includes(this.saleType);
    },
  },
  watch: {
    saleType: {
      immediate: true,
      handler() {
        this.updateSelection();
      },
    },
    legalOwnerId: {
      immediate: true,
      handler() {
        this.updateSelection();
      },
    },
  },
  methods: {
    updateSelection() {
      if (this.options.length == 1) {
        this.$emit("input", this.options[0].id.toString());
      } else {
        this.$emit("input", "");
      }
    },
  },
};
</script>
