<template>
  <div class="payoff-statement-details payoff-statement-show-details">
    <payoff-statement-show-overview />
    <payoff-statement-show-header :payoff-statement="statement" />
    <payoff-statement-show-dates :payoff-statement="statement" />
    <principal-section />
    <interest-section />
    <payoff-statement-show-fees :payoff-statement="statement" />
    <credits-section />
    <payoff-statement-show-comments :comments="statement.comments"/>
  </div>
</template>

<script>
import PayoffStatementShowDetailsMixin from "components/manage/payoff_statement/show/details_mixin";
import PayoffStatementShowOverview from "components/manage/payoff_statement/resale/show/overview";
import PrincipalSection from "./principal_section";
import InterestSection from "./interest_section";
import CreditsSection from "./credits_section";
import { mapState } from "pinia";
import useResaleStatementStore from "controllers/manage/store/resaleStatement";

export default {
  components: { PayoffStatementShowOverview, PrincipalSection, InterestSection, CreditsSection },
  mixins: [PayoffStatementShowDetailsMixin],
  computed: {
    ...mapState(useResaleStatementStore, ["statement"]),
  },
};
</script>
