const Events = PS.Models.Diligence.Events;
const Commands = PS.Models.Diligence.Commands;
const Rule = PS.Models.Rule;

class ChecklistRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.Checklist.DeselectDocumentTypeRequested:
        return this.whenDeselectDocumentTypeRequested(event);

      case Events.Checklist.SelectDocumentTypeRequested:
        return this.whenSelectDocumentTypeRequested(event);

      case Events.Checklist.RequestBlockUI:
        return this.whenRequestBlockUI(event);

      case Events.Checklist.RequestUnblockUI:
        return this.whenRequestUnblockUI(event);
    }

    return super.check(event);
  }

  whenDeselectDocumentTypeRequested(event) {
    return [new Commands.Checklist.DeselectDocumentType(event, this.state)];
  }

  whenSelectDocumentTypeRequested(event) {
    return [new Commands.Checklist.SelectDocumentType(event, this.state)];
  }

  whenRequestBlockUI(event) {
    return [new Commands.Checklist.BlockUI(event, this.state)];
  }

  whenRequestUnblockUI(event) {
    return [new Commands.Checklist.UnblockUI(event, this.state)];
  }
}

PS.Models.Diligence.ChecklistRule = ChecklistRule;
