<template>
  <div class="col-md-2">
    <ul class="menu">
      <li
        class="item"
        v-for="(item, id) in items"
        :class="{ active: isActive(id) }"
        :key="id"
        @click="setView(item)">
        {{ item.label }}
        <i class="fas fa-caret-right" />
      </li>
    </ul>
  </div>
</template>
<script>
export const VIEWS = {
  distributionRules: "distributionRules",
  subservicerFees: "subservicerFees",
  distributionFees: "distributionFees",
};

export const MENU_ITEMS = {
  [VIEWS.distributionRules]: {
    id: VIEWS.distributionRules,
    component: "DistributionRules",
    label: "Distribution Rules",
  },
  [VIEWS.subservicerFees]: {
    id: VIEWS.subservicerFees,
    component: "SubservicerFees",
    label: "Subservicer Fees",
  },
  [VIEWS.distributionFees]: {
    id: VIEWS.distributionFees,
    component: "DistributionFees",
    label: "Distribution Fees",
  },
};

export const defaultView = view => {
  if (view && VIEWS[view] !== undefined) {
    return MENU_ITEMS[view];
  } else {
    return MENU_ITEMS[VIEWS.distributionRules];
  }
};

export default {
  props: {
    currentView: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: MENU_ITEMS,
    };
  },
  methods: {
    setView(view) {
      this.$emit("view", view);
    },
    isActive(view) {
      return this.currentView.id === view;
    },
  },
};
</script>
