const KEY_ESC = 27;
const KEY_ENTER = 13;

export function isEscKey(e) {
  return e.keyCode === KEY_ESC;
}

export function isCtrlEnterKey(e) {
  return (e.ctrlKey || e.metaKey) && e.keyCode === KEY_ENTER;
}
