<template>
  <div>
    <base-table
      :data="batches"
      :loading="loading"
      :paginated="true"
      :per-page="10"
      :pagination-simple="true"
    >
      <base-column
        v-slot="props"
        field="lender"
        label="Lender"
      >
        {{ props.row.lender }}
      </base-column>
      <base-column
        v-slot="props"
        field="sellingChannelText"
        label="Selling Channel"
      >
        {{ props.row.sellingChannelText }}
      </base-column>
      <base-column
        v-slot="props"
        field="archived"
        label="Upload as Archived"
        centered
      >
        {{ props.row.archived | yesNo }}
      </base-column>
      <base-column
        v-slot="props"
        field="createdAt"
        label="Import Time"
      >
        {{ props.row.createdAt | formatTime }}
      </base-column>
      <base-column
        v-slot="props"
        field="importedBy"
        label="Imported By"
      >
        {{ props.row.importedBy }}
      </base-column>
      <base-column
        v-slot="props"
        field="id"
        label="Batch ID"
        centered
      >
        <base-tooltip
          v-if="props.row.completed"
          label="View Import Batch Details"
          with-delay
          position="is-top"
        >
          <a :href="props.row.batchURL">{{ props.row.id }}</a>
        </base-tooltip>
        <base-tag v-else type="warning">In Progress</base-tag>
      </base-column>
      <template v-slot:empty>
        No Batches
      </template>
    </base-table>
  </div>
</template>

<script>
export default {
  props: {
    batches: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
