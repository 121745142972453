<template>
  <tr>
    <td>
      <a :href="loanLink">{{ batchLoanId }}</a>
    </td>
    <td class="allocation-table-amount">
      {{ batchLoan.loan.totalPrincipalBalance | accounting }}
    </td>
    <td class="allocation-table-amount">
      {{ purchaseRate | percent(3) }}
    </td>
    <td>
      <div v-show="!isEligibilityDataPresent" class="ps-loader ps-loader-small" />
      <eligible-buyers-input
        v-if="isEligibilityDataPresent"
        :loan-id="batchLoanId"
        :included-buyers="allocationBuyers"
        :disabled-buyers="batchLoan.ineligibleBuyers"
        :edit-mode="editMode"
        :show-active-but-ineligible="true"
        @change="changeBuyers"
      />
    </td>
    <td>
      <div v-if="editMode">
        <select2
          :value="forcedBuyer"
          class="form-control"
          :options="options"
          @input="overrideBuyers"
        >
          <option value="">
            None
          </option>
          <option v-for="buyer in batchLoan.ineligibleBuyers" :key="buyer" :value="buyer">
            {{ buyerLabel(buyer) }}
          </option>
        </select2>
      </div>
      <div v-else>
        {{ buyerLabel(forcedBuyer) }}
      </div>
    </td>
    <td>
      <span v-if="showMode">{{ batchLoan.loan.previousAllocation }}</span>
      <span v-else>{{ batchLoan.loan.currentAllocation }}</span>
    </td>
    <td>
      <span v-if="showMode">{{ batchLoan.loan.currentAllocation }}</span>
    </td>
    <td v-if="editMode">
      <i class="fas fa-times loans-remove-button" @click="remove" />
    </td>
  </tr>
</template>
<script>
import EligibleBuyersInput from "components/loans/eligibility_boxes/eligible_buyers_input";
import Select2 from "components/shared/select2";

export default {
  edit: "loans-row",
  components: {
    EligibleBuyersInput,
    Select2,
  },
  props: {
    batchLoan: {
      type: Object,
      default: function() {
        return {};
      },
    },
    buyers: {
      type: Array,
      default: function() {
        return [];
      },
    },
    editMode: Boolean,
    showMode: Boolean,
    options: {
      type: Object,
      default: () => ({ width: "100%" }),
    },
  },
  computed: {
    isEligibilityDataPresent() {
      if (this.batchLoan.id !== undefined) {
        return true;
      }

      return this.batchLoan.buyersLoaded;
    },

    batchLoanId() {
      return this.batchLoan.loan.psId;
    },

    loanLink() {
      return `/manage/loans/${this.batchLoan.loan.psId}`;
    },

    purchaseRate() {
      return (
        parseFloat(this.batchLoan.loan.interestRate) - parseFloat(this.batchLoan.loan.lenderSpread)
      );
    },

    isBuyerOverriden() {
      if (
        this.batchLoan.allocationBuyers.length === 1 &&
        this.batchLoan.ineligibleBuyers.includes(this.batchLoan.allocationBuyers[0])
      ) {
        return true;
      }
      return false;
    },

    forcedBuyer() {
      if (this.isBuyerOverriden) {
        return this.batchLoan.allocationBuyers[0];
      }
      return null;
    },

    allocationBuyers() {
      if (this.isBuyerOverriden) {
        return [];
      }
      return this.batchLoan.allocationBuyers;
    },
  },
  created() {
    if (!this.isEligibilityDataPresent) {
      this.$emit("load-eligibility-data", this.batchLoan);
    }
  },
  methods: {
    remove() {
      this.$emit("remove-loan", this.batchLoan);
    },
    changeBuyers(includedBuyers) {
      this.$emit("change-buyers", this.batchLoan, includedBuyers);
    },
    overrideBuyers(buyerName) {
      this.$emit("override-buyers", this.batchLoan, buyerName);
    },
    buyerLabel(buyerName) {
      const foundBuyer = this.buyers.find(buyer => buyer.name === buyerName) || {};
      return foundBuyer.label;
    },
  },
};
</script>
