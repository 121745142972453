<template>
  <div class="edit-actions">
    <a @click="update" href="#" class="btn btn-action-cta"> <i class="fas fa-pencil-alt"></i>
    Update
    </a>
    <a @click="cancel" href="#" class="btn btn-cancel-cta">
    Cancel
    </a>
  </div>
</template>
<script>
export default {
  name: "manage-payoff-statement-edit-button",
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    update() {
      this.$emit("update");
    },
  },
};
</script>
