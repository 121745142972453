<template>
  <validation-provider
    :rules="rules"
    v-slot="{ valid }"
    slim>
    <input
      v-model="innerValue"
      class="form-control"
      :class="validationClass(valid)"
      type="text"
    />
  </validation-provider>
</template>
<script>
import toNumber from "lodash/toNumber";
import { ValidationProvider } from "vee-validate";

export default {
  components: { ValidationProvider },
  props: {
    value: [String, Number],
    upb: [String, Number],
  },
  data() {
    return { innerValue: this.value };
  },
  computed: {
    rules() {
      return { required: true, between: { min: 1, max: toNumber(this.upb) } };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.innerValue = val;
      },
    },
    innerValue: {
      immediate: true,
      handler(val) {
        this.$emit("input", toNumber(val));
      },
    },
  },
  methods: {
    validationClass: valid => (valid === null ? null : valid ? "is-valid" : "is-invalid"),
  },
};
</script>
