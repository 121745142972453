<template>
  <div class="d-flex align-items-center">
    <h3 class="cash-receipts-subheader spacer">
      {{ header }}
      <a class="clear" v-if="hasLoanIdFilter" @click="clearLoanIdFilter" href="#">
        <i class="clear-icon fas fa-times-circle" />
        Clear
      </a>
    </h3>

    <approve-button :items="items" @approve="$listeners.approve" class="mr-2" />
    <distribute-button :items="items" @showDistributeConfirmation="showDistributeConfirmation" />
    <distribute-confirmation
      v-if="isDistributeConfirmationShown"
      :items="items"
      @close="hideDistributeConfirmation"
      @distribute="$listeners.distribute"
    />
  </div>
</template>

<script>
import DistributeButton from "./distribute_button";
import ApproveButton from "./approve_button";
import DistributeConfirmation from "./distribute_confirmation";

export default {
  components: {
    DistributeButton,
    ApproveButton,
    DistributeConfirmation,
  },
  props: {
    filters: Object,
    items: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isDistributeConfirmationShown: false,
    };
  },
  methods: {
    clearLoanIdFilter() {
      this.$emit("clear-loan-id-filter");
    },
    showDistributeConfirmation() {
      this.isDistributeConfirmationShown = true;
    },
    hideDistributeConfirmation() {
      this.isDistributeConfirmationShown = false;
    },
  },
  computed: {
    header() {
      if (this.hasLoanIdFilter) {
        return `Showing ${this.items.length} of ${this.total} Transactions for PS Loan ID "${
          this.filters.loanId
        }"`;
      } else {
        return `Showing ${this.items.length} of ${this.total} Transactions`;
      }
    },
    hasLoanIdFilter() {
      return this.filters.loanId !== "";
    },
  },
};
</script>
