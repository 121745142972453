<template>
  <div class="details-row row">
    <div class="col-12">
      <h4>Overview</h4>
    </div>
    <div class="col-6 border-row">
      <div class="editable-field-container">
        <label class="loan-attribute-label control-label">Seller</label>
        <div>{{ seller }}</div>
      </div>
    </div>
    <div class="col-6"></div>
    <div class="col-6 border-row">
      <div class="editable-field-container">
        <label class="loan-attribute-label control-label">Reason for Sale</label>
        <div>{{ payoffStatement.payoffReason }}</div>
      </div>
    </div>
    <div class="col-6"></div>
    <div class="col-6" v-bind:class="{ 'border-row': !payoffStatement.servicingReleased }">
      <div class="editable-field-container">
        <label class="loan-attribute-label control-label">Servicing Released</label>
        <div>{{ payoffStatement.servicingReleased ? "Yes" : "No" }}</div>
      </div>
    </div>
    <div class="col-6" v-if="!payoffStatement.servicingReleased"></div>
    <div class="col-6 border-row" v-if="!payoffStatement.servicingReleased">
      <div class="editable-field-container">
        <label class="loan-attribute-label control-label">Remaining Construction Reserve</label>
        <div class="editable-field-value">
          {{ constructionReserveAmount }}
        </div>
      </div>
    </div>
    <div class="col-6" v-if="!payoffStatement.servicingReleased"></div>
    <div class="col-6" v-if="!payoffStatement.servicingReleased">
      <div class="editable-field-container">
        <label class="loan-attribute-label control-label">Remaining Other Reserve</label>
        <div class="editable-field-value">
          {{ otherReserveAmount }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberFormatter from "models/formatters/number_formatter";

export default {
  name: "payoff-statement-show-overview",
  props: ["payoffStatement"],
  computed: {
    seller() {
      return this.payoffStatement["seller"];
    },
    constructionReserveAmount() {
      return this.formatCurrency(this.payoffStatement.constructionReserveAmount);
    },
    otherReserveAmount() {
      return this.formatCurrency(this.payoffStatement.otherReserveAmount);
    },
  },
  methods: {
    formatCurrency(value) {
      return NumberFormatter.formatAccounting(Number.parseFloat(value));
    },
  },
};
</script>
