import AjaxService from "services/ajax_service";
const INTERVAL = 3000;

class PriceStatusService {
  constructor() {
    this.interval = this.defaultInterval;
    this.polling = false;
  }

  get defaultInterval() {
    return INTERVAL;
  }

  async getPrice(url) {
    const res = await AjaxService.get(url);

    if (res && res.loan) {
      return res.loan;
    } else {
      return this.poll(url);
    }
  }

  poll(url) {
    return new Promise(resolve => {
      const i = setInterval(async () => {
        if (this.polling) {
          return;
        }

        this.polling = true;
        const res = await AjaxService.get(url);
        this.polling = false;

        if (res && res.loan) {
          clearInterval(i);
          resolve(res.loan);
        }
      }, this.interval);
    });
  }
}

export default new PriceStatusService();
