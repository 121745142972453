<template>
  <div class="settings-actions">
    <a class="btn btn-action-cta" :disabled="!enabled" @click="save()">Save</a>
    <template v-if="enabled">
      <a class="btn btn-cancel-cta" @click="reset()">Reset</a>
    </template>
  </div>
</template>

<script>
const Commands = PS.Models.EmailSubscription.Commands;
const Event = PS.Models.Event;

export default Vue.component("email-subscriptions-actions", {
  props: {
    hasChanges: Boolean,
    updating: Boolean,
  },
  computed: {
    enabled: function() {
      return this.hasChanges && !this.updating;
    },
  },
  methods: {
    reset: function() {
      new Commands.Table.RequestResetChanges(new Event(), {}).execute();
    },
    save: function() {
      new Commands.Table.RequestSaveChanges(new Event(), {}).execute();
    },
  },
});
</script>
