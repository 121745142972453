<template>
  <div>
    <h3 class="lender-entities-header">
      {{header}}
    </h3>

    <a v-if="canAddStates" href="#" class="add-state-link" v-on:click.prevent="openNewModal">+ Add a State</a>

    <div class="row no-gutters">
      <ul v-for="states in groupedStates" class="lender-entities-states-list col-4">
        <li v-for="state in states" :key="state.id">
          <a :href="stateLink(state)">{{ state.stateLabel }}</a>
        </li>
      </ul>
    </div>

    <lender-legal-entity-state-form-modal
      :show="isEditModalOpened"
      :states-options="statesOptions"
      @close="closeNewModal"
      @lender-legal-entity-state-saved="openState"
    />
  </div>
</template>
<script>
import LegalEntityState from "models/lender_legal_entities/state";
import AjaxService from "services/ajax_service";
import Location from "services/location_service";
import selectOptionsToMapping from "utils/select_options_to_mapping";
import LenderLegalEntityStateFormModal from "./form_modal";
import chunk from "lodash/chunk";
const STATES_CHUNK_SIZE = 12;

export default {
  name: "lender-legal-entity-state-panel",
  components: {
    LenderLegalEntityStateFormModal,
  },
  props: {
    policy: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      isEditModalOpened: false,
      states: [],
      statesOptions: null,
      routes: PSData.routes || {},
    };
  },
  computed: {
    canAddStates() {
      return this.statesOptions && this.policy.canManage;
    },
    header() {
      return `States (${this.states.length})`;
    },
    groupedStates() {
      return chunk(this.states, STATES_CHUNK_SIZE);
    },
  },
  mounted() {
    AjaxService.getJSON(this.routes.lenderLegalEntityStates).then(data => {
      this.states = LegalEntityState.initStates(data.states);
      this.statesOptions = this.availableStatesOptions(data.states, data.options);
    });
  },
  methods: {
    openNewModal() {
      this.isEditModalOpened = true;
    },
    closeNewModal() {
      this.isEditModalOpened = false;
    },
    openState(newState) {
      Location.href = `${this.stateLink(newState)}#new`;
    },
    stateLink(state) {
      return `${this.routes.lenderLegalEntityStates}/${state.id}`;
    },
    availableStatesOptions(states, options) {
      const addedStates = states.map(stateData => stateData.state);
      const availableStatesOptions = options.states.filter(
        stateOption => !addedStates.includes(stateOption[1])
      );

      return selectOptionsToMapping(availableStatesOptions);
    },
  },
};
</script>
