<template>
  <modal-form
    :show="true"
    title="Close Workflow"
    description="Close an incomplete workflow if no more progress is required. Task in this workflow will no longer be editable. This cannot be undone."
    save-button-name="Mark as Closed"
    :save-disabled="submitDisabled"
    class="workflows-section-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <form @keypress.enter="onSave">
      <editable-select
        v-validate="'required'"
        label="Closing Reason"
        name="assetManagementWorkflowClosedReasonId"
        :options="relevantClosingReasons"
        :editing="true"
        :value="form.assetManagementWorkflowClosedReasonId"
        @input="updateForm"
      />
      <div v-if="isOtherReason" class="form-group">
        <label class="control-label">Description</label>
        <textarea
          v-validate="{ required: isOtherReason }"
          class="form-control"
          name="otherClosedReason"
          rows="4"
          :value="form.otherClosedReason"
          @input="updateForm"
        />
      </div>
    </form>
  </modal-form>
</template>
<script>
import ModalForm from "components/modal_form";
import EditableSelect from "../../editable_select";

export default {
  components: {
    ModalForm,
    EditableSelect,
  },
  $_veeValidate: {
    validator: "new",
  },
  props: {
    closingReasons: {
      type: Array,
      default: () => [],
    },
    isSaving: Boolean,
  },
  data() {
    return {
      form: {
        assetManagementWorkflowClosedReasonId: null,
        otherClosedReason: null,
      },
      isValid: false,
    };
  },
  watch: {
    "form.assetManagementWorkflowClosedReasonId": function() {
      this.form.otherClosedReason = null;
    },
  },
  computed: {
    hasErrors() {
      return !this.isValid;
    },
    submitDisabled() {
      return this.hasErrors || this.isSaving;
    },
    isOtherReason() {
      const workflowClosedReason = this.relevantClosingReasons.find(
        reason => reason.id == this.form.assetManagementWorkflowClosedReasonId
      );
      if (!workflowClosedReason) {
        return false;
      }

      return workflowClosedReason.name === "other";
    },
    relevantClosingReasons() {
      return this.closingReasons.filter(reason => reason.name !== "completed");
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    async updateForm(e) {
      this.$set(this.form, e.target.name, e.target.value);
      await Vue.nextTick();
      this.isValid = await this.$validator.validateScopes();
    },
    onSave() {
      this.$emit("save", this.form);
    },
  },
};
</script>
