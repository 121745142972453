<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form
      class="loan-sales-form"
      @submit.prevent="handleSubmit"
    >
      <sale-type-select
        :value="form.saleType"
        :disabled="disabled"
        @input="handleInput('saleType', $event)"
      />

      <legal-owner-select
        :value="form.legalOwnerId"
        :sale-type="form.saleType"
        :disabled="disabled"
        @input="handleInput('legalOwnerId', $event)"
      />

      <funding-entity-select
        :value="form.fundingEntityId"
        :sale-type="form.saleType"
        :legal-owner-id="form.legalOwnerId"
        :disabled="disabled"
        @input="handleInput('fundingEntityId', $event)"
      />

      <sale-date-input
        :value="form.saleDate"
        :disabled="disabled"
        @input="handleInput('saleDate', $event)"
      />

      <buyer-input
        v-if="form.saleType === 'servicingReleased'"
        :legal-owner-id="form.legalOwnerId"
        :value="form.buyer"
        :disabled="disabled"
        @input="handleInput('buyer', $event)"
      />

      <interest-calculation-method-select
        v-if="form.saleType === 'servicingReleased'"
        :legal-owner-id="form.legalOwnerId"
        :value="form.interestCalculationMethod"
        :disabled="disabled"
        @input="handleInput('interestCalculationMethod', $event)"
      />

      <transfer-reserves-select
        v-if="form.saleType === 'servicingReleased'"
        :legal-owner-id="form.legalOwnerId"
        :value="form.transferReserves"
        :disabled="disabled"
        @input="handleInput('transferReserves', $event)"
      />

      <loan-csv-input
        v-if="form.saleType === 'servicingReleased'"
        :value="form.loanSales"
        :disabled="disabled"
        @input="handleInput('loanSales', $event)"
      />

      <loan-ids-input
        v-else
        :value="form.loanSales"
        :disabled="disabled"
        @input="handleInput('loanSales', $event)"
      />

      <series-ids-input
        v-if="form.saleType === 'nonDutchReimbursement'"
        :value="form.seriesSales"
        :disabled="disabled"
        @input="handleInput('seriesSales', $event)"
      />

      <button
        :disabled="disabled || invalid"
        type="submit"
        class="btn btn-primary float-right"
      >
        Add Loans
      </button>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";

import SaleTypeSelect from "./sale_type_select";
import SaleDateInput from "./sale_date_input";
import LegalOwnerSelect from "./legal_owner_select";
import FundingEntitySelect from "./funding_entity_select";
import LoanIdsInput from "./loan_ids_input";
import SeriesIdsInput from "./series_ids_input";
import LoanCsvInput from "./loan_csv_input";
import BuyerInput from "./buyer_input";
import InterestCalculationMethodSelect from "./interest_calculation_method_select";
import TransferReservesSelect from "./transfer_reserves_select";

export default {
  name: "LoanSaleBatchForm",
  components: {
    ValidationObserver,
    SaleTypeSelect,
    LegalOwnerSelect,
    FundingEntitySelect,
    SaleDateInput,
    LoanIdsInput,
    SeriesIdsInput,
    LoanCsvInput,
    BuyerInput,
    InterestCalculationMethodSelect,
    TransferReservesSelect,
  },
  props: {
    disabled: Boolean,
    form: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleInput(field, value) {
      this.$emit("update", field, value);
    },
    async handleSubmit() {
      if (await this.$refs.observer.validate()) {
        this.$emit("validate");
        this.$refs.observer.reset();
      }
    },
  },
};
</script>
