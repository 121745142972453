const DEFAULTS = {
  html: true,
  placement: "bottom",
  trigger: "hover focus",
  animation: false,
};

$(document).ready(() => {
  $("body").on("mousedown", ".popover", preventDefault);
});

export const Popover = {
  inserted(el, binding) {
    Vue.nextTick(function() {
      let options = binding.value ? binding.value : {};

      $(el)
        .on("click", preventDefault)
        .popover(
          Object.assign(DEFAULTS, options, {
            content: function() {
              return (options.content ? $(options.content) : $(el).next()).html();
            },
          })
        );

      if (options.onShow) {
        $(el).on("show.bs.popover", options.onShow);
      }
    });
  },
  componentUpdated(el, binding) {
    let modifiers = binding.modifiers ? binding.modifiers : {};
    let options = binding.value ? binding.value : {};

    if (modifiers.dynamic) {
      Vue.nextTick(function() {
        const $el = $(el);
        const popover = $el.data("bs.popover");
        const tip = popover.getTipElement();
        if (tip.classList.contains("show")) {
          $el.attr("data-content", function() {
            return (options.content ? $(options.content) : $el.next()).html();
          });
          popover.setContent();
          tip.classList.add("fade");
          tip.classList.add("show");
          $el.popover("update");
        }
      });
    }
  },
  unbind(el) {
    $(el)
      .off("click", preventDefault)
      .popover("dispose");
  },
};

Vue.directive("popover", Popover);

function preventDefault(e) {
  e.preventDefault();
}
