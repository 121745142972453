<template>
  <tr class="account-group-total-row">
    <td>Total</td>
    <td></td>
    <td class="account-group-amount">{{ balanceTotal | accounting }}</td>
    <td class="account-group-amount">{{ capacityTotal | accounting }}</td>
    <td class="account-group-amount">{{ fundedTotal | accounting }}</td>
    <td class="account-group-amount">{{ dryPowderTotal | accounting }}</td>
    <td class="account-group-amount">{{ inTransitTotal | accounting }}</td>
    <td class="account-group-amount">{{ earmarkedTotal | accounting }}</td>
    <td class="account-group-amount">{{ availableTotal | accounting }}</td>
    <td class="account-group-amount">{{ dailyActivityTotal | accounting }}</td>
    <td class="account-group-amount">{{ throughputTotal | accounting }}</td>
  </tr>
</template>
<script>
import sum from "lodash/sum";
import map from "lodash/map";

export default {
  props: {
    accountGroup: Array,
  },
  computed: {
    balanceTotal() {
      return sum(map(this.accountGroup, g => g.balance));
    },

    capacityTotal() {
      return sum(map(this.accountGroup, g => g.capacity));
    },

    fundedTotal() {
      return sum(map(this.accountGroup, g => g.funded));
    },

    inTransitTotal() {
      return sum(map(this.accountGroup, g => g.inTransit));
    },

    dryPowderTotal() {
      return sum(map(this.accountGroup, g => g.dryPowder));
    },

    earmarkedTotal() {
      return sum(map(this.accountGroup, g => g.earmarked));
    },

    availableTotal() {
      return sum(map(this.accountGroup, g => g.available));
    },

    dailyActivityTotal() {
      return sum(map(this.accountGroup, g => g.dailyActivity));
    },

    throughputTotal() {
      return sum(map(this.accountGroup, g => g.throughput));
    },
  },
};
</script>
