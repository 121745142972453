<template>
  <div>
    <categorized-bar-chart :chart="chart" :sources="sources" class="housing-stock-age-chart"></categorized-bar-chart>
    <div class="row listing-stats-explanation-section">
      <div class="col-md-10">
        <div>% Built ≥ 2000</div>
      </div>
      <div class="col-md-2 value-section">
        <span v-show="sources.isLoading">
          &nbsp;
        </span>
        <span v-show="sources.isSuccessful">
          {{ builtAfter2000Pct | percent(0, true) | notAvailable }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "d3";

import CategorizedBarChart from "components/market_data/categorized_bar_chart";

export default {
  name: "housing-stock-age-chart",
  components: {
    CategorizedBarChart,
  },
  props: {
    sources: Object,
  },
  data() {
    return {
      chart: {
        title: this.sources.name,

        axisXTitle: "Year built",

        axisYTitle: "Percent of households",
        axisYTickFormat: format(",.0%"),

        tooltipFormatValue: format(",.2%"),

        sizeHeight: undefined,

        labels: {
          builtBefore1940Pct: "< 1940",
          built19401949Pct: "1940s",
          built19501959Pct: "1950s",
          built19601969Pct: "1960s",
          built19701979Pct: "1970s",
          built19801989Pct: "1980s",
          built19901999Pct: "1990s",
          built20002009Pct: "2000s",
          builtAfter2009Pct: "≥ 2010",
        },

        columns: {
          categories: {
            color: "#66cc33",
            label: "Percent of households",
          },
        },
      },
    };
  },
  computed: {
    builtAfter2000Pct() {
      if (Number.isFinite(this.sources.builtAfter2000Pct)) {
        return this.sources.builtAfter2000Pct * 100;
      }
    },
  },
  watch: {
    "sources.hasData": function(newValue) {
      this.chart.sizeHeight = newValue ? 276 : undefined;
    },
  },
};
</script>
