<template>
  <comment
    :created-by-name="record.createdByName"
    :created-at="record.commentDate"
    :comment="record.comment"
    :tags="record.tags"
    :badge="record.label"
    :badge-color="labelColor"
  />
</template>
<script>
import Comment from "../comment";
export default {
  components: {
    Comment,
  },
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    labelColor() {
      if (this.record.label === "communication log") {
        return "grey";
      }
      return "blue";
    },
  },
};
</script>
