<template>
  <div class="loan-doc-checklist">
    <div v-if="uiIsBlocked" class="ps-loader-hover" />
    <h3>
      Loan checklist
    </h3>
    <ul>
      <li
        v-for="type in orderedDocumentTypes"
        :key="type.id"
        :class="documentTypeStatus(type)"
        @click="selectType(type)"
      >
        <span class="count">
          {{ documentCount[type.documentType] || 0 }}
        </span>
        <span class="document-name">
          {{ type.text }}
        </span>
        <span v-if="type.clarification" class="clarification">
          {{ type.clarification }}
        </span>
        <template v-if="completedPercentage[type.name] >= 0">
          <span class="note-percentage">({{ completedPercentage[type.documentType] | percent(0) }})</span>
        </template>
        <template v-if="notedFields[type.documentType] > 0">
          <span class="note-control noted noted-document"></span>
        </template>
      </li>
    </ul>
    <template v-if="uncategorizedCount > 0">
      <a class="doc-type-unknown" :href="documentsPath()">
        {{ uncategorizedCount | pluralize("Document") }} not categorized
      </a>
    </template>
  </div>
</template>

<script>
const DocumentTypes = PS.Collections.DocumentTypes;
const Event = PS.Models.Event;
const Commands = PS.Models.Diligence.Commands;

export default Vue.component("diligence-document-checklist", {
  props: {
    types: Array,
    documents: Array,
    documentsInfo: Array,
    loan: Object,
  },
  data: function() {
    return {
      currentType: undefined,
      documentTypes: new DocumentTypes(this.types.slice()),
      bus: PS.Services.MessageBusService.diligenceBus,
      rulebook: undefined,
      uiIsBlocked: false,
    };
  },
  created: function() {
    this.rulebook = new PS.Models.Diligence.ChecklistRule(this);
    this.bus.attachRulebook(this.rulebook);
  },
  beforeDestroy: function() {
    this.bus.detachRulebook(this.rulebook);
  },
  computed: {
    orderedDocumentTypes: function() {
      return this.documentTypes
        .diligenceToolOnly()
        .whenLoanIsEntity(this.loan.isEntity)
        .whenPropertyTypeForCommercialLoans(this.loan.propertyType)
        .whenLoanHasNoAssignments(this.loan.lenderNoteStatus)
        .orderByDDToolPriority().types;
    },
    documentCount: function() {
      let documentCount = {};

      this.documents.forEach(document => {
        documentCount[document.documentType] = documentCount[document.documentType] + 1 || 1;
      });

      return documentCount;
    },
    documentInfoByType: function() {
      let info = {};
      this.documentsInfo.forEach(docInfo => {
        info[docInfo.documentType] = docInfo;
      });

      return info;
    },
    uncategorizedCount: function() {
      return this.documentCount[undefined] || 0;
    },
    notedFields: function() {
      let notedFields = {};

      this.types.forEach(type => {
        let documentInfo = this.documentInfoByType[type.documentType] || { notedFields: 0 };
        notedFields[type.documentType] = documentInfo.notedFields;
      });

      return notedFields;
    },
    completedPercentage: function() {
      let completedPercentage = {};

      this.types.forEach(type => {
        let documentInfo = this.documentInfoByType[type.documentType];

        if (documentInfo && documentInfo.totalFields) {
          completedPercentage[type.documentType] =
            (documentInfo.validatedFields / documentInfo.totalFields) * 100;
        } else {
          completedPercentage[type.documentType] = undefined;
        }
      });

      return completedPercentage;
    },
  },
  methods: {
    documentsPath: function() {
      let link = `/manage/loans/${this.loan.toParam}/documents`;

      if (window.location.pathname === link) {
        return "javascript:void(0)";
      } else {
        return link;
      }
    },
    selectType: function(type) {
      new Commands.Document.RequestSelectDocument(new Event(), { documentType: type }).execute();
    },
    documentTypeStatus: function(type) {
      let classes = [];

      if (this.currentType && this.currentType.documentType === type.documentType) {
        classes.push("selected");
      }

      if (this.documentCount[type.documentType] === undefined) {
        classes.push("file-missing");
      }

      return classes;
    },
  },
});
</script>
