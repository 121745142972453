<template>
  <tr>
    <td>
      <div class="bank-accounts-table_item-title">{{ bankAccountName }}</div>
      <div
        v-for="fundingEntity in fundingEntities"
        :key="fundingEntity.name"
        class="bank-accounts-table_entity-title"
      >
        {{ fundingEntity.name }}
      </div>
    </td>
    <template v-if="bankAccount.currentAvailable">
      <td>
        <div class="bank-accounts-table_item-title">{{ requiredAmount | money(2) }}</div>
        <div
          v-for="fundingEntity in fundingEntities"
          :key="fundingEntity.name"
          class="bank-accounts-table_entity-item"
        >
          {{ fundingEntity.requiredAmount | money(2) }}
        </div>
      </td>
      <td class="bank-accounts-table_item-title">{{ bankAccount.currentAvailable | money(2) }}</td>
      <td class="bank-accounts-table_item-title">{{ remainingAmount | money(2) }}</td>
    </template>
    <template v-else>
      <td>
        <div class="bank-accounts-table_item-title">N/A</div>
        <div
          v-for="fundingEntity in fundingEntities"
          :key="fundingEntity.name"
          class="bank-accounts-table_entity-item"
        >
          N/A
        </div>
      </td>
      <td class="bank-accounts-table_item-title">N/A</td>
      <td class="bank-accounts-table_item-title">N/A</td>
    </template>
  </tr>
</template>
<script>
import sum from "lodash/sum";

export default {
  props: {
    bankAccount: Object,
    fundingEntitiesByName: Object,
  },
  computed: {
    bankAccountName() {
      if (!this.bankAccount.name || !this.bankAccount.accountNumber) {
        return "N/A";
      }
      return this.bankAccount.name;
    },
    requiredAmount() {
      return sum(
        this.bankAccount.fundingEntitiesNames.map(
          name => this.fundingEntitiesByName[name].requiredAmount
        )
      );
    },
    remainingAmount() {
      return this.bankAccount.currentAvailable - this.requiredAmount;
    },
    fundingEntities() {
      return this.bankAccount.fundingEntitiesNames.map(name => this.fundingEntitiesByName[name]);
    },
  },
};
</script>
