<template>
  <view-panel class="loan-details-view-panel track-record-deals-panel">
    <template slot="header">
      <div class="track-record-deals-panel is-flex">
        Track Record
        <button class="btn btn-action-modal" @click="addRecord">
          Add New Record
        </button>
      </div>
    </template>
    <summary-view :records="records" />
    <template v-if="records.length">
      <template v-for="filteredRecords in recordsByLoanStrategy">
        <div v-for="(record, index) in filteredRecords" :key="record.id">
          <base-hr class="mt-4" />
          <track-record-view
            :record="record"
            :index="index"
            @edit="editRecord"
            @delete="updateRecords"
          />
        </div>
      </template>
    </template>
    <track-record-form
      :show="showModal"
      :track-record="recordToEdit"
      @close="closeModal"
      @save="updateRecords"
    />
  </view-panel>
</template>

<script>
import ViewPanel from "components/view_panel";
import TrackRecordDeal from "models/track_record_deal";
import TrackRecordView from "./track_record_deal/view";
import TrackRecordForm from "./track_record_deal/form";
import SummaryView from "./summary";
import Ajax from "services/ajax_service";

export default {
  components: {
    ViewPanel,
    TrackRecordView,
    TrackRecordForm,
    SummaryView,
  },
  props: {
    responsiblePartyId: {
      type: [String, Number],
      required: true,
    },
  },
  data: function() {
    return {
      records: [],
      recordToEdit: new TrackRecordDeal(),
      showModal: false,
      routes: PSData.routes || {},
      options: {},
    };
  },
  computed: {
    recordsByLoanStrategy() {
      const recordsByLoanStrategy = {
        fix_n_flip: [],
        fix_to_rent: [],
        rental: [],
        flip: [],
        default: [],
      };

      this.records.forEach(record => {
        const loanStrategy = record.loanStrategy || "default";
        recordsByLoanStrategy[loanStrategy].push(record);
      });

      return recordsByLoanStrategy;
    },
  },
  mounted() {
    Ajax.getJSON(this.routes.trackRecordDeals)
      .then(data => {
        this.options = data.options;
        this.updateRecords(data.items);
      })
      .catch(() => {
        this.records = [];
      });
  },
  methods: {
    addRecord() {
      this.recordToEdit = new TrackRecordDeal({}, this.options);
      this.showModal = true;
    },
    editRecord(record) {
      this.recordToEdit = record;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    updateRecords(updatedRecords = []) {
      this.records = updatedRecords.map(record => new TrackRecordDeal(record, this.options));
    },
  },
};
</script>
<style scoped>
.track-record-deals-panel >>> .view-panel-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.track-record-deals-panel {
  align-items: center;
  background: var(--white);
  justify-content: space-between;
}
</style>
