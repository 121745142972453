<template>
  <div class="details-row row">
    <div class="col-12">
      <h4>Principal</h4>
    </div>
    <div class="col-4">
      <editable-field
        label="Investor Loan Amount"
        :value="statement.investorLoanAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div class="col-8" />
    <div class="col-4">
      <editable-field
        label="Current Principal Balance"
        :value="statement.unpaidPrincipalAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div class="col-4">
      <div class="editable-field-container">
        <editable-currency-field
          label="Corrected Principal Balance"
          :value="statement.correctedUnpaidPrincipalAmount"
          :callback="updateEditableField('correctedUnpaidPrincipalAmount', statement)"
        />
      </div>
    </div>
    <div class="col-4">
      <div class="editable-field-container">
        <div class="form-group">
          <label class="editable-field-label control-label">Reason For Correction</label>
          <select
            v-model="statement.correctedPrincipalReasonType"
            class="form-control"
          >
            <option />
            <option v-for="(label, name) in reasonsForCorrection" :key="name" :value="name">
              {{ label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-4" v-if="!statement.servicingReleased">
      <editable-field
        label="B-Piece"
        :value="statement.bPieceAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div class="col-4">
      <editable-field
        label="Investor Current Principal Balance"
        :value="statement.investorUnpaidPrincipalAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div class="col-4">
      <editable-field
        label="Unfunded Commitment"
        :value="statement.unfundedCommitmentAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div class="col-4" v-if="statement.servicingReleased" />
    <div class="col-4">
      <div class="editable-field-container">
        <waived-input
          label="Price Discount/Premium"
          :value="statement.priceDiscountPremiumAmount"
          :amount="statement.investorUnpaidPrincipalAmount"
          :data-change="updateEditableField('priceDiscountPremiumAmount', statement)"
        />
      </div>
    </div>
    <div class="col-4">
      <div class="editable-field-container">
        <waived-input
          label="Waived Principal"
          :value="statement.waivedUnpaidPrincipalAmount"
          :amount="statement.investorUnpaidPrincipalAmount"
          :data-change="updateEditableField('waivedUnpaidPrincipalAmount', statement)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WaivedInput from "components/manage/payoff_statement/waived_input";
import EditableField from "components/shared/editable_field.vue";
import EditableCurrencyField from "components/shared/editable_currency_field.vue";
import EditPartialMixin from "components/manage/payoff_statement/edit/edit_partial_mixin";

export default {
  name: "repurchase-statement-edit-principal",
  components: {
    WaivedInput,
    EditableField,
    EditableCurrencyField,
  },
  mixins: [EditPartialMixin],
  props: ["isEditable", "statement", "reasonsForCorrection"],
};
</script>
