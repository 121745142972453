<template>
  <fieldset>
    <h3>
      {{ fieldset.label }}
      <span
        v-if="fieldset.collapsible"
        class="fieldset-caret fas"
        :class="{ 'fa-caret-down': showFields, 'fa-caret-right': !showFields, 'total-validated': totalValidated }"
        @click="toggleShowFields"
      />
      <a
        v-if="fieldset.validatable && !fieldset.disabled"
        class="fieldset-actions"
        @click="validateAll()"
      >
        validate all
      </a>
      <a
        v-if="fieldset.clearable && !fieldset.disabled"
        class="fieldset-actions"
        @click="clearFieldset()"
      >
        clear
      </a>
      <a
        v-if="fieldset.canBeRemoved && !fieldset.disabled"
        class="fieldset-actions"
        @click="deleteFieldset()"
      >
        remove
      </a>
      <span
        v-if="fieldset.uploadedAt"
        class="fieldset-extracted-at"
      >
        Uploaded at {{ fieldset.uploadedAt }}
      </span>
    </h3>
    <fieldset-preview v-show="!showFields" :fields="fieldset.fields"/>
    <div v-show="showFields">
      <component
        :is="field.toComponent()"
        v-for="field in fieldset.fields"
        :key="field.id"
        :document="document"
        :field="field"
        :fieldset="fieldset"
        :parent-disabled="parentDisabled"
      ></component>
    </div>
    <div v-if="fieldset.canBeCopied && !fieldset.disabled" class="col-5 offset-5">
      <a class="add-borrower-link" @click="createFieldset()">
        {{ fieldset.addButtonLabel }}
      </a>
    </div>
  </fieldset>
</template>

<script>
const Event = PS.Models.Event;
const Commands = PS.Models.Diligence.Commands;
import FieldsetPreview from "./fieldset_preview";

export default Vue.component("diligence-document-fieldset", {
  components: { FieldsetPreview },
  props: {
    document: Object,
    fieldset: Object,
    parentDisabled: Boolean,
  },
  data() {
    return {
      showFields: !this.fieldset.collapsible,
    };
  },
  computed: {
    totalValidated() {
      return this.fieldset.fields.every(field => field.validated);
    },
  },
  methods: {
    toggleShowFields() {
      this.showFields = !this.showFields;
    },
    createFieldset: function() {
      new Commands.Checklist.RequestBlockUI().execute();
      new Commands.Fieldset.RequestFieldsetCreation(new Event(), this).execute();
    },
    deleteFieldset: function() {
      new Commands.Checklist.RequestBlockUI().execute();
      new Commands.Fieldset.RequestFieldsetDeletion(new Event(), this).execute();
    },
    clearFieldset: function() {
      new Commands.Checklist.RequestBlockUI().execute();
      new Commands.Fieldset.RequestFieldsetClear(new Event(), this).execute();
    },
    validateAll: function() {
      new Commands.Checklist.RequestBlockUI().execute();
      new Commands.Fieldset.RequestFieldsetValidate(new Event(), this).execute();
    },
  },
});
</script>
