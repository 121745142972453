<template>
  <div class="is-flex">
    <div class="is-flex">
      <revision-number-tag>{{ revision.revisionNumberLabel }}</revision-number-tag>
      <status-item label="Active" class="ml-3">
        {{ revision.publishedAtFormatted }}
      </status-item>
    </div>
    <div class="ml-5 revision-loans-count">
      <status-item label="Loans">
        {{ revision.loansCount }}
      </status-item>
    </div>
  </div>
</template>

<script>
import StatusItem from "./status_item";
import RevisionNumberTag from "./revision_number_tag";

export default {
  components: {
    StatusItem,
    RevisionNumberTag,
  },
  props: {
    revision: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
