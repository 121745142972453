<template>
  <div class="form-group">
    <label>
      {{ label }}
      <span class="font-weight-normal">
        (
        <a href="#" @click.prevent="innerValue=[]">
          Deselect All
        </a>
        )
      </span>
    </label>

    <div
      v-for="{id, text} in $options.legalOwners"
      :key="id"
      class="form-check"
    >
      <input
        v-model="innerValue"
        class="form-check-input"
        type="checkbox"
        :name="name"
        :value="id"
      >
      <label class="form-check-label font-weight-normal">
        {{ text }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return { innerValue: this.value };
  },
  watch: {
    innerValue(v) {
      this.$emit("filter", this.name, v);
    },
  },
  legalOwners: PSData.legalOwners,
};
</script>
