<template>
  <with-errors :class="wrapperClass" :errors="errors" :on="attributeName">
    <label :for="id" :class="labelClass">{{ label }}</label>
    <input
      :id="id"
      v-model="value"
      :class="inputClass"
      type="text"
      @input="updateField"
    >
  </with-errors>
</template>

<script>
import WithErrors from "components/shared/with_errors";

export default {
  name: "dynamic-text-input",
  components: { WithErrors },
  props: {
    attributeName: {
      type: String,
      required: true,
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
      default: "",
    },
    schema: {
      type: Object,
      required: true,
    },
    initialValue: {
      type: String,
      required: false,
      default: "",
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: this.initialValue,
    };
  },
  computed: {
    name() {
      return this.schema.name;
    },
    label() {
      return this.schema.label;
    },
  },
  methods: {
    updateField() {
      this.$emit("on-field-update", this.value);
    },
  },
};
</script>
