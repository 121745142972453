import submitWithRecaptcha from "components/lenders/submit_with_recaptcha.vue";

$(() => {
  const FormValidator = PS.Utils.FormValidator;

  new Vue({
    components: {
      submitWithRecaptcha,
    },
    el: document.querySelector(".v-signup-controller"),
    data() {
      return {
        validator: undefined,
      };
    },
    mounted() {
      this.validator = new FormValidator($(".validate"), this.onFormValid);
    },
    methods: {
      onFormValid() {
        if (grecaptcha) {
          grecaptcha.execute();
        }
      },
    },
  });
});
