<template>
  <div>
    <h3><slot name="title" /></h3>
    <div class="action-buttons">
      <div class="select-all-rows">
        <check-box :value="allSelected" :partial="isPartiallySelected" @change="toggleAll" />
      </div>
      <div
        class="btn btn-outline-grey"
        :disabled="noneSelected || !canManage"
        @click="openIgnoreItemsModal"
      >
        Ignore
      </div>
    </div>
    <table class="table import-items-group">
      <thead>
        <tr>
          <th class="checkbox-header"></th>
          <th>Loan ID</th>
          <th>Address</th>
          <th>Doc Name</th>
          <th class="exception-header">Exception</th>
          <th>To</th>
          <th>From</th>
          <th>
            Standard Doc
            <standard-doc-tooltip />
          </th>
          <th>Description</th>
          <th>Old Status</th>
          <th>New Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in unresolvedItems" :key="item.id">
          <td class="checkbox">
            <check-box v-model="item.checked" />
          </td>
          <td><loan-link v-if="item.isFirstItem" :item="item"/></td>
          <td>{{ item.address }}</td>
          <td>
            <a v-if="canManage" href="#" class="assign-document" @click.prevent="selectItem(item)">
              <img :src="require('images/icons/icon_edit_import_item.svg')" alt="edit">
              {{ item.docName }}
            </a>
            <template v-else>
              {{ item.docName }}
            </template>
          </td>
          <td>{{ item.exceptionDescription }}</td>
          <td>{{ item.toAssignment }}</td>
          <td>{{ item.fromAssignment }}</td>
          <td>{{ item.standardDocText }}</td>
          <td>{{ item.description }}</td>
          <td>Unknown</td>
          <td>{{ item.newDocStatusText }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import LoanLink from "./loan_link.vue";
import CheckBox from "components/shared/check_box";
import StandardDocTooltip from "./standard_doc_tooltip";

function exceptionDescription(item) {
  return item.statusCode.code
    ? `${item.statusCode.description} Code ${item.statusCode.code}`
    : "Exception not found";
}

export default {
  name: "physical-doc-tracking-import-docs-group",
  components: {
    LoanLink,
    CheckBox,
    StandardDocTooltip,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    canManage: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      unresolvedItems: this.setupItems(),
    };
  },
  computed: {
    count() {
      return this.unresolvedItems.length;
    },
    isPartiallySelected() {
      return !this.allSelected && this.checkedItemsLength > 0;
    },
    checkedItems() {
      return this.unresolvedItems.filter(item => item.checked === true);
    },
    checkedItemsLength() {
      return this.checkedItems.length;
    },
    allSelected() {
      return this.count !== 0 && this.checkedItemsLength === this.count;
    },
    noneSelected() {
      return this.checkedItemsLength === 0;
    },
  },
  watch: {
    items() {
      this.unresolvedItems = this.setupItems();
    },
  },
  methods: {
    selectItem(item) {
      return this.$emit("on-item-selected", item);
    },
    toggleAll(value) {
      this.unresolvedItems.forEach(item => this.$set(item, "checked", value));
    },
    openIgnoreItemsModal() {
      this.$emit("ignore-items", this.checkedItems);
    },
    setupItems() {
      const ids = {};
      return this.items.map(item => {
        const newItem = Object.assign({}, item);
        if (!ids[newItem.loanPsId]) {
          ids[newItem.loanPsId] = true;
          newItem.isFirstItem = true;
        }
        newItem.exceptionDescription = exceptionDescription(newItem);
        newItem.checked = false;

        return newItem;
      });
    },
  },
};
</script>
