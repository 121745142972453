<template>
  <modal-view :show="show" :on-close="onClose" class="base-modal institution-sales-tracking-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          Set Date
        </h4>
      </div>
      <div class="modal-body">
        <div class="institution-sales-tracking-modal-usernote">{{modalTitle}}</div>
        <inline-date-picker :label="fieldLabel" :field="'set-date-input'" :value="selectedDate" @input="changeDate"></inline-date-picker>
        <div v-if="isOffered" class="institution-sales-tracking-modal-usernote">Note: This will end new allocations to this batch</div>
      </div>
      <div class="modal-footer">
        <div>
        </div>
        <div>
          <button class="btn btn-cancel-cta btn-link" @click="onClose">
            Cancel
          </button>
          <button class="btn btn-action-cta" @click="onSave">
            Save
          </button>
        </div>
      </div>
    </div>
  </modal-view>
</template>
<script>
import moment from "moment";
import ModalView from "components/modal_view";
import InlineDatePicker from "components/loans/inline_date_picker";

const LABELS = {
  targetOfferDate: "Target Offer",
  offerDate: "Offered",
  wireDate: "Wired",
  salesDocsCompletedDate: "Sales Docs Done",
};

export default {
  name: "set-date-modal",
  components: {
    ModalView,
    InlineDatePicker,
  },
  props: {
    show: Boolean,
    modalTitle: String,
    fieldName: String,
  },
  data() {
    return {
      selectedDate: moment().format("MM/DD/YYYY"),
    };
  },
  methods: {
    changeDate(date) {
      this.selectedDate = date;
    },

    onClose() {
      this.$emit("close");
    },

    onSave() {
      this.$emit("save", { [this.fieldName]: this.selectedDate });
    },
  },
  computed: {
    fieldLabel() {
      return LABELS[this.fieldName];
    },
    isOffered() {
      return this.fieldName === "offerDate";
    },
  },
};
</script>
