<template>
  <div class="message-item v-message-item" v-bind:class="event.visibility">
    <div class="message-owner" v-bind:class="{'analyst-owner': event.isAnalyst}">
      {{event.nameAbbreviation}}
    </div>
    <div class="message-item-header">
      <p>
        {{event.name}}
        <span v-bind:title="event.timePublished">
          {{event.timeAgoPublished}} ago
        </span>
      </p>
    </div>
    <div class="message-item-body" v-html="event.message" />
  </div>
</template>
<script>
export default {
  props: {
    event: Object,
  },
};
</script>
