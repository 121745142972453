import enumerize from "../utils/enumerize";

const AccountType = enumerize({
  lender: {
    value: "lender",
    title: "Lender",
  },
  broker: {
    value: "broker",
    title: "Broker",
  },
});

export default AccountType;
