import enumerize from "../utils/enumerize";

const TrackRecord = enumerize({
  0: {
    value: 0,
    title: "0",
  },
  1: {
    value: 1,
    title: "1",
  },
  2: {
    value: 2,
    title: "2",
  },
  3: {
    value: 3,
    title: "3",
  },
  4: {
    value: 4,
    title: "4",
  },
  5: {
    value: 5,
    title: "5",
  },
  6: {
    value: 6,
    title: "6+",
  },
});

export default TrackRecord;
