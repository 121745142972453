import { PROGRAM_CLASSIFICATION } from "./program";

export const InputType = {
  Text: "Text",
  Select: "Select",
  YesNo: "YesNo",
  Number: "Number",
  PositiveNumber: "PositiveNumber",
  SelectNumber: "SelectNumber",
};

export const SectionType = {
  ProgramType: "ProgramType",
  LoanType: "LoanType",
  Property: "Property",
  PropertyDetails: "PropertyDetails",
  Loan: "Loan",
  Borrower: "Borrower",
  Pricing: "Pricing",
  Summary: "Summary",
  Capital: "Capital",
};

const RESIDENTIAL_BRIDGE_FIELDS = {
  programType: { inputType: InputType.Text, section: SectionType.ProgramType },

  lenderNoteStatus: { inputType: InputType.Select, section: SectionType.LoanType },
  loanStrategy: { inputType: InputType.Select, section: SectionType.LoanType },
  loanStrategyId: { inputType: InputType.Number, section: SectionType.LoanType },
  loanPurpose: { inputType: InputType.Select, section: SectionType.LoanType },
  lenderTargetPurchaseDate: {
    inputType: InputType.Text,
    section: SectionType.LoanType,
    required: true,
  },

  propertyType: { inputType: InputType.Select, section: SectionType.Property },
  properties: { inputType: InputType.Text, section: SectionType.Property },
  address: { inputType: InputType.Text, section: SectionType.Property },
  city: { inputType: InputType.Text, section: SectionType.Property },
  state: { inputType: InputType.Text, section: SectionType.Property },
  zip: { inputType: InputType.Text, section: SectionType.Property },
  propertyUnitCount: { inputType: InputType.Number, section: SectionType.Property },
  propertyLotAcres: { inputType: InputType.Number, section: SectionType.Property },

  purchasePrice: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  draftAsIsValuation: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  draftArv: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  yearlyTaxesOwed: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  yearlyPremium: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  propertyLeased: { inputType: InputType.YesNo, section: SectionType.PropertyDetails },
  totalRentPerMonth: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  propertyMarketRentPerMonth: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  monthlyHoaDues: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  paidHardConstructionCosts: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  totalCostBasis: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  existingLoanAmount: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  projectedRehabBudget: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  draftValuationMethod: { inputType: InputType.Select, section: SectionType.PropertyDetails },
  draftValuationDate: { inputType: InputType.Text, section: SectionType.PropertyDetails },
  propertyGroundLease: { inputType: InputType.YesNo, section: SectionType.PropertyDetails },
  propertyEnvironmentalIssuesIdentified: {
    inputType: InputType.YesNo,
    section: SectionType.PropertyDetails,
  },
  propertyOccupancyPercentage: {
    inputType: InputType.Number,
    section: SectionType.PropertyDetails,
  },
  propertyPurchaseDate: { inputType: InputType.Text, section: SectionType.PropertyDetails },

  originationDate: { inputType: InputType.Text, section: SectionType.Loan },
  extensionTerm: { inputType: InputType.Number, section: SectionType.Loan },
  totalPrincipalBalanceAtOrigination: { inputType: InputType.Number, section: SectionType.Loan },
  initialPurchaseDate: { inputType: InputType.Text, section: SectionType.Loan, required: false },
  interestRate: { inputType: InputType.Number, section: SectionType.Loan },
  originalMaturityDate: { inputType: InputType.Text, section: SectionType.Loan },
  extensionExercised: { inputType: InputType.YesNo, section: SectionType.Loan },
  totalPrincipalPayments: { inputType: InputType.Number, section: SectionType.Loan },
  paymentStructure: { inputType: InputType.Select, section: SectionType.Loan },
  lenderEntityLegalEntityId: {
    inputType: InputType.Number,
    section: SectionType.Loan,
  },
  cema: {
    inputType: InputType.YesNo,
    section: SectionType.Loan,
    required: false,
  },
  brokeringEntityId: {
    inputType: InputType.Number,
    section: SectionType.Loan,
  },
  psaExpirationDate: { inputType: InputType.Text, section: SectionType.Loan, required: false },
  loanTerm: { inputType: InputType.Number, section: SectionType.Loan },

  constructionReserve: { inputType: InputType.Number, section: SectionType.Capital },
  constructionReserveDisbursedAtValuation: {
    inputType: InputType.Number,
    section: SectionType.Capital,
  },
  interestReserve: { inputType: InputType.Number, section: SectionType.Capital },
  otherReserves: { inputType: InputType.Number, section: SectionType.Capital },
  bPieceAmount: { inputType: InputType.Number, section: SectionType.Capital },
  juniorLienTotal: { inputType: InputType.Number, section: SectionType.Capital },
  juniorLienHolders: { inputType: InputType.Select, section: SectionType.Capital },
  interestAccrualMethod: { inputType: InputType.Select, section: SectionType.Capital },

  borrowerType: { inputType: InputType.Select, section: SectionType.Borrower },
  borrowerFico: { inputType: InputType.Number, section: SectionType.Borrower },
  professionallyManaged: {
    inputType: InputType.YesNo,
    section: SectionType.Borrower,
    required: false,
  },
  borrowerGuarantorTrackRecord: {
    inputType: InputType.SelectNumber,
    section: SectionType.Borrower,
  },
  borrowerEmail: { inputType: InputType.Text, section: SectionType.Borrower, required: false },
  borrowerLiquidity: { inputType: InputType.Text, section: SectionType.Borrower },
  borrowerResidency: { inputType: InputType.Select, section: SectionType.Borrower },
  personalGuarantyYN: { inputType: InputType.YesNo, section: SectionType.Borrower },

  totalOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  totalOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },
  psOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  psOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },
  thirdPartyOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  thirdPartyOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },

  lenderSummary: { inputType: InputType.Text, section: SectionType.Summary },
  customId: { inputType: InputType.Text, section: SectionType.Summary, required: false },
};

const EXTENDED_BRIDGE_FIELDS = {
  propertyType: { inputType: InputType.Select, section: SectionType.Property },
  properties: { inputType: InputType.Text, section: SectionType.Property },
  address: { inputType: InputType.Text, section: SectionType.Property },
  city: { inputType: InputType.Text, section: SectionType.Property },
  state: { inputType: InputType.Text, section: SectionType.Property },
  zip: { inputType: InputType.Text, section: SectionType.Property },
  loanPurpose: { inputType: InputType.Select, section: SectionType.Property },
  loanStrategy: { inputType: InputType.Select, section: SectionType.Property, disabled: true },
  loanStrategyId: { inputType: InputType.Number, section: SectionType.LoanType },
  purchasePrice: { inputType: InputType.Number, section: SectionType.Property },
  draftAsIsValuation: { inputType: InputType.Number, section: SectionType.Property },
  totalRentPerMonth: { inputType: InputType.Number, section: SectionType.Property },
  propertyMarketRentPerMonth: { inputType: InputType.Number, section: SectionType.Property },
  yearlyTaxesOwed: { inputType: InputType.Number, section: SectionType.Property },
  yearlyPremium: { inputType: InputType.Number, section: SectionType.Property },
  monthlyHoaDues: { inputType: InputType.Number, section: SectionType.Property },
  propertyUnitCount: { inputType: InputType.Number, section: SectionType.Property, disabled: true },
  propertyLeased: { inputType: InputType.YesNo, section: SectionType.Property },

  lenderNoteStatus: { inputType: InputType.Select, section: SectionType.Loan },
  originationDate: { inputType: InputType.Text, section: SectionType.Loan },
  extensionTerm: { inputType: InputType.Number, section: SectionType.Loan },
  totalPrincipalBalanceAtOrigination: { inputType: InputType.Number, section: SectionType.Loan },
  interestReserve: { inputType: InputType.Number, section: SectionType.Loan },
  juniorLienTotal: { inputType: InputType.Number, section: SectionType.Loan },
  bPieceAmount: { inputType: InputType.Number, section: SectionType.Loan },
  initialPurchaseDate: { inputType: InputType.Text, section: SectionType.Loan, required: false },
  interestRate: { inputType: InputType.Number, section: SectionType.Loan },
  originalMaturityDate: { inputType: InputType.Text, section: SectionType.Loan },
  extensionExercised: { inputType: InputType.YesNo, section: SectionType.Loan },
  totalPrincipalPayments: { inputType: InputType.Number, section: SectionType.Loan },
  otherReserves: { inputType: InputType.Number, section: SectionType.Loan },
  juniorLienHolders: { inputType: InputType.Select, section: SectionType.Loan },
  paymentStructure: { inputType: InputType.Select, section: SectionType.Loan },
  lenderEntityLegalEntityId: {
    inputType: InputType.Number,
    section: SectionType.Loan,
  },
  cema: {
    inputType: InputType.YesNo,
    section: SectionType.Loan,
    required: false,
  },
  brokeringEntityId: {
    inputType: InputType.Number,
    section: SectionType.Loan,
  },
  existingLoanAmount: { inputType: InputType.Number, section: SectionType.Loan },
  psaExpirationDate: { inputType: InputType.Text, section: SectionType.Loan, required: false },
  loanTerm: { inputType: InputType.Number, section: SectionType.Loan },

  borrowerType: { inputType: InputType.Select, section: SectionType.Borrower },
  borrowerFico: { inputType: InputType.Number, section: SectionType.Borrower },
  professionallyManaged: {
    inputType: InputType.YesNo,
    section: SectionType.Borrower,
    required: false,
  },
  borrowerGuarantorTrackRecord: {
    inputType: InputType.SelectNumber,
    section: SectionType.Borrower,
  },
  borrowerResidency: { inputType: InputType.Select, section: SectionType.Borrower },
  borrowerEmail: { inputType: InputType.Text, section: SectionType.Borrower, required: false },
  borrowerLiquidity: { inputType: InputType.Text, section: SectionType.Borrower },

  totalOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  totalOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },
  psOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  psOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },
  thirdPartyOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  thirdPartyOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },

  lenderSummary: { inputType: InputType.Text, section: SectionType.Summary },
  customId: { inputType: InputType.Text, section: SectionType.Summary, required: false },
  lenderTargetPurchaseDate: {
    inputType: InputType.Text,
    section: SectionType.Summary,
    required: true,
  },
};

const RESIDENTIAL_RENTAL_LONG_TERM_FIELDS = {
  propertyType: { inputType: InputType.Select, section: SectionType.Property },
  properties: { inputType: InputType.Text, section: SectionType.Property },
  address: { inputType: InputType.Text, section: SectionType.Property },
  city: { inputType: InputType.Text, section: SectionType.Property },
  state: { inputType: InputType.Text, section: SectionType.Property },
  zip: { inputType: InputType.Text, section: SectionType.Property },
  loanPurpose: { inputType: InputType.Select, section: SectionType.Property },
  loanStrategy: { inputType: InputType.Select, section: SectionType.Property, disabled: true },
  loanStrategyId: { inputType: InputType.Number, section: SectionType.LoanType },
  purchasePrice: { inputType: InputType.Number, section: SectionType.Property },
  draftAsIsValuation: { inputType: InputType.Number, section: SectionType.Property },
  totalRentPerMonth: { inputType: InputType.Number, section: SectionType.Property },
  propertyMarketRentPerMonth: { inputType: InputType.Number, section: SectionType.Property },
  yearlyTaxesOwed: { inputType: InputType.Number, section: SectionType.Property },
  yearlyPremium: { inputType: InputType.Number, section: SectionType.Property },
  monthlyHoaDues: { inputType: InputType.Number, section: SectionType.Property },
  propertyUnitCount: { inputType: InputType.Number, section: SectionType.Property, disabled: true },
  propertyLeased: { inputType: InputType.YesNo, section: SectionType.Property },

  lenderNoteStatus: { inputType: InputType.Select, section: SectionType.Loan },
  originationDate: { inputType: InputType.Text, section: SectionType.Loan },
  totalPrincipalBalanceAtOrigination: { inputType: InputType.Number, section: SectionType.Loan },
  interestRate: { inputType: InputType.Number, section: SectionType.Loan },
  originalMaturityDate: { inputType: InputType.Text, section: SectionType.Loan, disabled: true },
  paymentStructure: { inputType: InputType.Select, section: SectionType.Loan },
  lenderEntityLegalEntityId: {
    inputType: InputType.Number,
    section: SectionType.Loan,
  },
  cema: {
    inputType: InputType.YesNo,
    section: SectionType.Loan,
    required: false,
  },
  brokeringEntityId: {
    inputType: InputType.Number,
    section: SectionType.Loan,
  },
  loanTerm: { inputType: InputType.Number, section: SectionType.Loan },

  paidHardConstructionCosts: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  totalCostBasis: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  psaExpirationDate: { inputType: InputType.Text, section: SectionType.Loan, required: false },

  borrowerType: { inputType: InputType.Select, section: SectionType.Borrower },
  borrowerFico: { inputType: InputType.Number, section: SectionType.Borrower },
  professionallyManaged: { inputType: InputType.YesNo, section: SectionType.Borrower },
  borrowerGuarantorTrackRecord: {
    inputType: InputType.SelectNumber,
    section: SectionType.Borrower,
  },
  borrowerResidency: { inputType: InputType.Select, section: SectionType.Borrower },
  borrowerEmail: { inputType: InputType.Text, section: SectionType.Borrower, required: false },

  totalOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  totalOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },
  psOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  psOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },
  thirdPartyOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  thirdPartyOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },

  lenderSummary: { inputType: InputType.Text, section: SectionType.Summary },
  customId: { inputType: InputType.Text, section: SectionType.Summary, required: false },
  lenderTargetPurchaseDate: { inputType: InputType.Text, section: SectionType.Summary },
};

const COMMERCIAL_MULTIFAMILY_FIELDS = {
  propertyType: { inputType: InputType.Select, section: SectionType.Property },
  properties: { inputType: InputType.Text, section: SectionType.Property },
  propertyUnitCount: { inputType: InputType.Number, section: SectionType.Property },
  address: { inputType: InputType.Text, section: SectionType.Property },
  city: { inputType: InputType.Text, section: SectionType.Property },
  state: { inputType: InputType.Text, section: SectionType.Property },
  zip: { inputType: InputType.Text, section: SectionType.Property },
  loanPurpose: { inputType: InputType.Select, section: SectionType.Property },
  loanStrategy: { inputType: InputType.Select, section: SectionType.Property },
  loanStrategyId: { inputType: InputType.Number, section: SectionType.LoanType },
  paidHardConstructionCosts: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  propertyPurchaseDate: { inputType: InputType.Text, section: SectionType.Property },
  purchasePrice: { inputType: InputType.Number, section: SectionType.Property },
  constructionReserve: { inputType: InputType.Number, section: SectionType.Property },
  constructionReserveDisbursedAtValuation: {
    inputType: InputType.Number,
    section: SectionType.Property,
  },
  draftAsIsValuation: { inputType: InputType.Number, section: SectionType.Property },
  draftArv: { inputType: InputType.Number, section: SectionType.Property },
  totalCostBasis: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  projectedRehabBudget: { inputType: InputType.Number, section: SectionType.Property },
  propertyEnvironmentalIssuesIdentified: {
    inputType: InputType.YesNo,
    section: SectionType.Property,
  },
  propertyGroundLease: { inputType: InputType.YesNo, section: SectionType.Property },

  lenderNoteStatus: { inputType: InputType.Select, section: SectionType.Loan },
  originationDate: { inputType: InputType.Text, section: SectionType.Loan },
  totalPrincipalBalanceAtOrigination: { inputType: InputType.Number, section: SectionType.Loan },
  totalPrincipalPayments: { inputType: InputType.Number, section: SectionType.Loan },
  interestReserve: { inputType: InputType.Number, section: SectionType.Loan },
  bPieceAmount: { inputType: InputType.Number, section: SectionType.Loan },
  interestRate: { inputType: InputType.Number, section: SectionType.Loan },
  originalMaturityDate: { inputType: InputType.Text, section: SectionType.Loan },
  extensionTerm: { inputType: InputType.Number, section: SectionType.Loan },
  extensionExercised: { inputType: InputType.YesNo, section: SectionType.Loan },
  otherReserves: { inputType: InputType.Number, section: SectionType.Loan },
  juniorLienTotal: { inputType: InputType.Number, section: SectionType.Loan },
  juniorLienHolders: { inputType: InputType.Select, section: SectionType.Loan },
  lenderEntityLegalEntityId: {
    inputType: InputType.Number,
    section: SectionType.Loan,
  },
  cema: {
    inputType: InputType.YesNo,
    section: SectionType.Loan,
    required: false,
  },
  brokeringEntityId: {
    inputType: InputType.Number,
    section: SectionType.Loan,
  },
  psaExpirationDate: { inputType: InputType.Text, section: SectionType.Loan, required: false },
  loanTerm: { inputType: InputType.Number, section: SectionType.Loan },
  professionallyManaged: {
    inputType: InputType.YesNo,
    section: SectionType.Borrower,
    required: false,
  },

  borrowerType: { inputType: InputType.Select, section: SectionType.Borrower },
  borrowerFico: { inputType: InputType.Number, section: SectionType.Borrower },
  borrowerGuarantorTrackRecord: {
    inputType: InputType.SelectNumber,
    section: SectionType.Borrower,
  },
  borrowerEmail: { inputType: InputType.Text, section: SectionType.Borrower, required: false },

  totalOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  totalOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },
  psOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  psOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },
  thirdPartyOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  thirdPartyOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },

  lenderSummary: { inputType: InputType.Text, section: SectionType.Summary },
  customId: { inputType: InputType.Text, section: SectionType.Summary, required: false },
  lenderTargetPurchaseDate: { inputType: InputType.Text, section: SectionType.Summary },
};

const COMMERCIAL_NON_MULTIFAMILY_FIELDS = {
  propertyType: { inputType: InputType.Select, section: SectionType.Property },
  properties: { inputType: InputType.Text, section: SectionType.Property },
  address: { inputType: InputType.Text, section: SectionType.Property },
  city: { inputType: InputType.Text, section: SectionType.Property },
  state: { inputType: InputType.Text, section: SectionType.Property },
  zip: { inputType: InputType.Text, section: SectionType.Property },
  loanPurpose: { inputType: InputType.Select, section: SectionType.Property },
  loanStrategy: { inputType: InputType.Select, section: SectionType.Property, disabled: true },
  loanStrategyId: { inputType: InputType.Number, section: SectionType.LoanType },
  propertyPurchaseDate: { inputType: InputType.Text, section: SectionType.Property },
  purchasePrice: { inputType: InputType.Number, section: SectionType.Property },
  draftAsIsValuation: { inputType: InputType.Number, section: SectionType.Property },
  paidHardConstructionCosts: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  totalCostBasis: { inputType: InputType.Number, section: SectionType.PropertyDetails },

  lenderNoteStatus: { inputType: InputType.Select, section: SectionType.Loan },
  interestRate: { inputType: InputType.Number, section: SectionType.Loan },
  totalPrincipalBalanceAtOrigination: { inputType: InputType.Number, section: SectionType.Loan },
  totalPrincipalPayments: { inputType: InputType.Number, section: SectionType.Loan },
  originationDate: { inputType: InputType.Text, section: SectionType.Loan },
  originalMaturityDate: { inputType: InputType.Text, section: SectionType.Loan },
  constructionReserve: { inputType: InputType.Number, section: SectionType.Loan },
  constructionReserveDisbursedAtValuation: {
    inputType: InputType.Number,
    section: SectionType.Loan,
  },
  interestReserve: { inputType: InputType.Number, section: SectionType.Loan },
  otherReserves: { inputType: InputType.Number, section: SectionType.Loan },
  bPieceAmount: { inputType: InputType.Number, section: SectionType.Loan },
  juniorLienTotal: { inputType: InputType.Number, section: SectionType.Loan },
  juniorLienHolders: { inputType: InputType.Select, section: SectionType.Loan },
  lenderEntityLegalEntityId: {
    inputType: InputType.Number,
    section: SectionType.Loan,
  },
  cema: {
    inputType: InputType.YesNo,
    section: SectionType.Loan,
    required: false,
  },
  brokeringEntityId: {
    inputType: InputType.Number,
    section: SectionType.Loan,
  },
  psaExpirationDate: { inputType: InputType.Text, section: SectionType.Loan, required: false },
  loanTerm: { inputType: InputType.Number, section: SectionType.Loan },

  borrowerType: { inputType: InputType.Select, section: SectionType.Borrower },
  borrowerFico: { inputType: InputType.Number, section: SectionType.Borrower },
  borrowerGuarantorTrackRecord: {
    inputType: InputType.SelectNumber,
    section: SectionType.Borrower,
  },
  borrowerEmail: { inputType: InputType.Text, section: SectionType.Borrower, required: false },

  totalOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  totalOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },
  psOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  psOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },
  thirdPartyOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  thirdPartyOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },

  lenderSummary: { inputType: InputType.Text, section: SectionType.Summary },
  customId: { inputType: InputType.Text, section: SectionType.Summary, required: false },
  lenderTargetPurchaseDate: { inputType: InputType.Text, section: SectionType.Summary },
};

const GROUND_UP_CONSTRUCTION_FIELDS = {
  programType: { inputType: InputType.Text, section: SectionType.ProgramType },

  lenderNoteStatus: { inputType: InputType.Select, section: SectionType.LoanType },
  loanStrategy: { inputType: InputType.Select, section: SectionType.LoanType },
  loanStrategyId: { inputType: InputType.Number, section: SectionType.LoanType },
  loanPurpose: { inputType: InputType.Select, section: SectionType.LoanType },
  lenderTargetPurchaseDate: {
    inputType: InputType.Text,
    section: SectionType.LoanType,
    required: true,
  },

  propertyType: { inputType: InputType.Select, section: SectionType.Property },
  properties: { inputType: InputType.Text, section: SectionType.Property },
  address: { inputType: InputType.Text, section: SectionType.Property },
  city: { inputType: InputType.Text, section: SectionType.Property },
  state: { inputType: InputType.Text, section: SectionType.Property },
  zip: { inputType: InputType.Text, section: SectionType.Property },
  propertyUnitCount: { inputType: InputType.Number, section: SectionType.Property },
  propertyLotAcres: { inputType: InputType.Number, section: SectionType.Property },

  purchasePrice: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  draftAsIsValuation: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  draftArv: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  yearlyTaxesOwed: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  yearlyPremium: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  propertyLeased: { inputType: InputType.YesNo, section: SectionType.PropertyDetails },
  totalRentPerMonth: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  propertyMarketRentPerMonth: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  monthlyHoaDues: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  paidHardConstructionCosts: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  totalCostBasis: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  existingLoanAmount: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  projectedRehabBudget: { inputType: InputType.Number, section: SectionType.PropertyDetails },
  draftValuationMethod: { inputType: InputType.Select, section: SectionType.PropertyDetails },
  draftValuationDate: { inputType: InputType.Text, section: SectionType.PropertyDetails },
  propertyGroundLease: { inputType: InputType.YesNo, section: SectionType.PropertyDetails },
  propertyEnvironmentalIssuesIdentified: {
    inputType: InputType.YesNo,
    section: SectionType.PropertyDetails,
  },
  propertyOccupancyPercentage: {
    inputType: InputType.Number,
    section: SectionType.PropertyDetails,
  },
  propertyPurchaseDate: { inputType: InputType.Text, section: SectionType.PropertyDetails },

  originationDate: { inputType: InputType.Text, section: SectionType.Loan },
  extensionTerm: { inputType: InputType.Number, section: SectionType.Loan },
  totalPrincipalBalanceAtOrigination: { inputType: InputType.Number, section: SectionType.Loan },
  initialPurchaseDate: { inputType: InputType.Text, section: SectionType.Loan, required: false },
  interestRate: { inputType: InputType.Number, section: SectionType.Loan },
  originalMaturityDate: { inputType: InputType.Text, section: SectionType.Loan },
  extensionExercised: { inputType: InputType.YesNo, section: SectionType.Loan },
  totalPrincipalPayments: { inputType: InputType.Number, section: SectionType.Loan },
  paymentStructure: { inputType: InputType.Select, section: SectionType.Loan },
  lenderEntityLegalEntityId: {
    inputType: InputType.Number,
    section: SectionType.Loan,
  },
  cema: {
    inputType: InputType.YesNo,
    section: SectionType.Loan,
    required: false,
  },
  brokeringEntityId: {
    inputType: InputType.Number,
    section: SectionType.Loan,
  },
  psaExpirationDate: { inputType: InputType.Text, section: SectionType.Loan, required: false },
  loanTerm: { inputType: InputType.Number, section: SectionType.Loan },

  constructionReserve: { inputType: InputType.Number, section: SectionType.Capital },
  constructionReserveDisbursedAtValuation: {
    inputType: InputType.Number,
    section: SectionType.Capital,
  },
  interestReserve: { inputType: InputType.Number, section: SectionType.Capital },
  otherReserves: { inputType: InputType.Number, section: SectionType.Capital },
  bPieceAmount: { inputType: InputType.Number, section: SectionType.Capital },
  juniorLienTotal: { inputType: InputType.Number, section: SectionType.Capital },
  juniorLienHolders: { inputType: InputType.Select, section: SectionType.Capital },
  interestAccrualMethod: { inputType: InputType.Select, section: SectionType.Capital },

  borrowerType: { inputType: InputType.Select, section: SectionType.Borrower },
  borrowerFico: { inputType: InputType.Number, section: SectionType.Borrower },
  professionallyManaged: {
    inputType: InputType.YesNo,
    section: SectionType.Borrower,
    required: false,
  },
  borrowerGuarantorTrackRecord: {
    inputType: InputType.SelectNumber,
    section: SectionType.Borrower,
  },
  borrowerEmail: { inputType: InputType.Text, section: SectionType.Borrower, required: false },
  borrowerLiquidity: { inputType: InputType.Text, section: SectionType.Borrower },
  borrowerResidency: { inputType: InputType.Select, section: SectionType.Borrower },
  personalGuarantyYN: { inputType: InputType.YesNo, section: SectionType.Borrower },

  totalOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  totalOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },
  psOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  psOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },
  thirdPartyOriginationFeesAmount: { inputType: InputType.Number, section: SectionType.Pricing },
  thirdPartyOriginationPoints: { inputType: InputType.Number, section: SectionType.Pricing },

  lenderSummary: { inputType: InputType.Text, section: SectionType.Summary },
  customId: { inputType: InputType.Text, section: SectionType.Summary, required: false },
};

export const FIELDS = {
  [PROGRAM_CLASSIFICATION.RESIDENTIAL_BRIDGE]: RESIDENTIAL_BRIDGE_FIELDS,
  [PROGRAM_CLASSIFICATION.EXTENDED_BRIDGE]: EXTENDED_BRIDGE_FIELDS,
  [PROGRAM_CLASSIFICATION.RESIDENTIAL_RENTAL_LONG_TERM]: RESIDENTIAL_RENTAL_LONG_TERM_FIELDS,
  [PROGRAM_CLASSIFICATION.COMMERCIAL_MULTIFAMILY]: COMMERCIAL_MULTIFAMILY_FIELDS,
  [PROGRAM_CLASSIFICATION.COMMERCIAL_NON_MULTIFAMILY]: COMMERCIAL_NON_MULTIFAMILY_FIELDS,
  [PROGRAM_CLASSIFICATION.GROUND_UP_CONSTRUCTION]: GROUND_UP_CONSTRUCTION_FIELDS,
};
