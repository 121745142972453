export function generateUploadedFilesPayload(provider, { filesUploaded, filesFailed }) {
  Bugsnag.leaveBreadcrumb(
    "filestack:uploaded",
    filesUploaded.reduce((files, file, idx) => {
      files[idx] = `${file.originalPath} -> ${file.key}`;
      return files;
    }, {})
  );

  Bugsnag.leaveBreadcrumb(
    "filestack:failed",
    filesFailed.reduce((files, file, idx) => {
      files[idx] = file.originalPath;
      return files;
    }, {})
  );

  if (!filesUploaded || filesUploaded.length === 0) {
    Bugsnag.notify(
      { name: "FileStackError", message: "filesUploaded is blank" },
      {
        severity: "error",
        metaData: {
          custom: { filesUploaded: filesUploaded, filesFailed: filesFailed },
        },
      }
    );

    return;
  }

  return {
    filesUploaded: filesUploaded.map(file => ({ provider: provider, data: file })),
    filesFailed: filesFailed.map(file => ({ provider: provider, data: file })),
  };
}
