import TagTrigger from "components/tags/tag_trigger";

export default {
  components: {
    TagTrigger,
  },
  data() {
    return {
      lenderTags: PSData.lenderTags,
    };
  },
};
