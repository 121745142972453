<template>
  <div class="worklist-cell progress-update" @click.stop="showPopup = true">
    <progress-update-tooltip
      v-if="currentProgressUpdate"
      :progress-update="currentProgressUpdate"
      :class="{ 'loan-progress-update-tooltip-short': showFollowUpReminder }">
    </progress-update-tooltip>
    <div v-else>
      <div>--</div>
    </div>
    <div v-if="showFollowUpReminder" class="badge badge-danger">
      <i class="fas fa-bell"/>
      <span>Follow Up</span>
    </div>
    <div class="worklist-cell progress-update-label" @click.prevent="showModal = true">
      <i class="fas fa-pencil-alt" />Add an Update
    </div>
    <div v-if="showPopup" @click.stop="showPopup = false" class="worklist-cell-overlay"></div>
    <new-progress-update-modal
      v-if="canUpdateWorklistData"
      :show="showModal"
      :endpoint="loan.routes.progressUpdatePath"
      :last-progress-update="currentProgressUpdate"
      :loan-id="loan.loanId"
      @click.native.stop
      @close="hideModal"
      @progress-update-saved="addCurrentProgressUpdate"
    >
    </new-progress-update-modal>
  </div>
</template>
<script>
import moment from "moment";
import NewProgressUpdateModal from "components/loan_progress_updates/new_modal";
import ProgressUpdateTooltip from "components/reports/loan_progress_update_tooltip";

export default {
  name: "worklist-progress-update-cell",
  props: {
    loan: {
      type: Object,
      required: true,
    },
    canUpdateWorklistData: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ProgressUpdateTooltip,
    NewProgressUpdateModal,
  },
  data() {
    return {
      currentProgressUpdate: this.loan.currentProgressUpdate,
      showPopup: false,
      showModal: false,
    };
  },
  computed: {
    showFollowUpReminder() {
      if (!this.currentProgressUpdate || !this.currentProgressUpdate.followUpDate) {
        return false;
      }

      const followUpDate = moment(this.currentProgressUpdate.followUpDate, "MM/DD/YYYY");
      const days = followUpDate.diff(moment(), "days", true);

      return days <= 0;
    },
  },
  methods: {
    addCurrentProgressUpdate(data, turnOffActionOnAnalyst) {
      this.currentProgressUpdate = data;
      if (this.loan.attentionRequired) {
        this.loan.attentionRequired = !turnOffActionOnAnalyst;
      }
    },
    hideModal() {
      this.showModal = false;
      this.showPopup = false;
    },
  },
};
</script>
