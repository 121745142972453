import { isEqual } from "lodash";

class Lender {
  constructor(lender = {}) {
    this.id = lender.id;
    this.psId = lender.psId;
    this.raw = lender;
    this.raw.defaultAnalysts = lender.defaultAnalysts || [];
    this.raw.defaultAnalystsUserIds = this.raw.defaultAnalysts.map(analyst => analyst.userId);
    this.reset();
  }

  get isChanged() {
    if (this.haveDefaultAnalystsChanged()) return true;

    return [
      "defaultServicerId",
      "defaultLenderServicer",
      "miscInfo",
      "name",
      "constructionDrawVendor",
    ].some(attr => {
      return this[attr] !== this.raw[attr];
    });
  }

  haveDefaultAnalystsChanged() {
    const original = Object.values(this.defaultAnalysts.map(analyst => analyst.userId));
    const current = Object.values(this.raw.defaultAnalystsUserIds);

    return !isEqual(original, current);
  }

  reset() {
    this.defaultServicerId = this.raw.defaultServicerId;
    this.defaultLenderServicer = this.raw.defaultLenderServicer;
    this.constructionDrawVendor = this.raw.constructionDrawVendor;
    this.raw.miscInfo = this.raw.miscInfo || "";
    this.miscInfo = this.raw.miscInfo;
    this.name = this.raw.name;
    this.defaultAnalysts = this.raw.defaultAnalysts.sort((a, b) => {
      var labelA = a.label.toUpperCase();
      var labelB = b.label.toUpperCase();
      return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
    });
  }
}

PS.Models.Lenders.Lender = Lender;
