<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content tracking-numbers-modal-content">
      <div>
        <h4 class="tracking-numbers-modal-header">
          <slot name="header"></slot>
          <a class="modal-close-icon" @click.prevent="onClose" href="#close">&times;</a>
        </h4>
      </div>
      <div>
        <form @keypress.enter="submit">
          <div class="row">
            <div class="col-12 form-group">
              <with-errors on="receiver" :errors="errors">
                <label>Sending To</label>
                <select v-model="model.receiver" type="text" class="form-control">
                  <option disabled value="">Select Destination</option>
                  <option v-for="option in receiverOptions" v-bind:value="option[1]">{{option[0]}}</option>
                </select>
              </with-errors>
            </div>
          </div>
          <div class="row">
            <div class="col-6 form-group">
              <with-errors on="trackingNumber" :errors="errors">
                <label>Tracking Number</label>
                <input v-model="model.trackingNumber" type="text" class="form-control">
              </with-errors>
            </div>
            <div class="col-6 form-group">
              <with-errors on="carrier" :errors="errors">
                <label>Carrier</label>
                <select v-model="model.carrier" type="text" class="form-control">
                  <option disabled value="">Select Carrier</option>
                  <option v-for="carrier in carriers" v-bind:value="carrier[1]">{{carrier[0]}}</option>
                </select>
              </with-errors>
            </div>
          </div>
          <div class="row" v-if="isOtherCarrier">
            <div class="col-12 form-group">
              <with-errors on="carrierName" :errors="errors">
                <label>Carrier Name</label>
                <input v-model="model.carrierName" type="text" class="form-control">
              </with-errors>
            </div>
          </div>
        </form>
      </div>
      <div class="tracking-numbers-modal-footer">
        <button @click.prevent="onClose" class="btn btn-link">
          Cancel
        </button>
        <button :disabled="loading" @click.prevent="submit" class="btn btn-action-cta">
          Save
        </button>
      </div>
    </div>
  </modal-view>
</template>

<script>
import ModalView from "components/modal_view";
import TrackingNumberModel from "models/physical_docs_tracking_number";
import WithErrors from "components/shared/with_errors";

export default {
  name: "physical-docs-tracking-numbers-modal",
  components: {
    ModalView,
    WithErrors,
  },
  props: {
    show: Boolean,
    loading: Boolean,
    receiverOptions: {
      default: () => {
        const options = PSData.physicalDocsTrackingNumbers;
        return options ? options.receiverTypes : [];
      },
    },
    carriers: {
      default: () => {
        const options = PSData.physicalDocsTrackingNumbers;
        return options ? options.carriers : [];
      },
    },
    trackingNumber: Object,
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      model: new TrackingNumberModel(),
    };
  },
  watch: {
    trackingNumber: {
      handler(newVal) {
        this.model = new TrackingNumberModel(newVal);
      },
    },
    show(shouldShow) {
      if (!shouldShow) {
        this.model = new TrackingNumberModel();
      }
    },
    isOtherCarrier(newVal) {
      if (newVal == false) {
        this.model.carrierName = "";
      }
    },
  },
  computed: {
    isOtherCarrier() {
      return this.model.carrier === "other";
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit", this.model);
    },
  },
};
</script>
