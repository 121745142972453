<template>
  <div class="permissions-roles-details">
    <div>
      <a href="#" @click.prevent="$emit('role-deselected')">&lt; All roles</a>
      <h3>{{role.name}}</h3>
      <p>{{role.description}}</p>
    </div>
    <UsersTable :users="users" :readonly="readonly" :updating="updating"/>
  </div>
</template>

<script>
import UsersTable from "components/permissions/users/table";

export default {
  components: { UsersTable },
  props: {
    readonly: Boolean,
    role: Object,
    updating: Boolean,
    users: Array,
  },
};
</script>
