import requireGoogleMap from "google_maps";

const Command = PS.Models.Command;
const Events = PS.Models.Autocomplete.Events;
const CONTROL_STATE = PS.Models.Autocomplete.Const.CONTROL_STATE;

class RequestAutocompleteSetup extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.SetupRequested,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

class RequestAutocompleteFinishing extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.SetupAutocompleteFinished,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

class RequestResultSelection extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.SelectResultRequested,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

class RequestSearchCompletion extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.AutocompleteSearchFinished,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

class RequestAutocomplete extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.AutocompleteSearchRequested,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

class RequestSuggestionValidation extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.SuggestionValidationRequested,
      PS.Services.MessageBusService.autocompleteBus,
      { suggestion: this.state.selectedSuggestion, sourceId: this.event.sourceId }
    );
  }
}

class RequestSuggestionAcceptance extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.SuggestionAccepted,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

class RequestShowSuggestions extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.ShowSuggestionsRequested,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

class RequestHideSuggestions extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.HideSuggestionsRequested,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

class RequestNextSuggestion extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.NextSuggestionRequested,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

class RequestPrevSuggestion extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.PrevSuggestionRequested,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

class RequestSuggestionSelect extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.SuggestionSelectRequested,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

class RequestSimulationOfAutocompleteValidation extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.SimulateAutocompleteValidationRequested,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

class WaitForGoogleAPILoading extends Command {
  execute() {
    return requireGoogleMap();
  }
}

class SetupAutocomplete extends Command {
  execute() {
    this.state.searchEnabled = false;

    this.state.dataSource.setupAutocomplete();

    return Promise.resolve();
  }
}

class FinishSetupAutocomplete extends Command {
  execute() {
    this.state.searchEnabled = true;

    return Promise.resolve();
  }
}

class SaveAutocompleteResult extends Command {
  execute() {
    const suggestions = this.state.dataSource.suggestions;

    if (suggestions.length > 0) {
      this.state.selectedPosition = 0;
      this.state.controlState = CONTROL_STATE.SHOW_SUGGESTIONS;
    } else {
      this.state.controlState = CONTROL_STATE.EMPTY;
    }

    return Promise.resolve();
  }
}

class ResetSuggestions extends Command {
  execute() {
    this.state.selectedSuggestion = undefined;
    this.state.selectedPosition = -1;

    return Promise.resolve();
  }
}

class RunAutocomplete extends Command {
  execute() {
    this.state.controlState = CONTROL_STATE.LOADING;
    this.state.dataSource.runAutocomplete(this.event.query);

    return Promise.resolve();
  }
}

class ValidateSuggestion extends Command {
  execute() {
    this.state.dataSource.validateSuggestion(this.event.suggestion);

    return Promise.resolve();
  }
}

class ResetState extends Command {
  execute() {
    this.state.dataSource.resetState();

    return Promise.resolve();
  }
}

class CheckAutocompleteForErrors extends Command {
  execute() {
    let errors = [];

    if (this.state.suggestions.length < 1) {
      errors.push("Please provide a property address");
    }

    this.state.errors = errors;

    return Promise.resolve();
  }
}

class ResetAutocomplete extends Command {
  execute() {
    this.state.controlState = CONTROL_STATE.EMPTY;

    return Promise.resolve();
  }
}

class ApplyCurrentSuggestion extends Command {
  execute() {
    const suggestion = this.state.suggestions[this.state.selectedPosition];
    this.state.selectedSuggestion = suggestion;
    this.state.dataSource.query = suggestion.text;

    return Promise.resolve();
  }
}

class ApproveNextQueryUpdate extends Command {
  execute() {
    this.state.ignoreNextQueryUpdate = false;

    return Promise.resolve();
  }
}

class IgnoreNextQueryUpdate extends Command {
  execute() {
    this.state.ignoreNextQueryUpdate = true;

    return Promise.resolve();
  }
}

class ShowSuggestions extends Command {
  execute() {
    this.state.controlState = CONTROL_STATE.SHOW_SUGGESTIONS;

    return Promise.resolve();
  }
}

class HideSuggestions extends Command {
  execute() {
    this.state.controlState = CONTROL_STATE.HIDE_SUGGESTIONS;

    return Promise.resolve();
  }
}

class SelectNextSuggestion extends Command {
  execute() {
    if (this.state.selectedPosition + 1 < this.state.suggestions.length) {
      this.state.selectedPosition++;
    } else {
      this.state.selectedPosition = 0;
    }

    return Promise.resolve();
  }
}

class SelectPrevSuggestion extends Command {
  execute() {
    if (this.state.selectedPosition - 1 >= 0) {
      this.state.selectedPosition--;
    } else {
      this.state.selectedPosition = this.state.suggestions.length - 1;
    }

    return Promise.resolve();
  }
}

class SelectSuggestion extends Command {
  execute() {
    this.state.selectedPosition = this.event.suggestionIndex;

    return Promise.resolve();
  }
}

class ApproveAddress extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.AddressApproved,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

PS.Models.Autocomplete.Commands = {
  RequestAutocompleteSetup: RequestAutocompleteSetup,
  RequestAutocompleteFinishing: RequestAutocompleteFinishing,
  RequestResultSelection: RequestResultSelection,
  RequestSearchCompletion: RequestSearchCompletion,
  RequestAutocomplete: RequestAutocomplete,
  RequestSuggestionAcceptance: RequestSuggestionAcceptance,
  RequestShowSuggestions: RequestShowSuggestions,
  RequestHideSuggestions: RequestHideSuggestions,
  RequestNextSuggestion: RequestNextSuggestion,
  RequestPrevSuggestion: RequestPrevSuggestion,
  RequestSuggestionSelect: RequestSuggestionSelect,
  RequestSimulationOfAutocompleteValidation: RequestSimulationOfAutocompleteValidation,
  WaitForGoogleAPILoading: WaitForGoogleAPILoading,
  SetupAutocomplete: SetupAutocomplete,
  FinishSetupAutocomplete: FinishSetupAutocomplete,
  SaveAutocompleteResult: SaveAutocompleteResult,
  RunAutocomplete: RunAutocomplete,
  RequestSuggestionValidation: RequestSuggestionValidation,
  ValidateSuggestion: ValidateSuggestion,
  ResetState: ResetState,
  CheckAutocompleteForErrors: CheckAutocompleteForErrors,
  ResetAutocomplete: ResetAutocomplete,
  ShowSuggestions: ShowSuggestions,
  HideSuggestions: HideSuggestions,
  ResetSuggestions: ResetSuggestions,
  ApplyCurrentSuggestion: ApplyCurrentSuggestion,
  IgnoreNextQueryUpdate: IgnoreNextQueryUpdate,
  ApproveNextQueryUpdate: ApproveNextQueryUpdate,
  SelectNextSuggestion: SelectNextSuggestion,
  SelectPrevSuggestion: SelectPrevSuggestion,
  SelectSuggestion: SelectSuggestion,
  ApproveAddress: ApproveAddress,
};
