<template>
  <validation-provider
    ref="provider"
    v-slot="{ errors, valid, validate }"
    name="Loan CSV"
    rules="required"
    slim
  >
    <div class="form-group">
      <label>Loan CSV</label>

      <input
        class="form-control-file form-control"
        placeholder="Select a CSV file"
        name="loanCsv"
        type="file"
        accept=".csv"
        :class="validationClass(valid)"
        :disabled="disabled"
        @input="handleInput($event) || validate($event)"
      >

      <div v-if="valid === false" class="invalid-feedback">
        {{ errors.map(({ msg }) => msg).join(", ") }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import camelCase from "lodash/camelCase";
import map from "lodash/map";
import { ValidationProvider } from "vee-validate";
import Papa from "papaparse";

const transformHeader = header => camelCase(header.replace(/\s+/, "_"));

export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },
  methods: {
    parseCSV(file) {
      return new Promise(resolve => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          transformHeader,
          complete: resolve,
        });
      });
    },
    validate({ errors: csvErrors, meta }) {
      const errors = map(csvErrors, "message");

      if (errors.length == 0) {
        if (!meta.fields.includes("loanId")) {
          errors.push("Loan ID column is missing");
        }

        if (!meta.fields.includes("salesPrice")) {
          errors.push("Sales Price column is missing");
        }
      }

      this.$refs.provider.applyResult({
        errors,
        valid: errors.length == 0,
        failedRules: {},
      });
    },
    async handleInput(event) {
      this.$refs.provider.value = event.target.files;
      const csv = await this.parseCSV(event.target.files[0]);
      this.validate(csv);

      if (this.$refs.provider.isValid) {
        this.$emit("input", this.extractLoanSales(csv));
      }
    },
    extractLoanSales({ data }) {
      return data.map(({ loanId: loanPsId, salesPrice, interestDays }) => (
        { loanPsId, salesPrice, interestDays })
      );
    },
    validationClass: valid => (valid === null ? null : valid ? "is-valid" : "is-invalid"),
  },
};
</script>
