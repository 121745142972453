let UID = 0;

export default class Rule {
  static executeAll(commands) {
    let continueExecution = true;

    return commands.reduce((chain, command) => {
      return chain
        .then(() => (continueExecution ? command.execute() : undefined))
        .catch(() => (continueExecution = false)); //break chain
    }, Promise.resolve());
  }

  constructor(state) {
    this.state = state;
    this.uid = UID++;
  }

  checkAndApply(event) {
    let commands = this.check(event);
    Rule.executeAll(commands);
  }

  check(event) {
    return [];
  }
}

PS.Models.Rule = Rule;
