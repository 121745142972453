import isEmpty from "lodash/isEmpty";
import AjaxService from "services/ajax_service";
import Location from "services/location_service";
const SessionStorageService = PS.Services.SessionStorageService;
const SHOW_EDIT_MODE = "settlement_statement_show_edit_mode";

export default {
  data() {
    return {
      loan: PSData.loan || {},
      settlementStatement: PSData.settlementStatement || {},
      settlementStatementPolicy: PSData.settlementStatementPolicy,
      loanPolicy: PSData.loanPolicy,
      isLoading: false,
      errors: [],
      errorType: undefined,
    };
  },
  computed: {
    isPurchased() {
      return this.settlementStatement.status === "purchased";
    },
    hasErrors() {
      return !isEmpty(this.errors);
    },
  },
  methods: {
    showEditMode() {
      return SessionStorageService.getItem(SHOW_EDIT_MODE) === true;
    },
    clearEditModeFlag() {
      SessionStorageService.removeItem(SHOW_EDIT_MODE);
    },
    setEditModeFlag() {
      SessionStorageService.setItem(SHOW_EDIT_MODE, true);
    },
    generateStatement() {
      const messages = {
        onSuccess: "Settlement Statement was successfully created",
        onError: "Unable to create Settlement Statement",
      };
      const url = `/manage/loans/${this.loan.toParam}/settlement_statements`;

      this.makeAjaxCall(url, messages, {
        errorsScope: "loan",
        onSuccess: this.goToEditNewStatement,
      });
    },
    approveStatement() {
      const messages = {
        onSuccess: "Settlement Statement was successfully approved.",
        onError: "Unable to approve Settlement Statement.",
      };
      const url = `/manage/settlement_statements/${this.settlementStatement.id}/approve`;

      this.makeAjaxCall(url, messages);
    },
    reverseStatement() {
      const messages = {
        onSuccess: "Settlement Statement was successfully reversed.",
        onError: "Unable to reverse Settlement Statement.",
      };
      const url = `/manage/settlement_statements/${this.settlementStatement.id}/reverse`;

      this.makeAjaxCall(url, messages, {
        errorsScope: "loan",
        onSuccess: this.goToNewStatement,
      });
    },
    submitStatement() {
      const messages = {
        onSuccess: "Settlement Statement submitted for approval.",
        onError: "Unable to submit Settlement Statement.",
      };
      const url = `/manage/settlement_statements/${this.settlementStatement.id}/submit`;

      this.makeAjaxCall(url, messages);
    },
    purchaseStatement() {
      const messages = {
        onSuccess: "Settlement Statement was successfully purchased",
        onError: "Can't purchase Settlement Statement this time",
      };
      const url = `/manage/settlement_statements/${this.settlementStatement.id}/purchase`;

      this.makeAjaxCall(url, messages, { onSuccess: this.reloadPage });
    },
    goToNewStatement({ settlementStatement: { id } }) {
      Location.assign(`/manage/settlement_statements/${id}/`);
    },
    goToEditNewStatement({ settlementStatement: { id } }) {
      this.setEditModeFlag();
      Location.assign(`/manage/settlement_statements/${id}/?cb=${Math.random()}`);
    },
    reloadPage() {
      Location.reload();
    },
    makeAjaxCall(url, messages, opts = {}) {
      const { onSuccess, errorsScope } = Object.assign(
        {
          onSuccess: this.processAjaxResponse,
          errorsScope: "base",
        },
        opts
      );

      this.toggleLoader();

      AjaxService.withNotifications(messages)
        .post(url)
        .then(data => {
          this.errorType = undefined;
          this.errors = [];
          onSuccess(data);
        })
        .catch(({ data }) => {
          this.errors = data.errors[errorsScope];
          this.errorType = data.type;
        })
        .finally(this.toggleLoader);
    },
    processAjaxResponse({ settlementStatement, settlementStatementPolicy, loanPolicy }) {
      this.errorType = undefined;
      this.errors = [];
      this.settlementStatement = settlementStatement;
      this.settlementStatementPolicy = settlementStatementPolicy;
      this.loanPolicy = loanPolicy;
    },
    toggleLoader() {
      this.isLoading = !this.isLoading;
    },
  },
};
