<template>
  <div class="col-12 section-header">
    <div class="section-header-col">
      <div>
        <slot />
      </div>
      <span class="subheader">{{ subheading }}</span>
    </div>
    <div v-if="!actionsHidden" class="section-header-col loan-actions">
      <div v-if="!editing">
        <button class="btn btn-action-cta" @click="$emit('edit')">
          Edit
        </button>
      </div>

      <div v-else>
        <button
          class="btn btn-action-cta"
          :disabled="updating || disabled"
          @click.prevent.once="$emit('update')"
        >
          {{ updateButtonText }}
        </button>
        <button
          class="btn btn-cancel-cta"
          :disabled="updating"
          @click="$emit('cancel')"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-header",
  props: {
    editing: {
      type: Boolean,
      required: false,
    },
    updating: {
      type: Boolean,
      required: false,
    },
    subheading: {
      type: String,
      required: false,
      default: null,
    },
    actionsHidden: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    updateButtonText() {
      return this.updating ? "Saving..." : "Save";
    },
  },
};
</script>
