<template>
  <div>
    <div class="lender-sections-container">
      <div class="lender-heading">
        <h3>Dashboard</h3>
      </div>

      <div class="row">
        <div class="col-6 monthly-loan-sales-container">
          <monthly-loan-sales :sales="loanSales.lastMonth" :goal="loanSales.monthlyGoal" />
        </div>
        <div class="col-6 monthly-loan-sales-container">
          <quarterly-loan-sales :sales="loanSales.lastQuarter" :goal="loanSales.quarterlyGoal" />
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 monthly-loan-sales-container">
          <div class="monthly-loan-sales lender-stats lender-section">
            <loan-pipeline-chart :sources="charts.loanPipeline" :loans-url="routes.loansUrl" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="row multiline">
            <div class="col-lg-6 monthly-loan-sales-container">
              <active-loan-stats />
            </div>

            <div class="col-lg-6 monthly-loan-sales-container">
              <maturing-loan-stats />
            </div>

            <div class="col-lg-6 monthly-loan-sales-container">
              <year-to-date-loan-submissions />
            </div>

            <div class="col-lg-6 monthly-loan-sales-container">
              <year-to-date-loan-sales />
            </div>
          </div>
        </div>

        <div class="col-lg-6 monthly-loan-sales-container">
          <div class="monthly-loan-sales lender-section">
            <all-time-sales-chart :sources="charts.allTimeSales" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 monthly-loan-sales-container">
          <div class="monthly-loan-sales lender-stats lender-section">
            <loan-trends-chart :sources="charts.loanTrends">
              <template slot="header">
                <ul>
                  <li
                    :class="{ active: loanTrendsType === 'monthly' }"
                    @click="loanTrendsType = 'monthly'"
                  >
                    Monthly
                  </li>
                  <li
                    :class="{ active: loanTrendsType === 'quarterly' }"
                    @click="loanTrendsType = 'quarterly'"
                  >
                    Quarterly
                  </li>
                </ul>
              </template>
            </loan-trends-chart>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="location-marker-map-section monthly-loan-sales lender-section">
            <h4 class="location-marker-map-title">
              Your Investor Reach
              <div v-if="isLoadingInvestorReachData" class="ps-loader ps-loader-small" />
            </h4>

            <div class="stats-container">
              <div>
                <h5>Investors</h5>
                <span class="stats">{{ investorReachData.investors_count | number(0) }}</span>
              </div>

              <div>
                <h5>U.S. States &amp; Territories</h5>
                <span class="stats">{{ investorReachData.states_count | number(0) }}</span>
              </div>
            </div>
          </div>

          <div>
            <location-marker-map
              :map-locations="investorReachData.locations"
              @map-loaded="turnOffMapLoading"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoanSales from "models/loan/loan_sales";
import LoanSubmissions from "models/loan/loan_submissions";
import activeLoanStatsComponent from "components/active_loans_stats";
import locationMarkerMapComponent from "components/location_marker_map";
import loanPipelineChart from "models/lenders/charts/pipeline";
import AllTimeSales from "models/lenders/charts/all_time_sales";
import loanPipelineChartComponent from "components/lenders/loan_pipeline_chart";
import { MonthlyLoanTrends, QuarterlyLoanTrends } from "models/market_data/charts/loan_trends";
import loanTrendsChartComponent from "components/loan_trends_chart";
import AllTimeSalesChart from "components/lenders/all_time_sales";
import maturingLoanStatsComponent from "components/maturing_loans_stats";
import monthlyLoanSalesComponent from "components/monthly_loan_sales";
import quarterlyLoanSalesComponent from "components/quarterly_loan_sales";
import yearToDateLoanSalesComponent from "components/year_to_date_loan_sales";
import yearToDateLoanSubmissionsComponent from "components/year_to_date_loan_submissions";

import toPlainObject from "utils/to_plain_object";

const Ajax = PS.Services.AjaxService;

export default {
  components: {
    "active-loan-stats": activeLoanStatsComponent,
    "loan-pipeline-chart": loanPipelineChartComponent,
    "loan-trends-chart": loanTrendsChartComponent,
    "location-marker-map": locationMarkerMapComponent,
    "maturing-loan-stats": maturingLoanStatsComponent,
    "monthly-loan-sales": monthlyLoanSalesComponent,
    "quarterly-loan-sales": quarterlyLoanSalesComponent,
    "year-to-date-loan-sales": yearToDateLoanSalesComponent,
    "year-to-date-loan-submissions": yearToDateLoanSubmissionsComponent,
    "all-time-sales-chart": AllTimeSalesChart,
  },
  data() {
    return {
      lenderSummary: PSData.lenderSummaries,
      loanTrendsType: "monthly",
      loanSubmissions: new LoanSubmissions(PSData.lenderSubmissions6Quarters),
      loanSales: new LoanSales(PSData.lenderSales6Quarters, PSData.lenderGoals),
      charts: {
        loanPipeline: new loanPipelineChart(),
        loanTrends: this.monthlyLoanTrendsSource,
        allTimeSales: new AllTimeSales(PSData.lenderSales || []),
      },
      routes: PSData.routes || {},
      investorReachData: {},
      isLoadingInvestorReachData: undefined,
    };
  },
  computed: {
    monthlyLoanTrendsSource() {
      return new MonthlyLoanTrends({
        submissions: this.loanSubmissions.monthly,
        sales: this.loanSales.monthly,
      });
    },
    quarterlyLoanTrendsSource() {
      return new QuarterlyLoanTrends({
        submissions: this.loanSubmissions.quarterly,
        sales: this.loanSales.quarterly,
      });
    },
  },
  watch: {
    loanTrendsType: {
      immediate: true,
      handler(newValue) {
        if (newValue === "monthly") {
          this.charts.loanTrends = this.monthlyLoanTrendsSource;
        } else {
          this.charts.loanTrends = this.quarterlyLoanTrendsSource;
        }
      },
    },
  },
  created() {
    this.getPipeline();
    this.getInvestorReachData();
  },
  methods: {
    getPipeline() {
      const url = this.routes.loanPipelineSummaryUrl;

      if (!url) {
        return this.charts.loanPipeline.fail();
      }

      return Ajax.get(url).then(
        data => {
          if (!data.error) {
            this.charts.loanPipeline.success(data);
          } else {
            this.charts.loanPipeline.fail();

            Bugsnag.notify({
              name: "Pipeline Data Error",
              message: `Unable to get pipeline data: ${data.error}`,
            });
          }
        },
        ({ response }) => {
          this.charts.loanPipeline.fail();

          Bugsnag.notify(
            { name: "Request Error", message: "Unable to get pipeline data" },
            { metaData: toPlainObject(response) }
          );
        }
      );
    },
    getInvestorReachData() {
      const url = this.routes.investorReachUrl;
      this.isLoadingInvestorReachData = true;

      return Ajax.get(url).then(
        data => {
          if (!data.error) {
            this.investorReachData = data;
          } else {
            this.turnOffMapLoading();

            Bugsnag.notify({
              name: "Investor Reach Error",
              message: `Unable to get investor reach data ${data.error}`,
            });
          }
        },
        ({ response }) => {
          this.turnOffMapLoading();

          Bugsnag.notify(
            { name: "Request Error", message: "Unable to get investor reach data" },
            { metaData: toPlainObject(response) }
          );
        }
      );
    },
    turnOffMapLoading() {
      this.isLoadingInvestorReachData = false;
    },
  },
};
</script>

<style lang="scss">
.banner-carousel {
  .carousel-item {
    display: block;
  }
}
</style>
