<template>
  <tr>
    <td class="modification-type-column">
      <div class="row no-gutters">
        <editable-select
          :options="modificationOptions"
          :editing="true"
          :value="form.typeId"
          @input="updateType($event)"
        />
      </div>
    </td>
    <td class="modification-value-column">
      <div class="row no-gutters">
        <div :class="{'has-error': hasFieldError('fromValue')}">
          <component
            :is="inputField"
            :value="form.fromValue"
            :editing="true"
            :disabled="!form.typeId"
            :allow-minus="false"
            v-validate="{ required: isExercised }"
            :data-vv-scope="formScope"
            name="fromValue"
            @input="updateValue('fromValue', $event)"
          />
        </div>
      </div>
    </td>
    <td class="modification-value-column">
      <div class="row no-gutters">
        <div :class="{'has-error': hasFieldError('toValue')}">
          <component
            :is="inputField"
            :value="form.toValue"
            :editing="true"
            :disabled="!form.typeId"
            :allow-minus="false"
            v-validate="{ required: isExercised }"
            :data-vv-scope="formScope"
            name="toValue"
            @input="updateValue('toValue', $event)"
          />
        </div>
      </div>
    </td>
    <td>
      <i
        class="far fa-trash-alt fa-lg"
        @click="deleteModification"
      />
    </td>
  </tr>
</template>
<script>
import EditableDate from "../../editable_date";
import EditableCurrency from "../../editable_currency";
import EditableSelect from "../../editable_select";
import uniqueId from "lodash/uniqueId";

export default {
  inject: ["$validator"],
  components: {
    EditableCurrency,
    EditableDate,
    EditableSelect,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    selectedOptions: {
      type: Array,
      default: () => [],
    },
    defaultValues: {
      type: Object,
      default: () => ({}),
    },
    isExercised: Boolean,
  },
  data() {
    return {
      form: {
        typeId: "",
        fromValue: "",
        toValue: "",
        ...this.item,
      },
      formScope: uniqueId(),
    };
  },
  methods: {
    updateType(event) {
      this.form.typeId = event.target.value;
      this.form.fromValue = "";
      this.form.toValue = "";
      if (this.defaultValues[this.form.typeId]) {
        this.form.fromValue = this.defaultValues[this.form.typeId];
      }
    },
    updateValue(valueName, value) {
      this.form[valueName] = value;
    },
    deleteModification() {
      this.$emit("delete");
    },
    hasFieldError(fieldName) {
      return this.errors.has(fieldName, this.formScope);
    },
  },
  watch: {
    form: {
      handler(v) {
        this.$emit("change", v);
      },
      deep: true,
    },
  },
  computed: {
    inputField() {
      const mapping = {
        maturityDate: "editable-date",
        paidToDate: "editable-date",
        paymentAmount: "editable-currency",
      };
      return mapping[this.item.typeId] || "editable-date";
    },
    modificationOptions() {
      const optionList = [
        { id: "maturityDate", text: "Maturity Date (Extension)" },
        { id: "paidToDate", text: "Paid To Date (Deferral)" },
        { id: "paymentAmount", text: "Payment Amount (Modification)" },
      ];

      return optionList.filter(
        option => option.id === this.item.typeId || !this.selectedOptions.includes(option.id)
      );
    },
  },
};
</script>
