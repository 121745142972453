<template>
  <div class="level">
    <div class="level-left">
      <div class="level-item">
        <base-radio
          :disabled="disabled"
          :value="value"
          @input="$listeners.input"
          name="recoverable-filter"
          native-value="all_fees_costs"
        >
          All Fees & Costs
        </base-radio>
      </div>
      <div class="level-item">
        <base-radio
          :disabled="disabled"
          :value="value"
          @input="$listeners.input"
          name="recoverable-filter"
          native-value="all_recoverable"
        >
          All Recoverable
        </base-radio>
      </div>
      <div class="level-item">
        <base-radio
          :disabled="disabled"
          :value="value"
          @input="$listeners.input"
          name="recoverable-filter"
          native-value="investor_recoverable"
        >
          Investor Recoverable
        </base-radio>
      </div>
      <div class="level-item">
        <base-radio
          :disabled="disabled"
          :value="value"
          @input="$listeners.input"
          name="recoverable-filter"
          native-value="borrower_recoverable"
        >
          Borrower Recoverable
        </base-radio>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: String,
    disabled: Boolean,
  },
};
</script>
<style scoped>
::v-deep label span {
  color: var(--color-grey-86);
}

::v-deep .base-radio {
  display: flex;
  padding-bottom: 0;
}
</style>
