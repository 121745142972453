<template>
  <div v-if="type == 'dropdown'" class="editable-cell-dropdown">
    <span @click.stop="toggleDropdown">
      {{ currentValue }} /
      <i class="fas fa-caret-down" :class="classForCaret" />
    </span>
    <div v-if="dropdown" @click.stop="hideDropdown" class="dropdown-list-overlay"></div>
    <div v-if="dropdown" class="dropdown-list">
      <ul>
        <li v-for="option in options" :key="option.name" @click.stop="onClick(option)">
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "editable-cell",
  props: {
    type: {
      type: String,
      required: true,
    },
    options: Array,
    value: String,
  },
  data() {
    return { name: this.value, dropdown: false };
  },
  methods: {
    toggleDropdown() {
      if (this.dropdown) {
        return this.hideDropdown();
      }

      this.dropdown = true;
    },
    hideDropdown() {
      this.dropdown = false;
    },
    onClick(option) {
      this.hideDropdown();

      if (option.name === "blank") {
        this.name = null;
      } else {
        this.name = option.name;
      }

      this.$emit("input", this.name);
    },
  },
  computed: {
    currentValue() {
      if (this.name) {
        return this.options.find(option => option.name === this.name).label;
      } else {
        return "—";
      }
    },
    classForCaret() {
      if (this.dropdown) {
        return "open";
      }
    },
  },
};
</script>
