<template>
  <div class="sorting-header" @click="changeSortingOrder">
    <span class="title">{{ title }}</span>
    <span class="sort-direction" :class="sortingClass">
      <i class="fas fa-caret-up arrow-up" />
      <i class="fas fa-caret-down arrow-down" />
    </span>
  </div>
</template>

<script>
const SORTING_CLASSES = {
  "-1": "direction-down",
  "0": "direction-none",
  "1": "direction-up",
};

export default {
  name: "sorting-header",
  props: {
    title: String,
    order: Number,
  },
  data() {
    return {
      sortingOrder: 0,
    };
  },
  watch: {
    order: {
      handler: function(value) {
        this.sortingOrder = value;
      },
      immediate: true,
    },
  },
  computed: {
    sortingClass() {
      return SORTING_CLASSES[this.sortingOrder];
    },
  },
  methods: {
    changeSortingOrder() {
      if (this.sortingOrder === 0) {
        this.sortingOrder = 1;
      } else {
        this.sortingOrder *= -1;
      }

      this.$emit("on-change-direction", this.sortingOrder);
    },
  },
};
</script>
