import Pusher from "pusher-js";

const DEFAULT_TIMEOUT = 60000;
let socket;

class PusherService {
  static get client() {
    if (socket === undefined) {
      socket = new Pusher(PSData.global.pusherKey, {
        cluster: PSData.global.pusherCluster,
      });
    }

    return socket;
  }

  static listenOnce(channelId, event, timeout = DEFAULT_TIMEOUT) {
    return new Promise((resolve, reject) => {
      const channel = this.client.subscribe(channelId);
      const timer = setTimeout(() => {
        this.closeConnection(channel);

        reject(new Error("Operation timed out"));
      }, timeout);

      channel.bind(event, data => {
        this.closeConnection(channel);

        clearTimeout(timer);
        resolve(data);
      });
    });
  }

  static closeConnection(channel) {
    channel.unbind();
    this.client.unsubscribe(channel.name);
  }
}

PS.Services.PusherService = PusherService;
