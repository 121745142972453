import MidasService from "services/midas_service";
import toPlainObject from "utils/to_plain_object";

const Command = PS.Models.Command;
const Events = PS.Models.Reports.Events;
const Analytics = PS.Models.MarketData.Analytics;
const Charts = PS.Models.MarketData.Charts;
const Tables = PS.Models.MarketData.Tables;
const Common = PS.Models.Reports.Commands.Common;

class RequestZipReportLoading extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.ZipReport.ZipReportRequested,
      PS.Services.MessageBusService.lenderBus,
      this.event.options
    );
  }
}

class ClearZipReport extends Command {
  execute() {
    Object.assign(this.state, {
      terminusData: undefined,
      terminusError: undefined,

      midasData: undefined,
      midasError: undefined,

      analytics: {
        clearCapitalZip: new Analytics.ClearCapitalZip(),
        midasZip: new Analytics.MidasZip(),
        midasMsa: new Analytics.MidasMsa(),
        midasNational: new Analytics.MidasNational(),
      },

      tables: {
        zipAtGlance: new Tables.ZipAtGlance(),
      },

      charts: {
        homeIndex: new Charts.ZipHomeIndex(),
        costOfHomeownership: new Charts.CostOfHomeownership(),
        marketActionMedian: new Charts.MarketActionMedian(),
        inventoryTotal: new Charts.ListingStats("Inventory (Total Listings)", "inventoryTotal"),
        estimatedSalesTotal: new Charts.ListingStats(
          "Estimated Monthly Sales",
          "hcEstimatedSalesTotal"
        ),
        daysOnMarketMedian: new Charts.ListingStats("Days on Market", "daysOnMarketMedian"),
        monthsOfSupply: new Charts.ListingStats("Months of Supply", "hcMonthsOfInventoryMedian"),
        rentVersusOwnership: new Charts.RentVersusOwnership(),
        householdGrowth: new Charts.HouseholdGrowth(),
        occupiedVersusVacant: new Charts.OccupiedVersusVacant(),
        housingStockValue: new Charts.HousingStockValue("zipCrossSection"),
        housingStockAge: new Charts.HousingStockAge("zipCrossSection"),
        housingStockType: new Charts.HousingStockType("zipCrossSection"),
        housingStockSize: new Charts.HousingStockSize("zipCrossSection"),
      },
    });

    return Promise.resolve();
  }
}

class LoadLenderTerminusData extends Command {
  execute() {
    const payload = {
      source: this.event.source,
      zip: this.event.zip,
    };

    return PS.Services.AjaxService.post("/tools/zip_report/user_search", payload).then(
      data => {
        this.state.terminusData = data;
      },
      data => {
        this.state.terminusError = data;

        Bugsnag.notify(new Error("Unable to get lender searched terminus data"), {
          metaData: { custom: { response: toPlainObject(data.response), payload: payload } },
        });
      }
    );
  }
}

class AddTerminusDataToHomeIndexChart extends Command {
  execute() {
    const { terminusData, terminusError } = this.state;
    const chart = this.state.charts.homeIndex;

    if (terminusError) {
      chart.fail({
        terminus: "Unable to get Clear Capital data",
      });
    } else if (terminusData.error) {
      chart.fail({
        terminus: terminusData.error[0],
      });
    } else {
      chart.success({
        terminus: terminusData,
      });
    }

    return Promise.resolve();
  }
}

class AddTerminusDataToAnalytics extends Command {
  execute() {
    const { terminusData, terminusError } = this.state;
    const analytics = this.state.analytics;

    if (terminusError) {
      analytics.clearCapitalZip.fail("Unable to get Clear Capital data");
    } else if (terminusData.error) {
      analytics.clearCapitalZip.fail(terminusData.error[0]);
    } else {
      analytics.clearCapitalZip.success(terminusData);
    }

    return Promise.resolve();
  }
}

class LoadLenderMidasData extends Command {
  execute() {
    const payload = {
      source: this.event.source,
      zip: this.event.zip,
    };

    return MidasService.zipReport(payload.zip).then(
      ({ data }) => {
        this.state.midasData = data;
      },
      data => {
        this.state.midasError = data;

        Bugsnag.notify(new Error("Unable to get lender searched midas data"), {
          metaData: { custom: { response: toPlainObject(data), payload: payload } },
        });
      }
    );
  }
}

class AddMidasDataToAnalytics extends Command {
  execute() {
    const { midasData, midasError } = this.state;
    const analytics = this.state.analytics;

    if (midasError) {
      const message = "Unable to get House Canary data";

      analytics.midasZip.fail(message);
      analytics.midasMsa.fail(message);
      analytics.midasNational.fail(message);
    } else if (midasData.error) {
      const message = midasData.error[0];

      analytics.midasZip.fail(message);
      analytics.midasMsa.fail(message);
      analytics.midasNational.fail(message);
    } else {
      analytics.midasZip.success(midasData.zipCrossSection, midasData.zipTimeSeriesSummary);
      analytics.midasMsa.success(midasData.msaCrossSection, midasData.msaTimeSeriesSummary);
      analytics.midasNational.success(
        midasData.nationalCrossSection,
        midasData.nationalTimeSeriesSummary
      );
    }

    return Promise.resolve();
  }
}

PS.Models.Reports.Commands.ZipReport = {
  RequestZipReportLoading: RequestZipReportLoading,
  ClearZipReport: ClearZipReport,
  LoadLenderTerminusData: LoadLenderTerminusData,
  AddTerminusDataToHomeIndexChart: AddTerminusDataToHomeIndexChart,
  AddTerminusDataToAnalytics: AddTerminusDataToAnalytics,
  LoadLenderMidasData: LoadLenderMidasData,
  AddMidasDataToAnalytics: AddMidasDataToAnalytics,
  AddMidasDataToCharts: Common.AddMidasDataToCharts,
  AddMidasDataToTables: Common.AddMidasDataToTables,
};
