import selectOptionsToMapping from "utils/select_options_to_mapping";
import DateService from "services/date_service";

export default class TrackRecordDeal {
  constructor(record = {}, options = {}) {
    this.update(record);
    this._optionsRaw = options;

    this.propertyStates = options.states || [];
    this.propertyStatesMapping = selectOptionsToMapping(options.states || []);
  }

  get objectToSave() {
    return {
      id: this.id,
      property_address: this.propertyAddress,
      property_city: this.propertyCity,
      property_state: this.propertyState,
      property_zip: this.propertyZip,
      acquisition_date: this.acquisitionDate,
      acquisition_price: this.acquisitionPrice,
      disposition_date: this.dispositionDate,
      disposition_price: this.dispositionPrice,
      owner: this.owner,
      ownership: this.ownership,
      renovation_funds_used: this.renovationFundsUsed,
      gross_rental_income: this.grossRentalIncome,
      current_cost_basis: this.currentCostBasis,
      present_market_value: this.presentMarketValue,
      mortgages_and_liens_amount: this.mortgagesAndLiensAmount,
    };
  }

  get formattedAcquisitionDate() {
    return DateService.formatDate(this.acquisitionDate);
  }

  get formattedDispositionDate() {
    return DateService.formatDate(this.dispositionDate);
  }

  get propertyStateLabel() {
    return this.propertyStatesMapping[this.propertyState];
  }

  update(record) {
    this._raw = record;
    Object.assign(this, record);
  }

  reset() {
    this.update(this._raw);
  }

  get copy() {
    return new TrackRecordDeal(this._raw, this._optionsRaw);
  }
}
