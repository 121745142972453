<template>
  <base-modal-form
    :show="show"
    :title="formTitle"
    :save-disabled="isSaving"
    class="loan-task-definition-form"
    @close="onClose"
    @save="onSave"
  >
    <base-tabs
      v-if="show"
      v-model="activeTab"
      bordered
      :animated="false"
      class="loan-task-definition-tabs"
    >
      <base-tab-item value="general" label="General">
        <template v-slot:content>
          <general-form
            :definition="definition"
            :action-types="actionTypes"
            :task-types="taskTypes"
            :mapping-required-task-types="mappingRequiredTaskTypes"
            :tasks="tasks"
            :document-types="documentTypes"
            :loan-task-groups="loanTaskGroups"
            :errors="errors"
          />
        </template>
      </base-tab-item>
      <base-tab-item value="details" label="QA Details">
        <template v-slot:content>
          <detail-form :detail="definition.detail" :error-codes="errorCodes" />
        </template>
      </base-tab-item>
      <base-tab-item value="advanced" label="Advanced">
        <template v-slot:content>
          <advanced-form
            :definition="definition"
            :duration-types="durationTypes"
            :required-roles="requiredRoles"
            :errors="errors"
          />
        </template>
      </base-tab-item>
    </base-tabs>
    <template v-slot:footer>
      <div class="secondary-actions">
        <base-switch
          v-model="definition.active"
          :native-value="definition.active"
          class="pb-0"
        >
          Task Enabled
        </base-switch>
        <base-button type="text" v-if="canCopy" @click="copyToBorrowerTask">
          Copy To Borrower
        </base-button>
      </div>
    </template>
  </base-modal-form>
</template>
<script>
import AjaxService from "services/ajax_service";
import { MANAGE_TASK_DEFINITIONS_ROUTE } from "models/loan_task_definition";
import GeneralForm from "./general_form";
import DetailForm from "./detail_form";
import AdvancedForm from "./advanced_form";

export default {
  components: {
    GeneralForm,
    DetailForm,
    AdvancedForm,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    borrowerTaskCopyingEnabled: {
      type: Boolean,
      default: () => false,
    },
    definition: {
      type: Object,
      default: () => ({}),
    },
    documentTypes: {
      type: Array,
      default: () => [],
    },
    actionTypes: {
      type: Array,
      default: () => [],
    },
    taskTypes: {
      type: Array,
      default: () => [],
    },
    mappingRequiredTaskTypes: {
      type: Array,
      default: () => [],
    },
    requiredRoles: {
      type: Array,
      default: () => [],
    },
    durationTypes: {
      type: Array,
      default: () => [],
    },
    errorCodes: {
      type: Array,
      default: () => [],
    },
    loanTaskGroups: {
      type: Array,
      default: () => [],
    },
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSaving: false,
      errors: {},
      activeTab: "general",
    };
  },
  computed: {
    canCopy() {
      return (
        this.borrowerTaskCopyingEnabled &&
        this.definition.taskType == "lender" &&
        !this.definition.isNew
      );
    },
    formTitle() {
      return this.definition && this.definition.isNew ? "New Task" : "Edit Task";
    },
  },
  methods: {
    copyToBorrowerTask() {
      this.$emit("copy-to-borrower-task");
    },
    onClose() {
      this.errors = {};
      this.$emit("close");
    },
    onSave() {
      this.save();
    },
    save() {
      const messages = { onSuccess: "Definition is saved", onError: this.onError };

      this.isSaving = true;

      return this.saveDefinition(messages)
        .then(data => {
          this.$emit("save", data);
          this.onClose();
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    saveDefinition(messages) {
      if (this.definition.isNew) {
        return AjaxService.withNotifications(messages).post(
          MANAGE_TASK_DEFINITIONS_ROUTE,
          this.definition.objectToSave
        );
      } else {
        return AjaxService.withNotifications(messages).patch(
          `${MANAGE_TASK_DEFINITIONS_ROUTE}/${this.definition.id}`,
          this.definition.objectToSave
        );
      }
    },
    onError({ data }) {
      this.errors = data.errors || {};
      if (this.errors.system_name) {
        this.activeTab = "advanced";
      }
      return null;
    },
  },
};
</script>
<style scoped>
.secondary-actions {
  display: flex;
}

.loan-task-definition-form >>> .modal-card {
  min-width: 600px;
  max-width: 600px;
}

.loan-task-definition-tabs >>> .tabs {
  margin: 0 calc(var(--space-unit) * -3);
}

.loan-task-definition-tabs >>> .tabs ul {
  padding: 0 calc(var(--space-unit) * 3);
}
</style>
