<template>
  <banner-alert
    v-if="show"
    title="No lenders are following this loan"
    description="The lender will not receive emails for this loan until a lender is added as a follower."
  />
</template>

<script>
import BannerAlert from "components/banners/alert";

export default {
  name: "no-lender-followers-alert",
  components: {
    BannerAlert,
  },
  props: {
    show: Boolean,
  },
};
</script>
