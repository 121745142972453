<template>
  <div ref="loan-tasks-container" class="loan-tasks-component">
    <div class="loan-tasks-list-container" :class="{ 'loan-tasks-list-hidden': isTaskView }">
      <div class="loan-tasks-actions">
        <filter-dropdown
          v-model="filterValue"
          :options="filterOptions"
        />
        <loan-tasks-select-multiple-button v-show="showSubmitMultiple" @click="startMultipleSubmit">
          Submit Multiple
        </loan-tasks-select-multiple-button>
        <template v-if="showInviteBorrower">
          <template v-if="peersuiteSubdomainIsSet">
            <base-button @click="toggleInviteModal" class="invite-borrower" type="text" icon-left="paper-plane">
              Share Tasks
            </base-button>
          </template>
          <template v-else>
            <base-tooltip always :active="showPeersuiteSubdomainIsNotSetTooltip" position="is-left" multilined>
              <base-button
                @click="showPeersuiteSubdomainIsNotSetTooltip = !showPeersuiteSubdomainIsNotSetTooltip"
                class="invite-borrower"
                type="text"
                icon-left="paper-plane"
              >
                Share Tasks
              </base-button>
              <template v-slot:content>
                Please set your <a :href="brandingSettingsUrl">PeerSuite subdomain</a> first.
              </template>
            </base-tooltip>
          </template>
        </template>
      </div>
      <div class="loan-tasks-list" :class="tasksListFilterClass">
        <task-completion-section v-if="showCompletedMessage" />

        <template v-for="group in allTaskGroups">
          <loan-task-section
            v-if="taskSections[group.id].length"
            :key="group.id"
            :header="group.label"
            :tasks="taskSections[group.id]"
            :seen-tasks-ids="seenTasksIds"
            :documents="documents"
            :upload-settings="uploadSettings"
            :multiple-submit="submitMultiple"
            :selected-tasks="selectedTasks"
            :class="{ 'loan-task-section-hidden' : allTasksHidden(taskSections[group.id]) }"
            @on-upload-started="trackTaskListEvent"
            @on-selected-tasks-change="onSelectedTasksChange"
            @open="openTask"
          />
        </template>

        <loan-tasks-select-multiple-section
          v-if="submitMultiple"
          :all-tasks="tasksToSelect"
          :selected-tasks="tasksToTransit"
          :complete-only="true"
          @complete="completeLoanTasks"
          @cancel="stopMultipleSubmit"
          @select-all="selectAll"
          @deselect-all="deselectAll"
        />
      </div>
    </div>
    <loan-documents-drop-to-upload
      v-if="isTaskView"
      class="drop-file-to-upload-task-details"
      :metadata="{ taskId: currentTask.id }"
      :initial-options="uploadSettings"
      @on-upload-started="trackTaskDetailsEvent($event, currentTask)"
    >
      <template slot="dropzone">
        <task-drop-file-to-upload-placeholder />
      </template>

      <loan-task-details
        :task="currentTask"
        :documents="documents"
        @task-completed="completeLoanTask"
        @task-commented="onNewComment"
        @back="openListView"
        @file-upload-click="trackFileUploadClick"
        @document-attachment-click="trackDocumentAttachmentClick"
      />
    </loan-documents-drop-to-upload>
    <documents-attachment-modal :documents="documents" />
    <borrower-invite-modal :show="showInviteBorrowerModal" @on-close="toggleInviteModal" />
  </div>
</template>

<script>
import Ajax from "services/ajax_service";
import { error } from "services/notification";
import toPlainObject from "utils/to_plain_object";
import LoanTaskMilestone from "models/loan_tasks/milestone";
import LoanTasksMixin from "components/activity_feed_events/mixins/loan_tasks_mixin";
import LoanTaskSection from "components/activity_feed_events/loan_tasks/section";
import LoanTaskDetails from "components/activity_feed_events/loan_tasks/details";
import BorrowerInviteModal from "components/activity_feed_events/loan_tasks/borrower_invite_modal";
import DocumentsAttachmentModal from "components/activity_feed_events/documents_attachment_modal";
import LoanDocumentsDropToUpload from "components/loans/documents/drop_to_upload";
import LoanTasksSelectMultipleButton from "components/activity_feed_events/loan_tasks/multiple_selection/select_multiple_button";
import LoanTasksSelectMultipleSection from "components/activity_feed_events/loan_tasks/multiple_selection/select_multiple_section";
import TaskCompletionSection from "components/activity_feed_events/loan_tasks/task_completion_section";
import TaskDropFileToUploadPlaceholder from "components/activity_feed_events/loan_tasks/task_drop_file_to_upload_placeholder";
import SegmentService from "services/segment_service";

export default {
  name: "loan-tasks",
  components: {
    LoanTaskSection,
    LoanTaskDetails,
    DocumentsAttachmentModal,
    LoanDocumentsDropToUpload,
    LoanTasksSelectMultipleButton,
    LoanTasksSelectMultipleSection,
    TaskCompletionSection,
    TaskDropFileToUploadPlaceholder,
    BorrowerInviteModal,
  },
  mixins: [LoanTasksMixin],
  props: {
    documents: Array,
    loan: Object,
    routes: Object,
  },
  data() {
    return {
      showInviteBorrowerModal: false,
      shareTasksEnabled: false,
      peersuiteSubdomainIsSet: false,
      showPeersuiteSubdomainIsNotSetTooltip: false,
      brandingSettingsUrl: undefined,
    };
  },
  created() {
    this.loadTasks();
  },
  watch: {
    "routes.loanTask": {
      handler(newValue, oldValue) {
        if (newValue === oldValue) return;

        this.loadTasks();
        this.openListView();
        this.scrollToFirstTask();
      },
    },
  },
  methods: {
    scrollToFirstTask() {
      this.$refs["loan-tasks-container"].scrollTop = 0;
    },
    completeLoanTask(id) {
      if (this.isMarkingComplete) {
        return;
      }

      const url = `${this.loanTaskRoute}/${id}/complete`;
      const payload = { id: id };

      return this.completeApiCall(url, payload, "Task Submitted", "Unable to submit loan task");
    },
    completeLoanTasks() {
      if (this.isMarkingComplete || !this.selectedTasks.length) {
        return;
      }

      const url = `${this.loanTaskRoute}/bulk_submit`;
      const payload = { tasks_ids: this.selectedTasks };

      return this.completeApiCall(url, payload, "Tasks Submitted", "Unable to submit loan tasks");
    },
    toggleInviteModal() {
      this.showInviteBorrowerModal = !this.showInviteBorrowerModal;
    },
    completeApiCall(url, payload, successMessage, errorMessage) {
      const messages = {
        onSuccess: successMessage,
        onError: ({ response, data }) => {
          if (response.status === 422) {
            return {
              message: data.error,
              status: "error",
            };
          } else {
            error(errorMessage);
          }
        },
      };

      this.isMarkingComplete = true;

      return Ajax.withNotifications(messages)
        .patchJSON(url, payload)
        .then(data => {
          if (data.error) {
            Ajax.sendNotification(data.error, "error");
          } else {
            this.updateTasks(data);
          }
        })
        .catch(({ response }) => {
          Bugsnag.notify(
            { name: "Request Error", message: errorMessage },
            { metaData: { custom: { response: toPlainObject(response), payload } } }
          );
        })
        .finally(() => {
          this.isMarkingComplete = false;
          this.stopMultipleSubmit();
        });
    },
    loadTasks() {
      return Ajax.get(this.loanTaskRoute)
        .then(data => {
          if (data.error) {
            Ajax.sendNotification(data.error, "error");
          } else {
            this.updateTasks(data);
            this.shareTasksEnabled = data.share_tasks_enabled;
            this.peersuiteSubdomainIsSet = data.peersuite_subdomain_is_set;
            this.brandingSettingsUrl = data.branding_settings_url;
            this.displayTaskOnLoad();
          }
        })
        .catch(({ response }) => {
          Bugsnag.notify(
            { name: "Request Error", message: "Unable to load loan tasks" },
            { metaData: { custom: { response: toPlainObject(response) } } }
          );
        })
        .finally(() => {
          this.stopMultipleSubmit();
        });
    },
    updateTasks(data) {
      this.loanTasks = this.prepareTasks(data.tasks);
      this.seenTasksIds = data.seen_tasks_ids;
      this.loanTaskGroups = data.groups.map(group => {
        return new LoanTaskMilestone(group);
      });
    },
    trackTaskListEvent(event, task) {
      SegmentService.track("dropToTaskList", {
        files: event.files.length,
        taskSystemName: task.definition.systemName,
        loanId: this.loan.toParam,
      });
    },
    trackTaskDetailsEvent(event, task) {
      SegmentService.track("dropToTaskDetails", {
        files: event.files.length,
        taskSystemName: task.definition.systemName,
        loanId: this.loan.toParam,
      });
    },
    trackFileUploadClick(event) {
      SegmentService.track("attachFile", {
        action: "Upload...",
        taskSystemName: event.task.definition.systemName,
        loanId: this.loan.toParam,
      });
    },
    trackDocumentAttachmentClick(event) {
      SegmentService.track("attachFile", {
        action: "Attach from Documents",
        taskSystemName: event.task.definition.systemName,
        loanId: this.loan.toParam,
      });
    },
  },
  computed: {
    loanTaskRoute() {
      return this.routes.loanTask;
    },
    showCompletedMessage() {
      if (
        !this.loanTaskGroups.length ||
        this.loan.underwritingStatus === "draft" ||
        !this.filterTasks
      ) {
        return false;
      }

      return this.loanTasks.every(task => task.isConfirmed);
    },
    showInviteBorrower() {
      return this.loan.underwritingStatus != "draft" && this.shareTasksEnabled;
    },
  },
};
</script>
<style scoped>
.loan-tasks-list-hidden {
  opacity: 0;
  position: absolute;
}

.loan-tasks-list-container {
  left: 0;
  height: 100%;
  overflow: scroll;
  top: 0;
}

.invite-borrower {
  align-items: baseline;
  color: #3299ff !important;
}

.invite-borrower span {
  font-size: 12px !important;
}

.invite-borrower:hover {
  color: #3299ff !important;
}
</style>
