<template>
  <td>
    <template v-if="hasFundingTypes">
      <funding-type
        v-for="fundingType in fundingTypes"
        :key="fundingType.noteOrigination"
        :funding-type="fundingType"
      />
    </template>
  </td>
</template>

<script>
import FundingType from "./funding_type.vue";

export default {
  name: "loan-type-cell",
  components: { FundingType },
  props: {
    fundingTypes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    hasFundingTypes() {
      return !!this.fundingTypes.length;
    },
  },
};
</script>
