<template>
  <div>
    <div class="loan-tape-batch-navigation py-3">
      <div class="container">
        <base-breadcrumb :items="breadcrumbItems" />
      </div>
    </div>
    <base-hr class="m-0" />
    <base-header size="medium" class="pt-3 container">
      {{ batchTitle }}
    </base-header>
    <base-hr />
    <base-table
      :data="loans"
      :loading="loading"
      :paginated="true"
      :per-page="10"
      :pagination-simple="true"
      class="container"
    >
      <base-column
        v-slot="props"
        field="name"
        label="Loan"
      >
        {{ props.row.name }}
      </base-column>
      <base-column
        v-slot="props"
        field="status"
        label="Import Status"
        centered
      >
        <loan-status :success="props.row.success" :completed="props.row.completed" />
      </base-column>
      <base-column
        v-slot="props"
        field="errors"
        label="Import Result Details"
      >
        <a v-if="props.row.loanPath" :href="props.row.loanPath">View Loan Details</a>
        <div v-else-if="props.row.errors" class="content">
          Errors:
          <ul>
            <li v-for="(error, index) in props.row.errors" :key="`${error.name}-${index}`">
              <strong>{{ error.name }}</strong> {{ error.message }}
            </li>
          </ul>
        </div>
      </base-column>
      <template v-slot:empty>
        No Loans
      </template>
    </base-table>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import { MANAGE_LOAN_TAPES_BATCHES_ROUTE } from "models/loan_tapes_batch";
import BreadcrumbNavigation from "components/shared/breadcrumb_navigation";
import LoanStatus from "./loan_status";

export default {
  components: {
    BreadcrumbNavigation,
    LoanStatus,
  },
  props: {
    batchId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => {
    return {
      loans: [],
      loading: false,
    };
  },
  computed: {
    batchTitle() {
      return `Batch #${this.batchId} Details`;
    },
    breadcrumbItems() {
      return [
        {
          name: "Import Loans",
          link: MANAGE_LOAN_TAPES_BATCHES_ROUTE,
        },
        {
          name: this.batchTitle,
        },
      ];
    },
  },
  mounted() {
    this.loading = true;
    AjaxService.getJSON(`${MANAGE_LOAN_TAPES_BATCHES_ROUTE}/${this.batchId}`)
      .then(data => {
        this.loans = data.loans || [];
      })
      .catch(() => {
        this.loans = [];
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
<style scoped>
.loan-tape-batch-navigation {
  background: var(--color-grey-06);
}
</style>
