<template>
  <div class="form-group percentage required">
    <label class="control-label required">{{ label }}</label>
    <input
      v-show="showPercentage"
      v-model="innerValue"
      v-mask:percentage="{ round: 2, minus: false }"
      class="form-control string required percentage-or-amount-input"
      type="text"
    >
    <input
      v-show="showAmount"
      v-model="innerValue"
      v-mask:currency="{ round: 2, minus: false }"
      class="form-control string required percentage-or-amount-input"
      type="text"
    >
    <select
      v-model="innerType"
      class="form-control select percentage-or-amount-select"
    >
      <option value="percentage">
        %
      </option>
      <option value="amount">
        $
      </option>
    </select>
  </div>
</template>

<script>
import NumberFormatter from "models/formatters/number_formatter";

export default {
  name: "fee-input",
  props: {
    label: {
      type: String,
      default: () => "",
    },
    value: {
      type: [String, Number],
      default: () => "0.00",
    },
    valueType: {
      type: String,
      default: () => "amount",
    },
  },
  data() {
    return {
      innerValue: this.value,
      innerType: this.valueType,
    };
  },
  computed: {
    showAmount() {
      return this.innerType === "amount";
    },
    showPercentage() {
      return this.innerType === "percentage";
    },
  },
  watch: {
    innerValue() {
      this.$emit("input", {
        value: NumberFormatter.unmaskNumber(this.innerValue),
        valueType: this.innerType,
      });
    },
    innerType() {
      this.$emit("input", {
        value: NumberFormatter.unmaskNumber(this.innerValue),
        valueType: this.innerType,
      });
    },
  },
};
</script>
