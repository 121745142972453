const buildDistributionFees = allFeeTypes => {
  return allFeeTypes
    .filter(({ groups }) => groups.includes("distribution_fees"))
    .reduce((acc, fee) => {
      acc[fee.name] = fee.label;
      return acc;
    }, {});
};

export { buildDistributionFees };
