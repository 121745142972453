<template>
  <div class="at-glance-data">
    <h3 class="details-section-title">
      The MSA at a Glance
      <div v-show="isLoading" class="ps-loader ps-loader-small"></div>
    </h3>
    <div class="details-section-body">
      <table class="analytics-table">
        <thead>
        <tr class="analytics-source">
          <th>
            &nbsp;
          </th>
          <th>
            MSA Median
          </th>
          <th>
            Percentile
          </th>
        </tr>
        </thead>
        <tr class="analytics-source">
          <td class="analytics-name">
            Avg household earnings
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <b>
                {{ table.income | money(0, true) | notAvailable }}
              </b>
            </span>
          </td>
          <td>
            <div v-if="table.isLoading">
              &nbsp;
            </div>
            <div v-else>
              <percentile-bar :value="table.incomeStatePercentile"></percentile-bar>
            </div>
          </td>
        </tr>
        <tr class="analytics-source">
          <td class="analytics-name">
            Avg household investment income
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <b>
                {{ table.investmentIncome | money(0, true) | notAvailable }}
              </b>
            </span>
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <percentile-bar :value="table.investmentIncomeStatePercentile"></percentile-bar>
            </span>
          </td>
        </tr>
        <tr class="analytics-source">
          <td class="analytics-name">
            Avg household retirement income
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <b>
                {{ table.retirementIncome | money(0, true) | notAvailable }}
              </b>
            </span>
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <percentile-bar :value="table.retirementIncomeStatePercentile"></percentile-bar>
            </span>
          </td>
        </tr>
        <tr class="analytics-source">
          <td class="analytics-name">
            Percent of households below poverty line
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <b>
                {{ table.poverty | percent(2, true) | notAvailable }}
              </b>
            </span>
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <percentile-bar :value="table.povertyStatePercentile"></percentile-bar>
            </span>
          </td>
        </tr>
        <tr class="analytics-source">
          <td class="analytics-name">
            Percent of population with college degree
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <b>
                {{ table.education | percent(2, true) | notAvailable }}
              </b>
            </span>
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <percentile-bar :value="table.educationStatePercentile"></percentile-bar>
            </span>
          </td>
        </tr>
        <tr class="analytics-source">
          <td class="analytics-name">
            Percent of workers in high tech jobs
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <b>
                {{ table.highTech | percent(2, true) | notAvailable }}
              </b>
            </span>
          </td>
          <td style="width: 325px">
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <percentile-bar :value="table.highTechStatePercentile"></percentile-bar>
            </span>
          </td>
        </tr>
        <tr class="analytics-source">
          <td class="analytics-name">
            Median home price
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <b>
                {{ table.homeValue | money(0, true) | notAvailable }}
              </b>
            </span>
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <percentile-bar :value="table.homeValueStatePercentile"></percentile-bar>
            </span>
          </td>
        </tr>
        <tr class="analytics-source">
          <td class="analytics-name">
            Median monthly home ownership cost
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <b>
                {{ table.monthlyCostOwn | money(0, true) | notAvailable }}
              </b>
            </span>
          </td>
          <td>
            <span v-if="table.isLoading">
              &nbsp;
            </span>
            <span v-else>
              <percentile-bar :value="table.monthlyCostOwnStatePercentile"></percentile-bar>
            </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import PercentileBar from "components/market_data/percentile_bar";

export default {
  name: "msa-at-a-glance",
  components: {
    PercentileBar,
  },
  props: {
    table: Object,
  },
  computed: {
    isLoading() {
      return this.table.isLoading;
    },
  },
};
</script>
