const STICKY_HEADER_OFFSET = 400;

if (!window.__ps_test_mode) {
  document.addEventListener("DOMContentLoaded", () => {
    let header = document.querySelector(".home-header");
    let content = document.querySelector(".hero-home-content");
    new InitStickyHeader(header, content);
  });
}

class InitStickyHeader {
  constructor(header, content) {
    if (!header || !content) {
      return;
    }

    this.stickyHeaderShown = false;
    const HEADER_HEIGHT = header.offsetHeight;

    window.addEventListener("scroll", () => {
      const showStickyHeader =
        window.pageYOffset >= STICKY_HEADER_OFFSET && !this.stickyHeaderShown;
      const hideStickyHeader =
        window.pageYOffset < STICKY_HEADER_OFFSET &&
        window.pageYOffset > HEADER_HEIGHT &&
        this.stickyHeaderShown;

      if (showStickyHeader) {
        this.showStickyHeader(header, content);
      } else if (hideStickyHeader) {
        this.hideStickyHeader(header, content);
      }
    });
  }

  showStickyHeader(header, content) {
    window.requestAnimationFrame(() => {
      content.classList.add("with-sticky-header");
      header.classList.remove("detached");
      header.classList.add("sticky");
    });

    this.stickyHeaderShown = true;
  }

  hideStickyHeader(header, content) {
    window.requestAnimationFrame(() => {
      header.classList.add("detached");
      setTimeout(function() {
        content.classList.remove("with-sticky-header");
        header.classList.remove("detached", "sticky");
      }, 100);
    });

    this.stickyHeaderShown = false;
  }
}
