class MarketDataEvent extends PS.Models.Event {}

class LenderMarketDataSearchRequested extends MarketDataEvent {
  get source() {
    return this.options.source;
  }

  get zip() {
    return this.options.zip;
  }
}

class CheckForPredefinedSearch extends MarketDataEvent {}

class AllMarketDataRequested extends MarketDataEvent {
  get loanId() {
    return this.options.loan.id;
  }

  get loanZip() {
    return this.options.loan.zip;
  }
}

class TrackMarketDataReportLinkRequested extends MarketDataEvent {
  get source() {
    return this.options.source;
  }

  get zip() {
    return this.options.zip;
  }
}

class TrackMarketDataReportBannerRequested extends MarketDataEvent {
  get zip() {
    return this.options.zip;
  }
}

PS.Models.MarketData.Events = {
  LenderMarketDataSearchRequested: LenderMarketDataSearchRequested,
  CheckForPredefinedSearch: CheckForPredefinedSearch,
  AllMarketDataRequested: AllMarketDataRequested,
  TrackMarketDataReportLinkRequested: TrackMarketDataReportLinkRequested,
  TrackMarketDataReportBannerRequested: TrackMarketDataReportBannerRequested,
};
