import { defineStore } from "pinia";
import { poll } from "poll";
import Notification from "components/loan_scenarios/utils/notification";
import { backoff, sleep } from "utils/time";

const useHistoryStore = defineStore("history", {
  state: () => ({
    runs: [],
    isLoading: false,
  }),
  getters: {
    allRunsComplete: state => !state.runs.some(r => r.inProgress),
  },
  actions: {
    async loadHistory() {
      try {
        this.isLoading = true;
        const result = await this.$api.runs.list();
        this.runs = result.data;
      } catch (error) {
        Notification.error();
      } finally {
        this.isLoading = false;
      }

      if (this.allRunsComplete) return;

      await sleep(1000);
      await poll(
        async () => {
          const result = await this.$api.statuses.list();
          const inProgressIds = result.data;
          this.runs.forEach(r => (r.inProgress = inProgressIds.includes(r.id)));
        },
        2000,
        () => this.allRunsComplete
      );
    },
  },
});

export default useHistoryStore;
