<template>
  <div class="details-row row">
    <div class="col-12">
      <h4>Credits</h4>
    </div>
    <div class="col-4">
      <editable-field
        label="Current Lender B-Piece"
        :value="statement.bPieceAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div class="col-4">
      <editable-field
        label="Remaining Other Reserve"
        :value="statement.otherReserveAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div class="col-4" />
    <div class="col-4">
      <editable-field
        label="Remaining Construction Reserve"
        :value="statement.constructionReserveAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div class="col-4">
      <editable-field
        label="Waived Principal, Interest and Fees"
        :editing="isEditable"
        :value="statement.waivedPrincipalInterestFeesAmount"
        currency
      />
    </div>
    <div class="col-4" />
    <div class="col-4">
      <editable-currency-field
        label="Escrow Reserve"
        :value="statement.escrowReserveAmount"
        :callback="updateEditableField('escrowReserveAmount', statement)"
        currency
      />
    </div>
    <div class="col-4">
      <editable-currency-field
        v-if="isEstimatePayoffQuote"
        label="Suspense Balance"
        :value="statement.suspenseBalance"
        :callback="updateEditableField('suspenseBalance', statement)"
        currency
      />
    </div>
  </div>
</template>

<script>
import EditableField from "components/shared/editable_field.vue";
import EditableCurrencyField from "components/shared/editable_currency_field";
import EditPartialMixin from "components/manage/payoff_statement/edit/edit_partial_mixin";

export default {
  name: "resale-statment-credits",
  components: { EditableCurrencyField, EditableField },
  mixins: [EditPartialMixin],
  props: ["isEditable", "statement"],
  computed: {
    isEstimatePayoffQuote() {
      return this.statement.statementType === "estimate_payoff_quote";
    },
  },
};
</script>
