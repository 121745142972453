<template>
  <mfe-connect
    :service-url="loanScenariosServiceHost"
    application-name="borrower_leads"
    :props="props"
  />
</template>

<script>
import MfeConnect from "components/mfe_connector";

export default {
  components: { MfeConnect },
  data() {
    return {
      loanScenariosServiceHost: `${window.location.protocol}//${PSData.loanScenariosServiceHost}`,
      props: {
        lenderSiteHost: `${PSData.borrowerPortalUrl}`,
        baseUrl: `${PSData.borrowerLeadsBaseUrl}`,
        brandingSettingsUrl: `${PSData.brandingSettingsUrl}`,
      },
    };
  },
};
</script>
