<template>
  <div class="asset-management-table asset-management-table-fees">
    <subsection-header>Added Fees</subsection-header>
    <table class="table">
      <thead>
        <tr>
          <th>Fee</th>
          <th>Amount</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <row
          v-for="fee in fees"
          :key="fee.key"
          :item="fee"
          :options="options"
          @change="updateRow(fee.key, $event)"
          @delete="deleteRow(fee.key)"
        />
        <tr>
          <td colspan="4">
            <span class="action-link" @click="addNewFee">+ Add Fee</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import uniqueId from "lodash/uniqueId";
import Row from "./modification_fees_table_form/row";
import SubsectionHeader from "../components/subsection_header";

export default {
  components: {
    Row,
    SubsectionHeader,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      feeTypes:
        PSData.loanModificationFeeTypes.sort((a, b) => a.label.localeCompare(b.label)) || [],
      fees: this.extractList(this.item.fees || []),
    };
  },
  watch: {
    fees: {
      handler(newList) {
        this.$emit("change", newList);
      },
      deep: true,
    },
  },
  methods: {
    extractList(fees) {
      return fees.map(fee => ({ ...fee, key: uniqueId() }));
    },
    addNewFee() {
      this.fees.push({ feeTypeId: null, key: uniqueId(), amount: 0 });
    },
    updateRow(key, value) {
      const index = this.fees.findIndex(fee => fee.key === key);

      if (index !== -1) {
        Object.assign(this.fees[index], value);
      }
    },
    deleteRow(key) {
      const index = this.fees.findIndex(fee => fee.key === key);
      this.fees.splice(index, 1);
    },
  },
  computed: {
    options() {
      return this.feeTypes.map(fee => {
        return {
          id: fee.feeTypeId,
          text: fee.label,
          defaultAmount: fee.defaultAmount || 0,
          name: fee.name,
        };
      });
    },
  },
};
</script>
