<template>
  <base-tag :class="`badge-${badgeColor}`" size="small" type="none">
    <slot />
  </base-tag>
</template>
<script>
export default {
  props: {
    badgeColor: {
      type: String,
      default: "light-grey",
      validator: prop => ["light-grey", "grey", "black", "blue"].includes(prop),
    },
  },
};
</script>
<style scoped>
.badge-light-grey {
  color: var(--color-grey-86);
  background-color: var(--color-grey-06);
}
.badge-grey {
  color: var(--color-white);
  background-color: var(--color-grey-38);
}
.badge-black {
  color: var(--color-white);
  background-color: var(--color-grey-86);
}
.badge-blue {
  color: var(--color-white);
  background-color: var(--color-blue-hover);
}
</style>
