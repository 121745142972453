<template>
  <div class="rules col-md-9">
    <h2>{{ currentInvestor.label }}</h2>
    <span class="subheader">Distribution Rules</span>
    <div>
      <table class="table">
        <thead>
          <tr>
            <th class="text-left">
              Status
            </th>
            <th class="text-left">
              Distribution Fee
            </th>
            <th class="text-left">
              Split Type
            </th>
            <th class="text-right">
              % to Entity
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="record in records" :key="record.id">
            <td class="text-left status" :class="record.status">
              <span class="status-block">{{ record.status }}</span>
            </td>
            <td class="text-left">
              {{ feeType(record) }}
              <i
                v-if="record.feeTypeDisabled"
                v-tooltip
                title="Distribution fee is disabled"
                class="fas fa-exclamation-triangle"
              />
            </td>
            <td class="text-left">{{ splitType(record) }}</td>
            <td class="text-right">{{ (record.investorFactor * 100) | percent }}</td>
            <td v-if="policy.canAddRule()" class="text-right" title="Edit" @click="editRule(record)">
              <i class="fas fa-pencil-alt" />
            </td>
            <td v-else></td>
          </tr>
        </tbody>
      </table>
      <span v-if="policy.canAddRule()" class="add-button" @click="showModal">+ Add Distribution Rule</span>
    </div>
    <modal
      v-if="isModalShown"
      v-bind="{ currentInvestor, rule }"
      @saved="onSave"
      @close="closeModal"
    />
  </div>
</template>
<script>
import Modal from "./modal";
import Policy from "./../policy";

export default {
  components: { Modal },
  props: {
    currentInvestor: {
      type: Object,
      required: true,
    },
    records: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { isModalShown: false, rule: undefined, policy: new Policy(PSData.policy) };
  },
  methods: {
    editRule(rule) {
      this.rule = rule;
      this.showModal();
    },
    onSave(data) {
      if (this.rule) {
        this.$emit("updated", data);
      } else {
        this.$emit("created", data);
      }

      this.closeModal();
    },
    showModal() {
      this.isModalShown = true;
    },
    closeModal() {
      this.isModalShown = false;
      this.rule = undefined;
    },
    feeType(record) {
      return record.feeType?.label || "Default Interest";
    },
    splitType(record) {
      if (record.spreadFactor) {
        return "Investor Spread";
      } else {
        return "Investor Split";
      }
    },
  },
};
</script>
