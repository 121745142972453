<template>
  <div class="details-row row">
    <div class="col-12">
      <h4>Credits</h4>
    </div>
    <div class="col-4" v-if="statement.servicingReleased">
      <div class="editable-field-container">
        <editable-currency-field
          label="Remaining Construction Reserve"
          :value="statement.constructionReserveAmount"
          :callback="updateEditableField('constructionReserveAmount', statement)"
        />
      </div>
    </div>
    <div class="col-4" v-if="statement.servicingReleased">
      <div class="editable-field-container">
        <editable-currency-field
          label="Remaining Other Reserve"
          :value="statement.otherReserveAmount"
          :callback="updateEditableField('otherReserveAmount', statement)"
        />
      </div>
    </div>
    <div class="col-4">
      <div class="editable-field-container">
        <editable-currency-field
          label="Credits"
          :value="statement.payoffStatementCreditAmount"
          :callback="updateEditableField('payoffStatementCreditAmount', statement)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EditableCurrencyField from "components/shared/editable_currency_field.vue";
import EditableField from "components/shared/editable_field.vue";
import EditPartialMixin from "components/manage/payoff_statement/edit/edit_partial_mixin";

export default {
  name: "repurchase-statment-credits",
  components: { EditableCurrencyField, EditableField },
  mixins: [EditPartialMixin],
  props: ["isEditable", "statement"],
};
</script>
