import * as filestack from "filestack-js";
import AjaxService from "services/ajax_service";

export const SOURCES = Object.freeze([
  "local_file_system",
  "box",
  "dropbox",
  "googledrive",
  "onedrive",
  "onedriveforbusiness",
]);

export const PROVIDERS = Object.freeze({
  filestack: "Filestack::S3",
});

export function getFilestackClient(apiKey, uploadSettings) {
  const cacheKey = uploadSettings.refreshUrl;
  let client = CLIENTS_CACHE[cacheKey];

  if (!client) {
    client = CLIENTS_CACHE[cacheKey] = {
      instance: filestack.init(apiKey, { security: uploadSettings.security }),
      options: uploadSettings,
    };

    client.instance.on("upload.error", error => {
      if (
        error.type === "request" &&
        error.details.code === 403 &&
        error.details.data.error.match(/signature has expired/)
      ) {
        Bugsnag.leaveBreadcrumb("filestack:settings_expired");

        refreshUploadSettings(client);
      }
    });

    scheduleUploadSettingsRefresh(client);
  }

  return client.instance;
}

export function generateFilePickerOptions({ type, id, folder, extensions, multiple }, sources) {
  const options = {
    fromSources: sources,
    maxFiles: multiple === undefined ? 1 : 1000,
    storeTo: { path: `/${type}/${id}/${folder}/` },
    uploadInBackground: false,
    onFileSelected(file) {
      Bugsnag.leaveBreadcrumb("filestack:selected", { ...file });
    },
    onUploadStarted(selectedFiles) {
      Bugsnag.leaveBreadcrumb(
        "filestack:started",
        selectedFiles.reduce((files, file, idx) => {
          files[idx] = file.originalPath;
          return files;
        }, {})
      );
    },
  };

  if (extensions !== undefined) {
    options.accept = extensions.split(",");
  }

  return options;
}

const CLIENTS_CACHE = {};

function refreshUploadSettings(client) {
  return AjaxService.get(client.options.refreshUrl).then(newOptions => {
    client.options = newOptions;
    client.instance.setSecurity(newOptions.security);
  });
}

function scheduleUploadSettingsRefresh(client) {
  const expiresIn = client.options.expiration * 1000 - Date.now();
  const expireThreshold = 15000;

  setTimeout(() => {
    refreshUploadSettings(client).then(() => {
      return scheduleUploadSettingsRefresh(client);
    });
  }, expiresIn - expireThreshold);
}
