<template>
  <div>
    <base-dropdown class="template-dropdown" type="none" label="workflow templates">
      <base-dropdown-item
        v-for="template in workflowTemplates"
        :key="`${template.name}-${template.revision}`"
        @click="setActiveWorkflowTemplate(template)"
        >{{ template.text }}
        <span class="enabled-text"
          >(revision {{ template.revision }}{{ template.enabled ? "" : ", disabled" }})</span
        >
      </base-dropdown-item>
    </base-dropdown>
    <base-button type="primary" class="is-pulled-right" @click="showNewWorkflowModal"
      >+ New Workflow Template</base-button
    >
    <hr />
    <div class="header-wrapper">
      <base-header class="mr-2" v-if="currentTemplate">{{ currentTemplate.text }}</base-header>
      <base-button
        v-if="currentTemplate"
        size="small"
        label="+ New task"
        @click="setActiveTask(newTask)"
      />
    </div>
    <base-switch
      v-if="currentTemplate"
      :value="currentTemplate.enabled"
      :native-value="currentTemplate.enabled"
      @click="toggleEnabled"
    >
      Enabled
    </base-switch>
    <base-table v-if="currentTemplateTasks.length > 0" :data="currentTemplateTasks">
      <base-column width="83px" v-slot="props">
        <base-button @click="setActiveTask(props.row)">
          <base-icon icon="pencil-alt" />
        </base-button>
      </base-column>
      <base-column 
        v-slot="props" 
        field="sequence" 
        label="sequence" 
        width="100px"
        sortable
      >
        {{ props.row.sequence }}
      </base-column>
      <base-column 
        v-slot="props" 
        field="label" 
        label="label" 
        width="250px"
        searchable
      >
        {{ props.row.label }}
      </base-column>
      <base-column 
        v-slot="props" 
        field="description" 
        label="description" 
        searchable
      >
        <markdown-view v-if="props.row.description !== null" :value="props.row.description" />
      </base-column>
      <base-column 
        v-slot="props"  
        field="enabled" 
        label="enabled" 
        numeric
        sortable
      >
        {{ props.row.enabled }}
      </base-column>
    </base-table>
    <task-form
      :show="showTaskModal"
      :task="activeTask"
      @close="closeModal"
      @new-task-template-saved="addNewTaskTemplate"
      @task-template-updated="updateTaskTemplate"
    />
    <workflow-form
      :show="showWorkflowModal"
      @close="closeModal"
      @new-workflow-template-saved="addNewWorkflowTemplate"
    />
  </div>
</template>

<script>
import TaskForm from "./task_form";
import WorkflowForm from "./workflow_form";
import MarkdownView from "components/shared/markdown_view";
import Ajax from "services/ajax_service";

export default {
  components: { TaskForm, MarkdownView, WorkflowForm },
  data() {
    return {
      workflowTemplates: PSData.workflowTemplates,
      currentTemplate: null,
      currentTemplateTasks: [],
      activeTask: {},
      showTaskModal: false,
      showWorkflowModal: false,
      newTask: {
        id: null,
        label: null,
        description: null,
        sequence: null,
        enabled: true,
        assetManagementWorkflowTemplateId: null,
      },
    };
  },
  methods: {
    setNewTaskData() {
      const sequences = this.currentTemplateTasks.map(taskTemplate => taskTemplate.sequence);
      const nextSequence = Math.max(...sequences, 0) + 10;
      this.newTask.sequence = nextSequence;
      this.newTask.assetManagementWorkflowTemplateId = this.currentTemplate.id;
    },
    setActiveWorkflowTemplate(template) {
      this.currentTemplate = template;
      this.currentTemplateTasks = template.taskTemplates;
      this.setNewTaskData();
    },
    setActiveTask(task) {
      this.activeTask = { ...task };
      this.showTaskModal = true;
    },
    closeModal() {
      this.showTaskModal = false;
      this.showWorkflowModal = false;
    },
    showNewWorkflowModal() {
      this.showWorkflowModal = true;
    },
    addNewWorkflowTemplate(newTemplate) {
      this.workflowTemplates.push(newTemplate);
      this.setActiveWorkflowTemplate(newTemplate);
    },
    addNewTaskTemplate(newTemplate) {
      this.currentTemplateTasks.push(newTemplate);
      this.setNewTaskData();
    },
    updateTaskTemplate(updatedTemplate) {
      this.currentTemplateTasks = this.currentTemplateTasks.map(task => {
        if (task.id === updatedTemplate.id) {
          return updatedTemplate;
        } else {
          return task;
        }
      });
      this.setNewTaskData();
    },
    toggleEnabled() {
      const enabled = this.currentTemplate.enabled;
      const actionText = enabled ? "disable" : "enable";
      const messages = {
        onError: ({ response }) => {
          if (response.status === 403) {
            return { message: "Unauthorized", status: "error" };
          } else {
            return { message: "Invalid Parameters", status: "error" };
          }
        },
      };
      if (window.confirm(`Are you sure you want to ${actionText} this template?`)) {
        Ajax.withNotifications(messages)
          .patchJSON(`/manage/asset_management_workflow_templates/${this.currentTemplate.id}`, {
            enabled: !enabled,
          })
          .then(data => {
            this.currentTemplate = data;
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.template-dropdown {
  margin-right: 100px;
}

.markdown-content {
  word-break: break-word;
}

.header-wrapper {
  display: flex;
  align-items: center;
}

.enabled-text {
  color: var(--color-grey-72);
  font-size: 12px;
}
</style>
