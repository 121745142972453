<template>
  <div class="drag-to-upload">
    <div
      ref="dropPane"
      class="drop-zone"
      :class="{ active: dropZoneActive }"
    >
      <div class="drop-zone-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import * as filestack from "filestack-js";
import get from "lodash/get";

const ERRORS_TIMEOUT = 3000;

export default {
  name: "drag-to-upload",
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    initialOptions: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      apiKey: get(PSData, "global.filepickerApiKey"),
      options: this.initialOptions,
      visible: false,
      provider: "Filestack::S3",
      filesUploaded: [],
      filesFailed: [],
      dropZoneActive: false,
    };
  },
  computed: {
    clientOptions() {
      const { security } = this.options;

      return { security };
    },
    pickerOptions() {
      const { type, id, folder, extensions } = this.options;

      const options = {
        container: this.$refs.dropPane,
        displayMode: "dropPane",
        storeTo: { path: `/${type}/${id}/${folder}/` },
        uploadInBackground: false,
        errorsTimeout: ERRORS_TIMEOUT,
        dropPane: {
          customText: "Drag File Here",
          showIcon: false,
          overlay: false,
          onSuccess: this.uploadFiles,
          onDragOver: this.onDragOver,
          onDragLeave: this.onDragLeave,
          onDrop: this.onDrop,
          disableClick: true,
        },
      };

      if (extensions !== undefined) {
        options.accept = extensions.split(",");
      }

      return options;
    },
  },
  created() {
    this.initFilePickerClient();
  },
  methods: {
    onDrop(e) {
      e.preventDefault();
      if (e.dataTransfer.items.length > 1) {
        this.timer = setTimeout(() => (this.dropZoneActive = false), ERRORS_TIMEOUT);
      }
    },
    onDragOver() {
      if (this.timer) clearTimeout(this.timer);
      this.dropZoneActive = true;
    },
    onDragLeave() {
      this.dropZoneActive = false;
    },
    initFilePickerClient() {
      if (!this.enabled) return;

      return new Promise(resolve => {
        if (Date.now() / 1000 < this.options.expiration) {
          resolve(this.options);
        } else {
          PS.Services.AjaxService.get(this.refreshUploadSettingsUrl).then(resolve);
        }
      }).then(options => {
        this.options = options;
        this.client = filestack.init(this.apiKey, this.clientOptions);
        this.client.picker(this.pickerOptions).open();
      });
    },
    uploadFiles(blobs) {
      this.dropZoneActive = false;

      if (blobs.length === 0) {
        Bugsnag.notify(
          { name: "FileStackError", message: "filesUploaded is blank" },
          {
            severity: "error",
            metaData: {
              custom: { filesUploaded: blobs.filesUploaded, filesFailed: blobs.filesFailed },
            },
          }
        );

        return this.$emit("on-upload-error", "Upload failed. Try again.");
      }

      return this.$emit(
        "on-upload-success",
        blobs.map(blob => {
          return { provider: this.provider, data: blob };
        })
      );
    },
  },
};
</script>
