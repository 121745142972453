<template>
  <base-modal-form
    :show="show"
    title="Send Initial Disclosures"
    :save-disabled="isSaving"
    class="disclosure-modal"
    @close="onClose"
    @save="onSave"
    saveButtonName="Send"
    :cancel-options="cancelOptions"
  >
    <p>
      Any individual whose credit will be pulled should be added as a signer.
      Create a 6-digit pass code that signers must enter to access disclosures.
      You must then share the pass code via phone or text with your signer upon sending the disclosures.
    </p>
    <form>
      <div class="columns">
        <label class="label column is-3">Borrower First Name</label>
        <label class="label column is-3">Borrower Last Name</label>
        <label class="label column is-3">Signer Email</label>
        <label class="label column is-2">6-Digit Pass Code</label>
      </div>
      <div
        v-for="(recipient, index) in recipients"
        :key="`recipient-${index}`"
        class="columns recipient"
      >
        <base-text-field
          :id="'first-name-' + index"
          v-model="recipient.first_name"
          :rules="'required'"
          :errors="errors.recipient"
          type="text"
          name="first_name"
          label="First Name"
          class="column is-3"
        />
        <base-text-field
          :id="'last-name-' + index"
          v-model="recipient.last_name"
          :rules="'required'"
          :errors="errors.recipient"
          type="text"
          name="last_name"
          label="Last Name"
          class="column is-3"
        />
        <base-text-field
          :id="'email-' + index"
          v-model="recipient.email"
          :rules="'required|email'"
          :errors="errors.recipient"
          type="text"
          name="email"
          label="Signer Email"
          class="column is-3"
        />
        <base-text-field
          :id="'passcode-' + index"
          v-model="recipient.passcode"
          :rules="'required|numeric|length:6'"
          :errors="errors.passcode"
          maxlength="6"
          type="text"
          name="passcode"
          label="6-Digit Pass Code"
          class="column is-2"
        />
        <base-button
          v-if="index > 0"
          @click="deleteRecipient(index)"
          type="icon"
          size="normal"
          icon="trash-alt"
          class="btn-icon delete-button column is-1"
        />
      </div>
      <a href="#" v-if="canAddRecipient" @click.prevent="addRecipient">+ Add Email Recipient</a>
    </form>
    <template v-slot:footer>
      <base-button @click="onBack">Back</base-button>
    </template>
  </base-modal-form>
</template>

<script>
import AjaxService from "services/ajax_service";
import GlobalBusService from "services/global_bus_service";
import uniqBy from "lodash/uniqBy";

const MAX_RECIPIENTS = 4;

export default {
  name: "send-disclosure-modal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    loan: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      routes: PSData.routes,
      errors: {},
      isSaving: false,
      recipients: [{ first_name: "", last_name: "", email: "", passcode: "" }],
      cancelOptions: ["escape", "x"],
    };
  },
  computed: {
    canAddRecipient() {
      return this.recipients.length < MAX_RECIPIENTS + 1;
    },
  },
  created() {
    this.reset();
  },
  methods: {
    onClose() {
      this.reset();
      this.errors = {};
      this.$emit("close");
    },
    reset() {
      this.recipients = [{}];
    },
    onSave() {
      this.save();
    },
    onBack() {
      this.onClose();
      GlobalBusService.$emit("open-prepare-disclosure");
    },
    addRecipient() {
      this.recipients.push({ first_name: "", last_name: "", email: "", passcode: "" });
    },
    deleteRecipient(index) {
      this.recipients.splice(index, 1);
    },
    save() {
      const messages = {
        onSuccess: "Initial disclosures sent successfully",
        onError: this.onError,
      };

      this.isSaving = true;

      const recipients = uniqBy(this.recipients, "email");

      const payload = {
        disclosure: {
          recipients: recipients,
        },
        id: this.loan.toParam,
      };

      return this.createDisclosure(messages, payload)
        .then(data => {
          GlobalBusService.$emit("disclosure-sent");
          this.onClose();
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    createDisclosure(messages, payload) {
      return AjaxService.withNotifications(messages).post(this.routes.loanDisclosures, payload, {
        "content-type": "application/json",
      });
    },
    onError({ response, data }) {
      if (response.status === 422) {
        this.errors = data.errors;
      } else {
        this.errors = data.errors || {};
        return null;
      }
    },
  },
};
</script>
<style scoped>
.disclosure-modal >>> .modal-card {
  min-width: 960px;
}

.disclosure-modal >>> .modal-card-body {
  padding-bottom: 16px;
}

.disclosure-modal p {
  margin-bottom: 16px;
}

.disclosure-modal .recipient {
  padding-left: 0;
  padding-top: 0;
  position: relative;
}
.disclosure-modal .btn-icon {
  color: var(--color-grey-86);
  position: relative;
  top: 10px;
}

.disclosure-modal .label {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: var(--color-grey-86);
  display: inline-flex;
  margin-bottom: 0;
}

.disclosure-modal .recipient >>> .base-field-label {
  display: none;
}

.disclosure-modal .recipient >>> .base-field {
  padding-bottom: 30px;
}

.disclosure-modal >>> .modal-card-foot .base-button.is-normal:not(.is-primary) {
  color: var(--color-grey-72);
}
</style>
