<template>
  <modal-view
    :show="show"
    :on-close="close"
    class="base-modal"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          Generate Template Document
          <a href="#close" @click.prevent="close">&times;</a>
        </h4>
        <span class="subheader">Choose a document template to generate and edit using Google Docs</span>
      </div>

      <div class="modal-body">
        <form @keypress.enter="submit">
          <div class="row">
            <div class="col-12 form-group">
              <label>Search Document Name</label>
              <input
                v-model="searchText"
                class="form-control"
                placeholder="Enter document name or URL"
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12 form-group foreclosure-document-template-list">
              <TemplateList v-model="selectedTemplateId">
                <template slot-scope="{setSelected, isSelected}">
                  <TemplateListItem
                    v-for="{id, text} in filteredDocumentTemplates"
                    :key="id"
                    :label="text"
                    :selected="isSelected(id)"
                    @click="setSelected(id)"
                  />
                </template>
              </TemplateList>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button class="btn btn-link" @click.prevent="close">
          Cancel
        </button>
        <button
          :class="{ disabled: noSelection }"
          class="btn btn-action-cta"
          @click.prevent="generate"
        >
          Generate Document
        </button>
      </div>
    </div>
  </modal-view>
</template>

 <script>
import ModalView from "components/modal_view";
import TemplateList from "./template-list";
import TemplateListItem from "./template-list-item";

export default {
  name: "foreclosure-document-modal",
  components: {
    ModalView,
    TemplateList,
    TemplateListItem,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      searchText: "",
      selectedTemplateId: null,
    };
  },
  computed: {
    initialDocumentTemplates: () => PSData.loanForeclosureDocumentTemplates || [],
    filteredDocumentTemplates() {
      const regex = RegExp(this.searchText, "i");
      return this.initialDocumentTemplates.filter(dt => regex.test(dt.text));
    },
    noSelection() {
      return this.selectedTemplateId === null;
    },
  },
  methods: {
    close() {
      this.searchText = "";
      this.selectedTemplateId = null;
      this.$emit("close");
    },
    generate() {
      if (this.selectedTemplateId != null) {
        this.$emit("generate", this.selectedTemplateId);
        this.searchText = "";
        this.selectedTemplateId = null;
      }
    },
  },
};
</script>
