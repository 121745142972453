<template>
  <div class="offering-details">
    <div><div class="offering-details-field-name">Loan ID:</div><span> {{offering.loanPsId}}</span></div>
    <div><div class="offering-details-field-name">Property Type:</div><span> {{offering.loanPropertyTypeText}}</span></div>
    <div><div class="offering-details-field-name">PS Allocation:</div><span> {{offering.psAllocation | money}}</span></div>
    <div><div class="offering-details-field-name">Investor Rate:</div><span> {{offering.loanInvestorRate | percent(3)}}</span></div>
    <div><div class="offering-details-field-name">LTV:</div><span> {{offering.loanLtv | percent(3)}}</span></div>
    <div><div class="offering-details-field-name">Allocated Days:</div><span> {{offering.daysAllocated}}</span></div>
    <div><div class="offering-details-field-name">Review:</div><span> {{offering.reviewText}}</span></div>
  </div>
</template>
<script>
export default {
  name: "offering-details",
  props: {
    offering: Object,
  },
};
</script>
