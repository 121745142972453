<template>
  <div class="document-tracking-container">
    <notification-group
      :message-groups="bannerMessage"
    />
    <div class="incomplete-task-section">
      <physical-docs-tracking-section
        :can-edit="loanEditingEnabled"
        @set-tracking-numbers="setTrackingNumbers"
        @load-tracking-numbers="loadTrackingNumbers"
        @update-docs-tracking-number="updateDocsTrackingNumber"
        @delete-docs-tracking-number="deleteDocsTrackingNumber"
      />
    </div>
    <div class="incomplete-task-section">
      <closing-agent-info-section :for-lender="false" :can-edit="loanEditingEnabled" />
    </div>
    <div class="document-tracking-header">
      <institution-details :loan="loan" />
      <tracking-document-actions
        v-if="canEdit"
        :toggle-edit-mode="toggleEditMode"
        :edit-mode="editMode"
        :updating="updating"
        :on-update="createEvents"
        :on-cancel="cancel"
        :on-print-cover-sheet="onPrintCoverSheet"
      />
    </div>
    <show-document-tracking-status
      v-if="!editMode"
      :tracking-documents="trackingDocuments"
      :selected-document="selectedDocument"
      @on-select="setSelectedDocument"
    />
    <edit-document-tracking-status
      v-if="editMode"
      :tracking-documents="trackingDocuments"
      :tracking-numbers="trackingNumbers"
      :add-tracking-event-to-create="addTrackingEventsToCreate"
      :tracking-event-types="trackingEventTypes"
      @update-doc-tracking-number="updateDocTrackingNumber"
    />
    <span class="add-new-doc-button" @click="toggleNewDocumentModal">+ Add New Document</span>
    <div class="upload-files-container">
      <drag-to-upload
        :enabled="canEdit"
        :initial-options="uploadSettings"
        @on-upload-success="fileDropped"
      >
        <div class="drag-n-drop-container">
          Drag File Here
        </div>
      </drag-to-upload>
      <loans-documents-preview
        v-if="displayPreview"
        :document="selectedDocument.currentFile"
      />
      <div v-else-if="displayPreviewAlert" class="preview-alert notification-message alert-info justify-content-center">
        <p>Preview is unavailable. File is deleted or has not been uploaded to Box.</p>
      </div>
    </div>
    <new-document-modal
      :show="showNewDocumentModal"
      @on-save="documentCreated"
      @on-close="toggleNewDocumentModal"/>
    <upload-modal
      v-if="canEdit"
      :filename="fileName"
      :modal-open="modalOpen"
      @close-modal="modalOpen=false"
      @upload-form-submitted="handleSubmit"
    />
  </div>
</template>

<script>
import DragToUpload from "components/manage/document_tracking/drag_to_upload";
import UploadModal from "components/manage/document_tracking/upload_modal";
import ShowDocumentTrackingStatus from "components/manage/document_tracking/show.vue";
import EditDocumentTrackingStatus from "components/manage/document_tracking/edit.vue";
import TrackingDocumentActions from "components/manage/document_tracking/actions.vue";
import InstitutionDetails from "components/manage/document_tracking/institution_details.vue";
import PhysicalDocsTrackingSection from "components/physical_docs_tracking_numbers/section";
import ClosingAgentInfoSection from "components/closing_agent/info_section";
import NewDocumentModal from "components/manage/document_tracking/new_document_modal.vue";
import NotificationGroup from "components/shared/notification_group";
import LoansDocumentsPreview from "components/loans/documents/preview";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import { objectToSnakeCaseDeep } from "utils/object_to_snake_case";

const Ajax = PS.Services.AjaxService;

export default {
  components: {
    DragToUpload,
    UploadModal,
    ShowDocumentTrackingStatus,
    EditDocumentTrackingStatus,
    TrackingDocumentActions,
    InstitutionDetails,
    PhysicalDocsTrackingSection,
    ClosingAgentInfoSection,
    NewDocumentModal,
    NotificationGroup,
    LoansDocumentsPreview,
  },
  data: function() {
    const trackingDocuments = PSData.trackingDocuments || [];
    const initialDocs = this.buildInitialDocs(trackingDocuments);
    const loan = PSData.loan || {};
    const documentBatch = PSData.documentBatch || {};
    const message = documentBatch.inTransitMessage ? [documentBatch.inTransitMessage] : undefined;

    return {
      loanId: PSData.documentTrackingLoanId,
      canEdit: PSData.documentTrackingCanEdit,
      editMode: false,
      updating: false,
      trackingEventsToCreate: {},
      trackingEventTypes: PSData.documentTrackingEventTypes || [],
      trackingNumbers: [],
      initialDocs,
      trackingDocuments,
      loan,
      showNewDocumentModal: false,
      loanEditingEnabled: null,
      bannerMessage: message,
      uploadSettings: get(PSData, "trackingDocumentUploadSettings", {}),
      fileName: "",
      modalOpen: false,
      file: undefined,
      saveUploadedFilesUrl: get(PSData, "routes.uploadUrl"),
      error: undefined,
      selectedDocument: undefined,
    };
  },
  mounted() {
    this.loadPolicy();
  },
  computed: {
    displayPreview() {
      return !!get(this.selectedDocument, "currentFile");
    },
    displayPreviewAlert() {
      return !!this.selectedDocument && !get(this.selectedDocument, "currentFile");
    },
  },
  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    cancel() {
      this.toggleEditMode();
      this.trackingEventsToCreate = {};
      this.trackingDocuments.forEach(trackingDocument => {
        if (this.initialDocs[trackingDocument.id]) {
          Object.assign(trackingDocument, this.initialDocs[trackingDocument.id]);
        }
      });
    },
    toggleNewDocumentModal() {
      this.showNewDocumentModal = !this.showNewDocumentModal;
    },
    addTrackingEventsToCreate({ documentId, trackingEventTypeId }) {
      if (this.initialDocs[documentId].eventTypeId === trackingEventTypeId) {
        delete this.trackingEventsToCreate[documentId];
        return;
      }
      this.trackingEventsToCreate[documentId] = trackingEventTypeId;
    },
    createEvents() {
      const documentData = this.formatDocumentData();
      if (documentData.events.length === 0 && documentData.documents.length === 0) return;

      this.updating = true;

      Ajax.patch(`/manage/loans/${this.loanId}/document_tracking`, documentData, {
        "Content-Type": "application/json",
      })
        .then(response => {
          this.handleResponse(response);
        })
        .catch(error => {
          Bugsnag.notify(
            { name: "Request Error", message: "Unable to create tracking events" },
            { metaData: { xhr: error } }
          );
        })
        .finally(() => {
          this.updating = false;
        });
    },
    loadTrackingNumbers(trackingNumbers) {
      this.trackingNumbers = trackingNumbers;
    },
    setTrackingNumbers(trackingNumbers) {
      this.loadTrackingNumbers(trackingNumbers);
      this.refreshDocuments();
    },
    updateDocTrackingNumber(trackingNumberId, documentId) {
      this.trackingDocuments = this.trackingDocuments.map(doc => {
        if (doc.id == documentId) {
          doc.trackingNumberId = trackingNumberId;
        }
        return doc;
      });
    },
    updateDocsTrackingNumber(trackingNumber, trackingNumbers) {
      this.trackingDocuments = this.trackingDocuments.map(doc => {
        if (doc.trackingNumberId == trackingNumber.id) {
          doc.trackingNumber = trackingNumber.trackingNumber;
        }
        return doc;
      });

      this.loadTrackingNumbers(trackingNumbers);
    },
    deleteDocsTrackingNumber(trackingNumber, trackingNumbers) {
      this.trackingDocuments = this.trackingDocuments.map(doc => {
        if (doc.trackingNumberId == trackingNumber.id) {
          doc.trackingNumber = undefined;
          doc.trackingNumberId = undefined;
        }
        return doc;
      });

      this.loadTrackingNumbers(trackingNumbers);
    },
    refreshDocuments() {
      this.updating = true;

      Ajax.get(`/manage/loans/${this.loanId}/document_tracking`, {
        "Content-Type": "application/json",
      })
        .then(response => {
          this.handleResponse(response);
        })
        .catch(error => {
          Bugsnag.notify(
            { name: "Request Error", message: "Unable to refresh documents" },
            { metaData: { xhr: error } }
          );
        })
        .finally(() => {
          this.updating = false;
        });
    },
    documentCreated(response) {
      this.handleResponse(response);
      this.toggleNewDocumentModal();
    },
    handleResponse(response) {
      this.trackingEventsToCreate = {};
      this.editMode = false;
      this.trackingDocuments = response.tracking_documents;
      this.initialDocs = this.buildInitialDocs(this.trackingDocuments);
    },
    buildInitialDocs(rawDocs) {
      return rawDocs.reduce((acc, trackingDocument) => {
        acc[trackingDocument.id] = cloneDeep(trackingDocument);

        return acc;
      }, {});
    },
    formatDocumentData() {
      return {
        events: this.formatDocumentEvents(),
        documents: this.collectDocumentChanges(),
      };
    },
    collectDocumentChanges() {
      return this.trackingDocuments
        .filter(document => {
          return (
            document.description !== this.initialDocs[document.id].description ||
            document.trackingNumberId !== this.initialDocs[document.id].trackingNumberId
          );
        })
        .reduce((acc, document) => {
          return acc.concat({
            id: document.id,
            description: document.description,
            physical_docs_tracking_number_id: document.trackingNumberId,
          });
        }, []);
    },
    formatDocumentEvents() {
      return Object.keys(this.trackingEventsToCreate).map(documentId => ({
        document_id: documentId,
        event_type_id: this.trackingEventsToCreate[documentId],
      }));
    },
    onPrintCoverSheet() {
      window.print();
    },
    loadPolicy() {
      const formPolicy = new PS.Policies.EditLoanFormPolicy(PSData.loanPolicy);
      this.loanEditingEnabled = formPolicy.canUpdateLoanData();
    },
    fileDropped(files) {
      if (!files.length) return;
      this.file = files[0];
      this.fileName = get(this, "file.data.filename");
      this.modalOpen = true;
    },
    handleSubmit(formData) {
      const messages = {
        onSuccess: "File successfully uploaded.",
        onError: "File upload failed.",
      };

      const payload = {
        file: objectToSnakeCaseDeep(this.file),
        suffix: formData.suffix,
        id: formData.documentId,
      };

      Ajax.withNotifications(messages)
        .post(this.saveUploadedFilesUrl, payload)
        .catch(error => {
          Bugsnag.notify(
            { name: "Request Error", message: "Unable to upload tracking document" },
            { metaData: { xhr: error } }
          );
        })
        .finally(() => {
          this.modalOpen = false;
        });
    },
    setSelectedDocument(selectedDocument) {
      this.selectedDocument = selectedDocument;
    },
  },
};
</script>
