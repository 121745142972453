<template>
  <div>
    <div v-if="loading" class="ps-loader" />

    <empty v-if="!loading && items.length === 0" text="No Transactions Found" />

    <div v-if="!loading && items.length > 0">
      <h4 v-if="focused">
        Showing {{ items.length }} Transactions for Loan ID "{{ items[0].loan.id }}"
      </h4>
      <h4 v-else>
        Showing {{ items.length }} of {{ total }} Transactions
      </h4>

      <div v-if="focused">
        <h3>Ready to Distribute</h3>
        <items-table :items="readyItems" v-on="$listeners" />

        <h3>Transactions for Review</h3>
        <items-table :items="pendingItems" v-on="$listeners" />

        <h3>Transactions on Hold</h3>
        <items-table :show-hold-reason="true" :items="holdItems" v-on="$listeners" />
      </div>

      <div v-else>
        <items-table :items="pendingItems" v-on="$listeners" />
      </div>
    </div>
  </div>
</template>

<script>
import filter from "lodash/filter";
import ItemsTable from "./table";
import Empty from "components/shared/empty";

export default {
  components: {
    ItemsTable,
    Empty,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    focused: Boolean,
    loading: Boolean,
  },
  computed: {
    readyItems() {
      return filter(this.items, item => {
        return item.status === "ready" || item.status === "distributed";
      });
    },
    pendingItems() {
      return filter(this.items, { status: "pending" });
    },
    holdItems() {
      return filter(this.items, { status: "hold" });
    },
  },
};
</script>
