<template>
  <modal-form
    :show="show"
    title="Add Borrower"
    class="responsible-parties-borrower-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <form>
      <div class="row">
        <with-errors on="name" :errors="errors" class="col-12 control-wrapper form-group">
          <label for="name">Name</label>
          <input
            id="name"
            v-model="borrower.name"
            type="text"
            class="form-control"
          >
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="borrower_type" :errors="errors" class="col-6 control-wrapper form-group">
          <label for="borrower_type">Borrower Type</label>
          <select
            id="borrower_type"
            v-model="borrower.borrowerType"
            name="borrower_type"
            class="form-control"
          >
            <option value />
            <option v-for="[label, name] in options.borrowerTypes" :key="name" :value="name">
              {{ label }}
            </option>
          </select>
        </with-errors>
        <with-errors on="tin" :errors="errors" class="col-6 control-wrapper form-group">
          <label for="tin">{{ borrower.tinFieldLabel }}</label>
          <input
            id="tin"
            :key="tinMask"
            v-model="borrower.tin"
            v-mask:[tinMask]=""
            type="text"
            class="form-control"
          >
        </with-errors>
      </div>
      <div v-if="showResidencyStatus" class="row">
        <with-errors on="residency" :errors="errors" class="col-6 control-wrapper form-group">
          <label for="residency">Residency Status</label>
          <select
            id="residency"
            v-model="borrower.residency"
            name="residency"
            class="form-control"
          >
            <option value />
            <option v-for="[label, name] in options.borrowerResidency" :key="name" :value="name">
              {{ label }}
            </option>
          </select>
        </with-errors>
      </div>
    </form>
  </modal-form>
</template>

<script>
import ModalForm from "components/modal_form";
import WithErrors from "components/shared/with_errors";
import AjaxService from "services/ajax_service";

export default {
  name: "responsible-parties-add-borrower-modal",
  components: { ModalForm, WithErrors },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    borrower: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      options: PSData.options,
      routes: PSData.routes,
    };
  },
  computed: {
    tinMask() {
      return this.borrower.tinFieldMask;
    },
    showResidencyStatus() {
      return this.borrower.isIndividual;
    },
  },
  watch: {
    borrower: {
      deep: true,
      handler() {
        this.resetErrors();
      },
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onSave() {
      const params = {
        borrower: this.borrower.toPlainObject({ toSnakeCase: true }),
      };

      if (this.borrower.isPersisted) {
        this.updateBorrower(params);
      } else {
        this.createBorrower(params);
      }
    },
    createBorrower(params) {
      AjaxService.withNotifications()
        .postJSON(this.routes.borrowersPath, params)
        .then(response => {
          this.$emit("on-parties-update", response);
          this.onClose();
        })
        .catch(({ response, data }) => {
          if (response.status === 422) {
            this.errors = data.errors || {};
          } else {
            Bugsnag.notify(new Error(`Unable to create borrower: ${response.statusText}`));
          }
        });
    },
    updateBorrower(params) {
      AjaxService.withNotifications()
        .patchJSON(`${this.routes.borrowersPath}/${this.borrower.id}`, params)
        .then(response => {
          this.$emit("on-parties-update", response);
          this.onClose();
        })
        .catch(({ response, data }) => {
          if (response.status === 422) {
            this.errors = data.errors || {};
          } else {
            Bugsnag.notify(new Error(`Unable to update borrower: ${response.statusText}`));
          }
        });
    },
    resetErrors() {
      this.errors = {};
    },
  },
};
</script>
