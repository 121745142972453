<template>
  <history-provider :history="$options.history">
    <params-provider v-slot="{ params, setParams }">
      <loan-module-index
        :params="params"
        @change-view="(tab) => setParams({ view: tab })"
      ></loan-module-index>
    </params-provider>
  </history-provider>
</template>

<script>
import { createBrowserHistory } from "history";
import ParamsProvider from "components/shared/params_provider";
import HistoryProvider from "components/shared/history_provider";
import LoanModuleIndex from "components/manage/asset_management/loan_module_index";

export default {
  name: "asset-management-controller",
  history: createBrowserHistory(),
  components: {
    HistoryProvider,
    ParamsProvider,
    LoanModuleIndex,
  },
};
</script>
