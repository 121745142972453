<template>
  <div class="owners col-md-3">
    <ul class="menu">
      <li
        class="owner"
        v-for="subservicer in subservicers"
        :key="subservicer.id"
        :class="{ active: isActive(subservicer.id) }"
        @click="setActiveSubservicer(subservicer.id)"
       >
        <span class="owner-name">
          {{ subservicer.label }}
          <i class="fas fa-caret-right" />
        </span>
        <span class="owner-rules-length">{{ subservicer.recordsCount }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    subservicers: {
      type: Object,
      required: true,
    },
    currentSubservicerId: [Number, String],
  },
  data() {
    return {
      subservicerId: this.currentSubservicerId,
    };
  },
  methods: {
    isActive(subservicerId) {
      return this.subservicerId === subservicerId;
    },
    setActiveSubservicer(subservicerId) {
      if (this.subservicerId == subservicerId) {
        this.subservicerId = null;
        this.$emit("clear");
      } else {
        this.subservicerId = subservicerId;
        this.$emit("details", subservicerId);
      }
    },
  },
};
</script>
