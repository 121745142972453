<template>
  <div v-show="canImportSeries" class="lender-sections-container">
    <h2 class="lender-section-title">
      Funding/Ownership
      <div v-show="isLoading" class="ps-loader ps-loader-small"></div>
    </h2>
    <google-sheets-import-form
      :submit-handler="createImport"
      :loading="isLoading"
      v-show="pending"
    />
    <div v-show="processed">
      <div class="import-data-table-header">Continue importing {{ total }} records?</div>
      <form>
        <div class="form-group">
          <a class="btn btn-cancel-cta" @click="onReset">
            Cancel
          </a>
          <a class="btn btn-action-cta" :disabled="isLoading" @click="onConfirm">
            Confirm
          </a>
        </div>
      </form>
    </div>
    <div v-show="completed">
      <form>
        <div class="form-group">
          <a class="btn btn-action-cta" @click="onReset">
            New Import
          </a>
        </div>
      </form>
      <div class="import-data-table-header">Successfully created {{ completed }} records:</div>
    </div>
    <div v-show="failed">
      <div class="import-data-table-header">{{ errorCountMessage }}</div>
      <form>
        <div class="form-group">
          <a class="btn btn-action-cta" @click="onReset">
            Back
          </a>
        </div>
      </form>
    </div>
    <div class="text-danger" v-show="error">
      <h3>System Error Occurred: Please contact your administrator.</h3>
    </div>
    <div v-show="importData">
      <import-data-table :import-data="importData" />
    </div>
  </div>
</template>

<script>
import GoogleSheetsImportForm from "components/imports/google_sheet_form.vue";
import ImportDataTable from "components/imports/data_table.vue";

const AjaxService = PS.Services.AjaxService;

const importUrl = "buy_back_import";
const completeImportUrl = `${importUrl}/complete`;

export default {
  name: "buy-back-import-controller",
  components: { GoogleSheetsImportForm, ImportDataTable },
  data() {
    return {
      import: undefined,
      error: undefined,
      isLoading: false,
      canImportSeries: PSData.canImportSeries,
    };
  },
  computed: {
    pending() {
      return !this.import || this.import.state === "pending";
    },
    processed() {
      return this.import && this.import.state === "processed" && !this.importErrors;
    },
    failed() {
      this.importData && console.log(this.importErrors, this.import.data);
      return this.importErrors;
    },
    completed() {
      return this.import && this.import.state === "completed";
    },
    total() {
      return this.importData ? this.importData.count : 0;
    },
    importData() {
      return this.import && this.import.data;
    },
    importErrors() {
      return this.importData && this.importData.errors > 0;
    },
    importErrorsCount() {
      return this.importData && this.importData.errors;
    },
    completed() {
      return this.importData && this.importData.completed;
    },
    errorCountMessage() {
      return `The import document has ${this.importErrorsCount} error(s) that must be corrected.`;
    },
  },
  methods: {
    createImport(data) {
      this.isLoading = true;
      this.error = false;

      AjaxService.post(importUrl, data)
        .then(result => (this.import = result))
        .catch(error => (this.error = error))
        .finally(() => (this.isLoading = false));
    },
    onConfirm() {
      this.isLoading = true;

      AjaxService.post(completeImportUrl, { id: this.import.id })
        .then(result => (this.import = result))
        .catch(error => (this.error = error))
        .finally(() => (this.isLoading = false));
    },
    onReset() {
      this.import = undefined;
      this.error = undefined;
    },
  },
};
</script>
