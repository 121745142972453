<template>
  <modal-view :show="show" :on-close="onClose" class="base-modal in-transit-details-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          Daily Activity / Throughput
          <div v-show="isLoading" class="ps-loader ps-loader-small"></div>
          <a class="modal-close-icon" @click.prevent="onClose" href="#close">&times;</a>
        </h4>
      </div>
      <div class="modal-body">
        <h5>{{account.name}}</h5>
        <table class="table">
          <thead>
            <tr>
              <th>Time</th>
              <th>Transaction</th>
              <th>Bank</th>
              <th>Loan ID</th>
              <th class="account-group-amount">Amount</th>
              <th class="account-group-amount">Throughput</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="wireRequest in wireRequests" :key="wireRequest.psId">
              <td>{{wireRequest.responseReceivedAt | formatTime}}</td>
              <td>{{wireRequest.requestType}}</td>
              <td>Wells Fargo</td>
              <td>{{wireRequest.loan.psId}}</td>
              <td class="account-group-amount">{{wireRequest.requestAmount | money(2)}}</td>
              <td class="account-group-amount">{{Math.abs(wireRequest.requestAmount) | money(2)}}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td></td>
              <td></td>
              <td></td>
              <td class="account-group-amount">{{amountTotal | money(2)}}</td>
              <td class="account-group-amount">{{throughputTotal | money(2)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button class="btn btn-action-cta" @click.prevent="onClose">
          Ok
        </button>
      </div>
    </div>
  </modal-view>
</template>

<script>
import AjaxService from "services/ajax_service";
import ModalView from "components/modal_view";
import moment from "moment";
import sum from "lodash/sum";

export default {
  name: "in-transit-details-modal",
  components: { ModalView },
  props: {
    account: Object,
    show: Boolean,
  },
  data: function() {
    return {
      routes: PSData.routes,
      wireRequests: [],
      isLoading: false,
    };
  },
  mounted: function() {
    this.getDailyWireRequests();
  },
  computed: {
    amountTotal() {
      return sum(this.wireRequests.map(wireRequest => wireRequest.requestAmount));
    },
    throughputTotal() {
      return sum(this.wireRequests.map(wireRequest => Math.abs(wireRequest.requestAmount)));
    },
  },
  filters: {
    formatTime: function(value) {
      return moment(value).format("hh:mmA");
    },
  },
  methods: {
    getDailyWireRequests() {
      if (this.isLoading) return;
      this.isLoading = true;
      const url = this.account.getDailyWireRequestsUrl(this.routes);

      return AjaxService.get(url).then(({ wireRequests }) => {
        this.wireRequests = [...wireRequests]
          .map(a => ({
            ...a,
            responseReceivedAt: new Date(a.responseReceivedAt),
            requestAmount: parseFloat(a.requestAmount),
          }))
          .sort((a, b) => a.responseReceivedAt - b.responseReceivedAt);
        this.isLoading = false;
      });
    },

    onClose() {
      this.$emit("close");
    },
  },
};
</script>
