<template>
  <div class="alert alert-warning notification-message loan-duplicates" v-if="showWarning">
    <div>
      <i class="fas fa-exclamation-triangle"></i>
    </div>
    <div>
      <h3>{{ bannerTitle }}</h3>
      <ul :class="{'with-bullets': expand}">
        <li><loan-duplicate-item :loan="loanDuplicates[0]" /></li>
        <li v-for="loan in loanDuplicates.slice(1)" v-if="expand">
          <loan-duplicate-item :loan="loan" :key="loan.toParam" />
        </li>
      </ul>
      <div v-show="expand">
        <a href="#" @click.prevent="toogleExpand">show less</a>
      </div>
      <div v-if="showExpandLink">
        <a href="#" @click.prevent="toogleExpand">+ {{ loanDuplicates.length - 1 }} more</a>
      </div>
    </div>
  </div>
</template>

<script>
import LoanDuplicateItem from "components/loan_duplicate_item";

export default {
  name: "loan-duplicates",
  components: {
    LoanDuplicateItem,
  },
  props: {
    loanDuplicates: Array,
  },
  data() {
    return {
      expand: false,
    };
  },
  computed: {
    showWarning() {
      return this.loanDuplicates.length > 0;
    },
    showExpandLink() {
      return this.loanDuplicates.length > 1 && !this.expand;
    },
    bannerTitle() {
      return `Possible Duplicate${this.loanDuplicates.length > 1 ? "s" : ""}`;
    },
  },
  methods: {
    toogleExpand() {
      this.expand = !this.expand;
    },
  },
};
</script>
