import FieldWithError from "components/transaction/error.vue";

import AjaxService from "services/ajax_service";
import { success, error } from "services/notification";

export default {
  components: { FieldWithError },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      inFlight: false,
    };
  },
  watch: {
    show(value) {
      if (!value) {
        this.close();
      }
    },
  },
  methods: {
    close() {
      this.clear();
      this.$emit("close");
      this.errors = {};
    },
    submit() {
      if (!this.canSend) return;

      this.inFlight = true;

      AjaxService.post(this.url, { transaction: this.transaction })
        .then(data => {
          success("Transaction was created successfully");

          this.$emit("created", data);
          this.close();
        })
        .catch(({ response, data }) => {
          if (response.status === 422) {
            this.errors = data;
          } else {
            error("Something went wrong");
          }
        })
        .finally(() => (this.inFlight = false));
    },
    isNotEmptyString(value) {
      return typeof value === "string" && value.length > 0;
    },
  },
};
