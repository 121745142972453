class LegalOwnership {
  constructor(data) {
    this.raw = data;
    this.id = data.id;
    this._ownerName = data.ownerName;

    this.reset();
  }

  static dateSort(a, b) {
    return new Date(a.raw.startDate || 0) - new Date(b.raw.startDate || 0);
  }

  get ownerId() {
    return this._ownerId;
  }
  set ownerId(value) {
    this._ownerId = value;
  }

  get ownerName() {
    return this._ownerName;
  }

  get wireDate() {
    return this._wireDate;
  }
  set wireDate(value) {
    this._wireDate = value;
  }

  get startDate() {
    return this._startDate;
  }
  set startDate(value) {
    this._startDate = value;
  }

  get endDate() {
    return this._endDate;
  }
  set endDate(value) {
    this._endDate = value;
  }

  get note() {
    return this._note;
  }
  set note(value) {
    this._note = value;
  }

  reset() {
    this._ownerId = this.raw.ownerId;
    this._wireDate = window.PS.Models.DateFormatter.formatDate(this.raw.wireDate);
    this._startDate = window.PS.Models.DateFormatter.formatDate(this.raw.startDate);
    this._endDate = window.PS.Models.DateFormatter.formatDate(this.raw.endDate);
    this._note = this.raw.note;
  }
}

window.PS.Models.LegalOwnership = LegalOwnership;
