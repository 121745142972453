const Event = PS.Models.Event;
const Rule = PS.Models.Rule;
const Events = PS.Models.Diligence.Events;
const Commands = PS.Models.Diligence.Commands;

class FormRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.Document.LoadingRequested:
        return this.whenNewFormRequested(event);

      case Events.Field.ValueChanged:
        return this.whenFieldValueChanged(event);

      case Events.Field.NoteChanged:
        return this.whenFieldNoteChanged(event);

      case Events.Field.ValidationChanged:
        return this.whenFieldValidationChanged(event);

      case Events.Fieldset.CreateRequested:
        return this.whenFieldsetCreateRequested(event);

      case Events.Fieldset.DeleteRequested:
        return this.whenFieldsetDeleteRequested(event);

      case Events.Fieldset.ClearRequested:
        return this.whenFieldsetClearRequested(event);

      case Events.Fieldset.ValidateRequested:
        return this.whenFieldsetValidateRequested(event);

      case Events.Field.FieldValueClarified:
        return this.whenFieldValueClarified(event);

      case Events.Fieldset.FieldsetCreationAnnounced:
        return this.whenFieldsetCreationAnnounced(event);
    }

    return super.check(event);
  }

  whenNewFormRequested(event) {
    return [
      new Commands.Form.StartLoading(event, this.state),
      new Commands.Form.LoadFromServer(event, this.state),
      new Commands.Form.LoadUploadStatuses(event, this.state),
      new Commands.Field.RequestFieldValuesClarification(event),
      new Commands.Form.SetUpFormState(event, this.state),
      new Commands.Form.EndLoading(event, this.state),
    ];
  }

  whenFieldValueChanged(event) {
    const isJuniorLienChanged =
      this.state.form.document.type === "note" && event.field.name === "junior_lien";
    const doesJuniorLienExist = event.value === "1";

    const isAssignmentOfLeasesSeparateChanged =
      this.state.form.document.type === "mortgage" &&
      event.field.name === "assignment_of_leases_separate_document";
    const isAssignmentOfLeasesSeparate = event.value === "1";

    const isPersonalGuarantyChanged =
      this.state.form.document.type === "personal_guaranty" &&
      event.field.name === "guaranty_present";
    const isPersonalGuarantyExist = event.field.value;

    const isEscrowedChanged =
      this.state.form.document.type === "settlement_statement" &&
      event.field.name === "taxes_and_insurance_escrowed";
    const isEscrowedEnabled = event.field.value === "1";

    const isLoanAssignmentTransfersChanged =
      this.state.form.document.type === "assignment_transfers" &&
      event.field.name === "assignment_transfers_exist";
    const isLoanAssignmentTransfersEnabled = event.field.value === "1";

    const isFloodInsuranceChanged =
      this.state.form.document.type === "evidence_of_insurance" &&
      event.field.name === "coverage_flood_zone";
    const isFloodInsuranceEnabled = event.field.value === "1";

    const isWindInsuranceChanged =
      this.state.form.document.type === "evidence_of_insurance" &&
      event.field.name === "coverage_wind_zone";
    const isWindInsuranceEnabled = event.field.value === "1";

    const isLiabilityInsuranceChanged =
      this.state.form.document.type === "evidence_of_insurance" &&
      event.field.name === "liability_insurance_coverage";
    const isLiabilityInsuranceEnabled = event.field.value === "1";

    const isRentalLossInsuranceChanged =
      this.state.form.document.type === "evidence_of_insurance" &&
      event.field.name === "rental_loss_insurance_coverage";
    const isRentalLossInsuranceEnabled = event.field.value === "1";

    const isLenderValuationZoning =
      this.state.form.document.type === "appraisal_or_bpo" && event.field.name === "lender_zoning";
    const isLenderValuationZoningValueEmpty = event.field.isEmpty();

    const isPSValuationZoning =
      this.state.form.document.type === "appraisal_or_bpo" && event.field.name === "ps_zoning";
    const isPSValuationZoningValueEmpty = event.field.isEmpty();

    const isEvnIssuesIdentified =
      this.state.form.document.type === "environmental" &&
      event.field.name === "environmental_issues_identified";
    const evnIssuesIdentifiedEnabled = event.field.value === "1";

    const isHarmfulSubstances =
      this.state.form.document.type === "environmental" &&
      event.field.name === "harmful_substances";
    const harmfulSubstancesEnabled = event.field.value === "1";

    const isBankruptcyOther =
      this.state.form.document.type === "loan_application" &&
      event.field.name === "bankruptcy_other";
    const isBankruptcyOtherEnabled = event.field.value === "1";

    const isComparablesChanged =
      this.state.form.document.type === "appraisal_or_bpo" &&
      event.field.name === "has_comparables";
    const isComparablesEnabled = event.value === "1";

    switch (true) {
      case isAssignmentOfLeasesSeparateChanged && isAssignmentOfLeasesSeparate:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Fieldset.RequestFieldsetCreation(new Event(), {
            fieldset: { name: "assignment_of_leases_details" },
          }),
        ];
      case isAssignmentOfLeasesSeparateChanged && !isAssignmentOfLeasesSeparate:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Form.RemoveAllSimilarFieldsets(
            new Events.Fieldset.DeleteAllFieldsetsRequested({
              fieldset: { name: "assignment_of_leases_details" },
            }),
            this.state
          ),
        ];
      case isJuniorLienChanged && doesJuniorLienExist:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Form.ShowFields(
            new Events.Form.ShowFieldsRequested({
              fields: ["junior_lien_total", "junior_lien_holders"],
            }),
            this.state
          ),
          new Commands.Fieldset.RequestFieldsetCreation(new Event(), {
            fieldset: { name: "junior_lien_details" },
          }),
          new Commands.Field.RequestFieldEnable(new Events.Field.FieldEnableRequested(), {
            field: { name: "promissory_note" },
          }),
          new Commands.Field.RequestFieldEnable(new Events.Field.FieldEnableRequested(), {
            field: { name: "recorded_agreement" },
          }),
          new Commands.Field.RequestFieldEnable(new Events.Field.FieldEnableRequested(), {
            field: { name: "inter_creditor_agreement" },
          }),
        ];

      case isJuniorLienChanged && !doesJuniorLienExist:
        const subordinateDocumentsFieldset = this.state.form.fields.find(
          f => f.name === "subordinate_documents"
        );

        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Form.HideFields(
            new Events.Form.HideFieldsRequested({
              fields: ["junior_lien_total", "junior_lien_holders"],
            }),
            this.state
          ),
          new Commands.Form.RemoveAllSimilarFieldsets(
            new Events.Fieldset.DeleteAllFieldsetsRequested({
              fieldset: { name: "junior_lien_details" },
            }),
            this.state
          ),
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "promissory_note" },
          }),
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "recorded_agreement" },
          }),
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "inter_creditor_agreement" },
          }),
          new Commands.Field.ChangeValue(new Events.Field.ValueChangeRequested({ value: false }), {
            field: subordinateDocumentsFieldset.fields.find(f => f.name === "promissory_note"),
          }),
          new Commands.Field.ChangeValue(new Events.Field.ValueChangeRequested({ value: false }), {
            field: subordinateDocumentsFieldset.fields.find(f => f.name === "recorded_agreement"),
          }),
          new Commands.Field.ChangeValue(new Events.Field.ValueChangeRequested({ value: false }), {
            field: subordinateDocumentsFieldset.fields.find(
              f => f.name === "inter_creditor_agreement"
            ),
          }),
        ];

      case isPersonalGuarantyChanged && isPersonalGuarantyExist:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Fieldset.RequestFieldsetCreation(new Event(), {
            fieldset: { name: "guaranty" },
          }),
        ];

      case isPersonalGuarantyChanged && !isPersonalGuarantyExist:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Form.RemoveAllSimilarFieldsets(
            new Events.Fieldset.DeleteAllFieldsetsRequested({ fieldset: { name: "guaranty" } }),
            this.state
          ),
        ];

      case isEscrowedChanged && isEscrowedEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Fieldset.RequestFieldsetCreation(new Event(), {
            fieldset: { name: "taxes_and_insurance_details" },
          }),
        ];

      case isEscrowedChanged && !isEscrowedEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Form.RemoveAllSimilarFieldsets(
            new Events.Fieldset.DeleteAllFieldsetsRequested({
              fieldset: { name: "taxes_and_insurance_details" },
            }),
            this.state
          ),
        ];

      case isLoanAssignmentTransfersChanged && isLoanAssignmentTransfersEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Fieldset.RequestFieldsetCreation(new Event(), {
            fieldset: { name: "loan_assignment_docs" },
          }),
        ];

      case isLoanAssignmentTransfersChanged && !isLoanAssignmentTransfersEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Form.RemoveAllSimilarFieldsets(
            new Events.Fieldset.DeleteAllFieldsetsRequested({
              fieldset: { name: "loan_assignment_docs" },
            }),
            this.state
          ),
        ];

      case isFloodInsuranceChanged && isFloodInsuranceEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Fieldset.RequestFieldsetCreation(new Event(), {
            fieldset: { name: "flood_insurance_details" },
          }),
        ];

      case isFloodInsuranceChanged && !isFloodInsuranceEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Form.RemoveAllSimilarFieldsets(
            new Events.Fieldset.DeleteAllFieldsetsRequested({
              fieldset: { name: "flood_insurance_details" },
            }),
            this.state
          ),
        ];

      case isWindInsuranceChanged && isWindInsuranceEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Fieldset.RequestFieldsetCreation(new Event(), {
            fieldset: { name: "wind_insurance_details" },
          }),
        ];

      case isWindInsuranceChanged && !isWindInsuranceEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Form.RemoveAllSimilarFieldsets(
            new Events.Fieldset.DeleteAllFieldsetsRequested({
              fieldset: { name: "wind_insurance_details" },
            }),
            this.state
          ),
        ];

      case isLiabilityInsuranceChanged && isLiabilityInsuranceEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Fieldset.RequestFieldsetCreation(new Event(), {
            fieldset: { name: "liability_insurance_details" },
          }),
        ];

      case isLiabilityInsuranceChanged && !isLiabilityInsuranceEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Form.RemoveAllSimilarFieldsets(
            new Events.Fieldset.DeleteAllFieldsetsRequested({
              fieldset: { name: "liability_insurance_details" },
            }),
            this.state
          ),
        ];

      case isRentalLossInsuranceChanged && isRentalLossInsuranceEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Fieldset.RequestFieldsetCreation(new Event(), {
            fieldset: { name: "rental_loss_insurance_details" },
          }),
        ];

      case isRentalLossInsuranceChanged && !isRentalLossInsuranceEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Form.RemoveAllSimilarFieldsets(
            new Events.Fieldset.DeleteAllFieldsetsRequested({
              fieldset: { name: "rental_loss_insurance_details" },
            }),
            this.state
          ),
        ];

      case this.isEnvironmentalReportTypeChanged(event) &&
        !this.isEnvironmentalReportTypeVeracheck(event):
        return [new Commands.Field.SaveValue(event), ...this.hideVeracheckScoreField()];

      case this.isEnvironmentalReportTypeChanged(event) &&
        this.isEnvironmentalReportTypeVeracheck(event):
        return [new Commands.Field.SaveValue(event), ...this.showVeracheckScoreField()];

      case isLenderValuationZoning && isLenderValuationZoningValueEmpty:
        return [
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "lender_zoning_confirmation" },
            fieldset: event.fieldset,
          }),
          new Commands.Field.ChangeValue(new Events.Field.ValueChangeRequested({ value: null }), {
            field: event.fieldset.fields.find(f => f.name === "lender_zoning_confirmation"),
            document: this.state.document,
          }),
        ];

      case isLenderValuationZoning && !isLenderValuationZoningValueEmpty:
        return [
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Field.RequestFieldEnable(new Events.Field.FieldEnableRequested(), {
            field: { name: "lender_zoning_confirmation" },
            fieldset: event.fieldset,
          }),
        ];

      case isPSValuationZoning && isPSValuationZoningValueEmpty:
        return [
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "ps_zoning_confirmation" },
            fieldset: event.fieldset,
          }),
          new Commands.Field.ChangeValue(new Events.Field.ValueChangeRequested({ value: null }), {
            field: event.fieldset.fields.find(f => f.name === "ps_zoning_confirmation"),
            document: this.state.document,
          }),
        ];

      case isPSValuationZoning && !isPSValuationZoningValueEmpty:
        return [
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Field.RequestFieldEnable(new Events.Field.FieldEnableRequested(), {
            field: { name: "ps_zoning_confirmation" },
            fieldset: event.fieldset,
          }),
        ];

      case isEvnIssuesIdentified && evnIssuesIdentifiedEnabled:
        return [
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Field.RequestFieldEnable(new Events.Field.FieldEnableRequested(), {
            field: { name: "environmental_issues_resolved" },
          }),
        ];

      case isEvnIssuesIdentified && !evnIssuesIdentifiedEnabled:
        return [
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "environmental_issues_resolved" },
          }),
          new Commands.Field.ChangeValue(new Events.Field.ValueChangeRequested({ value: null }), {
            field: this.state.form.fields.find(f => f.name === "environmental_issues_resolved"),
            document: this.state.document,
          }),
        ];

      case isHarmfulSubstances && harmfulSubstancesEnabled:
        return [
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Field.RequestFieldEnable(new Events.Field.FieldEnableRequested(), {
            field: { name: "condition_details" },
          }),
          new Commands.Field.RequestFieldEnable(new Events.Field.FieldEnableRequested(), {
            field: { name: "operations_maintenance_program" },
          }),
        ];

      case isHarmfulSubstances && !harmfulSubstancesEnabled:
        return [
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "condition_details" },
          }),
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "operations_maintenance_program" },
          }),
          new Commands.Field.ChangeValue(new Events.Field.ValueChangeRequested({ value: null }), {
            field: this.state.form.fields.find(f => f.name === "condition_details"),
            document: this.state.document,
          }),
          new Commands.Field.ChangeValue(new Events.Field.ValueChangeRequested({ value: null }), {
            field: this.state.form.fields.find(f => f.name === "operations_maintenance_program"),
            document: this.state.document,
          }),
        ];

      case isBankruptcyOther && isBankruptcyOtherEnabled:
        return [
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Field.RequestFieldEnable(new Events.Field.FieldEnableRequested(), {
            field: { name: "bankruptcy_date" },
            fieldset: event.fieldset,
          }),
          new Commands.Field.RequestFieldEnable(new Events.Field.FieldEnableRequested(), {
            field: { name: "foreclosure_date" },
            fieldset: event.fieldset,
          }),
          new Commands.Field.RequestFieldEnable(new Events.Field.FieldEnableRequested(), {
            field: { name: "short_sale_date" },
            fieldset: event.fieldset,
          }),
        ];
      case isBankruptcyOther && !isBankruptcyOtherEnabled:
        return [
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "bankruptcy_date" },
            fieldset: event.fieldset,
          }),
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "foreclosure_date" },
            fieldset: event.fieldset,
          }),
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "short_sale_date" },
            fieldset: event.fieldset,
          }),
          new Commands.Field.ChangeValue(new Events.Field.ValueChangeRequested({ value: null }), {
            field: event.fieldset.fields.find(f => f.name === "bankruptcy_date"),
          }),
          new Commands.Field.ChangeValue(new Events.Field.ValueChangeRequested({ value: null }), {
            field: event.fieldset.fields.find(f => f.name === "foreclosure_date"),
          }),
          new Commands.Field.ChangeValue(new Events.Field.ValueChangeRequested({ value: null }), {
            field: event.fieldset.fields.find(f => f.name === "short_sale_date"),
          }),
        ];

      case isComparablesChanged && isComparablesEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Fieldset.RequestFieldsetCreation(new Event(), {
            fieldset: { name: "comparable" },
          }),
        ];

      case isComparablesChanged && !isComparablesEnabled:
        return [
          new Commands.Checklist.RequestBlockUI(event),
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
          new Commands.Form.RemoveAllSimilarFieldsets(
            new Events.Fieldset.DeleteAllFieldsetsRequested({
              fieldset: { name: "comparable" },
            }),
            this.state
          ),
        ];

      default:
        return [
          new Commands.Field.SaveValue(event),
          new Commands.Field.RequestFormulaRecalculation(event),
        ];
    }
  }

  whenFieldNoteChanged(event) {
    return [
      new Commands.Field.SaveNote(event),
      new Commands.Form.BroadcastDocumentUpdate(event, this.state),
    ];
  }

  whenFieldValidationChanged(event) {
    return [
      new Commands.Field.SaveValidation(event),
      new Commands.Form.BroadcastDocumentUpdate(event, this.state),
    ];
  }

  whenFieldsetCreateRequested(event) {
    const isJuniorLienDetailsChanged = event.fieldset.name === "junior_lien_details";
    const isFloodInsuranceDetailsChanged = event.fieldset.name === "flood_insurance_details";
    const isWindInsuranceDetailsChanged = event.fieldset.name === "wind_insurance_details";
    const isLiabilityInsuranceDetailsChanged =
      event.fieldset.name === "liability_insurance_details";
    const isRentalLossInsuranceDetailsChanged =
      event.fieldset.name === "rental_loss_insurance_details";
    const isComparableChanged = event.fieldset.name === "comparable";

    switch (true) {
      case isJuniorLienDetailsChanged:
        return [
          new Commands.Fieldset.CreateFieldset(event, this.state),
          new Commands.Form.BroadcastDocumentUpdate(event, this.state),
          new Commands.Field.RequestFieldValuesClarification(event),
          new Commands.Form.RearrangeFields(
            new Events.Form.RearrangeFieldsRequested({
              name: "junior_lien_details",
              insertAfter: "junior_lien_holders",
            }),
            this.state
          ),
          new Commands.Checklist.RequestUnblockUI(event),
        ];

      case isFloodInsuranceDetailsChanged:
        return [
          new Commands.Fieldset.CreateFieldset(event, this.state),
          new Commands.Form.BroadcastDocumentUpdate(event, this.state),
          new Commands.Field.RequestFieldValuesClarification(event),
          new Commands.Form.RearrangeFields(
            new Events.Form.RearrangeFieldsRequested({
              name: "flood_insurance_details",
              insertAfter: "flood_insurance",
            }),
            this.state
          ),
          new Commands.Checklist.RequestUnblockUI(event),
        ];

      case isWindInsuranceDetailsChanged:
        return [
          new Commands.Fieldset.CreateFieldset(event, this.state),
          new Commands.Form.BroadcastDocumentUpdate(event, this.state),
          new Commands.Field.RequestFieldValuesClarification(event),
          new Commands.Form.RearrangeFields(
            new Events.Form.RearrangeFieldsRequested({
              name: "wind_insurance_details",
              insertAfter: "wind_insurance",
            }),
            this.state
          ),
          new Commands.Checklist.RequestUnblockUI(event),
        ];

      case isRentalLossInsuranceDetailsChanged:
        return [
          new Commands.Fieldset.CreateFieldset(event, this.state),
          new Commands.Form.BroadcastDocumentUpdate(event, this.state),
          new Commands.Field.RequestFieldValuesClarification(event),
          new Commands.Form.RearrangeFields(
            new Events.Form.RearrangeFieldsRequested({
              name: "rental_loss_insurance_details",
              insertAfter: "rental_loss_insurance",
            }),
            this.state
          ),
          new Commands.Checklist.RequestUnblockUI(event),
        ];

      case isLiabilityInsuranceDetailsChanged:
        return [
          new Commands.Fieldset.CreateFieldset(event, this.state),
          new Commands.Form.BroadcastDocumentUpdate(event, this.state),
          new Commands.Field.RequestFieldValuesClarification(event),
          new Commands.Form.RearrangeFields(
            new Events.Form.RearrangeFieldsRequested({
              name: "liability_insurance_details",
              insertAfter: "liability_insurance",
            }),
            this.state
          ),
          new Commands.Checklist.RequestUnblockUI(event),
        ];

      case isComparableChanged:
        return [
          new Commands.Fieldset.CreateFieldset(event, this.state),
          new Commands.Form.BroadcastDocumentUpdate(event, this.state),
          new Commands.Field.RequestFieldValuesClarification(event),
          new Commands.Form.RearrangeFields(
            new Events.Form.RearrangeFieldsRequested({
              name: "comparable",
              insertAfter: "comparables",
            }),
            this.state
          ),
          new Commands.Checklist.RequestUnblockUI(event),
        ];

      default:
        return [
          new Commands.Fieldset.CreateFieldset(event, this.state),
          new Commands.Form.BroadcastDocumentUpdate(event, this.state),
          new Commands.Field.RequestFieldValuesClarification(event),
          new Commands.Checklist.RequestUnblockUI(event),
        ];
    }
  }

  whenFieldsetDeleteRequested(event) {
    return [
      new Commands.Fieldset.DeleteFieldset(event, this.state),
      new Commands.Form.BroadcastDocumentUpdate(event, this.state),
      new Commands.Fieldset.RequestFormulaInvalidation(event),
      new Commands.Field.RequestFieldValuesClarification(event),
      new Commands.Checklist.RequestUnblockUI(event),
    ];
  }

  whenFieldsetClearRequested(event) {
    return [
      new Commands.Fieldset.ClearFieldset(event, this.state),
      new Commands.Checklist.RequestUnblockUI(event),
    ];
  }

  whenFieldsetValidateRequested(event) {
    return [
      new Commands.Fieldset.ValidateFieldset(event, this.state),
      new Commands.Checklist.RequestUnblockUI(event),
    ];
  }

  whenFieldValueClarified(event) {
    switch (true) {
      case this.isEnvironmentalReportTypeChanged(event) &&
        !this.isEnvironmentalReportTypeVeracheck(event):
        return [
          ...this.hideVeracheckScoreField(),
          new Commands.Field.RequestFormulaRecalculation(event),
        ];
      case this.isEnvironmentalReportTypeChanged(event) &&
        this.isEnvironmentalReportTypeVeracheck(event):
        return [
          ...this.showVeracheckScoreField(),
          new Commands.Field.RequestFormulaRecalculation(event),
        ];

      default:
        return [new Commands.Field.RequestFormulaRecalculation(event)];
    }
  }

  whenFieldsetCreationAnnounced(event) {
    switch (event.fieldset.name) {
      case "valuation":
        return [
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "lender_zoning_confirmation" },
            fieldset: event.fieldset,
          }),
        ];

      case "borrower_details":
        return [
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "bankruptcy_date" },
            fieldset: event.fieldset,
          }),
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "foreclosure_date" },
            fieldset: event.fieldset,
          }),
          new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
            field: { name: "short_sale_date" },
            fieldset: event.fieldset,
          }),
        ];
    }

    return [];
  }

  isEnvironmentalReportTypeChanged(event) {
    return this.state.form.document.type === "environmental" && event.field.name === "report_type";
  }

  isEnvironmentalReportTypeVeracheck(event) {
    return event.field.value === "veracheck";
  }

  hideVeracheckScoreField() {
    return [
      new Commands.Field.ChangeValue(new Events.Field.ValueChangeRequested({ value: null }), {
        field: this.state.form.fields.find(f => f.name === "veracheck_score"),
        document: this.state.document,
      }),
      new Commands.Field.RequestFieldDisable(new Events.Field.FieldDisableRequested(), {
        field: { name: "veracheck_score" },
      }),
    ];
  }

  showVeracheckScoreField() {
    return [
      new Commands.Field.RequestFieldEnable(new Events.Field.FieldEnableRequested(), {
        field: { name: "veracheck_score" },
      }),
    ];
  }
}

PS.Models.Diligence.FormRule = FormRule;
