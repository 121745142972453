<template>
  <div class="loan-progress-update-tooltip">
    <div v-if="isProgressUpdatePresent || isShowSuspensionContent" class="progress-update-data">
      <a v-if="isShowSuspensionContent" href="#" tabindex=-1, v-popover class="condensed-data">
        <div class="condensed-data-row">
          <span class="condensed-data-header">Date:</span>
          <span class="condensed-data-value">{{ loanSuspension.updatedAt }}</span>
        </div>
        <div class="condensed-data-row">
          <span class="condensed-data-header">Status:</span>
          <span class="condensed-data-value">{{ loanSuspension.statusName }}</span>
        </div>
      </a>
      <a v-else href="#" tabindex=-1, v-popover class="condensed-data">
        <div class="condensed-data-row">
          <span class="condensed-data-header">{{ progressUpdate.shortDate }}</span>
          <span class="condensed-data-value">{{ userName }}</span>
        </div>
        <div class="condensed-data-row">
          <span class="condensed-data-header">Status update:</span>
          <span class="condensed-data-value">{{ progressUpdate.currentStatus }}</span>
        </div>
      </a>
      <div v-if="isShowSuspensionContent" class="popover-content hidden">
        <div class="loan-progress-update-tooltip-content">
          <p><b>Date</b>: {{ loanSuspension.updatedAt }}</p>
          <p><b>Status</b>: {{ loanSuspension.statusName }}</p>
          <p>
            <b>Reasons For Suspending</b>:
          </p>
          <ul>
            <li v-for="reason in loanSuspension.loanSuspensionReasons" :key="reason.id">{{ reason.label }}</li>
          </ul>
          <p><b>Description</b>: {{ loanSuspension.description }}</p>
        </div>
      </div>
      <div else class="popover-content hidden">
        <div class="loan-progress-update-tooltip-content">
          <p><b>Date</b>: {{progressUpdate.shortDate}} {{progressUpdate.usTime}}</p>
          <p><b>Analyst</b>: {{ userName }}</p>
          <p><b>Status Update</b>: {{progressUpdate.currentStatus}}</p>
          <p><b>Analyst Next Steps</b>: {{progressUpdate.nextSteps}}</p>
          <p><b>Lender Next Steps</b>: {{progressUpdate.lenderNextSteps}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loan-progress-update-tooltip",
  props: {
    progressUpdate: Object,
    loanSuspension: Object,
  },
  computed: {
    userName() {
      if (this.progressUpdate && this.progressUpdate.user) {
        return this.progressUpdate.user.name;
      }
    },
    isProgressUpdatePresent() {
      return this.progressUpdate && Object.keys(this.progressUpdate).length > 0;
    },
    isShowSuspensionContent() {
      return this.loanSuspension && this.loanSuspension.isDisplay;
    },
  },
};
</script>
