import Rule from "models/rule";
import {
  CalculateFundingEntitiesAmounts,
  SubmitSelectedSeries,
  InitiateRefresh,
  RefreshView,
  SendLoanUpdate,
} from "models/funding_entities/commands";
import {
  LoanUpdateSent,
  TransferInitiated,
  RefreshInitiated,
} from "models/funding_entities/events";

export default class FundingEntitiesRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case LoanUpdateSent:
        return this.whenLoanUpdateSent(event);
      case TransferInitiated:
        return this.whenTransferInitiated(event);
      case RefreshInitiated:
        return this.whenRefreshInitiated(event);
    }

    return super.check(event);
  }

  whenLoanUpdateSent(event) {
    return [new CalculateFundingEntitiesAmounts(event, this.state)];
  }

  whenTransferInitiated(event) {
    return [new SubmitSelectedSeries(event, this.state), new InitiateRefresh(event, this.state)];
  }

  whenRefreshInitiated(event) {
    return [new RefreshView(event, this.state), new SendLoanUpdate(event, this.state)];
  }
}
