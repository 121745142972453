<template>
  <criteria-selector
    :label="`Is`"
    :options="options"
    :tags="tags"
    @tag-added="onTagAdded"
    @tag-removed="onTagRemoved"
    :class-names="`column is-full`"
    :validation-rule="`required`"
  />
</template>

<script>
import { stateOptions } from "./utils/mappings";
import CriteriaSelector from "./criteria_selector";

export default {
  name: "state-selector",
  components: {
    CriteriaSelector,
  },
  props: {
    ruleStates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: stateOptions,
    };
  },
  computed: {
    tags() {
      return this.ruleStates.reduce((arr, v) => {
        const opt = this.options.find(o => o.value === v);
        if (opt) {
          arr.push(opt);
        }
        return arr;
      }, []);
    },
  },
  methods: {
    onTagAdded(tag) {
      this.$emit("state-added", tag);
    },
    onTagRemoved(tag) {
      this.$emit("state-removed", tag);
    },
  },
};
</script>
