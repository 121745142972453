<template>
  <div class="loan-documents-list">
    <div class="list-header">
      <sorting-header
        class="header-cell document-name"
        :title="'Name'"
        :order="sortByNameOrder"
        @on-change-direction="useSortingByName"
      />

      <sorting-header
        class="header-cell document-modified-date"
        :title="'Last modified date'"
        :order="sortByModifiedDateOrder"
        @on-change-direction="useSortingByModifiedDate"
      />

      <sorting-header
        class="header-cell document-modified-user"
        :title="'Last modified by'"
        :order="sortByUserOrder"
        @on-change-direction="useSortingByUser"
      />

      <sorting-header
        class="header-cell document-uploaded-date"
        :title="'Uploaded date'"
        :order="sortByUploadedDateOrder"
        @on-change-direction="useSortingByUploadedDate"
      />

      <div class="header-cell document-action-buttons"></div>
    </div>

    <ul class="list-details">
      <li
        v-for="document in orderedDocuments"
        :key="document.id"
        class="document-item"
        :class="documentRowClasses(document)"
        @click.self="editDocument(document)"
      >
        <base-tooltip
          class="document-name"
          :always=false
          position="is-top"
          :label="document.originalFileName"
        >
          <span
            @click="editDocument(document)"
          >
            {{ document.fullName }}
          </span>
        </base-tooltip>

        <span
          class="details-cell document-modified-date"
          @click="editDocument(document)"
        >
          {{ document.lastModifiedAtText || "-" }}
        </span>

        <span
          class="details-cell document-modified-user"
          :class="{ 'lender-user': document.isLastModifiedByLender }"
          @click="editDocument(document)"
        >
          {{ document.lastModifiedByName || "-" }}
        </span>

        <span
          class="details-cell document-uploaded-date"
          @click="editDocument(document)"
        >
          {{ document.createdAtText }}
        </span>

        <span class="details-cell document-action-buttons">
          <a :href="document.url" class="js-external-link" target="_blank">
            <img
              alt="View file"
              class="js-external-link"
              :src="require('images/icons/icon_external_link.svg')"
            >
            <span>View</span>
          </a>
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
import SortingHeader from "components/shared/sorting_header";
import Documents from "collections/documents";

function chooseOrderDirection(realOrderBy, expectedOrderBy, orderWay) {
  if (realOrderBy === expectedOrderBy) {
    return orderWay;
  } else {
    return 0;
  }
}

const SortBy = {
  Default: "Default",
  ModifiedDate: "ModifiedDate",
  Name: "Name",
  UploadedDate: "UploadedDate",
  User: "User",
};

export default {
  name: "LoanDocumentList",
  components: {
    SortingHeader,
  },
  props: {
    documents: Array,
    selectedDocument: Object,
    allowEdit: Boolean,
    blockedDocumentIds: Array,
  },
  data() {
    return {
      lastModifiedUsers: PSData.lastModifiedUsers,
      sortBy: SortBy.Default,
      sortingOrder: 1,
      categories: PSData.loanDocumentTypes,
    };
  },
  computed: {
    orderedDocuments() {
      switch (this.sortBy) {
        case SortBy.Name:
          return this.sortByName(this.documents, this.sortingOrder);

        case SortBy.ModifiedDate:
          return this.sortByModifiedDate(this.documents, this.sortingOrder);

        case SortBy.UploadedDate:
          return this.sortByUploadedDate(this.documents, this.sortingOrder);

        case SortBy.User:
          return this.sortByUser(this.documents, this.sortingOrder);

        default:
          return this.sortByDefault(this.documents);
      }
    },
    sortByNameOrder() {
      return chooseOrderDirection(this.sortBy, SortBy.Name, this.sortingOrder);
    },
    sortByModifiedDateOrder() {
      return chooseOrderDirection(this.sortBy, SortBy.ModifiedDate, this.sortingOrder);
    },
    sortByUploadedDateOrder() {
      return chooseOrderDirection(this.sortBy, SortBy.UploadedDate, this.sortingOrder);
    },
    sortByUserOrder() {
      return chooseOrderDirection(this.sortBy, SortBy.User, this.sortingOrder);
    },
  },
  methods: {
    useSortingByName(newOrder) {
      this.sortBy = SortBy.Name;
      this.sortingOrder = newOrder;
    },
    useSortingByModifiedDate(newOrder) {
      this.sortBy = SortBy.ModifiedDate;
      this.sortingOrder = newOrder;
    },
    useSortingByUploadedDate(newOrder) {
      this.sortBy = SortBy.UploadedDate;
      this.sortingOrder = newOrder;
    },
    useSortingByUser(newOrder) {
      this.sortBy = SortBy.User;
      this.sortingOrder = newOrder;
    },
    sortByDefault(documents) {
      return new Documents(documents, this.categories).orderedByDocumentType();
    },
    sortByName(documents, order) {
      return documents.sort((a, b) => {
        return a.fullName.localeCompare(b.fullName) * order;
      });
    },
    sortByModifiedDate(documents, order) {
      return documents.sort((a, b) => {
        return (a.lastModifiedAt - b.lastModifiedAt) * order;
      });
    },
    sortByUploadedDate(documents, order) {
      return documents.sort((a, b) => {
        return (a.createdAt - b.createdAt) * order;
      });
    },
    sortByUser(documents, order) {
      return documents.sort((a, b) => {
        return (a.lastModifiedByName || "").localeCompare(b.lastModifiedByName || "") * order;
      });
    },
    editDocument(document) {
      if (this.allowEdit && !this.blockUI(document)) {
        this.$emit("on-edit", document);
      }
    },
    documentRowClasses(document) {
      if (this.selectedDocument && document.id === this.selectedDocument.id) {
        return "selected";
      } else {
        return undefined;
      }
    },
    blockUI(document) {
      return this.blockedDocumentIds.includes(document.id);
    },
  },
};
</script>
