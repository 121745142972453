<template>
  <div class="details-row row non-total-row">
    <div v-if="creditsExist" class="interest-row">
      <div class="col-12">
        <h4>Credits</h4>
      </div>
      <div class="col-6">
        <row label="Current B-Piece" :value="bPieceAmount" />
        <row label="Current B-Piece" :value="bPieceAmount" />
        <row
          v-if="isDutch"
          label="Remaining Construction Reserve"
          :value="constructionReserveAmount"
        />
        <row v-if="isEstimatePayoffQuote" label="Suspense Balance" :value="suspenseBalance" />
        <row
          v-if="isEstimatePayoffQuote && showEscrowReserve"
          label="Escrow Reserve"
          :value="escrowReserveAmount"
        />
        <row label="Remaining Other Reserve" :value="otherReserveAmount" />
        <row
          label="Waived Principal, Interest and Fees"
          :value="waivedPrincipalInterestFeesAmount"
          total
        />
        <row label="Total Credits" :value="totalCredits" total last />
      </div>
    </div>
    <div v-else class="interest-row">
      <div class="col-12">
        <h4>Credits</h4>
      </div>
      <div class="col-12">No Credits</div>
    </div>
  </div>
</template>
<script>
import PayoffStatementShowDetailsMixin from "components/manage/payoff_statement/show/details_mixin";
import Row from "./row";
import NumberFormatter from "models/formatters/number_formatter";
import { mapState } from "pinia";
import useResaleStatementStore from "controllers/manage/store/resaleStatement";

export default {
  components: { Row },
  mixins: [PayoffStatementShowDetailsMixin],
  computed: {
    ...mapState(useResaleStatementStore, ["statement"]),
    bPieceAmount() {
      return this.formatCurrency(this.statement.bPieceAmount);
    },
    constructionReserveAmount() {
      return this.formatCurrency(this.statement.constructionReserveAmount);
    },
    suspenseBalance() {
      return this.formatCurrency(this.statement.suspenseBalance);
    },
    escrowReserveAmount() {
      return this.formatCurrency(this.statement.escrowReserveAmount);
    },
    otherReserveAmount() {
      return this.formatCurrency(this.statement.otherReserveAmount);
    },
    waivedPrincipalInterestFeesAmount() {
      return this.formatCurrency(this.statement.waivedPrincipalInterestFeesAmount);
    },
    totalCredits() {
      return this.formatCurrency(this.statement.totalCredits);
    },
    isEstimatePayoffQuote() {
      return this.statement.statementType === "estimate_payoff_quote";
    },
    showEscrowReserve() {
      return this.statement.escrowReserveAmount <= 0;
    },
  },
  methods: {
    formatCurrency(value) {
      return NumberFormatter.formatAccounting(Number.parseFloat(value));
    },
  },
};
</script>
