const ASSIGNMENT_TRANSFERS_DOC_TYPES = ["assignment_transfers"];
const ENTITY_DOC_TYPES = ["corp_entity_doc"];
const COMMERCIAL_DOC_TYPES = ["environmental", "property_condition_report", "survey"];

const COMMERCIAL_PROPERTY_TYPES = [
  "multifamily",
  "mixed_use",
  "office",
  "retail",
  "industrial_warehouse",
  "land",
  "mobile_home_park",
  "self_storage",
  "hospitality",
  "other",
  "special_purpose",
];

const NOTE_ORIGINATION_FOR_ASSIGNMENT_TRANSFERS = ["funded", "not_funded"];

export default class DocumentTypes {
  static sortByName(documentA, documentB) {
    if (documentA === undefined && documentB === undefined) {
      return 0;
    }
    if (documentA === undefined && documentB !== undefined) {
      return 1;
    }
    if (documentA !== undefined && documentB === undefined) {
      return -1;
    }
    if (documentA.documentType === "other" && documentB.documentType !== "other") {
      return 1;
    }
    if (documentA.documentType !== "other" && documentB.documentType === "other") {
      return -1;
    }

    return documentA.text.localeCompare(documentB.text);
  }

  static sortByNameUntypedFirst(documentA, documentB) {
    if (documentA === undefined && documentB === undefined) {
      return 0;
    }
    if (documentA === undefined && documentB !== undefined) {
      return -1;
    }
    if (documentA !== undefined && documentB === undefined) {
      return 1;
    }
    if (documentA.documentType === "other" && documentB.documentType !== "other") {
      return 1;
    }
    if (documentA.documentType !== "other" && documentB.documentType === "other") {
      return -1;
    }

    return documentA.text.localeCompare(documentB.text);
  }

  static sortByPriority(priority) {
    return (a, b) => {
      let priorityA = (a.displayOptions || {})[priority];
      let priorityB = (b.displayOptions || {})[priority];

      if (!priorityA && priorityA !== 0 && (!priorityB && priorityB !== 0)) {
        return 0;
      }
      if (priorityA === undefined) return 1;
      if (priorityB === undefined) return -1;

      return priorityA - priorityB;
    };
  }

  static wrapToMap(types) {
    const map = {};
    types.forEach(type => (map[type.documentType] = type));

    return map;
  }

  static orderByName(types) {
    return types.slice().sort(DocumentTypes.sortByName);
  }

  constructor(documentTypes) {
    this.types = documentTypes.sort(DocumentTypes.sortByName);
  }

  diligenceToolOnly() {
    this.types = this.types.filter(docType => docType.displayOptions.ddTool);

    return this;
  }

  whenLoanIsEntity(isEntity) {
    this.types = this.types.filter(docType => {
      if (ENTITY_DOC_TYPES.includes(docType.documentType)) {
        return isEntity;
      } else {
        return true;
      }
    });

    return this;
  }

  whenLoanHasNoAssignments(lenderNoteStatus) {
    if (NOTE_ORIGINATION_FOR_ASSIGNMENT_TRANSFERS.includes(lenderNoteStatus)) {
      return this;
    }

    this.types = this.types.filter(docType => {
      return !ASSIGNMENT_TRANSFERS_DOC_TYPES.includes(docType.documentType);
    });

    return this;
  }

  whenPropertyTypeForCommercialLoans(loanPropertyType) {
    this.types = this.types.filter(docType => {
      if (COMMERCIAL_DOC_TYPES.includes(docType.documentType)) {
        return COMMERCIAL_PROPERTY_TYPES.includes(loanPropertyType);
      } else {
        return true;
      }
    });

    return this;
  }

  orderByDDToolPriority() {
    this.types = this.types.sort(DocumentTypes.sortByPriority("ddToolPriority"));

    return this;
  }
}

PS.Collections.DocumentTypes = DocumentTypes;
