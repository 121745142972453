<template>
  <table class="table">
    <thead>
      <tr>
        <th><div><span class="title">PS Loan ID</span></div></th>
        <th><div><span class="title">Servicer ID</span></div></th>
        <th><sorting-header
          title="Asset Manager"
          :order="isSorted('assetManagerName')"
          @on-change-direction="v => $emit('update-sort-column', 'assetManagerName', v)"
        /></th>
        <th><div><span class="title">Property Address</span></div></th>
        <th><sorting-header
          title="Loan Action"
          :order="isSorted('loanForeclosureActionLabel')"
          @on-change-direction="v => $emit('update-sort-column', 'loanForeclosureActionLabel', v)"
        /></th>
        <th><sorting-header
          title="Loan Status"
          :order="isSorted('servicerLoanStatus')"
          @on-change-direction="v => $emit('update-sort-column', 'servicerLoanStatus', v)"
        /></th>
        <th><sorting-header
          title="Days Past Due"
          :order="isSorted('daysPastDue')"
          @on-change-direction="v => $emit('update-sort-column', 'daysPastDue', v)"
        /></th>
        <th><sorting-header
          title="Days To Maturity"
          :order="isSorted('daysToMaturity')"
          @on-change-direction="v => $emit('update-sort-column', 'daysToMaturity', v)"
        /></th>
        <th><sorting-header
          title="Owner"
          :order="isSorted('loanOwner')"
          @on-change-direction="v => $emit('update-sort-column', 'loanOwner', v)"
        /></th>
        <th><sorting-header
          title="Amount on PeerStreet Warehouse Line(s)"
          :order="isSorted('amountOnPsWarehouseLine')"
          @on-change-direction="v => $emit('update-sort-column', 'amountOnPsWarehouseLine', v)"
        /></th>
        <th><sorting-header
          title="Unpaid Principal Balance"
          :order="isSorted('unpaidPrincipalBalance')"
          @on-change-direction="v => $emit('update-sort-column', 'unpaidPrincipalBalance', v)"
        /></th>
        <th><sorting-header
          title="Last Contact Date"
          :order="isSorted('lastOutreachDate')"
          @on-change-direction="v => $emit('update-sort-column', 'lastOutreachDate', v)"
        /></th>
        <th><sorting-header
          title="Last Contact Status"
          :order="isSorted('lastOutreachStatusLabel')"
          @on-change-direction="v => $emit('update-sort-column', 'lastOutreachStatusLabel', v)"
        /></th>
      </tr>
    </thead>
    <tbody>
      <slot />
    </tbody>
  </table>
</template>

<script>
import SortingHeader from "components/shared/sorting_header";

export default {
  name: "loan-tracker-table",
  components: {
    SortingHeader,
  },
  props: {
    sortColumn: {
      type: String,
      default: "lastOutreachDate",
    },
    sortDirection: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    isSorted(columnName) {
      return columnName === this.sortColumn ? this.sortDirection : 0;
    },
  },
};
</script>
