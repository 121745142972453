<template>
  <div class="time-series-comparison-chart">
    <h3 class="title">
      {{chart.title}}
      <div v-if="sources.isLoading" class="ps-loader ps-loader-small"></div>
    </h3>
    <slot name="header"></slot>
    <div class="body">
      <div v-if="hasTitle" class="axes-title">
        {{chart.axes.y.title}}
        <span v-if="chart.axes.y2">
          {{chart.axes.y2.title}}
        </span>
      </div>
      <div class="chart" ref="chart"></div>
      <div v-if="!chart.hideLegend" class="legend">
        <span class="legend-item"
          v-for="(column, key) in sources.data"
          :key="key"
          :class="legendClasses[key]"
          @mouseover="onMouseOver(key)"
          @mouseout="onMouseOut(key)"
          @click="onClick(key)">

          <span :style="{ backgroundColor: chart.columns[key].color }"></span>
          {{chart.columns[key].observed.data}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { format, timeFormat } from "d3";
import c3 from "c3";
import MarketDataChartMixin from "components/market_data/market_data_chart_mixins";

export default {
  name: "time-series-comparison-chart",
  mixins: [MarketDataChartMixin],
  data() {
    return {
      options: {
        padding: this.chart.padding,
        axis: {
          x: {
            type: "timeseries",
            tick: {
              values: this.chart.axisXTickValues,
              count: this.xAxisTickCount,
              format: "%b %Y",
            },
          },
          y: {
            min: this.chart.axisYMin,
            max: this.chart.axisYMax,
            tick: {
              values: this.chart.axisYTickValues,
              count: this.chart.axisYTickCount,
              format: this.chart.tickFormat,
            },
          },
        },
        grid: {
          y: {
            show: true,
            tick: {
              format: this.chart.tickFormat,
            },
          },
        },
        point: {
          r: 0,
          focus: {
            expand: {
              r: 5,
            },
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          contents: function(data, defaultTitleFormat, defaultValueFormat, color) {
            let deduplicatedData = data.filter(d => {
              let baseId = d.id.replace(/ \(forecast\)/, "");
              let isForecast = d.id.match(/forecast/);
              let hasObservedData = data.findIndex(d => d.id === baseId) !== -1;
              let isDuplicate = isForecast && hasObservedData;

              return !isDuplicate;
            });

            return this.getTooltipContent(
              deduplicatedData,
              defaultTitleFormat,
              defaultValueFormat,
              color
            );
          },
          format: {
            value: this.chart.tooltipFormat || format(",.2f"),
            title: this.chart.tooltipTitleFormat || timeFormat("%b %Y"),
          },
        },
        size: {
          height: undefined,
        },
      },
      legendClasses: this.prepareLegendClasses(),
    };
  },
  computed: {
    hasTitle() {
      return (
        this.chart.axes &&
        ((this.chart.axes.y && this.chart.axes.y.title) ||
          (this.chart.axes.y2 && this.chart.axes.y2.title))
      );
    },
    xAxisTickCount() {
      if (!this.sources.hasData) {
        return;
      }

      return this.chart.axisXTickCount || 10;
    },
  },
  methods: {
    render() {
      this.options.size.height = this.chart.sizeHeight;
      this.legendClasses = this.prepareLegendClasses();
      this.options.data = this.prepareData();

      // show some of the data on y2 axis, if present
      if (this.chart.axes && this.chart.axes.y2) {
        this.options.axis.y2 = { show: true };
        this.options.data.axes = this.chart.axes.y2.columns.reduce((axes, key) => {
          axes[this.chart.columns[key].observed.data] = "y2";
          axes[this.chart.columns[key].predicted.data] = "y2";

          return axes;
        }, {});
      }

      this.c3 = c3.generate({
        ...this.options,
        bindto: this.$refs.chart,
      });
    },
    prepareData() {
      let columns = this.chart.columns;
      let data = this.sources.data;
      let dataKeys = Object.keys(data);

      return {
        empty: {
          label: {
            text: this.chartMessage,
          },
        },
        type: this.chart.dataType || "spline",
        xs: dataKeys.reduce((obj, key) => {
          obj[columns[key].observed.data] = columns[key].observed.labels;
          obj[columns[key].predicted.data] = columns[key].predicted.labels;

          return obj;
        }, {}),
        columns: dataKeys.reduce((arr, key) => {
          arr.push([columns[key].observed.data, ...data[key].observed.data]);
          arr.push([columns[key].observed.labels, ...data[key].observed.labels]);

          arr.push([
            columns[key].predicted.data,
            ...data[key].observed.data.slice(-1),
            ...data[key].predicted.data,
          ]);
          arr.push([
            columns[key].predicted.labels,
            ...data[key].observed.labels.slice(-1),
            ...data[key].predicted.labels,
          ]);

          return arr;
        }, []),
        colors: dataKeys.reduce((obj, key) => {
          obj[columns[key].observed.data] = columns[key].color;
          obj[columns[key].predicted.data] = columns[key].color;

          return obj;
        }, {}),
      };
    },
    prepareLegendClasses() {
      return Object.keys(this.chart.columns).reduce((obj, key) => {
        obj[key] = {
          fade: false,
          off: false,
        };

        return obj;
      }, {});
    },
    onMouseOver(key) {
      if (this.legendClasses[key].off) return;

      this.c3.focus([
        this.chart.columns[key].observed.data,
        this.chart.columns[key].predicted.data,
      ]);

      Object.keys(this.legendClasses).forEach(k => {
        this.legendClasses[k].fade = k !== key;
      });
    },
    onMouseOut() {
      this.c3.revert();

      Object.keys(this.legendClasses).forEach(k => {
        this.legendClasses[k].fade = false;
      });
    },
    onClick(key) {
      this.c3.toggle([
        this.chart.columns[key].observed.data,
        this.chart.columns[key].predicted.data,
      ]);

      Object.keys(this.legendClasses).forEach(k => {
        this.legendClasses[k].fade = false;
      });
      this.legendClasses[key].off = !this.legendClasses[key].off;
    },
  },
};
</script>
