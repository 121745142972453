import truncate from "lodash/truncate";

const truncateMemo = value => truncate(value, { length: 35, omission: "" }).trim();

export default class WireInstruction {
  constructor(wireInstruction = {}) {
    this.beneficiaryName = wireInstruction.beneficiaryName;
    this.borrowerName = wireInstruction.borrowerName;
    this.escrowFileNumber = wireInstruction.escrowFileNumber;
    this.escrowTitleOfficer = wireInstruction.escrowTitleOfficer;
    this.loanId = truncateMemo(wireInstruction.memo1);
    this.loanName = truncateMemo(wireInstruction.memo3);
    this.memo = wireInstruction.optionalMemo;
    this.bankWireTemplateId = wireInstruction.bankWireTemplateId;
    this.accountNumberToken = wireInstruction.accountNumberToken;
    this.routingNumberToken = wireInstruction.routingNumberToken;
    this.drawWireType = wireInstruction.drawWireType;
  }

  static paramsFromLoan(loan) {
    return {
      beneficiaryName: "",
      borrowerName: "",
      escrowFileNumber: "",
      escrowTitleOfficer: "",
      memo1: loan.toParam,
      memo2: "",
      memo3: loan.fullAddress,
      bankWireTemplateId: "",
      accountNumberToken: "",
      routingNumberToken: "",
    };
  }

  memoPreview() {
    return [`${this.memo1()}`, `${this.memo2()}`, `${this.memo3()}`, `${this.memo4()}`].join("\n");
  }

  memo1() {
    return truncateMemo(this.loanId);
  }

  memo2() {
    return truncateMemo(this._generateLine([["escrowFileNumber", "FN"], ["borrowerName", "BWR"]]));
  }

  memo3() {
    return truncateMemo(this.loanName);
  }

  memo4() {
    return truncateMemo(this._generateLine([["escrowTitleOfficer", "TO"], ["memo", "MEMO"]]));
  }

  _generateLine(attrs) {
    const values = [];
    attrs.forEach(([attr, prefix]) => {
      if (this[attr]) {
        values.push(`${prefix}: ${this[attr]}`);
      }
    });
    return values.join(", ");
  }

  toHash() {
    return {
      bank_wire_template_id: this.bankWireTemplateId,
      beneficiary_name: this.beneficiaryName.trim(),
      borrower_name: this.borrowerName ? this.borrowerName.trim() : null,
      escrow_file_number: this.escrowFileNumber.trim(),
      escrow_title_officer: this.escrowTitleOfficer.trim(),
      account_number_token: this.accountNumberToken.trim(),
      routing_number_token: this.routingNumberToken.trim(),
      optional_memo: this.memo ? this.memo.trim() : undefined,
      memo_1: this.memo1(),
      memo_2: this.memo2(),
      memo_3: this.memo3(),
      memo_4: this.memo4(),
      draw_wire_type: this.drawWireType,
    };
  }
}
