<template>
  <div>
    <time-series-comparison-chart
      :chart="chart"
      :sources="sources">
    </time-series-comparison-chart>

    <div class="row listing-stats-explanation-section">
      <div class="col-md-5">
        Current Job Growth
      </div>
      <div class="col-md-1 value-section">
        <span v-if="sources.isLoading">
          &nbsp;
        </span>
        <span v-if="sources.isSuccessful">
          {{ sources.msaMostRecent | percent(0, true) | notAvailable }}
        </span>
      </div>
      <div class="col-md-5">
        National Average
      </div>
      <div class="col-md-1 value-section">
        <span v-if="sources.isLoading">
          &nbsp;
        </span>
        <span v-if="sources.isSuccessful">
          {{ sources.nationalMostRecent | percent(0, true) | notAvailable }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "d3";

import TimeSeriesComparisonChart from "components/market_data/time_series_comparison_chart";

export default {
  name: "job-growth-chart",
  components: {
    TimeSeriesComparisonChart,
  },
  props: {
    sources: Object,
  },
  data() {
    return {
      chart: {
        title: "Job Growth (Non-Agriculture)",
        tickFormat: format(",.0%"),
        tooltipFormat: format(",.2%"),
        columns: {
          msaJobGrowth: {
            color: "#66cc33",
            observed: {
              data: "MSA Job Growth",
              labels: "msa-job-growth",
            },
            predicted: {
              data: "MSA Job Growth (forecast)",
              labels: "msa-job-growth-predicted",
            },
          },
          nationalJobGrowth: {
            color: "#5b6770",
            observed: {
              data: "National Job Growth",
              labels: "national-job-growth",
            },
            predicted: {
              data: "National Job Growth (forecast)",
              labels: "national-job-growth-predicted",
            },
          },
        },
      },
    };
  },
};
</script>
