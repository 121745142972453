<template>
  <div class="autocomplete">
    <input
      v-model="dataSource.query"
      class="search-bar js-autocomplete-active"
      type="text"
      :disabled="!searchEnabled"
      :placeholder="placeholder"
      @focus="showSuggestions"
      @keydown.down="applyNextSuggestion"
      @keydown.enter="selectItem"
      @keydown.esc="hideSuggestions"
      @keydown.up="applyPrevSuggestion"
    >
    <ul v-show="suggestionsEnabled" class="suggestions">
      <li
        v-for="(suggestion, index) in dataSource.suggestions"
        :key="suggestion.id"
        class="suggestion js-autocomplete-active"
        :class="{ selected: isSelected(index) }"
        @click="applySuggestionWith(index)"
      >
        <span
          v-for="(match, index) in suggestion.itemMatches"
          :key="index"
          class="match js-autocomplete-active"
          :class="{ highlighted: match.highlighted }"
        >
          {{ match.text }}
        </span>
      </li>
    </ul>
    <a
      v-show="!loading"
      class="btn btn-action-search search-button js-autocomplete-active"
      @click="selectItem"
    >
      <i class="fas fa-search js-autocomplete-active" />
    </a>
    <div v-show="loading" class="ps-loader ps-loader-small search-loader" />
    <div v-show="hasErrors" class="error">
      {{ topPriorityError }}
    </div>
  </div>
</template>

<script>
import debounce from "utils/debounce";

const Autocomplete = PS.Models.Autocomplete;
const Commands = Autocomplete.Commands;
const Events = Autocomplete.Events;

const CONTROL_STATE = PS.Models.Autocomplete.Const.CONTROL_STATE;
const INTERACTIVE_CLASS = "js-autocomplete-active";
const MIN_CHARS_TO_SEARCH = PS.Models.Autocomplete.Const.MIN_CHARS_TO_SEARCH;

const SEARCH_DELAY_MS = 350;

export default Vue.component("autocomplete", {
  props: {
    placeholder: String,
    dataSource: Object,
    minCharsToSearch: {
      type: Number,
      default: MIN_CHARS_TO_SEARCH,
    },
    transferSearch: Boolean,
  },
  data() {
    return {
      autocompleteRulebook: undefined,
      bus: PS.Services.MessageBusService.autocompleteBus,
      selectedSuggestion: undefined,
      selectedPosition: -1,
      controlState: CONTROL_STATE.EMPTY,
      ignoreNextQueryUpdate: false,
      searchEnabled: false,
    };
  },
  mounted() {
    this.autocompleteRulebook = new Autocomplete.Rule(this);
    this.bus.attachRulebook(this.autocompleteRulebook);

    this.dataSource.setupAutocomplete();
    document.addEventListener("click", this.clickOutside, true);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutside);
    this.bus.detachRulebook(this.autocompleteRulebook);
  },
  methods: {
    clickOutside(e) {
      const clickInsideControl = e.target.classList.contains(INTERACTIVE_CLASS);
      if (!clickInsideControl) {
        this.hideSuggestions();
      }
    },
    selectItem() {
      if (this.transferSearch && this.selectedPosition >= 0) {
        this.dataSource.transferToReport(this.selectedPosition);
      }

      new Commands.RequestResultSelection(
        new Events.AutocompleteSearchRequested({
          query: this.dataSource.query,
          sourceId: this.dataSource.sourceId,
        })
      ).execute();
    },
    applyNextSuggestion() {
      if (!this.suggestionsEnabled) {
        return;
      }

      new Commands.RequestNextSuggestion(
        new Events.AutocompleteEvent({ sourceId: this.dataSource.sourceId })
      ).execute();
    },
    applyPrevSuggestion() {
      if (!this.suggestionsEnabled) {
        return;
      }

      new Commands.RequestPrevSuggestion(
        new Events.AutocompleteEvent({ sourceId: this.dataSource.sourceId })
      ).execute();
    },
    applySuggestionWith(index) {
      if (this.transferSearch && index >= 0) {
        this.dataSource.transferToReport(index);
      }

      new Commands.RequestSuggestionSelect(
        new Events.SuggestionSelectRequested({
          suggestionIndex: index,
          sourceId: this.dataSource.sourceId,
        })
      ).execute();
    },
    showSuggestions() {
      new Commands.RequestShowSuggestions(
        new Events.AutocompleteEvent({ sourceId: this.dataSource.sourceId })
      ).execute();
    },
    hideSuggestions() {
      new Commands.RequestHideSuggestions(
        new Events.AutocompleteEvent({ sourceId: this.dataSource.sourceId })
      ).execute();
    },
    isSelected(index) {
      return this.selectedPosition === index;
    },
  },
  watch: {
    "dataSource.query": debounce(function(newQuery, oldQuery) {
      const ignoreWhitespaces = newQuery && oldQuery && newQuery.trim() === oldQuery.trim();
      if (ignoreWhitespaces) {
        return;
      }

      new Commands.RequestAutocomplete(
        new Events.AutocompleteSearchRequested({
          query: newQuery,
          sourceId: this.dataSource.sourceId,
        })
      ).execute();
    }, SEARCH_DELAY_MS),
  },
  computed: {
    suggestions() {
      return this.dataSource.suggestions;
    },
    suggestionsEnabled() {
      return this.controlState === CONTROL_STATE.SHOW_SUGGESTIONS;
    },
    loading() {
      return this.controlState === CONTROL_STATE.LOADING;
    },
    hasErrors() {
      return this.dataSource.errors.length > 0;
    },
    topPriorityError() {
      return this.dataSource.errors[0];
    },
  },
});
</script>
