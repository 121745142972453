<template>
  <div>
    <select2
      v-model="innerValue"
      :name="name"
      :class="classes"
      :options="options"
    />
    <div v-if="invalid" class="invalid-feedback">
      {{ errors.collect(name).join(",") }}
    </div>
  </div>
</template>

<script>
import filter from "lodash/filter";
import get from "lodash/get";

import Select2 from "components/shared/select2";

const DISTRIBUTION_GROUP_NAME = "distribution_fees";

export default {
  inject: ["$validator"],
  $_veeValidate: {
    value() {
      return this.innerValue;
    },
  },
  components: {
    select2: Select2,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    feeTypes: {
      type: Array,
      default: () => PSData.feeTypes,
    },
  },
  data() {
    return { innerValue: this.value };
  },
  computed: {
    dirty() {
      return get(this.fields, [this.name, "dirty"], false);
    },
    invalid() {
      return this.errors.has(this.name);
    },
    classes() {
      return {
        "form-control": true,
        "select2-hidden-accessible": true,
        "is-invalid": this.invalid,
        "is-valid": this.dirty && !this.invalid,
      };
    },
    selectableFeeTypes() {
      return filter(PSData.feeTypes, ({ groups }) => groups.includes(DISTRIBUTION_GROUP_NAME));
    },
    options() {
      return {
        placeholder: "Fee Types",
        width: "100%",
        data: this.selectableFeeTypes.map(({ name: id, label: text }) => ({ id, text })),
      };
    },
  },
  watch: {
    innerValue(v) {
      this.$emit("input", v);
    },
    value(v) {
      this.innerValue = v;
    },
  },
};
</script>
