<template>
  <select2
    :value="value"
    class="form-control"
    :options="{ data, placeholder, multiple: false, width: 250 }"
    @input="handleInput"
  />
</template>

<script>
import Select2 from "components/shared/select2";
import { RequestFilterLoansByInterval } from "models/reports/hotlist_loans/commands/hotlist_loans_commands";
import Event from "models/event";

export const DEFAULT_DATA = PS.Models.Reports.Periods.reportableMonths().map(
  ({ shortFormat: id, fullFormat: text }) => ({ id, text })
);

export default {
  components: {
    select2: Select2,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    paidOffOnly: Boolean,
    placeholder: {
      type: String,
      default: "Select A Month...",
    },
    data: {
      type: Array,
      default: () => DEFAULT_DATA,
    },
  },
  mounted() {
    this.handleInput(this.value);
  },
  methods: {
    handleInput(value) {
      new RequestFilterLoansByInterval(new Event(), {
        interval: value,
        paidOffOnly: this.paidOffOnly,
      }).execute();
    },
  },
};
</script>
