<template>
  <div class="row">
    <div class="col-sm-6">
      <detail-form v-bind="$props" v-on="$listeners" />
    </div>
    <div class="col-sm-6">
      <detail-reference :reference="value.reference" />
    </div>
  </div>
</template>

<script>
import DetailForm from "./form";
import DetailReference from "./reference";

export default {
  components: {
    DetailForm,
    DetailReference,
  },
  props: {
    refreshAccruedInterest: Boolean,
    useHoldActions: { type: Boolean, default: false },
    value: {
      type: Object,
      default: null,
    },
  },
};
</script>
