const FORMULA_LIST = {
  // Note
  ORIGINATOR_SKIN_IN_THE_GAME: `
    :junior_lien_details.current.originator_skin_in_the_game_amount /
      (:loan_amount + :junior_lien_details.current.junior_lien_amount)
  `,

  ESCROW_BALANCE_AT_ORIGINATION: `
    :taxes_and_insurance_details.current.tax_escrow_at_origination + :taxes_and_insurance_details.current.insurance_escrow_at_origination
  `,

  JUNIOR_LIEN_POSITION: `
    PS.Models.OrdinalSuffixFormatter.addSuffix(
      :junior_lien_details.$collection.$index + 2
    )
  `,

  JUNIOR_LIEN_HOLDERS: `
    PS.Models.Diligence.FormulaHelper.joinJuniorLienHolders(
      :junior_lien_details.$collection.junior_lien_holder
    )
  `,

  JUNIOR_LIEN_TOTAL: `
    :junior_lien_details.all.junior_lien_amount
  `,

  TOTAL_PRINCIPAL_PAYMENTS: `
    :scheduled_principal_payments + :unscheduled_principal_payments
  `,

  // Credit report
  FORMULA_AVERAGE_CREDIT_SCORE: `
    PS.Models.Diligence.ScoreCalculator.calculatedCreditScore([
      :credit_report.current.experian_credit_scores,
      :credit_report.current.transunion_credit_scores,
      :credit_report.current.equifax_credit_scores
    ])
  `,

  PRIMARY_CREDIT_SCORE: `
     PS.Models.Diligence.ScoreCalculator.max(
       :credit_report.$collection.formula_average_credit_score
     )
   `,

  // Appraisal or BPO
  LENDER_PSF_VALUE: `
    :valuation.current.lender_value /
      :valuation.current.lender_square_footage
  `,

  LENDER_AS_IS_LTV: `
      (:loan_amount - :loan_b_piece_amount - :loan_total_principal_payments -
      ((:construction_amount || 0) - (:construction_reserve_disbursed_at_valuation || 0))) /
        :valuation.current.lender_value
  `,

  LENDER_ARV_LTV: `
      (:loan_amount - :loan_b_piece_amount - :loan_total_principal_payments) /
        :valuation.current.lender_arv_lender
  `,

  FORMULA_EFFECTIVE_LTV: `
    (:loan_amount - :construction_amount - :loan_b_piece_amount) /
      Math.min(:valuation.all.lender_value, :validation.all.ps_value)
  `,

  // SETTLEMENT STATEMENT
  FORMULA_TOTAL_ORIGINATION_FEES: `
    PS.Models.Diligence.FormulaHelper.sumPartialValues([
      :lender_origination_fees,
      :third_party_origination_fees,
      :ps_origination_fees
    ])
  `,

  // RENT & LEASES
  UNIT_LEASE_TERM: `
    Math.floor(new PS.Models.Diligence.TermCalculator(
      :unit.current.unit_lease_start_date,
      :unit.current.unit_lease_end_date
    ).months())
  `,

  UNIT_REMAINING_TERM: `
    Math.floor(new PS.Models.Diligence.TermCalculator(
      Date.now(),
      :unit.current.unit_lease_end_date
    ).months())
  `,

  TOTAL_RENT_PER_MONTH: `
    :unit.all.unit_rent_monthly
  `,
};

for (let name in FORMULA_LIST) {
  FORMULA_LIST[name] = normalizeFormula(FORMULA_LIST[name]);
}

function normalizeFormula(str) {
  return str.trim().replace(/\s+/g, " ");
}

class FormulaList {
  static find(formulaName) {
    return FORMULA_LIST[formulaName.toUpperCase()];
  }
}

PS.Models.FormulaList = FormulaList;
