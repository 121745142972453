<template>
  <tr>
    <td>
      <div class="checkbox-control">
        <input type="checkbox" :id="loan.psId" v-model="loan.checked" @click="sendLoanUpdate">
        <label :for="loan.psId"><span class="control-toggle"></span></label>
      </div>
    </td>
    <td>{{loan.psId}}</td>
    <td>{{loan.fullAddress}}</td>
    <td>
      <div
        v-for="series in loan.series"
        :key="series.id"
      >
        {{series.amount | money(2) }}
      </div>
    </td>
    <td>
      <div
        v-for="series in loan.series"
        :key="series.id"
      >
        {{series.fundingEntity.label}}
      </div>
    </td>
  </tr>
</template>
<script>
import Event from "models/event";
import { SendLoanUpdate } from "models/funding_entities/commands";

export default {
  props: {
    loan: Object,
  },
  methods: {
    sendLoanUpdate() {
      new SendLoanUpdate(new Event(), {}).execute();
    },
  },
};
</script>
