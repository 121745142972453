<template>
  <diligence-document-field :document="document" :field="field">
    <div class="col-5">
      <input
        v-if="field.mask.type == 'integer'"
        :id="field.id"
        v-model="field.value"
        v-mask:integer="field.mask.options"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @blur="saveValueImmediately"
      />
      <input
        v-else-if="field.mask.type == 'number'"
        :id="field.id"
        v-model="field.value"
        v-mask:number="field.mask.options"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @blur="saveValueImmediately"
      />
      <input
        v-else-if="field.mask.type == 'currency'"
        :id="field.id"
        v-model="field.value"
        v-mask:currency="field.mask.options"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @blur="saveValueImmediately"
      />
      <input
        v-else-if="field.mask.type == 'percentage'"
        :id="field.id"
        v-model="field.value"
        v-mask:percentage="field.mask.options"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @blur="saveValueImmediately"
      />
      <input
        v-else-if="field.mask.type == 'date'"
        :id="field.id"
        v-model="field.value"
        v-mask:date="field.mask.options"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @blur="saveValueImmediately"
      />
      <input
        v-else-if="field.mask.type == 'time'"
        :id="field.id"
        v-model="field.value"
        v-mask:time="field.mask.options"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @blur="saveValueImmediately"
      />
      <input
        v-else-if="field.mask.type == 'phone'"
        :id="field.id"
        v-model="field.value"
        v-mask:phone="field.mask.options"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @blur="saveValueImmediately"
      />
      <input
        v-else-if="field.mask.type == 'visible'"
        :id="field.id"
        v-model="field.value"
        v-mask:visible="field.mask.options"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @blur="saveValueImmediately"
      />
      <input
        v-else-if="field.mask.type == 'zip'"
        :id="field.id"
        v-model="field.value"
        v-mask:zip="field.mask.options"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @blur="saveValueImmediately"
      />
      <input
        v-else-if="field.mask.type === 'year'"
        :id="field.id"
        v-model="field.value"
        v-mask:year="field.mask.options"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @change="saveValueImmediately"
      />
      <input
        v-else
        :id="field.id"
        v-model="field.value"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @blur="saveValueImmediately"
      />
    </div>
  </diligence-document-field>
</template>

<script>
import BaseTextField from "./base/text_field";

const TextField = BaseTextField.extend({});

export default Vue.component("diligence-document-text-field", TextField);
</script>
