<template>
  <div class="details-row row">
    <div class="col-12">
      <h4>Interest</h4>
    </div>
    <div class="col-12">
      <h5>Rate Details</h5>
    </div>
    <div class="col-6 border-row">
      <editable-field
        label="Interest Rate"
        :value="statement.interestRate"
        :editing="false"
        percentage
      />
    </div>
    <div class="col-6">
      <editable-field
        label="Default Rate"
        :value="statement.defaultRate"
        :editing="false"
        percentage
      />
    </div>
    <div class="col-6">
      <editable-field
        label="Originator Servicing Spread"
        :value="statement.lenderRate"
        :editing="false"
        percentage
      />
    </div>
    <div class="col-12">
      <h5>Note Interest Calculation</h5>
    </div>
    <div class="col-6">
      <editable-field
        label="Note Interest Accrual"
        :value="statement.interestAccrualMethodText"
        :editing="false"
      />
    </div>
    <div class="col-6">
      <editable-field
        label="Note Interest Calculation"
        :value="statement.interestCalculationMethodText"
        :editing="false"
      />
    </div>
    <div class="interest-row non-total-row">
      <!-- Left Column: Interest Calculations -->
      <div class="col-6">
        <h5>Interest Calculation</h5>
        <row label="Interest Due" :value="currentInterestDue" />
        <row label="Deferred Interest" :value="deferredInterestAmount" />
        <row label="Unpaid Interest" :value="unpaidInterestDueFromBorrowerAmount" />
        <row label="Lender Spread Interest Credit" :value="interestLenderSpreadAmount" />
        <row label="Lender B-Piece Interest Credit" :value="interestBPieceAmount" />
        <row label="Total Interest Due" :value="totalInterest" total />
        <row label="Waived Interest" :value="statement.waivedInterestValue" total />
        <row label="Waived Deferred Interest" :value="statement.waivedDeferredInterestValue" total />
        <row label="Waived Unpaid Interest" :value="statement.waivedUnpaidInterestValue" total />
        <row label="Net Interest Due" :value="netInterest" total last />
      </div>
      <!-- Right Column: Default Interest Calculations -->
      <div class="col-6">
        <h5>Default Interest Calculation</h5>
        <row label="Default Interest Due" :value="currentDefaultInterestDue" />
        <row label="Lender B-Piece Default Interest Credit" :value="defaultInterestBPieceAmount" />
        <row label="Total Default Interest Due" :value="totalDefaultInterest" total />
        <row label="Waived Default Interest" :value="statement.waivedDefaultInterestValue" total />
        <row label="Net Default Interest Due" :value="netDefaultInterest" total last />
      </div>
    </div>
  </div>
</template>
<script>
import EditableField from "components/shared/editable_field";
import Row from "./row";
import NumberFormatter from "models/formatters/number_formatter";
import { mapState } from "pinia";
import useResaleStatementStore from "controllers/manage/store/resaleStatement";

export default {
  components: { EditableField, Row },
  computed: {
    ...mapState(useResaleStatementStore, ["statement"]),
    currentInterestDue() {
      return this.formatCurrency(this.statement.currentInterestDue);
    },
    deferredInterestAmount() {
      return this.formatCurrency(this.statement.deferredInterestAmount);
    },
    unpaidInterestDueFromBorrowerAmount() {
      return this.formatCurrency(this.statement.unpaidInterestDueFromBorrowerAmount);
    },
    interestLenderSpreadAmount() {
      return this.formatCurrency(this.statement.interestLenderSpreadAmount);
    },
    interestBPieceAmount() {
      return this.formatCurrency(this.statement.interestBPieceAmount);
    },
    totalInterest() {
      return this.formatCurrency(this.statement.totalInterest);
    },
    totalDefaultInterest() {
      return this.formatCurrency(this.statement.totalDefaultInterest);
    },
    waivedDeferredInterestValue() {
      return this.formatCurrency(this.statement.waivedDeferredInterestValue);
    },
    waivedUnpaidInterestValue() {
      return this.formatCurrency(this.statement.waivedUnpaidInterestValue);
    },
    netInterest() {
      return this.formatCurrency(this.statement.netInterest);
    },
    currentDefaultInterestDue() {
      return this.formatCurrency(this.statement.currentDefaultInterestDue);
    },
    defaultInterestBPieceAmount() {
      return this.formatCurrency(this.statement.defaultInterestBPieceAmount);
    },
    netDefaultInterest() {
      return this.formatCurrency(this.statement.netDefaultInterest);
    },
  },
  methods: {
    formatCurrency(value) {
      return NumberFormatter.formatAccounting(Number.parseFloat(value));
    },
  },
};
</script>
