<template>
  <div class="todolist-filter">
    <div class="float-right">
      <base-tooltip :label="liveUpdatesLabel">
        <span role="button" @click.stop="liveUpdates = !liveUpdates">
          <base-icon
            icon="broadcast-tower"
            :class="{ 'todolist-icon-disabled': !liveUpdates, 'todolist-icon-enabled': liveUpdates }"
          />
        </span>
      </base-tooltip>
      <base-tooltip v-if="!liveUpdates" label="Apply filters">
        <span role="button" @click.stop="applyFilters">
          <base-icon
            icon="sync"
          />
        </span>
      </base-tooltip>
    </div>
    <label>Underwriting status</label>
    <ul>
      <li v-for="status in underwritingStatuses" :key="status">
        <base-checkbox v-model="selectedUnderwritingStatuses" :native-value="status">
          {{ enumValueToText(status) }}
        </base-checkbox>
      </li>
    </ul>
    <base-hr />

    <label>Loan Program</label>
    <ul>
      <li v-for="program in loanPrograms" :key="program.id">
        <base-checkbox v-model="selectedPrograms" :native-value="program.id">
          {{ program.text }}
        </base-checkbox>
      </li>
    </ul>
    <base-hr />

    <label>Lender Name</label>
    <users-dropdown
      :users="lenders"
      :role="`Enter lender name`"
      :current-selection="selectedLenders"
      @filter-options="filterByLender"
    />
    <base-hr />

    <label>Scheduled Wire Date</label>
    <base-checkbox v-model="selectedScheduledWireDate" native-value="today">
      Today
    </base-checkbox>
    <base-checkbox v-model="selectedScheduledWireDate" native-value="tomorrow">
      Tomorrow
    </base-checkbox>
    <base-checkbox v-model="selectedScheduledWireDate" native-value="this_week">
      This week
    </base-checkbox>
    <base-hr />

    <label>Loan Assignee</label><br />
    <small>Processor</small>
    <users-dropdown
      :users="analysts"
      :role="`Processor name`"
      :current-selection="selectedProcessors"
      @filter-options="filterByProcessor"
    />
    <small>Analyst</small>
    <users-dropdown
      :users="analysts"
      :role="`Analyst name`"
      :current-selection="selectedAnalysts"
      @filter-options="filterByAnalyst"
    />
    <base-hr />

    <label>Loan Followers</label>
    <users-dropdown
      :users="analysts"
      :role="`Enter follower name`"
      :current-selection="selectedFollowers"
      @filter-options="filterByFollower"
    />
    <base-hr />

    <label>Communication</label>
    <base-switch v-model="actionOnAnalyst" native-value="base-switch">
      Action on Analyst only
    </base-switch>
    <base-hr />

    <label>Task Name</label>
    <b-input
      v-model="innerTaskName"
      lazy
      placeholder="Enter task name..."
      @blur="onTaskNameBlur"
    />
    <base-hr />

    <label>Task Assignee</label>
    <users-dropdown
      :users="analysts"
      :role="`Enter Assignee`"
      :current-selection="selectedTaskAssignee"
      @filter-options="filterByTaskAssignee"
    />
    <base-hr />

    <label>Milestone Group</label>
    <ul>
      <li v-for="milestone in milestones" :key="milestone">
        <base-checkbox v-model="selectedTaskMilestones" :native-value="milestone">
          {{ milestone }}
        </base-checkbox>
      </li>
    </ul>
    <base-hr />

    <label>Task Status</label>
    <base-checkbox v-model="selectedTaskStatus" native-value="completed">
      Completed
    </base-checkbox>
    <base-checkbox v-model="selectedTaskStatus" native-value="open">
      Open
    </base-checkbox>
    <base-checkbox v-model="selectedTaskStatus" native-value="pending">
      Pending
    </base-checkbox>
    <base-hr />


    <label>Task Type</label>
    <ul>
      <li v-for="type in taskTypes" :key="type">
        <base-checkbox v-model="selectedTaskType" :native-value="type">
          {{ taskTypeText(type) }}
        </base-checkbox>
      </li>
    </ul>
    <base-hr />

    <label>Required Role</label>
    <ul>
      <li v-for="role in taskRequiredRoles" :key="role">
        <base-checkbox v-model="selectedRequiredRole" :native-value="role">
          {{ enumValueToText(role) }}
        </base-checkbox>
      </li>
    </ul>
    <base-hr />


    <label>Task Action Type</label>
    <ul>
      <li v-for="type in taskActionTypes" :key="type">
        <base-checkbox v-model="selectedTaskActionType" :native-value="type">
          {{ enumValueToText(type) }}
        </base-checkbox>
      </li>
    </ul>
    <base-hr />
  </div>
</template>

<script>
import UsersDropdown from "components/reports/users_dropdown";
import LocationService from "services/location_service.js";
import cloneDeep from "lodash/cloneDeep";
import capitalize from "lodash/capitalize";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";

export default {
  components: { UsersDropdown },
  props: {
    filterQuery: {
      type: String,
      default: () => LocationService.search,
    },
    milestones: {
      type: Array,
      default: () => PSData.milestones || [],
    },
    taskNames: {
      type: Array,
      default: () => PSData.taskNames || [],
    },
    taskRequiredRoles: {
      type: Array,
      default: () => PSData.taskRequiredRoles || [],
    },
  },
  data() {
    const params = new URLSearchParams(this.filterQuery);

    return {
      analysts: (cloneDeep(PSData.analysts) || []).sort((a, b) => a.name.localeCompare(b.name)),
      lenders: (PSData.lenders || []).sort((a, b) => a.name.localeCompare(b.name)),
      loanPrograms: PSData.loanPrograms || [],
      selectedPrograms: params.getAll("loanProgramId[]"),
      selectedLenders: params.getAll("lenderId[]"),
      selectedAnalysts: params.getAll("analystId[]"),
      selectedProcessors: params.getAll("processorId[]"),
      selectedFollowers: params.getAll("followerId[]"),
      selectedUnderwritingStatuses: params.getAll("underwritingStatus[]"),
      selectedScheduledWireDate: params.getAll("scheduledWireDate[]"),
      selectedRequiredRole: params.getAll("taskRequiredRole[]"),
      selectedTaskAssignee: params.getAll("taskAssigneeId[]"),
      selectedTaskStatus: params.getAll("taskStatus[]"),
      selectedTaskType: params.getAll("taskType[]"),
      selectedTaskActionType: params.getAll("taskActionType[]"),
      selectedTaskMilestones: params.getAll("taskMilestone[]"),
      taskName: params.get("taskName") || "",
      innerTaskName: params.get("taskName") || "",
      actionOnAnalyst: !!params.get("actionOnAnalyst"),
      liveUpdates: true,
    };
  },
  computed: {
    underwritingStatuses() {
      return [
        "evaluating",
        "qualified",
        "upload_files",
        "diligencing",
        "diligenced",
        "purchasing",
        "wired",
        "review",
      ];
    },
    taskTypes() {
      return ["underwriter", "lender", "sla", "quality_assurance", "closer"];
    },
    taskActionTypes() {
      return ["default", "upload", "additional_info", "custom"];
    },
    liveUpdatesLabel() {
      if (this.liveUpdates) {
        return "Disable live filtering";
      }
      return "Enable live filtering";
    },
    filterUrlParams() {
      const searchParams = new URLSearchParams(this.filterQuery);

      searchParams.delete("underwritingStatus[]");
      this.selectedUnderwritingStatuses.forEach(status => {
        searchParams.append("underwritingStatus[]", status);
      });

      searchParams.delete("loanProgramId[]");
      this.selectedPrograms.forEach(programId => {
        searchParams.append("loanProgramId[]", programId);
      });

      searchParams.delete("lenderId[]");
      this.selectedLenders.forEach(lenderId => {
        searchParams.append("lenderId[]", lenderId);
      });

      searchParams.delete("analystId[]");
      this.selectedAnalysts.forEach(analystId => {
        searchParams.append("analystId[]", analystId);
      });

      searchParams.delete("scheduledWireDate[]");
      this.selectedScheduledWireDate.forEach(filter => {
        searchParams.append("scheduledWireDate[]", filter);
      });

      searchParams.delete("processorId[]");
      this.selectedProcessors.forEach(processorId => {
        searchParams.append("processorId[]", processorId);
      });

      searchParams.delete("followerId[]");
      this.selectedFollowers.forEach(followerId => {
        searchParams.append("followerId[]", followerId);
      });

      searchParams.delete("actionOnAnalyst");
      if (this.actionOnAnalyst) {
        searchParams.set("actionOnAnalyst", this.actionOnAnalyst);
      }

      searchParams.delete("taskRequiredRole[]");
      this.selectedRequiredRole.forEach(role => {
        searchParams.append("taskRequiredRole[]", role);
      });

      searchParams.delete("taskAssigneeId[]");
      this.selectedTaskAssignee.forEach(assignee => {
        searchParams.append("taskAssigneeId[]", assignee);
      });

      searchParams.delete("taskStatus[]");
      this.selectedTaskStatus.forEach(status => {
        searchParams.append("taskStatus[]", status);
      });

      searchParams.delete("taskType[]");
      this.selectedTaskType.forEach(type => {
        searchParams.append("taskType[]", type);
      });

      searchParams.delete("taskActionType[]");
      this.selectedTaskActionType.forEach(type => {
        searchParams.append("taskActionType[]", type);
      });

      searchParams.delete("taskMilestone[]");
      this.selectedTaskMilestones.forEach(milestone => {
        searchParams.append("taskMilestone[]", milestone);
      });

      searchParams.delete("taskName");
      if (this.taskName.length > 0) {
        searchParams.set("taskName", this.taskName);
      }

      return searchParams.toString();
    },
  },
  watch: {
    selectedPrograms: function() {
      this.updateValue();
    },
    selectedLenders: function() {
      this.updateValue();
    },
    selectedAnalysts: function() {
      this.updateValue();
    },
    selectedProcessors: function() {
      this.updateValue();
    },
    selectedFollowers: function() {
      this.updateValue();
    },
    selectedUnderwritingStatuses: function() {
      this.updateValue();
    },
    selectedScheduledWireDate: function() {
      this.updateValue();
    },
    actionOnAnalyst: function() {
      this.updateValue();
    },
    selectedRequiredRole: function() {
      this.updateValue();
    },
    selectedTaskAssignee: function() {
      this.updateValue();
    },
    selectedTaskStatus: function() {
      this.updateValue();
    },
    selectedTaskType: function() {
      this.updateValue();
    },
    selectedTaskActionType: function() {
      this.updateValue();
    },
    selectedTaskMilestones: function() {
      this.updateValue();
    },
    taskName: function() {
      this.updateValue();
    },
  },
  methods: {
    enumValueToText(value) {
      return startCase(camelCase(value));
    },
    taskTypeText(type) {
      if (type == "quality_assurance") {
        return "QA";
      }
      if (type == "sla") {
        return "SLA";
      }
      return capitalize(type);
    },
    filterByLender(id) {
      id = id.includes("all") ? [] : id;

      this.selectedLenders = id;
    },
    filterByAnalyst(id) {
      id = id.includes("all") ? [] : id;

      this.selectedAnalysts = id;
    },
    filterByProcessor(id) {
      id = id.includes("all") ? [] : id;

      this.selectedProcessors = id;
    },
    filterByFollower(id) {
      id = id.includes("all") ? [] : id;

      this.selectedFollowers = id;
    },
    filterByTaskAssignee(id) {
      id = id.includes("all") ? [] : id;

      this.selectedTaskAssignee = id;
    },
    updateValue() {
      if (this.liveUpdates) {
        this.applyFilters();
      }
    },
    applyFilters() {
      this.$emit("filter-update", this.filterUrlParams);
    },
    onTaskNameBlur() {
      this.taskName = this.innerTaskName || "";
    },
  },
};
</script>
<style scoped>
.base-field {
  padding-bottom: 0 !important;
}
</style>
<style>
.base-checkbox {
  padding-bottom: 0 !important;
}
.todolist-filter {
  padding-top: 24px;
}
.todolist-icon-disabled {
  color: var(--color-grey-16);
}
.todolist-icon-enabled {
  color: #39f;
}
</style>
