<template>
  <base-tag v-bind="$attrs" class="loan-status-tag" :class="statusClassName">
    <div class="loan-status-tag-content">
      {{ statusLabel }}
      <slot />
    </div>
  </base-tag>
</template>
<script>
const STATUSES = {
  draft: {
    label: "Draft",
    className: "loan-status-draft",
  },
  evaluating: {
    label: "Evaluating",
    className: "loan-status-evaluating",
  },
  qualified: {
    label: "Qualified",
    className: "loan-status-qualified",
  },
  upload_files: {
    label: "Upload Files",
    className: "loan-status-upload-files",
  },
  diligencing: {
    label: "In Diligence",
    className: "loan-status-diligencing",
  },
  diligenced: {
    label: "Diligenced",
    className: "loan-status-diligenced",
  },
  purchasing: {
    label: "Purchasing",
    className: "loan-status-purchasing",
  },
  wired: {
    label: "Wired",
    className: "loan-status-wired",
  },
  purchased: {
    label: "Purchased",
    className: "loan-status-purchased",
  },
  review: {
    label: "Review",
    className: "loan-status-review",
  },
  canceled: {
    label: "Withdrawn",
    className: "loan-status-canceled",
  },
  rejected: {
    label: "Declined",
    className: "loan-status-rejected",
  },
  not_applicable: {
    label: "Not Applicable",
    className: "loan-status-not-applicable",
  },
};

export default {
  name: "loan-status-tag",
  props: {
    status: {
      type: String,
      default: "not_applicable",
      validator: function(value) {
        return Object.keys(STATUSES).includes(value);
      },
    },
  },
  computed: {
    statusMapping() {
      return STATUSES[this.status] || {};
    },
    statusLabel() {
      return this.statusMapping.label;
    },
    statusClassName() {
      return this.statusMapping.className;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../app/assets/stylesheets/helpers/variables.scss";

.loan-status-tag {
  color: var(--color-white) !important;
}

.loan-status-tag-content {
  align-items: center;
  display: flex;
}

.loan-status-draft {
  border: 1px solid $color-grey !important;
  color: $color-grey !important;
  background-color: transparent !important;
}

.loan-status-evaluating {
  background-color: $color-yellow !important;
}

.loan-status-qualified {
  background-color: $color-bright-red !important;
}

.loan-status-upload-files {
  background-color: $color-hibiscus !important;
}

.loan-status-diligencing {
  background-color: $color-bright-blue !important;
}

.loan-status-diligenced {
  background-color: $color-blue !important;
}

.loan-status-purchasing {
  background-color: $color-highlight-green !important;
}

.loan-status-wired {
  background-color: $color-blue-chill !important;
}

.loan-status-purchased {
  background-color: $color-bright-green !important;
}

.loan-status-review {
  background-color: $color-light-brown !important;
}

.loan-status-canceled {
  background-color: $color-grey-86 !important;
}

.loan-status-rejected {
  background-color: $color-cool-grey !important;
}

.loan-status-not-applicable {
  background-color: $color-dark-grey !important;
}
</style>
