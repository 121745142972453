import AjaxService from "./ajax_service";

export default class TagService {
  static prepare(tags) {
    return {
      taggings: {
        create: tags.create.map(tag => ({ name: tag.name })),
        delete: tags.delete.map(tag => ({ name: tag.name })),
      },
    };
  }

  static sortAlphabetically(tags) {
    return tags.sort((a, b) => {
      const aName = a.name.toLocaleUpperCase();
      const bName = b.name.toLocaleUpperCase();

      return aName < bName ? -1 : aName > bName ? 1 : 0;
    });
  }

  static update(tags, resource, id) {
    const headers = { "Content-Type": "application/json" };
    const url = `/manage/${resource}/${id}/taggings/bulk_update`;

    return new Promise((resolve, reject) => {
      return AjaxService.withNotifications()
        .post(url, this.prepare(tags), headers)
        .then(response => resolve(response))
        .catch(err => reject(err));
    });
  }
}
