import omit from "lodash/omit";

export default class SeriesCollection {
  static groupedByLoan(seriesCollection) {
    const loansById = seriesCollection.reduce((result, series) => {
      result[series.loan.psId] = series.loan;
      return result;
    }, {});
    seriesCollection.forEach(series => {
      const loanId = series.loan.psId;
      const seriesWithoutLoan = omit(series, "loan");
      loansById[loanId].series = loansById[loanId].series || [];
      loansById[loanId].series.push(seriesWithoutLoan);
    });

    return Object.values(loansById);
  }
}
