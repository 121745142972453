<template>
  <div class="lender-zip-report-details">
    <template v-if="showReport">
      <h3>
        Price Index
      </h3>
      <div class="lender-section">
        <analytics-table :analytics="analytics"></analytics-table>
      </div>
      <div class="lender-section">
        <msa-home-index-chart :sources="charts.homeIndex"></msa-home-index-chart>
      </div>
      <div class="lender-section">
        <risk-of-decline-chart :sources="charts.riskOfDecline"></risk-of-decline-chart>
      </div>
      <h3>
        MSA Profile
      </h3>
      <div class="lender-section">
        <msa-at-a-glance :table="tables.msaAtGlance"></msa-at-a-glance>
      </div>
      <div class="lender-section">
        <job-growth-chart :sources="charts.jobGrowth"></job-growth-chart>
      </div>
      <div class="lender-section">
        <unemployment-rate-chart :sources="charts.unemploymentRate"></unemployment-rate-chart>
      </div>
      <h3>
        Housing Market
      </h3>
      <div class="row">
        <div class="col-md-6">
          <div class="lender-section">
            <housing-stock-value-chart
              :sources="charts.housingStockValue"
            ></housing-stock-value-chart>
          </div>
        </div>
        <div class="col-md-6">
          <div class="lender-section">
            <housing-stock-age-chart :sources="charts.housingStockAge"></housing-stock-age-chart>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="lender-section">
            <housing-stock-type-chart :sources="charts.housingStockType"></housing-stock-type-chart>
          </div>
        </div>
        <div class="col-md-6">
          <div class="lender-section">
            <housing-stock-size-chart :sources="charts.housingStockSize"></housing-stock-size-chart>
          </div>
        </div>
      </div>
      <div class="lender-disclaimer">
        <p>
          <span v-if="analytics.midasNational.isSuccessful">
            HouseCanary data as of
            {{ analytics.midasNational.completedAt | formatDate("MMMM D, YYYY") }}.
          </span>
        </p>
        <p>
          DISCLAIMER: The data presented above is provided by HouseCanary and has not been reviewed
          or endorsed by PeerStreet. The information contained in this site is provided strictly for
          informational purposes only, is subject to change from time to time without notice to you,
          and should not be construed as advice on any subject matter whatsoever. You should not act
          or refrain from acting on the basis of any content included in this site without seeking
          legal or other professional advice.
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import AnalyticsTable from "components/market_data/analytics_table";
import HousingStockAgeChart from "components/market_data/housing_stock_age_chart";
import HousingStockSizeChart from "components/market_data/housing_stock_size_chart";
import HousingStockTypeChart from "components/market_data/housing_stock_type_chart";
import HousingStockValueChart from "components/market_data/housing_stock_value_chart";
import MsaAtAGlance from "components/market_data/msa_at_a_glance";
import JobGrowthChart from "components/market_data/job_growth_chart";
import MsaHomeIndexChart from "components/market_data/msa_home_index_chart";
import RiskOfDeclineChart from "components/market_data/risk_of_decline_chart";
import UnemploymentRateChart from "components/market_data/unemployment_rate_chart";

const Analytics = PS.Models.MarketData.Analytics;
const Tables = PS.Models.MarketData.Tables;
const Charts = PS.Models.MarketData.Charts;

export default Vue.component("msa-report-details", {
  components: {
    AnalyticsTable,
    HousingStockAgeChart,
    HousingStockSizeChart,
    HousingStockTypeChart,
    HousingStockValueChart,
    MsaAtAGlance,
    JobGrowthChart,
    MsaHomeIndexChart,
    RiskOfDeclineChart,
    UnemploymentRateChart,
  },
  data() {
    return {
      reportIsLoading: false,

      midasData: undefined,
      midasError: undefined,

      analytics: {
        midasMsa: new Analytics.MidasMsa(),
        midasNational: new Analytics.MidasNational(),
      },

      tables: {
        msaAtGlance: new Tables.MsaAtGlance(),
      },

      charts: {
        homeIndex: new Charts.MsaHomeIndex(),
        unemploymentRate: new Charts.UnemploymentRate(),
        riskOfDecline: new Charts.RiskOfDecline(),
        jobGrowth: new Charts.JobGrowth(),
        housingStockValue: new Charts.HousingStockValue("msaCrossSection"),
        housingStockAge: new Charts.HousingStockAge("msaCrossSection"),
        housingStockType: new Charts.HousingStockType("msaCrossSection"),
        housingStockSize: new Charts.HousingStockSize("msaCrossSection"),
      },

      bus: PS.Services.MessageBusService.lenderBus,
      msaReportRulebook: undefined,
    };
  },
  mounted() {
    this.msaReportRulebook = new PS.Models.Reports.MsaReportRule(this);
    this.bus.attachRulebook(this.msaReportRulebook);
  },
  beforeDestroy() {
    this.bus.detachRulebook(this.msaReportRulebook);
  },
  computed: {
    showReport() {
      return this.reportIsLoading || this.midasData !== undefined || this.midasError !== undefined;
    },
  },
});
</script>
