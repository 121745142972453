<template>
  <fragment>
    <div class="col-6">
      <editable-datepicker
        label="Estimated Payoff Date"
        :value="payoffStatement.payoffEstimatedDate"
        :editing="false"
      />
    </div>
    <div class="col-6">
      <editable-field
        label="Estimated Payoff Amount"
        :value="payoffStatement.wireTargetDateAmount"
        :editing="false"
        currency
      />
    </div>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import EditableField from "components/shared/editable_field.vue";
import EditableDatepicker from "components/shared/editable_datepicker";

export default {
  components: {
    Fragment,
    EditableField,
    EditableDatepicker,
  },
  props: ["payoffStatement"],
};
</script>
