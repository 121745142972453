<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content base-modal loan-details-actions-modal">
      <div class="modal-header">
        <h4>
          Prefill Formstack
          <a class="modal-close-icon" @click.prevent="onClose" href="#close">&times;</a>
        </h4>
        <span>Generate a URL for a prefilled Loan Doc Request Form.</span>
      </div>
      <div class="modal-body">
        <p class="loan-address">Property Address: <span>{{ propertyAddress }}</span></p>
        <label for="formstack-url">Prefilled Formstack URL</label>
        <div class="form-group">
          <input id="formstack-url" type="text" class="form-control" :value="lenderUrl" disabled="true">
          <div>
            <clipboard-copy :value="lenderUrl">
              <button class="btn btn-outline-grey copy-url-btn" @click="onURLCopy" :disabled="copied">
                {{ copyBtnText }}
              </button>
            </clipboard-copy>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button @click.prevent="onClose" class="btn btn-outline-grey">
          Cancel
        </button>
        <a :href="manageUrl" target="_blank" class="btn btn-action-cta">
          View Form
        </a>
      </div>
    </div>
  </modal-view>
</template>

<script>
import ModalView from "components/modal_view";

export default {
  name: "prefill-formstack-modal",
  components: { ModalView },
  props: {
    show: Boolean,
    propertyAddress: String,
    formstackUrls: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  computed: {
    lenderUrl() {
      return this.formstackUrls["lender"];
    },
    manageUrl() {
      return this.formstackUrls["manage"];
    },
    copyBtnText() {
      return this.copied ? "Copied" : "Copy URL";
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onURLCopy() {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
  },
};
</script>
