import EditLenderFormPolicy from "policies/edit_lender_form_policy";
import LenderUsers from "components/lender_users";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-edit-lender-users-controller"),
    components: { LenderUsers },
    data: function() {
      return {
        lenderUsers: PSData.lenderUsers,
        lenderEditingDisabled: false,
      };
    },
    mounted: function() {
      this.loadPolicy();
    },
    methods: {
      loadPolicy() {
        const formPolicy = new EditLenderFormPolicy(PSData.lenderPolicy);
        this.lenderEditingDisabled = !formPolicy.canUpdateLenderData();
      },
    },
  });
});
