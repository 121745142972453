<template>
  <div class="reserve-draw-request" v-cloak>
    <div class="reserve-draw-request-header">
      <div class="reserve-draw-request-title-container">
        <base-header size="medium">Draw Requests</base-header>
        <span class="ps-loader ps-loader-small" v-if="isLoading"></span>
        <div class="draw-request-sub" v-if="hasDrawRequests">
          <span>{{ drawRequestCount }} Draw Requests</span> •
          <span>Last updated {{ lastUpdated | fromNow(null) }}</span>
        </div>
        <div class="draw-request-sub" v-else>
          No draws generated
        </div>
      </div>
    </div>
    <div v-if="hasDrawRequests" class="draw-request-list">
      <table class="table">
        <thead>
          <tr>
            <th class="draw-request-number-header">Draw Number</th>
            <th class="draw-request-updated-header">Last Updated</th>
            <th class="draw-request-type-header">Draw Type</th>
            <th class="draw-request-amount-header">Appd Draw Amount</th>
            <th class="draw-request-status-header">Draw Status</th>
            <th class="draw-request-notes-header">Notes</th>
            <th class="draw-request-pdf-header">PDF</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(request, index) in reserveDrawRequests"
            :key="'request-' + index"
            @click="showDrawRequest(request.id)"
          >
            <td>
              <p>Draw Request {{ request.drawRequestNumber }}</p>
              <p class="draw-request-on-platform">{{ request.onPlatform }}</p>
            </td>
            <td>{{ request.updatedAt | formatDate("MM/DD/YYYY [at] hh:mm A", null) }}</td>
            <td>{{ request.drawRequestTypeText }}</td>
            <td>{{ request.approvedDrawAmount | money }}</td>
            <td><span :class="statusClass(request, index)">{{ request.status }}</span></td>
            <td class="draw-request-notes">{{ request.notes }}</td>
            <td>
              <span @click.stop="openPdfDocument(request.id)">
                <i :class="pdfClass(request, index)" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="canGenerateConstruction">
      <a :href="newConstructionDrawRequestRoute">
        + Generate Construction Draw Request
      </a>
    </div>
    <div v-if="canGenerateOther">
      <a :href="newOtherDrawRequestRoute">
        + Generate Other Draw Request
      </a>
    </div>
  </div>
</template>

<script>
import Ajax from "services/ajax_service";
import DrawRequest from "models/reserve_draw_request";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import Location from "services/location_service";
import Window from "services/window_service";

export default {
  name: "reserve-draw-request-index",
  data() {
    return {
      isLoading: false,
      reserveDrawRequests: [],
      reserveDrawRequestPolicy: PSData.reserveDrawRequestPolicy || {},
      reserveDrawRequestsRoute: get(PSData, "routes.reserveDrawRequests"),
    };
  },
  created() {
    this.getDrawRequests();
  },
  methods: {
    getDrawRequests() {
      this.isLoading = true;
      Ajax.get(this.reserveDrawRequestsRoute)
        .then(response => {
          this.reserveDrawRequests = [...response.reserveDrawRequests].map(
            req => new DrawRequest(req)
          );
        })
        .finally(() => (this.isLoading = false));
    },
    showDrawRequest(requestId) {
      Location.href = `${this.reserveDrawRequestsRoute}/${requestId}`;
    },
    statusClass(request, index) {
      const type = this.removeReversal(request);
      const status = this.firstOfType[type] == index ? request.status : "draft";
      return ["draw-request-status", `draw-request-status-${status}`];
    },
    pdfClass(request, index) {
      const type = this.removeReversal(request);
      const status = this.firstOfType[type] == index ? "active" : "inactive";
      return ["download", status];
    },
    openPdfDocument(requestId) {
      Window.open(`${this.reserveDrawRequestsRoute}/${requestId}.pdf`, "blank");
    },
    removeReversal(request) {
      return request.drawRequestType.split("_reversal")[0];
    },
  },
  computed: {
    firstOfType() {
      let types = {};
      this.reserveDrawRequests.forEach((request, index) => {
        const type = this.removeReversal(request);
        if (types[type] == undefined) {
          types[type] = index;
        }
      });
      return types;
    },
    canCreateConstruction() {
      return get(this.reserveDrawRequestPolicy, "constructionCreate.allowed");
    },
    canCreateOther() {
      return get(this.reserveDrawRequestPolicy, "otherCreate.allowed");
    },
    canGenerateConstruction() {
      return this.canCreateConstruction && !this.isLoading;
    },
    canGenerateOther() {
      return this.canCreateOther && !this.isLoading;
    },
    drawRequestCount() {
      return this.reserveDrawRequests.length;
    },
    newConstructionDrawRequestRoute() {
      return `${this.reserveDrawRequestsRoute}/new?request_type=construction`;
    },
    newOtherDrawRequestRoute() {
      return `${this.reserveDrawRequestsRoute}/new?request_type=other`;
    },
    hasDrawRequests() {
      return !isEmpty(this.reserveDrawRequests);
    },
    lastUpdated() {
      return get(this.reserveDrawRequests[0], "updatedAt");
    },
  },
};
</script>
