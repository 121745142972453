<template>
  <with-errors :on="field" :errors="errors" class="form-group" :class="inline ? 'form-group-inline' : ''">
    <label>{{ label }}</label>
    <select class="form-control" :value="value" :disabled="disabled" @input="$emit('input', $event.target.value)">
      <option v-if="blankOption" value />
      <option v-for="(name, value) in options" :key="value" :value="value">{{ name }}</option>
    </select>
  </with-errors>
</template>

<script>
import WithErrors from "components/shared/with_errors";

export default {
  name: "form-select",
  components: {
    WithErrors,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String | Boolean,
      default: "",
    },
    field: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    blankOption: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
