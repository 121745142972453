<template>
  <div class="submit-multiple-section">
    <div class="submit-multiple-control">
      <div @click="onClick">
        <i v-if="showSelectAll" class="far fa-square" />
        <i v-else class="fas" :class="selectIcon" />
        Select All
      </div>
    </div>
    <div class="panel-default panel-info">
      Tasks with attachments are preselected for submission.
    </div>
    <div class="clearfix">
      <button
        v-if="completeOnly"
        class="btn-action-cta-sm float-right"
        @click="$emit('complete')"
      >
        Submit Tasks
      </button>

      <tasks-transit-actions
        v-else
        :tasks="selectedTasks"
        class="float-right"
        @confirm="$emit('confirm')"
        @complete="$emit('complete')"
        @reopen="$emit('reopen')"
      />
      <button class="btn-cancel-cta-sm float-right" @click="$emit('cancel')">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import TasksTransitActions from "components/activity_feed_events/manage/loan_tasks/tasks_transit_actions";

export default {
  name: "loan-tasks-select-multiple-section",
  components: {
    TasksTransitActions,
  },
  props: {
    allTasks: {
      type: Array,
      default: () => [],
    },
    selectedTasks: {
      type: Array,
      default: () => [],
    },
    completeOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showSelectAll() {
      return this.selectedTasks.length === 0;
    },
    allTasksSelected() {
      return this.selectedTasks.length === this.allTasks.length;
    },
    selectIcon() {
      return this.showSelectAll || this.allTasksSelected ? "fa-check-square" : "fa-minus-square";
    },
  },
  methods: {
    onClick() {
      this.showSelectAll ? this.$emit("select-all") : this.$emit("deselect-all");
    },
  },
};
</script>
