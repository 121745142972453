import SegmentService from "services/segment_service";
import WelcomeModal from "components/welcome_modal";

$(() => {
  new Vue({
    el: document.querySelector(".v-header-controller"),
    components: {
      WelcomeModal,
    },
    methods: {
      trackEmailPreferencesClick() {
        SegmentService.track("clickedEmailPreferences");
      },
    },
  });
});
