const Events = PS.Models.Loan.Events;
const Commands = PS.Models.Loan.Commands;
const Rule = PS.Models.Rule;

class PeriscopeRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.Periscope.DashboardRequested:
        return this.whenDashboardRequested(event);
      case Events.Periscope.DashboardResizeRequested:
        return this.whenDashboardResizeRequested(event);
      case Events.Periscope.DrilldownRequested:
        return this.whenDrilldownRequested(event);
    }

    return super.check(event);
  }

  whenDashboardRequested(event) {
    return [
      new Commands.Periscope.SetDashboardSettings(event, this.state),
      new Commands.Periscope.LoadDashboard(event, this.state),
    ];
  }

  whenDashboardResizeRequested(event) {
    return [new Commands.Periscope.ResizeDashboard(event, this.state)];
  }

  whenDrilldownRequested(event) {
    return [
      new Commands.Periscope.ResetDashboard(event, this.state),
      new Commands.Periscope.SetDrilldownSettings(event, this.state),
      new Commands.Periscope.LoadDashboard(event, this.state),
    ];
  }
}

PS.Models.Loan.PeriscopeRule = PeriscopeRule;
