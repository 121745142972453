import autoComplete from "js-autocomplete";
import Inputmask from "inputmask";
import moment from "moment";

class Directive {
  static options(binding) {
    let isObject = typeof binding.value === "object";

    if (binding.value && !isObject) {
      console.error(
        `Expected Object for binding.value but was ${binding.value}. Found in v-${
          binding.name
        } directive.`
      );
    }

    return isObject ? binding.value : {};
  }

  static forceChangeEvent() {
    let event = document.createEvent("HTMLEvents");
    event.initEvent("input", true, true);
    this.dispatchEvent(event);
    $(this).trigger("change");
  }
}

class DatePickerDirective extends Directive {
  static bind(el, binding) {
    let options = super.options(binding);
    el.addEventListener("blur", DatePickerDirective.onBlurEventHandler);

    $(el).datepicker({
      dateFormat: "format" in options ? options.format : "mm/dd/yy",
      prevText: '<i class="fas fa-chevron-left"></i>',
      nextText: '<i class="fas fa-chevron-right"></i>',
      onSelect: super.forceChangeEvent,
      onClose: DatePickerDirective.onCloseEventHandler,
      maxDate: options.maxDate,
      minDate: options.minDate,
    });
  }

  static unbind(el) {
    $(el).datepicker("destroy");
  }

  static onCloseEventHandler(_value, datepicker) {
    DatePickerDirective.validateDate(datepicker.input.get(0));
  }

  static onBlurEventHandler(e) {
    const datepickerClosed = !$(e.target)
      .datepicker("widget")
      .is(":visible");

    if (datepickerClosed) {
      DatePickerDirective.validateDate(e.target);
    }
  }

  static validateDate(input) {
    const dateFormat = $(input).datepicker("option", "dateFormat");

    if (!moment(input.value, dateFormat).isValid()) {
      $(input).datepicker("setDate", "");
      super.forceChangeEvent.call(input);
    }
  }
}

class MaskDirective extends Directive {
  static bind(el, binding) {
    let options = super.options(binding);

    switch (binding.arg) {
      case "digits":
        return MaskDirective.digits(el, options, { mask: "9{*}" });
      case "integer":
        return MaskDirective.number(el, options, { round: 0, minus: true });
      case "positive-integer":
        return MaskDirective.number(el, options, { round: 0, minus: false });
      case "number":
      case "currency":
        return MaskDirective.number(el, options, { round: 2, minus: true });
      case "positive-number":
        return MaskDirective.number(el, options, { round: 2, minus: false });
      case "decimal":
        return MaskDirective.decimal(el, options, { round: 2, minus: true });
      case "percentage":
        return MaskDirective.number(el, options, { round: 3, minus: true });
      case "date":
        return MaskDirective.date(el, options, { alias: "mm/dd/yyyy" });
      case "time":
        return MaskDirective.time(el, options);
      case "phone":
        return MaskDirective.visible(el, options, { mask: "(999) 999-9999" });
      case "tin":
        return MaskDirective.visible(el, options, { mask: "999999999" });
      case "ssn":
        return MaskDirective.visible(el, options, { mask: "999-99-9999" });
      case "ein":
        return MaskDirective.visible(el, options, { mask: "99-9999999" });
      case "visible":
        return MaskDirective.visible(el, options);
      case "extension-term":
        return MaskDirective.digits(el, options, { mask: "9[9]" });
      case "zip":
        return MaskDirective.digits(el, options, { mask: "[99999]" });
      case "year":
        return MaskDirective.digits(el, options, { mask: "[9999]" });
      case "fico":
        return MaskDirective.digits(el, options, { mask: "[999]" });
      case "ps_sales_price":
        return MaskDirective.digits(el, options, { mask: "9[9][9][.99999]", greedy: false });
      case "routing-number":
        return MaskDirective.digits(el, options, { mask: "[999999999]" });
      case "":
        return;
      default:
        console.error(`Invalid binding.arg ${binding.arg} in v-${binding.name} directive.`);
    }
  }

  static unbind(el) {
    el.inputmask && el.inputmask.remove();
  }

  static number(el, options, defaults) {
    MaskDirective._init(el, {
      alias: "numeric",
      autoGroup: true,
      groupSeparator: ",",
      digits: "round" in options ? options.round : defaults.round,
      radixPoint: ".",
      allowPlus: false,
      allowMinus: "minus" in options ? options.minus : defaults.minus,
      rightAlign: false,
      clearMaskOnLostFocus: false,
    });
  }

  static decimal(el, options, defaults) {
    MaskDirective._init(el, {
      mask: "mask" in options ? options.mask : null,
      alias: "numeric",
      autoGroup: true,
      groupSeparator: ",",
      digits: "round" in options ? options.round : defaults.round,
      radixPoint: ".",
      allowPlus: false,
      allowMinus: "minus" in options ? options.minus : defaults.minus,
      rightAlign: false,
      clearMaskOnLostFocus: false,
    });
  }

  static date(el, options, defaults = {}) {
    MaskDirective._init(el, {
      alias: "alias" in options ? options.alias : defaults.alias,
      showMaskOnHover: false,
      yearrange: {
        minyear: 1000,
        maxyear: 9999,
      },
    });
  }

  static time(el, options, defaults = {}) {
    MaskDirective._init(el, {
      alias: "datetime",
      mask: "h:s t\\m",
      placeholder: "hh:mm xm",
      hourFormat: "12",
      showMaskOnHover: false,
    });
  }

  static visible(el, options, defaults = {}) {
    MaskDirective._init(el, {
      mask: "mask" in options ? options.mask : defaults.mask,
      showMaskOnHover: false,
    });
  }

  static digits(el, options, defaults) {
    MaskDirective._init(el, {
      mask: "mask" in options ? options.mask : defaults.mask,
      digits: 0,
      greedy: false,
      allowPlus: false,
      allowMinus: false,
      rightAlign: false,
      clearMaskOnLostFocus: false,
      placeholder: "",
    });
  }

  static _init(el, options) {
    const im = new Inputmask({
      ...options,
      oncomplete: super.forceChangeEvent,
      oncleared: super.forceChangeEvent,
    });

    im.mask(el);
  }
}

class TextAutocompleteDirective extends Directive {
  static bind(el, binding) {
    el.addEventListener("blur", super.forceChangeEvent);

    TextAutocompleteDirective.COMPLETERS[el.id] = new autoComplete({
      selector: el,
      minChars: 2,
      onSelect: super.forceChangeEvent.bind(el),
      source: (term, suggest) => {
        term = term.toLocaleLowerCase().replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const collection = binding.value.filter(entry => {
          return entry.toLocaleLowerCase().match(term);
        });

        return suggest(collection);
      },
    });
  }

  static unbind(el) {
    TextAutocompleteDirective.COMPLETERS[el.id].destroy();
  }
}

TextAutocompleteDirective.COMPLETERS = {};

export const Datepicker = {
  bind: DatePickerDirective.bind,
  unbind: DatePickerDirective.unbind,
};
Vue.directive("datepicker", Datepicker);

export const Mask = {
  bind: MaskDirective.bind,
  unbind: MaskDirective.unbind,
};
Vue.directive("mask", Mask);

export const TextAutocomplete = {
  bind: TextAutocompleteDirective.bind,
  unbind: TextAutocompleteDirective.unbind,
};
Vue.directive("text-autocomplete", TextAutocomplete);
