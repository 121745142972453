import enumerize from "../utils/enumerize";

const PaymentStructure = enumerize({
  amortizing: {
    value: "amortizing",
    title: "Amortizing",
  },
  interestOnly: {
    value: "interest_only",
    title: "Interest Only",
  },
});

export default PaymentStructure;
