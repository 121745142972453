<template>
  <div>
    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="wire_date">
        <label>Wire Date</label>
        <input
          name="date"
          type="text"
          class="date-picker form-control"
          v-model="transaction.wire_date"
          v-datepicker
          v-mask:date />
      </field-with-error>
    </div>
    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="off_platform">
        <div class="checkbox">
          <label>
            <input
              name="offPlatform"
              type="checkbox"
              v-model="transaction.off_platform" />
            Off-Platform
          </label>
        </div>
      </field-with-error>
      <div v-if="showPayoffCheckbox">
        <field-with-error :errors="errors" field="payoff">
          <div class="checkbox">
            <label>
              <input
                name="payoff"
                type="checkbox"
                v-model="transaction.payoff"
                @change="onPayoffBalance" />
              Payoff
            </label>
          </div>
        </field-with-error>
      </div>
      <field-with-error :errors="errors" field="return_funds">
        <div class="checkbox">
          <label>
            <input
              name="returnFunds"
              type="checkbox"
              v-model="transaction.return_funds" />
            Return Funds
          </label>
        </div>
      </field-with-error>
    </div>
    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="amount">
        <label>Amount</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input
            name="amount"
            type="currency"
            class="form-control"
            v-model="transaction.amount"
            v-mask:currency />
        </div>
      </field-with-error>
    </div>
    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="notes">
        <label>Notes</label>
        <textarea
          name="note"
          class="form-control"
          placeholder="Optional"
          v-model="transaction.note">
        </textarea>
      </field-with-error>
    </div>
    <div class="modal-actions">
      <a class="btn btn-cancel-cta" @click="close">Cancel</a>
      <a class="btn btn-action-cta"
        @click="submit"
        :disabled="!canSend">
        Save
      </a>
    </div>
  </div>
</template>
<script>
import FormMixin from "components/transaction/form_mixin";
import NumberFormatter from "models/formatters/number_formatter";

export default {
  name: "transaction-base-form",
  mixins: [FormMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    loan: {
      type: Object,
      required: true,
    },
    balances: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      urls: {
        escrow_draw: `/manage/loans/${this.loan.toParam}/transactions/escrow_draws`,
        interest_draw: `/manage/loans/${this.loan.toParam}/transactions/interest_draws`,
        other_draw: `/manage/loans/${this.loan.toParam}/transactions/other_draws`,
      },
      transaction: {
        type: this.type,
        wire_date: undefined,
        return_funds: false,
        off_platform: false,
        amount: undefined,
        note: undefined,
        payoff: false,
      },
    };
  },
  watch: {
    type(value) {
      this.transaction.type = value;
    },
  },
  computed: {
    url() {
      return this.urls[this.type];
    },
    canSend() {
      const { wire_date, amount } = this.transaction;

      return !this.inFlight && this.isNotEmptyString(wire_date) && this.isNotEmptyString(amount);
    },
    showPayoffCheckbox() {
      return this.transaction.type === "other_draw";
    },
  },
  methods: {
    clear() {
      this.transaction.wire_date = undefined;
      this.transaction.return_funds = false;
      this.transaction.amount = undefined;
      this.transaction.note = undefined;
      this.transaction.off_platform = false;
    },
    onPayoffBalance() {
      if (this.transaction.payoff) {
        this.transaction.amount = this.transaction.off_platform
          ? this.balances.otherRemainingOffPlatform
          : this.balances.otherRemaining;
        this.transaction.return_funds = false;
      } else {
        this.transaction.amount = undefined;
      }
    },
  },
};
</script>
