<template>
  <div>
    <breadcrumb-navigation :items="breadcrumbItems" />
    <party-details
      :party="responsibleParty"
      @party-updated="replaceParty"
    />
    <base-hr class="my-5" />
    <roles-details
      :party="responsibleParty"
      @party-updated="replaceParty"
    />
    <base-hr class="my-5" />
    <party-details-background-review
      :raw-party="responsibleParty"
      @party-updated="replaceParty"
    />
    <background-review
      :credit-report-options="options"
      :credit-reports="creditReports"
      :routes="routes"
    />
    <base-hr class="my-5" />
    <track-record-deals
      :responsible-party-id="responsibleParty.id"
    />
  </div>
</template>

<script>
import BreadcrumbNavigation from "components/shared/breadcrumb_navigation";
import PartyDetails from "./party_details";
import RolesDetails from "./roles_details";
import TrackRecordDeals from "./track_records_deals";
import BackgroundReview from "components/shared/background_review";
import PartyDetailsBackgroundReview from "./party_details_background_review";

export default {
  name: "responsible-party-details",
  components: {
    BreadcrumbNavigation,
    PartyDetails,
    RolesDetails,
    TrackRecordDeals,
    BackgroundReview,
    PartyDetailsBackgroundReview,
  },
  data() {
    return {
      responsibleParty: PSData.responsibleParty,
      creditReports: PSData.creditReports,
      options: PSData.options,
      routes: PSData.routes,
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          name: "Loan Sponsors",
          link: this.routes.responsibleParties,
        },
        {
          name: this.responsibleParty.name,
        },
      ];
    },
  },
  methods: {
    replaceParty(newParty) {
      this.responsibleParty = newParty;
    },
  },
};
</script>
