import MarketDataAnalytics from "components/market_data/market_data_analytics";
import CopyToClipboard from "components/copy_to_clipboard";
import LenderTagsMixin from "../../mixins/manage/lenders/tags";
import PiecesOfCollateral from "components/tags/pieces_of_collateral";

$(() => {
  const Event = PS.Models.Event;

  new Vue({
    el: document.querySelector(".v-manage-market-data-controller"),
    components: {
      MarketDataAnalytics,
      CopyToClipboard,
      PiecesOfCollateral,
    },
    mixins: [LenderTagsMixin],
    data: {
      loan: PSData.loan,

      msaData: {},
      msaDataLoaded: false,
      bus: PS.Services.MessageBusService.loanBus,
      msaRulebook: undefined,
    },
    mounted: function() {
      this.msaRulebook = new PS.Models.MSA.PopulationRule(this);
      this.bus.attachRulebook(this.msaRulebook);

      if (this.loanHasZip) {
        new PS.Models.MSA.Commands.Population.RequestData(new Event(), {}).execute();
      }
    },
    beforeDestroy: function() {
      this.bus.detachRulebook(this.msaRulebook);
    },
    computed: {
      loanHasZip: function() {
        return this.loan.zip !== undefined && this.loan.zip !== "";
      },
    },
  });
});
