<template>
  <base-message
    @close="close"
    title="Sync Error"
    type="danger"
  >
    <p v-for="(baseError, i) in baseErrors" :key="`base-error-${i}`">
      {{ baseError }}
    </p>
    <div v-for="(syncError, j) in formattedSyncErrors" :key="`key-${j}`">
      <p class="error-key">{{ syncError.key }}</p>
      <p class="error-value" v-for="(error, k) in syncError.errors" :key="`value-${k}`">
        {{ error[0] }}: {{ error[1] }}
      </p>
    </div>
  </base-message>
</template>

<script>
export default {
  name: "SyncErrors",
  props: {
    syncErrors: Object,
  },
  computed: {
    baseErrors() {
      return this.syncErrors?.base;
    },
    formattedSyncErrors() {
      if (!this.syncErrors) return undefined;

      const formattedErrors = [];
      Object.keys(this.syncErrors).forEach(key => {
        if (key != "base" && this.syncErrors[key].length > 0) {
          formattedErrors.push({
            key: key.replace(/_/g, " ").toUpperCase(),
            errors: this.syncErrors[key]
              .map(error => [
                Object.entries(error)[0][0],
                this.formatError(Object.entries(error)[0][1]),
              ])
              .filter(error => error[1].length != 0),
          });
        }
      });

      return formattedErrors;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formatError(error) {
      return typeof error[0] == "object" ? "" : error.join(", ");
    },
  },
};
</script>

<style scoped>
.error-key {
  font-weight: 600;
}

.error-value {
  padding-left: 15px;
}
</style>
