import toPlainObject from "./to_plain_object";

export default function(object, shouldIncludeNullValues = false) {
  return toPlainObject(object, (object, key) => {
    if (object[key] === undefined) return false;
    if (object[key] === null && !shouldIncludeNullValues) return false;

    return true;
  });
}
