<template>
  <select>
    <slot />
  </select>
</template>

<script>
export default Vue.component("select2", {
  props: {
    options: {
      type: Object,
      default: function() {
        return {};
      },
    },
    value: undefined,
  },
  mounted: function() {
    let vm = this;

    $(vm.$el)
      .val(this.value)
      .select2(this.options)
      .on("select2:select", function() {
        vm.$emit("input", this.value);
      });
  },
  watch: {
    value: function(value) {
      $(this.$el)
        .val(value)
        .trigger("change");
    },
    options: function(options) {
      $(this.$el).select2(this.options);
    },
  },
  destroyed: function() {
    $(this.$el)
      .off()
      .select2("destroy");
  },
});
</script>
