import toPlainObject from "./to_plain_object";

export default {
  notify({ message, response, payload }) {
    response = typeof response.data === "object" ? toPlainObject(response.data) : response.data;

    Bugsnag.notify(
      { name: "Request Error", message },
      { metaData: { custom: { response, payload } } }
    );
  },
};
