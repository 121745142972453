export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    onShow() {
      this.visible = true;
    },
    onHide() {
      this.visible = false;
    },
  },
};
