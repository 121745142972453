<template>
  <div class="action-button btn-group" v-if="hasStatement">
    <button
      type="button"
      class="btn btn-secondary-action-cta dropdown-toggle"
      data-toggle="dropdown"
      :disabled="!isActionable"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Actions
      <i class="fas fa-angle-down"></i>
    </button>

    <ul class="dropdown-menu">
      <li v-for="option in enabledOptions" :key="option.label">
        <a :data-name="option.id" v-if="option.url" :href="option.url" :target="option.target || '_self'">{{ option.label }}</a>
        <a :data-name="option.id" v-else-if="option.emit" href="#" @click.prevent="option.emit" :class="option.class">
          {{ option.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import PayoffStatementActionButtonMixin, {
  STATUSES,
} from "components/manage/payoff_statement/action_button_mixin";

export default {
  name: "manage-repurchase-statement-action-button",
  mixins: [PayoffStatementActionButtonMixin],
  props: ["statement"],
  computed: {
    buttonsOrdering() {
      return {
        [STATUSES.draft]: [
          this.buttons.submitForReview.id,
          this.buttons.editStatement.id,
          this.buttons.downloadPdf.id,
          this.buttons.cancelStatement.id,
        ],
        [STATUSES.pending]: [
          this.buttons.markAsReviewed.id,
          this.buttons.skipToApproved.id,
          this.buttons.refreshStatement.id,
          this.buttons.editStatement.id,
          this.buttons.downloadPdf.id,
          this.buttons.cancelStatement.id,
        ],
        [STATUSES.reviewed]: [
          this.buttons.approveStatement.id,
          this.buttons.editStatement.id,
          this.buttons.refreshStatement.id,
          this.buttons.downloadPdf.id,
          this.buttons.cancelStatement.id,
        ],
        [STATUSES.approved]: [
          this.buttons.markAsPurchased.id,
          this.buttons.downloadPdf.id,
          this.buttons.cancelStatement.id,
        ],
        [STATUSES.purchased]: [this.buttons.editWireDetails.id, this.buttons.downloadPdf.id],
        [STATUSES.expired]: [
          this.buttons.duplicateRepurchase.id,
          this.buttons.markAsPurchased.id,
          this.buttons.downloadPdf.id,
        ],
      };
    },
    buttons() {
      return {
        submitForReview: {
          id: "submitForReview",
          label: "Submit for Review",
          enabled: this.isSubmittable,
          emit: this.submit,
        },
        editStatement: {
          id: "editStatement",
          label: "Edit Statement",
          enabled: this.isEditable,
          url: this.statement.routes.edit,
        },
        editWireDetails: {
          id: "editWireDetails",
          label: "Edit Wire Details",
          enabled: this.isWireDetailsEditable,
          emit: this.editWireDetails,
        },
        downloadPdf: {
          id: "downloadPdf",
          label: "Download PDF",
          enabled: true,
          url: this.statement.routes.downloadUrl,
          target: "_blank",
        },
        cancelStatement: {
          id: "cancelStatement",
          label: "Cancel Statement",
          enabled: this.isCancelable,
          emit: this.cancel,
        },
        refreshStatement: {
          id: "refreshStatement",
          label: "Refresh Statement",
          enabled: this.isRefreshable,
          emit: this.refresh,
        },
        markAsReviewed: {
          id: "markAsReviewed",
          label: "Mark as Reviewed",
          enabled: this.isMarkableAsReviewed,
          emit: this.markAsReviewed,
        },
        markAsPurchased: {
          id: "markAsPurchased",
          label: "Mark as Purchased",
          enabled: this.isMarkableAsPurchased,
          emit: this.markAsPurchased,
        },
        approveStatement: {
          id: "approveStatement",
          label: "Approve Statement",
          enabled: this.isApprovable,
          emit: this.approve,
        },
        skipToApproved: {
          id: "skipToApproved",
          label: "Skip to Approved",
          enabled: this.isSkippable,
          emit: this.skipToApproved,
        },
        duplicateRepurchase: {
          id: "duplicateRepurchase",
          label: "Duplicate Repurchase",
          enabled: this.isDuplicatable,
          url: this.statement.routes.duplicate,
        },
      };
    },
    isWireDetailsEditable() {
      return this.statement.status === STATUSES.purchased && this.statementPolicy.canPurchase();
    },
    isMarkableAsPurchased() {
      return (
        [STATUSES.approved, STATUSES.expired].includes(this.statement.status) &&
        this.statementPolicy.canPurchase()
      );
    },
    isCancelable() {
      return (
        [STATUSES.draft, STATUSES.pending, STATUSES.reviewed, STATUSES.approved].includes(
          this.statement.status
        ) && this.statementPolicy.canCancel()
      );
    },
    isSubmittable() {
      return this.statement.status === STATUSES.draft && this.statementPolicy.canCreate();
    },
    isRefreshable() {
      return (
        [STATUSES.pending, STATUSES.reviewed].includes(this.statement.status) &&
        this.statementPolicy.canApprove()
      );
    },
  },
  methods: {
    markAsPurchased() {
      if (this.isMarkableAsPurchased) {
        this.$emit("mark-as-purchased");
      }
    },
    editWireDetails() {
      if (this.isWireDetailsEditable) {
        this.$emit("mark-as-purchased");
      }
    },
  },
};
</script>
