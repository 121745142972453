<template>
  <div class="servicer-loan-boarding-review-flag-filter">
    <b class="label">Filter:</b>
    <check-box v-model="value" @change="toggle"></check-box>
    <span>No Review Flags</span>
  </div>
</template>

<script>
import CheckBox from "components/shared/check_box";

export default {
  name: "manage-servicer-loan-boarding-review-flag-filter",
  components: { CheckBox },
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return { value: this.enabled };
  },
  watch: {
    enabled(enabled) {
      this.value = enabled;
    },
  },
  methods: {
    toggle(value) {
      this.$emit("changed", value);
    },
  },
};
</script>
