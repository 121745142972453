<template>
  <div class="is-flex is-flex-direction-column">
    <div class="template-revision-navigation py-3">
      <div class="container">
        <base-breadcrumb :items="breadcrumbItems" />
      </div>
    </div>
    <base-hr class="m-0" />
    <div class="template-revision pb-5">
      <base-loading :show="loading" />
      <template v-if="!loading">
        <div class="container is-flex is-justify-content-space-between is-align-items-center py-3">
          <base-header size="medium">
            {{ revision.templateName }}
          </base-header>
          <div class="is-flex">
            <status-item label="Loan Program" class="mx-3">
              {{ revision.loanProgramName }}
            </status-item>
            <status-item label="Funding Type" class="mx-3">
              {{ revision.fundingTypeText }}
            </status-item>
            <base-button @click="openRevisionsBar">
              <base-icon icon="history" />
            </base-button>
          </div>
        </div>
        <base-hr class="m-0" />
        <revision-status :revision="revision" class="container py-3" />
        <base-hr class="m-0" />
        <milestones-list
          class="container"
          :revision="revision"
          :can-manage="canManage"
        />
      </template>
      <revisions-bar
        :opened="revisionsBarOpened"
        :template-id="templateId"
        :revisions="[revision]"
        @close="closeRevistionsBar"
      />
    </div>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import TasklistTemplateRevision, {
  manageTasklistTemplateRevisionPath,
} from "models/tasklist_template_revision";
import StatusItem from "./status_item";
import MilestonesList from "./milestones_list";
import RevisionsBar from "./revisions_bar";
import RevisionStatus from "./revision_status";
import { store } from "./store";

export default {
  components: {
    StatusItem,
    MilestonesList,
    RevisionsBar,
    RevisionStatus,
  },
  data: () => ({
    loading: false,
    canManage: false,
    revision: {},
    revisionsBarOpened: false,
  }),
  computed: {
    templateId() {
      return this.$route.params.template_id;
    },
    revisionId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      return [
        {
          name: "Task List Templates",
          link: "#/",
        },
        {
          name: "Edit Template",
        },
      ];
    },
  },
  mounted() {
    this.loading = true;
    AjaxService.getJSON(manageTasklistTemplateRevisionPath(this.templateId, this.revisionId))
      .then(data => {
        store.setTriggerStatuses(data.trigger_statuses);
        store.setRulesDefinitions(data.definitions);
        this.revision = new TasklistTemplateRevision(data.revision);
        this.canManage = data.can_manage;
      })
      .catch(() => {
        this.revision = {};
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    openRevisionsBar() {
      this.revisionsBarOpened = true;
    },
    closeRevistionsBar() {
      this.revisionsBarOpened = false;
    },
  },
};
</script>
<style scoped>
.template-revision-navigation {
  background: var(--color-grey-06);
}

.template-revision {
  flex: 1;
  position: relative;
}

.revision-number-tag {
  height: 36px;
  min-width: 48px;
}
</style>
