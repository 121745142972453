<template>
  <div>
    <div class="is-flex is-justify-content-space-between is-align-items-center milestones-tasks-list-header">
      <base-header size="small" class="">
        Milestone Tasks
      </base-header>
      <div class="is-flex">
        <base-button type="primary" outlined @click="addNewTask">
          Add Tasks
        </base-button>
      </div>
    </div>
    <base-table
      :data="milestone.taskMappings"
      :draggable="canManage"
      class="milestones-tasks-list"
      :row-class="() => 'task-mapping-row'"
      @dragstart="dragstart"
      @drop="drop"
      @dragover="dragover"
      @dragleave="dragleave"
      @click="viewTaskMapping"
    >
      <base-column
        v-slot="props"
        label="Task Name"
        width="400"
      >
        {{ props.row.taskDefinition.label }}
      </base-column>
      <base-column
        v-slot="props"
        label="Task Type"
        width="150"
      >
        {{ formatTaskType(props.row.taskDefinition.taskType) }}
      </base-column>
      <base-column
        v-slot="props"
        label="Trigger"
        width="150"
      >
        <loan-status-tag :status="props.row.triggerStatus" size="medium">
          <base-icon v-if="props.row.hasConditions" icon="code-branch" class="status-tag-icon" />
        </loan-status-tag>
      </base-column>
      <base-column
        v-slot="props"
        label="Description"
        width="250"
      >
        <template v-if="props.row.useGlobalDescription">
          Global Description
        </template>
        <template v-else>
          Custom Description
          <base-icon icon="code-branch" />
        </template>
      </base-column>
      <base-column
        v-if="canManage"
        v-slot="props"
        label="Actions"
        numeric
        cell-class="actions-cell"
      >
        <base-button
          type="icon"
          icon="pencil-alt"
          tooltip="Edit"
          @click.stop="editTaskMapping(props.row)"
        />
        <base-button
          type="icon"
          icon="trash-alt"
          tooltip="Delete"
          @click.stop="deleteTaskMapping(props.row)"
        />
        <base-button type="icon" icon="sort" />
      </base-column>
      <template v-slot:empty>
        No tasks
      </template>
    </base-table>
    <task-mapping-preview
      :show="showPreview"
      :task-mapping="taskMappingForPreview"
      @close="showPreview=false"
    />
    <task-mapping-edit-form
      :show="showEditForm"
      :task-mapping="taskMappingForEdit"
      :trigger-statuses="triggerStatuses"
      @close="showEditForm=false"
      @save="onTaskMappingSave"
    />
    <task-mapping-add-form
      :show="showAddForm"
      :milestone="milestone"
      :trigger-statuses="triggerStatuses"
      @close="showAddForm=false"
      @save="onTasksAdd"
    />
  </div>
</template>

<script>
import WindowService from "services/window_service";
import LoanStatusTag from "components/shared/loan_status_tag";
import TaskMappingPreview from "../task_mapping/preview";
import TaskMappingEditForm from "../task_mapping/edit_form";
import TaskMappingAddForm from "../task_mapping/add_form";
import cloneDeep from "lodash/cloneDeep";
import startCase from "lodash/startCase";
import { store } from "../template_revision/store";

export default {
  components: {
    LoanStatusTag,
    TaskMappingPreview,
    TaskMappingEditForm,
    TaskMappingAddForm,
  },
  props: {
    milestone: {
      type: Object,
      default: () => {},
    },
    canManage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      draggingRow: null,
      draggingRowIndex: null,
      showPreview: false,
      showEditForm: false,
      showAddForm: false,
      taskMappingForPreview: null,
      taskMappingForEdit: null,
      triggerStatuses: store.state.triggerStatuses,
    };
  },
  methods: {
    dragstart(payload) {
      payload.event.dataTransfer.effectAllowed = "copy";
      store.state.draggingRow = payload.row;
      store.state.draggingRowIndex = payload.index;
      store.state.draggingMilestone = this.milestone;
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = "copy";
      payload.event.target.closest("tr").classList.add("is-draggable");
      payload.event.preventDefault();
    },
    dragleave(payload) {
      payload.event.target.closest("tr").classList.remove("is-draggable");
      payload.event.preventDefault();
    },
    drop(payload) {
      payload.event.target.closest("tr").classList.remove("is-draggable");
      const droppedOnRowIndex = payload.index;
      store.state.draggingMilestone.taskMappings.splice(store.state.draggingRowIndex, 1);
      this.milestone.taskMappings.splice(droppedOnRowIndex, 0, store.state.draggingRow);
      this.$emit("update-order", `${store.state.draggingRow.taskDefinition.label} has been moved`);
    },
    viewTaskMapping(taskMapping) {
      this.showPreview = true;
      this.taskMappingForPreview = taskMapping;
    },
    editTaskMapping(taskMapping) {
      this.showEditForm = true;
      this.taskMappingForEdit = cloneDeep(taskMapping);
    },
    deleteTaskMapping(taskMapping) {
      if (WindowService.confirm("Are you sure?")) {
        this.$emit("task-delete", taskMapping);
      }
    },
    onTaskMappingSave(taskMapping) {
      const taskMappingIndex = this.milestone.taskMappings.findIndex(
        item => item.id === taskMapping.id
      );
      this.milestone.taskMappings.splice(taskMappingIndex, 1, taskMapping);
    },
    addNewTask() {
      this.showAddForm = true;
    },
    formatTaskType(type) {
      return startCase(type);
    },
    onTasksAdd(milestone) {
      this.$emit("update", milestone);
    },
  },
};
</script>
<style scoped>
.milestones-tasks-list-header {
  padding: calc(var(--space-unit) * 3) calc(var(--space-unit) * 3.5) var(--space-unit);
}
.milestones-tasks-list >>> .table thead th {
  border-bottom-width: 1px;
}

.milestones-tasks-list >>> .actions-cell {
  padding-bottom: var(--space-unit);
  padding-top: var(--space-unit);
}

.milestones-tasks-list >>> .table tr.is-draggable {
  background-color: var(--color-blue-hightlight);
}

.milestones-tasks-list >>> .task-mapping-row:hover {
  background: var(--color-white);
  cursor: pointer;
}

.milestones-tasks-list >>> .task-mapping-row td {
  border-bottom-width: 1px;
}

.status-tag-icon {
  padding-left: calc(var(--space-unit) * 0.5);
}
</style>
