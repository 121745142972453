<template>
  <div :class="{ 'grouped-rules': groupedRules }">
    <operator v-if="group.logicOperator" :rule="group" :disabled="disabled" />
    <div class="rules-group">
      <div
        v-for="(rule, index) in group.rules"
        :key="`${group.id}_${index}`"
      >
        <rules-group
          v-if="rule.rules"
          :rules="group.rules"
          :group="rule"
          :group-index="index"
          :disabled="disabled"
          :used-fields="usedFields"
          @remove-rule="removeGroupRule"
          @add-rule="addGroupRule"
          @add-group="addGroupToGroup"
          :filter-by-context="filterByContext"
        />
        <div v-else class="is-flex is-align-items-flex-end">
          <rule
            :mapping="rule"
            :disabled="disabled"
            :used-fields="usedFields"
            class="rule-row"
            :filter-by-context="filterByContext"
          />
          <template v-if="!disabled">
            <base-button class="ml-2 rule-action" :disabled="!group.logicOperator || (index === 0 && groupedRules)" @click="removeRule(index)">
              <base-icon icon="minus" />
            </base-button>
            <base-button class="ml-2 rule-action" @click="addRule(index)">
              <base-icon icon="plus" />
            </base-button>
            <base-button v-if="false" class="ml-2 rule-action" @click="addGroup(index)">
              <base-icon icon="sitemap" />
            </base-button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Rule from "./rule";
import Operator from "./operator";

export default {
  name: "RulesGroup",
  components: {
    Rule,
    Operator,
  },
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Object,
      default: () => ({}),
    },
    groupIndex: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    usedFields: {
      type: Array,
      default: () => [],
    },
    filterByContext: {
      type: String,
      default: null,
    },
  },
  computed: {
    groupedRules() {
      return this.group.rules.length > 1;
    },
  },
  methods: {
    removeRule(index) {
      this.$emit("remove-rule", this.rules, this.group, this.groupIndex, index);
    },
    addRule(index) {
      this.$emit("add-rule", this.rules, this.group, this.groupIndex, index);
    },
    addGroup(index) {
      this.$emit("add-group", this.rules, this.group, this.groupIndex, index);
    },
    removeGroupRule(rules, group, groupIndex, index) {
      this.$emit("remove-rule", rules, group, groupIndex, index);
    },
    addGroupRule(rules, group, groupIndex, index) {
      this.$emit("add-rule", rules, group, groupIndex, index);
    },
    addGroupToGroup(rules, group, groupIndex, index) {
      this.$emit("add-group", rules, group, groupIndex, index);
    },
  },
};
</script>
<style scoped>
.grouped-rules > .rules-group {
  border: 1px solid var(--color-grey-16);
  border-radius: var(--border-radius);
  padding: var(--space-unit);
}

.rules-group .rule-action {
  height: 40px;
  width: 40px;
}

.rule-row {
  flex: 1;
}
</style>
