<template>
  <base-alert
    class="notification-message"
    :variant="variant"
    :dismissible="dismissible"
    v-on="$listeners"
  >
    <div v-if="iconWithDefault" class="notification-message-icon-container">
      <icon :icon="iconWithDefault" size="lg" />
    </div>

    <div class="notification-message-content-container">
      <div class="notification-message-title">
        <slot name="title">
          <h3 v-if="hasTitle">
            {{ title }}
          </h3>
        </slot>
      </div>

      <div class="notification-message-content">
        <slot />
      </div>
    </div>
  </base-alert>
</template>

<script>
import isEmpty from "lodash/isEmpty";

import BaseAlert from "components/shared/base/alert";
import Icon from "components/shared/icon";

const VARIANT_TO_ICON_MAPPING = {
  danger: "times-circle",
  warning: "exclamation-triangle",
  info: "info-circle",
};

export default {
  name: "notification",
  components: {
    BaseAlert,
    Icon,
  },
  props: {
    ...BaseAlert.props,
    /**
     * The title to render. Optional.
     * Use named-slot `title` if customization is needed.
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * The FontAwesome icon name used to render next to the title. Optional.
     * If not specified, icon will change based on `variant` property
     */
    icon: {
      type: String,
      default: null,
    },
  },
  computed: {
    hasTitle() {
      return !isEmpty(this.title);
    },
    iconWithDefault() {
      return this.icon || VARIANT_TO_ICON_MAPPING[this.variant];
    },
  },
};
</script>
