<template>
  <params-provider v-slot="{ params }">
    <div class="workflows-list">
      <div v-if="hasWorkflows">
        <div class="workflows-list-header">
          <div class="workflows-list-row">
            <div class="workflows-list-column workflows-list-workflows">
              Workflows
            </div>
            <div class="workflows-list-column workflows-list-assigned">
              Assigned To
            </div>
            <div class="workflows-list-column workflows-list-due-date">
              Due
            </div>
            <div class="workflows-list-column workflows-list-actions" />
          </div>
        </div>

        <workflows-list-item
          v-for="workflow in workflows"
          :key="workflow.id"
          :workflow="workflow"
          :params="params"
        />
      </div>
      <div v-else>
        No workflows
      </div>
    </div>
  </params-provider>
</template>
<script>
import WorkflowsListItem from "./workflows_list_item";
import ParamsProvider from "components/shared/params_provider";
import useAssetManagementStore from "controllers/manage/store/asset_management";
import { mapState } from "pinia";

export default {
  components: {
    WorkflowsListItem,
    ParamsProvider,
  },
  computed: {
    ...mapState(useAssetManagementStore, ["workflows"]),
    hasWorkflows() {
      return this.workflows.length > 0;
    },
  },
};
</script>
