class UsersService {
  static resendInviteLink(lender, user) {
    return `/manage/lenders/${lender.psId}/invitations/${user.id}`;
  }

  static restoreLink(lender, user) {
    return `/manage/lenders/${lender.psId}/users/${user.id}/restore`;
  }

  static invite(lender, user) {
    var url = `/manage/lenders/${lender.psId}/invitations`;
    var messages = { onSuccess: "User is invited.", onError: this.onError };
    var data = {
      user: {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        job_position: user.jobPosition,
        phone: user.phone,
      },
    };

    return PS.Services.AjaxService.withNotifications(messages).post(url, data);
  }

  static update(lender, user) {
    var url = `/manage/lenders/${lender.psId}/users/${user.id}`;
    var messages = { onSuccess: "User details updated.", onError: this.onError };
    var data = {
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      job_position: user.jobPosition,
      phone: user.phone,
    };

    return PS.Services.AjaxService.withNotifications(messages).patch(url, data);
  }

  static destroy(lender, user) {
    var url = `/manage/lenders/${lender.psId}/users/${user.id}`;
    var messages = { onSuccess: "User deleted.", onError: this.onError };

    return PS.Services.AjaxService.withNotifications(messages).delete(url);
  }

  static onError({ response, data }) {
    return {
      message: data.error,
      status: "error",
    };
  }
}

export default UsersService;
