<template>
  <base-modal-form
    :show="show"
    :save-disabled="evaluationInProgress || isSaving"
    :cancel-options="cancelOptions"
    @close="onClose"
    @save="onSave"
    :title="title"
    :save-button-name="buttonName"
    class="save-draft-modal"
  >
    <form>
      <div class="columns">
        <base-text-field
          data-cy="address-text"
          v-model="record.address"
          rules="required"
          label="Address*"
          :errors="errors.address"
          type="text"
          name="address"
          class="column is-full"
          id="address-field-save-draft-modal"
          placeholder=""
          ref="address"
        />
      </div>
      <div class="columns">
        <base-text-field
          data-cy="city-text"
          v-model="record.city"
          label="City"
          :errors="errors.city"
          type="text"
          name="city"
          class="column is-two-fifth"
        />
        <base-select-field
          data-cy="state-dropdown"
          v-model="record.state"
          label="State"
          :options="states"
          :errors="errors.state"
          showEmpty
          class="column is-two-fifth"
          id="state-field-save-draft-modal"
        />
        <base-zip-field
          data-cy="zip-code-zip"
          v-model="record.zipCode"
          label="Zip Code"
          :errors="errors.zipCode"
          type="text"
          name="zipCode"
          class="column is-one-fifth"
        />
      </div>
      <base-loading
        :show="isSaving"
        :is-full-page="false"
      />
    </form>
  </base-modal-form>
</template>

<script>
import Notification from "./utils/notification";
import RequestError from "./utils/request_error";
import states from "./utils/states";
import GoogleAutocompleteMixin from "mixins/google_autocomplete_mixin";

export default {
  name: "save-draft-modal",
  inject: ["api"],
  mixins: [GoogleAutocompleteMixin],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    scenario: {
      type: Object,
      default: {},
    },
    selectedScenario: {
      type: Object,
      default: {},
    },
    creditboxResults: {
      type: Object,
      default: {},
    },
    priceResults: {
      type: Array,
      default: [],
    },
    propertyAddress: {
      type: Object,
      default: {},
    },
    evaluationInProgress: {
      type: Boolean,
      required: true,
    },
    loanCreationInProgress: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      states,
      record: {},
      errors: {},
      isSaving: false,
      cancelOptions: ["escape", "x"],
    };
  },
  watch: {
    show(isShow) {
      if (isShow) {
        // The <base-modal-form> component is not loaded into the DOM
        // until it is shown for the first time. There does
        // not seem to be a way to configure this behavior.
        // We need to defer attaching the autocomplete element until the modal is shown.
        this.initializeAutocomplete();
        this.reset();
      }
    },
  },
  computed: {
    isNew() {
      return this.id === "new";
    },
    title() {
      return this.loanCreationInProgress
        ? `Name Your Loan`
        : this.isNew
          ? `Save Scenario`
          : `Edit Address`;
    },
    buttonName() {
      return this.loanCreationInProgress ? `Create` : `Save`;
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onSave() {
      this.save();
    },
    reset() {
      this.errors = {};
      this.record = { ...this.propertyAddress, zipCode: this.scenario.zipCode };
    },
    initializeAutocomplete() {
      if (!this.googleAutocomplete.autocomplete) {
        this.$nextTick(() => this.attachAutocompleteToElement(this.$refs.address.$el));
      }
    },
    handleAutocompleteSelected(newAddress) {
      this.record.address = newAddress.fullStreetName;
      this.record.city = newAddress.city;
      this.record.state = newAddress.state;
      this.record.zipCode = newAddress.zipCode;
    },
    async save() {
      this.isSaving = true;

      try {
        await this.saveDraft();
        this.onClose();
      } catch ({ response }) {
        if (response.status === 422) {
          this.errors = response.data;
        } else {
          this.onClose();
          Notification.error(response.data.errors);
          RequestError.notify({ message: "Unable to save loan scenario draft", response, payload });
        }
      } finally {
        this.isSaving = false;
      }
    },
    async saveDraft() {
      let result;
      let estimates = {};
      let details = {
        ...this.scenario,
        propertyState: this.record.state,
        zipCode: this.record.zipCode,
      };

      if (this.loanCreationInProgress) {
        estimates = {
          pricing: this.priceResults,
          creditbox: this.creditboxResults,
        };
        details = {
          ...details,
          convertedLtvRate: this.selectedScenario.ltv,
          customRate: this.selectedScenario.custom,
        };
      }
      const payload = {
        ...this.record,
        details: details,
        estimate: estimates,
      };

      if (this.isNew) {
        result = await this.api.draft.create(payload);
        if (this.loanCreationInProgress && result?.id) {
          this.$emit("convert-scenario-to-loan", result.id, this.record);
        } else {
          this.$emit("scenario-draft-saved");
        }
      } else {
        await this.api.draft.update(this.id, payload);
        this.$emit("scenario-address-updated", this.record);
      }
    },
  },
};
</script>

<style>
.pac-container {
  z-index: 1051;
}
</style>
