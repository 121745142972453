export const MANAGE_TASKLIST_TEMPLATE_ROUTE = "/manage/settings/tasklist_templates";
import DateFormatter, { DATE_TIME_FORMAT } from "models/formatters/date_formatter";

export default class TasklistTemplate {
  constructor(record = {}) {
    this.update(record);
  }

  get activeRevisionLabel() {
    return this.activeRevisionNumber ? `V${this.activeRevisionNumber}` : "Draft";
  }

  get activeRevisionTagType() {
    return this.activeRevisionNumber ? "success" : "default";
  }

  get publishedAtFormatted() {
    return DateFormatter.formatTime(this.publishedAt, DATE_TIME_FORMAT);
  }

  get objectToSave() {
    return {
      id: this.id,
      name: this.name,
    };
  }

  update(record) {
    this._raw = record;
    Object.assign(this, record);
  }

  reset() {
    this.update(this._raw);
  }

  get copy() {
    return new TasklistTemplate(this._raw);
  }
}
