<template>
  <div>
    <table class="additional-info-table">
      <thead v-if="closingAgents.length">
        <tr>
          <th>Company</th>
          <th>Contact Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th></th>
        </tr>
      </thead>

      <tbody
        v-for="agent in closingAgentsList"
        :key="agent.id"
        :agent="agent"
        class="borrower-info"
      >
        <tr>
          <td class="required">{{ agent.companyName }}</td>
          <td class="required">{{ agent.contactName }}</td>
          <td class="required">{{ agent.phone }}</td>
          <td class="required">{{ agent.email }}</td>
          <td class="actions">
            <a
              href="#"
              @click.prevent="openClosingAgentModal(agent, 'update')"
              title="Edit closing agent's information"
            >
              <i class="fas fa-pencil-alt"></i>
            </a>
            <a href="#" @click.prevent="deleteClosingAgent(agent)" title="Delete closing agent">
              <i class="fas fa-trash-alt"></i>
            </a>
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td colspan="5" v-if="canEdit">
            <a
              href="#"
              @click.prevent="openClosingAgentModal({}, 'create')"
              title="Add closing agent"
            >
              {{addClosingAgentCopy}}
            </a>
          </td>
        </tr>
      </tfoot>
    </table>

    <closing-agent-modal
      :show="showClosingAgentModal"
      :closing-agent-types="closingAgentTypes"
      :agent-to-edit="agentToEdit"
      :operation="modalOperation"
      @closing-agent-created="closingAgentCreated"
      @closing-agent-updated="closingAgentUpdated"
      @close="closeClosingAgentModal"
    >
    </closing-agent-modal>
  </div>
</template>
<script>
import ClosingAgent from "models/closing_agent";
import AjaxService from "services/ajax_service";
import ClosingAgentModal from "components/closing_agent/modal";

export default {
  name: "closing-agent-info-table",
  components: {
    ClosingAgentModal,
  },
  computed: {
    addClosingAgentCopy() {
      if (this.closingAgents.length > 0) {
        return "+ Add Additional Closing Agent";
      } else {
        return "+ Add Closing Agent";
      }
    },
    closingAgentsList() {
      return this.closingAgents.map(a => new ClosingAgent(a));
    },
  },
  props: {
    closingAgents: {
      type: Array,
      default: [],
    },
    closingAgentTypes: {
      type: Object,
      default: {},
    },
    canEdit: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      routes: PSData.routes || {},
      showClosingAgentModal: false,
      modalOperation: "create",
      agentToEdit: {},
      isDeletingAgent: this.closingAgents.reduce((isDeletingAgent, agent) => {
        isDeletingAgent[agent.id] = false;
        return isDeletingAgent;
      }, {}),
    };
  },
  methods: {
    openClosingAgentModal(agent, operation) {
      this.modalOperation = operation;
      this.agentToEdit = agent;
      this.showClosingAgentModal = true;
    },
    closeClosingAgentModal() {
      this.showClosingAgentModal = false;
    },
    closingAgentCreated(agent) {
      this.$emit("closing-agent-created", agent);
    },
    closingAgentUpdated(agent) {
      this.$emit("closing-agent-updated", agent);
    },
    deleteClosingAgent(agent) {
      if (this.isDeletingAgent[agent.id]) return;
      if (!window.confirm("Are you sure?")) return;

      this.isDeletingAgent[agent.id] = true;

      const url = `${this.routes.loanClosingAgents}/${agent.id}`;

      AjaxService.delete(url)
        .then(() => this.$emit("closing-agent-deleted", agent))
        .finally(() => (this.isDeletingAgent[agent.id] = false));
    },
  },
};
</script>
