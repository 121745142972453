const CHECKBOX_OPTS = {
  true: "Yes",
  false: "No",
};

class ValueFormatter {
  constructor(type, collection = undefined) {
    this.type = type;
    this.collection = collection;
  }

  format(value) {
    if (value === undefined || value === null) {
      return;
    }

    switch (this.type) {
      case "currency":
        return PS.Models.NumberFormatter.formatMoney(value);
      case "percentage":
        return PS.Models.NumberFormatter.formatPercent(value, 3);
      case "select":
      case "radio":
        return this.collection[value] || value;
      case "checkbox":
        return CHECKBOX_OPTS[value];
      case "yesNo":
        return this.formatYesNo(value);
      case "usStates":
        this.loadStates();
        return this.states[value];
      default:
        return value;
    }
  }

  formatYesNo(value) {
    switch (value) {
      case "0":
      case 0:
        return "No";
      case "1":
      case 1:
        return "Yes";
    }
  }

  loadStates() {
    this.states =
      this.states ||
      PS.Const.USStates.reduce((states, item) => {
        states[item.value] = item.name;
        return states;
      }, {});
  }
}

PS.Models.Diligence = PS.Models.Diligence || {};
PS.Models.Diligence.ValueFormatter = ValueFormatter;
