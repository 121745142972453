<template>
  <editable-input :value="value" :label="label" :editing="editing">
    <template #show>
      {{ displayAmount(value) }}
    </template>

    <template #edit>
      <div class="input-group-wrapper">
        <div class="input-group flex-nowrap editable-field-input-wrapper">
          <input
            class="form-control"
            v-mask:percentage="{ round: 2, minus: allowMinus}"
            :value="value"
            @input="handleInput"
          >
          <div class="input-group-append">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>
    </template>
  </editable-input>
</template>

<script>
import toNumber from "lodash/toNumber";
import isNull from "lodash/isNull";

import NumberFormatter from "models/formatters/number_formatter";
import EditableInput from "./editable_input";

export default {
  name: "editable-percent",
  components: {
    EditableInput,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    editing: Boolean,
    tooltip: {
      type: Object,
      default: null,
    },
    allowMinus: {
      type: Boolean,
      default: true,
    },
    maxValue: {
      type: Number,
      default: null,
    },
  },
  computed: {
    hasTooltip() {
      return !!this.tooltip;
    },
  },
  watch: {
    value(newVal) {
      if (this.maxValue !== null) {
        if (toNumber(newVal) > this.maxValue) {
          this.$emit("input", this.maxValue);
        }
      }
    },
  },
  methods: {
    handleInput(event) {
      let value = event.target.value.replace(/,/g, "");
      if (value && value.length > 0) {
        value = toNumber(value);
      }
      this.$emit("input", value);
    },
    displayAmount(value) {
      if (isNull(value) || value.length == 0) return null;
      return NumberFormatter.formatMoney(toNumber(value), 2, true);
    },
  },
};
</script>
