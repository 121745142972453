<template>
  <div class="finops-item-list" v-if="showTable">
    <table class="table">
      <thead>
        <tr>
          <th class="revision">Revision #</th>
          <th class="last-updated">Last Updated</th>
          <th class="revision-type">Revision Type</th>
          <th class="wire-amount">Net Wire Amount</th>
          <th class="status-header">Status</th>
          <th class="note">Notes</th>
          <th class="pdf">PDF</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in list" :key="item.id" @click="showDetails(item)">
          <td>{{ item.versionNumber }}</td>
          <td>{{ item.lastUpdatedAt }}</td>
          <td class="text-capitalize">{{ item.type }}</td>
          <td>{{ item.wireAmount | accounting }}</td>
          <td>
            <span :class="statusClass(item)" class="">
              {{ item.status }}
            </span>
          </td>
          <td></td>
          <td>
            <span @click.stop="openPdfDocument(item)">
              <i class="download" />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Location from "services/location_service";
import Window from "services/window_service";

export default {
  name: "SettlementStatementList",
  data() {
    return { list: PSData.pastSettlementStatements || [], statement: PSData.settlementStatement };
  },
  methods: {
    statusClass(item) {
      return [`finops-status`, `finops-status-${item.status}`];
    },
    showDetails(statement) {
      Location.assign(statement.showPath);
    },
    openPdfDocument(item) {
      Window.open(item.downloadUrl, "blank");
    },
  },
  computed: {
    showTable() {
      return this.list && this.list.length > 0;
    },
  },
};
</script>
