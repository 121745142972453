export default class Series {
  constructor(series = {}) {
    this.series = series;
  }

  get loanId() {
    return this.series.loan.psId;
  }

  get id() {
    return this.series.psId;
  }

  get amount() {
    return parseFloat(this.series.amount) * -1;
  }

  get tranche() {
    return this.series.tranche;
  }

  get targetDate() {
    return this.series.targetDate;
  }
}
