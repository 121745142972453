<template>
  <params-provider v-slot="{params, setParams}">
    <data-provider
      v-slot="{total, loading, displayMode, groupedItems, legalOwners, displayDetail, displayOverview, fetchData, approve, distribute, invalidItems}"
      v-bind="params"
    >
      <overview
        v-if="displayMode === 'overview'"
        :filters="params"
        :legal-owners="legalOwners"
        :grouped-items="groupedItems"
        :total="total"
        :loading="loading"
        :invalid-items="invalidItems"
        @filter="setParams"
        @show="displayDetail"
        @approve="approve"
        @distribute="distribute"
      />

      <Details
        v-else
        :filters="params"
        :legal-owners="legalOwners"
        :grouped-items="groupedItems"
        @back="displayOverview"
      />
    </data-provider>
  </params-provider>
</template>

<script>
import ParamsProvider from "components/shared/params_provider";
import DataProvider from "./data_provider";
import Overview from "./overview";
import Details from "./details";

export default {
  components: {
    ParamsProvider,
    DataProvider,
    Overview,
    Details,
  },
};
</script>
