<template>
  <div v-if="show">
    <div class="alert alert-danger notification-message no-fly-list-hit-alert">
      <div><i class="fas fa-times-circle fa-lg"></i></div>
      <div>
        <h3>Suspicious Activity Detected</h3>
        <li v-for="(row) in displayRestrictions">{{ message(row) }}</li>
        <p>
          Disclaimer: This information is for internal use only and private. Refer to this
          <a
            href="https://peerstreet.atlassian.net/wiki/spaces/ORC/pages/2571239596/No-Fly+Policy+for+IC+Presentation+and+Implementation#Internal-and-external-communication-for-direct-no-fly-list-hits%3A"
            target="_blank"
          >
          wiki page
          </a>
          for more information and guidance in communicating this issue.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "no-fly-list-hit-alert",
  props: {
    show: Boolean,
    restrictions: Array,
  },
  computed: {
    hasDirectHits() {
      return this.directRestrictions.length > 0;
    },
    directRestrictions() {
      return this.restrictions.filter(restriction => restriction.restrictionType === "direct");
    },
    associateRestrictions() {
      return this.restrictions.filter(restriction => restriction.restrictionType === "associate");
    },
    displayRestrictions() {
      if (this.hasDirectHits) {
        return this.directRestrictions;
      } else {
        return this.associateRestrictions;
      }
    },
  },
  methods: {
    message(row) {
      if (this.hasDirectHits) {
        return this.directMessage(row);
      } else {
        return this.associateMessage(row);
      }
    },
    directMessage(row) {
      return `${row.name}, a ${
        row.roles
      } on this loan has been found on PeerStreet's No Fly list due to Reason Code: ${
        row.restrictionReasonName
      }.`;
    },
    associateMessage(row) {
      return `${row.name}, a ${row.roles} on this loan has been associated with ${
        row.directName
      } on PeerStreet's No Fly list in loan: ${row.directLoans} due to Reason Code: ${
        row.restrictionReasonName
      }.`;
    },
  },
};
</script>
