<template>
  <div class="events-section">
    <div class="messages messages-list">
      <activity-feed-event-item
        v-for="event in sortedEvents"
        v-bind:key="event.id"
        v-bind:event="event"
      />
    </div>
    <div v-if="canUpdateComments" ref="messages-input-container" class="messages-widget">
      <div class="message-type" v-if="showMessageTypeToggle">
        <span
          class="message-type-toggle internal"
          v-on:click="visibleFor('public')"
          v-bind:class="publicClass"
          v-if="canPickType && canUpdateExternalComments"
        >Lender</span>
        <span
          class="message-type-toggle"
          v-on:click="visibleFor('internal')"
          v-bind:class="internalClass"
          v-if="canUpdateInternalComments"
        >Internal</span>
      </div>
      <div class="form-group clearfix" v-if="renderTextArea">
        <autosize-textarea
          class="form-control"
          v-bind:placeholder="textareaPlaceholder"
          v-bind:class="visibility"
          v-bind:disabled="uiIsBlocked"
          v-model="content"
        ></autosize-textarea>
        <h5 v-if="errors.length > 0" class="error">{{errors.join(", ")}}</h5>

        <input
          class="btn-action-cta-sm btn-add-comment"
          type="button"
          v-bind:value="submitCaption"
          v-bind:disabled="submitIsDisabled"
          v-on:click="sendMessage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import MessageBusService from "services/message_bus_service";
import ActivityFeedEventsRule from "models/activity_feed_events/rule";
import Event from "models/event";
import {
  RequestSendingMessage,
  RequestComponentLoading,
} from "models/activity_feed_events/commands";
import ActivityFeedEventItem from "components/activity_feed_events/event_item";
import AutosizeTextarea from "components/shared/autosize_textarea";

const INTERNAL_SCOPE = "internal";
const PUBLIC_SCOPE = "public";

export default {
  components: {
    ActivityFeedEventItem,
    AutosizeTextarea,
  },
  props: {
    loan: Object,
    events: Array,
    displayFor: String,
    heightOffset: Number,
    renderTextArea: Boolean,
    canUpdateExternalComments: {
      type: Boolean,
      required: true,
    },
    canUpdateInternalComments: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      eventsRulebook: undefined,
      bus: MessageBusService.loanBus,
      uiIsBlocked: false,
      errors: [],
      visibility: this.detectVisibility(),
      content: "",
    };
  },
  mounted() {
    this.eventRulebook = new ActivityFeedEventsRule(this);
    this.bus.attachRulebook(this.eventRulebook);

    new RequestComponentLoading().execute();
  },
  beforeDestroy() {
    this.bus.detachRulebook(this.eventRulebook);
  },
  watch: {
    content() {
      this.errors = [];
    },
    events() {
      new RequestComponentLoading().execute();
    },
  },
  methods: {
    visibleFor(scope) {
      this.visibility = scope;
    },
    sendMessage() {
      const messageAttrs = {
        messageableId: this.loan.messageableId,
        messageableType: "Loan",
        displayFor: this.displayFor,
        visibility: this.visibility,
        content: this.content,
      };
      this.content = "";

      new RequestSendingMessage(new Event(messageAttrs)).execute();
    },
    detectVisibility() {
      if (this.displayFor === "servicing_loan" || this.displayFor === "analyst_loan") {
        return INTERNAL_SCOPE;
      }

      return PUBLIC_SCOPE;
    },
  },
  computed: {
    canUpdateComments() {
      return this.canUpdateExternalComments || this.canUpdateInternalComments;
    },
    canPickType() {
      return this.displayFor == "analyst_loan";
    },
    showMessageTypeToggle() {
      return this.detectVisibility() === INTERNAL_SCOPE;
    },
    sortedEvents() {
      return this.events.sort((a, b) => a.createdAt - b.createdAt);
    },
    publicClass() {
      return this.visibility === PUBLIC_SCOPE ? "active" : undefined;
    },
    internalClass() {
      return this.visibility === INTERNAL_SCOPE ? "active" : undefined;
    },
    textareaPlaceholder() {
      return this.visibility === PUBLIC_SCOPE && this.canPickType
        ? "Write lender here..."
        : "Write comment here...";
    },
    submitIsDisabled() {
      return this.uiIsBlocked || this.content.trim().length === 0;
    },
    submitCaption() {
      if (this.uiIsBlocked) {
        return "Commenting...";
      } else {
        return "Comment";
      }
    },
  },
};
</script>
