<template>
  <span v-if="properties > 1" class="pieces-of-collateral">
    <base-tooltip :label="`${properties} Properties`">
      <base-tag type="primary" size="medium">
        {{ properties }}
      </base-tag>
    </base-tooltip>
  </span>
</template>

<script>
export default {
  name: "pieces-of-collateral",
  props: {
    properties: {
      type: Number,
      required: true,
      default: 0,
    },
  },
};
</script>
<style>
.pieces-of-collateral {
  align-items: center;
  display: inline-flex !important;
  height: 18px;
  width: 18px;
  margin-left: 6px;
}
.pieces-of-collateral .base-tooltip {
  position: absolute;
}
.pieces-of-collateral .base-tag.is-primary {
  background-color: #8873dc;
  color: white;
}
.pieces-of-collateral .base-tag span {
  text-align: center;
  width: 2ch;
}
.loan-address-details .pieces-of-collateral {
  height: 16px;
  width: 16px;
}
</style>
