<template>
  <fragment>
    <div class="col-6">
      <editable-field
        label="Estimated Payoff Amount"
        :value="payoffStatement.wireTargetDateAmount"
        :editing="false"
        currency
      />
    </div>
    <div class="col-6">
      <editable-field
        :label="wireAmountLabel"
        :value="payoffStatement.wireAmount"
        :editing="false"
        currency
      />
    </div>
    <div class="col-6">
      <editable-datepicker
        :label="estimatedDateLabel"
        :value="payoffStatement.payoffEstimatedDate"
        :editing="false"
      />
    </div>
    <div class="col-6">
      <editable-field
        label="Expected Wire"
        :value="payoffStatement.wireExpectedAmount"
        :editing="false"
        currency
      />
    </div>
    <div class="col-6">
      <editable-field
        :label="expirationDateLabel"
        :value="payoffStatement.payoffExpirationDate"
        :editing="false"
      />
    </div>
    <div class="col-6">
      <editable-field :label="wireDateLabel" :value="payoffStatement.wireDate" :editing="false" />
    </div>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import EditableField from "components/shared/editable_field.vue";
import EditableDatepicker from "components/shared/editable_datepicker";

const LABELS = {
  estimatedDate: {
    lender_repurchase: "Estimated Payoff Date",
    third_party_sale: "Estimated Payoff Date",
    peerstreet_repurchase: "Estimated Purchase Date",
    estimate_payoff_quote: "Estimated Payoff Date",
  },
  expirationDate: {
    lender_repurchase: "Statement Expiration Date",
    third_party_sale: "Statement Expiration Date",
    peerstreet_repurchase: "Repurchase Expiration Date",
  },
  wireDate: {
    lender_repurchase: "Received Wire Date",
    third_party_sale: "Received Wire Date",
    peerstreet_repurchase: "Sent Wire Date",
  },
  wireAmount: {
    lender_repurchase: "Received Wire",
    third_party_sale: "Received Wire",
    peerstreet_repurchase: "Sent Wire",
  },
};

export default {
  components: {
    Fragment,
    EditableField,
    EditableDatepicker,
  },
  props: ["payoffStatement"],
  computed: {
    estimatedDateLabel() {
      return LABELS.estimatedDate[this.payoffStatement.statementType];
    },
    expirationDateLabel() {
      return LABELS.expirationDate[this.payoffStatement.statementType];
    },
    wireDateLabel() {
      return LABELS.wireDate[this.payoffStatement.statementType];
    },
    wireAmountLabel() {
      return LABELS.wireAmount[this.payoffStatement.statementType];
    },
    estimatePayoffQuoteSelected() {
      return this.payoffStatement.statementType === "estimate_payoff_quote";
    },
  },
};
</script>
