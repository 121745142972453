<template>
  <div>
    <div v-if="loading" class="loans-list-content">
      <div class="ps-loader" />
    </div>
    <table v-else class="table report-imports-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Docs</th>
          <th>Uploaded By</th>
          <th class="items-group">Unresolved</th>
          <th class="items-group">Failed</th>
          <th class="items-group">Skipped</th>
          <th class="items-group">Success</th>
          <th class="complete-status">Done</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="reportImport in imports"
          :key="reportImport.id"
          :disabled="reportImport.pending"
          @click="openReportImportSummary(reportImport)"
        >
          <td>{{ reportImport.date }}</td>
          <td>{{ itemsCount(reportImport.itemsCount, reportImport.pending) }}</td>
          <td>{{ reportImport.uploadedBy }}</td>
          <td class="items-group">
            <span :class="groupClassName(reportImport, 'unresolved')">
              {{ groupedItemsCount(reportImport.unresolvedItemsCount, reportImport.pending) }}
            </span>
          </td>
          <td class="items-group">
            <span :class="groupClassName(reportImport, 'failed')">
              {{ groupedItemsCount(reportImport.failedItemsCount, reportImport.pending) }}
            </span>
          </td>
          <td class="items-group">
            <span :class="groupClassName(reportImport, 'skipped')">
              {{ groupedItemsCount(reportImport.skippedItemsCount, reportImport.pending) }}
            </span>
          </td>
          <td class="items-group">
            {{ groupedItemsCount(reportImport.successfulItemsCount, reportImport.pending) }}
          </td>
          <td class="complete-status">
            <img
              v-if="reportImport.completed"
              :src="require('images/icons/icon_check_import_item.svg')"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import WindowService from "services/window_service";

export default {
  name: "physical-doc-tracking-imports",
  props: {
    imports: {
      type: Array,
      default: () => [],
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    openReportImportSummary(reportImport) {
      if (reportImport.pending) return;

      WindowService.location = reportImport.showUrl;
    },
    itemsCount(value, isPending) {
      return isPending ? "Pending" : value;
    },
    groupedItemsCount(value, isPending) {
      return isPending ? "--" : value;
    },
    groupClassName(reportImport, groupName) {
      if (reportImport.pending || reportImport.completed) return "";
      if (reportImport[`${groupName}ItemsCount`] === 0) return "";

      return "attention-required-import-item";
    },
  },
};
</script>
