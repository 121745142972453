import ActivityFeedEventsSection from "components/activity_feed_events/manage/section";
import CopyToClipboard from "components/copy_to_clipboard";
import LenderTagsMixin from "mixins/manage/lenders/tags";
import ResaleStatementShow from "components/manage/payoff_statement/resale/show";
import ResaleStatementForm from "components/manage/payoff_statement/resale/form";
import { createPinia } from "pinia";

$(() => {
  if (!document.querySelector(".v-manage-resale-statement-controller")) {
    return;
  }

  new Vue({
    el: document.querySelector(".v-manage-resale-statement-controller"),
    pinia: createPinia(),
    components: {
      ActivityFeedEventsSection,
      CopyToClipboard,
      ResaleStatementShow,
      ResaleStatementForm,
    },
    mixins: [LenderTagsMixin],
    data() {
      return {
        documents: PSData.documents,
        loan: PSData.loan,
        events: PSData.events,
      };
    },
  });
});
