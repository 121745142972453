import Vue from "vue";
import { createPinia } from "pinia";
import createRouter from "./router";
import createAxios from "./utils/axios";
import CrudApi from "./api/crud";
import locale from "./locales/en";
import App from "./app.vue";

export default function(el) {
  if (!el) return;

  const baseURL = el.dataset.baseUrl;
  if (!baseURL) throw new Error("The data-base-url property is required to start Scenario Builder");

  const csrfToken = $?.rails?.csrfToken();
  if (!csrfToken) throw new Error("Failed to read CSRF token.");

  const client = createAxios({ baseURL, csrfToken });

  const Instance = new Vue({
    provide: {
      locale,
      api: {
        draft: new CrudApi({ client, path: "drafts" }),
      },
    },
    pinia: createPinia(),
    router: createRouter({ base: baseURL }),
    render: h =>
      h(App, {
        props: {
          baseURL,
        },
      }),
  });

  Instance.$mount(el);
}
