import AjaxService from "services/ajax_service";

export default class DocumentService {
  static update(document) {
    const url = "/uploaded_files/" + document.id;
    const messages = { onSuccess: "Document updated." };
    const data = {
      resource: {
        loan_document_type_id: document.loanDocumentTypeId,
        designations: document.designations,
        name: document.userName,
      },
    };

    return AjaxService.withNotifications(messages).patch(url, data, {
      "Content-Type": "application/json",
    });
  }

  static destroy(document) {
    const url = "/uploaded_files/" + document.id;
    const messages = { onSuccess: "Document deleted." };

    return AjaxService.withNotifications(messages).delete(url);
  }
}

PS.Services.DocumentService = DocumentService;
