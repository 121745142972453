<template>
  <div class="modification-form">
    <subsection-header>
      Option {{ optionNumber }}
    </subsection-header>
    <div class="row no-gutters">
      <div class="modification-form-column">
        <div class="row no-gutters">
          <div class="modification-form-column modification-form-subcolumn">
            <div :class="{'has-error': hasFieldError('ownerType')}">
              <editable-select
                label="Owner"
                :no-status-label="''"
                :value="form.ownerType"
                :options="ownerOptions"
                :editing="true"
                name="ownerType"
                v-validate="{ required: isExercised }"
                :data-vv-scope="formScope"
                tooltip="Person/entity to exercise the modification option"
                @input="updateValue('ownerType', $event.target.value)"
              />
            </div>
            <yes-no-input
              label="Modification"
              :value="form.modification"
              @input="updateValue('modification', $event)"
            />
            <yes-no-input
              label="Exercised"
              :value="form.exercised"
              @input="updateValue('exercised', $event)"
            />
            <subsection-header>
              Option {{ optionNumber }} Fees
            </subsection-header>
            <div :class="{'has-error': hasFieldError('modificationFee')}">
              <fee-input
                :value="form.feeValue"
                :value-type="form.feeType"
                label="Modification Fee"
                name="modificationFee"
                v-validate="{ required: isExercised }"
                :data-vv-scope="formScope"
                @input="updateFees($event)"
              />
            </div>
            <div :class="{'has-error': hasFieldError('psFeeAllocation')}">
              <editable-percent
                label="PS Fee Allocation"
                :value="form.peerstreetFeeAllocation"
                :allow-minus="false"
                :editing="true"
                name="psFeeAllocation"
                v-validate="{ required: isExercised }"
                :data-vv-scope="formScope"
                @input="updateValue('peerstreetFeeAllocation', $event)"
              />
            </div>
            <editable-select
              label="Payment Options"
              :value="paymentOption"
              :options="paymentOptions"
              :editing="true"
              @input="updatePaymentOption($event.target.value)"
            />
          </div>
        </div>
      </div>
      <div class="modification-form-column">
        <div :class="{'has-error': hasFieldError('modificationOptions')}">
          <modification-options-table-form
            :item="item"
            :is-exercised="isExercised"
            name="modificationOptions"
            v-validate="{ required: isExercised }"
            :data-vv-scope="formScope"
            @change="updateValue"
          />
        </div>
        <modification-fees-table-form
          :item="item"
          @change="updateValue('fees', $event)"
        />
      </div>
    </div>
    <div class="row no-gutters" v-if="isPaymentOptionCustom">
      <div class="modification-form-column">
        <div class="row no-gutters">
          <div class="modification-form-column modification-form-subcolumn">
            <editable-percent
              label="Payment % Upfront"
              :value="form.paymentUpfrontPercent"
              :allow-minus="false"
              :max-value="100"
              :editing="true"
              @input="updateValue('paymentUpfrontPercent', $event)"
            />
          </div>
          <div class="modification-form-column modification-form-subcolumn">
            <editable-percent
              label="Payment % Payoff"
              :value="form.paymentPayoffPercent"
              :allow-minus="false"
              :max-value="100"
              :editing="true"
              @input="updateValue('paymentPayoffPercent', $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditableSelect from "../editable_select";
import EditablePercent from "../editable_percent";
import YesNoInput from "../components/yes_no_input";
import SubsectionHeader from "../components/subsection_header";
import FeeInput from "./fee_input";
import ModificationOptionsTableForm from "./modification_options_table_form";
import ModificationFeesTableForm from "./modification_fees_table_form";
import uniqueId from "lodash/uniqueId";
import zip from "lodash/zip";

export default {
  components: {
    EditableSelect,
    EditablePercent,
    YesNoInput,
    SubsectionHeader,
    FeeInput,
    ModificationOptionsTableForm,
    ModificationFeesTableForm,
  },
  inject: ["$validator"],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    optionNumber: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      form: {
        ownerType: null,
        modification: false,
        exercised: false,
        feeValue: null,
        feeType: "percentage",
        peerstreetFeeAllocation: 0,
        paymentUpfrontPercent: null,
        paymentPayoffPercent: null,
        newMaturityDate: null,
        oldMaturityDate: null,
        newPaidToDate: null,
        oldPaidToDate: null,
        newPaymentAmount: null,
        oldPaymentAmount: null,
        fees: this.item.fees || [],
        ...this.item,
      },
      paymentOption: this.initialPaymentOption(this.item),
      limited: 0,
      formScope: uniqueId(),
    };
  },
  methods: {
    updateFees({ valueType, value }) {
      this.form.feeType = valueType;
      this.form.feeValue = value;
    },
    updatePaymentOption(value) {
      this.paymentOption = value;
      if (this.paymentOption === "100_upfront") {
        this.form.paymentUpfrontPercent = 100;
        this.form.paymentPayoffPercent = 0;
      } else if (this.paymentOption === "100_payoff") {
        this.form.paymentUpfrontPercent = 0;
        this.form.paymentPayoffPercent = 100;
      } else if (this.paymentOption === "50_50") {
        this.form.paymentUpfrontPercent = 50;
        this.form.paymentPayoffPercent = 50;
      }
    },
    updateValue(valueName, value) {
      this.$set(this.form, valueName, value);
    },
    initialPaymentOption({ paymentUpfrontPercent, paymentPayoffPercent }) {
      const paymentUpfrontValues = [null, undefined, "100.0", "0.0", "50.0"];
      const paymentPayoffValues = [null, undefined, "0.0", "100.0", "50.0"];
      const paymentOptionTypes = [null, null, "100_upfront", "100_payoff", "50_50"];
      const paymentValues = zip(paymentUpfrontValues, paymentPayoffValues, paymentOptionTypes);
      const match = paymentValues.find(([paymentUpfrontValue, paymentPayoffValue]) => {
        return (
          paymentUpfrontPercent === paymentUpfrontValue &&
          paymentPayoffPercent === paymentPayoffValue
        );
      });
      return match ? match[2] : "custom";
    },
    hasFieldError(fieldName) {
      return this.errors.has(fieldName, this.formScope);
    },
  },
  watch: {
    "form.paymentUpfrontPercent": function(newVal) {
      this.form.paymentPayoffPercent = 100 - newVal;
    },
    "form.paymentPayoffPercent": function(newVal) {
      this.form.paymentUpfrontPercent = 100 - newVal;
    },
    form: {
      handler(v) {
        this.$emit("change", v);
      },
      deep: true,
    },
  },
  computed: {
    isPaymentOptionCustom() {
      return this.paymentOption === "custom";
    },
    isExercised() {
      return this.form.exercised;
    },
    ownerOptions() {
      return [{ id: "lender", text: "Lender" }, { id: "borrower", text: "Borrower" }];
    },
    paymentOptions() {
      return [
        {
          id: "100_upfront",
          text: "100% Upfront",
        },
        {
          id: "100_payoff",
          text: "100% Payoff",
        },
        {
          id: "50_50",
          text: "50% Upfront 50% at Payoff",
        },
        {
          id: "custom",
          text: "Custom",
        },
      ];
    },
  },
};
</script>
