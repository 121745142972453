<template>
  <div v-show="canImportSeries" class="lender-sections-container">
    <h2 class="lender-section-title">
      Funding Import
      <div v-show="isLoading" class="ps-loader ps-loader-small"></div>
    </h2>
    <google-sheets-import-form
      :submitHandler="createImport"
      :loading="isLoading"
      v-show="pending"
    />
    <div v-show="processed">
      <div class="import-data-table-header">Continue importing {{ total }} funding records?</div>
      <form>
        <div class="form-group">
          <a class="btn btn-cancel-cta" @click="reset">
            Cancel
          </a>
          <a class="btn btn-action-cta" :disabled="isLoading" @click="onConfirm">
            Confirm
          </a>
        </div>
      </form>
    </div>
    <div v-show="completed">
      <form>
        <div class="form-group">
          <a class="btn btn-action-cta" @click="reset">
            New Import
          </a>
        </div>
      </form>
      <div class="import-data-table-header">Successfully created {{ completed }} records:</div>
    </div>
    <div v-show="failed">
      <div class="import-data-table-header">{{ errorCountMessage }}</div>
      <form>
        <div class="form-group">
          <a class="btn btn-action-cta" @click="reset">
            Back
          </a>
        </div>
      </form>
    </div>
    <div class="text-danger import-error" v-show="error">
      <h3>System Error Occurred: Please contact your administrator.</h3>
    </div>
    <div v-show="data">
      <import-data-table :importData="data" />
    </div>
  </div>
</template>

<script>
import GoogleSheetsImportForm from "components/imports/google_sheet_form.vue";
import ImportDataTable from "components/imports/data_table.vue";

const AjaxService = PS.Services.AjaxService;

const importUrl = "funding_import";
const completeImportUrl = `${importUrl}/complete`;

export default {
  name: "series-import-controller",
  components: { GoogleSheetsImportForm, ImportDataTable },
  data() {
    return {
      import: undefined,
      error: undefined,
      isLoading: false,
      canImportSeries: PSData.canImportSeries,
    };
  },
  computed: {
    pending() {
      return !this.import || this.import.state === "pending";
    },
    processed() {
      return this.import && this.import.state === "processed";
    },
    failed() {
      return this.import && this.import.state === "failed";
    },
    completed() {
      return this.import && this.import.state === "completed";
    },
    total() {
      return this.data ? this.data.count : 0;
    },
    data() {
      return this.import && this.import.data;
    },
    errors() {
      return this.data && this.data.errors;
    },
    completed() {
      return this.data && this.data.completed;
    },
    errorCountMessage() {
      return `The import document has ${this.errors} error(s) that must be corrected.`;
    },
  },
  methods: {
    createImport(data) {
      this.isLoading = true;
      AjaxService.post(importUrl, data)
        .then(result => (this.import = result))
        .catch(error => (this.error = error))
        .finally(() => (this.isLoading = false));
    },
    onConfirm() {
      this.isLoading = true;

      AjaxService.post(completeImportUrl, { id: this.import.id })
        .then(result => (this.import = result))
        .catch(error => (this.error = error))
        .finally(() => (this.isLoading = false));
    },
    reset() {
      this.import = undefined;
      this.error = undefined;
    },
  },
};
</script>
