var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"provider",attrs:{"name":"Loan CSV","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Buyer")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],staticClass:"form-control",class:_vm.validationClass(valid),attrs:{"type":"text","name":"buyer","disabled":_vm.disabled},domProps:{"value":(_vm.innerValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.innerValue=$event.target.value}}}),_vm._v(" "),(valid === false)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n      "+_vm._s(errors.map(function (ref) {
	var msg = ref.msg;

	return msg;
}).join(", "))+"\n    ")]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }