<template>
  <div class="email-preferences">
    <h2 class="page-header">Email Preferences</h2>
    <div class="section-header">Receive email notifications for:</div>
    <div class="radio-control-blue">
      <div class="radio">
        <input id="all-loans" v-model="selected" value="allLoans" type="radio" />
        <label for="all-loans">
          {{ captions.allLoans }}
          <div class="email-preference-desc">
            Follow activity on all loans that are submitted to PeerStreet. You may still unfollow individual loans.
          </div>
        </label>
      </div>
      <div class="radio">
        <input id="selected-loans" v-model="selected" value="selectedLoans" type="radio" />
        <label for="selected-loans">
          {{ captions.selectedLoans }}
          <div class="email-preference-desc">
            Follow activity on only the loans of your choice. You may unfollow loans at any time.
          </div>
        </label>
      </div>
    </div>
    <div class="clearfix">
      <input type="button" class="btn-action-cta-sm float-right" :value="saveCaption" :disabled="saveDisabled" @click="onSave" />
      <button class="btn-cancel-cta-sm float-right" v-if="isChanged" :disabled="uiIsBlocked" @click="onCancel">Cancel</button>
    </div>
  </div>
</template>

<script>
import LocationService from "services/location_service";
import Ajax from "services/ajax_service";
import SegmentService from "services/segment_service";

const ALL_LOANS = "allLoans";
const SELECTED_LOANS = "selectedLoans";

export default {
  name: "email-preferences",
  data: function() {
    let initialValue = PSData.userPreferences.allLoansActivitySubscription
      ? ALL_LOANS
      : SELECTED_LOANS;

    return {
      lender: PSData.lender,
      currentUser: PSData.user,
      selected: initialValue,
      initialValue: initialValue,
      uiIsBlocked: false,
      captions: {
        [ALL_LOANS]: "All new loans",
        [SELECTED_LOANS]: "Selected loans only",
      },
    };
  },
  computed: {
    isChanged() {
      return this.initialValue !== this.selected;
    },
    saveCaption() {
      if (this.uiIsBlocked) {
        return "Saving...";
      } else {
        return "Save";
      }
    },
    saveDisabled() {
      return !this.isChanged || this.uiIsBlocked;
    },
  },
  methods: {
    onCancel() {
      this.selected = this.initialValue;
    },
    onSave() {
      this.uiIsBlocked = true;
      return Ajax.patch(LocationService.href, {
        all_loans_activity_subscription: this.selected === ALL_LOANS,
      }).then(data => {
        this.uiIsBlocked = false;

        if (data.error) {
          Ajax.sendNotification(data.error, "error");
        } else {
          this.initialValue = data.userPreferences.allLoansActivitySubscription
            ? ALL_LOANS
            : SELECTED_LOANS;

          const segmentPayload = data.userPreferences.allLoansActivitySubscription
            ? {
                previous_email_preference: this.captions[SELECTED_LOANS],
                selected_email_preference: this.captions[ALL_LOANS],
              }
            : {
                previous_email_preference: this.captions[ALL_LOANS],
                selected_email_preference: this.captions[SELECTED_LOANS],
              };

          SegmentService.track("editedEmailPreferences", segmentPayload);
        }
      });
    },
  },
};
</script>
