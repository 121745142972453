import Event from "models/event";

class DocumentsEvent extends Event {
  get documents() {
    return this.options.documents;
  }
}

class OneDocumentEvent extends Event {
  get document() {
    return this.options.document;
  }
}

export class DocumentsUpdated extends DocumentsEvent {}

export class NewDocumentsUploaded extends DocumentsEvent {}

export class DocumentDeleted extends OneDocumentEvent {}

export class OneDocumentUpdated extends OneDocumentEvent {}

export class TaskAttachmentChanged extends OneDocumentEvent {
  get taskId() {
    return this.options.taskId;
  }

  get taskAttachmentId() {
    return this.options.taskAttachmentId;
  }
}
