export const ALL_ACCOUNT_TYPES_ITEM = {
  id: "all",
  text: "All Channels",
};

export const ALL_LOAN_PROGRAMS_ITEM = {
  id: "all",
  text: "All Loan Programs",
};

export const ALL_PROPERTY_TYPES_ITEM = {
  id: "all",
  text: "All Property Types",
};

export const ORDERED_ACCOUNT_TYPES = ["broker", "lender"];

export const ORDERED_LOAN_PROGRAMS = [
  "residential_bridge",
  "marketplace_bridge",
  "extended_bridge",
  "residential_rental_long_term",
  "commercial_multifamily",
  "commercial_non_multifamily",
];

export const ORDERED_PROPERTY_TYPES = [
  "sfr",
  "hospitality",
  "industrial_warehouse",
  "mixed_use",
  "multifamily",
  "office",
  "special_purpose",
  "retail",
  "condo",
  "duplex",
  "triplex",
  "fourplex",
  "land",
  "mobile_home_park",
  "self_storage",
];
