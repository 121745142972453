import {
  PartialUpdateRequested,
  FilterLoansByAnalystRequested,
  FilterLoansByTaskAssigneeRequested,
  FilterLoansByPropertyTypeRequested,
  FilterLoansByIntervalRequested,
  FilterLoansByLoanProgramRequested,
  FilterLoansByAccountTypeRequested,
} from "models/reports/hotlist_loans/events/hotlist_loans_events";
import LoansCollection from "collections/manage/loans";
import Command from "models/command";
import { LOADING_STATUSES } from "models/reports/loan";
import FilterParams from "models/hotlist/filter_params";
import intersection from "lodash/intersection";

export class RequestUpdate extends Command {
  execute() {
    return Command.broadcastEvent(
      PS.Models.Reports.Events.Hotlist.Updated,
      PS.Services.MessageBusService.hotlistBus,
      this.event.options
    );
  }
}

export class RequestPartialUpdate extends Command {
  execute() {
    return Command.broadcastEvent(
      PartialUpdateRequested,
      PS.Services.MessageBusService.hotlistBus,
      this.event.options
    );
  }
}

export class RequestFilterLoansByAnalyst extends Command {
  execute() {
    return Command.broadcastEvent(
      FilterLoansByAnalystRequested,
      PS.Services.MessageBusService.hotlistBus,
      { analystIds: this.state.selectedAnalystIds }
    );
  }
}

export class RequestFilterLoansByTaskAssignee extends Command {
  execute() {
    return Command.broadcastEvent(
      FilterLoansByTaskAssigneeRequested,
      PS.Services.MessageBusService.hotlistBus,
      { taskAssigneeIds: this.state.selectedTaskAssigneeIds }
    );
  }
}

export class RequestFilterLoansByPropertyType extends Command {
  execute() {
    return Command.broadcastEvent(
      FilterLoansByPropertyTypeRequested,
      PS.Services.MessageBusService.hotlistBus,
      { propertyTypes: this.state.selectedPropertyTypes }
    );
  }
}

export class RequestFilterLoansByLoanProgram extends Command {
  execute() {
    return Command.broadcastEvent(
      FilterLoansByLoanProgramRequested,
      PS.Services.MessageBusService.hotlistBus,
      { loanPrograms: this.state.selectedLoanPrograms }
    );
  }
}

export class RequestFilterLoansByAccountType extends Command {
  execute() {
    return Command.broadcastEvent(
      FilterLoansByAccountTypeRequested,
      PS.Services.MessageBusService.hotlistBus,
      { accountTypes: this.state.selectedAccountTypes }
    );
  }
}

export class RequestFilterLoansByInterval extends Command {
  execute() {
    return Command.broadcastEvent(
      FilterLoansByIntervalRequested,
      PS.Services.MessageBusService.hotlistBus,
      this.state
    );
  }
}

export class GetOneStatusFromServer extends Command {
  execute() {
    return new Promise(resolve => {
      this.state.loadingStates[this.event.clientUnderwritingStatus] = LOADING_STATUSES.loading;
      PS.Services.AjaxService.getJSON(
        `/manage/reports/hotlist_loans/${this.event.underwritingStatus}`
      ).then(
        data => {
          this.event.serverResponse = data;
          resolve();
        },
        ({ data }) => {
          this.event.serverResponse = data;
          resolve();
        }
      );
    });
  }
}

export class GetPurchasedFromServer extends Command {
  execute() {
    return new Promise(resolve => {
      const { interval, paidOffOnly } = this.event.options;
      const { from, to } = PS.Models.Reports.Periods.monthInterval(interval);
      const query = `from=${from}&to=${to}`;
      let url = `/manage/reports/purchased_loans`;
      if (paidOffOnly) {
        url = `${url}/paid_off`;
      }
      url = `${url}?${query}`;

      this.event.options.underwritingStatus = this.state.group;
      this.state.loadingStates = {
        ...this.state.loadingStates,
        [this.event.clientUnderwritingStatus]: LOADING_STATUSES.loading,
      };

      PS.Services.AjaxService.getJSON(url).then(
        data => {
          this.event.serverResponse = data;
          resolve();
        },
        ({ data }) => {
          this.event.serverResponse = data;
          resolve();
        }
      );
    });
  }
}

export class RequestNextStatusFromServer extends Command {
  execute() {
    return this.state.statusFetcher.fetchOne();
  }
}

export class LoadFromServer extends Command {
  execute() {
    this.state.analysts = this.event.analysts;
    this.state.lenders = this.event.lenders;
    this.state.documents = this.event.documents;
    this.state.serverTime = this.event.serverTime;

    return new Promise(resolve => {
      const loans = LoansCollection.mergeAssociations({
        loans: this.event.loans,
        analysts: this.state.analysts,
        lenders: this.state.lenders,
        documents: this.state.documents,
        serverTime: this.state.serverTime,
      });

      Vue.nextTick(() => {
        const groupedLoans = LoansCollection.groupByUnderwritingStatus(loans);
        this.state.groupedLoans = groupedLoans;
        resolve();
      });
    });
  }
}

export class LoadOneStatusFromServer extends Command {
  execute() {
    return new Promise(resolve => {
      const loans = LoansCollection.mergeAssociations({
        loans: this.event.loans,
        loanTaskAssigneeIds: this.event.loanTaskAssigneeIds,
        analysts: this.state.analysts,
        lenders: this.state.lenders,
        documents: this.state.documents,
        serverTime: this.state.serverTime,
      });

      Vue.nextTick(() => {
        this.state.groupedLoans[this.event.clientUnderwritingStatus] = loans;
        this.state.loadingStates[this.event.clientUnderwritingStatus] = LOADING_STATUSES.complete;
        resolve();
      });
    });
  }
}

export class UpdateFilters extends Command {
  execute() {
    if (this.event.constructor === FilterLoansByAnalystRequested) {
      if (this.event.analystIds.includes("all")) {
        this.state.filterOptions.analystIds = [];
      } else {
        this.state.filterOptions.analystIds = this.event.analystIds;
      }
    }

    if (this.event.constructor === FilterLoansByTaskAssigneeRequested) {
      if (this.event.taskAssigneeIds.includes("all")) {
        this.state.filterOptions.taskAssigneeIds = [];
      } else {
        this.state.filterOptions.taskAssigneeIds = this.event.taskAssigneeIds;
      }
    }

    if (this.event.constructor === FilterLoansByPropertyTypeRequested) {
      if (this.event.propertyTypes.includes("all")) {
        this.state.filterOptions.propertyTypes = [];
      } else {
        this.state.filterOptions.propertyTypes = this.event.propertyTypes;
      }
    }

    if (this.event.constructor === FilterLoansByLoanProgramRequested) {
      if (this.event.loanPrograms.includes("all")) {
        this.state.filterOptions.loanPrograms = [];
      } else {
        this.state.filterOptions.loanPrograms = this.event.loanPrograms;
      }
    }

    if (this.event.constructor === FilterLoansByAccountTypeRequested) {
      if (this.event.accountTypes.includes("all")) {
        this.state.filterOptions.accountTypes = [];
      } else {
        this.state.filterOptions.accountTypes = this.event.accountTypes;
      }
    }

    if (this.event.constructor === FilterLoansByIntervalRequested) {
      this.state.filterOptions.interval = this.event.options.interval;
    }

    return Promise.resolve();
  }
}

export class SaveHotlistFilters extends Command {
  execute() {
    FilterParams.instance.saveParams(this.state.filterOptions);

    return Promise.resolve();
  }
}

export class RestoreHotlistFilters extends Command {
  execute() {
    this.state.filterOptions = FilterParams.instance.loadParams();

    return Promise.resolve();
  }
}

export class FilterBy extends Command {
  filterLoan(loan, filterOptions) {
    const analystId = (loan.analyst.id || "").toString();
    const lenderAccountManagerId = (loan.lenderAccountManagerId || "").toString();

    const belongsToAnalyst =
      filterOptions.analystIds.includes(analystId) ||
      filterOptions.analystIds.includes(lenderAccountManagerId);

    const analystIds = filterOptions.analystIds.filter(id => id !== "all");
    if (analystIds.length > 0 && !belongsToAnalyst) {
      loan.visibleAsFiltered = false;
      return;
    }

    const belongsToPropertyType = filterOptions.propertyTypes.includes(loan.propertyTypeName);

    const propertyTypes = filterOptions.propertyTypes.filter(id => id !== "all");
    if (propertyTypes.length > 0 && !belongsToPropertyType) {
      loan.visibleAsFiltered = false;
      return;
    }

    const belongsToLoanProgram = filterOptions.loanPrograms.includes(loan.loanProgramName);

    const loanPrograms = filterOptions.loanPrograms.filter(id => id !== "all");
    if (loanPrograms.length > 0 && !belongsToLoanProgram) {
      loan.visibleAsFiltered = false;
      return;
    }

    const belongsToAccountType = filterOptions.accountTypes.includes(loan.accountTypeName);

    const accountTypes = filterOptions.accountTypes.filter(id => id !== "all");
    if (accountTypes.length > 0 && !belongsToAccountType) {
      loan.visibleAsFiltered = false;
      return;
    }

    const loanTaskAssigneeIds = loan.taskAssigneeIds.map(id => id.toString());
    const belongsToTaskAssignee =
      intersection(filterOptions.taskAssigneeIds, loanTaskAssigneeIds).length > 0;

    const taskAssigneeIds = filterOptions.taskAssigneeIds.filter(id => id !== "all");
    if (taskAssigneeIds.length > 0 && !belongsToTaskAssignee) {
      loan.visibleAsFiltered = false;
      return;
    }

    loan.visibleAsFiltered = true;
  }

  execute() {
    Object.keys(this.state.groupedLoans).forEach(status => {
      this.state.groupedLoans[status].forEach(loan => {
        this.filterLoan(loan, this.state.filterOptions);
      });
    });

    return Promise.resolve();
  }
}

export class FilterOneStatusBy extends FilterBy {
  execute() {
    this.state.groupedLoans[this.event.clientUnderwritingStatus].forEach(loan => {
      this.filterLoan(loan, this.state.filterOptions);
    });

    return Promise.resolve();
  }
}

PS.Models.Reports = PS.Models.Reports || {};
PS.Models.Reports.Commands = PS.Models.Reports.Commands || {};
PS.Models.Reports.Commands.Hotlist = {
  GetPurchasedFromServer,
  RequestFilterLoansByInterval,
  RequestUpdate,
  LoadFromServer,
};
