<template>
  <div class="d-flex my-4">
    <div class="pr-3" style="width: 250px">
      <date-type-filter
        name="dateType"
        :value="dateType"
        @filter="handleFilter"
      />
    </div>
    <div v-if="showRange" class="pr-3" style="width: 150px">
      <date-filter
        name="fromDate"
        :value="fromDate"
        label="From"
        @filter="handleFilter"
      />
    </div>
    <div v-if="showRange" class="pr-3" style="width: 150px">
      <date-filter
        name="toDate"
        :value="toDate"
        label="To"
        @filter="handleFilter"
      />
    </div>
    <div class="pr-3" style="width: 250px">
      <legal-owners-filter
        name="legalOwnerName"
        label="Owners"
        :value="legalOwnerName"
        @filter="handleFilter"
      />
    </div>
    <div class="pr-3" style="width: 250px">
      <loan-id-filter
        name="loanId"
        :value="loanId"
        @filter="handleFilter"
      />
    </div>
    <div v-if="advanceFieldsEnabled" class="pr-3" style="width: 250px">
      <sub-lender-note-status-filter
        name="subLenderNoteStatus"
        label="Sub Funding Type"
        :value="subLenderNoteStatus"
        @filter="handleFilter"
      />
    </div>
  </div>
</template>

<script>
import LoanIdFilter from "../../shared/filters/loan_id_filter";
import DateTypeFilter from "./date_type_filter";
import LegalOwnersFilter from "./legal_owners_filter";
import DateFilter from "../../shared/filters/date_filter";
import SubLenderNoteStatusFilter from "./sub_lender_note_status_filter";
import { previousWorkday, toInput } from "../../shared/date_helper";

export default {
  components: {
    LoanIdFilter,
    DateTypeFilter,
    LegalOwnersFilter,
    DateFilter,
    SubLenderNoteStatusFilter,
  },
  props: {
    dateType: {
      type: String,
      default: "today",
    },
    fromDate: {
      type: String,
      default: toInput(previousWorkday()),
    },
    toDate: {
      type: String,
      default: toInput(previousWorkday()),
    },
    loanId: {
      type: String,
      default: "",
    },
    legalOwnerName: {
      type: String,
    },
    subLenderNoteStatus: {
      type: String,
    },
  },
  data() {
    return {
      advanceFieldsEnabled: PSData.advanceFieldsEnabled,
    };
  },
  computed: {
    showRange() {
      return this.dateType === "range";
    },
    filters() {
      const { dateType, fromDate, toDate, loanId, legalOwnerName, subLenderNoteStatus } = this;
      return {
        dateType,
        fromDate,
        toDate,
        loanId,
        legalOwnerName,
        subLenderNoteStatus,
      };
    },
  },
  mounted() {
    this.$emit("filter", this.filters);
  },
  methods: {
    handleFilter(name, value) {
      this.$emit("filter", { ...this.filters, [name]: value });
    },
  },
};
</script>
