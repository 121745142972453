<template>
  <time-series-comparison-chart
    :chart="chart"
    :sources="sources"
    class="home-index-chart">
  </time-series-comparison-chart>
</template>

<script>
import TimeSeriesComparisonChart from "components/market_data/time_series_comparison_chart";

export default {
  name: "home-index-chart",
  components: {
    TimeSeriesComparisonChart,
  },
  props: {
    sources: Object,
  },
  data() {
    return {
      chart: {
        title: "Home Price Index",
        axes: {
          y: {
            title: "House Canary Price Index",
          },
          y2: {
            title: "Clear Capital Data Index",
            columns: ["ccZip"],
          },
        },
        columns: {
          hcZip: {
            color: "#66cc33",
            observed: {
              data: "ZIP Code - HC",
              labels: "hc-zip-observed",
            },
            predicted: {
              data: "ZIP Code - HC (forecast)",
              labels: "hc-zip-predicted",
            },
          },
          hcMsa: {
            color: "#5b6770",
            observed: {
              data: "MSA - HC",
              labels: "hc-msa-observed",
            },
            predicted: {
              data: "MSA - HC (forecast)",
              labels: "hc-msa-predicted",
            },
          },
          hcZipTrend: {
            color: "#a9b2bd",
            observed: {
              data: "ZIP Code Trend - HC",
              labels: "hc-zip-trend-observed",
            },
            predicted: {
              data: "ZIP Code Trend - HC (forecast)",
              labels: "hc-zip-trend-predicted",
            },
          },
          ccZip: {
            color: "#3399ff",
            observed: {
              data: "ZIP Code - CC",
              labels: "cc-zip-observed",
            },
            predicted: {
              data: "ZIP Code - CC (forecast)",
              labels: "cc-zip-predicted",
            },
          },
        },
      },
    };
  },
};
</script>
