export default class Document {
  static initDocuments(docs) {
    return (docs || []).map(doc => new Document(doc));
  }

  constructor(doc = {}) {
    this.update(doc);
  }

  get id() {
    return this._id;
  }

  get documentType() {
    return this._documentType || "";
  }

  set documentType(documentType) {
    return (this._documentType = documentType);
  }

  get documentTypeLabel() {
    return this._documentTypeName;
  }

  get status() {
    return this._status || "";
  }

  set status(status) {
    return (this._status = status);
  }

  get statusLabel() {
    return this._statusName;
  }

  get formStatusLabel() {
    return this.documentTypeLabel;
  }

  get notes() {
    return this._notes || "";
  }

  set notes(notes) {
    return (this._notes = notes);
  }

  get docTypes() {
    return this._docTypes;
  }

  get statuses() {
    return this._statuses;
  }

  get objectToSave() {
    return {
      id: this._id,
      document_type: this.documentType,
      status: this.status,
      notes: this.notes,
    };
  }

  get changed() {
    return (
      this._status !== this._raw.status ||
      this._documentType !== this._raw.documentType ||
      this._notes !== this._raw.notes
    );
  }

  update(doc = {}) {
    this._raw = doc;
    this._id = doc.id;
    this._documentType = doc.documentType;
    this._documentTypeName = doc.documentTypeName;
    this._status = doc.status;
    this._statusName = doc.statusName;
    this._notes = doc.notes;
  }

  reset() {
    this.update(this._raw);
  }
}
