<template>
  <div class="market-data-analytics">
    <analytics-table :analytics="analytics" :show-dates="true"></analytics-table>
    <div v-for="chart in chartSources" :key="chart.name" class="market-data">
      <home-index-chart :sources="chart" class="market-data-chart"></home-index-chart>
    </div>
    <template v-if="analytics.riskAnalysis.marketData" :show-data-tables="false">
      <market-data-details :market-data="analytics.riskAnalysis.marketData" :show-chart-title="true"></market-data-details></template>
  </div>
</template>

<script>
import AnalyticsTable from "components/market_data/analytics_table";
import HomeIndexChart from "components/market_data/home_index_chart";
import MarketDataDetails from "components/market_data/market_data_details";

const Analytics = PS.Models.MarketData.Analytics;
const Charts = PS.Models.MarketData.Charts;
const Event = PS.Models.Event;

export default {
  name: "market-data-analytics",
  components: {
    AnalyticsTable,
    HomeIndexChart,
    MarketDataDetails,
  },
  props: {
    loan: Object,
  },
  data() {
    return {
      analytics: {
        riskAnalysis: new Analytics.RiskAnalysis(),
        clearCapitalZip: new Analytics.ClearCapitalZip(),
        midasZip: new Analytics.MidasZip(),
        midasMsa: new Analytics.MidasMsa(),
        midasNational: new Analytics.MidasNational(),
      },
      charts: {
        homeIndex: new Charts.ZipHomeIndex(),
      },
      bus: PS.Services.MessageBusService.loanBus,
      marketDataRulebook: undefined,
    };
  },
  mounted() {
    this.marketDataRulebook = new PS.Models.MarketData.MarketDataRule(this);
    this.bus.attachRulebook(this.marketDataRulebook);

    new PS.Models.MarketData.Commands.RequestAllMarketDataLoading(
      new Event({
        loan: {
          id: this.loan.toParam,
          zip: this.loan.zip,
        },
      }),
      {}
    ).execute();
  },
  beforeDestroy: function() {
    this.bus.detachRulebook(this.marketDataRulebook);
  },
  computed: {
    chartSources() {
      return Object.values(this.charts).filter(item => !!item);
    },
  },
};
</script>
