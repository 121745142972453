<template>
  <div>
    <base-dropdown
      :append-to-body="false"
      label="Actions"
      :disabled="disabled"
      position="left"
    >
      <base-dropdown-item
        :disabled="action.disabled"
        :key="action.label"
        v-for="action in actions"
        @click="action.onClick"
        >{{ action.label }}</base-dropdown-item
      >
    </base-dropdown>
  </div>
</template>
<script>
export default {
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    onClickMarkAsPaidOff() {
      this.$emit("open-modal");
    },
  },
};
</script>
