<template>
  <div class="loan-task-definition-list">
    <div v-if="canManage" class="is-flex is-justify-content-right pb-4">
      <base-button type="primary" @click="addRecord">
        Add New Task
      </base-button>
    </div>
    <base-table
      :data="records"
      :loading="loading"
      :paginated="true"
      :per-page="10"
      :pagination-simple="true"
    >
      <base-column
        v-slot="props"
        field="label"
        label="Task name"
        sortable
        searchable
      >
        {{ props.row.label }}
      </base-column>
      <base-column
        v-slot="props"
        field="taskType"
        label="Task type"
        sortable
        searchable
      >
        {{ taskTypeLabel(props.row.taskType) }}
      </base-column>
      <base-column
        v-slot="props"
        field="mappedLoanTaskDefinitionLabel"
        label="Mapped Task"
        sortable
        searchable
      >
        {{ props.row.mappedLoanTaskDefinitionLabel }}
      </base-column>
      <base-column
        v-slot="props"
        field="groupLabel"
        label="Group"
        sortable
        searchable
      >
        {{ props.row.groupLabel }}
      </base-column>
      <base-column
        v-slot="props"
        field="loanDocumentTypeLabel"
        label="Doc type"
        sortable
        searchable
      >
        {{ props.row.loanDocumentTypeLabel }}
      </base-column>
      <base-column
        v-slot="props"
        field="progress"
        label="Enabled"
        centered
      >
        {{ props.row.activeLabel }}
      </base-column>
      <base-column
        v-slot="props"
        field="actions"
        label="Actions"
        cell-class="actions-cell"
        numeric
      >
        <base-tooltip label="Preview" with-delay position="is-top">
          <base-button
            v-if="!props.row.descriptionDisabled"
            type="icon"
            icon="eye"
            tooltip="Preview"
            @click="previewRecord(props.row)"
          />
        </base-tooltip>
        <base-tooltip label="Edit" with-delay position="is-top">
          <base-button
            v-if="canManage"
            type="icon"
            icon="pencil-alt"
            tooltip="Edit"
            @click="editRecord(props.row)"
          />
        </base-tooltip>
      </base-column>
      <template v-slot:empty>
        No rows
      </template>
    </base-table>
    <loan-task-definition-form
      :show="showEditModal"
      :definition="recordToEdit"
      :document-types="loanDocumentTypes"
      :action-types="actionTypes"
      :tasks="tasks"
      :task-types="taskTypes"
      :mapping-required-task-types="mappingRequiredTaskTypes"
      :required-roles="requiredRoles"
      :duration-types="durationTypes"
      :error-codes="errorCodes"
      :loan-task-groups="loanTaskGroups"
      :borrower-task-copying-enabled="borrowerTaskCopyingEnabled"
      @close="closeEditModal"
      @save="updateRecords"
      @copy-to-borrower-task="copyToBorrowerTask"
    />
    <loan-task-definition-preview
      :show="showPreviewModal"
      :definition="recordToEdit"
      :can-manage="canManage"
      @close="closePreviewModal"
      @edit="openEditModal"
    />
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import LoanTaskDefinitionForm from "./form";
import LoanTaskDefinitionPreview from "./preview";
import LoanTaskDefiniton, { MANAGE_TASK_DEFINITIONS_ROUTE } from "models/loan_task_definition";
import selectOptionsToMapping from "utils/select_options_to_mapping";

export default {
  components: {
    LoanTaskDefinitionForm,
    LoanTaskDefinitionPreview,
  },
  props: {},
  data: () => ({
    loading: false,
    recordToEdit: null,
    records: [],
    showEditModal: false,
    showPreviewModal: false,
    loanDocumentTypes: [],
    actionTypes: [],
    taskTypes: [],
    mappingRequiredTaskTypes: [],
    requiredRoles: [],
    durationTypes: [],
    errorCodes: [],
    loanTaskGroups: [],
    canManage: false,
    borrowerTaskCopyingEnabled: false,
    taskTypesOptions: [],
  }),
  computed: {
    tasks() {
      return this.records.filter(record => {
        return !this.mappingRequiredTaskTypes.includes(record.taskType);
      });
    },
  },
  mounted() {
    this.loading = true;
    AjaxService.getJSON(MANAGE_TASK_DEFINITIONS_ROUTE)
      .then(data => {
        this.updateRecords(data.items || []);
        this.loanDocumentTypes = data.loan_document_types || [];
        this.actionTypes = data.action_types || [];
        this.taskTypes = data.task_types || [];
        this.mappingRequiredTaskTypes = data.mapping_required_task_types || [];
        this.requiredRoles = data.required_roles || [];
        this.durationTypes = data.duration_types || [];
        this.errorCodes = data.error_codes || [];
        this.loanTaskGroups = data.loan_task_groups || [];
        this.canManage = data.can_manage;
        this.borrowerTaskCopyingEnabled = data.borrower_task_copying_enabled;
      })
      .catch(() => {
        this.records = [];
      })
      .finally(() => {
        this.taskTypesOptions = selectOptionsToMapping(this.taskTypes);
        this.loading = false;
      });
  },
  methods: {
    addRecord() {
      this.recordToEdit = new LoanTaskDefiniton();
      this.openEditModal();
    },
    copyToBorrowerTask() {
      this.recordToEdit = this.recordToEdit.copyToBorrower();
    },
    editRecord(record) {
      this.recordToEdit = record.copy;
      this.openEditModal();
    },
    previewRecord(record) {
      this.recordToEdit = record.copy;
      this.showPreviewModal = true;
    },
    openEditModal() {
      if (this.canManage) {
        this.closePreviewModal();
        if (this.recordToEdit) {
          this.showEditModal = true;
        }
      }
    },
    closeEditModal() {
      this.showEditModal = false;
      this.recordToEdit.reset();
    },
    closePreviewModal() {
      this.showPreviewModal = false;
    },
    updateRecords(updatedRecords = []) {
      this.records = updatedRecords.map(record => new LoanTaskDefiniton(record));
    },
    taskTypeLabel(type) {
      return this.taskTypesOptions[type];
    },
  },
};
</script>
<style scoped>
.loan-task-definition-list >>> .actions-cell {
  padding-top: var(--space-unit);
  padding-bottom: var(--space-unit);
}
</style>
