<template>
  <div>
    <div class="loan-details-view-header clearfix">
      {{ party.name }}
      <div class="dropdown-button btn-group float-right" role="group">
        <button
          class="btn btn-action-cta-sm"
          name="edit-responsible-party"
          @click="$emit('on-edit')"
        >
          Edit Details
        </button>
        <button
          type="button"
          class="btn btn-action-cta-sm dropdown-toggle dropdown-toggle-split edit-details-dropdown-button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fas fa-caret-down" />
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <button
            class="dropdown-item"
            name="delete-responsibly-party"
            @click="deleteParty"
          >
            Delete this party
          </button>
        </div>
      </div>
    </div>
    <view-panel class="loan-details-view-panel">
      <template slot="header">
        Details
      </template>
      <div class="row loan-details-view-panel-row">
        <div class="col-6 loan-details-view-panel-column">
          <details-list :data="detailsRows1" />
        </div>
        <div class="col-6 loan-details-view-panel-column">
          <details-list :data="detailsRows2" />
        </div>
      </div>
    </view-panel>
  </div>
</template>

<script>
import ResponsibleParty from "models/responsible_party";
import ViewPanel from "components/view_panel.vue";
import DetailsList from "components/loan_details/list";

export default {
  name: "responsible-party-details-table",
  components: {
    ViewPanel,
    DetailsList,
  },
  props: {
    rawParty: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      party: ResponsibleParty.deserialize(this.rawParty),
    };
  },
  computed: {
    detailsRows1() {
      return [
        { label: "Name", value: this.party.name, show: true },
        { label: "Type", value: this.party.typeLabel, show: true },
      ];
    },
    detailsRows2() {
      return [
        { label: this.party.tinFieldLabel, value: this.party.tinLast4, show: true },
        { label: "Residency Status", value: this.party.residencyLabel, show: true },
      ];
    },
  },
  methods: {
    deleteParty() {
      const message = `Are you sure you want to delete this party? Any roles and borrower
        associations will also be deleted. This cannot be undone.`;

      if (window.confirm(message)) {
        this.$emit("on-delete");
      }
    },
  },
};
</script>
<style scoped>
.edit-details-dropdown-button {
  border-left: 1px solid rgba(255, 255, 255, 0.38);
  margin-left: 0;
  padding: 5px 10px;
}
</style>
