<template>
  <div class="loan-sales-detail">
    <h4 class="loan-sales-page-sub-heading">
      Loans For Sale
      <csv-report-button :batch="batch" />
    </h4>

    <div v-if="!batch" class="loan-sales-empty">
      No Loans
    </div>

    <template v-else>
      <transfer-detail
        v-if="form.saleType === 'transfer'"
        :batch="batch"
        :form="form"
        :disabled="disabled"
        v-on="$listeners"
      />
      <participation-detail
        v-else-if="form.saleType === 'participation'"
        :batch="batch"
        :form="form"
        :disabled="disabled"
        v-on="$listeners"
      />
      <sale-detail
        v-else
        :batch="batch"
        :disabled="disabled"
        v-on="$listeners"
      />
    </template>
  </div>
</template>

<script>
import SaleDetail from "./sale";
import TransferDetail from "./transfer";
import ParticipationDetail from "./participation";
import CsvReportButton from "./csv_report_button";

export default {
  components: {
    TransferDetail,
    ParticipationDetail,
    SaleDetail,
    CsvReportButton,
  },
  inheritAttrs: false,
  props: {
    batch: {
      type: Object,
      default: null,
    },
    form: {
      type: Object,
      required: true,
    },
    disabled: Boolean,
  },
};
</script>
