import CrudApi from "components/loan_scenarios/api/crud";

export default class PreviewsClient extends CrudApi {
  async revert(id) {
    const response = await this.client.post(`${this.path}/${id}/revert`, {});

    return response.data;
  }

  async import(id, csvFile) {
    const formData = new FormData();
    formData.append("uploaded_csv_file", csvFile);
    const response = await this.client.put(`${this.path}/${id}/import`, formData);

    return response.data;
  }
}
