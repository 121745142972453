<template>
  <div>
    <div v-cloak v-if="accountManager" class="account-manager-display">
      <img :src="gravatarUrl" />
      <div class="info">
        <p class="manager contact">Relationship Manager:</p>
        <p class="name">{{ accountManager.name }}</p>
        <p class="contact">{{ accountManager.phone }}</p>
        <a class="contact" :href="mailToAddress" @click="trackEmailClick">
          {{ accountManager.email }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Segment from "services/segment_service";
import Location from "services/location_service";

export default Vue.component("account-manager-display", {
  props: {
    accountManager: {
      type: Object,
      default: () => PSData.accountManager,
    },
  },
  mounted() {
    const display = this.$el.querySelector(".account-manager-display");

    if (display) {
      display.addEventListener("click", e => {
        e.stopPropagation();
      });
    }
  },
  computed: {
    gravatarUrl() {
      return `https://www.gravatar.com/avatar/${this.accountManager.obfuscatedEmail}?d=mm&&s=80`;
    },
    mailToAddress() {
      return `mailTo:${this.accountManager.email}`;
    },
  },
  methods: {
    trackEmailClick() {
      const data = {
        accountManager: this.accountManager.name,
        source: Location.href,
      };

      Segment.track("clickAMEmailLink", data);
    },
  },
});
</script>
