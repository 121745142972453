<template>
  <div>
    <ul class="task-attachments loan-task-view-attachments-list">
      <li v-for="attachment in viewableAttachments">
        <a :href="attachment.url">{{ attachment.fullName }}</a>
        <button
          v-show="attachment.taskIds.length > 0"
          class="attachment-delete delete-action"
          @click="deleteFromDocument(attachment)"
        >
          <span class="short-icon fas fa-times"></span>
        </button>
      </li>
    </ul>
    <a
      v-if="attachments.length > collapsedMaxSize"
      class="expand-attachments-list"
      :class="showAllLinkClass"
      @click="expand"
    >
      Show all files
    </a>
  </div>
</template>
<script>
import { DetachTaskFromDocument } from "models/loan/commands/document_commands";
import { TaskAttachmentChanged } from "models/loan/events/document_events";
import Documents from "collections/documents";

const COLLAPSED_MAX_SIZE = 2;

export default {
  name: "task-attachments",
  props: {
    documents: Array,
    task: Object,
  },
  data() {
    return {
      collapsed: true,
      collapsedMaxSize: COLLAPSED_MAX_SIZE,
    };
  },
  computed: {
    attachments() {
      return new Documents(this.documents, []).orderedByNameAndDate();
    },
    showAllLinkClass() {
      return this.collapsed ? "" : "d-none";
    },
    viewableAttachments() {
      return this.collapsed ? this.attachments.slice(0, this.collapsedMaxSize) : this.attachments;
    },
  },
  methods: {
    deleteFromDocument(document) {
      if (!window.confirm("Are you sure you want to remove?")) {
        return;
      }

      const taskAttachment = document.loanTaskAttachments.find(lta => {
        return lta.loanTaskId === this.task.id;
      });

      new DetachTaskFromDocument(
        new TaskAttachmentChanged({
          document,
          taskAttachmentId: taskAttachment.id,
        })
      ).execute();
    },
    expand() {
      this.collapsed = false;
    },
  },
};
</script>
