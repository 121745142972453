import ActivityFeedEventsSection from "components/activity_feed_events/manage/section";
import CopyToClipboard from "components/copy_to_clipboard";
import LenderTagsMixin from "mixins/manage/lenders/tags";
import RepurchaseStatementShow from "components/manage/payoff_statement/repurchase/show";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-repurchase-statement-controller"),
    components: {
      ActivityFeedEventsSection,
      CopyToClipboard,
      RepurchaseStatementShow,
    },
    mixins: [LenderTagsMixin],
    data() {
      return {
        documents: PSData.documents,
        loan: PSData.loan,
        events: PSData.events,
      };
    },
  });
});
