import DDAsyncCheck from "models/diligence_document/dd_async_check";

const EXPIRE_TIME = 1 * 60 * 1000;

const Command = PS.Models.Command;
const Events = PS.Models.Diligence.Events;

class UpdateSelectedDocuments extends Command {
  execute() {
    this.state.selectedDocuments = this.state.documents.filter(document => {
      return document.documentType === this.event.documentType.documentType;
    });

    this.state.selectedDocumentId = (this.state.selectedDocuments[0] || {}).id;

    return Promise.resolve();
  }
}

class RequestDocumentSelection extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Preview.DocumentSelectionRequested,
      PS.Services.MessageBusService.diligenceBus,
      {}
    );
  }
}

class SelectDocument extends Command {
  execute() {
    this.state.selectedDocument = this.state.previewDocuments.filter(doc => {
      return doc.id === parseInt(this.state.selectedDocumentId, 10);
    })[0];

    return Promise.resolve();
  }
}

class StartLoading extends Command {
  execute() {
    let document = this.state.selectedDocument;
    if (!document || document.ajaxInProgress) {
      return Promise.resolve();
    }

    if (document.urlValidUntil < new Date().getTime()) {
      document.boxEmbedUrl = undefined;
    }

    return Promise.resolve();
  }
}

class LoadSelectedDocument extends Command {
  execute() {
    let document = this.state.selectedDocument;
    if (!document || document.ajaxInProgress) {
      return Promise.resolve();
    }

    if (!document.boxEmbedUrl) {
      document.ajaxInProgress = true;

      return new Promise((resolve, reject) => {
        const url = `/manage/embed_documents/${document.boxFileId}`;
        const sameDocument = DDAsyncCheck.generate();

        PS.Services.AjaxService.getJSON(url)
          .then(data => {
            if (sameDocument()) {
              document.boxEmbedUrl = data.url;
              document.urlValidUntil = new Date().getTime() + EXPIRE_TIME;
              resolve();
            } else {
              reject();
            }
          })
          .catch(result => {
            if (result.response.status === 403) {
              PS.Services.LocationService.assign(
                `/users/auth/box_oauth2?redirect=${PS.Services.LocationService.pathname}`
              );
              resolve();
            } else {
              reject(result);
            }
          });
      });
    } else {
      return Promise.resolve();
    }
  }
}

class FinishLoading extends Command {
  execute() {
    let document = this.state.selectedDocument;
    if (document && document.ajaxInProgress) {
      document.ajaxInProgress = false;
    }

    return Promise.resolve();
  }
}

PS.Models.Diligence.Commands.Preview = {
  LoadSelectedDocument: LoadSelectedDocument,
  RequestDocumentSelection: RequestDocumentSelection,
  SelectDocument: SelectDocument,
  StartLoading: StartLoading,
  FinishLoading: FinishLoading,
  UpdateSelectedDocuments: UpdateSelectedDocuments,
};
