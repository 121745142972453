export default class FetchConstraints {
  static async call(loanPrograms, serviceClient) {
    const constraints = {};

    const constraintsRequests = loanPrograms.map(async loanProgram => {
      const payload = {
        program_type: loanProgram,
        borrowers: [{}],
        properties: [{}],
        // FIXME: these should be removed from schema later
        ltv: 100,
        total_principal_balance_at_origination: 100000,
        loan_purpose: "acquisition",
        loan_strategy: "",
      };

      constraints[loanProgram] = await serviceClient.requestConstraints(payload);
    });

    await Promise.all(constraintsRequests);

    return constraints;
  }
}
