import EmailPreferences from "components/email_preferences";

$(() => {
  new Vue({
    el: document.querySelector(".v-email-preferences-controller"),
    components: {
      EmailPreferences,
    },
  });
});
