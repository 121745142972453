const LOCATE_VARIABLE_PATTERN = /(:[\w.\$]+)/g;
const REPLACE_VARIABLE_PATTERN = /\:(\w)/g;

class FormulaCompiler {
  static extractFields(rawExpression, metadata) {
    var rawFields = rawExpression.match(LOCATE_VARIABLE_PATTERN);
    rawFields = rawFields.filter((field, i) => {
      // return only uniq fields
      return rawFields.indexOf(field) === i;
    });

    return rawFields.map(field => {
      if (field.indexOf(".") === -1) {
        // field
        return {
          field: { name: field.slice(1) },
        };
      } else {
        // fieldset

        let fieldsetVar = field.split(".");
        let fieldData = {
          fieldset: {
            id: undefined,
            name: fieldsetVar[0].slice(1),
            modifier: fieldsetVar[1],
          },
          field: { name: fieldsetVar[2] },
        };

        const isCurrent = fieldData.fieldset.modifier === "current";
        const isIndex =
          fieldData.fieldset.modifier === "$collection" && fieldData.field.name === "$index";
        const acceptMetadata = isCurrent || isIndex;
        const shouldSetFieldsetId =
          acceptMetadata &&
          metadata.currentFieldset &&
          fieldData.fieldset.name === metadata.currentFieldset.name;

        if (shouldSetFieldsetId) {
          fieldData.fieldset.id = metadata.currentFieldset.id;
        }

        return fieldData;
      }
    });
  }

  static createVariables(fields) {
    return fields.map(field => {
      return field.fieldset
        ? new PS.Models.FormulaVariableFieldset(field)
        : new PS.Models.FormulaVariable(field);
    });
  }

  static prepareExpression(rawExpression) {
    return rawExpression.replace(REPLACE_VARIABLE_PATTERN, "context.$1");
  }

  static bakeExpression(expression) {
    return new Function("context", `return ${expression};`);
  }
}

class Formula {
  static compile(rawExpression, metadata = {}) {
    var fields = FormulaCompiler.extractFields(rawExpression, metadata);
    var variables = FormulaCompiler.createVariables(fields);
    var expression = FormulaCompiler.prepareExpression(rawExpression);
    var compiledExpression = FormulaCompiler.bakeExpression(expression);

    return new Formula(compiledExpression, variables);
  }

  constructor(expression, variables) {
    this.expression = expression;
    this.variables = variables;
    this.value = undefined;
  }

  update(fieldUpdate) {
    this.variables.forEach(variable => {
      variable.update(fieldUpdate);
    });
  }

  resetFieldsetData() {
    this.variables.forEach(variable => variable.reset());
  }

  buildContext() {
    var contexts = this.variables.map(variable => variable.toContext());
    return $.extend(true, {}, ...contexts);
  }

  compute() {
    var result = this.expression(this.buildContext());

    if ((result || result === 0) && result !== Infinity && result !== -Infinity) {
      return result;
    } else {
      return undefined;
    }
  }
}

PS.Models.FormulaCompiler = FormulaCompiler;
PS.Models.Formula = Formula;
