<template>
  <div>
    <div v-for="group in groups" :key="group.id">
      <div class="group-header py-2">
        <span>{{ group.groupName }}</span>
        <base-tooltip :label="group.tooltipText" position="is-right">
          <base-icon
            icon="question-circle"
            type="hint"
          />
        </base-tooltip>
      </div>

      <div class="is-flex flex-wrap">
        <div
          v-for="option in mainOptions(group.options)"
          :key="`${group.id}_${option.id}`"
          :class="{ 'fullwidth': fullwidthOption(option, group) }"
          class="pr-3"
        >
          <template v-if="group.isMultiselectAllowed">
            <base-checkbox
              v-model="option.value"
              :name="`option_${group.id}`"
              :native-value="option.id || 'none'"
              @click="onOptionClick(option, group)"
            >
              {{ option.optionName }}
            </base-checkbox>
          </template>
          <template v-else>
            <base-radio
              v-model="group.value"
              :name="`group_${group.id}`"
              :native-value="option.id"
              @click="onOptionClick(option, group)"
            >
              {{ option.optionName }}
            </base-radio>
          </template>
          <div v-if="option.isFreeform">
            <base-text-field
              v-model="option.freeformValue"
              label=""
              :placeholder="option.freeformPlaceholderText"
              :disabled="!option.value"
            />
          </div>
          <div v-if="hasChildren(group, option.id)" class="pl-5 is-flex flex-wrap">
            <div
              v-for="childOption in childOptions(group, option.id)"
              :key="`${group.id}_${childOption.id}`"
              :class="{ 'fullwidth': childOption.isFreeform }"
              class="pr-3"
            >
              <base-checkbox
                v-model="childOption.value"
                :name="`option_${group.id}`"
                :native-value="childOption.id"
                @click="onChildOptionClick(childOption, group)"
              >
                {{ childOption.optionName }}
              </base-checkbox>
              <div v-if="childOption.isFreeform">
                <base-text-field
                  v-model="childOption.freeformValue"
                  label=""
                  :placeholder="childOption.freeformPlaceholderText"
                  :disabled="!childOption.value"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="is-flex pt-2 pb-4">
        <div class="mr-2">
          <span>Based on Visual Observation or Surname</span>
          <base-tooltip
            :label="`${group.groupName} of Borrower collected on the Basis of Visual Observation or Surname`"
            position="is-top"
          >
            <base-icon
              icon="question-circle"
              type="hint"
            />
          </base-tooltip>
        </div>
        <div class="is-flex">
          <base-radio
            v-model="group.isBasedOnObservation"
            :disabled="isBasedOnObservationDisabled(group)"
            :name="`is_based_on_observation_${group.id}`"
            native-value="true"
            class="pr-3"
          >
            Yes
          </base-radio>
          <base-radio
            v-model="group.isBasedOnObservation"
            :disabled="isBasedOnObservationDisabled(group)"
            :name="`is_based_on_observation_${group.id}`"
            native-value="false"
          >
            No
          </base-radio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "borrower-demographic-info-form",
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isBasedOnObservationDisabled(group) {
      const notProvidedOption = group.options.find(option => option.isExclusive);
      let notProvidedOptionChecked = false;
      if (group.isMultiselectAllowed) {
        notProvidedOptionChecked = notProvidedOption.value;
      } else {
        notProvidedOptionChecked = group.value === notProvidedOption.id;
      }

      const anyOptionChecked = group.options
        .filter(option => !option.parentOptionId)
        .some(option => {
          if (group.isMultiselectAllowed) {
            return option.value;
          } else {
            return !!group.value;
          }
        });

      return notProvidedOptionChecked || !anyOptionChecked;
    },
    fullwidthOption(option, group) {
      return (
        option.isFreeform ||
        this.hasChildren(group, option.id) ||
        group.options.some(option => option.parentOptionId)
      );
    },
    mainOptions(options) {
      return options.filter(option => !option.parentOptionId);
    },
    childOptions(group, optionId) {
      return group.options.filter(option => option.parentOptionId === optionId);
    },
    hasChildren(group, optionId) {
      return group.options.some(option => option.parentOptionId === optionId);
    },
    onOptionClick(option, group) {
      if (option.isExclusive) {
        group.options.filter(opt => !opt.isExclusive).forEach(opt => {
          Vue.set(opt, "value", false);
          Vue.set(opt, "freeformValue", "");
        });
        Vue.set(group, "isBasedOnObservation", undefined);
      } else {
        Vue.set(group.options.find(opt => opt.isExclusive), "value", false);
      }

      if (!group.isMultiselectAllowed) {
        group.options.forEach(opt => {
          Vue.set(opt, "value", false);
          Vue.set(opt, "freeformValue", "");
        });
      } else {
        group.options.filter(opt => opt.parentOptionId === option.id).forEach(opt => {
          Vue.set(opt, "value", false);
          Vue.set(opt, "freeformValue", "");
        });
      }
    },
    onChildOptionClick(childOption, group) {
      if (group.isMultiselectAllowed) {
        const parentOption = group.options.find(option => option.id === childOption.parentOptionId);
        Vue.set(parentOption, "value", true);
      } else {
        Vue.set(group, "value", childOption.parentOptionId);
      }

      Vue.set(childOption, "freeformValue", "");
      Vue.set(group.options.find(opt => opt.isExclusive), "value", false);
    },
  },
};
</script>
<style scoped>
.group-header {
  font-weight: 600;
}

.fullwidth {
  width: 100%;
}
</style>
