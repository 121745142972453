export default function isValidRoutingNumber(number) {
  let n = 0;

  for (var i = 0; i < number.length; i += 3) {
    n +=
      parseInt(number.charAt(i), 10) * 3 +
      parseInt(number.charAt(i + 1), 10) * 7 +
      parseInt(number.charAt(i + 2), 10);
  }

  return n != 0 && n % 10 == 0;
}
