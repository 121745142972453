import FetchCreditbox from "./fetch_creditbox";
import FetchPricingScenarios from "./fetch_pricing_scenarios";

export default class EvaluateScenario {
  static async call(scenario, serviceClient) {
    // if multiple evaluations are requested in rapid succession
    // only the most recent request matters
    serviceClient.abortPendingRequests();

    const [pricing, creditbox] = await Promise.all([
      FetchPricingScenarios.call(scenario, serviceClient),
      FetchCreditbox.call(scenario, serviceClient),
    ]);

    return { pricing: pricing, creditbox: creditbox };
  }
}
