<template>
  <div class="market-data">
    <template v-if="marketData.zip">
      <ul v-if="showDataTables" class="market-data-tiles">
        <li class="col-2">
          <p>Quarter/Quarter</p>
          <span>{{marketData.quarterOverQuarter | percent}}</span>
        </li>
        <li class="col-2">
          <p>Year/Year</p>
          <span>{{marketData.yearOverYear | percent}}</span>
        </li>
        <li class="col-2">
          <p>Distressed</p>
          <span>{{marketData.distressedSaturation | percent}}</span>
        </li>
        <li class="col-2">
          <p>Next 12</p>
          <span>{{marketData.projected12 | percent}}</span>
        </li>
        <li class="col-2">
          <p>Next 24</p>
          <span>{{marketData.projected24 | percent}}</span>
        </li>
      </ul>
      <div v-if="showDataTables" class="market-data-tables">
        <div class="market-data-table">
          <h3>Max Decrease</h3>
          <div class="market-data-decrease">
            <template v-for="item in marketData.maxDecreases">
              <div :class="{ relevant: item.relevant }" class="market-data-item">
                <p>
                  <span>Rolling {{item.month}} Months</span>
                  {{item.value | percent}}
                </p>
              </div>
            </template>
          </div>
        </div></div>
      <div v-if="showDataTables" class="market-data-chart">
        <h3>Home Data Index</h3>
        <div id="hdi-chart"></div>
      </div>
      <div class="market-data-chart">
        <h3 v-if="showChartTitle">REO Saturation</h3>
        <div id="reo-chart"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "market-data-details",
  props: {
    marketData: Object,
    showDataTables: Boolean,
    showChartTitle: Boolean,
  },
  data: function() {
    return {
      hdiChart: undefined,
      reoChart: undefined,
    };
  },
  methods: {
    renderCharts() {
      var filter = new PS.MarketData.PointsFilter(this.marketData.points);

      if (this.hdiChart === undefined && this.reoChart === undefined) {
        this.hdiChart = new PS.MarketData.AreaChart("#hdi-chart", "hdi");
        this.reoChart = new PS.MarketData.AreaChart("#reo-chart", "reo");
      }

      this.hdiChart.render(filter.hdi());
      this.reoChart.render(filter.reo());
    },
  },
  watch: {
    marketData: {
      immediate: true,
      deep: true,
      handler(newData, oldData) {
        if (this.marketData.points && this.marketData.points.length > 0) {
          this.$nextTick(() => {
            this.renderCharts();
          });
        }
      },
    },
  },
};
</script>
