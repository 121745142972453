<template>
  <select multiple="true" class="multiselect">
    <slot></slot>
  </select>
</template>
<script>
import $ from "jquery";

export default {
  name: "multi-select2",
  props: {
    options: {
      type: Object,
      default: function() {
        return {};
      },
    },
    value: undefined,
    afterRender: {
      type: Function,
      default: () => () => {},
    },
  },
  mounted: function() {
    const $element = $(this.$el);
    $element.val(this.value).trigger("change.select2");

    $element
      .val(this.value)
      .select2(this.options)
      .on("select2:select select2:unselect", () => this.$emit("input", $element.val()))
      .on("select2:open", e => this.$emit("open", e))
      .on("select2:close", e => this.$emit("close", e));

    setTimeout(() => {
      this.afterRender();
    }, 100);
  },
  watch: {
    value: function(value, oldValue) {
      if (value !== oldValue) {
        $(this.$el)
          .val(value)
          .trigger("change");

        this.$emit("on-value-change");
      }
    },
    options: function(options) {
      $(this.$el).select2(this.options);
    },
  },
  destroyed: function() {
    $(this.$el)
      .off()
      .select2("destroy");
  },
};
</script>
