<template>
  <div>
    <time-series-comparison-chart
      :chart="chart"
      :sources="sources">
    </time-series-comparison-chart>

    <div class="row listing-stats-explanation-section">
      <div class="col-md-3">
        Current MSA Risk of Decline
      </div>
      <div class="col-md-3 value-section">
        <span v-if="sources.isLoading">
          &nbsp;
        </span>
          <span v-if="sources.isSuccessful">
          {{ sources.whatThisMeans | notAvailable }}
        </span>
      </div>
      <div class="col-md-2">
        Most Recent
      </div>
      <div class="col-md-1 value-section">
        <span v-if="sources.isLoading">
          &nbsp;
        </span>
        <span v-if="sources.isSuccessful">
          {{ sources.mostRecent | percent(0, true) | notAvailable }}
        </span>
      </div>
      <div class="col-md-2">
        One Year Ago
      </div>
      <div class="col-md-1 value-section">
      <span v-if="sources.isLoading">
        &nbsp;
      </span>
        <span v-if="sources.isSuccessful">
        {{ sources.oneYearAgo | percent(0, true) | notAvailable }}
      </span>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "d3";

import TimeSeriesComparisonChart from "components/market_data/time_series_comparison_chart";

export default {
  name: "risk-of-decline-chart",
  components: {
    TimeSeriesComparisonChart,
  },
  props: {
    sources: Object,
  },
  data() {
    return {
      chart: {
        title: "MSA Risk of Decline",
        tickFormat: format("%"),
        tooltipFormat: format(",.2%"),
        columns: {
          hcMsa: {
            color: "#66cc33",
            observed: {
              data: "MSA Risk of Decline",
              labels: "hc-msa-observed",
            },
            predicted: {
              data: "MSA Risk of Decline (forecast)",
              labels: "hc-msa-predicted",
            },
          },
        },
      },
    };
  },
};
</script>
