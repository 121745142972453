<template>
  <div>
    <div v-show="!editBorrowerView">
      <breadcrumb-navigation :items="breadcrumbItems" />

      <h3 class="loan-details-view-header clearfix pt-4">
        {{ borrower.name }}

        <base-group class="is-pulled-right">
          <base-group-control>
            <base-button type="primary" :disabled="actionDisabled" @click="onEdit">
              Edit Details
            </base-button>
          </base-group-control>
          <base-group-control v-if="canDeleteBorrower">
            <base-dropdown type="primary" position="left" :disabled="actionDisabled">
              <base-dropdown-item @click="onDelete">
                Delete this Borrower
              </base-dropdown-item>
            </base-dropdown>
          </base-group-control>
        </base-group>
      </h3>

      <loan-borrower-details :borrower="borrower" />
    </div>
    <loan-borrower-form
      v-show="editBorrowerView"
      :borrower="borrower"
      :can-update-borrower-data="canUpdateBorrowerData"
      @on-cancel="onBorrowerEditCancel"
      @on-save="onBorrowerSave"
    />
    <background-review
      :credit-report-options="creditReportOptions"
      :credit-reports="creditReports"
      :routes="routes"
    />
  </div>
</template>

<script>
import BreadcrumbNavigation from "../shared/breadcrumb_navigation";
import LoanBorrowerDetails from "./details/show";
import LoanBorrowerForm from "./borrower_form";
import Borrower from "models/borrower";
import BackgroundReview from "components/shared/background_review";
import AjaxService, { ERROR_STATUS, ERROR_MESSAGE } from "services/ajax_service";

export default {
  name: "loan-borrower",
  components: {
    BreadcrumbNavigation,
    LoanBorrowerDetails,
    LoanBorrowerForm,
    BackgroundReview,
  },
  props: {
    canUpdateBorrowerData: {
      type: Boolean,
      required: true,
    },
    canDeleteBorrower: {
      type: Boolean,
      required: true,
    },
  },
  data: function() {
    return {
      borrower: new Borrower(PSData.borrower, PSData.borrowerOptions),
      creditReports: PSData.creditReports,
      creditReportOptions: PSData.creditReportOptions,
      routes: PSData.routes,
      editBorrowerView: false,
      actionDisabled: false,
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          name: "Details",
          link: this.routes.loan,
        },
        {
          name: "Loan Sponsors",
          link: this.routes.responsibleParties,
        },
        {
          name: `Borrower - ${this.borrower.name || "(name not yet added)"}`,
        },
      ];
    },
  },
  methods: {
    onEdit() {
      this.editBorrowerView = true;
    },
    onDelete() {
      if (
        !window.confirm(
          "Are you sure you want to delete this borrower? Any associations to this borrower will no longer be linked. This cannot be undone."
        )
      ) {
        return;
      }
      this.deleteBorrower();
    },
    deleteBorrower() {
      this.actionDisabled = true;

      return AjaxService.delete(this.routes.borrower)
        .then(() => {
          window.location = this.routes.responsibleParties;
        })
        .catch(() => {
          AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
          this.actionDisabled = false;
        });
    },
    onBorrowerSave(updatedBorrower) {
      this.borrower.update(updatedBorrower);
      this.editBorrowerView = false;
    },
    onBorrowerEditCancel() {
      this.editBorrowerView = false;
      this.borrower.reset();
    },
  },
};
</script>
