<template>
  <div class="credit-box-guidelines">
    <div v-if="!isLoanGuide">
      <a href="https://peerstreet.zendesk.com/hc/en-us" target="_blank">View Credit Box, Pricing Sheets, and Diligence Requirements</a>
    </div>
  </div>
</template>
<script>
import { PROGRAM_CLASSIFICATION } from "models/lender_form/program";

const HELPDESK_URL = "https://peerstreet.zendesk.com/hc/en-us/articles";
const SECTION_NAMES = {
  [PROGRAM_CLASSIFICATION.RESIDENTIAL_BRIDGE]: "Residential",
  [PROGRAM_CLASSIFICATION.EXTENDED_BRIDGE]: "Residential",
  [PROGRAM_CLASSIFICATION.RESIDENTIAL_RENTAL_LONG_TERM]: "Residential for Rent",
  [PROGRAM_CLASSIFICATION.COMMERCIAL_MULTIFAMILY]: "Multifamily",
  [PROGRAM_CLASSIFICATION.COMMERCIAL_NON_MULTIFAMILY]: "Commercial",
};

const CREDIT_BOX_GUIDELINES = {
  [PROGRAM_CLASSIFICATION.RESIDENTIAL_BRIDGE]: {
    url: `${HELPDESK_URL}/360018533752-Residential-Bridge-1-4-Units-Credit-Box`,
    name: "Residential Bridge: Credit Box",
  },
  [PROGRAM_CLASSIFICATION.EXTENDED_BRIDGE]: {
    url: `${HELPDESK_URL}/360018533752-Residential-Bridge-1-4-Units-Credit-Box`,
    name: "Extended Bridge: Credit Box",
  },
  [PROGRAM_CLASSIFICATION.RESIDENTIAL_RENTAL_LONG_TERM]: {
    url: `${HELPDESK_URL}/360047695091-Residential-for-Rent-Credit-Box-`,
    name: "Residential for Rent: Credit Box",
  },
  [PROGRAM_CLASSIFICATION.COMMERCIAL_MULTIFAMILY]: {
    url: `${HELPDESK_URL}/360018817011-Multifamily-5-Units-Credit-Box-`,
    name: "Multifamily Bridge: Credit Box",
  },
  [PROGRAM_CLASSIFICATION.COMMERCIAL_NON_MULTIFAMILY]: {
    url: `${HELPDESK_URL}/360018817511-Commercial-Small-Balance-Credit-Box`,
    name: "Commercial Bridge (Small Balance): Credit Box",
  },
};
const LOAN_POLICY_GUIDELINES = {
  [PROGRAM_CLASSIFICATION.RESIDENTIAL_BRIDGE]: {
    url: `${HELPDESK_URL}/360035528511-Residential-Bridge-Underwriting-Guidelines`,
    name: "Residential Bridge: Underwriting Standards",
  },
  [PROGRAM_CLASSIFICATION.EXTENDED_BRIDGE]: {
    url: `${HELPDESK_URL}/360035528511-Residential-Bridge-Underwriting-Guidelines`,
    name: "Extended Bridge: Underwriting Standards",
  },
  [PROGRAM_CLASSIFICATION.RESIDENTIAL_RENTAL_LONG_TERM]: {
    url: `${HELPDESK_URL}/360047696151-Residential-for-Rent-Underwriting-Guidelines`,
    name: "Residential for Rent: Underwriting Standards",
  },
  [PROGRAM_CLASSIFICATION.COMMERCIAL_MULTIFAMILY]: {
    url: `${HELPDESK_URL}/360025230512-Multifamily-Underwriting-Standards`,
    name: "Multifamily Bridge: Underwriting Standards",
  },
  [PROGRAM_CLASSIFICATION.COMMERCIAL_NON_MULTIFAMILY]: {
    url: `${HELPDESK_URL}/360027397352-Commercial-Bridge-Underwriting-Standards`,
    name: "Commercial Bridge: Underwriting Standards",
  },
};

export default {
  props: {
    programClassification: String,
    isLoanGuide: Boolean,
  },
  methods: {
    creditBoxGuidelines(program) {
      return CREDIT_BOX_GUIDELINES[program];
    },
    loanPolicyGuidelines(program) {
      return LOAN_POLICY_GUIDELINES[program];
    },
  },
};
</script>
