<template>
  <div class="monthly-loan-sales lender-section">
    <div class="title">
      <h3>{{sales.period}}</h3>
      <span>Loans Funded Through PeerStreet</span>
    </div>
    <p>{{sales.amount | money}} <span>{{sales.count | pluralize("Loan")}}</span></p>

    <div v-if="goal.amount" class="goal">
      <p>
        <progress :max="maxProgress" :value="sales.amount"></progress>
        <span class="goal-progress-tick"></span>
        <span class="goal-progress-label">Goal</span>
      </p>

      <div class="goal-values">
        <div class="goal-value">
          <span class="goal-title">{{goal.title}}</span>
          <div class="goal-amount">{{goal.amount | money}}</div>
        </div>

        <div v-if="exceeded" class="goal-value">
          <span class="sales-title">Exceeded Goal</span>
          <div class="sales-value sales-exceeded">+{{exceeded | money}}</div>
        </div>
        <div v-else class="goal-value">
          <span class="sales-title">Remaining to Goal</span>
          <div class="sales-value sales-remaining">{{remaining | money}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { money, pluralize } from "filters";

export default {
  name: "loan-sales",
  filters: {
    money,
    pluralize,
  },
  props: {
    sales: Object,
    goal: Object,
  },
  computed: {
    maxProgress() {
      return this.goal.amount * 1.33333;
    },
    remaining() {
      return this.goal.amount - this.sales.amount;
    },
    exceeded() {
      return this.remaining >= 0 ? undefined : -this.remaining;
    },
  },
};
</script>
