<template>
  <div class="branding-notification">
    Please complete the branding setup first.
    <a :href="brandingSettingsUrl">Click here</a>
  </div>
</template>

<script>
export default {
  name: "BrandingNotification",
  props: {
    brandingSettingsUrl: { type: String, default: null },
  },
};
</script>

<style lang="scss" scoped>
.branding-notification {
  background: var(--color-yellow-light);
  border-radius: 4px;
  color: var(--color-grey-86);
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin: 16px 0;
  padding: 16px;

  a {
    color: #3299ff;
    cursor: pointer;
    text-decoration: none;
  }
}
</style>
