<template>
  <div class="loan-actions sticky-element" :data-offset="201">
    <div v-if="!editMode">
      <button class="btn btn-action-completed" @click="onPrintCoverSheet">
        Print Cover Sheet
      </button>
      <button class="ml-3 btn btn-action-cta" @click="toggleEditMode">
        Edit
      </button>
    </div>

    <div v-if="editMode">
      <button
        class="btn btn-action-cta"
        v-on:click="onUpdate"
        :disabled="updating">
        {{ updateButtonText }}
      </button>

      <button
        class="ml-3 btn btn-cancel-cta"
        v-on:click="onCancel"
        :disabled="updating">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["toggleEditMode", "editMode", "onUpdate", "onCancel", "updating", "onPrintCoverSheet"],
  computed: {
    updateButtonText() {
      return this.updating ? "Updating..." : "Update";
    },
  },
};
</script>
