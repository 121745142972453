import LoanBorrower from "components/borrowers/borrower";
import EditBorrowerFormPolicy from "policies/edit_borrower_form_policy";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-borrowers-controller"),
    components: {
      LoanBorrower,
    },
    data: {
      canUpdateBorrowerData: false,
      canDeleteBorrower: false,
    },
    mounted() {
      this.loadPolicy();
    },
    methods: {
      loadPolicy() {
        const borrowerPolicy = new EditBorrowerFormPolicy(PSData.borrowerPolicy);
        this.canUpdateBorrowerData = borrowerPolicy.canUpdateBorrowerData();
        this.canDeleteBorrower = borrowerPolicy.canDeleteBorrower();
      },
    },
  });
});
