<template>
  <div v-if="peersuiteSettingsEnabled">
    <branding-notification
      v-if="!peersuiteSubdomainIsSet"
      :branding-settings-url="brandingSettingsUrl"
    />
    <mfe-connect v-bind="emailSubscriptions" />
  </div>
  <mfe-connect v-else v-bind="lenderNotifications" />
</template>

<script>
import MfeConnect from "components/mfe_connector";
import BrandingNotification from "../components/branding_notification";

export default {
  components: {
    MfeConnect,
    BrandingNotification,
  },

  created() {
    // new PeerSuite settings
    const peersuiteSettingsServiceUrl = `${window.location.protocol}//${
      PSData.peersuiteSettingsServiceHost
    }`;
    this.peersuiteSettingsEnabled = PSData.peersuiteSettingsEnabled;
    this.peersuiteSubdomainIsSet = PSData.peersuiteSubdomainIsSet;
    this.brandingSettingsUrl = PSData.brandingSettingsUrl;
    this.emailSubscriptions = {
      serviceUrl: peersuiteSettingsServiceUrl,
      applicationName: "email_subscriptions",
      props: {
        serviceUrl: peersuiteSettingsServiceUrl,
      },
    };

    // old PeerSuite settings
    this.lenderNotifications = {
      serviceUrl: `${window.location.protocol}//${PSData.loanScenariosServiceHost}`,
      applicationName: "lender_notifications",
      props: {
        brandingSettingsUrl: `${PSData.brandingSettingsUrl}`,
      },
    };
  },
};
</script>
