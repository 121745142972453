<template>
  <modal-form
    :show="show"
    :save-disabled="saveBtnDisabled"
    :confirm="changed"
    :title="title"
    save-button-name="Save"
    class="lender-legal-entity-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <form>
      <div class="row">
        <with-errors on="licenseType" :errors="errors" class="col-6 form-group">
          <label for="licenseType">License Type</label>
          <select
            id="licenseType"
            v-model="license.licenseType"
            name="licenseType"
            class="form-control"
            @change="onTypeChange"
          >
            <option v-for="(label, key) in licensesTypes" :key="key" :value="key">
              {{ label }}
            </option>
          </select>
        </with-errors>
        <with-errors on="licenseName" :errors="errors" class="col-6 form-group">
          <label for="licenseName">License Name</label>
          <select
            id="licenseName"
            v-model="license.licenseName"
            :disabled="licenseNames.length == 0"
            name="licenseName"
            type="text"
            class="form-control"
          >
            <option v-for="(option, key) in licenseNames" :key="key" :value="option.licenseName">
              {{ option.licenseNameText }}
            </option>
          </select>
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="licenseeName" :errors="errors" class="col-6 form-group">
          <label for="licenseeName">Name of Licensee</label>
          <input
            id="licenseeName"
            v-model="license.licenseeName"
            type="text"
            class="form-control"
          >
        </with-errors>
        <with-errors on="licenseNumber" :errors="errors" class="col-6 form-group">
          <label for="licenseNumber">License #</label>
          <input
            id="licenseNumber"
            v-model="license.licenseNumber"
            type="text"
            class="form-control"
          >
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="startDate" :errors="errors" class="col-6 form-group">
          <label for="startDate">Start Date</label>
          <input
            id="startDate"
            v-model="license.startDate"
            v-mask:date
            v-datepicker
            class="date-picker form-control"
            type="text"
          >
        </with-errors>
        <with-errors on="expirationDate" :errors="errors" class="col-6 form-group">
          <label for="expirationDate">Expiration Date</label>
          <input
            id="expirationDate"
            v-model="license.expirationDate"
            v-datepicker
            v-mask:date
            class="date-picker form-control"
            type="text"
          >
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="status" :errors="errors" class="col-6 form-group">
          <label for="status">Status</label>
          <select
            id="status"
            v-model="license.status"
            name="status"
            class="form-control"
          >
            <option v-for="(label, key) in statuses" :key="key" :value="key">
              {{ label }}
            </option>
          </select>
        </with-errors>
      </div>
    </form>
  </modal-form>
</template>

<script>
import ModalForm from "components/modal_form";
import WithErrors from "components/shared/with_errors";
import AjaxService from "services/ajax_service";

import selectOptionsToMapping from "utils/select_options_to_mapping";

const FORM_MODES = {
  edit: "edit",
  create: "create",
};

export default {
  name: "LenderLegalEntitiesLicensesModalForm",
  components: { ModalForm, WithErrors },
  props: {
    onClose: {
      type: Function,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    license: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    options: {
      type: Object,
      required: true,
    },
    state: {
      type: Object,
      required: true,
    },
    routes: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      errors: {},
      saveInProgress: false,
    };
  },
  computed: {
    saveBtnDisabled() {
      return this.saveInProgress || !this.changed;
    },
    changed() {
      return this.license.changed();
    },
    title() {
      if (this.formMode === FORM_MODES.edit) {
        return `Edit ${this.state.stateLabel} License Details`;
      } else {
        return `Add ${this.state.stateLabel} License Details`;
      }
    },
    formMode() {
      if (this.license.persisted()) {
        return FORM_MODES.edit;
      } else {
        return FORM_MODES.create;
      }
    },
    licenseNames() {
      return this.options.licenseNames.filter(
        option =>
          option.state == this.state.state &&
          this.licenseTypeMatch(this.license.licenseType, option.licenseType)
      );
    },
    licensesTypes() {
      return selectOptionsToMapping(this.options.licenseTypes);
    },
    statuses() {
      return selectOptionsToMapping(this.options.statuses);
    },
  },
  methods: {
    onTypeChange() {
      if (!this.licenseNames.some(name => name.licenseName == this.licenseName)) {
        this.license.licenseName = "";
      }
    },
    licenseTypeMatch(type1, type2) {
      if ([type1, type2].includes(undefined)) {
        return false;
      }

      return [type1, type2].includes("sfr_cre") ? true : type1 == type2;
    },
    onSave() {
      this.formMode === FORM_MODES.edit ? this.updateLicense() : this.createLicense();
    },
    createLicense() {
      this.saveInProgress = true;

      const messages = { onSuccess: "License has been created", onError: this.onError };

      AjaxService.withNotifications(messages)
        .post(this.routes.licenses, this.license.toPlainObject({ toSnakeCase: true }), {
          "Content-Type": "application/json",
        })
        .then(data => this.$emit("on-save", data))
        .finally(() => (this.saveInProgress = false));
    },
    updateLicense() {
      this.saveInProgress = true;

      const messages = { onSuccess: "License has been updated", onError: this.onError };

      AjaxService.withNotifications(messages)
        .patch(
          `${this.routes.licenses}/${this.license.id}`,
          this.license.toPlainObject({ toSnakeCase: true }),
          {
            "Content-Type": "application/json",
          }
        )
        .then(data => this.$emit("on-save", data))
        .finally(() => (this.saveInProgress = false));
    },
    onError({ response, data }) {
      if (response.status === 403) {
        return { message: "Unauthorized", status: "error" };
      } else {
        this.errors = data.errors;
        return null;
      }
    },
  },
};
</script>
