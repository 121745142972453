<template>
  <div class="report-lender-name">
    <span v-if="!showLenderBlocked" class="lender-name">
      <span v-if="showBrokerTag" class="broker-tag"></span>
        {{ lender.name }}
      </span>
    </span>

    <span v-else class="lender-name lender-blocked">
      <a href="#" tabindex=-1, v-popover>
        <i v-if="showLenderBlocked" class="fas fa-times-circle fa-lg"></i>
        <span v-if="showBrokerTag" class="broker-tag"></span>
        {{ lender.name }}
      </a>
      <div class="popover-content hidden">
        <p>{{ lender.lastBlockStatus.description }}</p>
      </div>
    </span>
  </div>
</template>

<script>
import get from "lodash/get";

const BROKER = "broker";

export default {
  name: "report-lender-name",
  props: {
    lender: Object,
    lenderBlockStatusDisplayable: {
      type: Boolean,
      default: false,
    },
    brokerTagDisplayable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showLenderBlocked() {
      return this.lenderBlockStatusDisplayable && this.isLenderBlocked;
    },
    isLenderBlocked() {
      return get(this.lender, "lastBlockStatus");
    },
    showBrokerTag() {
      return this.brokerTagDisplayable && this.lender.accountType === BROKER;
    },
  },
};
</script>
