import Event from "models/event";
import Command from "models/command";
import {
  SendMessageRequested,
  EventsUpdateRequested,
  ComponentLoadingRequested,
  ErrorDisplayRequested,
} from "models/activity_feed_events/events";

import AjaxService from "services/ajax_service";
import MessageBusService from "services/message_bus_service";

export class RequestComponentLoading extends Command {
  execute() {
    return Command.broadcastEvent(
      ComponentLoadingRequested,
      MessageBusService.loanBus,
      this.event.options
    );
  }
}

export class RequestSendingMessage extends Command {
  execute() {
    return Command.broadcastEvent(
      SendMessageRequested,
      MessageBusService.loanBus,
      this.event.options
    );
  }
}

export class RequestEventsUpdate extends Command {
  execute() {
    return Command.broadcastEvent(
      EventsUpdateRequested,
      MessageBusService.loanBus,
      this.event.options
    );
  }
}

export class RequestDisplayErrors extends Command {
  execute() {
    return Command.broadcastEvent(
      ErrorDisplayRequested,
      MessageBusService.loanBus,
      this.event.options
    );
  }
}

export class SendMessage extends Command {
  execute() {
    const headers = { "Content-Type": "application/json; charset=utf-8" };

    return AjaxService.post("/messages", this.event.requestParams, headers)
      .then(data => {
        new RequestEventsUpdate(new Event({ newEvent: data.event })).execute();
      })
      .catch(({ data }) => {
        new RequestDisplayErrors(new Event({ errors: data.errors })).execute();
      });
  }
}

export class ClearMessageInput extends Command {
  execute() {
    this.state.content = "";

    return Promise.resolve();
  }
}

export class BlockUI extends Command {
  execute() {
    this.state.uiIsBlocked = true;

    return Promise.resolve();
  }
}

export class UnblockUI extends Command {
  execute() {
    this.state.uiIsBlocked = false;

    return Promise.resolve();
  }
}

export class UpdateMessages extends Command {
  execute() {
    this.state.events.push(this.event.newEvent);

    return Promise.resolve();
  }
}

export class ScrollToLastMessage extends Command {
  execute() {
    const messageEl = this.state.$el.querySelector(".messages-list");
    if (messageEl) {
      messageEl.scrollTop = messageEl.scrollHeight;
    }

    return Promise.resolve();
  }
}

export class DisplayErrors extends Command {
  execute() {
    this.state.errors = this.event.errors;

    return Promise.resolve();
  }
}
