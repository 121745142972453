<template>
  <view-panel>
    <template slot="header">
      <editable-view-panel-header :title="panelTitle" :on-edit="openEditModal" :can-edit="canEdit" />
    </template>
    <table class="view-table">
      <thead>
        <tr>
          <th>Document</th>
          <th>Status</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="doc in documents" :key="doc.documentType">
          <td class="view-table-cell-label">
            {{ doc.documentTypeLabel }}
          </td>
          <td class="view-table-cell-label">
            {{ doc.statusLabel }}
          </td>
          <td class="view-table-cell-notes">
            {{ doc.notes || "- -" }}
          </td>
        </tr>
      </tbody>
    </table>
    <documents-form-modal
      :show="isEditModalOpened"
      :title="formTitle"
      :documents="documentsToUpdate"
      :statuses-options="statusesOptions"
      :route="route"
      @close="closeEditModal"
      @lender-legal-entity-docs-updated="updateDocs"
    />
  </view-panel>
</template>
<script>
import AjaxService from "services/ajax_service";
import selectOptionsToMapping from "utils/select_options_to_mapping";
import ViewPanel from "components/view_panel.vue";
import DocumentsFormModal from "./documents_form_modal";
import Document from "models/lender_legal_entities/document";
import EditableViewPanelHeader from "components/lender_legal_entities/shared/editable_view_panel_header";

export default {
  name: "documents-show-panel",
  components: {
    ViewPanel,
    DocumentsFormModal,
    EditableViewPanelHeader,
  },
  props: {
    panelTitle: {
      required: true,
      type: String,
    },
    formTitle: {
      required: true,
      type: String,
    },
    policy: {
      type: Object,
      required: true,
    },
    route: {
      required: true,
      type: String,
    },
  },
  data: function() {
    return {
      isEditModalOpened: false,
      documents: [],
      documentsToUpdate: [],
      statusesOptions: {},
    };
  },
  computed: {
    canEdit() {
      return this.policy.canManage;
    },
  },
  mounted() {
    AjaxService.getJSON(this.route).then(data => {
      this.updateDocs(data.documents);
      this.statusesOptions = selectOptionsToMapping(data.options.statuses || []);
    });
  },
  methods: {
    openEditModal() {
      this.isEditModalOpened = true;
    },
    closeEditModal() {
      this.isEditModalOpened = false;
      this.documentsToUpdate.forEach(doc => doc.reset());
    },
    updateDocs(docs) {
      this.documents = Document.initDocuments(docs);
      this.documentsToUpdate = Document.initDocuments(docs);
    },
  },
};
</script>
