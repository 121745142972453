export default {
  tooltip: {
    assetValue:
      "The lowest of the valuation report, valuation validation, or purchase price (for acquisitions). Minimum Asset Value is ",
    ltv:
      "Loan to Value. The A-Piece amount that is currently distributed divided by the as-is value.",
    ltc:
      "Loan to Cost. The A-Piece amount that is currently distributed divided by the cost basis (purchase price plus the paid hard construction costs).",
    loanAmount:
      "The total principal balance of the loan which includes the balance of any principal held back for reserves.",
    dscr:
      "Debt Service Coverage Ratio. The gross ratio of the designated property revenue divided by the sum of principal, interest, taxes, insurance, HOA dues (if applicable), and Property Management Expenses (if applicable).",
    designatedCreditScore:
      "The lowest median score among all guarantors shall be the Designated Credit Score. Minimum allowed Credit Score is ",
    designatedTrackRecord:
      "Comparable projects previously completed by the borrower. The track record template should be used. Properties listed should have similar loan strategies i.e. rental properties for rental loans.",
    propertyCurrentlyLeased:
      "Properties are considered leased if a tenant is in place today, or a lease has been signed that begins in 45 days or less. For loans secured by multiple units, properties are considered leased if a maximum of a single unit is vacant, or ≤ 10% of the property is vacant.",
    yearlyTaxesOwed:
      "The annual property taxes due, or if property taxes are due more frequently than yearly, the annualized taxes due.",
    yearlyInsurancePremium:
      "The sum of the annual premiums from property, casualty, and flood insurance.",
    monthlyRentalIncomeLeased: "Lesser of Monthly Market Rent and Monthly Gross Rental Income.",
    monthlyRentalIncomeUnleased: "90% of Monthly Market Rent.",
    monthlyRentalIncome:
      "For Unleased Properties: 90% of Monthly Market Rent.<br>For Leased Properties: Lesser of Monthly Market Rent and Monthly Gross Rental Income.",
    residentialBridge: {
      arv:
        "After repair value. This value should be greater than As Is Value + Construction Budget.",
      assetValue:
        "PeerStreet’s valuation process compares the variance between two third-party reports (an appraisal and either a validation or the purchase price if an acquisition) to determine the Designated Value. If the variance is less than or equal to 10%, the higher of the two values will be used. Otherwise, the lower of the two values will be designated. Minimum Asset Value is ",
      bPiece: "Subordinate position in the 1st lien",
      designatedCreditScore:
        "The highest median score among all guarantors. Minimum allowed Credit Score is ",
    },
    createLoan: "Select a scenario to convert to a PeerStreet draft loan",
    disabledRental: "Please contact your RM for Residential for Rent inquiries",
  },
};
