<template>
  <div>
    <div class="columns">
      <base-text-field
        v-model="detail.ruleNameAndOrFieldRequirement"
        label="Rule Name and/or Field Requirement"
        placeholder="Rule Name and/or Field Requirement"
        class="column"
      />
    </div>
    <div class="columns">
      <base-text-field
        v-model="detail.standardReferenced"
        label="Standard Referenced"
        placeholder="Standard Referenced"
        class="column"
      />
    </div>
    <div class="columns">
      <base-text-field
        v-model="detail.dataChecked"
        label="Data Checked"
        placeholder="Data Checked"
        class="column"
      />
    </div>
    <div class="columns">
      <base-select-field
        v-model="detail.errorCode"
        :options="errorCodes"
        show-empty
        label="Error Code"
        class="column"
      />
    </div>
    <div class="columns">
      <base-text-field
        v-model="detail.categoryTag"
        label="Category Tag"
        placeholder="Category Tag"
        class="column"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    errorCodes: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
