<template>
  <base-tooltip
    multilined
    position="is-right"
  >
    <template v-slot:content>
      Vesting Clause can either be system generated or customized.<br/>
      * For individuals, system will require gender and marital status to be specified. If property is sole property of one individual, specify so.<br/>
      * For trust, system will require name and title of signatories associated to borrower to be specified.<br/>
      * For non-trust entity types, system will require entity type and formation state to be specified.<br/>
    </template>
    <base-icon
      icon="question-circle"
      type="hint"
    />
  </base-tooltip>
</template>

<script>
export default {
  name: "vesting-clause-tooltip",
};
</script>
