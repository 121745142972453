export default class PropertyValuationOrder {
  constructor(options) {
    this.reportTypes = options.orderReportTypes;
    this.turnTimeTypes = options.orderTurnTimeTypes;

    this.reportType = undefined;
    this.turnTime = undefined;
    this.notes = undefined;

    this.reset();
  }

  reset() {
    this.reportType = this.reportTypes[0].name;
    this.resetTurnTime();
    this.notes = undefined;
  }

  resetTurnTime() {
    this.turnTime = this.turnTimeOptions()[0].name;
  }

  turnTimeOptions() {
    return this.turnTimeTypes.find(o => {
      return o.name == this.reportType;
    }).values;
  }

  toHash() {
    return {
      report_type: this.reportType,
      turn_time: this.turnTime,
      notes: this.notes,
    };
  }
}
