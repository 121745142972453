<template>
  <span>
    <template v-if="peersuiteSubdomainIsSet">
      <base-button
        v-if="type === 'primary'"
        @click="openModal"
        type="primary"
      >
        Share Tasks
      </base-button>
      <button
        v-else
        @click="openModal"
        type="button"
        class="btn-action-cta btn btn-outline-grey prepare-disclosure"
      >
        Share Tasks
      </button>
    </template>
    <template v-else>
      <base-tooltip always :active="showPeersuiteSubdomainIsNotSetTooltip" position="is-bottom">
        <base-button
          v-if="type === 'primary'"
          @click="showPeersuiteSubdomainIsNotSetTooltip = !showPeersuiteSubdomainIsNotSetTooltip"
          type="primary"
        >
          Share Tasks
        </base-button>
        <button
          v-else
          @click="showPeersuiteSubdomainIsNotSetTooltip = !showPeersuiteSubdomainIsNotSetTooltip"
          type="button"
          class="btn-action-cta btn btn-outline-grey prepare-disclosure"
        >
          Share Tasks
        </button>
        <template v-slot:content>
          Please set your <a href="/peersuite_settings/branding">PeerSuite subdomain</a> first.
        </template>
      </base-tooltip>
    </template>
  </span>
</template>

<script>
import GlobalBusService from "services/global_bus_service";

export default {
  name: "share-tasks-button",
  props: {
    peersuiteSubdomainIsSet: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showPeersuiteSubdomainIsNotSetTooltip: false,
    };
  },
  methods: {
    openModal() {
      GlobalBusService.$emit("open-invite-borrower");
    },
  },
};
</script>
