<template>
  <div class="details-row row no-gutters section-content">
    <section-header
      :actions-hidden="actionsHidden"
      subheading="Track attempts to recover the loan asset"
    >
      <h4>Workout Case</h4>
    </section-header>
    <div class="col-12">
      <notification-group
        :title="'Workout Case Operation Error'"
        :message-groups="errors"
        :dismissible="true"
        @dismissed="clearErrors"
      />
    </div>
    <div v-if="emptyWorkoutCases" class="col-12">
      <ul class="loan-block">
        <li>
          <span class="no-status-state not-applicable-section">No workout case added </span>
        </li>
      </ul>
    </div>
    <div v-else class="col-12">
      <table class="table workout-case-table">
        <thead>
          <tr class="workout-case-summary-header">
            <th> {{ displayWorkoutTableHeader() }} &middot; {{ lastWorkoutCaseUpdate }}</th>
            <th>Case ID</th>
            <th>Cause</th>
            <th>Duration</th>
            <th>Outcome</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <workout-case-list-item
            v-for="workoutCase in workoutCases"
            :key="workoutCase.caseNumber"
            :workout-case="workoutCase"
            :causes="foreclosureCauseTypes"
            :outcomes="foreclosureOutcomeTypes"
            @delete="handleDelete"
          />
        </tbody>
      </table>
    </div>
    <div class="col-6">
      <div v-if="canEdit" class="new-workout-case-link">
        <a href="asset_management/workout_cases/new">+ Add Workout Case</a>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "./section_header";
import WorkoutCaseListItem from "./foreclosure_workout/workout_case_list_item";
import NotificationGroup from "components/shared/notification_group";
import AjaxService from "services/ajax_service";
import toPlainObject from "utils/to_plain_object";
import { formatDate } from "filters";

export default {
  name: "workout-case-summary",
  components: {
    NotificationGroup,
    SectionHeader,
    WorkoutCaseListItem,
  },
  data() {
    return {
      errors: [],
      workoutCases: PSData.foreclosureWorkoutCases || [],
    };
  },
  computed: {
    actionsHidden: () => true,
    canEdit: () => PSData.assetManagementCanEdit,
    emptyWorkoutCases() {
      return this.workoutCases.length == 0;
    },
    foreclosureCauseTypes: () => PSData.loanForeclosureCauseTypes,
    foreclosureOutcomeTypes: () => PSData.loanForeclosureOutcomeTypes,
    lastWorkoutCaseUpdate() {
      const lastUpdate = PSData.lastWorkoutCaseUpdate;
      return `Updated ${formatDate(new Date(lastUpdate), "MMMM DD, YYYY [at] h:mm A")}`;
    },
  },
  methods: {
    clearErrors() {
      this.errors = [];
    },
    displayWorkoutTableHeader() {
      if (this.workoutCases.length > 1) {
        return `${this.workoutCases.length} workout cases`;
      } else {
        return `${this.workoutCases.length} workout case`;
      }
    },
    errorMessage() {
      const message = "Unable to delete workout case at this time";
      return { message, status: "error" };
    },
    async handleDelete(workoutCaseId) {
      try {
        await AjaxService.withNotifications({
          onSuccess: "Workout Case was succesfully deleted",
          onError: this.errorMessage,
        }).delete(`asset_management/workout_cases/${workoutCaseId}`);

        this.workoutCases = this.workoutCases.filter(wc => {
          return wc.id !== workoutCaseId;
        });
      } catch ({ data, response }) {
        const genericErrorMessage = "Unable to delete workout case";
        this.errors = [genericErrorMessage];
        Bugsnag.notify(
          { name: "Request Error", message: data.error || genericErrorMessage },
          { metaData: { custom: { response: toPlainObject(response) } } }
        );
      }
    },
  },
};
</script>
