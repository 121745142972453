<template>
  <div class="responsible-party warm-body" :class="{ nested }">
    <div class="name">
      <img :src="icon" alt="Guarantor or Other Party">
      {{ responsibleParty.name }}
    </div>
    <div class="meta">
      <span v-for="role in responsibleParty.responsibleRoles" :key="role.id" :class="getClass(role)">
        {{ role.label }}
      </span>
      <span class="actions">
        <a href="#" @click.prevent="editParty">
          <img :src="require('images/icons/pencil-alt.svg')" alt="Edit">
          Edit
        </a>
      </span>
      <span class="actions">
        <a :href="detailsPath">View Details</a>
      </span>
    </div>
  </div>
</template>

<script>
const borrowerIcon = require("images/icons/responsible-party-building.svg");
const userIcon = require("images/icons/responsible-party-user.svg");

export default {
  name: "responsible-party-warm-body",
  props: {
    nested: {
      type: Boolean,
      default: true,
    },
    responsibleParty: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      routes: PSData.routes,
    };
  },
  computed: {
    icon() {
      if (this.responsibleParty.type === "individual") {
        return userIcon;
      } else {
        return borrowerIcon;
      }
    },
    isGuarantor() {
      return this.responsibleParty.isGuarantor;
    },
    detailsPath() {
      return `${this.routes.responsiblePartiesPath}/${this.responsibleParty.id}`;
    },
  },
  methods: {
    editParty() {
      this.$emit("edit-party", this.responsibleParty);
    },
    getClass(role) {
      if (role.label === "Guarantor") {
        return "guarantor-role";
      } else {
        return "";
      }
    },
  },
};
</script>
