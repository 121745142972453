<template>
  <modal-form
    :show="show"
    :save-disabled="!responsibleParty"
    title="Add Loan Sponsor"
    class="responsible-party-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <div v-if="!responsibleParty" class="ps-loader" />
    <form v-else>
      <div class="row">
        <with-errors on="name" :errors="errors" class="col-6 control-wrapper form-group">
          <label for="name">Name</label>
          <input
            id="name"
            v-model="responsibleParty.name"
            type="text"
            class="form-control"
          >
        </with-errors>
        <with-errors on="responsiblePartyType" :errors="errors" class="col-6 control-wrapper form-group">
          <label for="partyType">Type</label>
          <select
            id="partyType"
            v-model="responsibleParty.type"
            name="partyType"
            class="form-control"
          >
            <option value disabled>
              Select type
            </option>
            <option
              v-for="[label, value] in options.responsiblePartyTypes"
              :key="value"
              :value="value"
              :disabled="isPartyTypeDisabled(value)"
            >
              {{ label }}
            </option>
          </select>
        </with-errors>
      </div>
      <div class="row">
        <with-errors :errors="errors" on="tin" class="col-6 control-wrapper form-group">
          <label for="tin">{{ responsibleParty.tinFieldLabel }}</label>
          <input
            id="tin"
            :key="tinMask"
            v-model="responsibleParty.tin"
            v-mask:[tinMask]=""
            class="form-control"
          >
        </with-errors>
        <with-errors
          v-if="showResidencyStatus"
          on="residency"
          :errors="errors"
          class="col-6 control-wrapper form-group"
        >
          <label for="residency">Residency Status</label>
          <select
            id="residency"
            v-model="responsibleParty.residency"
            name="residency"
            class="form-control"
          >
            <option value />
            <option v-for="[label, name] in options.responsiblePartyResidency" :key="name" :value="name">
              {{ label }}
            </option>
          </select>
        </with-errors>
      </div>
      <h5>Individual/Entity Role(s)</h5>
      <role-form-row
        v-for="(role, index) in responsibleParty.roles"
        :key="role.id"
        :role="role"
        :borrowers="borrowers"
        :errors="getRoleErrors(index)"
        :index="index"
        :role-definitions="roleDefinitions"
        :is-entity="responsibleParty.isEntity"
        @remove-role="removeRole"
      />
      <a v-if="canAddMoreRoles" href="#" @click.prevent="addNewRole">+ Add Another Role</a>
    </form>
  </modal-form>
</template>

<script>
import ModalForm from "components/modal_form";
import RoleFormRow from "./responsible_roles/form_row";
import AjaxService from "services/ajax_service";
import ResponsibleParty from "models/responsible_party";
import WithErrors from "components/shared/with_errors";

import get from "lodash/get";

export default {
  name: "add-responsible-party-modal",
  components: { ModalForm, RoleFormRow, WithErrors },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    responsiblePartyId: {
      type: Number,
      required: false,
      default: null,
    },
    borrowers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: PSData.options,
      routes: PSData.routes,
      responsibleParty: undefined,
      roleDefinitions: PSData.responsibleRoleDefinitions,
      errors: {},
    };
  },
  computed: {
    canAddMoreRoles() {
      return this.responsibleParty.canAddMoreRoles;
    },
    tinMask() {
      return this.responsibleParty.tinFieldMask;
    },
    showResidencyStatus() {
      return this.responsibleParty.isIndividual;
    },
  },
  watch: {
    responsibleParty: {
      deep: true,
      handler() {
        this.resetErrors();
      },
    },
  },
  mounted() {
    if (this.responsiblePartyId) {
      AjaxService.getJSON(
        `${this.routes.responsiblePartiesPath}/${this.responsiblePartyId}/edit`
      ).then(responsibleParty => {
        this.responsibleParty = ResponsibleParty.deserialize(responsibleParty);
      });
    } else {
      this.responsibleParty = ResponsibleParty.buildEmpty();
    }
  },
  methods: {
    isPartyTypeDisabled(type) {
      return this.responsibleParty.isIndividualWise && type !== "individual";
    },
    addNewRole() {
      this.responsibleParty.addNewRole();
    },
    removeRole(role) {
      this.responsibleParty.removeRole(role);
    },
    onClose() {
      this.$emit("close");
    },
    onSave() {
      const params = {
        responsible_party: this.responsibleParty.toPlainObject({ toSnakeCase: true }),
      };

      if (this.responsibleParty.isPersisted) {
        this.updateParty(params);
      } else {
        this.createParty(params);
      }
    },
    createParty(params) {
      AjaxService.withNotifications()
        .postJSON(this.routes.responsiblePartiesPath, params)
        .then(data => {
          this.$emit("on-parties-update", data);
          this.onClose();
        })
        .catch(({ response, data }) => {
          if (response.status === 422) {
            this.errors = data.errors || {};
          } else {
            Bugsnag.notify(new Error(`Unable to create responsible party: ${response.statusText}`));
          }
        });
    },
    updateParty(params) {
      AjaxService.withNotifications()
        .patchJSON(`${this.routes.responsiblePartiesPath}/${this.responsibleParty.id}`, params)
        .then(data => {
          this.$emit("on-parties-update", data);
          this.onClose();
        })
        .catch(({ response, data }) => {
          if (response.status === 422) {
            this.errors = data.errors || {};
          } else {
            Bugsnag.notify(new Error(`Unable to update responsible party: ${response.statusText}`));
          }
        });
    },
    getRoleErrors(index) {
      return get(this.errors, "responsible_roles", [])[index];
    },
    resetErrors() {
      this.errors = {};
    },
  },
};
</script>
