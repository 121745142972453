<template>
  <div class="incomplete-task">
    <h4 class="loan-details-section-header">
      Borrower Contact Information
      <span>
        Valid information is required for servicing payments
      </span>
    </h4>

    <borrower-info-table
      :borrowers="borrowers"
      :borrower-residency="borrowerResidency"
      :borrower-types="borrowerTypes"
      :us-states="usStates"
      :genders="genders"
      :can-update-borrower-data="canUpdateBorrowerData"
      @borrower-created="borrowerCreated"
      @borrower-updated="borrowerUpdated"
      @borrower-deleted="borrowerDeleted"
    />
  </div>
</template>

<script>
import BorrowerInfoTable from "components/borrower/info_table";
import selectOptionsToMapping from "utils/select_options_to_mapping";

export default {
  name: "borrower-info-section",
  components: {
    BorrowerInfoTable,
  },
  props: {
    canUpdateBorrowerData: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      borrowers: PSData.borrowers,
      borrowerResidency: selectOptionsToMapping(PSData.borrowerResidency),
      borrowerTypes: selectOptionsToMapping(PSData.borrowerTypes),
      usStates: selectOptionsToMapping(PSData.usStates),
      genders: selectOptionsToMapping(PSData.borrowerGenders),
    };
  },
  methods: {
    borrowerCreated(borrower) {
      this.borrowers.push(borrower);
    },
    borrowerUpdated(borrower) {
      let idx = this.borrowers.findIndex(b => b.id === borrower.id);
      Vue.set(this.borrowers, idx, borrower);
    },
    borrowerDeleted(borrower) {
      this.borrowers = this.borrowers.filter(b => b.id !== borrower.id);
    },
  },
};
</script>
