<template>
  <base-tag v-if="success" type="success">Success</base-tag>
  <base-tag v-else-if="completed" type="danger">Error</base-tag>
  <base-tag v-else type="warning">In Progress</base-tag>
</template>

<script>
export default {
  props: {
    success: {
      type: Boolean,
      required: true,
    },
    completed: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
