<template>
  <div class="tasklist-templates-list">
    <base-loading :show="loading" />
    <template-group
      v-for="group in groups"
      :key="group.name"
      :name="group.name"
      :templates="group.templates"
      :can-manage="canManage"
      class="pt-2 pb-4"
    />
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import TasklistTemplate, { MANAGE_TASKLIST_TEMPLATE_ROUTE } from "models/tasklist_template";
import TemplateGroup from "./templates_group";

export default {
  components: {
    TemplateGroup,
  },
  data: () => ({
    loading: false,
    groups: [],
    canManage: false,
  }),
  mounted() {
    this.loading = true;
    AjaxService.getJSON(MANAGE_TASKLIST_TEMPLATE_ROUTE)
      .then(data => {
        this.updateRecords(data.groups || []);
        this.canManage = data.can_manage;
      })
      .catch(() => {
        this.groups = [];
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    updateRecords(groups = []) {
      this.groups = groups.map(item => {
        return {
          name: item.name,
          templates: (item.templates || []).map(template => new TasklistTemplate(template)),
        };
      });
    },
    onTemplateCreate() {},
  },
};
</script>
<style scoped>
.tasklist-templates-list {
  min-height: 100px;
  position: relative;
}
</style>
