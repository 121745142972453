<template>
  <div v-if="showTip" class="loan-documents-tip">
    <i class="far fa-clock clock-icon" />
    <h5>
      <template v-if="uploadEnabled">
        For faster and more efficient submission review, use the dropdown menu to tag your
        loan docs to the associated task.
      </template>
      <template v-else>
        For faster and more efficient submission review,
        please submit the loan and use the tasklist to upload documents.
      </template>
    </h5>
    <i class="fas fa-times close-tip" @click="closeTip" />
  </div>
</template>

<script>
import LocalStorageService from "services/local_storage_service";

const TIP_KEY = "ps.loan_docs_tip.show";

export default {
  name: "loan-documents-tip",
  props: {
    uploadEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const storage = new LocalStorageService();
    return {
      storage: storage,
      showTip: storage.getItem(TIP_KEY) !== false,
    };
  },
  methods: {
    closeTip() {
      this.storage.setItem(TIP_KEY, false);
      this.showTip = false;
    },
  },
};
</script>
