<template>
  <div>
    <div v-for="rule in violationRules" :key="rule.explanation" v-if="showMessage">
      <div
          class="alert alert-danger loan-constraints-violation-alert notification-message"
          v-if="showAlert(rule)"
      >
        <div><i class="fas fa-times-circle fa-lg"></i></div>
        <div>
          <h3>PeerStreet Restriction. Do not buy/fund.</h3>
          <p>{{rule.explanation}}</p>
        </div>
      </div>

      <div
          class="alert alert-warning loan-constraints-violation-alert notification-message"
          v-if="showWarning(rule)"
      >
        <div><i class="fas fa-exclamation-triangle fa-lg"></i></div>
        <div>
          <h3>PeerStreet Restriction</h3>
          <p>{{rule.explanation}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { prepareRules } from "models/credit_box/credit_box_rules";

export default {
  name: "licensing-violation-alert",
  props: {
    rules: Array,
  },
  computed: {
    showMessage() {
      return this.violationRules.length > 0;
    },
    violationRules() {
      return prepareRules(this.rules);
    },
  },
  methods: {
    showAlert(rule) {
      return rule.explanationType === "critical";
    },
    showWarning(rule) {
      return rule.explanationType === "warning";
    },
  },
};
</script>
