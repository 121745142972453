<template>
  <modal-view :show="show" :on-close="onClose" class="base-modal institution-sales-tracking-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          Move to Offer
        </h4>
      </div>
      <div class="modal-body">
        <offering-details :offering="offering"></offering-details>
        <form>
          <div class="form-group">
            <label>Notes</label>
            <textarea class="form-control notes-input" v-model="offeringForm.notes"/>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div>
          <button class="btn btn-outline-grey" @click="clearNotes">Clear notes</button>
        </div>
        <div>
          <button class="btn btn-cancel-cta btn-link" @click="onClose">
            Cancel
          </button>
          <button class="btn btn-action-cta" @click="onSave">
            Save
          </button>
        </div>
      </div>
    </div>
  </modal-view>
</template>
<script>
import ModalView from "components/modal_view";
import LoanOffering from "models/institution/loan_offering";
import OfferingDetails from "./offering_details";

export default {
  name: "move-to-offer-modal",
  components: {
    ModalView,
    OfferingDetails,
  },
  props: {
    show: Boolean,
    offering: Object,
  },
  data() {
    return {
      offeringForm: {
        nextAction: null,
        state: "accepted",
        notes: this.offering.notes,
      },
    };
  },
  methods: {
    clearNotes() {
      this.offeringForm.notes = "";
    },

    onClose() {
      this.$emit("close");
    },

    onSave() {
      this.$emit("save", this.offering, this.offeringForm);
    },
  },
};
</script>
