<template>
  <div class="sticky-preview sticky-element" :data-offset="218">
    <div class="preview-container">
      <div class="preview-inner">
        <select2
          v-model="selectedDocumentId"
          class="form-control"
          placeholder="Type to select document"
        >
          <optgroup
            v-for="documentType in selectedDocumentTypes"
            :key="documentType.text"
            :label="documentType.text"
          >
            <option
              v-for="document in groupedDocuments[documentType.documentType]"
              :key="document.id"
              :value="document.id"
            >
              {{ document.name }}
            </option>
          </optgroup>
        </select2>
        <div v-if="selectedDocument">
          <div v-if="selectedDocument.boxFileId">
            <div v-show="selectedDocument.boxEmbedUrl === undefined" class="ps-loader"></div>
            <div v-show="selectedDocument.boxEmbedUrl !== undefined" class="box-container">
              <iframe
                allowfullscreen=""
                frameborder="0"
                height="1000"
                msallowfullscreen=""
                :src="selectedDocument.boxEmbedUrl"
                webkitallowfullscreen=""
                width="500"
              ></iframe>
            </div>
          </div>
          <div v-else="" class="error-notification">
            <p>No boxFileId (File has not uploaded to Box yet)</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoumentTypes from "collections/document_types";

const Commands = PS.Models.Diligence.Commands;

export default Vue.component("diligence-document-preview", {
  props: {
    types: Array,
    documents: Array,
  },
  data: function() {
    return {
      selectedDocumentId: undefined,
      selectedDocument: undefined,
      selectedDocuments: [],
      rulebook: undefined,
      bus: PS.Services.MessageBusService.diligenceBus,
    };
  },
  created: function() {
    this.rulebook = new PS.Models.Diligence.PreviewRule(this);
    this.bus.attachRulebook(this.rulebook);
  },
  beforeDestroy: function() {
    this.bus.detachRulebook(this.rulebook);
  },
  computed: {
    previewDocuments: function() {
      return coerceDocuments(this.documents);
    },
    previewSelectedDocuments: function() {
      return coerceDocuments(this.selectedDocuments);
    },
    selectedDocumentTypes: function() {
      let noType = { name: undefined, text: "Not categorized" };

      return [...this.prioritizedTypes, noType].filter(t => {
        return this.previewDocuments.some(d => {
          return d.documentType === t.documentType;
        });
      });
    },
    groupedDocuments: function() {
      return this.selectedDocumentTypes.reduce((opts, t) => {
        opts[t.documentType] = this.previewDocuments.filter(d => d.documentType === t.documentType);
        return opts;
      }, {});
    },
    prioritizedTypes() {
      return this.types.sort(DoumentTypes.sortByPriority("ddToolPreviewPriority"));
    },
  },
  watch: {
    selectedDocumentId: {
      handler: function() {
        new Commands.Preview.RequestDocumentSelection(new PS.Models.Event(), {}).execute();
      },
      immediate: true,
    },
  },
});

function coerceDocuments(documents) {
  return documents.map(document => {
    return {
      id: document.id,
      name: document.fullName,
      documentType: document.documentType,
      boxEmbedUrl: undefined,
      urlValidUntil: undefined,
      ajaxInProgress: false,
      boxFileId: document.boxFileId,
    };
  });
}
</script>
