<template>
  <div>
    <div class="exceptions-report-import">
      <h3>
        Exception Report Imports
      </h3>
      <file-upload
        v-if="canManage"
        class="btn-action-cta btn-upload"
        :initial-options="uploadSettings"
        @on-upload-success="saveFiles"
        @on-upload-error="showError"
      >
        New Import
      </file-upload>
    </div>
    <report-imports-filter @on-period-change="handleSearch" :reportableMonths="reportableMonths" />
    <doc-tracking-imports :imports="reportImports" :loading="reportImportsLoading" />
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import FileUpload from "components/file_upload.vue";
import ReportImportsFilter from "components/physical_doc_tracking_imports/imports_filter.vue";
import DocTrackingImports from "components/physical_doc_tracking_imports/index.vue";
import { success, error } from "services/notification";

export default {
  name: "physical-doc-tracking-report-imports-controller",
  components: { FileUpload, ReportImportsFilter, DocTrackingImports },
  data() {
    return {
      canManage: PSData.policy.manage,
      uploadSettings: PSData.physicalDocTrackingReportUploadSettings,
      reportImports: [],
      reportImportsLoading: false,
      reportableMonths: PSData.reportableMonths,
    };
  },
  methods: {
    saveFiles(files) {
      return PS.Services.AjaxService.post(this.uploadSettings.saveUploadedFilesUrl, {
        files: files,
      })
        .then(() => {
          success("Report has been uploaded successfully. Import will begin shortly");
        })
        .catch(result => {
          error(result.data["error"]);
          Bugsnag.notify(
            {
              name: "PhysicalDocTrackingImportError",
              message: "Error in PhysicalDocTrackingImportsController#create",
            },
            {
              severity: "error",
              metaData: {
                custom: {
                  filesUploaded: files,
                  resultResponse: result.response,
                  resultData: result.data,
                },
              },
            }
          );
        });
    },
    showError(errorMessage) {
      error(errorMessage);
    },
    handleSearch(period) {
      const searchParams = new URLSearchParams({ period });
      const url = `/manage/physical_doc_tracking_imports/search?${searchParams}`;

      this.reportImportsLoading = true;

      AjaxService.get(url)
        .then(data => {
          this.reportImports = data;
        })
        .finally(() => (this.reportImportsLoading = false));
    },
  },
};
</script>
