<template>
  <table class="table">
    <thead>
      <tr>
        <th>Select</th>
        <th>Loan ID</th>
        <th>Address</th>
        <th>Transfer Amount</th>
        <th>Source of Capital</th>
      </tr>
    </thead>
    <tbody>
      <loans-list-item
        v-for="loan in sortedLoans"
        :key="loan.psId"
        :loan="loan"
      />
    </tbody>
  </table>
</template>

<script>
import LoansListItem from "components/manage/funding_entities/loans_list_item";

export default {
  props: {
    loans: Array,
  },
  components: {
    LoansListItem,
  },
  methods: {},
  computed: {
    sortedLoans() {
      return this.loans.sort((a, b) => {
        return a.psId.localeCompare(b.psId);
      });
    },
  },
};
</script>
