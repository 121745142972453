import CopyToClipboard from "components/copy_to_clipboard";
import LenderTagsMixin from "mixins/manage/lenders/tags";
import PiecesOfCollateral from "components/tags/pieces_of_collateral";

import SignatureRequestsTable from "components/disclosures/signature_requests_table";

$(() => {
  if (!document.querySelector(".v-manage-signature-requests-controller")) {
    return;
  }

  new Vue({
    el: document.querySelector(".v-manage-signature-requests-controller"),
    components: {
      CopyToClipboard,
      SignatureRequestsTable,
      PiecesOfCollateral,
    },
    mixins: [LenderTagsMixin],
    data() {
      return {
        documents: PSData.documents,
        loan: PSData.loan,
        events: PSData.events,
      };
    },
  });
});
