import bugsnag from "@bugsnag/js";

if (window.__ps_test_mode) {
  window.Bugsnag = bugsnag({
    autoNotify: false,
    logger: null,
    apiKey: "TEST",
  });
} else {
  window.Bugsnag = bugsnag({
    apiKey: PSData.global.bugsnagApiKey,
    releaseStage: PSData.global.bugsnagReleaseStage,
    user: PSData.user,
    metaData: { app_name: PSData.global.appName },
  });
}
