<template>
  <base-dropdown ref="filterDropdown" class="filter-dropdown" position="right">
    <template v-slot:trigger>
      <base-button
        type="text"
        icon="caret-down"
      >
        Filter
      </base-button>
    </template>
    <base-dropdown-item
      aria-role="menu-item"
      :focusable="false"
      custom
      paddingless
    >
      <base-checkbox
        v-model="filterValue"
        :native-value="allTasksValue"
        :disabled="allOptionDisabled"
        class="filter-option"
        @change.native="onAllOptionChange"
      >
        All Tasks
      </base-checkbox>
      <base-checkbox
        v-for="(optionLabel, optionValue) in options"
        :key="optionValue"
        v-model="filterValue"
        :native-value="optionValue"
        :disabled="optionDisabled(optionValue)"
        name="filter"
        class="filter-option"
        @change.native="onOptionChange"
      >
        {{ optionLabel }}
      </base-checkbox>
    </base-dropdown-item>
  </base-dropdown>
</template>

<script>
import {
  TASKS_FILTER,
  filterToSave,
  parseFilter,
} from "services/user_settings/loan_tasks_filter_config";

export default {
  name: "filter-dropdown",
  props: {
    name: {
      type: String,
      default: "Filter",
    },
    value: {
      type: [String, Array],
      default: "",
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filterValue: [],
      allTasks: false,
    };
  },
  computed: {
    allTasksValue() {
      return "allTasks";
    },
    allTasksLabel() {
      return TASKS_FILTER.allTasks;
    },
    allOptionDisabled() {
      return this.filterValue.length > this.optionValues.length;
    },
    optionValues() {
      return Object.keys(this.options);
    },
    allValues() {
      return [this.allTasksValue, ...this.optionValues];
    },
  },
  watch: {
    value(newValue) {
      Vue.nextTick(() => {
        this.filterValue = this.parsedValue(newValue);
      });
    },
  },
  created() {
    this.filterValue = this.parsedValue(this.value);
  },
  methods: {
    parsedValue(value) {
      const values = parseFilter(value);

      if (values.includes(this.allTasksValue)) {
        return this.allValues;
      } else {
        return values;
      }
    },
    onAllOptionChange() {
      Vue.nextTick(() => {
        this.selectAll();
        this.saveFilter();
      });
    },
    selectAll() {
      this.filterValue = this.allValues;
    },
    onOptionChange() {
      Vue.nextTick(() => {
        let filterValue = this.filterValue;

        if (filterValue.includes(this.allTasksValue)) {
          filterValue = this.filterValue.filter(value => value !== this.allTasksValue);
        }

        if (filterValue.length === this.optionValues.length) {
          this.selectAll();
        } else {
          this.filterValue = filterValue;
        }

        this.saveFilter();
      });
    },
    saveFilter() {
      this.$emit("input", filterToSave(this.filterValue));
    },
    optionDisabled(optionValue) {
      return this.filterValue.length <= 1 && this.filterValue.includes(optionValue);
    },
  },
};
</script>
<style scoped>
.filter-option {
  margin: 0;
  padding: calc(var(--space-unit) * 0.5) calc(var(--space-unit) * 1.5);
}
</style>
