import MemoryStorage from "memorystorage";

class SessionStorageService {
  constructor() {
    try {
      const x = `peerstreet.test.${Date.now()}`;
      sessionStorage.setItem(x, x);

      const y = sessionStorage.getItem(x);
      sessionStorage.removeItem(x);

      if (x !== y) throw new Error();

      this.storage = sessionStorage;
    } catch (e) {
      this.storage = new MemoryStorage("peerstreet-memory-storage");
    }
  }

  setItem(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  getItem(key) {
    const item = this.storage.getItem(key);
    return JSON.parse(item);
  }

  removeItem(key) {
    const item = this.getItem(key);
    this.storage.removeItem(key);

    return item;
  }
}

PS.Services.SessionStorageService = new SessionStorageService();
