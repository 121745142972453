import moment from "moment";

const AMERICAN = "MM/DD/YYYY";
const ISO8601 = "YYYY-MM-DD";

export const fromInput = str => moment(str, AMERICAN);
export const fromAPI = str => moment(str, ISO8601);
export const toInput = date => moment(date).format(AMERICAN);
export const toAPI = date => moment(date).format(ISO8601);
export const previousWorkday = (date = moment()) => {
  let day = date.day();
  let diff = 1;
  if (day === 0 || day === 1) {
    diff = day + 2;
  }
  return date.subtract(diff, "days");
};
