import BaseResponse from "./base_response";

export default class ResidentialBridgeResponse extends BaseResponse {
  constructor(...args) {
    super(...args);

    this.interestRate = this.scenario.calculateInterestRate(this.response.purchaseRate);
    this.ltarv = this.scenario.calculateLtarv(this.loanAmount);
    this.monthlyPayment = this.scenario.calculateMonthlyPayment(this.loanAmount, this.interestRate);
    this.spreadRevenue = this.scenario.calculateSpreadRevenue(this.loanAmount, this.interestRate);
    this.purchaseRate = parseFloat(this.response.purchaseRate);
    this.cbFlag = !this.response.purchaseRate;
  }

  toHash() {
    return {
      bestRateLoanAmount: this.bestRateLoanAmount,
      cbFlag: this.cbFlag,
      custom: this.custom,
      interestRate: this.interestRate,
      loanAmount: this.loanAmount,
      ltarv: this.ltarv,
      ltv: this.ltv,
      maxLeverageLoanAmount: this.maxLeverageLoanAmount,
      monthlyPayment: this.monthlyPayment,
      purchaseRate: this.purchaseRate,
      spreadRevenue: this.spreadRevenue,
    };
  }
}
