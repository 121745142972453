<template>
  <validation-observer
    ref="observer"
    v-slot="{ invalid, dirty }"
    tag="form"
  >
    <loan-outreach-form
      :initial-outreach="initialOutreach"
      :borrower="borrower"
      :guarantor="guarantor"
      :lender="lender"
      :outreach-types="outreachTypes"
      :outreach-categories="outreachCategories"
      @update-form="(payload) => updateForm(payload)"
    />
    <div class="new-outreach-buttons">
      <base-button type="text" @click="clearForm">
        Cancel
      </base-button>
      <base-button
        :disabled="invalid || !dirty"
        type="primary"
        @click="submitNewOutreach"
      >
        <div v-if="isSaving">
          <i class="ps-loader ps-loader-small" />
        </div>
        <div v-else>
          Add Note
        </div>
      </base-button>
    </div>
  </validation-observer>
</template>
<script>
import AjaxService, { ERROR_STATUS, ERROR_MESSAGE } from "services/ajax_service";
import GlobalBusService from "services/global_bus_service";
import { ValidationObserver } from "vee-validate";
import moment from "moment";
import objectToSnakeCase from "utils/object_to_snake_case";
import LoanOutreachForm from "./outreach_form";

const defaultCategories = {
  dialing_for_dollars: ["Dialing for Dollars"],
  litigation: ["Litigation"],
  liquidation: [
    "Foreclosure",
    "Repurchase - Originator",
    "Note Sale to 3rd Party",
    "Paid in Full",
    "Sale of Property - Borrower",
    "Discounted Payoff",
    "Foreclosure - Sold to 3rd Party Bidder",
  ],
  other: [
    "Bankruptcy - Chapter 11",
    "Refinance - Borrower/Originator",
    "Title Issue",
    "Bankruptcy - Chapter 7",
    "Reinstatement",
    "Partial Release",
    "Bankruptcy - Chapter 13",
    "Deed in Lieu",
    "Monitor Loan",
    "Refinance - PeerStreet",
    "Short Pay",
  ],
  workout: [
    "Extension - Regular",
    "Extension - Flash",
    "Modification",
    "Extension - Loan Agreement Terms",
    "COVID Modification",
    "Forbearance",
  ],
  reo: ["REO - Sold", "REO - Owned"],
};

export default {
  components: {
    LoanOutreachForm,
    ValidationObserver,
  },
  data() {
    const outreachTypes = PSData.outreachTypes || [];
    const outreachCategories = PSData.outreachCategories || [];
    const foreclosureStatusTypes = PSData.loanForeclosureStatusTypes || [];
    const loanForeclosure = PSData.loanForeclosure || {};

    return {
      routes: PSData.routes,
      outreachTypes,
      outreachCategories,
      borrower: this.borrowerContactDetails(),
      lender: this.lenderContactDetails(),
      guarantor: this.guarantorContactDetails(),
      initialOutreach: {
        category: this.defaultOutreachCategory(foreclosureStatusTypes, loanForeclosure.statusId),
        outreachDate: moment().format("YYYY-MM-DD"),
      },
      outreachForm: {},
      isSaving: false,
    };
  },
  methods: {
    borrowerContactDetails() {
      const loan = PSData.loan || {};
      return {
        name: loan.borrowerName || "",
        email: loan.borrowerEmail || "",
        phone: loan.borrowerPhone || "",
      };
    },
    lenderContactDetails() {
      const lender = PSData.lender || {};
      return {
        name: lender.name || "",
        email: lender.email || "",
        phone: lender.phone || "",
      };
    },
    guarantorContactDetails() {
      const loan = PSData.loan || {};
      return {
        name: loan.guarantorName || "",
        email: loan.borrowerEmail || "",
        phone: loan.borrowerPhone || "",
      };
    },
    async updateForm(payload) {
      this.outreachForm = { ...this.outreachForm, ...payload };
    },
    async clearForm() {
      this.initialOutreach = {
        outreachDate: moment().format("YYYY-MM-DD"),
      };
      this.outreachForm = {};
      setTimeout(() => {
        this.$refs.observer.reset();
      });
    },
    createOutreach() {
      const url = this.routes.assetManagementLoanOutreaches;
      const params = {
        outreaches: [objectToSnakeCase(this.outreachForm)],
      };
      return AjaxService.withNotifications({
        onSuccess: "Outreach was created successfully",
        onError: "Failed to create outreach",
      }).postJSON(url, params);
    },
    async submitNewOutreach() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }
      try {
        this.isSaving = true;
        await this.createOutreach();
        GlobalBusService.$emit("outreach-created");
        await this.clearForm();
      } catch ({ data }) {
        if (data && data.errors) {
          AjaxService.sendNotification(data.errors.join("\n"), ERROR_STATUS);
        } else {
          AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
        }
      } finally {
        this.isSaving = false;
      }
    },
    getLoanStatusName(foreclosureStatusTypes, loanStatusId) {
      const loanStatus = foreclosureStatusTypes.find(type => type.id === loanStatusId);
      return loanStatus ? loanStatus.text : "";
    },
    defaultOutreachCategory(foreclosureStatusTypes, loanStatusId) {
      const loanStatusName = this.getLoanStatusName(foreclosureStatusTypes, loanStatusId);
      const category =
        Object.entries(defaultCategories).find(([value, options]) =>
          options.includes(loanStatusName)
        ) || [];
      return category[0];
    },
  },
};
</script>
<style scoped>
.new-outreach-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
