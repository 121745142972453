export default class Milestone {
  constructor(milestone = {}) {
    this.update(milestone);
  }

  get id() {
    return this._raw.id;
  }

  get name() {
    return `milestone_${this._raw.id}`;
  }

  get label() {
    return this._raw.name;
  }

  get visibility() {
    return "lender";
  }

  update(record) {
    this._raw = record;
  }
}
