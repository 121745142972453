<template>
  <editable-section
    header-title="Loan Overview"
    subheading=""
    :editing="editing"
    :actions-hidden="!canEdit"
    @edit="handleEdit"
    @cancel="handleCancel"
    @update="handleUpdate"
  >
    <div class="detail-section mt-0">
      <div v-if="editing" class="detail-section-left">
        <ul class="loan-block">
          <li>
            <editable-select
              label="Loan Action"
              no-status-label="No Action"
              :value="form.statusId"
              :options="foreclosureStatusTypes"
              :editing="editing"
              @input="e => updateForm('statusId', e.target.value)"
            />
          </li>
          <li>
            <span class="d-flex">
              <editable-select
                label="Asset Manager"
                no-status-label=""
                :value="form.assetManagerId"
                :options="assetManagerOptions"
                :editing="editing"
                @input="e => updateForm('assetManagerId', e.target.value)"
              />
              <a
                v-show="editing"
                class="assign-current-asset-manager-button"
                href="#"
                @click="assignCurrentUser"
              >
                Assign to Me
              </a>
            </span>
          </li>
          <li>
            <editable-input
              label="Attorney Name"
              :value="form.attorneyName"
              :editing="editing"
              @input="e => updateForm('attorneyName', e.target.value)"
            />
          </li>
          <li>
            <editable-select
              label="Judicial Action"
              no-status-label=""
              :value="mapToYesNoId(form.judicialAction)"
              :options="yesNoOptions"
              :editing="editing"
              @input="event => updateYesNo(event, 'judicialAction')"
            />
          </li>
          <li>
            <editable-select
              label="Early Payment Default"
              no-status-label=""
              :value="mapToYesNoId(form.earlyPaymentDefault)"
              :options="yesNoOptions"
              :editing="editing"
              @input="event => updateYesNo(event, 'earlyPaymentDefault')"
            />
          </li>
          <li>
            <editable-datepicker
              label="Early Payment Default Date"
              :value="form.earlyPaymentDefaultDate"
              :editing="editing"
              @input="value => updateForm('earlyPaymentDefaultDate', value)"
            />
          </li>
        </ul>
      </div>
    </div>

    <loan-details
      v-if="!editing"
      :details="details"
      :loan-action="loanStatusName"
      :judicial-action="judicialAction"
      :early-payment-default="earlyPaymentDefault"
      :early-payment-default-date="earlyPaymentDefaultDate"
      :attorney-name="attorneyName"
      :asset-manager-name="assetManagerName"
    />
  </editable-section>
</template>

<script>
import EditableSection from "./editable_section";
import EditableSelect from "./editable_select";
import EditableInput from "./editable_input";
import EditableDatepicker from "./editable_date";
import BaseForeclosureSection from "./base_foreclosure_section.js";
import LoanDetails from "./loan_details";

export default {
  name: "loan-overview",
  components: {
    EditableSection,
    EditableSelect,
    EditableInput,
    EditableDatepicker,
    LoanDetails,
  },
  extends: BaseForeclosureSection,
  props: {
    form: {
      type: Object,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      assetManagers: PSData.assetManagers || [],
      foreclosureStatusTypes: PSData.loanForeclosureStatusTypes || [],
    };
  },
  computed: {
    assetManagerOptions() {
      return this.assetManagers.map(u => ({ id: u.id, text: u.name }));
    },
    assetManager() {
      return this.assetManagers.find(manager => manager.id === this.form.assetManagerId);
    },
    assetManagerName() {
      return this.assetManager ? this.assetManager.name : "";
    },
    attorneyName() {
      return this.form.attorneyName;
    },
    yesNoOptions: () => {
      return [{ id: "Yes", text: "Yes", value: true }, { id: "No", text: "No", value: false }];
    },
    currentUserName: () => PSData.user.name,
    judicialAction() {
      return this.mapToYesNoId(this.form.judicialAction);
    },
    loanStatus() {
      return this.foreclosureStatusTypes.find(type => type.id === this.form.statusId);
    },
    loanStatusName() {
      return this.loanStatus ? this.loanStatus.text : "";
    },
    earlyPaymentDefault() {
      return this.mapToYesNoId(this.form.earlyPaymentDefault);
    },
    earlyPaymentDefaultDate() {
      return this.form.earlyPaymentDefaultDate;
    },
  },
  methods: {
    assignCurrentUser() {
      const assetManagerId = PSData.assetManagers.find(
        u => u.name.toLowerCase() === this.currentUserName.toLowerCase()
      );
      this.updateForm("assetManagerId", assetManagerId.id);
    },
    mapToYesNoId(value) {
      const action = this.yesNoOptions.find(a => a.value === value);
      return action ? action.id : null;
    },
    updateYesNo(event, property) {
      const action = this.yesNoOptions.find(a => a.text === event.target.value);
      const value = action ? action.value : null;
      this.updateForm(property, value);
    },
  },
};
</script>
