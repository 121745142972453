<template>
  <div>
    <template v-if="isCompact">
      <base-tooltip
        class="loan-status-tracker-tooltip"
        position="is-top"
        multilined
      >
        <template v-slot:content>
          <span class="loan-status-dot completed" /> {{ completedTasks }}% Completed <br>
          <span class="loan-status-dot pending" /> {{ pendingTasks }}% Pending <br>
        </template>
        <loan-status-tracker-bars
          :completed-color="completedColor"
          :incomplete-tasks="incompleteTasks"
          :completed-tasks="completedTasks"
          :pending-tasks="pendingTasks"
          :show-bar-tooltip="false"
        />
      </base-tooltip>
    </template>
    <template v-else>
      <div class="full-size">
        <loan-status-tracker-bars
          :completed-color="completedColor"
          :incomplete-tasks="incompleteTasks"
          :completed-tasks="completedTasks"
          :pending-tasks="pendingTasks"
        />
        <small> {{ chartLegend }}</small>
      </div>
    </template>
  </div>
</template>

<script>
import countBy from "lodash/countBy";
import NumberFormatter from "models/formatters/number_formatter";
import LoanStatusTrackerBars from "./loan_status_tracker_bars";

export default {
  name: "LoanStatusTracker",
  components: { LoanStatusTrackerBars },
  props: {
    completedColor: {
      type: String,
      default: "completed",
    },
    tasks: {
      type: Array,
      default: () => [],
    },
    listOpenTasks: {
      type: Number,
      default: 0,
    },
    listPendingTasks: {
      type: Number,
      default: 0,
    },
    listCompletedTasks: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const tasksCount = this.listOpenTasks + this.listPendingTasks + this.listCompletedTasks;

    return {
      tasksCount: this.tasks.length > 0 ? this.tasks.length : tasksCount,
      isCompact: this.tasks.length == 0,
    };
  },
  computed: {
    tasksByCategory() {
      if (this.tasks.length > 0) {
        const defaults = { incomplete: 0, completed: 0, pending: 0 };

        return Object.assign(defaults, countBy(this.tasks, this.taskCategory));
      } else {
        return {
          incomplete: this.listOpenTasks,
          completed: this.listCompletedTasks,
          pending: this.listPendingTasks,
        };
      }
    },
    completedTasks() {
      return Math.round((this.tasksByCategory.completed / this.tasksCount) * 100);
    },
    pendingTasks() {
      return Math.round((this.tasksByCategory.pending / this.tasksCount) * 100);
    },
    incompleteTasks() {
      return Math.round((this.tasksByCategory.incomplete / this.tasksCount) * 100);
    },
    completedTasksPercent() {
      return NumberFormatter.formatPercent(this.completedTasks, 0, true);
    },
    pendingTasksPercent() {
      return NumberFormatter.formatPercent(this.pendingTasks, 0, true);
    },
    chartLegend() {
      return this.pendingTasks > 0
        ? `${this.completedTasksPercent} Completed, ${this.pendingTasksPercent} Pending`
        : `${this.completedTasksPercent} Completed`;
    },
  },
  methods: {
    taskCategory(task) {
      if (task.isConfirmed) {
        return "completed";
      } else if (task.isPending) {
        return "pending";
      }
      return "incomplete";
    },
  },
};
</script>
<style scoped>
.loan-status-tracker {
  background: var(--color-white) !important;
}

.loan-status-tracker-tooltip {
  margin-top: 6px;
  width: 100%;
}

.base-tooltip.loan-status-tracker-tooltip >>> .tooltip-content {
  text-align: left;
  width: 150px;
}

small {
  color: var(--color-grey-60);
  font-size: 0.95em;
}

.full-size .progress-wrapper {
  margin-bottom: 2px !important;
  margin-top: 2ch;
}

.incomplete {
  background-color: var(--color-grey-16) !important;
}

.pending {
  background-color: #16a2b7 !important;
}

.completed {
  background-color: #297acc !important;
}
.loan-status-dot {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}
</style>
