import { SectionType } from "models/lender_form/fields";
import Section from "models/lender_form/section";

const CAPITAL_FIELD_NAMES = [
  "constructionReserve",
  "constructionReserveDisbursedAtValuation",
  "interestReserve",
  "otherReserves",
  "bPieceAmount",
  "juniorLienTotal",
  "juniorLienHolders",
];

export default class CapitalSection extends Section {
  constructor(fields) {
    super(SectionType.Capital, fields);

    this.checkProgramType();
  }

  update() {
    this.checkJuniorLienTotal();
    this.checkConstructionReserve();

    super.update();
  }

  checkJuniorLienTotal() {
    if (this.fields.juniorLienHolders) {
      this.fields.juniorLienHolders.disabled = !(this.fields.juniorLienTotal.convertedValue > 0);
    }
  }

  checkConstructionReserve() {
    const {
      constructionReserve,
      constructionReserveDisbursedAtValuation,
      interestAccrualMethod,
    } = this.fields;
    const hasConstructionReserve = constructionReserve.convertedValue > 0;
    constructionReserve.value = this.isConstructionReserveDisabled ? 0 : constructionReserve.value;
    interestAccrualMethod.disabled =
      !this.isMultifamilyBridge || (this.isMultifamilyBridge && !hasConstructionReserve);
    interestAccrualMethod.required = !interestAccrualMethod.disabled;

    if (constructionReserveDisbursedAtValuation) {
      if (this.isFunded && hasConstructionReserve) {
        this.fields.constructionReserveDisbursedAtValuation.disabled = false;
      } else {
        this.fields.constructionReserveDisbursedAtValuation.disabled = true;
        this.fields.constructionReserveDisbursedAtValuation.value = 0;
      }
    }
  }

  checkProgramType() {
    if (this.isResiRental) {
      this.disableSection();
    }
  }

  disableSection() {
    CAPITAL_FIELD_NAMES.forEach(fieldName => {
      if (this.fields[fieldName]) {
        this.fields[fieldName].required = false;

        if (fieldName !== "juniorLienHolders") this.fields[fieldName].value = 0;
      }
    });
  }

  get constructionLoanStrategies() {
    return PSData.constructionLoanStrategies || [];
  }

  get isConstructionReserveDisabled() {
    return !this.constructionLoanStrategies.includes(this.fields.loanStrategyId.convertedValue);
  }

  get isFunded() {
    return this.fields.lenderNoteStatus.value === "funded";
  }

  get isResiRental() {
    return this.fields.programType.value === "residential_rental_long_term";
  }

  get isMultifamilyBridge() {
    return this.fields.programType.value === "commercial_multifamily";
  }
}
