<template>
  <div class="payoff-statement">
    <breadcrumb-navigation :items="breadcrumbItems"></breadcrumb-navigation>
    <div class="payoff-statement-header no-border">
      <div class="payoff-statement-title-container">
        <h3>Payoff Statement</h3>
        <span :class="statusClass">{{ statement.statusLabel }}</span>
        <span class="ps-loader ps-loader-small" v-if="isLoading"></span>
        <div class="payoff-statement-sub">
          <span>Version {{ statement.versionNumber }}</span>
          <span>• Updated {{ statement.lastUpdated }}</span>
        </div>
      </div>
      <resale-statement-action-button
        :statement-policy="statementPolicy"
        @transition="updateStatus"
        @transitionWithCheck="updateStatusWithGuardrail"
        @refresh="refresh"
        @cancel="cancel"
        @mark-as-wired="showCompletedPopup"
      />
    </div>
    <errors :errors="errors" :type="errorType" />
    <resale-statement-show-details />
    <payoff-statement-completed-popup
      :show="isCompletedPopupShown"
      :on-close="hideCompletedPopup"
      :statement="statement"
    />
  </div>
</template>

<script>
import PayoffStatementPolicy from "policies/payoff_statement_policy";
import get from "lodash/get";
import BreadcrumbNavigation from "components/shared/breadcrumb_navigation";
import ResaleStatementShowDetails from "components/manage/payoff_statement/resale/show/details";
import ResaleStatementActionButton from "components/manage/payoff_statement/resale/action_button";
import PayoffStatementCompletedPopup from "components/manage/payoff_statement/completed_popup";
import selectHashOptionsToMapping from "utils/select_hash_options_to_mapping";
import Errors from "components/manage/payoff_statement/errors";
import Ajax from "services/ajax_service";
import { STATUSES } from "components/manage/payoff_statement/action_button_mixin";
import { success, error } from "services/notification";
import Location from "services/location_service";
import useResaleStatementStore from "controllers/manage/store/resaleStatement";
import { mapActions, mapState } from "pinia";

const STATES = {
  show: "show",
  duplicate: "duplicate",
  new: "new",
  edit: "edit",
};

const JSON_HEADER = { "Content-Type": "application/json" };

const NOTIFICATIONS = {
  refresh: "Payoff Statement was successfully refreshed",
  cancelled: "Payoff Statement cancelled",
  sold: "Payoff statement marked as sold",
  wired_short: "Payoff Statement marked as wired-short",
  pending: "Payoff Statement marked as reviewed",
  approved: "Payoff Statement approved",
  reviewed: "Payoff Statement marked as reviewed",
  transition: {
    onSuccess: "Payoff Statement successfully updated",
    onError: {
      approved: "Unable to approve Payoff Statement",
      reviewed: "Unable to mark as reviewed Payoff Statement",
    },
  },
};

export default {
  name: "resale-statement-show",
  components: {
    BreadcrumbNavigation,
    ResaleStatementShowDetails,
    ResaleStatementActionButton,
    PayoffStatementCompletedPopup,
    Errors,
  },
  props: {
    state: {
      type: String,
      default: () => STATES.new,
    },
  },
  data() {
    return {
      isLoading: false,
      created: false,
      currentState: this.state,
      isCompletedPopupShown: false,
      statementPolicy: new PayoffStatementPolicy(PSData.statementPolicy),
      payoffReasonTypes: selectHashOptionsToMapping(PSData.payoffReasonTypes, "name", "label"),
      statementTypes: selectHashOptionsToMapping(PSData.statementTypes, "name", "label"),
      feeTypes: selectHashOptionsToMapping(PSData.feeTypes, "name", "label"),
      errors: [],
      errorType: null,
    };
  },
  computed: {
    ...mapState(useResaleStatementStore, ["statement"]),
    breadcrumbItems() {
      return [
        {
          name: "Servicing",
          link: this.routes.servicing,
        },
        {
          name: "Payoff Statement",
        },
      ];
    },
    routes() {
      return {
        servicing: get(PSData, "routes.servicing"),
        show: this.statement.routes.show,
        saveDraft: this.statement.routes.saveDraft,
        submit: this.statement.routes.save,
        calculateInterest: this.statement.routes.calculateInterest,
        update: this.statement.routes.update,
        transition: this.statement.routes.transition,
        refresh: this.statement.routes.refresh,
        fetchFees: this.statement.routes.fetchFees,
      };
    },
    statusClass() {
      return ["payoff-statement-status", `payoff-statement-status-${this.statement.status}`];
    },
  },
  methods: {
    saveDraft() {
      this.makeRequest(this.routes["saveDraft"]);
    },
    submitForReview() {
      this.makeRequest(
        this.routes["submit"],
        "post",
        this.statement.toHash(),
        this.updateStatementAndPolicy
      );
    },
    updateStatementAndPolicy(result) {
      this.updateCreatedStatement(result);
      if (!this.errors && result.statementPolicy) {
        this.statementPolicy = new PayoffStatementPolicy(result.statementPolicy);
      }
    },
    notification(status) {
      return get(NOTIFICATIONS, status);
    },
    async makeRequest(
      url,
      method = "post",
      resource = this.statement.toHash(),
      postCallback = this.updateCreatedStatement
    ) {
      try {
        this.isLoading = true;

        var result = await Ajax[method](url, { resource }, JSON_HEADER);

        if (result.errors !== undefined) {
          this.errors = result.errors;
        }
        this.errorType = result.errorType;
        postCallback(result);

        return result;
      } catch (err) {
        this.errors = [err.message];
        return {};
      } finally {
        this.isLoading = false;
      }
    },
    updateCreatedStatement(result) {
      if (!this.errors) {
        success(this.notification(`submit.${this.currentState}`));
        this.created = true;
        this.currentState = STATES.show;
        this.setPayoffStatement(result);
      } else {
        error(this.notification("submit.onError"));
      }
    },
    setStatement(result) {
      if (result.statement) {
        this.setPayoffStatement(result);
      }
    },
    showCompletedPopup() {
      this.isCompletedPopupShown = true;
    },
    hideCompletedPopup(notify) {
      this.isCompletedPopupShown = false;

      if (notify && this.statement.status !== STATUSES.approved) {
        success(this.notification(this.statement.status));
      }
    },
    ...mapActions(useResaleStatementStore, {
      setStatementStatus: "setStatus",
      setPayoffStatement: "setStatement",
    }),
    setStatus(result) {
      this.errors = result.errors;
      if (!this.errors) {
        this.setStatementStatus(result.statement);
      }
    },
    persist() {
      this.makeRequest(this.routes.update, "patch");
    },
    cancel() {
      this.updateStatus("cancelled").then(() => (Location.href = this.routes.servicing));
    },
    async refresh() {
      const result = await this.makeRequest(
        this.routes.refresh,
        "post",
        this.statement.toHash(),
        this.setStatement
      );

      if (!result.errors) {
        success(this.notification("refresh"));
      }
    },
    async updateStatus(status) {
      const result = await this.makeRequest(
        this.routes.transition,
        "post",
        { status },
        this.setStatus
      );

      if (!result.errors && this.notification(status)) {
        success(this.notification(status));
      }
    },
    async updateStatusWithGuardrail(status) {
      const params = {
        status: status,
        guardrail: true,
      };

      const result = await this.makeRequest(this.routes.transition, "post", params, this.setStatus);

      if (!result.errors) {
        success(this.notification(status) || this.notification("transition.onSuccess"));
      } else {
        error(this.notification(`transition.onError.${status}`));
      }
    },
  },
};
</script>
