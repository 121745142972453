<template>
  <div>
    <div class="detail-section mt-0">
      <div class="detail-section-left">
        <attribute-value :label="'Loan Action'">{{ loanAction | missingValue }}</attribute-value>
        <attribute-value :label="'Judicial Action'">{{ judicialAction | missingValue }}</attribute-value>
        <attribute-value :label="'Early Payment Default'">{{ earlyPaymentDefault | missingValue }}</attribute-value>
        <attribute-value :label="'Early Payment Default Date'">{{ earlyPaymentDefaultDate | missingValue }}</attribute-value>
      </div>
      <div class="detail-section-right">
        <attribute-value :label="'Asset Manager'">{{ assetManagerName | missingValue }}</attribute-value>
        <attribute-value :label="'Relationship Manager'">{{ details.accountManagerName | missingValue }}</attribute-value>
        <attribute-value :label="'Attorney Name/Law Firm'">{{ attorneyName | missingValue }}</attribute-value>
      </div>
    </div>

    <div class="detail-section">
      <div class="detail-section-left">
        <h4 class="detail-subheader">Loan Info</h4>
        <attribute-value :label="'Unpaid Principal Balance'">{{ details.unpaidPrincipalBalance | money | missingValue }}</attribute-value>
        <attribute-value :label="'PeerStreet Warehouse Amount'">{{ details.warehouseUnpaidPrincipalBalance | money | missingValue }}</attribute-value>
        <attribute-value :label="'Interest Reserve Remaining'">{{ details.interestReserveRemaining | money | missingValue }}</attribute-value>
        <attribute-value :label="'Construction Reserve Balance'">{{ details.constructionReserveRemaining | money | missingValue }}</attribute-value>
        <attribute-value :label="'B-Piece Amount'">{{ details.bPieceAmount | money | missingValue }}</attribute-value>
        <attribute-value :label="'B-Piece Holder'">{{ details.bPieceHolderName | missingValue }}</attribute-value>
        <attribute-value :label="'Legal Owner'">{{ details.currentOwnerText | missingValue }}</attribute-value>
        <attribute-value :label="'Interest Rate'">{{ details.noteRate | percent | missingValue }}</attribute-value>
        <attribute-value :label="'Loan Purpose'">{{ details.loanPurpose | missingValue }}</attribute-value>
      </div>

      <div class="detail-section-right">
        <h4 class="detail-subheader">Servicing Info</h4>
        <attribute-value :label="'First Payment Date'">{{ details.firstPaymentDate | formatDate | missingValue }}</attribute-value>
        <attribute-value :label="'Last Payment Date'">{{ details.lastPaymentReceivedDate | formatDate | missingValue }}</attribute-value>
        <attribute-value :label="'Next Payment Date'">{{ details.nextPaymentDueDate | formatDate | missingValue }}</attribute-value>
        <attribute-value :label="'Paid to Date'">{{ details.paidToDate | formatDate | missingValue }}</attribute-value>
        <attribute-value :label="'Servicer Status'">{{ details.statusCode | missingValue }}</attribute-value>
        <attribute-value :label="'Days Late'">{{ details.daysLate | missingValue }}</attribute-value>
        <attribute-value :label="'Grace Period'">{{ details.gracePeriodDays | missingValue }}</attribute-value>
        <attribute-value :label="'Maturity Date'">{{ details.currentMaturityDate | formatDate | missingValue}}</attribute-value>
        <attribute-value :label="'Days to Maturity'">{{ details.daysToMaturity | missingValue }}</attribute-value>
      </div>
    </div>

    <div class="detail-section">
      <div class="detail-section-left">
        <h4 class="detail-subheader">Property Info</h4>
        <attribute-value :label="'APN'">{{ details.propertyApn | missingValue }}</attribute-value>
        <attribute-value :label="'County Name'">{{ details.recordationCounty | missingValue }}</attribute-value>
        <attribute-value :label="'Square Footage'">{{ details.propertySquareFeet | missingValue }}</attribute-value>
        <attribute-value :label="'Year Built'">{{ details.propertyYearBuilt | missingValue }}</attribute-value>
      </div>
    </div>

    <div class="detail-section">
      <div class="detail-section-left">
        <h4 class="detail-subheader">Borrower/Guarantor Contact Info</h4>
        <attribute-value :label="'Borrower Name'">{{ details.borrowerName | missingValue }}</attribute-value>
        <attribute-value :label="'Mailing Address'">{{ details.borrowerAddress | missingValue }}</attribute-value>
        <attribute-value :label="'City'">{{ details.borrowerCity | missingValue }}</attribute-value>
        <attribute-value :label="'State'">{{ details.borrowerState | missingValue }}</attribute-value>
        <attribute-value :label="'ZIP'">{{ details.borrowerZip | missingValue }}</attribute-value>
        <attribute-value :label="'Email'">{{ details.borrowerEmail | missingValue }}</attribute-value>
        <attribute-value :label="'Phone'">{{ details.borrowerHomePhone | missingValue }}</attribute-value>
        <attribute-value :label="'Guarantor Name'">{{ details.guarantorName | missingValue }}</attribute-value>
      </div>

      <div class="detail-section-right">
        <h4 class="detail-subheader">Lender/Originator Contact Info</h4>
        <attribute-value :label="'City'">{{ details.lenderCity | missingValue }}</attribute-value>
        <attribute-value :label="'State'">{{ details.lenderState | missingValue }}</attribute-value>
        <attribute-value :label="'Email'">{{ details.lenderEmail | missingValue }}</attribute-value>
        <attribute-value :label="'Phone'">{{ details.lenderPhone | missingValue }}</attribute-value>
      </div>
    </div>
  </div>
</template>

<script>
import NumberFormatter from "models/formatters/number_formatter";
import EditableInput from "./editable_input";
import EditableCurrency from "./editable_currency";
import AttributeValue from "./components/attribute_value";

export default {
  name: "loan-details",
  components: {
    EditableInput,
    EditableCurrency,
    AttributeValue,
  },
  data() {
    return {
      assetManagers: PSData.assetManagers || [],
      foreclosureStatusTypes: PSData.loanForeclosureStatusTypes || [],
    }
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
    loanAction: String,
    judicialAction: String,
    earlyPaymentDefault: String,
    earlyPaymentDefaultDate: String,
    attorneyName: String,
    assetManagerName: String,
  }
}
</script>

