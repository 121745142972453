<template>
  <div class="servicing-generate-section-header">
    <div class="payoff-body">
      <base-header size="medium">Payoff</base-header>
      <action-button :actions="actions" :disabled="isPaidOff" @open-modal="togglePayoffModal" />
    </div>
    <div class="messages">
      <div v-if="isPaidOff">
        <base-message
          :title="`This loan is paid off as of ${paidOffDate}`"
          type="success"
          :closable="false"
        >
          <p>Loan has an unpaid principal balance of $0 and has been marked as "Paid Off"</p>
        </base-message>
      </div>

      <div v-else>
        <base-message
          v-if="hasErrors"
          title="This loan cannot be marked as paid off due to:"
          type="warning"
          :closable="false"
        >
          <ul>
            <li v-for="(message, index) in errors" :key="index">{{ message }}</li>
          </ul>
        </base-message>

        <base-message
          v-if="hasSubordinateErrors && hasSubordinatePiece"
          title="This loan is not eligible for a subordinate principal payoff transaction due to:"
          type="warning"
          :closable="false"
          class="subordinate-warning-banner"
        >
          <ul>
            <li v-for="(message, index) in subordinateErrors" :key="index">{{ message }}</li>
          </ul>
        </base-message>
      </div>
    </div>
    <payoff-modal :show="showPayoffDateModal" @close="togglePayoffModal" />
    <subordinate-payment-modal
      :show="showSubordinatePayoffModal"
      @close="toggleSubordinatePayoffModal"
    />
  </div>
</template>

<script>
import ActionButton from "./action_button";
import PayoffModal from "./modal";
import SubordinatePaymentModal from "./subordinate_payment_modal";
import DateFormatter from "models/formatters/date_formatter";

export default {
  components: { ActionButton, PayoffModal, SubordinatePaymentModal },
  data() {
    return {
      showPayoffDateModal: false,
      showSubordinatePayoffModal: false,
      isPaidOff: PSData.loan.noteStatus === "paid_off",
      paidOffDate: DateFormatter.formatDate(PSData.loan.paidOffDate),
      errors: PSData.payoffErrors,
      subordinateErrors: PSData.subordinatePaymentErrors,
    };
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0;
    },
    hasSubordinateErrors() {
      return this.subordinateErrors.length > 0;
    },
    disableMarkAsPaidOff() {
      return this.hasErrors || this.isPaidOff;
    },
    hasSubordinatePiece() {
      const series = PSData.series;

      return series.some(item => {
        return item.tranche !== "a" && item.fundingEntityName === "inv_lender";
      });
    },
    actions() {
      const actions = [
        {
          label: "Subordinate Principal Payment Transaction",
          disabled: this.hasSubordinateErrors,
          onClick: () => this.toggleSubordinatePayoffModal(),
          show: this.hasSubordinatePiece,
        },
        {
          label: "Mark as Paid Off",
          disabled: this.disableMarkAsPaidOff,
          onClick: () => this.togglePayoffModal(),
          show: true,
        },
      ];

      return actions.filter(action => {
        return action.show;
      });
    },
  },
  methods: {
    togglePayoffModal() {
      this.showPayoffDateModal = !this.showPayoffDateModal;
    },
    toggleSubordinatePayoffModal() {
      this.showSubordinatePayoffModal = !this.showSubordinatePayoffModal;
    },
  },
};
</script>

<style scoped>
.servicing-generate-section-header {
  flex-direction: column;
}
.payoff-body {
  display: flex;
  justify-content: space-between;
}

.messages {
  padding-top: var(--space-unit-lg);
}
</style>
