<template>
  <div>
    <breadcrumb-navigation :items="breadcrumbItems" />

    <h3 class="loan-details-view-header is-clearfix pt-4">
      {{ title }}

      <base-button type="primary" class="is-pulled-right" :disabled="saveDisabled" @click="onSave">Save</base-button>
      <base-button type="text" class="is-pulled-right mr-2" @click="onCancel">Cancel</base-button>
    </h3>

    <form>
      <view-panel class="loan-details-view-panel borrower-details-view-panel">
        <template slot="header">
          Details
        </template>
        <div class="row loan-details-view-panel-row">
          <div v-if="canUpdateBorrowerData" class="col-6 loan-details-view-panel-column">
            <form-input v-model="fields.name" label="Name" field="name" :errors="errors" />
            <form-select v-model="fields.borrowerType" label="Type" field="borrowerType" :options="fields.borrowerTypes" :errors="errors" />
            <form-select
              v-if="showSinglePurposeEntity"
              v-model="fields.singlePurposeEntityYN"
              label="Is borrower a single purpose entity?"
              field="singlePurposeEntityYN"
              :options="fields.yesNoOptions"
              :errors="errors"
              blank-option
            />
            <form-input v-model="fields.email" label="Email" field="email" :errors="errors" />
            <form-input v-model="fields.phone" label="Phone" field="phone" mask="phone" :errors="errors" />
            <form-select
              v-if="showMaritalStatus"
              v-model="fields.maritalStatus"
              label="Marital Status"
              field="maritalStatus"
              :options="fields.maritalStatuses"
              :errors="errors"
              blank-option
            />
            <div v-if="showSoleProperty" class="form-group form-group-inline">
              <label>Subject property is his/her sole and separate property</label>
              <div class="radio-control-blue radio-control-inline borrower-details-radio-control">
                <div class="radio borrower-details-radio">
                  <input id="yesSoleProperty" v-model="fields.soleProperty" value="true" type="radio" />
                  <label for="yesSoleProperty">Yes</label>
                </div>
                <div class="radio borrower-details-radio">
                  <input id="noSoleProperty" v-model="fields.soleProperty" value="false" type="radio" />
                  <label for="noSoleProperty">No</label>
                </div>
              </div>
            </div>
            <form-select
              v-if="showEntityState"
              v-model="fields.entityState"
              label="Formation State"
              field="entityState"
              :options="fields.states"
              :errors="errors"
              blank-option
            />
            <form-input v-if="showEntityState" v-model="fields.secretaryOfStateId" label="Secretary of State ID" field="secretaryOfStateId" :errors="errors" />
            <div class="borrower-details-input-control vesting-clause">
              <label>Vesting Clause</label>
              <vesting-clause-tooltip />
              <a class="refresh" @click="refreshVestingClause" >
                <i class="fas fa-undo" />
                <span>Refresh</span>
              </a>
              <input class="form-control" v-model="fields.vestingClause" />
            </div>
            <div class="borrower-details-input-control">
              <label>Legal Name</label>
              <input class="form-control" v-model="fields.legalName" disabled />
            </div>
          </div>
          <div class="col-6 loan-details-view-panel-column">
            <form-input v-model="fields.tin" :label="fields.tinFieldLabel" field="tin" :mask="fields.tinFieldMask" :key="fields.tinFieldMask" :errors="errors" />
            <form-select v-if="canUpdateBorrowerData" v-model="fields.residency" label="Residency Status" field="residency"  :options="fields.residencies" :errors="errors" />
            <form-select v-if="showGender" v-model="fields.gender" label="Gender" field="gender" :options="fields.genders" :errors="errors" />

            <form-date-input v-if="showDateOfBirth" v-model="fields.dateOfBirth" label="Date of Birth" field="dateOfBirth" :errors="errors" />

            <template v-if="canUpdateBorrowerData">
              <form-input v-model="fields.address.address" label="Mailing Address" field="address.address" :errors="errors" />
              <form-input v-model="fields.address.city" label="City" field="address.city" :errors="errors" />
              <form-select v-model="fields.address.state" label="State" field="address.state"  :options="fields.states" :errors="errors" />
              <form-input v-model="fields.address.postalCode" label="ZIP Code" field="address.postalCode" :errors="errors" mask="zip" />
            </template>
          </div>
        </div>
      </view-panel>
      <view-panel v-if="canUpdateBorrowerData && showBorrowerFinancials" class="loan-details-view-panel">
        <template slot="header">
          Borrower Financials
        </template>
        <div class="row loan-details-view-panel-row">
          <div class="col-6 loan-details-view-panel-column">
            <form-input v-model="fields.netWorth" label="Net Worth" field="netWorth" mask="currency" :errors="errors">
              <template slot="prepend">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
              </template>
            </form-input>
            <form-date-input v-model="fields.netWorthAsOfDate" label="Net Worth as of Date" field="netWorthAsOfDate" :errors="errors" />
            <form-input v-model="fields.liquidity" label="Liquidity" field="liquidity" mask="currency" :errors="errors">
              <template slot="prepend">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
              </template>
            </form-input>
            <form-date-input v-model="fields.liquidityAsOfDate" label="Liquidity as of Date" field="liquidityAsOfDate" :errors="errors" />
          </div>
        </div>
      </view-panel>
      <view-panel v-if="canUpdateBorrowerData" class="loan-details-view-panel">
        <template slot="header">
          Borrower Background Summary
        </template>
        <div class="row loan-details-view-panel-row">
          <div class="col-6 loan-details-view-panel-column">
            <form-input v-model="fields.fico" label="FICO" field="fico" mask="fico" :errors="errors" />
            <form-select
              v-model="fields.guarantorTrackRecord"
              label="Track Record"
              field="guarantorTrackRecord"
              :options="fields.guarantorTrackRecords"
              :errors="errors"
            />
            <form-input v-if="showReserveRatio" v-model="fields.reserveRatio" label="Reserve Ratio" field="reserveRatio" mask="percentage" :errors="errors">
              <template slot="append">
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </template>
            </form-input>
          </div>
          <div class="col-6 loan-details-view-panel-column">
            <form-select
              v-model="fields.personalGuaranty"
              label="Personal Guaranty"
              field="personalGuaranty"
              :options="fields.yesNoOptions"
              :errors="errors"
            />
          </div>
        </div>
      </view-panel>
      <view-panel class="loan-details-view-panel" v-if="borrower.supportHMDA">
        <template slot="header">
          Demographic Information
        </template>
        <div class="row loan-details-view-panel-row">
          <div class="col-6 loan-details-view-panel-column">
            <borrower-demographic-info-form :groups="hmdaInfoOptionGroups" />
          </div>
        </div>
      </view-panel>
      <background-review-form :party="fields" />
    </form>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import Borrower from "models/borrower";
import BorrowerMixin from "./borrower_mixin";
import AjaxService from "services/ajax_service";
import ViewPanel from "components/view_panel.vue";
import BreadcrumbNavigation from "components/shared/breadcrumb_navigation";
import FormInput from "components/shared/form/form_input";
import FormDateInput from "components/shared/form/form_date_input";
import FormSelect from "components/shared/form/form_select";
import BackgroundReviewForm from "components/shared/background_review/form";
import BorrowerDemographicInfoForm from "./borrower_demographic_info_form";
import VestingClauseTooltip from "./vesting_clause_tooltip.vue";

export default {
  name: "loan-borrower-form",
  components: {
    BreadcrumbNavigation,
    ViewPanel,
    FormInput,
    FormDateInput,
    FormSelect,
    BorrowerDemographicInfoForm,
    BackgroundReviewForm,
    VestingClauseTooltip,
  },
  mixins: [BorrowerMixin],
  props: {
    borrower: {
      type: Borrower,
      required: true,
    },
    canUpdateBorrowerData: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      routes: PSData.routes || {},
      saveDisabled: false,
      hmdaInfoOptionGroups: this.initHMDAData(),
    };
  },
  computed: {
    fields() {
      return this.borrower;
    },
    isNew() {
      return !this.fields.id;
    },
    title() {
      return this.isNew ? "New Borrower" : this.borrower.fullAddress;
    },
    breadcrumbItems() {
      return [
        {
          name: "Details",
          link: this.routes.loan,
        },
        {
          name: "Loan Sponsors",
          link: this.routes.borrowers,
        },
        {
          name: `Borrower - ${this.borrower.name || "(name not yet added)"}`,
        },
      ];
    },
  },
  watch: {
    "borrower.hmdaInfoGroups": function() {
      this.hmdaInfoOptionGroups = this.initHMDAData();
    },
    "borrower.name": function() {
      if (!this.saveDisabled) {
        this.refreshVestingClause();
        this.refreshLegalName();
      }
    },
    "borrower.gender": function() {
      if (!this.saveDisabled) {
        this.refreshVestingClause();
      }
    },
    "borrower.maritalStatus": function() {
      if (!this.saveDisabled) {
        this.refreshVestingClause();
      }
    },
    "borrower.soleProperty": function(oldValue, newValue) {
      if (!this.saveDisabled && String(newValue) != String(oldValue)) {
        this.refreshVestingClause();
      }
    },
    "borrower.entityState": function() {
      if (!this.saveDisabled) {
        this.refreshVestingClause();
      }
    },
    "borrower.borrowerType": function() {
      if (!this.saveDisabled) {
        this.refreshVestingClause();
      }
    },
    "borrower.vestingClause": function() {
      if (!this.saveDisabled) {
        this.refreshLegalName();
      }
    },
  },
  methods: {
    onCancel() {
      this.errors = {};
      this.$emit("on-cancel");
    },
    onSave() {
      this.save();
    },
    save() {
      this.saveDisabled = true;
      this.errors = {};
      return this.saveBorrower()
        .then(data => {
          this.$emit("on-save", data);
        })
        .finally(() => {
          this.saveDisabled = false;
        });
    },
    saveBorrower() {
      const messages = { onSuccess: "Borrower is saved", onError: this.onError };
      const requestMethod = this.isNew ? "post" : "patch";
      const objectToSave = this.borrower.objectToSave;
      objectToSave.hmda_info_groups = this.prepareHMDAData();

      return AjaxService.withNotifications(messages)[requestMethod](
        this.routes.borrower,
        objectToSave,
        { "Content-Type": "application/json" }
      );
    },
    onError({ data }) {
      this.errors = data.errors || {};
      return null;
    },
    prepareHMDAData() {
      return this.hmdaInfoOptionGroups.map(group => {
        const options = group.options
          .filter(option => !!option.value)
          .map(option => ({ option_id: option.id, freeform_value: option.freeformValue }));
        if (!group.isMultiselectAllowed && group.value) {
          options.unshift({ option_id: group.value });
        }

        let isBasedOnObservation = undefined;
        if (group.isBasedOnObservation === "true") {
          isBasedOnObservation = true;
        }
        if (group.isBasedOnObservation === "false") {
          isBasedOnObservation = false;
        }

        return {
          group_id: group.id,
          observation_based: isBasedOnObservation,
          infos: options,
        };
      });
    },
    initHMDAData() {
      const schema = cloneDeep(PSData.hmdaInfoOptionGroups || []);
      (this.borrower.hmdaInfoGroups || []).forEach(group => {
        const groupSchema = schema.find(groupSchema => groupSchema.id === group.groupId);

        if (!groupSchema) return;

        const groupSchemaOptions = groupSchema.options || [];

        (group.hmdaInfos || []).forEach(info => {
          const schemaOption = groupSchemaOptions.find(option => option.id === info.optionId) || {};

          schemaOption.value = true;
          if (info.freeformValue) {
            schemaOption.freeformValue = info.freeformValue;
          }
        });
        groupSchema.value = (
          groupSchemaOptions.find(option => !option.parentOptionId && option.value) || {}
        ).id;

        let isBasedOnObservation =
          typeof group.isBasedOnObservation === "boolean"
            ? group.isBasedOnObservation.toString()
            : undefined;

        groupSchema.isBasedOnObservation = isBasedOnObservation;
      });

      return schema;
    },
    refreshVestingClause() {
      this.borrower.refreshVestingClause();
    },
    refreshLegalName() {
      this.borrower.refreshLegalName();
    },
  },
};
</script>
