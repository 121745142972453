<template>
  <div class="cash-receipts-admin row">
    <Menu v-bind="{ currentView }" @view="updateView" />
    <component :is="buildViewComponent" v-bind="{ filters }" @filter="$listeners.filter" />
  </div>
</template>
<script>
import Menu, { defaultView } from "./menu";
import DistributionRules from "./distribution_rules";
import SubservicerFees from "./subservicer_fees";
import DistributionFees from "./distribution_fees";

export default {
  components: {
    Menu,
    DistributionRules,
    SubservicerFees,
    DistributionFees,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentView: defaultView(this.filters.view),
    };
  },
  methods: {
    updateView(view) {
      this.currentView = view;
      this.$emit("filter", { view: view.id });
    },
  },
  computed: {
    buildViewComponent() {
      return this.currentView.component;
    },
  },
};
</script>
