<template>
  <div class="loan-tasks-custom-form">
    <div>
      <div class="form-group">
        <input
          class="form-input"
          v-model="title"
          :placeholder="titlePlaceholder"
          ref="custom-task-form-title"
          @keydown.enter="submitForm"
        />
      </div>
      <task-group-dropdown class="form-group" v-model="groupId" :groups="filteredTaskGroups" />
      <editor-field
        v-model="description"
        :placeholder="descriptionPlaceholder"
        class="p-0"
      />
    </div>

    <div class="loan-task-buttons-container loan-task-buttons">
      <button class="btn btn-link loan-task-btn" @click="cancel">
        Cancel
      </button>
      <button class="btn btn-action-cta loan-task-btn" :disabled="!canSubmit" @click="submitForm">
        <slot name="submit-text"></slot>
      </button>
    </div>
  </div>
</template>

<script>
import TaskGroupDropdown from "components/activity_feed_events/manage/loan_tasks/task_group_dropdown";
import EditorField from "components/shared/editor_field";

const TITLE_PLACEHOLDERS = {
  underwriter: "Internal Task Title",
  lender: "Lender Task Title",
};

const DESCRIPTION_PLACEHOLDERS = {
  underwriter: "Internal Task Description",
  lender: "Lender Task Description",
};

export default {
  components: {
    TaskGroupDropdown,
    EditorField,
  },
  props: {
    task: {
      type: Object,
      default: () => {
        return {
          title: "",
          description: "",
          groupId: "",
        };
      },
    },
    taskType: String,
    taskGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: this.task.title,
      description: this.task.description,
      groupId: this.task.groupId,
      saveDisabled: false,
    };
  },
  methods: {
    submitForm() {
      if (!this.canSubmit) return;

      this.saveDisabled = true;

      this.$emit("form-submitted", {
        title: this.title,
        description: this.description,
        loan_task_group_id: this.groupId,
        tasklist_milestone_id: this.groupId,
      });
    },
    cancel() {
      this.$emit("canceled");
    },
  },
  computed: {
    canSubmit() {
      if (this.saveDisabled) {
        return false;
      }

      const groupIdMissed = this.isLenderTask && !this.groupId;

      return this.title.trim().length > 0 && !groupIdMissed;
    },
    titlePlaceholder() {
      return TITLE_PLACEHOLDERS[this.taskType];
    },
    descriptionPlaceholder() {
      return DESCRIPTION_PLACEHOLDERS[this.taskType];
    },
    isLenderTask() {
      return this.taskType === "lender";
    },
    filteredTaskGroups() {
      return this.taskGroups.filter(group => {
        return group.name === "internal" || group.visibility === "lender";
      });
    },
  },
};
</script>
