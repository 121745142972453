<template>
  <div>
    <slot />

    <ul ref="ul" :class="{ 'opacity-mask': showMask }">
      <li ref="results" v-for="(rule, index) in orderedResults" :class="'credit-box-rules-' + rule.type">
         {{rule.explanation}}
         <LpsExplain :context="{ resultId: rule.id }" :enabled="viewLpsResults" />
      </li>
    </ul>
  </div>
</template>

<script>
import LpsExplain from "components/lps_explain";

const VISIBLE_RESULTS = 3;
const PADDING = 40;

export default {
  name: "credit-box-results",
  components: {
    LpsExplain,
  },
  props: {
    results: {
      type: Array,
      required: true,
    },
    showMask: {
      type: Boolean,
      required: false,
    },
    viewLpsResults: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.showMask) {
        const elem = this.$refs.ul;
        const li = this.$refs.results[VISIBLE_RESULTS];
        const newHeight = li.offsetTop - elem.offsetTop + PADDING;
        elem.style.height = `${newHeight}px`;
      }
    });
  },
  computed: {
    orderedResults() {
      return this.results.sort((r1, r2) => {
        if (r1.type !== r2.type) {
          return r1.type === "system" ? 1 : -1;
        } else {
          return 0;
        }
      });
    },
  },
};
</script>
