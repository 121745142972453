import last from "lodash/last";
import remove from "lodash/remove";

import { FicoRange, LtvRange } from "./range";
import PurchaseRate from "./purchase_rate";

export default class Grid {
  static deserialize(rawGrid) {
    return new Grid(
      rawGrid.ficos.map(col => new FicoRange(col.id, col.from, col.to)),
      rawGrid.ltvs.map(row => new LtvRange(row.id, row.from, row.to)),
      rawGrid.rates.map(cell => new PurchaseRate(cell.ficoId, cell.ltvId, cell.value))
    );
  }

  constructor(
    ficos = [new FicoRange(1)],
    ltvs = [new LtvRange(1)],
    rates = [new PurchaseRate(1, 1)]
  ) {
    this.ficos = ficos;
    this.ltvs = ltvs;
    this.rates = rates;
  }

  addCol() {
    const id = last(this.ficos).id + 1;

    this.ficos.push(new FicoRange(id));
    this.ltvs.forEach(row => this.rates.push(new PurchaseRate(id, row.id)));
  }

  removeCol(col) {
    this.ficos.splice(this.ficos.indexOf(col), 1); // to keep reactivity
    remove(this.rates, c => c.ficoId === col.id);
  }

  addRow() {
    const id = last(this.ltvs).id + 1;

    this.ltvs.push(new LtvRange(id));
    this.ficos.forEach(col => this.rates.push(new PurchaseRate(col.id, id)));
  }

  removeRow(row) {
    this.ltvs.splice(this.ltvs.indexOf(row), 1); // to keep reactivity
    remove(this.rates, r => r.ltvId === row.id);
  }

  getCell(ltvId, ficoId) {
    return this.rates.find(rate => rate.ltvId === ltvId && rate.ficoId === ficoId);
  }

  copy() {
    return Grid.deserialize(this.toPlainObject());
  }

  toPlainObject({ toSnakeCase } = { toSnakeCase: false }) {
    return {
      ficos: this.ficos.map(col => col.toPlainObject({ toSnakeCase })),
      ltvs: this.ltvs.map(row => row.toPlainObject({ toSnakeCase })),
      rates: this.rates.map(cell => cell.toPlainObject({ toSnakeCase })),
    };
  }

  get canDeleteCol() {
    return this.ficos.length > 1;
  }

  get canDeleteRow() {
    return this.ltvs.length > 1;
  }
}
