<template>
  <div class="loan-tracker">
    <loan-tracker-filter
      :status-options="filterOptions"
      :asset-manager-options="assetManagerOptions"
      :relationship-manager-options="relationshipManagerOptions"
      :status="filters.status"
      :asset-manager-id="filters.assetManagerId"
      :relationship-manager-id="filters.relationshipManagerId"
      :is-searching="isSearching"
      @input="updateStatusFilter"
      @update-asset-manager="updateAssetManager"
      @update-relationship-manager="updateRelationshipManager"
    />
    <div class="table-header">
      <h5>Showing {{ loanDisplayCount }} of {{ loanAvailableCount }} Loans</h5>
    </div>
    <div class="table-container">
      <notification-group
        :title="'Loan Tracker Search Error'"
        :message-groups="errors"
        :dismissible="true"
        @dismissed="clearErrors"
      />
      <loan-tracker-table
        :sort-column="sort.column"
        :sort-direction="sort.direction"
        @update-sort-column="updateSortColumn"
      >
        <table-row
          v-for="loan in currentLoans"
          :key="`${loan.psLoanId}-${loan.propertyAddress}`"
          :sort-column="sort.column"
          v-bind="loan"
        />
      </loan-tracker-table>
    </div>
    <div class="footer d-flex">
      <button
        v-show="showLoadMoreButton"
        class="btn btn-outline-secondary"
        @click="loadMoreLoans"
      >
        Load 50 More
      </button>
    </div>
  </div>
</template>

<script>
const DEBOUNCE_DELAY = 600;

import { isEmpty, debounce } from "lodash";
import { objectAndValuesToSnakeCase } from "utils/object_to_snake_case";
import AjaxService, { formatParams, ERROR_MESSAGE } from "services/ajax_service";
import NotificationGroup from "components/shared/notification_group";

import TableRow from "./table_row";
import LoanTrackerFilter from "./filter";
import LoanTrackerTable from "./table";
export default {
  components: {
    TableRow,
    NotificationGroup,
    LoanTrackerFilter,
    LoanTrackerTable,
  },
  data() {
    return {
      currentLoans: [],
      errors: [],
      filters: {
        assetManagerId: undefined,
        relationshipManagerId: undefined,
        status: [],
      },
      sort: {
        column: "lastOutreachDate",
        direction: 1,
      },
      loanAvailableCount: PSData.available || 0,
      isSearching: false,
    };
  },
  computed: {
    filterOptions: () => PSData.loanTracker.filterOptions,
    assetManagerOptions: () => PSData.assetManagers,
    relationshipManagerOptions: () => PSData.loanTracker.relationshipManagers,
    loanDisplayCount() {
      return this.currentLoans.length;
    },
    routes: () => PSData.routes,
    showLoadMoreButton() {
      return this.loanDisplayCount < this.loanAvailableCount;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.debouncedSearch(true);
      },
    },
    sort: {
      deep: true,
      handler() {
        this.debouncedSearch(true);
      },
    },
  },
  created() {
    this.debouncedSearch = debounce(this.search, DEBOUNCE_DELAY);
    this.currentLoans = PSData.loans;
  },
  methods: {
    clearErrors() {
      this.errors = [];
    },
    loadMoreLoans() {
      this.search();
    },
    updateSortColumn(columnName, sortDirection) {
      this.$set(this.sort, "column", columnName);
      this.$set(this.sort, "direction", sortDirection);
    },
    updateStatusFilter(e) {
      if (e.target.checked) {
        this.filters.status.push(e.target.value);
      } else {
        this.filters.status = this.filters.status.filter(s => s !== e.target.value);
      }
    },
    updateAssetManager(e) {
      let managerId = undefined;
      if (!isEmpty(e.target.value)) {
        managerId = parseInt(e.target.value, 10);
      }
      this.filters.assetManagerId = managerId;
    },
    updateRelationshipManager(e) {
      let managerId = undefined;
      if (!isEmpty(e.target.value)) {
        managerId = parseInt(e.target.value, 10);
      }
      this.filters.relationshipManagerId = managerId;
    },
    async search(reset = false) {
      const filterParams = formatParams(objectAndValuesToSnakeCase(this.filters));
      const sortParams = formatParams(objectAndValuesToSnakeCase(this.sort));
      const offset = reset ? 0 : this.currentLoans.length;
      const offsetParams = formatParams({ offset: offset });
      const url = `${this.routes.loanTracker}?${filterParams}&${sortParams}&${offsetParams}`;
      this.isSearching = true;
      try {
        const response = await AjaxService.getJSON(url);

        if (reset) {
          this.currentLoans = response.loans;
          this.loanAvailableCount = response.available;
        } else {
          this.currentLoans = this.currentLoans.concat(response.loans);
        }

        this.$emit("update-data-timestamp");
      } catch ({ data, response }) {
        if (Array.isArray(data.errors)) {
          this.errors = data.errors.join(". ");
        } else {
          this.errors = [ERROR_MESSAGE];
        }
      } finally {
        this.isSearching = false;
      }
    },
  },
};
</script>
