<template>
  <div class="incomplete-task">
    <h4 class="loan-details-section-header">
      Closing Agent Information
      <span v-if="forLender">
        Valid information is required for loan processing
      </span>
    </h4>

    <closing-agent-info-table
      :closing-agents="closingAgents"
      :closing-agent-types="closingAgentTypes"
      :can-edit="canEdit"
      @closing-agent-created="closingAgentCreated"
      @closing-agent-updated="closingAgentUpdated"
      @closing-agent-deleted="closingAgentDeleted">
    </closing-agent-info-table>
  </div>
</template>

<script>
import ClosingAgentInfoTable from "components/closing_agent/info_table";
import selectOptionsToMapping from "utils/select_options_to_mapping";

export default {
  name: "closing-agent-info-section",
  components: {
    ClosingAgentInfoTable,
  },
  props: {
    forLender: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      closingAgents: PSData.closingAgents,
      closingAgentTypes: selectOptionsToMapping(PSData.closingAgentTypes),
    };
  },
  methods: {
    closingAgentCreated(agent) {
      this.closingAgents.push(agent);
    },
    closingAgentUpdated(agent) {
      let idx = this.closingAgents.findIndex(a => a.id === agent.id);
      Vue.set(this.closingAgents, idx, agent);
    },
    closingAgentDeleted(agent) {
      this.closingAgents = this.closingAgents.filter(a => a.id !== agent.id);
    },
  },
};
</script>
