<template>
  <div>
    <select2
      v-model="selectedGroup"
      :options="select2Options"
      class="form-input"
      @on-value-change="onValueChange"
    />
  </div>
</template>

<script>
import Select2 from "components/shared/select2";

export default {
  name: "task-group-dropdown",
  components: {
    Select2,
  },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      selectedGroup: this.value,
    };
  },
  computed: {
    select2Options() {
      return {
        data: this.groups.map(group => ({ id: group.id, text: group.label })),
        placeholder: "Task Group",
      };
    },
  },
  methods: {
    onValueChange() {
      this.$emit("input", this.selectedGroup);
    },
  },
};
</script>
