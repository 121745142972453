<template>
  <fragment>
    <base-tag
      v-for="tag in sortedTags"
      :key="tag.id"
      :class="tag.name" 
      type="none"
      size="small"
    >
      {{ tag.label }}
    </base-tag>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
export default {
  components: { Fragment },
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
  computed: {
    sortedTags() {
      const tagsToSort = this.tags;
      return tagsToSort.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
};
</script>

<style scoped>
.base-tag {
  margin-left: var(--space-unit-sm) !important;
}

.accounting {
  color: var(--color-white);
  background-color: var(--color-primary-green);
}

.adverse_litigation {
  color: var(--color-white);
  background-color: blueviolet;
}

.attorney_client {
  color: var(--color-white);
  background-color: var(--color-red);
}

.borrower_entity_dispute {
  color: var(--color-white);
  background-color: saddlebrown;
}

.capital_markets {
  color: var(--color-white);
  background-color: var(--yellow);
}

.borrower_credit {
  color: var(--color-white);
  background-color: var(--color-blue-dark);
}

.construction_budget {
  color: var(--color-white);
  background-color: var(--color-grey-38);
}

.deceased_obligor {
  color: var(--color-white);
  background-color: darkolivegreen;
}

.environmental_issue {
  color: var(--color-white);
  background-color: turquoise;
}

.external {
  color: var(--color-white);
  background-color: var(--pink);
}

.fraud {
  color: var(--color-white);
  background-color: var(--color-red);
}

.inspection {
  color: var(--color-white);
  background-color: var(--color-yellow-dark);
}

.insurance {
  color: var(--color-white);
  background-color: var(--purple);
}

.midstream_construction {
  color: var(--color-white);
  background-color: var(--color-grey-60);
}

.natural_disaster {
  color: var(--color-white);
  background-color: salmon;
}

.owner_occupied {
  color: var(--color-white);
  background-color: var(--color-red-dark);
}

.paralegal {
  color: var(--color-white);
  background-color: var(--orange);
}

.post_closing {
  color: var(--color-white);
  background-color: var(--teal);
}

.quarterly_loan_status {
  color: var(--color-white);
  background-color: var(--color-green-dark);
}

.rent_control {
  color: var(--color-white);
  background-color: var(--color-blue);
}

.rent_issues {
  color: var(--color-white);
  background-color: var(--color-yellow-warning-dark);
}

.site_visit {
  color: var(--color-white);
  background-color: var(--cyan);
}

.tax {
  color: var(--color-white);
  background-color: var(--indigo);
}

.title {
  color: var(--color-white);
  background-color: var(--color-blue-iceberg-dark);
}

.title_claims {
  color: var(--color-white);
  background-color: indigo;
}

.valuation {
  color: var(--color-white);
  background-color: var(--dark);
}

.valuation_issues {
  color: var(--color-white);
  background-color: var(--color-grey-100);
}

.unresponsive_borrower {
  color: var(--color-white);
  background-color: orangered;
}

.zoning_permit_use_issues {
  color: var(--color-white);
  background-color: var(--color-green);
}
</style>
