<template>
  <modal-view :show="show" :on-close="onClose" class="base-modal institution-sales-tracking-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          Reallocate
        </h4>
      </div>
      <div class="modal-body">
        <offering-details :offering="offering"></offering-details>
        <form>
          <div class="form-group">
            <div class="radio-control radio-control-inline">
              <input type="radio" id="peerstreet" value="not_offered" v-model="offeringForm.state"/>
              <label for="peerstreet">
                <span class="control-toggle"></span>
                Peerstreet Reallocation Decision
              </label>
            </div>
            <div class="radio-control radio-control-inline">
              <input
                type="radio"
                id="buyer"
                value="rejected"
                v-model="offeringForm.state"
                :disabled="!batchWasOffered"
              />
              <label for="buyer">
                <span class="control-toggle"></span>
                Buyer Rejection
              </label>
            </div>
          </div>
          <div class="form-group">
            <label>Reason</label>
            <textarea class="form-control notes-input" v-model="offeringForm.notes"/>
          </div>
          <div class="form-group" v-if="batchWasOffered">
            <label>Primary Category</label>
            <select v-model="offeringForm.primaryRejectionReason" class="form-control">
              <option value></option>
              <option v-for="rejectionReason in primaryRejectionReasons" :key="rejectionReason.name" :value="rejectionReason.name">
                {{rejectionReason.label}}
              </option>
            </select>
          </div>
          <div class="form-group" v-if="showSecondaryRejectionReasons">
            <label>Secondary Category</label>
            <select v-model="offeringForm.secondaryRejectionReason" class="form-control">
              <option value></option>
              <option v-for="rejectionReason in secondaryRejectionReasons" :key="rejectionReason.name" :value="rejectionReason.name">
                {{rejectionReason.label}}
              </option>
            </select>
          </div>
          <div class="institution-sales-tracking-modal-usernote">
            Loan will automatically be reallocated to a new buyer
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div>
          <button class="btn btn-outline-grey" @click="clearNotes">Clear notes</button>
        </div>
        <div>
          <button class="btn btn-cancel-cta" @click="onClose">
            Cancel
          </button>
          <button class="btn btn-action-cta" @click="onSave">
            Confirm
          </button>
        </div>
      </div>
    </div>
  </modal-view>
</template>
<script>
import moment from "moment";
import ModalView from "components/modal_view";
import LoanOffering from "models/institution/loan_offering";
import OfferingDetails from "./offering_details";

export default {
  name: "reallocate-modal",
  components: {
    ModalView,
    OfferingDetails,
  },
  props: {
    show: Boolean,
    offering: Object,
    rejectionReasons: Array,
    batch: Object,
  },
  data() {
    return {
      offeringForm: {
        nextAction: "reallocate",
        primaryRejectionReason: this.offering.primaryRejectionReason,
        secondaryRejectionReason: this.offering.secondaryRejectionReason,
        notes: this.offering.notes,
        state: this.offering.state,
      },
    };
  },
  methods: {
    clearNotes() {
      this.offeringForm.notes = "";
    },

    onClose() {
      this.$emit("close");
    },
    onSave() {
      this.$emit("save", this.offering, this.offeringForm);
    },
  },
  computed: {
    batchWasOffered() {
      return this.batch.offerDate;
    },
    showSecondaryRejectionReasons() {
      return this.secondaryRejectionReasons.length > 0;
    },
    primaryRejectionReasons() {
      return this.rejectionReasons.filter(rejectionReason => !rejectionReason.parent);
    },
    secondaryRejectionReasons() {
      if (!this.offeringForm.primaryRejectionReason) {
        return [];
      }
      return this.rejectionReasons.filter(rejectionReason => {
        return rejectionReason.parent === this.offeringForm.primaryRejectionReason;
      });
    },
  },
  watch: {
    "offeringForm.state": function(newVal, oldVal) {
      if (newVal === "not_offered" && !this.offeringForm.notes) {
        this.offeringForm.notes = `[${moment().format("MM/DD/YYYY")}] - ${
          this.offering.reviewText
        }`;
      }
    },
  },
};
</script>
