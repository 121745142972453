const RowDetailsSource = PS.Models.MarketData.Tables.RowDetailsSource;

class HouseholdGrowth extends RowDetailsSource {
  constructor() {
    super();

    this.raw = {
      totalHouseholds: { value: undefined, type: "number", precision: 0 },
      zipGrowthInPastYear: { value: undefined, type: "number", precision: 0 },
      zipGrowthInPastYearPercentage: { value: undefined, type: "percent", precision: 2 },
      msaGrowthInPastYear: { value: undefined, type: "number", precision: 0 },
      msaGrowthInPastYearPercentage: { value: undefined, type: "percent", precision: 2 },
    };
  }

  get name() {
    return "Household Growth";
  }

  get data() {
    const formatted = this.formatted;

    return [
      {
        label: "Total Households",
        value: formatted.totalHouseholds,
      },
      {
        label: "Growth in Past Year",
        value: this.zipGrowthInPastYearDetails(formatted),
      },
      {
        label: "MSA Growth in Past Year",
        value: this.msaGrowthInPastYeardetails(formatted),
      },
    ];
  }

  zipGrowthInPastYearDetails(formatted) {
    if (formatted.zipGrowthInPastYear) {
      return `${formatted.zipGrowthInPastYear} (${formatted.zipGrowthInPastYearPercentage})`;
    }
  }

  msaGrowthInPastYeardetails(formatted) {
    if (formatted.msaGrowthInPastYear) {
      return `${formatted.msaGrowthInPastYear} (${formatted.msaGrowthInPastYearPercentage})`;
    }
  }

  pastYearPercentage(growth, totalHouseholds) {
    if (growth && totalHouseholds) {
      const value = (growth / totalHouseholds) * 100;
      return Number.isFinite(value) ? value : undefined;
    }
  }

  success(data) {
    const zipData = data.midas.zipCrossSection || {};
    const msaData = data.midas.msaCrossSection || {};

    this.raw.totalHouseholds.value = zipData.totalHh;
    this.raw.zipGrowthInPastYear.value = zipData.chgHh;
    this.raw.msaGrowthInPastYear.value = msaData.chgHh;

    this.raw.zipGrowthInPastYearPercentage.value = this.pastYearPercentage(
      zipData.chgHh,
      zipData.totalHh
    );

    this.raw.msaGrowthInPastYearPercentage.value = this.pastYearPercentage(
      msaData.chgHh,
      msaData.totalHh
    );

    super.success();
  }
}

PS.Models.MarketData.Charts.HouseholdGrowth = HouseholdGrowth;
