class ReportEvent extends PS.Models.Event {}

class LoadingRequested extends ReportEvent {}

class ReloadReportRequested extends ReportEvent {}

class UpdateMLDRequested extends ReportEvent {}

class ChangeValueRequested extends ReportEvent {
  prepareData(options) {
    this.value = options.value;
    this.elementName = options.elementName;
  }
}

PS.Models.Diligence.Events.Report = {
  LoadingRequested: LoadingRequested,
  ReloadReportRequested: ReloadReportRequested,
  ChangeValueRequested: ChangeValueRequested,
  UpdateMLDRequested: UpdateMLDRequested,
};
