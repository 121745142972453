<template>
  <view-panel class="loan-details-view-panel">
    <template slot="header">
      Taxes & Insurance
    </template>
    <editable-list
      header="Tax Installments"
      :columns="loanTaxInstallmentsColumns"
      :create-record="loanTaxInstallmentCreate"
      :record-route="routes.taxInstallments"
    />
    <editable-list
      header="Insurance Installments"
      :columns="loanInsurancePremiumsColumns"
      :create-record="loanInsurancePremiumCreate"
      :record-route="routes.insurancePremiums"
    />
  </view-panel>
</template>

<script>
import ViewPanel from "components/view_panel.vue";
import Property from "models/lender_form/property";
import EditableList from "./editable_list";
import LoanTaxInstallment from "models/lender_form/loan_tax_installment";
import LoanInsurancePremium from "models/lender_form/loan_insurance_premium";

export default {
  name: "loan-property-tax-and-insurance",
  components: {
    ViewPanel,
    EditableList,
  },
  props: {
    property: {
      type: Property,
      required: true,
    },
  },
  data() {
    return {
      routes: PSData.routes || {},
    };
  },
  computed: {
    loanTaxInstallmentsColumns() {
      return [
        "Installment Type",
        "Installment Due Date",
        "Installment Amount Due",
        "Payment Method",
        "Payee",
      ];
    },
    loanInsurancePremiumsColumns() {
      return ["Premium Type", "Premium Due Date", "Premium Amount Due", "Payment Method", "Payee"];
    },
    loanTaxInstallmentCreate() {
      return LoanTaxInstallment.create;
    },
    loanInsurancePremiumCreate() {
      return LoanInsurancePremium.create;
    },
  },
};
</script>
