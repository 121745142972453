import { titleize, formatNumber, formatPercent, formatCurrency } from "../utils/formatter";
import { isEmpty, isEqual } from "lodash";

export default {
  props: {
    constraints: Object,
    convertedScenario: Object,
    loanCreationInProgress: Boolean,
    priceResults: Array,
    scenario: Object,
  },
  data() {
    return {
      showCustomRow: false,
      selectedRow: null,
    };
  },
  watch: {
    priceResults: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (!this.isConvertedScenarioEmpty() && newVal.length > 0) {
          this.setSelectedRow();
        }
      },
    },
  },
  methods: {
    formatCurrency,
    formatNumber,
    formatPercent,
    titleize,

    toggleCustomScenario() {
      this.showCustomRow = !this.showCustomRow;
    },
    calculateLoanAmount(_) {
      this.scenario.loanAmount = this.scenario.calculateLoanAmount(this.scenario.ltv);
    },
    calculateLtv(_) {
      this.scenario.ltv = this.scenario.calculateLtv(this.scenario.loanAmount);
    },
    selectScenarioLabel(newRow, currentRow) {
      return isEqual(newRow, currentRow) ? "selected" : "select";
    },
    setSelectedRow() {
      this.selectedRow = this.priceResults.find(r => {
        return (
          r.custom === JSON.parse(this.convertedScenario.custom) &&
          r.ltv === parseFloat(this.convertedScenario.ltv) &&
          !r.cbFlag
        );
      });

      if (JSON.parse(this.convertedScenario.custom)) {
        this.showCustomRow = true;
      }
    },
    isConvertedScenarioEmpty() {
      return isEmpty(this.convertedScenario);
    },
    canSelectRow(cbFlag = false, ltv = true) {
      return (
        !this.loanCreationInProgress &&
        this.showSelectColumn &&
        this.isConvertedScenarioEmpty() &&
        !cbFlag &&
        !!ltv
      );
    },
  },
  computed: {
    minLoanAmount() {
      return this.constraints.minLoanAmount || this._minLoanAmount;
    },
    maxLoanAmount() {
      return this.constraints.maxLoanAmount || this._maxLoanAmount;
    },
    scenariosResults() {
      let results = this.priceResults.length === 0 ? this.defaultResults : this.priceResults;
      let custom = results.find(r => r.custom === true);

      if (custom === undefined && this.showCustomRow) {
        results.push(this.emptyCustom);
      }

      return results;
    },
    showSelectColumn() {
      return this.priceResults.length > 0;
    },
    customInputDisabled() {
      return this.loanCreationInProgress;
    },
  },
};
