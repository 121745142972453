<template>
<div class="action-button btn-group" v-if="hasSettlementStatement">
  <button
    type="button"
    class="btn btn-secondary-action-cta dropdown-toggle"
    data-toggle="dropdown"
    :disabled="!isActionable"
    aria-haspopup="true"
    aria-expanded="false"
  >
    Actions
    <i class="fas fa-angle-down"></i>
  </button>

  <ul class="dropdown-menu">
    <li v-for="option in enabledOptions" :key="option.label" :class="option.class" :role="option.role">
      <a v-if="option.url" :href="option.url" target="_blank">{{ option.label }}</a>
      <a v-else-if="option.emit" href="#" @click.prevent="option.emit" :class="option.class">
        {{ option.label }}
      </a>
    </li>
  </ul>
</div>

<button
  type="button"
  class="btn btn-secondary-action-cta"
  @click="createSettlementStatement"
  :disabled="isDisabled"
  v-else
>
  Generate
</button>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import SettlementStatementPolicy from "policies/settlement_statement_policy";

export default {
  name: "SettlementStatementActionButton",
  props: [
    "statement",
    "isLoading",
    "settlementStatementPolicy",
    "showPreviousValues",
    "generalOnly",
    "loanPolicy",
  ],
  data() {
    return {
      servicingShowEditForm: false,
      messageBus: window.PS.Services.MessageBusService.servicingBus,
    };
  },
  computed: {
    enabledOptions() {
      return this.options.filter(option => option.enabled);
    },
    options() {
      return [
        {
          label: "Purchase",
          enabled: this.isPurchasable,
          emit: this.purchaseStatement,
        },
        {
          label: "Approve",
          enabled: this.isApprovable,
          emit: this.approveStatement,
        },
        {
          label: "Download PDF",
          enabled: this.isDownloadable,
          url: this.statement.downloadUrl,
        },
        {
          label: this.createStatementButtonText,
          enabled: this.isCreate,
          emit: this.createSettlementStatement,
        },
        {
          label: "Refresh Statement",
          enabled: this.isRefreshable,
          emit: this.refreshStatement,
        },
        {
          label: this.showPreviousValuesBtnText,
          enabled: this.isComparable,
          emit: this.togglePreviousValues,
        },
        {
          label: "Edit Statement",
          enabled: this.isEditable,
          emit: this.editStatement,
        },
        {
          label: "Submit for Approval",
          enabled: this.isSubmittable,
          emit: this.submitStatement,
        },
        {
          label: "Repair Statement",
          enabled: this.isRepairable,
          emit: this.repairStatement,
        },
        {
          label: "Wire Instructions",
          enabled: this.isWireable,
          emit: this.updateWireInstruction,
        },
        {
          role: "separator",
          class: "dropdown-divider",
          enabled: this.isShowSeparator,
        },
        {
          label: "Reverse Purchase",
          enabled: this.isReversible,
          emit: this.reverseStatement,
        },
        {
          label: "Cancel Statement",
          enabled: this.isCancelable,
          emit: this.cancelStatement,
          class: { disabled: !this.isCancelable },
        },
      ];
    },
    policy() {
      return new SettlementStatementPolicy(this.settlementStatementPolicy);
    },
    loanEditPolicy() {
      return new PS.Policies.EditLoanFormPolicy(this.loanPolicy);
    },
    hasSettlementStatement() {
      return !isEmpty(this.statement);
    },
    isReconciliation() {
      return this.statement.settlementStatementType == "reconciliation";
    },
    isLatestStatement() {
      return this.statement.isLatestStatement;
    },
    isPastStatement() {
      return !this.statement.isLatestStatement;
    },
    isCreate() {
      return this.isCreatable && this.isLatestStatement;
    },
    isShowSeparator() {
      return this.isStatementView && (this.isReversible || this.isCancelable);
    },
    isApprovable() {
      return this.policy.canApprove() && this.isStatementView && this.isLatestStatement;
    },
    isStatementView() {
      return !this.generalOnly;
    },
    isActionable() {
      if (this.generalOnly) {
        const buttonChecks = [this.isReversible, this.isCreatable];

        return buttonChecks.includes(true);
      } else {
        return !(this.isLoading || this.servicingShowEditForm);
      }
    },
    isCancelable() {
      return this.policy.canCancel() && this.isStatementView && this.isLatestStatement;
    },
    isCreatable() {
      return this.loanEditPolicy.canCreateSettlementStatement();
    },
    isComparable() {
      return this.policy.canShowPrevious() && this.isStatementView && this.isReconciliation;
    },
    isDisabled() {
      return !this.isCreatable || this.servicingShowEditForm || this.isLoading;
    },
    isDownloadable() {
      return this.policy.canDownload() && this.isStatementView;
    },
    isRefreshable() {
      return this.policy.canRefresh() && this.isStatementView && this.isLatestStatement;
    },
    isSubmittable() {
      return this.policy.canSubmit() && this.isStatementView && this.isLatestStatement;
    },
    isPurchasable() {
      return (
        this.policy.canPurchase() &&
        this.policy.canManualPurchase() &&
        this.isStatementView &&
        this.isLatestStatement
      );
    },
    isReversible() {
      return this.policy.canReverse() && this.isLatestStatement;
    },
    isEditable() {
      return this.policy.canUpdate() && this.isStatementView && this.isLatestStatement;
    },
    isRepairable() {
      return this.policy.canRepair() && this.isStatementView && this.isLatestStatement;
    },
    isWireViewable() {
      return ["approved", "purchased", "reversal"].includes(this.statement.status);
    },
    isWireUpdateableOrViewable() {
      return this.policy.canUpdateWireInstruction() || this.isWireViewable;
    },
    isWireable() {
      return this.isWireUpdateableOrViewable && this.isStatementView && this.isLatestStatement;
    },
    createStatementButtonText() {
      if (this.statement.settlementStatementType == "reversal") {
        return "Generate Statement";
      } else {
        return "Generate Reconciliation Statement";
      }
    },
    showPreviousValuesBtnText() {
      if (this.showPreviousValues) return "Hide Previous Values";

      return "Show Previous Values";
    },
  },
  methods: {
    approveStatement(event) {
      if (this.isApprovable) {
        return this.$emit("approve-statement");
      }
    },
    cancelStatement(event) {
      if (this.isCancelable) {
        return this.$emit("cancel-statement");
      }

      event.stopPropagation();
    },
    refreshStatement() {
      if (this.isRefreshable) {
        this.$emit("refresh-statement");
      }
    },
    submitStatement() {
      if (this.isSubmittable) {
        this.$emit("submit-statement");
      }
    },
    purchaseStatement() {
      if (this.isPurchasable) {
        this.$emit("purchase-statement");
      }
    },
    reverseStatement() {
      if (this.isReversible) {
        this.$emit("reverse-statement");
      }
    },
    editStatement() {
      if (this.isEditable) {
        this.$emit("edit-statement");
      }
    },
    repairStatement() {
      if (this.isRepairable) {
        this.$emit("repair-statement");
      }
    },
    updateWireInstruction() {
      if (this.isWireable) {
        this.$emit("wire-instruction");
      }
    },
    createSettlementStatement() {
      if (this.isCreatable) {
        this.$emit("generate-statement");
      }
    },
    togglePreviousValues() {
      if (this.isComparable) {
        this.$emit("toggle-previous-values");
      }
    },
  },
  mounted() {
    this.messageBus.subscribe(event => {
      if (event.name === "servicingShowEditForm") {
        this.servicingShowEditForm = event.value;
      }
    });
  },
};
</script>
