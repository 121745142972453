import AjaxService from "services/ajax_service";
import RequestError from "components/loan_scenarios/utils/request_error";

export default class LoanDraftsClient {
  constructor() {
    this.abortController = new AbortController();
  }

  async post(id, payload) {
    this.abortController.abort();
    this.abortController = new AbortController();

    let response;
    let path = window.location.pathname.replace(/\/$/, "");
    let url = path + `/drafts/${id}/draft_loans`;

    try {
      response = await AjaxService.postJSON(url, payload, {}, this.abortController.signal);
    } catch (error) {
      if (response) {
        RequestError.notify({
          message: "Unable to convert scenario to draft loan",
          response,
          payload,
        });
      }
    } finally {
      return response || {};
    }
  }
}
