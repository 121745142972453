<template>
  <div>
    <categorized-bar-chart :chart="chart" :sources="sources" class="housing-stock-size-chart"></categorized-bar-chart>
    <div class="row listing-stats-explanation-section">
      <div class="col-md-10">
        Median # of Bedrooms
      </div>
      <div class="col-md-2 value-section">
        <span v-show="sources.isLoading">
          &nbsp;</span>
        <span v-show="sources.isSuccessful">
          {{ medianNumberOfBedrooms | notAvailable }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "d3";

import CategorizedBarChart from "components/market_data/categorized_bar_chart";

export default {
  name: "housing-stock-size-chart",
  components: {
    CategorizedBarChart,
  },
  props: {
    sources: Object,
  },
  data() {
    return {
      chart: {
        title: this.sources.name,

        axisXTitle: "Home size (# of bedrooms)",

        axisYTitle: "Percent of households",
        axisYTickFormat: format(",.0%"),

        tooltipFormatValue: format(",.2%"),

        sizeHeight: undefined,

        labels: {
          bedrooms0Pct: "Studio",
          bedrooms1Pct: "1",
          bedrooms2Pct: "2",
          bedrooms3Pct: "3",
          bedrooms4Pct: "4",
          bedrooms5PlusPct: "5+",
        },

        columns: {
          categories: {
            color: "#66cc33",
            label: "Percent of households",
          },
        },
      },
    };
  },
  computed: {
    medianNumberOfBedrooms() {
      if (this.sources.medianCategory) {
        return this.chart.labels[this.sources.medianCategory];
      }
    },
  },
  watch: {
    "sources.hasData": function(newValue) {
      this.chart.sizeHeight = newValue ? 254 : undefined;
    },
  },
};
</script>
