<template>
  <tr>
    <td>
      <i class="fas fa-file-alt" aria-hidden="true" />
    </td>
    <td>
      <a :href="foreclosureDocument.url">
        {{ foreclosureDocument.type }}
      </a>
    </td>
    <td>
      {{ new Date(foreclosureDocument.createdAt) | formatDate("MMMM DD, YYYY [at] h:mm A") }}
    </td>
    <td class="actions">
      <a
        v-if="canEdit"
        :class="valueClass"
        href="#delete"
        title="Delete foreclosure document"
        @click.prevent.once="$emit('delete', foreclosureDocument.id)"
      >
        <i class="fas fa-trash-alt" />
      </a>
    </td>
  </tr>
</template>

<script>
export default {
  name: "foreclosure-document-list-item",
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    foreclosureDocument: {
      type: Object,
      required: true,
    },
  },
  computed: {
    valueClass() {
      return { "disable-delete": this.foreclosureDocument.pending };
    },
  },
};
</script>
