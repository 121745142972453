<template>
  <div class="task-selector">
    <multi-select2
        class="form-control"
        v-model="selectedTaskIds"
        :after-render="onRendered"
        :options="{ data: orderedTasks, placeholder: 'Tag to a Task...' }"
    />
  </div>
</template>

<script>
import MultiSelect2 from "components/shared/multi_select2";
import xor from "lodash/xor";

export default Vue.component("task-selector", {
  components: {
    MultiSelect2,
  },
  props: {
    document: Object,
    tasks: Array,
  },
  data() {
    return {
      selectedTaskIds: [],
      prevSelectedTaskIds: [],
      loading: true,
      skipNextUpdate: false,
    };
  },
  watch: {
    selectedTaskIds(taskIds) {
      if (this.loading) {
        return;
      }
      if (this.skipNextUpdate) {
        this.skipNextUpdate = false;
        return;
      }

      const targetTaskId = xor(taskIds, this.prevSelectedTaskIds)[0];
      if (targetTaskId === undefined || targetTaskId === null) {
        return;
      }

      if (this.prevSelectedTaskIds.length < taskIds.length) {
        this.$emit("task-attached", {
          document: this.document,
          taskId: targetTaskId,
        });
      } else if (this.prevSelectedTaskIds.length > taskIds.length) {
        const taskAttachment = this.document.loanTaskAttachments.find(
          lta => lta.loanTaskId.toString() === targetTaskId
        );

        if (taskAttachment === undefined) {
          return;
        }

        this.$emit("task-detached", {
          document: this.document,
          taskId: targetTaskId,
          taskAttachmentId: taskAttachment.id,
        });
      }
    },
    document: {
      deep: true,
      handler() {
        this.skipNextUpdate = true;
        this.onRendered();
      },
    },
  },
  methods: {
    onRendered() {
      this.selectedTaskIds = this.fetchSelectedTaskIds();
      this.prevSelectedTaskIds = [...this.selectedTaskIds];

      Vue.nextTick(() => {
        this.loading = false;
      });
    },
    fetchSelectedTaskIds() {
      return this.document.loanTaskAttachments.map(taskAttachment => {
        return taskAttachment.loanTaskId.toString();
      });
    },
  },
  computed: {
    orderedTasks() {
      return this.tasks.map(task => ({ id: task.id.toString(), text: task.title }));
    },
  },
});
</script>
