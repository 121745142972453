<template>
  <div>
    <banner-alert
      description="This loan is held on PeerStreet warehouse line(s)."
      title=""
      v-if="showWarehouseAlert"
    />
  </div>
</template>
<script>
import BannerAlert from "components/banners/alert";

export default {
  name: "asset-management-alert-controller",
  components: {
    BannerAlert,
  },
  data() {
    return {
      loanDetails: PSData.loanDetails,
    };
  },
  computed: {
    showWarehouseAlert() {
      return this.loanDetails.warehouseUnpaidPrincipalBalance > 0;
    },
  },
};
</script>
