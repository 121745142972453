<template>
  <div>
    <template v-for="legalOwner in legalOwners">
      <list-item
        v-if="groupedItems[legalOwner.id]"
        :key="legalOwner.id"
        :legal-owner="legalOwner"
        :items="groupedItems[legalOwner.id]"
        @show="$listeners.show"
      />
    </template>
  </div>
</template>

<script>
import ListItem from "./list_item";

export default {
  components: {
    ListItem,
  },
  props: {
    legalOwners: {
      type: Array,
      required: true,
    },
    groupedItems: {
      type: Object,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    loading: Boolean,
  },
};
</script>
