import uniqBy from "lodash/uniqBy";

export function prepareRules(rawRules) {
  return rawRules.filter(rule => !!rule.explanation);
}

export function uniqResults(results) {
  return uniqBy(results, "explanation");
}

export function hasIssues(results) {
  return results.some(rule => rule.type === "validation");
}

export function hasAdjustments(results) {
  return results.some(rule => rule.type === "system");
}

export function uniqIssues(results) {
  return results.filter(rule => rule.type === "validation");
}

export function uniqAdjustments(results) {
  return results.filter(rule => rule.type === "system");
}
