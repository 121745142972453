import get from "lodash/get";

class SeriesService {
  static create(loan, series) {
    var url = `/manage/loans/${loan.toParam}/series`;
    var messages = { onSuccess: "Loan series created." };

    return PS.Services.AjaxService.withNotifications(messages).post(url, this.seriesData(series));
  }

  static update(loan, series) {
    const url = `/manage/loans/${loan.toParam}/series/${series.psId}`;
    const messages = {
      onSuccess: "Loan series updated.",
      onError: res => ({ message: get(res, "data.display_errors"), status: "error" }),
    };

    return PS.Services.AjaxService.withNotifications(messages).patch(url, this.seriesData(series));
  }

  static destroy(loan, series) {
    const url = `/manage/loans/${loan.toParam}/series/${series.psId}`;
    const messages = {
      onSuccess: "Loan series deleted.",
      onError: res => ({ message: get(res, "data.display_errors"), status: "error" }),
    };

    return PS.Services.AjaxService.withNotifications(messages).delete(url);
  }

  static seriesData(series) {
    return {
      amount: series.amount,
      funding_entity: series.fundingEntityId,
      start_date: series.startDate,
      end_date: series.endDate,
      ps_spread: series.psSpread,
      note: series.note,
      tranche: series.tranche,
      mdpn: series.mdpn,
      series_type: series.seriesType,
    };
  }
}

PS.Services.SeriesService = SeriesService;
