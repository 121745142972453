<template>
  <div v-if="show" :class="classes" role="alert">
    <slot />

    <button
      v-if="dismissible"
      type="button"
      class="close"
      aria-label="Close"
      @click="handleDismiss"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import includes from "lodash/includes";

const VARIANTS = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"];

export default {
  name: "base-alert",
  props: {
    /**
     * If `true`, then a close button will be rendered.
     * `dismissed` event will be triggered if button is clicked.
     */
    dismissible: Boolean,
    /**
     * Applies supplied variant to bootstrap alert.
     */
    variant: {
      type: String,
      default: "danger",
      validator: value => includes(VARIANTS, value),
    },
  },
  data: () => ({
    show: true,
  }),
  computed: {
    classes() {
      return {
        alert: true,
        [`alert-${this.variant}`]: true,
        "alert-dismissible": this.dismissible,
      };
    },
  },
  methods: {
    handleDismiss() {
      this.show = false;
      this.$emit("dismissed");
    },
  },
};
</script>
