<template>
  <div class="loan-progress-updates-table-container">
    <div class="details-row">
      <h4 class="section-title">Progress Updates</h4>
      <table class="table">
        <thead>
          <tr>
            <th class="progress-update-date">Date/Follow-up</th>
            <th class="progress-update-user">Analyst</th>
            <th class="progress-update-current-status">Current Status</th>
            <th class="progress-update-next-steps">Next Steps</th>
            <th class="progress-update-lender-next-steps">Lender Next Steps</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(progressUpdate, index) in displayedProgressUpdates" :key="index">
            <td class="progress-update-date">
              <p v-if="progressUpdate.shortDate">{{ progressUpdate.shortDate }}</p>
              <p v-if="progressUpdate.usTime">{{ progressUpdate.usTime }}</p>
              <p v-if="progressUpdate.followUpDate"><br/>Reminder: {{ progressUpdate.followUpDate }}</p>
            </td>
            <td class="progress-update-user"><img v-bind:src="userGravatarUrl(progressUpdate.user)" /></td>
            <td class="progress-update-current-status">{{ progressUpdate.currentStatus }}</td>
            <td class="progress-update-next-steps">{{ progressUpdate.nextSteps }}</td>
            <td class="progress-update-lender-next-steps">{{ progressUpdate.lenderNextSteps }}</td>
          </tr>
        </tbody>
      </table>
      <a v-if="!disabled" href="#" class="btn-action-cta" @click.prevent="openNewProgressUpdateModal">
        Add Update
      </a>
      <a class="view-type" v-show="showViewAllButton" v-on:click="viewAll">View All</a>
      <a class="view-type" v-show="showViewLessButton" v-on:click="viewLess">View Less</a>
      <NewProgressUpdateModal
        :endpoint="progressUpdatesRoute"
        :show="isNewProgressUpdateModalOpen"
        :last-progress-update="lastProgressUpdate"
        :loan-id="loanId"
        @close="closeNewProgressUpdateModal"
        @progress-update-saved="addCurrentProgressUpdate"
      />
    </div>
  </div>
</template>

<script>
import Ajax from "services/ajax_service";
import GravatarUrl from "utils/gravatar_url";
import NewProgressUpdateModal from "components/loan_progress_updates/new_modal";
import get from "lodash/get";

export default {
  name: "loan-progress-update",
  components: { NewProgressUpdateModal },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isNewProgressUpdateModalOpen: false,
      progressUpdatesRoute: get(PSData, "routes.loanProgressUpdates"),
      progressUpdates: [],
      displayedProgressUpdates: [],
      lastProgressUpdate: {},
      isViewAll: false,
      loanId: get(PSData, "loan.toParam"),
    };
  },
  created() {
    this.getProgressUpdates();
  },
  computed: {
    showViewAllButton() {
      return !this.isViewAll && this.progressUpdates.length > 1;
    },
    showViewLessButton() {
      return this.isViewAll && this.areProgressUpdatesPresent;
    },
    areProgressUpdatesPresent() {
      return this.progressUpdates.length > 0;
    },
  },
  methods: {
    openNewProgressUpdateModal() {
      this.isNewProgressUpdateModalOpen = true;
    },
    closeNewProgressUpdateModal() {
      this.isNewProgressUpdateModalOpen = false;
    },
    getProgressUpdates() {
      if (this.progressUpdatesRoute) {
        Ajax.get(this.progressUpdatesRoute).then(updates => {
          this.progressUpdates = updates;
          this.setDisplay();
          this.setLastProgressUpdate();
        });
      }
    },
    addCurrentProgressUpdate(progressUpdate) {
      this.progressUpdates.unshift(progressUpdate);
      this.setDisplay();
      this.setLastProgressUpdate();
    },
    setDisplay() {
      if (!this.areProgressUpdatesPresent) {
        this.displayedProgressUpdates = [{}];
      } else if (this.isViewAll) {
        this.displayedProgressUpdates = this.progressUpdates;
      } else {
        this.displayedProgressUpdates = this.progressUpdates.slice(0, 1);
      }
    },
    setLastProgressUpdate() {
      if (this.areProgressUpdatesPresent) {
        this.lastProgressUpdate = this.progressUpdates.slice(0, 1).pop();
      }
    },
    userGravatarUrl(user) {
      if (get(user, "emailHash")) {
        return GravatarUrl.small(user.emailHash);
      }
    },
    viewAll() {
      this.isViewAll = true;
      this.setDisplay();
    },
    viewLess() {
      this.isViewAll = false;
      this.setDisplay();
    },
  },
};
</script>
