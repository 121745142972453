export default class ValueFormatter {
  static formatInvalidValue(value, placeholder) {
    return value === undefined || value === null ? placeholder : value;
  }

  static formatYesNoValue(value) {
    if (typeof value !== "boolean") {
      return;
    }
    return value ? "Yes" : "No";
  }

  static formatPhoneNumber(value) {
    if (value) {
      var match = value.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
    }
    return value;
  }
}

window.PS.Models.ValueFormatter = ValueFormatter;
