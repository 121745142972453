<template>
  <div class="institution-sales-tracking-search-box">
    <input
      class="form-control"
      v-model="searchText"
      autocapitalize="off"
      autocomplete="off"
      autocorrect="off"
      placeholder="Search Loan ID"
      spellcheck="false"
      type="text"
      @click="displayResults"
      @focus="displayResults"
      @keydown.esc="hideResults"
    />
    <div
      class="institution-sales-tracking-search-box-overlay"
      v-if="showResults"
      @click="hideResults"
    >
    </div>
    <div
      class="institution-sales-tracking-search-box-results"
      v-if="showResults"
    >
      <h3>Batch <div v-show="isSearching" class="ps-loader ps-loader-small"></div></h3>
      <div class="institution-sales-tracking-search-box-empty" v-if="!hasSearchResults">
        No batches
      </div>
      <table class="table" v-if="hasSearchResults">
        <thead>
          <tr>
            <th>Buyer</th>
            <th>Batch ID</th>
            <th>Batch Status</th>
            <th>Loan Batch Status</th>
          </tr>
        </thead>
        <tbody>
          <search-row
            v-for="offering in offerings"
            :offering="offering"
            :key="offering.id"
          ></search-row>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import debounce from "utils/debounce";
import SearchRow from "./search_row";

export default {
  name: "institution-sales-tracking-search-box",
  components: {
    SearchRow,
  },
  props: {
    offerings: Array,
    isSearching: Boolean,
  },
  data() {
    return {
      searchText: "",
      showResults: false,
    };
  },
  methods: {
    displayResults() {
      this.showResults = true;
    },
    hideResults() {
      this.showResults = false;
    },
  },
  computed: {
    hasSearchResults() {
      return this.offerings && this.offerings.length > 0;
    },
  },
  watch: {
    searchText: debounce(function(text) {
      if (text.trim().length > 0) {
        this.$emit("search", text.trim());
      } else {
        this.searchText = "";
      }
    }, 350),
  },
};
</script>
