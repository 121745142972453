<template>
  <div class="btn-group dropdown-button">
    <button type="button" class="btn btn-outline-grey dropdown-toggle" id="dropdown-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <slot name="dropdown-name"></slot>
      <i class="fas fa-angle-down"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-right">
      <slot name="dropdown-content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "dropdown-button",
};
</script>
