<template>
  <base-field
    :label="input.name"
  >
    <div class="control file has-name" v-if="!readOnly">
      <label class="file-label">
        <input class="file-input" type="file" name="resume" @change="onFileSelect">
        <span class="file-cta">
          <span class="file-icon">
            <i class="fas fa-upload"></i>
          </span>
          <span class="file-label">
            Choose a file…
          </span>
        </span>
        <span class="file-name">
          {{ fileName }}
        </span>
      </label>
    </div>
    <iframe v-if="showPreview" :src="fileSrc" frameborder="0" width="100%" style="height: 500px;"></iframe>
  </base-field>
</template>

<script>
import Vue from "vue";

// import VariableComment from "../variable_comment";

import Input from "models/workflow_engine/input";

export default Vue.extend({
  name: "file-upload-input-component",
  components: {
    // VariableComment,
  },
  props: {
    input: {
      type: Input,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fileName() {
      return this.input.value.name || "No file selected";
    },
    fileSrc() {
      return `${location.origin}/${this.input.value.value}`;
    },
    showPreview() {
      return !!this.input.value.value;
    },
  },
  methods: {
    onFileSelect(event) {
      const file = event.target.files[0];

      if (file) {
        this.serializeFile(file).then(hash => {
          this.input.value = { name: file.name, encoded_file: hash };
        });
      } else {
        this.input.value = {};
      }
    },
    serializeFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
  },
});
</script>
