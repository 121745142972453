export const FUNDED_STATUS = ["Funded (disclosures not required)", "funded"];

export class DisclosureInfo {
  constructor(record = {}, options = {}) {
    this.toParam = record.toParam;
    this.update(record);
    this._rawOptions = options;
    this.lenderNoteStatusOptions = this.mapping(options.noteStatuses);
    this.brokeringLegalEntityOptions = options.brokeringLegalEntityOptions;
    this.lenderLegalEntities = options.lenderLegalEntities;
    this.psLegalEntities = options.psLegalEntities;
  }

  update(record) {
    this._raw = record;
    this.lenderNoteStatus = record.lenderNoteStatus;
    this.lenderEntityLegalEntityId = record.lenderEntityLegalEntityId;
    this.brokeringEntityId = record.brokeringEntityId;
    this.email = record.lender.email;
    this.phone = record.lender.phone;
  }

  mapping(options) {
    return options.reduce((mapping, option) => {
      if (option[1] === FUNDED_STATUS[1]) {
        mapping.push(FUNDED_STATUS);
      } else {
        mapping.push(option);
      }
      return mapping;
    }, []);
  }

  reset() {
    this.update(this._raw);
  }

  get phoneLabel() {
    if (this.isFio) {
      return `Broker Phone`;
    } else {
      return `Lender Phone`;
    }
  }

  get emailLabel() {
    if (this.isFio) {
      return `Broker Email`;
    } else {
      return `Lender Email`;
    }
  }

  get isFio() {
    return this.lenderNoteStatus === "funding_into_origination";
  }

  changed() {
    return (
      this.lenderNoteStatus !== this._raw.lenderNoteStatus ||
      this.lenderEntityLegalEntityId !== this._raw.lenderEntityLegalEntityId ||
      this.brokeringEntityId !== this._raw.brokeringEntityId ||
      this.email !== this._raw.lender.email ||
      this.phone !== this._raw.lender.phone
    );
  }

  toHash() {
    return {
      loan_id: this.toParam,
      lender_note_status: this.lenderNoteStatus,
      lender_entity_legal_entity_id: this.lenderEntityLegalEntityId,
      brokering_entity_id: this.brokeringEntityId,
      email: this.email,
      phone: this.phone,
    };
  }
}
