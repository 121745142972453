<template>
  <select :disabled="disabled">
    <slot></slot>
    <div ref="appended-dropdown" name="appended-dropdown">
      <slot name="appended-dropdown"></slot>
    </div>
  </select>
</template>
<script>
import $ from "jquery";

export default {
  props: {
    customOptions: {
      type: Object,
      default: () => {
        return {
          appendToDropdown: false,
          extraClasses: "",
        };
      },
    },
    options: {
      type: Object,
      default: function() {
        return {};
      },
    },
    value: undefined,
    disabled: Boolean,
  },
  watch: {
    value(value) {
      $(this.$el)
        .val(value)
        .trigger("change");

      this.$emit("on-value-change");
    },
    options() {
      this.destroySelect2();
      this.initSelect2();
    },
    customOptions({ extraClasses }, { extraClasses: oldExtraClasses }) {
      if (extraClasses === oldExtraClasses) {
        return;
      }

      $(this.$el)
        .removeClass(oldExtraClasses)
        .addClass(extraClasses);
    },
  },
  mounted() {
    this.initSelect2();
  },
  destroyed() {
    this.destroySelect2();
  },
  methods: {
    initSelect2() {
      const vm = this;

      $(this.$el)
        .select2(this.options)
        .on("select2:select", function() {
          vm.$emit("input", this.value);
        })
        .on("select2:open", e => {
          this.$emit("open", e);

          const dropdown = $(".select2-container--open .select2-dropdown");
          const dropdownResults = document.querySelector(".select2-results");

          if (dropdown) {
            dropdown.on("click", e => e.stopPropagation());
          }

          if (this.customOptions.appendToDropdown) {
            this.append(dropdownResults, this.$refs["appended-dropdown"]);
          }
        })
        .on("select2:close", e => this.$emit("close", e))
        .val(this.value)
        .trigger("change");
    },
    destroySelect2() {
      $(this.$el)
        .off()
        .select2("destroy")
        .empty();
    },
    append(parent, child) {
      parent.appendChild(child);

      // Trigger a dropdown close to prevent select2 scrollbar locking
      this.$refs["appended-dropdown"].addEventListener(
        "click",
        () => $(this.$el).select2("close"),
        false
      );
    },
    open() {
      $(this.$el).select2("open");
    },
  },
};
</script>
