<template>
  <form>
    <div>
      <div class="form-group control-wrapper"
          :class="{'has-error': errors.has('source_id')}">
        <label>Google Sheet Id</Label>
        <input
          v-validate="'required'"
          v-model="form.source_id"
          name="source_id"
          class="form-control" />
        <span class="error control-label" v-show="errors.has('source_id')">
          This field is required.
        </span>
      </div>
      <div class="form-group control-wrapper"
           :class="{'has-error': errors.has('source_tab_name')}">
        <label>Worksheet Name</Label>
        <input
          v-validate="'required'"
          v-model="form.source_tab_name"
          name="source_tab_name"
          class="form-control" />
        <span class="error control-label" v-show="errors.has('source_tab_name')">
            This field is required.
        </span>
      </div>
    </div>
    <div>
      <a class="btn btn-action-cta"
        :disabled="loading"
        @click="onSubmit">
        Import
      </a>
    </div>
  </form>
</template>

<script>
export default {
  name: "google-sheet-form",
  $_veeValidate: {
    validator: "new",
  },
  data() {
    return {
      form: {
        source_id: undefined,
        source_tab_name: "Sheet1",
      },
    };
  },
  props: {
    submitHandler: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSubmit() {
      this.$validator.validateAll().then(valid => {
        if (!valid) return;

        this.submitHandler(this.form);
      });
    },
  },
};
</script>
