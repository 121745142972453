import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";

class HotlistLoansEvent extends PS.Models.Event {}

export class Updated extends HotlistLoansEvent {
  get loanTaskAssigneeIds() {
    return this.options.loanTaskAssigneeIds;
  }

  get loans() {
    return this.options.loans;
  }

  get analysts() {
    return this.options.analysts;
  }

  get lenders() {
    return this.options.lenders;
  }

  get documents() {
    return this.options.documents;
  }

  get positions() {
    return this.options.positions;
  }

  get serverTime() {
    return this.options.serverTime;
  }
}

export class HotlistObjectChangeRequested extends HotlistLoansEvent {
  prepareData() {
    this.options.response = {};
  }

  set serverResponse(response) {
    this.options.response = response;
  }

  get lastUpdatedAt() {
    return this.options.lastUpdatedAt;
  }

  get loans() {
    return this.options.response.loans;
  }

  get loanTaskAssigneeIds() {
    return this.options.response.loanTaskAssigneeIds;
  }

  get underwritingStatus() {
    return snakeCase(this.options.underwritingStatus);
  }

  get clientUnderwritingStatus() {
    return camelCase(this.options.underwritingStatus);
  }
}

export class PartialUpdateRequested extends HotlistObjectChangeRequested {}

export class FilterLoansByAnalystRequested extends HotlistLoansEvent {
  get analystIds() {
    return this.options.analystIds;
  }
}

export class FilterLoansByTaskAssigneeRequested extends HotlistLoansEvent {
  get taskAssigneeIds() {
    return this.options.taskAssigneeIds;
  }
}

export class FilterLoansByPropertyTypeRequested extends HotlistLoansEvent {
  get propertyTypes() {
    return this.options.propertyTypes;
  }
}

export class FilterLoansByLoanProgramRequested extends HotlistLoansEvent {
  get loanPrograms() {
    return this.options.loanPrograms;
  }
}

export class FilterLoansByAccountTypeRequested extends HotlistLoansEvent {
  get accountTypes() {
    return this.options.accountTypes;
  }
}

export class FilterLoansByIntervalRequested extends HotlistObjectChangeRequested {}

PS.Models.Reports = PS.Models.Reports || {};
PS.Models.Reports.Events = PS.Models.Reports.Events || {};
PS.Models.Reports.Events.Hotlist = {
  Updated,
  FilterLoansByAnalystRequested,
  FilterLoansByPropertyTypeRequested,
  FilterLoansByIntervalRequested,
  FilterLoansByLoanProgramRequested,
  FilterLoansByAccountTypeRequested,
};
