<template>
  <modal-form
    :show="show"
    :save-disabled="uiIsBlocked"
    title="Followers"
    :save-button-name="submitCaption"
    class="loan-followers-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <form>
      <div class="row">
        <with-errors on="user_ids" :errors="errors" class="col-12 form-group">
          <label>Add/Remove Followers</label>
          <multi-select2
            ref="followers"
            v-model="selectedFollowerIds"
            :key="`selected-followers-${selectedFollowerIds.join('_')}`"
            :disabled="uiIsBlocked"
            :options="{placeholder: 'Follower'}"
            class="form-control"
          >
            <option
              v-for="follower in sortedFollowerCandidates"
              :value="follower.id"
              :key="`follower-${follower.id}`"
            >
              {{ follower.name }}
            </option>
          </multi-select2>
        </with-errors>
      </div>
      <div v-if="canAddLenderFollowers" class="row">
        <with-errors on="lender_ids" :errors="errors" class="col-12 form-group">
          <label>Add/Remove Lender Followers</label>
          <multi-select2
            ref="lenders"
            v-model="selectedLenderIds"
            :key="`selected-lenders-${selectedLenderIds.join('_')}`"
            :disabled="uiIsBlocked"
            :options="{placeholder: 'Follower'}"
            class="form-control"
          >
            <option
              v-for="follower in sortedLenderCandidates"
              :value="follower.id"
              :key="`lender-${follower.id}`"
            >
              {{ follower.name }}
            </option>
          </multi-select2>
        </with-errors>
      </div>
    </form>
    <slot v-if="!canAddLenderFollowers"></slot>
  </modal-form>
</template>

<script>
import orderBy from "lodash/orderBy";
import difference from "lodash/difference";
import AjaxService from "services/ajax_service";
import ModalForm from "components/modal_form";
import MultiSelect2 from "components/shared/multi_select2";
import WithErrors from "components/shared/with_errors";

function followerIds(followers) {
  return followers.map(follower => follower.id.toString());
}

export default {
  name: "loan-followers-modal",
  components: {
    ModalForm,
    MultiSelect2,
    WithErrors,
  },
  props: {
    routes: Object,
    followers: Array,
    followerCandidates: Array,
    show: Boolean,
    canAddLenderFollowers: Boolean,
    lenders: Array,
    lenderCandidates: Array,
  },
  data() {
    return {
      selectedFollowerIds: followerIds(this.followers),
      uiIsBlocked: false,
      errors: {},
      selectedLenderIds: followerIds(this.lenders),
    };
  },
  computed: {
    initiallySelectedFollowerIds() {
      return followerIds(this.followers);
    },
    sortedFollowerCandidates() {
      return orderBy(this.followerCandidates, "name");
    },
    submitCaption() {
      if (this.uiIsBlocked) {
        return "Saving...";
      } else {
        return "Save";
      }
    },
    initiallySelectedLenderIds() {
      return followerIds(this.lenders);
    },
    sortedLenderCandidates() {
      return orderBy(this.lenderCandidates, "name");
    },
    payload() {
      let payload = { user_ids: this.selectedFollowerIds };
      if (this.canAddLenderFollowers) {
        payload.lender_ids = this.selectedLenderIds;
      }
      return payload;
    },
  },
  watch: {
    followers(newValue) {
      this.selectedFollowerIds = followerIds(newValue);
    },
    lenders(newValue) {
      this.selectedLenderIds = followerIds(newValue);
    },
  },
  methods: {
    onClose() {
      this.close();
    },
    onSave() {
      this.save();
    },
    close() {
      this.selectedFollowerIds = this.initiallySelectedFollowerIds;
      this.selectedLenderIds = this.initiallySelectedLenderIds;
      this.errors = {};
      this.$emit("close");
    },
    save() {
      this.uiIsBlocked = true;

      AjaxService.patch(this.routes.loanFollowers, this.payload, {
        "Content-Type": "application/json",
      })
        .then(data => {
          this.$emit("on-save", data.loanFollowers);
          this.close();
        })
        .catch(error => {
          this.errors = error.data;
        })
        .finally(() => {
          this.uiIsBlocked = false;
        });
    },
  },
};
</script>
