import { LoanProduct } from "../enums";

export const PROGRAMS = {
  RESIDENTIAL_BRIDGE: LoanProduct.residentialBridge.value,
  RESIDENTIAL_RENTAL: LoanProduct.residentialRentalLongTerm.value,
};

export const RENTAL_RICING_LTV_INTERVALS = [60, 65, 70, 75, 80];
export const RENTAL_MAX_LOAN_AMOUNT = 2000000;
export const RENTAL_MIN_ASSET_VALUE = 100000;
export const RENTAL_MIN_CREDIT_SCORE = 660;
export const RENTAL_MIN_LOAN_AMOUNT = 55000;

export const BRIDGE_PRICING_LTV_INTERVALS = [50, 55, 60, 65, 70, 75];
export const BRIDGE_MAX_LOAN_AMOUNT = 1500000;
export const BRIDGE_MIN_ASSET_VALUE = 100000;
export const BRIDGE_MIN_CREDIT_SCORE = 650;
export const BRIDGE_MIN_LOAN_AMOUNT = 100000;

export function ltvIntervals(type) {
  switch (type) {
    case LoanProduct.residentialBridge.value:
      return BRIDGE_PRICING_LTV_INTERVALS;
    case LoanProduct.residentialRentalLongTerm.value:
      return RENTAL_RICING_LTV_INTERVALS;
    default:
      return [];
  }
}

export function maxLtvBaseLine(type) {
  let arr = ltvIntervals(type);

  return arr[arr.length - 1];
}

export function bestLtvBaseLine(type) {
  return ltvIntervals(type)[0];
}
