<template>
  <div class="loan-documents-editor">
    <h1>{{originalUserName}}</h1>

    <div class="form-group control-wrapper">
      <label for="document-type">Document Type</label>
      <select2
        id="document-type"
        ref="documentType"
        v-model="loanDocumentTypeId"
        class="form-control"
        :options="documentTypeOptions"
        @on-value-change="recalculateUserName"
        @open="onSelectOpen"
        @close="onSelectClose"
      />
    </div>

    <div class="form-group control-wrapper">
      <label for="designation">Designation (optional)</label>
      <multi-select2
        id="designation"
        v-model="designations"
        :options="designationOptions"
        :after-render="onDesignationsRendered"
        @on-value-change="recalculateUserName"
        @open="onSelectOpen"
        @close="onSelectClose"
      />
    </div>

    <div class="form-group control-wrapper">
      <label for="file-name">File Name</label>
      <textarea id="file-name" class="form-control" v-model="userName"></textarea>
    </div>

    <div class="action-btn-wrapper">
      <div>
        <button class="btn-action-cta-sm" @click="onSave" :disabled="saveDisabled">Save</button>
        <button class="btn-link" @click="onClose">Cancel</button>
      </div>
      <delete-button
        :document="document"
        v-show="!blockUI(document)"
        @beforeDelete="beforeDelete"
        @deleteFile="documentDeleted"
      >
        <template>
          <img alt="Delete file" :src="require('images/icons/icon_trash_alt.svg')" />
          <span>Delete</span>
        </template>
      </delete-button>
    </div>
  </div>
</template>
<script>
const ACTIVE_INPUTS_COUNT = 2;

import { isEscKey, isCtrlEnterKey } from "utils/hot_keys";
import Select2 from "components/shared/select2";
import MultiSelect2 from "components/shared/multi_select2";
import DeleteButton from "components/diligence_document/file_list_delete_button";
import { RequestOneDocumentUpdate } from "models/loan/commands/document_commands";
import { RequestDocumentRemoval } from "models/loan/commands/document_commands";
import { DocumentDeleted } from "models/loan/events/document_events";
import { OneDocumentUpdated } from "models/loan/events/document_events";
import xor from "lodash/xor";

export default {
  name: "loan-documents-editor",
  components: {
    DeleteButton,
    Select2,
    MultiSelect2,
  },
  props: {
    document: Object,
    blockedDocumentIds: Array,
    documentTypes: Array,
  },
  data() {
    return {
      loanDocumentTypeId: undefined,
      designations: [],
      documentDesignations: PSData.documentDesignations,
      userName: "",
      originalUserName: "",
      userTriggeredInput: ACTIVE_INPUTS_COUNT,
      firstLoading: true,
    };
  },
  watch: {
    document: {
      immediate: true,
      handler(document) {
        this.userTriggeredInput = ACTIVE_INPUTS_COUNT;
        this.loanDocumentTypeId = document.loanDocumentTypeId;
        this.designations = [...document.designations];
        this.userName = document.userName;
        this.originalUserName = document.fullName;

        if (this.firstLoading) {
          this.userTriggeredInput--;
          this.firstLoading = false;
        }

        this.$nextTick(() => {
          this.$refs.documentType.$el.focus();
        });
      },
    },
  },
  mounted() {
    this.addKeyUpHandler();
    this.addFocusHandler();
  },
  beforeDestroy() {
    this.removeKeyUpHandler();
    this.removeFocusHandler();
  },
  methods: {
    onSave() {
      this.$emit("on-save", this.updatedDocument);

      new RequestOneDocumentUpdate(
        new OneDocumentUpdated({ document: this.updatedDocument })
      ).execute();
    },
    onClose() {
      this.$emit("on-close", this.document);
    },
    onDesignationsRendered() {
      this.designations = [...this.document.designations];
    },
    recalculateUserName() {
      if (this.userTriggeredInput > 0) {
        this.userTriggeredInput--;
        return;
      }

      const docType = this.documentTypes.find(type => {
        return type.id === parseInt(this.loanDocumentTypeId);
      });

      if (!docType) {
        return;
      }

      if (this.designations.length > 0) {
        const designations = `(${this.designations.join(" - ")})`;
        this.userName = [docType.text, designations].join(" ");
      } else {
        this.userName = docType.text;
      }
    },
    beforeDelete() {
      this.$emit("on-before-delete", this.document);
    },
    documentDeleted() {
      this.$emit("on-document-deleted", this.document);
      new RequestDocumentRemoval(new DocumentDeleted({ document: this.document })).execute();
      this.onClose();
    },
    blockUI(document) {
      return this.blockedDocumentIds.includes(document.id);
    },
    addKeyUpHandler() {
      document.addEventListener("keyup", this.handleEscKey);
    },
    removeKeyUpHandler() {
      document.removeEventListener("keyup", this.handleEscKey);
    },
    addFocusHandler() {
      this.$el.addEventListener("focusin", this.handleFocus);
      this.$el.addEventListener("focusout", this.handleBlur);
    },
    removeFocusHandler() {
      this.$el.removeEventListener("focusin", this.handleFocus);
      this.$el.removeEventListener("focusout", this.handleBlur);
    },
    handleEscKey(e) {
      if (isEscKey(e) && this.document) {
        this.onClose();
      }
    },
    handleFocus(e) {
      e.target.addEventListener("keydown", this.handleEnterKey);
    },
    handleBlur(e) {
      e.target.removeEventListener("keydown", this.handleEnterKey);
    },
    handleEnterKey(e) {
      if (isCtrlEnterKey(e)) {
        this.preventDropdownOpen = true;
        this.closeDropdowns();

        const formHasFocus = this.$el.querySelector(":focus");

        if (!this.saveDisabled && formHasFocus) {
          this.onSave();
        }
      }
    },
    onSelectOpen() {
      if (this.preventDropdownOpen) {
        this.closeDropdowns();
      }

      this.removeKeyUpHandler();
    },
    onSelectClose() {
      this.preventDropdownOpen = false;

      if (isEscKey(window.event)) {
        document.addEventListener("keyup", this.addKeyUpHandler, {
          once: true,
        });
      } else {
        this.addKeyUpHandler();
      }
    },
    closeDropdowns() {
      $(this.$el)
        .find("select")
        .select2("close");
    },
  },
  computed: {
    documentTypeOptions() {
      return { data: this.orderedTypeCategories };
    },
    designationOptions() {
      return { data: this.documentDesignations };
    },
    formattedUsername() {
      return this.userName.trim();
    },
    orderedTypeCategories() {
      return this.documentTypes.sort((c1, c2) => {
        return c1.text.localeCompare(c2.text);
      });
    },
    saveDisabled() {
      return (
        this.blockedDocumentIds.includes(this.document.id) ||
        this.loanDocumentTypeId === undefined ||
        this.documentHasNotChanged ||
        this.formattedUsername.length === 0
      );
    },
    updatedDocument() {
      return {
        id: this.document.id,
        loanDocumentTypeId: this.loanDocumentTypeId,
        designations: [...this.designations],
        userName: this.formattedUsername,
      };
    },
    documentHasNotChanged() {
      return (
        parseInt(this.loanDocumentTypeId) === this.document.loanDocumentTypeId &&
        xor(this.designations, this.document.designations).length === 0 &&
        this.formattedUsername === this.document.userName.trim()
      );
    },
  },
};
</script>
