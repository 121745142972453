<template>
  <div v-if="!taskMapping.useGlobalDescription">
    <b-collapse
      v-for="(rule, index) of rules"
      :key="rule.id"
      class="card description-preview"
      animation="slide"
      :open="openedRule == index"
      @open="openedRule = index"
    >
      <template #trigger="props">
        <div
          class="card-header description-preview-header px-2"
          role="button"
        >
          <base-icon v-if="props.open" icon="chevron-down" />
          <base-icon v-else icon="chevron-up" />
          <div class="description-preview-title">
            {{ ruleTitle(rule, index) }}
          </div>
          <div v-if="isDefaultRule(rule)" class="description-preview-header-note">
            Default will be displayed if no conditions are set or satisfied.
          </div>
        </div>
      </template>
      <div>
        <rules-preview
          class="description-preview-rules"
          :rules="rule.conditions"
          :disabled="!editable"
          @change="rule => onRulesChange(rule, index)"
          :filter-by-context="taskMapping.contextName"
        />
        <base-hr class="m-0" />
        <slot name="content" :rule="rule">
          <div
            class="content description-preview-content"
            v-html="ruleDescription(rule)"
          />
        </slot>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import LoanTaskDefiniton from "models/loan_task_definition";
import RulesPreview from "../rules/preview";

export default {
  components: {
    RulesPreview,
  },
  props: {
    taskMapping: {
      type: Object,
      default: () => ({}),
    },
    showConditions: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      openedRule: null,
      //descriptionTriggerRules: {},
    };
  },
  computed: {
    definition() {
      return new LoanTaskDefiniton(this.taskMapping && this.taskMapping.taskDefinition);
    },
    defaultRule() {
      return this.taskMapping.taskDescriptionRules.find(rule => rule.defaultDescription);
    },
    customRules() {
      return this.taskMapping.taskDescriptionRules.filter(rule => !rule.defaultDescription);
    },
    rules() {
      return [this.defaultRule, ...this.customRules];
    },
  },
  methods: {
    isDefaultRule(rule) {
      return !Object.keys(rule.conditions).length;
    },
    ruleTitle(rule, index) {
      return this.isDefaultRule(rule) ? "Default description" : `Conditional description ${index}`;
    },
    ruleDescription(rule) {
      return rule.customDescription || this.definition.defaultDescription;
    },
    onRulesChange(rule, index) {
      this.$parent.$emit("updateDescriptionRules", rule, index);
    },
  },
};
</script>
<style scoped>
.description-preview {
  --rules-space: calc(var(--space-unit) * 1.5);
  border-bottom-width: 0;
  border-radius: 0;
  box-shadow: none;
}

.description-preview:last-child {
  border-bottom-width: 1px;
}

.description-preview.collapse:not(.show) {
  display: block;
}

.description-preview-content,
.description-preview-rules {
  padding: var(--rules-space);
}

.description-preview-header {
  border-bottom-width: 0;
}

.description-preview-title {
  font-weight: 600;
  padding-left: var(--space-unit);
}

.description-preview-header-note {
  align-self: center;
  flex: 1;
  font-size: var(--font-size-sm);
  text-align: right;
}
</style>
