<template>
  <div>
    <div class="creditbox-settings-header">
      Creditbox Settings
    </div>
    <div class="row">
      <div class="col-6">
        <table class="table creditbox-settings-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Loan Program</th>
              <th class="timestamps">
                Created At
              </th>
              <th class="timestamps">
                Updated At
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="setting in creditboxSettings" :key="setting.id">
              <td>{{ setting.id }}</td>
              <td>{{ setting.loanProgramLabel }}</td>
              <td>{{ setting.createdAt }}</td>
              <td>{{ setting.updatedAt }}</td>
              <td class="actions">
                <i class="fas fa-pencil-alt" @click="editSetting(setting)" />
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <button class="btn btn-action-cta-sm" @click="$emit('add-new-config')">
            + Add New Config
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "creditbox-settings-list-table",
  props: {
    creditboxSettings: {
      type: Array,
      required: true,
    },
  },
  methods: {
    editSetting(setting) {
      this.$emit("on-setting-edit", setting);
    },
  },
};
</script>
