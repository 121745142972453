<template>
  <div class="tasklist-rule-operator">
    <base-field v-if="true" class="condition-logic-operator">
      <div disabled class="input">
        {{ rule.logicOperator }}
      </div>
    </base-field>
    <base-select-field
      v-else
      v-model="rule.logicOperator"
      :options="operatorOptions"
      rules="required"
      class="condition-logic-operator"
    />
  </div>
</template>
<script>
import { LOGIC_OPERATORS } from "./definitions_mapping";

export default {
  props: {
    rule: {
      type: Object,
      default: () => ({ logicOperator: "AND" }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    operatorOptions() {
      return Object.keys(LOGIC_OPERATORS).map(operatorKey => [
        LOGIC_OPERATORS[operatorKey],
        operatorKey,
      ]);
    },
  },
};
</script>
<style scoped>
.tasklist-rule-operator .condition-logic-operator {
  padding: calc(var(--space-unit) * 1.5) 0;
  position: relative;
  width: 90px;
}

.condition-logic-operator:before,
.condition-logic-operator:after {
  background: var(--color-grey-16);
  content: "";
  display: block;
  height: calc(var(--space-unit) * 1.5);
  left: 50%;
  margin-left: -2px;
  position: absolute;
  width: 4px;
}

.condition-logic-operator:before {
  top: 0;
}

.condition-logic-operator:after {
  bottom: 0;
}
</style>
