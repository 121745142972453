<template>
  <div>
    <breadcrumb-navigation :items="breadcrumbItems" />

    <h3 class="loan-property-header clearfix">
      {{ title }}

      <button class="btn btn-action-modal float-right" @click="onSave" :disabled="saveDisabled">Save</button>
      <button class="btn btn-cancel-modal float-right" @click="onCancel">Cancel</button>
    </h3>

    <form>
      <view-panel class="loan-details-view-panel">
        <template slot="header">Property Details</template>

        <div class="row loan-details-view-panel-row">
          <div class="col-6 loan-details-view-panel-column">
            <form-select label="Property Type" field="propertyType" v-model="fields.propertyType" :options="property.propertyTypes" :errors="errors" />
            <form-input label="Street Address" field="street" v-model="fields.street" :errors="errors" placeholder="" ref="propertyAddress" />
            <form-input label="City" field="city" v-model="fields.city" :errors="errors" />
            <form-select label="State" field="state" v-model="fields.state" :options="property.states" :errors="errors" />
            <form-input label="ZIP Code" field="postalCode" v-model="fields.postalCode" mask="zip" :errors="errors" />
            <form-input label="# Stories" field="stories" v-model="fields.stories" mask="positive-integer" :errors="errors" />
            <form-input label="County" field="county" v-model="fields.county" :errors="errors" />
            <form-input label="APN" field="apn" v-model="fields.apn" :errors="errors" />

            <template v-if="typeCommercialOrMultifamily">
              <form-input label="# Buildings" field="buildings" v-model="fields.buildings" mask="positive-integer" :errors="errors" />
              <form-select label="Tenancy" field="tenancy" v-model="fields.tenancy" :options="property.tenancies" :errors="errors" />
              <form-input label="NRA" field="nra" v-model="fields.nra" mask="positive-integer" :errors="errors" />
              <form-input label="Occupancy %" field="occupancyPercentage" v-model="fields.occupancyPercentage" mask="percentage" :errors="errors" />
            </template>

            <base-text-field
              v-model="fields.legalDescription"
              horizontal="horizontal"
              label="Legal Description"
              type="textarea"
            />
          </div>
          <div class="col-6 loan-details-view-panel-column">
            <form-input label="Bed" field="beds" v-model="fields.beds" mask="positive-integer" :errors="errors" />
            <form-input label="Bath" field="baths" v-model="fields.baths" mask="positive-number" :errors="errors" />
            <form-input label="Square Feet" field="squareFeet" v-model="fields.squareFeet" mask="positive-integer" :errors="errors" />
            <form-input label="Lot Acres" field="lotAcres" v-model="fields.lotAcres" mask="positive-number" :errors="errors" />
            <form-input label="Year Built" field="yearBuilt" v-model="fields.yearBuilt" mask="year" :errors="errors" />
            <form-input label="Condition" field="condition" v-model="fields.condition" :errors="errors" />
            <form-input label="HOA($)" field="monthlyHoaDues" v-model="fields.monthlyHoaDues" mask="positive-number" :errors="errors" />
            <form-input label="Previous Transaction Sale Price($)" field="exitSalePrice" v-model="fields.exitSalePrice" mask="positive-number" :errors="errors" />
            <form-date-input label="Previous Transaction Date" field="soldDate" v-model="fields.soldDate" :errors="errors" />
            <form-input label="Current Transaction Purchase Price($)" field="purchasePrice" v-model="fields.purchasePrice" mask="positive-number" :errors="errors" />
            <template v-if="typeMultifamily">
              <form-select label="Ground Lease" field="groundLease" v-model="fields.groundLease" :options="property.yesNoOptions" :errors="errors" />
              <form-select label="Environmental Issues Identified" field="environmentalIssuesIdentified" v-model="fields.environmentalIssuesIdentified" :options="property.yesNoOptions" :errors="errors" />
            </template>
          </div>
        </div>
      </view-panel>
    </form>
  </div>
</template>

<script>
import Property from "models/lender_form/property";
import AjaxService from "services/ajax_service";
import PropertyDetailsClient from "components/loan_scenarios/api/property_details_client";
import ViewPanel from "components/view_panel.vue";
import BreadcrumbNavigation from "components/shared/breadcrumb_navigation";
import FormInput from "components/shared/form/form_input";
import FormDateInput from "components/shared/form/form_date_input";
import FormSelect from "components/shared/form/form_select";
import PropertyMixin from "./property_mixin";
import debounce from "utils/debounce";
import GoogleAutocompleteMixin from "mixins/google_autocomplete_mixin";

export default {
  name: "loan-property-form",
  components: {
    BreadcrumbNavigation,
    ViewPanel,
    FormInput,
    FormDateInput,
    FormSelect,
  },
  mixins: [PropertyMixin, GoogleAutocompleteMixin],
  props: {
    property: {
      type: Property,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      routes: PSData.routes || {},
      saveDisabled: false,
      propertyDetailsClient: new PropertyDetailsClient(),
      promiseGetPropertyDetails: null,
    };
  },
  watch: {
    "fields.street"() {
      this.requestPropertyDetails();
    },
    "fields.city"() {
      this.requestPropertyDetails();
    },
    "fields.state"() {
      this.requestPropertyDetails();
    },
    "fields.postalCode"() {
      this.requestPropertyDetails();
    },
  },
  mounted() {
    this.initializeAutocomplete();
  },
  computed: {
    fields() {
      return this.property.data;
    },
    isNew() {
      return !this.fields.id;
    },
    title() {
      return this.isNew ? "New Property" : this.property.fullAddressCalculated;
    },
    breadcrumbItems() {
      return [
        {
          name: "Details",
          link: this.routes.loan,
        },
        {
          name: "Properties",
          link: this.routes.properties,
        },
        {
          name: this.title,
        },
      ];
    },
  },
  methods: {
    onCancel() {
      this.errors = {};
      this.$emit("on-cancel");
    },
    onSave() {
      this.save();
    },
    save() {
      this.saveDisabled = true;
      this.errors = {};

      return this.saveProperty()
        .then(data => {
          this.$emit("on-save", data);
        })
        .finally(() => {
          this.saveDisabled = false;
        });
    },
    async saveProperty() {
      await this.promiseGetPropertyDetails;

      const messages = { onSuccess: "Property is saved", onError: this.onError };

      const requestMethod = this.isNew ? "post" : "patch";

      return AjaxService.withNotifications(messages)[requestMethod](
        this.routes.property,
        this.property.objectToSave
      );
    },
    requestPropertyDetails: debounce(function() {
      this.getPropertyDetails();
    }, 350),
    async getPropertyDetails() {
      const { street: address, city, state, postalCode: zipCode } = this.fields;
      this.promiseGetPropertyDetails = this.propertyDetailsClient.fetchDetails({
        address,
        city,
        state,
        zipCode,
      });
      const { cbsaType, cbsaCode, cbsaName } = await this.promiseGetPropertyDetails;
      this.promiseGetPropertyDetails = null;
      Object.assign(this.fields, { cbsaType, cbsaCode, cbsaName });
    },
    onError({ data }) {
      this.errors = data.errors || {};
      return null;
    },
    initializeAutocomplete() {
      if (!this.googleAutocomplete.autocomplete) {
        this.$nextTick(() => this.attachAutocompleteToElement(this.$refs.propertyAddress.$el));
      }
    },
    handleAutocompleteSelected(newAddress) {
      this.fields.street = newAddress.fullStreetName;
      this.fields.city = newAddress.city;
      this.fields.state = newAddress.state;
      this.fields.postalCode = newAddress.zipCode;
    },
  },
};
</script>
