<template>
  <div class="template-revision-status-item">
    <div class="template-revision-status-label">
      {{ label }}
    </div>
    <div class="template-revision-status-value">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.template-revision-status-item {
  color: var(--color-grey-86);
}

.template-revision-status-label {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

.template-revision-status-value {
  font-weight: 600;
}
</style>
