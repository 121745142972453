import CopyToClipboard from "components/copy_to_clipboard";
import LenderTagsMixin from "../../mixins/manage/lenders/tags";
import ActivityFeedEventsSection from "components/activity_feed_events/manage/section";
import LoanRequestsHistory from "components/loans/loan_requests_history";
import PiecesOfCollateral from "components/tags/pieces_of_collateral";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-loan-tab-controller"),
    components: {
      ActivityFeedEventsSection,
      CopyToClipboard,
      LoanRequestsHistory,
      PiecesOfCollateral,
    },
    mixins: [LenderTagsMixin],
    data: function() {
      return {
        loan: PSData.loan || {},
        payoffRequests: PSData.payoffRequests || [],
        loanRequestsPath: (PSData.routes || {}).loanRequestsPath,
      };
    },
  });
});
