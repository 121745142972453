<template>
  <div>
    <h4 class="section-title">Pricing Check Evaluation</h4>

    <ul v-if="hasIssues" >
      <li v-for="issue in issues" :class=displayClass(issue)>
        <div>
          {{issue.explanation}}
        </div>
      </li>
    </ul>

    <div v-else class="pricing-success">
      <span class="check"></span>
      <p>Fits Pricing Guidelines</p>
    </div>
  </div>
</template>

<script>
import {
  prepareRules,
  uniqResults,
  hasIssues,
  uniqIssues,
} from "models/credit_box/credit_box_rules";

export default {
  name: "pricing-evaluation",
  props: {
    pricingResults: Array,
  },
  computed: {
    results() {
      return uniqResults(prepareRules(this.pricingResults));
    },
    hasIssues() {
      return hasIssues(this.results);
    },
    issues() {
      return uniqIssues(this.results);
    },
  },
  methods: {
    displayClass(issue) {
      return issue.explanationType === "critical"
        ? "pricing-evaluation-critical"
        : "pricing-evaluation";
    },
  },
};
</script>
