<template>
  <div class="view-panel-row">
    <view-panel>
      <template slot="header">
        {{ panelTitle }}
      </template>
      <table class="view-table">
        <thead>
          <tr>
            <th>License Type</th>
            <th>License Name</th>
            <th>Name of Licensee</th>
            <th>License #</th>
            <th>Start Date</th>
            <th>Expiration Date</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="license in licenses" :key="license.id">
            <td class="view-table-cell-label">
              {{ license.licenseTypeText }}
            </td>
            <td class="view-table-cell-text">
              {{ license.licenseNameText }}
            </td>
            <td class="view-table-cell-text">
              {{ license.licenseeName }}
            </td>
            <td class="view-table-cell-text">
              {{ license.licenseNumber }}
            </td>
            <td class="view-table-cell-label">
              {{ license.startDate }}
            </td>
            <td class="view-table-cell-label">
              {{ license.expirationDate }}
            </td>
            <td class="view-table-cell-label">
              {{ license.statusText }}
            </td>
            <td v-if="canManage" class="actions">
              <a href="#" title="Edit signer" @click.prevent="editLicense(license)">
                <i class="fas fa-pencil-alt"/>
              </a>
              <a href="#" title="Delete signer" @click.prevent="deleteLicense(license)">
                <i class="fas fa-trash-alt"/>
              </a>
            </td>
          </tr>
        </tbody>

        <tfoot v-if="canManage">
          <tr>
            <td colspan="8">
              <a href="#" title="Add License" @click.prevent="addLicense">
                + Add License
              </a>
            </td>
          </tr>
        </tfoot>
      </table>
    </view-panel>
    <license-form-modal
      v-if="showUpsertLicenseModal"
      :license="selectedLicense"
      :routes="routes"
      :show="showUpsertLicenseModal"
      :state="state"
      :options="licenseOptions"
      :on-close="resetSelectedLicense"
      @on-save="onLicenseSave"
    />
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import WindowService from "services/window_service";
import License from "models/lender_legal_entities/license";
import ViewPanel from "components/view_panel";
import LicenseFormModal from "./form_modal";

export default {
  name: "lender-legal-entities-license-panel",
  components: { ViewPanel, LicenseFormModal },
  props: {
    state: {
      type: Object,
      required: true,
    },
    routes: {
      type: Object,
      required: true,
    },
    policy: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      licenses: [],
      licenseOptions: { statuses: [], licenseTypes: [], licenseNames: [] },
      selectedLicense: undefined,
    };
  },
  computed: {
    showUpsertLicenseModal() {
      return !!this.selectedLicense;
    },
    panelTitle() {
      return `${this.state.stateLabel} License Details`;
    },
    canManage() {
      return this.policy.canManage;
    },
  },
  mounted() {
    AjaxService.getJSON(this.routes.licenses).then(data => this.updateLicenses(data));
    AjaxService.getJSON(this.routes.options).then(data => (this.licenseOptions = data));
  },
  methods: {
    addLicense() {
      this.selectedLicense = License.nullLicense();
    },
    editLicense(license) {
      this.selectedLicense = license.clone();
    },
    deleteLicense(license) {
      if (!WindowService.confirm("Are you sure you want to remove this license?")) {
        return;
      }

      const messages = {
        onSuccess: "License has been deleted",
        onError: ({ response }) => {
          if (response.status === 403) {
            return { message: "Unauthorized", status: "error" };
          } else {
            return { message: "Something went wrong", status: "error" };
          }
        },
      };

      AjaxService.withNotifications(messages)
        .delete(`${this.routes.licenses}/${license.id}`)
        .then(data => this.updateLicenses(data));
    },
    resetSelectedLicense() {
      this.selectedLicense = undefined;
    },
    onLicenseSave(licenses) {
      this.updateLicenses(licenses);
      this.resetSelectedLicense();
    },
    updateLicenses(licenses) {
      this.licenses = License.initLicenses(licenses);
    },
  },
};
</script>
