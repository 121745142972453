import ActivityFeedEventsSection from "components/activity_feed_events/manage/section";
import ManageSettlementStatementIndexController from "controllers/manage/settlement_statement/index_controller";
import CopyToClipboard from "components/copy_to_clipboard";
import LenderTagsMixin from "../../mixins/manage/lenders/tags";
import ReserveDrawRequestIndex from "components/manage/reserve_draw_request/index";
import RepurchaseStatementIndex from "components/manage/payoff_statement/repurchase/index";
import ResaleStatementIndex from "components/manage/payoff_statement/resale/index";
import SegmentedControl from "components/segmented_control";
import LoanFeesTracker from "components/manage/loan_fees_tracker";
import ChargeOffStatementIndex from "components/manage/charge_off_statement/index";
import LoanBalanceIndex from "components/manage/servicing/loan_balance/index";
import PayoffIndex from "components/manage/servicing/payoff/index";
import PiecesOfCollateral from "components/tags/pieces_of_collateral";

const UNEDITABLE_UNDERWRITING_STATUSES = Object.freeze(["canceled", "purchased", "rejected"]);

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-servicing-controller"),
    components: {
      "activity-feed-events-section": ActivityFeedEventsSection,
      "manage-settlement-statement-index-controller": ManageSettlementStatementIndexController,
      CopyToClipboard,
      ReserveDrawRequestIndex,
      RepurchaseStatementIndex,
      ResaleStatementIndex,
      ChargeOffStatementIndex,
      SegmentedControl,
      LoanFeesTracker,
      LoanBalanceIndex,
      PayoffIndex,
      PiecesOfCollateral,
    },
    mixins: [LenderTagsMixin],
    data: function() {
      return {
        loan: PSData.loan || {},
        events: PSData.events || {},
        routes: PSData.routes || {},
        servicingOptions: PSData.servicingOptions || {},
        canUpdateServicing: null,
        canUpdateServicingFees: null,
        showServicingEditButton: null,
        showEditForm: null,
        messageBus: window.PS.Services.MessageBusService.servicingBus,
        options: [{ name: "general", label: "General" }, { name: "fees", label: "Fees" }],
        activeOption: "general",
      };
    },
    methods: {
      edit() {
        this.showServicingEditButton = false;
        this.showEditForm = true;
      },
      cancel() {
        this.showServicingEditButton = true;
        this.showEditForm = false;
      },
      changeCurrentTab(tab) {
        this.activeOption = tab;
      },
    },
    mounted: function() {
      const formPolicy = new PS.Policies.EditLoanFormPolicy(PSData.loanPolicy);
      const underwritingStatus = PSData.loan.underwritingStatus;
      this.canUpdateServicing = formPolicy.canUpdateServicing();
      this.canUpdateServicingFees = formPolicy.canUpdateServicingFees();
      this.showServicingEditButton = !UNEDITABLE_UNDERWRITING_STATUSES.includes(underwritingStatus);
      this.showEditForm = formPolicy.canUpdate();
    },
    watch: {
      showEditForm(value) {
        this.messageBus.broadcast({ name: "servicingShowEditForm", value: value });
      },
    },
  });
});
