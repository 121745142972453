export default class SyncFormParams {
  static prepare(state) {
    const fieldsets = state.form.fields
      .filter(field => {
        return field.type == "multipleFieldset";
      })
      .map(set => {
        return set.fields.reduce((mem, currentField) => {
          mem[currentField.name] = this.value(currentField);
          return mem;
        }, {});
      });

    const fields = state.form.fields
      .filter(field => !["multipleFieldset", "fieldset"].includes(field.type))
      .reduce((mem, currentField) => {
        mem[currentField.name] = this.value(currentField);
        return mem;
      }, {});

    return {
      fieldsets: fieldsets,
      fields: fields,
    };
  }

  static value(field) {
    if (["yesNo", "radio"].includes(field.type)) {
      return field.value == "1";
    } else {
      return field.value;
    }
  }
}
