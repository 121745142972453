<template>
  <base-tab-item :label="tabName">
    <template v-slot:content>
      <creditbox
        v-if="!emptyCreditbox"
        :issues="creditboxIssues"
        :adjustments="creditboxAdjustments"
      />
      <div class="columns cb-columns">
        <div class="column">
          <h6 class="table-name">Rates</h6>
          <component
            :is="currentRatesComponent"
            :scenario="scenario"
            :constraints="constraints"
            :price-results="priceResults"
            :converted-scenario="convertedScenario"
            :loan-creation-in-progress="loanCreationInProgress"
            v-on="$listeners"
          />
        </div>
      </div>
    </template>
  </base-tab-item>
</template>

<script>
import Creditbox from "../creditbox/index";
import { LoanProduct } from "../enums";
import { ResidentialBridgeRates, ResidentialForRentRates } from "../rates";

const TAB_NAME = `Credit Box Advisor`;

export default {
  name: "creditbox-tab",
  components: { Creditbox },
  inject: ["locale"],
  props: {
    creditboxResults: Object,
    priceResults: Array,
    constraints: Object,
    scenario: Object,
    convertedScenario: Object,
    loanCreationInProgress: Boolean,
  },
  data() {
    return {
      LoanProduct,
      currentRatesComponent: this.ratesComponent(this.scenario.loanProduct),
    };
  },
  watch: {
    "scenario.loanProduct": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.currentRatesComponent = this.ratesComponent(newVal);
        }
      },
    },
  },
  methods: {
    isEmptyResults(obj) {
      return isEmpty(obj) || (obj.results && obj.results.length === 0);
    },
    ratesComponent(loanProduct) {
      switch (loanProduct) {
        case LoanProduct.residentialBridge.value:
          return ResidentialBridgeRates;
        case LoanProduct.residentialRentalLongTerm.value:
          return ResidentialForRentRates;
      }
    },
  },
  computed: {
    tabName() {
      if (this.emptyCreditbox) {
        return TAB_NAME;
      } else {
        const total =
          this.creditboxIssues.length > 0
            ? this.creditboxIssues.length + this.creditboxAdjustments.length
            : 0;
        const count = total > 0 ? ` (${total})` : ``;

        return TAB_NAME + count;
      }
    },
    emptyCreditbox() {
      return !this.creditboxResults.isValid;
    },
    creditboxIssues() {
      return this.creditboxResults.errors || [];
    },
    creditboxAdjustments() {
      return this.creditboxResults.warnings || [];
    },
  },
};
</script>

<style scoped>
.scenario-tabs >>> .tabs li a,
.scenario-tabs >>> .tabs li.is-active a,
.scenario-tabs >>> .tabs li a:not([href]):not([tabindex]),
.scenario-tabs >>> .tabs li a:not([href]):not([tabindex]):hover {
  color: var(--color-grey-38);
  font-size: var(--font-size-md);
}

.scenario-tabs >>> .tabs li.is-active a,
.scenario-tabs >>> .tabs li.is-active a:not([href]):not([tabindex]),
.scenario-tabs >>> .tabs li.is-active a:not([href]):not([tabindex]):hover {
  color: var(--color-grey-86);
}

.scenario-tabs >>> .tabs ul {
  border-bottom: 1px solid var(--color-grey-16);
}

.scenario-tabs >>> .tab-content {
  min-height: 90px;
  padding-left: 0;
  padding-right: 0;
}

.scenario-tabs .table-name {
  color: var(--color-grey-86);
  font-size: var(--font-size);
  font-weight: 600;
  margin-bottom: 20px;
}

::v-deep .cb-columns .custom-row-link {
  font-weight: 400;
  margin-left: 16px;
}

::v-deep .custom-scenarios {
  padding-bottom: 16px;
}

::v-deep .custom-scenarios .table td {
  position: relative;
}

::v-deep .custom-scenarios .table tr.disabled-scenario td {
  color: var(--color-grey-38);
}

::v-deep .custom-scenarios .table th,
::v-deep .custom-scenarios .table .cb-flag {
  background-color: var(--color-grey-06);
}

::v-deep .cb-columns .custom-scenarios .table th,
::v-deep .cb-columns .custom-scenarios .table td {
  padding: 16px 8px;
}

::v-deep .custom-scenarios .table .custom-row td {
  padding: 22px 12px;
  line-height: 40px;
}

::v-deep .custom-scenarios .table .custom-row .base-field {
  padding-bottom: 0;
}

::v-deep .custom-scenarios .table #custom-loan-amount {
  width: 150px;
}

::v-deep .custom-scenarios .table .hidden-row {
  display: none;
}

::v-deep .custom-scenarios .table td span.flag {
  color: #52a329;
  font-family: var(--font-family);
  font-size: var(--font-size-xs);
  font-weight: 600;
  line-height: 9px;
  min-width: 75px;
  position: absolute;
  text-transform: uppercase;
  top: 6px;
}

@media (max-width: 600px) {
  ::v-deep .custom-scenarios .table td span.flag {
    font-size: 9px;
  }
}

::v-deep .custom-scenarios .table .ltv-cell {
  min-width: 130px;
  max-width: 140px;
  width: auto;
}

::v-deep .custom-scenarios .table .cb-flag {
  border-radius: var(--border-radius);
  display: block;
  height: 18px;
  width: 100%;
}

::v-deep .custom-scenarios .table .custom-row .cb-flag {
  margin-top: 10px;
}

::v-deep .custom-scenarios .table .cb-flag.fit-label::after {
  background-color: var(--color-grey-06);
  color: var(--color-grey-86);
  content: "NOT A CREDIT BOX FIT";
  font-size: var(--font-size-xs);
  display: inline-block;
  height: 18px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  width: fit-content;
  z-index: 1;
}

::v-deep .custom-scenarios .table tr.is-selected {
  background-color: var(--color-grey-06);
  color: var(--color-grey-86);
}

::v-deep .custom-scenarios .table .select-scenario-label {
  align-items: center;
  background: var(--color-white);
  border: 1px solid var(--color-grey-16);
  box-sizing: content-box;
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-sm);
  font-weight: 600;
  justify-content: center;
  height: 24px;
  line-height: var(--line-height-sm);
  width: 65px;
}

::v-deep .custom-scenarios .table .select-scenario-label.disabled {
  border-color: var(--color-grey-16);
  color: var(--color-grey-16);
  cursor: not-allowed;
}

::v-deep .custom-scenarios .table .is-selected .select-scenario-label,
::v-deep .custom-scenarios .table .is-selected .select-scenario-label.disabled {
  background-color: var(--color-blue-8);
  border-color: var(--color-blue);
  color: var(--color-blue);
}

::v-deep .custom-scenarios .table .custom-row .select-scenario-label {
  margin-top: 7px;
}
</style>
