<template>
  <div class="reviews-list">
    <div class="reviews-list-item" v-for="review in reviews" :key="review.message">
      <div class="reviews-list-error" v-if="review.type === 'error'"><i class="fas fa-times-circle"></i></div>
      <div class="reviews-list-warning" v-if="review.type === 'warning'"><i class="fas fa-exclamation-triangle"></i></div>
      <div v-if="review.addtlInfo">
        <div v-popover="{placement: 'top'}">{{review.message}}</div>
        <div class="hidden">{{ review.addtlInfo }}</div>
      </div>
      <div v-else>{{review.message}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    reviews: Array,
  },
};
</script>
