import selectOptionsToMapping from "utils/select_options_to_mapping";
import ACHDetails from "models/lender_legal_entities/ach_details";

export default class LegalEntity {
  constructor(entity = {}, options = {}) {
    this._id = entity.id;
    this._lastEditedAt = entity.lastEditedAt;
    this.update(entity);
    this._optionsRaw = options;
    this._entityTypes = selectOptionsToMapping(options.entityTypes || []);
    this._states = selectOptionsToMapping(options.states || []);
    this._achDetails = new ACHDetails(entity, options);
  }

  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
  }

  get entityType() {
    return this._entityType || "";
  }

  set entityType(entityType) {
    this._entityType = entityType;
  }

  get lastEditedAt() {
    return this._lastEditedAt;
  }

  set lastEditedAt(lastEditedAt) {
    this._lastEditedAt = lastEditedAt;
  }

  get entityTypeLabel() {
    return this._entityTypes[this._entityType];
  }

  get isEntityTypeIndividual() {
    return this._entityType === "individual";
  }

  get state() {
    if (this.isEntityTypeIndividual) {
      return "";
    }

    return this._state || "";
  }

  set state(state) {
    this._state = state;
  }

  get stateLabel() {
    return this._states[this.state];
  }

  get vestingClause() {
    return this._vestingClause;
  }

  set vestingClause(vestingClause) {
    this._vestingClause = vestingClause;
  }

  get fullName() {
    return this._fullName;
  }

  set fullName(fullName) {
    this._fullName = fullName;
  }

  get entityTypes() {
    return this._entityTypes;
  }

  get states() {
    return this._states;
  }

  get notes() {
    return this._notes;
  }

  set notes(notes) {
    this._notes = notes;
  }

  get achDetails() {
    return this._achDetails;
  }

  get objectToSaveOnCreate() {
    return {
      name: this.name,
      state: this.state,
      entity_type: this.entityType,
    };
  }

  get objectToSaveOnUpdate() {
    return {
      name: this.name,
      state: this.state,
      entity_type: this.entityType,
      vesting_clause: this.vestingClause,
      full_name: this.fullName,
      notes: this.notes,
    };
  }

  get copy() {
    return new LegalEntity(this._raw, this._optionsRaw);
  }

  get changed() {
    return (
      this._name !== this._raw.name ||
      this._state !== this._raw.state ||
      this._entityType !== this._raw.entityType ||
      this._vestingClause !== this._raw.vestingClause ||
      this._fullName !== this._raw.fullName ||
      this._notes !== this._raw.notes
    );
  }

  update(updatedEntity) {
    this._raw = updatedEntity;
    this._name = updatedEntity.name;
    this._state = updatedEntity.state;
    this._entityType = updatedEntity.entityType;
    this._vestingClause = updatedEntity.vestingClause;
    this._fullName = updatedEntity.fullName;
    this._notes = updatedEntity.notes;
    this._lastEditedAt = updatedEntity.lastEditedAt;
  }

  reset() {
    this.update(this._raw);
  }
}
