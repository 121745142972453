<template>
  <div>
    <template v-if="isLender">
      <h6 class="form-label">Note Origination*</h6>
      <div 
        class="radio-wrapper note-origination"
        data-cy="note-origination-radio"
      >
        <base-radio v-model="scenario.noteOrigination"
          class="radio-element"
          id="table-funding"
          :native-value="NoteOrigination.tableFunding.value">
          {{ NoteOrigination.tableFunding.title }}
        </base-radio>
        <base-radio v-model="scenario.noteOrigination"
          class="radio-element"
          id="funding-into-origination"
          :native-value="NoteOrigination.fundingIntoOrigination.value">
          {{ NoteOrigination.fundingIntoOrigination.title }}
        </base-radio>
      </div>
    </template>
    <h6 class="form-label">Loan Purpose*</h6>
    <div 
      class="radio-wrapper"
      data-cy="loan-purpose-radio"
    >
      <base-radio v-model="scenario.loanPurpose"
        class="radio-element"
        :native-value="LoanPurpose.acquisition.value">
        {{ LoanPurpose.acquisition.title }}
      </base-radio>
      <base-radio v-model="scenario.loanPurpose"
        class="radio-element"
        id="cash-out-refinance"
        :native-value="LoanPurpose.cashOutRefinance.value">
        {{ LoanPurpose.cashOutRefinance.title }}
      </base-radio>
      <base-radio v-model="scenario.loanPurpose"
        class="radio-element"
        id="refinance"
        :native-value="LoanPurpose.refinance.value">
        {{ LoanPurpose.refinance.title }}
      </base-radio>
    </div>
    <div class="columns is-multiline">
      <base-select-field
        id="loan-strategy"
        data-cy="loan-strategy-dropdown"
        class="column is-half"
        label="Loan Strategy*"
        :options="LoanStrategy.options"
        show-empty
        v-model="scenario.loanStrategy"
      />
      <base-zip-field
        ref="form"
        data-cy="zip-code-zip"
        id="zip-code-field"
        v-model="scenario.zipCode"
        label="Zip Code*"
        class="column is-half"
      />
      <base-currency-field
        id="as-is-value"
        data-cy="designated-value-currency"
        label="Designated Value*"
        class="column is-half"
        v-model="scenario.asIsValue"
        @input="calculateLoanAmount"
        rules="exclusive_min_number_value:0"
        :with-tooltip="true"
        :tooltip-label="locale.tooltip.residentialBridge.assetValue + `${minAssetValueFormatted}`"
      />
      <base-currency-field
        id="construction-budget"
        data-cy="construction-budget-currency"
        label="Construction Budget*"
        rules="min_number_value:0"
        class="column is-half"
        v-model="scenario.constructionBudget"
      />
      <base-currency-field
        :disabled="!isArvRequired"
        id="arv"
        data-cy="arv-currency"
        :label="arvLabel"
        :rules="arvRule"
        class="column is-half"
        v-model="scenario.arv"
        :with-tooltip="true"
        :tooltip-label="locale.tooltip.residentialBridge.arv"
      />
      <base-currency-field
        :disabled="!hasConstructionStrategy"
        data-cy="construction-reserve-currency"
        id="construction-reserve"
        class="column is-half"
        :label="constructionReserveLabel"
        v-model="scenario.constructionReserve"
      />
      <base-fico-field
        id="fico"
        data-cy="credit-score-integer"
        class="column is-half"
        label="Designated Credit Score*"
        v-model="scenario.ficoScore"
        :with-tooltip="true"
        :tooltip-label="locale.tooltip.residentialBridge.designatedCreditScore + `${minCreditScore}`"
      />
      <base-select-field
        id="track-record"
        data-cy="track-record-dropdown"
        class="column is-half"
        label="Designated Track Record*"
        :with-tooltip="true"
        :tooltip-label="locale.tooltip.designatedTrackRecord"
        :options="TrackRecord.options"
        show-empty
        v-model="scenario.trackRecord"
      />
      <base-number-field
        id="loan-term"
        data-cy="loan-term-integer"
        class="column is-half"
        label="Loan Term (Months)"
        type="int"
        v-model="scenario.loanTerm"
      />
      <base-percent-field
        v-if="isLender"
        id="lender-spread"
        data-cy="lender-spread-currency"
        class="column is-half"
        label="Lender Spread (max 3%)*"
        rules="between:0,3"
        v-model="scenario.lenderSpread"
      />
      <base-currency-field
        v-if="isLender"
        id="b-piece"
        data-cy="b-piece-currency"
        class="column is-half"
        label="B-Piece"
        v-model="scenario.bPiece"
        :with-tooltip="true"
        :tooltip-label="locale.tooltip.residentialBridge.bPiece"
      />
    </div>
  </div>
</template>

<script>
import {
  LoanProduct,
  LoanPurpose,
  NoteOrigination,
  LoanStrategy,
  TrackRecord,
  AccountType,
} from "../enums";
import { formatCurrency } from "../utils/formatter";
import { BRIDGE_MIN_ASSET_VALUE, BRIDGE_MIN_CREDIT_SCORE } from "../utils/helpers";

export default {
  name: "residential-bridge-fieldset",
  inject: ["locale"],
  props: {
    scenario: Object,
    constraints: Object,
  },
  data() {
    return {
      LoanProduct,
      LoanPurpose,
      NoteOrigination,
      LoanStrategy,
      TrackRecord,
    };
  },
  watch: {
    "scenario.loanStrategy": {
      handler(value) {
        if (!value || value === LoanStrategy.bridge.value) {
          this.scenario.constructionReserve = null;
        }

        if ((!value || !this.hasConstructionStrategy) && !this.hasPositiveConstructionBudget()) {
          this.scenario.arv = null;
        }
      },
    },
    "scenario.constructionBudget": {
      handler(newVal, oldVal) {
        if ((!newVal || parseFloat(newVal) === 0) && !this.hasConstructionStrategy) {
          this.scenario.arv = null;
        }
      },
    },
  },
  methods: {
    calculateLoanAmount() {
      this.scenario.loanAmount = this.scenario.calculateLoanAmount(this.scenario.ltv);
    },
    hasPositiveConstructionBudget() {
      return this.scenario._hasPositiveConstructionBudget();
    },
  },
  computed: {
    arvLabel() {
      return this.isArvRequired ? `ARV*` : `ARV`;
    },
    constructionReserveLabel() {
      let label = `Construction Reserve`;
      return this.hasConstructionStrategy ? label + `*` : label;
    },
    isArvRequired() {
      return this.scenario._isArvRequired();
    },
    hasConstructionStrategy() {
      return this.scenario._hasConstructionStrategy();
    },
    minAssetValue() {
      return this.constraints.minAssetValue || BRIDGE_MIN_ASSET_VALUE;
    },
    minAssetValueFormatted() {
      return formatCurrency(this.minAssetValue);
    },
    minCreditScore() {
      return this.constraints.minCreditScore || BRIDGE_MIN_CREDIT_SCORE;
    },
    minArvValue() {
      return (
        parseFloat(this.scenario.asIsValue || 0) + parseFloat(this.scenario.constructionBudget || 0)
      );
    },
    arvRule() {
      return "min_number_value:" + this.minArvValue;
    },
    isLender() {
      return this.scenario.lenderAccountType != AccountType.broker.value;
    },
  },
};
</script>
