<template>
  <div v-cloak>
    <div class="active-portfolio-loans-list" v-cloak>
      <div class="portfolio-table-header">
        Total Lender Origination Fees Earned
        <span>{{ totalLenderOriginationFees | money(2, true) }}</span>
      </div>
    </div>
    <div
      id="residential-for-rent-loans-list"
      class="residential-for-rent-loans-list active-portfolio-loans-list"
    >
      <div class="portfolio-table-header">
        Showing {{ totalLoans }} of {{ totalLoans }}
        totalling {{ principalBalanceTotals | money(2, true) }}
      </div>

      <table class="portfolio-table">
        <thead>
          <tr>
            <th class="hotlist-sortable loan-origination-date">
              <sorting-header
                title="Orig Date"
                :order="findDirectionBy(SortingColumns.OrigDate)"
                @on-change-direction="sortBy(SortingColumns.OrigDate, ...arguments)"
              />
            </th>
            <th class="hotlist-sortable loan-full-address">
              <sorting-header
                title="Address"
                :order="findDirectionBy(SortingColumns.Address)"
                @on-change-direction="sortBy(SortingColumns.Address, ...arguments)"
              />
            </th>
            <th class="hotlist-sortable loan-principal-balance">
              <sorting-header
                title="Principal Bal at Orig"
                :order="findDirectionBy(SortingColumns.PrincipalBalance)"
                @on-change-direction="sortBy(SortingColumns.PrincipalBalance, ...arguments)"
              />
            </th>
            <th class="hotlist-sortable loan-interest-rate">
              <sorting-header
                title="Note Rate"
                :order="findDirectionBy(SortingColumns.InterestRate)"
                @on-change-direction="sortBy(SortingColumns.InterestRate, ...arguments)"
              />
            </th>
            <th class="hotlist-sortable loan-pdti">
              <sorting-header
                v-if="replacePdtiWithDscr"
                title="DSCR"
                :order="findDirectionBy(SortingColumns.Dscr)"
                @on-change-direction="sortBy(SortingColumns.Dscr, ...arguments)"
              />
              <sorting-header
                v-else
                title="PDTI"
                :order="findDirectionBy(SortingColumns.Pdti)"
                @on-change-direction="sortBy(SortingColumns.Pdti, ...arguments)"
              />
            </th>
            <th class="hotlist-sortable loan-prepayment-penalty">
              <sorting-header
                :title="'Prepayment Penalty'"
                :order="findDirectionBy(SortingColumns.PrepaidPremium)"
                @on-change-direction="sortBy(SortingColumns.PrepaidPremium, ...arguments)"
              />
            </th>
            <th class="hotlist-sortable loan-lender-fees">
              <sorting-header
                title="Lender Orig Fees"
                :order="findDirectionBy(SortingColumns.LenderOrigFees)"
                @on-change-direction="sortBy(SortingColumns.LenderOrigFees, ...arguments)"
              />
            </th>
            <th class="hotlist-sortable loan-borrower-contact">
              <sorting-header
                title="Borrower"
                :order="findDirectionBy(SortingColumns.Borrower)"
                @on-change-direction="sortBy(SortingColumns.Borrower, ...arguments)"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="loan in loans"
            :key="loan.toParam"
            :data-path="loanPath(loan)"
          >
            <td :class="activeSortClass(SortingColumns.OrigDate)" class="loan-origination-date">
              {{ loan.originationDateText }}
            </td>

            <td :class="activeSortClass(SortingColumns.Address)" class="loan-full-address">
              <span class="loan-street">
                {{ loan.address }}
              </span>
              <span>{{ loan.city }}, {{ loan.state }} {{ loan.zip }}</span>
            </td>

            <td
              :class="activeSortClass(SortingColumns.PrincipalBalance)"
              class="loan-principal-balance"
            >
              {{ loan.totalPrincipalBalanceAtOrigination | money(2, true) | missingValue }}
            </td>

            <td :class="activeSortClass(SortingColumns.NoteRate)" class="loan-interest-rate">
              <div>
                <span
                  v-popover="{'container': '#residential-for-rent-loans-list'}"
                  class="with-field-tooltip"
                >
                  {{ loan.interestRate | percent(3, true) | missingValue }}
                </span>
                <div class="popover-content hidden">
                  <p>
                    <strong>Note Rate:</strong> {{ loan.interestRate | percent(3, true) | missingValue }}
                  </p>
                  <p>
                    <strong>Lender Spread:</strong> {{ loan.lenderSpread | percent(3, true) | missingValue }}
                  </p>
                  <p><strong>LTV:</strong> {{ loan.ltv | percent(3, true) | missingValue }}</p>
                  <p><strong>LTARV:</strong> {{ loan.ltarv | percent(3, true) | missingValue }}</p>
                </div>
              </div>
            </td>

            <td v-if="replacePdtiWithDscr" :class="activeSortClass(SortingColumns.Dscr)" class="loan-pdti">
              {{ loan.dscr | number(3, true) | missingValue }}
            </td>
            <td v-else :class="activeSortClass(SortingColumns.Pdti)" class="loan-pdti">
              {{ loan.pdti | percent(3, true) | missingValue }}
            </td>

            <td :class="activeSortClass(SortingColumns.PrepaidPremium)" class="loan-prepayment-penalty">
              {{ loan.prepaidPremium | percent(0, true) | missingValue }}
            </td>

            <td :class="activeSortClass(SortingColumns.LenderOrigFees)" class="loan-lender-fees">
              {{ loan.lenderOriginationFeesAmount | money(2, true) | missingValue }}
            </td>

            <td :class="activeSortClass(SortingColumns.Borrower)" class="loan-borrower-contact">
              <div>
                <span
                  v-popover="{'container': '#residential-for-rent-loans-list'}"
                  class="with-field-tooltip"
                >
                  {{ loan.borrowers[0].formattedName }}
                </span>
                <div class="popover-content hidden">
                  <borrowers-info :borrowers="loan.borrowers" />
                </div>
              </div>
            </td>
          </tr>

          <tr v-if="loans.length === 0">
            <td class="loan-empty" colspan="2">No Loans</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SortingHeader from "components/shared/sorting_header";
import SortingHelper from "utils/sorting_helper";
import BorrowersInfo from "components/active_portfolio/borrowers_info";
import Ajax from "services/ajax_service";

function sortByUpdatedAt(a, b) {
  return a.updatedAtTime - b.updatedAtTime;
}

const SortingColumns = {
  OrigDate: "originationDate",
  Address: "fullAddress",
  PrincipalBalance: "totalPrincipalBalanceAtOrigination",
  InterestRate: "interestRate",
  Pdti: "pdti",
  Dscr: "dscr",
  PrepaidPremium: "prepaidPremium",
  LenderOrigFees: "lenderOriginationFeesAmount",
  Borrower: "firstBorrowerName",
};

export default {
  name: "residential-for-rent-loans-list",
  components: {
    SortingHeader,
    BorrowersInfo,
  },
  props: {
    loansRoute: String,
    residentialForRentLoansRoute: String,
  },
  data() {
    let replacePdtiWithDscr = PSData.replacePdtiWithDscr; // feature flag: lp.replace_pdti_with_dscr

    return {
      replacePdtiWithDscr,
      loans: [],
      sortingDirection: 1,
      sortingColumn: SortingColumns.OrigDate,
      sorter: new SortingHelper(
        {
          [SortingColumns.OrigDate]: {},
          [SortingColumns.Address]: {},
          [SortingColumns.PrincipalBalance]: {},
          [SortingColumns.InterestRate]: {},
          [replacePdtiWithDscr ? SortingColumns.Dscr : SortingColumns.Pdti]: {},
          [SortingColumns.PrepaidPremium]: {},
          [SortingColumns.LenderOrigFees]: {},
          [SortingColumns.Borrower]: {},
        },
        sortByUpdatedAt
      ),
      SortingColumns,
    };
  },
  mounted() {
    Ajax.getJSON(this.residentialForRentLoansRoute)
      .then(data => {
        this.loans = data.loans;
        this.sortBy(this.sortingColumn, this.sortingDirection);
        this.$emit("loaded", "residentialForRent", this.loans.length);
      })
      .catch(() => {
        this.loans = [];
      });
  },
  computed: {
    totalLenderOriginationFees() {
      return this.loans
        .map(loan => loan.lenderOriginationFeesAmount || 0)
        .reduce((total, current) => total + current, 0);
    },
    principalBalanceTotals() {
      return this.loans
        .map(loan => loan.totalPrincipalBalanceAtOrigination || 0)
        .reduce((total, current) => total + current, 0);
    },
    totalLoans() {
      return this.loans.length;
    },
  },
  methods: {
    findDirectionBy(columnName) {
      return this.sorter.findDirection(columnName, this.sortingColumn, this.sortingDirection);
    },
    sortBy(columnName, direction) {
      this.sortingColumn = columnName;
      this.sortingDirection = direction;

      this.loans = this.loans.sort(this.sorter.sort(columnName, direction));
    },
    activeSortClass(column) {
      if (this.sortingColumn && this.sortingColumn === column) {
        return "hotlist-active-column";
      } else {
        return "";
      }
    },
    loanPath(loan) {
      return `${this.loansRoute}/${loan.toParam}`;
    },
  },
};
</script>
