<template>
  <div class="column is-3">
    <validation-observer ref="observer" tag="form">
      <fieldset :disabled="criteriaDisabled">
        <div class="criteria-box">
          <div class="update-method-container">
            <base-header size="medium" class="section-header">Choose Update Method</base-header>
            <base-radio :native-value="updateMethods.CRITERIA" v-model="updateMethod" class="radio-element pb-1">
              Enter criteria
            </base-radio>
            <base-radio :native-value="updateMethods.CSV_UPLOAD" v-model="updateMethod" class="radio-element pb-1">
              Upload a CSV
            </base-radio>
          </div>
        </div>
        <template v-if="isUpdateMethodCriteria">
          <div class="criteria-box">
            <base-header size="medium" class="section-header">Set Loan Criteria</base-header>
            <uw-status-selector />
            <program-selector />

            <base-header size="medium" class="section-header pt-3">Set Rate Change Type</base-header>
            <base-radio native-value="ltv_fico_grid" v-model="rateChangeType" class="radio-element pb-1">
              Set custom grid pricing
            </base-radio>
            <base-radio native-value="flat_rate" v-model="rateChangeType" class="radio-element">
              Set global price change
            </base-radio>

            <template v-if="rateChangeType === 'ltv_fico_grid'">
              <div class="criteria-box custom-grid-actions is-flex is-justify-content-space-between is-align-items-center">
                <span v-if="hasAdjustments" class="note-txt">Custom grid has been set</span>
                <span v-else>Set custom grid</span>
                <div>
                  <base-tooltip v-if="hasAdjustments" label="Clear Grid" position="is-top" type="is-dark">
                    <base-button
                      type="icon"
                      class="is-hoverable clear-icon"
                      @click.native="clearGrid"
                    >
                      <i class="fas fa-times" />
                    </base-button>
                  </base-tooltip>
                  <base-tooltip label="Edit Grid" position="is-top" type="is-dark" class="ml-3">
                    <base-button
                      type="icon"
                      class="is-hoverable edit-icon"
                      icon="pencil-alt"
                      @click.native="openGridModal"
                    />
                  </base-tooltip>
                </div>
              </div>
            </template>

            <template v-if="rateChangeType === 'flat_rate'">
              <label>Adjust Rate</label>
              <base-tooltip
                :label="locale.tooltip.adjustRate"
                position="is-top"
                multilined
              >
                <base-icon icon="question-circle" type="hint" />
              </base-tooltip>
              <base-percent-field
                v-model="flatRateAdjustment"
                :positive="false"
                rules="between:-100,100"
              />
            </template>
          </div>

          <template v-if="rawRules">
            <div v-for="(rule, index) in rawRules" :key="index">
              <div class="criteria-box rule-actions is-flex is-justify-content-space-between is-align-items-center">
                <template v-if="rule.attr === 'state'">
                  <span class="rule-info">
                    If {{ titleize(rule.attr) }}
                    {{ rule.condition }}
                    <template v-for="(val, index) in rule.value">
                      <template v-if="!index == 0">, </template>
                      {{ val }}
                    </template>
                    <br>
                    Rate = <b>{{ rule.adjustment}}</b>
                  </span>
                </template>

                <template v-if="rule.attr === 'loan_amount'">
                  <span class="rule-info">
                    If {{ titleize(rule.attr) }} &nbsp; {{ rule.condition }} &nbsp; {{ formatCurrency(rule.value) }}
                    <br>
                    Rate = <b>{{ rule.adjustment}}</b>
                  </span>
                </template>
                <div>
                  <base-tooltip label="Clear Rule" position="is-top" type="is-dark">
                    <base-button
                      type="icon"
                      class="is-hoverable clear-icon"
                      @click.native="deleteRule(index, rule)"
                    >
                      <i class="fas fa-times" />
                    </base-button>
                  </base-tooltip>
                  <base-tooltip label="Edit Rule" position="is-top" type="is-dark" class="ml-3">
                    <base-button
                      type="icon"
                      class="is-hoverable edit-icon"
                      icon="pencil-alt"
                      @click.native="editRule(index, rule)"
                    />
                  </base-tooltip>
                </div>
              </div>
            </div>
          </template>

          <base-button
            @click="openRuleModal"
            type="textBlue"
            class="add-rule"
            :disabled="!isReady"
          >
            + Add Rule
          </base-button>
        </template>

        <template v-if="isUpdateMethodCsvUpload">
          <div class="criteria-box">
            <base-header size="medium" class="section-header">Upload CSV</base-header>
            <p>Please upload a CSV file and click preview to see rate adjustments.</p>
            <div class="upload-container">
              <b-upload v-model="uploadedCsvFile" class="file-label" accept=".csv">
                <span class="button base-button is-normal is-primary file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span class="file-label">{{ uploadedCsvFile.name || "Click to Upload" }}</span>
                </span>
              </b-upload>
            </div>
          </div>
        </template>

        <div class="is-flex is-justify-content-flex-end">
          <base-button
            @click="clearCriteria"
            type="text"
            class="mr-5"
          >
            Clear
          </base-button>
          <base-button
            @click="generatePreview"
            type="primary"
            :disabled="!isReady || isPreviewLoading"
          >
            {{ isPreviewLoading ? "Generating Preview..." : "Preview" }}
          </base-button>
        </div>
      </fieldset>
    </validation-observer>

    <grid-modal
      :if="showGridModal"
      :show="showGridModal"
      @close="closeGridModal"
      @preview-alert="openPreviewAlert"
    ></grid-modal>

    <rule-modal
      :v-if="showRuleModal"
      :show="showRuleModal"
      :raw-rule="selectedRule"
      :rule-index="selectedRuleIndex"
      @close="closeRuleModal"
      @preview-alert="openPreviewAlert"
    ></rule-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import useCriteriaStore from "./stores/criteria";
import useRuleStore from "./stores/rule";
import useLtvFicoGridStore from "./stores/ltv_fico_grid";
import usePreviewStore from "./stores/preview";
import { titleize, formatCurrency } from "./utils/formatter";
import { ValidationObserver } from "vee-validate";
import GridModal from "./grid_modal";
import RuleModal from "./rule_modal";
import ProgramSelector from "./program_selector";
import UwStatusSelector from "./uw_status_selector";
import UPDATE_METHODS from "./consts/update_methods";

export default {
  name: "criteria",
  inject: ["locale"],
  components: {
    RuleModal,
    GridModal,
    ValidationObserver,
    ProgramSelector,
    UwStatusSelector,
  },
  data() {
    const criteriaStore = useCriteriaStore();
    const ruleStore = useRuleStore();
    const gridStore = useLtvFicoGridStore();
    const previewStore = usePreviewStore();
    return {
      criteriaDisabled: false,
      showRuleModal: false,
      showGridModal: false,
      criteriaStore,
      ruleStore,
      gridStore,
      previewStore,
      selectedRule: {},
      selectedRuleIndex: null,
      updateMethods: UPDATE_METHODS,
    };
  },
  watch: {
    "$route.params.id": {
      handler: function(id) {
        if (this.previewId !== id) {
          this.previewId = id;
          this.initializePreview();
        }
      },
      deep: true,
      immediate: true,
    },
    rateChangeType: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal && !!oldVal) {
          if (
            this.isAssignedState &&
            this.flatRateAdjustment === null &&
            newVal === "ltv_fico_grid"
          ) {
            this.criteriaStore.resetStateFlag();
            return;
          }

          if (newVal === "ltv_fico_grid") {
            this.criteriaStore.clearFlatRate();
            this.openPreviewAlert();
          }

          if (newVal === "flat_rate") {
            this.clearGrid();
          }

          if (this.isAssignedState) {
            this.criteriaStore.resetStateFlag();
          }
        }
      },
    },
    flatRateAdjustment: {
      immediate: true,
      handler(newVal, oldVal) {
        if (this.isAssignedState && (newVal === oldVal || oldVal === null || oldVal === "")) {
          this.criteriaStore.resetStateFlag();
          return;
        }

        if (newVal !== null && newVal !== oldVal) {
          this.openPreviewAlert();
        }
      },
    },
  },
  computed: {
    ...mapState(usePreviewStore, ["isPreviewLoading"]),
    ...mapWritableState(usePreviewStore, { previewId: "id" }),
    ...mapWritableState(useCriteriaStore, [
      "rateChangeType",
      "flatRateAdjustment",
      "updateMethod",
      "uploadedCsvFile",
      "isAssignedState",
    ]),
    ...mapState(useLtvFicoGridStore, ["hasAdjustments"]),
    ...mapState(useCriteriaStore, ["isReady", "isUpdateMethodCriteria", "isUpdateMethodCsvUpload"]),
    isNewPreview() {
      return this.$route.params.id === "new";
    },
    rawRules() {
      return this.ruleStore.rules;
    },
  },
  methods: {
    ...mapActions(useRuleStore, ["deleteRule"]),
    ...mapActions(usePreviewStore, ["initializePreview", "generatePreview", "showAlert"]),

    formatCurrency,
    titleize,
    openGridModal() {
      this.showGridModal = true;
    },
    closeGridModal() {
      this.showGridModal = false;
    },
    openPreviewAlert() {
      if (!this.isNewPreview) {
        this.showAlert();
      }
    },
    openRuleModal() {
      this.showRuleModal = true;
    },
    closeRuleModal() {
      this.selectedRule = {};
      this.selectedRuleIndex = null;
      this.showRuleModal = false;
    },
    editRule(index, rule) {
      this.selectedRule = { ...rule };
      this.selectedRuleIndex = index;
      this.openRuleModal();
    },
    clearCriteria() {
      const previewId = this.previewId;
      this.gridStore.resetState();
      this.criteriaStore.resetState();
      this.ruleStore.resetState();
      this.previewStore.$reset();
      this.previewId = previewId;
    },
    clearGrid() {
      this.gridStore.resetState();
      this.openPreviewAlert();
    },
  },
};
</script>
<style lang="scss">
.criteria-box,
.base-button.is-text-blue.add-rule {
  background: var(--color-white);
  border: 1px solid var(--color-grey-16);
  border-radius: var(--border-radius);
  margin-bottom: 24px;
}
.criteria-box {
  height: fit-content;
  padding: 20px 24px;
}
.base-button.is-text-blue.add-rule {
  width: 100%;
}
.base-button.is-text-blue.add-rule:hover {
  background: var(--color-grey-16);
  color: var(--color-grey-86);
}
.section-header {
  margin-top: 0;
  padding-bottom: 0;
}
.pricing-tool label {
  font-weight: 600;
  height: 19px;
}
.pricing-tool .file-label {
  display: block;
  font-size: 14px;
  height: unset;
  text-overflow: ellipsis;
}
.pricing-tool .file-cta span {
  margin: unset;
}
.pricing-tool .file-cta {
  width: 158px;

  &:hover {
    background-color: var(--color-blue-hover) !important;
    color: var(--color-white) !important;
  }
}
.pricing-tool .base-tooltip {
  margin-left: -4px;
}
.update-method-container .base-radio {
  display: inline;

  &:last-child {
    margin-left: 24px;
  }
}
.update-method-container .control-label {
  padding-left: 8px !important;
}
.update-method-container .check {
  width: 20px !important;
  height: 20px !important;
}
.criteria-box .base-button.is-icon,
.criteria-box .base-button.is-icon:hover,
.criteria-box .base-button.is-icon:focus {
  background: none !important;
  border: none;
  box-shadow: none;
}
.criteria-box .base-button.edit-icon,
.criteria-box .base-button.edit-icon:hover,
.criteria-box .base-button.clear-icon:hover {
  color: var(--color-blue);
}
.criteria-box .base-button.clear-icon {
  color: var(--color-grey-38);
}
.criteria-box .base-button.edit-icon,
.criteria-box .base-button.clear-icon {
  width: 16px !important;
}
.criteria-box .upload-container {
  margin-top: 18px;
}
.custom-grid-actions {
  line-height: 36px;
  margin-bottom: 4px;
  padding: 0 16px;
}
.rule-actions {
  padding-bottom: 18px;
  padding-top: 18px;
}
.rule-info {
  width: 233px;
}
.pricing-tool .base-tooltip.is-dark .tooltip-content {
  background: var(--color-grey-86);
  color: var(--color-white);
}
.pricing-tool .b-tooltip.is-top.is-dark .tooltip-content:before {
  border-top-color: var(--color-grey-86);
}
</style>
