<template>
  <div>
    <div class="is-flex py-4 add-property-section">
      <span class="add-property-section-header">{{ preview.name }}</span>
    </div>
    <preview-component v-for="preview in previews" :key="preview.id" :preview="preview" :meta="meta" />
  </div>
</template>

<script>
import Preview from "models/workflow_engine/preview";

export default Vue.extend({
  name: "entity-preview",
  components: {
    PreviewComponent: () => import("components/workflows/preview"),
  },
  props: {
    preview: {
      type: Preview,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    previews() {
      return this.meta.previews.filter(
        preview => preview.parentVariableInstanceId === this.preview.id
      );
    },
  },
});
</script>
<style scoped>
.add-property-section {
  align-items: center;
}

.add-property-section-header {
  font-size: var(--font-size-md);
  font-weight: 600;
}
</style>
