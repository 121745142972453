<template>
  <reallocate-amounts-form
    :submitting.sync="submitting"
    :loading.sync="loading"
    :table-distribution-data.sync="tableDistributionData"
    :loan-ps-id="loanPsId"
    :owner-to-id.sync="ownerToId"
    :distribution-to-id.sync="distributionToId"
    :original-fees-total.sync="originalFeesTotal"
    :user-fee-column-total.sync="userFeeColumnTotal"
    :original-column-totals.sync="originalColumnTotals"
    :user-column-totals.sync="userColumnTotals"
    :cash-receipt-item-id="cashReceiptItemId"
    :reasons="reasons"
    :selected-reason-id="selectedReasonId"
    :custom-reason="customReason"
    :show-reason-error="showReasonError"
    @onSelectReason="onSelectReason"
    @onInputCustomReason="onInputCustomReason"
    @onShowReasonError="onShowReasonError"
  />
</template>

<script>
import ReallocateAmountsForm from "./reallocate_amounts_form";
import AjaxServiceWithNotifications from "services/ajax_service";
import { parseNumericString } from "./utils";
import Big from "big.js";

export default {
  components: {
    ReallocateAmountsForm,
  },
  props: ["cashReceiptItemId"],
  data: function() {
    return {
      blankCurrency: "0.00",
      distributionToId: {},
      feeTypes: [],
      loading: true,
      submitting: false,
      loanPsId: "",
      originalFeesTotal: 0,
      originalColumnTotals: {
        principal: 0,
        supplemental_payment: 0,
        interest: 0,
        default_interest: 0,
        deferred_interest: 0,
        prepaid_interest: 0,
      },
      ownerToId: {},
      tableDistributionData: [],
      userColumnTotals: {
        principal: 0,
        supplemental_payment: 0,
        interest: 0,
        default_interest: 0,
        deferred_interest: 0,
        prepaid_interest: 0,
      },
      userFeeColumnTotal: 0,
      reasons: [],
      selectedReasonId: null,
      customReason: null,
      showReasonError: false,
    };
  },
  watch: {
    tableDistributionData: {
      deep: true,
      handler() {
        this.userColumnTotals.principal = this.columnTotal("principal");
        this.userColumnTotals.supplemental_payment = this.columnTotal("supplemental_payment");
        this.userColumnTotals.interest = this.columnTotal("interest");
        this.userColumnTotals.default_interest = this.columnTotal("default_interest");
        this.userColumnTotals.deferred_interest = this.columnTotal("deferred_interest");
        this.userColumnTotals.prepaid_interest = this.columnTotal("prepaid_interest");
        this.userFeeColumnTotal = this.feeColumnTotal();
      },
    },
  },
  mounted: function() {
    this.fetchParticipants().then(participantsData => {
      this.fetchDistributions().then(distributionData => {
        let parsedData = this.parseDistributions(distributionData);
        this.loanPsId = this.setPsId(distributionData);
        this.tableDistributionData = this.formatDistributions(parsedData);
        this.addParticipantsToTableDistributions(participantsData);
        this.addParticipantsToOwnerMap(participantsData);
        this.createDistributionAndOwnerMaps(distributionData);
        this.feeTypes = this.createFeeTypesArray(distributionData);
        this.addMissingFeeTypes();
        this.setOriginalFeesTotal();
        this.fetchReasons().then(reasonData => {
          this.reasons = reasonData;
        });
      });
    });
  },
  methods: {
    onSelectReason(reasonId) {
      this.selectedReasonId = reasonId;
    },
    onInputCustomReason(reason) {
      this.customReason = reason;
    },
    onShowReasonError(showReasonError) {
      this.showReasonError = showReasonError;
    },
    async fetchParticipants() {
      this.loading = true;
      let rawParticipantData;
      const url = `/manage/participants/?filters[cash_receipt_item_id]=${this.cashReceiptItemId}`;
      try {
        rawParticipantData = await AjaxServiceWithNotifications.getJSON(url);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
      return rawParticipantData;
    },
    async fetchDistributions() {
      this.loading = true;
      let rawDistributionData;
      const url = `/manage/distributions/?filters[cash_receipt_item_id]=${this.cashReceiptItemId}`;

      try {
        rawDistributionData = await AjaxServiceWithNotifications.getJSON(url);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
      return rawDistributionData;
    },
    async fetchReasons() {
      this.loading = true;
      let rawReasonData;
      const url = "/manage/modification_reasons?type=ManualAllocationReason";

      try {
        rawReasonData = await AjaxServiceWithNotifications.getJSON(url);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }

      return rawReasonData;
    },
    formatDistributions(input) {
      const defaultDisbursementTypes = {
        fees: [],
        principal: "0.00",
        supplemental_payment: "0.00",
        interest: "0.00",
        default_interest: "0.00",
        deferred_interest: "0.00",
        prepaid_interest: "0.00",
      };
      return input.reduce((array, obj) => {
        const index = array.findIndex(dist => dist.owner === obj.owner);
        if (index >= 0) {
          if (obj.disbursementType === "fee") {
            array[index]["fees"].push({
              amount: obj.amount,
              cash_receipt_item_detail: obj.cash_receipt_item_detail,
            });
          } else {
            array[index][obj.disbursementType] = this.totalForDisbursementType(
              array[index][obj.disbursementType],
              obj.amount
            );
            this.originalColumnTotals[obj.disbursementType] =
              obj.cash_receipt_item_detail.revised_amount;
          }
        } else {
          if (obj.disbursementType === "fee") {
            array.push({
              ...defaultDisbursementTypes,
              owner: obj.owner,
              fees: [
                {
                  amount: obj.amount,
                  cash_receipt_item_detail: obj.cash_receipt_item_detail,
                },
              ],
            });
          } else {
            array.push({
              ...defaultDisbursementTypes,
              owner: obj.owner,
              [obj.disbursementType]: obj.amount,
              fees: [],
            });
            this.originalColumnTotals[obj.disbursementType] =
              obj.cash_receipt_item_detail.revised_amount;
          }
        }
        return array;
      }, []);
    },
    totalForDisbursementType(total, input) {
      return new Big(total || 0).add(new Big(input)).toFixed(2);
    },
    addParticipantsToTableDistributions(participants) {
      const defaultDisbursementTypes = {
        fees: [],
        principal: "0.00",
        supplemental_payment: "0.00",
        interest: "0.00",
        default_interest: "0.00",
        deferred_interest: "0.00",
        prepaid_interest: "0.00",
      };
      participants.forEach(participant => {
        const index = this.tableDistributionData.findIndex(dist => dist.owner == participant.label);
        if (index < 0) {
          this.tableDistributionData.push({
            ...defaultDisbursementTypes,
            owner: participant.label,
            [participant.type]: "0.00",
            fees: [],
          });
        }
      });
      return this.tableDistributionData;
    },
    setPsId(distributions) {
      if (distributions.length > 0) {
        return distributions[0].loan_id;
      }
    },
    parseDistributions(distributions) {
      return distributions.map(distribution => {
        return {
          owner: distribution.participant.label,
          amount: distribution.amount,
          cash_receipt_item_detail: distribution.cash_receipt_item_detail,
          disbursementType: distribution.cash_receipt_item_detail.disbursement_type,
        };
      });
    },
    addParticipantsToOwnerMap(participants) {
      participants.forEach(participant => {
        this.ownerToId[participant.label] = {
          id: participant.id,
          type: participant.type,
          seriesId: participant.series_id,
        };
      });
      return this.ownerToId;
    },
    createDistributionAndOwnerMaps(distributions) {
      distributions.forEach(distribution => {
        let disbursementType = distribution.cash_receipt_item_detail.disbursement_type;
        this.ownerToId[distribution.participant.label] = {
          id: distribution.participant.id,
          type: distribution.participant.type,
          seriesId: distribution.participant.series_id,
        };
        if (disbursementType !== "fee") {
          this.distributionToId[disbursementType] = distribution.cash_receipt_item_detail.id;
        }
      });
    },
    createFeeTypesArray(distributions) {
      return distributions.reduce((newFeeTypesArray, distribution) => {
        let disbursementType = distribution.cash_receipt_item_detail.disbursement_type;
        if (disbursementType === "fee") {
          let feeName = distribution.cash_receipt_item_detail.fee_type.name;
          let feeLabel = distribution.cash_receipt_item_detail.fee_type.label;
          let cashReceiptItemDetailId = distribution.cash_receipt_item_detail.id;
          let foundFee = this.feeTypes.findIndex(feeObj => {
            return feeObj.name === feeName;
          });

          if (foundFee) {
            newFeeTypesArray.push({
              name: feeName,
              label: feeLabel,
              cashReceiptItemDetailId: cashReceiptItemDetailId,
            });
          }
        }
        return newFeeTypesArray;
      }, []);
    },
    addMissingFeeTypes() {
      this.tableDistributionData.forEach(row => {
        this.feeTypes.forEach(feeType => {
          let hasFee = row.fees.find(fee => {
            return fee.cash_receipt_item_detail.fee_type.name === feeType.name;
          });

          if (!hasFee) {
            row.fees.push({
              amount: "0",
              cash_receipt_item_detail: {
                id: feeType.cashReceiptItemDetailId,
                disbursement_type: "fee",
                fee_type: {
                  name: feeType.name,
                  label: feeType.label,
                },
              },
            });
          }
        });
      });
    },
    setOriginalFeesTotal() {
      this.originalFeesTotal = this.feeColumnTotal();
    },
    feeColumnTotal() {
      let sum = new Big(0);
      this.tableDistributionData.forEach(row => {
        row.fees.forEach(fee => {
          sum = sum.add(new Big(fee.amount));
        });
      });
      return sum.toFixed(2);
    },
    columnTotal(disbursementType) {
      let total = this.tableDistributionData.reduce((sum, dist) => {
        const replaced = dist[disbursementType].replace(/,/g, "") || this.blankCurrency;
        return sum.add(new Big(replaced));
      }, new Big(0));
      return total.toFixed(2);
    },
  },
};
</script>
