<template>
  <banner-alert
    v-if="show"
    title="Funded Loan Closing Restriction"
    description="A complete loan file must be submitted to PeerStreet five business days prior to the end of the month in order to transact with this lender in the last week of the month."
  />
</template>

<script>
import BannerAlert from "components/banners/alert";

export default {
  name: "closing-restriction-alert",
  components: {
    BannerAlert,
  },
  props: {
    show: Boolean,
  },
};
</script>
