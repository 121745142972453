<template>
    <div class="loan-followers">
      <div class="title">Followers</div>

      <div class="followers-list" v-popover="{ content: `#loan-followers-popover-${instanceCounter}` }">
        <div
          v-for="follower in firstFollowers"
          :key="`follower-${follower.id}`"
          class="loan-follower"
        >
          {{follower.nameAbbreviation}}
        </div>
      </div>

      <div v-if="moreFollowers" class="more-followers">+{{moreFollowers}}</div>

      <div @click="openLoanFollowersModal" class="edit-followers">
        <i class="fas fa-pencil-alt"/>
      </div>

      <loan-followers-modal
        class="loan-followers-modal"
        :routes="routes"
        :followers="editableFollowers"
        :follower-candidates="followerCandidates"
        :show="showLoanFollowersModal"
        :errors="updateLoanFollowersErrors"
        :loading="isUpdatingLoanFollowers"
        @close="closeLoanFollowersModal"
        @on-save="updateLoanFollowers"
      >
        <div class="notification-message alert-info">
          Followers are notified of new loan messages and tasks by email.
          Users who leave a message or comment on a task on a loan
          are automatically added as followers.
        </div>
      </loan-followers-modal>

      <div :id="`loan-followers-popover-${instanceCounter}`" class="popover-content hidden">
        <div class="all-followers-list">
          <div v-for="follower in sortedFollowers" :key="`popover-lender-follower-${follower.id}`">
            {{follower.name}}
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import take from "lodash/take";
import orderBy from "lodash/orderBy";
import partition from "lodash/partition";
import LoanFollowersModal from "components/activity_feed_events/loan_followers/modal";

const FIRST_FOLLOWERS = 4;
let instanceCounter = 0;

export default {
  name: "lender-loan-followers",
  components: {
    LoanFollowersModal,
  },
  props: {
    routes: Object,
    followers: Array,
    followerCandidates: Array,
  },
  data() {
    return {
      instanceCounter: instanceCounter,
      editableFollowers: this.followers,
      showLoanFollowersModal: false,
      updateLoanFollowersErrors: {},
      isUpdatingLoanFollowers: false,
    };
  },
  created() {
    instanceCounter++;
  },
  watch: {
    followers(newValue) {
      this.editableFollowers = newValue;
    },
  },
  computed: {
    firstFollowers() {
      return take(this.sortedFollowers, FIRST_FOLLOWERS);
    },
    sortedFollowers() {
      let followers = orderBy(this.editableFollowers, "name");
      let [me, others] = partition(followers, u => u.name === PSData.user.name);
      return [...me, ...others];
    },
    moreFollowers() {
      const followersCount = this.sortedFollowers.length;
      return followersCount > FIRST_FOLLOWERS ? followersCount - FIRST_FOLLOWERS : undefined;
    },
  },
  methods: {
    openLoanFollowersModal() {
      this.showLoanFollowersModal = true;
    },
    closeLoanFollowersModal() {
      this.updateLoanFollowersErrors = {};
      this.showLoanFollowersModal = false;
    },
    updateLoanFollowers(followers) {
      this.editableFollowers = followers;
    },
  },
};
</script>
