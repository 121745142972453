<template>
  <div class="filter-container d-flex">
    <h4>Filters:</h4>
    <select
      class="form-control"
      @input="e => $emit('update-asset-manager', e)"
    >
      <option value="">
        All Asset Managers
      </option>
      <option
        v-for="{ id, name } in assetManagerOptions"
        :key="id"
        :value="id"
      >
        {{ name }}
      </option>
    </select>
    <select
      class="form-control"
      @input="e => $emit('update-relationship-manager', e)"
    >
      <option value="">
        All Relationship Managers
      </option>
      <option
        v-for="{ id, name } in relationshipManagerOptions"
        :key="id"
        :value="id"
      >
        {{ name }}
      </option>
    </select>
    <div class="checkbox-control form-group d-flex">
      <div
        v-for="(option, i) in statusOptions"
        :key="i"
        class="checkbox"
      >
        <input
          :id="`filter-checkbox-group-option-${option.name}`"
          :name="`filter-checkbox-group-option-${option.name}`"
          :value="option.name"
          :checked="isChecked(option.name)"
          type="checkbox"
          autocomplete="off"
          v-on="$listeners"
        >
        <label
          :for="`filter-checkbox-group-option-${option.name}`"
        >
          <span class="control-toggle"></span>
          {{ option.label }}
        </label>
      </div>
    </div>
    <div v-show="isSearching" class="ps-loader ps-loader-small" />
  </div>
</template>

<script>
export default {
  name: "loan-tracker-filter",
  props: {
    assetManagerOptions: {
      type: Array,
      default: () => [],
    },
    relationshipManagerOptions: {
      type: Array,
      default: () => [],
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    status: {
      type: Array,
      default: () => [],
    },
    assetManagerId: {
      type: Number,
      default: null,
    },
    relationshipManagerId: {
      type: Number,
      default: null,
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isChecked(value) {
      return this.status.includes(value);
    },
  },
};
</script>
