import SettlementStatementDetailsController from "controllers/manage/settlement_statement/details_controller.vue";
import ActivityFeedEventsSection from "components/activity_feed_events/manage/section";
import CopyToClipboard from "components/copy_to_clipboard";
import LenderTagsMixin from "mixins/manage/lenders/tags";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-settlement-statement-show-controller"),
    components: {
      SettlementStatementDetailsController,
      CopyToClipboard,
      ActivityFeedEventsSection,
    },
    mixins: [LenderTagsMixin],
    data: {
      routes: PSData.routes || {},
      documents: PSData.documents,
      loan: PSData.loan,
      events: PSData.events,
    },
  });
});
