<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <div class="input-group-text">
        $
      </div>
    </div>
    <input
      v-model="innerValue"
      v-mask:currency
      :name="name"
      :class="classes"
      type="currency"
      :readonly="readonly"
    >
    <div v-if="invalid" class="invalid-feedback">
      {{ errors.collect(name).join(",") }}
    </div>
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  inject: ["$validator"],
  $_veeValidate: {
    value() {
      return this.innerValue;
    },
  },
  props: {
    initialValue: {
      type: [Number, String],
      default: null,
    },
    inputClass: {
      type: String,
      default: null,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    readonly: Boolean,
  },
  data() {
    const innerValue = (this.initialValue || this.value || 0).toString();
    return { innerValue };
  },
  computed: {
    dirty() {
      return get(this.fields, [this.name, "dirty"], false);
    },
    invalid() {
      return this.errors.has(this.name);
    },
    classes() {
      return {
        "form-control": true,
        "is-invalid": this.invalid,
        "is-valid": this.dirty && !this.invalid,
        [this.inputClass]: true,
      };
    },
  },
  watch: {
    innerValue(v) {
      const num = v.replace(",", "");

      if (!isNaN(num)) {
        this.$emit("input", +num);
      }
    },
    value(v) {
      this.innerValue = (v || 0).toString();
    },
  },
};
</script>
