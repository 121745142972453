<template>
  <div class="docgen-request-access-button-wrapper">
    <a
      v-if="userStatus.hasAccount"
      href="https://docgen.peerstreet.com"
      class="btn btn-primary-cta">
        Open DocGen
    </a>
    <button
      v-if="!userStatus.hasAccount && !invitation.sent"
      :disabled="invitation.inProgress"
      @click="requestAccess"
      class="btn btn-primary-cta">
        Request Email Invitation
    </button>
    <div
      v-if="invitation.sent"
      class="docgen-invitation-sent">
        <h5>Your invitation has been sent!</h5>
        <p>
          We have sent an invitation to {{userStatus.email}}.
          If you have any questions contact {{accountManager.name}}, your Account Manager.
        </p>
    </div>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";

export default {
  name: "doc-gen-request-access-button",
  props: {
    invitation: Object,
    userStatus: Object,
    accountManager: Object,
  },
  methods: {
    requestAccess() {
      this.invitation.inProgress = true;

      AjaxService.post(this.userStatus.requestAccountUrl)
        .then(() => {
          this.invitation.sent = true;
        })
        .finally(() => {
          this.invitation.inProgress = false;
        });
    },
  },
};
</script>
