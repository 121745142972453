<template>
  <div hidden />
</template>

<script>
import NonTextField from "./base/non_text_field";

const VirtualField = NonTextField.extend({});

export default Vue.component("diligence-document-virtual-field", VirtualField);
</script>
