<template>
  <div>
    <slot :setSelected="setSelected" :isSelected="isSelected" />
  </div>
</template>

<script>
export default {
  name: "template-list",
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
  methods: {
    setSelected(v) {
      this.$emit("input", v);
    },
    isSelected(v) {
      return this.value == v;
    },
  },
};
</script>
