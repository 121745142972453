<template>
  <div>
    <status-indicator
      v-popover="{placement: 'bottom'}"
      :status="mapStatus(overallStatus)"
    />
    <div class="d-none">
      <template v-if="hasErrorMessages">
        <ul class="list-unstyled">
          <li v-for="errorMessage in loanSale.errorMessages" :key="errorMessage">
            {{ errorMessage }}
          </li>
        </ul>
      </template>
      <template v-else>
        LP Status:
        <status-indicator
          v-popover="{placement: 'bottom'}"
          :status="mapStatus(loanSale.status)"
        />
        {{ loanSale.status }}
        <br>
        RP Status:
        <status-indicator
          v-popover="{placement: 'bottom'}"
          :status="mapStatus(loanSale.retailStatus)"
        />
        {{ loanSale.retailStatus }}
      </template>
    </div>
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";

import StatusIndicator from "components/shared/status_indicator";

const STATUS_MAP = {
  pending: "pending",
  complete: "success",
  skipped: "warning",
  failed: "failure",
};

export default {
  components: {
    StatusIndicator,
  },
  props: {
    loanSale: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasErrorMessages() {
      return !isEmpty(this.loanSale.errorMessages);
    },
    overallStatus() {
      const { status, retailStatus } = this.loanSale;

      if (status === "skipped" && retailStatus === "skipped") {
        return "skipped";
      }

      if (status === "failed" || retailStatus === "failed") {
        return "failed";
      }

      if (status === "pending" || retailStatus === "pending") {
        return "pending";
      }

      return "complete";
    },
  },
  methods: {
    mapStatus(loanSaleStatus) {
      return STATUS_MAP[loanSaleStatus];
    },
  },
};
</script>
