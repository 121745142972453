export default class Preview {
  static deserialize({
    collection,
    id,
    name,
    parent_variable_id,
    parent_variable_instance_id,
    settings,
    step_id,
    value,
    variable_id,
    variable_type,
  } = {}) {
    return new Preview(
      id,
      name,
      variable_type,
      value,
      collection,
      settings,
      step_id,
      variable_id,
      parent_variable_id,
      parent_variable_instance_id
    );
  }

  constructor(
    id,
    name,
    variableType,
    value,
    collection,
    settings,
    stepId,
    variableId,
    parentVariableId,
    parentVariableInstanceId
  ) {
    this.id = id;
    this.name = name;
    this.variableType = variableType;
    this.value = value;
    this.collection = collection;
    this.settings = settings;
    this.stepId = stepId;
    this.variableId = variableId;
    this.parentVariableId = parentVariableId;
    this.parentVariableInstanceId = parentVariableInstanceId;
  }

  toServer() {
    return {
      id: this.id,
      value: this.value,
    };
  }
}
