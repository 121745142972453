<template>
  <span v-if="multiline">
    {{ postAddress.firstLine }}
    <template v-if="postAddress.secondLine">
      <br>
      {{ postAddress.secondLine }}
    </template>
  </span>
  <span v-else>
    {{ postAddress.fullAddress }}
  </span>
</template>

<script>
import PostAddress from "./models/post_address";

export default {
  name: "post-address",
  props: {
    address: {
      type: Object,
      required: true,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    postAddress() {
      return new PostAddress(this.address);
    },
  },
};
</script>
