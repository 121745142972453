<template>
  <div class="creditbox-info">
    <template v-if="hasIssues">
      <creditbox-results v-show="hasIssues" :results="issues" />
      <creditbox-results v-show="hasAdjustments && hasIssues" :results="adjustments" />
    </template>
  </div>
</template>

<script>
import CreditboxResults from "components/loan_scenarios/creditbox/results";

export default {
  name: "creditbox",
  components: { CreditboxResults },
  props: {
    issues: Array,
    adjustments: Array,
  },
  computed: {
    hasIssues() {
      return this.issues.length !== 0;
    },
    hasAdjustments() {
      return this.adjustments.length !== 0;
    },
  },
};
</script>

<style scoped>
.creditbox-info {
  margin-bottom: calc(var(--space-unit) * 3);
}

.creditbox-info >>> ul {
  list-style-type: none;
  margin-bottom: var(--space-unit);
  padding-left: 0;
}

.creditbox-info >>> ul li {
  color: var(--color-grey-86);
  font-size: var(--font-size);
  line-height: 19px;
  padding: 6px 0 5px 24px;
  position: relative;
}

.creditbox-info >>> ul li::before {
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 14px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 14px;
}

.creditbox-info >>> ul li.rule-error::before {
  background-color: var(--color-yellow);
}

.creditbox-info >>> ul li.rule-warning::before {
  background-color: var(--color-grey-60);
}
</style>
