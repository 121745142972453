<template>
  <div v-if="!loading">
    {{ value | accounting }}
  </div>
  <div v-else>
    <icon icon="spinner" spin/>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

import Icon from "components/shared/icon";
import AjaxService, { formatParams, ERROR_STATUS } from "services/ajax_service";

const DELAY = 300;

export default {
  components: {
    Icon,
  },
  props: {
    refreshOnMount: Boolean,
    initialValue: {
      type: Number,
      default: 0,
    },
    loanId: {
      type: String,
      required: true,
    },
    from: {
      type: Date,
      default: null,
    },
    to: {
      type: Date,
      default: null,
    },
  },
  data() {
    return { value: this.initialValue, loading: false };
  },
  computed: {
    isValid() {
      const { from, to } = this;
      return from && to && from < to;
    },
    params() {
      const { from, to, loanId: loan_id } = this;
      return { from, to, loan_id };
    },
    url() {
      return `/manage/cash_receipt_items/accrued_interest?${formatParams(this.params)}`;
    },
  },
  watch: {
    from() {
      this.debouncedFetch();
    },
    to() {
      this.debouncedFetch();
    },
  },
  mounted() {
    if (this.refreshOnMount) {
      this.fetch();
    }
  },
  methods: {
    debouncedFetch: debounce(function() {
      this.fetch();
    }, DELAY),
    async fetch() {
      if (!this.isValid) {
        return;
      }

      try {
        this.loading = true;
        const { accrualInterest } = await AjaxService.get(this.url);
        this.value = accrualInterest;
      } catch (e) {
        AjaxService.sendNotification("Unable to fetch accrual interest.", ERROR_STATUS);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
