<template>
  <div v-if="batchDate" @click="updateDate">
    <badge :date="batchDate"></badge>
  </div>
  <div v-else-if="canSetDate">
    <button class="institution-sales-tracking-set-date-button" @click="onClick">Set date</button>
  </div>
</template>
<script>
import Badge from "./badge";

const REQUIRED_DATE = {
  targetOfferDate: "startDate",
  offerDate: "startDate",
  wireDate: "offerDate",
  salesDocsCompletedDate: "offerDate",
};

const BLOCKER_DATE = { targetOfferDate: "offerDate" };

const UPDATEABLE_DATES = ["targetOfferDate"];

export default {
  name: "set-date-button",
  components: {
    Badge,
  },
  props: {
    fieldName: String,
    batch: Object,
    canUpdateBatch: Boolean,
  },
  methods: {
    onClick() {
      this.$emit("set-date", this.fieldName);
    },
    updateDate() {
      if (this.canUpdateDate) {
        this.$emit("set-date", this.fieldName);
      }
    },
  },
  computed: {
    batchDate() {
      return this.batch[this.fieldName];
    },
    hasRequiredDate() {
      return this.batch[REQUIRED_DATE[this.fieldName]];
    },
    hasBlockerDate() {
      return this.batch[BLOCKER_DATE[this.fieldName]];
    },
    canSetDate() {
      return this.canUpdateBatch && this.hasRequiredDate && !this.hasBlockerDate;
    },
    canUpdateDate() {
      return UPDATEABLE_DATES.includes(this.fieldName) && this.canSetDate;
    },
  },
};
</script>
