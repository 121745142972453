export default class Loan {
  constructor(params) {
    this.id = params.psId;
    this.raw = params;
  }

  toHash() {
    return {
      ps_id: this.id,
      series: this.raw.series.map(s => ({
        id: s.id,
        amount: s.amount,
        buyer: s.buyer,
        tranche: s.tranche,
      })),
    };
  }
}
