<template>
  <div class="details-row row no-gutters">
    <section-header
      :actions-hidden="actionsHidden"
      subheading="The foreclosure process in each state governed by the state's foreclosure laws"
    >
      <h4>Foreclosure Document Tracking</h4>
    </section-header>
    <div class="col-12 incomplete-task-section">
      <div class="incomplete-task">
        <h5>Document Tracking Information</h5>
        <span class="subheader">Track formal notices demanding or informing of action with a deadline</span>
        <div v-if="emptyWorkoutDocuments" class="col-12">
          <div class="no-foreclosure-documents">
            <span class="empty-letter-list">No letters added</span>
          </div>
        </div>
        <div v-else class="foreclosure-document-tracking-table">
          <table>
            <thead>
              <tr class="workout-document-summary-header">
                <th>Document Type</th>
                <th>Date Sent</th>
                <th>Tracking Number</th>
                <th>Expiration Date Deadline</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <workout-document-list-item
                v-for="workoutDocument in workoutDocuments"
                :key="workoutDocument.id"
                :can-edit="canEdit"
                :workout-document="workoutDocument"
                :workout-document-types="workoutDocumentTypes"
                @edit="handleModalOpen(workoutDocument, false)"
                @delete="handleDelete"
              />
            </tbody>
          </table>
        </div>
        <div v-if="canEdit" class="workout-case-link">
          <a
            href="#"
            title="Add Document Tracking"
            @click.prevent="handleModalOpen({}, true)"
          >+ Add Document Tracking</a>
        </div>
      </div>
    </div>

    <foreclosure-document-tracking-modal
      :show="showDocumentTrackingModal"
      :document-to-edit="documentToEdit"
      :create-mode="createMode"
      :errors="errors"
      :in-flight="inFlight"
      :workout-document-types="workoutDocumentTypes"
      @close="handleClose"
      @save="handleSave"
      @input="handleInput"
    />
  </div>
</template>

<script>
import SectionHeader from "../section_header";
import ForeclosureDocumentTrackingModal from "./foreclosure_document_tracking_modal";
import WorkoutDocumentListItem from "./workout_document_list_item";
import AjaxService from "services/ajax_service";
import { error } from "services/notification";

import get from "lodash/get";

const serializePayload = (payload, workoutId) => ({
  id: payload.id,
  foreclosure_workout_id: workoutId,
  document_type_id: payload.documentTypeId,
  date_sent: payload.dateSent,
  expiration_date: payload.expirationDate,
  tracking_number: payload.trackingNumber,
  carrier: payload.carrier,
  note: payload.note,
});

export default {
  name: "foreclosure-document-tracking",
  components: {
    ForeclosureDocumentTrackingModal,
    SectionHeader,
    WorkoutDocumentListItem,
  },
  props: {
    loanPsId: {
      type: String,
      required: true,
    },
    workoutCaseId: {
      type: Number,
      required: false,
    },
    workoutDocumentTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDocumentTrackingModal: false,
      createMode: true,
      documentToEdit: {},
      errors: {},
      inFlight: false,
      workoutDocuments: PSData.foreclosureWorkoutDocuments || [],
    };
  },
  computed: {
    actionsHidden: () => true,
    baseUrl() {
      return `/manage/loans/${this.loanPsId}/asset_management/workout_documents`;
    },
    canEdit: () => PSData.assetManagementCanEdit,
    emptyWorkoutDocuments() {
      return this.workoutDocuments.length == 0;
    },
  },
  methods: {
    handleModalOpen(document, createMode) {
      this.createMode = createMode;
      this.documentToEdit = { ...document };
      this.showDocumentTrackingModal = true;
    },
    async handleDelete(documentId) {
      try {
        await AjaxService.withNotifications({
          onSuccess: "Workout Document was succesfully deleted",
          onError: this.errorMessage,
        }).delete(`${this.baseUrl}/${documentId}`);

        this.workoutDocuments = this.workoutDocuments.filter(doc => {
          return doc.id !== documentId;
        });
      } finally {
        this.inFlight = false;
      }
    },
    handleSave() {
      const payload = serializePayload(this.documentToEdit, this.workoutCaseId);
      const endpoint = this.actionUrl();
      this.createMode ? this.handleCreate(endpoint, payload) : this.handleUpdate(endpoint, payload);
    },
    async handleCreate(url, payload) {
      this.inFlight = true;
      try {
        const { foreclosure_workout_document: doc } = await AjaxService.withNotifications({
          onSuccess: "Workout Document was succesfully created",
          onError: this.errorMessage,
        }).postJSON(url, payload);

        this.workoutDocuments.push(doc);
        this.handleClose();
      } catch ({ response, data }) {
        response.status === 422 ? (this.errors = data.errors) : error();
      } finally {
        this.inFlight = false;
      }
    },
    async handleUpdate(url, payload) {
      this.inFlight = true;
      try {
        const { foreclosure_workout_document: doc } = await AjaxService.withNotifications({
          onSuccess: "Workout Document was succesfully updated",
          onError: this.errorMessage,
        }).patchJSON(url, payload);

        const index = this.workoutDocuments.findIndex(d => {
          return d.id === doc.id;
        });
        this.workoutDocuments[index] = doc;
        this.handleClose();
      } catch ({ response, data }) {
        response.status === 422 ? (this.errors = data.errors) : error();
      } finally {
        this.inFlight = false;
      }
    },
    handleInput(fieldName, fieldValue) {
      Vue.set(this.documentToEdit, fieldName, fieldValue);
    },
    actionUrl() {
      if (this.createMode) {
        return this.baseUrl;
      }
      return `${this.baseUrl}/${this.documentToEdit.id}`;
    },
    handleClose() {
      this.showDocumentTrackingModal = false;
      this.documentToEdit = {};
      this.errors = {};
    },
    errorMessage(resp) {
      const message = get(resp, "data.error") || "Can't update workout document at this time";
      return { message, status: "error" };
    },
  },
};
</script>
