<template>
  <div>
    <div class="allocation-tab-header float-right">
      <div v-if="!editMode">
        <button type="button" class="btn btn-secondary-action-cta" @click="editTable" :disabled="isEditDisabled">
          Edit
        </button>
      </div>
      <div v-else>
        <button type="button" class="btn btn-cancel-cta" @click="cancelEdit">
          Cancel
        </button>
        <button type="button" class="btn btn-action-cta" @click="save" :disabled="isSaving">
          Save
        </button>
      </div>
    </div>
    <loans-table
      :is-validating="isValidating"
      :batch-loans="orderedLoans"
      :buyers="buyers"
      :edit-mode="editMode"
      @remove-loan="removeLoan"
      @change-buyers="changeLoanBuyers"
      @override-buyers="forceBuyer"
      @open-reallocate-modal="openReallocateLoansModal"
      @load-eligibility-data="loadEligibilityData"
    ></loans-table>
    <div class="float-right" v-if="!editMode">
      <button type="button" class="btn btn-action-cta" @click="allocate" :disabled="isAllocateDisabled">Allocate</button>
    </div>
    <div v-if="editMode">
      <button type="button" class="btn btn-secondary-action-cta" @click="importLoans" :disabled="isImporting">
        Import Loans
      </button>
    </div>
    <reallocate-loans-modal
      :show="showReallocateLoansModal"
      @close="closeReallocateLoansModal"
      @add="addToReallocate"
      v-if="showReallocateLoansModal"
    ></reallocate-loans-modal>
  </div>
</template>
<script>
import LoansTable from "./loans_table";
import ReallocateLoansModal from "./reallocate_loans_modal";
import cloneDeep from "lodash/cloneDeep";
import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import AllocationService from "services/allocation_service";

export default {
  name: "loans-tab",
  components: {
    LoansTable,
    ReallocateLoansModal,
  },
  props: {
    batch: Object,
    batchLoans: Array,
    buyers: Array,
    isImporting: Boolean,
    isSaving: Boolean,
    isAllocating: Boolean,
    isValidating: Boolean,
    allocationInProgress: Boolean,
  },
  data() {
    return {
      editMode: false,
      amendedLoans: cloneDeep(this.batchLoans),
      showReallocateLoansModal: false,
    };
  },
  methods: {
    editTable() {
      if (!this.batch.id) {
        this.importLoans();
      }
      this.editMode = true;
    },

    save() {
      this.$emit("save", this.amendedLoans, () => {
        this.editMode = false;
      });
    },

    cancelEdit() {
      this.$emit("cancel");
      this.amendedLoans = cloneDeep(this.batchLoans);
      this.editMode = false;
    },

    importLoans() {
      this.$emit("import-loans", batchLoans => {
        this.addNewBatchLoans(batchLoans);
      });
    },

    buildBatchLoan(batchLoan) {
      return {
        ...batchLoan,
        reallocate: !!batchLoan.loan.currentAllocation,
        buyersLoaded: false,
        eligibleBuyers: [],
        ineligibleBuyers: [],
        allocationBuyers: [],
        excludedBuyers: [],
      };
    },

    addNewBatchLoans(batchLoans) {
      const builtBatchLoans = batchLoans.map(batchLoan => this.buildBatchLoan(batchLoan));
      this.mergeBatchLoans(builtBatchLoans);
    },

    mergeBatchLoans(batchLoans) {
      batchLoans.forEach(batchLoan => {
        const existingBatchLoanIndex = this.amendedLoans.findIndex(
          x => x.loan.psId === batchLoan.loan.psId
        );
        if (existingBatchLoanIndex === -1) {
          this.amendedLoans.push(batchLoan);
        } else if (this.amendedLoans[existingBatchLoanIndex].destroy) {
          this.$set(this.amendedLoans, existingBatchLoanIndex, batchLoan);
        }
      });
    },

    removeLoan(batchLoan) {
      this.$set(batchLoan, "destroy", true);
    },

    changeLoanBuyers(batchLoan, includedBuyers) {
      this.$set(batchLoan, "allocationBuyers", includedBuyers);
      const excludedBuyers = batchLoan.eligibleBuyers.filter(
        buyer => !includedBuyers.includes(buyer)
      );
      this.$set(batchLoan, "excludedBuyers", excludedBuyers);
    },

    loadEligibilityData(batchLoan) {
      AllocationService.evaluateLoan(batchLoan.loan.psId)
        .then(data => {
          const eligibleBuyers = data.filter(box => box.eligible === true).map(box => box.name);
          const ineligibleBuyers = data.filter(box => box.eligible !== true).map(box => box.name);
          const allocationBuyers = data
            .filter(box => box.active === true && box.eligible === true)
            .map(box => box.name);

          this.$set(batchLoan, "allocationBuyers", allocationBuyers);
          this.$set(batchLoan, "eligibleBuyers", eligibleBuyers);
          this.$set(batchLoan, "ineligibleBuyers", ineligibleBuyers);
        })
        .finally(() => this.$set(batchLoan, "buyersLoaded", true));
    },

    forceBuyer(batchLoan, buyer) {
      const allocationBuyers = [];
      if (buyer.length > 0) {
        allocationBuyers.push(buyer);
      }
      this.$set(batchLoan, "allocationBuyers", allocationBuyers);
      this.$set(batchLoan, "excludedBuyers", [...batchLoan.eligibleBuyers]);
    },

    allocate() {
      this.$emit("allocate");
    },

    openReallocateLoansModal() {
      this.showReallocateLoansModal = true;
    },

    closeReallocateLoansModal() {
      this.showReallocateLoansModal = false;
    },

    addToReallocate(loanIds) {
      if (this.isValidating) {
        return;
      }

      const batchLoansToReallocate = loanIds.map(loanId => ({
        loan: { psId: loanId },
        reallocate: true,
      }));
      this.$emit("validate", batchLoansToReallocate, batchLoans => {
        this.addNewBatchLoans(batchLoans);
        this.closeReallocateLoansModal();
      });
    },
  },
  computed: {
    filteredLoans() {
      return this.amendedLoans.filter(loan => !loan.destroy);
    },
    orderedLoans() {
      return this.filteredLoans.sort((a, b) => a.loan.psId.localeCompare(b.loan.psId));
    },
    noLoans() {
      return this.orderedLoans.length === 0;
    },
    isAllocateDisabled() {
      return this.isAllocating || this.allocationInProgress || !this.batch.id || this.noLoans;
    },
    isEditDisabled() {
      return this.isAllocating || this.allocationInProgress;
    },
  },
  watch: {
    batchLoans(newVal) {
      this.amendedLoans = cloneDeep(newVal);
    },
  },
};
</script>
