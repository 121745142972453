<template>
  <details-table
    v-if="showPartyDetails"
    :raw-party="party"
    @on-edit="editParty"
    @on-delete="deleteParty"
  />
  <details-form
    v-else
    :raw-party="party"
    @on-cancel="cancelEditing"
    @on-update="updateParty"
  />
</template>

<script>
import AjaxService from "services/ajax_service";
import LocationService from "services/location_service";

import DetailsTable from "./party_details/table";
import DetailsForm from "./party_details/form";

export default {
  name: "responsible-party-details",
  components: {
    DetailsTable,
    DetailsForm,
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      routes: PSData.routes,
    };
  },
  computed: {
    showPartyDetails() {
      return !this.editMode;
    },
  },
  methods: {
    editParty() {
      this.editMode = true;
    },
    cancelEditing() {
      this.editMode = false;
    },
    updateParty(data) {
      this.$emit("party-updated", data);
      this.cancelEditing();
    },
    deleteParty() {
      const messages = { onSuccess: "The party has been deleted." };
      const url = `${this.routes.responsibleParties}/${this.party.id}`;

      AjaxService.withNotifications(messages)
        .delete(url)
        .then(() => {
          setTimeout(() => {
            LocationService.href = this.routes.responsibleParties;
          }, 500);
        })
        .catch(({ response }) => {
          Bugsnag.notify(new Error(`Unable to delete responsible party: ${response.statusText}`));
        });
    },
  },
};
</script>
