<template>
  <div class="comment">
    <div class="is-flex align-baseline">
      <div class="mr-4">
        <span class="comment-author">
          {{ createdByName }}
        </span>
      </div>
      <div class="mr-4">
        <span class="comment-date">
          {{ commentDate | formatDate(dateFormat) }}
        </span>
      </div>
      <div v-if="badge">
        <detail-badge :badge-color="badgeColor">
          <div class="badge-text">{{ badge }}</div>
        </detail-badge>
      </div>
      <comment-tags :tags="tags" />
    </div>
    <markdown-view :value="comment" class="comment-text" />
  </div>
</template>

<script>
import moment from "moment";
import DetailBadge from "../detail_badge";
import MarkdownView from "components/shared/markdown_view";
import CommentTags from "./tags";

export default {
  components: {
    DetailBadge,
    MarkdownView,
    CommentTags,
  },
  props: {
    createdByName: {
      type: String,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
    comment: {
      type: String,
      default: "",
    },
    tags: {
      type: Array,
      default: () => [],
    },
    badge: {
      type: String,
    },
    badgeColor: {
      type: String,
      default: "black",
    },
  },
  computed: {
    commentDate() {
      return moment(this.createdAt);
    },
    dateFormat() {
      const zonedTime = moment.parseZone(this.createdAt);
      if (zonedTime.hours() === 0 && zonedTime.minutes() === 0 && zonedTime.seconds() === 0) {
        return "MM/DD/YYYY";
      }
      return "MM/DD/YYYY h:mm A";
    },
  },
};
</script>

<style scoped>
.align-baseline {
  flex-wrap: wrap;
  align-items: baseline;
}
.comment {
  padding-bottom: var(--space-unit) / 1.6;
  padding-top: var(--space-unit) / 1.6;
  margin-bottom: var(--space-unit-md);
}
.comment-author {
  font-size: var(--font-size-md);
  font-weight: 600;
  white-space: nowrap;
}
.comment-date {
  color: var(--color-grey-60);
  font-size: var(--font-size-sm);
  white-space: nowrap;
}
.comment-text {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
.badge-text {
  text-transform: uppercase;
}
</style>
