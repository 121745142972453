<template>
  <div class="comments-tab">
    <div v-if="hasComments" ref="list" class="am-comment-list">
      <comment v-for="comment in sortedComments" :key="comment.id" v-bind="comment" />
    </div>
    <div v-else-if="loading" class="am-comment-list">
      <i class="ps-loader ps-loader-small" />
      <p>Loading...</p>
    </div>
    <div v-else class="am-comment-list">
      <p>This workflow has no notes.</p>
    </div>
    <div v-if="showNewCommentForm">
      <comment-form
        :resource-url="buildUrl"
        @comment-created="appendComment"
      />
    </div>
  </div>
</template>

<script>
import AjaxService, { ERROR_STATUS, ERROR_MESSAGE } from "services/ajax_service";
import Comment from "../comment";
import CommentForm from "./form";
import moment from "moment";
import useAssetManagementStore from "controllers/manage/store/asset_management";

export default {
  components: {
    Comment,
    CommentForm,
  },
  props: {
    workflow: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      comments: [],
      loading: true,
      loadingNewNote: false,
      newComment: "",
    };
  },
  computed: {
    showNewCommentForm() {
      return PSData.canAssignWorkflows;
    },
    hasComments() {
      return this.comments.length > 0;
    },
    sortedComments() {
      const origArray = this.comments.slice(0);
      return origArray.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
    },
    buildUrl() {
      return `/manage/asset_management/workflows/${this.workflow.id}/comments`;
    },
  },
  created() {
    this.fetchComments();
  },
  methods: {
    appendComment(newComment) {
      this.comments.push(newComment);
      const store = useAssetManagementStore();
      store.refreshWorkflowItem({ workflow: this.workflow });
    },
    async fetchComments() {
      try {
        this.comments = await AjaxService.getJSON(this.buildUrl);
      } catch ({ data }) {
        if (data && data.errors) {
          AjaxService.sendNotification(data.errors.join("\n"), ERROR_STATUS);
        } else {
          AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.comments-tab {
  background-color: var(--color-grey-06);
  border-radius: 5px;
  padding: calc(var(--space-unit) * 1.5);
}

.am-comment-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: var(--space-unit-sm);
  overflow-y: scroll;
  padding-bottom: var(--space-unit-lg);
}
</style>
