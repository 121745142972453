import { defineStore } from "pinia";
import usePreviewStore from "./preview";

const useRuleStore = defineStore("rule", {
  state: () => ({
    adjustments: [],
  }),
  actions: {
    addRule(rule) {
      this.adjustments.push(rule);
    },
    updateRule(index, rule) {
      Object.assign(this.adjustments[index], rule);
    },
    deleteRule(index, rule) {
      this.adjustments.splice(index, 1);
      usePreviewStore().showAlert();
    },
    resetState() {
      this.$reset();
    },
  },
  getters: {
    rules(state) {
      return state.adjustments;
    },
    hasAdjustments() {
      return this.adjustments.length > 0;
    },
    hasStateCondition() {
      return this.adjustments.findIndex(c => c.attr === "state") !== -1;
    },
    hasLoanAmountCondition() {
      return this.adjustments.findIndex(c => c.attr === "loan_amount") !== -1;
    },
  },
});

export default useRuleStore;
