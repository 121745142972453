<template>
  <div class="document-tracking-table-container">
    <table class="table document-tracking-table table-striped table-hover">
      <thead>
        <tr>
          <th>Document</th>
          <th class="document-description">Description</th>
          <th class="document-tracking">Tracking Number/<br>Carrier Status</th>
          <th class="document-status">Status</th>
          <th>Notes</th>
          <th class="document-updated">Last Updated</th>
          <th class="document-updated">Updated By</th>
        </tr>
      </thead>
      <tbody class="document-tracking-table-body">
        <tr
          v-for="document in trackingDocuments"
          :key="document.name"
          :class="documentRowClasses(document)"
          @click="selectDocument(document)"
        >
          <td>{{ document.name }}</td>
          <td>{{ document.description }}</td>
          <td>{{ document.trackingNumber }}<br>{{ document.carrierStatus }}</td>
          <td class="document-status"><DocumentStatus :document="document"/></td>
          <td class="document-note">{{ document.note }}</td>
          <td class="document-updated">{{ document.lastUpdatedAt }}</td>
          <td class="document-updated">
            {{ document.lastUpdatedBy }}
            <span
              v-if="document.reportImportUrl"
              @click.stop
            >
              (<a :href="document.reportImportUrl">imported</a>)
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DocumentStatus from "components/document_tracking/document_status";

export default {
  name: "document-tracking-show",
  components: { DocumentStatus },
  props: {
    trackingDocuments: {
      type: Array,
      required: true,
    },
    selectedDocument: Object,
  },
  methods: {
    documentRowClasses(document) {
      if (this.selectedDocument && document.id === this.selectedDocument.id) {
        return "selected";
      } else {
        return undefined;
      }
    },
    selectDocument(document) {
      this.$emit("on-select", document);
    },
  },
};
</script>
