<template>
  <base-table
    detailed
    paginated
    backend-pagination
    pagination-simple
    class="todolist-items"
    :row-class="() => 'borderless-row'"
    detail-key="toParam"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    :show-header="false"
    :data="loans"
    :opened-detailed="loans.map(loan => loan.toParam)"
    :loading="loading"
    :total="totalLoansCount"
    :per-page="loansPerPage"
    :current-page.sync="currentPage"
    @page-change="onPageChange"
  >
    <base-column
      v-slot="props"
      field="lenderTargetPurchaseDate"
      header-class="is-hidden"
      cell-class="loan-details-cell"
    >
      <div class="loan-details-header">
        <div>
          <h2 class="loan-full-address" @click="onLoanClick(props.row)">
            {{ props.row.fullAddress }}
          </h2>
          <div class="loan-essence">
            <span :class="underwritingStatusClass(props.row)">
              {{ props.row.underwritingStatusText }}
            </span>
            <span>
              <a :href="props.row.lenderLink">
                {{ props.row.lenderName }}
              </a>
            </span>
            <span class="dot-divider">
              {{ props.row.toParam }}
            </span>
            <span class="dot-divider">
              {{ props.row.loanProgramTitle }}
            </span>
            <span v-if="props.row.lenderTargetPurchaseDate" class="dot-divider">
              Requested Wire Date {{ props.row.lenderTargetPurchaseDate }}
            </span>
          </div>
        </div>
      </div>
    </base-column>
    <template #detail="{ props }">
      <div class="todolist-loan-tasks">
        <todolist-loan-tasks :tasks="props.row.loanTasks" />
      </div>
    </template>
    <template v-slot:empty>
      No loans found
    </template>
  </base-table>
</template>

<script>
import TodolistLoanTasks from "components/reports/todolist_loan_tasks";
import WindowService from "services/window_service";

export default {
  components: { TodolistLoanTasks },
  props: {
    loans: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    loansPerPage: {
      type: Number,
      default: () => 30,
    },
    offset: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      currentPage: this.offset,
    };
  },
  computed: {
    totalLoansCount() {
      if (this.loans.length < this.loansPerPage) {
        return (this.offset - 1) * this.loansPerPage + this.loans.length;
      } else {
        return (this.offset + 1) * this.loansPerPage;
      }
    },
  },
  methods: {
    underwritingStatusClass(loan) {
      return `loan-status loan-${loan.underwritingStatus}`;
    },
    loanLink(loan) {
      return `/manage/loans/${loan.toParam}`;
    },
    onLoanClick(loan) {
      WindowService.open(this.loanLink(loan), "_blank").focus();
    },
    onPageChange(page) {
      this.$emit("offset-change", page);
    },
  },
};
</script>
<style scoped>
.loan-full-address {
  cursor: pointer;
}
.toggle-task-filtering {
  align-items: center;
  margin-left: 6px;
  display: inline-flex !important;
}
</style>
<style>
.borderless-row td {
  border: none !important;
}
.borderless-row .chevron-cell {
  padding-right: 5px !important;
  padding-left: 0 !important;
  width: 25px !important;
}
.loan-details-cell {
  padding: 0 !important;
}
.todolist-items .detail-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.todolist-items tr.detail {
  background-color: white !important;
  box-shadow: none !important;
}
.todolist-items tr.detail td[colspan="2"] {
  border: none !important;
}
.todolist-items > .table-wrapper > table {
  border: none !important;
}
nav.pagination > small.info {
  display: none !important;
}
</style>
