import DDAsyncCheck from "models/diligence_document/dd_async_check";
import AjaxService from "../../../services/ajax_service";

const Command = PS.Models.Command;
const Events = PS.Models.Diligence.Events;

class RequestValueSaving extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Field.ValueChanged,
      PS.Services.MessageBusService.diligenceBus,
      {
        field: this.state.field,
        fieldset: this.state.fieldset,
        document: this.state.document,
      }
    );
  }
}

class SaveValue extends Command {
  execute() {
    const event = this.event;
    const service = event.field.formula ? AjaxService : AjaxService.withNotifications();

    return new Promise((resolve, reject) => {
      const sameDocument = DDAsyncCheck.generate();

      service
        .patch(`/manage/diligence_fields/${event.field.id}`, {
          field: {
            value: event.value,
          },
          metadata: {
            document: event.document.type,
            fieldset: event.fieldset ? event.fieldset.name : undefined,
          },
        })
        .then(() => {
          if (sameDocument()) {
            event.field.syncChanges();
            resolve();
          } else {
            reject();
          }
        });
    });
  }
}

class RequestNoteSaving extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Field.NoteChanged,
      PS.Services.MessageBusService.diligenceBus,
      {
        field: this.state.field,
        fieldset: this.state.fieldset,
        document: this.state.document,
      }
    );
  }
}

class SaveNote extends Command {
  execute() {
    return new Promise((resolve, reject) => {
      const sameDocument = DDAsyncCheck.generate();

      PS.Services.AjaxService.withNotifications()
        .patch(`/manage/diligence_fields/${this.event.field.id}`, {
          field: {
            note: this.event.note,
          },
          metadata: {
            document: this.event.document.type,
            fieldset: this.event.fieldset ? this.event.fieldset.name : undefined,
          },
        })
        .then(() => {
          if (sameDocument()) {
            resolve();
          } else {
            reject();
          }
        });
    });
  }
}

class RequestValidationSaving extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Field.ValidationChanged,
      PS.Services.MessageBusService.diligenceBus,
      {
        field: this.state.field,
        fieldset: this.state.fieldset,
        document: this.state.document,
      }
    );
  }
}

class SaveValidation extends Command {
  execute() {
    return new Promise((resolve, reject) => {
      const sameDocument = DDAsyncCheck.generate();

      PS.Services.AjaxService.withNotifications()
        .patch(`/manage/diligence_fields/${this.event.field.id}`, {
          field: {
            validated_at: this.event.validatedAt,
          },
          metadata: {
            document: this.event.document.type,
            fieldset: this.event.fieldset ? this.event.fieldset.name : undefined,
          },
        })
        .then(() => {
          if (sameDocument()) {
            resolve();
          } else {
            reject();
          }
        });
    });
  }
}

class RequestFormulaRecalculation extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Field.FormulaRecalculationRequested,
      PS.Services.MessageBusService.diligenceBus,
      {
        field: this.event.field,
        fieldset: this.event.fieldset,
      }
    );
  }
}

class RecalculateFormula extends Command {
  execute() {
    this.prepareFormula();

    if (this.state.compiledFormula) {
      this.state.compiledFormula.update(this.event);

      let value = this.state.compiledFormula.compute();
      this.state.field.value = value === undefined ? "" : value;
    }

    return Promise.resolve();
  }

  prepareFormula() {
    if (this.state.compiledFormula) {
      return;
    }

    let formulaText = PS.Models.FormulaList.find(this.state.field.name);
    if (!formulaText) {
      return;
    }

    if (this.state.fieldset) {
      this.state.compiledFormula = PS.Models.Formula.compile(formulaText, {
        currentFieldset: this.state.fieldset,
      });
    } else {
      this.state.compiledFormula = PS.Models.Formula.compile(formulaText);
    }
  }
}

class RequestFieldValuesClarification extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Field.ValueResendingRequested,
      PS.Services.MessageBusService.diligenceBus
    );
  }
}

class ResendFieldValues extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Field.FieldValueClarified,
      PS.Services.MessageBusService.diligenceBus,
      {
        field: this.state.field,
        fieldset: this.state.fieldset,
      }
    );
  }
}

class InvalidateFormula extends Command {
  execute() {
    Vue.nextTick(() => {
      if (this.state.compiledFormula) {
        this.state.compiledFormula.resetFieldsetData();
      }
    });

    return Promise.resolve();
  }
}

class ChangeValue extends Command {
  execute() {
    this.state.field.value = this.event.value;

    return Promise.resolve();
  }
}

class RequestFieldDisable extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Field.FieldDisableRequested,
      PS.Services.MessageBusService.diligenceBus,
      {
        field: this.state.field,
        fieldset: this.state.fieldset,
      }
    );
  }
}

class RequestFieldEnable extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Field.FieldEnableRequested,
      PS.Services.MessageBusService.diligenceBus,
      {
        field: this.state.field,
        fieldset: this.state.fieldset,
      }
    );
  }
}

class DisableField extends Command {
  execute() {
    this.state.field.disabled = true;

    return Promise.resolve();
  }
}

class EnableField extends Command {
  execute() {
    this.state.field.disabled = false;

    return Promise.resolve();
  }
}

class RequestToggleDisabledFields extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Field.ToggleDisabledFieldsRequested,
      PS.Services.MessageBusService.diligenceBus,
      { field: this.state.field }
    );
  }
}

PS.Models.Diligence.Commands.Field = {
  RequestValueSaving: RequestValueSaving,
  SaveValue: SaveValue,
  RequestNoteSaving: RequestNoteSaving,
  SaveNote: SaveNote,
  RequestValidationSaving: RequestValidationSaving,
  SaveValidation: SaveValidation,
  RequestFormulaRecalculation: RequestFormulaRecalculation,
  RecalculateFormula: RecalculateFormula,
  RequestFieldValuesClarification: RequestFieldValuesClarification,
  ResendFieldValues: ResendFieldValues,
  InvalidateFormula: InvalidateFormula,
  ChangeValue: ChangeValue,
  RequestFieldDisable: RequestFieldDisable,
  RequestFieldEnable: RequestFieldEnable,
  DisableField: DisableField,
  EnableField: EnableField,
  RequestToggleDisabledFields: RequestToggleDisabledFields,
};
