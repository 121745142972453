<template>
  <div v-if="!currentItem">
    <div v-if="loading" class="ps-loader" />
    <div v-else>
      <div class="cash-receipts-subheader">
        <h3>
          Hold
        </h3>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <label>PS Loan ID</label>
          <input
            v-model.trim="loanIdFilter"
            name="loanIdFilter"
            class="form-control"
            type="search"
          >
        </div>
      </div>

      <div class="cash-receipts-table-actions">
        <div v-if="!loanIdFilter" class="select-all-rows">
          <check-box :partial="selectedSome" :value="selectedAll" @change="toggleSelectAll" />
        </div>
        <div class="buttons">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="selectedNone || loading"
            @click="reprocess"
          >
            Reprocess Selected
          </button>
          <button
            type="submit"
            class="btn btn-outline-secondary"
            :disabled="selectedNone || loading"
            @click="override"
          >
            Override Selected
          </button>
        </div>
      </div>

      <div v-if="loanIdFilter">
        <h3>Ready to Distribute</h3>
        <items-table :items="readyItems" @review="displayItemDetails" />

        <h3>Transactions for Review</h3>
        <items-table :items="pendingItems" @review="displayItemDetails" />

        <h3>Transactions on Hold</h3>
        <items-table :show-hold-reason="true" :items="holdItems" @review="displayItemDetails" />
      </div>
      <div v-else>
        <hold-items-table
          :items="items"
          :selected-ids="selectedIds"
          @toggleSelect="toggleSelect"
          @displayItemDetails="displayItemDetails"
        />
      </div>
    </div>
  </div>

  <item-details v-else :value="currentItem" :use-hold-actions="useHoldActions" @back="displayItemList" />
</template>

<script>
import HoldItemsTable from "./table";
import ItemsTable from "../review/items/table";
import ItemDetails from "../review/details";
import CheckBox from "components/shared/check_box";
import AjaxService, { formatParams, ERROR_STATUS } from "services/ajax_service";
import debounce from "lodash/debounce";
import filter from "lodash/filter";

const DEBOUNCE_DELAY = 500;

export default {
  components: {
    HoldItemsTable,
    ItemsTable,
    ItemDetails,
    CheckBox,
  },
  data() {
    return {
      items: [],
      selectedIds: new Set(),
      loading: false,
      currentItem: null,
      loanIdFilter: null,
    };
  },
  computed: {
    selectedSome() {
      return (
        this.items.length > 0 &&
        this.selectedIds.size > 0 &&
        this.items.length > this.selectedIds.size
      );
    },
    selectedNone() {
      return this.selectedIds.size === 0;
    },
    selectedAll() {
      return (
        this.items.length > 0 &&
        this.selectedIds.size > 0 &&
        this.items.length == this.selectedIds.size
      );
    },
    params() {
      const statuses = this.loanIdFilter ? ["hold", "pending", "ready", "distributed"] : ["hold"];
      return formatParams({ statuses: statuses, loan_id: this.loanIdFilter });
    },
    readyItems() {
      return filter(this.items, item => {
        return item.status === "ready" || item.status === "distributed";
      });
    },
    pendingItems() {
      return filter(this.items, { status: "pending" });
    },
    holdItems() {
      return filter(this.items, { status: "hold" });
    },
    useHoldActions() {
      return this.currentItem.status === "hold";
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    loanIdFilter: {
      handler: debounce(function() {
        this.fetchData();
      }, DEBOUNCE_DELAY),
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const url = `/manage/cash_receipt_items?${this.params}`;
        const { items } = await AjaxService.getJSON(url);

        this.items = items;
      } catch (e) {
        AjaxService.sendNotification(e, ERROR_STATUS);
      } finally {
        this.loading = false;
      }
    },
    async reprocess() {
      this.loading = true;

      const headers = { "content-type": "application/json" };
      const body = { items: Array.from(this.selectedIds) };

      try {
        await AjaxService.patch("/manage/cash_receipt_items/reprocess", body, headers);

        this.selectedIds = new Set();
        this.items = this.items.filter(function(item) {
          return !body.items.includes(item.id);
        });
      } catch (e) {
        AjaxService.sendNotification(e, ERROR_STATUS);
      } finally {
        this.loading = false;
      }
    },
    async override() {
      if (!confirm("Override selected transactions?")) {
        return;
      }

      this.loading = true;

      const headers = { "content-type": "application/json" };
      const body = { items: Array.from(this.selectedIds) };

      try {
        await AjaxService.patch("/manage/cash_receipt_items/override", body, headers);

        this.selectedIds = new Set();
        this.items = this.items.filter(function(item) {
          return !body.items.includes(item.id);
        });
      } catch (e) {
        AjaxService.sendNotification(e, ERROR_STATUS);
      } finally {
        this.loading = false;
      }
    },
    toggleSelect({ id }) {
      if (this.selectedIds.has(id)) {
        this.selectedIds.delete(id);
        this.selectedIds = new Set(this.selectedIds);
      } else {
        this.selectedIds.add(id);
        this.selectedIds = new Set(this.selectedIds);
      }
    },
    toggleSelectAll() {
      if (this.selectedIds.size > 0) {
        this.selectedIds = new Set();
      } else {
        this.selectedIds = new Set(this.items.map(({ id }) => id));
      }
    },
    displayItemDetails(item) {
      this.currentItem = item;
    },
    displayItemList() {
      this.currentItem = null;
    },
  },
};
</script>
