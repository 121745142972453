import Vue from "vue";

export default class BorrowerRelationships {
  constructor(relationships) {
    this.update(relationships);
  }

  update(relationships) {
    this.relationships = relationships || [];
    this.spouseList = this.updateSpouseList();
    this.spouseLookup = this.updateSpouseLookup();
  }

  findRelationshipIndex(borrower) {
    return this.relationships.findIndex(relationship => {
      return relationship.borrowers.some(b => b.id == borrower.id);
    });
  }

  unMarry(borrower) {
    const relationshipIndex = this.findRelationshipIndex(borrower);
    if (relationshipIndex > -1) {
      let newRelationships = this.relationships.filter((_, index) => {
        return index != relationshipIndex;
      });
      this.update(newRelationships);
    }
  }

  marry(borrower1, borrower2) {
    if (!borrower1 || !borrower2) {
      return;
    }
    if (this.findSpouse(borrower1.id)?.id == borrower2.id) {
      return;
    }
    this.unMarry(borrower1);
    let newRelationships = this.relationships.concat({
      id: null,
      borrowers: [{ ...borrower1 }, { ...borrower2 }],
    });
    this.update(newRelationships);
  }

  updateSpouseLookup() {
    const lookup = {};
    this.relationships.forEach(relationship => {
      const borrowers = relationship.borrowers;
      lookup[`${borrowers[0]?.id}`] = borrowers[1];
      lookup[`${borrowers[1]?.id}`] = borrowers[0];
    });
    return lookup;
  }

  updateSpouseList() {
    let list = [];
    this.relationships.forEach(relationship => {
      if (relationship.borrowers.length > 1) {
        relationship.borrowers.forEach(borrower => {
          list.push(borrower.id);
        });
      }
    });
    return list;
  }

  notInRelationship(borrowers) {
    return borrowers.filter(borrower => {
      return !this.spouseList.includes(borrower.id);
    });
  }

  findSpouse(borrower_id) {
    return this.spouseLookup[`${borrower_id}`];
  }
}
