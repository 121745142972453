import objectToSnakeCase from "utils/object_to_snake_case";

const AjaxService = PS.Services.AjaxService;
const Command = PS.Models.Command;
const Events = PS.Models.Lenders.Events;
const Lender = PS.Models.Lenders.Lender;

class SendSettingsUpdate extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.SaveChangesRequested,
      PS.Services.MessageBusService.lenderBus,
      {}
    );
  }
}

class StartUpdating extends Command {
  execute() {
    this.state.updating = true;
    return Promise.resolve();
  }
}

class FinishUpdating extends Command {
  execute() {
    this.state.updating = false;
    return Promise.resolve();
  }
}

class SendToServer extends Command {
  execute() {
    const data = {
      lender: {
        misc_info: this.state.lender.miscInfo,
        default_servicer_id: this.state.lender.defaultServicerId,
        default_lender_servicer: this.state.lender.defaultLenderServicer,
        default_analysts: this.state.lender.defaultAnalysts.map(a => objectToSnakeCase(a)),
        construction_draw_vendor: this.state.lender.constructionDrawVendor,
      },
    };
    const url = `/manage/lenders/${this.state.lender.psId}`;

    return AjaxService.withNotifications({ onError: "Couldn't save changes." })
      .patch(url, data, { "Content-type": "application/json" })
      .then(
        data => {
          this.state.lender = new Lender(data);
        },
        () => {
          /* catch failure to invoke FinishUpdating */
        }
      );
  }
}

PS.Models.Lenders.Commands.UpdateSettings = {
  FinishUpdating: FinishUpdating,
  SendSettingsUpdate: SendSettingsUpdate,
  SendToServer: SendToServer,
  StartUpdating: StartUpdating,
};
