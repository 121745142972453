import moment from "moment";

const FORMAT_IN = "YYYY-MM-DD";
const FORMAT_OUT = "MM/DD/YYYY";
const TIME_FORMAT_OUT = "MMMM DD, YYYY [at] h:mm A";
export const DATE_TIME_FORMAT = "MMM DD, YYYY - h:mm A";

export default class DateFormatter {
  static formatDate(value, formatOut = FORMAT_OUT, formatIn = FORMAT_IN) {
    const date = moment(value, formatIn);

    if (date.isValid()) {
      return date.format(formatOut);
    } else {
      return value;
    }
  }

  static formatTime(value, formatOut = TIME_FORMAT_OUT) {
    if (!value) return "";

    const date = moment(value);

    if (date.isValid()) {
      return date.format(formatOut);
    } else {
      return value;
    }
  }

  static fromNow(value, formatIn = FORMAT_IN) {
    const date = moment(value, formatIn);

    if (date.isValid()) {
      return date.fromNow();
    } else {
      return value;
    }
  }
}
window.PS.Models.DateFormatter = DateFormatter;
