const YES_NO_OPTIONS = [{ name: "Yes", value: "1" }, { name: "No", value: "0" }];

class RadioField extends PS.Models.Field {
  constructor(element) {
    super(element);

    this.collection = PS.Models.Field.expandCollection(element.collection || []);
  }
}

class YesNoRadioField extends RadioField {
  constructor(collection) {
    super(collection);

    this.collection = YES_NO_OPTIONS;
  }
}

PS.Models.Fields.RadioField = RadioField;
PS.Models.Fields.YesNoRadioField = YesNoRadioField;
