
<template>
  <div>
    <base-text-field
      id="name"
      :label="label"
      disabled
      :value="borrower.name"
    />
    <div v-if="showCheck" class="borrower-marriage-checkbox">
      <base-checkbox
        :value="borrower.isMarriedToOther"
        @input="onCheck(borrower, $event)"
      >
        Borrower is married to another borrower
      </base-checkbox>
    </div>
    <base-select-field
      v-if="borrower.isMarriedToOther && currentMarriageOptions[`${borrower.id}`].length"
      :value="borrower.currentSpouse"
      label="Spouse"
      placeholder="Select One"
      class="spouse-select"
      :options="currentMarriageOptions[`${borrower.id}`]"
      @input="onInput(borrower, $event)"
    />
  </div>
</template>

<script>
export default {
  name: "BorrowerField",
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    borrower: {
      type: Object,
      required: true,
    },
    currentMarriageOptions: {
      type: Object,
      required: false,
      default: () => {},
    },
    marriagesPresent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    showCheck() {
      return (
        this.marriagesPresent &&
        this.borrower.maritalStatus == "married" &&
        this.borrower.borrowerType == "individual"
      );
    },
  },
  methods: {
    onCheck(borrower, event) {
      this.borrower.isMarriedToOther = event;
      this.$emit("onCheck", borrower, event);
    },
    onInput(borrower, event) {
      this.$emit("updateSpouse", borrower, event);
    },
  },
};
</script>
