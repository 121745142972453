<template>
  <div>
    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="wire_date">
        <label>Wire Date</label>
        <input
          name="date"
          type="text"
          class="date-picker form-control"
          v-model="transaction.wire_date"
          v-datepicker
          v-mask:date />
      </field-with-error>
    </div>
    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="off_platform">
        <div class="checkbox">
          <label>
            <input
              name="offPlatform"
              type="checkbox"
              v-model="transaction.off_platform" />
            Off-Platform
          </label>
        </div>
      </field-with-error>
      <field-with-error :errors="errors" field="payoff">
        <div class="checkbox">
          <label>
            <input
              name="payoff"
              type="checkbox"
              v-model="transaction.payoff"
              @change="onPayoffBalance" />
            Payoff
          </label>
        </div>
      </field-with-error>
      <field-with-error :errors="errors" field="return_funds">
        <div class="checkbox">
          <label>
            <input
              name="returnFunds"
              type="checkbox"
              v-model="transaction.return_funds" />
            Return Funds
          </label>
        </div>
      </field-with-error>
    </div>
    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="total_reduction">
        <label>Total Reduction</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input
            name="totalReduction"
            type="currency"
            class="form-control"
            v-model="transaction.total_reduction"
            v-mask:currency />
        </div>
      </field-with-error>
    </div>
    <div class="row">
      <div class="col-md-6 form-group control-wrapper">
        <field-with-error :errors="errors" field="inspection_fee">
          <label>Inspection Fee</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              name="inspectionFee"
              type="text"
              class="form-control"
              v-model="transaction.inspection_fee"
              v-mask:currency />
          </div>
        </field-with-error>
      </div>
      <div class="col-md-6 form-group control-wrapper">
        <field-with-error :errors="errors" field="wire_fee">
          <label>Wire Fee</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              name="wireFee"
              type="text"
              class="form-control"
              v-model="transaction.wire_fee"
              v-mask:currency />
          </div>
        </field-with-error>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 form-group control-wrapper">
        <field-with-error :errors="errors" field="setup_fee">
          <label>Setup Fee</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              name="setupFee"
              type="text"
              class="form-control"
              v-model="transaction.setup_fee"
              v-mask:currency />
          </div>
        </field-with-error>
      </div>
      <div class="col-md-6 form-group control-wrapper">
        <field-with-error :errors="errors" field="other_fee">
          <label>Other Fee</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              name="otherFee"
              type="text"
              class="form-control"
              v-model="transaction.other_fee"
              v-mask:currency />
          </div>
        </field-with-error>
      </div>
    </div>
    <div class="form-group control-wrapper">
      <label>Net Amount To Wire</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          name="netAmountToWire"
          type="currency"
          class="form-control"
          disabled="true"
          v-model="netAmountToWire"
          v-mask:currency />
      </div>
    </div>
    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="notes">
        <label>Notes</label>
        <textarea
          name="note"
          class="form-control"
          placeholder="Optional"
          v-model="transaction.note">
        </textarea>
      </field-with-error>
    </div>
    <div class="modal-actions">
      <a class="btn btn-cancel-cta" @click="close">Cancel</a>
      <a class="btn btn-action-cta"
        @click="submit"
        :disabled="!canSend">
        Save
      </a>
    </div>
  </div>
</template>
<script>
import NumberFormatter from "models/formatters/number_formatter";
import FormMixin from "components/transaction/form_mixin";
import compact from "lodash/compact";

export default {
  mixins: [FormMixin],
  name: "transaction-construction-draw-form",
  props: {
    loan: {
      type: Object,
      required: true,
    },
    balances: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      url: `/manage/loans/${this.loan.toParam}/transactions/construction_draws`,
      transaction: {
        wire_date: undefined,
        total_reduction: undefined,
        return_funds: false,
        inspection_fee: undefined,
        wire_fee: undefined,
        setup_fee: undefined,
        other_fee: undefined,
        note: undefined,
        payoff: false,
        off_platform: false,
      },
    };
  },
  computed: {
    canSend() {
      const { wire_date, total_reduction } = this.transaction;

      return (
        !this.inFlight && this.isNotEmptyString(wire_date) && this.isNotEmptyString(total_reduction)
      );
    },
    netAmountToWire() {
      const totalReduction = NumberFormatter.unmaskNumber(this.transaction.total_reduction || 0);

      const totalFees = compact([
        this.transaction.inspection_fee,
        this.transaction.wire_fee,
        this.transaction.setup_fee,
        this.transaction.other_fee,
      ]).reduce((acc, value) => {
        return acc + NumberFormatter.unmaskNumber(value);
      }, 0);

      return totalReduction - totalFees;
    },
  },
  methods: {
    clear() {
      this.transaction.wire_date = undefined;
      this.transaction.return_funds = false;
      this.transaction.total_reduction = undefined;
      this.transaction.note = undefined;
      this.transaction.payoff = false;
      this.transaction.off_platform = false;
      this.resetFees();
    },
    resetFees() {
      this.transaction.inspection_fee = undefined;
      this.transaction.wire_fee = undefined;
      this.transaction.setup_fee = undefined;
      this.transaction.other_fee = undefined;
    },
    onPayoffBalance() {
      if (this.transaction.payoff) {
        this.transaction.total_reduction = this.transaction.off_platform
          ? this.balances.constructionRemainingOffPlatform
          : this.balances.constructionRemaining;

        this.transaction.return_funds = false;
      } else {
        this.resetFees();
        this.transaction.total_reduction = undefined;
      }
    },
  },
};
</script>
