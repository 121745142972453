<template>
  <span>
    <span v-if="isTruncated" v-popover.dynamic>
      {{ truncatedValue }}
    </span>
    <span :class="{ 'hidden':isTruncated }" v-html="value" />
  </span>
</template>
<script>
import truncate from "lodash/truncate";

const MAX_LENGTH = 20;

export default {
  name: "truncated-text",
  props: {
    value: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
      default: MAX_LENGTH,
    },
  },
  computed: {
    truncatedValue() {
      return truncate(this.value, { length: this.maxLength });
    },
    isTruncated() {
      return this.value.length > this.maxLength;
    },
  },
};
</script>
