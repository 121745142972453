<template>
  <div v-if="visibleTasks.length" class="loan-task-section">
    <div
      v-if="header"
      class="loan-task-section-header"
      data-toggle="collapse"
      :data-target="'#' + sectionId"
      role="button"
      aria-expanded="true"
      aria-controls="collapseExample"
    >
      {{ sectionHeader }}
      <i class="fas fa-angle-down" />
      <i class="fas fa-angle-up" />
    </div>
    <div :id="sectionId" class="loan-task-section-component collapse show">
      <div v-for="task in visibleTasks" :key="'tasks-' + task.id" :class="buildStyleOptions(task)">
        <div v-if="multipleSubmit" class="task-select-checkbox">
          <input
            :id="'task-select-' + task.id"
            v-model="tasksToSubmit"
            type="checkbox"
            :value="task.id"
            @change="onSelectedChange"
          >
          <label :for="'task-select-' + task.id" />
        </div>

        <loan-documents-drop-to-upload
          :metadata="{ taskId: task.id }"
          :initial-options="uploadSettings"
          :upload-disabled="task.documentsUploadDisabled"
          class="task-upload-container"
          @on-upload-success="recordView(task)"
          @on-upload-started="uploadEvent($event, task)"
        >
          <template slot="dropzone">
            <div class="drop-file-to-upload-placeholder-task-list">Drop to Upload</div>
          </template>

          <task
            :task="task"
            :documents="documents"
            :is-seen="isSeen(task)"
            @click.native="openTask(task)"
          />
        </loan-documents-drop-to-upload>
      </div>
    </div>
  </div>
</template>

<script>
import kebabCase from "lodash/kebabCase";
import Task from "components/activity_feed_events/loan_tasks/task";
import LoanDocumentsDropToUpload from "components/loans/documents/drop_to_upload";
import Ajax from "services/ajax_service";

export default {
  name: "loan-tasks-section",
  components: {
    Task,
    LoanDocumentsDropToUpload,
  },
  props: {
    header: String,
    tasks: Array,
    selectedTasks: {
      type: Array,
      default: () => [],
    },
    seenTasksIds: Array,
    documents: Array,
    multipleSubmit: {
      type: Boolean,
      default: false,
    },
    uploadSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tasksToSubmit: this.selectedTasks.slice(),
    };
  },
  computed: {
    sectionHeader() {
      return this.header + " (" + this.activeTasks.length + ")";
    },
    sectionId() {
      return `${kebabCase(this.header)}`;
    },
    openTasks() {
      return this.tasks.filter(task => task.isOpen);
    },
    activeTasks() {
      return this.tasks.filter(task => !task.isConfirmed);
    },
    visibleTasks() {
      if (this.multipleSubmit) {
        return this.tasks.filter(task => task.canComplete);
      }

      return this.tasks;
    },
  },
  methods: {
    recordView(task) {
      if (this.seenTasksIds.includes(task.id)) return;

      Ajax.post(task.viewsPath).then(() => this.seenTasksIds.push(task.id));
    },
    uploadEvent(event, task) {
      this.$emit("on-upload-started", event, task);
    },
    openTask(task) {
      this.recordView(task);

      this.$emit("open", task);
    },
    isSeen(task) {
      return this.seenTasksIds.includes(task.id);
    },
    openTaskEditForm(index) {
      this.loanTasks.forEach((task, i) => {
        task.isEditing = i === index;
      });
    },
    closeTaskEditForm(index) {
      this.loanTasks.some((task, i) => {
        if (i === index) {
          task.isEditing = false;
          return true;
        }
      });
    },
    buildStyleOptions(task) {
      const actionType = `${kebabCase(task.definition.actionType)}-action`;
      const styles = {
        "loan-task": true,
        "lender-task": task.definition.taskType === "lender",
        "open-task": task.isOpen,
        "completed-task": task.isConfirmed,
        "inprogress-task": task.isPending,
        "internal-task": task.isInternal,
      };

      styles[actionType] = true;

      return styles;
    },
    onSelectedChange() {
      this.$emit("on-selected-tasks-change", this.tasksToSubmit);
    },
  },
  watch: {
    selectedTasks(newValue) {
      this.tasksToSubmit = (newValue || []).slice();
    },
  },
};
</script>
