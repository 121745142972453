<template>
  <a :href="loanLink" target="_blank"><slot>{{ item.loanPsId }}</slot></a>
</template>

<script>
export default {
  name: "report-import-item-loan-link",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    loanLink() {
      return `/manage/loans/${this.item.loanPsId}/document_tracking`;
    },
  },
};
</script>
