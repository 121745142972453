<template>
  <div class="row workout-case asset-management">
    <section-header
      :editing="editing"
      :updating="saving"
      :subheading="getSubheading"
      :actions-hidden="!canEdit"
      @edit="handleEdit"
      @cancel="handleCancel"
      @update="handleSave"
    >
      <h3>{{ getHeadingTitle }}</h3>
    </section-header>
    <div class="col-12">
      <div class="details-row no-border row">
        <div class="col-6">
          <ul class="loan-block">
            <li>
              <editable-input
                :value="workoutCase.processCode"
                label="Case ID"
                :editing="editing"
                @input="e => workoutCase.processCode = e.target.value"
              />
            </li>
            <li>
              <editable-select
                :value="workoutCase.foreclosureCauseId"
                label="Workout Case Cause"
                :options="loanForeclosureCauseTypes"
                :editing="editing"
                no-status-label="No Cause"
                @input="e => workoutCase.foreclosureCauseId = e.target.value"
              />
            </li>
          </ul>
        </div>
        <div class="col-6">
          <ul class="loan-block">
            <li>
              <editable-datepicker
                :value="workoutCase.startDate"
                label="Case Start Date"
                :editing="editing"
                @input="value => workoutCase.startDate = value"
              />
            </li>
            <li>
              <editable-select
                :value="workoutCase.foreclosureOutcomeId"
                label="Workout Case Outcome"
                :options="loanForeclosureOutcomeTypes"
                :editing="editing"
                no-status-label="No Outcome"
                @input="e => workoutCase.foreclosureOutcomeId = e.target.value"
              />
            </li>
          </ul>
        </div>
      </div>
      <foreclosure-document-tracking
        :loan-ps-id="loanPsId"
        :workout-case-id="workoutCase.id"
        :workout-document-types="loanForeclosureDocumentTrackingTypes"
      />
      <workout-case-notes
        :value="workoutCase.notes"
        :editing="editing"
        @input="e => workoutCase.notes = e.target.value"
      />
    </div>
  </div>
</template>

<script>
import SectionHeader from "../section_header";
import EditableInput from "../editable_input";
import EditableDatepicker from "../editable_date";
import ForeclosureDocumentTracking from "./foreclosure_document_tracking";
import EditableSelect from "../editable_select";
import WorkoutCaseNotes from "./workout_case_notes";
import AjaxService from "services/ajax_service";
import { formatDate } from "filters";

import get from "lodash/get";

const serializePayload = (payload, nextCaseNumber = 1) => ({
  case_number: payload.caseNumber || nextCaseNumber,
  process_code: payload.processCode,
  start_date: payload.startDate,
  end_date: payload.endDate,
  loan_foreclosure_cause_id: payload.foreclosureCauseId,
  loan_foreclosure_outcome_id: payload.foreclosureOutcomeId,
  notes: payload.notes,
});

export default {
  name: "workout-case-detail",
  components: {
    SectionHeader,
    EditableInput,
    EditableDatepicker,
    EditableSelect,
    ForeclosureDocumentTracking,
    WorkoutCaseNotes,
  },
  props: {
    loanPsId: {
      type: String,
      required: true,
    },
    nextWorkoutCaseNumber: {
      type: Number,
      default: 1,
    },
  },
  data() {
    const workoutCase = PSData.foreclosureWorkoutCase || this.initialWorkoutCase();
    const isInitialEditMode = workoutCase["caseNumber"] == undefined;
    return {
      editing: isInitialEditMode,
      redirect: false,
      saving: false,
      workoutCase: workoutCase,
    };
  },
  computed: {
    loanForeclosureCauseTypes: () => PSData.loanForeclosureCauseTypes || [],
    loanForeclosureOutcomeTypes: () => PSData.loanForeclosureOutcomeTypes || [],
    loanForeclosureDocumentTrackingTypes: () => PSData.loanForeclosureDocumentTypes || [],
    canEdit: () => PSData.assetManagementCanEdit,
    caseNumber() {
      return this.workoutCase.caseNumber;
    },
    getHeadingTitle() {
      return `Workout Case ${this.caseNumber || this.nextWorkoutCaseNumber}`;
    },
    getSubheading() {
      if (!this.workoutCase.createdAt) return "";
      return `Added ${formatDate(
        new Date(this.workoutCase.createdAt),
        "MMMM DD, YYYY [at] h:mm A"
      )}`;
    },
  },
  methods: {
    initialWorkoutCase() {
      return {
        startDate: new Date().toLocaleDateString("en-US"),
      };
    },
    handleEdit() {
      this.editing = true;
    },
    handleCancel() {
      window.location.href = `/manage/loans/${this.loanPsId}/asset_management`;
    },
    postUpdate(caseNumber) {
      this.editing = false;
      this.saving = false;
      if (this.redirect) {
        window.location.href = `/manage/loans/${
          this.loanPsId
        }/asset_management/workout_cases/${caseNumber}`;
      }
    },
    async handleSave() {
      this.saving = true;
      const payload = serializePayload(this.workoutCase, this.nextWorkoutCaseNumber);
      try {
        await AjaxService.withNotifications({
          onSuccess: "Workout Case was succesfully updated",
          onError: this.errorMessage,
        }).patchJSON(
          `/manage/loans/${this.loanPsId}/asset_management/workout_cases/${
            payload.case_number
          }/upsert`,
          payload
        );
        this.redirect = true;
      } catch (error) {
        Bugsnag.notify(
          { name: "Request Error", message: "Unable to update foreclosure workout case" },
          { metaData: { xhr: error } }
        );
      } finally {
        this.postUpdate(payload.case_number);
      }
    },
    errorMessage(resp) {
      const message = get(resp, "data.error") || "Unable to update foreclosure workout case";
      return { message, status: "error" };
    },
  },
};
</script>
