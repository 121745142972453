<template>
  <div class="active-portfolio-summary-bar">
    <div class="panel-header">{{ chartTitle }}</div>
    <div ref="activePortfolioChart" :class="{'active-portfolio-chart': true, empty: isChartEmpty}" />
    <div class="chart-legend">
      <div class="legend-container">
        <div class="group-title">
          {{ summaryData.current.groupToTotalRatio }} {{ summaryData.current.label }}
        </div>
        <div class="group-container current" />
      </div>
      <div class="legend-container">
        <div class="group-title">
          {{ summaryData.non_performing.groupToTotalRatio }} {{ summaryData.non_performing.label }}
        </div>
        <div class="group-container non-performing" />
      </div>
    </div>
  </div>
</template>

<script>
import c3 from "c3";
import pluralizer from "pluralize";
import { select } from "d3";
import keyBy from "lodash/keyBy";

const CHART_HEIGHT = 37;
const BAR_HEIGHT = 31;

export default {
  name: "ActivePortfolioSummaryBar",
  props: {
    summaryData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: {
        current: this.summaryData.current.categories,
        nonPerforming: this.summaryData.non_performing.categories,
      },
    };
  },
  computed: {
    combinedLoanData() {
      return [
        ...this.data.current.map(cat => [cat.name, cat.amount]),
        ...this.data.nonPerforming.map(cat => [cat.name, cat.amount]),
      ];
    },
    chartTitle() {
      const loansCount = this.summaryData.total_active.loansCount;
      const totalValue = this.summaryData.total_active.totalVolume;

      return `${loansCount} Active ${pluralizer("loan", loansCount)} totalling ${totalValue}`;
    },
    chartData() {
      return {
        data: {
          columns: this.combinedLoanData,
          type: "bar",
          groups: [this.combinedLoanData.map(loan => loan[0])],
          order: "asc",
          stack: {
            normalize: true,
          },
          colors: {
            current: "#3399ff",
            late: "#ffbb00",
            late_30: "#ff7701",
            matured: "#bb3377",
            foreclosure: "#2b3b44",
            late_60_plus: "#ee4444",
          },
        },
        size: {
          height: CHART_HEIGHT,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        axis: {
          rotated: true,
          y: {
            show: false,
          },
          x: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        bar: {
          width: BAR_HEIGHT,
        },
        tooltip: {
          show: false,
        },
      };
    },
    isChartEmpty() {
      return this.summaryData.total_active.loansCount === 0;
    },
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      const chart = c3.generate({ ...this.chartData, bindto: this.$refs.activePortfolioChart });

      this.appendLegend(".current", this.data.current, chart);
      this.appendLegend(".non-performing", this.data.nonPerforming, chart);
    },
    appendLegend(container, data, chart) {
      const groupedData = keyBy(data, "name");

      select(container)
        .selectAll("span")
        .data(data.map(category => category.name))
        .enter()
        .append("div")
        .attr("class", "legend-item")
        .attr("data-id", function(id) {
          return id;
        })
        .each(function(id) {
          select(this)
            .append("span")
            .attr("class", `legend-color ${id}`);

          select(this)
            .append("span")
            .attr("class", "legend-label-amount")
            .html(id => {
              return groupedData[id].amountLabel;
            });

          select(this)
            .append("span")
            .html(id => {
              return `${groupedData[id].label} (${groupedData[id].spread})`;
            });
        })
        .on("mouseover", id => {
          if (groupedData[id].amount === 0) return;

          chart.focus(id);
        })
        .on("mouseout", () => chart.revert());
    },
  },
};
</script>
