<template>
  <notification
    icon="archive"
    variant="dark"
  >
    <div class="notification-group-message-description">
      {{ description }}
    </div>
  </notification>
</template>

<script>
import Notification from "components/shared/notification";

export default {
  name: "archived-loan-alert",
  components: {
    Notification,
  },
  props: {
    date: {
      type: String,
      default: "",
    },
  },
  computed: {
    description() {
      return `This loan was archived on ${this.date}.`;
    },
  },
};
</script>
