import PayoffStatement from "models/payoff_statement";
import Ajax from "services/ajax_service";
import get from "lodash/get";
import uniqueId from "lodash/uniqueId";
import { STATUSES } from "components/manage/payoff_statement/action_button_mixin";
import { success, error } from "services/notification";
import Location from "services/location_service";

const JSON_HEADER = { "Content-Type": "application/json" };

export const STATES = {
  show: "show",
  duplicate: "duplicate",
  new: "new",
  edit: "edit",
};

export default {
  props: {
    state: {
      type: String,
      default: () => STATES.new,
    },
  },
  computed: {
    isEditable() {
      return !this.created && ![STATES.show].includes(this.currentState);
    },
    isEdit() {
      return this.currentState === STATES.edit;
    },
    showActionButtons() {
      return this.isPersisted && this.currentState !== STATES.edit;
    },
    routes() {
      return {
        servicing: get(PSData, "routes.servicing"),
        show: this.statement.routes.show,
        saveDraft: this.statement.routes.saveDraft,
        submit: this.statement.routes.save,
        calculateInterest: this.statement.routes.calculateInterest,
        update: this.statement.routes.update,
        transition: this.statement.routes.transition,
        refresh: this.statement.routes.refresh,
        fetchFees: this.statement.routes.fetchFees,
      };
    },
    isPersisted() {
      return this.statement.id !== undefined && this.statement.id !== null;
    },
    statusClass() {
      return ["payoff-statement-status", `payoff-statement-status-${this.statement.status}`];
    },
  },
  methods: {
    addComment() {
      this.statement.comments.push({ id: uniqueId(), comment: null });
    },
    addFee() {
      this.statement.fees.push({
        id: uniqueId(),
        feeType: null,
        amount: 0,
        waivedAmount: 0,
      });
    },
    removeFee(fee) {
      const fees = this.statement.fees.filter(record => record.id != fee.id);

      this.statement.update({ fees });
      this.syncInterest();
    },
    removeComment(comment) {
      const comments = this.statement.comments.filter(record => record.id != comment.id);
      this.statement.update({ comments });
    },
    async syncInterest() {
      if (this.abortController) {
        this.abortController.abort();
      }

      this.abortController = new AbortController();

      const result = await this.makeRequest(
        this.routes.calculateInterest,
        "post",
        this.statement.toHash(),
        () => {},
        this.abortController.signal
      );
      this.statement.update(result.interestData);
    },
    async makeRequest(
      url,
      method = "post",
      resource = this.statement.toHash(),
      postCallback = this.updateCreatedStatement,
      signal = null
    ) {
      this.isLoading = true;

      var result = await Ajax[method](url, { resource }, JSON_HEADER, signal);

      if (result.errors !== undefined) {
        this.errors = result.errors;
      }
      this.errorType = result.errorType;
      this.isLoading = false;

      postCallback(result);

      return result;
    },
    updateCreatedStatement(result) {
      if (!this.errors) {
        success(this.notification(`submit.${this.currentState}`));
        this.created = true;
        this.currentState = STATES.show;
        this.setStatement(result);
      } else {
        error(this.notification("submit.onError"));
      }
    },
    setStatement(result) {
      if (result.statement) {
        this.statement = new PayoffStatement(result.statement);
      }
    },
    showCompletedPopup() {
      this.isCompletedPopupShown = true;
    },
    hideCompletedPopup(notify) {
      this.isCompletedPopupShown = false;

      if (notify && this.statement.status !== STATUSES.approved) {
        success(this.notification(this.statement.status));
      }
    },
    setStatus(result) {
      this.errors = result.errors;
      if (!this.errors) {
        this.statement.status = result.statement.status;
        this.statement.statusLabel = result.statement.statusLabel;
      }
    },
    showStatement() {
      Location.href = this.routes.show;
    },
    persist() {
      this.makeRequest(this.routes.update, "patch");
    },
    cancel() {
      if (this.isPersisted && !this.isEdit) {
        this.updateStatus("cancelled").then(() => (Location.href = this.routes.servicing));
      } else {
        success(this.notification("cancelled"));
        Location.href = this.routes.servicing;
      }
    },
    async refresh() {
      const result = await this.makeRequest(
        this.routes.refresh,
        "post",
        this.statement.toHash(),
        this.setStatement
      );

      if (!result.errors) {
        success(this.notification("refresh"));
      }
    },
    async updateStatus(status) {
      const result = await this.makeRequest(
        this.routes.transition,
        "post",
        { status },
        this.setStatus
      );

      if (!result.errors && this.notification(status)) {
        success(this.notification(status));
      }
    },
    async updateStatusWithGuardrail(status) {
      const params = {
        status: status,
        guardrail: true,
      };

      const result = await this.makeRequest(this.routes.transition, "post", params, this.setStatus);

      if (!result.errors) {
        success(this.notification(status) || this.notification("transition.onSuccess"));
      } else {
        error(this.notification(`transition.onError.${status}`));
      }
    },
  },
};
