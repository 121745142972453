import LoanTasksSettings from "components/manage/settings/loan_tasks/index";
import LoanTasksTemplateVersion from "components/tasklist/templates/template_revision";
import VueRouter from "vue-router";
import { tasklistTemplateRevisionRoute } from "models/tasklist_template_revision";

$(() => {
  const el = document.querySelector(".v-manage-settings-loan-tasks-controller");

  if (!el) {
    return;
  }

  const routes = [
    { path: "/", component: LoanTasksSettings },
    {
      path: tasklistTemplateRevisionRoute(":template_id", ":id"),
      component: LoanTasksTemplateVersion,
    },
  ];

  const router = new VueRouter({
    routes,
  });

  new Vue({
    router,
    el,
  });
});
