<template>
  <base-text-field
    v-model="preview.value.value"
    :label="preview.name"
    :disabled="true"
  />
</template>

<script>
import Preview from "models/workflow_engine/preview";

// import VariableComment from "../variable_comment";

export default Vue.extend({
  name: "string-preview-component",
  components: {
    // VariableComment,
  },
  props: {
    preview: {
      type: Preview,
      required: true,
    },
  },
});
</script>
