<template>
  <modal-view :show="show" :on-close="onClose" class="base-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          Add Borrower
          <a @click.prevent="onClose" href="#close">&times;</a>
        </h4>
      </div>

      <div class="modal-body">
        <form @keypress.enter="submit">
          <div class="row">
            <div class="col-12 form-group">
              <with-errors on="name" :errors="errors">
                <label>Name</label>
                <input v-model="borrower.name" type="text" class="form-control">
              </with-errors>
            </div>
          </div>

          <div class="row">
            <div class="col-12 form-group">
              <with-errors on="address.address" :errors="errors">
                <label>Mailing Address</label>
                <input v-model="borrower.address.address" type="text" class="form-control">
              </with-errors>
            </div>
          </div>

          <div class="row">
            <div class="col-12 form-group">
              <with-errors on="address.city" :errors="errors">
                <label>City</label>
                <input v-model="borrower.address.city" type="text" class="form-control">
              </with-errors>
            </div>
          </div>

          <div class="row">
            <div class="col-6 form-group">
              <with-errors on="address.state" :errors="errors">
                <label>State</label>
                <select v-model="borrower.address.state" class="form-control">
                  <option value />
                  <option v-for="(label, value) in usStates" :key="value" :value="value">
                    {{ label }}
                  </option>
                </select>
              </with-errors>
            </div>
            <div class="col-6 form-group">
              <with-errors on="address.postalCode" :errors="errors">
                <label>ZIP Code</label>
                <input v-model="borrower.address.postalCode" v-mask:zip type="text" class="form-control">
              </with-errors>
            </div>
          </div>

          <div class="row">
            <div class="col-6 control-wrapper">
              <with-errors on="email" :errors="errors">
                <label>Email</label>
                <input v-model="borrower.email" type="text" class="form-control">
              </with-errors>
            </div>
            <div class="col-6 control-wrapper">
              <with-errors on="phone" :errors="errors">
                <label>Phone Number</label>
                <input v-model="borrower.phone" v-mask:phone type="text" class="form-control">
              </with-errors>
            </div>
          </div>

          <div class="row">
            <div class="col-6 control-wrapper">
              <with-errors on="borrowerType" :errors="errors">
                <label>Borrower Type</label>
                <select v-model="borrower.borrowerType" class="form-control">
                  <option value />
                  <option v-for="(label, value) in borrowerTypes" :key="value" :value="value">
                    {{ label }}
                  </option>
                </select>
              </with-errors>
            </div>
            <div class="col-6 control-wrapper">
              <with-errors on="tin" :errors="errors">
                <label>{{ tinLabel }}</label>
                <input v-show="!borrower.isIndividual && !borrower.isEntity" maxlength="9" v-model="borrower.tin" type="text" class="form-control">
                <input v-show="borrower.isIndividual" v-mask:ssn v-model="borrower.tin" type="text" class="form-control">
                <input v-show="borrower.isEntity" v-mask:ein v-model="borrower.tin" type="text" class="form-control">
              </with-errors>
            </div>
          </div>
          <template v-if="borrower.isIndividual">
            <div class="row">
              <div class="col-6 control-wrapper">
                <with-errors on="gender" :errors="errors">
                  <label>Gender</label>
                  <select v-model="borrower.gender" class="form-control">
                    <option value />
                    <option v-for="(label, value) in genders" :key="value" :value="value">
                      {{ label }}
                    </option>
                  </select>
                </with-errors>
              </div>
              <div class="col-6 control-wrapper">
                <with-errors on="dateOfBirth" :errors="errors">
                  <label>Date of Birth</label>
                  <input
                    v-model="borrower.dateOfBirth"
                    v-mask:date
                    v-datepicker="{ maxDate: -1 }"
                    type="text"
                    class="form-control"
                  >
                </with-errors>
              </div>
            </div>
            <div class="row">
              <div class="col-6 control-wrapper">
                <with-errors on="residency" :errors="errors">
                  <label for="residency">Residency Status</label>
                  <select
                    id="residency"
                    v-model="borrower.residency"
                    name="residency"
                    class="form-control"
                  >
                    <option value />
                    <option v-for="(label, name) in borrowerResidency" :key="name" :value="name">
                      {{ label }}
                    </option>
                  </select>
                </with-errors>
              </div>
            </div>
          </template>
        </form>
      </div>

      <div class="modal-footer">
        <button @click.prevent="onClose" class="btn btn-link">
          Cancel
        </button>
        <button @click.prevent="submit" :class="{ disabled: inFlight }" class="btn btn-action-cta">
          Save
        </button>
      </div>
    </div>
  </modal-view>
</template>

<script>
import Borrower from "models/borrower";
import ModalView from "components/modal_view";
import WithErrors from "components/shared/with_errors";
import { Mask } from "directives/inputs";
import AjaxService from "services/ajax_service";
import { error } from "services/notification";

export default {
  name: "borrower-add-modal",
  components: {
    ModalView,
    WithErrors,
  },
  directives: {
    Mask,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    borrowerResidency: {
      type: Object,
      default: () => ({}),
    },
    borrowerTypes: {
      type: Object,
      default: () => ({}),
    },
    usStates: {
      type: Object,
      default: () => ({}),
    },
    genders: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      borrower: new Borrower(),
      routes: PSData.routes || {},
      inFlight: false,
      errors: {},
    };
  },
  computed: {
    tinLabel() {
      return this.borrower.tinFieldLabel;
    },
  },
  methods: {
    submit() {
      if (this.inFlight) return;

      this.inFlight = true;

      const url = this.routes.loanBorrowers;
      const payload = { borrower: this.borrower.toHash() };

      return AjaxService.post(url, payload)
        .then(data => this.onCreated(data))
        .catch(({ response, data }) => {
          if (response.status === 422) {
            this.errors = data.errors;
          } else {
            error("Something went wrong");
          }
        })
        .finally(() => (this.inFlight = false));
    },
    onCreated(borrower) {
      this.$emit("borrower-created", borrower);
      this.onClose();
    },
    onClose() {
      this.$emit("close");
      this.borrower = new Borrower();
      this.errors = {};
    },
  },
};
</script>
