<template>
  <params-provider v-slot="{ params, setParams }">
    <admin :filters="params" @filter="setParams" />
  </params-provider>
</template>

<script>
import ParamsProvider from "components/shared/params_provider";
import Admin from "./admin";

export default {
  components: {
    Admin,
    ParamsProvider,
  },
};
</script>
