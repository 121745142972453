<template>
  <table class="table versions">
    <thead>
      <tr>
        <th>When</th>
        <th>Who</th>
        <th>What</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(version, index) in versions" :key="index">
        <td>{{ version.when }}</td>
        <td>{{ version.who }}</td>
        <td>
          <span>{{ version.what.title }}</span>
          <ul class="list-unstyled">
            <li v-for="(item, key) in version.what.changes" :key="key">
              Set
              <strong>{{ item.attr }}</strong>
              to
              <i>{{ item.to }}</i>
              <template v-if="version.what.eventType === 'update'">
                &nbsp;from
                <i>{{ item.from }}</i>
              </template>
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default Vue.component("versions-table", {
  props: {
    versions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
});
</script>
