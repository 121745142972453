<template>
  <div class="checkbox-control" @click.stop="handleChecked">
    <input type="checkbox" :checked="checked" v-if="!inFlight" :disabled="!canComplete"/>
    <label>
      <span class="control-toggle" v-if="!inFlight"></span>
      <span class="ps-loader ps-loader-small" v-if="inFlight"></span>
      <span>{{text}}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "sla-task-todo",
  props: {
    canComplete: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    checked: false,
    inFlight: false,
  }),
  methods: {
    handleChecked(event) {
      if (this.checked || !this.canComplete) {
        return;
      }

      this.checked = true;
      this.inFlight = true;

      this.$emit("checked", () => (this.inFlight = false));
    },
  },
};
</script>
