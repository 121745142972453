import DateService from "services/date_service";

export default class LoanSponsor {
  constructor(loanSponsor = {}) {
    this.update(loanSponsor);
  }

  update(loanSponsor) {
    this._raw = loanSponsor;
    this.id = loanSponsor.id;
    this.type = loanSponsor.type;
    this.tin = loanSponsor.tin;
    this.residency = loanSponsor.residency;
    this.bankruptcyDate = loanSponsor.bankruptcyDate;
    this.foreclosureDate = loanSponsor.foreclosureDate;
  }

  get isPersisted() {
    return !!this.id;
  }

  get tinLast4() {
    if (!this.tin) return "";

    switch (true) {
      case this.isSSNFormat:
        return `*** ** ${this.tin.slice(-4)}`;
      case this.isEINFormat:
        return `** ***${this.tin.slice(-4)}`;
      default:
        return `*****${this.tin.slice(-4)}`;
    }
  }

  get tinFieldLabel() {
    switch (true) {
      case this.isSSNFormat:
        return "SSN";
      case this.isEINFormat:
        return "EIN";
      case this.isTINFormat:
        return "SSN/TIN";
      default:
        return "SSN/EIN";
    }
  }

  get tinFieldMask() {
    switch (true) {
      case this.isSSNFormat:
        return "ssn";
      case this.isEINFormat:
        return "ein";
      default:
        return "tin";
    }
  }

  get isSSNFormat() {
    return this.isIndividual && !this.isForeignNational;
  }

  get isTINFormat() {
    return this.isIndividual && this.isForeignNational;
  }

  get isEINFormat() {
    return this.isEntity;
  }

  get isIndividual() {
    return this.type === "individual";
  }

  get isTrust() {
    return this.type === "trust";
  }

  get isEntity() {
    return !this.isIndividual && !!this.type;
  }

  get isForeignNational() {
    return !!this.residency && this.residency === "foreign_national";
  }

  get formattedBankruptcyDate() {
    return DateService.formatDate(this.bankruptcyDate);
  }

  get formattedForeclosureDate() {
    return DateService.formatDate(this.foreclosureDate);
  }

  reset() {
    this.update(this._raw);
  }
}
