export default {
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      this.$emit("submit");
    },
    saveDraft() {
      this.$emit("save-draft");
    },
    removeFee(fee) {
      this.$emit("remove-fee", fee);
    },
    addFee() {
      this.$emit("add-fee");
    },
    addComment() {
      this.$emit("add-comment");
    },
    removeComment(comment) {
      this.$emit("remove-comment", comment);
    },
    syncInterest() {
      this.$emit("sync-interest");
    },
  },
};
