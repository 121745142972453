<template>
  <div>
    <div v-show="!addPropertyView">
      <breadcrumb-navigation :items="breadcrumbItems" />

      <h3 class="loan-properties-header">
        Properties ({{ propertiesCount }})
      </h3>
      <base-table
        draggable
        hoverable
        :data="properties"
        :loading="loading"
        @click="goToProperty"
        @dragstart="dragstart"
        @drop="drop"
        @dragover="dragover"
        @dragleave="dragleave"
      >
        <base-column v-slot="props" label="Address">
          {{ props.row.data.street }}
        </base-column>
        <base-column v-slot="props" label="Zip">
          {{ props.row.data.postalCode }}
        </base-column>
        <base-column v-slot="props" label="Type">
          {{ props.row.data.propertyTypeLabel }}
        </base-column>
      </base-table>
      <div class="pt-2">
        <a
          href="#"
          title="Add Property"
          class="view-table-add-item"
          @click.prevent="createNewProperty"
        >
          + Add Property
        </a>
      </div>
      <management v-if="showManagement" />
    </div>
    <loan-property-form
      v-show="addPropertyView"
      :property="property"
      @on-cancel="onPropertyCreateCancel"
      @on-save="onPropertySave"
    />
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import Location from "services/location_service";
import Property from "models/lender_form/property";
import BreadcrumbNavigation from "../shared/breadcrumb_navigation";
import LoanPropertyForm from "./property_form.vue";
import Management from "./management/show.vue";

export default {
  name: "loan-properties-list",
  components: {
    BreadcrumbNavigation,
    LoanPropertyForm,
    Management,
  },
  data() {
    return {
      properties: (PSData.properties || []).map(
        property => new Property(property, PSData.propertyOptions, PSData.loan)
      ),
      routes: PSData.routes || {},
      property: new Property({}, PSData.propertyOptions, PSData.loan),
      showManagement: ["commercial_multifamily", "residential_rental_long_term"].includes(
        PSData.loan.programType
      ),
      loading: false,
      addPropertyView: false,
      draggingRow: {},
      draggingRowIndex: 0,
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          name: "Details",
          link: this.routes.loan,
        },
        {
          name: "Properties",
        },
      ];
    },
    propertiesCount() {
      return this.properties.length;
    },
  },
  methods: {
    dragstart(payload) {
      payload.event.dataTransfer.effectAllowed = "copy";
      this.draggingRow = payload.row;
      this.draggingRowIndex = payload.index;
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = "copy";
      payload.event.target.closest("tr").classList.add("is-draggable");
      payload.event.preventDefault();
    },
    dragleave(payload) {
      payload.event.target.closest("tr").classList.remove("is-draggable");
      payload.event.preventDefault();
    },
    drop(payload) {
      const droppedOnRowIndex = payload.index;

      payload.event.target.closest("tr").classList.remove("is-draggable");
      this.properties.splice(this.draggingRowIndex, 1);
      this.properties.splice(droppedOnRowIndex, 0, this.draggingRow);

      const properties = this.properties.map((property, index) => {
        return {
          id: property.data.id,
          order: index + 1,
        };
      });

      this.loading = true;
      return AjaxService.withNotifications()
        .patchJSON(this.routes.propertiesBulkUpdate, {
          properties: properties,
        })
        .then(data => {
          (data || []).forEach((property, index) => {
            this.$set(
              this.properties,
              new Property(property, PSData.propertyOptions, PSData.loan),
              index
            );
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    propertyLink(id) {
      return `${this.routes.property}/${id}`;
    },
    createNewProperty() {
      this.addPropertyView = true;
    },
    onPropertySave(newProperty) {
      Location.href = this.propertyLink(newProperty.id);
    },
    onPropertyCreateCancel() {
      this.addPropertyView = false;
      this.property.reset();
    },
    goToProperty(row) {
      Location.href = this.propertyLink(row.data.id);
    },
  },
};
</script>
<style scoped>
.base-table >>> .table tr {
  cursor: pointer;
}
</style>
