import { SectionType } from "models/lender_form/fields";
import Section from "models/lender_form/section";

export default class ProgramTypeSection extends Section {
  constructor(fields) {
    super(SectionType.ProgramType, fields);
  }

  update() {
    super.update();
  }

  get isResiBridge() {
    return this.fields.programType.value === "residential_bridge";
  }

  get isResiRental() {
    return this.fields.programType.value === "residential_rental_long_term";
  }

  get isMultifamilyBridge() {
    return this.fields.programType.value === "commercial_multifamily";
  }

  get isGroundUpConstruction() {
    return this.fields.programType.value === "ground_up_construction";
  }
}
