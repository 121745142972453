<template>
  <div v-show="!field.isHidden" :field-id="field.id" class="diligence-row js-diligence-field">
    <div class="row">
      <div class="col-5">
        <label :id="fieldId" :for="field.id" class="diligence-control-label">
          <template v-if="field.tooltip">
            <span
              v-popover="{ placement: 'top', container: fieldIdSelector }"
              class="name with-popover"
            >
              {{ field.label }}
            </span>
            <div class="hidden" v-html="field.tooltip"></div>
            <span class="hint">
              {{ field.hint }}
            </span>
          </template>
          <template v-else="">
            <span class="name">
              {{ field.label }}
            </span>
            <span class="hint">
              {{ field.hint }}
            </span>
          </template>
        </label>
      </div>
      <slot></slot>
      <div class="col-2">
        <div class="action-buttons">
          <div
            v-if="!field.hasAutocalculation()"
            class="radio-control radio-control-inline js-validate-checkbox"
          >
            <input :id="validateId" v-model="field.validated" type="checkbox" />
            <label :for="validateId">
              <span class="control-toggle"></span>
            </label>
          </div>
          <div v-if="field.hasAutocalculation()" class="formula-icon">
            <i class="fas fa-magic" title="This field is automatically calculated"></i>
          </div>
          <note-icon :tooltip="field.note" @click.native="toggleNote"></note-icon>
        </div>
      </div>
    </div>
    <edit-note
      v-if="showNote"
      v-model="field.note"
      class="row"
      @cancel-note="closeNote"
      @save-note="saveNote"
    ></edit-note>
  </div>
</template>

<script>
const Commands = PS.Models.Diligence.Commands;
const Event = PS.Models.Event;

export default Vue.component("diligence-document-field", {
  props: ["document", "field"],
  data: function() {
    return {
      messageBus: PS.Services.MessageBusService.diligenceBus,
      ruleBook: undefined,
      showNote: false,
    };
  },
  computed: {
    validateId: function() {
      return `validated_${this.field.id}`;
    },
    fieldId: function() {
      return `field-${this.field.id}`;
    },
    fieldIdSelector: function() {
      return `#field-${this.field.id}`;
    },
  },
  methods: {
    toggleNote: function() {
      this.showNote = !this.showNote;
    },
    closeNote: function() {
      this.showNote = false;
    },
    saveNote: function() {
      new Commands.Field.RequestNoteSaving(new Event(), this).execute();
      this.showNote = false;
    },
  },
  mounted: function() {
    this.ruleBook = new PS.Models.Diligence.FieldRule(this);
    this.messageBus.attachRulebook(this.ruleBook);
  },
  beforeDestroy: function() {
    this.messageBus.detachRulebook(this.ruleBook);
  },
});
</script>
