import BankAccount from "./bank_account";
import FundingEntity from "./funding_entity";

export default class Account {
  static buildAccount(accountType, account) {
    if (accountType === "capital") {
      return new FundingEntity(account);
    } else {
      return new BankAccount(account);
    }
  }
}
