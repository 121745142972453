<template>
  <validation-provider v-slot="{ valid, validate }" rules="required" slim>
    <select2
      v-model="innerValue"
      :options="{data: options, width: width}"
      :custom-options="customOptions(valid)"
    />
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

import Select2 from "components/shared/select2";

export default {
  components: {
    select2: Select2,
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: "300px",
    },
  },
  data() {
    return { innerValue: this.value };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.innerValue = val;
      },
    },
    innerValue: {
      immediate: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    customOptions(valid) {
      return { extraClasses: this.validationClass(valid) };
    },
    validationClass: valid => (valid === null ? null : valid ? "is-valid" : "is-invalid"),
  },
};
</script>
