<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content">
      <div class="modal-header">
        <h4>New Transaction</h4>
      </div>
      <div class="transaction-form modal-body">
        <form>
          <div class="form-group control-wrapper">
            <label>Transaction Type</label>
            <select
              name="type"
              class="form-control"
              v-model="type">
              <option v-for="(description, value) in types" :key="value" :value="value">
                {{description}}
              </option>
            </select>
          </div>
          <component :is="formType" @close="onClose" @created="onCreated" :show="show"
                     :type="type" :loan="loan" :balances="balances"></component>
        </form>
      </div>
    </div>
  </modal-view>
</template>

<script>
import ModalView from "components/modal_view.vue";

import DrawForm from "components/transaction/draw_form.vue";
import ConstructionDrawForm from "components/transaction/construction_draw/form.vue";
import PrincipalAdjustmentForm from "components/transaction/principal_adjustment/form.vue";
import BPiecePayoffForm from "components/transaction/b_piece_payoff/form.vue";

export default {
  name: "transaction-modal",
  components: {
    ModalView,
    DrawForm,
    ConstructionDrawForm,
    PrincipalAdjustmentForm,
    BPiecePayoffForm,
  },
  props: {
    show: {
      required: true,
    },
    loan: {
      type: Object,
      required: true,
    },
    balances: {
      type: Object,
      required: true,
    },
  },
  data() {
    const defaultType = "construction_draw";

    return {
      type: defaultType,
      defaultType: defaultType,
      transaction: undefined,
      types: {
        construction_draw: "Construction Draw",
        escrow_draw: "Escrow Draw",
        interest_draw: "Interest Draw",
        other_draw: "Other Draw",
        principal_adjustment: "Principal Adjustment",
        b_piece_payoff: "B-Piece Payoff",
      },
    };
  },
  computed: {
    formType() {
      switch (this.type) {
        case "construction_draw":
          return "ConstructionDrawForm";
        case "escrow_draw":
        case "interest_draw":
        case "other_draw":
          return "DrawForm";
        case "principal_adjustment":
          return "PrincipalAdjustmentForm";
        case "b_piece_payoff":
          return "BPiecePayoffForm";
      }
    },
    url() {
      return this.urls[this.type];
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
      this.type = this.defaultType;
    },
    onCreated(payload) {
      this.$emit("created", payload);
    },
  },
};
</script>
