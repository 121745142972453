import enumerize from "../utils/enumerize";

const PropertyType = enumerize({
  sfr: {
    value: "sfr",
    title: "Single Family Residential (SFR)",
  },
  condo: {
    value: "condo",
    title: "Condo",
  },
  duplex: {
    value: "duplex",
    title: "Duplex",
  },
  triplex: {
    value: "triplex",
    title: "Triplex",
  },
  fourplex: {
    value: "fourplex",
    title: "Fourplex",
  },
});

export default PropertyType;
