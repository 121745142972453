<template>
  <div>
    <div class="mb-4 mt-3">
      <base-breadcrumb data-test="charge-offs-breadcrumbs" :items="breadcrumbs"></base-breadcrumb>
    </div>
    <div class="is-flex is-flex-direction-row mb-4">
      <div class="mr-3">
        <base-header size="medium">Charge-Off</base-header>
      </div>
      <base-tag size="large">DRAFT</base-tag>
    </div>
    <errors-banner :errors="errors.base" :full-width="false" @close="errors.base = []" />
    <div class="columns">
      <div class="column is-half">
        <charge-off-form
          data-test="charge-offs-form"
          :errors="errors"
          :disable-save="isSaveDisabled"
          :disable-process="isProcessDisabled"
          @process="process"
          @save="save"
          @cancel="goToServicing"
        />
      </div>
    </div>
  </div>
</template>
<script>
import RequestMixin from "./request_mixin";
import Location from "services/location_service";
import ChargeOffForm from "./form";
import ErrorsBanner from "./errors_banner";

export default {
  mixins: [RequestMixin],
  components: {
    ChargeOffForm,
    ErrorsBanner,
  },
  data() {
    return {
      isLoading: false,
      errors: {},
    };
  },
  methods: {
    handleResponse(response) {
      const { chargeOffStatement } = response;
      Location.assign(chargeOffStatement.routes.show);
    },
    goToServicing() {
      Location.assign(this.servicingUrl);
    },
    save(chargeOffForm) {
      this.saveChargeOff(chargeOffForm);
    },
    process(chargeOffForm) {
      this.processChargeOff(chargeOffForm);
    },
  },
  computed: {
    canProcessChargeOff() {
      return PSData.chargeOffStatementPolicy.process.allowed;
    },
    isSaveDisabled() {
      return this.isLoading;
    },
    isProcessDisabled() {
      return this.isLoading || !this.canProcessChargeOff;
    },
    servicingUrl() {
      return PSData.routes.servicing;
    },
    breadcrumbs() {
      return [{ link: this.servicingUrl, name: "Servicing" }, { name: "Charge-Off" }];
    },
  },
};
</script>
