<template>
  <base-modal-form
    class="invite-borrowr-modal"
    :show="show"
    save-button-name="Share with Borrower(s)"
    cancel-button-name="Cancel"
    title="Share Tasks"
    @close="onClose"
    @save="sendEmail"
    :save-disabled="isSaving"
  >
    <p class="mb-4">
      By sharing this task the borrower(s) below will receive an invite to your white labeled portal where they can upload required documentation.
    </p>
    <base-text-field
      v-model="borrowerEmail"
      label="Borrower Email"
      :disabled="isSaving"
      :errors="errors.borrower_email"/>
    <base-button
      class="mb-4"
      v-show="!showCoBorrowerEmail"
      icon-left="plus"
      type="link"
      @click="toggleShowCoBorrowerEmail"
    >
      Co-Borrower
    </base-button>
    <div v-show="showCoBorrowerEmail">
      <base-text-field
        v-model="coBorrowerEmail"
        class="pb-2"
        label="Co-Borrower Email"
        :disabled="isSaving"
        :errors="errors.co_borrower_email"/>
      <base-button
        type="link"
        icon-left="times"
        @click="toggleShowCoBorrowerEmail"
      >
        remove
      </base-button>
    </div>
  </base-modal-form>
</template>

<script>
import AjaxService from "services/ajax_service";

export default {
  name: "borrower-invite-modal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      borrowerEmail: "",
      coBorrowerEmail: "",
      errors: {},
      isSaving: false,
      showCoBorrowerEmail: false,
    };
  },
  methods: {
    toggleShowCoBorrowerEmail() {
      this.showCoBorrowerEmail = !this.showCoBorrowerEmail;
      if (!this.showCoBorrowerEmail) {
        this.coBorrowerEmail = "";
        this.errors["co_borrower_email"] = undefined;
      }
    },
    onClose() {
      this.showCoBorrowerEmail = false;
      this.borrowerEmail = "";
      this.coBorrowerEmail = "";
      this.errors = {};
      this.$emit("on-close");
    },
    sendEmail() {
      const messages = { onSuccess: "Tasks shared with borrower(s)", onError: this.onError };

      this.isSaving = true;

      AjaxService.withNotifications(messages)
        .postJSON(PSData.routes.shareTasks, {
          borrower_email: this.borrowerEmail,
          co_borrower_email: this.coBorrowerEmail,
        })
        .then(() => {
          this.onClose();
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    onError({ data }) {
      this.errors = data.errors || {};
      return null;
    },
  },
};
</script>

<style scoped>
.close-icon {
  color: #1a8cff;
}
</style>
