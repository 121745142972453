<template>
  <div v-if="searchQuery" class="instant-search-results">
    <template v-if="results.length">
      <instant-search-result
        v-for="result in results"
        :key="result.index.name"
        :search-query="searchQuery"
        :search-result="result"
      />
    </template>
    <div v-if="loading" class="instant-search-loader">
      <span class="loader" />
    </div>
  </div>
</template>

<script>
import InstantSearchResult from "./instant_search_result";

export default {
  name: "instant-search-results",
  components: {
    InstantSearchResult,
  },
  props: {
    searchQuery: {
      type: String,
      default: "",
    },
    results: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
