<template>
  <div>
    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="wire_date">
        <label>Date</label>
        <input
          name="date"
          type="text"
          class="date-picker form-control"
          v-model="transaction.wire_date"
          v-datepicker
          v-mask:date />
      </field-with-error>
    </div>

    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="adjustment_amount">
        <label>Amount</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input
            name="adjustment_amount"
            type="currency"
            class="form-control"
            v-model="transaction.adjustment_amount"
            v-mask:currency />
        </div>
      </field-with-error>
    </div>

    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="notes">
        <label>Notes</label>
        <textarea
          name="note"
          class="form-control"
          placeholder="Required"
          v-model="transaction.note">
        </textarea>
      </field-with-error>
    </div>

    <div class="modal-actions">
      <a class="btn btn-cancel-cta" @click="close">Cancel</a>
      <a class="btn btn-action-cta"
        @click="submit"
        :disabled="!canSend">
        Save
      </a>
    </div>
  </div>
</template>

<script>
import NumberFormatter from "models/formatters/number_formatter";
import FormMixin from "components/transaction/form_mixin";

export default {
  mixins: [FormMixin],
  name: "transaction-principal-adjustment-form",
  data() {
    const loan = PSData.loan || {};

    return {
      url: `/manage/loans/${loan.toParam}/transactions/principal_adjustments`,
      transaction: {
        adjustment_amount: undefined,
        wire_date: undefined,
        note: undefined,
      },
    };
  },
  computed: {
    canSend() {
      const { wire_date, adjustment_amount, note } = this.transaction;

      return (
        !this.inFlight &&
        this.isNotEmptyString(wire_date) &&
        this.isNotEmptyString(adjustment_amount) &&
        this.isNotEmptyString(note)
      );
    },
  },
  methods: {
    clear() {
      this.transaction.wire_date = undefined;
      this.transaction.adjustment_amount = undefined;
      this.transaction.note = undefined;
    },
  },
};
</script>
