<template>
  <div class="bank-center">
    <div class="bank-center-header">
      <div class="row">
        <div class="col-4">
          <h2>Bank Center <span>As of {{formattedLoadedAt}}</span><div v-show="isLoading" class="ps-loader ps-loader-small"></div></h2>
        </div>
        <div class="col-8">
          <div class="float-right">
            <button
              type="button"
              class="btn btn-secondary-action-cta"
              @click="getBankCenter"
            >
              Refresh List
            </button>
          </div>
        </div>
      </div>
    </div>

    <account-types-menu v-if="isLoaded" :account-types="accountTypesWithSelection" @change="toggleAccountType" ></account-types-menu>
    <account-groups-list
      v-if="isLoaded"
      :account-types="accountTypesWithSelection"
      :account-groups="accountGroups"
      :problem-wire-requests="problemWireRequests"
    >
    </account-groups-list>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import AccountTypesMenu from "./account_types_menu";
import AccountGroupsList from "./account_groups_list";
import Account from "models/bank_center/account";
import ProblemWireRequest from "models/bank_center/problem_wire_request";
import moment from "moment";

export default {
  data: function() {
    return {
      loadedAt: new Date(),
      isLoading: false,
      isLoaded: false,
      accountTypes: [],
      selectedAccountTypes: {},
      accountGroups: {},
      problemWireRequests: [],
      routes: PSData.routes,
    };
  },
  created: function() {},
  mounted: function() {
    this.getBankCenter();
  },
  methods: {
    getBankCenter() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      Promise.all([this.getAllAccounts(), this.getProblemWireRequests()]).then(() => {
        this.isLoading = false;
        this.isLoaded = true;
        this.loadedAt = new Date();
      });
    },

    toggleAccountType(selectedAccountType) {
      const currentState = this.selectedAccountTypes[selectedAccountType.name];

      this.$set(this.selectedAccountTypes, selectedAccountType.name, !currentState);
    },

    getAllAccounts() {
      return this.getAccountTypes().then(() => this.getAllAccountGroups());
    },

    getAccountTypes() {
      const url = this.routes.manageBankCenterAccountTypes;

      return AjaxService.get(url).then(({ accountTypes }) => {
        const internalAccountTypes = [...accountTypes].sort(
          (a, b) => a.displaySequence - b.displaySequence
        );
        this.accountTypes = internalAccountTypes;
      });
    },

    getAllAccountGroups() {
      return Promise.all(
        this.accountTypes.map(accountType => this.getAccountGroup(accountType.name))
      );
    },

    getAccountGroup(accountGroupName) {
      const url = this.getAccountGroupUrl(accountGroupName);
      return this.requestAccounts(url, accountGroupName);
    },

    getAccountGroupUrl(accountGroupName) {
      if (accountGroupName === "capital") {
        return this.routes.manageBankCenterFundingEntities;
      } else {
        const params = new URLSearchParams({ account_type: accountGroupName });
        return `${this.routes.manageBankCenterAccounts}?${params.toString()}`;
      }
    },

    requestAccounts(url, accountGroupName) {
      return AjaxService.get(url).then(({ accounts }) => {
        const internalAccounts = [...accounts]
          .map(account => Account.buildAccount(accountGroupName, account))
          .sort((a, b) => a.name.localeCompare(b.name));
        this.$set(this.accountGroups, accountGroupName, internalAccounts);
      });
    },

    getProblemWireRequests() {
      const url = this.routes.manageBankCenterProblemWireRequests;

      return AjaxService.get(url).then(({ problemWireRequests }) => {
        const wireRequests = problemWireRequests.map(w => new ProblemWireRequest(w));
        this.problemWireRequests = wireRequests;
      });
    },
  },
  components: {
    AccountTypesMenu,
    AccountGroupsList,
  },
  computed: {
    formattedLoadedAt() {
      return moment(this.loadedAt).format("MM/DD/YY hh:mmA");
    },

    accountTypesWithSelection() {
      return this.accountTypes.map(accountType => {
        return {
          ...accountType,
          isSelected: this.selectedAccountTypes[accountType.name],
        };
      });
    },
  },
};
</script>
