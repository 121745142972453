import CopyToClipboard from "components/copy_to_clipboard";
import LenderTagsMixin from "../../mixins/manage/lenders/tags";
import ActivityFeedEventsSection from "components/activity_feed_events/manage/section";
import PiecesOfCollateral from "components/tags/pieces_of_collateral";

$(() => {
  const Commands = PS.Models.Diligence.Commands;

  new Vue({
    el: document.querySelector(".v-manage-diligence-controller"),
    components: {
      CopyToClipboard,
      ActivityFeedEventsSection,
      PiecesOfCollateral,
    },
    mixins: [LenderTagsMixin],
    data: {
      loan: PSData.loan || {},
      documents: PSData.documents || [],
      documentTypes: PSData.primaryDocumentTypes || [],
      documentsInfo: PSData.documentsInfo || [],
      loanStatuses: PSData.loanStatuses || [],
      mode: "diligence",
      rulebook: undefined,
      bus: PS.Services.MessageBusService.diligenceBus,
      canAnalyze: null,
    },
    mounted: function() {
      this.rulebook = new PS.Models.Diligence.DocumentRule(this);
      this.bus.attachRulebook(this.rulebook);
      this.loadPolicy();
    },
    beforeDestroy: function() {
      this.bus.detachRulebook(this.rulebook);
    },
    methods: {
      generateReport: function() {
        new Commands.Document.GenerateReport(new PS.Models.Event(), {}).execute();
      },
      loadPolicy() {
        const formPolicy = new PS.Policies.EditLoanFormPolicy(PSData.loanPolicy);
        this.canAnalyze = formPolicy.canUpdateLoanData();
      },
    },
    computed: {
      inDiligence: function() {
        return this.mode == "diligence";
      },
    },
  });
});
