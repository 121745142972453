<template>
  <div>
    <view-panel>
      <template slot="header">
        Entity Signers
      </template>
      <table class="view-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Title</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="signer in signers" :key="signer.id">
            <td class="view-table-cell-text">
              {{ signer.name }}
            </td>
            <td class="view-table-cell-text">
              {{ signer.title }}
            </td>
            <td v-if="canManage" class="actions">
              <a href="#" title="Edit signer" @click.prevent="editSigner(signer)">
                <i class="fas fa-pencil-alt"/>
              </a>
              <a href="#" title="Delete signer" @click.prevent="deleteSigner(signer)">
                <i class="fas fa-trash-alt"/>
              </a>
            </td>
          </tr>
        </tbody>

        <tfoot v-if="canManage">
          <tr>
            <td colspan="3">
              <a href="#" title="Add Signer" @click.prevent="addSigner">
                + Add Signer
              </a>
            </td>
          </tr>
        </tfoot>
      </table>
    </view-panel>

    <upsert-signer-form-modal
      v-if="showUpsertSignerModal"
      :signer="selectedSigner"
      :on-close="resetSelectedSigner"
      :show="showUpsertSignerModal"
      :routes="routes"
      @on-save="onSignersSave"
    />
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import UpsertSignerFormModal from "./form_modal";
import WindowService from "services/window_service";
import ViewPanel from "components/view_panel.vue";
import MessageBusService from "services/message_bus_service";

export default {
  name: "lender-legal-entities-signers-panel",
  components: { UpsertSignerFormModal, ViewPanel },
  props: {
    routes: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    policy: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      signers: [],
      selectedSigner: undefined,
      bus: MessageBusService.entityBus,
    };
  },
  computed: {
    showUpsertSignerModal() {
      return !!this.selectedSigner;
    },
    canManage() {
      return this.policy.canManage;
    },
  },
  mounted() {
    AjaxService.getJSON(this.routes.signersPath).then(data => {
      this.signers = data;
    });
  },
  methods: {
    addSigner() {
      this.selectedSigner = { id: null, name: null, title: null };
    },
    editSigner(signer) {
      this.selectedSigner = Object.assign({}, signer);
    },
    deleteSigner(signer) {
      if (!WindowService.confirm("Are you sure you want to remove this signer?")) {
        return;
      }

      const messages = {
        onSuccess: "Signer has been deleted",
        onError: ({ response }) => {
          if (response.status === 403) {
            return { message: "Unauthorized", status: "error" };
          } else {
            return { message: "Something went wrong", status: "error" };
          }
        },
      };

      AjaxService.withNotifications(messages)
        .delete(`${this.routes.signersPath}/${signer.id}`)
        .then(data => {
          this.signers = data;
          this.bus.broadcast("entity-updated");
        });
    },
    resetSelectedSigner() {
      this.selectedSigner = undefined;
    },
    onSignersSave(signers) {
      this.signers = signers;
      this.selectedSigner = undefined;
    },
  },
};
</script>
