PS.Models.Autocomplete = PS.Models.Autocomplete || {};
PS.Models.Autocomplete.Events = {};
PS.Models.Autocomplete.Commands = {};
PS.Models.Autocomplete.Datasources = {};
PS.Models.Autocomplete.Const = {
  CONTROL_STATE: {
    EMPTY: "EMPTY",
    LOADING: "LOADING",
    SHOW_SUGGESTIONS: "SHOW_SUGGESTIONS",
    HIDE_SUGGESTIONS: "HIDE_SUGGESTIONS",
  },
  MIN_CHARS_TO_SEARCH: 1,
};

PS.Models.Diligence = PS.Models.Diligence || {};
PS.Models.Diligence.Commands = {};
PS.Models.Diligence.Events = {};

PS.Models.Loan = PS.Models.Loan || {};
PS.Models.Loan.Commands = {};
PS.Models.Loan.Events = {};

PS.Models.LocationMap = PS.Models.LocationMap || {};
PS.Models.LocationMap.Events = {};
PS.Models.LocationMap.Commands = {};

PS.Models.MarketData = PS.Models.MarketData || {};
PS.Models.MarketData.Commands = {};
PS.Models.MarketData.Events = {};
PS.Models.MarketData.Analytics = {};
PS.Models.MarketData.Charts = {};
PS.Models.MarketData.Tables = {};
PS.Models.MarketData.Const = {
  SOURCE_OF_SEARCH: "MARKET_DATA::SOURCE_OF_SEARCH",
};
PS.Mixins.MarketData = {};

PS.Models.MSA = PS.Models.MSA || {};
PS.Models.MSA.Commands = {};
PS.Models.MSA.Events = {};

PS.Models.EmailSubscription = PS.Models.EmailSubscription || {};
PS.Models.EmailSubscription.Commands = {};
PS.Models.EmailSubscription.Events = {};

PS.Models.Permission = PS.Models.Permission || {};
PS.Models.Permission.Commands = {};
PS.Models.Permission.Events = {};

PS.Models.Reports = PS.Models.Reports || {};
PS.Models.Reports.Commands = {};
PS.Models.Reports.Events = {};
PS.Models.Reports.Rule = {};
PS.Models.Reports.Const = {
  TRACKING_SOURCE_KEY: "REPORTS_TRACKING_SOURCE",
};

PS.Models.Lenders = PS.Models.Lenders || {};
PS.Models.Lenders.Commands = {};
PS.Models.Lenders.Commands.UpdateSettings = PS.Models.Lenders.Commands.UpdateSettings || {};
PS.Models.Lenders.Events = {};

PS.Models.File = PS.Models.File || {};
PS.Models.File.Commands = {};
PS.Models.File.Events = {};
PS.Models.MarketData.Tables = PS.Models.MarketData.Tables || {};

PS.Models.FundingEntities = PS.Models.FundingEntities || {};
PS.Models.FundingEntities.Commands = {};
PS.Models.FundingEntities.Events = {};
