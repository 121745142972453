class Subscription {
  constructor(lenderId, category, subscriptions) {
    this.raw = {};
    this.lenderId = lenderId;
    this.category = category;

    this.raw.enabled =
      subscriptions.filter(sub => {
        return sub.lenderId == this.lenderId && sub.category == this.category;
      }).length > 0;

    this.reset();
  }

  get id() {
    return `${this.category}-${this.lenderId}`;
  }

  get isChanged() {
    return this.raw.enabled !== this.enabled;
  }

  reset() {
    this.enabled = this.raw.enabled;
  }
}

window.PS.Models.EmailSubscription.Subscription = Subscription;
