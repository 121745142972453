import snakeCase from "lodash/snakeCase";
import transform from "lodash/transform";
import isObjectLike from "lodash/isObjectLike";
import isString from "lodash/isString";

function objectToSnakeCase(obj) {
  return transform(obj, (result, val, key) => {
    result[snakeCase(key)] = val;
  });
}

// Recursively snake_case nested objects keys
export function objectToSnakeCaseDeep(obj) {
  return transform(obj, (result, value, key) => {
    if (isObjectLike(value) && !Array.isArray(value)) {
      result[snakeCase(key)] = objectToSnakeCaseDeep(value);
    } else {
      result[snakeCase(key)] = value;
    }
  });
}

// Recursively snake_case nested objects keys (including arrays)
export function complexObjectToSnakeCaseDeep(obj) {
  return transform(obj, (result, value, key) => {
    if (Array.isArray(value)) {
      result[snakeCase(key)] = value.map(
        v => (isObjectLike(v) ? complexObjectToSnakeCaseDeep(v) : v)
      );
    } else if (isObjectLike(value) && !Array.isArray(value)) {
      result[snakeCase(key)] = complexObjectToSnakeCaseDeep(value);
    } else {
      result[snakeCase(key)] = value;
    }
  });
}

export function objectAndValuesToSnakeCase(obj) {
  return transform(obj, (result, value, key) => {
    if (isString(value)) {
      result[snakeCase(key)] = snakeCase(value);
    } else {
      result[snakeCase(key)] = value;
    }
  });
}

export default objectToSnakeCase;
