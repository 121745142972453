import { LoanStrategy, PaymentStructure, LoanProduct, AccountType } from "../../enums";
import BaseScenario from "./base_scenario";

export default class ResidentialBridgeScenario extends BaseScenario {
  loanProduct = LoanProduct.residentialBridge.value;
  noteOrigination;
  loanPurpose;
  loanStrategy;
  zipCode;
  asIsValue;
  constructionBudget;
  arv;
  constructionReserve;
  ficoScore;
  trackRecord;
  loanTerm;
  lenderSpread;
  bPiece;
  fipsCode;
  propertyState;
  loanAmount;
  ltv;
  convertedLtvRate;
  customRate;
  psLoanId;
  cbsaType;
  cbsaCode;
  lenderAccountType;

  _validationMap = {
    loanPurpose: () => {
      return this._isValuePresent(this.loanPurpose);
    },
    noteOrigination: () => {
      return this._isBroker() ? true : this._isValuePresent(this.noteOrigination);
    },
    loanStrategy: () => {
      return this._isValuePresent(this.loanStrategy);
    },
    zipCode: () => {
      return this._isValuePresent(this.zipCode) && this.zipCode.length === 5;
    },
    asIsValue: () => {
      return this._hasAsIsValue();
    },
    constructionBudget: () => {
      return this._hasConstructionBudget();
    },
    arv: () => {
      return this._hasValidArvValue();
    },
    constructionReserve: () => {
      return this._hasConstructionStrategy()
        ? this._isValuePresent(this.constructionReserve)
        : true;
    },
    ficoScore: () => {
      return (
        (this._isValuePresent(this.ficoScore) && this.ficoScore >= 300) || this.ficoScore <= 900
      );
    },
    trackRecord: () => {
      return this._isValuePresent(this.trackRecord);
    },
    lenderSpread: () => {
      return this._isBroker()
        ? true
        : this._isValuePresent(this.lenderSpread) &&
            this.lenderSpread >= 0 &&
            this.lenderSpread <= 3;
    },
  };

  _isBridgeStrategy() {
    return (
      this._isValuePresent(this.loanStrategy) && this.loanStrategy === LoanStrategy.bridge.value
    );
  }

  _hasConstructionStrategy() {
    return (
      this._isValuePresent(this.loanStrategy) &&
      [
        LoanStrategy.lightConstruction.value,
        LoanStrategy.heavyConstruction.value,
        LoanStrategy.flexProduct.value,
      ].includes(this.loanStrategy)
    );
  }

  _hasConstructionBudget() {
    return this._isValuePresent(this.constructionBudget);
  }

  _hasPositiveConstructionBudget() {
    return this._hasConstructionBudget() && this.constructionBudget > 0;
  }

  _hasAsIsValue() {
    return this._isValuePresent(this.asIsValue) && this.asIsValue > 0;
  }

  _hasArvValue() {
    return this._isValuePresent(this.arv) && this.arv > 0;
  }

  _isArvRequired() {
    return (
      this._hasConstructionStrategy() ||
      (!this._hasConstructionStrategy() && this._hasPositiveConstructionBudget())
    );
  }

  _hasValidArvValue() {
    if (!this._hasArvValue()) {
      return !this._isArvRequired() ? true : false;
    }

    if (this._hasArvValue() && this._hasAsIsValue() && this._hasConstructionBudget()) {
      return (
        parseFloat(this.arv) >= parseFloat(this.asIsValue) + parseFloat(this.constructionBudget)
      );
    }

    return false;
  }

  _isBroker() {
    return (
      this._isValuePresent(this.lenderAccountType) &&
      this.lenderAccountType === AccountType.broker.value
    );
  }

  calculateLoanAmount(ltv) {
    let loanAmount = null;
    let bPiece = this.bPiece || 0;
    let constructionReserve = this.constructionReserve || 0;

    if (this.hasValidProperties("asIsValue")) {
      loanAmount = parseFloat(
        (
          (parseFloat(ltv) / 100) * parseFloat(this.asIsValue) +
          parseFloat(bPiece) +
          parseFloat(constructionReserve)
        ).toFixed(2)
      );
    }

    return loanAmount;
  }

  calculateLtv(loanAmount) {
    let ltv = null;
    let bPiece = this.bPiece || 0;
    let constructionReserve = this.constructionReserve || 0;

    if (this.hasValidProperties("asIsValue")) {
      ltv = parseFloat(
        (
          ((parseFloat(loanAmount) - parseFloat(bPiece) - parseFloat(constructionReserve)) /
            parseFloat(this.asIsValue)) *
          100
        ).toFixed(3)
      );
    }

    return ltv;
  }

  calculateLtarv(loanAmount) {
    let ltarv = null;
    let bPiece = this.bPiece || 0;

    if (this._hasConstructionBudget() && this.hasValidProperties("arv")) {
      ltarv = parseFloat(
        (((parseFloat(loanAmount) - parseFloat(bPiece)) / parseFloat(this.arv)) * 100).toFixed(3)
      );
    }

    return ltarv;
  }

  calculateInterestRate(purchaseRate) {
    let interestRate = null;

    if (this.hasValidProperties("lenderSpread")) {
      interestRate = parseFloat(
        (parseFloat(purchaseRate) + parseFloat(this.lenderSpread)).toFixed(3)
      );
    }

    return interestRate;
  }

  calculateMonthlyPayment(loanAmount, interestRate) {
    let monthlyPayment = null;

    if (this.hasValidProperties("lenderSpread")) {
      monthlyPayment = parseFloat(
        ((parseFloat(loanAmount) * parseFloat(interestRate / 100)) / 12).toFixed(2)
      );
    }

    return monthlyPayment;
  }

  calculateSpreadRevenue(loanAmount, interestRate) {
    let spreadRevenue = null;
    let bPiece = this.bPiece || 0;

    if (this.hasValidProperties("lenderSpread")) {
      let revenue =
        (parseFloat(loanAmount) - parseFloat(bPiece)) * parseFloat(this.lenderSpread / 100) +
        parseFloat(bPiece) * parseFloat(interestRate / 100);
      spreadRevenue = parseFloat((revenue / 12).toFixed(3));
    }

    return spreadRevenue;
  }

  toHash() {
    return {
      as_is_valuation: parseFloat(this.asIsValue),
      b_piece_amount: parseFloat(this.bPiece || 0),
      loan_purpose: this.loanPurpose,
      payment_structure: PaymentStructure.interestOnly.value,
      program_type: this.loanProduct,
      loan_strategy: this.loanStrategy,
      interest_rate: 0,
      funding_strategy: this.noteOrigination,
      ...(this._isValuePresent(this.medianHomePriceInZip) && {
        median_home_price_in_zip: parseFloat(this.medianHomePriceInZip),
      }),
      borrowers: [
        {
          fico_score: parseFloat(this.ficoScore),
          guarantor_track_record: this.trackRecord,
          residency: this.residencyStatus,
        },
      ],
      properties: [
        {
          property_type: this.propertyType,
          postal_code: this.zipCode,
          ...(this.propertyState && {
            state: this.propertyState,
          }),
          ...(this.fipsCode && {
            fips_codes: [this.fipsCode],
          }),
          ...(this._isValuePresent(this.cbsaType) && {
            cbsa_type: this.cbsaType,
          }),
          ...(this._isValuePresent(this.cbsaCode) && {
            cbsa_code: this.cbsaCode,
          }),
        },
      ],
      projected_rehab_budget: parseFloat(this.constructionBudget),
      ...(this._hasConstructionStrategy() && {
        construction_reserve_at_origination: parseFloat(this.constructionReserve),
      }),
      ...(this._isArvRequired() &&
        this._isValuePresent(this.arv) && {
          arv: parseFloat(this.arv),
        }),
      lender_spread: parseFloat(this.lenderSpread),
      ...(this._isValuePresent(this.loanTerm) && {
        original_term: parseFloat(this.loanTerm),
      }),
      ...(this._isValuePresent(this.lenderAccountType) && {
        lender_account_type: this.lenderAccountType,
      }),
    };
  }

  toLoanPayload() {
    return {
      program_type: this.loanProduct,
      lender_note_status: this.noteOrigination,
      loan_purpose: this.loanPurpose,
      loan_strategy: this.loanStrategy,
      zip: this.zipCode,
      draft_as_is_valuation: parseFloat(this.asIsValue),
      projected_rehab_budget: parseFloat(this.constructionBudget),
      ...(this._isArvRequired() &&
        this._isValuePresent(this.arv) && {
          draft_arv: parseFloat(this.arv),
        }),
      ...(this._hasConstructionStrategy() && {
        construction_reserve: parseFloat(this.constructionReserve),
      }),
      borrower_fico: parseFloat(this.ficoScore),
      borrower_guarantor_track_record: this.trackRecord,
      ...(this._isValuePresent(this.loanTerm) && {
        loan_term: parseFloat(this.loanTerm),
      }),
      b_piece_amount: parseFloat(this.bPiece || 0),
      payment_structure: PaymentStructure.interestOnly.value,
    };
  }
}
