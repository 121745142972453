<template>
  <div class="details-row row">
    <div class="col-12">
      <h4>Overview</h4>
    </div>
    <div class="col-4">
      <div class="editable-field-container">
        <editable-field
          label="Seller"
          :value="statement.seller"
          :editing="isEditable"
        />
      </div>
    </div>
    <div class="col-4">
      <div class="editable-field-container">
        <div class="form-group">
          <label class="editable-field-label control-label">Reason for Purchase</label>
          <select
            v-model="statement.payoffReasonType"
            class="form-control"
          >
            <option />
            <option v-for="(label, name) in payoffReasonTypes" :key="name" :value="name">
              {{ label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-4">
      <editable-field
        label="Servicing Released"
        :value="statement.servicingReleased ? 'Yes' : 'No'"
        :editing="isEditable"
      />
    </div>
    <div class="col-4" v-if="!statement.servicingReleased">
      <editable-field
        label="Remaining Construction Reserve"
        :editing="isEditable"
        :disabled="true"
        :value="statement.constructionReserveAmount"
        currency
      />
    </div>
    <div class="col-4" v-if="!statement.servicingReleased">
      <editable-field
        label="Remaining Other Reserve"
        :editing="isEditable"
        :disabled="true"
        :value="statement.otherReserveAmount"
        currency
      />
    </div>
  </div>
</template>

<script>
import EditableField from "components/shared/editable_field.vue";

export default {
  name: "repurchase-statement-edit-overview",
  props: ["payoffReasonTypes", "statement", "isEditable"],
  components: {
    EditableField,
  },
};
</script>
