
<template>
  <div>
    <div class="vesting-clause-header">
      <h4>Full Borrower Vesting Clause</h4>
    </div>
    <div class="vesting-clause-preview" :class="{'disabled' :disableVestingClause}">
      <span>{{ vestingClauseText }}</span>
      <span class="actions">
        <base-button
          type="textBlue"
          label="Edit"
          size="normal"
          icon-left="pencil-alt"
          :disabled="disableVestingClause"
          @click="editVestingClause"
        />
      </span>
    </div>
    <span v-if="disableVestingClause" class="vesting-clause-message">
      Full vesting clause is not editable until each borrower’s legal name has been generated
    </span>
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";

export default {
  name: "VestingClausePreview",
  props: {
    disableVestingClause: {
      type: Boolean,
      default: false,
    },
    vestingClause: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    vestingClauseText() {
      return isEmpty(this.vestingClause) ? "--" : this.vestingClause;
    },
  },
  methods: {
    editVestingClause() {
      this.$emit("edit-vesting-clause");
    },
  },
};
</script>
