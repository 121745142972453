<template>
  <params-provider v-slot="slotProps">
    <hold />
  </params-provider>
</template>

<script>
import ParamsProvider from "components/shared/params_provider";
import Hold from "./hold";

export default {
  components: {
    Hold,
    ParamsProvider,
  },
};
</script>
