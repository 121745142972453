import MidasService from "services/midas_service";
import toPlainObject from "utils/to_plain_object";

const Command = PS.Models.Command;
const MarketData = PS.Models.MarketData;
const SessionStorageService = PS.Services.SessionStorageService;
const SegmentService = PS.Services.SegmentService;
const SOURCE_OF_SEARCH = PS.Models.MarketData.Const.SOURCE_OF_SEARCH;

class LoadRiskAnalysisData extends Command {
  execute() {
    const url = `/manage/loans/${this.event.loanId}/market_data/risk_analysis_data`;

    return PS.Services.AjaxService.get(url).then(
      data => {
        if (!data.error) {
          this.state.analytics.riskAnalysis.success(data);

          this.state.charts.homeIndex.success({
            terminus: data,
          });
        } else {
          this.state.analytics.riskAnalysis.fail(data.error);

          this.state.charts.homeIndex.fail({
            terminus: data.error,
          });
        }
      },
      ({ response }) => {
        Bugsnag.notify(
          { name: "Request Error", message: "Unable to get Risk Analysis Data" },
          { metaData: { custom: { response: toPlainObject(response) } } }
        );
        this.state.analytics.riskAnalysis.fail("Unable to get Risk Analysis Data");

        this.state.charts.homeIndex.fail({
          terminus: "Unable to get Clear Capital data",
        });
      }
    );
  }
}

class LoadLenderMarketData extends Command {
  execute() {
    this.state.source = PS.Services.LocationService.origin + this.event.source;
    this.state.zip = this.event.zip;

    return Promise.resolve();
  }
}

class LoadClearCapitalZipData extends Command {
  execute() {
    const payload = {
      zip: this.event.loanZip,
      loan_id: this.event.loanId,
    };

    return PS.Services.AjaxService.post("/tools/zip_report/search", payload).then(
      data => {
        if (!data.error) {
          this.state.analytics.clearCapitalZip.success(data);
        } else {
          this.state.analytics.clearCapitalZip.fail(data.error);
        }
      },
      ({ response }) => {
        Bugsnag.notify(
          { name: "Request Error", message: "Unable to get MarketData data" },
          { metaData: { custom: { response: toPlainObject(response), payload: payload } } }
        );
        this.state.analytics.clearCapitalZip.fail("Unable to get Clear Capital Data");
      }
    );
  }
}

class TransferSearchToMarketDataPage extends Command {
  execute() {
    const marketDataPage = `${PS.Services.LocationService.origin}/tools/zip_report?zip=${
      this.event.zip
    }`;

    SessionStorageService.setItem(SOURCE_OF_SEARCH, this.event.source);

    PS.Services.LocationService.assign(marketDataPage);

    return Promise.resolve();
  }
}

class TrackMarketDataReportLink extends Command {
  execute() {
    const data = {
      zip: this.event.zip,
      source: PS.Services.LocationService.href,
    };

    SegmentService.track("searchMarketDataReportLink", data);

    return Promise.resolve();
  }
}

class SetPredefinedZip extends Command {
  execute() {
    const searchParams = new URLSearchParams(PS.Services.LocationService.search);
    this.state.predefinedZip = searchParams.get("zip");

    return Promise.resolve();
  }
}

class LoadMidasData extends Command {
  execute() {
    return MidasService.zipReport(this.event.loanZip).then(
      ({ data }) => {
        if (!data.error) {
          const returns = data.forecastedCumulativeReturns || {};
          const timeSeries = data.timeSeriesSummary;

          this.state.analytics.midasZip.success(data.zipCrossSection, data.zipTimeSeriesSummary);
          this.state.analytics.midasMsa.success(data.msaCrossSection, data.msaTimeSeriesSummary);
          this.state.analytics.midasNational.success(
            data.nationalCrossSection,
            data.nationalTimeSeriesSummary
          );

          this.state.charts.homeIndex.success({
            midas: {
              zipTimeSeries: data.zipTimeSeries,
              msaTimeSeries: data.msaTimeSeries,
            },
          });
        } else {
          const message = data.error[0];

          this.state.analytics.midasZip.fail(message);
          this.state.analytics.midasMsa.fail(message);
          this.state.analytics.midasNational.fail(message);

          this.state.charts.homeIndex.fail({
            midas: message,
          });
        }
      },
      result => {
        const name = "Request Error";
        const message = "Unable to get House Canary data";
        const metaData = {
          custom: { response: toPlainObject(result), payload: this.event.options },
        };

        Bugsnag.notify({ name, message }, { metaData });
        this.state.analytics.midasZip.fail(message);
        this.state.analytics.midasMsa.fail(message);
        this.state.analytics.midasNational.fail(message);

        this.state.charts.homeIndex.fail({
          midas: message,
        });
      }
    );
  }
}

class RequestAllMarketDataLoading extends Command {
  execute() {
    return Command.broadcastEvent(
      MarketData.Events.AllMarketDataRequested,
      PS.Services.MessageBusService.loanBus,
      this.event.options
    );
  }
}

class RequestLenderMarketDataLoading extends Command {
  execute() {
    return Command.broadcastEvent(
      MarketData.Events.LenderMarketDataSearchRequested,
      PS.Services.MessageBusService.lenderBus,
      this.event.options
    );
  }
}

class RequestCheckForPredefinedSearch extends Command {
  execute() {
    return Command.broadcastEvent(
      MarketData.Events.CheckForPredefinedSearch,
      PS.Services.MessageBusService.lenderBus,
      this.event.options
    );
  }
}

class TrackMarketDataReportBanner extends Command {
  execute() {
    const data = {
      zip: this.event.zip,
      source: PS.Services.LocationService.href,
    };

    SegmentService.track("searchMarketDataReportBanner", data);

    return Promise.resolve();
  }
}

PS.Models.MarketData.Commands = {
  LoadRiskAnalysisData: LoadRiskAnalysisData,
  LoadClearCapitalZipData: LoadClearCapitalZipData,
  LoadMidasData: LoadMidasData,
  RequestAllMarketDataLoading: RequestAllMarketDataLoading,
  LoadLenderMarketData: LoadLenderMarketData,
  TransferSearchToMarketDataPage: TransferSearchToMarketDataPage,
  TrackMarketDataReportLink: TrackMarketDataReportLink,
  SetPredefinedZip: SetPredefinedZip,
  RequestLenderMarketDataLoading: RequestLenderMarketDataLoading,
  RequestCheckForPredefinedSearch: RequestCheckForPredefinedSearch,
  TrackMarketDataReportBanner: TrackMarketDataReportBanner,
};
