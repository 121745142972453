<template>
  <div class="portfolio-breakdown">
    <active-loans :data="totalActiveLoans" class="panel-bordered" />
    <current-loans :data="currentLoans" class="panel-bordered" />
    <non-performing-loans :data="nonPerformingLoans" class="panel-bordered" />
  </div>
</template>

<script>
import ActiveLoans from "./portfolio_breakdown/active_loans";
import CurrentLoans from "./portfolio_breakdown/current_loans";
import NonPerformingLoans from "./portfolio_breakdown/non_performing_loans";

export default {
  name: "active-portfolio-breakdown",
  components: { ActiveLoans, CurrentLoans, NonPerformingLoans },
  props: {
    portfolioBreakdown: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalActiveLoans() {
      return this.portfolioBreakdown.total_active;
    },
    currentLoans() {
      return this.portfolioBreakdown.current;
    },
    nonPerformingLoans() {
      return this.portfolioBreakdown.non_performing;
    },
  },
};
</script>
