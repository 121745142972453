const LocationMap = PS.Models.LocationMap;
const Event = PS.Models.Event;

class LocationMapSetupRequested extends Event {
  get address() {
    return this.options.address;
  }

  get latitude() {
    const location = this.options.address || {};

    return parseFloat(location.latitude);
  }

  get longitude() {
    const location = this.options.address || {};

    return parseFloat(location.longitude);
  }

  get query() {
    return this.options.query;
  }
}

PS.Models.LocationMap.Events = {
  LocationMapSetupRequested: LocationMapSetupRequested,
};
