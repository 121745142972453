<template>
  <div ref="loan-tasks-container" class="loan-tasks-component">
    <div class="loan-tasks-list-container" :class="{ 'loan-tasks-list-hidden': isTaskView }">
      <div class="loan-tasks-actions">
        <filter-dropdown
          v-model="filterValue"
          :options="filterOptions"
        />
        <loan-tasks-select-multiple-button v-show="showSubmitMultiple" @click="startMultipleSubmit">
          Select Multiple
        </loan-tasks-select-multiple-button>
      </div>
      <div class="loan-tasks-list" :class="tasksListFilterClass">
        <create-task-panel
          :definitions="loanTaskDefinitions"
          :custom-definitions="customLoanTaskDefinitions"
          :question-definitions="questionLoanTaskDefinitions"
          :groups="loanTaskGroups"
          :can-update-loan-task-data="canUpdateLoanTaskData"
          @on-create="createLoanTask"
        />

        <template v-for="group in allTaskGroups">
          <loan-task-section
            v-if="taskSections[group.id].length"
            :key="group.id"
            :header="group.label"
            :tasks="taskSections[group.id]"
            :seen-tasks-ids="seenTasksIds"
            :documents="documents"
            :upload-settings="uploadSettings"
            :multiple-submit="submitMultiple"
            :selected-tasks="selectedTasks"
            :can-update-loan-task-data="canUpdateLoanTaskData"
            :class="{ 'loan-task-section-hidden' : allTasksHidden(taskSections[group.id]) }"
            @on-selected-tasks-change="onSelectedTasksChange"
            @open="openTask"
          />
        </template>

        <loan-tasks-select-multiple-section
          v-if="submitMultiple"
          :all-tasks="tasksToSelect"
          :selected-tasks="tasksToTransit"
          @confirm="confirmLoanTasks"
          @complete="completeLoanTasks"
          @reopen="reopenLoanTasks"
          @cancel="stopMultipleSubmit"
          @select-all="selectAll"
          @deselect-all="deselectAll"
        />
      </div>
    </div>
    <template v-if="isTaskView">
      <loan-task-sla-details
        v-if="currentTask.isSla"
        :task="currentTask"
        @task-updated="updateLoanTask"
        @task-commented="onNewComment"
        @back="openListView"
      />
      <loan-documents-drop-to-upload
        v-else
        :metadata="{ taskId: currentTask.id }"
        :initial-options="uploadSettings"
        class="drop-file-to-upload-task-details"
      >
        <template slot="dropzone">
          <task-drop-file-to-upload-placeholder />
        </template>

        <loan-task-details
          :task="currentTask"
          :documents="documents"
          :upload-settings="uploadSettings"
          :users="users"
          :groups="loanTaskGroups"
          :canUpdateLoanTaskData="canUpdateLoanTaskData"
          :can-update-external-comments="canUpdateExternalComments"
          :can-update-internal-comments="canUpdateInternalComments"
          @task-completed="completeLoanTask"
          @task-confirmed="confirmLoanTask"
          @task-reopened="reopenLoanTask"
          @task-deleted="deleteLoanTask"
          @task-updated="updateLoanTask"
          @task-commented="onNewComment"
          @task-assigned="updateAssignee"
          @assignee-deleted="deleteAssignee"
          @back="openListView"
        />
      </loan-documents-drop-to-upload>
    </template>
    <documents-attachment-modal :documents="documents" />
  </div>
</template>

<script>
import Ajax from "services/ajax_service";
import { error } from "services/notification";
import toPlainObject from "utils/to_plain_object";
import LoanTasksMixin from "components/activity_feed_events/mixins/loan_tasks_mixin";
import LoanTaskSection from "components/activity_feed_events/loan_tasks/section";
import CreateTaskPanel from "components/activity_feed_events/manage/create_task_panel";

import LoanTaskModel from "models/loan_tasks/task";
import LoanTaskMilestone from "models/loan_tasks/milestone";
import LoanTaskDetails from "components/activity_feed_events/manage/loan_tasks/details";
import LoanTaskSlaDetails from "components/activity_feed_events/manage/loan_tasks/sla/details";
import DocumentsAttachmentModal from "components/activity_feed_events/documents_attachment_modal";
import LoanDocumentsDropToUpload from "components/loans/documents/drop_to_upload";
import LoanTasksSelectMultipleButton from "components/activity_feed_events/loan_tasks/multiple_selection/select_multiple_button";
import LoanTasksSelectMultipleSection from "components/activity_feed_events/loan_tasks/multiple_selection/select_multiple_section";
import TaskDropFileToUploadPlaceholder from "components/activity_feed_events/loan_tasks/task_drop_file_to_upload_placeholder";

const NullTaskDefinition = function() {
  this.id = "";
  this.label = "";
  this.isNull = true;
};

export default {
  name: "manage-loan-tasks",
  components: {
    LoanTaskSection,
    LoanTaskDetails,
    LoanTaskSlaDetails,
    DocumentsAttachmentModal,
    LoanDocumentsDropToUpload,
    CreateTaskPanel,
    LoanTasksSelectMultipleButton,
    LoanTasksSelectMultipleSection,
    TaskDropFileToUploadPlaceholder,
  },
  mixins: [LoanTasksMixin],
  props: {
    documents: { type: Array, default: () => PSData.documents },
    loanId: {
      type: String,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    canUpdateLoanTaskData: {
      type: Boolean,
      required: true,
    },
    canUpdateExternalComments: {
      type: Boolean,
      required: true,
    },
    canUpdateInternalComments: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loanTaskDefinitions: [],
      customLoanTaskDefinitions: [],
      questionLoanTaskDefinitions: [],
      isDeleting: false,
    };
  },
  computed: {
    loanTaskRoute() {
      return `/manage/loans/${this.loanId}/tasks`;
    },
  },
  watch: {
    loanId() {
      this.openListView();
      this.loadTasksAndDefinitions();
    },
  },
  created() {
    this.loadTasksAndDefinitions();
  },
  methods: {
    updateAssignee(assignee) {
      const index = this.loanTasks.findIndex(l => l.id === assignee.loanTaskId);

      this.$set(this.loanTasks[index].assignees, 0, assignee);
    },
    deleteAssignee(assignee) {
      const index = this.loanTasks.findIndex(l => l.id === assignee.loanTaskId);

      this.$delete(this.loanTasks[index].assignees, 0);
    },
    createLoanTask(selectedDefinitionId, params) {
      const payload = {
        definition_id: selectedDefinitionId,
        ...params,
      };
      payload.loan_task_group_id = undefined;

      return Ajax.post(this.loanTaskRoute, payload)
        .then(data => {
          if (data.error) {
            Ajax.sendNotification(data.error, "error");
          } else {
            this.updateTasks(data);
          }
        })
        .catch(({ response }) => {
          Bugsnag.notify(
            { name: "Request Error", message: "Unable to create loan task" },
            { metaData: { custom: { response: toPlainObject(response), payload } } }
          );
        });
    },
    completeLoanTask(id) {
      this.transitLoanTask(id, "complete", "Task moved to Pending", "Unable to update loan task");
    },
    confirmLoanTask(id) {
      this.transitLoanTask(id, "confirm", "Task Completed", "Unable to update loan task");
    },
    reopenLoanTask(id) {
      this.transitLoanTask(id, "reopen", "Task Reopened", "Unable to update loan task");
    },
    transitLoanTask(id, transition, successMessage, errorMessage) {
      const url = `${this.loanTaskRoute}/${id}/${transition}`;
      const payload = { id: id };
      this.completeTransitApiCall(url, payload, successMessage, errorMessage);
    },
    completeLoanTasks() {
      this.transitLoanTasks(
        this.selectedTasks,
        "bulk_complete",
        "Tasks moved to Pending",
        "Unable to update loan tasks"
      );
    },
    confirmLoanTasks() {
      this.transitLoanTasks(
        this.selectedTasks,
        "bulk_confirm",
        "Tasks Completed",
        "Unable to update loan tasks"
      );
    },
    reopenLoanTasks() {
      this.transitLoanTasks(
        this.selectedTasks,
        "bulk_reopen",
        "Tasks Reopened",
        "Unable to update loan tasks"
      );
    },
    transitLoanTasks(ids, transition, successMessage, errorMessage) {
      const url = `${this.loanTaskRoute}/${transition}`;
      const payload = { tasks_ids: ids };
      this.completeTransitApiCall(url, payload, successMessage, errorMessage);
    },
    completeTransitApiCall(url, payload, successMessage, errorMessage) {
      if (this.isMarkingComplete) {
        return;
      }

      const messages = {
        onSuccess: successMessage,
        onError: ({ response, data }) => {
          if ([422, 403].includes(response.status)) {
            return {
              message: data.error,
              status: "error",
            };
          } else {
            error(errorMessage);
          }
        },
      };

      this.isMarkingComplete = true;

      return Ajax.withNotifications(messages)
        .patchJSON(url, payload)
        .then(data => {
          this.updateTasks(data);
          if (successMessage === "Task Completed") {
            this.markAsSeen(data.tasks.filter(task => task.id === payload.id));
          }
        })
        .catch(({ response }) => {
          Bugsnag.notify(
            { name: "Request Error", message: errorMessage },
            { metaData: { custom: { response: toPlainObject(response), payload } } }
          );
        })
        .finally(() => {
          this.isMarkingComplete = false;
          this.stopMultipleSubmit();
        });
    },
    deleteLoanTask(id) {
      if (this.isDeleting) {
        return;
      }
      const url = `${this.loanTaskRoute}/${id}`;
      const messages = {
        onSuccess: "Task Deleted",
        onError: () => {
          error("Couldn't Delete the Task");
        },
      };
      this.isDeleting = true;

      return Ajax.withNotifications(messages)
        .delete(url)
        .then(data => {
          this.loanTasks = this.loanTasks.filter(l => l.id !== id);
        })
        .finally(() => {
          this.isDeleting = false;
        });
    },
    updateLoanTask(task) {
      const url = `${this.loanTaskRoute}/${task.id}`;
      task.loan_task_group_id = undefined;

      return Ajax.patch(url, task).then(data => {
        if (data.error) {
          Ajax.sendNotification(data.error, "error");
        } else {
          this.setLoanTaskData(data.task);
        }
      });
    },
    loadTasksAndDefinitions() {
      return Ajax.get(this.loanTaskRoute)
        .then(data => {
          this.updateTasks(data);
          this.customLoanTaskDefinitions = data.custom_definitions;
          this.questionLoanTaskDefinitions = data.question_definitions;
          this.loanTaskGroups = data.groups.map(group => {
            return new LoanTaskMilestone(group);
          });

          this.displayTaskOnLoad();
        })
        .catch(({ response }) => {
          Bugsnag.notify(
            { name: "Request Error", message: "Unable to load loan tasks" },
            { metaData: { custom: { response: toPlainObject(response) } } }
          );
        })
        .finally(() => {
          this.stopMultipleSubmit();
        });
    },
    updateTasks(data) {
      this.loanTasks = this.prepareTasks(data.tasks);
      this.seenTasksIds = data.seen_tasks_ids;
      // Null definition prevents select2 from visually defaulting selection to the first item
      this.loanTaskDefinitions = [new NullTaskDefinition(), ...data.definitions];
    },
    setLoanTaskData(task) {
      const newTask = new LoanTaskModel(task);
      const index = this.loanTasks.findIndex(t => t.id === task.id);

      this.$set(this.loanTasks, index, newTask);
      this.currentTask = newTask;
    },
    markAsSeen(tasks) {
      tasks.forEach(task => {
        if (this.seenTasksIds.includes(task.id)) return;
        Ajax.post(task.viewsPath).then(() => this.seenTasksIds.push(task.id));
      });
    },
  },
};
</script>
<style scoped>
.loan-tasks-list-hidden {
  opacity: 0;
  position: absolute;
}

.loan-tasks-list-container {
  left: 0;
  height: 100%;
  overflow: scroll;
  top: 0;
}
</style>
