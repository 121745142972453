<template>
  <task-list
    :tasks="tasks"
    :workflow="workflow"
    :edit-disabled="disabled"
    @update-task="updateTask"
    @add-duplicate-task="addDuplicateTask"
  />
</template>

<script>
import TaskList from "./task_list";
import AjaxService, { ERROR_STATUS, ERROR_MESSAGE } from "services/ajax_service";
import useAssetManagementStore from "controllers/manage/store/asset_management";

export default {
  components: {
    TaskList,
  },
  props: {
    workflow: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tasks: [],
    };
  },
  created() {
    this.fetchTasks();
  },
  methods: {
    async fetchTasks() {
      const url = this.workflow.routes.tasks;
      try {
        this.tasks = await AjaxService.get(url);
      } catch ({ data }) {
        if (data && data.errors) {
          AjaxService.sendNotification(data.errors.join("\n"), ERROR_STATUS);
        } else {
          AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
        }
      }
    },
    findTaskIndex(targetTask) {
      return this.tasks.findIndex(task => {
        return (
          task.assetManagementTaskTemplateId === targetTask.assetManagementTaskTemplateId &&
          task.id === targetTask.id
        );
      });
    },
    refreshWorkflowData() {
      const store = useAssetManagementStore();
      store.refreshWorkflowItem({ workflow: this.workflow });
    },
    updateTask(updatedTask) {
      const index = this.findTaskIndex(updatedTask);
      this.$set(this.tasks, index, updatedTask);
      this.refreshWorkflowData();
    },
    addDuplicateTask({ duplicate, original }) {
      const index = this.findTaskIndex(original);
      const taskList = [...this.tasks];
      taskList.splice(index + 1, 0, duplicate);
      this.tasks = taskList;
      this.refreshWorkflowData();
    },
  },
};
</script>
