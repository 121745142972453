// Error reporting
import "bugsnag_config";

// Polyfills
import "@babel/polyfill";
import "@webcomponents/custom-elements";

import USP from "url-search-params";
window.URLSearchParams = window.URLSearchParams || USP;
import "whatwg-fetch";

// Custom elements
import "clipboard-copy-element";

// Libs
import "vue";
import "vue_config";

// jQuery and jQuery plugins
import "jquery";
import "jquery-ujs";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-validation";
import "popper.js/dist/popper";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/tooltip";
import "bootstrap/js/dist/popover";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/collapse";
import "select2";

// Analytics
import "analytics";

// Zendesk
import "zendesk";

// Stripe
import "@stripe/stripe-js";

import { Fragment } from "vue-fragment";
Vue.component("fragment", Fragment);

////////////////////////////////////////////////////////////////////////////////
// Application code
////////////////////////////////////////////////////////////////////////////////
import { setupComponents } from "setup_helper";

//base components
import PeerstreetDesignLibrary from "peerstreet-design-library";
Vue.use(PeerstreetDesignLibrary);

// require_tree ./utils
import "utils/debounce";
import "utils/secure_external_windows";
import "utils/sticky_header";
import "utils/text_highlighter";

import "services/ajax_service";
import "services/location_service";
import "services/segment_service";
import "services/session_storage_service";
import "services/local_storage_service";

// require_tree ./models/formatters
import "models/formatters/date_formatter";
import "models/formatters/number_formatter";
import "models/formatters/ordinal_suffix_formatter";
import "models/formatters/value_formatter";

import "models/address";
import "models/constants";
import "models/namespaces";
import "models/event";
import "models/command";
import "models/rule";
import "models/diligence_document/fields/field";
import "models/diligence_document/form";
import "models/diligence_document/formula_variable";
import "models/diligence_document/document_upload";
import "models/diligence_report/value_formatter";
import "models/diligence_report/element";
import "models/autocomplete/events";
import "models/autocomplete/suggestion";
import "models/autocomplete/datasource";
import "models/email_subscription/lender";
import "models/email_subscription/subscription";
import "models/email_subscription/events";
import "models/permissions/role";
import "models/permissions/user";
import "models/permissions/events";
import "models/lenders/lender";
import "models/lenders/user";
import "models/lenders/events";
import "models/property_valuation";
import "models/location_map/events";
import "models/market_data/source";
import "models/market_data/analytics/source";
import "models/market_data/analytics/midas_source";
import "models/market_data/charts/source";
import "models/market_data/charts/midas";
import "models/market_data/charts/home_index";
import "components/market_data/market_data_chart_mixins";
import "models/market_data/tables/row_details_source";

// require_tree ./models
import "models/address";
import "models/autocomplete/commands";
import "models/autocomplete/datasource";
import "models/autocomplete/datasources/dummy";
import "models/autocomplete/datasources/google_address";
import "models/autocomplete/datasources/msa_list";
import "models/autocomplete/events";
import "models/autocomplete/rule";
import "models/autocomplete/suggestion";
import "models/command";
import "models/constants";
import "models/diligence_document/commands/checklist_commands";
import "models/diligence_document/commands/document_commands";
import "models/diligence_document/commands/field_commands";
import "models/diligence_document/commands/fieldset_commands";
import "models/diligence_document/commands/form_commands";
import "models/diligence_document/commands/preview_commands";
import "models/diligence_document/commands/report_commands";
import "models/diligence_document/events/checklist_events";
import "models/diligence_document/events/document_events";
import "models/diligence_document/events/field_events";
import "models/diligence_document/events/fieldset_events";
import "models/diligence_document/events/form_events";
import "models/diligence_document/events/preview_events";
import "models/diligence_document/events/report_events";
import "models/diligence_document/fields/checkbox_field";
import "models/diligence_document/fields/date_field";
import "models/diligence_document/fields/field";
import "models/diligence_document/fields/fieldset";
import "models/diligence_document/fields/number_field";
import "models/diligence_document/fields/memo_field";
import "models/diligence_document/fields/radio_field";
import "models/diligence_document/fields/select_field";
import "models/diligence_document/fields/text_field";
import "models/diligence_document/fields/textarea_field";
import "models/diligence_document/fields/virtual_field";
import "models/diligence_document/form";
import "models/diligence_document/formula";
import "models/diligence_document/formula_helper";
import "models/diligence_document/formula_list";
import "models/diligence_document/formula_variable";
import "models/diligence_document/rules/checklist_rules";
import "models/diligence_document/rules/document_rules";
import "models/diligence_document/rules/field_rules";
import "models/diligence_document/rules/form_rules";
import "models/diligence_document/rules/preview_rules";
import "models/diligence_document/rules/report_rules";
import "models/diligence_document/score_calculator";
import "models/diligence_document/term_calculator";
import "models/diligence_report/element";
import "models/diligence_report/report";
import "models/diligence_report/value_formatter";
import "models/email_subscription/commands/all_item_commands";
import "models/email_subscription/commands/item_commands";
import "models/email_subscription/commands/table_commands";
import "models/email_subscription/events";
import "models/email_subscription/lender";
import "models/email_subscription/rules/all_item_rule";
import "models/email_subscription/rules/item_rule";
import "models/email_subscription/rules/table_rule";
import "models/email_subscription/subscription";
import "models/empty_property_valuation";
import "models/event";
import "models/file/commands/picker_commands";
import "models/file/events/picker_events";
import "models/file/rules/picker_rules";
import "models/formatters/date_formatter";
import "models/formatters/number_formatter";
import "models/formatters/ordinal_suffix_formatter";
import "models/formatters/value_formatter";
import "models/legal_ownership";
import "models/lenders/commands/update_settings_commands";
import "models/lenders/events";
import "models/lenders/lender";
import "models/lenders/rules/update_settings_rule";
import "models/loan/commands/periscope_commands";
import "models/loan/commands/series_commands";
import "models/loan/commands/series_summary_commands";
import "models/loan/events/periscope_events";
import "models/loan/events/series_events";
import "models/loan/events/series_summary_events";
import "models/loan/rules/periscope_rules";
import "models/loan/rules/series_summary_rules";
import "models/loan_series";
import "models/property_valuation_order";
import "models/location_map/commands";
import "models/location_map/events";
import "models/location_map/rule";
import "models/market_data/analytics/clear_capital_zip";
import "models/market_data/analytics/midas/msa";
import "models/market_data/analytics/midas/national";
import "models/market_data/analytics/midas/zip";
import "models/market_data/analytics/midas_source";
import "models/market_data/analytics/risk_analysis";
import "models/market_data/analytics/source";
import "models/market_data/charts/calculator";
import "models/market_data/charts/comparison";
import "models/market_data/charts/cost_of_homeownership";
import "models/market_data/charts/home_index";
import "models/market_data/charts/household_growth";
import "models/market_data/charts/housing_stock";
import "models/market_data/charts/loan_trends";
import "models/market_data/charts/job_growth";
import "models/market_data/charts/listing_stats";
import "models/market_data/charts/midas";
import "models/market_data/charts/occupied_versus_vacant";
import "models/market_data/charts/rent_versus_ownership";
import "models/market_data/charts/risk_of_decline";
import "models/market_data/charts/source";
import "models/market_data/charts/terminus";
import "models/market_data/charts/unemployment_rate";
import "models/market_data/commands/market_data_commands";
import "models/market_data/events/market_data_events";
import "models/market_data/rules/market_data_rules";
import "models/market_data/source";
import "models/market_data/tables/msa_at_glance";
import "models/market_data/tables/row_details_source";
import "models/market_data/tables/zip_at_glance";
import "models/msa/commands/population_commands";
import "models/msa/events/population_events";
import "models/msa/population";
import "models/msa/rules/population_rules";
import "models/namespaces";
import "models/permissions/commands";
import "models/permissions/events";
import "models/permissions/role";
import "models/permissions/rules";
import "models/permissions/user";
import "models/funding_entities/commands";
import "models/funding_entities/events";
import "models/funding_entities/rules";
import "models/reports/commands/common_commands";
import "models/reports/commands/msa_report_commands";
import "models/reports/commands/zip_report_commands";
import "models/reports/events/msa_report_events";
import "models/reports/events/zip_report_events";
import "models/reports/hotlist_loans/commands/hotlist_loans_commands";
import "models/reports/hotlist_loans/events/hotlist_loans_events";
import "models/reports/hotlist_loans/rules/hotlist_loans_rule";
import "models/reports/periods";
import "models/reports/rules/msa_report_rules";
import "models/reports/rules/zip_report_rules";
import "models/rule";
import "models/setting_changes";

// require_tree ./collections
import "collections/document_types";
import "collections/manage/loan_association_merger";
import "collections/manage/loans";

// require_tree ./services
import "services/ajax_service";
import "services/browser_update_service";
import "services/date_service";
import "services/delayed_ajax_service";
import "services/diligence_report_service";
import "services/document_service";
import "services/legal_ownership_service";
import "services/local_storage_service";
import "services/location_service";
import "services/message_bus_service";
import "services/msa_service";
import "services/pusher_service";
import "services/report_loans_service";
import "services/segment_service";
import "services/series_service";
import "services/session_storage_service";
import "services/window_service";

// require_tree ./policies
import "policies/edit_loan_form_policy";
import "policies/edit_lender_form_policy";
import "policies/edit_worklist_policy";

import "filters";

// require_tree ./components
import "components/autocomplete";
import "components/browser-update";
import "components/diligence_document/checklist";
import "components/diligence_document/edit_modal";
import "components/diligence_document/field";
import "components/diligence_document/fieldset";
import "components/diligence_document/file_list";
import "components/diligence_document/file_list_delete_button";
import "components/diligence_document/form";
import "components/diligence_document/preview";
import "components/diligence_report/element";
import "components/diligence_report/report";
import "components/diligence_report/value";
import "components/edit_note";
import "components/email_preferences";
import "components/email_subscription/actions";
import "components/email_subscription/all_item";
import "components/email_subscription/item";
import "components/email_subscription/table";
import "components/google.maps";
import "components/js_upload";
import "components/lender_legal_entities/entities_list";
import "components/lender_legal_entities/entity";
import "components/lender_legal_entities/state";
import "components/lender_legal_entities/history";
import "components/loan_legal_ownerships_item";
import "components/loan_legal_ownerships_table";
import "components/loan_series_item";
import "components/loan_series_summary";
import "components/loan_series_table";
import "components/property_valuations/show";
import "components/loans/loan_attention_toggle";
import "components/loans/sticky_element";
import "components/loans/loan_nav_tabs";
import "components/market_data/market_data_chart_mixins";
import "components/market_data/market_data_charts";
import "components/market_data/market_data_zip_search";
import "components/market_data/market_data_zip_search_button";
import "components/market_data/msa_search_button";
import "components/market_data/msa_report_banner";
import "components/msa_population";
import "components/nav_tabs";
import "components/note_icon";
import "components/open_details";
import "components/periscope_dashboard";
import "components/points_or_amount_input";
import "components/predictive_select";
import "components/reports/loan";
import "components/reports/loans";
import "components/reports/msa_report_details";
import "components/reports/report_row_details";
import "components/reports/zip_report_details";
import "components/select2";
import "components/toggle_password";
import "components/tooltip";
import "components/validate_forms";
import "components/account_manager_display";

// require_tree ./directives
import "directives/inputs";
import "directives/popover";
import "directives/tooltip";

// require_tree ./controllers
import "controllers/ajax_notifications_controller";
import "controllers/browser_update_controller";
import "controllers/edit_loan_controller";
import "controllers/loan_controller";
import "controllers/loans_controller";
import "controllers/manage/dashboard_controller";
import "controllers/manage/diligence_controller";
import "controllers/manage/documents_controller";
import "controllers/manage/edit_lender_info_controller";
import "controllers/manage/edit_lender_legal_entities_controller";
import "controllers/manage/edit_lender_legal_entity_controller";
import "controllers/manage/edit_lender_users_controller";
import "controllers/manage/edit_loan_controller";
import "controllers/manage/new_loan_controller";
import "controllers/manage/email_subscriptions_controller";
import "controllers/manage/loan_tab_controller";
import "controllers/manage/market_data_controller";
import "controllers/manage/workflows_controller";
import "controllers/manage/menu_controller";
import "controllers/manage/open_auction_controller";
import "controllers/manage/permissions_controller";
import "controllers/manage/funding_entities_controller";
import "controllers/manage/bank_center_controller";
import "controllers/manage/allocation_controller";
import "controllers/manage/reports/loans_controller";
import "controllers/manage/reports/purchased_loans_controller";
import "controllers/manage/reports/worklist_controller";
import "controllers/manage/reports/todolist_controller";
import "controllers/manage/resale_statement_controller";
import "controllers/manage/repurchase_statement_controller";
import "controllers/manage/charge_off_statements_controller";
import "controllers/manage/reserve_draw_request_controller";
import "controllers/manage/servicing_controller";
import "controllers/manage/settlement_statement/index_controller";
import "controllers/manage/settlement_statement/show_controller";
import "controllers/manage/loan_header_controller";
import "controllers/manage/lender_banner_controller";
import "controllers/manage/lender_tag_controller";
import "controllers/manage/servicer_loan_boardings_controller";
import "controllers/manage/institution_sales_tracking_controller";
import "controllers/manage/borrowers_controller";
import "controllers/manage/properties_controller";
import "controllers/manage/lenders_list_controller";
import "controllers/market_data_controller";
import "controllers/msa_report_controller";
import "controllers/signup_controller";
import "controllers/header_controller";
import "controllers/docgen_controller";
import "controllers/email_preferences_controller";
import "controllers/loan_index_controller";
import "controllers/manage/responsible_parties_controller";
import "controllers/withdrawn_declined_loans_controller";
import "controllers/loan_index_controller";
import "controllers/manage/pricing_grids_controller";
import "controllers/manage/creditbox_settings_controller";
import "controllers/manage/settings/loan_tapes_batches_controller";
import "controllers/manage/settings/loan_tasks_controller";
import "controllers/manage/signature_requests_controller";
import "controllers/loan_scenarios_controller";
import "controllers/manage/cash_receipts_controller";
import "controllers/manage/restrictions_controller";
import "controllers/manage/asset_management_controller";
import "controllers/manage/pricing_tool_controller";
import "controllers/peersuite_settings_controller";

import AjaxNotificationsController from "controllers/ajax_notifications_controller";
import DocumentTrackingController from "controllers/document_tracking_controller";
import LoanDashboardController from "controllers/dashboard_controller";
import ManageDocumentTrackingController from "controllers/manage/document_tracking_controller";
import ManageTransactionController from "controllers/manage/transactions_controller";
import SeriesImportController from "controllers/manage/series_import_controller";
import BuyBackImportController from "controllers/manage/buy_back_import_controller";
import NewLoanSaleBatchController from "components/manage/loan_sale_batches/new";
import ShowLoanSaleBatchController from "components/manage/loan_sale_batches/show";
import HotlistController from "controllers/manage/reports/hotlist_controller";
import AssetManagementFullHistory from "components/manage/asset_management/full_history";
import AssetManagementWorkflowTemplates from "components/manage/asset_management/workflow_templates";
import AssetManagementAlertController from "controllers/manage/asset_management_alert_controller";
import ForeclosureWorkoutController from "controllers/manage/foreclosure_workout_controller";
import ReportImportsController from "controllers/manage/physical_doc_tracking/report_imports_controller";
import ReportImportDetailsController from "controllers/manage/physical_doc_tracking/report_import_details_controller";
import ManageActivePortfolioController from "controllers/manage/active_portfolio_controller";
import AssetManagementAdmin from "components/manage/asset_management/show";
import ServicingFeesImporter from "components/manage/servicing_fees_importer";
import LenderApplyFlow from "components/lenders/apply_flow";
import LenderLoanApplicationList from "components/lenders/loan_application_list";
import BorrowerLeads from "components/lenders/borrower_leads";
import Marketplace from "components/marketplace/marketplace";
import LoanTradingController from "controllers/manage/loan_trading_controller";

setupComponents({
  "#v-ajax-notifications": AjaxNotificationsController,
  ".v-document-tracking-controller": DocumentTrackingController,
  ".v-loan-dashboard-controller": LoanDashboardController,
  ".v-manage-document-tracking-controller": ManageDocumentTrackingController,
  ".v-manage-transactions-controller": ManageTransactionController,
  ".v-series-import-controller": SeriesImportController,
  ".v-buy-back-import-controller": BuyBackImportController,
  ".v-new-loan-sale-batch-controller": NewLoanSaleBatchController,
  ".v-show-loan-sale-batch-controller": ShowLoanSaleBatchController,
  ".v-hotlist-dnd-controller": HotlistController,
  ".v-asset-management-history": AssetManagementFullHistory,
  ".v-asset-management-workflow-templates": AssetManagementWorkflowTemplates,
  ".v-asset-management-alert-controller": AssetManagementAlertController,
  ".v-foreclosure-workout-controller": ForeclosureWorkoutController,
  ".v-physical-doc-tracking-imports-controller": ReportImportsController,
  ".v-physical-doc-tracking-report-import-details": ReportImportDetailsController,
  ".v-manage-active-portfolio-controller": ManageActivePortfolioController,
  ".v-manage-asset-management-admin": AssetManagementAdmin,
  ".v-servicing-fees-importer": ServicingFeesImporter,
  ".v-lender-apply-flow": LenderApplyFlow,
  ".v-lender-loan-application-list": LenderLoanApplicationList,
  ".v-lender-borrower-leads": BorrowerLeads,
  ".v-marketplace": Marketplace,
  ".v-manage-loan-trading-controller": LoanTradingController,
});
