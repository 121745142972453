<template>
  <div class="workflow-badge">
    <base-tooltip
      type="is-white"
      :multilined="true"
      :active="isClosed"
      sticky
    >
      <div
        class="badge finops-status"
        :class="badgeClass"
      >
        {{ workflowStatus }}
      </div>
      <template v-slot:content>
        <markdown-view v-model="tooltipText" />
      </template>
    </base-tooltip>
  </div>
</template>
<script>
import MarkdownView from "components/shared/markdown_view";
import isNil from "lodash/isNil";

export default {
  components: {
    MarkdownView,
  },
  props: {
    workflow: {
      type: Object,
      default: () => ({}),
    },
    closingReasons: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    badgeClass() {
      return `finops-status-${this.workflowStatus}`;
    },
    workflowStatus() {
      switch (true) {
        case this.isClosed:
          return "closed";
        case this.isCompleted:
          return "complete";
        default:
          return "in-progress";
      }
    },
    isInProgress() {
      return !this.workflow.closedAt;
    },
    isClosed() {
      return this.workflowHasClosedAtDate && !this.hasCompletedReason;
    },
    isCompleted() {
      return this.workflowHasClosedAtDate && this.hasCompletedReason;
    },
    workflowHasClosedAtDate() {
      return !isNil(this.workflow.closedAt);
    },
    tooltipText() {
      if (!this.workflowClosedReason) {
        return "";
      }
      if (this.workflowClosedReason.name === "other") {
        return this.workflow.otherClosedReason;
      }
      return this.workflowClosedReason.text;
    },
    hasCompletedReason() {
      if (!this.workflowClosedReason) {
        return false;
      }

      return this.workflowClosedReason.name === "completed";
    },
    workflowClosedReason() {
      return this.closingReasons.find(
        reason => reason.id === this.workflow.assetManagementWorkflowClosedReasonId
      );
    },
  },
};
</script>
<style scoped>
.badge {
  cursor: pointer;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}
.workflow-badge {
  line-height: var(--line-height-sm);
}
</style>
