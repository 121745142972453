<template>
  <iframe frameborder="0" :src="iframeSrc" :style="iframeStyle" width="100%" />
</template>

<script>
const BusService = PS.Services.MessageBusService;
const Commands = PS.Models.Loan.Commands.Periscope;
const Event = PS.Models.Event;

const PERISCOPE_ORIGIN = "https://app.periscopedata.com";

export default Vue.component("periscope-dashboard", {
  data: function() {
    return {
      bus: BusService.loanBus,
      rulebook: undefined,

      iframeSrc: undefined,
      iframeStyle: {
        height: undefined,
      },

      defaultSettings: {},
      settings: {
        aggregation: undefined,
        dashboard: undefined,
        border: undefined,
        daterange: {},
        filters: undefined,
        visible: undefined,
      },
    };
  },
  created: function() {
    window.addEventListener("message", this.drilldown, false);
    window.addEventListener("message", this.resizeDashboard, false);
  },
  mounted: function() {
    this.rulebook = new PS.Models.Loan.PeriscopeRule(this);
    this.bus.attachRulebook(this.rulebook);
  },
  beforeDestroy: function() {
    window.removeEventListener("message", this.drilldown, false);
    window.removeEventListener("message", this.resizeDashboard, false);

    this.messageBus.detachRulebook(this.rulebook);
  },
  methods: {
    drilldown: function(event) {
      if (event.origin !== PERISCOPE_ORIGIN) return;
      if (event.data.event_type !== "drilldown") return;

      const eventPayload = new Event({
        dashboard: event.data.destination_dashboard_id,
        filters: event.data.filter_values,
      });

      new Commands.RequestDrilldown(eventPayload, {}).execute();
    },
    resizeDashboard: function(event) {
      if (event.origin !== PERISCOPE_ORIGIN) return;
      if (event.data.event_type !== "dashboard_resize") return;

      const eventPayload = new Event({
        height: event.data.dashboard_height,
      });

      new Commands.RequestDashboardResize(eventPayload, {}).execute();
    },
  },
});
</script>
