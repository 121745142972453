export default {
  props: {
    chart: Object,
    sources: Object,
  },
  mounted() {
    this.render();
  },
  destroyed() {
    this.destroy();
  },
  watch: {
    sources: {
      deep: true,
      handler() {
        this.destroy();
        this.render();
      },
    },
  },
  computed: {
    chartMessage() {
      switch (true) {
        case this.sources.isLoading:
          return "Loading...";
        case this.sources.isFailed:
          return "Unable to load data";
        case this.sources.hasData:
          return undefined;
        default:
          return "Data is not available";
      }
    },
  },
  methods: {
    render() {
      throw "Report chart components must implment #render";
    },
    destroy() {
      if (this.c3) {
        this.c3.destroy();
        this.c3 = null;
      }
    },
  },
};
