import { SectionType } from "models/lender_form/fields";
import Section from "models/lender_form/section";

export default class LoanTypeSection extends Section {
  constructor(fields) {
    super(SectionType.LoanType, fields);
  }

  update() {
    super.update();
  }
}
