<template>
  <div class="loan-request-details-payoff">
    <p v-if="request.cc.length > 0">CC:
      <span>{{request.cc.join(", ")}}</span>
    </p>

    <p v-if="request.requestDetails.desiredPayoffDate">
      Desired Payoff Date:
      <span>{{request.requestDetails.desiredPayoffDate}}</span>
    </p>

    <p v-if="loan.isCemaFinancingSupported">
      CEMA:
      <span>{{request.requestDetails.cemaText}}</span>
    </p>

    <p v-if="loan.isPartialPayoffAllowed">
      Multicollateral Payoff:
      <span>{{request.requestDetails.multicollateralPayoffText}}</span>
    </p>

    <p v-if="request.requestDetails.payoffAddresses">
      Payoff Address:
      <span>{{request.requestDetails.payoffAddresses.join(", ")}}</span>
    </p>

    <p v-if="request.requestDetails.notes">
      Notes:
      <span>{{request.requestDetails.notes}}</span>
    </p>
  </div>
</template>
<script>
export default {
  name: "loan-request-details-payoff",
  props: {
    request: {
      type: Object,
      required: true,
    },
    loan: {
      type: Object,
      required: true,
    },
  },
};
</script>
