import FetchPricingAtTargetLoanAmount from "./fetch_pricing_at_target_loan_amount";
import { bestLtvBaseLine } from "../utils/helpers";

export default class FetchBestRate {
  static async call(scenario, serviceClient) {
    const bestRatePricing = await FetchPricingAtTargetLoanAmount.call(
      scenario,
      bestLtvBaseLine(scenario.loanProduct),
      serviceClient,
      "bestRateLoanAmount"
    );

    return bestRatePricing;
  }
}
