<template>
  <div class="credit-report">
    <h4>Credit Report</h4>
    <table class="view-table credit-reports-table">
      <thead>
        <tr>
          <th>Requested Date</th>
          <th>Request Type</th>
          <th>Requested By</th>
          <th>Vendor</th>
          <th>Score</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="report in reports" :key="report.id">
          <td>{{ report.requestedDate }}</td>
          <td>{{ report.typeLabel }}</td>
          <td>{{ report.requestedByLabel }}</td>
          <td class="credit-score-vendors">
            <span v-for="score in report.scores" :key="score.id" class="credit-score-vendor">
              {{ score.vendorLabel }}
            </span>
          </td>
          <td class="credit-scores">
            <span v-for="score in report.scores" :key="score.id" class="credit-score">
              {{ score.score }}
            </span>
          </td>
          <td class="credit-score-actions">
            <a href="#" class="fas fa-pencil-alt" @click="$emit('on-report-edit', report)" />
            <a href="#" class="fas fa-trash-alt" @click="$emit('on-report-delete', report)" />
          </td>
        </tr>
      </tbody>
    </table>
    <a href="#" class="add-new-report" @click.prevent="$emit('on-add-new-report')">+ Add New Report</a>
  </div>
</template>

<script>
export default {
  name: "credit-reports-table",
  props: {
    reports: {
      type: Array,
      required: true,
    },
  },
};
</script>
