<template>
  <modal-view :show="show" :on-close="onClose">
    <div class="modal-content base-modal subservicer-fees-form">
      <div class="modal-header">
        <h4>
          {{ title }}
          <i v-if="loading" class="ps-loader ps-loader-small"></i>
        </h4>
        <span>
          All fields are required.
          Fee code must match exactly to the servicer document and is case sensitive.
        </span>
      </div>
      <div class="modal-body">
        <form @keypress.enter="onSave">
          <div class="form-group controll-wrapper">
            <form-input
              v-model.trim="resource.feeCode"
              label="Subservicer Fee Code"
              field="fee_code"
              :errors="errors"
              :inline="false"
            />
          </div>
          <div class="form-group controll-wrapper">
            <form-input
              v-model.trim="resource.feeCodeDescription"
              label="Subservicer Fee Name"
              field="fee_code_description"
              :errors="errors"
              :inline="false"
            />
          </div>
          <div class="associated-distribution-fee">
            <form-select
              v-model="resource.feeType"
              label="Associated Distribution Fee"
              :field="feeTypeField"
              :options="feeTypeOptions"
              :errors="errors"
              :inline="false"
              @input="errors = {}"
            />
            <form-input
              v-if="resource.feeType == 'new'"
              v-model.trim="resource.feeTypeName"
              label="New Distribution Fee Name"
              field="feeType"
              :errors="errors"
              :inline="false"
            />
          </div>
          <check-box
            v-if="fee"
            v-model="resource.disabled"
            label="Disable Fee"
          />
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-grey" @click="onClose">Cancel</button>
        <button class="btn btn-action-cta" @click="onSave">
          Save
        </button>
      </div>
    </div>
  </modal-view>
</template>
<script>
import FormSelect from "components/shared/form/form_select";
import FormInput from "components/shared/form/form_input";
import CheckBox from "components/shared/check_box";
import ModalView from "components/modal_view";
import AjaxService from "services/ajax_service";

import { buildDistributionFees } from "./../fees";

export default {
  components: { ModalView, FormSelect, FormInput, CheckBox },
  props: {
    fee: [Object, undefined],
    currentSubservicer: {
      type: Object,
      required: true,
    },
  },
  data() {
    const resource = {};

    if (this.fee) {
      resource.disabled = this.fee.status === "disabled";
      resource.feeCode = this.fee.feeCode;
      resource.feeCodeDescription = this.fee.feeCodeDescription;
      resource.feeType = this.fee.feeType.name;
    }

    return {
      loading: false,
      show: true,
      errors: {},
      resource,
    };
  },
  computed: {
    title() {
      if (this.fee) {
        return "Edit Subservicer Fee";
      } else {
        return "Add Subservicer Fee";
      }
    },
    feeTypeOptions() {
      return { new: "Add New", ...this.fees };
    },
    feeTypeField() {
      const { feeType, feeTypeName } = this.resource;

      if (feeType !== "new" && !feeTypeName) {
        return "fee_type";
      } else {
        return "fee_type_dummy";
      }
    },
    fees() {
      return buildDistributionFees(PSData.feeTypes);
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onSave() {
      const { feeCode, feeCodeDescription, feeType, feeTypeName, disabled } = this.resource;

      const data = {
        fee_code: feeCode,
        fee_code_description: feeCodeDescription,
        fee_type_new: feeType === "new",
        fee_type: feeType === "new" ? feeTypeName || "" : this.fees[feeType],
        servicer_entity_id: this.currentSubservicer.id,
        enabled: !disabled,
      };

      this.save(data);
    },
    async save(resource) {
      this.loading = true;

      try {
        const action = this.fee ? "patch" : "post";
        const data = await AjaxService[action](
          `/manage/cash_receipt/subservicer_fees/${this.fee?.id || ""}`,
          { resource },
          { "content-type": "application/json" }
        );

        this.$emit("saved", data);
      } catch ({ response, data }) {
        if (response.status === 422) {
          this.errors = data;
        } else {
          console.log("Error");
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
