<template>
  <div>
    <view-panel class="master-agreements-panel">
      <template slot="header">
        Master Agreements
      </template>
      <table class="view-table">
        <thead>
          <tr>
            <th>Master Agreement</th>
            <th>Date Signed</th>
            <th>Deal Types Permitted</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="agreement in masterAgreements" :key="agreement.documentType">
            <td class="view-table-cell-text">
              {{ agreement.documentName }}
            </td>
            <td class="view-table-cell-text">
              {{ agreement.signedDate }}
            </td>
            <td class="view-table-cell-text">
              {{ agreement.dealType }}
            </td>
            <td class="actions" v-if="canManage">
              <a href="#" title="Edit master agreement" @click.prevent="editMasterAgreement(agreement)">
                <i class="fas fa-pencil-alt"/>
              </a>
              <a href="#" title="Delete master agreement" @click.prevent="deleteMasterAgreement(agreement)">
                <i class="fas fa-trash-alt"/>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="canManage">
          <tr>
            <td colspan="4">
              <a href="#" @click.prevent="addMasterAgreement">+ Add Master Agreement</a>
            </td>
          </tr>
        </tfoot>
      </table>
    </view-panel>

    <master-agreements-edit-modal
      v-if="showMasterAgreementModal"
      :show="showMasterAgreementModal"
      :document-types="availableDocumentTypes"
      :agreement="selectedAgreement"
      :on-close="closeMasterAgreementModal"
      @on-save="onMasterAgreementSave"
    />
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import MasterAgreementsEditModal from "./form_modal.vue";
import selectOptionsToMapping from "utils/select_options_to_mapping";
import ViewPanel from "components/view_panel.vue";
import WindowService from "services/window_service";
import MessageBusService from "services/message_bus_service";

export default Vue.component("master-agreements", {
  components: {
    MasterAgreementsEditModal,
    ViewPanel,
  },
  props: {
    entity: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    policy: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      routes: PSData.routes || {},
      documentTypes: [],
      masterAgreements: [],
      selectedAgreement: undefined,
      bus: MessageBusService.entityBus,
    };
  },
  computed: {
    availableDocumentTypes() {
      const signedTypes = new Set(this.masterAgreements.map(agreement => agreement.documentType));
      signedTypes.delete(this.selectedAgreement.documentType);
      return selectOptionsToMapping(this.documentTypes.filter(type => !signedTypes.has(type[1])));
    },
    canManage() {
      return this.policy.canManage;
    },
    showMasterAgreementModal() {
      return !!this.selectedAgreement;
    },
  },
  mounted() {
    return AjaxService.getJSON(this.routes.masterAgreements).then(data => {
      this.masterAgreements = data.masterAgreements;
      this.documentTypes = data.documentTypes;
    });
  },
  methods: {
    addMasterAgreement() {
      this.selectedAgreement = { documentType: undefined, signedDate: undefined };
    },
    editMasterAgreement(agreement) {
      this.selectedAgreement = agreement;
    },
    closeMasterAgreementModal() {
      this.selectedAgreement = undefined;
    },
    onMasterAgreementSave(masterAgreements) {
      this.masterAgreements = masterAgreements;
      this.selectedAgreement = undefined;
    },
    deleteMasterAgreement(agreement) {
      if (!WindowService.confirm("Are you sure you want to remove this master agreement?")) {
        return;
      }

      const messages = {
        onSuccess: "Master Agreement has been deleted",
        onError: ({ response }) => {
          if (response.status === 403) {
            return { message: "Unauthorized", status: "error" };
          } else {
            return { message: "Something went wrong", status: "error" };
          }
        },
      };

      AjaxService.withNotifications(messages)
        .delete(`${this.routes.masterAgreements}/${agreement.id}`)
        .then(data => {
          this.masterAgreements = data;
          this.bus.broadcast("entity-updated");
        });
    },
  },
});
</script>
