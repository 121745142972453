<template>
  <div>
    <div
      v-for="(group, index) in rulesMapping"
      :key="index"
    >
      <rules-group
        :rules="rulesMapping"
        :group="group"
        :group-index="index"
        :disabled="disabled"
        :used-fields="usedFields"
        @remove-rule="removeRule"
        @add-rule="addRule"
        @add-group="addGroup"
        :filter-by-context="filterByContext"
      />
    </div>
    <p v-if="errors" class="help is-danger conditions-errors">
      {{ errorsMessage }}
    </p>
  </div>
</template>
<script>
import compact from "lodash/compact";
import startCase from "lodash/startCase";
import RulesGroup from "./rules_group";
import { OPERATORS, VALUES } from "./definitions_mapping";
import { store } from "../template_revision/store";

export default {
  components: {
    RulesGroup,
  },
  props: {
    rules: {
      type: [Object, Array],
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: [String, Array],
      default: null,
    },
    filterByContext: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      rulesMapping: [],
    };
  },
  computed: {
    usedFields() {
      return compact(
        this.rulesMapping.map(mapping => mapping.rules.map(rule => rule.field)).flat()
      );
    },
    errorsMessage() {
      if (!this.errors) {
        return null;
      } else if (Array.isArray(this.errors)) {
        return this.errors.join(", ");
      } else {
        return this.errors;
      }
    },
  },
  watch: {
    deep: true,
    rules: {
      immediate: true,
      handler() {
        this.initMappings();
      },
    },
    rulesMapping: {
      deep: true,
      immediate: true,
      handler() {
        const rules = {};
        this.parseRules(this.rulesMapping, rules);
        this.$emit("change", rules);
      },
    },
  },
  methods: {
    initMappings() {
      const mappings = [];

      this.populateMappings(mappings, this.rules);
      this.rulesMapping = mappings;
    },
    operatorMatch(operator) {
      return OPERATORS[operator] || operator;
    },
    valueMatch(conditionValue) {
      const values = Array.isArray(conditionValue) ? values : [conditionValue];

      return values
        .map(value => VALUES[value] || VALUES[value.toUpperCase()] || startCase(value))
        .join(", ");
    },
    fieldMatch(field) {
      return startCase(field);
    },
    valueIsRule(value) {
      return Object.prototype.toString.call(value) === "[object Object]";
    },
    buildRule(field, value, condition, logicOperator) {
      return {
        field: this.disabled ? this.fieldMatch(field) : field,
        value: this.disabled ? this.valueMatch(this.valueToString(field, value)) : value,
        operator: this.disabled ? this.operatorMatch(condition) : condition,
        logicOperator: logicOperator,
        definitionName: field,
      };
    },
    isSelectField(field) {
      return store.state.rulesDefinitions[field]?.type === "select";
    },
    valueToString(field, value) {
      if (this.isSelectField(field)) {
        const fieldValues = store.state.rulesDefinitions[field]?.values || [];
        const fieldValue = fieldValues.find(v => v[1] === value);

        if (fieldValue) {
          value = fieldValue[0];
        }
      }

      return value.toString();
    },
    logicOperator(valueIndex, ruleIndex) {
      let logicOperator = null;
      if (valueIndex) {
        logicOperator = "OR";
      } else {
        logicOperator = ruleIndex ? "AND" : null;
      }

      return logicOperator;
    },
    buildMapping(rulesGroup, logicOperator, id) {
      return {
        rules: rulesGroup,
        logicOperator: logicOperator,
        id: id,
      };
    },
    populateMappings(mappings, rules) {
      Object.keys(rules).forEach((field, ruleIndex) => {
        Object.keys(rules[field]).forEach(condition => {
          const conditionValue = rules[field][condition];
          const values = Array.isArray(conditionValue) ? conditionValue : [conditionValue];
          values.forEach((value, valueIndex) => {
            const logicOperator = this.logicOperator(valueIndex, ruleIndex);
            const groupRules = [];

            if (this.valueIsRule(value)) {
              Object.keys(value).forEach((subValue, subValueIndex) => {
                const subRules = value[subValue];
                subRules[field] = { [condition]: subValue };

                const subGroup = [];
                this.populateMappings(subGroup, subRules);

                groupRules.push(
                  this.buildMapping(
                    subGroup,
                    this.logicOperator(subValueIndex, ruleIndex),
                    `${field}_${valueIndex}_${subValueIndex}`
                  )
                );
              });
            } else {
              groupRules.push(this.buildRule(field, value, condition, null));
            }
            mappings.push(this.buildMapping(groupRules, logicOperator, `${field}_${valueIndex}`));
          });
        });
      });
    },
    removeRule(rules, group, groupIndex, index) {
      if (group.rules.length > 1) {
        group.rules.splice(index, 1);
      } else {
        rules.splice(groupIndex, 1);
      }
    },
    addRule(rules, group, groupIndex) {
      rules.splice(
        groupIndex + 1,
        0,
        this.buildMapping([this.buildRule("", "", "", null)], "AND", `_${groupIndex + 1}_${0}`)
      );
    },
    addGroup(rules, group, groupIndex, index) {
      group.rules.splice(
        index + 1,
        0,
        this.buildMapping(
          [this.buildRule("", "", "", null)],
          "AND",
          `_${groupIndex + 1}_${index + 1}`
        )
      );
    },
    parseRules(rulesMapping, rules) {
      rulesMapping.forEach(mapping => {
        if (mapping.rules && mapping.rules.length > 1) {
          mapping.rules.forEach(rule => {
            if (rule.rules) {
              const subRules = {};

              this.parseRules(rule.rules, subRules);
              rules[rule.field] = { [rule.operator]: rule.value };
            }
          });
        } else {
          const rule = mapping.rules ? mapping.rules[0] : mapping;
          if (
            rules[rule.field] &&
            rules[rule.field][rule.operator] &&
            mapping.logicOperator === "OR"
          ) {
            let value = rules[rule.field][rule.operator];
            value = Array.isArray(value) ? value : [value];
            value.push(rule.value);
            rules[rule.field][rule.operator] = value;
          } else {
            rules[rule.field] = { [rule.operator]: rule.value };
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.conditions-errors {
  margin-top: 18px;
}
</style>
