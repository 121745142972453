<template>
  <div v-if="showPartyDetails">
    <div class="float-right">
      <button class="btn btn-action-cta-sm" @click="editParty">
        Edit Background Review
      </button>
    </div>
    <background-review-view :party="party" />
  </div>
  <div v-else>
    <div class="float-right">
      <button class="btn btn-cancel-modal" @click="onCancel">
        Cancel
      </button>
      <button class="btn btn-action-modal" @click="updateParty">
        Update
      </button>
    </div>
    <background-review-form :party="party" />
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import ResponsibleParty from "models/responsible_party";
import BackgroundReviewView from "components/shared/background_review/view";
import BackgroundReviewForm from "components/shared/background_review/form";

export default {
  components: {
    BackgroundReviewView,
    BackgroundReviewForm,
  },
  props: {
    rawParty: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      routes: PSData.routes,
      party: ResponsibleParty.deserialize(this.rawParty),
    };
  },
  computed: {
    showPartyDetails() {
      return !this.editMode;
    },
  },
  methods: {
    editParty() {
      this.editMode = true;
    },
    onCancel() {
      this.party = ResponsibleParty.deserialize(this.rawParty);
      this.cancelEditing();
    },
    cancelEditing() {
      this.editMode = false;
    },
    updateParty() {
      const params = { responsible_party: this.party.toPlainObject({ toSnakeCase: true }) };

      AjaxService.withNotifications()
        .patchJSON(this.routes.updateDetails, params)
        .then(data => {
          this.$emit("party-updated", data);
          this.party = ResponsibleParty.deserialize(data);
          this.cancelEditing();
        })
        .catch(({ response, data }) => {
          if (response.status === 422) {
            this.errors = data.errors;
          } else {
            Bugsnag.notify(new Error(`Unable to update responsible party: ${response.statusText}`));
          }
        });
    },
  },
};
</script>
