<template>
  <div class="servicer-loan-boarding-notes">
    <loan-boarding-truncated-text :value="record.comments"></loan-boarding-truncated-text>
    <span class="edit-note" @click="show = true">
      <i class="fa fa-pencil" aria-hidden="true"></i>
      Edit notes
    </span>
    <modal-view :show="show" :on-close="onClose">
      <div class="modal-content base-modal">
        <div class="modal-header">
          <h4>PS Internal Notes</h4>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group control-wrapper">
              <label for="notes">Notes</label>
              <textarea id="notes" maxlength="255" class="form-control" v-model="resource.note" rows="5">
              </textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-grey" @click="onClose">Cancel</button>
          <button class="btn btn-action-cta" @click="onSubmit">
            Save
          </button>
        </div>
      </div>
    </modal-view>
  </div>
</template>
<script>
import LoanBoardingTruncatedText from "components/servicer/loan_boarding/truncated_text";
import ModalView from "components/modal_view";
import AjaxService from "services/ajax_service";
import trim from "lodash/trim";
import { success, error } from "services/notification";

const RUSH_BOARD_PLACEHOLDER = "Rush board – ";

export default {
  name: "manage-servicer-loan-boarding-ps-internal-notes",
  components: { ModalView, LoanBoardingTruncatedText },
  props: {
    record: Object,
  },
  data() {
    return {
      show: false,
      inFlight: false,
      resource: {
        note: this.record.comments,
      },
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
      this.show = false;
      this.resource.note = this.record.comments;
    },
    async onSubmit() {
      this.inFlight = true;
      const resource = { servicer_loan_boarding: { comments: this.resource.note } };
      const messages = {
        onSuccess: "Note was updated successfully",
      };

      const result = await AjaxService.withNotifications(messages).patch(
        this.record.routes.update,
        resource
      );

      this.record.comments = result.comments;
      this.onClose();
      this.inFlight = false;
    },
  },
};
</script>
