<template>
  <div>
    <h3 class="table-header">Ordered and Lender Submitted Values</h3>
    <valuation-table
      :items="lenderSubmittedValuations"
      :add-in-process="addInProcess"
      :designation-changes="designationChanges"
      :disabled="disabled"
      :lender-submitted="true"
      :property-options="propertyOptions"
      :validation-options="validationOptions"
      @delete-item="deleteItem"
      @edit-item="editItem"
      @cancel-edit="cancelEdit"
      @update-item="updateItem"
      @designate-deferred-maintenance="designateDeferredMaintenance"
      @designate-arv="designateArv"
      @designate-item="designateItem"
    />
    <h3 class="table-header">Property Valuations and Validations</h3>
    <valuation-table
      :items="orderedValuations"
      :add-in-process="addInProcess"
      :designation-changes="designationChanges"
      :disabled="disabled"
      :lender-submitted="false"
      :property-options="propertyOptions"
      :validation-options="validationOptions"
      :allow-apply-designations="allowApplyDesignations"
      @delete-item="deleteItem"
      @edit-item="editItem"
      @cancel-edit="cancelEdit"
      @update-item="updateItem"
      @designate-deferred-maintenance="designateDeferredMaintenance"
      @designate-arv="designateArv"
      @designate-item="designateItem"
      @cancel-designations="cancelDesignations"
      @apply-designations="applyDesignations"
    />
    <div class="loan-valuation-forms" v-if="!disabled">
      <a v-show="!orderFormVisible" class="show-form-link" @click="showOrderForm()">
        + Order/Add Valuation
      </a>
      <a v-show="orderFormVisible" class="show-form-link" @click="hideOrderForm()">
        - Order/Add Valuation
      </a>
      <div v-show="orderFormVisible" class="loan-valuation-form order-valuation">
        <h4>Order Valuation</h4>
        <div class="form-group row">
          <div class="col-2">
            <label class="control-label" for="valuation-order-report-type">BPO Type:</label>
          </div>
          <div class="col-4">
            <select
              id="valuation-order-report-type"
              v-model="order.reportType"
              class="form-control"
            >
              <option
                v-for="option in options.orderReportTypes"
                :key="option.name"
                :value="option.name"
              >
                {{ option.value }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-2">
            <label class="control-label" for="valuation-order-turn-time">Turn Around Time:</label>
          </div>
          <div class="col-4">
            <select id="valuation-order-turn-time" v-model="order.turnTime" class="form-control">
              <option
                v-for="option in order.turnTimeOptions()"
                :key="option.name"
                :value="option.name"
              >
                {{ option.value }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-2">
            <label class="control-label" for="valuation-order-note">Notes:</label>
          </div>
          <div class="col-4">
            <textarea
              id="valuation-order-note"
              v-model="order.notes"
              class="form-control"
            ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-4 offset-2">
            <a
              id="valuation-order-button"
              class="btn-action-cta"
              tabindex="0"
              :class="{ 'btn-disabled': addInProcess }"
              @click="orderValuation()"
            >
              <img :src="require('images/icons/icon_valuation.svg')" />
              Submit Order
            </a>
          </div>
        </div>
      </div>
      <div v-show="orderFormVisible" class="loan-valuation-form">
        <h4>Manually Add Valuation</h4>
        <table class="table">
          <thead>
            <th>
              <label class="control-label" for="valuation-address-new">Address</label>
            </th>
            <th>
              <label class="control-label" for="valuation-date-new">Date</label>
            </th>
            <th>
              <label class="control-label" for="valuation-type-new">Type</label>
            </th>
            <th>
              <label class="control-label" for="valuation-as-is-amount-new">As Is Amount</label>
            </th>
            <th>
              <label class="control-label" for="valuation-arv-amount-new">ARV Amount</label>
            </th>
            <th>
              <label class="control-label" for="valuation-deferred-maintenance-amount-new">Deferred Maintenance</label>
            </th>
            <th>
              <label class="control-label" for="valuation-method-new">Method</label>
            </th>
            <th>
              <label class="control-label" for="valuation-appraiser-new">Appraiser</label>
            </th>
            <th>
              <label class="control-label" for="valuation-commissioner-new">Commissioned By</label>
            </th>
            <th>
              <label class="control-label" for="valuation-note-new">Note</label>
            </th>
            <th></th>
          </thead>
          <tbody>
            <td>
              <select
                id="valuation-address-new"
                v-model="newItem.propertyId"
                class="form-control"
              >
                <option
                  v-for="option in propertyOptions"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </option>
              </select>
            </td>
            <td>
              <input
                id="valuation-date-new"
                v-model="newItem.date"
                v-datepicker=""
                v-mask:date=""
                class="form-control"
              />
            </td>
            <td>
              <div class="form-group">
                <select
                  id="valuation-type-new"
                  v-model="newItem.reportType"
                  class="form-control"
                >
                  <option
                    v-for="option in validationOptions"
                    :key="option.name"
                    :value="option.name"
                  >
                    {{ option.value }}
                  </option>
                </select>
              </div>
            </td>
            <td>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="valuation-as-is-amount-new"
                  v-model="newItem.asIsAmount"
                  v-mask:currency=""
                  class="form-control"
                />
              </div>
            </td>
            <td>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="valuation-arv-amount-new"
                  v-model="newItem.arvAmount"
                  v-mask:currency=""
                  class="form-control"
                />
              </div>
            </td>
            <td>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="valuation-deferred-maintenance-amount-new"
                  v-model="newItem.deferredMaintenanceAmount"
                  v-mask:currency=""
                  class="form-control"
                />
              </div>
            </td>
            <td>
              <select
                id="valuation-method-new"
                v-model="newItem.valuationMethod"
                class="form-control"
              >
                <option></option>
                <option
                  v-for="option in options.valuationMethods"
                  :key="option.name"
                  :value="option.name"
                >
                  {{ option.value }}
                </option>
              </select>
            </td>
            <td>
              <select id="valuation-appraiser-new" v-model="newItem.appraiser" class="form-control">
                <option></option>
                <option
                  v-for="option in options.appraisers"
                  :key="option.name"
                  :value="option.name"
                >
                  {{ option.value }}
                </option>
              </select>
            </td>
            <td>
              <select
                id="valuation-commissioner-new"
                v-model="newItem.commissioner"
                class="form-control"
              >
                <option
                  v-for="option in options.commissioners"
                  :key="option.name"
                  :value="option.name"
                >
                  {{ option.value }}
                </option>
              </select>
            </td>
            <td>
              <input id="valuation-note-new" v-model="newItem.note" class="form-control" />
            </td>
            <td>
              <a
                id="valuation-add-new"
                class="btn-action-cta"
                tabindex="0"
                :class="{ 'btn-disabled': addInProcess }"
                @click="addItem()"
              >
                Add
              </a>
            </td>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import PropertyValuation from "models/property_valuation";
import PropertyValuationOrder from "models/property_valuation_order";
import EmptyPropertyValuation from "models/empty_property_valuation";
import PropertyValuations from "models/property_valuation_collection";
import ValuationTable from "./table";
import isEmpty from "lodash/isEmpty";

export default Vue.component("loan-valuations-show", {
  components: { ValuationTable },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      options: PSData.valuationOptions,
      propertyValuations: new PropertyValuations(PSData.valuations),
      loan: PSData.loan,
      orderFormVisible: false,
      newItem: new EmptyPropertyValuation(PSData.valuationOptions, PSData.loan.loanProperties),
      order: new PropertyValuationOrder(PSData.valuationOptions),
      addInProcess: false,
      properties: PSData.loan.loanProperties,
      validationOptions: [
        {
          name: "validation",
          value: "Validation",
        },
        {
          name: "valuation",
          value: "Valuation",
        },
        {
          name: "purchase_price",
          value: "Purchase Price",
        },
      ],
    };
  },
  computed: {
    designationChanges() {
      return this.propertyValuations.designationChanges || false;
    },
    representedProperties() {
      return this.orderedValuations.reduce((mem, valuation) => {
        mem[valuation.property_id] = {};
        return mem;
      }, {});
    },
    allowApplyDesignations() {
      return this.propertyValuations.allowApplyDesignations(
        this.lenderSubmittedValuations,
        this.orderedValuations
      );
    },
    propertyOptions() {
      return this.properties.map(property => {
        return { id: property.id, name: property.street };
      });
    },
    orderedValuations() {
      return this.propertyValuations.items.filter(item => {
        return !item.lenderSubmitted && !!item.propertyId;
      });
    },
    lenderSubmittedValuations() {
      return this.propertyValuations.items.filter(item => {
        return item.lenderSubmitted;
      });
    },
  },
  watch: {
    "order.reportType": function() {
      this.order.resetTurnTime();
    },
  },
  mounted: function() {
    this.$nextTick(() => {
      this.sortItems();
      this.initTooltips();
    });
  },
  methods: {
    applyDesignations: function() {
      const valuation_designations = this.propertyValuations.designations();
      var url = `/manage/loans/${this.loan.toParam}/valuations/bulk_designate`;
      this.addInProcess = true;
      PS.Services.AjaxService.withNotifications()
        .post(url, { valuation_designations })
        .then(() => {
          location.reload();
        })
        .finally(() => {
          this.addInProcess = false;
        });
    },
    hideAddress: function(index) {
      if (index == 0) return false;

      return (
        this.orderedValuations[index - 1].raw.propertyId ==
        this.orderedValuations[index].raw.propertyId
      );
    },
    convertItems: function(items) {
      return items.map(e => {
        return new PropertyValuation(e);
      });
    },
    addItem: function() {
      if (this.addInProcess) return;

      var url = `/manage/loans/${this.loan.toParam}/valuations`;
      this.addInProcess = true;
      const messages = { onSuccess: "Valuation updated.", onError: this.onError };
      PS.Services.AjaxService.withNotifications(messages)
        .post(url, { loan_valuation: this.newItem.toHash() })
        .then(data => {
          var item = new PropertyValuation(data);
          this.propertyValuations.items.push(item);
          this.hideOrderForm();
          this.sortItems();
          this.reload(item);
        })
        .catch(({ data }) => {
          this.onError(data);
        })
        .finally(() => {
          this.addInProcess = false;
        });
    },
    orderValuation: function() {
      if (this.addInProcess) return;

      var url = `/manage/loans/${this.loan.toParam}/valuations/order`;
      this.addInProcess = true;
      PS.Services.AjaxService.withNotifications()
        .post(url, { loan_valuation: this.order.toHash() })
        .then(data => {
          this.propertyValuations.items.push(new PropertyValuation(data));
          this.sortItems();
          this.hideOrderForm();
        })
        .finally(() => {
          this.addInProcess = false;
        });
    },
    deleteItem: function(item) {
      if (this.askConfirmation()) {
        var url = `/manage/loan_valuations/${item.id}`;
        PS.Services.AjaxService.withNotifications()
          .delete(url)
          .then(data => {
            Vue.delete(this.propertyValuations.items, this.propertyValuations.items.indexOf(item));
          })
          .finally(() => {
            this.reload(item);
          });
      }
    },
    updateItem: function(item) {
      const messages = { onSuccess: "Valuation updated.", onError: this.onError };
      if (this.askConfirmation()) {
        var url = `/manage/loan_valuations/${item.id}`;
        PS.Services.AjaxService.withNotifications(messages)
          .patch(url, { loan_valuation: item.toHash() })
          .then(data => {
            item.update(data);
            this.sortItems();
          })
          .catch(({ data }) => {
            this.onError(data);
          })
          .finally(() => {
            this.reload(item);
          });
      }
    },
    onError: function({ data }) {
      return {
        message: data?.error,
        status: "error",
      };
    },
    designateDeferredMaintenance: function(item) {
      this.propertyValuations.designateDeferredMaintenance(item);
    },
    designateArv: function(item) {
      this.propertyValuations.designateArv(item);
    },
    designateItem: function(item) {
      this.propertyValuations.designateItem(item);
    },
    editItem: function(item) {
      item.editing = true;
      this.cancelAll(item);
    },
    cancelAll: function(item) {
      this.propertyValuations.items.forEach(i => {
        if (i != item) {
          this.cancelEdit(i);
        }
      });
    },
    cancelEdit: function(item) {
      item.reset();
    },
    cancelDesignations: function() {
      this.propertyValuations.cancelDesignations();
    },
    sortItems: function() {
      this.propertyValuations.items.sort(PropertyValuation.addressDateSort);
    },
    initTooltips: function() {
      $(this.$el).tooltip({
        placement: "bottom",
        selector: "[type=radio]:disabled:not(:checked) + label>.control-toggle",
        title: "Date, Method & Appraiser are required to designate",
      });
    },
    findOptionValue: function(name, options) {
      var option = options.filter(option => option.name === name)[0] || {};
      return option.value;
    },
    findOptionNameById: function(id, options) {
      var option = options.filter(option => option.id == id)[0] || {};
      return option.name;
    },
    reload: function(item) {
      if (item.needReload()) {
        location.reload();
      }
    },
    showOrderForm: function() {
      this.orderFormVisible = true;
    },
    hideOrderForm: function() {
      this.orderFormVisible = false;
      this.newItem.reset();
      this.order.reset();
    },
    askConfirmation: function() {
      return window.confirm("Are you sure?");
    },
    isAsIsSelected: function(item) {
      return item.designated;
    },
    isArvSelected: function(item) {
      return item.designatedArv;
    },
    isDeferredMaintenanceSelected: function(item) {
      return item.designatedDeferredMaintenance;
    },
    amountChangeDisabled: function(item) {
      return this.disabled || !item.isRequiredAttributesPresent();
    },
  },
});
</script>
