import moment from "moment";

class TermCalculator {
  constructor(startDate, endDate) {
    this.startDate = this.parseDate(startDate);
    this.endDate = this.parseDate(endDate);
  }

  months() {
    if (!this.startDate || !this.endDate) {
      return;
    }

    if (this.startDate > this.endDate) {
      return;
    }

    return this.wholeMonths() + this.partialMonths();
  }

  partialMonths() {
    if (this.startDate.date() === this.endDate.date()) {
      return 1.0;
    } else {
      const daysInEndMonth = this.endDate.daysInMonth();
      const daysInStartMonth = this.startDate.daysInMonth();

      return (
        this.endDate.date() / daysInEndMonth +
        (daysInStartMonth - this.startDate.date()) / daysInStartMonth
      );
    }
  }

  wholeMonths() {
    return (
      12 * (this.endDate.year() - this.startDate.year()) +
      (this.endDate.month() - this.startDate.month()) -
      1
    );
  }

  parseDate(date) {
    if (!date) {
      return null;
    }

    if (typeof date === "number") {
      // Epoch
      return moment(date);
    }

    if (typeof date === "string") {
      return moment(date, ["MM-DD-YYYY", "YYYY-MM-DD"]);
    }

    return null;
  }
}

PS.Models.Diligence.TermCalculator = TermCalculator;
