const Event = PS.Models.Event;

class EmailSubscriptionEvent extends Event {
  prepareData(options) {
    this.subscription = options.subscription;
  }
}

class AllSubscriptionUpdated extends EmailSubscriptionEvent {}
class SubscriptionUpdated extends EmailSubscriptionEvent {}

class LendersSetupRequested extends Event {}
class ResetChangesRequested extends Event {}
class SaveChangesRequested extends Event {}

PS.Models.EmailSubscription.Events = {
  AllSubscriptionUpdated: AllSubscriptionUpdated,
  LendersSetupRequested: LendersSetupRequested,
  ResetChangesRequested: ResetChangesRequested,
  SaveChangesRequested: SaveChangesRequested,
  SubscriptionUpdated: SubscriptionUpdated,
};
