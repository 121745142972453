import filter from "lodash/filter";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import some from "lodash/some";

import AjaxService, {
  formatParams,
  ERROR_MESSAGE,
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "services/ajax_service";

export default {
  props: {
    transactionType: {
      type: String,
      default: "all",
    },
    fromDate: {
      type: String,
      default: "",
    },
    toDate: {
      type: String,
      default: "",
    },
    loanId: {
      type: String,
      default: "",
    },
    legalOwnerNames: {
      type: Array,
      default: () => [],
    },
    transactionId: {
      type: String,
      default: "",
    },
    ownerName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      currentLegalOwnerName: null,
      items: [],
      total: 0,
      abortController: null,
      invalidItems: "",
    };
  },
  computed: {
    params() {
      const { transactionType, loanId: loan_id, fromDate: from, toDate: to } = this;

      return {
        from,
        to,
        statuses: ["ready", "distributed"],
        loan_id,
        distribution_status: transactionType,
      };
    },
    groupedItems() {
      const groupedItems = {};

      if (!this.loading) {
        forEach(this.items, item => {
          forEach(item.primaryOwners, owner => {
            const isRetail = some(item.participatingFundingEntities, {
              name: "inv_fractionalized",
            });
            const ownerName = isRetail ? "retail" : owner.name;

            if (some(this.legalOwners, { id: ownerName })) {
              if (!groupedItems[ownerName]) {
                groupedItems[ownerName] = [];
              }
              groupedItems[ownerName].push(item);
            }
          });
        });
      }
      return groupedItems;
    },
    legalOwners() {
      if (this.loading) {
        return [];
      }

      if (this.displayMode === "detail") {
        return filter(this.$options.legalOwners, { id: this.currentLegalOwnerName });
      }

      if (this.legalOwnerNames.length === 0) {
        return this.$options.legalOwners;
      }

      return filter(this.$options.legalOwners, ({ id: name }) =>
        includes(this.legalOwnerNames, name)
      );
    },
    displayMode() {
      return this.currentLegalOwnerName ? "detail" : "overview";
    },
  },
  methods: {
    handleDistributeResponse(data) {
      if (data.invalid_item_ids.length) {
        this.invalidItems = `The following transactions are missing a series:${data.invalid_item_ids.map(
          i => " " + i
        )}.`;
      }
      if (data.item_ids.length) {
        AjaxService.sendNotification(
          `${data.item_ids.length} transactions queued for distribution`,
          SUCCESS_STATUS
        );
        this.items = this.items.filter(item => !data.item_ids.includes(item.id));
      }
    },
    async fetchData() {
      this.loading = true;

      if (this.abortController) {
        this.abortController.abort();
      }

      this.abortController = new AbortController();

      try {
        const url = `/manage/cash_receipt_items/?${formatParams(this.params)}`;
        const { items, total } = await AjaxService.getJSON(url, {}, this.abortController.signal);
        this.items = items;
        this.total = total;
        // TODO: [remove-hijack-redirect]
        if (this.ownerName) {
          this.displayDetail(this.ownerName);
          window.history.replaceState(
            null,
            "",
            window.location.href.replace(`&ownerName=${this.ownerName}`, "")
          );
        }
      } catch (error) {
        AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
      } finally {
        this.loading = false;
      }
    },
    async distribute(ids, handleSuccess) {
      try {
        await AjaxService.post(
          `/manage/cash_receipt_items/distribute`,
          { ids },
          { "content-type": "application/json" }
        ).then(data => this.handleDistributeResponse(data));
        handleSuccess();
      } catch (error) {
        AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
      }
    },
    async approve(ids, handleSuccess) {
      try {
        await AjaxService.post(
          `/manage/cash_receipt_items/approve`,
          { ids },
          { "content-type": "application/json" }
        );

        AjaxService.sendNotification(
          `${ids.length} transactions have been approved`,
          SUCCESS_STATUS
        );

        this.items = this.items.filter(item => !ids.includes(item.id));
        handleSuccess();
      } catch (error) {
        AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
      }
    },
    displayDetail(legalOwnerName) {
      this.currentLegalOwnerName = legalOwnerName;
    },
    displayOverview() {
      this.currentLegalOwnerName = null;
    },
  },
  created() {
    this.fetchData();
  },
  render() {
    const {
      legalOwners,
      groupedItems,
      loading,
      total,
      displayMode,
      displayDetail,
      displayOverview,
      fetchData,
      approve,
      distribute,
      invalidItems,
    } = this;

    return this.$scopedSlots.default({
      legalOwners,
      groupedItems,
      loading,
      total,
      displayMode,
      displayDetail,
      displayOverview,
      fetchData,
      approve,
      distribute,
      invalidItems,
    });
  },
  watch: {
    fromDate() {
      this.fetchData();
    },
    toDate() {
      this.fetchData();
    },
    loanId() {
      this.fetchData();
    },
    transactionType() {
      this.fetchData();
    },
  },
  legalOwners: PSData.legalOwners,
};
