export const RULES = [["Loan Amount", "loan_amount"], ["State", "state"]];

export const OPERATORS = [
  ["Equal to", "=="],
  ["Not equal to", "!="],
  ["Greater than", ">"],
  ["Less than", "<"],
  ["Greater than or equal to", ">="],
  ["Less than or equal to", "<="],
];
