const Event = PS.Models.Event;

class AutocompleteEvent extends Event {
  get sourceId() {
    return this.options.sourceId;
  }

  get query() {
    return this.options.query;
  }
}

class SetupRequested extends AutocompleteEvent {}

class SetupAutocompleteFinished extends AutocompleteEvent {}

class SelectResultRequested extends AutocompleteEvent {}

class AutocompleteSearchRequested extends AutocompleteEvent {}

class SuggestionValidationRequested extends AutocompleteEvent {
  get suggestion() {
    return this.options.suggestion;
  }
}

class AutocompleteSearchFinished extends AutocompleteEvent {}

class SuggestionAccepted extends AutocompleteEvent {}

class AddressApproved extends AutocompleteEvent {
  get address() {
    return this.options.address;
  }
}

class SuggestionSelectRequested extends AutocompleteEvent {
  get suggestionIndex() {
    return this.options.suggestionIndex;
  }
}

class ShowSuggestionsRequested extends AutocompleteEvent {}

class HideSuggestionsRequested extends AutocompleteEvent {}

class NextSuggestionRequested extends AutocompleteEvent {}

class PrevSuggestionRequested extends AutocompleteEvent {}

class SimulateAutocompleteValidationRequested extends SuggestionSelectRequested {}

PS.Models.Autocomplete.Events = {
  AutocompleteEvent: AutocompleteEvent,
  SetupRequested: SetupRequested,
  SetupAutocompleteFinished: SetupAutocompleteFinished,
  SelectResultRequested: SelectResultRequested,
  AutocompleteSearchRequested: AutocompleteSearchRequested,
  ShowSuggestionsRequested: ShowSuggestionsRequested,
  HideSuggestionsRequested: HideSuggestionsRequested,
  NextSuggestionRequested: NextSuggestionRequested,
  PrevSuggestionRequested: PrevSuggestionRequested,
  SuggestionSelectRequested: SuggestionSelectRequested,
  AutocompleteSearchFinished: AutocompleteSearchFinished,
  SuggestionValidationRequested: SuggestionValidationRequested,
  SuggestionAccepted: SuggestionAccepted,
  AddressApproved: AddressApproved,
  SimulateAutocompleteValidationRequested: SimulateAutocompleteValidationRequested,
};
