<template>
  <mfe-connect
    :service-url="loanApplicationServiceHost"
    application-name="lender_loan_applications"
    :props="props"
  />
</template>

<script>
import MfeConnect from "components/micro_frontend_connect";

export default {
  components: { MfeConnect },
  data() {
    return {
      loanApplicationServiceHost: `${window.location.protocol}//${
        PSData.loanApplicationServiceHost
      }`,
      props: {
        lenderSiteHost: `${PSData.borrowerPortalUrl}`,
        basePath: `/loan_applications`,
        brandingSettingsUrl: `${PSData.brandingSettingsUrl}`,
      },
    };
  },
};
</script>
