<template>
  <div>
    <h3>
      Servicer Data
    </h3>

    <table class="table">
      <tbody>
        <tr style="border-top:hidden;">
          <td class="font-weight-bold">Servicer Loan ID</td>
          <td class="text-right">
            <a
              v-if="reference.servicer === 'FCI'"
              :href="servicerUrl"
              target="_blank"
            >{{ reference.loanCode }}</a>
            <span v-else>
              {{ reference.loanCode }}
            </span>
          </td>
        </tr>

        <tr v-for="({label, filter}, key) in fields" :key="key" :label="label">
          <td class="font-weight-bold">{{ label }}</td>
          <td class="text-right">
            {{ getValue(key, filter) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import get from "lodash/get";
import Field from "./field";
import { formatDate, accounting } from "filters";

export default {
  components: {
    Field,
  },
  props: {
    reference: {
      type: Object,
      required: true,
    },
  },
  computed: {
    servicerUrl() {
      const { loanCode: code } = this.reference;
      return `https://lirs.trustfci.com/Loans?page=1&limit=30&fromFilter=True&account=${code}&balance=-1&department=0`;
    },
    fields() {
      return {
        servicer: { label: "Servicer Name" },
        description: { label: "Description" },
        checkAmount: { label: "Check Amount", filter: "money" },
        checkNumber: { label: "Check Number" },
        checkDate: { label: "Check Date", filter: "date" },
        code: { label: "Code" },
        serviceFee: { label: "Service Fees", filter: "money" },
        interest: { label: "Interest", filter: "money" },
        principal: { label: "Principal", filter: "money" },
        lateCharge: { label: "Late Charges", filter: "money" },
        chargesPrincipal: { label: "Principal Charges", filter: "money" },
        chargesInterest: { label: "Interest Charges", filter: "money" },
        prepayFees: { label: "Prepay Fee", filter: "money" },
        extensionFees: { label: "Extension Fees", filter: "money" },
        otherFees: { label: "Other Fees", filter: "money" },
        otherTaxable: { label: "Other Taxable", filter: "money" },
        otherNonTaxable: { label: "Other Non Taxable", filter: "money" },
        otherPayment: { label: "Other Payment", filter: "money" },
        "servicerOtherPayment.description": { label: "Other Payment Description" },
        "servicerOtherPayment.code": { label: "Other Payment Code" },
        "servicerOtherPayment.name": { label: "Other Payment Name" },
        "servicerOtherPayment.amount": { label: "Other Payment Amount", filter: "money" },
      };
    },
  },
  methods: {
    getValue(key, filter) {
      return this.applyFilter(get(this.reference, key), filter);
    },
    applyFilter(str, filter) {
      if (str === null || str === undefined) {
        return str;
      }

      switch (filter) {
        case "date":
          return formatDate(str);
        case "money":
          return accounting(str);
        default:
          return str;
      }
    },
  },
};
</script>
