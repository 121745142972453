export default class PhysicalDocsTrackingNumber {
  constructor(trackingNumber = {}, errors = {}) {
    this.id = trackingNumber.id;
    this.sendingTo = trackingNumber.sendingTo;
    this.receiver = trackingNumber.receiver || "";
    this.trackingNumber = trackingNumber.trackingNumber || "";
    this.carrierStatus = trackingNumber.carrierStatus || "";
    this.trackingUrl = trackingNumber.trackingUrl || "";
    this.carrier = trackingNumber.carrier || "";
    this.carrierText = trackingNumber.carrierText || "";
    this.carrierName = trackingNumber.carrierName || "";
    this.addedOn = trackingNumber.addedOn;
  }

  toHash() {
    return {
      receiver: this.receiver,
      tracking_number: this.trackingNumber,
      carrier: this.carrier,
      carrier_name: this.carrierName,
    };
  }
}
