import Rule from "models/rule";
import {
  DocumentsUpdated,
  NewDocumentsUploaded,
  DocumentDeleted,
  OneDocumentUpdated,
} from "models/loan/events/document_events";

import {
  UpdateDocuments,
  AddDocuments,
  RemoveDocument,
  UpdateOneDocument,
} from "models/loan/commands/document_commands";

export default class DocumentRules extends Rule {
  check(event) {
    switch (event.constructor) {
      case DocumentsUpdated:
        return this.whenDocumentsUpdated(event);
      case NewDocumentsUploaded:
        return this.whenNewDocumentsUploaded(event);
      case DocumentDeleted:
        return this.whenDocumentDeleted(event);
      case OneDocumentUpdated:
        return this.whenOneDocumentUpdated(event);
    }

    return super.check(event);
  }

  whenDocumentsUpdated(event) {
    return [new UpdateDocuments(event, this.state)];
  }

  whenNewDocumentsUploaded(event) {
    const metadata = event.options.metadata || {};
    if (metadata.taskId) {
      return [
        new AddDocuments(event, this.state),
        ...event.documents.map(document => {
          return new UpdateOneDocument(new OneDocumentUpdated(document), this.state);
        }),
      ];
    } else {
      return [new AddDocuments(event, this.state)];
    }
  }

  whenDocumentDeleted(event) {
    return [new RemoveDocument(event, this.state)];
  }

  whenOneDocumentUpdated(event) {
    return [new UpdateOneDocument(event, this.state)];
  }
}
