export default class LoanProgramParams {
  constructor(data = {}) {
    this.borrowerFico = this.parseValue(data.borrowerFico.value);
    this.borrowerGuarantorTrackRecord = this.parseValue(data.borrowerGuarantorTrackRecord.value);
    this.borrowerLiquidity = this.parseValue(data.borrowerLiquidity.value);
    this.borrowerResidency = data.borrowerResidency.value;
    this.borrowerType = data.borrowerType.value;
    this.bPieceAmount = this.parseValue(data.bPieceAmount.value);
    this.constructionReserve = this.parseValue(
      data.constructionReserve && data.constructionReserve.value
    );
    this.constructionReserveDisbursedAtValuation = this.parseValue(
      data.constructionReserveDisbursedAtValuation &&
        data.constructionReserveDisbursedAtValuation.value
    );
    this.draftAsIsValuation = this.parseValue(data.draftAsIsValuation.value);
    this.draftArv = this.parseValue(data.draftArv.value);
    this.extensionExercised = JSON.parse(data.extensionExercised.value);
    this.extensionTerm = data.extensionTerm.value;
    this.existingLoanAmount = this.parseValue(data.existingLoanAmount.value);
    this.initialPurchaseDate = data.initialPurchaseDate.value;
    this.interestRate = this.parseValue(data.interestRate.value);
    this.interestReserve = this.parseValue(data.interestReserve.value);
    this.juniorLienHolders = data.juniorLienHolders.value;
    this.juniorLienTotal = this.parseValue(data.juniorLienTotal.value);
    this.lenderNoteStatus = data.lenderNoteStatus.value;
    this.lenderTargetPurchaseDate = data.lenderTargetPurchaseDate.value;
    this.loanPurpose = data.loanPurpose.value;
    this.loanStrategy = data.loanStrategy.value;
    this.monthlyHoaDues = this.parseValue(data.monthlyHoaDues.value);
    this.originalMaturityDate = data.originalMaturityDate.value;
    this.originationDate = data.originationDate.value;
    this.otherReserves = data.otherReserves.value;
    this.paymentStructure = data.paymentStructure.value;
    this.projectedRehabBudget = this.parseValue(data.projectedRehabBudget.value);
    this.properties = this.parseValue(data.properties.value);
    const propertyLeased =
      data.propertyLeased.value === undefined ? null : data.propertyLeased.value;
    this.propertyLeased = JSON.parse(propertyLeased);
    this.propertyMarketRentPerMonth = this.parseValue(data.propertyMarketRentPerMonth.value);
    this.propertyType = data.propertyType.value;
    this.purchasePrice = this.parseValue(data.purchasePrice.value);
    this.paidHardConstructionCosts = this.parseValue(data.paidHardConstructionCosts.value);
    this.state = data.state.value;
    this.totalPrincipalBalanceAtOrigination = this.parseValue(
      data.totalPrincipalBalanceAtOrigination.value
    );
    this.totalPrincipalPayments = this.parseValue(data.totalPrincipalPayments.value);
    this.totalRentPerMonth = this.parseValue(data.totalRentPerMonth.value);
    this.yearlyPremium = this.parseValue(data.yearlyPremium.value);
    this.yearlyTaxesOwed = this.parseValue(data.yearlyTaxesOwed.value);

    this.baseRateCalcMethod = null;
    this.lenderSpread = null;
    this.medianHomePriceInZip = this.parseValue(data.medianHomePriceInZip);
    this.propertyCbsaType = data.cbsaType;
    this.propertyCbsaCode = data.cbsaCode;
    this.propertyCbsaName = data.cbsaName;
    this.designatedRentalIncome = data.designatedRentalIncome;
    this.programType = data.programType;
    this.propertyEffectivePurchasePrice = this.parseValue(data.purchasePrice.value);
    this.propertySellerCredit = null;
    this.purchaseRate = null;
    this.underwritingStatus = "draft";
    this.zip = data.zip.value;
    this.city = data.city.value;
    this.address = data.address.value;
    this.thirdPartyOriginationFeesAmount = this.parseValue(
      data.thirdPartyOriginationFeesAmount.value
    );
    this.thirdPartyOriginationPoints = this.parseValue(data.thirdPartyOriginationPoints.value);
    this.totalOriginationFeesAmount = this.parseValue(data.totalOriginationFeesAmount.value);
    this.totalOriginationPoints = this.parseValue(data.totalOriginationPoints.value);
    this.psOriginationFeesAmount = this.parseValue(data.psOriginationFeesAmount.value);
    this.psOriginationPoints = this.parseValue(data.psOriginationPoints.value);
    this.loanTerm = this.parseValue(data.loanTerm.value);
    const professionallyManaged =
      data.professionallyManaged.value === undefined ? null : data.professionallyManaged.value;
    this.professionallyManaged = JSON.parse(professionallyManaged);
    this.propertyLotAcres = this.parseValue(data.propertyLotAcres.value);
    this.draftValuationDate = data.draftValuationDate.value;
    this.draftValuationMethod = this.parseValue(data.draftValuationMethod.value);
    this.propertyOccupancyPercentage = this.parseValue(data.propertyOccupancyPercentage.value);
    this.propertyGroundLease = JSON.parse(data.propertyGroundLease.value || null);
    this.propertyEnvironmentalIssuesIdentified = JSON.parse(
      data.propertyEnvironmentalIssuesIdentified.value || null
    );
    this.personalGuarantyYN = JSON.parse(data.personalGuarantyYN.value || null);
    this.interestAccrualMethod = data.interestAccrualMethod.value;
    this.propertyUnitCount = this.parseValue(data.propertyUnitCount.value);
    this.propertyPurchaseDate = data.propertyPurchaseDate.value;
  }

  parseValue(value) {
    if (value === undefined || value === null || value === "") {
      return null;
    }

    if (typeof value === "number") {
      return parseFloat(value);
    }

    if (typeof value === "string") {
      return parseFloat(value.toString().replace(/,/g, ""));
    }

    return value;
  }

  toHash() {
    const plainObject = JSON.parse(JSON.stringify(this));
    const propertyNames = Object.getOwnPropertyNames(plainObject);
    let hash = {};
    propertyNames.forEach(property => {
      const key = property.replace(/[A-Z]/g, character => `_${character.toLowerCase()}`);
      hash[key] = plainObject[property];
    });

    return hash;
  }
}
