<template>
  <div class="responsible-party borrower">
    <div class="name">
      <img :src="icon" alt="Borrower">
      {{ borrower.name }}
    </div>
    <div class="meta">
      <span>
        Borrower
      </span>
      <span class="actions">
        <a href="#" @click.prevent="editBorrower">
          <img :src="require('images/icons/pencil-alt.svg')" alt="Edit">
          Edit
        </a>
      </span>
      <span class="actions">
        <a :href="viewDetailsPath">View Details</a>
      </span>
    </div>
  </div>
</template>

<script>
const borrowerIcon = require("images/icons/responsible-party-building.svg");
const userIcon = require("images/icons/responsible-party-user.svg");

export default {
  name: "responsible-parties-borrower",
  props: {
    borrower: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      routes: PSData.routes,
    };
  },
  computed: {
    icon() {
      if (this.borrower.borrowerType === "individual") {
        return userIcon;
      } else {
        return borrowerIcon;
      }
    },
    viewDetailsPath() {
      return `${this.routes.loanPath}/borrowers/${this.borrower.id}`;
    },
  },
  methods: {
    editBorrower() {
      this.$emit("on-edit", this.borrower);
    },
  },
};
</script>
