<template>
  <span v-if="data">
    {{ status }}
    <span v-if="showDueDate" :class="dueDateClass">
      {{ new Date(data.expectedAt) | formatDate("MM/DD/YYYY hh:mm A") }}
    </span>
  </span>
</template>

<script>
import moment from "moment";

export default {
  name: "qc-data",
  props: {
    name: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    status() {
      return `${this.name}: ${this.data.isCompleted ? "Completed" : "Open"}`;
    },
    showDueDate() {
      return !this.data.isCompleted && this.data.expectedAt;
    },
    dueDateClass() {
      if (!this.data || !this.data.expectedAt) {
        return;
      }

      const date = moment(this.data.expectedAt);
      const days = date.diff(moment(), "days");

      switch (true) {
        case days < 0:
          return "badge badge-danger";
        case days >= 0 && days <= 2:
          return "badge badge-warning";
        case days > 2:
          return "badge badge-success";
      }
    },
  },
};
</script>
