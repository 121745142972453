<template>
  <div class="details-row row non-total-row">
    <div class="col-12">
      <h4>Principal</h4>
    </div>
    <div class="col-6">
      <row label="PeerStreet Loan Amount" :value="principalAtPurchaseAmount" />
      <row label="Principal Adjustments" :value="principalAdjustmentsAmount" />
      <row label="Principal Payments" :value="principalPaymentsAmount" />
      <row v-if="!isDutch" label="Unfunded Commitment" :value="unfundedCommitmentAmount" />
      <row label="Current Principal Balance" :value="currentPrincipalBalance" />
      <row
        v-if="statement.priceDiscountPremiumAmount != 0.0"
        label="Price Discount/Premium"
        :value="statement.discountPremiumValue"
        total
      />
      <row label="Waived Principal" :value="statement.waivedPrincipalValue" total />
      <row label="Net Principal Due" :value="netPrincipalValue" total last />
    </div>
  </div>
</template>
<script>
import Row from "./row";
import NumberFormatter from "models/formatters/number_formatter";
import { mapState } from "pinia";
import useResaleStatementStore from "controllers/manage/store/resaleStatement";

export default {
  components: { Row },
  computed: {
    ...mapState(useResaleStatementStore, ["statement"]),
    principalAtPurchaseAmount() {
      return this.formatCurrency(this.statement.principalAtPurchaseAmount);
    },
    principalAdjustmentsAmount() {
      return this.formatCurrency(this.statement.principalAdjustmentsAmount);
    },
    principalPaymentsAmount() {
      return this.formatCurrency(this.statement.principalPaymentsAmount);
    },
    unfundedCommitmentAmount() {
      return this.formatCurrency(this.statement.unfundedCommitmentAmount);
    },
    currentPrincipalBalance() {
      return this.formatCurrency(this.statement.currentPrincipalBalance);
    },
    priceDiscountPremiumAmount() {
      return this.formatCurrency(this.statement.priceDiscountPremiumAmount);
    },
    waivedPrincipalValue() {
      return this.formatCurrency(this.statement.waivedPrincipalValue);
    },
    netPrincipalValue() {
      return this.formatCurrency(this.statement.netPrincipalValue);
    },
    isDutch() {
      return this.statement.interestAccrualMethod == "dutch";
    },
  },
  methods: {
    formatCurrency(value) {
      return NumberFormatter.formatAccounting(Number.parseFloat(value));
    },
  },
};
</script>
