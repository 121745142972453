<template>
  <div class="loan-suspension-reason checkbox-control">
    <input type="checkbox" :id="item.id" v-model="value" @click="onChoose">
    <label :for="item.id" :class="{ changed: item.isChanged }">
      <span class="control-toggle"></span>
      <base-tooltip v-if="item.tooltipLabel" :label="item.tooltipLabel" multilined>
        <span class="loan-suspension-reason-name">{{ item.label }}</span>
      </base-tooltip>
      <span v-else class="loan-suspension-reason-name">{{ item.label }}</span>
    </label>
  </div>
</template>
<script>
export default {
  name: "manage-loan-suspension-item",
  props: {
    item: {
      type: Object,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return { value: this.enabled };
  },
  methods: {
    onChoose(event) {
      this.$emit("choosed", { item: this.item, value: event.target.checked });
    },
  },
};
</script>
