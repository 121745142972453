<template>
  <div class="editing editable-field-container" >
    <div class="form-group currency">
      <base-tooltip 
        :multilined="true"
        position="is-top"
        :active="showTooltip"
        :label="tooltipContent"
      >
        <label v-if="label" class="editable-field-label control-label">
          {{ label }}
          <base-icon
          :style="{ position: 'absolute', top: '5px' }"
          v-if="showTooltip"
          pack="fas"
          size="is-small"
          icon="question-circle"
          type="hint"
        />
        </label>
      </base-tooltip>
      <div  class="loan-attribute-value">
        <div  class="input-group-wrapper">
          <div class="input-group flex-nowrap editable-field-input-wrapper">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              type="text"
              v-model="amountInputValue"
              class="form-control"
              :disabled="disabled"
              v-mask:currency />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toNumber from "lodash/toNumber";

export default {
  name: "EditableCurrencyField",
  props: {
    value: [String, Number, Function],
    label: String,
    callback: {
      type: Function,
      default: new Function(),
    },
    allowNull: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    tooltipContent: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      amountInputValue: this.inputDisplayValue(this.value),
    };
  },
  methods: {
    coerceNumber(amount) {
      return isNaN(amount) ? toNumber(amount.replace(/,/g, "")) : amount;
    },
    inputDisplayValue(value) {
      if (this.allowNull && value === null) {
        return null;
      }
      return parseFloat(value || 0).toFixed(2);
    },
  },
  watch: {
    amountInputValue() {
      if (this.allowNull && this.amountInputValue === "") {
        this.callback(null);
      } else {
        const value = this.coerceNumber(this.amountInputValue);
        this.callback(value);
      }
    },
  },
};
</script>
