import Command from "models/command";
import MessageBusService from "services/message_bus_service";
import {
  LoanUpdateSent,
  TransferInitiated,
  RefreshInitiated,
} from "models/funding_entities/events";
import AjaxService from "services/ajax_service";
import SeriesCollection from "collections/manage/series_collection";
import FundingEntitiesCollection from "collections/manage/funding_entities_collection";
import Loan from "models/funding_entities/loan";
import keyBy from "lodash/keyBy";
import { error } from "services/notification";

export class SendLoanUpdate extends Command {
  execute() {
    return Command.broadcastEvent(
      LoanUpdateSent,
      MessageBusService.settingsBus,
      this.event.options
    );
  }
}

export class CalculateFundingEntitiesAmounts extends Command {
  execute() {
    Object.keys(this.state.fundingEntitiesByName).forEach(name => {
      Vue.set(this.state.fundingEntitiesByName[name], "requiredAmount", 0);
    });

    this.state.wireRequests.forEach(wireRequest => {
      if (this.state.fundingEntitiesByName.hasOwnProperty(wireRequest.fundingEntityName)) {
        this.state.fundingEntitiesByName[
          wireRequest.fundingEntityName
        ].requiredAmount += parseFloat(wireRequest.requestAmount);
      }
    });

    this.state.loans.forEach(loan => {
      if (loan.checked) {
        loan.series.forEach(series => {
          const name = series.fundingEntity.name;
          if (this.state.fundingEntitiesByName.hasOwnProperty(name)) {
            this.state.fundingEntitiesByName[name].requiredAmount += parseFloat(series.amount);
          }
        });
      }
    });
    return Promise.resolve();
  }
}

export class InitiateTransfer extends Command {
  execute() {
    return Command.broadcastEvent(
      TransferInitiated,
      MessageBusService.settingsBus,
      this.event.options
    );
  }
}

export class SubmitSelectedSeries extends Command {
  execute() {
    this.state.errors = undefined;
    this.state.isInitiatingTransfer = true;

    const messages = {
      onSuccess: "Transfer successfully initiated",
      onError: ({ response, data }) => {
        if (response.status === 422) {
          this.state.errors = data.errors;
        } else {
          error("Failed to initiate transfers");
        }
      },
    };

    const loansSnapshot = this.state.loans
      .filter(loan => loan.checked)
      .map(loan => new Loan(loan).toHash());

    return AjaxService.withNotifications(messages)
      .postJSON("/manage/funding_entities/fund_transfers", {
        loans: loansSnapshot,
      })
      .finally(() => {
        this.state.isInitiatingTransfer = false;
      });
  }
}

export class InitiateRefresh extends Command {
  execute() {
    return Command.broadcastEvent(
      RefreshInitiated,
      MessageBusService.settingsBus,
      this.event.options
    );
  }
}

function selectLoans(loans) {
  return loans.map(loan => ({ ...loan, checked: true }));
}

export class RefreshView extends Command {
  execute() {
    this.state.errors = undefined;
    this.state.isLoading = true;
    const messages = {
      onSuccess: "List successfully refreshed",
      onError: "Failed to refresh list",
    };

    return AjaxService.withNotifications(messages)
      .getJSON("/manage/funding_entities.json")
      .then(({ fundingEntities, series, wireRequests }) => {
        const fundingEntitiesByName = keyBy(fundingEntities, "name");
        const bankAccounts = FundingEntitiesCollection.pickBankAccounts(fundingEntities);
        this.state.fundingEntitiesByName = fundingEntitiesByName;
        this.state.bankAccounts = bankAccounts;
        this.state.wireRequests = wireRequests;
        this.state.loans = selectLoans(SeriesCollection.groupedByLoan(series));
      })
      .finally(() => {
        this.state.isLoading = false;
      });
  }
}
