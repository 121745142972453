<template>
  <div class="columns mt-4">
    <div class="column is-half">
      <div class="assignee-filter">
        <div class="mr-5">
          <base-radio v-model="assigneeFilter" name="assignee" native-value="my_entities">
            {{ myEntitiesLabel }}
          </base-radio>
        </div>
        <div v-if="allOptionEnabled" class="mr-5">
          <base-radio v-model="assigneeFilter" name="assignee" native-value="all_entities">
            {{ allEntitiesLabel }}
          </base-radio>
        </div>
        <div class="mr-2">
          <base-radio v-model="assigneeFilter" name="assignee" native-value="assignee_entities">
            Select Assignee
          </base-radio>
        </div>
        <div class="assignee-select">
          <base-select-field
            v-model="assigneeId"
            :disabled="!isSelectAssigneeFilter"
            :options="options"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isNil from "lodash/isNil";

const MY_ENTITIES = "my_entities";
const ALL_ENTITIES = "all_entities";
const ASSIGNEE_ENTITIES = "assignee_entities";

export default {
  props: {
    currentUserId: {
      type: [String, Number],
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    initialFilter: {
      type: [String, Number],
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => ({}),
    },
    allOptionEnabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      assigneeFilter: null,
      assigneeId: null,
    };
  },
  computed: {
    isMyEntitiesFilter() {
      return this.assigneeFilter === MY_ENTITIES;
    },
    isAllEntitiesFilter() {
      return this.assigneeFilter === ALL_ENTITIES;
    },
    isSelectAssigneeFilter() {
      return this.assigneeFilter === ASSIGNEE_ENTITIES;
    },
    myEntitiesLabel() {
      return this.labels[MY_ENTITIES];
    },
    allEntitiesLabel() {
      return this.labels[ALL_ENTITIES];
    },
  },
  watch: {
    assigneeFilter() {
      if (this.isMyEntitiesFilter) {
        this.$emit("filter", this.currentUserId);
        return;
      }
      if (this.isAllEntitiesFilter) {
        this.$emit("filter", undefined);
        return;
      }
      if (!isNil(this.assigneeId)) {
        this.$emit("filter", this.assigneeId);
      }
    },
    assigneeId() {
      if (this.isSelectAssigneeFilter) {
        this.$emit("filter", this.assigneeId);
      }
    },
  },
  created() {
    this.assigneeFilter = this.updateAssigneeFilter(this.initialFilter);
  },
  methods: {
    updateAssigneeFilter(filter) {
      if (filter === "all") {
        return ALL_ENTITIES;
      }
      if (filter === this.currentUserId) {
        return MY_ENTITIES;
      }
      return ASSIGNEE_ENTITIES;
    },
  },
};
</script>
<style scoped>
.assignee-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.assignee-select {
  width: 50%;
}
</style>
