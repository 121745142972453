<template>
  <ul class="tag-list" :class="listOrientationClass">
    <tag v-for="tag in tags"
         :active="true"
         :editable="false"
         :name="tag.name"
         :key="tag.name" />
  </ul>
</template>

<script>
import Tag from "./tag";
import TagService from "services/tag_service";

export default {
  name: "tag-list",
  components: {
    Tag,
  },
  props: {
    messageBus: {
      required: false,
    },
    orientation: {
      type: String,
      required: true,
    },
    initialTags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tags: TagService.sortAlphabetically(this.initialTags),
    };
  },
  created() {
    if (this.messageBus) {
      this.messageBus.subscribe(this.handleUpdatedTagsEvent);
    }
  },
  computed: {
    listOrientationClass() {
      return this.orientation === "vertical" ? "tag-list--vertical" : "tag-list--horizontal";
    },
  },
  methods: {
    handleUpdatedTagsEvent({ tags }) {
      this.tags = TagService.sortAlphabetically(tags);
    },
  },
};
</script>
