const Event = PS.Models.Event;
const Rule = PS.Models.Rule;
const Events = PS.Models.Permission.Events;
const Commands = PS.Models.Permission.Commands;

class TableRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.DataSetupRequested:
        return this.whenDataSetupRequested(event);
      case Events.RolesSetupRequested:
        return this.whenRolesSetupRequested(event);
      case Events.UsersSetupRequested:
        return this.whenUsersSetupRequested(event);
      case Events.RoleUpdated:
        return this.whenRoleUpdated(event);
      case Events.ResetChangesRequested:
        return this.whenResetChangesRequested(event);
      case Events.SaveChangesRequested:
        return this.whenSaveChangesRequested(event);
    }

    return super.check(event);
  }

  whenDataSetupRequested(event) {
    return [
      new Commands.PrepareUsers(event, this.state),
      new Commands.PrepareRoles(event, this.state),
    ];
  }

  whenRolesSetupRequested(event) {
    return [new Commands.PrepareRoles(event, this.state)];
  }

  whenUsersSetupRequested(event) {
    return [new Commands.PrepareUsers(event, this.state)];
  }

  whenRoleUpdated(event) {
    return [new Commands.AddChange(event, this.state)];
  }

  whenResetChangesRequested(event) {
    if (this.state.updating) {
      return [];
    }

    return [new Commands.ResetChanges(event, this.state)];
  }

  whenSaveChangesRequested(event) {
    if (this.state.updating || !this.state.hasChanges) {
      return [];
    }

    return [
      new Commands.StartUpdating(event, this.state),
      new Commands.SendToServer(event, this.state),
      new Commands.FinishUpdating(event, this.state),
      new Commands.ResetChanges(event, this.state),
      new Commands.PrepareUsers(event, this.state),
      new Commands.PrepareRoles(event, this.state),
    ];
  }
}

PS.Models.Permission.Rule = TableRule;
