const Event = PS.Models.Event;
const Rule = PS.Models.Rule;
const Commands = PS.Models.Lenders.Commands.UpdateSettings;
const Events = PS.Models.Lenders.Events;

class UpdateSettingsRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.SaveChangesRequested:
        return this.whenSaveChangesRequested(event);
    }

    return super.check(event);
  }

  whenSaveChangesRequested(event) {
    return [
      new Commands.StartUpdating(event, this.state),
      new Commands.SendToServer(event, this.state),
      new Commands.FinishUpdating(event, this.state),
    ];
  }
}

PS.Models.Lenders.UpdateSettingsRule = UpdateSettingsRule;
