class LegalOwnershipService {
  static all(loan) {
    let url = `/manage/loans/${loan.toParam}/legal_ownerships`;
    return PS.Services.AjaxService.get(url);
  }

  static update(ownership) {
    let url = `/manage/legal_ownerships/${ownership.id}`;
    let messages = {
      onSuccess: "Legal ownership updated.",
      onError: ({ response, data }) => {
        if (response.status === 422) {
          return {
            message: `Legal ownership has not been updated: ${data.error}`,
            status: "error",
          };
        }
      },
    };
    let data = {
      legal_ownership: this.getPayload(ownership),
    };

    return PS.Services.AjaxService.withNotifications(messages).patch(url, data);
  }

  static getPayload(ownership) {
    return {
      owner_id: ownership.ownerId,
      wire_date: ownership.wireDate,
      start_date: ownership.startDate,
      end_date: ownership.endDate,
      note: ownership.note,
    };
  }
}

PS.Services.LegalOwnershipService = LegalOwnershipService;
