<template>
  <validation-provider
    v-slot="{ errors, valid }"
    name="Series IDs"
    :rules="rules"
    slim
  >
    <div class="form-group">
      <label>Series IDs</label>
      <textarea
        v-model="innerValue"
        class="form-control loan-ids-input"
        :class="validationClass(valid)"
        placeholder="Copy and paste Series IDs"
        name="loanIds"
        :disabled="disabled"
      />

      <div v-if="valid === false" class="invalid-feedback">
        {{ errors.map(({ msg }) => msg).join(", ") }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

const ID_PREFIX = PSData.seriesIdPrefix || "11";
const VALID_IDS_FORMAT = new RegExp(`^((\\s|,)*(?:${ID_PREFIX})?\\d{10}(\\s|,)*)+$`);

export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },
  data: () => ({ innerValue: "" }),
  computed: {
    rules() {
      return { required: true, regex: VALID_IDS_FORMAT };
    },
  },
  watch: {
    innerValue: {
      immediate: true,
      handler(val) {
        this.$emit("input", this.formatValue(val));
      },
    },
  },
  methods: {
    validationClass: valid => (valid === null ? null : valid ? "is-valid" : "is-invalid"),
    formatValue: val => val.split(/,?\s+/).map(seriesPsId => ({ seriesPsId })),
  },
};
</script>
