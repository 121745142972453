<template>
  <multi-select2
    class="form-control"
    v-model="selectedOptionsTypes"
    :options="{ data: types, placeholder: placeholder }"
    :after-render="onRendered"
  />
</template>

<script>
import MultiSelect2 from "components/shared/multi_select2";
import Event from "models/event";
import FilterParams from "models/hotlist/filter_params";
import {
  RequestFilterLoansByPropertyType,
  RequestFilterLoansByLoanProgram,
  RequestFilterLoansByAccountType,
} from "models/reports/hotlist_loans/commands/hotlist_loans_commands";

export default {
  name: "hotlist-filter",
  components: {
    MultiSelect2,
  },
  props: {
    allItem: Object,
    filterOptions: Array,
    orderedTypes: Array,
    placeholder: String,
    role: String,
  },
  data() {
    return {
      selectedOptionsTypes: [],
    };
  },
  watch: {
    selectedOptionsTypes(options) {
      if (this.role === "accountTypes") {
        new RequestFilterLoansByAccountType(new Event(), {
          selectedAccountTypes: options,
        }).execute();
      }

      if (this.role === "loanPrograms") {
        new RequestFilterLoansByLoanProgram(new Event(), {
          selectedLoanPrograms: options,
        }).execute();
      }

      if (this.role === "propertyTypes") {
        new RequestFilterLoansByPropertyType(new Event(), {
          selectedPropertyTypes: options,
        }).execute();
      }
    },
  },
  methods: {
    onRendered() {
      if (this.role === "accountTypes") {
        this.selectedOptionsTypes = FilterParams.instance.loadParams().accountTypes;
      }

      if (this.role === "loanPrograms") {
        this.selectedOptionsTypes = FilterParams.instance.loadParams().loanPrograms;
      }

      if (this.role === "propertyTypes") {
        this.selectedOptionsTypes = FilterParams.instance.loadParams().propertyTypes;
      }
    },
  },
  computed: {
    types() {
      return [this.allItem].concat(
        this.orderedTypes
          .map(ordered_type => {
            return this.filterOptions.find(t => t.name === ordered_type);
          })
          .map(type => {
            return { id: type.name, text: type.text };
          })
      );
    },
  },
};
</script>
