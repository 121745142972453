import requireGoogleMap from "google_maps";

$(function() {
  class GoogleMaps {
    constructor($element) {
      this.fakeLoanElements = {};

      var mapOptions = {
        center: { lat: 39, lng: -95 },
        zoom: 4,
        maxZoom: 20,
        minZoom: 1,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
        styles: [
          {
            featureType: "all",
            stylers: [{ saturation: -50 }],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [{ hue: "#00ffee", saturation: 50 }],
          },
          {
            featureType: "poi.business",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
        ],
      };

      this.geocoder = new google.maps.Geocoder();
      this.map = new google.maps.Map($element, mapOptions);

      this.bounds = new google.maps.LatLngBounds();
    }

    closeExistingInfoWindow() {
      if (window.infoWindow) {
        window.infoWindow.close();
      }
    }

    addMarker(loan) {
      if (loan.fullAddress === "") {
        return;
      }

      if (loan.latitude !== undefined && loan.longitude !== undefined) {
        var location = new google.maps.LatLng(loan.latitude, loan.longitude);
        var marker = this.createMarker(location, loan.fullAddress, loan.status);
        this.updateBounds(marker);
      } else {
        this.geocoder.geocode({ address: loan.fullAddress }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            var marker = this.createMarker(
              results[0].geometry.location,
              loan.fullAddress,
              loan.status
            );
            this.updateBounds(marker);
          }
        });
      }
    }

    createMarker(location, title, status) {
      var color = this.getStatusColor(status);
      var marker = new google.maps.Marker({
        map: this.map,
        position: location,
        icon: {
          path:
            "M4.99909115,12.142334 C4.99909115,12.142334 9.99818134,7.59179687 9.99818204,4.99909102 C9.99818273,2.40638516 7.76001275,0 4.99909102,0 C2.23816929,0 0,2.23816929 0,4.99909102 C0,7.76001275 4.99909115,12.142334 4.99909115,12.142334 Z",
          scale: 1.5,
          fillColor: color,
          fillOpacity: 1.0,
          strokeColor: "#ffffff",
          strokeWeight: 1,
        },
      });

      marker.addListener("mouseover", () => {
        this.closeExistingInfoWindow();
        window.infoWindow = new google.maps.InfoWindow({ content: title });
        window.infoWindow.open(this.map, marker);
      });

      marker.addListener("mouseout", () => {
        this.closeExistingInfoWindow();
      });

      return marker;
    }

    updateBounds(marker) {
      this.bounds.extend(marker.position);
      this.map.fitBounds(this.bounds);
    }

    getStatusColor(status) {
      if (!this.fakeLoanElements[status]) {
        this.fakeLoanElements[status] = $(
          `<div class="loan-status loan-${status} hidden" />`
        ).appendTo($("body"));
      }

      return this.fakeLoanElements[status].css("background-color") || "#999999";
    }
  }

  requireGoogleMap(function() {
    var container = document.getElementById("loans-map");
    if (container && PSData.mapLoans !== undefined) {
      var maps = new GoogleMaps(container);
      var loans_length = PSData.mapLoans.length;
      for (var i = 0; i < loans_length; i++) {
        maps.addMarker(PSData.mapLoans[i]);
      }
    }
  });
});
