<template>
  <div>
    <task-group-dropdown v-model="groupId" :groups="filteredTaskGroups" class="form-group" />
    <div>
      <textarea
        class="loan-task-question-title-input form-input"
        v-model="title"
        ref="question-task-description"
        placeholder="Question for Lender"
      />
    </div>

    <div class="loan-task-buttons-container loan-task-buttons">
      <button class="btn btn-link loan-task-btn" @click="cancel">
        Cancel
      </button>
      <button class="btn btn-action-cta loan-task-btn" :disabled="formIsDisabled" @click="submitForm">
        <slot name="submit-text"></slot>
      </button>
    </div>
  </div>
</template>

<script>
import TaskGroupDropdown from "components/activity_feed_events/manage/loan_tasks/task_group_dropdown";

export default {
  name: "loan-tasks-question-form",
  components: {
    TaskGroupDropdown,
  },
  props: {
    task: {
      type: Object,
      default: () => {
        return {
          title: "",
          groupId: "",
        };
      },
    },
    taskGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: this.task.title,
      groupId: this.task.groupId,
    };
  },
  methods: {
    submitForm() {
      this.$emit("form-submitted", {
        title: this.title,
        loan_task_group_id: this.groupId,
        tasklist_milestone_id: this.groupId,
      });
    },
    cancel() {
      this.$emit("form-canceled");
    },
  },
  computed: {
    formIsDisabled() {
      return this.title.trim().length === 0 && this.groupId;
    },
    filteredTaskGroups() {
      return this.taskGroups.filter(group => group.visibility === "lender");
    },
  },
};
</script>
