<template>
  <div>
    <stacked-bar-chart :chart="chart" :sources="sources" :link="loansUrl" />
  </div>
</template>

<script>
import { format } from "d3";
import stackedBarChartComponent from "components/charts/stacked_bar_chart";

export default {
  components: {
    "stacked-bar-chart": stackedBarChartComponent,
  },
  props: {
    sources: Object,
    loansUrl: String,
  },
  mounted() {
    this.$watch(
      "sources.totals",
      newVal => {
        this.chart.legendSubtext = newVal;
      },
      { deep: true }
    );
    this.$watch(
      "sources.display",
      newVal => {
        this.changeDisplay();
      },
      { deep: true }
    );
  },
  data() {
    return {
      chart: {
        title: this.sources.name,
        customLegendPosition: "top",
        legendSubtext: this.sources.totals,
        axis: {
          x: {
            type: "category",
            categories: ["Evaluating", "Qualified", "Upload Files", "In Diligence", "Purchasing"],
          },
          y: {
            tick: {
              format: format("$,"),
            },
          },
        },
        columns: {
          funded: {
            color: "#66cc33",
            label: "Funded",
          },
          not_funded: {
            color: "#5b6770",
            label: "Not Funded",
          },
          funding_into_origination: {
            color: "#3a9bfc",
            label: "Funding Into Origination",
          },
          table_funding: {
            color: "#ffbb00",
            label: "Table Funding",
          },
        },
        legend: {
          show: false,
        },
        grid: {
          y: {
            show: true,
          },
        },
        groups: [["Funded", "Not Funded", "Funding Into Origination", "Table Funding"]],
      },
    };
  },
  methods: {
    changeDisplay() {
      let columns = Object.keys(this.chart.columns);

      columns.forEach((column, i) => {
        this.chart.columns[column].hide = !this.sources.display[column];
      });
    },
  },
};
</script>
