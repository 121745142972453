<template>
  <div class="task-list">
    <task-category-list
      v-for="category in taskStatusCategories"
      :key="category.name"
      :category="category"
      :tasks="filteredTasks(category.name)"
      :edit-disabled="editDisabled"
      :workflow="workflow"
      @update-task="$listeners['update-task']"
      v-on="$listeners"
    />
  </div>
</template>
<script>
import TaskCategoryList from "./task_category_list";

export default {
  components: {
    TaskCategoryList,
  },
  props: {
    editDisabled: Boolean,
    workflow: {
      type: Object,
      required: true,
    },
    tasks: {
      type: Array,
      required: true,
    },
  },
  computed: {
    taskStatusCategories: () => PSData.taskStatusTypes,
  },
  methods: {
    filteredTasks(status) {
      return this.tasks.filter(t => t.status === status);
    },
  },
};
</script>
