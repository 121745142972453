
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MaxRollingDeclineFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Summary"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"geoId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"geoType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"recordedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rolling3MaxDecline"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rolling6MaxDecline"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rolling9MaxDecline"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rolling12MaxDecline"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rolling15MaxDecline"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rolling18MaxDecline"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rolling21MaxDecline"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rolling24MaxDecline"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rolling27MaxDecline"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rolling30MaxDecline"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rolling33MaxDecline"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rolling36MaxDecline"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":347}};
    doc.loc.source = {"body":"fragment MaxRollingDeclineFragment on Summary {\n  id\n  geoId\n  geoType\n  recordedAt\n  rolling3MaxDecline\n  rolling6MaxDecline\n  rolling9MaxDecline\n  rolling12MaxDecline\n  rolling15MaxDecline\n  rolling18MaxDecline\n  rolling21MaxDecline\n  rolling24MaxDecline\n  rolling27MaxDecline\n  rolling30MaxDecline\n  rolling33MaxDecline\n  rolling36MaxDecline\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
