<template>
  <div>
    <table class="table document-tracking-table table-striped table-hover">
      <thead>
        <tr>
          <th>Document</th>
          <th class="document-description">Description</th>
          <th class="document-tracking">Tracking Number</th>
          <th class="document-status">Status</th>
          <th class="document-updated">Last Updated</th>
          <th class="document-updated">Updated By</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="document in trackingDocuments" :key="document.name">
          <td class="col-md-2">
            {{ document.name }}
          </td>
          <td class="col-md-2">
            <autosize-textarea
              v-model="document.description"
              class="form-control"
              type="text"
              maxlength="255"
            />
          </td>
          <td class="col-md-2 tracking-number-select">
            <select
              v-bind:value="document.trackingNumberId"
              v-on:change="updateDocTrackingNumber($event, document.id)"
              class="tracking-number-select"
            >
              <option value="">No Tracking Information</option>
              <option
                v-for="trackingNumber in trackingNumbers"
                :value="trackingNumber.id"
              >
                {{ trackingNumber.trackingNumber }}
              </option>
            </select>
          </td>
          <td class="document-status">
            <select
              v-model="document.eventTypeId"
              v-on:change="onEventTypeChange(document)">
              <optgroup v-for="group in optionGroups" :key="group[0].groupId">
                <option
                  v-for="eventType in group"
                  :key="eventType.name"
                  :value="eventType.id">
                  {{ eventType.label }}
                </option>
              </optgroup>
            </select>
          </td>
          <td class="document-updated">{{document.lastUpdatedAt}}</td>
          <td class="document-updated">{{document.lastUpdatedBy}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AutosizeTextarea from "components/shared/autosize_textarea";

export default {
  name: "document-tracking-edit",
  props: ["trackingDocuments", "addTrackingEventToCreate", "trackingEventTypes", "trackingNumbers"],
  components: {
    AutosizeTextarea,
  },
  computed: {
    optionGroups() {
      return this.trackingEventTypes.reduce((acc, eventType) => {
        const groupArray = acc[eventType.group] || [];
        acc[eventType.group] = [
          ...groupArray,
          { ...eventType, groupId: `group-${eventType.group}` },
        ];

        return acc;
      }, []);
    },
  },
  methods: {
    updateDocTrackingNumber(event, documentId) {
      this.$emit("update-doc-tracking-number", event.target.value, documentId);
    },
    onEventTypeChange(trackingDocument) {
      const trackingEvent = this.trackingEventTypes.find(
        eventType => eventType.id === trackingDocument.eventTypeId
      );

      trackingEvent &&
        this.addTrackingEventToCreate({
          documentId: trackingDocument.id,
          trackingEventTypeId: trackingEvent.id,
        });
    },
  },
};
</script>
