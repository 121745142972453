<template>
  <div class="details-row row">
    <div class="col-12">
      <h4>Interest</h4>
    </div>
    <div class="col-12">
      <h5>Rate Details</h5>
    </div>
    <div class="col-4">
      <editable-field
        label="Interest Rate"
        :value="statement.interestRate"
        :editing="isEditable"
        percentage
      />
    </div>
    <div class="col-4">
      <editable-field
        label="Default Rate"
        :value="statement.defaultRate"
        :editing="isEditable"
        percentage
      />
    </div>
    <div class="col-4" />
    <div class="col-4">
      <editable-field
        label="Originator Servicing Spread"
        :value="statement.lenderRate"
        :editing="isEditable"
        percentage
      />
    </div>
    <div class="col-4">
      <editable-field
        label="PeerStreet Servicing Spread"
        :value="statement.psServicingSpread"
        :editing="isEditable"
        percentage
      />
    </div>
    <div class="col-4" />
    <div class="col-4">
      <editable-field
        label="Investor Rate"
        :value="statement.investorRate"
        :editing="isEditable"
        percentage
      />
    </div>
  </div>
</template>

<script>
import EditableField from "components/shared/editable_field.vue";

export default {
  name: "repurchase-statement-edit-interest",
  components: {
    EditableField,
  },
  props: ["isEditable", "statement"],
};
</script>
