<template>
  <select-input
    v-model="innerValue"
    label="Transfer Reserves"
    name="transferReserves"
    rules="required"
    :options="$options.selectOptions"
    :disabled="disabled"
  />
</template>

<script>
import SelectInput from "./select_input";

export default {
  components: {
    SelectInput,
  },
  props: {
    legalOwnerId: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
  },
  data() {
    return { innerValue: JSON.stringify(!!this.value) };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.innerValue = JSON.stringify(!!val);
      },
    },
    innerValue: {
      immediate: true,
      handler(val) {
        this.$emit("input", JSON.parse(val));
      },
    },
    legalOwnerId: {
      handler() {
        this.innerValue = "false";
      },
    },
  },
  selectOptions: [{ id: "true", text: "Yes" }, { id: "false", text: "No" }],
};
</script>
