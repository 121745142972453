<template>
  <div class="pricing-tool">
    <base-header class="">Update Rates</base-header>
    <div class="b-tabs base-tabs pricing-tabs">
      <nav role="tablist" aria-orientation="horizontal" class="tabs is-medium">
        <ul>
          <router-link :to="{ name: 'run_batch', params: { id: 'new' }}" active-class="is-active" tag="li"><a role="tab">Run Batch</a></router-link>
          <router-link :to="{ name: 'history'}" active-class="is-active" tag="li"><a role="tab">History</a></router-link>
        </ul>
      </nav>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "pricing-tool-app",
};
</script>

<style scoped>
.pricing-tool {
  color: var(--color-grey-86);
  font-family: var(--font-family);
  font-size: var(--font-size);
  margin: 0 auto;
  padding-top: 28px;
  width: 1255px;
}
.pricing-tool .base-header {
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 4px;
}
.pricing-tabs:not(:last-child) {
  margin-bottom: 0;
}
.pricing-tool >>> .tabs ul {
  border-bottom: 1px solid var(--color-grey-16);
  margin-bottom: 24px;
}
.pricing-tool >>> .tabs li {
  padding-right: 24px;
}
.pricing-tool >>> .tabs li a {
  border-bottom: 4px solid transparent;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
}
.pricing-tool >>> .tabs li a,
.pricing-tool >>> .tabs li a:hover,
.pricing-tool >>> .tabs li.is-active a:hover,
.pricing-tool >>> .tabs li a:not([href]):not([tabindex]),
.pricing-tool >>> .tabs li a:not([href]):not([tabindex]):hover {
  text-decoration: none;
}
.pricing-tool >>> .tabs li a,
.pricing-tool >>> .tabs li.is-active a,
.pricing-tool >>> .tabs li a:not([href]):not([tabindex]),
.pricing-tool >>> .tabs li a:not([href]):not([tabindex]):hover {
  color: var(--color-grey-72);
  font-size: var(--font-size-md);
}
.pricing-tool >>> .tabs li.is-active a,
.pricing-tool >>> .tabs li.is-active a:not([href]):not([tabindex]),
.pricing-tool >>> .tabs li.is-active a:not([href]):not([tabindex]):hover {
  border-bottom-color: var(--color-blue);
  color: var(--color-grey-86);
}
</style>
