import LoanAssociationMerger from "collections/manage/loan_association_merger";

export const SlaStatus = Object.freeze({
  NOT_STARTED: "NOT_STARTED",
  ACTIVE: "ACTIVE",
  COMPLETED: "COMPLETED",
});

const Loans = {
  mergeAssociations(models) {
    return new LoanAssociationMerger(models.loans)
      .makeLoansVisible()
      .applyDynamicDates(models.serverTime)
      .mergeWithAnalysts(models.analysts)
      .mergeWithQcApprover(models.analysts)
      .mergeWithLenders(models.lenders)
      .mergeWithTaskAssigneeIds(models.loanTaskAssigneeIds)
      .mergeWithDocuments(models.documents).loans;
  },

  get emptyGroup() {
    return {
      draft: [],
      evaluating: [],
      qualified: [],
      uploadFiles: [],
      diligencing: [],
      diligenced: [],
      purchasing: [],
      wired: [],
      purchased: [],
      review: [],
      canceled: [],
      rejected: [],
      paidOff: [],
    };
  },

  groupByUnderwritingStatus(loans = [], group = Loans.emptyGroup) {
    loans.forEach(loan => {
      switch (loan.purchasedGroup) {
        case "draft":
          {
            group.draft.push(loan);
          }
          break;
        case "evaluating":
          {
            group.evaluating.push(loan);
          }
          break;
        case "qualified":
          {
            group.qualified.push(loan);
          }
          break;
        case "upload_files":
          {
            group.uploadFiles.push(loan);
          }
          break;
        case "diligencing":
          {
            group.diligencing.push(loan);
          }
          break;
        case "diligenced":
          {
            group.diligenced.push(loan);
          }
          break;
        case "purchasing":
          {
            group.purchasing.push(loan);
          }
          break;
        case "wired":
          {
            group.wired.push(loan);
          }
          break;
        case "review":
          {
            group.review.push(loan);
          }
          break;
        case "canceled":
          {
            group.canceled.push(loan);
          }
          break;
        case "rejected":
          {
            group.rejected.push(loan);
          }
          break;
        default: {
          group.purchased.push(loan);
        }
      }
    });

    return group;
  },
};

PS.Collections.Loans = Loans;

export default Loans;
