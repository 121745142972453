<template>
  <div class="columns pricing-tool-columns">
    <criteria />

    <div class="column">
      <preview />
    </div>
  </div>
</template>

<script>
import Criteria from "./criteria";
import Preview from "./preview";

export default {
  name: "run-batch",
  components: {
    Criteria,
    Preview,
  },
  data() {
    return {};
  },
};
</script>
<style>
.pricing-tool-columns {
  margin: 0;
}
.pricing-tool-columns > .column {
  margin-bottom: 24px;
  padding: 0;
}
.pricing-tool-columns > .column.is-3 {
  margin-right: 24px;
  width: 340px;
}
.pricing-tool-columns label {
  font-weight: 600;
  height: 19px;
}
.pricing-tool b {
  font-weight: 600;
}
</style>
