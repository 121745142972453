<template>
  <tr @click="openTab(`/manage/loans/${psLoanId}/asset_management`)">
    <td>{{ psLoanId }}</td>
    <td @click.stop="">
      <servicer-loan-info
        :id="servicerLoanId"
        :url="servicerLoanUrl"
      />
    </td>
    <td>{{ assetManager }}</td>
    <td>{{ propertyAddress }}</td>
    <td>{{ loanForeclosureAction }}</td>
    <td>{{ servicerLoanStatus }}</td>
    <td>{{ daysPastDue }}</td>
    <td>{{ daysToMaturity }}</td>
    <td>{{ loanOwner }}</td>
    <td>{{ amountOnPsWarehouseLine | money }}</td>
    <td>{{ unpaidPrincipalBalance | money }}</td>
    <td>{{ lastOutreachDate }}</td>
    <td>{{ lastOutreachStatusLabel }}</td>
  </tr>
</template>

<script>
import ServicerLoanInfo from "../components/servicer_loan_info.vue";
import OpenTabMixin from "mixins/open_tab_mixin";

export default {
  components: {
    ServicerLoanInfo,
  },
  mixins: [OpenTabMixin],
  props: {
    psLoanId: {
      type: String,
      default: "",
    },
    servicerLoanId: {
      type: String,
      default: "",
    },
    servicerLoanUrl: {
      type: String,
      default: "",
    },
    assetManager: {
      type: String,
      default: "",
    },
    propertyAddress: {
      type: String,
      default: "",
    },
    loanForeclosureAction: {
      type: String,
      default: "",
    },
    servicerLoanStatus: {
      type: String,
      default: "",
    },
    daysPastDue: {
      type: Number,
      required: true,
    },
    daysToMaturity: {
      type: Number,
      required: true,
    },
    loanOwner: {
      type: String,
      default: "",
    },
    unpaidPrincipalBalance: {
      type: String,
      required: true,
    },
    amountOnPsWarehouseLine: {
      type: String,
      required: true,
    },
    lastOutreachDate: {
      type: String,
      default: "",
    },
    lastOutreachStatusLabel: {
      type: String,
      default: "",
    },
  },
};
</script>
