import WindowService from "services/window_service";
import LocalStorageService from "services/local_storage_service";

export const DEFAULT_ANALYSTS = [];
export const DEFAULT_TASK_ASSIGNEES = [];
export const DEFAULT_PROPERTY_TYPES = [];
export const DEFAULT_LOAN_PROGRAMS = [];
export const DEFAULT_ACCOUNT_TYPES = [];
export const HOTLIST_FILTERS_KEY = "ps.hotlist_filters";
export const ANALYST_IDS_KEY = "analystIds";
export const TASK_ASSIGNEE_IDS_KEY = "taskAssigneeIds";
export const PROPERTY_TYPES_KEY = "propertyTypes";
export const INTERVAL_KEY = "interval";
export const LOAN_PROGRAMS_KEY = "loanPrograms";
export const ACCOUNT_TYPES_KEY = "accountTypes";

export default class FilterParams {
  static get instance() {
    return new FilterParams(
      WindowService.location,
      WindowService.history,
      new LocalStorageService()
    );
  }

  constructor(locationService, historyService, localstorageService) {
    this.locationService = locationService;
    this.historySerivice = historyService;
    this.localstorageService = localstorageService;
  }

  saveParams(filterOptions) {
    const searchParams = {};
    if (filterOptions[PROPERTY_TYPES_KEY].length > 0) {
      searchParams[PROPERTY_TYPES_KEY] = filterOptions[PROPERTY_TYPES_KEY];
    } else {
      searchParams[PROPERTY_TYPES_KEY] = ["all"];
    }

    if (filterOptions[ANALYST_IDS_KEY].length > 0) {
      searchParams[ANALYST_IDS_KEY] = filterOptions[ANALYST_IDS_KEY];
    } else {
      searchParams[ANALYST_IDS_KEY] = ["all"];
    }

    if (filterOptions[TASK_ASSIGNEE_IDS_KEY].length > 0) {
      searchParams[TASK_ASSIGNEE_IDS_KEY] = filterOptions[TASK_ASSIGNEE_IDS_KEY];
    } else {
      searchParams[TASK_ASSIGNEE_IDS_KEY] = ["all"];
    }

    if (filterOptions[LOAN_PROGRAMS_KEY].length > 0) {
      searchParams[LOAN_PROGRAMS_KEY] = filterOptions[LOAN_PROGRAMS_KEY];
    } else {
      searchParams[LOAN_PROGRAMS_KEY] = ["all"];
    }

    if (filterOptions[ACCOUNT_TYPES_KEY].length > 0) {
      searchParams[ACCOUNT_TYPES_KEY] = filterOptions[ACCOUNT_TYPES_KEY];
    } else {
      searchParams[ACCOUNT_TYPES_KEY] = ["all"];
    }

    searchParams[INTERVAL_KEY] =
      filterOptions[INTERVAL_KEY] || PS.Models.Reports.Periods.reportableMonths()[0].shortFormat;

    const searchQuery = new URLSearchParams(searchParams).toString();
    let url =
      searchQuery.length === 0
        ? this.locationService.pathname
        : `${this.locationService.pathname}?${searchQuery}`;

    this.historySerivice.replaceState({}, WindowService.document.title, url);
    this.localstorageService.setItem(HOTLIST_FILTERS_KEY, filterOptions);
  }

  loadParams() {
    const searchParams = new URLSearchParams(this.locationService.search);
    let filterOptions = {};

    filterOptions[ANALYST_IDS_KEY] = searchParams.get(ANALYST_IDS_KEY)
      ? searchParams.get(ANALYST_IDS_KEY).split(",")
      : undefined;

    filterOptions[TASK_ASSIGNEE_IDS_KEY] = searchParams.get(TASK_ASSIGNEE_IDS_KEY)
      ? searchParams.get(TASK_ASSIGNEE_IDS_KEY).split(",")
      : undefined;

    filterOptions[PROPERTY_TYPES_KEY] = searchParams.get(PROPERTY_TYPES_KEY)
      ? searchParams.get(PROPERTY_TYPES_KEY).split(",")
      : undefined;

    filterOptions[LOAN_PROGRAMS_KEY] = searchParams.get(LOAN_PROGRAMS_KEY)
      ? searchParams.get(LOAN_PROGRAMS_KEY).split(",")
      : undefined;

    filterOptions[ACCOUNT_TYPES_KEY] = searchParams.get(ACCOUNT_TYPES_KEY)
      ? searchParams.get(ACCOUNT_TYPES_KEY).split(",")
      : undefined;

    filterOptions[INTERVAL_KEY] = searchParams.get(INTERVAL_KEY) || undefined;

    if (
      filterOptions[ANALYST_IDS_KEY] === undefined &&
      filterOptions[TASK_ASSIGNEE_IDS_KEY] === undefined &&
      filterOptions[PROPERTY_TYPES_KEY] === undefined &&
      filterOptions[INTERVAL_KEY] === undefined &&
      filterOptions[LOAN_PROGRAMS_KEY] === undefined &&
      filterOptions[ACCOUNT_TYPES_KEY] === undefined
    ) {
      filterOptions = this.localstorageService.getItem(HOTLIST_FILTERS_KEY) || {};
    }

    return {
      [ANALYST_IDS_KEY]: filterOptions[ANALYST_IDS_KEY] || DEFAULT_ANALYSTS,
      [TASK_ASSIGNEE_IDS_KEY]: filterOptions[TASK_ASSIGNEE_IDS_KEY] || DEFAULT_TASK_ASSIGNEES,
      [PROPERTY_TYPES_KEY]: filterOptions[PROPERTY_TYPES_KEY] || DEFAULT_PROPERTY_TYPES,
      [LOAN_PROGRAMS_KEY]: filterOptions[LOAN_PROGRAMS_KEY] || DEFAULT_LOAN_PROGRAMS,
      [ACCOUNT_TYPES_KEY]: filterOptions[ACCOUNT_TYPES_KEY] || DEFAULT_ACCOUNT_TYPES,
      [INTERVAL_KEY]:
        filterOptions[INTERVAL_KEY] || PS.Models.Reports.Periods.reportableMonths()[0].shortFormat,
    };
  }
}
