<template>
  <div class="loan-task-field-container">
    <label class="loan-task-field-label">
      {{ fieldLabel }}
    </label>

    <div class="loan-task-field-value">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "loan-task-field-container",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    fieldLabel() {
      return this.label ? `${this.label}:` : "";
    },
  },
};
</script>
