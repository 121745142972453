<template>
  <shared-loan-tab :default-filters="filters">
    <template v-slot:filters="props">
      <entities-filter
        :all-option-enabled="true"
        :current-user-id="currentUser.id"
        :initial-filter="filters.loanAssigneeId"
        :labels="{ my_entities: 'My Loans', all_entities: 'All Loans' }"
        :options="userOptions(assetManagers)"
        @filter="props.handleFilter('loanAssigneeId', $event)"
      />
    </template>
  </shared-loan-tab>
</template>
<script>
import SharedLoanTab from "./shared_tab";
import EntitiesFilter from "../portfolio/entities_filter";

export default {
  components: {
    EntitiesFilter,
    SharedLoanTab,
  },
  data() {
    const currentUser = PSData.currentUser;
    return {
      filters: {
        loanAssigneeId: currentUser.id,
      },
      currentUser,
    };
  },
  computed: {
    assetManagers: () => PSData.assetManagers || [],
  },
  methods: {
    userOptions(userList) {
      return userList
        .filter(user => user.id !== this.currentUser.id)
        .map(user => [user.name, user.id]);
    },
  },
};
</script>
