const DEFAULT_SERIES = {
  amount: null,
  fundingEntityId: null,
  startDate: null,
  endDate: null,
  status: null,
  psSpread: null,
  note: null,
  tranche: "a",
  mdpn: false,
  seriesType: null,
};

class LoanSeries {
  constructor(series) {
    series = series || DEFAULT_SERIES;

    this.raw = series;
    this.psId = series.psId;
    this.status = series.status;
    this.statusOrder = series.statusOrder;
    this.investorRate = series.investorRate;
    this.retailUrl = series.retailUrl;
    this.createdAt = series.createdAt;
    this.createdAtInt = series.createdAtInt;
    this.note = series.note;
    this.deletedAt = series.deletedAt;
    this.unpaidPrincipalBalance = series.unpaidPrincipalBalance;
    this.errors = {};

    this.reset();
  }

  reset() {
    this._amount = this.raw.amount;
    this._fundingEntityId = this.raw.fundingEntityId;
    this._startDate = this.raw.startDate;
    this._endDate = this.raw.endDate;
    this._status = this.raw.status;
    this._psSpread = this.raw.psSpread;
    this._note = this.raw.note;
    this._tranche = this.raw.tranche;
    this._mdpn = this.raw.mdpn;
    this._seriesType = this.raw.seriesType;
    this._unpaidPrincipalBalance = this.raw.unpaidPrincipalBalance;
    this._errors = {};
  }

  static statusSort(a, b) {
    var value = a.statusOrder - b.statusOrder;

    if (value === 0) {
      value = new Date(b.raw.endDate || 0) - new Date(a.raw.endDate || 0);
    }
    if (value === 0) {
      value = new Date(b.raw.startDate || 0) - new Date(a.raw.startDate || 0);
    }
    if (value === 0) {
      value = new Date(b.createdAtInt || 0) - new Date(a.createdAtInt || 0);
    }

    return value;
  }

  get amount() {
    return this._amount;
  }
  set amount(value) {
    this._amount = PS.Models.NumberFormatter.unmaskNumber(value);
  }

  get fundingEntityId() {
    return this._fundingEntityId;
  }
  set fundingEntityId(value) {
    this._fundingEntityId = value;
  }

  get startDate() {
    return this._startDate;
  }
  set startDate(value) {
    this._startDate = value;
  }

  get endDate() {
    return this._endDate;
  }
  set endDate(value) {
    this._endDate = value;
  }

  get psSpread() {
    return this._psSpread;
  }
  set psSpread(value) {
    this._psSpread = PS.Models.NumberFormatter.unmaskNumber(value);
  }

  get unpaidPrincipalBalance() {
    return this._unpaidPrincipalBalance;
  }

  set unpaidPrincipalBalance(value) {
    this._unpaidPrincipalBalance = value;
  }

  get note() {
    return this._note;
  }
  set note(value) {
    this._note = value;
  }

  get tranche() {
    return this._tranche;
  }
  set tranche(value) {
    this._tranche = value;
  }

  get mdpn() {
    return this._mdpn;
  }
  set mdpn(value) {
    this._mdpn = value;
  }

  get seriesType() {
    return this._seriesType;
  }
  set seriesType(value) {
    this._seriesType = value;
  }

  get errors() {
    return this._errors;
  }
  set errors(value) {
    this._errors = value;
  }

  get isDeleted() {
    return this.deletedAt;
  }
}

window.PS.Models.LoanSeries = LoanSeries;
