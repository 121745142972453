<template>
  <div class="form-group percentage required" :class="{ 'has-error': hasErrors }">
    <label v-if="tooltipLabel" class="control-label required">
      <span v-if="hasTooltipIcon">
        {{ label }}
        <i
          v-popover="tooltipLabelContent"
          class="fas fa-question-circle form-tooltip"
          tabindex="-1"
        />
      </span>
      <a
        v-else=""
        v-popover="tooltipLabelContent"
        class="with-field-tooltip"
        href="#"
        tabindex="-1"
      >
        {{ label }}
      </a>
    </label>
    <label v-if="label && !tooltipLabel" class="control-label required">{{ label }}</label>
    <input
      v-show="disabled && showPoints"
      v-model="pointsInputValue"
      type="hidden"
      :name="pointsInputName"
    />
    <input
      v-show="showPoints"
      :id="pointsInputId"
      v-model="pointsInputValue"
      v-mask:percentage=""
      class="form-control string required percentage-or-amount-input"
      type="text"
      :disabled="disabled"
      :name="pointsInputName"
    />
    <input
      v-show="disabled && showAmount"
      v-model="amountInputValue"
      type="hidden"
      :name="amountInputValue"
    />
    <input
      v-show="showAmount"
      :id="amountInputId"
      v-model="amountInputValue"
      v-mask:currency=""
      class="form-control string required percentage-or-amount-input"
      type="text"
      :disabled="disabled"
      :name="amountInputName"
    />
    <select
      v-model="currentType"
      :value="currentType"
      class="form-control select percentage-or-amount-select"
      :disabled="disabled || amountOrPointsOnlyField"
      @change="nullifyPointsOrAmounts"
    >
      <option value="points">%</option>
      <option value="amount">$</option>
    </select>
    <span v-if="showPoints">
      <span v-for="(error, index) in pointsInputErrors" :key="index" class="help-block">
        {{ error }}
      </span>
    </span>

    <span v-if="showAmount">
      <span v-for="(error, index) in amountInputErrors" :key="index" class="help-block">
        {{ error }}
      </span>
    </span>
  </div>
</template>

<script>
import camelCase from "lodash/camelCase";

const FIELD_TYPES = { any: "any", amount: "amount", points: "points" };
const FIELD_KINDS = Object.values(FIELD_TYPES);
const randomNumberMultiplier = 1000000;

export default Vue.component("points-or-amount-input", {
  props: {
    amountAttribute: String,
    amountValue: [String, Number],
    pointsAttribute: String,
    pointsValue: [String, Number],
    label: String,
    tooltipLabel: String,
    onFeesChange: Function,
    hasTooltipIcon: Boolean,
    disabled: Boolean,
    kind: {
      type: String,
      default: "any",
      validator: value => FIELD_KINDS.includes(value),
    },
    clearValues: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const errors = PSData.formErrors || {};

    return {
      amountInputValue: this.amountValue,
      pointsInputValue: this.pointsValue,
      currentType: this.getCurrentType(this.amountValue),
      amountInputErrors: errors[camelCase(this.amountAttribute)] || [],
      pointsInputErrors: errors[camelCase(this.pointsAttribute)] || [],
    };
  },
  computed: {
    amountOrPointsOnlyField() {
      return this.isSingleTypeField();
    },
    amountInputId() {
      return `resource_${this.amountAttribute}${(Math.random() * randomNumberMultiplier).toFixed(
        0
      )}`;
    },
    amountInputName() {
      return `resource[${this.amountAttribute}]`;
    },
    pointsInputId() {
      return `resource_${this.pointsAttribute}${(Math.random() * randomNumberMultiplier).toFixed(
        0
      )}`;
    },
    pointsInputName() {
      return `resource[${this.pointsAttribute}]`;
    },
    tooltipLabelContent() {
      if (this.tooltipLabel) {
        return { content: `#${this.tooltipLabel}` };
      }
    },
    hasErrors() {
      return this.pointsInputErrors.length || this.amountInputErrors.length;
    },
    showAmount() {
      return this.currentType === FIELD_TYPES.amount;
    },
    showPoints() {
      return this.currentType === FIELD_TYPES.points;
    },
  },
  methods: {
    nullifyPointsOrAmounts: function() {
      if (this.showAmount) {
        this.pointsInputValue = "";
      } else {
        this.amountInputValue = "";
      }
    },
    getCurrentType(amountInputValue) {
      if (this.isSingleTypeField()) {
        return this.kind;
      } else if (amountInputValue != null) {
        return FIELD_TYPES.amount;
      } else {
        return FIELD_TYPES.points;
      }
    },
    isSingleTypeField() {
      return this.kind !== FIELD_TYPES.any;
    },
  },
  watch: {
    amountValue(newValue) {
      this.amountInputValue = newValue;
    },
    pointsValue(newValue) {
      this.pointsInputValue = newValue;
    },
    amountInputValue() {
      this.onFeesChange(camelCase(this.amountAttribute), this.amountInputValue);
      this.onFeesChange(camelCase(this.pointsAttribute), null);
    },
    pointsInputValue() {
      this.onFeesChange(camelCase(this.pointsAttribute), this.pointsInputValue);
      this.onFeesChange(camelCase(this.amountAttribute), null);
    },
    clearValues: {
      handler(newValue) {
        if (newValue) {
          this.pointsInputValue = "";
          this.amountInputValue = "";
        }
      },
    },
  },
});
</script>
