import LoanFormMixin from "../../mixins/manage/loans/form";

$(() => {
  if (!document.querySelector(".v-manage-new-loan-controller")) {
    return;
  }

  new Vue({
    el: document.querySelector(".v-manage-new-loan-controller"),
    mixins: [LoanFormMixin],
    data: function() {
      return {
        loan: PSData.loan || {},
        investmentCommitteeRequired: null,
        servicingReleased: null,
        riskAnalysisDateDisabled: null,
        distributeToLenderDisabled: null,
        distributeToInvestorDisabled: null,
      };
    },
    mounted() {
      this.loadPolicy();
    },
    watch: {
      "loan.programType": {
        handler(newValue, oldValue) {
          if (newValue !== oldValue) {
            this.loan.loanProgramId = "";
          }
        },
      },
    },
    computed: {
      isFundingIntoOrigination() {
        return this.loan.lenderNoteStatus === "funding_into_origination";
      },
      showCema() {
        return this.loan.state === "NY";
      },
      isAcquisition() {
        return this.loan.loanPurpose === "acquisition";
      },
      isResiBridgeProgramType() {
        return this.loan.programType === "residential_bridge";
      },
      isRentalProgramType() {
        return this.loan.programType === "residential_rental_long_term";
      },
      isCommercialMultifamilyProgramType() {
        return this.loan.programType === "commercial_multifamily";
      },
      isEmptyProgramType() {
        return (
          !this.isResiBridgeProgramType &&
          !this.isRentalProgramType &&
          !this.isCommercialMultifamilyProgramType
        );
      },
    },
    methods: {
      loadPolicy() {
        const formPolicy = new PS.Policies.EditLoanFormPolicy(PSData.loanPolicy);
        this.riskAnalysisDateDisabled = !formPolicy.canUpdateRiskAnalysisDate();
        this.distributeToLenderDisabled = !formPolicy.canUpdateDistributeToLender();
        this.distributeToInvestorDisabled = !formPolicy.canUpdateDistributeToInvestor();
      },
    },
  });
});
