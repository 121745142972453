<template>
  <div class="institution-sales-tracking" >
    <div v-if="isLoading" class="ps-loader ps-loader-small"></div>
    <div v-if="isLoaded">
      <breadcrumb-navigation :items="breadcrumbItems"></breadcrumb-navigation>
      <div class="institution-sales-tracking-page-header">
        <h2>{{pageTitle}}</h2>
        <div class="institution-sales-tracking-page-header-actions">
          <div class="institution-sales-tracking-toggle">
            <label class="checkbox-toggle">
              Batch is Open
              <input type="checkbox" :checked="batch.open" @input="toggleOpen" :disabled="!canUpdateBatch">
              <div class="toggle-control"></div>
            </label>
          </div>
          <dropdown-button class="institution-sales-tracking-actions-button">
            <template slot="dropdown-name">
              Actions
            </template>
            <template slot="dropdown-content">
              <button class="dropdown-item" @click.prevent="syncLoanFiles" type="button" :disabled="isActionRequesting">
                Sync Loan Files to Box
              </button>
            </template>
          </dropdown-button>
        </div>
      </div>
      <div class="institution-sales-tracking-section-header">
        <h3>Batch Summary</h3>
      </div>
      <batch-summary-table
        :batch="batch"
        :can-update-batch="canUpdateBatch"
        @set-date="showSetDateModal('setDate', $event)"
      ></batch-summary-table>
      <div v-if="isOfferingsLoading" class="ps-loader ps-loader-small"></div>
      <div v-if="isOfferingsLoaded">
        <div class="institution-sales-tracking-section-header">
          <h3>Reallocate/Hold</h3>
        </div>
        <reallocate-table
          :batch="batch"
          :loan-offerings="notAcceptedOfferings"
          :can-update-batch="canUpdateBatch"
          @exclude="showActionModal('exclude', $event)"
          @move-to-offer="showActionModal('moveToOffer', $event)"
          @reallocate="showActionModal('reallocate', $event)"
          @edit-notes="showActionModal('editNotes', $event)"
          @critical-hold="showActionModal('criticalHold', $event)"
        ></reallocate-table>
        <div class="institution-sales-tracking-section-header">
          <h3>Offer</h3>
        </div>
        <offer-table
          :batch="batch"
          :loan-offerings="acceptedOfferings"
          :can-update-batch="canUpdateBatch"
          @exclude="showActionModal('exclude', $event)"
          @push-to-next="showActionModal('pushToNext', $event)"
          @reallocate="showActionModal('reallocate', $event)"
          @edit-notes="showActionModal('editNotes', $event)"
          @critical-hold="showActionModal('criticalHold', $event)"
        ></offer-table>
      </div>
      <exclude-modal
        v-if="modals.exclude"
        :show="modals.exclude"
        :offering="selectedOffering"
        @save="updateLoanOffering"
        @close="closeModals"
      ></exclude-modal>
      <move-to-offer-modal
        v-if="modals.moveToOffer"
        :show="modals.moveToOffer"
        :offering="selectedOffering"
        @save="updateLoanOffering"
        @close="closeModals"
      ></move-to-offer-modal>
      <reallocate-modal
        v-if="modals.reallocate"
        :show="modals.reallocate"
        :offering="selectedOffering"
        :batch="batch"
        :rejection-reasons="rejectionReasons"
        @save="updateLoanOffering"
        @close="closeModals"
      ></reallocate-modal>
      <edit-notes-modal
        v-if="modals.editNotes"
        :show="modals.editNotes"
        :offering="selectedOffering"
        @save="updateLoanOffering"
        @close="closeModals"
      ></edit-notes-modal>
      <critical-hold-modal
        v-if="modals.criticalHold"
        :show="modals.criticalHold"
        :offering="selectedOffering"
        :hold-reasons="holdReasons"
        @save="updateLoanOffering"
        @close="closeModals"
      ></critical-hold-modal>
      <push-to-next-modal
        v-if="modals.pushToNext"
        :show="modals.pushToNext"
        :offering="selectedOffering"
        :batch="batch"
        @save="updateLoanOffering"
        @close="closeModals"
      ></push-to-next-modal>
      <set-date-modal
        v-if="modals.setDate"
        :modalTitle="pageTitle"
        :field-name="selectedFieldName"
        :show="modals.setDate"
        @save="setBatchDate"
        @close="closeModals"
      ></set-date-modal>
    </div>
  </div>
</template>
<script>
import BatchSummaryTable from "./batch_summary_table";
import ReallocateTable from "./reallocate_table";
import OfferTable from "./offer_table";
import MoveToOfferModal from "./modals/move_to_offer";
import ReallocateModal from "./modals/reallocate";
import EditNotesModal from "./modals/edit_notes";
import CriticalHoldModal from "./modals/critical_hold";
import PushToNextModal from "./modals/push_to_next";
import SetDateModal from "./modals/set_date_modal";
import ExcludeModal from "./modals/exclude_modal";
import LoanOffering from "models/institution/loan_offering";
import BreadcrumbNavigation from "components/shared/breadcrumb_navigation";
import DropdownButton from "components/shared/dropdown_button.vue";
import AjaxService from "services/ajax_service";
import debounce from "utils/debounce";

const batchRepresenter = batch => ({
  open: batch.open,
  target_offer_date: batch.targetOfferDate,
  offer_date: batch.offerDate,
  wire_date: batch.wireDate,
  sales_docs_completed_date: batch.salesDocsCompletedDate,
});

export default {
  components: {
    BreadcrumbNavigation,
    BatchSummaryTable,
    ReallocateTable,
    OfferTable,
    MoveToOfferModal,
    ReallocateModal,
    EditNotesModal,
    CriticalHoldModal,
    PushToNextModal,
    SetDateModal,
    ExcludeModal,
    DropdownButton,
  },

  data() {
    return {
      batch: {},
      loanOfferings: [],
      rejectionReasons: [],
      holdReasons: PSData.loan.holdReasons,
      isLoading: false,
      isLoaded: false,
      isUpdating: false,
      isOfferingsLoading: false,
      isOfferingsLoaded: false,
      isActionRequesting: false,
      modals: {
        moveToOffer: false,
        reallocate: false,
        editNotes: false,
        pushToNext: false,
        criticalHold: false,
        setDate: false,
        exclude: false,
      },
      selectedOffering: {},
      selectedFieldName: null,
      routes: PSData.routes,
      canUpdateBatch: PSData.canUpdateBatch,
    };
  },

  mounted() {
    this.getBatch();
    this.getLoanOfferings();
    this.getRejectionReasons();
  },

  methods: {
    async getBatch() {
      if (this.isLoading) {
        return;
      }
      const url = this.routes.manageInstitutionLoanOfferingBatch;
      this.isLoading = true;

      try {
        const res = await AjaxService.get(url);
        this.batch = res.batch;
        this.isLoaded = true;
      } finally {
        this.isLoading = false;
      }
    },

    async updateBatch(batch) {
      if (this.isUpdating) {
        return;
      }
      const url = this.routes.manageInstitutionLoanOfferingBatch;
      this.isUpdating = true;
      try {
        const res = await AjaxService.patch(url, { batch: batchRepresenter(batch) });
        this.batch = res.batch;
      } finally {
        this.isUpdating = false;
      }
    },

    toggleBatchDebounced: debounce(function() {
      this.updateBatch({ open: this.batch.open });
    }, 500),

    toggleOpen() {
      this.batch.open = !this.batch.open;
      this.toggleBatchDebounced();
    },

    async getLoanOfferings() {
      if (this.isOfferingsLoading) {
        return;
      }
      this.isOfferingsLoading = true;
      const url = this.routes.manageInstitutionLoanOfferingBatchOfferings;
      try {
        const res = await AjaxService.get(url);
        this.loanOfferings = res.loanOfferings.map(offering => new LoanOffering(offering));
        this.isOfferingsLoaded = true;
      } finally {
        this.isOfferingsLoading = false;
      }
    },

    async getRejectionReasons() {
      const url = this.routes.manageInstitutionRejectionReasons;
      const res = await AjaxService.get(url);
      this.rejectionReasons = res.rejectionReasons;
    },

    async updateLoanOffering(offering, offeringForm) {
      offering.update(offeringForm);
      const url = offering.routes.loanOffering;
      const res = await AjaxService.patch(url, offering.toHash());
      this.setLoanOfferingById(res.loanOffering);
      this.closeModals();
    },

    setLoanOfferingById(loanOffering) {
      const offeringIndex = this.loanOfferings.findIndex(
        offering => offering.id === loanOffering.id
      );
      this.$set(this.loanOfferings, offeringIndex, new LoanOffering(loanOffering));
    },

    closeModals() {
      this.selectedOffering = {};
      this.selectedFieldName = null;
      Object.keys(this.modals).forEach(modalName => {
        this.modals[modalName] = false;
      });
    },

    showActionModal(modalName, offeringId) {
      const offering = this.loanOfferings.find(loanOffering => loanOffering.id === offeringId);
      this.selectedOffering = new LoanOffering(offering.attributes);
      this.modals[modalName] = true;
    },

    showSetDateModal(modalName, fieldName) {
      this.selectedFieldName = fieldName;
      this.modals[modalName] = true;
    },

    async setBatchDate(params) {
      await this.updateBatch(params);
      this.closeModals();
    },

    async syncLoanFiles(batch) {
      if (this.isActionRequesting) {
        return;
      }

      const messages = {
        onSuccess: "Loan files sync has been initiated",
        onError: ({ response, data }) => {
          if (response.status === 403) {
            return { message: "Unauthorized", status: "error" };
          } else {
            return { message: data.error, status: "error" };
          }
        },
      };

      this.isActionRequesting = true;
      try {
        await AjaxService.withNotifications(messages).postJSON(
          this.routes.manageInstitutionLoanOfferingBatchFileSyncs
        );
      } catch {
      } finally {
        this.isActionRequesting = false;
      }
    },
  },
  computed: {
    pageTitle() {
      return `${this.batch.buyerName} ${this.batchTitle}`;
    },

    batchTitle() {
      return `Batch ${this.batch.displayName || ""} ${this.batch.displayNumber}`;
    },

    breadcrumbItems() {
      return [
        {
          name: "Institutional Sales Tracking",
          link: this.routes.manageInstitutionSalesTrackings,
        },
        {
          name: `${this.batch.buyerName} Portfolio`,
        },
        {
          name: this.batchTitle,
        },
      ];
    },

    notAcceptedOfferings() {
      return this.loanOfferings.filter(offering => offering.state !== "accepted");
    },

    acceptedOfferings() {
      return this.loanOfferings.filter(offering => offering.state === "accepted");
    },
  },
};
</script>
