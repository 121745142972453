<template>
  <div>
    <base-switch
      v-model="taskMapping.useGlobalDescription"
      :native-value="taskMapping.useGlobalDescription"
      :disabled="taskMapping.customDescriptionDisabled"
    >
      <div class="is-flex is-justify-content-center">
        Use Global Task Description
        <base-tooltip
          label="Updating the global description will not override a custom description if this is disabled."
          position="is-top"
          multilined
          class="pl-1 mt-1"
        >
          <base-icon
            pack="fas"
            size="is-small"
            icon="question-circle"
            type="hint"
          />
        </base-tooltip>
      </div>
    </base-switch>
    <base-field v-if="taskMapping.useGlobalDescription" label="Global Description" class="py-1">
      <div
        class="content"
        v-html="definition.defaultDescription"
      />
    </base-field>
    <description-rules
      v-else
      ref="rules"
      :task-mapping="taskMapping"
    >
      <template #content="{rule}">
        <editor-field
          v-model="rule.customDescription"
          placeholder="Task Description"
          class="description-editor p-0"
        />
      </template>
    </description-rules>
    <div v-if="showAddCondtionsButton" class="is-flex is-align-items-center">
      <base-button type="link" class="p-0 pr-1 has-text-weight-semibold" @click="addDescriptionConditions">
        + Add Description Conditions
      </base-button>
      <base-tooltip
        label="Add Description Conditions."
        position="is-top"
        multilined
      >
        <b-icon
          pack="fas"
          size="is-small"
          icon="question-circle"
          type="is-ps-hint-icon"
        />
      </base-tooltip>
    </div>
  </div>
</template>
<script>
import LoanTaskDefiniton from "models/loan_task_definition";
import EditorField from "components/shared/editor_field";
import DescriptionRules from "./description_rules";

export default {
  components: {
    DescriptionRules,
    EditorField,
  },
  props: {
    taskMapping: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showConditions: false,
    };
  },
  computed: {
    definition() {
      return new LoanTaskDefiniton(this.taskMapping && this.taskMapping.taskDefinition);
    },
    showAddCondtionsButton() {
      return !this.taskMapping.useGlobalDescription;
    },
  },
  methods: {
    addDescriptionConditions() {
      this.showConditions = true;
      this.taskMapping.taskDescriptionRules.push({ conditions: { "": { "": "" } } });
      this.$refs.rules.openedRule = this.taskMapping.taskDescriptionRules.length - 1;
    },
  },
};
</script>
<style scoped>
.description-editor >>> .base-editor-wrapper {
  border: 0 none;
}
</style>
