import axios from "axios";
import { deepMapKeys, snakeToCamelCase, camelToSnakeCase } from "./casing.js";

const SAFE_HTTP_METHODS = ["GET", "HEAD", "OPTIONS", "TRACE"];

export default function createAxios({ baseURL, csrfToken }) {
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use(request => {
    request.headers["Accept"] = "application/json";
    request.headers["Content-Type"] = "application/json";
    request.headers["X-Requested-With"] = "XMLHttpRequest";

    if (!SAFE_HTTP_METHODS.includes(request.method.toUpperCase())) {
      request.headers["X-CSRF-Token"] = csrfToken;
    }

    return request;
  });

  instance.interceptors.request.use(
    request => {
      request.data = deepMapKeys(request.data, camelToSnakeCase);
      return request;
    },
    rejection => {
      rejection.response.data = deepMapKeys(rejection.response.data, camelToSnakeCase);
      return Promise.reject(rejection);
    }
  );

  instance.interceptors.response.use(
    response => {
      response.data = deepMapKeys(response.data, snakeToCamelCase);
      return response;
    },
    rejection => {
      rejection.response.data = deepMapKeys(rejection.response.data, snakeToCamelCase);
      return Promise.reject(rejection);
    }
  );

  return instance;
}
