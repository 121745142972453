<template>
  <div class="loan-property-editable-list">
    <header>{{ header }}</header>
    <table class="view-table">
      <thead>
        <tr>
          <th v-for="column in columns">
            {{ column }}
          </th>
          <th class="actions" />
        </tr>
      </thead>
      <tbody>
        <template v-for="item in sortedItems">
          <record
            :key="item.id"
            :record="item"
            :record-route="recordRoute"
            @delete="onRecordDelete"
          />
        </template>
        <record
          v-if="addingRecord"
          :record="newRecord"
          :record-route="recordRoute"
          @create="onRecordCreate"
          @cancel="onRecordCreateCancel"
        />
      </tbody>
    </table>
    <a href="#" title="Add Record" class="view-table-add-item" @click.prevent="addRecord">
      + Add Record
    </a>
  </div>
</template>

<script>
import Record from "./record";
import Ajax from "services/ajax_service";

export default {
  name: "loan-property-editable-list",
  components: {
    Record,
  },
  props: {
    header: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      required: true,
    },
    recordRoute: {
      type: String,
      required: true,
    },
    createRecord: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      addingRecord: false,
      items: [],
      newRecord: {},
      options: {},
    };
  },
  computed: {
    sortedItems() {
      return this.items.slice().sort(this.sortByTypeAndDate);
    },
  },
  mounted() {
    Ajax.getJSON(this.recordRoute).then(data => {
      this.options = data.options;
      this.items = (data.items || []).map(item => this.createRecord(item, this.options));
      this.newRecord = this.createRecord({}, this.options);
    });
  },
  methods: {
    addRecord() {
      this.addingRecord = true;
    },
    onRecordCreate(data) {
      this.addingRecord = false;
      this.newRecord.reset();
      this.items.push(this.createRecord(data, this.options));
    },
    onRecordCreateCancel() {
      this.addingRecord = false;
    },
    onRecordDelete(record) {
      const indexToDelete = this.items.findIndex(item => item.id === record.id);
      this.items.splice(indexToDelete, 1);
    },
    sortByTypeAndDate(a, b) {
      if (a.typeId === b.typeId) {
        return new Date(a.date) - new Date(b.date);
      }

      return b.typeId < a.typeId ? 1 : -1;
    },
  },
};
</script>
