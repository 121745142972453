<template>
  <div class="communication-history-container">
    <h1 class="history-header">
      Loan Notes
    </h1>
    <div class="history-list">
      <history-list :records="records" :total="total" />
    </div>
  </div>
</template>

<script>
import HistoryList from "components/manage/asset_management/workflows_section/history_section/history_list";
export default {
  components: {
    HistoryList,
  },
  data() {
    const communicationHistory = PSData.communicationHistory || [];
    return {
      records: communicationHistory,
      total: communicationHistory.length,
    };
  },
};
</script>

<style scoped>
.history-header {
  font-size: var(--font-size-lg);
  font-weight: 600;
  line-height: 1.5em;
  margin: var(--space-unit-lg) 0 var(--space-unit-md);
}

.history-list {
  background-color: var(--color-grey-06);
  border-radius: var(--border-radius);
  padding: var(--space-unit-md);
}
.communication-history-container {
  width: 75%;
  margin: 0 auto;
}
</style>
