import moment from "moment";

// Takes monthly stats array and sums values by quarter.
//
// In:
// [
//   { amount:  0, count: 0, month: "2018-01-01 },
//   { amount: 10, count: 1, month: "2018-02-01 },
//   { amount: 20, count: 2, month: "2018-03-01 },
// ]
//
// Out:
// [
//   { amount: 30, count: 3, month: "2018-01-01 },
// ]
export default function quarterize(monthlyStats, monthProperty = "month") {
  if (!monthlyStats.length) return [];

  const summable = Object.keys(monthlyStats[0]).filter(k => k !== monthProperty);

  return Object.values(
    monthlyStats.reduce((result, stats) => {
      let quarter = moment(stats.month)
        .startOf("quarter")
        .format("YYYY-MM-DD");

      if (!result[quarter]) {
        result[quarter] = { month: quarter };

        for (let key of summable) {
          result[quarter][key] = 0;
        }
      }

      for (let key of summable) {
        result[quarter][key] += parseFloat(stats[key]);
      }

      return result;
    }, {})
  );
}
