let currentDocumentId = 0;

export default {
  get currentDocumentId() {
    return currentDocumentId;
  },

  generate() {
    return (documentId => () => documentId === currentDocumentId)(currentDocumentId);
  },

  increment() {
    return ++currentDocumentId;
  },
};
