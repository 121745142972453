<template>
  <div>
    <lender-users-table
      class="lender-active-users-table"
      name="Users"
      :users="activeUsers"
      :can-edit-users="canEditUsers"
      @update="onUserUpdate"
    />
    <lender-users-table
      class="lender-archived-users-table"
      name="Archived Users"
      :users="archivedUsers"
      :can-add="false"
      :can-edit-users="canEditUsers"
      @update="onUserUpdate"
    />
  </div>
</template>
<script>
import LenderUsersTable from "components/lender_users/lender_users_table";
import User from "models/lenders/user";

export default {
  name: "lender-users",
  components: { LenderUsersTable },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    canEditUsers: {
      type: Boolean,
      required: true,
    },
  },
  data: function() {
    return {
      currentUsers: this.users.map(userData => new User(userData)),
    };
  },
  computed: {
    activeUsers() {
      return this.currentUsers.filter(user => !user.isArchived);
    },
    archivedUsers() {
      return this.currentUsers.filter(user => user.isArchived);
    },
  },
  methods: {
    onUserUpdate(userData) {
      const updatedUser = new User(userData);
      const updatedUserIndex = this.currentUsers.findIndex(user => user.id === updatedUser.id);

      if (updatedUserIndex === -1) {
        this.currentUsers.push(updatedUser);
      } else {
        Vue.set(this.currentUsers, updatedUserIndex, updatedUser);
      }
    },
  },
};
</script>
