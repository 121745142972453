import { defineStore } from "pinia";
import { v4 as generateSecureUUID } from "@lukeed/uuid/secure";

const useScenarioBuilderStore = defineStore("scenarioBuilder", {
  state: () => ({
    evaluationSessionId: null,
    evaluationGroupId: null,
  }),
  actions: {
    startEvaluationSession() {
      this.evaluationSessionId = generateSecureUUID();
    },
    endEvaluationSession() {
      this.evaluationSessionId = null;
    },
    startEvaluationGroup() {
      this.evaluationGroupId = generateSecureUUID();
    },
    endEvaluationGroup() {
      this.evaluationGroupId = null;
    },
  },
});

export default useScenarioBuilderStore;
