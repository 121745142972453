<template>
  <modal-view :show="show" :on-close="onClose" class="base-modal institution-sales-tracking-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          Push To Next
        </h4>
      </div>
      <div class="modal-body">
        <offering-details :offering="offering"></offering-details>
        <form>
          <div class="form-group">
            <label>Notes</label>
            <textarea class="form-control notes-input" v-model="offeringForm.notes"/>
          </div>
          <div v-if="showNote" class="institution-sales-tracking-modal-usernote">
            Loan will be immediately pushed to current open batch
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div>
          <button class="btn btn-outline-grey" @click="clearNotes">Clear notes</button>
        </div>
        <div>
          <button class="btn btn-cancel-cta btn-link" @click="onClose">
            Cancel
          </button>
          <button class="btn btn-action-cta" @click="onSave">
            Save
          </button>
        </div>
      </div>
    </div>
  </modal-view>
</template>
<script>
import moment from "moment";
import ModalView from "components/modal_view";
import OfferingDetails from "./offering_details";

export default {
  name: "push-to-next-modal",
  components: {
    ModalView,
    OfferingDetails,
  },
  props: {
    show: Boolean,
    offering: Object,
    batch: Object,
  },
  data() {
    return {
      offeringForm: {
        nextAction: "push_to_next",
        state: "hold",
        notes: this.offeringNotes(),
      },
    };
  },
  methods: {
    offeringNotes() {
      if (!this.offering.notes) {
        return `[${moment().format("MM/DD/YYYY")}] - ${this.offering.reviewText}`;
      }
      return this.offering.notes;
    },

    clearNotes() {
      this.offeringForm.notes = "";
    },

    onClose() {
      this.$emit("close");
    },

    onSave() {
      this.$emit("save", this.offering, this.offeringForm);
    },
  },
  computed: {
    showNote() {
      return this.batch.offerDate;
    },
  },
};
</script>
