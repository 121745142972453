<template>
  <div class="loan-request-details-modification">
    <p v-if="request.cc.length > 0">CC:
      <span>{{request.cc.join(", ")}}</span>
    </p>

    <p v-if="request.requestDetails.circumstances">
      Borrower Circumstances:
      <span>{{request.requestDetails.circumstances}}</span>
    </p>

    <p v-if="request.requestDetails.terms">
      Desired Modification Terms:
      <span>{{request.requestDetails.terms}}</span>
    </p>
  </div>
</template>
<script>
export default {
  name: "loan-request-details-modification",
  props: {
    request: {
      type: Object,
      required: true,
    },
    loan: {
      type: Object,
      required: true,
    },
  },
};
</script>
