<template>
  <table class="table table-hover review-table">
    <thead>
      <tr>
        <th scope="col" />
        <th scope="col" class="text-right">
          Transaction ID
        </th>
        <th scope="col" class="text-right">
          Loan ID
        </th>
        <th scope="col" class="text-right">
          Legal Owner
        </th>
        <th scope="col">
          Review Items
        </th>
        <th scope="col">
          Hold Reason
        </th>
        <th scope="col" class="text-right">
          Check Date
        </th>
        <th scope="col" class="text-right">
          Payment Due
        </th>
        <th scope="col" class="text-right">
          Amount
        </th>
        <th scope="col" class="text-right">
          Principal
        </th>
        <th scope="col" class="text-right">
          Interest
        </th>
        <th scope="col" class="text-right">
          Accrued Interest
        </th>
        <th scope="col" class="text-right">
          Fees
        </th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="item in items" :key="item.id" @click.prevent.stop="$emit('displayItemDetails', item)">
        <td class="min" @click.prevent.stop="$emit('toggleSelect', item)">
          <check-box :value="selected(item)"/>
        </td>
        <td>
          {{ item.id }}
          <span v-if="isReversed(item)" class="badge badge-danger">REVERSED</span>
        </td>
        <td>
          <a :href="`/manage/loans/${item.loan.id}`" target="_blank">
            {{ item.loan.id }}
          </a>
        </td>
        <td>
          {{ item.primaryOwners[0] ? item.primaryOwners[0].label : 'No Active Legal Owner' }}
        </td>
        <td>
          <status :flags="item.reviewFlags" />
        </td>
        <td>
          <icon :icon="'exclamation-triangle'" class="text-warning" />
          {{ item.holdReason }}
        </td>
        <td class="text-right">
          {{ formatDate(item.cashReceipt.receivedDate) }}
        </td>
        <td class="text-right">
          {{ formatDate(item.paymentDueDate) }}
        </td>
        <td class="text-right" :class="accountingClass(item.amount)">
          {{ item.totalAmount | accounting }}
        </td>
        <td class="text-right" :class="accountingClass(item.principal)">
          {{ detailAmount(item, "principal") | accounting }}
        </td>
        <td class="text-right" :class="accountingClass(item.interest)">
          {{ detailAmount(item, "interest") | accounting }}
        </td>
        <td class="text-right" :class="accountingClass(item.accruedInterest)">
          {{ item.accrualInterest | accounting }}
        </td>
        <td class="text-right" :class="accountingClass(item.fee)">
          {{ detailAmount(item, "fee") | accounting }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Icon from "components/shared/icon";
import CheckBox from "components/shared/check_box";
import Status from "../shared/status";
import moment from "moment";
import filter from "lodash/filter";
import sumBy from "lodash/sumBy";
import { isReversed } from "../shared/is_reversed";

export default {
  components: {
    Icon,
    CheckBox,
    Status,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedIds: {
      type: Set,
      required: true,
    },
  },
  methods: {
    isReversed,
    selected({ id }) {
      return this.selectedIds.has(id);
    },
    accountingClass(num) {
      return num < 0 ? "text-danger" : "";
    },
    formatDate(date) {
      return date && moment(date).format("MM/DD/YYYY");
    },
    detailAmount(item, disbursementType) {
      const filtered = filter(item.details, { disbursementType });
      return sumBy(filtered, detail => detail.revisedAmount || detail.originalAmount);
    },
  },
};
</script>
