$(() => {
  const Reports = PS.Models.Reports;
  const Events = PS.Models.Reports.Events;
  const Commands = PS.Models.Reports.Commands;
  const Location = PS.Services.LocationService;

  new Vue({
    el: document.querySelector(".v-msa-report-controller"),
    data() {
      return {
        dataSource: new PS.Models.Autocomplete.Datasources.MsaList(),
        autocompleteBus: PS.Services.MessageBusService.autocompleteBus,
        msaReportRulebook: undefined,
      };
    },
    mounted: function() {
      this.msaReportRulebook = new Reports.MsaReportRule(this);
      this.autocompleteBus.attachRulebook(this.msaReportRulebook);

      const search = this.searchParams();

      if (search.geoId) {
        const payload = {
          geoId: search.geoId,
          geoType: search.geoType,
          source: Location.href,
        };

        const event = new Events.MsaReport.MsaReportRequested(payload);
        new Commands.MsaReport.RequestReportLoading(event).execute();
      }
    },
    beforeDestroy: function() {
      this.autocompleteBus.detachRulebook(this.msaReportRulebook);
    },
    methods: {
      zipParam() {
        const searchParams = new URLSearchParams(PS.Services.LocationService.search);
        return searchParams.get("zip");
      },
      msaParam() {
        const searchParams = new URLSearchParams(PS.Services.LocationService.search);
        return searchParams.get("msa");
      },
      searchParams() {
        const zip = this.zipParam();
        const msa = this.msaParam();
        const result = {};

        if (zip !== undefined && zip !== null) {
          result.geoId = zip;
          result.geoType = "zip";
        } else if (msa !== undefined && msa !== null) {
          result.geoId = msa;
          result.geoType = "msa";
        }

        return result;
      },
    },
  });
});
