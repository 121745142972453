class Element {
  constructor(data, formatter = undefined) {
    this.id = data.id;
    this.documentType = data.documentType;
    this.group = data.group;
    this.name = data.name;
    this.label = data.label || this.name;
    this.fieldName = data.fieldName;
    this.value = this.coerceValue(data);
    this.matched = data.matched;
    this.note = data.note;
    this.validated = data.validated;

    this.formatter = formatter || new PS.Models.Diligence.ValueFormatter(data.as, data.collection);
    this.diff = (data.diff || []).map(item => {
      return new Element(item, this.formatter);
    });

    if (data.master) {
      this.master = new MasterElement(data.master, this.formatter);
    }
  }

  isFieldset() {
    return false;
  }

  formattedValue() {
    return this.formatter.format(this.value);
  }

  showDiff() {
    return !this.matched && this.diff.length > 0;
  }

  showMaster() {
    return !this.matched && !!this.master;
  }

  coerceValue(data) {
    const { value, as } = data;
    const collection = data.collection || {};

    switch (as) {
      case "select":
        return collection[value] ? value : undefined;
    }

    return value;
  }
}

class FieldsetElement extends Element {
  constructor(data, formatter = undefined) {
    super(data);

    this.formatter = undefined;
    this.elements = (data.fields || []).map(item => {
      return new Element(item);
    });
  }

  isFieldset() {
    return true;
  }
}

class MasterElement extends Element {
  constructor(data, formatter = undefined) {
    super(data, formatter);

    this.label = "Master Loan Detail";
    this.validated = true;
  }
}

PS.Models.Diligence = PS.Models.Diligence || {};
PS.Models.Diligence.ReportElement = Element;
PS.Models.Diligence.FieldsetElement = FieldsetElement;
PS.Models.Diligence.MasterElement = MasterElement;
