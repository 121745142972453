export default {
  explainEvaluationResults(evaluationId) {
    if (evaluationId === "off") {
      console.info("LPC is not configured to show details.");
      return;
    }

    if (!evaluationId) {
      console.error("Evaluation id is missing.");
      return;
    }

    const lpcPageUrl = `${PSData.routes.lpcUrl}/#/evaluations/${evaluationId}`;
    window.open(lpcPageUrl, "_blank");
  },
};
