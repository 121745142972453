<template>
  <div>
    <base-table class="signature-requests-table" :data="signatureRequests">
      <base-column field="firstName" label="Borrower First Name" v-slot="props">
        {{ props.row.firstName }}
      </base-column>
      <base-column field="lastName" label="Borrower Last Name" v-slot="props">
        {{ props.row.lastName }}
      </base-column>
      <base-column field="recipient" label="Recipient" v-slot="props">
        {{ props.row.recipient }}
      </base-column>
      <base-column field="passcode" label="Pass Code" v-slot="props">
        {{ props.row.passcode }}
        <copy-to-clipboard
          :value="props.row.passcode"
          title="Copy Pass Code"
          copy-icon-class="far fa-clone"
          class="copy-to-clipboard"
        />
      </base-column>
      <base-column field="status" label="Status" v-slot="props">
        <div v-for="documentStatus in props.row.document.statuses" :key="documentStatus.id">
          <span>
            {{ documentStatus.statusText }}
          </span>
          <template v-if="isDownloadable(props.row.document, documentStatus)">
            <span>&bull;</span>
            <span>
              <a :href="props.row.document.filestackUrl" target="_blank">
                Download
              </a>
            </span>
          </template>
        </div>
      </base-column>

      <base-column field="date" label="Date" v-slot="props">
        <div v-for="documentStatus in props.row.document.statuses" :key="documentStatus.id">
          {{ documentStatus.date }}
        </div>
      </base-column>
    </base-table>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import CopyToClipboard from "components/copy_to_clipboard";

export default {
  name: "signature-requests-table",
  components: {
    CopyToClipboard,
  },
  data: function() {
    return {
      routes: PSData.routes || {},
      signatureRequests: [],
    };
  },
  created: function() {
    AjaxService.getJSON(this.routes.signatureRequests).then(data => {
      this.signatureRequests = data;
    });
  },
  methods: {
    isDownloadable(document, documentStatus) {
      return document.filestackUrl && documentStatus.downloadable;
    },
  },
};
</script>

<style scoped>
.signature-requests-table.base-table >>> .table {
  border: none;
}

.signature-requests-table >>> td {
  line-height: 32px;
}

.signature-requests-table >>> .copy-to-clipboard {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  outline: none;
}

.signature-requests-table >>> .copy-to-clipboard-icon {
  color: var(--color-grey-38);
  font-size: 12px;
  margin-left: 3px;
  width: 10px;
}

.signature-requests-table >>> .copy-to-clipboard-icon .check-icon,
.signature-requests-table >>> .copy-to-clipboard-icon.highlight .copy-icon {
  display: none;
}

.signature-requests-table >>> .copy-to-clipboard-icon.highlight .check-icon {
  color: var(--color-grey-38);
  display: inline-block;
}
</style>
