import Event from "models/event";

export class SendMessageRequested extends Event {
  get requestParams() {
    return {
      messageable_id: this.options.messageableId,
      messageable_type: this.options.messageableType,
      display_for: this.options.displayFor,
      visibility: this.options.visibility,
      content: this.options.content,
    };
  }
}

export class EventsUpdateRequested extends Event {
  get newEvent() {
    return this.options.newEvent;
  }
}

export class ErrorDisplayRequested extends Event {
  get errors() {
    return this.options.errors;
  }
}

export class ComponentLoadingRequested extends Event {}
