<template>
  <div>
    <a :href="loanLink">{{ loan.fullAddress }} ({{ loan.toParam }})</a>
    Lender: {{ loan.lenderName }};
    Relationship Manager: {{ loan.relationManagerName }};
    Analyst: {{ loan.analystName || "Unassigned" }}
  </div>
</template>

<script>
export default {
  name: "loan-duplicate-item",
  props: {
    loan: Object,
  },
  computed: {
    loanLink() {
      return `/manage/loans/${this.loan.toParam}`;
    },
  },
};
</script>
