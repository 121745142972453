<template>
  <div class="loan-documents-preview">
    <div v-if="failToGetBoxFileId" class="notification-message alert-info justify-content-center">
      <p>Preview is currently unavailable. File has not been uploaded to Box yet</p>
    </div>
    <div
      v-else-if="isNotUploadedToBox"
      class="notification-message alert-info justify-content-center"
    >
      <p>Preview is unavailable. File is deleted or has not been uploaded to Box</p>
    </div>
    <div v-else="">
      <div v-if="isEmbedUrlLoaded" class="preview-container">
        <iframe
          allowfullscreen=""
          frameborder="0"
          msallowfullscreen=""
          :src="boxEmbedUrl"
          webkitallowfullscreen=""
          @load="restoreFocus"
        ></iframe>
      </div>
      <div v-else class="ps-loader"></div>
    </div>
  </div>
</template>
<script>
import Command from "models/command";
import MessageBusService from "services/message_bus_service";
import { OneDocumentUpdated } from "models/loan/events/document_events";

export default {
  name: "loan-documents-preview",
  props: {
    document: Object,
  },
  data() {
    return {
      documentEmbedUrl: undefined,
      failToGetBoxFileId: false,
      isGettingBoxFileIdInProgress: false,
      isNotUploadedToBox: false,
    };
  },
  computed: {
    isEmbedUrlLoaded() {
      return !!this.boxEmbedUrl;
    },
    isUploadedToBox() {
      return !!this.document.boxFileId;
    },
    boxEmbedUrl() {
      if (!this.documentEmbedUrl) {
        this.loadEmbedUrl();
      }
      return this.documentEmbedUrl;
    },
  },
  watch: {
    document() {
      this.documentEmbedUrl = undefined;
      this.isGettingBoxFileIdInProgress = false;
      this.failToGetBoxFileId = false;
      this.isNotUploadedToBox = false;
    },
  },
  methods: {
    loadEmbedUrl() {
      this.getBoxFileId().then(boxFileId => {
        if (!boxFileId) {
          return;
        }

        const url = `/manage/embed_documents/${boxFileId}`;

        PS.Services.AjaxService.getJSON(url)
          .then(data => {
            this.documentEmbedUrl = data.url;
          })
          .catch(result => {
            if (result.response.status === 403) {
              PS.Services.LocationService.assign(
                `/users/auth/box_oauth2?redirect=${PS.Services.LocationService.pathname}`
              );
            }
            if (result.response.status === 404) {
              this.isNotUploadedToBox = true;
            }
          });
      });
    },
    getBoxFileId() {
      return new Promise((resolve, reject) => {
        if (this.isUploadedToBox || this.isGettingBoxFileIdInProgress || this.failToGetBoxFileId) {
          resolve(this.document.boxFileId);
          return;
        }

        this.isGettingBoxFileIdInProgress = true;

        const url = `/manage/uploaded_files/${this.document.id}`;

        PS.Services.AjaxService.getJSON(url)
          .then(data => {
            if (!data.boxFileId) {
              this.failToGetBoxFileId = true;
            } else {
              const updatedDocument = { ...this.document, boxFileId: data.boxFileId };

              Command.broadcastEvent(OneDocumentUpdated, MessageBusService.loanBus, {
                document: updatedDocument,
                nonUserUpdate: true,
              });
            }
          })
          .catch(() => {
            this.failToGetBoxFileId = true;
          })
          .finally(() => {
            this.isGettingBoxFileIdInProgress = false;
            resolve(this.document.boxFileId);
          });
      });
    },
    restoreFocus() {
      const focusedElement = document.activeElement;

      setTimeout(() => {
        focusedElement.focus();
      }, 1200);
    },
  },
};
</script>
