<template>
  <view-panel class="loan-details-view-panel">
    <template slot="header">
      Property Details
    </template>
    <div class="row loan-details-view-panel-row">
      <div class="col-6 loan-details-view-panel-column">
        <details-list :data="columnOneRows" />
      </div>
      <div class="col-6 loan-details-view-panel-column">
        <details-list :data="columnTwoRows" />
      </div>
    </div>
  </view-panel>
</template>

<script>
import ViewPanel from "components/view_panel.vue";
import Property from "models/lender_form/property";
import DetailsList from "components/loan_details/list";
import PropertyMixin from "../property_mixin";

const CBSA_TYPE_NAMES = {
  msa: "Metropolitan Statistical Area",
  misa: "Micropolitan Statistical Area",
};

export default {
  name: "loan-property-details",
  components: {
    ViewPanel,
    DetailsList,
  },
  mixins: [PropertyMixin],
  props: {
    property: {
      type: Property,
      required: true,
    },
  },
  computed: {
    columnOneRows() {
      return [
        { label: "Property Type", value: this.property.propertyTypeLabel, show: true },
        { label: "Street Address", value: this.property.data.street, show: true },
        { label: "City", value: this.property.data.city, show: true },
        { label: "State", value: this.property.stateLabel, show: true },
        { label: "ZIP Code", value: this.property.data.postalCode, show: true },
        { label: "CBSA Name", value: this.property.data.cbsaName, show: true },
        { label: "CBSA Type", value: CBSA_TYPE_NAMES[this.property.data.cbsaType], show: true },
        { label: "CBSA Code", value: this.property.data.cbsaCode, show: true },
        { label: "County", value: this.property.data.county, show: true },
        { label: "APN", value: this.property.data.apn, show: true },
        { label: "Country", value: this.property.data.country, show: this.showCountry },
        { label: "# Stories", value: this.property.data.stories, show: this.showStories },
        {
          label: "# Buildings",
          value: this.property.data.buildings,
          show: this.typeCommercialOrMultifamily,
        },
        {
          label: "Tenancy",
          value: this.property.tenancyLabel,
          show: this.typeCommercialOrMultifamily,
        },
        { label: "NRA", value: this.property.data.nra, show: this.typeCommercialOrMultifamily },
        {
          label: "Occupancy %",
          value: this.property.data.occupancyPercentage,
          show: this.typeCommercialOrMultifamily,
        },
        { label: "Legal Description", value: this.property.data.legalDescription, show: true },
      ];
    },
    columnTwoRows() {
      return [
        { label: "Unit Count", value: this.property.data.unitCount, show: true },
        {
          label: "Affordable Unit Count",
          value: this.property.data.affordableUnitCount,
          show: this.typeMultifamily,
        },
        { label: "Bed", value: this.property.data.beds, show: true },
        { label: "Bath", value: this.property.data.baths, show: true },
        { label: "Square Feet", value: this.property.data.squareFeet, show: true },
        { label: "Lot Acres", value: this.property.data.lotAcres, show: true },
        { label: "Year Built", value: this.property.data.yearBuilt, show: true },
        { label: "Condition", value: this.property.data.condition, show: true },
        { label: "HOA($)", value: this.property.data.monthlyHoaDues, show: true },
        {
          label: "Previous Transaction Sale Price($)",
          value: this.property.data.exitSalePrice,
          show: true,
        },
        { label: "Previous Transaction Date", value: this.property.data.soldDate, show: true },
        {
          label: "Current Transaction Purchase Price($)",
          value: this.property.data.purchasePrice,
          show: true,
        },
        {
          label: "Environmental Issues Identified",
          value: this.property.environmentalIssuesIdentifiedLabel,
          show: this.typeMultifamily,
        },
        {
          label: "Ground Lease",
          value: this.property.groundLeaseLabel,
          show: this.typeMultifamily,
        },
      ];
    },
  },
};
</script>
