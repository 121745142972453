<template>
  <div>
    <slot
      :cashReceipts="cashReceipts"
      :selectedSome="selectedSome"
      :selectedNone="selectedNone"
      :selectedAll="selectedAll"
      :revisedAmount="revisedAmount"
      :setRevisedAmount="setRevisedAmount"
      :selected="selected"
      :toggleSelect="toggleSelect"
      :toggleSelectAll="toggleSelectAll"
      :reconcile="reconcile"
      :reconcileInFlight="reconcileInFlight"
      :disbursementsDataURL="disbursementsDataURL"
    />
  </div>
</template>

<script>
import omit from "lodash/omit";
import findIndex from "lodash/findIndex";
import sortBy from "lodash/sortBy";
import toPairs from "lodash/toPairs";

import AjaxService from "services/ajax_service";
import { success, error } from "services/notification";

export default {
  props: {
    initialCashReceipts: {
      type: Array,
      required: true,
    },
  },
  watch: {
    initialCashReceipts(newVal) {
      this.cashReceipts = newVal || [];
    },
  },
  data() {
    return {
      cashReceipts: sortBy(this.initialCashReceipts, ["distributedBy.label", "bankAccount.name"]),
      selectedIds: new Set(),
      revisedAmounts: {},
      reconcileInFlight: false,
    };
  },
  computed: {
    selectedSome() {
      return (
        this.cashReceipts.length > 0 &&
        this.selectedIds.size > 0 &&
        this.cashReceipts.length > this.selectedIds.size
      );
    },
    selectedNone() {
      return this.selectedIds.size === 0;
    },
    selectedAll() {
      return (
        this.cashReceipts.length > 0 &&
        this.selectedIds.size > 0 &&
        this.cashReceipts.length == this.selectedIds.size
      );
    },
  },
  methods: {
    disbursementsDataURL({ id }) {
      return `/manage/cash_receipts/${id}.csv`;
    },
    revisedAmount({ id }) {
      return this.revisedAmounts[id];
    },
    setRevisedAmount({ id }, amount) {
      this.revisedAmounts[id] = amount;
    },
    selected({ id }) {
      return this.selectedIds.has(id);
    },
    toggleSelect({ id }) {
      if (this.selectedIds.has(id)) {
        this.selectedIds.delete(id);
        this.selectedIds = new Set(this.selectedIds);
      } else {
        this.selectedIds.add(id);
        this.selectedIds = new Set(this.selectedIds);
      }
    },
    toggleSelectAll() {
      if (this.selectedIds.size > 0) {
        this.selectedIds = new Set();
      } else {
        this.selectedIds = new Set(this.cashReceipts.map(({ id }) => id));
      }
    },
    async reconcile() {
      this.reconcileInFlight = true;

      const headers = { "content-type": "application/json" };
      const body = {
        cash_receipts: Array.from(this.selectedIds).map(id => ({
          id,
          revised_amount: this.revisedAmounts[id],
        })),
      };

      try {
        const response = await AjaxService.patch("/manage/cash_receipts/reconcile", body, headers);
        const succeedIds = this.handleResult(response);

        this.revisedAmounts = omit(this.revisedAmounts, this.succeedIds);
        if (succeedIds.length === this.selectedIds.length) {
          success("Operation succeed");
        }
        this.selectedIds = new Set();
      } catch (e) {
        console.error(e);
        this.handleServerException();
      } finally {
        this.reconcileInFlight = false;
      }
    },
    handleResult(response) {
      const ids = [];

      toPairs(response).forEach(([id, result]) => {
        if (result) {
          this.removeCashReceipt(id);
          ids.push(id);
        } else {
          error(`Failed to reconcile Cash Receipt #${id}`);
        }
      });

      return ids;
    },
    handleServerException() {
      error("Operation forbidden");
    },
    removeCashReceipt(id) {
      const index = findIndex(this.cashReceipts, { id: parseInt(id) });

      if (index !== -1) {
        this.$delete(this.cashReceipts, index);
      }
    },
  },
};
</script>
