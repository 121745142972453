<template>
  <ul class="servicer-loan-boarding-table-review-flags">
    <li v-for="flag in record.reviewFlags" :key="flag.name">
      <i class="fa" :class="iconClass(flag.name)" />
      <truncated-text :value="flag.label" :max-length="18" />
    </li>
  </ul>
</template>
<script>
const ERROR_FLAGS = ["data_change"];

import TruncatedText from "components/servicer/loan_boarding/truncated_text";

export default {
  name: "manage-servicer-loan-boarding-review-flags",
  components: { TruncatedText },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  methods: {
    iconClass(flag) {
      return ERROR_FLAGS.includes(flag) ? "review-flag-error" : "fa-exclamation-triangle";
    },
  },
};
</script>
