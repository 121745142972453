import ActivityFeedEventsSection from "components/activity_feed_events/manage/section";
import CopyToClipboard from "components/copy_to_clipboard";
import LenderTagsMixin from "mixins/manage/lenders/tags";
import ChargeOffStatementNew from "components/manage/charge_off_statement/new";
import ChargeOffStatementShow from "components/manage/charge_off_statement/show";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-charge-off-statements-controller"),
    components: {
      ActivityFeedEventsSection,
      CopyToClipboard,
      ChargeOffStatementNew,
      ChargeOffStatementShow,
    },
    mixins: [LenderTagsMixin],
    data() {
      return {
        documents: PSData.documents,
        loan: PSData.loan,
        events: PSData.events,
      };
    },
  });
});
