export default function(callback) {
  const adjust = e => {
    let ticking = false;

    if (!ticking) {
      window.requestAnimationFrame(callback);

      ticking = true;
    }
  };

  callback();
  window.addEventListener("resize", adjust);
  window.addEventListener("scroll", adjust);
  window.document.body.addEventListener("scroll", adjust);
}
