<template>
  <div class="institution-sales-tracking-action-button">
    <button type="button" class="institution-sales-tracking-action-button-default" @click="$emit(defaultOption.event, offering.id)">
      {{defaultOption.name}}
    </button><button type="button" class="institution-sales-tracking-action-button-more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="fas fa-sort-down"></i>
    </button>
    <ul class="dropdown-menu dropdown-menu-right">
      <li v-for="option in otherOptions" :key="option.name" class="institution-sales-tracking-action-button-option">
        <a href="#" @click.prevent="$emit(option.event, offering.id)">
          {{option.name}}
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    batch: Object,
    offering: Object,
  },
  computed: {
    defaultOptionIndex() {
      return this.enabledOptions.findIndex(option => option.default);
    },
    defaultOption() {
      return this.enabledOptions[this.defaultOptionIndex];
    },
    otherOptions() {
      const index = this.defaultOptionIndex;
      const leftOptions = Array.from(this.enabledOptions);
      leftOptions.splice(index, 1);
      return leftOptions;
    },
    enabledOptions() {
      return this.options.filter(option => option.enabled);
    },
    options() {
      return [
        {
          name: "Move to Offer",
          event: "move-to-offer",
          enabled: this.isMovableToOffer,
          default: true,
        },
        {
          name: "Push to Next",
          event: "push-to-next",
          enabled: this.isPushableToNext,
          default: this.isPushableToNextDefault,
        },
        {
          name: "Reallocate",
          event: "reallocate",
          enabled: this.isReallocatable,
          default: this.isReallocatableDefault,
        },
        {
          name: "Exclude",
          event: "exclude",
          enabled: this.isExcludable,
        },
        {
          name: "Edit Notes",
          event: "edit-notes",
          enabled: true,
          default: true,
        },
        {
          name: "Critical Hold",
          event: "critical-hold",
          enabled: this.isCriticalHoldable,
        },
      ];
    },
    isExcludable() {
      return !this.batch.wireDate;
    },
    isMovableToOffer() {
      return (
        this.offering.state !== "accepted" &&
        !this.batch.offerDate &&
        !["reallocate", "remove"].includes(this.offering.nextAction)
      );
    },
    isPushableToNext() {
      return this.offering.state === "accepted" && !this.batch.wireDate;
    },
    isReallocatable() {
      return this.isMovableToOffer || this.isPushableToNext;
    },
    isCriticalHoldable() {
      return this.isMovableToOffer || this.isPushableToNext;
    },
    isPushableToNextDefault() {
      return !this.batch.offerDate;
    },
    isReallocatableDefault() {
      return this.batch.offerDate;
    },
  },
};
</script>
