import isEqual from "lodash/isEqual";
import mapKeys from "lodash/mapKeys";
import get from "lodash/get";
import snakeCase from "lodash/snakeCase";

export default class ResponsibleRole {
  static deserialize(rawData) {
    return new ResponsibleRole(
      rawData["id"],
      rawData["definition"],
      rawData["referenceId"],
      rawData["attributeValue"]
    );
  }

  constructor(id = null, definition = null, referenceId = null, attributeValue = null) {
    this.id = id;
    this.definition = definition;
    this.referenceId = referenceId;
    this.attributeValue = attributeValue;
  }

  updateAttributeValue(value) {
    this.attributeValue = value;
  }

  resetDefinitionFields(newDefinition, oldDefinition) {
    this.attributeValue = null;

    if (newDefinition.name === "guarantor" || !newDefinition.name) {
      this.referenceId = null;
    }

    if (newDefinition.name !== "guarantor" && oldDefinition.name === "guarantor") {
      this.referenceId = null;
    }
  }

  get attributeSchema() {
    if (!this.definition) return {};

    return prepareAttributeSchema(this.definition.attributeSchema);
  }

  get isGuarantor() {
    return this.definition && this.definition.name === "guarantor";
  }

  toPlainObject({ toSnakeCase } = { toSnakeCase: false }) {
    const role = {
      id: this.id,
      referenceId: this.referenceId,
      responsibleRoleDefinitionId: get(this.definition, "id", null),
      attributeValue: this.attributeValue,
    };

    if (toSnakeCase) return mapKeys(role, (_, key) => snakeCase(key));

    return role;
  }
}

const FieldTypes = {
  string: "TextInput",
  percentage: "NumberInput",
};

const prepareAttributeSchema = rawSchema => {
  if (isEqual(rawSchema, {})) return {};

  return {
    filedType: FieldTypes[rawSchema.type],
    name: rawSchema.name,
    label: rawSchema.label,
  };
};
