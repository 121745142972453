class ReserveDrawRequestPolicy {
  constructor(drawRequestPolicy) {
    this.drawRequestPolicy = drawRequestPolicy || {};
  }

  canApprove() {
    return this.drawRequestPolicy.approve.allowed;
  }

  canCancel() {
    return this.drawRequestPolicy.cancel.allowed;
  }

  canCancelReversal() {
    return this.drawRequestPolicy.cancelReversal.allowed;
  }

  canCompleteReversal() {
    return this.drawRequestPolicy.completeReversal.allowed;
  }

  canCreate() {
    return this.drawRequestPolicy.create.allowed;
  }

  canUpdate() {
    return this.drawRequestPolicy.update.allowed;
  }

  canSubmit() {
    return this.drawRequestPolicy.submit.allowed;
  }

  canRepair() {
    return this.drawRequestPolicy.repair.allowed;
  }

  canReverse() {
    return this.drawRequestPolicy.reverse.allowed;
  }

  canSubmitWireInstruction() {
    return this.drawRequestPolicy.wireInstruction.allowed;
  }

  canDownload() {
    return this.drawRequestPolicy.download.allowed;
  }
}

export default ReserveDrawRequestPolicy;
