<template>
  <div :class="{ 'loan-task-unseen': hasUpdates }" class="task-container">
    <div class="task-header">
      <span class="task-name">{{ task.title }}</span>
      <span class="task-status">{{ task.statusText }}</span>
    </div>

    <div class="task-icon-group">
      <div v-if="hasAssignees" class="task-icon-group-assignee-abbreviation">
        <div
          v-for="assignee in task.assignees"
          :key="'assignee' + assignee.id"
          class="loan-task-assignee-abbreviation"
        >
          {{ assignee.nameAbbreviation }}
        </div>
      </div>

      <div
        v-if="task.loanTaskCommentsCount > 0"
        class="loan-task-comment"
      >
        <i class="fas fa-comment loan-task-comment-icon" />
        <span>{{ task.loanTaskCommentsCount }}</span>
      </div>

      <div v-show="attachments.length > 0" class="loan-task-comment">
        <i class="fas fa-paperclip loan-task-comment-icon" />
        <span>{{ attachments.length }}</span>
      </div>

      <div v-show="task.updatedAgo || task.updatedBy" class="loan-task-comment loan-task-updated-by">
        <span>{{ updatedText }}</span>
      </div>

      <div v-if="task.isSla" class="loan-task-comment">
        <span v-if="task.completedAt">Completed At: {{ task.completedAt }}</span>
        <span v-else-if="task.expectedAt">Expected At: {{ task.expectedAt }}</span>
      </div>

      <div v-if="showDueDate" class="loan-task-comment">
        <i class="far fa-calendar loan-task-comment-icon" />
        <span>{{ new Date(task.expectedAt) | formatDate("MMM DD h:mm A") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loan-tasks-task",
  props: {
    isSeen: Boolean,
    task: {
      type: Object,
      default: () => {
        return {};
      },
    },
    documents: Array,
  },
  computed: {
    hasUpdates() {
      return this.isSeen === false;
    },
    hasAssignees() {
      return this.task.assignees.length > 0;
    },
    attachments() {
      return this.documents.filter(document => document.taskIds.includes(this.task.id));
    },
    updatedText() {
      return `${this.task.updatedAgo} ago ${this.task.updatedBy}`;
    },
    showDueDate() {
      return this.task.isInternal && !this.task.isSla && this.task.expectedAt;
    },
  },
};
</script>
