<template>
  <div class="active-portfolio-container">
    <div class="container-layout">
      <tabs :tabs="tabs" class="sub-tabs" @tab-selected="changeTab" />
      <div v-show="tabs.bridge.active">
        <portfolio-summary-bar :summary-data="portfolioBreakdown" class="panel-bordered" />
        <portfolio-breakdown :portfolio-breakdown="portfolioBreakdown" />
        <bridge-loans-list :loans="bridgeLoans" :loans-route="loansRoute" :is-lender="isLender" class="panel-bordered" />
      </div>
      <residential-for-rent-loans-list
        v-show="tabs.residentialForRent.active"
        :loans-route="loansRoute"
        :residential-for-rent-loans-route="residentialForRentLoansRoute"
        class="panel-bordered"
        @loaded="onDataLoad"
      />
      <reo-loans-list
        v-show="tabs.reo.active"
        :loans-route="loansRoute"
        class="panel-bordered"
        @loaded="onDataLoad"
      />
      <paid-off-loans-list
        v-show="tabs.paidOff.active"
        class="panel-bordered"
        @loaded="onDataLoad"
      />
    </div>
  </div>
</template>

<script>
import keyBy from "lodash/keyBy";

import PortfolioSummaryBar from "components/active_portfolio/portfolio_summary_bar";
import PortfolioBreakdown from "components/active_portfolio/portfolio_breakdown";
import Tabs from "components/shared/tabs";
import BridgeLoansList from "components/active_portfolio/bridge_loans_list";
import ReoLoansList from "components/active_portfolio/reo_loans_list";
import PaidOffLoansList from "components/active_portfolio/paid_off_loans_list";
import ResidentialForRentLoansList from "components/active_portfolio/residential_for_rent_loans_list";
import Segment from "services/segment_service";
import snakeCase from "lodash/snakeCase";

const TABS = {
  bridge: "Bridge",
  residentialForRent: "Residential for Rent",
  reo: "REO",
  paidOff: "Paid Off",
};

function tabName(name, length) {
  return `${name} (${length})`;
}

export default {
  name: "manage-active-portfolio-controller",
  components: {
    PortfolioSummaryBar,
    PortfolioBreakdown,
    Tabs,
    PaidOffLoansList,
    BridgeLoansList,
    ReoLoansList,
    ResidentialForRentLoansList,
  },
  data() {
    const bridgeLoans = PSData.bridgeLoans;
    const portfolioBreakdown = keyBy(PSData.portfolioBreakdown, "type");

    return {
      portfolioBreakdown,
      bridgeLoans,
      tabs: {
        bridge: { name: tabName(TABS.bridge, bridgeLoans.length), active: true },
        residentialForRent: { name: TABS.residentialForRent, active: false },
        paidOff: { name: TABS.paidOff, active: false },
        reo: { name: TABS.reo, active: false },
      },
      loansRoute: PSData.routes.loans,
      residentialForRentLoansRoute: PSData.routes.residentialForRentLoans,
      isLender: PSData.isLender,
    };
  },
  methods: {
    changeTab(selectedName) {
      Object.keys(this.tabs).forEach(name => {
        this.tabs[name].active = name === selectedName;
      });

      if (this.isLender) {
        Segment.track("clickedActiveFundedSubnavs", {
          selected: "selected_" + snakeCase(selectedName),
        });
      }
    },
    onDataLoad(group, dataLength) {
      this.tabs[group].name = tabName(TABS[group], dataLength);
    },
  },
};
</script>
