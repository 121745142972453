<template>
  <div class="portfolio-breakdown-group">
    <with-tooltip class="panel-header">
      {{ data.label }}
      <template slot="content">
        Represents all Single Family Residential, Multifamily, and Commercial bridge loans funded by
        PeerStreet that have a loan servicing status of Late, Late 30, Late 60, Late 90, Late 120+,
        are in foreclosure, or have matured.
      </template>
    </with-tooltip>
    <div class="projected-revenues">
      <div class="projected-revenue non-performing">
        <div class="title">
          Monthly Spread
          <with-tooltip>
            Revenue at Risk
            <template slot="content">
              = the total unpaid principal balance for all active non-performing bridge loans with
              one or more delinquent payments or have matured x (1/12) x the weighted average lender
              spread of those non-performing loans. This value is only a projection and may not
              represent the actual amount of monthly spread revenue at risk.
            </template>
          </with-tooltip>
        </div>
        <div class="value">
          {{ data.projectedMonthlySpreadRevenue }}
        </div>
      </div>
      <div v-if="data.showBPiecesAtRisk" class="projected-revenue non-performing">
        <div class="title">
          Total Lender
          <with-tooltip>
            B-Pieces at Risk
            <template slot="content">
              The sum of all subordinate positions the lender holds in their active portfolio where
              borrower payments have not been made and the subordinate position is at risk of loss.
            </template>
          </with-tooltip>
        </div>
        <div class="value">
          {{ data.bPiecesAtRisk }}
        </div>
      </div>
    </div>
    <div class="breakdown-summary">
      <with-tooltip>
        Total Volume
        <template slot="content">
          The sum of the total unpaid principal balance for all active non-performing Single Family
          Residential, Multifamily, and Commercial bridge loans.
        </template>
      </with-tooltip>
      <span class="summary-value">{{ data.totalVolume }} ({{ data.loansCountLabel }})</span>
    </div>
    <div class="breakdown-summary">
      <with-tooltip>
        Average Lender Spread
        <template slot="content">
          The weighted average lender spread for all active non-performing Single Family
          Residential, Multifamily, and Commercial bridge loans.
        </template>
      </with-tooltip>
      <span class="summary-value">{{ data.averageLenderSpread }}</span>
    </div>
  </div>
</template>

<script>
import WithTooltip from "components/shared/with_tooltip";

export default {
  name: "non-performing-loans-breakdown",
  components: {
    WithTooltip,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
