<template>
  <div class="restrictio-loan-detail-table">
    <span v-if="!hasDetails">
      No loans found.
    </span>
    <base-table v-else :data="details">
      <base-column field="loan" label="PS Loan ID" v-slot="props" >
        <a :href="props.row.loanUrl" target="_blank">
          {{ props.row.psId }}
        </a>
      </base-column>
      <base-column field="lender" label="Lender Name" v-slot="props" >
        <a :href="props.row.lenderUrl" target="_blank">
          {{ props.row.lenderName }}
        </a>
      </base-column>
      <base-column field="propertyAddress" width="500" label="Property Address" v-slot="props" >
        {{ props.row.propertyAddress }}
      </base-column>
      <base-column field="loanStatus" label="Loan Status" v-slot="props" >
        <div class="loan-status" :class="loanStatusClass(props.row.loanStatus)">
          {{props.row.loanStatusDisplay}}
        </div>
      </base-column>
      <base-column field="names" label="Alias Used on Loan" v-slot="props" >
        {{ props.row.names.join(', ') }}
      </base-column>
      <base-column field="roles" label="Roles" v-slot="props" >
        {{ props.row.roles.join(', ') }}
      </base-column>
    </base-table>
  </div>
</template>

<script>
export default {
  name: "restriction-loan-list",
  props: {
    details: {
      type: Array,
      default: [],
    },
  },
  methods: {
    loanStatusClass(status) {
      return `loan-${status}`;
    },
  },
  computed: {
    hasDetails() {
      return this.details.length > 0;
    },
  },
};
</script>
