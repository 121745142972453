const SUCCESS = "success";
const ERROR = "error";

const ERROR_MESSAGE = "Something went wrong";

function notify(event) {
  $(window.PS).trigger("ajaxNotification.add", event);
}

export function success(message) {
  notify({ status: SUCCESS, message: message });
}

export function error(message = ERROR_MESSAGE) {
  notify({ status: ERROR, message: message });
}
