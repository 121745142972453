<template>
  <div class="row no-gutters asset-management-modifications-item">
    <div class="col-12">
      <subsection-header>
        Option {{ model.sequenceNumber }}
        <span class="finops-status finops-status-exercised badge-exercised" v-if="model.isExercised">
          Exercised
        </span>
      </subsection-header>
    </div>
    <div class="col-6 asset-management-modifications-item-column">
      <attribute-value :label="'Owner'" :tooltip="'Person/entity to exercised the modification option'">
        <div class="text-capitalize">{{ model.ownerType }}</div>
      </attribute-value>
      <attribute-value :label="'Modification'">{{ model.isModification | yesNo }}</attribute-value>
      <attribute-value :label="'Exercised'">{{ model.isExercised | yesNo }}</attribute-value>
      <subsection-header>
        Fees
      </subsection-header>
      <attribute-value :label="'Total Fees and Costs'">
        <modification-fees-popover :model="model" />
      </attribute-value>
      <attribute-value :label="'Payment Option'">{{ model.paymentOptionText }}</attribute-value>
      <attribute-value :label="'Modification Fee'">
        <div v-if="isFeeTypePercent">
          {{ model.feeValue | percent }}
        </div>
        <div v-else>
          {{ model.feeValue | money }}
        </div>
      </attribute-value>
      <attribute-value :label="'PS Fee Allocation'">{{ model.peerstreetFeeAllocation | percent }}</attribute-value>
    </div>
    <div class="col-6 asset-management-modifications-item-column">
      <modification-options-table :item="item" />
    </div>
  </div>
</template>
<script>
import ModificationOptionsTable from "./modification_options_table";
import AttributeValue from "../components/attribute_value";
import SubsectionHeader from "../components/subsection_header";
import LoanModification from "models/loan_modification";
import ModificationFeesPopover from "./modification_fees_popover";

export default {
  components: {
    ModificationOptionsTable,
    AttributeValue,
    SubsectionHeader,
    ModificationFeesPopover
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    model() {
      return new LoanModification(this.item);
    },
    isFeeTypePercent() {
      return this.model.feeType === "percentage";
    },
  },
};
</script>
