<template>
  <div class="report-header">
    <div class="report-info">
      <p><span>Date:</span>&nbsp;{{ report.date }}</p>
      <p><span>Docs:</span>&nbsp;{{ report.itemsCount }}</p>
      <p><span>Uploaded By:</span>&nbsp;{{ report.uploadedBy }}</p>
      <p v-if="report.completedBy">
        <span>Completed By:&nbsp;</span>{{ report.completedBy }}
      </p>
      <p><span>Custodian: </span>&nbsp;{{ report.custodianText }}</p>
      <p>
        <span>File:</span>&nbsp;
        <a :href="report.fileUrl" target="_blank">{{ report.fileName }}</a>
      </p>
    </div>
    <div class="report-actions">
      <button
        v-if="!completed && canManage"
        class="btn-action-cta"
        :disabled="!canCompleteReport"
        @click="$emit('on-complete')"
      >
        Mark Complete
      </button>
      <button v-if="completed" class="btn-secondary-action-cta">
        <i class="fas fa-check" />
        Completed
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "report-import-header",
  props: {
    report: {
      type: Object,
      required: true,
    },
    completed: {
      type: Boolean,
      required: true,
    },
    canCompleteReport: {
      type: Boolean,
      required: true,
    },
    canManage: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
};
</script>
