<template>
  <base-table
    :data="loans"
    :loading="loading"
    detailed
    custom-detail-row
    detail-key="psLoanId"
    :opened-detailed="loanIds"
    :show-detail-icon="false"
    :row-class="(row) => rowClass(row, 0)"
    class="loan-portfolio-table"
    @click="(row) => openTab(workflowsUrl(row))"
  >
    <base-column
      field="psLoanId"
    >
      <template v-slot:header>
        <div>PS Loan ID/</div><div>Servicer ID</div>
      </template>
      <template v-slot="{ row }">
        <div>{{ row.psLoanId }}</div>
        <div @click.stop="">
          <servicer-loan-info
            :id="row.servicerLoanId"
            :url="row.servicerLoanUrl"
          />
        </div>
      </template>
    </base-column>
    <base-column
      v-slot="{ row }"
      field="loanTitle"
      label="Loan"
    >
      <div class="loan-details">
        {{ loanAddress(row.loanTitle) }}
      </div>
      <div class="loan-details">
        {{ loanStateDetails(row.loanTitle) }}
      </div>
    </base-column>
    <base-column
      v-slot="{ row }"
      field="assigneeName"
      label="Asset Manager"
    >
      {{ row.assigneeName }}
    </base-column>
    <base-column
      v-slot="{ row }"
      field="unpaidPrincipalBalance"
      label="Unpaid Principal"
      numeric
    >
      {{ row.unpaidPrincipalBalance | money }}
    </base-column>
    <base-column
      v-slot="{ row }"
      field="borrowerPaymentDaysPastDue"
      label="Days Past Due"
      numeric
    >
      {{ row.borrowerPaymentDaysPastDue }}
    </base-column>
    <base-column
      v-slot="{ row }"
      field="daysToMaturity"
      label="Days to Maturity"
      numeric
    >
      {{ row.daysToMaturity }}
    </base-column>
    <base-column
      v-slot="{ row }"
      field="loanOwner"
      label="Owner"
    >
      {{ row.loanOwner }}
    </base-column>
    <base-column
      v-slot="{ row }"
      field="label"
      label="Open Workflows"
    >
      <next-task-tooltip :workflow="row.workflows[0]">
        <div class="dimmable">
          {{ row.workflows[0].label }}
        </div>
      </next-task-tooltip>
    </base-column>
    <base-column
      v-slot="{ row }"
      field="progress"
      label="Progress"
      width="250"
    >
      <next-task-tooltip :workflow="row.workflows[0]">
        <div class="dimmable">
          <div class="workflow-progress">
            <base-progress :value="row.workflows[0].progress" type="info" size="small" />
            {{ row.workflows[0].progress }}%
          </div>
        </div>
      </next-task-tooltip>
    </base-column>
    <base-column
      v-slot="{ row }"
      field="dueDate"
      label="Due Date"
      width="90"
    >
      <div class="dimmable">
        <span :class="{ overdue: isOverdue(row.workflows[0].dueDate) }">
          {{ row.workflows[0].dueDate | formatDate("MMM D", "MM/DD/YYYY") }}
        </span>
      </div>
    </base-column>
    <base-column
      v-slot="{ row }"
      field="age"
      label="Age"
    >
      <div class="dimmable">
        {{ row.workflows[0].age }}d
      </div>
    </base-column>
    <base-column
      v-slot="{ row }"
      field="delinquentTasksTotal"
      label="Tasks Overdue"
    >
      <div class="dimmable">
        <span v-if="row.workflows[0].delinquentTasksTotal > 0" class="overdue-tasks">
          {{ row.workflows[0].delinquentTasksTotal }} <i class="fas fa-exclamation-triangle" />
        </span>
        <span v-else>
          -
        </span>
      </div>
    </base-column>
    <template v-slot:detail="{ props }">
      <tr
        v-for="(workflow, index) in props.row.workflows.slice(1)"
        :key="workflow.id"
        :class="[rowClass(props.row, index + 1)]"
        @click="openTab(workflowsUrl(props.row))"
      >
        <td colspan="7" />
        <td>
          <next-task-tooltip :workflow="workflow">
            <div class="dimmable">
              {{ workflow.label }}
            </div>
          </next-task-tooltip>
        </td>
        <td>
          <next-task-tooltip :workflow="workflow">
            <div class="dimmable">
              <div class="workflow-progress">
                <base-progress :value="workflow.progress" type="info" size="small" />
                {{ workflow.progress }}%
              </div>
            </div>
          </next-task-tooltip>
        </td>
        <td>
          <div class="dimmable">
            <span :class="{ overdue: isOverdue(workflow.dueDate) }">
              {{ workflow.dueDate | formatDate("MMM D", "MM/DD/YYYY") }}
            </span>
          </div>
        </td>
        <td>
          <div class="dimmable">
            {{ workflow.age }}d
          </div>
        </td>
        <td>
          <div class="dimmable">
            <span v-if="workflow.delinquentTasksTotal > 0" class="overdue-tasks">
              {{ workflow.delinquentTasksTotal }} <i class="fas fa-exclamation-triangle" />
            </span>
            <span v-else>
              -
            </span>
          </div>
        </td>
      </tr>
    </template>
    <template v-slot:empty>
      No loans
    </template>
  </base-table>
</template>
<script>
import moment from "moment";
import isNil from "lodash/isNil";
import ServicerLoanInfo from "../components/servicer_loan_info.vue";
import NextTaskTooltip from "./next_task_tooltip";
import OpenTabMixin from "mixins/open_tab_mixin";

export default {
  components: {
    ServicerLoanInfo,
    NextTaskTooltip,
  },
  mixins: [OpenTabMixin],
  props: {
    loans: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    workflowAssigneeId: {
      type: [String, Number],
    },
  },
  computed: {
    loanIds() {
      return this.loans.map(l => l.psLoanId);
    },
  },
  methods: {
    loanAddress(loanTitle) {
      const addressComponents = loanTitle.split(", ");
      return addressComponents[0];
    },
    loanStateDetails(loanTitle) {
      const addressComponents = loanTitle.split(", ");
      return addressComponents.slice(1).join(", ");
    },
    rowClass(row, index) {
      const classes = [];
      if (index < row.workflows.length - 1) {
        classes.push("table-row-light-border");
      }
      if (
        !isNil(this.workflowAssigneeId) &&
        row.workflows[index].assignedToId !== parseInt(this.workflowAssigneeId, 10)
      ) {
        classes.push("table-row-dim");
      }
      return classes;
    },
    isOverdue(dueDate) {
      const dueDateMoment = moment(dueDate, "MM-DD-YYYY");
      return dueDateMoment.isBefore(moment().startOf("day"));
    },
    workflowsUrl(row) {
      return `/manage/loans/${row.psLoanId}/asset_management?view=tasks`;
    },
  },
};
</script>
<style scoped>
.loan-details {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 260px;
}
.overdue {
  color: var(--color-red);
}

::v-deep .table-row-light-border td {
  border: 1px solid var(--color-grey-06);
  border-width: 0 0 1px;
}

::v-deep .table-row-dim .dimmable {
  opacity: 0.3;
}

.workflow-progress {
  display: flex;
  flex-direction: row;
}
.workflow-progress >>> .progress-wrapper {
  display: flex;
  align-items: center;
  flex: 1 1;
  margin-right: 8px;
}
.overdue-tasks {
  color: var(--color-red);
}
</style>

<style>
.loan-portfolio-table tbody tr {
  cursor: pointer;
}
</style>
