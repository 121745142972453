export default class User {
  constructor(user = {}) {
    this._name = user.name;
    this._isActive = user.isActive;
    this._isArchived = user.isArchived;
    this._id = user.id;
    this._raw = user;
    this.reset();
  }

  get email() {
    return this._email;
  }
  set email(value) {
    this._email = value;
  }
  get firstName() {
    return this._firstName;
  }
  set firstName(value) {
    this._firstName = value;
  }
  get lastName() {
    return this._lastName;
  }
  set lastName(value) {
    this._lastName = value;
  }
  get phone() {
    return this._phone;
  }
  set phone(value) {
    this._phone = value;
  }
  get jobPosition() {
    return this._jobPosition;
  }
  set jobPosition(value) {
    this._jobPosition = value;
  }
  get name() {
    return this._name;
  }
  get isActive() {
    return this._isActive;
  }
  get isArchived() {
    return this._isArchived;
  }
  get tasksSubscription() {
    return this._tasksSubscription !== false;
  }
  set tasksSubscription(value) {
    this._tasksSubscription = value;
  }
  get id() {
    return this._id;
  }

  reset() {
    this._email = this._raw.email;
    this._firstName = this._raw.firstName;
    this._lastName = this._raw.lastName;
    this._phone = this._raw.phone;
    this._jobPosition = this._raw.jobPosition;
  }
}

PS.Models.Lenders.User = User;
