<template>
  <table v-if="batch" class="table">
    <thead>
      <tr>
        <th>
          Status
        </th>
        <th>
          Loan ID
        </th>
        <th>
          Sale Type
        </th>
        <th>
          Sale Date
        </th>
        <th>
          Legal Owner
        </th>
        <th class="text-right">
          Investor Rate
        </th>
        <th class="text-right">
          Initial Balance
        </th>
        <th class="text-right">
          Sale Amount
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="loanSale in loanSales"
        :key="loanSale.loanPsId"
      >
        <td>
          <sale-status :loan-sale="loanSale" />
        </td>
        <td>
          <a :href="loanLink(loanSale.loan.id)">
            {{ loanSale.loan.id }}
          </a>
        </td>
        <td>
          {{ saleTypeText }}
        </td>
        <td>
          {{ batch.saleDate }}
        </td>
        <td>
          {{ batch.legalOwner.label }}
        </td>
        <td class="text-right">
          {{ loanSale.investorSpread | percent }}
        </td>
        <td class="text-right">
          {{ loanSale.initialAmount | money }}
        </td>
        <td class="text-right">
          {{ loanSale.saleAmount | money }}
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <th :colspan="7">
          Recording Fees ({{ recordCount }} x {{ unitRecordingFee | money }})
        </th>
        <th class="text-right">
          {{ totalRecordingFee | money }}
        </th>
      </tr>
      <tr>
        <th :colspan="7">
          Total
        </th>
        <th class="text-right">
          {{ total | money }}
        </th>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import sumBy from "lodash/sumBy";
import camelCase from "lodash/camelCase";
import sortBy from "lodash/sortBy";

import SaleStatus from "./sale_status";

export default {
  name: "LoanSaleBatchTable",
  components: { SaleStatus },
  props: {
    batch: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    routes: PSData.routes || {},
  }),
  computed: {
    saletypes() {
      return PSData.saleTypes;
    },
    recordCount() {
      return this.batch.loanSales.length;
    },
    unitRecordingFee() {
      return this.batch.legalOwner.loanRecordingFee;
    },
    total() {
      const totalUnpaid = sumBy(this.batch.loanSales, "saleAmount");

      return totalUnpaid + this.totalRecordingFee;
    },
    totalRecordingFee() {
      return this.recordCount * this.unitRecordingFee;
    },
    saleTypeText() {
      return PSData.saleTypes[camelCase(this.batch.saleType)];
    },
    loanSales() {
      return sortBy(this.batch.loanSales, ({ loan: { id } }) => id);
    },
  },
  methods: {
    loanLink(id) {
      return `${this.routes.loansUrl}/${id}`;
    },
  },
};
</script>
