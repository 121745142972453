<template>
  <div class="owners col-md-3">
    <ul class="menu">
      <li
        class="owner"
        v-for="owner in investors"
        :key="owner.id"
        :class="{ active: isActive(owner.id) }"
        @click="setActiveOwner(owner.id)"
       >
        <span class="owner-name">
          {{ owner.label }}
          <i class="fas fa-caret-right" />
          <span v-if="owner.lender" class="status lender">
            <span class="status-block">Lender</span>
          </span>
        </span>
        <span class="owner-rules-length">{{ owner.recordsCount }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    investors: {
      type: Object,
      required: true,
    },
    currentInvestorId: [Number, String],
  },
  data() {
    return {
      investorId: this.currentInvestorId,
    };
  },
  methods: {
    isActive(investorId) {
      return this.investorId === investorId;
    },
    setActiveOwner(investorId) {
      if (this.investorId == investorId) {
        this.investorId = null;
        this.$emit("clear");
      } else {
        this.investorId = investorId;
        this.$emit("details", investorId);
      }
    },
  },
};
</script>
