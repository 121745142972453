import { SectionType } from "models/lender_form/fields";
import Section from "models/lender_form/section";

export default class SummarySection extends Section {
  constructor(fields) {
    super(SectionType.Summary, fields);
  }

  calculateFields() {
    this.totalFields = 0;
    this.completedFields = 0;
  }
}
