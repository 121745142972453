import ResponsibleParties from "components/manage/responsible_parties/index";
import ResponsiblePartyDetails from "components/manage/responsible_parties/show";
import ActivityFeedEventsSection from "components/activity_feed_events/manage/section";
import CopyToClipboard from "components/copy_to_clipboard";
import LenderTagsMixin from "../../mixins/manage/lenders/tags";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-responsible-parties-controller"),
    components: {
      ResponsibleParties,
      ResponsiblePartyDetails,
      ActivityFeedEventsSection,
      CopyToClipboard,
    },
    mixins: [LenderTagsMixin],
    methods: {
      loanFollowersLoaded() {},
    },
  });
});
