export default class NumberFormatter {
  static formatMoney(value, precision = 2, numberOnly = false) {
    if (numberOnly && typeof value !== "number") {
      return;
    }
    return "$" + NumberFormatter.formatNumber(value, precision);
  }

  static formatPercent(value, precision = 2, numberOnly = false) {
    if (numberOnly && typeof value !== "number") {
      return;
    }

    return parseFloat(value || 0).toFixed(precision) + "%";
  }

  static formatNumber(value, precision = 2, numberOnly = false) {
    if (numberOnly && typeof value !== "number") {
      return;
    }

    return parseFloat(value || 0)
      .toFixed(precision)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  static unmaskNumber(value) {
    if (value || value === 0) {
      return parseFloat(value.toString().replace(/,/g, ""));
    } else {
      return null;
    }
  }

  static formatAccounting(value, precision = 2, numberOnly = false) {
    if (value < 0) {
      return "(" + NumberFormatter.formatMoney(value * -1, precision, numberOnly) + ")";
    } else {
      return NumberFormatter.formatMoney(value, precision, numberOnly);
    }
  }

  static formatPercentAndCurrency(amount, total_amount, multiplier = -1) {
    var percentage = (amount / total_amount) * 100 * multiplier;
    var formattedPercent = NumberFormatter.formatPercent(percentage, 1);
    var formattedDollars = NumberFormatter.formatAccounting(amount);
    return formattedPercent + " | " + formattedDollars;
  }
}
window.PS.Models.NumberFormatter = NumberFormatter;
