const Command = PS.Models.Command;

class AddMidasDataToCharts extends Command {
  execute() {
    const { midasData, midasError } = this.state;
    const charts = this.state.charts;

    if (midasError) {
      Object.values(charts).forEach(c => {
        c.fail({
          midas: "Unable to get House Canary data",
        });
      });
    } else if (midasData.error) {
      Object.values(charts).forEach(c => {
        c.fail({
          midas: midasData.error[0],
        });
      });
    } else {
      Object.values(charts).forEach(c => {
        c.success({
          midas: midasData,
        });
      });
    }

    return Promise.resolve();
  }
}

class AddMidasDataToTables extends Command {
  execute() {
    const { midasData, midasError } = this.state;
    const tables = this.state.tables;

    if (midasError) {
      const message = "Unable to get House Canary data";

      Object.values(tables).forEach(t => {
        t.fail(message);
      });
    } else if (midasData.error) {
      const message = midasData.error[0];

      Object.values(tables).forEach(t => {
        t.fail(message);
      });
    } else {
      Object.values(tables).forEach(t => {
        t.success(midasData);
      });
    }

    return Promise.resolve();
  }
}

PS.Models.Reports.Commands.Common = {
  AddMidasDataToCharts: AddMidasDataToCharts,
  AddMidasDataToTables: AddMidasDataToTables,
};
