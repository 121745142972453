<template>
  <div class="qualification">
    <div>
      <div class="tasks">
        <sla-countdown
          name="Qualification"
          :completed="countdown.completed"
          :deadline="countdown.deadline"
        />
        <sla-task-group
          name="Background"
          :approved="background.approved"
          :canApprove="background.canApprove"
          :need-review="false"
          @approved="$listeners['background-approved']"
        />
        <sla-task-group
          name="Credit"
          :reviewed="credit.reviewed"
          :approved="credit.approved"
          :canReview="credit.canReview"
          :canApprove="credit.canApprove"
          @reviewed="$listeners['credit-reviewed']"
          @approved="$listeners['credit-approved']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SlaCountdown from "./countdown";
import SlaTaskGroup from "./task_group";

export default {
  name: "sla-qualification",
  components: { SlaCountdown, SlaTaskGroup },
  props: {
    countdown: {
      type: Object,
      required: true,
    },
    background: {
      type: Object,
      required: true,
    },
    credit: {
      type: Object,
      required: true,
    },
  },
};
</script>
