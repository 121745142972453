<template>
  <div>
    <div class="loan-details-view-header clearfix">
      {{ party.name }}

      <div class="float-right">
        <button class="btn btn-cancel-modal" @click="$emit('on-cancel')">
          Cancel
        </button>
        <button class="btn btn-action-modal" @click="updateParty">
          Update
        </button>
      </div>
    </div>
    <form>
      <view-panel class="loan-details-view-panel">
        <template slot="header">
          Details
        </template>
        <div class="row loan-details-view-panel-row">
          <div class="col-6 loan-details-view-panel-column">
            <form-input
              v-model="party.name"
              label="Name"
              field="name"
              :errors="errors"
            />
            <form-select
              v-model="party.type"
              label="Type"
              field="responsiblePartyType"
              :options="responsiblePartyTypes"
              :errors="errors"
              blank-option
            />
          </div>
          <div class="col-6 loan-details-view-panel-column">
            <form-input
              :key="party.tinFieldMask"
              v-model="party.tin"
              :label="party.tinFieldLabel"
              field="tin"
              :mask="party.tinFieldMask"
              :errors="errors"
            />
            <form-select
              v-model="party.residency"
              label="Residency Status"
              field="residency"
              :options="responsiblePartyResidency"
              :errors="errors"
              blank-option
            />
          </div>
        </div>
      </view-panel>
    </form>
  </div>
</template>

<script>
import ResponsibleParty from "models/responsible_party";
import AjaxService from "services/ajax_service";
import ViewPanel from "components/view_panel.vue";
import FormInput from "components/shared/form/form_input";
import FormSelect from "components/shared/form/form_select";
import selectOptionsToMapping from "utils/select_options_to_mapping";

export default {
  name: "edit-responsible-party-details",
  components: {
    ViewPanel,
    FormInput,
    FormSelect,
  },
  props: {
    rawParty: {
      type: Object,
      required: true,
    },
  },
  data() {
    const party = ResponsibleParty.deserialize(this.rawParty);
    const options = PSData.options;
    const routes = PSData.routes;
    const errors = {};

    return {
      party,
      options,
      routes,
      errors,
    };
  },
  computed: {
    responsiblePartyTypes() {
      return selectOptionsToMapping(this.options.responsiblePartyTypes || []);
    },
    responsiblePartyResidency() {
      return selectOptionsToMapping(this.options.responsiblePartyResidency || []);
    },
  },
  watch: {
    party: {
      deep: true,
      handler() {
        this.resetErrors();
      },
    },
  },
  methods: {
    updateParty() {
      const params = { responsible_party: this.party.toPlainObject({ toSnakeCase: true }) };

      AjaxService.withNotifications()
        .patchJSON(this.routes.updateDetails, params)
        .then(data => {
          this.$emit("on-update", data);
        })
        .catch(({ response, data }) => {
          if (response.status === 422) {
            this.errors = data.errors;
          } else {
            Bugsnag.notify(new Error(`Unable to update responsible party: ${response.statusText}`));
          }
        });
    },
    resetErrors() {
      this.errors = {};
    },
    isPartyTypeDisabled(type) {
      return this.party.isIndividualWise && type !== "individual";
    },
  },
};
</script>
