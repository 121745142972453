import { SectionType } from "models/lender_form/fields";
import Section from "models/lender_form/section";

export default class PropertySection extends Section {
  constructor(fields) {
    super(SectionType.Property, fields);
  }

  update() {
    this.checkLoanPurpose();
    this.updateTotalCostBasis();
    this.checkMonthlyFields();

    this.setFieldDisabled("propertyUnitCount", !this.multifamilyProgramType);
    this.setFieldDisabled("propertyLotAcres", !this.multifamilyProgramType);

    super.update();
  }

  checkLoanPurpose() {
    this.setFieldDisabled(
      "propertyPurchaseDate",
      !this.multifamilyProgramType ||
        (!this.refinanceLoanPurpose && !this.cashOutRefinanceLoanPurpose)
    );
    this.setFieldDisabled(
      "paidHardConstructionCosts",
      !(this.refinanceLoanPurpose || this.cashOutRefinanceLoanPurpose)
    );
    if (this.acquisitionLoanPurpose) {
      this.fields.paidHardConstructionCosts.value = 0;
    }
  }

  checkMonthlyFields() {
    this.setFieldDisabled(
      "totalRentPerMonth",
      !this.rentalProgramType || this.isRentalAcquisitionAndNotLeased
    );
    this.setFieldDisabled("propertyMarketRentPerMonth", !this.rentalProgramType);
    this.setFieldDisabled("monthlyHoaDues", !this.rentalProgramType);
    this.setFieldDisabled("yearlyTaxesOwed", !this.rentalProgramType);
    this.setFieldDisabled("yearlyPremium", !this.rentalProgramType);
    this.setFieldDisabled("propertyLeased", !this.rentalProgramType);
  }

  updateTotalCostBasis() {
    if (!this.fields.totalCostBasis) {
      return;
    }
    const purchasePrice = this.fields.purchasePrice.convertedValue || 0;
    const paidHardConstructionCosts = this.fields.paidHardConstructionCosts.convertedValue || 0;

    this.fields.totalCostBasis.value = purchasePrice + paidHardConstructionCosts;
  }

  setFieldDisabled(fieldName, disabled) {
    const field = this.fields[fieldName];

    if (field) {
      field.disabled = disabled;
    }
  }

  get rentalLoanStrategies() {
    return PSData.rentalLoanStrategies || [];
  }

  get fixLoanStrategies() {
    return PSData.fixLoanStrategies || [];
  }

  get fixLoanStrategy() {
    return this.fixLoanStrategies.includes(this.fields.loanStrategyId.convertedValue);
  }

  get rentalLoanStrategy() {
    return this.rentalLoanStrategies.includes(this.fields.loanStrategyId.convertedValue);
  }

  get propertyLeased() {
    return this.fields.propertyLeased.value === "true";
  }

  get propertyNotLeased() {
    return this.fields.propertyLeased.value === "false";
  }

  get refinanceLoanPurpose() {
    return this.fields.loanPurpose.convertedValue === "refinance";
  }

  get acquisitionLoanPurpose() {
    return this.fields.loanPurpose.convertedValue === "acquisition";
  }

  get cashOutRefinanceLoanPurpose() {
    return this.fields.loanPurpose.convertedValue === "cash_out_refinance";
  }

  get isRefinanceLoanPurpose() {
    return this.refinanceLoanPurpose || this.cashOutRefinanceLoanPurpose;
  }

  get multifamilyRefinanceLoan() {
    return this.refinanceLoanPurpose && this.commercialPropertyType;
  }

  get isRentalAcquisitionAndNotLeased() {
    return this.rentalProgramType && this.acquisitionLoanPurpose && this.propertyNotLeased;
  }

  get rentalProgramType() {
    return this.fields.programType.value === "residential_rental_long_term";
  }

  get multifamilyProgramType() {
    return this.fields.programType.value === "commercial_multifamily";
  }
}
