<template>
  <div class="form-group">
    <label>{{ label }}</label>
    <select
      :name="name"
      :value="value"
      class="form-control"
      @input="$emit('filter', name, $event.target.value)"
    >
      <option v-for="(text, id) in $options.options" :key="id" :value="id">
        {{ text }}
      </option>
    </select>
  </div>
</template>

<script>
const OPTIONS = {
  today: "Today",
  range: "Date Range",
  all: "All",
};

export default {
  props: {
    name: {
      type: String,
      default: "dateType",
    },
    value: {
      type: String,
      default: Object.keys(OPTIONS)[0],
    },
    label: {
      type: String,
      default: "Show Date",
    },
  },
  options: OPTIONS,
};
</script>
