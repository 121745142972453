const Source = PS.Models.MarketData.Source;
const Calculator = PS.Models.MarketData.Charts.Calculator;

class HousingStock extends Source {
  constructor(dataKey) {
    super();

    this.dataKey = dataKey;
    this.data = {
      categories: {},
    };
  }

  get hasData() {
    return Object.values(this.data.categories).some(v => !Number.isNaN(parseFloat(v)));
  }
}

class HousingStockValue extends HousingStock {
  constructor(dataKey) {
    super(dataKey);

    this.medianHomeValue = undefined;
  }

  get name() {
    return "Housing Stock: Value";
  }

  success(data) {
    let housingStock = data.midas[this.dataKey] || {};

    this.data.categories = {
      valLess100Pct: housingStock.valLess100Pct,
      val100149Pct: Calculator.sumOrNull(housingStock.val100124Pct, housingStock.val125149Pct),
      val150199Pct: Calculator.sumOrNull(housingStock.val150174Pct, housingStock.val175199Pct),
      val200299Pct: housingStock.val200299Pct,
      val300499Pct: Calculator.sumOrNull(housingStock.val300399Pct, housingStock.val400499Pct),
      val500999Pct: Calculator.sumOrNull(housingStock.val500749Pct, housingStock.val750999Pct),
      valGreater1mPct: housingStock.valGreater1mPct,
    };

    this.medianHomeValue = housingStock.homeValue;

    super.success();
  }
}

class HousingStockAge extends HousingStock {
  constructor(dataKey) {
    super(dataKey);

    this.builtAfter2000Pct = undefined;
  }

  get name() {
    return "Housing Stock: Age";
  }

  success(data) {
    let housingStock = data.midas[this.dataKey] || {};

    this.data.categories = {
      builtBefore1940Pct: housingStock.builtBefore1940Pct,
      built19401949Pct: housingStock.built19401949Pct,
      built19501959Pct: housingStock.built19501959Pct,
      built19601969Pct: housingStock.built19601969Pct,
      built19701979Pct: housingStock.built19701979Pct,
      built19801989Pct: housingStock.built19801989Pct,
      built19901999Pct: housingStock.built19901999Pct,
      built20002009Pct: housingStock.built20002009Pct,
      builtAfter2009Pct: housingStock.builtAfter2009Pct,
    };

    this.builtAfter2000Pct = housingStock.builtAfter1999Pct;

    super.success();
  }
}

class HousingStockType extends HousingStock {
  constructor(dataKey) {
    super(dataKey);

    this.singleFamDetachedPct = undefined;
  }

  get name() {
    return "Housing Stock: Type";
  }

  success(data) {
    let housingStock = data.midas[this.dataKey] || {};

    this.data.categories = {
      singleFamDetachedPct: housingStock.singleFamDetachedPct,
      singleFamAttachedPct: housingStock.singleFamAttachedPct,
      multiFam2UnitPct: housingStock.multiFam2UnitPct,
      multiFam34UnitPct: housingStock.multiFam34UnitPct,
      multiFam59UnitPct: housingStock.multiFam59UnitPct,
      multiFam1019UnitPct: housingStock.multiFam1019UnitPct,
      multiFam2049UnitPct: housingStock.multiFam2049UnitPct,
      multiFam50PlusUnitPct: housingStock.multiFam50PlusUnitPct,
    };

    this.singleFamDetachedPct = housingStock.singleFamDetachedPct;

    super.success();
  }
}

class HousingStockSize extends HousingStock {
  constructor(dataKey) {
    super(dataKey);
  }

  get name() {
    return "Housing Stock: Size";
  }

  success(data) {
    let housingStock = data.midas[this.dataKey] || {};

    this.data.categories = {
      bedrooms0Pct: housingStock.bedrooms0Pct,
      bedrooms1Pct: housingStock.bedrooms1Pct,
      bedrooms2Pct: housingStock.bedrooms2Pct,
      bedrooms3Pct: housingStock.bedrooms3Pct,
      bedrooms4Pct: housingStock.bedrooms4Pct,
      bedrooms5PlusPct: housingStock.bedrooms5PlusPct,
    };

    super.success();
  }

  get medianCategory() {
    let sizes = [];

    return Object.keys(this.data.categories).find(k => {
      sizes.push(this.data.categories[k]);
      if (Calculator.sumOrNull(...sizes) >= 0.5) {
        return k;
      }
    });
  }
}

PS.Models.MarketData.Charts.HousingStockValue = HousingStockValue;
PS.Models.MarketData.Charts.HousingStockAge = HousingStockAge;
PS.Models.MarketData.Charts.HousingStockType = HousingStockType;
PS.Models.MarketData.Charts.HousingStockSize = HousingStockSize;
