import PropertyValuation from "./property_valuation";

export default class EmptyPropertyValuation extends PropertyValuation {
  constructor(options, properties = []) {
    var valuation = {
      commissioner: options.commissioners[0].name,
      designated: false,
      designatedArv: false,
      designatedDeferredMaintenance: false,
      propertyId: Array.isArray(properties) ? properties[0]?.id : null,
    };

    super(valuation);
  }

  update(valuation) {
    this.reset();
  }

  unDesignate() {
    this.designated = false;
  }

  unDesignateArv() {
    this.designatedArv = false;
  }

  unDesignateDeferredMaintenance() {
    this.designatedDeferredMaintenance = false;
  }

  designate() {
    this.designated = true;
  }

  designateArv() {
    this.designatedArv = true;
  }

  designateDeferredMaintenance() {
    this.designatedDeferredMaintenance = true;
  }
}
