<template>
  <div :class="mainWrapperClass" >
    <div v-if="editing && currency" class="form-group currency">
      <label v-if="label" class="editable-field-label control-label">
        {{ label }}
      </label>
      <div  :class="valueClass">
        <div  class="input-group-wrapper">
          <div :class="inputGroup">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              type="tel"
              @blur="handleInput"
              class="form-control"
              :disabled="disabled"
              v-mask:currency
              :value="inputDisplayValue" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="editing && !currency" class="form-group">
      <label v-if="label" class="editable-field-label control-label">
        {{ label }}
      </label>
      <div :class="inputGroupClass">
        <input
          type="text"
          @blur="handleInput"
          :class="inputClass"
          :disabled="disabled"
          :value="inputDisplayValue" />
        <div v-if="editing && percentage" class="input-group-append">
          <span class="input-group-text">%</span>
        </div>
      </div>
    </div>

    <label v-if="label && !editing" :class="labelClass" >
      {{ label }}
    </label>
    <div class="editable-field-value">
      <slot name="value" v-if="!editing">{{ displayValue }}</slot>
      <span v-if="!editing && percentage">%</span>
    </div>
  </div>
</template>

<script>
import toNumber from "lodash/toNumber";
import NumberFormatter from "models/formatters/number_formatter";

export default {
  name: "EditableField",
  props: {
    value: [String, Number, Function],
    label: String,
    className: String,
    currency: Boolean,
    percentage: Boolean,
    required: Boolean,
    format: {
      type: Boolean,
      default: () => true,
    },
    inputClasses: {
      type: Array,
      default: () => [],
    },
    callback: {
      type: Function,
      default: new Function(),
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainWrapperClass() {
      return { editing: this.editing, "editable-field-container": true };
    },
    labelClass() {
      return { [`${this.className || "loan-attribute"}-label`]: true, "control-label": true };
    },
    valueClass() {
      return `${this.className || "loan-attribute"}-value`;
    },
    inputClass() {
      const inputClasses = this.inputClasses.reduce((acc, c) => ({ ...acc, [c]: true }), {});
      return {
        "form-control": true,
        currency: this.currency,
        required: this.required,
        ...inputClasses,
      };
    },
    inputGroup() {
      return {
        "input-group": this.currency,
        "flex-nowrap": true,
        "editable-field-input-wrapper": true,
      };
    },
    inputGroupClass() {
      return this.percentage ? "input-group" : "";
    },
    resolvedValue() {
      return typeof this.value === "function" ? this.value() : this.value;
    },
    displayValue() {
      if (this.currency) return this.$options.filters.accounting(this.resolvedValue);
      return this.resolvedValue;
    },
    inputDisplayValue() {
      if (!this.format) return this.resolvedValue;

      if (this.currency) {
        return parseFloat(this.resolvedValue || 0).toFixed(2);
      }

      if (this.percentage) {
        return parseFloat(this.resolvedValue || 0).toFixed(3);
      }

      return this.resolvedValue;
    },
  },
  methods: {
    handleInput(e) {
      const value = this.currency ? this.coerceNumber(e.target.value) : e.target.value;
      return this.callback(value);
    },
    coerceNumber(amount) {
      return isNaN(amount) ? toNumber(amount.replace(/,/g, "")) : amount;
    },
  },
};
</script>
