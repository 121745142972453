const NumberFormatter = PS.Models.NumberFormatter;
const ValueFormatter = PS.Models.ValueFormatter;

const FORMAT = {
  NUMBER: "number",
  PERCENT: "percent",
  YES_NO: "yesNo",
  MONEY: "money",
};

function format(detail) {
  let value = detail.value;

  switch (detail.type) {
    case FORMAT.NUMBER:
      value = NumberFormatter.formatNumber(value, detail.precision, true);
      break;
    case FORMAT.PERCENT:
      value = NumberFormatter.formatPercent(value, detail.precision, true);
      break;
    case FORMAT.YES_NO:
      value = ValueFormatter.formatYesNoValue(detail.value);
      break;
    case FORMAT.MONEY:
      value = NumberFormatter.formatMoney(detail.value);
      break;
  }

  return value;
}

export default format;
