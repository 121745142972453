<template>
  <tr :data-path="offering.routes.batchDetails">
    <td>{{offering.batch.buyerName}}</td>
    <td>{{offering.batch.displayNumber}}</td>
    <td>{{batchStatus}}</td>
    <td>{{offering.stateText}}</td>
  </tr>
</template>
<script>
import { formatDate } from "filters";

export default {
  name: "institution-sales-tracking-search-row",
  props: {
    offering: Object,
  },
  computed: {
    batchStatus() {
      if (this.offering.batch.wireDate) {
        return `Wired - ${formatDate(this.offering.batch.wireDate)}`;
      }
      if (this.offering.batch.purchaseConfirmationDate) {
        return `Confirmed - ${formatDate(this.offering.batch.purchaseConfirmationDate)}`;
      }
      if (this.offering.batch.offerDate) {
        return `Offered - ${formatDate(this.offering.batch.offerDate)}`;
      }
      return `Open - ${formatDate(this.offering.batch.startDate)}`;
    },
  },
};
</script>
