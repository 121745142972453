<template>
  <div class="instant-search">
    <input
      v-model="searchValue"
      class="input instant-search-input"
      type="search"
      placeholder="Search for unsold open auction loans"
      @input="onInput"
    >
    <open-auction-search-results
      :search-query="searchQuery"
      :results="results"
      :loading="loading"
    />

    <open-auction-mark-as-sold-modal
      :show="showMarkAsSoldModal"
      :loan="selectedLoan"
      :sold-loans-url="soldLoansUrl"
      @loan-marked-as-sold="onLoanMarkedAsSold"
      @close="closeMarkAsSoldModal"
    ></open-auction-mark-as-sold-modal>
  </div>
</template>

<script>
import OpenAuctionSearchResults from "./search_results";
import OpenAuctionMarkAsSoldModal from "./mark_as_sold_modal";
import Ajax from "services/ajax_service";
import { debounce } from "lodash";
import GlobalBusService from "services/global_bus_service";

const MIN_SEARCH_QUERY_LENGTH = 3;
const DEBOUNCE_WAIT = 500;

export default {
  name: "open-auction-search",
  components: {
    OpenAuctionSearchResults,
    OpenAuctionMarkAsSoldModal,
  },
  props: {
    unsoldLoansUrl: {
      type: String,
      required: true,
    },
    soldLoansUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchValue: "",
      results: [],
      selectedLoan: {},
      loading: true,
      showMarkAsSoldModal: false,
    };
  },
  mounted() {
    GlobalBusService.$on("open-auction-open-mark-as-sold-modal", this.openMarkAsSoldModal);
  },
  computed: {
    queryUrl() {
      const searchQuery = encodeURIComponent(this.searchQuery);

      return `${this.unsoldLoansUrl}?query=${searchQuery}`;
    },
    searchQuery() {
      const searchValue = (this.searchValue || "").trim();

      return searchValue.length < MIN_SEARCH_QUERY_LENGTH ? "" : searchValue;
    },
  },
  methods: {
    onInput: debounce(function() {
      this.loadData();
    }, DEBOUNCE_WAIT),
    loadData: async function() {
      this.loading = true;

      if (!this.searchQuery) {
        return;
      }

      await this.$nextTick();

      this.results = await Ajax.getJSON(this.queryUrl);

      this.loading = false;
    },
    onLoanMarkedAsSold(loan) {
      this.searchValue = "";
    },
    openMarkAsSoldModal(loan) {
      this.selectedLoan = loan;
      this.showMarkAsSoldModal = true;
    },
    closeMarkAsSoldModal() {
      this.showMarkAsSoldModal = false;
      this.selectedLoan = {};
    },
  },
};
</script>
