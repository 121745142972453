<template>
  <tr>
    <td>
      <ul class="loan-block">
        <li>
          <a :href="workoutCaseLink" class="view-workout-case-link">Workout Case {{ workoutCase.caseNumber }}</a>
        </li>
        <li>
          <span class="create-date">Added {{ new Date(workoutCase.createdAt) | formatDate("MMMM DD, YYYY [at] h:mm A") }}</span>
        </li>
      </ul>
    </td>
    <td>
      {{ workoutCase.processCode }}
    </td>
    <td>
      <span :class="styleCauseValue">
        {{ getCauseText(workoutCase.foreclosureCauseId) }}
      </span>
    </td>
    <td>
      {{ displayValue(workoutCase.duration) }}
    </td>
    <td>
      <span :class="styleOutcomeValue">
        {{ getOutcomeText(workoutCase.foreclosureOutcomeId) }}
      </span>
    </td>
    <td class="actions">
      <a href="#delete" title="Delete workout case" @click.prevent.once="$emit('delete', workoutCase.id)">
        <i class="fas fa-trash-alt" />
      </a>
    </td>
  </tr>
</template>

<script>
export default {
  name: "workout-case-list-item",
  props: {
    causes: {
      type: Array,
      default: () => [],
    },
    outcomes: {
      type: Array,
      default: () => [],
    },
    workoutCase: {
      type: Object,
      required: true,
    },
  },
  computed: {
    styleCauseValue() {
      return {
        "no-status-state": !this.workoutCase.foreclosureCauseId,
      };
    },
    styleOutcomeValue() {
      return {
        "no-status-state": !this.workoutCase.foreclosureOutcomeId,
      };
    },
    workoutCaseLink() {
      return `asset_management/workout_cases/${this.workoutCase.caseNumber}`;
    },
  },
  methods: {
    displayValue: v => (v == undefined ? "-" : v),
    findElement(array, id) {
      return array.find(t => t.id == id);
    },
    getCauseText(id) {
      const selectedElem = this.findElement(this.causes, id);
      return selectedElem ? selectedElem.text : "No Cause";
    },
    getOutcomeText(id) {
      const selectedElem = this.findElement(this.outcomes, id);
      return selectedElem ? selectedElem.text : "No Outcome";
    },
  },
};
</script>
