import enumerize from "../utils/enumerize";

const LoanStrategy = enumerize({
  bridge: {
    value: "bridge",
    title: "Bridge - (Construction Budget < $10k)",
  },
  lightConstruction: {
    value: "light_construction",
    title: "Light Construction - (Budget ≥ 10k and < 50% Loan Amount)",
  },
  heavyConstruction: {
    value: "heavy_construction",
    title: "Heavy Construction - (Budget ≥ 10k and ≥ 50% Loan Amount)",
  },
  flexProduct: {
    value: "flex_product",
    title: "Flex Product",
  },
});

export default LoanStrategy;
