<template>
  <div v-if="!!columns" class="report-loans" :class="{ 'report-loans-settings-opened': settingsOpened }">
    <div class="loan-table-header" :class="headerClassName(group)">
      <div class="loan-table-header-title">
        <span class="status">
          {{ groupName(group) }}
        </span>
        <span class="total-allocation">
          {{ totalAllocation | money(0) }}
        </span>
        <span class="average-allocation">
          ({{ visibleLoans.length | pluralize("loan") }}, avg {{ averageAllocation | money(0) }})
        </span>
        <user-settings
          v-if="applySettings"
          :name="userSettingsKey"
          @open="settingsOpened = true"
          @close="settingsOpened = false"
        />
      </div>
      <div v-if="enableIntervalFilter" class="loan-table-header-controls">
        <hotlist-interval-filter v-bind="intervalFilter" />
      </div>
    </div>
    <div v-if="loadingState" class="loans-list-content">
      <div class="ps-loader" />
    </div>
    <div v-if="completeState && visibleLoans.length > 0" class="loans-list-content">
      <table class="table loans-table hotlist-table">
        <thead>
          <tr>
            <th v-if="columns.loanStatus.show" class="loan-status-wrapper">
              {{ columns.loanStatus.title }}
            </th>
            <hotlist-th
              v-if="columns.age.show"
              :active="isSortedBy('daysInCurrentStatus')"
              :direction="getDirection('daysInCurrentStatus')"
              class="loan-age"
              field-type="number"
              field="daysInCurrentStatus"
              @sort="handleSort"
            >
              Age/
              <span>Last</span>
            </hotlist-th>
            <hotlist-th
              v-if="columns.analyst.show"
              :active="isSortedBy('analyst.name')"
              :direction="getDirection('analyst.name')"
              class="loan-analyst"
              field-type="string"
              field="analyst.name"
              @sort="handleSort"
            >
              {{ columns.analyst.title }}
            </hotlist-th>
            <hotlist-th
              v-if="columns.qcApprover.show"
              :active="isSortedBy('qcApprover.name')"
              :direction="getDirection('qcApprover.name')"
              class="loan-analyst"
              field-type="string"
              field="qcApprover.name"
              @sort="handleSort"
            >
              {{ columns.qcApprover.title }}
            </hotlist-th>
            <hotlist-th
              v-if="columns.loanId.show"
              :active="isSortedBy('lender.name')"
              :direction="getDirection('lender.name')"
              class="loan-lender"
              field-type="string"
              field="lender.name"
              @sort="handleSort"
            >
              Lender/
              <span>Loan ID</span>
            </hotlist-th>
            <th v-if="columns.address.show" class="loan-address">
              {{ columns.address.title }}
            </th>
            <hotlist-th
              v-if="columns.propertyType.show"
              :active="isSortedBy('propertyType')"
              :direction="getDirection('propertyType')"
              class="loan-property-type"
              field-type="string"
              field="propertyType"
              @sort="handleSort"
            >
              {{ columns.propertyType.title }}
            </hotlist-th>
            <th v-if="columns.rate.show" class="loan-rate">
              Note/
              <span>Investor</span>
            </th>
            <th v-if="columns.ltvArv.show" class="loan-ltv">
              LTV/
              <span>ARV %</span>
            </th>
            <th v-if="columns.term.show" class="loan-term">
              Term/
              <span>Ext</span>
            </th>
            <hotlist-th
               v-if="columns.psAllocation.show"
              :active="isSortedBy('peerstreetAllocation')"
              :direction="getDirection('peerstreetAllocation')"
              class="loan-amount"
              field-type="number"
              field="peerstreetAllocation"
              @sort="handleSort"
            >
              PS Allocation/
              <span>B-Piece</span>
            </hotlist-th>
            <hotlist-th
              v-if="columns.noteStatus.show"
              :active="isSortedBy('lenderNoteStatusText')"
              :direction="getDirection('lenderNoteStatusText')"
              class="loan-note-status"
              field-type="string"
              field="lenderNoteStatusText"
              @sort="handleSort"
            >
              Note Status/
              <span>Origin. Date</span>
            </hotlist-th>
            <th v-if="columns.docs.show" class="loan-docs">
              {{ columns.docs.title }}
            </th>
            <th v-if="columns.valuation.show" class="loan-valuation">
              {{ columns.valuation.title }}
            </th>
            <hotlist-th
              v-if="columns.purchaseDate.show"
              :active="isSortedBy('initialPurchaseDate')"
              :direction="getDirection('initialPurchaseDate')"
              class="loan-purchase-date"
              field-type="date"
              field="initialPurchaseDate"
              @sort="handleSort"
            >
              {{ columns.purchaseDate.title }}
            </hotlist-th>
            <hotlist-th
              v-if="columns.forecastPurchaseDate.show"
              :active="isSortedBy('forecastPurchaseDate')"
              :direction="getDirection('forecastPurchaseDate')"
              class="loan-forecast-purchase-date"
              field-type="date"
              field="forecastPurchaseDate"
              @sort="handleSort"
            >
              {{ columns.forecastPurchaseDate.title }}
            </hotlist-th>
            <hotlist-th
              v-if="columns.targetPurchaseDate.show"
              :active="isSortedBy('targetPurchaseDate')"
              :direction="getDirection('targetPurchaseDate')"
              class="loan-target-date"
              field-type="date"
              field="targetPurchaseDate"
              @sort="handleSort"
            >
              {{ columns.targetPurchaseDate.title }}
            </hotlist-th>
            <hotlist-th
              v-if="columns.qcApprovalTask.show"
              :active="isSortedBy('qcApprovalTaskText')"
              :direction="getDirection('qcApprovalTaskText')"
              class="loan-qc-approval-task"
              field-type="string"
              field="qcApprovalTaskText"
              @sort="handleSort"
            >
              {{ columns.qcApprovalTask.title }}
            </hotlist-th>
            <th v-if="columns.notes.show">
              {{ columns.notes.title }}
            </th>
            <hotlist-th
              v-if="columns.openInternalTasks.show"
              :active="isSortedBy('openInternalTasks')"
              :direction="getDirection('openInternalTasks')"
              field="openInternalTasks"
              field-type="number"
              @sort="handleSort"
            >
              {{ columns.openInternalTasks.title }}
            </hotlist-th>
            <hotlist-th
              v-if="columns.openLenderTasks.show"
              :active="isSortedBy('openLenderTasks')"
              :direction="getDirection('openLenderTasks')"
              field="openLenderTasks"
              field-type="number"
              @sort="handleSort"
            >
              {{ columns.openLenderTasks.title }}
            </hotlist-th>
            <hotlist-th
              v-if="columns.currentProgressUpdate.show"
              :active="isSortedBy('currentProgressUpdate.shortDate')"
              :direction="getDirection('currentProgressUpdate.shortDate')"
              field-type="date"
              field="currentProgressUpdate.shortDate"
              @sort="handleSort"
            >
              {{ columns.currentProgressUpdate.title }}
            </hotlist-th>
            <hotlist-th
              v-if="columns.qcDate.show"
              :active="isSortedBy('qcData')"
              :direction="getDirection('qcData')"
              field-type="qcData"
              field="qcData"
              @sort="handleSort"
            >
              QA/
              <span>Date</span>
            </hotlist-th>
            <hotlist-th
              v-if="columns.openQcTasks.show"
              :active="isSortedBy('openQcTasks')"
              :direction="getDirection('openQcTasks')"
              field="openQcTasks"
              field-type="number"
              @sort="handleSort"
            >
              {{ columns.openQcTasks.title }}
            </hotlist-th>
            <hotlist-th
              v-if="columns.lenderTargetPurchaseDate.show"
              :active="isSortedBy('lenderTargetPurchaseDate')"
              :direction="getDirection('lenderTargetPurchaseDate')"
              field="lenderTargetPurchaseDate"
              class="loan-lender-target-purchase-date"
              field-type="date"
              @sort="handleSort"
            >
              {{ columns.lenderTargetPurchaseDate.title }}
            </hotlist-th>
            <th />
          </tr>
        </thead>
        <tbody class="loans-list-table-body" :data-group="group">
          <report-loan
            v-for="loan in visibleLoans"
            :key="loan.id"
            :columns="columns"
            :group="group"
            :loan="loan"
            :lender-block-status-displayable="lenderBlockStatusDisplayable"
            :broker-tag-displayable="brokerTagDisplayable"
            :sort-column="sort"
            @edit-notes="openEditNotesModal"
          />
        </tbody>
      </table>
      <edit-notes-modal
        :show="showEditNotesModal"
        :loan="loanToEdit"
        @close="closeEditNotesModal"
        @save="savePeerstreetNotes"
      />
    </div>
  </div>
</template>

<script>
import { LOADING_STATUSES } from "models/reports/loan";
import ReportLoansService from "services/report_loans_service";
import UserSettingsService from "services/user_settings/user_settings_service";
import { SETTINGS_KEYS } from "services/user_settings/config";
import { DEFAULT_COLUMNS_CONFIG } from "services/user_settings/hotlist_config";
import UserSettings from "components/user_settings";
import HotlistIntervalFilter from "./hotlist/interval_filter";
import HotlistTh from "./hotlist/th";
import get from "lodash/get";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import EditNotesModal from "./edit_notes_modal";

const comparators = {
  number: (a, b, direction) => {
    if (isNull(a) || isUndefined(a)) return 1;
    if (isNull(b) || isUndefined(b)) return -1;

    return a < b ? direction * -1 : direction;
  },
  string: (a, b, direction) => {
    if (!a) return 1;
    if (!b) return -1;

    return a < b ? direction * -1 : direction;
  },
  date: (a, b, direction) => {
    a = a ? new Date(a).getTime() : -Infinity;
    b = b ? new Date(b).getTime() : -Infinity;

    return a - b < 0 ? direction * -1 : direction;
  },
  qcData: (a, b, direction) => {
    const getDate = function(qcItem) {
      if (qcItem && !qcItem.isCompleted) {
        return qcItem.expectedAt
          ? -new Date(qcItem.expectedAt).getTime()
          : -Number.MAX_SAFE_INTEGER;
      } else {
        return -Infinity;
      }
    };

    if (isNull(a) || isUndefined(a)) return direction;
    if (isNull(b) || isUndefined(b)) return -1 * direction;

    const diligence_a = getDate(a.diligence);
    const diligence_b = getDate(b.diligence);
    const dap_a = getDate(a.dap);
    const dap_b = getDate(b.dap);
    a = [diligence_a, dap_a];
    b = [diligence_b, dap_b];

    return Math.max(...b) < Math.max(...a) ? direction * -1 : direction;
  },
};

export default Vue.component("report-loans", {
  components: { HotlistTh, HotlistIntervalFilter, UserSettings, EditNotesModal },
  props: {
    loans: Array,
    group: String,
    initialSort: Object,
    intervalFilter: Object,
    enableIntervalFilter: Boolean,
    state: {
      type: String,
      default() {
        return LOADING_STATUSES.complete;
      },
    },
    lenderBlockStatusDisplayable: {
      type: Boolean,
      default: false,
    },
    brokerTagDisplayable: {
      type: Boolean,
      default: false,
    },
    applySettings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sort: this.initialSort,
      messageBus: PS.Services.MessageBusService.hotlistBus,
      columns: null,
      settingsOpened: false,
      showEditNotesModal: false,
      loanToEdit: {},
    };
  },
  mounted() {
    if (this.applySettings) {
      UserSettingsService.subscribe(this.updateColumns);
      UserSettingsService.loadSettings();
    } else {
      this.updateColumns(UserSettingsService.defaultSettings);
    }
  },
  beforeDestroy() {
    if (this.applySettings) {
      UserSettingsService.unsubscribe(this.updateColumns);
    }
  },
  computed: {
    sortedLoans() {
      if (!this.sort) {
        return this.loans;
      }

      const { direction, field, fieldType } = this.sort;
      const comparator = comparators[fieldType];
      return [...this.loans].sort((a, b) => comparator(get(a, field), get(b, field), direction));
    },
    visibleLoans() {
      return ReportLoansService.visibleLoans(this.sortedLoans);
    },
    totalAllocation() {
      return ReportLoansService.totalAllocation(this.visibleLoans);
    },
    averageAllocation() {
      return ReportLoansService.averageAllocation(this.visibleLoans, this.totalAllocation);
    },
    currentMonthName() {
      const date = new Date();
      return PS.Services.DateService.currentMonthName(date);
    },
    loadingState() {
      return this.state === LOADING_STATUSES.loading;
    },
    completeState() {
      return this.state === LOADING_STATUSES.complete;
    },
    userSettingsKey() {
      return SETTINGS_KEYS.hotlist[this.group];
    },
  },
  methods: {
    updateColumns(settings) {
      const componentSettings = settings[this.userSettingsKey];

      this.columns = componentSettings ? componentSettings.items : DEFAULT_COLUMNS_CONFIG;
    },
    handleSort(direction, field, fieldType) {
      this.sort = { direction, field, fieldType };
    },
    isSortedBy(field) {
      return this.sort && this.sort.field === field;
    },
    getDirection(field) {
      if (this.isSortedBy(field)) {
        return this.sort.direction;
      }

      return 0;
    },
    groupName(group) {
      switch (group) {
        case "rejected":
          return "Declined";
        case "canceled":
          return "Withdrawn";
        case "diligencing":
          return "In Diligence";
        case "uploadFiles":
          return "Upload Files";
        case "paidOff":
          return "Paid Off";
        default:
          return group;
      }
    },
    headerClassName(group) {
      switch (group) {
        case "uploadFiles":
          return "upload-files-table-header";
        case "paidOff":
          return "paid-off-table-header";
        default:
          return group + "-table-header";
      }
    },
    openEditNotesModal(loan) {
      this.loanToEdit = Object.assign({}, loan);
      this.showEditNotesModal = true;
    },
    closeEditNotesModal() {
      this.showEditNotesModal = false;
    },
    savePeerstreetNotes(notes, new_loan) {
      const index = this.loans.findIndex(loan => loan.id === new_loan.id);
      Vue.set(this.loans[index], "peerstreetNotes", notes);
      this.closeEditNotesModal();
    },
  },
});
</script>
