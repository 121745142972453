export default {
  data() {
    const decoratedCollection = this.collection.map(item => {
      return Object.assign(item, { checked: false });
    });

    return {
      allSelected: false,
      isPartiallySelected: false,
      show: true,
      inFlight: false,
      reviewFlagFilterEnabled: false,
      filteredCollection: decoratedCollection,
    };
  },
  methods: {
    toggleReviewFlagFilter(value) {
      this.collection
        .filter(item => item.reviewFlags.length === 0)
        .forEach(item => (item.checked = value));

      this.toggleItem();
      this.reviewFlagFilterEnabled = value;
    },
    toggleAll(value) {
      this.collection.forEach(item => this.$set(item, "checked", value));
      this.isPartiallySelected = this.calculatePartialSelection();
    },
    toggleItem() {
      const selectedItemsCount = this.checkedItems().length;

      if (this.count == 0) {
        this.allSelected = false;
      } else {
        this.allSelected = selectedItemsCount === this.count;
      }

      this.isPartiallySelected = this.calculatePartialSelection();
    },
    checkedItems() {
      return this.collection.filter(item => item.checked === true);
    },
    moveToReadyDisabled() {
      return this.checkedItems().length === 0;
    },
    calculatePartialSelection() {
      const checkedItemsLength = this.checkedItems().length;

      if (this.count === 0 || checkedItemsLength === 0) return false;

      return checkedItemsLength > 0 && checkedItemsLength !== this.count;
    },
    isNoneSelected() {
      return this.checkedItems().length === 0;
    },
  },
  computed: {
    count() {
      return this.collection.length;
    },
  },
};
