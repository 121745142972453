<template>
  <roles-table v-if="showRoleDetails" :party="party" @on-edit="editRoles" />
  <roles-form
    v-else
    :raw-party="party"
    @on-update="updateParty"
    @on-cancel="cancelEditing"
  />
</template>

<script>
import RolesTable from "./role_details/table";
import RolesForm from "./role_details/form";

export default {
  name: "responsible-roles-details",
  components: { RolesTable, RolesForm },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
    };
  },
  computed: {
    showRoleDetails() {
      return !this.editMode;
    },
  },
  methods: {
    editRoles() {
      this.editMode = true;
    },
    cancelEditing() {
      this.editMode = false;
    },
    updateParty(data) {
      this.$emit("party-updated", data);
      this.cancelEditing();
    },
  },
};
</script>
