import Field from "./field";

export default Field.extend({
  methods: {
    saveValue() {
      const saveFn = this.field.formula ? this.saveValueImmediately : this.saveValueDebounced;
      saveFn();
    },
  },
});
