<template>
  <diligence-document-field :document="document" :field="field">
    <div class="col-5">
      <div class="checkbox-control checkbox-control-inline js-checkbox-input">
        <input :id="field.id" v-model="field.value" type="checkbox" :disabled="disabled" />
        <label :for="field.id">
          <span class="control-toggle"></span>
        </label>
      </div>
    </div>
  </diligence-document-field>
</template>

<script>
import NonTextField from "./base/non_text_field";

const CheckboxField = NonTextField.extend({});

export default Vue.component("diligence-document-checkbox-field", CheckboxField);
</script>
