import CopyToClipboard from "components/copy_to_clipboard";
import MessageBusService from "services/message_bus_service";
import DocumentRules from "models/loan/rules/document_rules";
import LenderTagsMixin from "../../mixins/manage/lenders/tags";
import LoansDocumentsTab from "components/loans/documents/tab";
import ActivityFeedEventsSection from "components/activity_feed_events/manage/section";
import PiecesOfCollateral from "components/tags/pieces_of_collateral";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-documents-controller"),
    components: {
      CopyToClipboard,
      LoansDocumentsTab,
      ActivityFeedEventsSection,
      PiecesOfCollateral,
    },
    mixins: [LenderTagsMixin],
    data: {
      loan: PSData.loan || {},
      documents: PSData.documents || [],
      documentTypes: PSData.primaryDocumentTypes || [],
      loanDocumentTypes: PSData.loanDocumentTypes || [],
      messageBus: MessageBusService.loanBus,
      ruleBook: undefined,
      uploadSettings: PSData.uploadSettings,
    },
    mounted() {
      this.rulebook = new DocumentRules(this);
      this.messageBus.attachRulebook(this.rulebook);
    },
  });
});
