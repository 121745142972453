<template>
  <div class="note-field">
    <textarea v-model="text"></textarea>
    <div class="note-buttons">
      <input class="btn-action-cta" type="button" value="Save" @click="save" />
      <input class="btn-cancel-cta" type="button" value="Cancel" @click="cancel" />
    </div>
  </div>
</template>

<script>
export default Vue.component("edit-note", {
  props: {
    value: String,
  },
  data: function() {
    return {
      text: this.value,
      textWas: this.value,
    };
  },
  watch: {
    value() {
      this.text = this.value;
    },
    text() {
      this.$emit("input", this.text);
    },
  },
  methods: {
    cancel: function() {
      this.$emit("input", this.textWas);
      this.$emit("cancel-note", this.textWas);
    },
    save: function() {
      this.$emit("save-note", this.text);
    },
  },
});
</script>
