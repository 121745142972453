import find from "lodash/find";
import get from "lodash/get";

export const SlaStatus = Object.freeze({
  NOT_STARTED: "NOT_STARTED",
  ACTIVE: "ACTIVE",
  COMPLETED: "COMPLETED",
});

export const QUALIFICATION_SLA_NAME = "qualification_sla";
export const VALUATION_SLA_NAME = "valuation_sla";

const selectTask = (tasks, name) => find(tasks, { name });

export const selectTaskDeadline = (tasks, name) => {
  return get(selectTask(tasks, name), "expectedAt");
};

export const selectTaskCompleted = (tasks, name) => {
  const task = selectTask(tasks, name);
  return task ? !!get(task, "completedAt") : undefined;
};

export const getTasksStatus = tasks => {
  const qualification = selectTaskCompleted(tasks, QUALIFICATION_SLA_NAME);
  const valuation = selectTaskCompleted(tasks, VALUATION_SLA_NAME);

  switch (true) {
    case qualification === undefined && valuation === undefined:
      return SlaStatus.NOT_STARTED;

    case qualification === true && valuation === true:
      return SlaStatus.COMPLETED;

    default:
      return SlaStatus.ACTIVE;
  }
};

export const canCompleteTask = (tasks, name) => {
  const task = selectTask(tasks, name);
  return !!get(task, "canComplete", false);
};
