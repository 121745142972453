var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('params-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var params = ref.params;
var setParams = ref.setParams;
return [_c('data-provider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var total = ref.total;
var loading = ref.loading;
var displayMode = ref.displayMode;
var groupedItems = ref.groupedItems;
var legalOwners = ref.legalOwners;
var displayDetail = ref.displayDetail;
var displayOverview = ref.displayOverview;
var fetchData = ref.fetchData;
var approve = ref.approve;
var distribute = ref.distribute;
var invalidItems = ref.invalidItems;
return [(displayMode === 'overview')?_c('overview',{attrs:{"filters":params,"legal-owners":legalOwners,"grouped-items":groupedItems,"total":total,"loading":loading,"invalid-items":invalidItems},on:{"filter":setParams,"show":displayDetail,"approve":approve,"distribute":distribute}}):_c('Details',{attrs:{"filters":params,"legal-owners":legalOwners,"grouped-items":groupedItems},on:{"back":displayOverview}})]}}],null,true)},'data-provider',params,false))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }