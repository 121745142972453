<template>
  <modal-view :show="show" :on-close="onClose" class="base-modal in-transit-details-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          In Transit
          <div v-show="isLoading" class="ps-loader ps-loader-small"></div>
          <a class="modal-close-icon" @click.prevent="onClose" href="#close">&times;</a>
        </h4>
      </div>
      <div class="modal-body">
        <h5>{{account.name}}</h5>
        <table class="table">
          <thead>
            <tr>
              <th>Source</th>
              <th>Bank</th>
              <th>Loan</th>
              <th>Series</th>
              <th class="account-group-amount">Amount</th>
              <th>Submitted At</th>
              <th>Status</th>
              <th>Responded At</th>
              <th>Bank Response</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="wireRequest in wireRequests" :key="wireRequest.psId">
              <td>{{wireRequest.requestType}}</td>
              <td>Wells Fargo</td>
              <td>{{wireRequest.loan.psId}}</td>
              <td>{{wireRequest.series && wireRequest.series.psId}}</td>
              <td class="account-group-amount">{{wireRequest.requestAmount | money(2)}}</td>
              <td>{{wireRequest.submittedAt | formatTime}}</td>
              <td>{{wireRequest.status}}</td>
              <td>{{wireRequest.responseReceivedAt | formatTime}}</td>
              <td>{{wireRequest.responseComment}}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td></td>
              <td></td>
              <td></td>
              <td class="account-group-amount">{{wireRequestsTotal | money(2)}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button class="btn btn-action-cta" @click.prevent="onClose">
          Ok
        </button>
      </div>
    </div>
  </modal-view>
</template>

<script>
import AjaxService from "services/ajax_service";
import ModalView from "components/modal_view";
import moment from "moment";
import sum from "lodash/sum";

export default {
  name: "in-transit-details-modal",
  components: { ModalView },
  props: {
    account: Object,
    show: Boolean,
  },
  data: function() {
    return {
      routes: PSData.routes,
      wireRequests: [],
      isLoading: false,
    };
  },
  mounted: function() {
    this.getOpenWireRequests();
  },
  computed: {
    wireRequestsTotal() {
      return sum(this.wireRequests.map(wireRequest => parseFloat(wireRequest.requestAmount)));
    },
  },
  filters: {
    formatTime: function(value) {
      if (!value) {
        return value;
      }

      const date = moment(value);
      if (date.isValid()) {
        return date.format("MM/DD/YY hh:mmA");
      } else {
        return value;
      }
    },
  },
  methods: {
    getOpenWireRequests() {
      if (this.isLoading) return;
      this.isLoading = true;
      const url = this.account.getWireRequestsUrl(this.routes);

      return AjaxService.get(url).then(({ wireRequests }) => {
        this.wireRequests = [...wireRequests]
          .map(a => ({ ...a, submittedAt: new Date(a.submittedAt) }))
          .sort((a, b) => a.submittedAt - b.submittedAt);
        this.isLoading = false;
      });
    },

    onClose() {
      this.$emit("close");
    },
  },
};
</script>
