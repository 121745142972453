import Source from "models/market_data/source";

class Pipeline extends Source {
  constructor() {
    super();

    this._pipeline = {
      funded: [],
      not_funded: [],
      funding_into_origination: [],
      table_funding: [],
    };

    this._displayColumns = {
      funded: false,
      not_funded: false,
      funding_into_origination: false,
      table_funding: false,
    };

    this._totals = {};
  }

  get name() {
    return "Current Pipeline";
  }

  get data() {
    return this._pipeline;
  }

  get display() {
    return this._displayColumns;
  }

  get totals() {
    return this._totals;
  }

  get hasData() {
    return Object.values(this.data).some(data => {
      return data.length > 0;
    });
  }

  get chartMapping() {
    return ["evaluating", "qualified", "upload_files", "in_diligence", "purchasing"];
  }

  success(data) {
    let { loans, totals } = data;

    this._totals = totals;

    this.chartMapping.forEach((uwStatus, i) => {
      Object.keys(this._pipeline).forEach(noteStatus => {
        let value = loans[noteStatus] ? loans[noteStatus][uwStatus] || null : null;
        this._pipeline[noteStatus].push(value);

        this._displayColumns[noteStatus] = !!loans[noteStatus];
      });
    });

    super.success();
  }
}

export default Pipeline;
