import { defineStore } from "pinia";
import useLtvFicoGridStore from "./ltv_fico_grid";
import usePreviewStore from "./preview";
import UPDATE_METHODS from "../consts/update_methods";

const useCriteriaStore = defineStore("criteria", {
  state: () => ({
    underwritingStatuses: [],
    programTypes: [],
    rateChangeType: "flat_rate",
    flatRateAdjustment: null,
    updateMethod: UPDATE_METHODS.CRITERIA,
    uploadedCsvFile: {},
    isAssignedState: false,
  }),
  actions: {
    addProgramType(tag) {
      this.programTypes.push(tag);
      this.openPreviewAlert();
    },
    deleteProgramType(tag) {
      const index = this.programTypes.findIndex(t => t === tag);
      if (index !== -1) {
        this.programTypes.splice(index, 1);
        this.openPreviewAlert();
      }
    },
    addUnderwritingStatuses(tag) {
      this.underwritingStatuses.push(tag);
      this.openPreviewAlert();
    },
    deleteUnderwritingStatuses(tag) {
      const index = this.underwritingStatuses.findIndex(t => t === tag);
      if (index !== -1) {
        this.underwritingStatuses.splice(index, 1);
        this.openPreviewAlert();
      }
    },
    clearFlatRate() {
      this.flatRateAdjustment = null;
    },
    resetStateFlag() {
      this.isAssignedState = false;
    },
    resetState() {
      this.$reset();
    },
    openPreviewAlert() {
      usePreviewStore().showAlert();
    },
  },
  getters: {
    isCriteriaUpdateReady(state) {
      const ltvFicoGridStore = useLtvFicoGridStore();
      const criterias = state.programTypes.length > 0 && state.underwritingStatuses.length > 0;
      const rate =
        state.rateChangeType === "ltv_fico_grid"
          ? ltvFicoGridStore.hasAdjustments
          : state.flatRateAdjustment !== null && state.flatRateAdjustment !== "";

      return criterias && rate;
    },
    isCsvFileUpdateReady(state) {
      return (
        state.updateMethod === UPDATE_METHODS.CSV_UPLOAD && state.uploadedCsvFile.name !== undefined
      );
    },
    isGrid() {
      return this.rateChangeType === "ltv_fico_grid";
    },
    isUpdateMethodCriteria(state) {
      return state.updateMethod === UPDATE_METHODS.CRITERIA;
    },
    isUpdateMethodCsvUpload(state) {
      return state.updateMethod === UPDATE_METHODS.CSV_UPLOAD;
    },
    isReady(_state) {
      return this.isCsvFileUpdateReady || this.isCriteriaUpdateReady;
    },
  },
});

export default useCriteriaStore;
