<template>
  <div class="borrower-task-container" v-show="hasInvitedBorrowers">
    <h2>
      <span>Shared with:</span>
    </h2>
    <div class="borrower-task" v-for="(borrowerTask, index) in borrowerTasks" :key="index" >
      <span>
        {{ borrowerTask.borrower.email }}
      </span>
      <div :class="statusBadgeClass(borrowerTask)">
        {{ borrowerTask.state }}
      </div>
    </div>
  </div>
</template>
<script>
import AjaxService from "services/ajax_service";

export default {
  name: "borrower-task-statuses",
  props: {
    borrowerTasksPath: String,
  },
  data() {
    return {
      borrowerTasks: [],
    };
  },
  mounted() {
    AjaxService.getJSON(this.borrowerTasksPath).then(response => (this.borrowerTasks = response));
  },
  computed: {
    hasInvitedBorrowers() {
      return this.borrowerTasks.length > 0;
    },
  },
  methods: {
    statusBadgeClass(borrowerTask) {
      return `borrower-task-status-badge status-${borrowerTask.state}`;
    },
  },
};
</script>
<style scoped lang="scss">
.borrower-task-container {
  border-bottom: 1px solid var(--color-grey-16);
  padding: 0px 16px 24px;
}

.borrower-task-container h2 {
  color: var(--color-grey-86);
  font-size: 14px;
  font-weight: 600;
}

.borrower-task {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.borrower-task-status-badge {
  border-radius: 3px;
  background: var(--color-grey-16);
  color: var(--color-grey-60);
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.2;
  padding: 4px 8px;
  text-align: center;
  text-transform: uppercase;

  &.status-completed {
    background: var(--color-primary-green);
    color: var(--color-white);
  }
}
</style>
