<template>
  <div class="payoff-statement">
    <breadcrumb-navigation :items="breadcrumbItems"></breadcrumb-navigation>
    <div class="payoff-statement-header no-border">
      <div class="payoff-statement-title-container">
        <h3>Payoff Statement</h3>
        <span :class="statusClass">{{ statement.statusLabel }}</span>
        <span class="ps-loader ps-loader-small" v-if="isLoading"></span>
        <div class="payoff-statement-sub">
          <span>Version {{ statement.versionNumber }}</span>
          <span>• Updated {{ statement.lastUpdated }}</span>
        </div>
      </div>
      <payoff-statement-edit-button
        v-if="isEdit"
        @update="persist"
        @cancel="goBackToShowPage"
      />
    </div>
    <errors
      :errors="errors"
      :type="errorType"
    />
    <resale-statement-details
      :resale-statement="statement"
      :is-editable="true"
      :is-edit="isEdit"
      :payoff-reason-types="payoffReasonTypes"
      :statement-types="statementTypes"
      :fee-types="feeTypes"
      :routes="routes"
      @save-draft="saveDraft"
      @cancel="cancel"
      @submit="submitForReview"
      @sync-interest="syncInterest"
      @add-fee="addFee"
      @remove-fee="removeFee"
      @add-comment="addComment"
      @remove-comment="removeComment"
    />
  </div>
</template>

<script>
import PayoffStatement from "models/payoff_statement";
import PayoffStatementPolicy from "policies/payoff_statement_policy";
import get from "lodash/get";
import BreadcrumbNavigation from "components/shared/breadcrumb_navigation";
import ResaleStatementDetails from "components/manage/payoff_statement/resale/edit/details";
import PayoffStatementEditButton from "components/manage/payoff_statement/edit_button";
import selectHashOptionsToMapping from "utils/select_hash_options_to_mapping";
import Errors from "components/manage/payoff_statement/errors";
import { success } from "services/notification";
import FormMixin, { STATES } from "components/manage/payoff_statement/form_mixin";

const NOTIFICATIONS = {
  submit: {
    new: "Payoff Statement successfully created",
    edit: "Payoff Statement was successfully updated",
    duplicate: "Payoff Statement successfully created",
    onError: "Unable to generate Payoff Statement",
  },
  duplicate: "Payoff Statement successfully duplicated",
};

export default {
  name: "resale-statement-form",
  mixins: [FormMixin],
  components: {
    BreadcrumbNavigation,
    ResaleStatementDetails,
    PayoffStatementEditButton,
    Errors,
  },
  created() {
    if (this.currentState === STATES.duplicate) {
      success(NOTIFICATIONS.duplicate);
    }
  },
  data() {
    return {
      isLoading: false,
      created: false,
      currentState: this.state,
      isCompletedPopupShown: false,
      statement: new PayoffStatement(PSData.statement || {}),
      statementPolicy: new PayoffStatementPolicy(PSData.statementPolicy),
      payoffReasonTypes: selectHashOptionsToMapping(PSData.payoffReasonTypes, "name", "label"),
      statementTypes: selectHashOptionsToMapping(PSData.statementTypes, "name", "label"),
      feeTypes: selectHashOptionsToMapping(PSData.feeTypes, "name", "label"),
      errors: [],
      errorType: null,
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          name: "Servicing",
          link: this.routes.servicing,
        },
        {
          name: "Payoff Statement",
        },
      ];
    },
  },
  methods: {
    saveDraft() {
      this.makeRequest(this.routes["saveDraft"]);
    },
    submitForReview() {
      this.makeRequest(
        this.routes["submit"],
        "post",
        this.statement.toHash(),
        this.updateStatementAndPolicy
      );
    },
    updateStatementAndPolicy(result) {
      this.updateCreatedStatement(result);
      if (!this.errors && result.statementPolicy) {
        this.statementPolicy = new PayoffStatementPolicy(result.statementPolicy);
      }
    },
    notification(status) {
      return get(NOTIFICATIONS, status);
    },
  },
};
</script>
