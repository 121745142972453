class Lender {
  constructor(lender, categories, subscriptions) {
    this.id = lender.value;
    this.name = lender.name;
    this.subscriptions = [];

    (categories || []).forEach(category => {
      this.subscriptions.push(
        new PS.Models.EmailSubscription.Subscription(this.id, category.value, subscriptions)
      );
    });
  }

  reset() {
    this.subscriptions.forEach(category => {
      category.reset();
    });
  }
}

window.PS.Models.EmailSubscription.Lender = Lender;
