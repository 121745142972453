<template>
  <validation-provider
    v-slot="{ errors, valid, validate }"
    name="Sale Date"
    rules="required"
    slim
  >
    <div class="form-group">
      <label>Sale Date</label>
      <input
        v-model="innerValue"
        v-datepicker
        v-mask:date
        class="date-picker form-control"
        :class="validationClass(valid)"
        type="text"
        name="saleDate"
        :disabled="disabled"
      >

      <div v-if="valid === false" class="invalid-feedback">
        {{ errors.map(({ msg }) => msg).join(", ") }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import moment from "moment";
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      default: moment().format("MM/DD/YYYY"),
    },
    disabled: Boolean,
  },
  data: () => ({ innerValue: "" }),
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.innerValue = val;
      },
    },
    innerValue: {
      immediate: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    validationClass: valid => (valid === null ? null : valid ? "is-valid" : "is-invalid"),
  },
};
</script>
