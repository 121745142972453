const Charts = PS.Models.MarketData.Charts;
const MarketDataSource = PS.Models.MarketData.Source;
const MultiSource = PS.Models.MarketData.Charts.MultiSource;
const Calculator = PS.Models.MarketData.Charts.Calculator;

class Source extends MarketDataSource {
  constructor() {
    super();

    this.msaTimeSeries = [];
  }

  get name() {
    return "MSA Risk of Decline";
  }

  success(data) {
    this.msaTimeSeries = data.msaTimeSeries;

    super.success();
  }

  get sources() {
    return [new Charts.Midas("hcMsa", this.msaTimeSeries, "month", "risk")];
  }
}

class RiskOfDecline extends MultiSource {
  constructor() {
    super();

    this._sources = {
      midas: new Source(),
    };
  }

  get name() {
    return "MSA Risk Of Decline";
  }

  get whatThisMeans() {
    if (this.isSuccessful && this.mostRecent !== undefined) {
      switch (true) {
        case this.mostRecent >= 70.01:
          return "Extremely High Risk";

        case this.mostRecent >= 50.01:
          return "High Low";

        case this.mostRecent >= 30.01:
          return "Modest Low";

        case this.mostRecent >= 15.01:
          return "Low Risk";

        default:
          return "Extremely Low Risk";
      }
    }
  }

  get mostRecent() {
    return Calculator.mostRecent(this, "hcMsa") * 100;
  }

  get oneYearAgo() {
    const monthsInYear = 12;

    return Calculator.valueFromEnd(this, "hcMsa", monthsInYear) * 100;
  }
}

PS.Models.MarketData.Charts.RiskOfDecline = RiskOfDecline;
