<template>
  <div>
    <slot
      name="filters"
      :loading="isSearching"
      :filters="filters"
      :handle-filter="filterBy"
    />
    <div class="mb-5">
      <template
        v-if="hasTableSlot"
      >
        <slot
          name="table"
          :loading="isSearching"
          :loans="loans"
          :filters="filters"
        />
      </template>
      <template v-else>
        <loans-table :loading="isSearching" :loans="loans" />
      </template>
    </div>
    <div v-if="showLoadMoreButton" class="pagination mb-5">
      <base-button size="large">
        Load 100 More
      </base-button>
    </div>
  </div>
</template>

<script>
import LoansTable from "./table";
import AjaxService, { formatParams, ERROR_MESSAGE } from "services/ajax_service";
import { objectAndValuesToSnakeCase } from "utils/object_to_snake_case";
import sortBy from "lodash/sortBy";
import isNil from "lodash/isNil";

export default {
  components: {
    LoansTable,
  },
  props: {
    defaultFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filters: this.defaultFilters,
      loans: [],
      errors: [],
      loansTotal: 0,
      isSearching: false,
    };
  },
  computed: {
    showLoadMoreButton() {
      return false;
    },
    loansCount() {
      return this.loans.length;
    },
    routes: () => PSData.routes,
    hasTableSlot() {
      return !isNil(this.$scopedSlots.table);
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.search();
      },
    },
  },
  created() {
    this.search();
  },
  methods: {
    filterBy(param, value) {
      this.$set(this.filters, param, value);
    },
    handleResponse(response) {
      this.loans = response.loans.map(l => ({
        ...l,
        workflows: sortBy(l.workflows, "label"),
      }));
      this.loansTotal = response.available;
    },
    async search() {
      const filterParams = formatParams(objectAndValuesToSnakeCase(this.filters));
      const url = `${this.routes.loanPortfolio}.json?${filterParams}`;
      this.isSearching = true;
      try {
        const response = await AjaxService.getJSON(url);
        this.handleResponse(response);
      } catch ({ data, response }) {
        if (Array.isArray(data.errors)) {
          this.errors = data.errors.join(". ");
        } else {
          this.errors = [ERROR_MESSAGE];
        }
      } finally {
        this.isSearching = false;
      }
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  justify-content: center;
}
</style>
