import moment from "moment";
import {
  selectTaskDeadline,
  selectTaskCompleted,
  getTasksStatus,
  SlaStatus,
  QUALIFICATION_SLA_NAME,
  VALUATION_SLA_NAME,
} from "collections/manage/sla";

export const SortBy = Object.freeze({
  SLA: "SLA",
  ForecastDate: "ForecastDate",
  LoanStatus: "LoanStatus",
  OpenTasks: "OpenTasks",
});

const StatusOrder = Object.freeze({
  wired: 1,
  purchasing: 2,
  diligenced: 3,
  in_diligence: 4,
  diligencing: 5,
  upload_files: 6,
  qualified: 7,
  evaluating: 8,
  review: 9,
  draft: 10,
});

const SlaSortOrder = Object.freeze({
  [SlaStatus.ACTIVE]: 0,
  [SlaStatus.NOT_STARTED]: 1,
  [SlaStatus.COMPLETED]: 2,
});

export const detectMinDeadline = loan => {
  const slaDeadlines = [
    {
      completed: selectTaskCompleted(loan.tasks, VALUATION_SLA_NAME),
      deadline: selectTaskDeadline(loan.tasks, VALUATION_SLA_NAME),
    },
    {
      completed: selectTaskCompleted(loan.tasks, QUALIFICATION_SLA_NAME),
      deadline: selectTaskDeadline(loan.tasks, QUALIFICATION_SLA_NAME),
    },
  ]
    .filter(task => {
      return !task.completed;
    })
    .map(task => {
      return task.deadline ? moment(task.deadline).unix() : Infinity;
    });

  return Math.min(...slaDeadlines);
};

export const prepareLoansForDisplay = loans => {
  return [...loans].map(loan => {
    let followUpWarning = false;
    if (loan.currentProgressUpdate) {
      const followUpDate = moment(loan.currentProgressUpdate.followUpDate, "MM/DD/YYYY");
      followUpWarning = followUpDate.diff(moment(), "days", true) <= 0;
    }

    loan.updatingAnalyst = false;
    loan.updatingProcessor = false;
    loan.shown = true;
    loan.slaStatus = getTasksStatus(loan.tasks);
    loan.slaDeadline = detectMinDeadline(loan);
    loan.followUpWarning = followUpWarning;

    return loan;
  });
};

export const actionOnAnalystItemSize = loans => {
  return loans.filter(loan => loan.attentionRequired).length;
};

export const activeSlaItemSize = loans => {
  return loans.filter(loan => loan.slaStatus === SlaStatus.ACTIVE).length;
};

export const followUpsItemSize = loans => {
  return loans.filter(loan => loan.followUpWarning === true).length;
};

export const openTasksSize = loans => {
  return loans.filter(loan => loan.openTasksCount > 0).length;
};

const chooseOrderDirection = (realOrderBy, expectedOrderBy, orderWay) => {
  if (realOrderBy === expectedOrderBy) {
    return orderWay;
  } else {
    return 0;
  }
};

export const orderDirectionByLoanStatus = (orderBy, orderWay) => {
  return chooseOrderDirection(orderBy, SortBy.LoanStatus, orderWay);
};

export const orderDirectionBySLA = (orderBy, orderWay) => {
  return chooseOrderDirection(orderBy, SortBy.SLA, orderWay);
};

export const orderDirectionByForecastDate = (orderBy, orderWay) => {
  return chooseOrderDirection(orderBy, SortBy.ForecastDate, orderWay);
};

export const orderDirectionByOpenTasks = (orderBy, orderWay) => {
  return chooseOrderDirection(orderBy, SortBy.OpenTasks, orderWay);
};

export const detectLoanVisibility = (
  loans,
  { actionOnAnalyst, activeSla, followUpsRequired, openTasks },
  onLoanUpdate
) => {
  if (loans.length === 0) {
    return;
  }

  loans.forEach((loan, index) => {
    const showByAttentionRequired = actionOnAnalyst ? loan.attentionRequired === true : true;
    const showByActiveSla = activeSla ? loan.slaStatus === SlaStatus.ACTIVE : true;
    const showByFollowUps = followUpsRequired ? loan.followUpWarning === true : true;
    const showByOpenTasks = openTasks ? loan.openTasksCount > 0 : true;
    const shown = showByAttentionRequired && showByActiveSla && showByFollowUps && showByOpenTasks;

    if (loan.shown !== shown) {
      loan.shown = shown;
      onLoanUpdate(index, loan);
    }
  });
};

const pickLoansOrder = (loanA, loanB, order) => {
  return order === 1 ? [loanA, loanB] : [loanB, loanA];
};

const keepConsistentSorting = (order, loanA, loanB) => {
  if (order === 0) {
    return loanA.statusChangedAt - loanB.statusChangedAt;
  } else {
    return order;
  }
};

export const sortByLoanStatus = (loans, order) => {
  return loans.sort((loanA, loanB) => {
    [loanA, loanB] = pickLoansOrder(loanA, loanB, order);

    const expectedOrder =
      (StatusOrder[loanA.purchasedGroup] || Infinity) -
      (StatusOrder[loanB.purchasedGroup] || Infinity);

    return keepConsistentSorting(expectedOrder, loanA, loanB);
  });
};

export const sortBySLA = (loans, order) => {
  return loans.sort((loanA, loanB) => {
    [loanA, loanB] = pickLoansOrder(loanA, loanB, order);

    const orderByStatus = SlaSortOrder[loanA.slaStatus] - SlaSortOrder[loanB.slaStatus];
    if (orderByStatus === 0) {
      return keepConsistentSorting(loanA.slaDeadline - loanB.slaDeadline, loanA, loanB);
    } else {
      return orderByStatus;
    }
  });
};

export const sortByForecastDate = (loans, order) => {
  return loans.sort((loanA, loanB) => {
    [loanA, loanB] = pickLoansOrder(loanA, loanB, order);

    const expectedOrder =
      (loanA.forecastPurchaseDateAt || Infinity) - (loanB.forecastPurchaseDateAt || Infinity);
    return keepConsistentSorting(expectedOrder, loanA, loanB);
  });
};

export const sortByOpenTasks = (loans, order) => {
  return loans.sort((loanA, loanB) => {
    [loanA, loanB] = pickLoansOrder(loanA, loanB, order);

    const expectedOrder = loanB.openTasksCount - loanA.openTasksCount;
    return keepConsistentSorting(expectedOrder, loanA, loanB);
  });
};
