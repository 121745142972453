class TextHighlighter {
  // Splits text to phrases based on required substitutions
  //
  // For example we have text "Hi my name is Bender" and want to highlight "my" and "Bender"
  // we should create 2 substitutions in the following format: {offset: , length: }
  // where offset: is position of first char we want to highlight and length is a total amount
  // of chars.
  // Final call will look like: splitToPhrases("Hi my name is Bender",
  //                                           [{offset: 0, length: 2}, {offset: 14, length: 6}])
  // And as a result you will receive array of phrases:
  // [
  //   {text: 'Hi ',        highlighted: false},
  //   {text: 'my',         highlighted: true},
  //   {text: ' name is ',  highlighted: false},
  //   {text: 'Bender',     highlighted: true},
  // ]
  //
  static splitToPhrases(text, substitutions) {
    if (substitutions.length === 0) {
      return [{ text: text, highlighted: false }];
    }

    let matches = [];
    let head = 0;

    substitutions.forEach(sub => {
      const start = sub.offset;
      const end = start + sub.length;

      if (start > head) {
        matches.push({ text: text.slice(head, start), highlighted: false });
      }

      matches.push({ text: text.slice(start, end), highlighted: true });
      head = end;
    });

    if (text.length > head) {
      matches.push({ text: text.slice(head, text.length), highlighted: false });
    }

    return matches;
  }

  static extractSubstitutions(text, substring) {
    text = text.toLocaleLowerCase();
    substring = substring.toLocaleLowerCase();

    if (text.indexOf(substring) > -1) {
      return [{ offset: text.indexOf(substring), length: substring.length }];
    } else {
      return [];
    }
  }
}

PS.Utils.TextHighlighter = TextHighlighter;
