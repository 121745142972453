export const PROGRAM_CLASSIFICATION = {
  RESIDENTIAL_BRIDGE: "residential_bridge",
  EXTENDED_BRIDGE: "extended_bridge",
  RESIDENTIAL_RENTAL_LONG_TERM: "residential_rental_long_term",
  COMMERCIAL_MULTIFAMILY: "commercial_multifamily",
  COMMERCIAL_NON_MULTIFAMILY: "commercial_non_multifamily",
  GROUND_UP_CONSTRUCTION: "ground_up_construction",
};

export const isLoanCommercialOrMultifamily = function(loan) {
  return [
    PROGRAM_CLASSIFICATION.COMMERCIAL_MULTIFAMILY,
    PROGRAM_CLASSIFICATION.COMMERCIAL_NON_MULTIFAMILY,
  ].includes(loan.programType);
};

export const isLoanMultifamily = function(loan) {
  return PROGRAM_CLASSIFICATION.COMMERCIAL_MULTIFAMILY === loan.programType;
};

export const isLoanResiForRent = function(loan) {
  return loan.programType === PROGRAM_CLASSIFICATION.RESIDENTIAL_RENTAL_LONG_TERM;
};

export const isLoanExtendedBridge = function(loan) {
  return loan.programType === PROGRAM_CLASSIFICATION.EXTENDED_BRIDGE;
};

export const isLoanResidentialBridge = function(loan) {
  return loan.programType === PROGRAM_CLASSIFICATION.RESIDENTIAL_BRIDGE;
};

export const isLoanGroundUpConstruction = function(loan) {
  return loan.programType === PROGRAM_CLASSIFICATION.GROUND_UP_CONSTRUCTION;
};
