import Vue from "vue";
import createRouter from "./router";
import App from "./app.vue";

export default function(el) {
  if (!el) return;

  // NOTE: We need a path relative to the root for vue-router.
  // https://github.com/vuejs/vue-router/issues/2927#issuecomment-531190379
  const baseUrl = el.dataset.baseUrl?.replace(/^.*:\/\/.*?\//, "/");

  if (!baseUrl) {
    throw new Error("The data-base-url property is required");
  }

  const Instance = new Vue({
    router: createRouter(baseUrl),
    render: h => h(App),
  });

  Instance.$mount(el);
}
