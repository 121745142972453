<template>
  <div v-cloak class="hotlist">
    <div class="report-top-controls">
      <div class="title-container">
        <h3 class="title">
          Hotlist
        </h3>
        <div class="filters-container">
          <label>Filter</label>
          <div>
            <analysts-dropdown :analysts="analysts" />
          </div>
          <div>
            <hotlist-filter
              :all-item="allPropertyItem"
              :filter-options="propertyTypes"
              :ordered-types="orderedPropertyTypes"
              placeholder="All Property Types"
              role="propertyTypes"/>
          </div>
          <div>
            <hotlist-filter
              :all-item="allProgramItem"
              :filter-options="loanPrograms"
              :ordered-types="orderedLoanPrograms"
              placeholder="All Loan Programs"
              role="loanPrograms"/>
          </div>
          <div class="channels-filter">
            <hotlist-filter
              :all-item="allAccountItem"
              :filter-options="accountTypes"
              :ordered-types="orderedAccountTypes"
              placeholder="All Channels"
              role="accountTypes"/>
          </div>
          <div>
            <task-assignees-dropdown :task-assignees="analysts" />
          </div>

          <div class="new-loan" v-if="canCreate">
            <a class="btn btn-action-cta-sm" href="/manage/loans/new">
              New Loan
            </a>
          </div>
        </div>
      </div>

      <div class="tabs-container">
        <hotlist-tabs v-model="tab" />
      </div>
    </div>

    <Pipeline v-if="tab === 'pipeline'" />
    <Purchased v-if="tab === 'purchased'" />
    <PaidOff v-if="tab === 'paidOff'" />
  </div>
</template>

<script>
import HotlistFilter from "components/reports/hotlist/hotlist_filter.vue";
import AnalystsDropdown from "components/reports/hotlist/analysts_dropdown.vue";
import TaskAssigneesDropdown from "components/reports/hotlist/task_assignees_dropdown.vue";
import HotlistTabs from "components/reports/hotlist/tabs";
import Pipeline from "components/reports/hotlist/pipeline";
import Purchased from "components/reports/hotlist/purchased";
import PaidOff from "components/reports/hotlist/paid_off";
import {
  ALL_ACCOUNT_TYPES_ITEM,
  ALL_LOAN_PROGRAMS_ITEM,
  ALL_PROPERTY_TYPES_ITEM,
  ORDERED_ACCOUNT_TYPES,
  ORDERED_LOAN_PROGRAMS,
  ORDERED_PROPERTY_TYPES,
} from "models/hotlist/filter_options";

export default {
  components: {
    HotlistFilter,
    AnalystsDropdown,
    TaskAssigneesDropdown,
    HotlistTabs,
    Pipeline,
    Purchased,
    PaidOff,
  },
  props: {
    analysts: {
      type: Array,
      default: () => PSData.analysts,
    },
    propertyTypes: {
      type: Array,
      default: () => PSData.propertyTypes,
    },
    loanPrograms: {
      type: Array,
      default: () => PSData.loanPrograms,
    },
    accountTypes: {
      type: Array,
      default: () => PSData.accountTypes,
    },
  },
  data: () => ({
    tab: "pipeline",
    canCreate: PSData.policy.canCreate,
  }),
  created() {
    this.allAccountItem = ALL_ACCOUNT_TYPES_ITEM;
    this.allProgramItem = ALL_LOAN_PROGRAMS_ITEM;
    this.allPropertyItem = ALL_PROPERTY_TYPES_ITEM;
    this.orderedAccountTypes = ORDERED_ACCOUNT_TYPES;
    this.orderedPropertyTypes = ORDERED_PROPERTY_TYPES;
    this.orderedLoanPrograms = ORDERED_LOAN_PROGRAMS;
  },
};
</script>
