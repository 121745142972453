import { US_ABBREVIATED_STATE_NAME } from "models/constants";

const GOOGLE_ADDRESS_MAPPING = {
  administrative_area_level_1: "state",
  locality: "city",
  route: "streetName",
  street_number: "streetNumber",
  postal_code: "zipcode",
};

class Address {
  static emptyHash() {
    return {
      street: undefined,
      streetName: undefined,
      streetNumber: undefined,
      city: undefined,
      state: undefined,
      zipcode: undefined,
      latitude: undefined,
      longitude: undefined,
    };
  }

  static fromGoogleAddress(googleAddress, googleGeometry) {
    let addressHash = (googleAddress || []).reduce((address, component) => {
      const type = GOOGLE_ADDRESS_MAPPING[component.types[0]];

      if (type) {
        address[type] = component.short_name;
      }

      return address;
    }, {});

    if (googleGeometry) {
      addressHash.latitude = googleGeometry.location.lat();
      addressHash.longitude = googleGeometry.location.lng();
    }

    return new Address(addressHash);
  }

  constructor(addressHash = {}) {
    this.street = addressHash.street;
    this.streetNumber = addressHash.streetNumber;
    this.streetName = addressHash.streetName;
    this.city = addressHash.city;
    this.state = addressHash.state;
    this.zipcode = addressHash.zipcode;
    this.latitude = addressHash.latitude;
    this.longitude = addressHash.longitude;
  }

  isComplete() {
    let addressCompleted =
      this.street &&
      this.street.length > 0 &&
      this.city &&
      this.city.length > 0 &&
      this.state &&
      this.state.length > 0 &&
      this.zipcode &&
      this.zipcode.length > 0;

    return !!addressCompleted;
  }

  isEqual(otherAddress) {
    return (
      this.street === otherAddress.street &&
      this.city === otherAddress.city &&
      this.state === otherAddress.state &&
      this.zipcode === otherAddress.zipcode
    );
  }

  toString() {
    return `${this.street}, ${this.city}, ${this.state} ${this.zipcode}`;
  }

  toHash() {
    return {
      street: this.street,
      streetName: this.streetName,
      streetNumber: this.streetNumber,
      city: this.city,
      state: this.state,
      zipcode: this.zipcode,
      latitude: this.latitude,
      longitude: this.longitude,
    };
  }

  get fullStreetName() {
    return [this.streetNumber, this.streetName].filter(partial => partial).join(" ");
  }

  get abbreviatedStateName() {
    return US_ABBREVIATED_STATE_NAME[this.state];
  }
}

window.PS.Models.Address = Address;

export default Address;
