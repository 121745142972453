const Event = PS.Models.Event;

class DocumentEvent extends Event {}

class LoadingRequested extends DocumentEvent {
  prepareData(options) {
    this.documentType = options.documentType;
  }
}

class ReportGenerationRequested extends DocumentEvent {}

PS.Models.Diligence.Events.Document = {
  LoadingRequested: LoadingRequested,
  ReportGenerationRequested: ReportGenerationRequested,
};
