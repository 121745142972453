<template>
  <div class="settlement-statement" v-cloak>
    <div class="settlement-statement-header">
      <base-header size="medium">
        Settlement Statement
        <div class="ps-loader ps-loader-small" v-if="isLoading"></div>
      </base-header>
      <action-button
        :statement="settlementStatement"
        :settlement-statement-policy="settlementStatementPolicy"
        :loan-policy="loanPolicy"
        :is-loading="isLoading"
        :general-only="true"
        @generate-statement="generateStatement"
        @approve-statement="approveStatement"
        @reverse-statement="reverseStatement"
        @submit-statement="submitStatement"
        @purchase-statement="purchaseStatement"
      ></action-button>
    </div>

    <div v-if="isPurchased" class="settlement-statement-purchase-status">
      <p>Loan Purchased on {{ settlementStatement.purchaseDate }}.</p>
    </div>

    <div class="settlement-statement-errors" v-if="hasErrors">
      <errors :type="errorType" :errors="errors"></errors>
    </div>

    <list></list>
  </div>
</template>

<script>
import ActionButton from "components/manage/settlement_statement/action_button.vue";
import Errors from "components/manage/settlement_statement/errors.vue";
import List from "components/manage/settlement_statement/list";
import BaseMixin from "controllers/manage/settlement_statement/base_mixin";

export default {
  name: "manage-settlement-statement-index-controller",
  mixins: [BaseMixin],
  components: {
    List,
    ActionButton,
    Errors,
  },
};
</script>
