<template>
  <tr>
    <td>
      {{ getDocumentTypeText(workoutDocument.documentTypeId) }}
    </td>
    <td class="date-column">
      {{ workoutDocument.dateSent }}
    </td>
    <td class="tracking-column">
      {{ workoutDocument.trackingNumber }}
    </td>
    <td class="date-column">
      {{ workoutDocument.expirationDate }}
    </td>
    <td>
      <div v-if="canEdit" class="actions d-flex">
        <div class="action-button">
          <a href="#edit" title="Edit workout document information" @click.prevent="$emit('edit', workoutDocument.id)">
            <i class="fas fa-pencil-alt" />
          </a>
        </div>
        <div class="action-button">
          <a href="#delete" title="Delete workout document" @click.prevent="$emit('delete', workoutDocument.id)">
            <i class="fas fa-trash-alt" />
          </a>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "workout-document-list-item",
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    documentTypes: {
      type: Array,
      default: () => [],
    },
    workoutDocument: {
      type: Object,
      required: true,
    },
    workoutDocumentTypes: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    findElement(array, id) {
      return array.find(t => t.id == id);
    },
    getDocumentTypeText(id) {
      const selectedElem = this.findElement(this.workoutDocumentTypes, id);
      return selectedElem ? selectedElem.text : "-";
    },
  },
};
</script>
