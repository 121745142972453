<template>
  <loan-boarding-table label="Assignment Transfers" :count="count">
    <template #header>
      <thead>
        <th>Submitted/<br>Days</th>
        <th>Subservicer<br>ID</th>
        <th>Loan ID</th>
        <th class="address">Address</th>
        <th>Assignment<br>Date</th>
        <th>Originator</th>
        <th>Borrower</th>
        <th class="amount">Total UPB</th>
        <th class="amount">Transfer UPB</th>
        <th class="amount">Note<br>Rate</th>
        <th class="amount">Transfer<br>Rate</th>
        <th class="amount">Originator<br>Rate</th>
      </thead>
    </template>
    <template #body>
      <tr v-for="item in collection" :key="item.id" :id="item.id">
        <td>{{ item.submittedDate }}<br>{{ item.daysSinceSubmitting }}</td>
        <td>{{ item.subservicerId }}</td>
        <td>{{ item.psLoanId }}</td>
        <td class="address">
          <loan-boarding-truncated-text :value="item.address"></loan-boarding-truncated-text>
        </td>
        <td>{{ item.assignmentDate }}</td>
        <td>
          <loan-boarding-truncated-text :value="item.originator"></loan-boarding-truncated-text>
        </td>
        <td>
          <loan-boarding-truncated-text :value="item.borrower"></loan-boarding-truncated-text>
        </td>
        <td class="amount">{{ item.totalPrincipalBalanceAtOrigination | accounting }}</td>
        <td class="amount">{{ item.unpaidPrincipalBalance | accounting }}</td>
        <td class="amount">{{ item.noteRate | percent(4) }}</td>
        <td class="amount">{{ item.transferRate | percent(4) }}</td>
        <td class="amount">{{ item.originatorRate | percent(4) }}</td>
      </tr>
    </template>
  </loan-boarding-table>
</template>
<script>
import LoanBoardingTruncatedText from "components/servicer/loan_boarding/truncated_text";
import LoanBoardingListMixin from "components/servicer/loan_boarding/mixin/list";
import LoanBoardingTable from "components/servicer/loan_boarding/table";

export default {
  mixins: [LoanBoardingListMixin],
  name: "manage-servicer-loan-boarding-transfer-submitted",
  components: {
    LoanBoardingTruncatedText,
    LoanBoardingTable,
  },
  props: {
    collection: Array,
    routes: Object,
  },
};
</script>
