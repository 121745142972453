<template>
  <div
    class="markdown-content"
    :class="[className]"
    @click="onClick"
    v-html="renderedContent"
  />
</template>

<script>
import marked from "marked";
import DOMPurify from "dompurify";

export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    renderedContent() {
      return DOMPurify.sanitize(marked(this.value, { breaks: true, gfm: true, ...this.options }));
    },
  },
  methods: {
    onClick(event) {
      if (event.target.tagName === "A") {
        event.target.setAttribute("target", "_blank");
      } else {
        return false;
      }
    },
  },
};
</script>
