const Charts = PS.Models.MarketData.Charts;
const Source = PS.Models.MarketData.Source;

class OccupiedVersusVacant extends Source {
  constructor() {
    super();

    this.occupied = [];
    this.vacant = [];
  }

  get name() {
    return "Occupied vs Vacant";
  }

  get data() {
    return {
      hcOccupied: this.occupied,
      hcVacant: this.vacant,
    };
  }

  get hasData() {
    return Object.values(this.data).some(data => {
      return data.length > 0;
    });
  }

  success(data) {
    const zipData = data.midas.zipCrossSection;

    if (zipData) {
      this.occupied.push(zipData.occupiedUnitsPct);
      this.vacant.push(zipData.vacantUnitsPct);
    }

    super.success();
  }
}

PS.Models.MarketData.Charts.OccupiedVersusVacant = OccupiedVersusVacant;
