<template>
  <i :class="classes" />
</template>

<script>
const SIZES = ["lg", "2x", "3x", "4x", "5x"];

import includes from "lodash/includes";

export default {
  name: "Icon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    spin: Boolean,
    size: {
      type: String,
      default: null,
      validator: value => !value || includes(SIZES, value),
    },
  },
  computed: {
    classes() {
      const { icon, size, spin } = this;

      return {
        fas: true,
        [`fa-${icon}`]: true,
        [`fa-${size}`]: size,
        "fa-spin": spin,
      };
    },
  },
};
</script>
