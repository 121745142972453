<template>
  <div class="payoff-statement">
    <breadcrumb-navigation :items="breadcrumbItems" />
    <div class="payoff-statement-header no-border">
      <div class="payoff-statement-title-container">
        <h3>PeerStreet Repurchase Statement</h3>
        <span :class="statusClass">{{ statement.statusLabel }}</span>
        <span v-if="isLoading" class="ps-loader ps-loader-small" />
        <div class="payoff-statement-sub">
          <span>Version {{ statement.versionNumber }}</span>
          <span>• Updated {{ statement.lastUpdated }}</span>
        </div>
      </div>
      <repurchase-statement-action-button
        v-if="showActionButtons"
        :statement="statement"
        :statement-policy="statementPolicy"
        @transition="updateStatus"
        @transitionWithCheck="updateStatusWithGuardrail"
        @refresh="refresh"
        @cancel="cancel"
        @mark-as-purchased="showCompletedPopup"
      />
      <payoff-statement-edit-button
        v-if="isEdit"
        @update="persist"
        @cancel="showStatement"
      />
    </div>
    <errors :errors="errors" :type="errorType" />
    <repurchase-statement-details
      v-if="isEditable"
      :repurchase-statement="statement"
      :is-editable="isEditable"
      :is-edit="isEdit"
      :payoff-reason-types="payoffReasonTypes"
      :reasons-for-correction="reasonsForCorrection"
      :fee-types="feeTypes"
      :routes="routes"
      @save-draft="submit('saveDraft')"
      @cancel="cancel"
      @submit="submit('submit')"
      @sync-interest="syncInterest"
      @add-fee="addFee"
      @remove-fee="removeFee"
      @add-comment="addComment"
      @remove-comment="removeComment"
    />
    <repurchase-statement-show-details
      v-else
      :statement="statement"
    />
    <payoff-statement-completed-popup
      :show="isCompletedPopupShown"
      :on-close="hideCompletedPopup"
      :statement="statement"
    />
  </div>
</template>

<script>
import PayoffStatement from "models/payoff_statement";
import PayoffStatementPolicy from "policies/payoff_statement_policy";
import get from "lodash/get";
import BreadcrumbNavigation from "components/shared/breadcrumb_navigation";
import RepurchaseStatementDetails from "components/manage/payoff_statement/repurchase/edit/details";
import RepurchaseStatementShowDetails from "components/manage/payoff_statement/repurchase/show/details";
import RepurchaseStatementActionButton from "components/manage/payoff_statement/repurchase/action_button";
import PayoffStatementEditButton from "components/manage/payoff_statement/edit_button";
import PayoffStatementCompletedPopup from "components/manage/payoff_statement/completed_popup";
import selectHashOptionsToMapping from "utils/select_hash_options_to_mapping";
import Errors from "components/manage/payoff_statement/errors";
import ShowMixin from "components/manage/payoff_statement/repurchase/show_mixin";

const NOTIFICATIONS = {
  submit: {
    new: "PeerStreet repurchase statement successfully generated",
    edit: "PeerStreet repurchase was successfully updated",
    duplicate: "PeerStreet repurchase statement successfully duplicated",
    onError: "Unable to generate PeerStreet repurchase statement",
  },
  refresh: "PeerStreet repurchase statement was successfully refreshed",
  cancelled: "PeerStreet repurchase cancelled",
  pending: "PeerStreet repurchase statement marked as reviewed",
  approved: "PeerStreet repurchase statement approved",
  reviewed: "PeerStreet repurchase statement marked as reviewed",
  purchased: "PeerStreet repurchase statement marked as purchased",
  transition: {
    onSuccess: "PeerStreet repurchase statement successfully updated",
    onError: {
      approved: "Unable to approve PeerStreet repurchase statement",
      reviewed: "Unable to mark as reviewed PeerStreet repurchase statement",
    },
  },
};

export default {
  name: "repurchase-statement-show",
  mixins: [ShowMixin],
  components: {
    BreadcrumbNavigation,
    PayoffStatementCompletedPopup,
    RepurchaseStatementDetails,
    RepurchaseStatementShowDetails,
    RepurchaseStatementActionButton,
    PayoffStatementEditButton,
    Errors,
  },
  data() {
    return {
      isLoading: false,
      created: false,
      currentState: this.state,
      isCompletedPopupShown: false,
      statement: new PayoffStatement(PSData.statement || {}),
      statementPolicy: new PayoffStatementPolicy(PSData.statementPolicy),
      payoffReasonTypes: selectHashOptionsToMapping(PSData.payoffReasonTypes, "name", "label"),
      reasonsForCorrection: selectHashOptionsToMapping(
        PSData.reasonsForCorrection,
        "name",
        "label"
      ),
      feeTypes: selectHashOptionsToMapping(PSData.feeTypes, "name", "label"),
      errors: [],
      errorType: null,
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          name: "Servicing",
          link: this.routes.servicing,
        },
        {
          name: "PeerStreet Repurchase Statement",
        },
      ];
    },
  },
  methods: {
    submit(route) {
      this.makeRequest(this.routes[route]);
    },
    notification(status) {
      return get(NOTIFICATIONS, status);
    },
  },
};
</script>
