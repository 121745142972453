<template>
  <base-modal-form
    :show="show"
    :save-disabled="isSaving"
    :cancel-options="cancelOptions"
    @close="onClose"
    @save="onSave"
    saveButtonName="Mark as Sold"
    title="Mark Open Auction Loan as Sold"
    class="mark-loan-as-sold-modal"
  >

    <b>{{loanAddress}}</b>
    <div class="loan-essence">
      <span class="loan-status" :class="`loan-${loan.underwriting_status}`">
        {{loanUnderwritingStatus}}
      </span>
      <span>{{loan.lender_name}}</span>
      <a :href="loan.path" target="_blank">
        <span>{{loan.ps_id}}</span>
        <base-icon packs="fas" size="is-small" icon="external-link-alt" />
      </a>
    </div>

    <hr>

    <form>
      <div class="columns">
        <base-date-field
          label="Sold On"
          v-model="record.sold_on"
          :errors="errors.sold_on"
          :max-date="today"
          class="column is-half"
        />
        <base-currency-field
          label="PS Fees"
          v-model="record.ps_fees"
          :errors="errors.ps_fees"
          class="column is-half"
        />
      </div>
      <div class="columns">
        <base-text-field
          type="textarea"
          label="Notes"
          v-model="record.note"
          :errors="errors.note"
          class="column is-full"
        />
      </div>
    </form>
  </base-modal-form>
</template>

<script>
import AjaxService from "services/ajax_service";
import PostAddress from "models/post_address";
import startCase from "lodash/startCase";
import { error, success } from "services/notification";
import toPlainObject from "utils/to_plain_object";

export default {
  name: "open-auction-mark-as-sold-modal",
  props: {
    soldLoansUrl: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    loan: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      today: new Date(),
      record: {
        sold_on: null,
        ps_fees: null,
        notes: null,
      },
      errors: {},
      isSaving: false,
      cancelOptions: ["escape", "x"],
    };
  },
  watch: {
    show(isShow) {
      if (isShow) {
        this.reset();
      }
    },
  },
  computed: {
    loanAddress() {
      return new PostAddress(this.loan).fullAddress;
    },
    loanUnderwritingStatus() {
      return startCase(this.loan.underwriting_status);
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onSave() {
      this.markAsSold();
    },
    reset() {
      this.errors = {};
      this.record = {
        sold_on: null,
        ps_fees: null,
        notes: null,
      };
    },
    markAsSold() {
      this.isSaving = true;

      const payload = {
        loan_ps_id: this.loan.ps_id,
        ...this.record,
      };

      AjaxService.post(this.soldLoansUrl, payload, { "Content-Type": "application/json" })
        .then(() => {
          this.$emit("loan-marked-as-sold", this.record);
          this.onClose();

          success("Loan has been Marked as Sold");
        })
        .catch(({ response, data }) => {
          if (response.status === 422) {
            this.errors = data.errors;
          } else {
            this.onClose();

            error("Unable to mark loan as sold");
            Bugsnag.notify(
              { name: "Request Error", message: "Unable to mark loan as sold" },
              { metaData: { custom: { response: toPlainObject(response), payload } } }
            );
          }
        })
        .finally(() => (this.isSaving = false));
    },
  },
};
</script>
