<template>
  <div class="rules col-md-9">
    <h2>{{ currentSubservicer.label }}</h2>
    <span class="subheader">Subservicer Fees</span>
    <div>
      <table class="table">
        <thead>
          <tr>
            <th class="text-left">
              Status
            </th>
            <th class="text-left">
              Subservicer Code
            </th>
            <th class="text-left">
              Subservicer Fee Name
            </th>
            <th class="text-left">
              Distribution Fee Mapping
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="record in records" :key="record.id">
            <td class="text-left status" :class="record.status">
              <span class="status-block">{{ record.status }}</span>
            </td>
            <td class="text-left">
              {{ record.feeCode }}
            </td>
            <td class="text-left">
              {{ record.feeCodeDescription }}
            </td>
            <td class="text-left">
              {{ record.feeType.label }}
            </td>
            <td v-if="isEditable" @click="editFee(record)" class="text-right" title="Edit">
              <i class="fas fa-pencil-alt" />
            </td>
            <td v-else></td>
          </tr>
        </tbody>
      </table>
      <span v-if="isEditable" @click="showModal" class="add-button">+ Add Subservicer Fee</span>
    </div>
    <modal
      v-if="isModalShown"
      v-bind="{ currentSubservicer, fee }"
      @saved="onSave"
      @close="closeModal"
    />
  </div>
</template>
<script>
import Modal from "./modal";
import Policy from "../policy";

export default {
  components: { Modal },
  props: {
    currentSubservicer: {
      type: Object,
      required: true,
    },
    records: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { isModalShown: false, fee: undefined, policy: new Policy(PSData.policy) };
  },
  computed: {
    isEditable() {
      return this.policy.canAddSubservicerFee();
    },
  },
  methods: {
    editFee(fee) {
      this.fee = fee;
      this.showModal();
    },
    onSave(data) {
      if (this.fee) {
        this.$emit("updated", data);
      } else {
        this.$emit("created", data);
      }

      this.closeModal();
    },
    showModal() {
      this.isModalShown = true;
    },
    closeModal() {
      this.isModalShown = false;
      this.fee = undefined;
    },
  },
};
</script>
