<template>
  <div>
    <loan-boarding-ready-to-submit
      :collection="currentCollection"
      :routes="routes"
      :options="options"
      @updated="updateCollections"
    />

    <loan-boarding-reconcile
      mode="submit"
      :collection="currentRepairedCollection"
      :routes="routes"
      :options="options"
      @updated="updateCollections"
    />

    <loan-boarding-transfer-ready-to-submit
      :collection="currentTransferCollection"
      :routes="routes.transfer"
      @updated="updateTransferCollections"
    />
  </div>
</template>
<script>
import LoanBoardingSectionMixin from "components/servicer/loan_boarding/mixin/section";
import LoanBoardingReadyToSubmit from "components/servicer/loan_boarding/ready_to_submit";
import LoanBoardingReconcile from "components/servicer/loan_boarding/reconcile";
import LoanBoardingTransferReadyToSubmit from "components/servicer/loan_boarding_transfer/ready_to_submit";

export default {
  name: "ManageServicerLoadBoardingSectionsReadyToSubmit",
  components: {
    LoanBoardingReadyToSubmit,
    LoanBoardingReconcile,
    LoanBoardingTransferReadyToSubmit,
  },
  mixins: [LoanBoardingSectionMixin],
  computed: {
    lastUpdatedAt() {
      return this.getLastUpdatedAt(this.currentCollection);
    },
    currentCollection() {
      let collection = this.collections.approved;
      return this.filterCollectionForServicer(collection);
    },
    currentRepairedCollection() {
      let collection = this.collections.repaired;
      return this.filterCollectionForServicer(collection);
    },
    currentTransferCollection() {
      let collection = this.transferCollections.approved;
      return this.filterCollectionForServicer(collection);
    },
    count() {
      return [
        this.currentCollection.length,
        this.collections.repaired.length,
        this.currentTransferCollection.length,
      ].reduce((acc, value) => acc + value);
    },
  },
  created() {
    this.emitSectionData();
  },
  methods: {
    emitSectionData() {
      this.$emit("loaded", {
        section: "readyToSubmit",
        count: this.count,
        lastUpdatedAt: this.lastUpdatedAt,
      });
    },
  },
};
</script>
