const Command = PS.Models.Command;
const Events = PS.Models.Loan.Events;

class RequestRefresh extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.SeriesSummary.RefreshRequested,
      PS.Services.MessageBusService.loanBus,
      {}
    );
  }
}

class StartLoading extends Command {
  execute() {
    this.state.loading = true;
    this.state.statusSummaries = [];
    this.state.fundingEntitySummaries = [];

    return Promise.resolve();
  }
}

class FinishLoading extends Command {
  execute() {
    this.state.loading = false;

    return Promise.resolve();
  }
}

class LoadFromServer extends Command {
  execute() {
    let url = `/manage/loans/${this.state.loan.toParam}/series_summaries`;
    return PS.Services.AjaxService.getJSON(url).then(summary => {
      this.state.statusSummaries = summary.statusSummaries;
      this.state.fundingEntitySummaries = summary.fundingEntitySummaries;
    });
  }
}

PS.Models.Loan.Commands.SeriesSummary = {
  RequestRefresh: RequestRefresh,
  StartLoading: StartLoading,
  LoadFromServer: LoadFromServer,
  FinishLoading: FinishLoading,
};
