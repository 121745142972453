<template>
  <div class="loan-view-toggle">
    View:
    <a :href="originalLink" :class="{ active: !isCustomView }">Original</a> |
    <a :href="customLink" :class="{ active: isCustomView }">{{ customName }}</a>
  </div>
</template>
<script>
import LocationService from "services/location_service.js";
import { PROGRAM_CLASSIFICATION } from "models/lender_form/program";

const DEFAULT_VIEW = "original";
const CUSTOM_VIEW = "custom";

export default {
  name: "loan-view-toggle",
  props: {
    programType: String,
  },
  data: function() {
    return {
      viewDetails: PSData.viewDetails || undefined,
    };
  },
  computed: {
    baseUrl() {
      return `${LocationService.origin}${LocationService.pathname}`;
    },
    originalLink() {
      return `${this.baseUrl}?view=${DEFAULT_VIEW}`;
    },
    customLink() {
      return `${this.baseUrl}?view=${CUSTOM_VIEW}`;
    },
    isCustomView() {
      return (
        new URLSearchParams(location.search).get("view") === CUSTOM_VIEW ||
        this.viewDetails == CUSTOM_VIEW
      );
    },
    customName() {
      switch (true) {
        case this.programType === PROGRAM_CLASSIFICATION.COMMERCIAL_MULTIFAMILY ||
          this.programType === PROGRAM_CLASSIFICATION.COMMERCIAL_NON_MULTIFAMILY:
          return "Commercial";
        case this.programType === PROGRAM_CLASSIFICATION.EXTENDED_BRIDGE:
          return "Extended Bridge UW";
        case this.programType === PROGRAM_CLASSIFICATION.RESIDENTIAL_RENTAL_LONG_TERM:
          return "Resi for Rent UW";
        default:
          return;
      }
    },
  },
};
</script>
