<template>
  <modal-form
    v-if="show"
    :save-disabled="submitDisabled"
    :show="show"
    save-button-name="Submit Request"
    title="Request Loan Modification"
    class="request-modal"
    @on-close="onClose"
    @on-save="submit"
  >
    <p v-if="loan" class="modal-form-description">
      {{ loan.fullAddress }}
    </p>
    <div class="panel-default panel-info">
      <header>Follow these steps:</header>
      <ol class="numbered-list">
        <li>Provide the information below and submit.</li>
        <li>Respond to the genererated email and attach requested documents.</li>
        <li>PeerStreet will review your request and return a response with eligible loan modification options.</li>
        <li>Respond once again with a selected option. Pending final review and approval, PeerStreet will send a loan modification agreement.</li>
      </ol>
    </div>
    <div>
      <div class="form-block">
        <label>
          CC
        </label>
        <div
          v-for="(recipient, index) in recipients"
          :key="'recipient-' + index"
          class="request-modal-item"
          :class="{ 'has-error': errors.has('email' + index) }"
        >
          <input
            v-model="recipient.value"
            v-validate="'required|email'"
            autocomplete="off"
            type="text"
            class="form-input"
            :disabled="index === 0"
            :name="'email-' + index"
          >
          <button v-if="index > 0" class="btn-icon delete-button" @click="deleteRecipient(index)">
            <i class="fas fa-trash-alt" />
          </button>
          <span v-show="errors.has('email-' + index)" class="error">
            Please enter a valid email address.
          </span>
        </div>
        <a href="#" v-if="canAddRecipients" @click.prevent="addRecipient">+ Add Email Recipient</a>
      </div>
      <div class="form-block">
        <label>Describe the borrower's circumstances</label>
        <textarea
          v-model="circumstances"
          v-validate="'required'"
          class="form-input"
          cols="30"
          rows="4"
          name="circumstances"
        />
      </div>
      <div class="form-block">
        <label>Describe your desired loan modification terms</label>
        <textarea
          v-model="terms"
          v-validate="'required'"
          class="form-input"
          cols="30"
          rows="4"
          name="terms"
        />
      </div>
    </div>
  </modal-form>
</template>

<script>
import ModalForm from "components/modal_form";
import AjaxService from "services/ajax_service";
import { error, success } from "services/notification";
import toPlainObject from "utils/to_plain_object";
import { RequestType } from "models/loan_requests";

const MAX_RECIPIENTS = 5;
const HELPDESK_URL =
  "https://peerstreet.zendesk.com/hc/en-us/articles/360026914431-Loan-Extension-Programs";

export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "modification-request-modal",
  components: { ModalForm },
  props: {
    show: Boolean,
    loan: Object,
    isLender: Boolean,
  },
  data() {
    const user = PSData.user || {};
    const user_properties = user.properties || {};

    return {
      recipients: [{ value: user_properties.email }],
      terms: "",
      circumstances: "",
      inFlight: false,
      backendErrors: {},
    };
  },
  computed: {
    canAddRecipients() {
      return this.recipients.length < MAX_RECIPIENTS + 1;
    },
    submitDisabled() {
      return !this.isLender || (this.terms === "" || this.circumstances === "");
    },
  },
  watch: {
    loan: function(newLoan) {
      if (newLoan) {
        const user = PSData.user || {};
        const user_properties = user.properties || {};

        this.recipients = [{ value: user_properties.email }];
        this.terms = "";
        this.circumstances = "";
      }
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    submit() {
      this.$validator.validateAll().then(result => {
        if (!result) return;

        if (this.inFlight) return;

        this.inFlight = true;
        this.backendErrors = {};
        const url = this.loan.loanRequestsPath;
        const payload = {
          request_type: RequestType.Modification,
          request_recipients: this.recipients.map(recipient => recipient.value),
          request_details: {
            terms: this.terms,
            circumstances: this.circumstances,
          },
        };

        return AjaxService.post(url, payload, { "content-type": "application/json" })
          .then(request => {
            success("Modification request has been sent.");
            this.$emit("modification-request-sent", this.loan, request);
            this.onClose();
          })
          .catch(({ response, data }) => {
            if (response.status === 422) {
              this.backendErrors = data.errors;
            } else {
              error("Something went wrong");
              Bugsnag.notify(
                { name: "Request Error", message: "Unable to create loan request" },
                { metaData: { custom: { response: toPlainObject(response), payload } } }
              );
              this.onClose();
            }
          })
          .finally(() => {
            this.inFlight = false;
          });
      });
    },
    addRecipient() {
      this.recipients.push({ value: "" });
    },
    deleteRecipient(index) {
      this.recipients.splice(index, 1);
    },
  },
};
</script>
