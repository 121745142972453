const Command = PS.Models.Command;
const Commands = PS.Models.EmailSubscription.Commands;
const Events = PS.Models.EmailSubscription.Events;

class SendSubscriptionUpdate extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.SubscriptionUpdated,
      PS.Services.MessageBusService.settingsBus,
      { subscription: this.state.subscription }
    );
  }
}

class DisableSubscription extends Command {
  execute() {
    if (!this.state.subscription.enabled) {
      return Promise.resolve();
    }

    this.state.subscription.enabled = false;
    return Command.broadcastEvent(
      Events.SubscriptionUpdated,
      PS.Services.MessageBusService.settingsBus,
      { subscription: this.state.subscription }
    );
  }
}

PS.Models.EmailSubscription.Commands.Item = {
  DisableSubscription: DisableSubscription,
  SendSubscriptionUpdate: SendSubscriptionUpdate,
};
