<template>
  <div :class="wrapperClass">
    <button
      class="segmented-control-option"
      :class="{ ['segmented-control-active']: option.name === activeOption }"
      v-for="option in options"
      :key="option.name"
      @click="onButtonClicked(option)"
    >
      {{ option.label }}
    </button>
  </div>
</template>
<script>
export default {
  props: {
    wrapperClass: {
      type: [String, Array],
      default: "segmented-control",
    },
    options: Array,
    activeOption: String,
  },
  methods: {
    onButtonClicked(option) {
      this.$emit("option-selected", option.name);
    },
  },
};
</script>
