<template>
  <div>
    <div class="is-flex is-justify-content-space-between is-align-items-center py-5">
      <base-header size="medium">
        Template Milestones
      </base-header>
      <base-tag>
        Read only
        <base-tooltip
          multilined
          label="This template is active. Only task descriptions can be updated."
          position="is-bottom"
          class="readonly-tooltip ml-1"
        >
          <b-icon
            pack="fas"
            size="is-small"
            icon="question-circle"
          />
        </base-tooltip>
      </base-tag>
    </div>
    <div class="is-flex is-flex-direction-row-reverse mb-2">
      <base-button class="toggle-open-details" size="small" @click="toggleOpenDetailsForAllMilestones()">{{ toggleOpenDetailsForAllMilestonesTitle }}</base-button>
    </div>
    <base-table
      :data="revision.milestones"
      detailed
      :draggable="false"
      detail-key="id"
      show-detail-icon
      :show-header="false"
      class="milestones-list"
      :opened-detailed="milestonesWithOpenedDetails"
    >
      <base-column
        v-slot="props"
        label="Milestone name"
        width="200"
      >
        {{ props.row.name }}
      </base-column>
      <base-column
        v-slot="props"
        label="Tasks count"
      >
        {{ props.row.taskMappings.length | pluralize("Task") }}
      </base-column>
      <template v-slot:empty>
        No milestones
      </template>
      <template v-slot:detail="{ props }">
        <milestone-tasks-list
          :milestone="props.row"
          :can-manage="canManage"
          @update="onMilestoneUpdate"
          @update-order="onOrderUpdate"
          @task-delete="onTaskDelete"
        />
      </template>
    </base-table>
  </div>
</template>

<script>
import MilestoneTasksList from "./milestone_tasks_list";
import AjaxService from "services/ajax_service";
import { MANAGE_TASKLIST_MAPPINGS_ROUTE } from "models/tasklist_template_revision";
import { manageTasklistTemplateTaskMappingPath } from "models/tasklist_template_revision";

const ORDER_MULTIPLE = 100;

export default {
  components: {
    MilestoneTasksList,
  },
  props: {
    revision: {
      type: Object,
      default: () => {},
    },
    canManage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      milestonesWithOpenedDetails: [],
      openDetailsForAllMilestones: false,
    };
  },
  computed: {
    toggleOpenDetailsForAllMilestonesTitle() {
      return this.openDetailsForAllMilestones ? "Collapse All" : "Expand All";
    },
  },
  methods: {
    onMilestoneUpdate(milestone) {
      const milestoneIndex = this.revision.milestones.findIndex(item => item.id === milestone.id);
      this.revision.milestones.splice(milestoneIndex, 1, milestone);
    },
    onOrderUpdate(successMessage) {
      const messages = { onSuccess: successMessage };

      return this.saveTasks(messages).then(data => {
        this.revision.milestones = data.revision.milestones;
      });
    },
    onTaskDelete(task) {
      const messages = { onSuccess: `Task "${task.taskDefinition.label}" has been removed` };
      const params = { revision_id: this.revision.id };

      return AjaxService.withNotifications(messages)
        .delete(manageTasklistTemplateTaskMappingPath(task.id), params)
        .then(data => {
          this.revision.milestones = data.revision.milestones;
        });
    },
    saveTasks(messages) {
      const taskMappingsToSave = this.revision.milestones.reduce((allTaskMappings, milestone) => {
        const milestoneTaskMappings = milestone.taskMappings.map((mapping, index) => ({
          id: mapping.id,
          order: (index + 1) * ORDER_MULTIPLE,
          milestone_id: milestone.id,
        }));
        allTaskMappings = allTaskMappings.concat(milestoneTaskMappings);

        return allTaskMappings;
      }, []);

      return AjaxService.withNotifications(messages).patchJSON(MANAGE_TASKLIST_MAPPINGS_ROUTE, {
        revision_id: this.revision.id,
        task_mappings: taskMappingsToSave,
      });
    },
    toggleOpenDetailsForAllMilestones() {
      if (this.openDetailsForAllMilestones) {
        this.milestonesWithOpenedDetails = [];
        this.openDetailsForAllMilestones = false;
      } else {
        this.milestonesWithOpenedDetails = this.revision.milestones.map(milestone => milestone.id);
        this.openDetailsForAllMilestones = true;
      }
    },
  },
};
</script>
<style scoped>
.milestones-list >>> .chevron-cell {
  width: 40px;
}

.milestones-list >>> .table tr.detail .detail-container {
  padding: 0;
}

.milestones-list >>> .detail-container .table {
  background: none;
  border: 0 none;
}

.readonly-tooltip {
  line-height: var(--line-height-sm);
  text-transform: none;
}
</style>
