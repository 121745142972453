<template>
  <div class="loan-task-view-attachments-section">
    <div class="loan-task-view-attachments">
      <h2 class="loan-task-view-title">
        <span>Attachments&nbsp;</span>
        <span v-if="attachments.length > 0">({{ attachments.length }})</span>
      </h2>

      <task-attachments-dropdown
        :task="task"
        :documents="documents"
        :upload-settings="uploadSettings"
        :can-upload-files="canUploadFiles"
        @file-upload-click="fileUploadClick"
        @document-attachment-click="documentAttachmentClick"
    />
    </div>

    <task-attachments :documents="attachments" :task="task" />
  </div>
</template>
<script>
import TaskAttachments from "components/activity_feed_events/loan_tasks/task_attachments";
import TaskAttachmentsDropdown from "components/activity_feed_events/loan_tasks/task_attachments_dropdown";

export default {
  name: "task-attachments-section",
  components: {
    TaskAttachments,
    TaskAttachmentsDropdown,
  },
  props: {
    task: Object,
    documents: Array,
    uploadSettings: {
      type: Object,
      required: true,
    },
    canUploadFiles: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    attachments() {
      return this.documents.filter(document => document.taskIds.includes(this.task.id));
    },
  },
  methods: {
    fileUploadClick(event) {
      this.$emit("file-upload-click", event);
    },
    documentAttachmentClick(event) {
      this.$emit("document-attachment-click", event);
    },
  },
};
</script>
