<template>
  <div>
    <i class="fas fa-check status-icon" v-if="statusPositive"></i>
    <i class="fas fa-times status-icon" v-if="statusNegative"></i>
    {{ label }}
  </div>
</template>
<script>
const STATUS_CLASS = {
  positive: "positive",
  negative: "negative",
};

const STATUSES_CLASS_MAP = {
  approved: STATUS_CLASS.positive,
  use_ps_docs: STATUS_CLASS.positive,
  see_note: STATUS_CLASS.positive,
  not_started: STATUS_CLASS.negative,
  not_approved: STATUS_CLASS.negative,
  need_more_info: STATUS_CLASS.negative,
};

export default {
  name: "lender-legal-entity-loan-documents-status",
  props: {
    value: String,
    label: String,
  },
  computed: {
    statusPositive: function() {
      return STATUSES_CLASS_MAP[this.value] === STATUS_CLASS.positive;
    },
    statusNegative: function() {
      return STATUSES_CLASS_MAP[this.value] === STATUS_CLASS.negative;
    },
  },
};
</script>
