<template>
  <base-select-field
    v-model="preview.value.value"
    :label="preview.name"
    :options="options"
    :disabled="true"
    show-empty
  />
</template>

<script>
import Preview from "models/workflow_engine/preview";

// import VariableComment from "../variable_comment";

export default Vue.extend({
  name: "select-preview-component",
  components: {
    // VariableComment,
  },
  props: {
    preview: {
      type: Preview,
      required: true,
    },
  },
  computed: {
    options() {
      return this.preview.settings.options.map(option => [option.name, option.value]);
    },
  },
});
</script>
