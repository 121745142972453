<template>
  <params-provider v-slot="slotProps">
    <review :filters="slotProps.params" @filter="slotProps.setParams" />
  </params-provider>
</template>

<script>
import ParamsProvider from "components/shared/params_provider";
import Review from "./review";

export default {
  components: {
    Review,
    ParamsProvider,
  },
};
</script>
