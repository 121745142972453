<template>
  <div class="valuation">
    <div class="tasks">
      <sla-countdown
        name="Valuation"
        :completed="countdown.completed"
        :deadline="countdown.deadline"
       />
      <sla-task-group
        name="Valuation"
        :reviewed="valuation.reviewed"
        :approved="valuation.approved"
        :canReview="valuation.canReview"
        :canApprove="valuation.canApprove"
        @reviewed="$listeners['valuation-reviewed']"
        @approved="$listeners['valuation-approved']"
      />
    </div>
  </div>
</template>

<script>
import SlaCountdown from "./countdown";
import SlaTaskGroup from "./task_group";

export default {
  name: "sla-valuation",
  components: { SlaCountdown, SlaTaskGroup },
  props: {
    countdown: {
      type: Object,
      required: true,
    },
    valuation: {
      type: Object,
      required: true,
    },
  },
};
</script>
