import get from "lodash/get";

export default class ReserveDrawRequest {
  constructor(drawRequest = {}) {
    this.drawRequest = drawRequest;
    this.id = drawRequest.id;
    this.drawRequestNumber = drawRequest.drawRequestNumber;
    this.updatedAt = drawRequest.updatedAt;
    this.drawRequestType = drawRequest.drawRequestType;
    this.drawRequestTypeText = drawRequest.drawRequestTypeText;
    this.wireDate = drawRequest.wireDate;
    this.approvedDrawAmount = drawRequest.approvedDrawAmount || 0;
    this.status = drawRequest.status;
    this.onPlatform = drawRequest.onPlatform ? "On Platform" : "Off Platform";
    this.notes = get(drawRequest.comments[0], "comment");
    this.requestedDrawAmount = drawRequest.requestedDrawAmount || 0;
    this.inspectionFee = drawRequest.inspectionFee || 0;
    this.administrationFee = drawRequest.administrationFee || 0;
    this.setupFee = drawRequest.setupFee || 0;
    this.otherFee = drawRequest.otherFee || 0;
    this.reimburseLenderDrawAmount = drawRequest.reimburseLenderDrawAmount || false;
    this.reimburseLenderInspectionFee = drawRequest.reimburseLenderInspectionFee || false;
    this.reimburseLenderAdministrationFee = drawRequest.reimburseLenderAdministrationFee || false;
    this.reimburseLenderSetupFee = drawRequest.reimburseLenderSetupFee || false;
    this.reimburseLenderOtherFee = drawRequest.reimburseLenderOtherFee || false;
    this.totalReserve = drawRequest.totalReserve;
    this.previousDraws = drawRequest.previousDraws;
    this.availableReserve = drawRequest.availableReserve;
  }

  approvedDrawAmountNumber() {
    return this._toNumber(this.approvedDrawAmount);
  }

  borrowerContributedAmount() {
    return this._requestedDrawAmountNumber() - this.approvedDrawAmountNumber();
  }

  netWireAmount() {
    return this.approvedDrawAmountNumber() - this._feeTotal();
  }

  isReimbursementRequired() {
    const inspection = this.reimburseLenderInspectionFee;
    const admin = this.reimburseLenderAdministrationFee;
    const setup = this.reimburseLenderSetupFee;
    const other = this.reimburseLenderOtherFee;
    return inspection || admin || setup || other;
  }

  toHash() {
    return {
      request_type: this.drawRequestType,
      draw_request_number: this.drawRequestNumber,
      requested_draw_amount: this._requestedDrawAmountNumber(),
      approved_draw_amount: this.approvedDrawAmountNumber(),
      inspection_fee: this._inspectionFeeNumber(),
      administration_fee: this._administrationFeeNumber(),
      setup_fee: this._setupFeeNumber(),
      other_fee: this._otherFeeNumber(),
      reimburse_lender_draw_amount: this.reimburseLenderDrawAmount,
      reimburse_lender_inspection_fee: this.reimburseLenderInspectionFee,
      reimburse_lender_administration_fee: this.reimburseLenderAdministrationFee,
      reimburse_lender_setup_fee: this.reimburseLenderSetupFee,
      reimburse_lender_other_fee: this.reimburseLenderOtherFee,
      comments: this._comments(),
    };
  }

  _feeTotal() {
    return (
      this._inspectionFeeNumber() +
      this._administrationFeeNumber() +
      this._setupFeeNumber() +
      this._otherFeeNumber()
    );
  }

  _toNumber(val) {
    if (typeof val === "number") {
      return val;
    } else if (typeof val === "string") {
      return Number(val.replace(/\,/g, ""));
    } else {
      return 0;
    }
  }

  _requestedDrawAmountNumber() {
    return this._toNumber(this.requestedDrawAmount);
  }

  _inspectionFeeNumber() {
    return this._toNumber(this.inspectionFee);
  }

  _administrationFeeNumber() {
    return this._toNumber(this.administrationFee);
  }

  _setupFeeNumber() {
    return this._toNumber(this.setupFee);
  }

  _otherFeeNumber() {
    return this._toNumber(this.otherFee);
  }

  _availableReserveNumber() {
    return this._toNumber(this.availableReserve);
  }

  _comments() {
    if (this.notes) {
      const latestComment = this.drawRequest.comments[0] || {};
      return [{ ...latestComment, comment: this.notes }];
    } else {
      return [];
    }
  }
}
