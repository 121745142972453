class Calculator {
  static mostRecent(source, key) {
    if (!source.isSuccessful) return;

    let points = source.data[key].observed.data;
    let index = points.length - 1;

    // taking last element is not enough, some data points might be missing
    while (typeof points[index] !== "number" && index >= 0) {
      index--;
    }

    return points[index];
  }

  static valueFromEnd(source, key, indexFromEnd = 0) {
    if (!source.isSuccessful) return;

    let points = source.data[key].observed.data;
    let index = points.length - 1 - indexFromEnd;

    return typeof points[index] === "number" ? points[index] : undefined;
  }

  static average(source, key) {
    if (!source.isSuccessful) return;

    let points = source.data[key].observed.data.filter(p => typeof p === "number");
    let sum = points.reduce((total, current) => total + current, 0);
    let avg = sum / points.length;

    return Number.isNaN(avg) ? undefined : avg;
  }

  static sumOrNull() {
    let numbers = Array.from(arguments)
      .map(v => parseFloat(v))
      .filter(v => !Number.isNaN(v));
    // with null instead of 0 as initial value [].sum returns null
    return numbers.reduce((total, current) => total + current, null);
  }

  static pointDistance(x1, y1, x2, y2) {
    let x = Math.pow(x1 - x2, 2);
    let y = Math.pow(y1 - y2, 2);

    return Math.sqrt(x + y);
  }
}

PS.Models.MarketData.Charts.Calculator = Calculator;
export default Calculator;
