<template>
  <component :is="referenceComponent" :reference="reference" />
</template>

<script>
import ServicerDisbursement from "./servicer_disbursement";

export default {
  components: {
    ServicerDisbursement,
  },
  props: {
    reference: {
      type: Object,
      required: true,
    },
  },
  computed: {
    referenceComponent() {
      switch (this.reference.type) {
        case "servicer_disbursement":
          return ServicerDisbursement;
        default:
          return null;
      }
    },
  },
};
</script>
