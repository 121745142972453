<template>
  <modal-form
    :save-disabled="saveDisabled"
    :show="show"
    :title="setting.title"
    class="user-settings-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <form class="user-settings-form">
      <div class="user-settings-modal-fake-container">
        <div v-for="item in visibleItems" :key="`fake-${item.id}`" />
      </div>
      <div class="user-settings-form-wrapper">
        <div v-for="item in visibleItems" :key="item.id" class="checkbox-control checkbox-control-blue user-settings-form-field">
          <input
            :id="item.id"
            v-model="item.show"
            type="checkbox"
            :disabled="changeDisabled(item)"
          >
          <label :for="item.id">
            <span class="control-toggle" />
            {{ item.title }}
          </label>
        </div>
      </div>
    </form>
  </modal-form>
</template>

<script>
import ModalForm from "components/modal_form.vue";

export default {
  name: "user-settings-modal",
  components: {
    ModalForm,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    setting: {
      type: Object,
      default: () => ({
        title: "",
        items: {},
      }),
    },
  },
  data() {
    return {
      saveDisabled: false,
      items: Object.keys(this.setting.items).map(itemKey => ({
        id: itemKey,
        title: this.setting.items[itemKey].title,
        show: this.setting.items[itemKey].show,
        visible: !this.setting.items[itemKey].hidden,
        disabled: this.setting.items[itemKey].disabled,
      })),
    };
  },
  computed: {
    visibleItems() {
      return this.items.filter(item => item.visible);
    },
    selectedItems() {
      return this.visibleItems.filter(item => item.show);
    },
    settingsToSave() {
      return this.visibleItems.reduce((setting, item) => {
        setting[item.id] = {
          show: item.show,
        };
        return setting;
      }, {});
    },
  },
  methods: {
    changeDisabled(fieldItem) {
      return fieldItem.disabled || (fieldItem.show && this.selectedItems.length <= 1);
    },
    onClose() {
      this.$emit("cancel");
    },
    onSave() {
      this.saveDisabled = true;
      this.$emit("save", this.settingsToSave);
    },
  },
};
</script>
