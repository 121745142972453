import RestrictionsIndex from "components/manage/restrictions";
import RestrictionDetails from "components/manage/restriction/details";
import Vue from "vue";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-restrictions-controller"),
    components: {
      RestrictionsIndex,
      RestrictionDetails,
    },
  });
});
