import VueRouter from "vue-router";
import CashReceiptsIndex from "components/manage/cash_receipts/index";
import ReallocateAmountsPage from "components/manage/cash_receipts/ready_to_distribute/details/reallocate_amounts";

$(() => {
  let componentRootSelector = ".v-manage-servicer-cash-receipts-controller";
  let el = document.querySelector(componentRootSelector);

  if (!el) {
    return;
  }

  const routes = [
    {
      path: "/reallocate_amounts/:id",
      name: "reallocate_amounts",
      props: true,
      component: ReallocateAmountsPage,
    },
    { path: "/", component: CashReceiptsIndex },
    { path: "*", component: CashReceiptsIndex },
  ];

  const router = new VueRouter({
    base: "/manage/cash_receipts/",
    mode: "history",
    routes,
  });

  new Vue({
    router,
    el,
  });
});
