<template>
  <editable-section
    :editing="editing"
    :actions-hidden="hideActionButtons"
    :is-applicable="isApplicable()"
    header-title="Foreclosure Status"
    subheading=""
    @edit="handleEdit"
    @cancel="handleCancel"
    @update="handleUpdate"
  >
    <div class="col-6">
      <ul class="loan-block">
        <li>
          <editable-datepicker
            label="Foreclosure Referral Date"
            :value="form.referralDate"
            :editing="editing"
            @input="updateForm('referralDate', $event)"
          />
        </li>
        <li>
          <editable-datepicker
            label="Judgment Date"
            :value="form.judgmentDate"
            :editing="editing"
            @input="updateForm('judgmentDate', $event)"
          />
        </li>
        <li>
          <editable-datepicker
            label="Sale Date"
            :value="form.salesDate"
            :editing="editing"
            @input="updateForm('salesDate', $event)"
          />
        </li>
        <li>
          <editable-datepicker
            label="Deed Recording Date"
            :value="form.deedRecordingDate"
            :editing="editing"
            @input="updateForm('deedRecordingDate', $event)"
          />
        </li>
        <li>
          <editable-currency
            label="PeerStreet Bid Price"
            :value="form.peerstreetBidPrice"
            :editing="editing"
            @input="updateForm('peerstreetBidPrice', $event)"
          />
        </li>
        <li>
          <editable-currency
            label="Winning Bid Price"
            :value="form.bidPrice"
            :editing="editing"
            @input="updateForm('bidPrice', $event)"
          />
        </li>
        <li>
          <editable-currency
            label="Full Loan Payoff"
            :value="form.fullLoanPayoffAmount"
            :editing="editing"
            @input="updateForm('fullLoanPayoffAmount', $event)"
          />
        </li>
        <li>
          <editable-currency
            label="Collateral Value at Sale"
            :value="form.collateralValueAtSale"
            :editing="editing"
            @input="updateForm('collateralValueAtSale', $event)"
          />
        </li>
        <li>
          <editable-select
            label="Purchased By"
            no-status-label="No Entity"
            :value="form.purchasedBy"
            :options="purchasedByOptions"
            :editing="editing"
            @input="e => updateForm('purchasedBy', e.target.value)"
          />
        </li>
      </ul>
    </div>
  </editable-section>
</template>

<script>
import EditableSection from "./editable_section";
import EditableCurrency from "./editable_currency";
import EditableDatepicker from "./editable_date";
import EditableSelect from "./editable_select";
import BaseForeclosureSection from "./base_foreclosure_section.js";

export default {
  name: "foreclosure-status",
  components: {
    EditableSection,
    EditableCurrency,
    EditableDatepicker,
    EditableSelect,
  },
  extends: BaseForeclosureSection,
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  computed: {
    purchasedByOptions() {
      return [{ id: "Peerstreet", text: "Peerstreet" }, { id: "3rd Party", text: "3rd Party" }];
    },
  },
  methods: {
    isApplicable() {
      return this.hasForeclosureWorkoutCase();
    },
  },
};
</script>
