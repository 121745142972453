<template>
  <div class="tabs-component">
    <ul class="nav-underlined">
      <li v-for="(tab, key) in tabs" :key="key" :class="{ active: tab.active }">
        <a href="#"
           @click.prevent="selectTab(key)"
           :class="{ 'has-activity': tab.hasActivity }"
        >{{tab.name}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Object,
      default: () => {
        {
        }
      },
    },
  },
  methods: {
    selectTab(tab) {
      this.$emit("tab-selected", tab);
    },
  },
};
</script>
