<template>
  <history-provider :history="$options.history">
    <div class="container-layout nav-bar cash-receipts">
      <page-header :current-tab="$options.currentTab" />
      <component :is="currentPage" />
    </div>
  </history-provider>
</template>

<script>
import { createBrowserHistory } from "history";

import HistoryProvider from "components/shared/history_provider";
import PageHeader from "./page_header";
import Unreconciled from "./unreconciled";
import Hold from "./hold";
import Review from "./review";
import ReadyToDistribute from "./ready_to_distribute";
import Admin from "./admin";
import Calculator from "./calculator";
import MissingSeries from "./missing_series";

const PAGES = {
  unreconciled: Unreconciled,
  hold: Hold,
  review: Review,
  ready: ReadyToDistribute,
  admin: Admin,
  calculator: Calculator,
  missing_series: MissingSeries,
};

export default {
  components: {
    PageHeader,
    Unreconciled,
    Review,
    HistoryProvider,
    ReadyToDistribute,
    Admin,
    Calculator,
    MissingSeries,
  },
  computed: {
    currentPage() {
      return PAGES[this.$options.currentTab];
    },
  },
  currentTab: PSData.currentTab,
  history: createBrowserHistory(),
};
</script>
