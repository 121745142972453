<template>
  <span class="checkbox-filter checkbox-control">
    <input type="checkbox"
           :id="checkboxId"
           :checked="checkboxValue"
           @change="onChange"/>
    <label :for="checkboxId">
      <span class="control-toggle"></span>
      <span class="caption">{{ title }}</span>
      <span class="counter">({{ total }})</span>
    </label>
  </span>
</template>

<script>
import uniqueId from "lodash/uniqueId";

export default {
  name: "checkbox-filter",
  props: {
    title: String,
    value: Boolean,
    total: Number,
  },
  data() {
    return {
      checkboxId: uniqueId("checkbox-filter-id-"),
      checkboxValue: this.value,
    };
  },
  methods: {
    onChange() {
      this.checkboxValue = !this.checkboxValue;
      this.$emit("filter-options", this.checkboxValue);
    },
  },
  watch: {
    value(newValue) {
      this.checkboxValue = newValue;
    },
  },
};
</script>
