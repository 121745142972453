<template>
  <modal-form
    v-if="show"
    :save-disabled="submitDisabled"
    :show="show"
    save-button-name="Submit Request"
    title="Request Payoff"
    class="request-modal"
    @on-close="onClose"
    @on-save="submit"
  >
    <p v-if="loan" class="modal-form-description">
      {{ loan.fullAddress }}
    </p>
    <div class="panel-default panel-info payoff-request-info">
      <header>How It Works</header>
      <ol class="numbered-list">
        <li>Provide the information below.</li>
        <li>We will automatically send an email notification to all appropriate parties.</li>
        <li>FCI will assemble a quote. PeerStreet will review.</li>
        <li>A payoff quote will be returned to you within 5 business days or 8 business days during peak times (e.g., end of month, holiday seasons)</li>
      </ol>
    </div>
    <div>
      <div class="form-block">
        <label>
          CC
        </label>
        <div
          v-for="(recipient, index) in recipients"
          :key="'recipient-' + index"
          class="request-modal-item"
          :class="{ 'has-error': errors.has('email' + index) }"
        >
          <input
            v-model="recipient.value"
            v-validate="'required|email'"
            :autocomplete="'email-' + index"
            type="email"
            class="form-input"
            :disabled="index === 0"
            :name="'email-' + index"
          >
          <button v-if="index > 0" class="btn-icon delete-button" @click="deleteRecipient(index)">
            <i class="fas fa-trash-alt" />
          </button>
          <span v-show="errors.has('email-' + index)" class="error">
            Please enter a valid email address.
          </span>
        </div>
        <a href="#" v-if="canAddRecipients" @click.prevent="addRecipient">+ Add Email Recipient</a>
      </div>
      <div
        class="form-block desired-payoff-input request-modal-item"
        :class="{ 'has-error': errors.has('desiredPayoffDate')}"
      >
        <with-errors on="request_details.desired_payoff_date" :errors="backendErrors">
          <label>Desired Payoff Date</label>
          <input
            v-model="desiredPayoffDate"
            v-validate.persist="'required'"
            v-datepicker="{maxDate: payoffRequestDaysLimit, minDate: new Date}"
            v-mask:date
            class="datepicker form-input"
            name="desiredPayoffDate"
            type="text"
            @focusin="fixDatepickerPosition"
          >
        </with-errors>
        <div class="form-input-note">Must be in the next {{ payoffRequestDaysLimit }} days</div>
        <span v-show="errors.has('desiredPayoffDate')" class="error">
          Please specify desired payoff date
        </span>
      </div>
      <div v-if="showCemaToggle" class="form-block">
        <label>Will there be new financing through a CEMA loan?</label>
        <div class="radio-control-blue radio-control-inline">
          <div class="radio">
            <input
              id="non-cema-loan"
              v-model="cemaLoan"
              :value="false"
              type="radio"
            >
            <label for="non-cema-loan">
              No
            </label>
          </div>

          <div class="radio">
            <input
              id="cema-loan"
              v-model="cemaLoan"
              :value="true"
              type="radio"
            >
            <label for="cema-loan">
              Yes
            </label>
          </div>
        </div>
        <div v-if="isCemaLoan" class="panel-default">
          Please note that there will be a $2,000 fee for any loans with CEMA financing.
        </div>
      </div>
      <div v-if="showPartialPayoffToggle">
        <div class="form-block">
          <label>Multicollateral Payoff</label>
          <div class="radio-control-blue radio-control-inline">
            <div class="radio">
              <input
                id="full-multicollateral-payoff"
                v-model="multicollateralPayoff"
                value="full"
                type="radio"
                :disabled="isCemaLoan"
              >
              <label for="full-multicollateral-payoff">
                Full Payoff
              </label>
            </div>
            <div class="radio">
              <input
                id="partial-multicollateral-payoff"
                v-model="multicollateralPayoff"
                value="partial"
                :disabled="isCemaLoan"
                type="radio"
              >
              <label for="partial-multicollateral-payoff">
                Partial Payoff
              </label>
            </div>
          </div>
        </div>
        <div v-if="isPartialPayoff" class="form-block">
          <label>Payoff Address</label>
          <div v-for="(address, index) in payoffAddresses" :key="'address-' + index" class="request-modal-item">
            <with-errors on="request_details.payoff_addresses" :errors="backendErrors">
              <input v-model="address.value" type="text" class="form-input" autocomplete="off">
              <button class="btn-icon delete-button" @click.prevent="deletePayoffAddress(index)" :disabled="!isPayoffAddressDeletable">
                <i class="fas fa-trash-alt" />
              </button>
            </with-errors>
          </div>
          <a href="#" @click.prevent="addPayoffAddress">+ Add Address</a>
        </div>
      </div>
      <div class="form-block">
        <label>Notes (Optional)</label>
        <textarea
          v-model="notes"
          class="form-input"
          cols="30"
          rows="4"
        />
      </div>
    </div>
  </modal-form>
</template>

<script>
import ModalForm from "components/modal_form";
import AjaxService from "services/ajax_service";
import { error, success } from "services/notification";
import WithErrors from "components/shared/with_errors";
import toPlainObject from "utils/to_plain_object";
import { RequestType } from "models/loan_requests";

const MAX_RECIPIENTS = 5;

export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "payoff-request-modal",
  components: { ModalForm, WithErrors },
  props: {
    show: Boolean,
    loan: Object,
    isLender: Boolean,
  },
  data() {
    const user = PSData.user || {};
    const user_properties = user.properties || {};

    return {
      recipients: [{ value: user_properties.email }],
      payoffAddresses: [],
      desiredPayoffDate: null,
      notes: "",
      cemaLoan: false,
      multicollateralPayoff: "full",
      inFlight: false,
      backendErrors: {},
      payoffRequestDaysLimit: 30,
    };
  },
  computed: {
    isPartialPayoff() {
      return this.multicollateralPayoff === "partial";
    },
    showCemaToggle() {
      return this.loan && this.loan.isCemaFinancingSupported;
    },
    isCemaLoan() {
      return this.cemaLoan;
    },
    showPartialPayoffToggle() {
      return this.loan && this.loan.isPartialPayoffAllowed;
    },
    isPayoffAddressDeletable() {
      return (this.payoffAddresses || []).length > 1;
    },
    canAddRecipients() {
      return this.recipients.length < MAX_RECIPIENTS + 1;
    },
    submitDisabled() {
      return !this.isLender || (this.desiredPayoffDate === null || this.desiredPayoffDate === "");
    },
  },
  watch: {
    loan: function(newLoan) {
      if (newLoan) {
        const user = PSData.user || {};
        const user_properties = user.properties || {};

        this.recipients = [{ value: user_properties.email }];
        this.payoffAddresses = [];
        this.desiredPayoffDate = null;
        this.notes = "";
        this.multicollateralPayoff = "full";
        this.cemaLoan = false;
      }
    },
    cemaLoan: function(newValue) {
      if (newValue) {
        this.multicollateralPayoff = "full";
      }
    },
    multicollateralPayoff: function(newValue) {
      if (newValue === "partial") {
        this.payoffAddresses = [{ value: this.loan.fullAddress }];
      } else {
        this.payoffAddresses = [];
      }
    },
  },
  methods: {
    onClose() {
      this.desiredPayoffDate = null;
      this.notes = "";

      this.$emit("close");
    },
    submit() {
      this.$validator.validateAll().then(result => {
        if (!result) return;

        if (this.inFlight) return;

        this.inFlight = true;
        this.backendErrors = {};
        const url = this.loan.loanRequestsPath;
        const payload = {
          request_type: RequestType.Payoff,
          request_recipients: this.recipients.map(recipient => recipient.value),
          request_details: {
            desired_payoff_date: this.desiredPayoffDate,
            cema: this.isCemaLoan,
            multicollateral_payoff: this.multicollateralPayoff,
            payoff_addresses: this.payoffAddresses.map(address => address.value),
            notes: this.notes,
          },
        };

        return AjaxService.post(url, payload, { "content-type": "application/json" })
          .then(request => {
            success("Payoff has been request sent.");
            this.$emit("payoff-request-sent", this.loan, request);
            this.onClose();
          })
          .catch(({ response, data }) => {
            if (response.status === 422) {
              this.backendErrors = data.errors;
            } else {
              error("Something went wrong");
              Bugsnag.notify(
                { name: "Request Error", message: "Unable to create loan request" },
                { metaData: { custom: { response: toPlainObject(response), payload } } }
              );
              this.onClose();
            }
          })
          .finally(() => {
            this.inFlight = false;
          });
      });
    },
    addRecipient() {
      this.recipients.push({ value: "" });
    },
    deleteRecipient(index) {
      this.recipients.splice(index, 1);
    },
    addPayoffAddress() {
      this.payoffAddresses.push({ value: "" });
    },
    deletePayoffAddress(index) {
      if (this.payoffAddresses.length > 1) {
        this.payoffAddresses.splice(index, 1);
      }
    },
    fixDatepickerPosition(event) {
      const datepicker = $(event.target).datepicker("widget");
      const rect = event.target.getBoundingClientRect();
      const top = rect.top + event.target.offsetHeight;

      datepicker.css({ top: `${top}px` });
    },
  },
};
</script>
