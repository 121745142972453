<template>
  <div class="worklist-cell role-cell" :class="assigneeType">
    <span :class="{ 'attention-indicator': attentionRequired }">
      <img @click.stop v-if="hasAssignee && !updating" :src="avatarUrl" class="avatar"/>
    </span>

    <div class="ps-loader ps-loader-small" v-if="updating"></div>

    <div class="dropdown" v-if="!updating">
      <a @click.prevent="showDropdown" href="#" :id="`${assigneeType}Dropdown`" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true" :class="{'edit-link': hasAssignee}"></a>

      <div @click.stop class="dropdown-menu" :aria-labelledby="`${assigneeType}Dropdown`">
        <select2 v-if="assigneeDropdownEnabled" @click.stop class="form-control" v-model="assignee" :options="{placeholder: `${assigneeType}`}">
          <option @click.stop v-for="user in users" :value="user.id" :key="user.id">{{ user.name }}</option>
        </select2>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from "components/shared/select2";

export default {
  name: "workitem-assignee",
  components: { Select2 },
  props: {
    attentionRequired: Boolean,
    assigneeId: Number,
    assigneeType: String,
    avatarUrl: String,
    updating: Boolean,
    users: Array,
    canUpdateWorklistData: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      assignee: this.assigneeId,
      renderAssigneeDropdown: false,
    };
  },
  computed: {
    hasAssignee() {
      return !!this.assignee;
    },
    assigneeDropdownEnabled() {
      return this.renderAssigneeDropdown && this.canUpdateWorklistData;
    },
  },
  watch: {
    assignee(newId, oldId) {
      if (newId !== oldId) {
        this.$emit("assignee-selected", newId);
      }
    },
  },
  methods: {
    showDropdown() {
      this.renderAssigneeDropdown = true;
    },
  },
};
</script>
