import isEqual from "lodash/isEqual";
import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";

export default class License {
  static initLicenses(rawLicenses) {
    return rawLicenses.map(rawLicense => new License(rawLicense));
  }

  static nullLicense() {
    return new License({
      id: undefined,
      licenseName: undefined,
      licenseNumber: undefined,
      licenseType: undefined,
      licenseeName: undefined,
      status: undefined,
      startDate: undefined,
      expirationDate: undefined,
    });
  }

  constructor(rawLicense) {
    this.rawLicense = rawLicense;
    this.id = rawLicense.id;
    this.reset();
  }

  reset() {
    this.licenseName = this.rawLicense.licenseName;
    this.licenseNameText = this.rawLicense.licenseNameText;
    this.licenseNumber = this.rawLicense.licenseNumber;
    this.licenseType = this.rawLicense.licenseType;
    this.licenseTypeText = this.rawLicense.licenseTypeText;
    this.licenseeName = this.rawLicense.licenseeName;
    this.status = this.rawLicense.status;
    this.statusText = this.rawLicense.statusText;
    this.startDate = this.rawLicense.startDate;
    this.expirationDate = this.rawLicense.expirationDate;
  }

  clone() {
    return new License(this.toPlainObject());
  }

  changed() {
    return !isEqual(this.rawLicense, this.toPlainObject());
  }

  persisted() {
    return !!this.id;
  }

  toPlainObject({ toSnakeCase } = { toSnakeCase: false }) {
    const license = {
      id: this.id,
      licenseName: this.licenseName,
      licenseNumber: this.licenseNumber,
      licenseType: this.licenseType,
      licenseeName: this.licenseeName,
      status: this.status,
      startDate: this.startDate,
      expirationDate: this.expirationDate,
    };

    if (toSnakeCase) return mapKeys(license, (_, key) => snakeCase(key));

    return license;
  }
}
