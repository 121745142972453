<template>
  <div>
    <div class="columns">
      <base-text-field
        v-if="definition.isNew"
        v-model="definition.systemName"
        name="systemName"
        label="System Name"
        placeholder="System Name"
        class="column"
        rules="required"
        :errors="errors.system_name"
      />
      <base-field
        v-else
        label="System Name"
        class="column"
      >
        {{ definition.systemName }}
      </base-field>
    </div>
    <div class="columns">
      <base-select-field
        v-model="definition.durationType"
        :options="durationTypes"
        name="durationType"
        label="Duration Type"
        placeholder="Duration Type"
        class="column"
        rules="required"
      />
    </div>
    <div class="columns">
      <base-number-field
        v-model="definition.duration"
        name="duration"
        label="Duration"
        placeholder="Duration"
        class="column"
      />
    </div>
    <div class="columns">
      <base-select-field
        v-model="definition.requiredRole"
        :options="requiredRoles"
        name="requiredRole"
        label="Required Role"
        placeholder="requiredRole"
        class="column"
        rules="required"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    definition: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    requiredRoles: {
      type: Array,
      default: () => [],
    },
    durationTypes: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
