<template>
  <table class="table">
    <thead>
      <tr>
        <th>
          Source of Capital
        </th>
        <th class="text-right">
          Available
        </th>
        <th class="text-right">
          Sale
        </th>
        <th class="text-right">
          Remaining
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="account in filteredAccounts"
        :key="account.id"
      >
        <td>
          {{ account.name }}
        </td>
        <td class="text-right">
          {{ account.available | accounting }}
        </td>
        <td class="text-right">
          {{ saleAmount(account) | accounting }}
        </td>
        <td class="text-right">
          {{ remainingAmount(account) | accounting }}
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <th :colspan="2">
          Total
        </th>
        <th class="text-right">
          {{ saleTotal | accounting }}
        </th>
        <th />
      </tr>
    </tfoot>
  </table>
</template>

<script>
import filter from "lodash/filter";
import uniq from "lodash/uniq";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import includes from "lodash/includes";
import find from "lodash/find";

import AjaxService from "services/ajax_service";
import Account from "models/bank_center/funding_entity";

export default {
  name: "SourceOfCapitalTable",
  props: {
    batch: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    accounts: [],
  }),
  computed: {
    fundingEntityAllocationIds() {
      return uniq(
        map(this.form.loanSales, ({ fundingEntityAllocationId }) =>
          parseInt(fundingEntityAllocationId, 10)
        )
      );
    },
    multipliers() {
      return filter(PSData.fundingEntityAllocationMultipliers, ({ fundingEntityAllocationId }) =>
        includes(this.fundingEntityAllocationIds, fundingEntityAllocationId)
      );
    },
    fundingEntityIds() {
      return map(this.multipliers, "fundingEntityId");
    },
    filteredAccounts() {
      return filter(this.accounts, ({ id }) => includes(this.fundingEntityIds, id));
    },
    saleTotal() {
      return sumBy(this.filteredAccounts, this.saleAmount);
    },
  },
  async created() {
    try {
      const { accounts } = await AjaxService.get("/manage/bank_center/funding_entities");
      this.accounts = map(accounts, account => new Account(account));
    } catch (e) {
      AjaxService.sendNotification("Something Went Wrong", "error");
    }
  },
  methods: {
    remainingAmount(account) {
      return account.available + this.saleAmount(account);
    },
    getAllocationId(loanSale) {
      const { fundingEntityAllocationId: id } = find(this.form.loanSales, {
        loanPsId: loanSale.loan.id,
      });
      return id;
    },
    saleAmount(account) {
      return sumBy(this.batch.loanSales, loanSale => {
        const multiplier = find(
          PSData.fundingEntityAllocationMultipliers,
          ({ fundingEntityAllocationId, fundingEntityId }) =>
            this.getAllocationId(loanSale) === fundingEntityAllocationId.toString() &&
            account.id === fundingEntityId
        );

        return multiplier ? multiplier.multiplier * loanSale.saleAmount * -1 : 0;
      });
    },
  },
};
</script>
