<template>
  <tr v-if="viewMode" class="view-row">
    <td class="form-value">
      {{ record.typeLabel }}
    </td>
    <td class="form-value">
      {{ record.date }}
    </td>
    <td class="form-value">
      {{ record.amount | money }}
    </td>
    <td class="form-value">
      {{ record.paymentMethodLabel }}
    </td>
    <td class="form-value payee-value">
      {{ record.payee }}
    </td>
    <td class="actions">
      <button class="btn-icon" @click="onEdit">
        <i class="fas fa-pencil-alt" />
      </button>
      <button class="btn-icon" @click="onDelete">
        <i class="fas fa-trash-alt" />
      </button>
    </td>
  </tr>
  <tr v-else-if="editMode">
    <td>
      <select v-model="record.type" class="form-control">
        <option v-for="(name, value) in record.types" :key="value" :value="value">
          {{ name }}
        </option>
      </select>
    </td>
    <td>
      <input
        v-model="record.date"
        v-datepicker
        v-mask:date
        name="date"
        class="form-control"
      />
    </td>
    <td>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          v-model="record.amount"
          v-mask:currency
          name="amount"
          class="form-control"
        />
      </div>
    </td>
    <td>
      <select v-if="!isNewRecord" v-model="record.paymentMethod" class="form-control">
        <option v-for="(name, value) in record.paymentMethods" :key="value" :value="value">
          {{ name }}
        </option>
      </select>
    </td>
    <td class="payee-value">
      <input
        v-model="record.payee"
        name="payee"
        class="form-control"
      />
    </td>
    <td class="actions">
      <button class="btn-icon" @click="onCancel">
        <i class="fas fa-times" />
      </button>
      <button class="btn-icon" @click="onSave">
        <i class="fas fa-check" />
      </button>
    </td>
  </tr>
</template>

<script>
import AjaxService, { ERROR_MESSAGE } from "services/ajax_service";

export default {
  name: "loan-tax-installment-record",
  props: {
    record: {
      type: Object,
      required: true,
    },
    recordRoute: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isEdit: false,
    };
  },
  computed: {
    viewMode() {
      return !this.isEdit && !this.isNewRecord;
    },
    editMode() {
      return this.isEdit || this.isNewRecord;
    },
    recordUrl() {
      const id = this.record.id || "";
      return `${this.recordRoute}/${id}`;
    },
    isNewRecord() {
      return !this.record.id;
    },
  },
  methods: {
    onCancel() {
      this.isEdit = false;
      this.$emit("cancel");
      this.record.reset();
    },
    onEdit() {
      this.isEdit = true;
    },
    onSave() {
      const messages = {
        onSuccess: "Changes saved! Please regenerate the amortization schedule.",
        onError: this.onError,
      };
      const requestMethod = this.isNewRecord ? "post" : "patch";

      return AjaxService.withNotifications(messages)
        [requestMethod](this.recordUrl, this.record.objectToSave)
        .then(data => {
          if (this.isNewRecord) {
            this.$emit("create", data);
          } else {
            this.record.update(data);
          }

          this.isEdit = false;
        });
    },
    onDelete() {
      if (!window.confirm("Are you sure?")) return;

      AjaxService.withNotifications()
        .delete(this.recordUrl, this.record.objectToSave)
        .then(() => {
          this.$emit("delete", this.record);
        });
    },
    onError({ data }) {
      return {
        message: data.error || ERROR_MESSAGE,
        status: "error",
      };
    },
  },
};
</script>
