import runningSum from "utils/running_sum";
import MarketDataSource from "models/market_data/source";
import MonthlyStats from "models/market_data/charts/monthly_stats";
import { MultiSource } from "models/market_data/charts/home_index";

class AllTimeSalesSource extends MarketDataSource {
  constructor(data) {
    super();

    this.sales = data;

    super.success();
  }

  get sources() {
    return [new MonthlyStats("sales", this.sales, "month", "amount")];
  }
}

class AllTimeSales extends MultiSource {
  constructor(data) {
    super();

    let trend = runningSum(data);
    let mostRecent = trend[trend.length - 1];

    this.totalMonths = trend.length;
    this.totalCount = (mostRecent && mostRecent.count) || 0;
    this.totalAmount = (mostRecent && mostRecent.amount) || 0;

    this._sources = {
      sales: new AllTimeSalesSource(trend),
    };
  }

  get name() {
    return "All-time Loans Funded Through PeerStreet";
  }
}

export default AllTimeSales;
