<template>
  <div class="monthly-loan-sales lender-stats lender-section">
    <h3>Maturing Next 60 Days</h3>
    <p>{{ stats.amount | money }}</p>
    <span>{{ stats.count | pluralize("Loan") }}</span>
    <span>&bull;</span>
    <span><a :href="routes.activeLoansUrl">View</a></span>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      stats: PSData.maturingLoansStats || {},
      routes: PSData.routes || {},
    };
  },
};
</script>
