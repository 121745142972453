<template>
  <tr>
    <td>
      <span v-if="readonly">{{user.name}}</span>
      <a href="#" v-if="!readonly" @click.prevent="$emit('select-user', user)">
        {{user.name}}
      </a>
    </td>
    <td>{{roles}}</td>
  </tr>
</template>

<script>
export default {
  props: {
    readonly: Boolean,
    user: Object,
  },
  computed: {
    roles() {
      return this.user.roles.map(role => role).join(", ");
    },
  },
};
</script>
