<template>
  <params-provider v-slot="slotProps">
    <unreconciled :filters="slotProps.params" @filter="slotProps.setParams" />
  </params-provider>
</template>

<script>
import ParamsProvider from "components/shared/params_provider";
import Unreconciled from "./unreconciled";

export default {
  components: {
    Unreconciled,
    ParamsProvider,
  },
};
</script>
