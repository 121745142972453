import FetchPricingAtTargetLoanAmount from "./fetch_pricing_at_target_loan_amount";
import { maxLtvBaseLine } from "../utils/helpers";

export default class FetchMaxLeverage {
  static async call(scenario, serviceClient) {
    const maxLeveragePricing = await FetchPricingAtTargetLoanAmount.call(
      scenario,
      maxLtvBaseLine(scenario.loanProduct),
      serviceClient,
      "maxLeverageLoanAmount"
    );

    return {
      ...maxLeveragePricing,
      maxLeverage: maxLeveragePricing.cbFlag ? false : true,
    };
  }
}
