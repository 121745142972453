<template>
  <div class="alert" v-if="hasErrors" :class="[this.alertType]">
    <strong>{{ errorHeader }}</strong>

    <br/>
    <br/>

    <ul>
      <li v-for="error in errors" :key="error">{{ error }}</li>
    </ul>
  </div>
</template>
<script>
import isEmpty from "lodash/isEmpty";

export default {
  name: "StatementErrors",
  props: ["errors", "type"],
  data() {
    return {
      errorHeaders: {
        save: "Cannot save statement",
        refresh: "Refresh before submitting. Values have changed since last update",
        cancel:
          "The following statement is no longer valid for the following reasons. Please cancel the current invalid statement and generate a new statement.",
        default: "Error occurred in statement",
        warning: "Warning",
      },
    };
  },
  computed: {
    errorHeader() {
      return this.errorHeaders[this.type || "default"];
    },
    alertType() {
      const alertTypes = {
        warning: "alert-warning",
      };
      return alertTypes[this.type] || "alert-danger";
    },
    hasErrors() {
      return !isEmpty(this.errors);
    },
  },
};
</script>
