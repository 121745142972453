<template>
  <div class="asset-management-subsection-header">
    <slot />
  </div>
</template>
<script>
export default {
  name: "asset-management-subsection-header",
};
</script>
