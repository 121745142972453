import moment from "moment";

const EMPTY_ANALYST = () => ({ id: 0 });
const EMPTY_TASK_ASSIGNEES = () => [0];
const EMPTY_LENDER = () => ({});
const EMPTY_DOCUMENTS = () => ({ count: 0 });

export default class LoanAssociationMerger {
  constructor(loans) {
    this._operations = [];
    this._loans = loans || [];
  }

  get loans() {
    this._loans.forEach(loan => {
      this._operations.forEach(operation => operation(loan));
    });

    return this._loans;
  }

  makeLoansVisible() {
    this._operations.push(loan => {
      loan.visibleAsFiltered = true;
    });

    return this;
  }

  applyDynamicDates(serverTime) {
    serverTime = serverTime || +Infinity;

    let serverDateTime = moment.unix(serverTime).utcOffset(0, true);

    this._operations.push(loan => {
      if (loan.lastUpdatedAt) {
        let lastUpdatedAt = moment.unix(loan.lastUpdatedAt).utcOffset(0, true);
        loan.daysSinceLastUpdate = serverDateTime.diff(lastUpdatedAt, "days");
      } else {
        loan.daysSinceLastUpdate = 0;
      }

      if (loan.statusChangedAt) {
        let statusChangedAt = moment.unix(loan.statusChangedAt).utcOffset(0, true);
        loan.daysInCurrentStatus = serverDateTime.diff(statusChangedAt, "days");
      } else {
        loan.daysInCurrentStatus = 0;
      }
    });

    return this;
  }

  mergeWithAnalysts(analysts) {
    analysts = analysts || [];

    let analystIdToAnalyst = {};
    analysts.forEach(analyst => (analystIdToAnalyst[analyst.id] = analyst));

    this._operations.push(loan => {
      loan.analyst = analystIdToAnalyst[loan.analystId] || EMPTY_ANALYST();
    });

    return this;
  }

  mergeWithQcApprover(analysts) {
    analysts = analysts || [];

    let analystIdToAnalyst = {};
    analysts.forEach(analyst => (analystIdToAnalyst[analyst.id] = analyst));

    this._operations.push(loan => {
      loan.qcApprover = analystIdToAnalyst[loan.qaApproverId] || EMPTY_ANALYST();
    });

    return this;
  }

  mergeWithLenders(lenders) {
    lenders = lenders || [];

    let lenderIdToLender = {};
    lenders.forEach(lender => (lenderIdToLender[lender.id] = lender));

    this._operations.push(loan => {
      loan.lender = lenderIdToLender[loan.lenderId] || EMPTY_LENDER();
    });

    return this;
  }

  mergeWithDocuments(documents) {
    documents = documents || [];

    let loanIdToDocument = {};
    documents.forEach(document => (loanIdToDocument[document.loanId] = document));

    this._operations.push(loan => {
      loan.document = loanIdToDocument[loan.id] || EMPTY_DOCUMENTS();
    });

    return this;
  }

  mergeWithTaskAssigneeIds(assignees) {
    assignees = assignees || [];

    let loanIdToTaskAssigneeIds = {};
    assignees.forEach(item => (loanIdToTaskAssigneeIds[item.loanId] = item.assigneeIds));

    this._operations.push(loan => {
      loan.taskAssigneeIds = loanIdToTaskAssigneeIds[loan.id] || EMPTY_TASK_ASSIGNEES();
    });

    return this;
  }
}

PS.Collections.LoanAssociationMerger = LoanAssociationMerger;
