<template>
  <div>
    <slot
      :batch="clonedBatch"
      :form="clonedForm"
      :errors="clonedErrors"
      :isValidating="isValidating"
      :isSelling="isSelling"
      :validationPassed="validationPassed"
      :handleFormUpdate="handleFormUpdate"
      :handleValidate="handleValidate"
      :handleSell="handleSell"
      :handleReset="handleReset"
    />
  </div>
</template>

<script>
import set from "lodash/set";
import find from "lodash/find";
import snakeCase from "lodash/snakeCase";

import AjaxService from "services/ajax_service";

export default {
  data: () => ({
    batch: null,
    form: {
      saleType: undefined,
      legalOwnerId: undefined,
      fundingEntityId: undefined,
      saleDate: undefined,
      buyer: undefined,
      interestCalculationMethod: undefined,
      transferReserves: undefined,
      loanSales: [],
      seriesSales: [],
    },
    errors: [],
    isValidating: false,
    isSelling: false,
    validationPassed: false,
  }),
  computed: {
    clonedBatch() {
      return this.clone(this.batch);
    },
    clonedForm() {
      return this.clone(this.form);
    },
    clonedErrors() {
      return this.clone(this.errors);
    },
    snapshot() {
      return this.batch.loanSales.map(({ loan: { id }, initialAmount, saleAmount }) => ({
        loan_id: id,
        initial_amount: initialAmount,
        sale_amount: saleAmount,
      }));
    },
    payload() {
      const isSelling = !!this.batch;
      const {
        saleType,
        legalOwnerId,
        fundingEntityId,
        saleDate,
        buyer,
        interestCalculationMethod,
        transferReserves,
        loanSales,
        seriesSales,
      } = this.form;
      const payload = {
        sale_type: snakeCase(saleType),
        legal_owner_id: legalOwnerId,
        funding_entity_id: fundingEntityId,
        sale_date: saleDate,
        buyer,
        interest_calculation_method: interestCalculationMethod,
        transfer_reserves: transferReserves,
        series_ps_ids: seriesSales.map(({ seriesPsId }) => seriesPsId),
        loan_sales: loanSales.map(
          ({
            loanPsId,
            salesPrice,
            fundingEntityAllocationId,
            fundingEntityMaxAllocationAmount,
            interestDays,
            overrides,
            allocationAmount,
          }) => ({
            loan_ps_id: loanPsId,
            sales_price: salesPrice,
            interest_per_diem_days: interestDays,
            funding_entity_allocation_id: fundingEntityAllocationId,
            funding_entity_max_allocation_amount: fundingEntityMaxAllocationAmount,
            overrides: overrides,
            allocation_amount: allocationAmount,
            selling: isSelling,
          })
        ),
      };

      if (this.batch) {
        payload.snapshot = this.snapshot;
      }

      return payload;
    },
  },
  methods: {
    handleFormUpdate(field, value) {
      const form = Object.assign({}, this.form);
      set(form, field, value);
      this.form = form;
    },
    handleReset() {
      this.batch = null;
      this.errors = [];
      this.isValidating = false;
      this.isSelling = false;
      this.validationPassed = false;
      this.removeDefaultFundingEntityAllocationIds();
    },
    async handleValidate() {
      this.isValidating = true;
      try {
        const batch = await AjaxService.post("/manage/loan_sale_batches/validate", this.payload, {
          "content-type": "application/json",
        });
        this.handleValidationSuccess(batch);
      } catch ({ response: { status }, data }) {
        if (status === 422) {
          this.handleValidationFailure(data);
        } else {
          this.handleServerException();
        }
      } finally {
        this.isValidating = false;
      }
    },
    async handleSell() {
      this.isSelling = true;
      try {
        const { location } = await AjaxService.post("/manage/loan_sale_batches", this.payload, {
          "content-type": "application/json",
        });

        window.location.href = location;
      } catch ({ response: { status }, data }) {
        if (status === 422) {
          this.handleValidationFailure(data);
        } else {
          this.handleServerException();
        }
      } finally {
        this.isSelling = false;
      }
    },
    handleServerException() {
      AjaxService.sendNotification("Something Went Wrong, please refresh page.", "error");
    },
    handleValidationFailure({ errors }) {
      this.batch = null;
      this.validationPassed = false;
      this.errors = errors;
    },
    handleValidationSuccess(batch) {
      this.batch = batch;
      this.validationPassed = true;
      this.errors = [];
      this.addDefaultFundingEntityAllocationIds();
    },
    addDefaultFundingEntityAllocationIds() {
      const form = Object.assign({}, this.form);
      this.batch.loanSales.forEach(({ loan: { id: loanPsId }, fundingEntityAllocationId }) => {
        const loanSale = find(form.loanSales, { loanPsId });
        if (loanSale) {
          loanSale.fundingEntityAllocationId = fundingEntityAllocationId;
        }
      });
      this.form = form;
    },
    removeDefaultFundingEntityAllocationIds() {
      const form = Object.assign({}, this.form);
      form.loanSales.forEach(loanSale => delete loanSale.fundingEntityAllocationId);
      this.form = form;
    },
    clone(value) {
      return JSON.parse(JSON.stringify(value));
    },
  },
};
</script>
