import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";
import some from "lodash/some";
import ResponsibleRole from "./responsible_role";
import LoanSponsor from "./loan_sponsor";

export default class ResponsibleParty extends LoanSponsor {
  static deserialize(rawData) {
    return new ResponsibleParty(rawData);
  }

  static buildEmpty() {
    return new ResponsibleParty({ responsibleRoles: [new ResponsibleRole()] });
  }

  update(responsibleParty) {
    this._raw = responsibleParty;
    this.id = responsibleParty.id;
    this.name = responsibleParty.name;
    this.residency = responsibleParty.residency;
    this.residencyLabel = responsibleParty.residencyLabel;
    this.type = responsibleParty.type;
    this.typeLabel = responsibleParty.typeLabel;
    this.tin = responsibleParty.tin;
    this.bankruptcyDate = responsibleParty.bankruptcyDate;
    this.foreclosureDate = responsibleParty.foreclosureDate;
    this.roles = responsibleParty.responsibleRoles.map(rawRole =>
      ResponsibleRole.deserialize(rawRole)
    );
  }

  isValid() {
    return false;
  }

  addNewRole() {
    this.roles = [...this.roles, new ResponsibleRole()];
  }

  removeRole(role) {
    this.roles.splice(this.roles.indexOf(role), 1);
  }

  get canAddMoreRoles() {
    return this.isIndividual || !this.roles.length;
  }

  get isIndividualWise() {
    const selectedDefinitions = this.roles.map(role => role.definition);

    return some(selectedDefinitions, { entity: false });
  }

  toPlainObject({ toSnakeCase } = { toSnakeCase: false }) {
    const responsibleParty = {
      id: this.id,
      name: this.name,
      residency: this.residency,
      responsiblePartyType: this.type,
      tin: (this.tin || "").replace(/[^\d]/g, ""),
      bankruptcyDate: this.bankruptcyDate,
      foreclosureDate: this.foreclosureDate,
      responsibleRoles: this.roles.map(role => role.toPlainObject({ toSnakeCase: true })),
    };

    if (toSnakeCase) return mapKeys(responsibleParty, (_, key) => snakeCase(key));

    return responsibleParty;
  }
}
