<template>
  <div
    class="loan-task-editable-field"
    :class="{ 'disabled': disabled }"
    :title="title"
    data-toggle="tooltip"
    data-placement="bottom"
    @click="editValue"
  >
    <slot />
    <i
      v-if="canBeDeleted"
      class="fas fa-times loan-task-editable-field-delete"
      aria-hidden="true"
      @click.stop="deleteValue"
    />
    <div v-show="isLoading" class="ps-loader ps-loader-small loan-task-editable-field-loader" />
  </div>
</template>

<script>
export default {
  name: "loan-task-editable-field",
  props: {
    title: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    canBeDeleted() {
      return !this.disabled && this.deletable;
    },
  },
  mounted() {
    $(this.$el).tooltip();
  },
  beforeDestroy() {
    $(this.$el).tooltip("dispose");
  },
  methods: {
    editValue() {
      if (this.disabled) return;

      this.$emit("edit");
    },
    deleteValue() {
      if (this.disabled) return;

      if (!window.__ps_test_mode) {
        if (!window.confirm("Are you sure you want to delete?")) return;
      }

      this.$emit("delete");
    },
  },
};
</script>
