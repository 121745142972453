<template>
  <div class="loan-task-view-header">
    <div class="loan-task-view-header-back" @click="$emit('on-back-btn')">
      <img
        class="loan-task-view-header-back-icon"
        :src="require('images/icons/icon_left_arrow_task.svg')"
      />
      Back
    </div>
    <div v-if="showCompleteButton" class="loan-task-buttons-container custom-loan-task-buttons">
      <div class="loan-task-buttons">
        <button class="btn btn-action-cta loan-task-btn" @click="$emit('on-complete-btn')">
          <i class="fas fa-check"></i>
          Submit
        </button>
      </div>
    </div>
    <div v-if="task.isPending" class="loan-task-buttons-container custom-loan-task-buttons">
      <div class="loan-task-buttons">
        <div v-popover class="task-status">Pending</div>
        <div class="popover-content hidden">
          <div class="task-status-popover">
            Task has been submitted and is currently with PeerStreet.
          </div>
        </div>
      </div>
    </div>
    <div v-if="task.isConfirmed" class="loan-task-buttons-container custom-loan-task-buttons-completed">
      <i class="fas fa-check"></i>
      Completed
    </div>
  </div>
</template>

<script>
export default {
  name: "loan-task-header",
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showCompleteButton() {
      return this.task.isOpen;
    },
  },
};
</script>
