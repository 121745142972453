<template>
  <div :class="mainWrapperClass" >
    <div v-if="editing" class="form-group">
      <label v-if="label" class="editable-field-label control-label">
        {{ label }}
      </label>
      <date-picker
        input-class-name="form-control"
        :value="inputDisplayValue"
        :options="{ static: false, dateFormat: 'm/d/Y', allowInput: true, minDate: this.minDate }"
        :disabled="disabled"
        @click.native.stop
        @input="handleInput"
      />
    </div>

    <label v-if="label && !editing" :class="labelClass" >
      {{ label }}
    </label>
    <div class="editable-field-value">
      <slot name="value" v-if="!editing">{{ displayValue }}</slot>
    </div>
  </div>
</template>

<script>
import DatePicker from "components/shared/date_picker";

export default {
  name: "EditableDatepicker",
  components: { DatePicker },
  props: {
    value: [String, Number, Function],
    label: String,
    className: String,
    required: Boolean,
    inputClasses: {
      type: Array,
      default: () => [],
    },
    callback: {
      type: Function,
      default: new Function(),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: [Date, String],
      default: null,
    },
  },
  computed: {
    mainWrapperClass() {
      return { editing: this.editing, "editable-field-container": true };
    },
    labelClass() {
      return { [`${this.className || "loan-attribute"}-label`]: true, "control-label": true };
    },
    inputClass() {
      const inputClasses = this.inputClasses.reduce((acc, c) => ({ ...acc, [c]: true }), {});
      return {
        "form-control": true,
        required: this.required,
        ...inputClasses,
      };
    },
    resolvedValue() {
      return typeof this.value === "function" ? this.value() : this.value;
    },
    displayValue() {
      return this.resolvedValue;
    },
    inputDisplayValue() {
      return this.resolvedValue;
    },
  },
  methods: {
    handleInput(value) {
      this.callback(value);
      this.$emit("input", value);
    },
  },
};
</script>
