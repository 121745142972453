<template>
  <div id="withdrawn-declined-loans-list" class="withdrawn-declined-loans-list" v-cloak>
    <div class="panel-header loan-table-header">
      Showing {{ currentLoansCount }} of {{ totalLoansCount }}
      totalling {{ totalBalance | money(2) | missingValue }}

      <div class="filters">
        <span>Filters: </span>

        <checkbox-filter
          title="Withdrawn"
          :value="filters.withdrawn"
          :total="filterTotal(Filters.Withdrawn)"
          @filter-options="applyFilter(Filters.Withdrawn, ...arguments)"
        />

        <checkbox-filter
          title="Declined"
          :value="filters.declined"
          :total="filterTotal(Filters.Declined)"
          @filter-options="applyFilter(Filters.Declined, ...arguments)"
        />
      </div>
    </div>

    <table class="table loans-list-table-body">
      <thead>
        <tr>
          <th class="column-sortable loan-status-wrapper">
            <sorting-header
              title="Status"
              :order="findDirectionBy(SortingColumns.Status)"
              @on-change-direction="sortBy(SortingColumns.Status, ...arguments)"
            />
          </th>
          <th class="column-sortable loan-submitted-date">
            <sorting-header
              title="Submitted Date"
              :order="findDirectionBy(SortingColumns.SubmittedDate)"
              @on-change-direction="sortBy(SortingColumns.SubmittedDate, ...arguments)"
            />
          </th>
          <th class="column-sortable loan-pass-date">
            <sorting-header
              title="Pass Date"
              :order="findDirectionBy(SortingColumns.PassDate)"
              @on-change-direction="sortBy(SortingColumns.PassDate, ...arguments)"
            />
          </th>
          <th class="column-sortable loan-full-address">
            <sorting-header
              title="Address"
              :order="findDirectionBy(SortingColumns.Address)"
              @on-change-direction="sortBy(SortingColumns.Address, ...arguments)"
            />
          </th>
          <th class="column-sortable loan-program-type">
            <sorting-header
              title="Loan Program"
              :order="findDirectionBy(SortingColumns.LoanProgram)"
              @on-change-direction="sortBy(SortingColumns.LoanProgram, ...arguments)"
            />
          </th>
          <th class="column-sortable loan-strategy">
            <sorting-header
              title="Loan Strategy"
              :order="findDirectionBy(SortingColumns.LoanStrategy)"
              @on-change-direction="sortBy(SortingColumns.LoanStrategy, ...arguments)"
            />
          </th>
          <th class="column-sortable loan-original-principal-balance">
            <sorting-header
              title="Principal Bal at Orig"
              :order="findDirectionBy(SortingColumns.PrincipleBalanceAtOrig)"
              @on-change-direction="sortBy(SortingColumns.PrincipleBalanceAtOrig, ...arguments)"
            />
          </th>
          <th class="column-sortable loan-interest-rate">
            <sorting-header
              title="Note Rate"
              :order="findDirectionBy(SortingColumns.NoteRate)"
              @on-change-direction="sortBy(SortingColumns.NoteRate, ...arguments)"
            />
          </th>
          <th class="loan-withdrawn-declined-reasons">
            Reasons
          </th>
        </tr>
      </thead>
      <tbody class="loans-list-table-body">
        <tr
          v-for="loan in orderedLoans"
          v-show="loan.visible"
          :key="loan.toParam"
          :data-path="loanPath(loan)"
          @click="trackClick(loan)"
        >
          <td :class="activeSortClass(SortingColumns.Status)">
            <span class="loan-status" :class="loanStatusClass(loan.underwritingStatus)">{{ loan.underwritingStatusText }}</span>
          </td>
          <td :class="activeSortClass(SortingColumns.SubmittedDate)" class="loan-submitted-at">
            {{ loan.submittedDate || "--" }}
          </td>
          <td :class="activeSortClass(SortingColumns.PassDate)" class="loan-passed-at">
            {{ loan.passedDate || "--" }}
          </td>
          <td :class="activeSortClass(SortingColumns.Address)" class="loan-full-address">
            <span v-if="!loan.address && !loan.city && !loan.state && !loan.zip">--</span>
            <span v-else>
              <span class="loan-street">
                {{ loan.address }}
              </span>
              <span>{{ loan.city }}, {{ loan.state }} {{ loan.zip }}</span>
            </span>
          </td>
          <td :class="activeSortClass(SortingColumns.LoanProgram)" class="loan-program-type">
            {{ loan.loanProgramText || "--" }}
          </td>
          <td :class="activeSortClass(SortingColumns.LoanStrategy)" class="loan-strategy">
            {{ loan.loanStrategyText || "--" }}
          </td>
          <td
            :class="activeSortClass(SortingColumns.PrincipleBalanceAtOrig)"
            class="loan-principle-balance-at-orig"
          >
            {{ prepNumber(loan.totalPrincipalBalanceAtOrigination) | money(2, true) | missingValue }}
          </td>
          <td :class="activeSortClass(SortingColumns.NoteRate)" class="loan-interest-rate">
            {{ prepNumber(loan.interestRate) | percent(3, true) | missingValue }}
          </td>
          <td :class="activeSortClass(SortingColumns.Reasons)" class="loan-reasons">
            <ul v-for="(reason, index) in parseReasons(loan.loanSuspensionReasons)" :key="index">{{ reason }}</ul>
          </td>
        </tr>
        <tr v-if="loans.length === 0">
          <td class="loan-empty" colspan="3">No Withdrawn/Declined Loans</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SortingHeader from "components/shared/sorting_header";
import CheckboxFilter from "components/reports/worklist/checkbox_filter";
import SortingHelper from "utils/sorting_helper";
import FilterHelper from "utils/filter_helper";
import moment from "moment";
import Segment from "services/segment_service";

const SortingColumns = {
  Status: "status",
  SubmittedDate: "submittedDate",
  PassDate: "passDate",
  Address: "address",
  LoanProgram: "loanProgramText",
  LoanStrategy: "loanStrategy",
  PrincipleBalanceAtOrig: "principleBalanceAtOrig",
  NoteRate: "noteRate",
};

const Filters = {
  Withdrawn: "Withdrawn",
  Declined: "Declined",
};

export default {
  components: {
    SortingHeader,
    CheckboxFilter,
  },
  props: {
    loans: {
      type: Array,
      required: true,
    },
    loansRoute: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sortingDirection: -1,
      sortingColumn: SortingColumns.PassDate,
      sorter: new SortingHelper({
        [SortingColumns.Status]: {
          sortBy: (a, b) => {
            return a.underwritingStatusText > b.underwritingStatusText ? 1 : -1;
          },
        },
        [SortingColumns.SubmittedDate]: {
          sortBy: (a, b) => {
            return moment(a.submittedDate, "MM/DD/YYYY") - moment(b.submittedDate, "MM/DD/YYYY");
          },
        },
        [SortingColumns.PassDate]: {
          sortBy: (a, b) => {
            return moment(a.passedDate, "MM/DD/YYYY") - moment(b.passedDate, "MM/DD/YYYY");
          },
        },
        [SortingColumns.Address]: {},
        [SortingColumns.LoanStrategy]: {},
        [SortingColumns.LoanProgram]: {},
        [SortingColumns.NoteRate]: {
          sortBy: (a, b) => {
            return parseFloat(a.interestRate || 0) - parseFloat(b.interestRate || 0);
          },
        },
        [SortingColumns.Address]: {},
        [SortingColumns.PrincipleBalanceAtOrig]: {
          sortBy: (a, b) => {
            return (
              parseFloat(a.totalPrincipalBalanceAtOrigination || 0) -
              parseFloat(b.totalPrincipalBalanceAtOrigination || 0)
            );
          },
        },
      }),
      filterHelper: new FilterHelper({
        [Filters.Withdrawn]: {
          on: loan => {
            return loan.underwritingStatus === "canceled";
          },
        },
        [Filters.Declined]: {
          on: loan => {
            return loan.underwritingStatus === "rejected";
          },
        },
      }),
      orderedLoans: [...this.loans],
      filters: {
        withdrawn: false,
        declined: false,
      },
      SortingColumns,
      Filters,
    };
  },
  computed: {
    totalLoansCount() {
      return this.orderedLoans.length;
    },
    currentLoansCount() {
      return this.orderedLoans.filter(loan => loan.visible).length;
    },
    totalBalance() {
      return this.orderedLoans
        .filter(loan => loan.visible)
        .map(loan => parseFloat(loan.totalPrincipalBalanceAtOrigination))
        .reduce((total, current) => total + (isNaN(current) ? 0 : current), 0);
    },
  },
  watch: {
    loans() {
      this.orderedLoans = [...this.loans];
      this.sortBy(this.sortingColumn, this.sortingDirection);
      this.applyFilters();
    },
  },
  beforeMount() {
    this.sortBy(this.sortingColumn, this.sortingDirection);
    this.applyFilters();
  },
  methods: {
    findDirectionBy(columnName) {
      return this.sorter.findDirection(columnName, this.sortingColumn, this.sortingDirection);
    },
    sortBy(columnName, direction) {
      this.sortingColumn = columnName;
      this.sortingDirection = direction;

      this.orderedLoans = this.orderedLoans.sort(this.sorter.sort(columnName, direction));
    },
    applyFilter(filterName, value) {
      this.filters[filterName] = value;
      this.filterHelper.updateFilter(filterName, value);

      this.applyFilters();
    },
    applyFilters() {
      this.filterHelper.applyFilters(this.orderedLoans).forEach((loan, index) => {
        Vue.set(this.orderedLoans, index, loan);
      });
    },
    filterTotal(filterName) {
      return this.filterHelper.filteredCount(filterName, this.orderedLoans);
    },
    loanStatusClass(status) {
      return status ? `loan-status loan-${status}` : undefined;
    },
    activeSortClass(column) {
      return this.sorter.activeSortClass(this.sortingColumn, column);
    },
    parseReasons(reasons) {
      return reasons.length > 0 ? reasons.slice(0, 3).map(reason => reason) : ["--"];
    },
    loanPath(loan) {
      return `${this.loansRoute}/${loan.toParam}`;
    },
    prepNumber(num) {
      return num === undefined ? num : parseFloat(num);
    },
    trackClick(loan) {
      Segment.track("clickedLoanOnWithdrawnDeclined", {
        loan_id: loan.toParam,
      });
    },
  },
};
</script>
