<template>
  <base-modal-form
    title="Edit Full Borrower Vesting Clause"
    class="full-vesting-clause-modal"
    :show="show"
    :save-disabled="pendingRequiredFields || saveDisabled"
    @close="onClose"
    @save="onSave"
  >
    <div v-for="(borrower, index) in syncedBorrowers" :key="index">
      <borrower-field
        :label="'Borrower ' + (index + 1)"
        :borrower="borrower"
        :current-marriage-options="currentMarriageOptions"
        :marriages-present="marriagesPresent"
        @onCheck="onCheck"
        @updateSpouse="updateSpouse"
      />
    </div>
    <base-select-field
      v-if="borrowers.length > 1"
      v-model="titleVestingClause"
      label="How Is Title Vested?"
      placeholder="Select One"
      :options="options"
    />
    <base-text-field
      v-if="showTitleVestingOther"
      id="name"
      v-model="titleVestingClauseOther"
    />
    <div class="preview-label">
      <span class="input-label">Preview</span>
      <base-button
        class="refresh-button"
        type="textBlue"
        label="Refresh"
        size="small"
        icon-left="undo"
        @click="refreshVestingClause(true)"
      />
    </div>
    <textarea
      v-model="fullBorrowerVestingClause"
      :disabled="pendingRequiredFields"
      class="form-input borrower-vesting-preview-field"
      resize="none"
      cols="30"
      rows="4"
    />
  </base-modal-form>
</template>

<script>
import AjaxService from "services/ajax_service";
import BorrowerField from "./borrower_field";
import BorrowerRelationships from "models/borrower_relationships";
import Borrower from "models/borrower";
import isEmpty from "lodash/isEmpty";
import selectOptionsToMapping from "utils/select_options_to_mapping";

export default {
  name: "EditVestingClauseModal",
  components: { BorrowerField },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    borrowers: {
      type: Array,
      required: true,
    },
    borrowerRelationships: {
      type: Array,
      default: () => [],
    },
    fullVestingClause: {
      type: String,
      default: "",
    },
    vestingClause: {
      type: String,
      default: "",
    },
    vestingClauseOther: {
      type: String,
      default: "as ",
    },
  },
  data() {
    return {
      options: PSData.options.titleVestingClauseTypes,
      titleVestingClause: this.vestingClause,
      titleVestingClauseOther: this.vestingClauseOther,
      fullBorrowerVestingClause: this.fullVestingClause,
      relationships: new BorrowerRelationships(this.borrowerRelationships),
      syncedBorrowers: undefined,
      saveDisabled: false,
      isEdited: false,
    };
  },
  computed: {
    marriagesPresent() {
      return (
        this.borrowers.filter(borrower => {
          return borrower.borrowerType == "individual" && borrower.maritalStatus == "married";
        }).length > 1
      );
    },
    showTitleVestingOther() {
      return this.titleVestingClause == "other";
    },
    currentVestingClause() {
      if (this.titleVestingClause == "other") {
        return this.titleVestingClauseOther;
      } else if (
        this.titleVestingClause == "not_applicable" ||
        isEmpty(this.titleVestingClause) ||
        this.borrowers.length < 2
      ) {
        return "";
      } else {
        return `as ${selectOptionsToMapping(this.options)[this.titleVestingClause]?.toLowerCase()}`;
      }
    },
    currentMarriageOptions() {
      const marriedToOthers = this.syncedBorrowers.filter(b => b.isMarriedToOther);
      const pendingSpouses = this.relationships.notInRelationship(marriedToOthers);
      const marriageOptions = {};

      marriedToOthers.forEach(borrower => {
        const currentSpouse = this.relationships.findSpouse(borrower.id);
        const possibleSpouses = pendingSpouses.filter(s => s.id != borrower.id);

        if (currentSpouse) {
          possibleSpouses.push(currentSpouse);
        }
        marriageOptions[`${borrower.id}`] = possibleSpouses.map(borrower => [
          borrower.name,
          borrower.id,
        ]);
      });
      return marriageOptions;
    },
    pendingRequiredFields() {
      return (
        (this.borrowers.length != 1 &&
          (isEmpty(this.titleVestingClause) ||
            (this.titleVestingClause == "other" && isEmpty(this.titleVestingClauseOther)))) ||
        this.missingBorrowerLegalNames
      );
    },
    missingBorrowerLegalNames() {
      return this.syncedBorrowers?.some(borrower => isEmpty(borrower.legalName));
    },
  },
  watch: {
    show(val) {
      if (val == true) {
        const refreshedBorrowers = this.borrowers.map(b => new Borrower(b));
        this.relationships = new BorrowerRelationships(this.borrowerRelationships);
        this.syncedBorrowers = this.syncBorrowers(refreshedBorrowers, true);
        this.titleVestingClause = this.vestingClause;
        this.titleVestingClauseOther = this.vestingClauseOther;
        this.fullBorrowerVestingClause = this.fullVestingClause;
        this.isEdited = false;
      }
    },
    "relationships.spouseLookup"() {
      this.syncedBorrowers = this.syncBorrowers(this.syncedBorrowers, false);
      this.refreshVestingClause();
      this.isEdited = true;
    },
    currentVestingClause() {
      this.refreshVestingClause();
      this.isEdited = true;
    },
    titleVestingClause(newVal) {
      if (newVal == "other" && isEmpty(this.vestingClauseOther)) {
        this.titleVestingClauseOther = "as ";
      }
    },
  },
  methods: {
    updateSpouse(borrower1, event) {
      const borrower2 = this.syncedBorrowers.find(borrower => borrower.id == event);
      this.relationships.marry(borrower1, borrower2);
    },
    onCheck(borrower, check) {
      if (check == false) {
        this.relationships.unMarry(borrower);
      }
    },
    onClose() {
      this.$emit("close");
    },
    syncBorrowers(borrowers = [], isFirstSync = false) {
      this.relationships.updateSpouseList();
      const syncedBorrowers = [];
      borrowers.forEach(borrower => {
        if (this.relationships.spouseList.includes(borrower.id)) {
          const spouse = this.relationships.findSpouse(borrower.id);
          borrower.currentSpouse = spouse?.id;
          if (isFirstSync) {
            borrower.isMarriedToOther = true;
          }
        } else {
          borrower.currentSpouse = null;
          if (isFirstSync) {
            borrower.isMarriedToOther = false;
          }
        }
        syncedBorrowers.push(borrower);
      });
      return syncedBorrowers;
    },
    onSave() {
      const messages = {
        onSuccess: "Information successfully updated",
        onError: this.onError,
      };
      this.saveDisabled = true;
      AjaxService.withNotifications(messages)
        .post(PSData.routes.vestingClause, this.vestingClausePayload(), {
          "Content-Type": "application/json",
        })
        .then(data => {
          this.$emit("on-save", data);
        })
        .finally(() => {
          this.onClose();
          this.saveDisabled = false;
        });
    },
    vestingClausePayload() {
      return {
        relationships: this.relationships.relationships,
        title_vesting_clause: this.titleVestingClause,
        title_vesting_clause_other:
          this.titleVestingClause == "other" ? this.titleVestingClauseOther : null,
        full_borrower_vesting_clause: this.fullBorrowerVestingClause,
      };
    },
    parseMarriageName(relationship) {
      if (this.isSoleProperty(relationship)) {
        return `${relationship.borrowers[0].legalName}, ${relationship.borrowers[1].legalName}`;
      } else {
        return (
          `${relationship.borrowers[0].name.toUpperCase()},` +
          ` and ${relationship.borrowers[1].name.toUpperCase()},` +
          ` a ${this.parseSpouseType(relationship.borrowers[0].gender)}` +
          ` and ${this.parseSpouseType(relationship.borrowers[1].gender)}`
        );
      }
    },
    parseSpouseType(gender) {
      if (gender == "male") {
        return "husband";
      } else if (gender == "female") {
        return "wife";
      } else {
        return "";
      }
    },
    isSoleProperty(relationship) {
      return relationship.borrowers.some(borrower => {
        return borrower.soleProperty;
      });
    },
    refreshVestingClause(forceRefresh = false) {
      if (!this.isEdited && !forceRefresh) {
        return;
      }
      if (this.pendingRequiredFields) {
        return (this.fullBorrowerVestingClause = "");
      }
      const clauses = [];
      this.relationships.relationships.forEach(relationship => {
        if (relationship.borrowers.length > 1) {
          clauses.push(this.parseMarriageName(relationship));
        }
      });
      this.syncedBorrowers.forEach(borrower => {
        if (!this.relationships.spouseList.includes(borrower.id)) {
          clauses.push(borrower.legalName);
        }
      });
      if (!isEmpty(this.currentVestingClause)) {
        clauses.push(this.currentVestingClause);
      }
      this.fullBorrowerVestingClause = clauses.join(", ");
    },
  },
};
</script>
