<template>
  <div class="distribution-rules col-md-9">
    <div v-if="loading" class="ps-loader ps-loader-small"></div>
    <div v-else class="row">
      <Investors v-bind="{ investors, currentInvestorId }"
        @clear="clearCurrentInvestor"
        @details="setCurrentInvestor"
      />
      <Rules
        v-if="currentInvestor"
        v-bind="{ records: currentInvestorRecords, currentInvestor }"
        @updated="updateRule"
        @created="addRule"
      />
    </div>
  </div>
</template>
<script>
import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";
import find from "lodash/find";

import Investors from "./investors";
import Rules from "./rules";

import AjaxService from "services/ajax_service";
import { error } from "services/notification";

const VIEWS = {
  none: "none",
  rules: "rules",
};

export default {
  components: { Investors, Rules },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      view: VIEWS.list,
      currentInvestorId: undefined,
      data: [],
    };
  },
  created() {
    this.requestData();
    this.initialize();
  },
  computed: {
    currentInvestor() {
      return this.investors[this.currentInvestorId];
    },
    currentInvestorRecords() {
      return this.groupedByInvestor[this.currentInvestorId] || [];
    },
    groupedByInvestor() {
      return groupBy(this.data, "investor.id");
    },
    investors() {
      return uniq(this.data.map(record => record.investor), "id").reduce((acc, record) => {
        const recordsCount = this.groupedByInvestor[record.id].length;
        acc[record.id] = { ...record, recordsCount };
        return acc;
      }, this.initialInvestors());
    },
  },
  methods: {
    initialize() {
      let view, currentInvestorId;

      if (this.filters.investorId) {
        currentInvestorId = parseInt(this.filters.investorId);
        view = VIEWS.rules;
      }

      this.view = view;
      this.currentInvestorId = currentInvestorId;
    },
    initialInvestors() {
      let investors = PSData.legalOwners.filter(legalOwnerItem =>
        legalOwnerItem.hasOwnProperty("databaseId")
      );

      investors = investors.reduce((acc, record) => {
        acc[record.databaseId] = {
          id: record.databaseId,
          label: record.text,
          lender: false,
          recordsCount: 0,
        };
        return acc;
      }, {});

      return investors;
    },
    updateRule(rule) {
      Object.assign(find(this.data, { id: rule.id }), rule);
    },
    addRule(rule) {
      this.data.push(rule);
    },
    clearCurrentInvestor() {
      this.currentInvestorId = null;
      this.view = VIEWS.none;
    },
    setCurrentInvestor(investorId) {
      this.currentInvestorId = investorId;
      this.view = VIEWS.rules;

      this.$emit("filter", { ...this.filters, investorId: investorId });
    },
    async requestData() {
      this.loading = true;

      try {
        this.data = await AjaxService.getJSON("/manage/cash_receipt_rules");
      } catch {
        error("Something went wrong");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
