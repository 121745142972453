<template>
  <div v-if="isPresent" class="servicer-loan-boarding-guardrails">
    <div v-for="record in dataChanges()" :key="record.id">
      <div class="servicer-loan-boarding-guardrail"
           v-for="item in record.dataChangeMessages"
           :key="item.column">
        <i class="review-flag-error" />
        <span class="guardrail-header">Data change detected</span>
        <span class="guardrail-label">
          {{ item.message }}
        </span>
        <button class="btn guardrail-dismiss-button" @click="dismiss(record, item)">Dismiss</button>
      </div>
    </div>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import { success, error } from "services/notification";

export default {
  name: "manage-servicer-loan-boarding-guardrails",
  props: {
    collection: Array,
  },
  data() {
    return { inFlight: false };
  },
  methods: {
    async dismiss(record, item) {
      if (this.inFlight) return;

      this.inFlight = true;
      const messages = {
        onSuccess: "Guardrail was dismissed successfully",
      };

      const result = await AjaxService.withNotifications(messages).post(
        record.routes.dismissWarning,
        { column: item.column }
      );

      record.dataChangeMessages = result.dataChangeMessages;
      record.reviewFlags = result.reviewFlags;

      this.inFlight = false;
    },
    dataChanges() {
      return this.collection.filter(record => record.dataChangeMessages !== undefined);
    },
  },
  computed: {
    isPresent() {
      return this.dataChanges().length > 0;
    },
  },
};
</script>
