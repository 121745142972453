export default class PostAddress {
  constructor(fields = {}) {
    this.address = fields.address;
    this.city = fields.city;
    this.state = fields.state;
    this.zipCode = fields.zipCode;
  }

  get firstLine() {
    return this.address;
  }

  get secondLine() {
    const cityState = [this.city, this.state].filter(v => !!v).join(", ");
    return [cityState, this.zipCode].filter(v => !!v).join(" ");
  }

  get fullAddress() {
    return [this.firstLine, this.secondLine].filter(v => !!v).join(", ");
  }
}
