<template>
  <div>
    <div class="cash-receipts-subheader">
      <h3>
        Missing Series
      </h3>
    </div>

    <form class="form-inline" :disabled="loading" @submit.prevent="fetchDistributions">
      <label class="form-label mb-2 mr-sm-2">Cash Receipt Item ID: </label>
      <input :disabled="loading" v-model="cashReceiptId" class="form-control mb-2 mr-sm-2">
      <button :disabled="loading" type="submit" class="btn btn-primary mb-2">
        Go
      </button>
    </form>
    <hr>

    <div v-if="loading" class="ps-loader" />
    <items-table
      v-if="showTable"
      :items="items"
      @remove-item="removeItem"
    />
    <span v-if="emptyResponse">no distributions found</span>
  </div>
</template>


<script>
import ItemsTable from "./table";
import AjaxService, { ERROR_STATUS } from "services/ajax_service";

export default {
  components: {
    ItemsTable,
  },
  data() {
    return {
      items: [],
      loading: false,
      cashReceiptId: null,
      emptyResponse: false,
    };
  },
  computed: {
    payload() {
      return { cash_receipt_item_id: this.cashReceiptId };
    },
    showTable() {
      return this.items.length && !this.loading;
    },
  },
  methods: {
    async fetchDistributions() {
      this.items = [];
      this.loading = true;
      const url = `/manage/distributions/missing_series`;
      AjaxService.post(url, this.payload, { "content-type": "application/json" })
        .then(data => {
          this.items = data;
          this.emptyResponse = data.length == 0;
        })
        .catch(e => {
          const message = e.data.errors || e;
          AjaxService.sendNotification(message, ERROR_STATUS);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeItem(id) {
      this.items = this.items.filter(i => {
        return i.id != id;
      });
    },
  },
};
</script>
