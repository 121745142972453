<template>
  <div ref="list" class="messages-list loan-task-comments-list">
    <component
      :is="getEventComponentName(comment)"
      v-for="comment in sortedComments"
      :key="comment.id"
      :event="comment"
    />
  </div>
</template>
<script>
import ActivityFeedEventItem from "components/activity_feed_events/event_item";
import ActivityFeedSystemEventItem from "components/activity_feed_events/system_event_item";

export default {
  name: "loan-tasks-comments-form",
  components: {
    ActivityFeedEventItem,
    ActivityFeedSystemEventItem,
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
  },
  computed: {
    sortedComments() {
      return this.comments.sort((a, b) => a.createdAt - b.createdAt);
    },
  },
  watch: {
    sortedComments() {
      this.scrollToBottom();
    },
  },
  mounted() {
    this.scrollToBottom();
  },
  methods: {
    scrollToBottom() {
      Vue.nextTick(() => {
        const container = this.$refs.list.parentElement;

        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    getEventComponentName(event) {
      if (event.isSystem) {
        return "ActivityFeedSystemEventItem";
      } else {
        return "ActivityFeedEventItem";
      }
    },
  },
};
</script>
