<template>
  <loan-boarding-table label="Assignment Transfers" :count="count">
    <template #actions>
      <div class="servicer-loan-boarding-table-actions">
        <div class="select-all-rows">
          <check-box v-model="allSelected" :partial="isPartiallySelected" @change="toggleAll" />
        </div>
        <div class="buttons">
          <button
            type="button"
            class="btn btn-action-cta"
            :disabled="isNoneSelected()"
            @click="submit"
          >
            Submit to Subservicer
          </button>
          <button
            type="button"
            class="btn btn-outline-grey"
            :disabled="isNoneSelected()"
            @click="moveToPipeline"
          >
            Move Back to Pipeline
          </button>
        </div>
      </div>
    </template>
    <template #guardrail>
      <loan-boarding-guardrails :collection="collection" />
    </template>
    <template #header>
      <thead>
        <th class="checkbox-header" />
        <th>Loan ID</th>
        <th>Subservicer ID</th>
        <th class="address">
          Address
        </th>
        <th>Review Flags</th>
        <th>Assignment<br>Date</th>
        <th>Originator</th>
        <th>Borrower</th>
        <th class="amount">
          Total UPB
        </th>
        <th class="amount">
          Transfer UPB
        </th>
        <th class="amount">
          Note<br>Rate
        </th>
        <th class="amount">
          Transfer<br>Rate
        </th>
        <th class="amount">
          Originator<br>Rate
        </th>
        <th>Ready To Submit Date</th>
      </thead>
    </template>
    <template #body>
      <tr v-for="item in collection" :id="item.id" :key="item.id">
        <td class="checkbox">
          <check-box v-model="item.checked" @change="toggleItem" />
        </td>
        <td>{{ item.psLoanId }}</td>
        <td>{{ item.subservicerId }}</td>
        <td class="address">
          <loan-boarding-truncated-text :value="item.address" />
        </td>
        <td>
          <loan-boarding-review-flags :record="item" />
        </td>
        <td>{{ item.assignmentDate }}</td>
        <td>
          <loan-boarding-truncated-text :value="item.originator" />
        </td>
        <td>
          <loan-boarding-truncated-text :value="item.borrower" />
        </td>
        <td class="amount">
          {{ item.totalPrincipalBalanceAtOrigination | accounting }}
        </td>
        <td class="amount">
          {{ item.unpaidPrincipalBalance | accounting }}
        </td>
        <td class="amount">
          {{ item.noteRate | percent(4) }}
        </td>
        <td class="amount">
          {{ item.transferRate | percent(4) }}
        </td>
        <td class="amount">
          {{ item.originatorRate | percent(4) }}
        </td>
        <td class="amount">
          {{ item.totalPrincipalBalanceAtOrigination | accounting }}
        </td>
        <td class="amount">
          {{ item.unpaidPrincipalBalance | accounting }}
        </td>
        <td class="amount">
          {{ item.noteRate | percent(4) }}
        </td>
        <td class="amount">
          {{ item.transferRate | percent(4) }}
        </td>
        <td class="amount">
          {{ item.originatorRate | percent(4) }}
        </td>
        <td>{{ item.approvedAt }}</td>
      </tr>
    </template>
  </loan-boarding-table>
</template>
<script>
import LoanBoardingTruncatedText from "components/servicer/loan_boarding/truncated_text";
import LoanBoardingListMixin from "components/servicer/loan_boarding/mixin/list";
import LoanBoardingUpdateStatusMixin from "components/servicer/loan_boarding/mixin/update_status";
import LoanBoardingReviewFlags from "components/servicer/loan_boarding/review_flags";
import LoanBoardingGuardrails from "components/servicer/loan_boarding/guardrails";
import LoanBoardingTable from "components/servicer/loan_boarding/table";
import CheckBox from "components/shared/check_box";

export default {
  name: "ManageServicerLoanBoardingTransferReadyToSubmit",
  components: {
    LoanBoardingReviewFlags,
    LoanBoardingTruncatedText,
    LoanBoardingGuardrails,
    LoanBoardingTable,
    CheckBox,
  },
  mixins: [LoanBoardingListMixin, LoanBoardingUpdateStatusMixin],
  props: {
    collection: Array,
    routes: Object,
  },
  methods: {
    moveToPipeline() {
      this.updateStatus(this.checkedItems(), "pending").then(collections =>
        this.$emit("updated", collections)
      );
    },
    submit() {
      this.submitBatch(this.checkedItems(), "submitted").then(collections =>
        this.$emit("updated", collections)
      );
    },
  },
};
</script>
