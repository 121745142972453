<template>
  <base-modal-form
    :show="show"
    :save-disabled="isDisabled"
    :cancel-options="cancelOptions"
    @close="onClose"
    @save="onSave"
    title="Set custom grid pricing"
    save-button-name="Set Custom Pricing Grid"
    class="grid-modal"
  >
    <p class="pb-2">Set pricing based on FICO credit score and LTV. <b>If N/A, leave blank.</b></p>
    <form>
      <div class="grid-pricing">
        <div class="grid-row pr-5">
          <div class="grid-cell empty"></div>
          <div v-for="(fico, index) in grid.ficos" :key="index" class="grid-cell fico" :class="{ 'txt-input': fico.attr == 'residency' }">
            <template v-if="fico.attr == 'residency'">
              {{ titleize(fico.value) }}
            </template>
            <template v-else-if="fico.condition == 'in'">
              <input v-model="fico.value[0]" v-mask:fico=""/>
              <span class="dash">-</span>
              <input v-model="fico.value[1]" v-mask:fico=""/>
            </template>
            <base-tooltip v-if="fico.attr !== 'residency'" label="Remove FICO column" position="is-top" type="is-dark" class="ml-1">
              <i v-if="canDeleteColumn" class="fas fa-times-circle del-col" @click="removeColumn(index)" />
            </base-tooltip>
          </div>
          <div class="grid-cell action fico-action">
            <base-tooltip v-if="canAddColumn" label="Add FICO column" position="is-left" type="is-dark">
              <i class="fas fa-plus-circle" @click="addColumn" />
            </base-tooltip>
          </div>
        </div>
        <div v-for="(ltv, index) in grid.ltvs" :key="index" class="grid-row">
          <div class="grid-cell ltv">
            <template v-if="ltv.condition == 'in'">
              <input v-model="ltv.value[0]" v-mask:percentage="ltvInput" />
              <span>%</span>
              <span class="dash">-</span>
              <input v-model="ltv.value[1]" v-mask:percentage="ltvInput" />
              <span>%</span>
            </template>
            <base-tooltip v-if="canDeleteRow" label="Remove LTV row" position="is-top" type="is-dark" class="ml-4">
              <i class="fas fa-times-circle del-row" @click="removeRow(index)" />
            </base-tooltip>
          </div>
          <div v-for="(rate, rateInd) in grid.rates[index]" :key="index +`-`+ rateInd" class="grid-cell rate">
            <base-number-field v-model="grid.rates[index][rateInd]" />
          </div>
        </div>
        <div class="grid-row">
          <div class="grid-cell action ltv-action">
            <base-tooltip v-if="canAddRow" label="Add LTV row" position="is-right" type="is-dark">
              <i class="fas fa-plus-circle" @click="addRow" />
            </base-tooltip>
          </div>
        </div>
      </div>
    </form>
  </base-modal-form>
</template>

<script>
import useLtvFicoGridStore from "./stores/ltv_fico_grid";
import { titleize } from "./utils/formatter";
import { cloneDeep, compact, flattenDeep } from "lodash";

export default {
  name: "grid-modal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const gridStore = useLtvFicoGridStore();
    const ltvInput = { minus: false, round: 2 };
    return {
      gridStore,
      ltvInput,
      errors: {},
      cancelOptions: ["escape", "x"],
      grid: {},
    };
  },
  mounted() {
    this.reset();
    this.gridStore.$subscribe((mutation, state) => {
      this.reset();
    });
  },
  computed: {
    canAddRow() {
      return this.grid?.ltvs?.length < 8;
    },
    canAddColumn() {
      return this.grid?.ficos?.length < 8;
    },
    canDeleteRow() {
      return this.grid.ltvs.length > 1;
    },
    canDeleteColumn() {
      return this.grid.ficos.length > 1;
    },
    isDisabled() {
      return this.hasNoAdjustments() || this.hasNoRanges();
    },
  },
  methods: {
    reset() {
      this.grid = cloneDeep({
        ...this.gridStore.getColumns,
        ...this.gridStore.getRows,
        ...this.gridStore.getAdjustments,
      });
    },
    titleize,
    onClose() {
      this.reset();
      this.$emit("close");
    },
    onSave() {
      this.gridStore.saveGrid(this.grid);
      this.$emit("preview-alert");
      this.$emit("close");
    },
    addColumn() {
      this.grid.ficos.push({ ...{ attr: "fico", condition: "in", value: [null, null] } });
      this.grid.rates.forEach(a => a.push(null));
    },
    removeColumn(index) {
      this.grid.ficos.splice(index, 1);
      this.grid.rates.forEach(a => a.splice(index, 1));
    },
    addRow() {
      this.grid.ltvs.push({ ...{ attr: "ltv", condition: "in", value: [null, null] } });
      const cols = this.grid.ficos.length;
      this.grid.rates.push(Array(cols).fill(null));
    },
    removeRow(index) {
      this.grid.ltvs.splice(index, 1);
      this.grid.rates.splice(index, 1);
    },
    hasNoAdjustments() {
      return compact(flattenDeep(this.grid.rates)).length === 0;
    },
    hasNoRanges() {
      const arr = flattenDeep([
        this.grid.ficos.map(i => i.value),
        this.grid.ltvs.map(i => i.value),
      ]);
      const index = arr.findIndex(e => {
        return e === null || e === "";
      });
      return index > -1;
    },
  },
};
</script>
<style>
.grid-modal .modal-card-body {
  padding-bottom: 24px;
}
.grid-modal .modal-card {
  min-width: 800px;
  width: auto;
}
.grid-modal .animation-content {
  max-width: 1306px !important;
}
.grid-modal .grid-pricing,
.grid-modal .grid-row,
.grid-modal form {
  display: flex;
}
.grid-modal .grid-pricing {
  flex-direction: column;
}
.grid-modal .grid-row:last-of-type .grid-cell {
  margin-top: 0;
}
.grid-modal .del-col,
.grid-modal .del-row {
  color: var(--color-grey-38);
  cursor: pointer;
  display: block;
  font-size: 13px;
}
.grid-modal .grid-cell {
  display: flex;
  height: 36px;
  min-width: 119px;
  width: 119px;
}
.grid-modal .empty {
  min-width: 119px;
}
.grid-modal .grid-cell input {
  color: var(--color-grey-86);
  text-align: left;
}
.grid-modal .rate,
.grid-modal .fico {
  margin-bottom: 16px;
  margin-right: 16px;
  width: 103px;
}
.grid-modal .fico,
.grid-modal .ltv {
  line-height: 16px;
}
.grid-modal .fico {
  flex: 0 0 auto;
  justify-content: center;
  margin-bottom: 8px;
}
.grid-modal .action,
.grid-modal .fico,
.grid-modal .ltv {
  align-items: center;
}
.grid-modal .txt-input,
.grid-modal .fico input,
.grid-modal .ltv input {
  font-size: var(--font-size-sm);
  font-weight: 600;
}
.grid-modal .txt-input {
  letter-spacing: -0.7px;
}
.grid-modal .txt-input .base-tooltip {
  letter-spacing: initial;
}
.grid-modal .fico input,
.grid-modal .ltv input {
  border: none;
  border-bottom: 1px dashed var(--color-grey-16);
  padding: 0;
  width: 23px;
}
.grid-modal .ltv input {
  width: 19px;
}
.grid-modal .rate input {
  border: 1px solid var(--color-grey-16);
  border-radius: var(--border-radius);
  font-size: var(--font-size-md);
  font-weight: 400;
  line-height: 36px;
  padding: 0 12px;
  width: 100%;
}
.grid-modal .rate .base-field {
  padding: 0 !important;
}
.grid-modal .grid-cell .dash {
  padding: 0 8px;
}
.grid-modal .action {
  color: var(--color-grey-38);
  justify-content: left;
  min-width: 19px;
  width: 19px;
}
.grid-modal .action .base-tooltip {
  margin-left: 0;
}
.grid-modal .action i {
  color: var(--color-blue);
  cursor: pointer;
  font-size: 19px;
}
.grid-modal .fico-action {
  margin-left: 16px;
}
.grid-modal .ltv-action {
  line-height: 1;
  height: 19px;
}
.grid-modal .grid-cell input[type="number"] {
  -moz-appearance: textfield;
}
.grid-modal .grid-cell input::-webkit-outer-spin-button,
.grid-modal .grid-cell input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
