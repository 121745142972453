const Charts = PS.Models.MarketData.Charts;
const MarketDataSource = PS.Models.MarketData.Source;

class MidasZipPriceIndex extends MarketDataSource {
  constructor() {
    super();

    this.zipTimeSeries = [];
    this.msaTimeSeries = [];
  }

  get name() {
    return "HouseCanary Price Index";
  }

  success(data) {
    this.zipTimeSeries = data.zipTimeSeries;
    this.msaTimeSeries = data.msaTimeSeries;

    super.success();
  }

  get sources() {
    return [
      new Charts.Midas("hcZip", this.zipTimeSeries, "month", "hpiValue"),
      new Charts.Midas("hcMsa", this.msaTimeSeries, "month", "hpiValue"),
      new Charts.Midas("hcZipTrend", this.zipTimeSeries, "month", "hpiTrend"),
    ];
  }
}

class TerminusPriceIndex extends MarketDataSource {
  constructor() {
    super();

    this.zipTimeSeries = [];
  }

  get name() {
    return "ClearCapital Data Index";
  }

  success(data) {
    this.zipTimeSeries = data.points.filter(point => point.type === "hdis");

    super.success();
  }

  get sources() {
    return [new Charts.Terminus("ccZip", this.zipTimeSeries, "effectiveAt", "value")];
  }
}

class MidasMsaPriceIndex extends MarketDataSource {
  constructor() {
    super();

    this.msaTimeSeries = [];
    this.nationalTimeSeries = [];
  }

  get name() {
    return "HouseCanary Price Index";
  }

  success(data) {
    this.msaTimeSeries = data.msaTimeSeries;
    this.nationalTimeSeries = data.nationalTimeSeries;

    super.success();
  }

  get sources() {
    return [
      new Charts.Midas("hcMsa", this.msaTimeSeries, "month", "hpiValue"),
      new Charts.Midas("hcMsaTrend", this.msaTimeSeries, "month", "hpiTrend"),
      new Charts.Midas("hcNational", this.nationalTimeSeries, "month", "hpiValue"),
    ];
  }
}

export class MultiSource {
  success(sources) {
    Object.keys(sources).forEach(key => {
      if (key in this._sources) {
        this._sources[key].success(sources[key]);
      }
    });
  }

  fail(sources) {
    Object.keys(sources).forEach(key => {
      if (key in this._sources) {
        this._sources[key].fail(sources[key]);
      }
    });
  }

  get isLoading() {
    return Object.values(this._sources).some(s => s.isLoading);
  }

  get isSuccessful() {
    return Object.values(this._sources).some(s => s.isSuccessful);
  }

  get isFailed() {
    return Object.values(this._sources).every(s => s.isFailed);
  }

  get hasData() {
    return this._comparison.hasData;
  }

  get data() {
    return this._comparison.data;
  }

  get _comparison() {
    let sources = Object.values(this._sources).reduce((acc, cur) => acc.concat(cur.sources), []);
    return new Charts.Comparison(...sources);
  }
}

class ZipHomeIndex extends MultiSource {
  constructor() {
    super();

    this._sources = {
      terminus: new TerminusPriceIndex(),
      midas: new MidasZipPriceIndex(),
    };
  }

  get name() {
    return "ZIP Home Index";
  }
}

class MsaHomeIndex extends MultiSource {
  constructor() {
    super();

    this._sources = {
      midas: new MidasMsaPriceIndex(),
    };
  }

  get name() {
    return "MSA Home Index";
  }
}

PS.Models.MarketData.Charts.MultiSource = MultiSource;
PS.Models.MarketData.Charts.ZipHomeIndex = ZipHomeIndex;
PS.Models.MarketData.Charts.MsaHomeIndex = MsaHomeIndex;
