<template>
  <div class="filters">
    <span class="label">Filter:</span>
    <div class="states-filter">
      <multi-select2
        id="states"
        v-model="selectedStates"
        :options="{ data: stateOptions, templateSelection: formatSelectedStateName, placeholder: 'States' }"
        name="states"
      />
    </div>
    <div class="funding-types-filter">
      <multi-select2
        id="funding-types"
        v-model="selectedFundingTypes"
        :options="{ data: fundingTypeOptions, placeholder: 'Funding Types' }"
        name="funding-types"
      />
    </div>
  </div>
</template>

<script>
import MultiSelect2 from "components/shared/multi_select2";

export default {
  name: "summary-chart-filters",
  components: { MultiSelect2 },
  props: {
    states: {
      required: true,
      type: Array,
    },
    fundingTypes: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectedStates: [],
      selectedFundingTypes: [],
    };
  },
  computed: {
    stateOptions() {
      return this.states.map(state => ({ id: state.value, text: state.name }));
    },
    fundingTypeOptions() {
      return this.fundingTypes.map(([label, value]) => ({ id: value, text: label }));
    },
  },
  watch: {
    selectedStates(states) {
      this.$emit("on-states-select", states);
    },
    selectedFundingTypes(fundingTypes) {
      this.$emit("on-funding-types-select", fundingTypes);
    },
  },
  methods: {
    formatSelectedStateName(option) {
      return option.id;
    },
  },
};
</script>
