<template>
  <RolesTable v-if="!selectedRole" :roles="roles" @role-selected="openRoleDetails"/>
  <RoleDetails v-else-if="selectedRole"
               :role="currentRole"
               :users="currentRole.users"
               :readonly="readonly"
               :updating="updating"
               @role-deselected="closeRoleDetails"/>
</template>

<script>
import RolesTable from "components/permissions/roles/table";
import RoleDetails from "components/permissions/roles/details";

export default {
  props: {
    readonly: Boolean,
    roles: Array,
    updating: Boolean,
  },
  data() {
    return {
      selectedRole: null,
    };
  },
  components: { RolesTable, RoleDetails },
  computed: {
    currentRole() {
      return this.roles.find(role => this.selectedRole.name === role.name);
    },
  },
  methods: {
    openRoleDetails(role) {
      this.selectedRole = role;
    },
    closeRoleDetails() {
      this.selectedRole = null;
    },
  },
};
</script>
