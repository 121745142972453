<template>
  <tr :class="{ 'buyers-row-inactive': isBuyerDisabled }">
    <td>
      {{ buyer.label }}
    </td>
    <td>
      {{ buyer.monthlyVolume | accounting }}
    </td>
    <td v-if="!editMode">
      {{ buyer.monthlyTarget | accounting }}
    </td>
    <td v-if="editMode">
      <input
        v-mask:currency
        class="form-control"
        type="currency"
        :value="buyer.monthlyTarget"
        :disabled="isBuyerDisabled"
        @input="changeMonthlyTarget"
      >
    </td>
    <td v-if="editMode">
      <input
        class="form-control"
        title="Enter a loan program URL"
        :value="buyer.lpsUrl"
        :disabled="isBuyerDisabled"
        @input="changeLpsUrl"
      >
      <span v-if="isInvalidUrl" class="error-control">This is not a valid Loan Program URL</span>
    </td>
    <td v-if="!editMode">
      <a
        v-if="buyer.externalId"
        :href="buyer.lpsUrl"
        class="lps-link"
        target="_blank"
      >
        link
      </a>
    </td>
    <td v-if="!editMode">
      {{ buyer.monthlyWeight }}
    </td>
    <td v-if="editMode">
      <label class="checkbox-toggle">
        <input
          type="checkbox"
          :checked="buyer.active"
          @input="toggleActive"
        >
        <div class="toggle-control" />
      </label>
    </td>
  </tr>
</template>
<script>
import NumberFormatter from "models/formatters/number_formatter";

export default {
  edit: "buyers-row",
  props: {
    buyer: {
      type: Object,
      default: function() {
        return {
          active: false,
          isValidLpsUrl: true,
        };
      },
    },
    editMode: Boolean,
  },
  computed: {
    isBuyerDisabled() {
      return !this.buyer.active;
    },
    isInvalidUrl() {
      return !this.buyer.isValidLpsUrl;
    },
  },
  methods: {
    updateIsValidLpsUrl() {
      this.buyer.isValidLpsUrl =
        !this.buyer.active ||
        !this.buyer.lpsUrl ||
        (this.buyer.lpsUrl &&
          this.isValidUrl(this.buyer.lpsUrl) &&
          this.extractUUIDFromUrlHash(this.buyer.lpsUrl) != null);
    },
    changeLpsUrl(event) {
      this.$emit("change-lps-url", this.buyer, event.target.value);
      this.updateIsValidLpsUrl();

      const externalId = this.extractUUIDFromUrlHash(event.target.value);
      this.$emit("change-external-id", this.buyer, externalId);
    },
    changeMonthlyTarget(event) {
      const amount = NumberFormatter.unmaskNumber(event.target.value || 0);
      this.$emit("change-monthly-target", this.buyer, amount);
    },
    toggleActive(event) {
      const checked = event.target.checked;
      this.$emit("change-active-buyer", this.buyer, checked);
      this.updateIsValidLpsUrl();
    },
    isValidUrl(value) {
      if (!value) return false;
      try {
        new URL(value);
        return true;
      } catch (err) {
        return false;
      }
    },
    extractUUIDFromUrlHash(value) {
      if (value) {
        try {
          let url = new URL(value);
          if (url.hash) {
            const match = url.hash.match(
              /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/g
            );
            if (match) return match[0];
          }
        } catch (err) {
          return null;
        }
      }
      return null;
    },
  },
};
</script>
