<template>
  <div>
    <button
      v-if="canUpdateLoanTaskData"
      v-show="!showSelectTask"
      class="btn-outlined show-select-button"
      @click="openSelectTask"
    >
      <i class="fas fa-plus fa-sm" />&nbsp;Add Task
    </button>

    <div v-show="showSelectTask" class="form-task-section add-task-section">
      <div v-show="showTaskDropdown" class="task-dropdown-section">
        <dropdown
          ref="select-task-definition-id"
          v-model="selectedDefinitionId"
          :options="select2Options"
          :custom-options="customSelect2Options"
          :value="selectedDefinitionId"
          class="form-input"
        >
          <option
            v-for="option in mainDefinitions"
            :key="'select2-' + option.id"
            :value="option.id"
          >
            {{ option.label }}
          </option>
          <template slot="appended-dropdown">
            <div
              v-for="definition in customDefinitions"
              :key="'custom-task-definition-' + definition.id"
              class="custom-loan-task-definition appended-loan-task-definition"
              @click="addCustomTask(definition.id)"
              @mouseover="removeSelect2Highlight"
            >
              + New {{ definition.label }}
            </div>

            <div
              v-for="definition in questionDefinitions"
              :key="'question-task-definition-' + definition.id"
              class="lender-question-task-definition appended-loan-task-definition"
              @click="addQuestionTask(definition.id)"
              @mouseover="removeSelect2Highlight"
            >
              + New {{ definition.label }}
            </div>

            <div
              @click="addQualityAssuranceTask"
              @mouseover="removeSelect2Highlight"
              class="quality-assurance-task-definition appended-loan-task-definition"
            >
              + New QA Task
            </div>
          </template>
        </dropdown>

        <div class="loan-task-buttons loan-task-buttons-container">
          <button class="btn btn-link loan-task-btn" @click="closeSelectTask">Cancel</button>
          <button
            class="btn btn-action-cta loan-task-btn add-task-button"
            :disabled="!selectedDefinitionId"
            @click="createLoanTask"
          >
            Add Task
          </button>
        </div>
      </div>

      <custom-task-form
        v-if="showCustomTask"
        ref="custom-task-form"
        :task-type="customDefinitionTaskType"
        :task-groups="groups"
        @form-submitted="createLoanTask"
        @canceled="closeSelectTask"
      >
        <template slot="submit-text">
          <span>Add Task</span>
        </template>
      </custom-task-form>

      <question-task-form
        v-if="showQuestionTask"
        ref="question-task-form"
        :task-groups="groups"
        @form-submitted="createLoanTask"
        @form-canceled="closeSelectTask"
      >
        <template slot="submit-text">
          <span>Add Question</span>
        </template>
      </question-task-form>

      <quality-assurance-task-form
        v-if="showQualityAssuranceTask"
        ref="quality-assurance-task-form"
        :definitions="qaDefinitions"
        @form-submitted="createLoanTask"
        @form-canceled="closeSelectTask"
      >
        <template slot="submit-text">
          <span>Add Task</span>
        </template>
      </quality-assurance-task-form>
    </div>
  </div>
</template>

<script>
import Dropdown from "components/shared/select2";
import CustomTaskForm from "components/activity_feed_events/manage/loan_tasks/custom/form";
import QuestionTaskForm from "components/activity_feed_events/manage/loan_tasks/question/form";
import QualityAssuranceTaskForm from "components/activity_feed_events/manage/loan_tasks/quality_assurance/form";
import { QA_DEFINITION_TASK_TYPE } from "models/loan_tasks/task";

export default {
  name: "create-task-panel",
  components: {
    Dropdown,
    CustomTaskForm,
    QuestionTaskForm,
    QualityAssuranceTaskForm,
  },
  props: {
    definitions: {
      type: Array,
      default: () => [],
    },
    customDefinitions: {
      type: Array,
      default: () => [],
    },
    questionDefinitions: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    canUpdateLoanTaskData: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showSelectTask: false,
      showCustomTask: false,
      showQuestionTask: false,
      showQualityAssuranceTask: false,
      showTaskDropdown: true,
      selectedDefinitionId: undefined,
    };
  },
  watch: {
    definitions() {
      this.closeSelectTask();
    },
    showSelectTask(show) {
      if (show) {
        this.$nextTick(() => {
          this.$refs["select-task-definition-id"].open();
        });
      }
    },
    showCustomTask(show) {
      if (show) {
        this.$nextTick(() => {
          const el = this.$refs["custom-task-form"].$refs["custom-task-form-title"];
          el.focus();
        });
      }
    },
    showQuestionTask(show) {
      if (show) {
        this.$nextTick(() => {
          const el = this.$refs["question-task-form"].$refs["question-task-description"];
          el.focus();
        });
      }
    },
    showQualityAssuranceTask(show) {
      if (show) {
        this.$nextTick(() => {
          const form = this.$refs["quality-assurance-task-form"];
          const select = form.$refs["quality-assurance-task-definition-id"];
          select.open();
        });
      }
    },
  },
  computed: {
    mainDefinitions() {
      return this.definitions.filter(d => d.isNull || QA_DEFINITION_TASK_TYPE !== d.taskType);
    },
    qaDefinitions() {
      return this.definitions.filter(d => d.isNull || QA_DEFINITION_TASK_TYPE === d.taskType);
    },
    select2Options() {
      return {
        placeholder: "Select a Task",
      };
    },
    customSelect2Options() {
      return {
        appendToDropdown: true,
      };
    },
    customDefinitionTaskType() {
      const definition = this.customDefinitions.find(d => {
        return d.id === this.selectedDefinitionId;
      });

      return definition && definition.taskType;
    },
  },
  methods: {
    openSelectTask() {
      this.showSelectTask = true;
    },
    closeSelectTask() {
      this.showSelectTask = false;
      this.showCustomTask = false;
      this.showQuestionTask = false;
      this.showQualityAssuranceTask = false;
      this.showTaskDropdown = true;
      this.selectedTaskId = undefined;
      this.selectedDefinitionId = undefined;
    },
    addCustomTask(definitionId) {
      this.showTaskDropdown = false;

      this.showCustomTask = true;
      this.selectedDefinitionId = definitionId;
    },
    addQuestionTask(definitionId) {
      this.showTaskDropdown = false;

      this.showQuestionTask = true;
      this.selectedDefinitionId = definitionId;
    },
    addQualityAssuranceTask() {
      this.showTaskDropdown = false;

      this.showQualityAssuranceTask = true;
      this.selectedDefinitionId = undefined;
    },
    createLoanTask(params = {}) {
      this.$emit("on-create", this.selectedDefinitionId, params);
    },
    removeSelect2Highlight() {
      const element = document.querySelector(".select2-results__option--highlighted");
      if (element) {
        element.classList.remove("select2-results__option--highlighted");
      }
    },
  },
};
</script>
