<template>
  <component
    :is="inputComponentName"
    :input="input"
    :read-only="readOnly"
    :meta="meta"
    @on-input-add="$emit('on-input-add')"
    @on-input-delete="$emit('on-input-delete')"
  />
</template>

<script>
import Input from "models/workflow_engine/input";
import IntegerInput from "./inputs/integer";
import FloatInput from "./inputs/float";
import CurrencyInput from "./inputs/currency";
import PercentageInput from "./inputs/percentage";
import StringInput from "./inputs/string";
import SelectInput from "./inputs/select";
import CheckboxInput from "./inputs/checkbox";
import DateInput from "./inputs/date";
import FileUploadInput from "./inputs/file_upload";
import EntityInput from "./inputs/entity";

export default Vue.extend({
  name: "input-component",
  components: {
    IntegerInput,
    FloatInput,
    CurrencyInput,
    PercentageInput,
    StringInput,
    SelectInput,
    CheckboxInput,
    DateInput,
    FileUploadInput,
    EntityInput,
  },
  props: {
    input: {
      type: Input,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    meta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    inputComponentName() {
      switch (this.input.variableType) {
        case "integer":
          return "IntegerInput";
        case "float":
          return "FloatInput";
        case "percentage":
          return "PercentageInput";
        case "currency":
          return "CurrencyInput";
        case "string":
          return "StringInput";
        case "select":
          return "SelectInput";
        case "boolean":
          return "CheckboxInput";
        case "date":
          return "DateInput";
        case "file":
          return "FileUploadInput";
        case "entity":
          return "EntityInput";
        default:
          return "StringInput";
      }
    },
  },
});
</script>
