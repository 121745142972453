<template>
  <div>
    <router-link to="/" class="pt-3 is-block">&lt; Go Back</router-link>
    <workflow-list
      v-show="showPendingSteps"
      :items="pendingSteps"
      header="Pending Steps:"
      key-field="uuid"
    >
      <template v-slot:item="{ item }">
        <router-link :to="`/pending_steps/${item.uuid}`">
          {{ item.name }}
        </router-link>
      </template>
    </workflow-list>
    <workflow-list
      v-show="showCompletedSteps"
      :items="completedSteps"
      header="Completed Steps:"
      key-field="uuid"
    >
      <template v-slot:item="{ item }">
        <completed-step :item="item" />
      </template>
    </workflow-list>
  </div>
</template>

<script>
import WorkflowEngineClient from "services/workflow_engine_client";
import Step from "models/workflow_engine/step";
import WorkflowList from "./list";
import CompletedStep from "./completed_step";

export default {
  components: {
    WorkflowList,
    CompletedStep,
  },
  data() {
    return {
      workflow: null,
      pendingSteps: [],
      completedSteps: [],
    };
  },
  mounted() {
    const workflowId = this.$route.params.id;
    const client = new WorkflowEngineClient();

    client.getPendingSteps(workflowId).then(data => {
      this.pendingSteps = data.map(d => Step.deserialize(d));
    });

    client.getCompletedSteps(workflowId).then(data => {
      this.completedSteps = data.map(d => Step.deserialize(d));
    });
  },
  computed: {
    showCompletedSteps() {
      return this.completedSteps.length;
    },
    showPendingSteps() {
      return this.pendingSteps.length;
    },
  },
};
</script>
