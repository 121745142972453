<template>
  <div class="checkbox-control">
    <input :id="identifier" type="checkbox" v-model="checkedValue" @change="change" />
    <label :for="identifier">
      <span v-if="partial" class="control-toggle control-toggle-partial"></span>
      <span v-else class="control-toggle"></span>
      {{ label }}
    </label>
  </div>
</template>
<script>
export default {
  name: "checkbox",
  props: {
    id: {
      type: String,
    },
    value: {
      required: true,
    },
    partial: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
  },
  methods: {
    change(event) {
      const value = this.partial ? !event.target.checked : event.target.checked;

      this.$emit("input", value);
      this.$emit("change", value);

      if (this.partial) {
        Vue.nextTick(() => (this.checkedValue = value));
      }
    },
  },
  data() {
    return { checkedValue: this.value };
  },
  watch: {
    value() {
      this.checkedValue = this.value;
    },
  },
  computed: {
    identifier() {
      return this.id || this.generatedId;
    },
    generatedId() {
      return Math.random()
        .toString(36)
        .substr(2, 9);
    },
  },
};
</script>
