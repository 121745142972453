<template>
  <div class="row">
    <div class="col-12">
      <h5>Note Interest Calculation</h5>
    </div>
    <div class="col-4">
      <editable-field
        label="Note Interest Accrual"
        :value="statement.interestAccrualMethodText"
        :editing="isEditable"
      />
    </div>
    <div class="col-4">
      <editable-field
        label="Note Interest Calculation"
        :value="statement.interestCalculationMethodText"
        :editing="isEditable"
      />
    </div>
    <div class="col-12">
      <h5>Interest Calculations</h5>
    </div>
    <div class="col-4">
      <editable-field
        label="Interest Due"
        :value="statement.interestDueFromBorrowerAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div v-if="isLoanSaleStatement" class="col-4">
      <editable-currency-field
        label="Corrected Interest Due"
        :value="statement.correctedInterestDueFromBorrowerAmount"
        :callback="updateEditableField('correctedInterestDueFromBorrowerAmount', statement)"
        :allow-null="true"
      />
    </div>
    <div class="col-4">
      <div class="editable-field-container">
        <waived-input
          label="Waived Interest"
          :value="statement.waivedInterestDueFromBorrowerAmount"
          :amount="statement.currentInterestDueFromBorrowerAmount"
          :data-change="updateEditableField('waivedInterestDueFromBorrowerAmount', statement)"
        />
      </div>
    </div>
    <!-- Added to keep layout intact. Remove line below once we add Corrected Interests to Repurchases -->
    <div v-if="!isLoanSaleStatement" class="col-4" />
    <div class="col-4">
      <editable-field
        label="Default Interest Due"
        :editing="isEditable"
        :value="statement.defaultInterestDueFromBorrowerAmount"
        currency
      />
    </div>
    <div v-if="isLoanSaleStatement" class="col-4">
      <editable-currency-field
        label="Corrected Default Interest Due"
        :value="statement.correctedDefaultInterestDueFromBorrowerAmount"
        :callback="updateEditableField('correctedDefaultInterestDueFromBorrowerAmount', statement)"
        :allow-null="true"
      />
    </div>
    <div class="col-4">
      <div class="editable-field-container">
        <waived-input
          label="Waived Default Interest"
          :value="statement.waivedDefaultInterestDueFromBorrowerAmount"
          :amount="statement.currentDefaultInterestDueFromBorrowerAmount"
          :data-change="updateEditableField('waivedDefaultInterestDueFromBorrowerAmount', statement)"
        />
      </div>
    </div>
    <!-- Added to keep layout intact. Remove line below once we add Corrected Interests to Repurchases -->
    <div v-if="!isLoanSaleStatement" class="col-4" />
    <div class="col-4">
      <editable-field
        label="Deferred Interest Due"
        :editing="isEditable"
        :value="statement.deferredInterestAmount"
        :disabled="false"
        :callback="updateEditableField('deferredInterestAmount', statement)"
        currency
      />
    </div>
    <div class="col-4">
      <div class="editable-field-container">
        <waived-input
          label="Waived Deferred Interest"
          :value="statement.waivedDeferredInterestAmount"
          :amount="statement.deferredInterestAmount"
          :data-change="updateEditableField('waivedDeferredInterestAmount', statement)"
        />
      </div>
    </div>
    <div class="col-4" />
    <div class="col-4">
      <editable-field
        label="Unpaid Interest Due"
        :editing="isEditable"
        :value="statement.unpaidInterestDueFromBorrowerAmount"
        :disabled="false"
        :callback="updateEditableField('unpaidInterestDueFromBorrowerAmount', statement)"
        currency
      />
    </div>
    <div class="col-4">
      <div class="editable-field-container">
        <waived-input
          label="Waived Unpaid Interest"
          :value="statement.waivedUnpaidInterestDueFromBorrowerAmount"
          :amount="statement.unpaidInterestDueFromBorrowerAmount"
          :data-change="updateEditableField('waivedUnpaidInterestDueFromBorrowerAmount', statement)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WaivedInput from "components/manage/payoff_statement/waived_input";
import EditableField from "components/shared/editable_field.vue";
import EditPartialMixin from "components/manage/payoff_statement/edit/edit_partial_mixin";
import EditableCurrencyField from "components/shared/editable_currency_field";

export default {
  name: "payoff-statement-edit-note-interest-calculation",
  components: {
    EditableCurrencyField,
    EditableField,
    WaivedInput,
  },
  mixins: [EditPartialMixin],
  props: ["isEditable", "statement"],
  computed: {
    isLoanSaleStatement() {
      return this.statement.statementType !== "peerstreet_repurchase";
    },
  },
};
</script>
