<template>
  <div class="details-row row no-gutters section-content loan-modifications">
    <section-header
      :actions-hidden="isActionsHidden"
      :editing="isModifying"
      :updating="isSaving"
      :disabled="isInvalid"
      @edit="onEdit"
      @update="onSave"
      @cancel="cancelForm"
    >
      <div class="loan-modifications-header">
        <h4>Modification Options</h4>
        <div v-if="isModifying && isInvalid" class="loan-modifications-error">Highlighted fields are required</div>
      </div>
    </section-header>
    <div class="col-12">
      <span class="action-link" @click="addNewOption" v-show="showAddNewOption">+ Add Option</span>
      <modification-form v-if="isCreating" :option-number="nextOptionNumber" @change="changeNewModification" />
      <loan-modifications-list :items="loanModifications" :editing="isEditing" @change="changeLoanModifications" />
    </div>
  </div>
</template>

<script>
import SectionHeader from "./section_header";
import LoanModificationsList from "./loan_modifications/list";
import ModificationForm from "./loan_modifications/modification_form";
import { complexObjectToSnakeCaseDeep } from "utils/object_to_snake_case";
import AjaxService from "services/ajax_service";
import keyBy from "lodash/keyBy";

const serializePayload = payload => complexObjectToSnakeCaseDeep(payload);
const prepareLoanModification = modification => ({
  ...modification,
  peerstreetFeeAllocation: modification.peerstreetFeeAllocation || "0",
});

const SHOW_MODIFICATIONS_VIEW = "showing";
const CREATE_MODIFICATION_VIEW = "creating";
const EDIT_MODIFICATIONS_VIEW = "editing";

export default {
  components: {
    SectionHeader,
    LoanModificationsList,
    ModificationForm,
  },
  $_veeValidate: {
    validator: "new",
  },
  data() {
    return {
      loanModificationsByKey: keyBy(PSData.loanModifications || [], "id"),
      newModification: {},
      changedModificationsByKey: {},
      isSaving: false,
      isValid: true,
      viewState: SHOW_MODIFICATIONS_VIEW,
    };
  },
  methods: {
    async addNewOption() {
      this.viewState = CREATE_MODIFICATION_VIEW;

      await this.validateForms();
    },
    cancelForm() {
      this.viewState = SHOW_MODIFICATIONS_VIEW;
      this.newModification = {};
      this.changedModificationsByKey = {};
    },
    async onEdit() {
      this.viewState = EDIT_MODIFICATIONS_VIEW;

      await this.validateForms();
    },
    async onSave() {
      if (this.isCreating) {
        this.createModification();
      } else {
        this.updateModifications();
      }
    },
    async createModification() {
      try {
        this.isSaving = true;
        const payload = prepareLoanModification(this.newModification);
        const modification = await this.upsertModification({
          payload,
          onSuccess: "New modification was created successfully",
          onError: "Failed to create a new modification",
        });
        this.$set(this.loanModificationsByKey, modification.id, modification);
        this.newModification = {};
        this.viewState = SHOW_MODIFICATIONS_VIEW;
      } finally {
        this.isSaving = false;
      }
    },
    async updateModifications() {
      try {
        this.isSaving = true;
        const changedModifications = Object.values(this.changedModificationsByKey);

        const modifications = await Promise.all(
          changedModifications.map(modification =>
            this.upsertModification({
              payload: prepareLoanModification(modification),
              onSuccess: "Modification was updated successfully",
              onError: "Failed to update modification",
            })
          )
        );
        modifications.forEach(modification => {
          this.$set(this.loanModificationsByKey, modification.id, modification);
        });
        this.changedModificationsByKey = {};
        this.viewState = SHOW_MODIFICATIONS_VIEW;
      } finally {
        this.isSaving = false;
      }
    },
    upsertModification({ payload, onSuccess, onError }) {
      const url = `/manage/loans/${this.loanPsId}/modifications/upsert`;
      const serializedPayload = serializePayload(payload);
      return AjaxService.withNotifications({ onSuccess, onError }).patchJSON(
        url,
        serializedPayload
      );
    },
    async changeNewModification(value) {
      this.newModification = value;
      await this.validateForms();
    },
    async changeLoanModifications(modificationId, data) {
      this.$set(this.changedModificationsByKey, modificationId, data);
      await this.validateForms();
    },

    async validateForms() {
      await Vue.nextTick();
      const result = await this.$validator.validateScopes();
      this.isValid = result;
    },
  },
  computed: {
    loanPsId: () => PSData.loan.toParam,
    loanModifications() {
      return Object.values(this.loanModificationsByKey);
    },
    nextOptionNumber() {
      return Math.max(...this.loanModifications.map(m => m.sequenceNumber), 0) + 1;
    },
    noLoanModifications() {
      return this.loanModifications.length === 0;
    },
    isActionsHidden() {
      return (
        !this.canEdit || (this.viewState !== CREATE_MODIFICATION_VIEW && this.noLoanModifications)
      );
    },
    showAddNewOption() {
      return this.canEdit && !this.isModifying;
    },
    isModifying() {
      return this.isEditing || this.isCreating;
    },
    isEditing() {
      return this.viewState === EDIT_MODIFICATIONS_VIEW;
    },
    isCreating() {
      return this.viewState === CREATE_MODIFICATION_VIEW;
    },
    isInvalid() {
      return !this.isValid;
    },
    canEdit() {
      return PSData.canEditLoanModifications;
    },
  },
};
</script>
