<template>
  <div class="pricing-grids-list">
    <pricing-grid-form
      v-if="selectedGrid"
      :grid="selectedGrid"
      :routes="routes"
      @on-cancel="cancelAddingGrid"
      @on-grids-update="updateGrids"
    />
    <pricing-grid-list
      v-else
      :grids="grids"
      :routes="routes"
      @add-new-grid="addNewGrid"
      @on-grid-edit="editGrid"
      @on-grid-copy="copyGrid"
      @on-grids-update="updateGrids"
    />
  </div>
</template>

<script>
import PricingGridForm from "./form";
import PricingGridList from "./list";

import PricingGrid from "models/pricing_grid";

export default {
  name: "pricing-grids",
  components: { PricingGridForm, PricingGridList },
  data() {
    return {
      selectedGrid: undefined,
      grids: PSData.pricingGrids,
      routes: PSData.routes,
    };
  },
  mounted() {
    if (window.location.hash === "#new") {
      this.selectedGrid = PricingGrid.buildEmpty();
    }
  },
  methods: {
    addNewGrid() {
      this.selectedGrid = PricingGrid.buildEmpty();
    },
    cancelAddingGrid() {
      this.selectedGrid = undefined;
    },
    updateGrids(grids) {
      this.grids = grids;
      this.cancelAddingGrid();
    },
    editGrid(grid) {
      this.selectedGrid = PricingGrid.deserialize(grid);
    },
    copyGrid(grid) {
      this.selectedGrid = PricingGrid.deserialize(
        Object.assign({}, grid, { label: `Copy of ${grid.label}` })
      ).copy();
    },
  },
};
</script>
