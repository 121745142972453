<template>
  <div>
    <h4 class="details-section-title">
      {{ details.name }}
      <div v-if="details.isLoading" class="ps-loader ps-loader-small"></div>
    </h4>
    <div class="detail-item-section">
      <div v-for="(detail, key) in details.data" :key="key" class="detail-item">
        <span>
          {{ detail.label }}
        </span>
        <span class="item-value">
          {{ detail.value | notAvailable }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default Vue.component("report-row-details", {
  props: {
    details: Object,
  },
});
</script>
