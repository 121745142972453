<template>
  <div class="account-types-menu">
    <h5>Select any to view</h5>
    <ul class="account-types-menu-list">
      <li
        :class="{'account-types-menu-item--selected': accountType.isSelected}"
        :key="accountType.name"
        class="account-types-menu-item"
        v-for="accountType in accountTypes"
        @click="change(accountType)"
      >
        {{accountType.label}}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    accountTypes: Array,
  },
  methods: {
    change(accountType) {
      this.$emit("change", accountType);
    },
  },
};
</script>
