import selectOptionsToMapping from "utils/select_options_to_mapping";
import { PROGRAM_CLASSIFICATION } from "models/lender_form/program";
import objectToSnakeCase from "utils/object_to_snake_case";

export const PROPERTY_TYPES = Object.freeze({
  sfr: "sfr",
  condo: "condo",
  duplex: "duplex",
  triplex: "triplex",
  fourplex: "fourplex",
  multifamily: "multifamily",
  mixed_use: "mixed_use",
  office: "office",
  retail: "retail",
  industrial_warehouse: "industrial_warehouse",
  land: "land",
  mobile_home_park: "mobile_home_park",
  self_storage: "self_storage",
  hospitality: "hospitality",
  special_purpose: "special_purpose",
  lot: "lot",
  multifamily_5_plus: "multifamily_5_plus",
});

export const countryUS = "US";

const PROPERTY_TYPES_BY_PROGRAM = {
  [PROGRAM_CLASSIFICATION.RESIDENTIAL_BRIDGE]: [
    PROPERTY_TYPES.sfr,
    PROPERTY_TYPES.condo,
    PROPERTY_TYPES.duplex,
    PROPERTY_TYPES.triplex,
    PROPERTY_TYPES.fourplex,
  ],
  [PROGRAM_CLASSIFICATION.RESIDENTIAL_RENTAL_LONG_TERM]: [
    PROPERTY_TYPES.sfr,
    PROPERTY_TYPES.condo,
    PROPERTY_TYPES.duplex,
    PROPERTY_TYPES.triplex,
    PROPERTY_TYPES.fourplex,
  ],
  [PROGRAM_CLASSIFICATION.EXTENDED_BRIDGE]: [
    PROPERTY_TYPES.sfr,
    PROPERTY_TYPES.condo,
    PROPERTY_TYPES.duplex,
    PROPERTY_TYPES.triplex,
    PROPERTY_TYPES.fourplex,
  ],
  [PROGRAM_CLASSIFICATION.COMMERCIAL_MULTIFAMILY]: [
    PROPERTY_TYPES.mixed_use,
    PROPERTY_TYPES.multifamily,
  ],
  [PROGRAM_CLASSIFICATION.COMMERCIAL_NON_MULTIFAMILY]: [
    PROPERTY_TYPES.mixed_use,
    PROPERTY_TYPES.office,
    PROPERTY_TYPES.industrial_warehouse,
  ],
  [PROGRAM_CLASSIFICATION.GROUND_UP_CONSTRUCTION]: [
    PROPERTY_TYPES.lot,
    PROPERTY_TYPES.sfr,
    PROPERTY_TYPES.condo,
    PROPERTY_TYPES.duplex,
    PROPERTY_TYPES.triplex,
    PROPERTY_TYPES.fourplex,
    PROPERTY_TYPES.multifamily_5_plus,
  ],
};

export default class Property {
  constructor(property = {}, options = {}, loan = {}) {
    this.data = {};
    this.update(property);
    this.loan = loan;
    this._optionsRaw = options;
    this._propertyTypes = selectOptionsToMapping(options.propertyTypes || []);
    this._states = selectOptionsToMapping(options.states || []);
    this._tenancies = selectOptionsToMapping(options.tenancies || []);
  }

  get propertyTypeLabel() {
    return this._propertyTypes[this.data.propertyType];
  }

  get propertyTypeCondo() {
    return this.data.propertyType === PROPERTY_TYPES.condo;
  }

  get stateLabel() {
    return this._states[this.data.state];
  }

  get tenancyLabel() {
    return this._tenancies[this.data.tenancy];
  }

  get yesNoOptions() {
    return {
      true: "Yes",
      false: "No",
    };
  }

  get environmentalIssuesIdentifiedLabel() {
    return this.yesNoOptions[this.data.environmentalIssuesIdentified];
  }

  get groundLeaseLabel() {
    return this.yesNoOptions[this.data.groundLease];
  }

  get countryUS() {
    return this.data.country === countryUS;
  }

  get fullAddressCalculated() {
    const combinedAddress = [this.data.street, this.data.city, this.data.state]
      .filter(item => !!item)
      .join(", ");

    return `${combinedAddress} ${this.data.postalCode || ""}`;
  }

  get states() {
    return this._states;
  }

  get propertyTypes() {
    return (PROPERTY_TYPES_BY_PROGRAM[this.loan.programType] || []).reduce(
      (propertyTypes, type) => {
        propertyTypes[type] = this._propertyTypes[type];
        return propertyTypes;
      },
      {}
    );
  }

  get tenancies() {
    return this._tenancies;
  }

  get objectToSave() {
    return objectToSnakeCase(this.data);
  }

  update(updatedProperty) {
    this._rawData = Object.assign({}, updatedProperty);
    this.data = updatedProperty;
  }

  reset() {
    this.data = this._rawData;
  }
}
