<template>
  <div class="details-row row">
    <div class="col-12">
      <h4>Statement Comments</h4>
    </div>
    <div v-for="comment in statement.comments" :key="comment.id" class="fields-container col-9 comment-block">
      <label>Comment</label>
      <textarea v-model="comment.comment" rows="5" class="comment" />
      <img class="trash-icon comment-trash" :src="require('images/icons/icon_trash_black.svg')" @click="removeComment(comment)">
    </div>
    <div class="col-12">
      <a @click="addComment">+ Add Comments</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "payoff-statement-edit-comments",
  props: ["statement"],
  methods: {
    removeComment(comment) {
      this.$emit("remove-comment", comment);
    },
    addComment() {
      this.$emit("add-comment");
    },
  },
};
</script>
