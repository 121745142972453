<template>
  <div>
    <base-table v-if="isLoading" :data="skeletonRuns" hoverable paginated :per-page="runsPerPage" pagination-simple class="mb-5">
      <b-table-column v-slot="props" field="date" label="Date" width="124">
        <base-skeleton width="76px" />
      </b-table-column>
      <b-table-column v-slot="props" field="user" label="User" cell-class="user" width="70">
        <base-skeleton circle width="32px" height="32px" />
      </b-table-column>
      <b-table-column v-slot="props" field="loanType" label="Type" width="195">
        <base-skeleton width="120px" />
      </b-table-column>
      <b-table-column v-slot="props" field="statuses" label="Status" width="200">
        <base-skeleton width="120px" />
      </b-table-column>
      <b-table-column v-slot="props" label="Rate Change" width="180">
        <base-skeleton width="50px" />
      </b-table-column>
      <b-table-column v-slot="props" field="additionalRules" label="Additional Rules">
        <base-skeleton />
      </b-table-column>
      <b-table-column v-slot="props" width="190">
        <div style="display: inline-block; height: 24px; width: 56px;">
          <base-skeleton v-if="props.row.isLatest" height="24px" width="56px" />
        </div>
        <div class="is-pulled-right" style="display: inline-block; height: 24px; width: 76px;">
          <base-skeleton height="24px" width="76px" />
        </div>
      </b-table-column>
    </base-table>
    <base-table v-else-if="hasAppliedOrInProgressRuns" :data="runs" hoverable paginated :per-page="runsPerPage" pagination-simple class="mb-5">
      <b-table-column v-slot="props" field="date" label="Date" width="124">
        <template v-if="!props.row.inProgress">{{ formatDate(props.row.date) }}</template>
        <base-skeleton :active="props.row.inProgress" width="76px"></base-skeleton>
      </b-table-column>
      <b-table-column v-slot="props" field="user" label="User" cell-class="user" width="70">
        <user v-if="!props.row.inProgress" :name="props.row.user" />
        <base-skeleton :active="props.row.inProgress" :circle="true" :height="circleSize" :width="circleSize"></base-skeleton>
      </b-table-column>
      <b-table-column v-slot="props" field="settings" label="Type" width="195">
        <template v-if="!props.row.inProgress" >{{ typeList(props.row.settings.criteria.programTypes) }}</template>
        <base-skeleton :active="props.row.inProgress" width="120px"></base-skeleton>
      </b-table-column>
      <b-table-column v-slot="props" field="settings" label="Status" width="200">
        <template v-if="!props.row.inProgress" >{{ statusList(props.row.settings.criteria.underwritingStatuses) }}</template>
        <base-skeleton :active="props.row.inProgress" width="120px"></base-skeleton>
      </b-table-column>
      <b-table-column v-slot="props" field="settings" label="Rate Change" width="180">
        <template v-if="!props.row.inProgress">
          <span v-if="props.row.isRevert">Revert</span>
          <span v-else-if="isCsvUpdateMethod(props.row)">CSV Upload</span>
          <template v-else-if="props.row.settings.ltvFicoGrid">
            <a @click.prevent="openGridViewModal(props.row.settings.ltvFicoGrid)">custom pricing grid</a>
          </template>
          <template v-else>
            {{ formatPercent(props.row.settings.criteria.flatRateAdjustment) }}
          </template>
        </template>
        <base-skeleton :active="props.row.inProgress" width="50px"></base-skeleton>
      </b-table-column>
      <b-table-column v-slot="props" field="settings" label="Additional Rules">
        <template v-if="!props.row.inProgress && props.row.settings.rule">
          <template v-for="(rule, index) in props.row.settings.rule.adjustments">
            <rule :rule="rule" :last="lastRule(index, props.row.settings.rule.adjustments.length)"/>
          </template>
        </template>
        <base-skeleton :active="props.row.inProgress"></base-skeleton>
      </b-table-column>
      <b-table-column v-slot="props" width="190">
        <template v-if="!props.row.inProgress">
          <base-button v-if="props.row === runs[0]" size="small" @click="showRevertModal(props.row)">Revert</base-button>
          <base-button size="small" @click="downloadPreview(props.row)" class="is-pulled-right">Download</base-button>
        </template>
        <div v-if="props.row.inProgress && props.row == runs[0]" style="display: inline-block; height: 24px; width: 56px;">
          <base-skeleton :active="props.row.inProgress && props.row == runs[0]" height="24px" width="56px" />
        </div>
        <div v-if="props.row.inProgress" class="is-pulled-right" style="display: inline-block; height: 24px; width: 76px;">
          <base-skeleton :active="props.row.inProgress" height="24px" width="76px" />
        </div>
      </b-table-column>
    </base-table>
    <div v-else class="criteria-box">
      You have not performed any batch updates.
    </div>

    <grid-view-modal
      :show="showGridViewModal"
      :grid="gridView"
      @close="closeGridViewModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import useHistoryStore from "./stores/history";
import usePreviewStore from "./stores/preview";
import { programTypes, underwritingStatuses } from "./utils/mappings";
import { formatPercent, formatDate } from "./utils/formatter";
import User from "./user";
import Rule from "./rule";
import GridViewModal from "./grid_view_modal";
import UPDATE_METHODS from "./consts/update_methods";

const SIZE = "32px";

export default {
  name: "history",
  components: {
    User,
    Rule,
    GridViewModal,
  },
  data: () => ({
    gridView: null,
    showGridViewModal: false,
  }),
  created() {
    this.loadHistory();
  },
  computed: {
    underwritingStatuses: () => underwritingStatuses,
    programTypes: () => programTypes,

    ...mapState(useHistoryStore, ["runs", "isLoading"]),

    hasAppliedOrInProgressRuns() {
      return this.runs.length > 0;
    },
    circleSize() {
      return SIZE;
    },
    skeletonRuns() {
      const runs = Array(this.runsPerPage);
      runs.fill({}, 1);
      runs[0] = { isLatest: true };
      return runs;
    },
    runsPerPage: () => 10,
  },
  methods: {
    formatPercent,
    formatDate,

    ...mapActions(useHistoryStore, ["loadHistory", "loadStatuses"]),
    ...mapActions(usePreviewStore, ["downloadCsv", "revert"]),

    typeList(types) {
      return types.map(type => this.programTypes[type]).join(", ");
    },
    statusList(statuses) {
      return statuses.map(status => this.underwritingStatuses[status]).join(", ");
    },
    openGridViewModal(grid) {
      this.gridView = grid;
      this.showGridViewModal = true;
    },
    closeGridViewModal() {
      this.gridView = null;
      this.showGridViewModal = false;
    },
    showPricingGrid(grid) {
      this.$buefy.dialog.alert({
        title: "Custom Pricing Grid",
        message: grid,
        confirmText: "OK",
        type: "is-primary",
      });
    },
    async revertRun(row) {
      await this.revert(row.previewId);
      await this.loadHistory();
    },
    showRevertModal(row) {
      this.$buefy.dialog.confirm({
        title: "Revert to previous rate",
        message: `Are you sure?  By doing so, you’ll be modifying ${
          row.activeLoanCount
        } active loans.`,
        confirmText: "Yes, Update Rates",
        type: "is-primary",
        focusOn: "cancel",
        onConfirm: () => {
          this.revertRun(row);
        },
      });
    },
    downloadPreview(row) {
      this.downloadCsv(row.previewId);
    },
    lastRule(current, total) {
      return current === total - 1;
    },
    isCsvUpdateMethod(row) {
      return row.settings.criteria.updateMethod === UPDATE_METHODS.CSV_UPLOAD;
    },
  },
};
</script>
