<template>
  <div class="cash-receipts-header">
    <h2>Distributions</h2>
    <tabs class="nav-menu" :tabs="tabs" @tab-selected="handleTabSelected" />
  </div>
</template>

<script>
import get from "lodash/get";
import Tabs from "components/shared/tabs";

export default {
  components: {
    Tabs,
  },
  props: {
    currentTab: {
      type: String,
      required: true,
    },
  },
  computed: {
    tabs() {
      return {
        unreconciled: {
          name: "Unreconciled",
          active: this.currentTab === "unreconciled",
        },
        hold: {
          name: "Hold",
          active: this.currentTab === "hold",
        },
        review: {
          name: "Review",
          active: this.currentTab === "review",
        },
        ready: {
          name: "Ready to Distribute",
          active: this.currentTab === "ready",
        },
        admin: {
          name: "Configurations",
          active: this.currentTab === "admin",
        },
        calculator: {
          name: "Calculator",
          active: this.currentTab == "calculator",
        },
        missing_series: {
          name: "Missing Series",
          active: this.currentTab == "missing_series",
        },
      };
    },
  },
  methods: {
    isActive(tab) {
      return get(this.tabs, [tab, "active"], false);
    },
    handleTabSelected(tab) {
      if (this.isActive(tab)) {
        return;
      }
      window.location = `/manage/cash_receipts/${tab}`;
    },
  },
};
</script>
