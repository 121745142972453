const Event = PS.Models.Event;
const Rule = PS.Models.Rule;
const Events = PS.Models.EmailSubscription.Events;
const Commands = PS.Models.EmailSubscription.Commands;

class AllItemRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.SubscriptionUpdated:
        return this.whenSubscriptionUpdated(event);
    }

    return super.check(event);
  }

  whenSubscriptionUpdated(event) {
    let isDifferentCategory = event.subscription.category !== this.state.subscription.category;
    let isAllSubscriptionEnabled = this.state.subscription.enabled;
    let isSubscriptionEnabled = event.subscription.enabled;

    if (isDifferentCategory || !isAllSubscriptionEnabled || !isSubscriptionEnabled) {
      return [];
    }

    return [new Commands.AllItem.DisableSubscription(new Event(), this.state)];
  }
}

PS.Models.EmailSubscription.AllItemRule = AllItemRule;
