<template>
  <div class="lender-block-statuses-container">
    <div class="form-section">
      <header class="form-header">Block Lender</header>
      <label class="checkbox-toggle">
        <input :disabled="disabled" type="checkbox" name="block-active" v-model="newBlockStatus.active" @click="toggleActive">
        <div class="toggle-control"></div>
        {{ toggleLabel }}
      </label>
      <p class=reasons-header>Reason(s)</p>
      <div class="row reasons-container">
        <div v-for="(reasonGroup, index) in groupedReasons" :key="'reason-group-' + index" class="col-sm-6">
          <div v-for="(reason, index) in reasonGroup" :key="'reason-' + index">
            <div class="checkbox-control">
              <input :disabled="disabled" type="checkbox" :name="'reason-' + reason.id" :id="'reason-' + reason.id" v-model="reason.checked">
              <label :for="'reason-' + reason.id">
                <span class="control-toggle"></span>
                <span class="reason-label">{{ reason.label }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group control-wrapper explanation-container">
        <label>Explanation</label>
        <textarea :disabled="disabled" class="form-control" v-model="newBlockStatus.description" />
      </div>
      <div class="btn btn-action-cta" @click="save" :disabled="isSaveDisabled">{{ saveLabel }}</div>
    </div>

    <div class="history-container" v-if="isHistoryPresent">
      <header class="form-header">History</header>
      <div v-for="(status, index) in blockStatuses" :key="'status-' + index" class="history-log">
        <p class=log-header>{{ status.historyHeader }}</p>
        <p class=log-description>{{ status.description }}</p>
        <p class=log-user>{{ status.user.name }} - {{ status.createdAt }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Ajax from "services/ajax_service";
import get from "lodash/get";
import chunk from "lodash/chunk";

export default {
  name: "lender-block-statuses",
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      lenderBlockStatusesRoute: get(PSData, "routes.lenderBlockStatuses"),
      blockReasonsMenu: [],
      blockStatuses: [],
      newBlockStatus: {},
      isUpdating: false,
    };
  },
  mounted() {
    this.getBlockStatuses();
  },
  computed: {
    latestBlockStatus() {
      return this.blockStatuses[0] || {};
    },
    toggleLabel() {
      return this.newBlockStatus.active ? "Blocked" : "Not Blocked";
    },
    isSaveDisabled() {
      if (this.disabled) return true;
      if (this.isUpdating) return true;
      if (!(this.newBlockStatus.description && this.newBlockStatus.description.trim())) return true;
      if (this.newBlockStatus.active && this.checkedReasonIds.length == 0) return true;
      if (!this.newBlockStatus.active && this.checkedReasonIds.length > 0) return true;
      return false;
    },
    checkedReasonIds() {
      return this.blockReasonsMenu.filter(reason => reason.checked).map(reason => reason.id);
    },
    postParams() {
      return {
        lender_block_status: {
          active: this.newBlockStatus.active,
          description: this.newBlockStatus.description,
          reason_ids: this.checkedReasonIds,
        },
      };
    },
    saveLabel() {
      return this.isUpdating ? "Saving..." : "Save";
    },
    groupedReasons() {
      return chunk(this.blockReasonsMenu, 2);
    },
    blockStatusFormPlaceholder() {
      const { description, ...rest } = this.blockStatuses[0] || { active: false, reasons: [] };
      return rest;
    },
    isHistoryPresent() {
      return this.blockStatuses.length > 0;
    },
  },
  methods: {
    getBlockStatuses() {
      Ajax.get(this.lenderBlockStatusesRoute).then(data => {
        this.handleFetchedData(data);
      });
    },
    handleFetchedData(data) {
      this.blockStatuses = data.lender_block_statuses || [];
      this.newBlockStatus = this.blockStatusFormPlaceholder;
      this.initializeReasonsMenu(data.lender_block_reasons_collection);
    },
    toggleActive(ev) {
      if (!ev.currentTarget.checked) {
        this.clearReasonsMenu();
      }
    },
    initializeReasonsMenu(reasonsCollection = []) {
      for (const [index, option] of reasonsCollection.entries()) {
        option.checked = this.newBlockStatus.reasons.some(selected => option.id === selected.id);
        this.$set(this.blockReasonsMenu, index, option);
      }
    },
    clearReasonsMenu() {
      for (let option of this.blockReasonsMenu) {
        option.checked = false;
      }
    },
    save() {
      this.isUpdating = true;
      const messages = { onSuccess: "Lender block status updated.", onError: this.onError };
      return Ajax.withNotifications(messages)
        .postJSON(this.lenderBlockStatusesRoute, this.postParams)
        .then(data => {
          this.handleSavedData(data);
          this.isUpdating = false;
        });
    },
    handleSavedData(data) {
      this.blockStatuses.unshift(data);
      this.newBlockStatus = this.blockStatusFormPlaceholder;
    },
    onError({ data }) {
      return {
        message: data.error,
        status: "error",
      };
    },
  },
};
</script>
