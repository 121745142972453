import LoanPropertiesList from "components/loan_properties/properties_list.vue";
import LoanProperty from "components/loan_properties/property.vue";
import ActivityFeedEventsSection from "components/activity_feed_events/manage/section";
import PiecesOfCollateral from "components/tags/pieces_of_collateral";
import CopyToClipboard from "components/copy_to_clipboard";
import LenderTagsMixin from "../../mixins/manage/lenders/tags";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-properties-controller"),
    components: {
      ActivityFeedEventsSection,
      LoanPropertiesList,
      LoanProperty,
      PiecesOfCollateral,
      CopyToClipboard,
    },
    mixins: [LenderTagsMixin],
    methods: {
      loanFollowersLoaded() {},
    },
  });
});
