import HotlistLoansRule from "models/reports/hotlist_loans/rules/hotlist_loans_rule";
import MessageBusService from "services/message_bus_service";
import Loans from "collections/manage/loans";
import LoanSales from "models/loan/loan_sales";
import MonthlyLoanSalesComponent from "components/monthly_loan_sales";
import QuarterlyLoanSalesComponent from "components/quarterly_loan_sales";

$(() => {
  new Vue({
    el: document.querySelector(".v-report-loan-controller"),
    components: {
      "monthly-loan-sales": MonthlyLoanSalesComponent,
      "quarterly-loan-sales": QuarterlyLoanSalesComponent,
    },
    data: function() {
      let loans = Loans.mergeAssociations({
        loans: PSData.reportLoans,
        positions: PSData.positions,
        analysts: PSData.analysts,
        lenders: PSData.lenders,
        documents: PSData.documents,
        serverTime: PSData.serverTime,
        loanPrograms: PSData.loanPrograms,
        accountTypes: PSData.accountTypes,
      });

      return {
        groupedLoans: Loans.groupByUnderwritingStatus(loans),
        loanSales: new LoanSales(PSData.lenderSales6Quarters, PSData.lenderGoals),
        messageBus: MessageBusService.hotlistBus,
      };
    },
    mounted: function() {
      this.ruleBook = new HotlistLoansRule(this);
      this.messageBus.attachRulebook(this.ruleBook);
    },
    watch: {
      groupedLoans: {
        immediate: true,
        handler: function() {
          this.$nextTick(function() {
            $(".analyst-notes a").tooltip();
          });
        },
      },
    },
  });
});
