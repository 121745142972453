<template>
  <div class="servicer-loan-boarding-details">
    <a class="show-more-button" @click="showDetails">Show more</a>
    <div v-if="show">
      <modal-view :show="show" :on-close="onClose">
        <div class="modal-content base-modal">
          <div class="modal-header">
            <h4>Loan Data</h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="section">General</div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Loan ID</div>
                  <div class="property-value">
                    <a :href="details.routes.loan" target="_blank">{{ details.psLoanId }}</a>
                  </div>
                </div>
                <div class="property">
                  <div class="property-name">Subservicer ID</div>
                  <div class="property-value">{{ details.servicerLoanCode }}</div>
                </div>
                <div class="property">
                  <div class="property-name">BWL Date</div>
                  <div class="property-value"></div>
                </div>
                <div class="property">
                  <div class="property-name">Review Reasons</div>
                  <div class="property-value property-value-review">
                    <review-flags :record="item" />
                  </div>
                </div>
              </div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Subservicer Comments</div>
                  <div class="property-value">
                    <truncated-text :value="details.flaggedComments" :max-length="maxLength" />
                  </div>
                </div>
                <div class="property">
                  <div class="property-name">Notes to Subservicer</div>
                  <div class="property-value">
                    <truncated-text :value="details.servicerComments" :max-length="maxLength" />
                  </div>
                </div>
                <div class="property">
                  <div class="property-name">PS Internal Notes</div>
                  <div class="property-value">
                    <truncated-text :value="details.comments" :max-length="maxLength" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="section">Property</div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Property Type</div>
                  <div class="property-value">{{ details.propertyType }}</div>
                </div>
              </div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Property Address</div>
                  <div class="property-value">
                    <truncated-text :value="details.propertyAddress" :max-length="maxLength" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="section">Loan</div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Loan Origination Date</div>
                  <div class="property-value">{{ details.originationDate }}</div>
                </div>
                <div class="property">
                  <div class="property-name">PS Purchase Date</div>
                  <div class="property-value">{{ details.purchaseDate }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Loan Maturity Date</div>
                  <div class="property-value">{{ details.maturityDate }}</div>
                </div>
              </div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Note Origination</div>
                  <div class="property-value">{{ details.lenderNoteStatus }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Lender</div>
                  <div class="property-value">
                    <truncated-text :value="details.lenderServicerName" :max-length="maxLength" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="section">Capital Structure</div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Original UPB</div>
                  <div class="property-value">{{ details.totalPrincipalBalanceAtOrigination | accounting }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Current UPB</div>
                  <div class="property-value">{{ details.unpaidPrincipalBalance | accounting }}</div>
                </div>
                <div class="property">
                  <div class="property-name">B-piece Amount</div>
                  <div class="property-value">{{ details.bPieceAmount | accounting }}</div>
                </div>
                <div class="property">
                  <div class="property-name">B-piece Holder</div>
                  <div class="property-value">{{ details.bPieceHolder }}</div>
                </div>
              </div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Pari Passu Amount</div>
                  <div class="property-value">{{ details.pariPassuAmount | accounting }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Pari Passu Holder</div>
                  <div class="property-value">{{ details.pariPassuHolder }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Construction Reserve at PS Purchase</div>
                  <div class="property-value">{{ details.constructionReserveRemaining | accounting }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="section">Interest Calculation</div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Rate Type</div>
                  <div class="property-value">{{ details.interestRateType }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Amortization Type</div>
                  <div class="property-value">{{ details.amortizationType }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Note Interest Accrual</div>
                  <div class="property-value">{{ details.interestAccrualMethod }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Note Interest Calculation</div>
                  <div class="property-value">{{ details.interestCalculation }}</div>
                </div>
              </div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Note Interest Rate</div>
                  <div class="property-value">{{ details.interestRate | percent(4) }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Blended Lender Rate</div>
                  <div class="property-value">{{ details.lenderBlendedRate | percent(4) }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Sold Rate</div>
                  <div class="property-value">{{ details.soldRate | percent(4) }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Default Rate</div>
                  <div class="property-value">
                    <missing-attribute value="default_rate" :missing-data="details.missingData">
                      {{ details.defaultRate | percent(4) }}
                    </missing-attribute>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="section">Borrower Payments</div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">First Payment Due Date</div>
                  <div class="property-value">{{ details.firstPaymentDate }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Next Payment Due Date</div>
                  <div class="property-value">{{ details.nextPaymentDueDate }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Paid to Date</div>
                  <div class="property-value">{{ details.paidToDate }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Current P&I Monthly Payments</div>
                  <div class="property-value">
                    <missing-attribute value="monthly_payment_principal_and_interest" :missing-data="details.missingData">
                      {{ details.monthlyPaymentPrincipalAndInterest | accounting }}
                    </missing-attribute>
                  </div>
                </div>
                <div class="property">
                  <div class="property-name">Current Monthly Impound Payments</div>
                  <div class="property-value">
                    <missing-attribute value="monthly_payment_taxes_and_insurance" :missing-data="details.missingData">
                      {{ details.monthlyPaymentTaxesAndInsurance | accounting }}
                    </missing-attribute>
                  </div>
                </div>
              </div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Prepaid Payments</div>
                  <div class="property-value">{{ details.prepaidInterestPayments | accounting }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Odd Day’s Interest</div>
                  <div class="property-value">{{ details.oddDaysInterest | accounting }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Escrow Reserves</div>
                  <div class="property-value">{{ details.escrowReserveAmount | accounting }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="section">Borrower Details</div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Borrower Type</div>
                  <div class="property-value">{{ details.borrowerType }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Name</div>
                  <div class="property-value">
                    <missing-attribute value="borrower_name" :missing-data="details.missingData">
                      <truncated-text :value="details.borrowerName" :max-length="maxLength" />
                    </missing-attribute>
                  </div>
                </div>
                <div class="property">
                  <div class="property-name">Borrower TIN</div>
                  <div class="property-value">
                    <missing-attribute value="encrypted_borrower_tin" :missing-data="details.missingData">
                      {{ details.encryptedBorrowerTin }}
                    </missing-attribute>
                  </div>
                </div>
                <div class="property">
                  <div class="property-name">Phone</div>
                  <div class="property-value">
                    <missing-attribute value="borrower_phone" :missing-data="details.missingData">
                      {{ details.borrowerPhone }}
                    </missing-attribute>
                  </div>
                </div>
                <div class="property">
                  <div class="property-name">Email</div>
                  <div class="property-value">
                    <missing-attribute value="borrower_email" :missing-data="details.missingData">
                      {{ details.borrowerEmail }}
                    </missing-attribute>
                  </div>
                </div>
                <div class="property">
                  <div class="property-name">Address</div>
                  <div class="property-value">
                    <missing-attribute value="address" :missing-data="details.missingData">
                      <truncated-text :value="details.address" :max-length="maxLength" />
                    </missing-attribute>
                  </div>
                </div>
                <div class="property">
                  <div class="property-name">Guarantor Name</div>
                  <div class="property-value">{{ details.guarantorName }}</div>
                </div>
              </div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Coborrower Name</div>
                  <div class="property-value">{{ details.coborrowerName }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Coborrower TIN</div>
                  <div class="property-value">{{ details.encryptedBorrowerTin }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Coborrower Phone</div>
                  <div class="property-value">{{ details.coborrowerPhone }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Coborrower Email</div>
                  <div class="property-value">{{ details.coborrowerEmail }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="section">Miscellaneous</div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Prepayments Penalty</div>
                  <div class="property-value">{{ details.prepaymentPenalty }}</div>
                </div>
                <div class="property">
                  <div class="property-name">Prepayments Penalty Expiration Date</div>
                  <div class="property-value">
                    <missing-attribute value="prepayment_penalty_date" :missing-data="details.missingData">
                      {{ details.prepaymentPenaltyDate }}
                    </missing-attribute>
                  </div>
                </div>
                <div class="property">
                  <div class="property-name">Prepayment Terms</div>
                  <div class="property-value">
                    <missing-attribute value="terms_prepayment" :missing-data="details.missingData">
                      <truncated-text :value="details.termsPrepayment" :max-length="maxLength" />
                    </missing-attribute>
                  </div>
                </div>
              </div>
              <div class="col-md-6 column">
                <div class="property">
                  <div class="property-name">Late Charge Grace Days</div>
                  <div class="property-value">
                    <missing-attribute value="late_charge_grace_days" :missing-data="details.missingData">
                      {{ details.lateChargeGraceDays }}
                    </missing-attribute>
                  </div>
                </div>
                <div class="property">
                  <div class="property-name">Late Charge Terms</div>
                  <div class="property-value">
                    <missing-attribute value="late_charge_description" :missing-data="details.missingData">
                      <truncated-text :value="details.lateChargeDescription" :max-length="maxLength" />
                    </missing-attribute>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-action-cta" @click="onClose">OK</button>
          </div>
        </div>
      </modal-view>
    </div>
  </div>
</template>

<script>
import TruncatedText from "components/servicer/loan_boarding/truncated_text";
import ReviewFlags from "components/servicer/loan_boarding/review_flags";
import MissingAttribute from "components/servicer/loan_boarding/missing_attrubute";
import ModalView from "components/modal_view";
import AjaxService from "services/ajax_service";
import { error } from "services/notification";

const MAX_LENGTH = 20;

export default {
  name: "manage-servicer-loan-boarding-details-popup",
  components: { ModalView, AjaxService, TruncatedText, ReviewFlags, MissingAttribute },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { inFlight: false, show: false, details: undefined };
  },
  methods: {
    onClose() {
      this.$emit("close");
      this.show = false;

      document.body.classList.remove("no-scroll");
    },
    showDetails() {
      if (this.inFlight) return;

      document.body.classList.add("no-scroll");

      this.inFlight = true;

      AjaxService.get(this.item.routes.details)
        .then(({ details }) => {
          this.details = details;
          this.show = true;
        })
        .catch(() => {
          error("Something went wrong");
        })
        .finally(() => (this.inFlight = false));
    },
  },
  computed: {
    maxLength() {
      return MAX_LENGTH;
    },
  },
};
</script>
