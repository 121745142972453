<template>
  <div class="tag-trigger" v-popover="{ content: '#tag-trigger-popover' }">
    <span class="fas fa-tag" />
    <span class="tag-trigger-count">{{tagCount}}</span>

    <div id="tag-trigger-popover" class="popover-content hidden tag-trigger-popover-content">
      <div class="tag-trigger-popover-content-body">
        <h4>Lender Tags ({{ tagCount }})</h4>

        <tag-list orientation="vertical" :initialTags="initialTags" />
      </div>
    </div>
  </div>
</template>

<script>
import TagList from "./tag_list";

export default {
  name: "tag-trigger",
  components: {
    TagList,
  },
  props: {
    initialTags: {
      type: Array,
      required: true,
    },
  },
  computed: {
    tagCount() {
      return this.initialTags.length;
    },
  },
};
</script>
