import requireGoogleMap from "google_maps";

const Command = PS.Models.Command;

class FindLatLong extends Command {
  execute() {
    const { latitude, longitude, query } = this.event;

    if (latitude && longitude) {
      return Promise.resolve();
    }

    if (query) {
      return new Promise((resolve, _) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: query }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            this.event.address.latitude = results[0].geometry.location.lat();
            this.event.address.longitude = results[0].geometry.location.lng();

            resolve();
          } else {
            Bugsnag.notify(
              {
                name: "Geocoder Fail",
                message: "Unable to geocode address",
              },
              {
                metaData: { query: query, status: status, results: results },
              }
            );

            resolve();
          }
        });
      });
    }

    return Promise.resolve();
  }
}

class SetupLocationMap extends Command {
  execute() {
    const name = "Argument Error";
    const message = "Location Map was passed invalid coordinates";
    const { latitude, longitude, query } = this.event;

    const coordinates = {
      lat: latitude,
      lng: longitude,
    };

    if (!latitude || !longitude) {
      Bugsnag.notify({ name, message }, { metaData: { coordinates, query } });
    }

    this.state.map = new google.maps.Map(this.state.container, {
      zoom: 16,
      center: coordinates,
    });

    this.state.marker = new google.maps.Marker({
      position: coordinates,
      map: this.state.map,
      icon: `${this.state.marker}`,
    });

    return Promise.resolve();
  }
}

class WaitForGoogleAPILoading extends Command {
  execute() {
    return requireGoogleMap();
  }
}

PS.Models.LocationMap.Commands = {
  SetupLocationMap: SetupLocationMap,
  WaitForGoogleAPILoading: WaitForGoogleAPILoading,
  FindLatLong: FindLatLong,
};
