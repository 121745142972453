<template>
  <tbody>
    <tr v-if="!series.isDeleted">
      <td>
        <a target="blank" :href="series.retailUrl">
          {{ series.psId }}
        </a>
      </td>
      <td>
        <template v-if="editMode">
          <div class="form-group" :class="{ 'has-error': recordErrors.seriesType }">
            <select v-model="series.seriesType" class="form-control" disabled>
              <option v-for="option in seriesTypeOptions" :key="option.value" :value="option.value">
                {{ option.name }}
              </option>
            </select>
            <span v-for="(error, key) in recordErrors.seriesType" :key="key" class="help-block">
              {{ error }}
            </span>
          </div>
        </template>
        <template v-else="">
          {{ seriesType }}
        </template>
      </td>
      <td class="number-cell">
        <template v-if="editMode">
          <div class="form-group currency" :class="{ 'has-error': recordErrors.amount }">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                v-model="series.amount"
                v-mask:currency=""
                class="form-control"
                type="text"
                :disabled="!isEditable"
              />
            </div>
            <span v-for="(error, key) in recordErrors.amount" :key="key" class="help-block">
              {{ error }}
            </span>
          </div>
        </template>
        <template v-else="">
          {{ series.amount | money(2) }}
        </template>
      </td>
      <td class="number-cell">
        {{ series.unpaidPrincipalBalance | money(2) }}
      </td>
      <td class="number-cell">
        <template v-if="editMode">
          <div class="form-group currency" :class="{ 'has-error': recordErrors.ps_spread }">
            <div class="input-group">
              <input
                v-model="series.psSpread"
                v-mask:percentage=""
                class="form-control"
                type="text"
                :disabled="!isEditable"
              />
              <div class="input-group-append">
                <span class="input-group-text">%</span>
              </div>
            </div>
            <span v-for="(error, key) in recordErrors.ps_spread" :key="key" class="help-block">
              {{ error }}
            </span>
          </div>
        </template>
        <template v-else="">
          {{ series.psSpread | percent(3) }}
          (<strong>{{ series.investorRate | percent(3) }}</strong
          >)
        </template>
      </td>
      <td>
        <template v-if="editMode">
          <div class="form-group" :class="{ 'has-error': recordErrors.fundingEntityId }">
            <select v-model="series.fundingEntityId" class="form-control" disabled>
              <option
                v-for="option in fundingEntityOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </option>
            </select>
            <span
              v-for="(error, key) in recordErrors.fundingEntityId"
              :key="key"
              class="help-block"
            >
              {{ error }}
            </span>
          </div>
        </template>
        <template v-else="">
          {{ fundingEntityName }}
        </template>
      </td>
      <td>
        <template v-if="editMode">
          <div class="form-group" :class="{ 'has-error': recordErrors.tranche }">
            <select v-model="series.tranche" class="form-control" disabled>
              <option v-for="option in trancheOptions" :key="option.value" :value="option.value">
                {{ option.name }}
              </option>
            </select>
            <span v-for="(error, key) in recordErrors.tranche" :key="key" class="help-block">
              {{ error }}
            </span>
          </div>
        </template>
        <template v-else="">
          {{ tranche }}
        </template>
      </td>
      <td>
        <template v-if="editMode">
          <div class="form-group" :class="{ 'has-error': recordErrors.mdpn }">
            <select v-model="series.mdpn" class="form-control" disabled>
              <option v-for="option in mdpnOptions" :key="option.value" :value="option.value">
                {{ option.name }}
              </option>
            </select>
            <span v-for="(error, key) in recordErrors.mdpn" :key="key" class="help-block">
              {{ error }}
            </span>
          </div>
        </template>
        <template v-else="">
          {{ mdpn }}
        </template>
      </td>
      <td>
        <template v-if="editMode">
          <div class="form-group">
            <input
              v-model="series.startDate"
              v-datepicker=""
              v-mask:date=""
              class="date-picker form-control"
              disabled
            />
          </div>
        </template>
        <template v-else="">
          {{ series.startDate }}
        </template>
      </td>
      <td>
        <template v-if="editMode">
          <div class="form-group">
            <input
              v-model="series.endDate"
              v-datepicker=""
              v-mask:date=""
              class="date-picker form-control"
              :disabled="!endDateIsEditable"
            />
          </div>
        </template>
        <template v-else="">
          {{ series.endDate }}
        </template>
      </td>
      <td>
        {{ series.status }}
      </td>
      <td v-if="!disabled">
        <template v-if="!editMode">
          <a
            class="btn-action-cta"
            tabindex="0"
            @click="edit"
          >
            Edit
          </a>
          <a
            class="btn-cancel-cta"
            tabindex="0"
            :class="{ 'btn-disabled': !isDeletable }"
            @click="remove"
          >
            Delete
          </a>
          <note-icon :tooltip="series.note" v-if="!disabled" @click.native="toggleNote"></note-icon>
        </template>
        <template v-else="">
          <a class="btn-action-cta" tabindex="0" @click="update">
            Update
          </a>
          <a class="btn-cancel-cta" tabindex="0" @click="cancel">
            Cancel
          </a>
        </template>
      </td>
    </tr>
    <tr v-if="noteMode">
      <td colspan="9">
        <edit-note
          v-model="series.note"
          @cancel-note="toggleNote"
          @save-note="updateNote"
        ></edit-note>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default Vue.component("loan-series-item", {
  props: {
    loan: Object,
    series: Object,
    fundingEntityOptions: Array,
    trancheOptions: Array,
    mdpnOptions: Array,
    seriesTypeOptions: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      editMode: false,
      noteMode: false,
    };
  },
  watch: {
    recordErrors: function(value) {
      if (Object.keys(value).length === 0) {
        this.editMode = false;
      }
    },
  },
  computed: {
    recordErrors: function() {
      return this.series.errors;
    },
    fundingEntityName: function() {
      return this.optionName(this.series.fundingEntityId, this.fundingEntityOptions);
    },
    tranche: function() {
      return this.optionName(this.series.tranche, this.trancheOptions);
    },
    mdpn: function() {
      return this.optionName(this.series.mdpn, this.mdpnOptions);
    },
    seriesType: function() {
      return this.optionName(this.series.seriesType, this.seriesTypeOptions);
    },
    isDeletable: function() {
      return this.series.status != "Paid Off" && !this.series.startDate;
    },
    isEditable: function() {
      return !this.series.startDate && this.series.seriesType === "sale";
    },
    endDateIsEditable: function() {
      const editableTypes = ["sale", "draw", "purchase"];

      if (this.series.tranche === "a") {
        return !!this.series.startDate && editableTypes.includes(this.series.seriesType);
      } else {
        return !!this.series.startDate;
      }
    },
  },
  methods: {
    toggleNote: function() {
      this.noteMode = !this.noteMode;
    },
    updateNote: function() {
      this.$emit("update-series", this.series);
      this.noteMode = false;
    },
    edit: function() {
      this.editMode = true;
      this.noteMode = false;
    },
    update: function() {
      var confirmMessage = "Are you sure you want to update this series?";

      if (window.confirm(confirmMessage)) {
        this.$emit("update-series", this.series);
      } else {
        this.cancel();
      }
    },
    remove: function() {
      var confirmMessage = "Are you sure you want to remove this series?";

      if (window.confirm(confirmMessage)) {
        this.$emit("remove-series", this.series);
      }
    },
    cancel: function() {
      this.editMode = false;
      this.series.reset();
    },
    optionName: function(value, options) {
      let option =
        options.filter(function(option) {
          return option.value === value;
        })[0] || {};

      return option.name;
    },
  },
});
</script>
