<template>
  <action-button
    :actions="actions"
    :disabled="disabled"
    v-on="$listeners"
  />
</template>
<script>
import ActionButton from "./action_button";

export default {
  components: {
    ActionButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  computed: {
    actions() {
      return [
        {
          label: "Dismiss Task",
          enabled: this.isOpen,
          event: "dismiss",
        },
        {
          label: "Reopen Task",
          enabled: this.isReopenable,
          event: "reopen",
        },
        {
          label: "Duplicate Task",
          enabled: true,
          event: "duplicate",
        },
      ];
    },
    isOpen() {
      return this.model.status === "open";
    },
    isReopenable() {
      return !this.isOpen;
    },
  },
};
</script>
