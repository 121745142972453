<template>
  <a class="js-upload" href="#" @click.prevent="openFilePicker">
    <slot />
  </a>
</template>

<script>
import { FilePickerRequested } from "models/file/events/picker_events";
import { RequestFilePicker } from "models/file/commands/picker_commands";

let ruleBook;
let componentsCount = 0;

export default Vue.component("js-upload", {
  props: {
    initialOptions: Object,
    metadata: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      apiKey: PSData.global.filepickerApiKey,
      client: undefined,
      loan: PSData.loan,
      sources: [
        "local_file_system",
        "box",
        "dropbox",
        "googledrive",
        "onedrive",
        "onedriveforbusiness",
      ],
      provider: "Filestack::S3",
      filesUploaded: [],
      filesFailed: [],
      messageBus: PS.Services.MessageBusService.loanBus,
      options: this.initialOptions,
    };
  },
  created() {
    componentsCount++;

    if (ruleBook === undefined) {
      ruleBook = new PS.Models.File.PickerRule(this);
      this.messageBus.attachRulebook(ruleBook);
    }
  },
  beforeDestroy() {
    componentsCount--;

    if (componentsCount <= 0 && ruleBook !== undefined) {
      this.messageBus.detachRulebook(ruleBook);
      ruleBook = undefined;
    }
  },
  methods: {
    openFilePicker() {
      this.$emit("file-picker-opened");
      new RequestFilePicker(new FilePickerRequested({ metadata: this.metadata }), this).execute();
    },
  },
});
</script>
