import quarterize from "utils/quarterize";

export default class LoanSales {
  constructor(sales = [], goals = {}) {
    this.monthly = sales.slice(-6);
    this.monthlyGoal = parseFloat(goals.monthlyLoanAmountGoal) || 0;
    this.lastMonth = this.monthly[this.monthly.length - 1];

    this.quarterly = quarterize(sales);
    this.quarterlyGoal = parseFloat(goals.quarterlyLoanAmountGoal) || 0;
    this.lastQuarter = this.quarterly[this.quarterly.length - 1];
  }
}
