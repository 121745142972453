import VueRouter from "vue-router";
import SegmentService from "services/segment_service";
import Branding from "./views/branding.vue";
import Notifications from "./views/notifications.vue";
import Rates from "./views/rates.vue";

export const routes = [
  {
    path: "/",
    redirect: "/branding",
  },
  {
    name: "branding",
    path: "/branding",
    component: Branding,
    meta: {
      title: "Branding",
    },
  },
  {
    name: "rates",
    path: "/rates",
    component: Rates,
    meta: {
      title: "Rates",
    },
  },
  {
    name: "notifications",
    path: "/notifications",
    component: Notifications,
    meta: {
      title: "Notifications",
    },
  },
  {
    path: "/*",
    redirect: "/",
  },
];

function pageTitleFrom({ route }) {
  const routeTitle = route.meta.title;
  const pageTitle = typeof routeTitle === "function" ? routeTitle(route) : routeTitle;

  return `${pageTitle} | PeerStreet Lender Platform`;
}

export default function createRouter(base) {
  let router = new VueRouter({
    mode: "history",
    base,
    routes,
  });

  router.afterEach((to, from) => {
    const title = pageTitleFrom({ route: to });
    const pageDetails = { title };

    if (from.name) {
      pageDetails.referrer = window.location.origin + router.resolve(from).href;
    }

    window.document.title = title;
    SegmentService.page(to.name, pageDetails);
  });

  return router;
}
