<template>
  <ul v-if="!loadingBoxes" class="eligible-buyers-input">
    <eligible-buyers-option
      v-for="buyer in orderedBuyers"
      :key="buyer.id"
      :buyer="buyer"
      :included-buyers="amendedIncludedBuyers"
      :disabled-buyers="disabledBuyers"
      :edit-mode="editMode"
      @toggle-buyer="toggle"
    />
  </ul>
</template>
<script>
import AllocationService from "services/allocation_service";
import EligibleBuyersOption from "./eligible_buyers_option";
import without from "lodash/without";

export default {
  name: "eligible-buyers-input",
  components: {
    EligibleBuyersOption,
  },
  props: {
    loanId: {
      type: String,
      required: true,
    },
    includedBuyers: {
      type: Array,
      default: function() {
        return [];
      },
    },
    disabledBuyers: {
      type: Array,
      default: function() {
        return [];
      },
    },
    editMode: Boolean,
    showActiveButIneligible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      amendedIncludedBuyers: [...this.includedBuyers],
      boxes: [],
      loadingBoxes: true,
    };
  },
  computed: {
    orderedBuyers() {
      return [...this.boxes]
        .filter(box => this.showBuyer(box))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  watch: {
    editMode() {
      this.amendedIncludedBuyers = [...this.includedBuyers];
    },
    includedBuyers(val, oldVal) {
      let equal = false;
      if (val.length === oldVal.length) {
        equal = val.every((item, index) => item === oldVal[index]);
      }

      if (!equal) {
        this.amendedIncludedBuyers = [...val];
      }
    },
  },
  created() {
    if (this.loadingBoxes) {
      this.loadBoxes();
    }
  },
  methods: {
    toggle(buyer, checked) {
      if (!this.editMode) {
        return;
      }
      if (checked) {
        this.amendedIncludedBuyers.push(buyer.name);
      } else {
        this.amendedIncludedBuyers = without(this.amendedIncludedBuyers, buyer.name);
      }
      this.$emit("change", this.amendedIncludedBuyers);
    },
    loadBoxes() {
      AllocationService.evaluateLoan(this.loanId)
        .then(data => (this.boxes = data))
        .finally(() => (this.loadingBoxes = false));
    },
    buyerHasErrors(buyer) {
      return Array.isArray(buyer.errors) && buyer.errors.length > 0;
    },
    buyerHasWarnings(buyer) {
      return Array.isArray(buyer.warnings) && buyer.warnings.length > 0;
    },
    buyerHasIssues(buyer) {
      return this.buyerHasErrors(buyer) || this.buyerHasWarnings(buyer);
    },
    showBuyer(buyer) {
      if (buyer.active) {
        if (buyer.eligible) {
          return true;
        } else {
          return this.showActiveButIneligible && this.buyerHasIssues(buyer);
        }
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.eligible-buyers-input {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
</style>
