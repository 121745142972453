export default class LoanOffering {
  constructor(attributes = {}) {
    this.attributes = { ...attributes };
  }

  get id() {
    return this.attributes.id;
  }

  get loanPsId() {
    return this.attributes.loanPsId;
  }

  get loanPropertyTypeText() {
    return this.attributes.loanPropertyTypeText || "N/A";
  }

  get psAllocation() {
    return this.attributes.psAllocation;
  }

  get loanInvestorRate() {
    return this.attributes.loanInvestorRate;
  }

  get loanLtv() {
    return this.attributes.loanLtv;
  }

  get daysAllocated() {
    return this.attributes.daysAllocated || "N/A";
  }

  get review() {
    return this.attributes.review;
  }

  get state() {
    return this.attributes.state;
  }

  get notes() {
    return this.attributes.notes;
  }

  get nextActionText() {
    return this.attributes.nextActionText;
  }

  get nextAction() {
    return this.attributes.nextAction;
  }

  get primaryRejectionReason() {
    return this.attributes.primaryRejectionReason;
  }

  get secondaryRejectionReason() {
    return this.attributes.secondaryRejectionReason;
  }

  get routes() {
    return this.attributes.routes;
  }

  set notes(val) {
    this.attributes.notes = val;
  }

  get reviewText() {
    return this.attributes.review.map(item => item.message).join(", ");
  }

  update(attributes) {
    this.attributes = {
      ...this.attributes,
      ...attributes,
    };
  }

  toHash() {
    return {
      notes: this.notes,
      state: this.state,
      next_action: this.nextAction,
      reject_category_1: this.primaryRejectionReason,
      reject_category_2: this.secondaryRejectionReason,
      loan_hold_reason: this.attributes.loanHoldReason,
    };
  }
}
