<template>
  <span v-if="attributeExists"><slot></slot></span>
  <span v-else class="missing">Missing</span>
</template>

<script>
export default {
  name: "manage-servicer-loan-boarding-missing-attribute",
  props: {
    value: {
      type: String,
      required: true,
    },
    missingData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    attributeExists() {
      return !this.missingData.includes(this.value);
    },
  },
};
</script>
