class Fieldset extends PS.Models.Field {
  constructor(element) {
    super(element);

    this.addButtonLabel = element.addButtonLabel;
    this.fields = [];
    this.canBeRemoved = false;
    this.canBeCopied = false;
    this.collapsible = element.collapsible;
    this.clearable = element.clearable;
    this.validatable = element.validatable;
    this.uploadedAt = element.uploadedAt;
  }

  disable() {
    this.disabled = true;
    this.fields.forEach(field => {
      field.disabled = true;
    });
  }

  enable() {
    this.disabled = false;
    this.fields.forEach(field => {
      field.disabled = false;
    });
  }

  fieldsCount() {
    return this.fields.length;
  }

  validatedCount() {
    return this.fields
      .map(field => field.validatedCount())
      .reduce((total, current) => total + current, 0);
  }

  notedCount() {
    return this.fields
      .map(field => field.notedCount())
      .reduce((total, current) => total + current, 0);
  }
}

class EditableFieldset extends Fieldset {
  constructor(element) {
    super(element);

    this.canBeRemoved = false;
    this.canBeCopied = true;
  }
}

PS.Models.Fields.Fieldset = Fieldset;
PS.Models.Fields.EditableFieldset = EditableFieldset;
