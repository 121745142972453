<template>
  <div class="settings-item checkbox-control">
    <input :id="subscription.id" v-model="subscription.enabled" type="checkbox" @change="update()" />
    <label :for="subscription.id" :class="{ changed: subscription.isChanged }">
      <span class="control-toggle"></span>
    </label>
  </div>
</template>

<script>
const Commands = PS.Models.EmailSubscription.Commands;
const Event = PS.Models.Event;

export default Vue.component("email-subscriptions-item", {
  props: {
    subscription: Object,
  },
  data: function() {
    return {
      rulebook: undefined,
      messageBus: PS.Services.MessageBusService.settingsBus,
    };
  },
  created: function() {
    this.rulebook = new PS.Models.EmailSubscription.ItemRule(this);
    this.messageBus.attachRulebook(this.rulebook);
  },
  beforeDestroy: function() {
    this.messageBus.detachRulebook(this.rulebook);
  },
  methods: {
    update: function() {
      new Commands.Item.SendSubscriptionUpdate(new Event(), {
        subscription: this.subscription,
      }).execute();
    },
  },
});
</script>
