class SelectField extends PS.Models.Field {
  constructor(element) {
    super(element);

    this.collection = PS.Models.Field.expandCollection(element.collection || []);
  }
}

class USStatesSelectField extends SelectField {
  constructor(element) {
    super(element);

    this.collection = PS.Const.USStates;
  }
}

PS.Models.Fields.SelectField = SelectField;
PS.Models.Fields.USStatesSelectField = USStatesSelectField;
