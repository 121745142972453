<template>
  <div v-cloak>
    <label class="checkbox-toggle">
      Action on Analyst
      <input v-model="attention" type="checkbox" />
      <div class="toggle-control"></div>
    </label>
  </div>
</template>

<script>
export default Vue.component("loan-attention-toggle", {
  data: function() {
    return {
      disableInput: false,
      loan: PSData.loan,
      attention: PSData.loan.attentionRequired === true,
    };
  },
  watch: {
    attention: "toggleAttention",
  },
  methods: {
    toggleAttention: function() {
      if (this.disableInput) {
        return;
      }

      this.disableInput = true;

      PS.Services.AjaxService.withNotifications()
        .patch(this.loanPath(), {
          format: "json",
          resource: { needs_action: this.attention },
        })
        .catch(() => (this.attention = !this.attention))
        .finally(() => (this.disableInput = false));
    },
    loanPath: function() {
      return `/manage/loans/${this.loan.toParam}/notifications`;
    },
  },
});
</script>
