const Events = PS.Models.MSA.Events;
const Commands = PS.Models.MSA.Commands;
const Rule = PS.Models.Rule;

class PopulationRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.Population.DataRequested:
        return this.whenDataRequested(event);
    }

    return super.check(event);
  }

  whenDataRequested(event) {
    return [new Commands.Population.LoadData(event, this.state)];
  }
}

PS.Models.MSA.PopulationRule = PopulationRule;
