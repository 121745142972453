import get from "lodash/get";
import last from "lodash/last";
import isEmpty from "lodash/isEmpty";
import Location from "services/location_service";
import Window from "services/window_service";

export default {
  computed: {
    lastStatement() {
      return last(this.statements || []);
    },
    canGenerate() {
      return get(this.statementPolicy, "create.allowed");
    },
    hasStatements() {
      return !isEmpty(this.statements);
    },
    hasCompletedStatement() {
      return this.completedStatement !== undefined;
    },
  },
  methods: {
    statusClass(item) {
      return [`finops-status`, `finops-status-${item.status}`];
    },
    show(item) {
      Location.assign(item.routes.show);
    },
    openPdfDocument(item) {
      Window.open(item.routes.downloadUrl, "blank");
    },
  },
};
