<template>
  <div class="columns">
    <div class="column">
      <ul>
        <li class="line-item">
          <div>Unpaid Principal Balance</div>
          <div>{{ unpaidPrincipalBalance | accounting }}</div>
        </li>
        <li class="line-item">
          <div>Charge-Off Amount</div>
          <div>{{ chargeOffStatement.amount | accounting }}</div>
        </li>
        <li class="line-item">
          <div>Reversal Amount</div>
          <div>{{ reversalAmount | accounting(2, true) | missingValue }}</div>
        </li>
        <li class="line-item last-item">
          <div>Remaining Unpaid Principal Balance</div>
          <div>{{ remainingUnpaidPrincipalBalance | accounting }}</div>
        </li>
      </ul>
    </div>
    <div class="column">
      <ul>
        <li class="line-item">
          <div>Effective Date</div>
          <div>{{ chargeOffStatement.effectiveDate | formatDate }}</div>
        </li>
        <li class="line-item">
          <div>Reversal Date</div>
          <div>{{ chargeOffStatement.reversedAt | formatDate | missingValue }}</div>
        </li>
        <li class="line-item">
          <div class="column">Notes</div>
          <div class="column clearfix">
            <div class="is-pulled-right notes">
              {{ chargeOffStatement.comment }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    chargeOffStatement: {
      type: Object,
    },
  },
  computed: {
    loanDetails() {
      return PSData.loanDetails;
    },
    reversalAmount() {
      const value = Number.parseFloat(this.chargeOffStatement.reversalAmount);
      if (isNaN(value)) {
        return;
      }
      return value;
    },
    unpaidPrincipalBalance() {
      if (this.chargeOffStatement.status === "draft") {
        return this.loanDetails.unpaidPrincipalBalance;
      } else {
        return this.chargeOffStatement.unpaidPrincipalBalance;
      }
    },
    remainingUnpaidPrincipalBalance() {
      return (
        Number.parseFloat(this.unpaidPrincipalBalance) +
        Number.parseFloat(this.chargeOffStatement.amount || 0) +
        Number.parseFloat(this.chargeOffStatement.reversalAmount || 0)
      );
    },
  },
};
</script>
<style scoped>
.line-item {
  display: flex;
  justify-content: space-between;
  padding: var(--space-unit) 0px var(--space-unit) var(--space-unit-md);
  border-top: 1px solid lightgray;
}

.line-item:first-child {
  border: none;
}

.line-item > .column {
  padding: 0;
}

.last-item {
  border-color: black;
  font-weight: 600;
}

.notes {
  overflow-wrap: break-word;
  word-break: break-word;
}
</style>
