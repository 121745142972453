import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";

export default class CreditReportScore {
  static deserialize({ id = null, vendor = null, vendorLabel = null, score = null }) {
    return new CreditReportScore(id, vendor, vendorLabel, score);
  }

  constructor(id = null, vendor = null, vendorLabel = null, score = null) {
    this.id = id;
    this.vendor = vendor;
    this.vendorLabel = vendorLabel;
    this.score = score;
  }

  toPlainObject({ toSnakeCase } = { toSnakeCase: false }) {
    const score = {
      id: this.id,
      vendor: this.vendor,
      score: this.score,
    };

    if (toSnakeCase) return mapKeys(score, (_, key) => snakeCase(key));

    return score;
  }
}
