<template>
  <base-modal-form
    :save-disabled="isSaving"
    :show="show"
    title="New Workflow"
    @save="onSave"
    @close="onCancel"
  >
    <base-text-field v-model="name" label="name" :errors="errors.name" />
    <base-text-field v-model="label" label="label" :errors="errors.label" />
  </base-modal-form>
</template>

<script>
import Ajax from "services/ajax_service";
import objectToSnakeCase from "utils/object_to_snake_case";

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return { isSaving: false, name: "", label: "", errors: {} };
  },
  methods: {
    onSave() {
      this.isSaving = true;
      const messages = {
        onError: ({ response }) => {
          if (response.status === 403) {
            return { message: "Unauthorized", status: "error" };
          } else {
            return { message: "Invalid Parameters", status: "error" };
          }
        },
      };
      Ajax.withNotifications(messages)
        .postJSON("/manage/asset_management_workflow_templates", this.newWorkflowParams())
        .then(data => {
          this.$emit("new-workflow-template-saved", data);
          this.$emit("close");
          this.errors = {};
        })
        .catch(({ data }) => {
          this.errors = data.errors;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    newWorkflowParams() {
      return objectToSnakeCase({
        name: this.name,
        label: this.label,
      });
    },
    onCancel() {
      this.name = "";
      this.label = "";
      this.errors = {};
      this.$emit("close");
    },
  },
};
</script>
