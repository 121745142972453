const Command = PS.Models.Command;
const Events = PS.Models.Loan.Events;

class BroadcastListUpdate extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Series.ListUpdated,
      PS.Services.MessageBusService.loanBus,
      {}
    );
  }
}

PS.Models.Loan.Commands.Series = {
  BroadcastListUpdate: BroadcastListUpdate,
};
