<template>
  <div>
    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="entry_date">
        <label>Date</label>
        <input
          name="date"
          type="text"
          class="date-picker form-control"
          v-model="transaction.entry_date"
          v-datepicker
          v-mask:date />
      </field-with-error>
    </div>
    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="amount">
        <label>Amount</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input
            name="amount"
            type="currency"
            class="form-control"
            v-model="transaction.amount"
            v-mask:currency />
        </div>
      </field-with-error>
    </div>

    <div class="form-group control-wrapper">
      <field-with-error :errors="errors" field="note">
        <label>Notes</label>
        <textarea
          name="note"
          class="form-control"
          placeholder="Optional"
          v-model="transaction.note">
        </textarea>
      </field-with-error>
    </div>

    <div class="modal-actions">
      <a class="btn btn-cancel-cta" @click="close">Cancel</a>
      <a class="btn btn-action-cta"
        @click="submit"
        :disabled="!canSend">
        Save
      </a>
    </div>
  </div>
</template>

<script>
import NumberFormatter from "models/formatters/number_formatter";
import FormMixin from "components/transaction/form_mixin";

export default {
  name: "transaction-b-piece-payoff-form",
  mixins: [FormMixin],
  data() {
    const loan = PSData.loan || {};

    return {
      url: `/manage/loans/${loan.toParam}/transactions/b_piece_payoff`,
      transaction: {
        amount: undefined,
        entry_date: undefined,
        note: undefined,
      },
    };
  },
  computed: {
    canSend() {
      const { entry_date, amount, note } = this.transaction;

      return !this.inFlight && this.isNotEmptyString(entry_date) && this.isNotEmptyString(amount);
    },
  },
  methods: {
    clear() {
      this.transaction.entry_date = undefined;
      this.transaction.amount = undefined;
      this.transaction.note = undefined;
    },
  },
};
</script>
