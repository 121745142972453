<template>
  <div class="pricing-grids-grid">
    <div class="grid-table-row grid-table-header">
      <div class="grid-table-cell" />
      <div v-for="fico in grid.ficos" :key="fico.id" class="grid-table-cell fico-range">
        <i v-if="grid.canDeleteCol" class="fas fa-minus-circle remove-col" @click="removeColumn(fico)" />
        <input v-model="fico.from" v-mask:fico="" placeholder="from">
        <span>—</span>
        <input v-model="fico.to" v-mask:fico="" placeholder="to">
      </div>
      <div class="grid-table-cell action">
        <i class="fas fa-plus-circle" @click="addColumn" />
      </div>
    </div>
    <div v-for="ltv in grid.ltvs" :key="ltv.id" class="grid-table-row">
      <div class="grid-table-cell ltv-range">
        <i v-if="grid.canDeleteRow" class="fas fa-minus-circle remove-row" @click="removeRow(ltv)" />
        <input
          v-model="ltv.from"
          v-mask:percentage="ltvInputOptions"
          placeholder="from"
        >
        <span>—</span>
        <input
          v-model="ltv.to"
          v-mask:percentage="ltvInputOptions"
          placeholder="to"
        >
        <span>%</span>
      </div>
      <div v-for="fico in grid.ficos" :key="`${ltv.id}-${fico.id}`" class="grid-table-cell purchase-rate">
        <input
          v-model="grid.getCell(ltv.id, fico.id).value"
          v-mask:percentage="rateInputOptions"
          placeholder="rate"
        >
      </div>
    </div>
    <div class="grid-table-row">
      <div class="grid-table-cell action">
        <i class="fas fa-plus-circle" @click="addRow" />
      </div>
    </div>
  </div>
</template>

<script>
import Grid from "models/pricing_grids/grid";

export default {
  name: "pricing-grids-grid",
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: Grid,
      required: true,
    },
  },
  data() {
    const ltvInputOptions = { minus: false, round: 2 };
    const rateInputOptions = { minus: false };

    return {
      ltvInputOptions,
      rateInputOptions,
    };
  },
  methods: {
    addColumn() {
      this.grid.addCol();
    },
    removeColumn(column) {
      this.grid.removeCol(column);
    },
    addRow() {
      this.grid.addRow();
    },
    removeRow(row) {
      this.grid.removeRow(row);
    },
  },
};
</script>
