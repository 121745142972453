const AjaxService = PS.Services.AjaxService;
const PusherService = PS.Services.PusherService;

const STATUS_COMPLETED = "COMPLETED";
const STATUS_PENDING = "PENDING";

class DelayedAjaxService {
  static factory(options) {
    return new DelayedAjaxService(options);
  }

  constructor(options) {
    this.options = options || {};
  }

  ajax(url, data) {
    return this.saveAndExecute("ajax", url, data);
  }

  get(url, data) {
    return this.saveAndExecute("get", url, data);
  }

  post(url, data) {
    return this.saveAndExecute("post", url, data);
  }

  saveAndExecute(method = "get", url, data) {
    if (this.options.request) {
      throw Error("Request already in flight");
    }

    this.options.request = {
      method: method,
      url: url,
      data: data,
    };

    return new Promise((resolve, reject) => {
      this.makeRequest()
        .then(response => this.handle(response, resolve, reject))
        .catch(reject);
    });
  }

  makeRequest() {
    const { url, method = "get", data } = this.options.request;
    return AjaxService[method](url, data);
  }

  handle(response, resolve, reject) {
    if (!this.isValidDelayedResponse(response)) {
      return resolve(response);
    }

    switch (response.data.status) {
      case STATUS_COMPLETED: {
        resolve(response);
        break;
      }
      case STATUS_PENDING: {
        this.trigger("onDelay", response);
        this.subscribe(response.data.id, resolve, reject);
        break;
      }
      default: {
        reject(new Error(`Invalid status received: ${response.data.status}`));
      }
    }
  }

  subscribe(id, resolve, reject) {
    const onData = data => {
      const isValid = typeof data === "object";

      if (isValid && data.status === "completed") {
        this.makeRequest()
          .then(resolve)
          .catch(reject)
          .then(() => delete this.options.request);
      } else {
        delete this.options.request;
        reject(data);
      }
    };

    PS.Services.PusherService.listenOnce(id, this.options.event)
      .then(onData)
      .catch(reject);
  }

  trigger(event, data) {
    if (this.options.listeners && event in this.options.listeners) {
      this.options.listeners[event](data);
    }
  }

  isValidDelayedResponse(response) {
    return (
      typeof response === "object" &&
      "data" in response &&
      "id" in response.data &&
      "status" in response.data
    );
  }
}

PS.Services.DelayedAjaxService = DelayedAjaxService;
