window.PS = window.PS || {};
window.PS.Utils = {};
window.PS.Models = {};
window.PS.Mixins = {};
window.PS.Collections = {};
window.PS.Services = {};
window.PS.Policies = {};
window.PS.Const = {};

module.exports = window.PS;
