<template>
  <div
    @dragenter.prevent="onDragEnter"
    @dragleave.prevent="onDragLeave"
    @drop.prevent="onDrop"
    :class="dropZoneClass"
    class="drop-file-to-upload"
  >
    <div ref="dropContainer" class="file-upload-drop-container">
      <div class="drop-file-to-upload-placeholder">
        <slot name="dropzone" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import {
  SOURCES,
  PROVIDERS,
  getFilestackClient,
  generateFilePickerOptions,
} from "services/filestack_service";
import { generateUploadedFilesPayload } from "services/file_upload_service";

export default {
  name: "drop-file-upload",
  props: {
    initialOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      client: undefined,
      apiKey: PSData.global.filepickerApiKey,
      options: this.initialOptions,
      sources: SOURCES,
      provider: PROVIDERS.filestack,
      filesUploaded: [],
      filesFailed: [],
      isPickerOpened: false,
      isDragInProgress: false,
      isUploadInProgress: false,
      dragEnterCounter: 0, // for onDragLeave detection workaround in Safari
    };
  },
  created() {
    // initialize client early for faster picker initialization in onDragEnter
    this.client = getFilestackClient(this.apiKey, this.options);
  },
  computed: {
    dropZoneClass() {
      return {
        "files-drag-in-progress": this.isDragInProgress && this.isPickerOpened,
        "files-upload-in-progress": this.isUploadInProgress,
      };
    },
    picker() {
      return this.client.picker(this.pickerOptions);
    },
    pickerOptions() {
      let options = generateFilePickerOptions(this.options, this.sources);

      return {
        ...options,
        displayMode: "dropPane",
        container: this.$refs.dropContainer,
        onUploadStarted: this.onUploadStarted,
        onUploadDone: this.uploadFiles,
        dropPane: {
          overlay: false,
          showIcon: false,
          disableClick: true,
          customText: "Drop to Upload",
          onProgress: this.onProgress,
        },
      };
    },
  },
  methods: {
    onDrop() {
      this.dragEnterCounter = 0;
    },
    onDragEnter(event) {
      const isDraggingFiles = Array.from(event.dataTransfer.types).includes("Files");

      if (isDraggingFiles) {
        this.openPicker();
        this.isDragInProgress = true;
        this.dragEnterCounter++;
      }
    },
    onDragLeave() {
      this.dragEnterCounter--;

      // Workaround for Safari not setting event.relatedTarget
      // See https://bugs.webkit.org/show_bug.cgi?id=66547
      //
      // This prevents us from detecting if cursor is still inside component
      // const isInsideComponent = event.currentTarget.contains(event.relatedTarget);
      const isInsideComponent = this.dragEnterCounter !== 0;

      if (isInsideComponent) return;

      this.isDragInProgress = false;
    },
    onProgress() {
      // Having this flag set in onDrop results in dropzone flickering.
      this.isDragInProgress = false;
    },
    onUploadStarted(files) {
      this.$emit("on-upload-started", { files });
      this.isUploadInProgress = true;
    },
    openPicker() {
      if (this.isPickerOpened) return;

      this.picker.open();
      this.isPickerOpened = true;
    },
    uploadFiles(blobs) {
      this.isUploadInProgress = false;

      const payload = generateUploadedFilesPayload(this.provider, blobs);

      if (payload) {
        this.$emit("on-upload-success", payload.filesUploaded, payload.filesFailed);
      } else {
        this.$emit("on-upload-error", "Upload failed. Please try again.");
      }
    },
  },
};
</script>
