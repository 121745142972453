class FormulaHelper {
  static joinJuniorLienHolders(values) {
    const uniqValues = [...new Set(values)];

    switch (uniqValues.length) {
      case 0:
        return undefined;
      case 1:
        return uniqValues[0];
      default:
        return "multiple";
    }
  }

  static sumPartialValues(values) {
    return values.reduce((acc, value) => {
      if (value || value === 0) {
        return (acc = acc ? acc + value : value);
      }

      return acc;
    });
  }
}

PS.Models.Diligence.FormulaHelper = FormulaHelper;
