export default {
  explainEvaluationResults(evaluationId, context) {
    if (evaluationId === "off") {
      console.info("LPS is not configured to show details.");
      return;
    }

    if (!evaluationId) {
      console.error("Evaluation id is missing.");
      return;
    }

    const pointAt = new URLSearchParams(context);
    const lpsPageUrl = `${PSData.routes.lpsUrl}/#/evaluations/${evaluationId}?${pointAt}`;
    window.open(lpsPageUrl, "_blank");
  },
};
