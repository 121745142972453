<template>
  <base-tag-field
    v-model="model"
    :data="filteredOptions"
    :open-on-focus="openOnFocus"
    field="title"
    :label="label"
    autocomplete
    @typing="filterOptions"
    @add="addTag"
    @remove="removeTag"
    icon-pack="fas"
    icon="tag"
    class="criteria-tags"
    :class="classNames"
    :rules="validationRule"
  >
    <template v-slot="{ props }">
      {{ props.option.title }}
    </template>
  </base-tag-field>
</template>

<script>
export default {
  name: "criteria-selector",
  props: {
    label: String,
    classNames: String,
    options: {
      type: Array,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
    validationRule: String,
  },
  data() {
    return {
      filteredOptions: this.options,
      model: this.tags,
      openOnFocus: true,
    };
  },
  watch: {
    tags: {
      immediate: true,
      deep: true,
      handler() {
        this.model = this.tags;
      },
    },
  },
  methods: {
    addTag(tag) {
      this.$emit("tag-added", tag.value);
    },
    removeTag(tag) {
      this.$emit("tag-removed", tag.value);
    },
    filterOptions(text) {
      this.filteredOptions = this.options.filter(option => {
        return (
          option.title
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        );
      });
    },
  },
};
</script>
<style>
.criteria-tags .taginput .taginput-container .tag,
.criteria-tags .taginput .taginput-container .tags {
  margin: 4px;
  padding: 0 10px 0 8px !important;
}
.criteria-tags .tag:not(body) {
  background: var(--color-grey-16);
  color: var(--color-grey-60);
}
.criteria-tags .tag:not(body) a.delete {
  background: var(--color-grey-60);
  margin-left: 8px;
  max-height: 14px;
  max-width: 14px;
  min-height: 14px;
  min-width: 14px;
}
.criteria-tags .tag:not(body) a.delete::after,
.criteria-tags .tag:not(body) a.delete::before {
  background: var(--color-grey-16);
}
</style>
