<template>
  <div class="asset-management-table">
    <table class="table">
      <thead>
        <tr>
          <th>Modification</th>
          <th>Old</th>
          <th>New</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="item.newMaturityDate || item.oldMaturityDate">
          <td>Maturity Date (Extension)</td>
          <td>{{ item.oldMaturityDate }}</td>
          <td>{{ item.newMaturityDate }}</td>
        </tr>
        <tr v-if="item.newPaidToDate || item.oldPaidToDate">
          <td>Paid To Date (Deferral)</td>
          <td>{{ item.oldPaidToDate }}</td>
          <td>{{ item.newPaidToDate }}</td>
        </tr>
        <tr v-if="item.newPaymentAmount || item.oldPaymentAmount">
          <td>Payment Amount (Modification)</td>
          <td>{{ item.oldPaymentAmount | money }}</td>
          <td>{{ item.newPaymentAmount | money }}</td>
        </tr>
        <tr v-if="!hasModifications">
          <td colspan="3">No applied modifications</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    hasModifications() {
      const values = [
        this.item.newMaturityDate,
        this.item.newPaidToDate,
        this.item.newPaymentAmount,
      ];

      return values.filter(value => !!value).length > 0;
    },
  },
};
</script>
