<template>
  <component
    :is="previewComponentName"
    :preview="preview"
    :meta="meta"
  />
</template>

<script>
import Preview from "models/workflow_engine/preview";
import StringPreview from "./previews/string";
import SelectPreview from "./previews/select";
import CheckboxPreview from "./previews/checkbox";
import FilePreview from "./previews/file";
import EntityPreview from "./previews/entity";
import DatePreview from "./previews/date";

export default Vue.extend({
  name: "preview-component",
  components: {
    StringPreview,
    SelectPreview,
    CheckboxPreview,
    FilePreview,
    EntityPreview,
    DatePreview,
  },
  props: {
    preview: {
      type: Preview,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    previewComponentName() {
      switch (this.preview.variableType) {
        case "string":
          return "StringPreview";
        case "select":
          return "SelectPreview";
        case "boolean":
          return "CheckboxPreview";
        case "file":
          return "FilePreview";
        case "entity":
          return "EntityPreview";
        case "date":
          return "DatePreview";
        default:
          return "StringPreview";
      }
    },
  },
});
</script>
