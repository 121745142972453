<template>
  <div>
    <div class="allocation-tab-header float-right">
      <div v-if="!editMode">
        <button type="button" class="btn btn-secondary-action-cta" @click="editTable">
          Edit
        </button>
      </div>
      <div v-else>
        <button type="button" class="btn btn-cancel-cta" @click="cancelEdit">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-action-cta"
          :disabled="anyBuyersInvalid"
          @click="save"
        >
          Save
        </button>
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th class="col-3">
            Buyer
          </th>
          <th class="col-2">
            Monthly Volume
          </th>
          <th class="col-2">
            Monthly Target
          </th>
          <th class="col-4">
            Loan Program
            <i
              v-tooltip
              title="Link to Loan Programs Service (LPS) Buyer Allocation definition."
              class="fa fa-question-circle blue"
            />
          </th>
          <th v-if="!editMode" class="col-1">
            Weight
          </th>
          <th v-if="editMode" class="col-1">
            On/Off
          </th>
        </tr>
      </thead>
      <tbody>
        <buyers-row
          v-for="buyer in orderedBuyers"
          :key="buyer.id"
          :buyer="buyer"
          :edit-mode="editMode"
          @change-external-id="changeExternalId"
          @change-lps-url="changeLpsUrl"
          @change-active-buyer="changeActiveBuyer"
          @change-monthly-target="changeMonthlyTarget"
        />
      </tbody>
    </table>
  </div>
</template>
<script>
import Buyer from "models/allocation/buyer";
import BuyersRow from "./buyers_row";

export default {
  name: "BuyersTab",
  components: {
    BuyersRow,
  },
  props: {
    buyers: Array,
  },
  data() {
    return {
      editMode: false,
      amendedBuyers: this.buyers.map(buyer => new Buyer(buyer)),
    };
  },
  computed: {
    orderedBuyers() {
      return this.amendedBuyers.sort((a, b) => a.label.localeCompare(b.label));
    },
    anyBuyersInvalid() {
      return !this.amendedBuyers.every(b => b.isValidLpsUrl);
    },
  },
  watch: {
    buyers: function(newVal) {
      this.amendedBuyers = newVal.map(buyer => new Buyer(buyer));
    },
  },
  methods: {
    changeActiveBuyer(buyer, value) {
      buyer.setActive(value);
    },
    changeExternalId(buyer, value) {
      buyer.setExternalId(value);
    },
    changeLpsUrl(buyer, value) {
      buyer.setLpsUrl(value);
    },
    changeMonthlyTarget(buyer, value) {
      buyer.setMonthlyTarget(value);
    },
    editTable() {
      this.amendedBuyers = this.buyers.map(buyer => new Buyer(buyer));
      this.editMode = true;
    },
    cancelEdit() {
      this.amendedBuyers = this.buyers.map(buyer => new Buyer(buyer));
      this.editMode = false;
    },
    save() {
      this.$emit("save", this.amendedBuyers, () => (this.editMode = false));
    },
  },
};
</script>
