import VueRouter from "vue-router";
import RunBatch from "./run_batch";
import History from "./history";

const routes = [
  {
    name: "root",
    path: "/",
    redirect: "/run_batch/new",
  },
  {
    name: "run_batch",
    path: "/run_batch/:id",
    component: RunBatch,
    props: {},
    meta: {
      title: "Run Batch",
    },
  },
  {
    name: "history",
    path: "/history",
    component: History,
    props: {},
    meta: {
      title: "History",
    },
  },
];

export default function createRouter() {
  const router = new VueRouter({ routes });

  return router;
}
