import toPlainObject from "utils/to_plain_object";

const Command = PS.Models.Command;
const Events = PS.Models.MSA.Events;

class LoadData extends Command {
  execute() {
    return PS.Services.MSAService.find(this.state.loan.zip)
      .then(result => {
        this.state.msaData = result.data;
      })
      .catch(result => {
        const response = toPlainObject(result);
        const payload = { zip: this.state.loan.zip };

        Bugsnag.notify(
          { name: "Request Error", message: "Unable to get MSA population data" },
          { metaData: { custom: { response, payload } } }
        );
      })
      .finally(() => (this.state.msaDataLoaded = true));
  }
}

class RequestData extends Command {
  execute() {
    this.state.msaDataLoaded = false;
    return Command.broadcastEvent(
      Events.Population.DataRequested,
      PS.Services.MessageBusService.loanBus,
      {}
    );
  }
}

PS.Models.MSA.Commands.Population = {
  RequestData: RequestData,
  LoadData: LoadData,
};
