<template>
  <div class="payoff-statement-details payoff-statement-show-details">
    <payoff-statement-show-overview :payoff-statement="statement" />
    <payoff-statement-show-header :payoff-statement="statement" />
    <payoff-statement-show-dates :payoff-statement="statement" />
    <div class="details-row row non-total-row">
      <div class="col-12">
        <h4>Principal</h4>
      </div>
      <div class="col-6 border-row">
        <editable-field
          label="Investor Loan Amount"
          :value="statement.investorLoanAmount"
          :editing="false"
          currency
        />
      </div>
      <div class="col-6 border-row tab">
        <editable-field
          label="Current Investor Principal Balance"
          :value="statement.investorUnpaidPrincipalAmount"
          :editing="false"
          currency
        />
      </div>
      <div class="col-6 border-row">
        <editable-field
          label="Current Principal Balance"
          :value="statement.unpaidPrincipalAmount"
          :editing="false"
          currency
        />
      </div>
      <div class="col-6 border-row tab">
        <editable-field v-if="statement.priceDiscountPremiumAmount != 0.0"
          label="Price Discount/Premium"
          :value="statement.discountPremiumValue"
          :editing="false"
        />
      </div>
      <div class="col-6 border-row">
        <editable-field
          label="Corrected Principal Balance"
          :value="statement.correctedUnpaidPrincipalAmount"
          :editing="false"
          currency
        />
      </div>
      <div class="col-6 dark-border-row total">
        <editable-field
          label="Waived Principal"
          :value="statement.waivedPrincipalValue"
          :editing="false"
        />
      </div>
      <div class="col-6 border-row">
        <editable-field
          label="Reason for Correction"
          :value="statement.correctedPrincipalReason"
          :editing="false"
        />
      </div>
      <div class="col-6 total">
        <editable-field
          label="Net Principal Due"
          :value="statement.netPrincipalValue"
          :editing="false"
          currency
        />
      </div>
      <div class="col-6 border-row">
        <editable-field v-if="!statement.servicingReleased"
          label="B-Piece Amount"
          :value="statement.bPieceAmount"
          :editing="false"
          currency
        />
      </div>
      <div class="col-6" />
      <div class="col-6">
        <editable-field
          label="Unfunded Commitment"
          :value="statement.unfundedCommitmentAmount"
          :editing="false"
          currency
        />
      </div>
    </div>
    <div class="details-row row">
      <div class="col-12"><h4>Interest</h4></div>
      <div class="col-12"><h5>Rate Details</h5></div>
      <div class="col-6 border-row">
        <editable-field
          label="Interest Rate"
          :value="statement.interestRate"
          :editing="false"
          percentage
        />
      </div>
      <div class="col-6 border-row">
        <editable-field
          label="Originator Servicing Spread"
          :value="statement.lenderRate"
          :editing="false"
          percentage
        />
      </div>
      <div class="col-6 border-row">
        <editable-field
          label="Investor Rate"
          :value="statement.investorRate"
          :editing="false"
          percentage
        />
      </div>
      <div class="col-6">
        <editable-field
          label="PeerStreet Servicing Spread"
          :value="statement.psServicingSpread"
          :editing="false"
          percentage
        />
      </div>
      <div class="col-6">
        <editable-field
          label="Default Rate"
          :value="statement.defaultRate"
          :editing="false"
          percentage
        />
      </div>
      <div class="col-12"><h5>Note Interest Calculation</h5></div>
      <div class="col-6">
        <editable-field
          label="Note Interest Accrual"
          :value="statement.interestAccrualMethodText"
          :editing="false"
        />
      </div>
      <div class="col-6">
        <editable-field
          label="Note Interest Calculation"
          :value="statement.interestCalculationMethodText"
          :editing="false"
        />
      </div>
    </div>
    <!-- INTEREST COLUMNS -->
    <div class="columns">
      <!--                        -->
      <!-- "INTEREST CALCULATION" -->
      <!--                        -->
      <div class="column">
        <h5>Interest Calculation</h5>
        <div class="border-row tab">
          <editable-field
            label="Interest Due"
            :value="statement.interestDueFromBorrowerAmount"
            :editing="false"
            currency
          />
        </div>
        <div class="border-row tab">
          <editable-field
            label="Deferred Interest Due"
            :value="statement.deferredInterestAmount"
            :editing="false"
            currency
          />
        </div>
        <div class="border-row tab">
          <editable-field
            label="Unpaid Interest Due"
            :value="statement.unpaidInterestDueFromBorrowerAmount"
            :editing="false"
            currency
          />
        </div>
        <div class="dark-border-row total">
          <editable-field
            label="Total Interest Due"
            :value="statement.totalInterest"
            :editing="false"
            currency
          />
        </div>
        <div class="dark-border-row total">
          <editable-field
            label="Waived Interest"
            :value="statement.waivedInterestValue"
            :editing="false"
          />
        </div>
        <div class="dark-border-row total">
          <editable-field
            label="Waived Deferred Interest"
            :value="statement.waivedDeferredInterestValue"
            :editing="false"
          />
        </div>
        <div class="dark-border-row total">
          <editable-field
            label="Waived Unpaid Interest"
            :value="statement.waivedUnpaidInterestValue"
            :editing="false"
          />
        </div>
        <div class="total">
          <editable-field
            label="Net Interest Due"
            :value="statement.netInterest"
            :editing="false"
            currency
          />
        </div>
      </div>
      <!--                                -->
      <!-- "DEFAULT INTEREST CALCULATION" -->
      <!--                                -->
      <div class="column">
        <h5>Default Interest Calculation</h5>
        <div class="border-row tab">
          <editable-field
            label="Default Interest Due"
            :value="statement.defaultInterestDueFromBorrowerAmount"
            :editing="false"
            currency
          />
        </div>
        <div class="dark-border-row total">
          <editable-field
            label="Total Default Interest Due"
            :value="statement.totalDefaultInterest"
            :editing="false"
            currency
          />
        </div>
        <div class="dark-border-row total">
          <editable-field
            label="Waived Default Interest"
            :value="statement.waivedDefaultInterestValue"
            :editing="false"
          />
        </div>
        <div class="total">
          <editable-field
            label="Net Default Interest Due"
            :value="statement.netDefaultInterest"
            :editing="false"
            currency
          />
        </div>
      </div>
    </div>
    <payoff-statement-show-fees :payoff-statement="statement" />
    <div class="details-row row non-total-row">
      <div v-if="creditsExist" class="interest-row">
        <div class="col-12"><h4>Credits</h4></div>
        <div class="col-6 border-row tab">
          <editable-field v-if="isDutch && statement.servicingReleased"
            label="Remaining Construction Reserve"
            :value="statement.constructionReserveAmount"
            :editing="false"
            currency
          />
        </div>
        <div class="col-6"></div>
        <div class="col-6 border-row tab">
          <editable-field v-if="statement.servicingReleased"
            label="Remaining Other Reserve"
            :value="statement.otherReserveAmount"
            :editing="false"
            currency
          />
        </div>
        <div class="col-6"></div>
        <div class="col-6 border-row tab">
          <editable-field
            label="Credits"
            :value="statement.payoffStatementCreditAmount"
            :editing="false"
            currency
          />
        </div>
        <div class="col-6"></div>
        <div class="col-6 dark-border-row tab">
          <editable-field
            label="Waived Principal, Interest and Fees"
            :value="statement.waivedPrincipalInterestFeesAmount"
            :editing="false"
            currency
          />
        </div>
        <div class="col-6"></div>
        <div class="col-6 total">
          <editable-field
            label="Total Credits"
            :value="statement.totalCredits"
            :editing="false"
            currency
          />
        </div>
      </div>
      <div v-else class="interest-row">
        <div class="col-12"><h4>Credits</h4></div>
        <div class="col-12">No Credits</div>
      </div>
    </div>
    <payoff-statement-show-comments :comments="statement.comments" />
  </div>
</template>

<script>
import PayoffStatementShowDetailsMixin from "components/manage/payoff_statement/show/details_mixin";
import PayoffStatementShowOverview from "components/manage/payoff_statement/repurchase/show/overview";

export default {
  name: "repurchase-statement-show-details",
  components: { PayoffStatementShowOverview },
  props: ["statement"],
  mixins: [PayoffStatementShowDetailsMixin],
};
</script>
