<template>
  <div class="details-row row">
    <div class="col-12"><h4>Statement Comments</h4></div>
    <div v-if="isNotEmpty" >
      <div class="col-12 form-group" v-for="comment in comments" :key="comment.id">
        {{ comment.comment }}
      </div>
    </div>
    <div v-else class="col-12 form-group">
      No Comments
    </div>
  </div>
</template>
<script>
export default {
  name: "manage-payoff-statement-show-comments",
  props: {
    comments: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isNotEmpty() {
      return this.comments.some(record => record.comment);
    },
  },
};
</script>
