import EditableField from "components/shared/editable_field.vue";
import EditableDatepicker from "components/shared/editable_datepicker";
import PayoffStatementShowFees from "components/manage/payoff_statement/show/fees";
import PayoffStatementShowComments from "components/manage/payoff_statement/show/comments";
import PayoffStatementShowDates from "components/manage/payoff_statement/show/dates";
import PayoffStatementShowHeader from "components/manage/payoff_statement/show/header";

export default {
  components: {
    EditableField,
    EditableDatepicker,
    PayoffStatementShowFees,
    PayoffStatementShowComments,
    PayoffStatementShowDates,
    PayoffStatementShowHeader,
  },
  computed: {
    isDutch() {
      return this.statement.interestAccrualMethod == "dutch";
    },
    isEstimatePayoffQuote() {
      return this.statement.statementType === "estimate_payoff_quote";
    },
    creditsExist() {
      return (
        this.statement.bPieceAmount != 0 ||
        this.statement.constructionReserveAmount != 0 ||
        this.statement.otherReserveAmount != 0 ||
        this.statement.waivedPrincipalInterestFeesAmount != 0 ||
        this.statement.payoffStatementCreditAmount != 0 ||
        this.statement.escrowReserveAmount != 0 ||
        this.statement.suspenseBalance != 0
      );
    },
  },
};
