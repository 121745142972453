import AjaxService from "services/ajax_service";
import { pluralize } from "filters";
import { success, error } from "services/notification";

const STATUSES = {
  submitted: "submitted",
  resubmitted: "resubmitted",
};

const updateStatusMessage = (state, status) => {
  if ([STATUSES.submitted, STATUSES.resubmitted].includes(status) && state.failed > 0) {
    error("Failed to submit to subservicer");
  } else {
    if (state.updated > 0)
      success(`${pluralize(state.updated, "loan")} successfully moved to ${status}`);
    if (state.failed > 0) error(`${pluralize(state.failed, "loan")} failed moving to ${status}`);
  }
};

export default {
  methods: {
    updateStatus(records, status) {
      return this.postRecords(records, status, this.routes.updateStatus);
    },
    submitBatch(records, status = "submitted", route = this.routes.submit) {
      return this.postRecords(records, status, route);
    },
    postRecords(records, status, url) {
      if (this.inFlight) {
        return;
      }

      this.inFlight = true;
      const data = { ids: records.map(record => record.id), status };

      return new Promise((resolve, reject) => {
        AjaxService.post(url, data, { "Content-Type": "application/json" })
          .then(data => {
            updateStatusMessage(data.state, status);
            resolve(data);
            this.allSelected = false;
            this.isPartiallySelected = false;
          })
          .catch(() => {
            error("Something went wrong");
            reject();
          })
          .finally(() => (this.inFlight = false));
      });
    },
  },
};
