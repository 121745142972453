export default {
  data: function() {
    return {
      loan: PSData.loan || {},
      riskAnalysisDateDisabled: null,
    };
  },
  watch: {
    "loan.lenderNoteStatus": {
      handler(newValue, oldValue) {
        if (newValue === oldValue) {
          return;
        }

        if (newValue === "funding_into_origination" || newValue === "table_funding") {
          this.loan.gracePeriodDays = 10;
          this.loan.lateFee = "10%";
          this.loan.defaultRate = "16";
          this.loan.interestCalculationMethod = "thirty_360";
        }
      },
    },
  },
  methods: {
    onFeesChange() {},
  },
  computed: {
    investmentCommitteeReasonDisabled: function() {
      return `${this.loan.investmentCommitteeRequired}` !== "true";
    },
    investmentCommitteeApprovalDisabled: function() {
      return `${this.loan.investmentCommitteeRequired}` !== "true";
    },
    PSSalePriceDisabled: function() {
      return `${this.loan.servicingReleased}` !== "true";
    },
    prepayPenaltyTermsDisabled: function() {
      return `${this.loan.prePayPenalty}` !== "true";
    },
    prepayPenaltyExpirationDateDisabled: function() {
      return (
        this.loan.programType === "residential_rental_long_term" ||
        this.loan.programType === "extended_bridge"
      );
    },
    amortizationTermDisabled: function() {
      const amortizing = [
        "amortizing",
        "three_year_then_amortizing",
        "five_year_then_amortizing",
      ].includes(this.loan.paymentStructure);
      return !amortizing;
    },
    releasePricingDisabled() {
      return !(this.loan.properties > 1);
    },
    loanPurposeAquisition() {
      return this.loan.loanPurpose == "acquisition";
    },
    loanTermDisabled() {
      return (
        this.loan.lenderNoteStatus === "funded" ||
        this.loan.programType === "residential_rental_long_term"
      );
    },
    originalMaturityDateDisabled() {
      return this.loan.lenderNoteStatus !== "funded";
    },
    firstPaymentDateDisabled() {
      return this.loan.lenderNoteStatus !== "funded";
    },
    isFundingIntoOrigination() {
      return this.loan.lenderNoteStatus === "funding_into_origination";
    },
  },
};
