<template>
  <modal-view :on-close="close" :show="show" class="edit-document-modal" type="container">
    <div class="modal-body">
      {{ basename }} -
      <input v-model="suffix" type="text" />
    </div>
    <div class="modal-actions">
      <a class="btn btn-cancel-cta" @click="close()">
        Cancel
      </a>
      <a class="btn btn-action-cta" @click="saveChanges()">
        Save
      </a>
    </div>
  </modal-view>
</template>

<script>
import ModalView from "components/modal_view";

export default Vue.component("diligence-document-edit-modal", {
  components: { ModalView },
  props: ["show", "document", "onUpdate"],
  data: function() {
    return {
      suffix: "",
      basename: "",
    };
  },
  mounted: function() {
    this.$nextTick(() => {
      document.addEventListener("keydown", e => {
        if (this.show && e.keyCode == 13) {
          // Enter key
          this.saveChanges();
        }
      });
    });
  },
  watch: {
    show: "initialize",
  },
  methods: {
    initialize: function() {
      if (this.show) {
        this.suffix = this.document.suffix;
        this.basename = this.document.basename;
      }
    },
    close: function() {
      this.$emit("hide-modal");
    },
    saveChanges: function() {
      this.document.suffix = this.suffix;

      this.onUpdate(this.document);
      this.close();
    },
  },
});
</script>
