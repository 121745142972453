<template>
  <div>
    <h3 class="property-management-header clearfix">
      Property Management
      <button class="btn btn-action-modal float-right" @click="onSave" :disabled="saveDisabled">Save</button>
      <button class="btn btn-cancel-modal float-right" @click="onCancel">Cancel</button>
    </h3>

    <form>
      <view-panel class="loan-details-view-panel">
        <div class="row loan-details-view-panel-row">
          <div class="col-6 loan-details-view-panel-column">
            <form-select
              label="Is the property managed by a 3rd party?"
              field="agreementPresent"
              v-model="propertyManagement.agreementPresent"
              :disabled="saveDisabled"
              :options="propertyManagement.yesNoOptions"
              :errors="errors"
            />
            <form-input
              label="Name"
              field="name"
              v-show="showFields"
              v-model="propertyManagement.name"
              :disabled="saveDisabled"
              :errors="errors"
            />
            <form-input
              label="Address"
              field="address"
              v-show="showFields"
              v-model="propertyManagement.address"
              :disabled="saveDisabled"
              :errors="errors"
              placeholder=""
              ref="managementAddress"
            />
            <form-input
              label="City"
              field="city"
              v-show="showFields"
              v-model="propertyManagement.city"
              :disabled="saveDisabled"
              :errors="errors"
            />
            <form-select
              label="State"
              field="state"
              v-show="showFields"
              v-model="propertyManagement.state"
              :disabled="saveDisabled"
              :options="propertyManagement.statesOptions"
              :errors="errors"
            />
            <form-input
              label="Zip Code"
              field="zipCode"
              v-show="showFields"
              v-model="propertyManagement.zipCode"
              :disabled="saveDisabled"
              :errors="errors"
            />
            <form-input
              label="Phone #"
              field="phone"
              v-show="showFields"
              v-model="propertyManagement.phone"
              :disabled="saveDisabled"
              :errors="errors"
            />
            <form-input
              label="Email Address"
              field="email"
              v-show="showFields"
              v-model="propertyManagement.email"
              :disabled="saveDisabled"
              :errors="errors"
            />
            <form-input
              label="Name of Management Agreement"
              field="agreementName"
              v-show="showFields"
              v-model="propertyManagement.agreementName"
              :disabled="saveDisabled"
              :errors="errors"
            />
            <form-date-input
              label="Agreement Date"
              field="agreementDate"
              v-show="showFields"
              v-model="propertyManagement.agreementDate"
              :disabled="saveDisabled"
              :errors="errors"
            />
            <points-or-amount-input
              v-show="showFields"
              :disabled="saveDisabled"
              label="Management Fees"
              :amount-value="feesAmount"
              :points-value="feesPoints"
              :on-fees-change="onFeesChange"
              :key="feesComponentKey"
              amount-attribute="amount"
              points-attribute="points"
            />
          </div>
        </div>
      </view-panel>
    </form>
  </div>
</template>

<script>
import PropertyManagement from "models/property_management";
import AjaxService from "services/ajax_service";
import FormInput from "components/shared/form/form_input";
import FormDateInput from "components/shared/form/form_date_input";
import FormSelect from "components/shared/form/form_select";
import ViewPanel from "components/view_panel.vue";
import GoogleAutocompleteMixin from "mixins/google_autocomplete_mixin";
import PointsOrAmountInput from "components/points_or_amount_input";

export default {
  name: "management-form",
  components: {
    FormInput,
    FormDateInput,
    FormSelect,
    ViewPanel,
    PointsOrAmountInput,
  },
  mixins: [GoogleAutocompleteMixin],
  props: {
    propertyManagement: {
      type: PropertyManagement,
      required: true,
    },
  },
  data() {
    this.setManagementFees();

    return {
      errors: {},
      routes: PSData.routes || {},
      saveDisabled: false,
      feesComponentKey: 0,
    };
  },
  mounted() {
    this.initializeAutocomplete();
  },
  computed: {
    showFields() {
      return this.propertyManagement.agreementPresentYN == "Yes";
    },
  },
  watch: {
    "propertyManagement.agreementPresent"(newValue) {
      if ([false, "false"].includes(newValue)) {
        this.propertyManagement.name = null;
        this.propertyManagement.address = null;
        this.propertyManagement.city = null;
        this.propertyManagement.state = null;
        this.propertyManagement.zipCode = null;
        this.propertyManagement.phone = null;
        this.propertyManagement.email = null;
        this.propertyManagement.agreementName = null;
        this.propertyManagement.agreementDate = null;
        this.propertyManagement.managementFeesAmount = null;
        this.propertyManagement.managementFeesPoints = null;
      }
    },
    showFields(val) {
      if (val) {
        this.initializeAutocomplete();
      }
    },
  },
  methods: {
    onSave() {
      this.saveDisabled = true;
      this.errors = {};
      const messages = { onSuccess: "Property Management has saved", onError: this.onError };

      AjaxService.withNotifications(messages)
        .patch(PSData.routes.propertyManagementUpsert, this.propertyManagement.toHash(), {
          "Content-Type": "application/json",
        })
        .then(data => {
          this.$emit("on-save", data);
        })
        .catch(response => {
          this.errors = response.data.errors;
        })
        .finally(() => {
          this.saveDisabled = false;
          if (!this.errors) {
            this.onCancel();
          }
        });
    },
    onCancel() {
      this.$emit("on-cancel");
      this.refreshFeesComponent();
    },
    initializeAutocomplete() {
      if (!this.googleAutocomplete.autocomplete) {
        this.$nextTick(() => this.attachAutocompleteToElement(this.$refs.managementAddress.$el));
      }
    },
    handleAutocompleteSelected(newAddress) {
      this.propertyManagement.address = newAddress.fullStreetName;
      this.propertyManagement.city = newAddress.city;
      this.propertyManagement.state = newAddress.state;
      this.propertyManagement.zipCode = newAddress.zipCode;
    },
    onFeesChange(feeType, newVal) {
      if (feeType === "amount") {
        this.propertyManagement.managementFeesAmount = newVal;
      } else if (feeType === "points") {
        this.propertyManagement.managementFeesPoints = newVal;
      }

      if (feeType === "amount" && newVal) {
        this.propertyManagement.managementFeesType = "amount";
      } else if (feeType === "points" && newVal) {
        this.propertyManagement.managementFeesType = "points";
      }
    },
    setManagementFees() {
      const feesAmount = this.propertyManagement.managementFeesAmount;
      const feesPoints = this.propertyManagement.managementFeesPoints;
      const feesType = this.propertyManagement.managementFeesType;
      const isFeesAmount = feesType === "amount";
      const isFeesPoints = feesType === "points";

      this.feesAmount = isFeesAmount ? feesAmount : null;
      this.feesPoints = isFeesPoints ? feesPoints : null;
    },
    refreshFeesComponent() {
      this.setManagementFees();
      this.feesComponentKey += 1;
    },
  },
};
</script>
