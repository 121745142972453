import LoanTaskModel from "models/loan_tasks/task.js";
import FilterDropdown from "components/shared/filter_dropdown";
import UserSettingsService from "services/user_settings/user_settings_service";
import Location from "services/location_service";
import WindowService from "services/window_service";
import DocumentService from "services/document_service";
import { SETTINGS_KEYS } from "services/user_settings/config";
import {
  DEFAULT_TASKS_FILTER,
  TASKS_FILTER,
} from "services/user_settings/loan_tasks_filter_config";
import replace from "lodash/replace";

const VIEW = {
  list: "list",
  task: "task",
};

export default {
  components: {
    FilterDropdown,
  },
  props: {
    uploadSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      view: VIEW.list,
      currentTask: undefined,
      loanTasks: [],
      loanTaskGroups: [],
      seenTasksIds: [],
      selectedTasks: [],
      filterValue: DEFAULT_TASKS_FILTER,
      submitMultiple: false,
      isMarkingComplete: false,
    };
  },
  watch: {
    hasUnreadTasks(value) {
      this.$emit("activity-update", value);
    },
    filterValue() {
      UserSettingsService.setItem(this.loanTasksFilterSettingsKey, this.filterValue, true);

      if (this.filterTasks) {
        this.selectedTasks = this.filteredTasks(
          this.selectedTasks.map(taskId => this.findTaskById(taskId))
        ).map(task => task.id);
      }
    },
  },
  computed: {
    isTaskView() {
      return this.view === VIEW.task;
    },
    allTaskGroups() {
      return [
        {
          id: "internal",
          label: "Internal Tasks",
        },
        {
          id: "sla",
          label: "SLAs",
        },
        {
          id: "qa",
          label: "QA Tasks",
        },
        {
          id: "miscellaneous",
          label: "Miscellaneous",
        },
        ...this.loanTaskGroups,
        {
          id: "others",
        },
      ];
    },
    miscellaneousGroup() {
      return (
        this.loanTaskGroups.find(group => group.label === "Miscellaneous") || {
          id: "miscellaneous",
        }
      );
    },
    taskSections() {
      const taskGroupsMap = this.allTaskGroups.reduce((memo, group) => {
        memo[group.id] = [];
        return memo;
      }, {});
      return this.loanTasks.reduce((memo, task) => {
        if (task.isQA) {
          memo.qa.push(task);
        } else if (memo[task.groupId]) {
          memo[task.groupId].push(task);
        } else {
          if (task.isCustomInternal) {
            memo.internal.push(task);
          } else if (task.isSla) {
            memo.sla.push(task);
          } else if (task.isCustom || task.isQuestion) {
            memo[this.miscellaneousGroup.id].push(task);
          } else {
            memo.others.push(task);
          }
        }

        return memo;
      }, taskGroupsMap);
    },
    filterOptions() {
      return {
        [TASKS_FILTER.openTasks]: "Open",
        [TASKS_FILTER.pendingTasks]: "Pending",
        [TASKS_FILTER.completedTasks]: "Completed",
      };
    },
    filterTasks() {
      return !this.filterValue.includes(TASKS_FILTER.allTasks);
    },
    includeOpenTasks() {
      return this.filterValue.includes(TASKS_FILTER.openTasks);
    },
    includePendingTasks() {
      return this.filterValue.includes(TASKS_FILTER.pendingTasks);
    },
    includeCompletedTasks() {
      return this.filterValue.includes(TASKS_FILTER.completedTasks);
    },
    tasksListFilterClass() {
      if (this.filterTasks) {
        return {
          "loan-tasks-list-exclude-open": !this.includeOpenTasks,
          "loan-tasks-list-exclude-pending": !this.includePendingTasks,
          "loan-tasks-list-exclude-completed": !this.includeCompletedTasks,
        };
      } else {
        return "";
      }
    },
    hasUnreadTasks() {
      return this.loanTasks.some(t => !this.seenTasksIds.includes(t.id));
    },
    openTasks() {
      return this.loanTasks.filter(task => task.isOpen);
    },
    tasksToSelect() {
      let tasks = this.filteredTasks(this.loanTasks);
      return tasks.filter(task => task.canComplete);
    },
    tasksToTransit() {
      return this.selectedTasks.map(taskId => this.findTaskById(taskId));
    },
    showSubmitMultiple() {
      return this.tasksToSelect.length && !this.submitMultiple;
    },
    loanTasksFilterSettingsKey() {
      return SETTINGS_KEYS.loanTasksFilter;
    },
  },
  mounted() {
    UserSettingsService.subscribe(this.updateFilter);
    UserSettingsService.loadSettings();
  },
  beforeDestroy() {
    UserSettingsService.unsubscribe(this.updateFilter);
  },
  methods: {
    openTask(task) {
      this.seenTasksIds.push(task.id);
      this.view = VIEW.task;
      this.currentTask = task;
    },
    openListView() {
      this.view = VIEW.list;
      this.currentTask = undefined;
    },
    prepareTasks(tasks) {
      return (tasks || []).map(task => new LoanTaskModel(task));
    },
    startMultipleSubmit() {
      this.selectedTasks = this.openTasks
        .filter(task => this.documents.some(document => document.taskIds.includes(task.id)))
        .map(task => task.id);
      this.submitMultiple = true;
    },
    stopMultipleSubmit() {
      this.selectedTasks = [];
      this.submitMultiple = false;
    },
    deselectAll() {
      this.selectedTasks = [];
    },
    selectAll() {
      this.selectedTasks = this.tasksToSelect.map(task => task.id);
    },
    onSelectedTasksChange(ids) {
      this.selectedTasks = ids || [];
    },
    onNewComment({ loanTask }) {
      this.currentTask.loanTaskCommentsCount = loanTask.loanTaskCommentsCount;
    },
    findTaskById(id) {
      return this.loanTasks.find(task => task.id === id);
    },
    displayTaskOnLoad() {
      const id = this.getTaskIdToOpen();
      const task = id ? this.findTaskById(id) : undefined;

      if (task) {
        this.$nextTick(() => this.openTask(task));
      }
    },
    getTaskIdToOpen() {
      const params = new URLSearchParams(Location.search);

      if (params.get("loan_task")) {
        const id = parseFloat(params.get("loan_task"));
        const url = replace(Location.href, Location.search, "");
        WindowService.history.replaceState(null, DocumentService.title, url);

        return id;
      } else {
        return parseFloat(PSData.displayTask);
      }
    },
    updateFilter() {
      this.filterValue = UserSettingsService.getItem(this.loanTasksFilterSettingsKey);
    },
    filteredTasks(tasks) {
      if (this.filterTasks) {
        return tasks.filter(
          task =>
            (this.includeOpenTasks && task.isOpen) ||
            (this.includePendingTasks && task.isPending) ||
            (this.includeCompletedTasks && task.isConfirmed)
        );
      }
      return tasks;
    },
    allTasksHidden(tasks) {
      return !this.filteredTasks(tasks).length;
    },
  },
};
