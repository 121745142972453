<template>
  <div class="servicer-loan-boarding">
    <div class="servicer-loan-boarding-header">
      <h2>Loan Purchase Boarding to Subservicer</h2>
      <span v-if="lastUpdatedAt" class="date-label">As of {{ lastUpdatedAt }}</span>
      <div class="refresh-button">
        <button
          type="button"
          class="btn btn-outline-grey"
          :disabled="isRefreshDisabled"
          @click="refresh"
        >
          Refresh Data
        </button>
      </div>
    </div>
    <div class="nav-menu clearfix">
      <ul class="nav-underlined">
        <li class="pipeline" :class="{ active: currentTab == 'pipeline' }">
          <a href="#" @click="currentTab = 'pipeline'">Pipeline ({{ counters.pipeline }})</a>
        </li>
        <li class="reconcile" :class="{ active: currentTab == 'reconcile' }">
          <a href="#" @click="currentTab='reconcile'">Reconcile ({{ counters.reconcile }})</a>
        </li>
        <li class="ready-to-submit" :class="{ active: currentTab == 'readyToSubmit' }">
          <a href="#" @click="currentTab='readyToSubmit'">Ready to Submit ({{ counters.readyToSubmit }})</a>
        </li>
        <li class="submitted" :class="{ active: currentTab == 'submitted' }">
          <a href="#" @click="currentTab='submitted'">Submitted ({{ counters.submitted }})</a>
        </li>
      </ul>
    </div>
    <section class="servicer-filter-section">
      <b-radio
        v-model="filterForServicerId"
        native-value=""
      >
        All Subservicers
      </b-radio>
      <b-radio
        v-model="filterForServicerId"
        native-value="1"
      >
        FCI
      </b-radio>
      <b-radio
        v-model="filterForServicerId"
        native-value="35"
      >
        Fay
      </b-radio>
    </section>
    <component
      :is="loanBoardingSection(tab)"
      v-for="tab in tabs"
      v-show="tab == currentTab"
      :key="tab"
      :collections="collections"
      :transfer-collections="transfer"
      :filter-for-servicer-id="filterForServicerId"
      :routes="routes"
      :options="options"
      @loaded="onLoad"
      @updated="updateCollections"
      @transfer-updated="updateTransferCollections"
    />
  </div>
</template>

<script>
const TABS = {
  pipeline: "pipeline",
  reconcile: "reconcile",
  readyToSubmit: "readyToSubmit",
  submitted: "submitted",
};

import LoanBoardingSectionPipeline from "components/servicer/loan_boarding/sections/pipeline";
import LoanBoardingSectionReconcile from "components/servicer/loan_boarding/sections/reconcile";
import LoanBoardingSectionReadyToSubmit from "components/servicer/loan_boarding/sections/ready_to_submit";
import LoanBoardingSectionSubmitted from "components/servicer/loan_boarding/sections/submitted";

import AjaxService from "services/ajax_service";
import moment from "moment";
import { success, error } from "services/notification";

export default {
  name: "ManageServicerLoanBoardings",
  components: {
    LoanBoardingSectionPipeline,
    LoanBoardingSectionReconcile,
    LoanBoardingSectionReadyToSubmit,
    LoanBoardingSectionSubmitted,
  },
  data() {
    return {
      inFlight: false,
      currentTab: TABS.pipeline,
      tabs: Object.keys(TABS),
      filterForServicerId: "",
      counters: {
        pipeline: 0,
        reconcile: 0,
        readyToSubmit: 0,
        submitted: 0,
      },
      lastUpdatedDates: {
        pipeline: null,
        reconcile: null,
        readyToSubmit: null,
        submitted: null,
      },
      options: PSData.options,
      routes: {
        updateStatus: "/manage/servicer/loan_boardings/update_status",
        refresh: "/manage/servicer/loan_boardings/refresh",
        submit: "/manage/servicer/loan_boardings/submit",
        transfer: {
          updateStatus: "/manage/servicer/loan_boarding_transfers/update_status",
          submit: "/manage/servicer/loan_boarding_transfers/submit",
        },
        reconciliation: {
          submit: "/manage/servicer/loan_boarding_reconciliations/submit",
        },
      },
      collections: {
        pending: PSData.pending || [],
        approved: PSData.approved || [],
        submitted: PSData.submitted || [],
        resubmitted: PSData.resubmitted || [],
        flagged: PSData.flagged || [],
        repaired: PSData.repaired || [],
        postBoarded: PSData.postBoarded || [],
      },
      transfer: {
        pending: PSData.transfer.pending || [],
        submitted: PSData.transfer.submitted || [],
        approved: PSData.transfer.approved || [],
      },
    };
  },
  computed: {
    currentSectionCounter() {
      return this.counters[this.currentTab];
    },
    isRefreshDisabled() {
      return this.currentSectionCounter === 0;
    },
    lastUpdatedAt() {
      return this.lastUpdatedDates[this.currentTab];
    },
  },
  methods: {
    loanBoardingSection(tab) {
      return `loan-boarding-section-${tab}`;
    },
    onLoad({ section, count, lastUpdatedAt }) {
      this.counters[section] = count;
      this.lastUpdatedDates[section] = lastUpdatedAt;
    },
    async refresh() {
      if (this.inFlight) return;

      const messages = {
        onSuccess: "Loans were refreshed successfully",
      };

      try {
        const result = await AjaxService.withNotifications(messages).post(this.routes.refresh, {
          section: this.currentTab,
        });

        Object.keys(result).forEach(key => {
          const isCustomCollection = key !== "transfer";

          if (isCustomCollection && result[key] !== undefined) {
            this.collections[key] = result[key] || [];
          }
        });

        if (result.transfer !== undefined) {
          Object.keys(result.transfer).forEach(key => {
            this.transfer[key] = result.transfer[key] || [];
          });
        }
      } finally {
        this.inFlight = false;
      }
    },
    updateCollections(collections) {
      Object.keys(collections).forEach(name => {
        if (collections[name] !== undefined) {
          this.collections[name] = collections[name] || [];
        }
      });
    },
    updateTransferCollections(collections) {
      Object.keys(collections).forEach(name => {
        if (collections[name] !== undefined) {
          this.transfer[name] = collections[name] || [];
        }
      });
    },
  },
};
</script>

<style scoped>
.servicer-filter-section {
  margin-top: 24px;
}
</style>
