<template>
  <base-tooltip
    type="is-white"
    class="next-task-tooltip"
  >
    <template v-slot:content>
      <span class="tooltip-header">Next Task Up</span>
      <div v-if="hasNextTask">
        {{ workflow.nextDueTaskLabel }}
      </div>
      <div v-else>
        None
      </div>
    </template>
    <template v-slot>
      <slot />
    </template>
  </base-tooltip>
</template>
<script>
import isNil from "lodash/isNil";

export default {
  props: {
    workflow: Object,
  },
  computed: {
    hasNextTask() {
      return !isNil(this.workflow.nextDueTaskLabel);
    },
  },
};
</script>
<style scoped>
.tooltip-header {
  font-weight: 600;
}

.next-task-tooltip {
  display: flex;
}
</style>
