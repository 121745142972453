<template>
  <div class="account-issues">
    <div class="account-issues-title">
      <h5>Request Issues({{relatedWireRequests.length}})</h5>
      <div class="account-issues-trigger">
        <a href="#" @click.prevent="showView" v-if="!show">
          Show Issues
        </a>
        <a href="#" @click.prevent="hideView" v-if="show">
          Hide Issues
        </a>
      </div>
    </div>
    <table class="table" v-show="show">
      <thead>
        <tr>
          <th>Errors</th>
          <th>Name</th>
          <th>Bank</th>
          <th>Source</th>
          <th>Type</th>
          <th>Loan ID</th>
          <th>Series ID</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <issues-table-row
          v-for="wireRequest in relatedWireRequests"
          :wire-request="wireRequest"
          :key="wireRequest.id"
        >
        </issues-table-row>
      </tbody>
    </table>
  </div>
</template>
<script>
import IssuesTableRow from "./issues_table_row";
import ProblemWireRequest from "models/bank_center/problem_wire_request";

export default {
  props: {
    accounts: Array,
    problemWireRequests: Array,
  },
  components: {
    IssuesTableRow,
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    showView() {
      this.show = true;
    },
    hideView() {
      this.show = false;
    },
  },
  computed: {
    relatedWireRequests() {
      return ProblemWireRequest.filterByAccounts(this.problemWireRequests, this.accounts);
    },
  },
  watch: {
    problemWireRequests: function() {
      this.show = true;
    },
  },
};
</script>
