import { SectionType } from "models/lender_form/fields";
import Section from "models/lender_form/section";

export default class BorrowerSection extends Section {
  constructor(fields) {
    super(SectionType.Borrower, fields);
  }

  update() {
    this.checkProgramType();
    this.checkProfessionallyManaged();

    super.update();
  }

  checkProgramType() {
    this.fields.personalGuarantyYN.disabled = !this.isMultifamilyBridge;
  }

  checkProfessionallyManaged() {
    this.fields.professionallyManaged.required = this.isResiRental;

    this.fields.borrowerGuarantorTrackRecord.disabled =
      this.fields.professionallyManaged.value === "true";
  }

  get borrowerTrackRecordLabel() {
    let loanStrategyId = this.fields.loanStrategyId.convertedValue;
    let label = "Borrower/Guarantor Track Record";

    if (this.bridgeLoanStrategies.includes(loanStrategyId)) {
      label = "# of Bridge Hold Projects Completed";
    } else if (this.rentalLoanStrategies.includes(loanStrategyId)) {
      label = "# of Rental Properties Managed";
    } else if (this.extendedBridgeRentalLoanStrategies.includes(loanStrategyId)) {
      label = "# of Extended Bridge Rental Projects Completed";
    } else if (this.fixLoanStrategies.includes(loanStrategyId)) {
      label = "# of Fix Projects Completed";
    }

    return label;
  }

  get extendedBridgeRentalLoanStrategies() {
    return PSData.extendedBridgeRentalLoanStrategies || [];
  }

  get bridgeLoanStrategies() {
    return PSData.bridgeLoanStrategies || [];
  }

  get rentalLoanStrategies() {
    return PSData.rentalLoanStrategies || [];
  }

  get fixLoanStrategies() {
    return PSData.fixLoanStrategies || [];
  }

  get isIndividualBorrower() {
    return this.fields.borrowerType.value === "individual";
  }

  get isResiRental() {
    return this.fields.programType.value === "residential_rental_long_term";
  }

  get isMultifamilyBridge() {
    return this.fields.programType.value === "commercial_multifamily";
  }
}
