<template>
  <div class="workflows-list-item panel-bordered" ref="domElement">
    <div class="workflows-list-row">
      <div class="workflows-list-column workflows-list-workflows">
        <div class="workflows-list-content">
          <div class="workflow-fold">
            <i v-show="!visible" class="fa fa-caret-right" @click="onShow" />
            <i v-show="visible" class="fa fa-caret-down" @click="onHide" />
          </div>
          <div>
            <div class="workflow-label">
              {{ templateName }}
              <i v-if="isSaving" class="ps-loader ps-loader-small" />
            </div>
            <div class="workflow-details">
              <div class="badge-item">
                <status-badge
                  :workflow="workflow"
                  :closing-reasons="closingReasons"
                />
              </div>
              <div class="badge-item">
                <detail-badge>
                  <i class="far fa-calendar" />
                  {{ createdAt }} - {{ closedAt }}
                </detail-badge>
              </div>
              <div v-if="hasOpenTasks" class="badge-item">
                <detail-badge>
                  <i class="fas fa-list" />
                  {{ workflow.openTasksTotal }} Open Tasks
                </detail-badge>
              </div>
              <div v-if="hasComments" class="badge-item">
                <detail-badge>
                  <i class="far fa-comment" />
                  {{ workflow.commentsTotal }} Notes
                </detail-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="workflows-list-column workflows-list-assigned">
        <user-editable-select
          :model="workflow"
          :editing="true"
          :value="form.assignedToId"
          :disabled="isEditDisabled"
          :users="workflowUsers"
          @input="change('assignedToId', $event.target.value)"
        />
      </div>
      <div
        :class="{ 'bold-red': isPastDue() }"
        class="workflows-list-column workflows-list-due-date"
      >
        <input
          v-show="isOpen"
          v-datepicker
          v-mask:date
          class="form-control foreclosure-input"
          :value="form.dueDate"
          :disabled="isEditDisabled"
          @input="change('dueDate', $event.target.value)"
        >
      </div>
      <div class="workflows-list-column workflows-list-actions">
        <workflow-action-button
          :model="workflow"
          :disabled="isEditDisabled"
          :closing-reasons="closingReasons"
          :has-open-tasks="hasOpenTasks"
          @collapse-workflow="onHide"
        />
      </div>
    </div>
    <section v-show="visible" class="workflow-tabs">
      <base-tabs
        v-model="activeTab"
        class="pt-2"
        destroy-on-hide
        :animated="false"
      >
        <base-tab-item label="Tasks">
          <template v-slot:content>
            <tasks-tab
              :workflow="workflow"
              :disabled="isEditDisabled"
            />
          </template>
        </base-tab-item>
        <base-tab-item label="Notes">
          <template v-slot:content>
            <comments-tab
              :workflow="workflow"
              :disabled="isEditDisabled"
            />
          </template>
        </base-tab-item>
      </base-tabs>
    </section>
  </div>
</template>
<script>
import UserEditableSelect from "./user_editable_select";
import WorkflowActionButton from "./workflow_action_button";
import StatusBadge from "./status_badge";
import TasksTab from "./tasks_tab";
import CommentsTab from "./comment/tab";
import DetailBadge from "./detail_badge";
import objectToSnakeCase from "utils/object_to_snake_case";
import AjaxService from "services/ajax_service";
import debounce from "lodash/debounce";
import foldableMixin from "mixins/foldable_mixin";
import moment from "moment";
import useAssetManagementStore from "controllers/manage/store/asset_management";

const DEBOUNCE_WAIT = 500;

export default {
  components: {
    UserEditableSelect,
    WorkflowActionButton,
    StatusBadge,
    TasksTab,
    CommentsTab,
    DetailBadge,
  },
  mixins: [foldableMixin],
  props: {
    workflow: {
      type: Object,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isSaving: false,
      form: {
        id: this.workflow.id,
        assignedToId: this.workflow.assignedToId,
        dueDate: this.workflow.dueDate || "",
      },
      closingReasons: PSData.workflowClosedReasons,
      activeTab: 0,
    };
  },
  mounted() {
    const viewWorkflowId = Number.parseInt(this.params.view_workflow_id, 10);
    if (viewWorkflowId === this.workflow.id) {
      this.onShow();
      if (this.$refs.domElement !== null) {
        setTimeout(() => this.$refs.domElement.scrollIntoView({ behavior: "smooth" }), 2000);
      }
    }
  },
  computed: {
    workflowUsers() {
      return PSData.workflowUsers.map(u => ({ id: u.id, text: u.name }));
    },
    createdAt() {
      return this.workflow.createdAt;
    },
    closedAt() {
      if (!this.workflow.closedAt) {
        return "Now";
      }
      return this.workflow.closedAt;
    },
    templateName() {
      return this.workflow.templateLabel;
    },
    isOpen() {
      return !this.workflow.closedAt;
    },
    isEditDisabled() {
      const store = useAssetManagementStore();
      return !(this.isOpen && store.canUpdateWorkflows);
    },
    hasOpenTasks() {
      return this.workflow.openTasksTotal > 0;
    },
    hasComments() {
      return this.workflow.commentsTotal > 0;
    },
  },
  methods: {
    isPastDue() {
      const workflowDueMoment = moment(this.workflow.dueDate, "L");
      const yesterdayMoment = moment().add(-1, "days");
      return yesterdayMoment.isAfter(workflowDueMoment);
    },
    change(fieldName, value) {
      if (this.form[fieldName] !== value) {
        this.$set(this.form, fieldName, value);
        this.onSaveDebounced();
      }
    },
    updateWorkflow() {
      const url = this.workflow.routes.upsert;
      const params = objectToSnakeCase(this.form);
      return AjaxService.withNotifications({
        onSuccess: "Workflow was updated successfully",
        onError: "Failed to update workflow",
      }).patchJSON(url, params);
    },
    async onSave() {
      try {
        this.isSaving = true;
        const workflow = await this.updateWorkflow();
        const store = useAssetManagementStore();
        store.updateWorkflow({ id: workflow.id, data: workflow });
      } catch ({ response, data }) {
        if (response.status === 422) {
          this.errors = data.errors;
        }
      } finally {
        this.isSaving = false;
      }
    },
    onSaveDebounced: debounce(function() {
      this.onSave();
    }, DEBOUNCE_WAIT),
  },
};
</script>
<style scoped>
.workflow-tabs {
  padding: calc(var(--space-unit) * 2) calc(var(--space-unit) * 2) 0 0;
  margin-left: calc(var(--space-unit) * 3.5);
}

.badge-item {
  margin-right: var(--space-unit-md);
}

.bold-red input {
  color: var(--color-red);
  font-weight: 600;
}
</style>
