export default class Section {
  constructor(type, fields) {
    this.type = type;
    this.fields = fields;

    this.isComplete = false;
    this.isValid = false;
    this.availableFields = [];
    this.totalFields = 0;
    this.completedFields = 0;
  }

  update() {
    this.filterAvailableFields();
    this.validate();
    this.calculateFields();
  }

  validate() {
    this.availableFields.forEach(field => (field.isValid = field.isComplete));
    this.isValid = this.availableFields.every(f => f.isValid);
    this.isComplete = this.isValid;
  }

  calculateFields() {
    this.totalFields = this.availableFields.length;
    this.completedFields = this.availableFields.filter(f => f.isValid).length;
  }

  filterAvailableFields() {
    const fieldsArray = Object.keys(this.fields).map(name => this.fields[name]);
    this.availableFields = fieldsArray.filter(f => f.section === this.type && !f.disabled);
  }
}
