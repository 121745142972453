<template>
  <div class="account-group">
    <div class="account-group-header">
      <h4>{{accountType.label}}</h4>
    </div>
    <div class="account-group-accounts">
      <table class="table">
        <thead>
          <tr>
            <th class="account-group-name">Name</th>
            <th class="account-group-issues">Request Issues</th>

            <th class="account-group-amount">
              Bank Balance
              <base-tooltip
                multilined
                position="is-bottom"
                class="readonly-tooltip ml-1"
              >
                <template v-slot:content>
                  <div class="column-tooltip">
                    The Bank Balance amount is the current Bank Account Balance.
                  </div>
                </template>
                <b-icon
                  pack="fas"
                  size="is-small"
                  icon="question-circle"
                />
              </base-tooltip>
            </th>

            <th class="account-group-amount">
              Capacity
              <base-tooltip
                multilined
                position="is-bottom"
                class="readonly-tooltip ml-1"
              >
                <template v-slot:content>
                  <div class="column-tooltip">
                    “Source of Capital" accounts have a set capacity. Capacity is 0 for all other accounts.
                  </div>
                </template>
                <b-icon
                  pack="fas"
                  size="is-small"
                  icon="question-circle"
                />
              </base-tooltip>
            </th>

            <th class="account-group-amount">
              Funded
              <base-tooltip
                multilined
                position="is-bottom"
                class="readonly-tooltip ml-1"
              >
                <template v-slot:content>
                  <div class="column-tooltip">
                    The Funded amount is made up of the UPB sum for all Active Series for a Loan for the
                    Source of Capital. Active Series is where:
                    <ul>
                      <li>Start Date is not Null</li>
                      <li>End Date is Null</li>
                    </ul>
                  </div>
                </template>
                <b-icon
                  pack="fas"
                  size="is-small"
                  icon="question-circle"
                />
              </base-tooltip>
            </th>

            <th class="account-group-amount">
              Dry Powder
              <base-tooltip
                multilined
                position="is-bottom"
                class="readonly-tooltip ml-1"
              >
                <template v-slot:content>
                  <div class="column-tooltip">
                    The Dry Powder amount is calculated as the Capacity less Funded amount per the Source of Capital.
                  </div>
                </template>
                <b-icon
                  pack="fas"
                  size="is-small"
                  icon="question-circle"
                />
              </base-tooltip>
            </th>

            <th class="account-group-amount">
              In-Transit
              <base-tooltip
                multilined
                position="is-bottom"
                class="readonly-tooltip ml-1"
              >
                <template v-slot:content>
                  <div class="column-tooltip">The In-Transit amount is made up of all Wire Requests that are yet to be sent to the Bank or
                    the Bank has yet to send a response to them. This includes all records where the Status is in:
                    <ul>
                      <li>Pending</li>
                      <li>Queued</li>
                      <li>Sent</li>
                    </ul>
                  </div>
                </template>
                <b-icon
                  pack="fas"
                  size="is-small"
                  icon="question-circle"
                />
              </base-tooltip>
            </th>

            <th class="account-group-amount">
              Earmarked
              <base-tooltip
                multilined
                position="is-bottom"
                class="readonly-tooltip ml-1"
              >
                <template v-slot:content>
                  <div class="column-tooltip">Earmarked amount is made up of the sum of all Pending Series for a Loan for the
                    Source of Capital. Pending Series is where:
                    <ul>
                      <li>Start Date is Null</li>
                      <li>End Date is Null</li>
                      <li>Target Purchase Date is on or before Current Date</li>
                    </ul>
                  </div>
                </template>
                <b-icon
                  pack="fas"
                  size="is-small"
                  icon="question-circle"
                />
              </base-tooltip>
            </th>

            <th class="account-group-amount">
              Available
              <base-tooltip
                multilined
                position="is-bottom"
                class="readonly-tooltip ml-1"
              >
                <template v-slot:content>
                  <div class="column-tooltip">
                    For a Source of Capital, the Available amount is calculated as the Capacity less Funded
                    less In-Transit less Earmarked. For a Bank Account, the Available amount is the retrieved
                    Bank Account Balance plus In-Transit plus Earmarked.
                  </div>
                </template>
                <b-icon
                  pack="fas"
                  size="is-small"
                  icon="question-circle"
                />
              </base-tooltip>
            </th>

            <th class="account-group-amount">
              Daily Activity
              <base-tooltip
                multilined
                position="is-bottom"
                class="readonly-tooltip ml-1"
              >
                <template v-slot:content>
                  <div class="column-tooltip">
                    The Daily Activity amount is made up of all Wire Requests that were sent to the Bank and
                    the Bank completed processing them today. Outbound wires are treated as a negative amount.
                  </div>
                </template>
                <b-icon
                  pack="fas"
                  size="is-small"
                  icon="question-circle"
                />
              </base-tooltip>
            </th>

            <th class="account-group-amount">
              Throughput
              <base-tooltip
                multilined
                position="is-left"
                class="readonly-tooltip ml-1"
              >
                <template v-slot:content>
                  <div class="column-tooltip">
                    The Throughput amount is made up of all Wire Requests that were sent to the Bank and the
                    Bank has completed processing them today. Outbound wires are treated as an absolute value.
                  </div>
                </template>
                <b-icon
                  pack="fas"
                  size="is-small"
                  icon="question-circle"
                />
              </base-tooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          <account-group-row
            v-for="account in accountGroup"
            :key="account.name"
            :account="account"
          ></account-group-row>
          <total-row
            :account-group="accountGroup"
          ></total-row>
        </tbody>
      </table>
    </div>
    <issues-table
      :problem-wire-requests="problemWireRequests"
      :accounts="accountGroup"
    >
    </issues-table>
  </div>
</template>

<script>
import AccountGroupRow from "./account_group_row";
import TotalRow from "./total_row";
import IssuesTable from "./issues_table";

export default {
  props: {
    accountType: Object,
    accountGroup: Array,
    problemWireRequests: Array,
  },
  components: {
    AccountGroupRow,
    TotalRow,
    IssuesTable,
  },
};
</script>
