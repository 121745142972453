<template>
  <div>
    <h4 class="h4">
      <b>Reallocate Amounts</b>
    </h4>

    <h5 class="h5">
      <b>Transaction {{ this.$route.params.id }}</b>
    </h5>
    <reallocate-amounts-container :cash-receipt-item-id="this.$route.params.id" />
  </div>
</template>

<script>
import ReallocateAmountsContainer from "./reallocate_amounts_container";

export default {
  components: {
    ReallocateAmountsContainer,
  },
};
</script>
