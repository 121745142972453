import AjaxService from "services/ajax_service";

export default class LoanRestrictionsService {
  constructor(url) {
    this.url = url;
  }
  load(context) {
    return AjaxService.get(this.url).then(data => {
      context.loanRestrictions = data.restrictions;
    });
  }
}
