<template>
  <div class="lender-zip-report-details">
    <h3>
      Price Index
    </h3>
    <div class="lender-section">
      <analytics-table :analytics="analytics"></analytics-table>
    </div>
    <div class="lender-section">
      <home-index-chart :sources="charts.homeIndex"></home-index-chart>
    </div>
    <div class="lender-section">
      <cost-of-homeownership-chart
        :sources="charts.costOfHomeownership"
      ></cost-of-homeownership-chart>
    </div>
    <h3>
      ZIP Code Profile
    </h3>
    <div class="lender-section">
      <zip-at-a-glance :table="tables.zipAtGlance"></zip-at-a-glance>
    </div>
    <div v-if="analytics.clearCapitalZip.isSuccessful" class="lender-section">
      <h4 class="details-section-title">REO Saturation</h4>
      <div class="details-section-body">
        <market-data-details :market-data="terminusData"></market-data-details>
      </div>
    </div>
    <div class="lender-section">
      <market-action-median-chart :sources="charts.marketActionMedian"></market-action-median-chart>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="lender-section">
          <listing-stats-chart :sources="charts.inventoryTotal"></listing-stats-chart>
        </div>
      </div>
      <div class="col-md-6">
        <div class="lender-section">
          <listing-stats-chart :sources="charts.estimatedSalesTotal"></listing-stats-chart>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="lender-section">
          <listing-stats-chart :sources="charts.daysOnMarketMedian"></listing-stats-chart>
        </div>
      </div>
      <div class="col-md-6">
        <div class="lender-section">
          <listing-stats-chart :sources="charts.monthsOfSupply"></listing-stats-chart>
        </div>
      </div>
    </div>
    <h3>Consumer Demographics</h3>
    <div class="row">
      <div class="col-md-6">
        <div class="lender-section">
          <rent-versus-ownership-chart
            :sources="charts.rentVersusOwnership"
          ></rent-versus-ownership-chart>
        </div>
      </div>
      <div class="col-md-6">
        <div class="lender-section">
          <occupied-versus-vacant-chart
            :sources="charts.occupiedVersusVacant"
          ></occupied-versus-vacant-chart>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="lender-section">
          <report-row-details :details="charts.householdGrowth"></report-row-details>
        </div>
      </div>
    </div>
    <h3>
      Housing Market
    </h3>
    <div class="row">
      <div class="col-md-6">
        <div class="lender-section">
          <housing-stock-value-chart
            :sources="charts.housingStockValue"
          ></housing-stock-value-chart>
        </div>
      </div>
      <div class="col-md-6">
        <div class="lender-section">
          <housing-stock-age-chart :sources="charts.housingStockAge"></housing-stock-age-chart>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="lender-section">
          <housing-stock-type-chart :sources="charts.housingStockType"></housing-stock-type-chart>
        </div>
      </div>
      <div class="col-md-6">
        <div class="lender-section">
          <housing-stock-size-chart :sources="charts.housingStockSize"></housing-stock-size-chart>
        </div>
      </div>
    </div>
    <div class="lender-disclaimer">
      <p>
        <span v-if="analytics.midasNational.isSuccessful">
          HouseCanary data as of
          {{ analytics.midasNational.completedAt | formatDate("MMMM D, YYYY") }}.
        </span>
        <span v-if="analytics.clearCapitalZip.isSuccessful">
          Clear Capital data as of
          {{ analytics.clearCapitalZip.completedAt | formatDate("MMMM D, YYYY") }}.
        </span>
      </p>
      <p>
        DISCLAIMER: The data presented above is provided by HouseCanary and Clear Capital and has
        not been reviewed or endorsed by PeerStreet. The information contained in this site is
        provided strictly for informational purposes only, is subject to change from time to time
        without notice to you, and should not be construed as advice on any subject matter
        whatsoever. You should not act or refrain from acting on the basis of any content included
        in this site without seeking legal or other professional advice.
      </p>
    </div>
  </div>
</template>

<script>
import AnalyticsTable from "components/market_data/analytics_table";
import HousingStockAgeChart from "components/market_data/housing_stock_age_chart";
import HousingStockSizeChart from "components/market_data/housing_stock_size_chart";
import HousingStockTypeChart from "components/market_data/housing_stock_type_chart";
import HousingStockValueChart from "components/market_data/housing_stock_value_chart";
import OccupiedVersusVacantChart from "components/market_data/occupied_versus_vacant_chart";
import RentVersusOwnershipChart from "components/market_data/rent_versus_ownership_chart";
import ZipAtAGlance from "components/market_data/zip_at_a_glance";
import MarketDataDetails from "components/market_data/market_data_details";
import HomeIndexChart from "components/market_data/home_index_chart";
import CostOfHomeownershipChart from "components/market_data/cost_of_homeownership_chart";
import ListingStatsChart from "components/market_data/listing_stats_chart";
import MarketActionMedianChart from "components/market_data/market_action_median_chart";

const Analytics = PS.Models.MarketData.Analytics;
const Charts = PS.Models.MarketData.Charts;
const Tables = PS.Models.MarketData.Tables;
const Event = PS.Models.Event;

export default Vue.component("zip-report-details", {
  components: {
    AnalyticsTable,
    HousingStockAgeChart,
    HousingStockSizeChart,
    HousingStockTypeChart,
    HousingStockValueChart,
    OccupiedVersusVacantChart,
    RentVersusOwnershipChart,
    ZipAtAGlance,
    MarketDataDetails,
    HomeIndexChart,
    CostOfHomeownershipChart,
    ListingStatsChart,
    MarketActionMedianChart,
  },
  props: {
    zip: String,
    source: String,
  },
  data() {
    return {
      terminusData: undefined,
      terminusError: undefined,

      midasData: undefined,
      midasError: undefined,

      analytics: {
        clearCapitalZip: new Analytics.ClearCapitalZip(),
        midasZip: new Analytics.MidasZip(),
        midasMsa: new Analytics.MidasMsa(),
        midasNational: new Analytics.MidasNational(),
      },

      tables: {
        zipAtGlance: new Tables.ZipAtGlance(),
      },

      charts: {
        homeIndex: new Charts.ZipHomeIndex(),
        costOfHomeownership: new Charts.CostOfHomeownership(),
        marketActionMedian: new Charts.MarketActionMedian(),
        inventoryTotal: new Charts.ListingStats(
          "Inventory (Total Listings)",
          "inventoryTotal",
          "Single family inventory (total listings)"
        ),
        estimatedSalesTotal: new Charts.ListingStats(
          "Estimated Monthly Sales",
          "hcEstimatedSalesTotal",
          "Estimated single family monthly sales"
        ),
        daysOnMarketMedian: new Charts.ListingStats(
          "Days on Market",
          "daysOnMarketMedian",
          "Days on market (single family)"
        ),
        monthsOfSupply: new Charts.ListingStats(
          "Months of Supply",
          "hcMonthsOfInventoryMedian",
          "Months of single family supply"
        ),
        rentVersusOwnership: new Charts.RentVersusOwnership(),
        occupiedVersusVacant: new Charts.OccupiedVersusVacant(),
        householdGrowth: new Charts.HouseholdGrowth(),
        housingStockValue: new Charts.HousingStockValue("zipCrossSection"),
        housingStockAge: new Charts.HousingStockAge("zipCrossSection"),
        housingStockType: new Charts.HousingStockType("zipCrossSection"),
        housingStockSize: new Charts.HousingStockSize("zipCrossSection"),
      },

      bus: PS.Services.MessageBusService.lenderBus,
      zipReportRulebook: undefined,
    };
  },
  mounted() {
    this.zipReportRulebook = new PS.Models.Reports.ZipReportRule(this);
    this.bus.attachRulebook(this.zipReportRulebook);

    this.loadReport(this.zip);
  },
  beforeDestroy: function() {
    this.bus.detachRulebook(this.zipReportRulebook);
  },
  watch: {
    zip(newZip) {
      this.loadReport(newZip);
    },
  },
  methods: {
    loadReport(zip) {
      if (zip) {
        const event = new Event({ zip: zip, source: this.source });

        new PS.Models.Reports.Commands.ZipReport.RequestZipReportLoading(event, {}).execute();
      }
    },
  },
});
</script>
