<template>
  <table class="table bank-account-table">
    <thead>
      <tr>
        <th>Source of Capital</th>
        <th>Required</th>
        <th>Available</th>
        <th>Remainder</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="bankAccount in sortedBankAccounts">
        <bank-account-list-item
          :bankAccount="bankAccount"
          :fundingEntitiesByName="fundingEntitiesByName"
          :key="bankAccount.name"
        />
      </template>
    </tbody>
  </table>
</template>

<script>
import BankAccountListItem from "components/manage/funding_entities/bank_account_list_item";

export default {
  props: {
    bankAccounts: Array,
    fundingEntitiesByName: Object,
  },
  components: {
    BankAccountListItem,
  },
  computed: {
    sortedBankAccounts() {
      return this.bankAccounts.sort((a, b) => {
        if (!a.name || !a.accountNumber) return 1;
        if (!b.name || !b.accountNumber) return -1;
        return a.name.localeCompare(b.name);
      });
    },
  },
};
</script>
