export default class Event {
  constructor(options = {}) {
    this.options = options;
    this.prepareData(options);
  }

  prepareData(options) {}
}

PS.Models.Event = Event;
