<template>
  <view-panel class="loan-details-view-panel">
    <template slot="header">
      Background Review
    </template>
    <div class="row loan-details-view-panel-row">
      <div class="col-6 loan-details-view-panel-column">
        <base-date-field
          id="bankruptcy-date"
          v-model="party.bankruptcyDate"
          label="Most Recent Bankruptcy Date"
          :max-date="maxAllowedDate"
          horizontal
        />
        <base-date-field
          id="foreclosure-date"
          v-model="party.foreclosureDate"
          label="Most Recent Foreclosure Date"
          :max-date="maxAllowedDate"
          horizontal
        />
      </div>
    </div>
  </view-panel>
</template>
<script>
import ViewPanel from "components/view_panel.vue";

export default {
  components: {
    ViewPanel,
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  computed: {
    maxAllowedDate() {
      return new Date();
    },
  },
};
</script>
