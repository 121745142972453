<template>
  <div class="servicing-generate-section" v-cloak>
    <div class="servicing-generate-section-header">
      <div class="servicing-generate-section-title-container">
        <base-header size="medium">PeerStreet Repurchase Statement</base-header>
        <div class="servicing-generate-section-sub" v-if="!hasStatements">
          No repurchases generated
        </div>
        <div class="payoff-statement-sub" v-else>
          <span>{{ lastStatement.statementTypeText }}</span>
          <span v-if="hasCompletedStatement">• Loan purchased on {{ completedStatement.lastUpdated }}</span>
        </div>
      </div>
    </div>
    <div class="finops-item-list" v-if="hasStatements">
      <table class="table">
        <thead>
          <tr>
            <th class="revision">Statement #</th>
            <th class="last-updated">Last Updated</th>
            <th class="wire-amount">Net Wire Amount</th>
            <th class="status-header">Status</th>
            <th class="note">Notes</th>
            <th class="pdf">PDF</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in statements" :key="item.id" @click="show(item)">
            <td>{{ item.versionNumber }}</td>
            <td>{{ item.lastUpdated }}</td>
            <td>{{ item.wireTargetDateAmount | accounting }}</td>
            <td>
              <span :class="statusClass(item)" class="">
                {{ item.statusLabel }}
              </span>
            </td>
            <td class="note-content">
              <repurchase-truncated-text :value="notes(item.comments)" />
            </td>
            <td>
              <span @click.stop="openPdfDocument(item)">
                <i class="download" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <a v-if="canGenerate" :href="newRepurchaseStatementRoute">
      + Generate PeerStreet Repurchase Statement
    </a>
  </div>
</template>

<script>
import RepurchaseTruncatedText from "components/manage/payoff_statement/repurchase/truncated_text";
import PayoffStatementTableMixin from "components/manage/payoff_statement/table_mixin";
import get from "lodash/get";

const STATUS = {
  completed: "purchased",
};

export default {
  name: "repurchase-statement-index",
  mixins: [PayoffStatementTableMixin],
  components: {
    RepurchaseTruncatedText,
  },
  data() {
    return {
      statements: PSData.repurchaseStatements,
      statementPolicy: PSData.repurchaseStatementPolicy || {},
      newRepurchaseStatementRoute: get(PSData, "routes.repurchaseStatementNew"),
    };
  },
  methods: {
    notes(comments) {
      return get(comments[0], "comment");
    },
  },
  computed: {
    completedStatement() {
      const statement = this.statements[0];

      if (statement && statement.status === STATUS.completed) {
        return statement;
      }
    },
  },
};
</script>
