const Source = PS.Models.MarketData.Charts.Source;

class Midas extends Source {
  constructor(...args) {
    super(...args);

    this.recordedAt = this.points[0] && new Date(this.points[0].recordedAt);
  }

  pointType(point) {
    return new Date(point[this.dateKey]) <= this.recordedAt ? "observed" : "predicted";
  }
}

PS.Models.MarketData.Charts.Midas = Midas;
