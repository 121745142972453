<template>
  <div class="report-banner msa-report-banner">
    <h2 class="title">
      MSA Report
    </h2>
    <autocomplete
      placeholder="Enter MSA Name"
      :data-source="dataSource"
      :min-chars-to-search="2"
      :transfer-search="true"
    />
  </div>
</template>

<script>
export default Vue.component("msa-report-banner", {
  data() {
    return {
      dataSource: new PS.Models.Autocomplete.Datasources.MsaList(),
    };
  },
});
</script>
