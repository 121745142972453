import { render, staticRenderFns } from "./creditbox_tab.vue?vue&type=template&id=d81d0d18&scoped=true&"
import script from "./creditbox_tab.vue?vue&type=script&lang=js&"
export * from "./creditbox_tab.vue?vue&type=script&lang=js&"
import style0 from "./creditbox_tab.vue?vue&type=style&index=0&id=d81d0d18&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d81d0d18",
  null
  
)

export default component.exports