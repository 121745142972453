<template>
  <form class="loan-tasks-custom-form">
    <div>
      <label>Started At:&nbsp;</label>
      <flat-pickr v-model="startedAt" :config="datePickerConfig" class="form-input" />
    </div>
    <div class="loan-task-buttons-container loan-task-buttons">
      <button class="btn btn-link loan-task-btn" @click.prevent="cancel">
        Cancel
      </button>
      <button
        class="btn btn-action-cta loan-task-btn"
        :disabled="!canSubmit"
        @click.prevent="submitForm"
      >
        <slot name="submit-text" />
      </button>
    </div>
  </form>
</template>

<script>
import FlatPickr from "vue-flatpickr-component";

export default {
  name: "sla-task-form",
  components: { FlatPickr },
  props: {
    task: {
      type: Object,
    },
    taskType: String,
  },
  data() {
    return {
      startedAt: this.task.startedAt,
      datePickerConfig: {
        dateFormat: "m/d/Y h:i K",
        enableTime: true,
        static: true,
      },
    };
  },
  computed: {
    canSubmit() {
      return this.startedAt !== this.task.startedAt;
    },
  },
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    updateStartedAt(value) {
      this.startedAt = value;
    },
    submitForm() {
      this.$emit("form-submitted", {
        started_at: this.startedAt,
      });
    },
  },
};
</script>
