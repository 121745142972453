<template>
  <diligence-document-field :document="document" :field="field">
    <div class="col-5">
      <textarea
        :id="field.id"
        v-model="field.value"
        class="form-control"
        :disabled="disabled"
        @blur="saveValueImmediately"
      ></textarea>
    </div>
  </diligence-document-field>
</template>

<script>
import TextField from "./base/text_field";

const TextareaField = TextField.extend({});

export default Vue.component("diligence-document-textarea-field", TextareaField);
</script>
