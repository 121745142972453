<template>
  <select-input
    label="Legal Owner"
    name="legalOwnerId"
    rules="required"
    :value="value"
    :options="options"
    :disabled="disabled || options.length < 2"
    v-on="$listeners"
  />
</template>

<script>
import SelectInput from "./select_input";

export default {
  components: {
    SelectInput,
  },
  props: {
    saleType: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    disabled: Boolean,
  },
  computed: {
    options() {
      return PSData.legalOwnerOptions[this.saleType] || [];
    },
  },
  watch: {
    saleType() {
      this.updateSelection();
    },
  },
  methods: {
    updateSelection() {
      if (this.options.length == 1) {
        this.$emit("input", this.options[0].id.toString());
      } else {
        this.$emit("input", "");
      }
    },
  },
};
</script>
