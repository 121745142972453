const Events = PS.Models.Loan.Events;
const Commands = PS.Models.Loan.Commands;
const Rule = PS.Models.Rule;

class SeriesSummaryRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.Series.ListUpdated:
        return this.whenRefreshRequested(event);
      case Events.SeriesSummary.RefreshRequested:
        return this.whenRefreshRequested(event);
    }

    return super.check(event);
  }

  whenRefreshRequested(event) {
    return [
      new Commands.SeriesSummary.StartLoading(event, this.state),
      new Commands.SeriesSummary.LoadFromServer(event, this.state),
      new Commands.SeriesSummary.FinishLoading(event, this.state),
    ];
  }
}

PS.Models.Loan.SeriesSummaryRule = SeriesSummaryRule;
