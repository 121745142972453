<template>
  <div>
    <base-table
      ref="table"
      :data="tableDistributionData"
      :loading="loading"
      detailed
      detail-key="owner"
      class="mb-5 mt-5"
    >
      <base-column v-slot="props" field="owner" label="Owner">
        {{ props.row.owner }}
      </base-column>

      <base-column v-slot="props" field="principal" label="Principal">
        <base-currency-field
          v-model="props.row.principal"
          :disabled="isColumnDisabled('principal')"
          @keyup="emit('update:props.row.principal', props.row.principal);"
        />
      </base-column>

      <base-column v-slot="props" field="supplemental_payment" label="Supplemental Payment">
        <base-currency-field
          v-model="props.row.supplemental_payment"
          :disabled="isColumnDisabled('supplemental_payment')"
          @keyup="emit('update:props.row.supplemental_payment', props.row.supplemental_payment);"
        />
      </base-column>

      <base-column v-slot="props" field="interest" label="Interest">
        <base-currency-field
          v-model="props.row.interest"
          :disabled="isColumnDisabled('interest')"
          @keyup="emit('update:props.row.interest', props.row.interest);"
        />
      </base-column>

      <base-column v-slot="props" field="default_interest" label="Default Interest">
        <base-currency-field
          v-model="props.row.default_interest"
          :disabled="isColumnDisabled('default_interest')"
          @keyup="emit('update:props.row.default_interest', props.row.default_interest);"
        />
      </base-column>

      <base-column v-slot="props" field="deferred_interest" label="Deferred Interest">
        <base-currency-field
          v-model="props.row.deferred_interest"
          :disabled="isColumnDisabled('deferred_interest')"
          @keyup="emit('update:props.row.deferred_interest', props.row.deferred_interest);"
        />
      </base-column>

      <base-column v-slot="props" field="prepaid_interest" label="Prepaid Interest">
        <base-currency-field
          v-model="props.row.prepaid_interest"
          :disabled="isColumnDisabled('prepaid_interest')"
          @keyup="emit('update:props.row.prepaid_interest', props.row.prepaid_interest);"
        />
      </base-column>

      <base-column v-slot="props" field="fees" label="Fees Total">
        <base-currency-field disabled :value="feesTotal(props.row.fees)" />
      </base-column>

      <template v-slot:detail="{ props }">
        <template v-if="!props.row.fees.length">
          <div class="columns is-flex-direction-row-reverse">
            <div class="column is-3">
              <b-message>
                No fees
              </b-message>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="row justify-content-end">
            <div class="col-6 fees-title">
              Fees
            </div>
          </div>
          <div v-for="fee in props.row.fees" :key="fee.cash_receipt_item_detail.id">
            <div class="columns">
              <div class="column is-6" />
              <div class="column is-3">
                <base-text-field disabled :value="fee.cash_receipt_item_detail.fee_type.label" />
              </div>
              <div class="column is-3">
                <base-currency-field
                  v-model="fee.amount"
                  @keyup="emit('update:fee.amount', fee.amount);"
                />
              </div>
            </div>
          </div>
        </template>
      </template>

      <template slot="footer">
        <td />
        <td class="text-center font-weight-bold">
          <b>Totals</b>
        </td>
        <td class="text-center font-weight-bold">
          <span :class="columnTotalClass('principal')">{{ userColumnTotals.principal }}</span>
        </td>
        <td class="text-center font-weight-bold">
          <span :class="columnTotalClass('supplemental_payment')">{{ userColumnTotals.supplemental_payment }}</span>
        </td>
        <td class="text-center font-weight-bold">
          <span :class="columnTotalClass('interest')">{{ userColumnTotals.interest }}</span>
        </td>
        <td class="text-center font-weight-bold">
          <span :class="columnTotalClass('default_interest')">{{ userColumnTotals.default_interest }}</span>
        </td>
        <td class="text-center font-weight-bold">
          <span :class="columnTotalClass('deferred_interest')">{{ userColumnTotals.deferred_interest }}</span>
        </td>
        <td class="text-center font-weight-bold">
          <span :class="columnTotalClass('prepaid_interest')">{{ userColumnTotals.prepaid_interest }}</span>
        </td>
        <td class="text-center font-weight-bold">
          <span :class="originalFeesTotal != userFeeColumnTotal ? 'text-danger' : ''">
            {{ userFeeColumnTotal }}
          </span>
        </td>
      </template>
    </base-table>

    <div class="row mb-5">
      <div class="col-3">
        <b-select
          expanded
          placeholder="Select a reason..."
          @input="onSelectReason($event)"
          @blur="onBlurReason()"
        >
          <option v-for="reason in reasons" v-bind:key="reason.id" :value="reason.id">
            {{ reason.description }}
          </option>
          <option :value="0">
            Other
          </option>
        </b-select>
      </div>
      <div v-if="selectedReasonId === 0" class="col-3 form-group">
        <base-text-field placeholder="Enter reason..." @input="onInputCustomReason($event)" :value="customReason"/>
      </div>
    </div>

    <template v-if="!loading">
      <div
        v-for="errorMessage in columnErrors"
        :key="errorMessage"
        class="alert alert-danger"
      >
        {{ errorMessage }}
      </div>
    </template>

    <template v-if="reasonMissing && showReasonError">
      <div class="alert alert-danger">
        Please provide a reason for reallocating this transaction.
      </div>
    </template>

    <div class="container-fluid">
      <div class="float-right">
        <base-button type="text" @click="redirectToReadyPage">
          Cancel
        </base-button>
        <base-button type="primary" :disabled="!canSubmitForm" @click="reallocateAmounts">
          Reallocate Amounts
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import AjaxServiceWithNotifications from "services/ajax_service";
import AjaxService, { SUCCESS_STATUS, ERROR_STATUS, ERROR_MESSAGE } from "services/ajax_service";
import startCase from "lodash/startCase";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import Big from "big.js";
import { parseNumericString } from "./utils";

export default {
  props: [
    "submitting",
    "loading",
    "tableDistributionData",
    "loanPsId",
    "ownerToId",
    "distributionToId",
    "originalFeesTotal",
    "originalColumnTotals",
    "userFeeColumnTotal",
    "userColumnTotals",
    "cashReceiptItemId",
    "reasons",
    "selectedReasonId",
    "customReason",
    "showReasonError",
  ],
  computed: {
    canSubmitForm: function() {
      return (
        Object.keys(this.originalColumnTotals).every(name => {
          return this.withinTwoCents(this.userColumnTotals[name], this.originalColumnTotals[name]);
        }) &&
        this.withinTwoCents(this.originalFeesTotal, this.userFeeColumnTotal) &&
        !this.reasonMissing &&
        !this.submitting &&
        this.formHasChanged
      );
    },
    columnErrors: function() {
      var errors = [];

      Object.keys(this.originalColumnTotals).forEach(col => {
        const currentTotal = this.userColumnTotals[col];
        const originalTotal = this.originalColumnTotals[col];

        if (!this.withinTwoCents(originalTotal, currentTotal)) {
          errors.push(`
          ${startCase(col)} total is ${currentTotal} but should be ${originalTotal}
          (Difference ${(originalTotal - currentTotal).toFixed(2)})
          `);
        }
      });

      return errors;
    },
    reasonMissing: function() {
      const noReasonSelected = this.selectedReasonId === null;
      const otherReasonSelected = this.selectedReasonId === 0;
      const customReasonMissing = this.customReason === null;
      const customReasonEmpty = this.customReason === "";
      return (
        noReasonSelected || (otherReasonSelected && (customReasonMissing || customReasonEmpty))
      );
    },
  },
  watch: {
    tableDistributionData: {
      handler(newValue, oldValue) {
        if (this.dataLoaded) {
          this.formHasChanged = !isEqual(newValue, this.originalData);
        } else {
          this.originalData = cloneDeep(newValue);
          this.dataLoaded = true;
        }
      },
      deep: true,
    },
  },
  data: function() {
    return {
      dataLoaded: false,
      formHasChanged: false,
      originalData: {},
    };
  },
  methods: {
    onSelectReason(reasonId) {
      this.$emit("onSelectReason", reasonId);
    },
    isColumnDisabled(columnName) {
      return this.originalColumnTotals[columnName] == 0;
    },
    anyColumnChanged(columnName) {
      return this.originalColumnTotals[columnName] == 0;
    },
    onInputCustomReason(reason) {
      this.$emit("onInputCustomReason", reason.trim());
    },
    onBlurReason() {
      this.$emit("onShowReasonError", true);
    },
    redirectToReadyPage() {
      window.location.href = `/manage/cash_receipts/ready?transactionType=all&fromDate=&toDate=&loanId=${
        this.loanPsId
      }`;
    },
    columnTotalClass(columnName) {
      if (
        !this.withinTwoCents(
          this.userColumnTotals[columnName],
          this.originalColumnTotals[columnName]
        )
      ) {
        return "text-danger";
      }
    },
    feesTotal(fees) {
      return fees.reduce((total, fee) => {
        return new Big(total).add(new Big(fee.amount)).toFixed(2);
      }, 0);
    },
    withinTwoCents(firstAmount, secondAmount) {
      return Math.abs(firstAmount - secondAmount) <= 0.02;
    },
    formatPostRequestBody() {
      let result = [];

      this.tableDistributionData.forEach(distribution => {
        Object.keys(this.originalColumnTotals).forEach(column => {
          let entry = {};
          if (distribution.hasOwnProperty(column) && distribution[column] != 0) {
            entry.amount = distribution[column];
            entry.cash_receipt_item_detail = {
              id: this.distributionToId[column],
            };
            entry.participant = {
              id: this.ownerToId[distribution.owner].id,
              type: this.ownerToId[distribution.owner].type,
              series_id: this.ownerToId[distribution.owner].seriesId,
            };
            result.push(entry);
          }
        });

        distribution.fees.forEach(fee => {
          if (fee.amount == 0) {
            return;
          }

          let entry = {
            amount: fee.amount,
            cash_receipt_item_detail: {
              id: fee.cash_receipt_item_detail.id,
            },
            participant: {
              id: this.ownerToId[distribution.owner].id,
              type: this.ownerToId[distribution.owner].type,
              series_id: this.ownerToId[distribution.owner].seriesId,
            },
          };

          result.push(entry);
        });
      });

      return result;
    },
    async reallocateAmounts() {
      if (this.reasonMissing) {
        this.$emit("onShowReasonError", true);
        return;
      }

      try {
        this.$emit("update:loading", true);
        this.$emit("update:submitting", true);
        await this.deleteExistingDistributions();
        await this.createNewDistributions();
        if (this.selectedReasonId === 0) {
          const result = await this.createReason();
          await this.createCashReceiptItemModificationReason(result.id);
        } else {
          await this.createCashReceiptItemModificationReason(this.selectedReasonId);
        }
        this.handleSuccess();
      } catch (err) {
        this.handleError(err);
        this.$emit("update:submitting", true);
      } finally {
        this.$emit("update:loading", false);
      }
    },
    deleteExistingDistributions() {
      const url = `/manage/distributions/clear_all?cash_receipt_item_id=${this.cashReceiptItemId}`;
      let headers = { "content-type": "application/json" };
      return AjaxServiceWithNotifications.delete(url, {}, headers);
    },
    createNewDistributions() {
      const url = "/manage/distributions";
      let data = this.formatPostRequestBody();
      let headers = { "content-type": "application/json" };
      return AjaxServiceWithNotifications.postJSON(url, data, headers);
    },
    createReason() {
      const url = "/manage/modification_reasons";
      let data = {
        description: this.customReason,
        type: "ManualAllocationReason",
      };
      let headers = { "content-type": "application/json" };
      return AjaxServiceWithNotifications.postJSON(url, data, headers);
    },
    createCashReceiptItemModificationReason(reasonId) {
      const url = "/manage/cash_receipt_item_modification_reasons";
      let data = {
        modification_reason_id: reasonId,
        cash_receipt_item_id: this.cashReceiptItemId,
      };
      let headers = { "content-type": "application/json" };
      return AjaxServiceWithNotifications.postJSON(url, data, headers);
    },
    handleSuccess() {
      AjaxService.sendNotification("Successfully reallocated amounts.", SUCCESS_STATUS);
      this.redirectToReadyPage();
    },
    handleError(error) {
      let msg = error.response.status === 422 ? error.data.error.message : ERROR_MESSAGE;
      AjaxService.sendNotification(msg, ERROR_STATUS);
    },
  },
};
</script>
<style scoped>
.fees-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
</style>
