<template>
  <div class="lender-legal-entity">
    <div class="d-flex justify-content-between align-items-center">
      <breadcrumb-navigation :items="breadcrumbItems()"></breadcrumb-navigation>
    </div>

    <h3 class="lender-entities-header">{{ entity.fullName }}</h3>
    <entity-summary-report />

    <hr />
    <h3 class="lender-entities-header">
      Entity Information
      <span class="lender-entities-sub-header">Last edited {{ entity.lastEditedAt }} &bull; </span>
      <a :href="routes.lenderLegalEntityHistory">View History</a>
    </h3>
    <div class="row view-panel-row">
      <div class="col-6">
        <entity-panel :entity="entity" :policy="policy" />
      </div>
      <div class="col-6">
        <master-agreements-panel :entity="entity" :policy="policy" />
      </div>
    </div>
    <div class="row view-panel-row">
      <div class="col-6">
        <entity-documents-panel :policy="policy" />
      </div>
      <div class="col-6">
        <signers-panel :routes="signersRoutes" :policy="policy" />
      </div>
    </div>
    <div class="row view-panel-row">
      <div class="col-6">
        <fictitious-names-panel :routes="fictitiousNamesRoutes" :policy="policy" />
      </div>
      <div class="col-6">
        <a-c-h-details-panel :ach-details="entity.achDetails" :policy="policy" />
      </div>
    </div>
    <hr />
    <div class="row view-panel-row">
      <div class="col-6">
        <lender-legal-entity-state-panel :policy="policy" />
      </div>
    </div>
  </div>
</template>

<script>
import Ajax from "services/ajax_service";
import LegalEntity from "models/lender_legal_entities/entity";
import BreadcrumbNavigation from "../shared/breadcrumb_navigation";
import EntityPanel from "./entity/show_panel.vue";
import EntitySummaryReport from "./entity_summary/report.vue";
import LenderLegalEntityStatePanel from "./state/show_panel";
import EntityDocumentsPanel from "./entity_document/show_panel";
import MasterAgreementsPanel from "./master_agreement/show_panel";
import SignersPanel from "./signer/show_panel";
import ACHDetailsPanel from "./ach_details/show_panel";
import FictitiousNamesPanel from "./fictitious_name/show_panel";
import MessageBusService from "services/message_bus_service";

export default Vue.component("lender-legal-entity", {
  components: {
    BreadcrumbNavigation,
    EntityPanel,
    EntitySummaryReport,
    LenderLegalEntityStatePanel,
    EntityDocumentsPanel,
    MasterAgreementsPanel,
    SignersPanel,
    ACHDetailsPanel,
    FictitiousNamesPanel,
  },
  data: function() {
    return {
      entity: new LegalEntity(PSData.lenderLegalEntity, PSData.lenderLegalEntityOptions),
      entityReport: PSData.lenderLegalEntityReport,
      routes: PSData.routes || {},
      policy: PSData.policy,
      bus: MessageBusService.entityBus,
    };
  },
  computed: {
    reportHasData() {
      return !!this.entityReport.states.length;
    },
    signersRoutes() {
      return { signersPath: this.routes.signers };
    },
    fictitiousNamesRoutes() {
      return { fictitiousNamesPath: this.routes.fictitiousNames };
    },
  },
  mounted() {
    this.bus.subscribe(this.onEntityUpdated);
  },
  methods: {
    breadcrumbItems() {
      return [
        {
          name: "Entities",
          link: this.routes.lenderLegalEntities,
        },
        {
          name: this.entity.fullName,
        },
      ];
    },
    onEntityUpdated() {
      Ajax.getJSON(this.routes.lenderLegalEntity).then(data => this.entity.update(data.entity));
    },
  },
});
</script>
