<template>
  <table class="table loan-associations-table loan-series-table">
    <thead>
      <tr>
        <th>ID</th>
        <th>Type</th>
        <th class="number-cell">
          Initial Amount
        </th>
        <th class="number-cell">Unpaid Principal Balance</th>
        <th class="number-cell">
          PS Spread (Rate)
        </th>
        <th>Source of Capital</th>
        <th>Tranche</th>
        <th>MDPN</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Status</th>
        <th v-if="!disabled">Actions</th>
      </tr>
    </thead>
    <template v-for="series in sortedSeries">
      <loan-series-item
        :key="series.psId"
        :funding-entity-options="options.fundingEntities"
        :loan="loan"
        :mdpn-options="options.mdpn"
        :series-type-options="options.seriesTypes"
        :series="series"
        :tranche-options="options.tranche"
        :disabled="disabled"
        @remove-series="remove($event)"
        @update-series="update($event)"
      />
    </template>
    <tbody>
      <tr v-if="!disabled">
        <td>--</td>
        <td>
          <div class="form-group" :class="{'has-error': recordErrors.seriesType}">
            <select v-model="newSeries.seriesType" class="form-control" disabled>
              <option v-for="option in options.seriesTypes" :key="option.value" :value="option.value">
                {{ option.name }}
              </option>
            </select>
            <span v-for="error in recordErrors.seriesType" class="help-block">{{ error }}</span>
          </div>
        </td>
        <td>
          <div class="form-group currency" :class="{'has-error': recordErrors.amount}">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                v-model="newSeries.amount"
                v-mask:currency=""
                class="form-control"
                type="text"
              >
            </div>
            <span class="help-block">{{ getError("amount.0") }}</span>
          </div>
        </td>
        <td>--</td>
        <td>
          <div class="form-group currency" :class="{'has-error': recordErrors.ps_spread}">
            <div class="input-group">
              <input
                v-model="newSeries.psSpread"
                v-mask:percentage=""
                class="form-control"
                type="text"
              >
              <div class="input-group-append">
                <span class="input-group-text">%</span>
              </div>
            </div>
            <span class="help-block">{{ getError("ps_spread.0") }}</span>
          </div>
        </td>
        <td>
          <div class="form-group" :class="{'has-error': recordErrors.funding_entity}">
            <select v-model="newSeries.fundingEntityId" class="form-control" disabled>
              <option v-for="option in options.fundingEntities" :key="option.value" :value="option.value">
                {{ option.name }}
              </option>
            </select>
            <span v-for="error in recordErrors.funding_entity" class="help-block">{{ error }}</span>
          </div>
        </td>
        <td>
          <div class="form-group" :class="{'has-error': recordErrors.tranche}">
            <select v-model="newSeries.tranche" class="form-control" disabled>
              <option v-for="option in options.tranche" :key="option.value" :value="option.value">
                {{ option.name }}
              </option>
            </select>
            <span v-for="error in recordErrors.tranche" class="help-block">{{ error }}</span>
          </div>
        </td>
        <td>
          <div class="form-group" :class="{'has-error': recordErrors.mdpn}">
            <select v-model="newSeries.mdpn" class="form-control" disabled>
              <option v-for="option in options.mdpn" :key="option.value" :value="option.value">
                {{ option.name }}
              </option>
            </select>
            <span v-for="error in recordErrors.mdpn" class="help-block">{{ error }}</span>
          </div>
        </td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
        <td>
          <a
            class="btn-action-cta"
            tabindex="0"
            :class="{ 'btn-disabled': addInProcess }"
            @click="create"
          >
            Add
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import get from "lodash/get";

const Commands = PS.Models.Loan.Commands;
const Event = PS.Models.Event;

const FRACTIONALIZED_ENTITY_NAME = "Fractionalized (INV)";

export default Vue.component("loan-series-table", {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    const defaultFundingEntityId = (
      PSData.seriesOptions.fundingEntities.find(entity => {
        return entity.name === FRACTIONALIZED_ENTITY_NAME;
      }) || { value: null }
    ).value;

    const newSeriesParams = {
      tranche: "a",
      seriesType: "sale",
      mdpn: true,
      fundingEntityId: defaultFundingEntityId,
    };

    return {
      loan: PSData.loan,
      options: PSData.seriesOptions,
      newSeries: new PS.Models.LoanSeries(newSeriesParams),
      series: PSData.series.map(x => {
        return new PS.Models.LoanSeries(x);
      }),
      addInProcess: false,
    };
  },
  computed: {
    sortedSeries: function() {
      return this.series.sort(PS.Models.LoanSeries.statusSort);
    },
    recordErrors: function() {
      return this.newSeries.errors;
    },
  },
  methods: {
    getError(field) {
      return get(this.recordErrors, field);
    },
    create: function() {
      if (this.addInProcess) return;

      this.addInProcess = true;
      PS.Services.SeriesService.create(this.loan, this.newSeries)
        .then(data => {
          this.newSeries.reset();
          this.addToList(new PS.Models.LoanSeries(data));
          this.broadcastUpdate();
        })
        .catch(({ data }) => {
          this.newSeries.errors = data.error;
        })
        .finally(() => {
          this.addInProcess = false;
        });
    },
    update: function(series) {
      PS.Services.SeriesService.update(this.loan, series)
        .then(data => {
          series.errors = {};
          this.updateSeries(series, data);
        })
        .catch(({ data }) => {
          series.errors = data.error;
        });
    },
    remove: function(series) {
      PS.Services.SeriesService.destroy(this.loan, series)
        .then(data => {
          this.updateSeries(series, data);
        })
        .catch(({ data }) => {
          series.errors = data.error;
        });
    },
    addToList: function(series) {
      this.series.push(series);
    },
    replaceInList: function(series, newSeries) {
      var index = this.series.indexOf(series);
      Vue.set(this.series, index, newSeries);
    },
    removeFromList: function(series) {
      Vue.delete(this.series, this.series.indexOf(series));
    },
    updateSeries: function(series, data) {
      var updatedSeries = new PS.Models.LoanSeries(data);
      this.replaceInList(series, updatedSeries);
      this.broadcastUpdate();
    },
    broadcastUpdate: function() {
      new Commands.Series.BroadcastListUpdate(new Event(), {}).execute();
    },
  },
});
</script>
