<template>
  <div class="loan-guide">
    <template v-if="!loanForm">
      <h4>Program Options</h4>

      <template v-if="lenderOnly">
        <p v-if="isComplete"><b>Note:</b> Estimated pricing may change prior to loan approval and remains subject to PeerStreet's due diligence.</p>

        <p v-else>Complete required sections on the new loan form to view available options.</p>
      </template>
    </template>

    <template v-else>
      <div>
        <h3>Select an Option</h3>
        <p class="hint">Complete all fields to generate your program options.</p>

        <div class="loan-guide-progress">
          <progress max="100" :value="completedPercentage" :class="{complete: isComplete}"></progress>
          <span>
            {{completedPercentage | percent(0)}} Ready
          </span>
        </div>

        <p v-if="showResults" class="submition-note"><b>Note:</b> Estimated pricing may change prior to loan approval and remains subject to PeerStreet's due diligence.</p>

        <template v-if="!isEvaluating && !isDraft">
          <input type="button"
                 class="btn-action-cta-sm"
                 :value="showCaption"
                 :disabled="showOptionsIsDisabled"
                 @click="onShowOptions"
                 :class="{ 'blocked': !hasProgramType }"/>

          <p class="note">We will save your draft.</p>
        </template>
      </div>
    </template>

    <div v-show="hasErrors || ((isDraft || isEvaluating) && !resultsReady && isEstimating)">
      <p class="error-message">
        Your options will appear here as soon as they are available
        <span v-if="isComplete && isEstimating" class="ps-loader ps-loader-small"></span>
      </p>
      <div v-show="!hasErrors" class="options-placeholder"></div>
      <base-message v-show="hasErrors" type="danger" title="Error" has-icon icon="times-circle" :closable="false">
        There was an error loading program options. Please try again later.
      </base-message>
    </div>

    <template v-if="showResults && resultsReady">
      <template v-for="(result, index) in results">
        <program-option
          v-if="isDraft || (isEvaluating && result.loanProgram.programType === programClassification)"
          :key="index"
          :option-id="index"
          :option="result"
          :is-estimating="isEstimating"
          :loan-form="loanForm"
          :underwriting-status="underwritingStatus"
          :submit-to-evaluation-disabled="submitToEvaluationDisabled"
          :view-lps-results="viewLpsResults"
          :ui-blocked="uiBlocked"
          :displayFor="displayFor"
          :is-form-completed="isComplete"
          @select="$emit('loan-guide-program-option-selected', result.loanProgram)"
          @cancel="$emit('loan-guide-program-option-cancel')"/>
      </template>
    </template>

    <p v-if="!hideGuidelines">
      <a href="https://peerstreet.zendesk.com/hc/en-us" target="_blank">View Credit Box, Pricing Sheets, and Diligence Requirements</a>
    </p>
  </div>
</template>

<script>
import ProgramOption from "components/loan_guide/program_option";
import LocationService from "services/location_service";

const HELPDESK_URL = "https://peerstreet.zendesk.com/hc/en-us/articles";
const GUIDELINES = {
  creditbox: `${HELPDESK_URL}/360018533752-Residential-Bridge-1-4-Units-Credit-Box`,
  diligence: `${HELPDESK_URL}/360035528511-Residential-Bridge-Underwriting-Guidelines`,
  pricing: `https://drive.google.com/file/d/1HBDhL4NvrgQ_kOtii_XSEdBR0AxODx5K/view`,
};

export default {
  name: "loan-guide",
  components: {
    ProgramOption,
  },
  props: {
    programOptions: Array,
    completedPercentage: {
      type: Number,
      default: 0,
    },
    underwritingStatus: String,
    isEstimating: Boolean,
    hasErrors: {
      type: Boolean,
      default: false,
    },
    uiBlocked: Boolean,
    loanForm: {
      type: Boolean,
      default: false,
    },
    submitToEvaluationDisabled: {
      type: Boolean,
      default: false,
    },
    viewLpsResults: {
      type: Boolean,
      default: false,
    },
    displayFor: {
      type: String,
      default: "lender",
    },
    programClassification: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    resultsReady() {
      return this.programOptions?.length !== 0 && !this.isEstimating;
    },
    showResults() {
      return (
        (this.isComplete && this.resultsReady && (this.isDraft || this.isEvaluating)) ||
        this.analystOnly
      );
    },
    showCaption() {
      if (this.uiBlocked) {
        return "Generating...";
      } else {
        return "Show Options";
      }
    },
    showOptionsIsDisabled() {
      return this.uiBlocked || !this.hasProgramType;
    },
    hideGuidelines() {
      return (!this.isComplete && !this.loanForm) || this.analystOnly;
    },
    results() {
      return this.programOptions;
    },
    isComplete() {
      return this.completedPercentage === 100;
    },
    isDraft() {
      return this.underwritingStatus === "draft" && !this.newLoan;
    },
    isEvaluating() {
      return this.underwritingStatus === "evaluating";
    },
    newLoan() {
      return LocationService.pathname === "/loans/new";
    },
    lenderOnly() {
      return this.displayFor === "lender";
    },
    analystOnly() {
      return this.displayFor === "analyst";
    },
    hasProgramType() {
      return this.programClassification !== "";
    },
  },
  methods: {
    onShowOptions() {
      this.$emit("loan-guide-show-options");
    },
    guidelines(type) {
      return GUIDELINES[type];
    },
  },
};
</script>

<style scoped>
.loan-guide .error-message {
  padding-bottom: 8px;
}
.loan-guide .base-message {
  border: none;
}
.loan-guide .base-message >>> .icon .svg-inline--fa {
  font-size: 24px;
}
</style>
