<template>
  <div class="report-imports-filter">
    <label>Showing</label>
    <select2
      :value="selectedPeriod"
      class="form-control"
      :options="{ data: availablePeriods, placeholder: 'Select', multiple: false, width: 250 }"
      @input="handleInput"
    />
  </div>
</template>

<script>
import Select2 from "components/shared/select2";
const LocalStorageService = PS.Services.LocalStorageService;

const FILTER_KEY = "physical_doc_tracking_report_imports_period";

export default {
  name: "report-imports-filter",
  components: { Select2 },
  props: {
    reportableMonths: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    const availablePeriods = this.reportableMonths;
    const selectedPeriod = LocalStorageService.getItem(FILTER_KEY) || availablePeriods[0];

    return {
      availablePeriods,
      selectedPeriod,
    };
  },
  mounted() {
    return this.handleInput(this.selectedPeriod);
  },
  methods: {
    handleInput(value) {
      this.$emit("on-period-change", value);
      LocalStorageService.setItem(FILTER_KEY, value);
    },
  },
};
</script>
