class MsaReportRequested extends PS.Models.Event {
  get source() {
    return this.options.source;
  }

  get geoId() {
    return this.options.geoId;
  }

  get geoType() {
    return this.options.geoType;
  }
}

class TrackMsaReportLinkRequested extends PS.Models.Event {
  get zip() {
    return this.options.zip;
  }

  get source() {
    return this.options.source;
  }
}

class MidasDataChangeRequested extends PS.Models.Event {
  get midasData() {
    return this.options.state.midasData || {};
  }
}

PS.Models.Reports.Events.MsaReport = {
  MidasDataChangeRequested: MidasDataChangeRequested,
  MsaReportRequested: MsaReportRequested,
  TrackMsaReportLinkRequested: TrackMsaReportLinkRequested,
};
