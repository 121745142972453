<template>
  <div class="responsible-parties">
    <div class="responsible-parties-header">
      <h4>Loan Sponsors</h4>
      <div class="controls">
        <button class="btn btn-action-cta-sm" @click="addNewBorrower">
          + Add Borrower
        </button>
        <button
          v-tooltip
          class="btn btn-action-cta-sm"
          title="Add Loan Sponsor"
          @click="addNewParty"
        >
          + Add Loan Sponsor
        </button>
      </div>
    </div>
    <div class="responsible-parties-list">
      <template v-for="borrower in borrowers">
        <borrower :key="borrower.id" :borrower="borrower" @on-edit="editBorrower" />
        <div
          v-if="borrower.responsibleParties.length"
          :key="`nested-${borrower.id}`"
          class="nested-parties"
        >
          <responsible-party-component
            v-for="responsibleParty in borrower.responsibleParties"
            :key="responsibleParty.id"
            :responsible-party="responsibleParty"
            @edit-party="editParty"
          />
        </div>
      </template>
      <responsible-party-component
        v-for="responsibleParty in responsibleParties"
        :key="responsibleParty.id"
        :responsible-party="responsibleParty"
        :nested="false"
        @edit-party="editParty"
      />
    </div>
    <vesting-clause-preview
      v-if="showVestingClausePreview"
      :vesting-clause="fullBorrowerVestingClause"
      :disable-vesting-clause="disableVestingClause"
      @edit-vesting-clause="editVestingClause"
    />
    <add-responsible-party
      v-if="selectedParty"
      :show="showAddPartyModal"
      :responsible-party-id="selectedParty.id"
      :borrowers="borrowers"
      @close="closeAddPartyModal"
      @on-parties-update="onPartiesUpdate"
    />
    <add-borrower
      v-if="selectedBorrower"
      :show="showAddBorrowerModal"
      :borrower="selectedBorrower"
      @close="closeBorrowerModal"
      @on-parties-update="onPartiesUpdate"
    />
    <edit-vesting-clause-modal
      :show="showVestingClauseModal"
      :borrower-relationships="borrowerRelationships"
      :borrowers="borrowers"
      :vesting-clause="vestingClause"
      :vesting-clause-other="vestingClauseOther"
      :full-vesting-clause="fullBorrowerVestingClause"
      @on-save="onVestingClauseSave"
      @close="closeVestingClauseModal"
    />
  </div>
</template>

<script>
import AddBorrower from "./add_borrower";
import AddResponsibleParty from "./add_responsible_party";
import Borrower from "./borrower";
import EditVestingClauseModal from "./vesting_clause/edit_modal";
import isEmpty from "lodash/isEmpty";
import ResponsiblePartyComponent from "./responsible_party";
import ResponsibleParty from "models/responsible_party";
import ResponsibleBorrower from "models/responsible_borrower";
import VestingClausePreview from "./vesting_clause/preview";

export default {
  name: "responsible-parties",
  components: {
    AddResponsibleParty,
    AddBorrower,
    Borrower,
    EditVestingClauseModal,
    ResponsiblePartyComponent,
    VestingClausePreview,
  },
  data() {
    return {
      showVestingClauseModal: false,
      showAddPartyModal: false,
      showAddBorrowerModal: false,
      responsibleParties: PSData.responsibleParties,
      borrowers: PSData.borrowers,
      selectedParty: undefined,
      selectedBorrower: undefined,
      borrowerRelationships: PSData.borrowerRelationships,
      vestingClause: PSData.loan.titleVestingClause,
      vestingClauseOther: PSData.loan.titleVestingClauseOther,
      fullBorrowerVestingClause: PSData.loan.fullBorrowerVestingClause,
    };
  },
  computed: {
    showVestingClausePreview() {
      return this.borrowers.length != 0;
    },
    disableVestingClause() {
      return this.borrowers.some(borrower => {
        return isEmpty(borrower.legalName);
      });
    },
  },
  methods: {
    addNewParty() {
      this.showAddPartyModal = true;
      this.selectedParty = ResponsibleParty.buildEmpty();
    },
    addNewBorrower() {
      this.showAddBorrowerModal = true;
      this.selectedBorrower = new ResponsibleBorrower();
    },
    editBorrower(selectedBorrower) {
      this.showAddBorrowerModal = true;
      this.selectedBorrower = ResponsibleBorrower.deserialize(selectedBorrower);
    },
    editVestingClause() {
      this.showVestingClauseModal = true;
    },
    closeAddPartyModal() {
      this.selectedParty = undefined;
      this.showAddPartyModal = false;
    },
    closeBorrowerModal() {
      this.showAddBorrowerModal = false;
    },
    closeVestingClauseModal() {
      this.showVestingClauseModal = false;
    },
    editParty(party) {
      this.showAddPartyModal = true;
      this.selectedParty = party;
    },
    onPartiesUpdate(data) {
      this.borrowers = data.borrowers;
      this.responsibleParties = data.responsibleParties;
      this.fullBorrowerVestingClause = data.fullBorrowerVestingClause;
    },
    onVestingClauseSave(data) {
      this.borrowerRelationships = data.relationships;
      this.vestingClause = data.loan.titleVestingClause;
      this.vestingClauseOther = data.loan.titleVestingClauseOther;
      this.fullBorrowerVestingClause = data.loan.fullBorrowerVestingClause;
    },
  },
};
</script>
