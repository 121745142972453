import Segment from "services/segment_service";

$(() => {
  const isLender = PSData.isLender;

  new Vue({
    el: document.querySelector(".v-loans-index-controller"),
    mounted() {
      if (isLender) {
        const activeFundedLink = this.$el.querySelector("#active-funded-link");
        const withdrawnDeclinedLink = this.$el.querySelector("#withdrawn-declined-link");

        if (activeFundedLink) {
          activeFundedLink.addEventListener("click", () => {
            Segment.track("clickedActiveFundedTab");
          });
        }

        if (withdrawnDeclinedLink) {
          withdrawnDeclinedLink.addEventListener("click", () => {
            Segment.track("clickedWithdrawnDeclinedTab");
          });
        }
      }
    },
  });
});
