import isEmpty from "lodash/isEmpty";

export const STATUSES = {
  draft: "draft",
  pending: "pending",
  reviewed: "reviewed",
  approved: "approved",
  sold: "sold",
  purchased: "purchased",
  wiredShort: "wired_short",
  expired: "expired",
  cancelled: "cancelled",
};

export default {
  props: ["statementPolicy"],
  computed: {
    enabledOptions() {
      const orderBy = (button1, button2) => {
        return this.ordering.indexOf(button1.id) > this.ordering.indexOf(button2.id) ? 1 : -1;
      };

      return Object.values(this.buttons)
        .filter(button => button.enabled && this.ordering.includes(button.id))
        .sort(orderBy);
    },
    ordering() {
      return this.buttonsOrdering[this.statement.status] || [];
    },
    hasStatement() {
      return !isEmpty(this.statement);
    },
    isActionable() {
      return this.statement.status !== STATUSES.cancelled;
    },
    isEditable() {
      return this.statementPolicy.canUpdate();
    },
    isDuplicatable() {
      return this.statementPolicy.canDuplicate();
    },
    isMarkableAsReviewed() {
      return STATUSES.pending === this.statement.status && this.statementPolicy.canApprove();
    },
    isApprovable() {
      return STATUSES.reviewed === this.statement.status && this.statementPolicy.canApprove();
    },
    isSkippable() {
      return STATUSES.pending === this.statement.status && this.statementPolicy.canApprove();
    },
  },
  methods: {
    approve() {
      if (this.isApprovable) {
        this.$emit("transitionWithCheck", STATUSES.approved);
      }
    },
    submit() {
      if (this.isSubmittable) {
        this.$emit("transition", STATUSES.pending);
      }
    },
    refresh() {
      if (this.isRefreshable) {
        this.$emit("refresh");
      }
    },
    markAsReviewed() {
      if (this.isMarkableAsReviewed) {
        this.$emit("transitionWithCheck", STATUSES.reviewed);
      }
    },
    skipToApproved() {
      if (this.isSkippable) {
        this.$emit("transitionWithCheck", STATUSES.approved);
      }
    },
    cancel() {
      if (this.isCancelable) {
        this.$emit("cancel");
      }
    },
  },
};
