const Source = PS.Models.MarketData.Analytics.Source;

class RiskAnalysis extends Source {
  constructor() {
    super();

    this.marketData = undefined;
  }

  get name() {
    return "Risk Analysis Data";
  }

  success(marketData) {
    this.marketData = marketData;
    this.completedAt = marketData.recordedAt;

    this.forecastedCumulativeReturn["YoY"] = marketData.yearOverYear;
    this.forecastedCumulativeReturn["12"] = marketData.projected12;
    this.forecastedCumulativeReturn["24"] = marketData.projected24;

    let relevantMaxDecrease = marketData.maxDecreases.find(d => !!d.relevant);
    if (relevantMaxDecrease) {
      this.relevantMaxDecreaseMonth = parseInt(relevantMaxDecrease.month);
    }

    Object.keys(this.maxRollingDecline).forEach(month => {
      this.maxRollingDecline[month] = (
        marketData.maxDecreases.find(d => d.month === parseInt(month)) || {}
      ).value;
    });

    super.success();
  }
}

PS.Models.MarketData.Analytics.RiskAnalysis = RiskAnalysis;
