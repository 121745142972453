import moment from "moment";
import { SectionType } from "models/lender_form/fields";
import Section from "models/lender_form/section";
import { PROGRAM_CLASSIFICATION } from "../program";

const DATE_FORMAT = "MM/DD/YYYY";

export default class LoanSection extends Section {
  constructor(fields) {
    super(SectionType.Loan, fields);

    this.setTotalPrincipalPayments();
  }

  update() {
    this.checkLenderNoteStatus();
    this.checkCema();
    this.checkProgramType();
    this.setTotalPrincipalPayments();
    this.setLoanTerm();
    this.setOriginationDate();

    super.update();
  }

  checkCema() {
    if (this.showCema) {
      this.fields.cema.required = true;
    } else {
      this.fields.cema.required = false;
      this.fields.cema.value = null;
    }
  }

  checkLenderNoteStatus() {
    this.fields.lenderEntityLegalEntityId.required = !this.isFundingIntoOrigination;
    this.fields.brokeringEntityId.required = this.isFundingIntoOrigination;
    this.fields.loanTerm.disabled = this.isFunded;
    this.fields.originationDate.disabled = !this.isFunded;
    this.fields.originalMaturityDate.disabled = !this.isFunded;

    const { extensionTerm, extensionExercised } = this.fields;
    extensionTerm.required = this.isFunded;
    extensionExercised.required = this.isFunded;
    if (!extensionTerm.required) {
      extensionTerm.value = null;
    }
  }

  checkProgramType() {
    if (this.isResiRental) {
      this.fields.paymentStructure.value = "amortizing";
    } else {
      this.fields.paymentStructure.value = "interest_only";
    }
  }

  setTotalPrincipalPayments() {
    if (!this.isFunded || this.isResiRental) {
      this.fields.totalPrincipalPayments.value = 0;
      this.fields.totalPrincipalPayments.required = false;
    } else {
      this.fields.totalPrincipalPayments.required = true;
    }
  }

  setLoanTerm() {
    if (this.isResiRental) {
      this.fields.loanTerm.disabled = true;
      this.fields.loanTerm.value = 360;
    }
  }

  setOriginationDate() {
    if (!this.fields.originationDate.required) {
      this.fields.originationDate.value = this.fields.lenderTargetPurchaseDate.value;
    }
  }

  get dateLabelPrefix() {
    if (
      this.fields.lenderNoteStatus.value === "funded" ||
      this.fields.lenderNoteStatus.value === ""
    ) {
      return "";
    } else {
      return "Projected";
    }
  }

  get isFundingIntoOrigination() {
    return this.fields.lenderNoteStatus.value === "funding_into_origination";
  }

  get showCema() {
    return this.fields.state.value === "NY";
  }

  get isFunded() {
    return this.fields.lenderNoteStatus.value === "funded";
  }

  get isAcquisition() {
    return this.fields.loanPurpose.convertedValue === "acquisition";
  }

  get isResiRental() {
    return this.fields.programType.value === "residential_rental_long_term";
  }

  get resetInterestRate() {
    this.fields.interestRate.value = 0;
    this.fields.interestRate.required = false;
  }
}
