<template>
  <div class="estimated-tabs">
    <base-tabs
      :animated="false"
      size="medium"
      bordered
      class="scenario-tabs"
    >
      <creditbox-tab
        :creditbox-results="creditboxResults"
        :constraints="constraints"
        :scenario="scenario"
        :price-results="priceResults"
        :converted-scenario="convertedScenario"
        :loan-creation-in-progress="loanCreationInProgress"
        v-on="$listeners"
      />
    </base-tabs>
  </div>
</template>

<script>
import CreditboxTab from "components/loan_scenarios/tabs/creditbox_tab";

export default {
  name: "estimated-tabs",
  components: { CreditboxTab },
  props: {
    creditboxResults: Object,
    constraints: Object,
    priceResults: Array,
    scenario: Object,
    convertedScenario: Object,
    loanCreationInProgress: Boolean,
  },
};
</script>

<style scoped>
.scenario-tabs {
  padding-bottom: var(--space-unit);
}

.scenario-tabs >>> .tabs li a,
.scenario-tabs >>> .tabs li.is-active a,
.scenario-tabs >>> .tabs li a:not([href]):not([tabindex]),
.scenario-tabs >>> .tabs li a:not([href]):not([tabindex]):hover {
  color: var(--color-grey-38);
  font-size: var(--font-size-md);
}

.scenario-tabs >>> .tabs li.is-active a,
.scenario-tabs >>> .tabs li.is-active a:not([href]):not([tabindex]),
.scenario-tabs >>> .tabs li.is-active a:not([href]):not([tabindex]):hover {
  color: var(--color-grey-86);
  font-weight: 600;
}

.scenario-tabs >>> .tabs li a {
  padding-left: 0;
  padding-right: 0;
}

.scenario-tabs >>> .tabs ul {
  border-bottom: 1px solid var(--color-grey-16);
  margin-bottom: 22px;
}

.scenario-tabs >>> .tab-content {
  min-height: 90px;
  padding-left: 0;
  padding-right: 0;
}
</style>
