<template>
  <params-provider v-slot="slotProps">
    <missing-series />
  </params-provider>
</template>

<script>
import ParamsProvider from "components/shared/params_provider";
import MissingSeries from "./missing_series";

export default {
  components: {
    MissingSeries,
    ParamsProvider,
  },
};
</script>
