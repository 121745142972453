<template>
  <div class="monthly-loan-sales lender-stats lender-section">
    <h3>
      {{stats.year}} Loans Funded
    </h3>
    <p>{{stats.amount | money}}</p>
    <span>{{stats.count | pluralize("Loan")}}</span>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      stats: PSData.lenderSalesYtd || {},
    };
  },
};
</script>
