import Source from "models/market_data/source";

class LoanTrends extends Source {
  constructor(data) {
    super();

    this.data = {
      submissions: {},
      sales: {},
    };

    this.labels = {};

    let categoryKeys = Object.keys(data);

    if (data && categoryKeys.length > 0) {
      categoryKeys.forEach(key => {
        this.data[key] = data[key].reduce((values, item) => {
          this.addValue(item, values);
          return values;
        }, {});
      });

      this.labels = data[categoryKeys[0]].reduce((labels, item) => {
        this.addLabel(item, labels);
        return labels;
      }, {});
    }

    super.success();
  }

  get name() {
    return "Loan Trends";
  }

  get hasData() {
    let keys = Object.keys(this.data);
    return keys.some(k => Object.values(this.data[k]).some(v => !Number.isNaN(parseFloat(v))));
  }
}

export class MonthlyLoanTrends extends LoanTrends {
  addValue(item, values) {
    values[item.month] = item.amount;
  }

  addLabel(item, labels) {
    labels[item.month] = PS.Models.DateFormatter.formatDate(item.month, "MMM 'YY");
  }
}

export class QuarterlyLoanTrends extends LoanTrends {
  addValue(item, values) {
    let quarter = this.getQuarter(item);
    values[quarter] = item.amount;
  }

  addLabel(item, labels) {
    let quarter = this.getQuarter(item);
    labels[quarter] = quarter;
  }

  getQuarter(item) {
    return "Q" + PS.Models.DateFormatter.formatDate(item.month, "Q 'YY");
  }
}
