<template>
  <report-import-details-page
    :report-import="reportImport"
  />
</template>

<script>
import ReportImportDetailsPage from "components/physical_doc_tracking_imports/show.vue";

export default {
  name: "report-import-details-controller",
  components: { ReportImportDetailsPage },
  data() {
    return {
      reportImport: PSData.reportImport,
    };
  },
};
</script>
