const TextHighlighter = PS.Utils.TextHighlighter;

class Suggestion {
  constructor(text, substitutions = [], options = {}) {
    this.text = text;
    this.substitutions = substitutions;
    this.options = options;
  }

  get itemMatches() {
    return TextHighlighter.splitToPhrases(this.text, this.substitutions);
  }
}

PS.Models.Autocomplete.Suggestion = Suggestion;
