<template>
  <div class="workflows-section">
    <div class="workflows-section-header">
      <header class="panel-header">Asset Management Tasks</header>
      <new-workflow-button v-if="canUpdateWorkflows" />
    </div>
    <div>
      <workflows-list />
    </div>
    <div>
      <history-section />
    </div>
  </div>
</template>
<script>
import HistorySection from "./workflows_section/history_section";
import WorkflowsList from "./workflows_section/workflows_list";
import NewWorkflowButton from "./workflows_section/new_workflow/button";
import { mapState } from "pinia";
import useAssetManagementStore from "controllers/manage/store/asset_management";

export default {
  components: {
    WorkflowsList,
    NewWorkflowButton,
    HistorySection,
  },
  computed: {
    ...mapState(useAssetManagementStore, ["canUpdateWorkflows"]),
  },
};
</script>
