<template>
  <modal-view :show="show" :on-close="onClose" class="base-modal reallocation-loans-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          Reallocate Loans
          <a class="tracking-numbers-modal-close-icon" @click.prevent="onClose" href="#close">&times;</a>
        </h4>
      </div>
      <div class="modal-body">
        <form>
          <textarea class="form-control reallocation-loans-modal-ids-input" v-model="loanIdsText" placeholder="Copy and paste loan IDs here"/>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-cancel-cta" @click.prevent="onClose">
          Cancel
        </button>
        <button class="btn btn-action-cta" @click.prevent="submit">
          Add to List
        </button>
      </div>
    </div>
  </modal-view>
</template>
<script>
import ModalView from "components/modal_view";
import compact from "lodash/compact";
import uniq from "lodash/uniq";

export default {
  name: "reallocate-loans-modal",
  components: { ModalView },
  props: {
    show: Boolean,
  },
  data() {
    return {
      loanIdsText: "",
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    submit() {
      const loanIds = this.loanIdsText.split(/(?:\s|,)+/g);
      this.$emit("add", uniq(compact(loanIds)));
    },
  },
};
</script>
