<template>
  <tbody>
    <tr>
      <td>
        {{ ownership.id }}
      </td>
      <td>
        {{ ownership.ownerName }}
      </td>
      <td>
        {{ ownership.wireDate }}
      </td>
      <td>
        {{ ownership.startDate }}
      </td>
      <td>
        {{ ownership.endDate }}
      </td>
      <td>
        <note-icon :tooltip="ownership.note" @click.native="toggleNote" />
      </td>
    </tr>
    <tr v-if="noteMode">
      <td colspan="6">
        <edit-note
          v-model="ownership.note"
          @cancel-note="toggleNote"
          @save-note="updateNote"
        />
      </td>
    </tr>
  </tbody>
</template>

<script>
export default Vue.component("loan-legal-ownerships-item", {
  props: {
    ownership: {
      type: Object,
      required: true,
    },
    owners: {
      type: Array,
      required: true,
    },
    bus: undefined,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      noteMode: false,
    };
  },
  methods: {
    toggleNote: function() {
      this.noteMode = !this.noteMode;
    },
    updateNote: function() {
      this.bus.$emit("update-ownership-note", this.ownership);
      this.noteMode = false;
    },
  },
});
</script>
