<template>
  <table class="table loan-associations-table">
    <thead>
      <tr>
        <th>Address</th>
        <th>ID / Date Ordered</th>
        <th>Type</th>
        <th>As Is Amount</th>
        <th>ARV Amount</th>
        <th>Deferred Maintenance</th>
        <th>Method</th>
        <th>Appraiser</th>
        <th>Commissioned By</th>
        <th>Note</th>
        <th v-if="!disabled">Actions</th>
      </tr>
    </thead>
    <template>
      <tbody v-for="(item, i) in items" :key="item.id">
        <tr v-show="item.editing">
          <td>
            <div v-if="!lenderSubmitted" class="form-group">
              <select
                :id="'valuation-address-' + item.id"
                v-model="item.propertyId"
                class="form-control"
              >
                <option
                  v-for="option in propertyOptions"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
          </td>
          <td>
            <div class="form-group">
              <input
                :id="'valuation-date-' + item.id"
                v-model="item.date"
                v-datepicker=""
                v-mask:date=""
                class="form-control"
              />
            </div>
          </td>
          <td>
            <div class="form-group">
              <select
                :id="'valuation-type-' + item.id"
                v-model="item.reportType"
                class="form-control"
              >
                <option
                  v-for="option in validationOptions"
                  :key="option.name"
                  :value="option.name"
                >
                  {{ option.value }}
                </option>
              </select>
            </div>
          </td>
          <td>
            <div class="form-group currency">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  :id="'valuation-as-is-amount-' + item.id"
                  v-model="item.asIsAmount"
                  v-mask:currency=""
                  class="form-control"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="form-group currency">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  :id="'valuation-arv-amount-' + item.id"
                  v-model="item.arvAmount"
                  v-mask:currency=""
                  class="form-control"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="form-group currency">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  :id="'valuation-deferred-maintenance-amount-' + item.id"
                  v-model="item.deferredMaintenanceAmount"
                  v-mask:currency=""
                  class="form-control"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="form-group">
              <select
                :id="'valuation-method-' + item.id"
                v-model="item.valuationMethod"
                class="form-control"
              >
                <option></option>
                <option
                  v-for="option in options.valuationMethods"
                  :key="option.name"
                  :value="option.name"
                >
                  {{ option.value }}
                </option>
              </select>
            </div>
          </td>
          <td>
            <div class="form-group">
              <select
                :id="'valuation-appraiser-' + item.id"
                v-model="item.appraiser"
                class="form-control"
              >
                <option></option>
                <option
                  v-for="option in options.appraisers"
                  :key="option.name"
                  :value="option.name"
                >
                  {{ option.value }}
                </option>
              </select>
            </div>
          </td>
          <td>
            <div class="form-group">
              <select
                :id="'valuation-commissioner-' + item.id"
                v-model="item.commissioner"
                class="form-control"
              >
                <option
                  v-for="option in options.commissioners"
                  :key="option.name"
                  :value="option.name"
                >
                  {{ option.value }}
                </option>
              </select>
            </div>
          </td>
          <td>
            <div class="form-group">
              <input :id="'valuation-note-' + item.id" v-model="item.note" class="form-control" />
            </div>
          </td>
          <td>
            <a
              :id="'valuation-save-' + item.id"
              class="btn-action-cta"
              tabindex="0"
              @click="updateItem(item)"
            >
              Save
            </a>
            <a
              :id="'valuation-cancel-' + item.id"
              class="btn-outline-grey"
              tabindex="0"
              @click="cancelEdit(item)"
            >
              Cancel
            </a>
          </td>
        </tr>
        <tr v-show="!item.editing">
          <td>
            <div v-if="lenderSubmitted">
              All Properties
            </div>
            <div v-else :class="{'hide-address': hideAddress(i)}">
              {{ findOptionNameById(item.propertyId, propertyOptions) }}
            </div>
          </td>
          <td v-if="item.apiAppraiser">
            {{ item.id }}
            <br>
            {{ item.date | missingValue }}
            <br>
            {{ item.orderedDate }}
          </td>
          <td v-else>
            {{ item.id }}
            <br>
            {{ item.date }}
          </td>
          <td>
            {{ findOptionValue(item.reportType, validationOptions) }}
          </td>
          <td>
            <div v-if="item.canDesignate()" class="radio-control radio-control-inline">
              <input
                :id="'checkbox-asis-amount-' + item.id"
                :name="'checkbox-asis-amount-' + item.propertyId"
                type="radio"
                :checked="isAsIsSelected(item)"
                :disabled="amountChangeDisabled(item)"
                @click="designateItem($event, item)"
              />
              <label :for="'checkbox-asis-amount-' + item.id">
                <span class="control-toggle"></span>
                {{ item.asIsAmount | money }}
              </label>
            </div>
            <span v-else class="readonly-checkbox">
              {{ item.asIsAmount | money }}
            </span>
          </td>
          <td>
            <div v-if="item.canDesignate()" class="radio-control radio-control-inline">
              <input
                :id="'checkbox-arv-amount-' + item.id"
                :name="'checkbox-arv-amount-' + item.propertyId"
                type="radio"
                :checked="isArvSelected(item)"
                :disabled="amountChangeDisabled(item)"
                @click="designateArv($event, item)"
              />
              <label :for="'checkbox-arv-amount-' + item.id">
                <span class="control-toggle"></span>
                {{ item.arvAmount | money }}
              </label>
            </div>
            <span v-else class="readonly-checkbox">
              {{ item.arvAmount | money }}
            </span>
          </td>
          <td>
            <div v-if="item.canDesignate()" class="radio-control radio-control-inline">
              <input
                :id="'checkbox-deferred-maintenance-amount-' + item.id"
                :name="'checkbox-deferred-maintenance-amount-' + item.propertyId"
                type="radio"
                :checked="isDeferredMaintenanceSelected(item)"
                :disabled="amountChangeDisabled(item)"
                @click="designateDeferredMaintenance($event, item)"
              />
              <label :for="'checkbox-deferred-maintenance-amount-' + item.id">
                <span class="control-toggle"></span>
                {{ item.deferredMaintenanceAmount | money }}
              </label>
            </div>
            <span v-else class="readonly-checkbox">
              {{ item.deferredMaintenanceAmount | money }}
            </span>
          </td>
          <td>
            {{ findOptionValue(item.valuationMethod, options.valuationMethods) }}
          </td>
          <td>
            {{ item.partyCompletingValuation }}
          </td>
          <td>
            <template v-if="item.isPeerStreetCommissioner()">
              <span class="valuation-peerstreet-logo"></span>
            </template>
            <template v-else>
              {{ findOptionValue(item.commissioner, options.commissioners) }}
            </template>
          </td>
          <td>
            {{ item.note }}
          </td>
          <td v-if="!disabled">
            <a
              v-if="item.canEdit()"
              :id="'valuation-edit-' + item.id"
              class="btn-outline-grey"
              @click="editItem(item)"
            >
              Edit
            </a>
            <a
              :disabled="!item.canDelete()"
              :id="'valuation-delete-' + item.id"
              class="btn-outline-grey"
              @click="deleteItem(item)"
            >
              Delete
            </a>
          </td>
        </tr>
      </tbody>
      <tbody v-if="!lenderSubmitted" :class="{'disabled-totals': addInProcess || designationChanges}" class="totals">
        <tr>
          <td class="designated-total-title"><div>Designated Totals</div></th>
          <td></td>
          <td></td>
          <td class="designated-totals">{{ loan.asIsValuationAmount | money }}</td>
          <td class="designated-totals">{{ loan.arv | money }}</td>
          <td class="designated-totals">{{ loan.deferredMaintenanceAmount | money }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="designation-actions">
            <div v-if="!lenderSubmitted" :class="{'hide-designation-actions': !designationChanges}">
              <a
                class="btn-action-cta"
                tabindex="0"
                :disabled="!designationChanges || addInProcess || !allowApplyDesignations"
                @click="applyDesignations"
              >
                Save Designations
              </a>
              <a
                class="btn-outline-grey"
                :disabled="!designationChanges || addInProcess"
                tabindex="0"
                @click="cancelDesignations"
              >
                Cancel
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </table>
</template>

<script>
export default Vue.component("loan-valuations-table", {
  props: {
    addInProcess: {
      type: Boolean,
      default: false,
    },
    allowApplyDesignations: {
      type: Boolean,
      default: false,
    },
    designationChanges: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    lenderSubmitted: {
      type: Boolean,
      default: false,
    },
    propertyOptions: {
      type: Array,
      default: () => [],
    },
    validationOptions: {
      type: Array,
      default: () => [],
    },
  },
  data: function() {
    return {
      loan: PSData.loan,
      properties: PSData.loan.loanProperties,
      options: PSData.valuationOptions,
    };
  },
  methods: {
    applyDesignations: function() {
      this.$emit("apply-designations");
    },
    cancelDesignations: function() {
      this.$emit("cancel-designations");
    },
    hideAddress: function(index) {
      if (index == 0) return false;

      return this.items[index - 1].raw.propertyId == this.items[index].raw.propertyId;
    },
    deleteItem: function(item) {
      this.$emit("delete-item", item);
    },
    updateItem: function(item) {
      this.$emit("update-item", item);
    },
    designateDeferredMaintenance: function(event, item) {
      this.$emit("designate-deferred-maintenance", item);
    },
    designateArv: function(event, item) {
      this.$emit("designate-arv", item);
    },
    designateItem: function(event, item) {
      this.$emit("designate-item", item);
    },
    editItem: function(item) {
      this.$emit("edit-item", item);
    },
    cancelEdit: function(item) {
      this.$emit("cancel-edit", item);
    },
    initTooltips: function() {
      $(this.$el).tooltip({
        placement: "bottom",
        selector: "[type=radio]:disabled:not(:checked) + label>.control-toggle",
        title: "Date, Method & Appraiser are required to designate",
      });
    },
    findOptionValue: function(name, options) {
      var option = options.filter(option => option.name === name)[0] || {};
      return option.value;
    },
    findOptionNameById: function(id, options) {
      var option = options.filter(option => option.id == id)[0] || {};
      return option.name;
    },
    isAsIsSelected: function(item) {
      return item.designated;
    },
    isArvSelected: function(item) {
      return item.designatedArv;
    },
    isDeferredMaintenanceSelected: function(item) {
      return item.designatedDeferredMaintenance;
    },
    amountChangeDisabled: function(item) {
      return this.disabled || !item.isRequiredAttributesPresent();
    },
  },
});
</script>

<style scoped>
.btn-outline-grey[disabled] {
  opacity: 0;
  transition: all 0s;
}

.hide-designation-actions {
  opacity: 0;
}

.hide-address {
  display: none;
}
.disabled-totals {
  color: #9d9d9d;
}
.totals {
  font-weight: 600;
}
td.designated-totals {
  padding-left: 58px;
}
.designated-total-title {
  display: flex;
}
.designated-total-title div {
  font-weight: bold;
  position: absolute;
  width: max-content;
}
.designation-actions {
  display: flex;
  flex-flow: row-reverse;
}

.designation-actions div {
  position: absolute;
}
</style>
