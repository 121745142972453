class EditLoanFormPolicy {
  constructor(loanPolicy) {
    this.formWithErrors = PSData.formErrors && !!Object.keys(PSData.formErrors).length;
    this.urlWithEdit = window.location.hash == "#edit";
    this.loanPolicy = loanPolicy || {};
  }

  canUpdate() {
    return this.canEdit() && (this.formWithErrors || this.urlWithEdit);
  }

  canUpdateServicing() {
    return this.loanPolicy.updateServicing.allowed;
  }

  canUpdateServicingFees() {
    return this.loanPolicy.updateServicingFees.allowed;
  }

  canUpdateSubservicer() {
    return this.loanPolicy.updateSubservicer.allowed;
  }

  canEdit() {
    return this.loanPolicy.update.allowed;
  }

  canCreateSettlementStatement() {
    return this.loanPolicy.createSettlementStatement.allowed;
  }

  canUpdateRiskAnalysisDate() {
    return this.loanPolicy.updateRiskAnalysisDate.allowed;
  }

  canUpdateLoanData() {
    return this.loanPolicy.updateLoanData.allowed;
  }

  canEditLoanForm() {
    return this.loanPolicy.updateLoanForm.allowed;
  }

  canShowActionDropdown() {
    return this.loanPolicy.loanActions.allowed;
  }

  canUpdateLoanForm() {
    return this.canEditLoanForm() && (this.formWithErrors || this.urlWithEdit);
  }

  canUpdateLenderSpread() {
    return this.loanPolicy.updateLenderSpread.allowed;
  }

  canUpdatePsSpread() {
    return this.loanPolicy.updatePsSpread.allowed;
  }

  canUpdateDistributeToLender() {
    return this.loanPolicy.updateDistributeToLender.allowed;
  }

  canUpdateDistributeToInvestor() {
    return this.loanPolicy.updateDistributeToInvestor.allowed;
  }

  canEditPeerStreetNotes() {
    return this.canEdit();
  }

  canSyncDocGen() {
    return this.loanPolicy.syncDocGen.allowed;
  }

  canViewLpsResults() {
    return this.loanPolicy.viewLpsResults.allowed;
  }

  canAddLenderFollowers() {
    return this.loanPolicy.updateLenderFollowers.allowed;
  }
}

PS.Policies.EditLoanFormPolicy = EditLoanFormPolicy;
