<template>
  <div>
    <div class="columns">
      <base-text-field
        v-model="definition.label"
        name="label"
        label="Task Name"
        placeholder="Task Name"
        class="column"
        rules="required"
        @blur="onLabelBlur"
      />
    </div>
    <div class="columns">
      <base-select-field
        v-model="definition.actionType"
        :options="actionTypes"
        name="actionType"
        label="Action Type"
        placeholder="Action Type"
        class="column"
        rules="required"
      />
    </div>
    <div class="columns">
      <base-select-field
        v-model="definition.taskType"
        :options="taskTypes"
        name="taskType"
        label="Task Type"
        placeholder="Task Type"
        class="column"
        rules="required"
      />
    </div>
    <div class="columns">
      <base-autocomplete
        id="taskGroup"
        v-model="definition.loanTaskGroupId"
        field="label"
        placeholder="Search Group"
        label="Task Group"
        class="column"
        :options="loanTaskGroups"
        rules="required"
      />
    </div>
    <div class="columns">
      <base-autocomplete
        v-if="showMappedDocumentType"
        id="doctype"
        v-model="definition.loanDocumentTypeId"
        field="text"
        placeholder="Search document type"
        label="Mapped Document Type"
        class="column"
        :options="documentTypes"
        hint="Documents uploaded to this task will be renamed and added to the correct folder."
      />
    </div>
    <div class="columns">
      <base-autocomplete
        v-if="showMappedTask"
        id="tasktype"
        v-model="definition.mappedLoanTaskDefinitionId"
        field="label"
        placeholder="Search Task"
        label="Mapped LP Task"
        class="column"
        icon="search"
        rules="required"
        :options="tasks"
      />
    </div>
    <div class="columns">
      <editor-field
        v-model="definition.defaultDescription"
        label="Static Description"
        placeholder="Task Description"
        class="column"
      />
    </div>
  </div>
</template>
<script>
import EditorField from "components/shared/editor_field";
import snakeCase from "lodash/snakeCase";

export default {
  components: {
    EditorField,
  },
  props: {
    definition: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    documentTypes: {
      type: Array,
      default: () => [],
    },
    actionTypes: {
      type: Array,
      default: () => [],
    },
    taskTypes: {
      type: Array,
      default: () => [],
    },
    mappingRequiredTaskTypes: {
      type: Array,
      default: () => [],
    },
    tasks: {
      type: Array,
      default: () => [],
    },
    loanTaskGroups: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showMappedDocumentType() {
      return this.definition.uploadActionType;
    },
    showMappedTask() {
      return this.mappingRequiredTaskTypes.includes(this.definition.taskType);
    },
  },
  watch: {
    "definition.taskType": function() {
      if (!this.showMappedTask && this.definition.mappedLoanTaskDefinitionId != null) {
        this.definition.mappedLoanTaskDefinitionId = null;
      }
    },
  },
  methods: {
    onLabelBlur() {
      if (!this.definition.systemName) {
        Vue.set(this.definition, "systemName", snakeCase(this.definition.label));
      }
    },
  },
};
</script>
