export function camelToSnakeCase(str) {
  return str.replace(/[A-Z]/g, character => `_${character.toLowerCase()}`);
}

export function snakeToCamelCase(str) {
  return str.replace(/_(\w)/g, (_, character) => character.toUpperCase());
}

export function deepMapKeys(obj, callback) {
  const prototypeName = Object.prototype.toString.call(obj);

  switch (prototypeName) {
    case "[object Object]":
      const keys = Object.keys(obj);
      if (keys.length === 0) {
        return obj;
      }

      return keys.reduce((accumulator, currentKey) => {
        const key = callback(currentKey);
        const value = obj[currentKey];
        accumulator[key] = deepMapKeys(value, callback);

        return accumulator;
      }, {});
    case "[object Array]":
      if (obj.length === 0) {
        return obj;
      }

      return obj.map(value => deepMapKeys(value, callback));
    default:
      return obj;
  }
}
