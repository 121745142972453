import { bestLtvBaseLine } from "../utils/helpers";

export default class FetchCreditbox {
  static async call(scenario, serviceClient) {
    const payload = scenario.toHash();

    // FIXME TEMP: LPS schema requires these two fields, just stub some values for now
    // will be removed after schema requirement is dropped
    payload.ltv = bestLtvBaseLine(payload.program_type);
    payload.total_principal_balance_at_origination = 100000;

    const creditboxResults = await serviceClient.requestCreditbox(payload);

    return creditboxResults;
  }
}
