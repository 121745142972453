<template>
  <div>
    <label v-if="!!label">{{ label }}</label>
    <div v-if="plaintext">
      {{ $options.parse(value) }}
    </div>
    <input
      v-else
      v-model="innerValue"
      v-datepicker=""
      v-mask:date=""
      :name="name"
      :class="classes"
      type="text"
      @blur="$emit('blur')"
    >
    <div v-if="invalid" class="invalid-feedback">
      {{ errors.collect(name).join(",") }}
    </div>
  </div>
</template>

<script>
import moment from "moment";
import get from "lodash/get";

const parse = date => (date ? moment(date).format("MM/DD/YYYY") : "");

export default {
  inject: ["$validator"],
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: [Date, String],
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    plaintext: Boolean,
  },
  data() {
    return { innerValue: parse(this.value) };
  },
  computed: {
    dirty() {
      return get(this.fields, [this.name, "dirty"], false);
    },
    invalid() {
      return this.errors.has(this.name);
    },
    classes() {
      return {
        "form-control": true,
        "is-invalid": this.invalid,
        "is-valid": this.dirty && !this.invalid,
      };
    },
  },
  watch: {
    innerValue(v) {
      if (!v) {
        this.$emit("input", null);
      }

      const date = moment(v, "MM/DD/YYYY", true);

      if (date.isValid()) {
        this.$emit("input", date.toDate());
      }
    },
  },
  parse: parse,
};
</script>
