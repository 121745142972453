import selectOptionsToMapping from "utils/select_options_to_mapping";
import objectToSnakeCase from "utils/object_to_snake_case";

export default class LoanInsurancePremium {
  static create(loanInsurancePremium, options) {
    return new LoanInsurancePremium(loanInsurancePremium, options);
  }

  constructor(loanInsurancePremium = {}, options = {}) {
    this.data = {};
    this.update(loanInsurancePremium);
    this._optionsRaw = options;
    this.types = selectOptionsToMapping(options.premiumTypes || []);
    this.paymentMethods = selectOptionsToMapping(options.paymentMethods || []);
  }

  get id() {
    return this.data.id;
  }

  get typeLabel() {
    return this.types[this.data.premiumType];
  }

  get type() {
    return this.data.premiumType;
  }

  get typeId() {
    return Object.keys(this.types).indexOf(this.data.premiumType);
  }

  set type(type) {
    this.data.premiumType = type;
  }

  get date() {
    return this.data.premiumDate;
  }

  set date(date) {
    this.data.premiumDate = date;
  }

  get amount() {
    return this.data.premiumAmount;
  }

  set amount(amount) {
    this.data.premiumAmount = amount;
  }

  get paymentMethodLabel() {
    return this.paymentMethods[this.data.paymentMethod];
  }

  get paymentMethod() {
    return this.data.paymentMethod;
  }

  set paymentMethod(paymentMethod) {
    this.data.paymentMethod = paymentMethod;
  }

  get payee() {
    return this.data.payee;
  }

  set payee(payee) {
    this.data.payee = payee;
  }

  get objectToSave() {
    return objectToSnakeCase(this.data);
  }

  update(updatedProperty) {
    this._rawData = Object.assign({}, updatedProperty);
    this.data = updatedProperty;
  }

  reset() {
    this.update(this._rawData);
  }
}
