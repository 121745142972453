<template>
  <base-modal-form
    :show="show"
    title="Add Tasks"
    class="task-mapping-add-form"
    :save-disabled="loading"
    @save="onSave"
    @close="onClose"
  >
    <div class="columns">
      <base-select-field
        v-model="triggerStatus"
        :options="triggerStatuses"
        label="Trigger"
        placeholder="Select Trigger"
        class="column"
        :errors="errors.trigger_status"
        hint="The loan status that triggers the creation of this task."
      />
    </div>
    <div class="is-flex is-align-items-center" v-if="!showConditions">
      <base-button type="link" class="p-0 pr-1 has-text-weight-semibold" @click="addConditions">
        Add Creation Conditions
      </base-button>
      <base-tooltip
        label="Add Custom Conditions."
        position="is-top"
        multilined
      >
        <b-icon
          pack="fas"
          size="is-small"
          icon="question-circle"
          type="is-ps-hint-icon"
        />
      </base-tooltip>
    </div>
    <div class="columns">
      <div v-if="showConditions" class="column">
        <rules-preview
          ref="conditions"
          :rules="conditions"
          @change="onRulesChange"
          :errors="errors.conditions"
        />
      </div>
    </div>
    <div class="columns">
      <base-tag-field
        ref="tagfield"
        v-model="tasks"
        label="Task"
        autocomplete
        icon="search"
        :data="filteredTasks"
        placeholder="Add tasks"
        class="column"
        :loading="dataLoading"
        rules="required"
        @typing="setFilteredDefinitions"
        :errors="errors.task_definition_ids"
      >
        <template v-slot="{ props }">
          <strong>{{ taskType(props.option) }}</strong>: {{ props.option.label }}
        </template>
        <template #empty>
          There are no items
        </template>
        <template #selected="{ props }">
          <base-tag
            v-for="(tag, index) in props.tags"
            :key="index"
            :type="taskTagType(tag)"
            :tabstop="false"
            ellipsis
            closable
            @close="$refs.tagfield.$refs.input.removeTag(index, $event)"
          >
            {{ tag.label }}
          </base-tag>
        </template>
      </base-tag-field>
    </div>
  </base-modal-form>
</template>
<script>
import AjaxService from "services/ajax_service";
import { manageTasklistMilestonePath } from "models/tasklist_template_revision";
import RulesPreview from "../rules/preview";
import { store } from "../template_revision/store";
import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";
import mapKeys from "lodash/mapKeys";

export default {
  components: {
    RulesPreview,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    milestone: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      triggerStatus: "",
      triggerRules: {},
      tasks: [],
      taskDefinitions: null,
      filteredTasks: [],
      loading: false,
      dataLoading: false,
      showConditions: false,
      conditions: {},
      triggerStatuses: store.state.triggerStatuses,
      errors: {},
      definitions: store.state.rulesDefinitions,
      ruleDefinitionIds: [],
    };
  },
  watch: {
    milestone() {
      this.taskDefinitions = null;
    },
    tasks() {
      this.errors = {};
    },
    triggerStatus() {
      if (this.errors.trigger_status) {
        this.errors.trigger_status = null;
      }
    },
  },
  methods: {
    onClose() {
      this.tasks = [];
      this.filteredTasks = [];
      this.triggerStatus = null;
      this.triggerRules = {};
      this.conditions = {};
      this.showConditions = false;
      this.$emit("close");
      this.errors = {};
      this.ruleDefinitionIds = [];
    },
    onSave() {
      this.loading = true;
      const messages = {
        onSuccess: "Tasks has been added",
        onError: this.onError,
      };

      AjaxService.withNotifications(messages)
        .patchJSON(manageTasklistMilestonePath(this.milestone.id), {
          trigger_status: this.triggerStatus,
          task_definition_ids: this.tasks.map(task => task.id),
          conditions: this.triggerRules,
          task_description_rules: this.descriptionTriggerRules,
          rule_definition_ids: this.ruleDefinitionIds,
        })
        .then(data => {
          this.$emit("save", data.milestone);
          this.onClose();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onError({ data }) {
      this.errors = data.errors || {};
      return null;
    },
    setFilteredDefinitions(text) {
      this.text = text;
      if (this.dataLoading) return;

      if (!this.taskDefinitions) {
        this.dataLoading = true;
        AjaxService.getJSON(manageTasklistMilestonePath(this.milestone.id))
          .then(data => {
            this.taskDefinitions = data.taskDefinitions;
            this.setFilteredTasks(this.text);
          })
          .catch(() => {
            this.taskDefinitions = [];
          })
          .finally(() => {
            this.dataLoading = false;
          });
      } else {
        this.setFilteredTasks(this.text);
      }
    },
    setFilteredTasks(text) {
      this.filteredTasks = this.taskDefinitions.filter(
        option =>
          option.label
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      );
    },
    taskType(definition) {
      switch (definition.taskType) {
        case "lender":
          return "Lender";
        case "borrower":
          return "Borrower";
        case "co_borrower":
          return "Co-Borrower";
        default:
          return "Internal";
      }
    },
    taskTagType(definition) {
      switch (definition.taskType) {
        case "lender":
          return "primary";
        case "borrower":
          return "primary";
        case "co_borrower":
          return "primary";
        default:
          return "dark";
      }
    },
    addConditions() {
      this.showConditions = true;
      this.conditions = { "": { "": "" } };
    },
    onRulesChange(rules) {
      this.triggerRules = mapKeys(rules, (value, key) => snakeCase(key));
      this.ruleDefinitionIds = Object.keys(rules)
        .map(
          rule => (this.definitions[camelCase(rule)] ? this.definitions[camelCase(rule)].id : null)
        )
        .filter(id => id);
      if (this.errors.conditions) {
        this.errors.conditions = null;
      }
    },
  },
};
</script>
<style scoped>
.task-mapping-add-form >>> .modal-card {
  min-width: 600px;
  max-width: 600px;
}
</style>
