<template>
  <base-progress class="loan-status-tracker">
    <template #progress_bar>
      <progress-bar
        v-if="completedTasks > 0"
        v-tooltip
        :value="completedTasks"
        :color="completedColor"
        :title="completedTitle"
      />
      <progress-bar
        v-if="pendingTasks > 0"
        v-tooltip
        :value="pendingTasks"
        color="pending"
        :title="pendingTitle"
      />
      <progress-bar
        v-if="incompleteTasks > 0"
        v-tooltip
        :value="incompleteTasks"
        color="incomplete"
        :title="incompleteTitle"
      />
    </template>
  </base-progress>
</template>
<script>
export default {
  name: "loan-status-tracker-bars",
  props: {
    completedColor: {
      type: String,
      default: "completed",
    },
    incompleteTasks: {
      type: Number,
      default: 0,
    },
    pendingTasks: {
      type: Number,
      default: 0,
    },
    completedTasks: {
      type: Number,
      default: 0,
    },
    showBarTooltip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    completedTitle() {
      return this.showBarTooltip ? "Completed Tasks" : null;
    },
    incompleteTitle() {
      return this.showBarTooltip ? "Incomplete Tasks" : null;
    },
    pendingTitle() {
      return this.showBarTooltip ? "Pending Tasks" : null;
    },
  },
};
</script>
<style scoped>
.loan-status-tracker {
  background: var(--color-white) !important;
}

.incomplete {
  background-color: var(--color-grey-16) !important;
}

.pending {
  background-color: #16a2b7 !important;
}

.completed {
  background-color: #297acc !important;
}
</style>
