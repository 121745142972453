<template>
  <div v-if="step">
    <div class="header">{{ step.name }}</div>
    <markdown-view :value="stepDescription" class-name="step-description mb-4" />
    <div v-if="showPreview" class="preview-block">
      <preview-component v-for="preview in previews" :key="preview.id" :preview="preview" :meta="{ previews: step.previews }" />
    </div>
    <input-component
      v-for="input in inputs"
      :key="input.id"
      :input="input"
      :meta="{ inputs: step.inputs }"
      @on-input-add="addInput(input)"
      @on-input-delete="deleteInput(input)"
    />
    <div class="py-2">
      <base-button :loading="isLoading" type="primary" @click="update">Complete</base-button>
    </div>

    <!-- <message-thread :step="step" :comments="comments" @on-comment-posted="updateComments" /> -->
  </div>
</template>

<script>
import WorkflowEngineClient from "services/workflow_engine_client";
import InputComponent from "./input";
import PreviewComponent from "./preview";
import MarkdownView from "components/shared/markdown_view";

import Step from "models/workflow_engine/step";

export default Vue.extend({
  name: "workflow-step-component",
  components: {
    InputComponent,
    PreviewComponent,
    MarkdownView,
  },
  data() {
    return {
      step: null,
      isLoading: false,
    };
  },
  mounted() {
    const stepId = this.$route.params.id;
    const client = new WorkflowEngineClient();

    client.getPendingStep(stepId).then(data => {
      this.step = Step.deserialize(data);
    });
  },
  computed: {
    showPreview() {
      return !!this.step.previews.length;
    },
    inputs() {
      return this.step.inputs.filter(input => !input.parentVariableId);
    },
    previews() {
      return this.step.previews.filter(prw => !prw.parentVariableId);
    },
    stepDescription() {
      return this.step.description || "";
    },
  },
  methods: {
    onInputUpdate(input, value) {},
    update() {
      const client = new WorkflowEngineClient();
      const data = {
        step: this.step.toServer(),
      };

      this.isLoading = true;
      client
        .completeStep(this.step.uuid, data)
        .then(() => {
          this.$router.push({ path: `/workflows/${this.step.workflowId}` });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addInput(input) {
      const client = new WorkflowEngineClient();

      client.addInput(this.step.uuid, input.variableId).then(({ data }) => {
        location.reload();
      });
    },
    deleteInput(input) {
      const client = new WorkflowEngineClient();

      client.removeInput(this.step.uuid, input.id).then(({ data }) => {
        location.reload();
      });
    },
    updateComments(newComment) {
      this.comments = [...this.comments, Comment.deserialize(newComment)];
    },
  },
});
</script>
<style scoped>
.header {
  font-size: var(--font-size-lg);
  font-weight: 600;
  padding: var(--space-unit-lg) 0;
}

.preview-block {
  border-left: 4px solid #f0f0f0;
  margin-bottom: 32px;
  padding-left: 24px;
}

/deep/ ol,
ul,
dl {
  padding-inline-start: 15px;
}
</style>
