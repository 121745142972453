<template>
  <div v-if="tabSettings" class="loan-nav-tabs nav-menu">
    <div class="loan-nav-tabs-menu">
      <ul class="nav-underlined">
        <li v-for="tab in visibleTabs" :key="tab.id" :class="{ 'active': tab.settings.active }">
          <a :href="tab.settings.link">
            {{ tab.settings.title }}
          </a>
        </li>
      </ul>
      <div v-if="hiddenTabs.length" class="loan-nav-tabs-more dropdown dropdown-button">
        <span
          class="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          More
          <i class="fa fa-angle-down" />
        </span>

        <div class="loan-nav-tabs-more-menu dropdown-menu dropdown-menu-right">
          <a
            v-for="tab in hiddenTabs"
            :key="tab.id"
            class="loan-nav-tabs-more-menu-item dropdown-item"
            :class="{ 'active': tab.settings.active }"
            :href="tab.settings.link"
          >
            {{ tab.settings.title }}
          </a>
        </div>
      </div>
    </div>
    <user-settings :name="userSettingsKey" class="loan-nav-tabs-settings" />
  </div>
</template>

<script>
import UserSettingsService from "services/user_settings/user_settings_service";
import { SETTINGS_KEYS } from "services/user_settings/config";
import UserSettings from "components/user_settings";

const VISIBLE_TABS_COUNT = 9;

export default Vue.component("loan-nav-tabs", {
  components: {
    UserSettings,
  },
  props: {
    tabs: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tabSettings: null,
    };
  },
  computed: {
    userSettingsKey() {
      return SETTINGS_KEYS.loanNavTabs;
    },
    activeTabs() {
      return Object.keys(this.tabSettings)
        .filter(tabKey => this.tabSettings[tabKey].show)
        .map(tabKey => ({
          id: tabKey,
          settings: this.tabs[tabKey],
        }));
    },
    visibleTabs() {
      return this.activeTabs.slice(0, VISIBLE_TABS_COUNT);
    },
    hiddenTabs() {
      return this.activeTabs.slice(VISIBLE_TABS_COUNT, this.activeTabs.length);
    },
  },
  mounted() {
    UserSettingsService.updateInitialSettings(this.userSettingsKey, { items: this.tabs });
    UserSettingsService.subscribe(this.updateTabs);
    UserSettingsService.loadSettings();
  },
  beforeDestroy() {
    UserSettingsService.unsubscribe(this.updateTabs);
  },
  methods: {
    updateTabs() {
      this.tabSettings = UserSettingsService.getItem(this.userSettingsKey).items;
    },
  },
});
</script>
