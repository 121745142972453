<template>
  <editable-section
    :editing="editing"
    :actions-hidden="hideActionButtons"
    :is-applicable="isApplicable()"
    header-title="Real Estate Owned (REO) Status"
    subheading="Foreclosed property ownership details"
    @edit="handleEdit"
    @cancel="handleCancel"
    @update="handleUpdate"
  >
    <div class="columns">
      <div class="column">
        <ul class="loan-block">
          <li>
            <editable-input
              label="Entity Name"
              :value="form.reoEntityName"
              :editing="editing"
              @input="e => updateForm('reoEntityName', e.target.value)"
            />
          </li>
          <li>
            <editable-select
              label="Property Occupied"
              :value="form.reoPropertyOccupied"
              :options="booleanOptions"
              :editing="editing"
              @input="e => updateForm('reoPropertyOccupied', e.target.value)"
            />
          </li>
          <li>
            <editable-datepicker
              label="Possession Date"
              :value="form.possessionDate"
              :editing="editing"
              @input="value => updateForm('possessionDate', value)"
            />
          </li>
          <li>
            <editable-datepicker
              label="Original List Date"
              :value="form.originalListDate"
              :editing="editing"
              @input="value => updateForm('originalListDate', value)"
            />
          </li>
          <li>
            <editable-datepicker
              label="Current List Date"
              :value="form.currentListDate"
              :editing="editing"
              @input="value => updateForm('currentListDate', value)"
            />
          </li>
          <li>
            <editable-datepicker
              label="Signed Contract Date"
              :value="form.signedContractDate"
              :editing="editing"
              @input="value => updateForm('signedContractDate', value)"
            />
          </li>
          <li>
            <editable-currency
              label="Sale Price"
              :value="form.reoSalePrice"
              :editing="editing"
              @input="value => updateForm('reoSalePrice', value)"
            />
          </li>
          <li>
            <editable-input
              label="Listing Agent Name"
              :value="form.listingAgentName"
              :editing="editing"
              @input="e => updateForm('listingAgentName', e.target.value)"
            />
          </li>
        </ul>
      </div>
      <div class="column">
        <ul class="loan-block">
          <li>
            <editable-select
              label="Collect Rent"
              :value="form.collectRent"
              :options="booleanOptions"
              :editing="editing"
              @input="e => updateForm('collectRent', e.target.value)"
            />
          </li>
          <li>
            <editable-select
              label="Eviction Status"
              :value="form.evictionStatus"
              :options="evictionStatusOptions"
              :editing="editing"
              @input="e => updateForm('evictionStatus', e.target.value)"
            />
          </li>
          <li>
            <editable-datepicker
              label="Insurance Expiration Date"
              :value="form.insuranceExpirationDate"
              :editing="editing"
              @input="value => updateForm('insuranceExpirationDate', value)"
            />
          </li>
          <li>
            <editable-currency
              label="Original List Price"
              :value="form.originalListPrice"
              :editing="editing"
              @input="value => updateForm('originalListPrice', value)"
            />
          </li>
          <li>
            <editable-currency
              label="Current List Price"
              :value="form.currentListPrice"
              :editing="editing"
              @input="value => updateForm('currentListPrice', value)"
            />
          </li>
          <li>
            <editable-datepicker
              label="REO Sale Closing Date"
              :value="form.reoSaleClosingDate"
              :editing="editing"
              @input="value => updateForm('reoSaleClosingDate', value)"
            />
          </li>
          <li>
            <editable-currency
              label="Net Proceeds"
              :value="form.reoNetProceeds"
              :editing="editing"
              @input="value => updateForm('reoNetProceeds', value)"
            />
          </li>
        </ul>
      </div>
    </div>
  </editable-section>
</template>

<script>
import EditableInput from "./editable_input";
import EditableSection from "./editable_section";
import EditableCurrency from "./editable_currency";
import EditableDatepicker from "./editable_date";
import EditableSelect from "./editable_select";
import BaseForeclosureSection from "./base_foreclosure_section.js";

import has from "lodash/has";

export default {
  name: "reo-status",
  components: {
    EditableInput,
    EditableSection,
    EditableCurrency,
    EditableDatepicker,
    EditableSelect,
  },
  extends: BaseForeclosureSection,
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  computed: {
    booleanOptions() {
      return [{ id: true, text: "Yes" }, { id: false, text: "No" }];
    },
    evictionStatusOptions() {
      return [
        { id: "pre_complaint", text: "Pre-Complaint" },
        { id: "complaint", text: "Complaint" },
        { id: "hearing", text: "Hearing" },
        { id: "completed", text: "Completed" },
      ];
    },
  },
  methods: {
    isApplicable() {
      return !!(
        has(this.form, "purchasedBy") &&
        this.form.purchasedBy.toLowerCase() == "peerstreet" &&
        this.hasForeclosureWorkoutCase()
      );
    },
  },
};
</script>
