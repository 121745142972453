<template>
  <base-modal
    :show="show"
    title="Customize Task"
    class="task-mapping-edit-form"
    save-button-name="Update"
    :save-disabled="saveDisabled"
    @save="onSave"
    @close="onClose"
  >
    <div v-if="definition" class="pb-3">
      <base-header size="medium" class="pb-4">
        {{ definition.label }}
      </base-header>
      <base-tabs
        bordered
        :animated="false"
        class="task-mapping-edit-form-tabs"
      >
        <base-tab-item label="Details">
          <template v-slot:content>
            <details-edit-form
              :task-mapping="taskMapping"
              :trigger-statuses="triggerStatuses"
              :trigger-rules="triggerRules"
              @updateRules="onRulesChange"
              :errors="errors"
              @clearTriggerStatusError="clearTriggerStatusError"
            />
          </template>
        </base-tab-item>
        <base-tab-item label="Description">
          <template v-slot:content>
            <description-edit-form ref="descriptions" :task-mapping="taskMapping" @updateDescriptionRules="onDescriptionRulesChange" />
          </template>
        </base-tab-item>
      </base-tabs>
    </div>
  </base-modal>
</template>
<script>
import AjaxService from "services/ajax_service";
import DetailsEditForm from "./details_edit_form";
import DescriptionEditForm from "./description_edit_form";
import { manageTasklistTemplateTaskMappingPath } from "models/tasklist_template_revision";
import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";
import mapKeys from "lodash/mapKeys";
import isEmpty from "lodash/isEmpty";
import { store } from "../template_revision/store";

export default {
  components: {
    DetailsEditForm,
    DescriptionEditForm,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    taskMapping: {
      type: Object,
      default: () => ({}),
    },
    triggerStatuses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSaving: false,
      triggerRules: this.taskMapping?.conditions,
      descriptionRules: [],
      errors: {},
      definitions: store.state.rulesDefinitions,
      ruleDefinitionIds: [],
    };
  },
  computed: {
    saveDisabled() {
      return this.isSaving;
    },
    definition() {
      return this.taskMapping && this.taskMapping.taskDefinition;
    },
    defaultRule() {
      return this.taskMapping.taskDescriptionRules.find(rule => rule.defaultDescription);
    },
  },
  methods: {
    onClose() {
      this.taskMapping.conditions = this.conditions;
      this.$emit("close");
      this.errors = {};
      this.ruleDefinitionIds = [];
    },
    onSave() {
      this.save();
    },
    save() {
      const messages = { onSuccess: "Task mapping is saved", onError: this.onError };

      this.isSaving = true;

      return this.saveTask(messages)
        .then(taskMapping => {
          this.$emit("save", taskMapping);
          this.onClose();
        })
        .catch(() => {})
        .finally(() => {
          this.isSaving = false;
        });
    },
    saveTask(messages) {
      return AjaxService.withNotifications(messages).patchJSON(
        manageTasklistTemplateTaskMappingPath(this.taskMapping.id),
        {
          id: this.taskMapping.id,
          trigger_status: this.taskMapping.triggerStatus,
          task_description_rules: this.prepareRulesToSave(),
          conditions: this.triggerRules,
          rule_definition_ids: this.ruleDefinitionIds,
        }
      );
    },
    prepareRulesToSave() {
      if (this.taskMapping.useGlobalDescription) {
        return [
          {
            id: this.defaultRule.id,
            custom_description: null,
            default_description: true,
            conditions: {},
          },
        ];
      } else {
        let rules = this.taskMapping.taskDescriptionRules.map((rule, index) => ({
          id: rule.id,
          custom_description: rule.customDescription,
          default_description: !!(rule.defaultDescription && isEmpty(this.descriptionRules[index])),
          conditions: this.descriptionRules[index] || {},
        }));

        return rules;
      }
    },
    onRulesChange(newRules) {
      this.triggerRules = mapKeys(newRules, (value, key) => snakeCase(key));
      this.ruleDefinitionIds = Object.keys(newRules)
        .map(
          rule => (this.definitions[camelCase(rule)] ? this.definitions[camelCase(rule)].id : null)
        )
        .filter(id => id);
      if (this.errors.conditions) {
        this.errors.conditions = null;
      }
    },
    onDescriptionRulesChange(newRules, index) {
      const conditions = mapKeys(newRules, (value, key) => snakeCase(key));

      if (index < this.descriptionRules.length) {
        this.descriptionRules[index] = conditions;
      } else {
        this.descriptionRules.push(conditions);
      }
    },
    clearTriggerStatusError() {
      this.errors.trigger_status = null;
    },
    onError({ data }) {
      this.errors = data.errors || {};
      return { message: "Something went wrong", status: "error" };
    },
  },
  watch: {
    taskMapping(newValue) {
      this.descriptionRules = newValue.taskDescriptionRules.map(rule => rule.conditions);
    },
  },
};
</script>
<style scoped>
.task-mapping-edit-form >>> .modal-card {
  min-width: 600px;
  max-width: 600px;
}

.task-mapping-edit-form-tabs >>> .tabs {
  margin: 0 calc(var(--space-unit) * -3);
}

.task-mapping-edit-form-tabs >>> .tabs ul {
  padding: 0 calc(var(--space-unit) * 3);
}
</style>
