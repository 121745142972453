<template>
  <div class="details-row row">
    <div class="col-12">
      <h4>Overview</h4>
    </div>
    <div class="col-6 border-row" v-if="!estimatePayoffQuoteSelected">
      <div class="editable-field-container">
        <label class="loan-attribute-label control-label">{{ statementTypeLabel }}</label>
        <div class="">{{ statementTypeValue }}</div>
      </div>
    </div>
    <div class="col-6"></div>
    <div class="col-6" v-if="!estimatePayoffQuoteSelected">
      <div class="editable-field-container">
        <label class="loan-attribute-label control-label">Reason for Sale</label>
        <div class="">{{ statement.payoffReason }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import useResaleStatementStore from "controllers/manage/store/resaleStatement";

const LABELS = {
  lender_repurchase: "Purchaser",
  third_party_sale: "Purchaser",
  peerstreet_repurchase: "Seller",
};

const VALUES = {
  lender_repurchase: "statementTypeText",
  third_party_sale: "statementTypeText",
  peerstreet_repurchase: "seller",
};

export default {
  name: "payoff-statement-show-overview",
  computed: {
    ...mapState(useResaleStatementStore, ["statement"]),
    statementTypeLabel() {
      return LABELS[this.statement.statementType];
    },
    statementTypeValue() {
      const attr = VALUES[this.statement.statementType];
      return this.statement[attr];
    },
    estimatePayoffQuoteSelected() {
      return this.statement.statementType === "estimate_payoff_quote";
    },
  },
};
</script>
