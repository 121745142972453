<template>
  <table class="table permissions-roles-table">
    <thead>
    <tr>
      <th>Role</th>
      <th>Users</th>
    </tr>
    </thead>
    <tbody>
    <template v-for="role in roles">
      <tr>
        <td>
          <a href="#" @click.prevent="$emit('role-selected', role)">{{role.name}}</a>
        </td>
        <td>
          {{ role.users.length | pluralize("User") }}
        </td>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    roles: Array,
  },
};
</script>
