<template>
  <table class="table table-hover review-table">
    <thead>
      <tr>
        <th scope="col" class="text-right">
          Transaction ID
        </th>
        <th scope="col">
          Loan ID
        </th>
        <th scope="col" class="text-right">
          Legal Owner
        </th>
        <th scope="col">
          Review Items
        </th>
        <th v-if="showHoldReason" scope="col">
          Hold Reason
        </th>
        <th scope="col" class="text-right">
          Check Date
        </th>
        <th scope="col" class="text-right">
          Payment Due
        </th>
        <th scope="col" class="text-right">
          Amount
        </th>
        <th scope="col" class="text-right">
          Principal
        </th>
        <th scope="col" class="text-right">
          Interest
        </th>
        <th scope="col" class="text-right">
          Accrued Interest
        </th>
        <th scope="col" class="text-right">
          Fees
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.id" @click.prevent.stop="$emit('review', item)">
        <td>
          {{ item.id }}
          <span v-if="isReversed(item)" class="badge badge-danger">REVERSED</span>
        </td>
        <td scope="row" class="text-right">
          <div class="loan-ps-id-cell">
            <span v-if="item.isFastFundingFacility" class="fast-funding-facility-tag">FFF</span>
            <a href="#" @click.prevent.stop="openLoanPage(item)">
              {{ item.loan.id }}
            </a>
          </div>
        </td>
        <td class="text-right">
          {{ legalOwner(item) }} <span v-if="isDistributed(item)" class="badge badge-success">DIST</span>
        </td>
        <td>
          <status :flags="item.reviewFlags" />
        </td>
        <td v-if="showHoldReason">
          <icon :icon="'exclamation-triangle'" class="text-warning" />
          {{ item.holdReason }}
        </td>
        <td class="text-right">
          {{ item.cashReceipt.receivedDate | formatDate }}
        </td>
        <td class="text-right">
          {{ item.paymentDueDate | formatDate }}
        </td>
        <td class="text-right" :class="accountingClass(item.amount)">
          {{ item.totalAmount | accounting }}
        </td>
        <td class="text-right" :class="accountingClass(item.principal)">
          {{ detailAmount(item, "principal") | accounting }}
        </td>
        <td class="text-right" :class="accountingClass(item.interest)">
          {{ getTotalForDisbursementTypes(item.details, ["interest", "prepaid_interest"]) | accounting }}
        </td>
        <td class="text-right" :class="accountingClass(item.accruedInterest)">
          {{ item.accrualInterest | accounting }}
        </td>
        <td class="text-right" :class="accountingClass(item.fee)">
          {{ detailAmount(item, "fee") | accounting }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import sumBy from "lodash/sumBy";
import filter from "lodash/filter";

import Status from "../../shared/status";
import { isDistributed } from "../../shared/is_distributed";
import { isReversed } from "../../shared/is_reversed";
import { getTotalForDisbursementTypes } from "../../shared/calculation_helper";
import Icon from "components/shared/icon";

export default {
  components: {
    Status,
    Icon,
  },
  props: {
    showHoldReason: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getTotalForDisbursementTypes,
    isDistributed,
    isReversed,
    legalOwner(item) {
      if (item.primaryOwners.length === 0) {
        return "";
      }

      const owner = item.primaryOwners[0];

      if (owner.name === "ps_funding_inc") {
        return "Retail";
      } else {
        return owner.label;
      }
    },
    openLoanPage(item) {
      window.open(`/manage/loans/${item.loan.id}`, "_blank");
    },
    accountingClass(num) {
      return num < 0 ? "text-danger" : "";
    },
    detailAmount(item, disbursementType) {
      const filtered = filter(item.details, { disbursementType });
      return sumBy(filtered, detail => detail.revisedAmount || detail.originalAmount);
    },
  },
};
</script>
