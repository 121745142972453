<template>
  <div>
    <div v-if="!currentItem" class="row">
      <div class="col-sm-12">
        <div class="py-4">
          <a href="#" @click.prevent="$emit('back')">
            <icon icon="chevron-left" />
            Back to Ready to Distribute
          </a>
        </div>
      </div>

      <div class="col-sm-2 bg-light py-4">
        <filters v-bind="filters" readonly />
      </div>

      <div class="col-sm-10">
        <overview-list-item :legal-owner="legalOwner" :items="items" />
        <items :items="items" @review="handleReviewOpen" />
      </div>
    </div>

    <div v-else>
      <review-details :value="currentItem" @back="handleReviewClose" />
    </div>
  </div>
</template>

<script>
import Icon from "components/shared/icon";
import Filters from "../filters";
import OverviewListItem from "../overview/list_item";
import Items from "./items";
import ReviewDetails from "../../review/details";

export default {
  components: {
    Icon,
    Filters,
    OverviewListItem,
    Items,
    ReviewDetails,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    legalOwners: {
      type: Array,
      required: true,
    },
    groupedItems: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { currentItem: null };
  },
  computed: {
    legalOwner() {
      return this.legalOwners[0];
    },
    items() {
      return this.groupedItems[this.legalOwner.id];
    },
  },
  // TODO: [remove-hijack-redirect]
  mounted() {
    if (this.filters.transactionId) {
      const selectedItem = this.items.find(i => i.id.toString() === this.filters.transactionId);

      if (selectedItem) {
        this.handleReviewOpen(selectedItem);
        window.history.replaceState(
          null,
          "",
          window.location.href.replace(`&transactionId=${this.filters.transactionId}`, "")
        );
      }
    }
  },
  methods: {
    handleReviewOpen(item) {
      this.currentItem = item;
    },
    handleReviewClose() {
      this.currentItem = null;
    },
  },
};
</script>
