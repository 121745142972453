import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";

export default class CreditboxSetting {
  static buildEmpty() {
    return new CreditboxSetting(null, null, {}, []);
  }

  static deserialize({ id = null, loanProgramId = null, settings = {}, creditboxRuleIds = [] }) {
    return new CreditboxSetting(id, loanProgramId, JSON.parse(settings), creditboxRuleIds);
  }

  constructor(id, loanProgramId, settings, creditboxRuleIds) {
    this.id = id;
    this.loanProgramId = loanProgramId;
    this.settings = settings;
    this.creditboxRuleIds = creditboxRuleIds;
  }

  isPersisted() {
    return !!this.id;
  }

  clone() {
    return new CreditboxSetting(this.id, this.loanProgramId, this.settings, this.creditboxRuleIds);
  }

  toPlainObject({ toSnakeCase } = { toSnakeCase: false }) {
    const creditboxSetting = {
      id: this.id,
      loanProgramId: this.loanProgramId,
      settings: this.settings,
      creditboxRuleIds: this.creditboxRuleIds,
    };

    if (toSnakeCase) return mapKeys(creditboxSetting, (_, key) => snakeCase(key));

    return creditboxSetting;
  }
}
