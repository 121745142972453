<template>
  <a
    v-if="batch"
    download="loan_sales.csv"
    :href="loanSalesCsvDataUrl"
    target="_blank"
  >
    <icon icon="file-csv" />
  </a>
</template>

<script>
import Icon from "components/shared/icon";

const CSV_HEADERS = [
  "PS ID",
  "Initial Amount",
  "Sale Amount",
  "PS Spread",
  "Sales price",
  "Gain or Loss Amount",
  "Interest Amount",
  "Unpaid Principal Amount",
  "Interest Per Die Amount",
  "Interest Per Diem Days",
  "Escrow Reserve Amount",
].join(",");

export default {
  components: {
    Icon,
  },
  props: {
    batch: {
      type: Object,
      default: null,
    },
  },
  computed: {
    loanSalesCsvDataUrl() {
      return `data:text/csv;base64,${btoa(this.loanSalesCsv)}`;
    },
    loanSalesCsv() {
      const rows = this.batch.loanSales.map(
        ({
          initialAmount,
          saleAmount,
          psSpread,
          salesPrice,
          gainOrLossAmount,
          interestAmount,
          unpaidPrincipalAmount,
          interestPerDiemAmount,
          interestPerDiemDays,
          escrowReserveAmount,
          loan: { id: psId },
        }) =>
          [
            psId,
            initialAmount,
            saleAmount,
            psSpread,
            salesPrice,
            gainOrLossAmount,
            interestAmount,
            unpaidPrincipalAmount,
            interestPerDiemAmount,
            interestPerDiemDays,
            escrowReserveAmount,
          ].join(",")
      );

      rows.unshift(CSV_HEADERS);
      return rows.join("\n") + "\n";
    },
  },
};
</script>
