export const TASKS_FILTER = {
  allTasks: "allTasks",
  openTasks: "openTasks",
  pendingTasks: "pendingTasks",
  completedTasks: "completedTasks",
};

export const filterToSave = filters => {
  if (filters.includes(TASKS_FILTER.allTasks)) {
    return TASKS_FILTER.allTasks;
  }

  return filters.join("|");
};

export const parseFilter = filter => {
  return (filter || "").split("|");
};

export const DEFAULT_TASKS_FILTER = `${TASKS_FILTER.openTasks}|${TASKS_FILTER.pendingTasks}`;
