<template>
  <div>
    <div class="task-list-item panel-bordered">
      <div class="task-list-row">
        <div class="task-list-column task-list-content">
          <div class="task-list-fold-container">
            <div class="workflow-fold">
              <i v-show="hasDescription && !visible" class="fa fa-caret-right" @click="onShow" />
              <i v-show="hasDescription && visible" class="fa fa-caret-down" @click="onHide" />
            </div>
          </div>
          <div v-if="isCheckboxEnabled" class="checkbox-control form-group">
            <div class="checkbox">
              <input type="checkbox" :checked="isTaskComplete" />
              <label>
                <span class="control-toggle" @click.once="onComplete" />
              </label>
            </div>
          </div>
          <div v-else class="task-list-icon">
            <i class="fas" :class="styleTaskCheckbox" />
          </div>
          <div class="task-list-label">
            {{ task.label }}
          </div>
        </div>
        <div class="task-list-column task-list-assigned">
          <user-editable-select
            :model="task"
            :editing="true"
            :disabled="isFormComponentDisabled"
            :value="form.assignedToId"
            :users="workflowUsers"
            @input="change('assignedToId', $event.target.value)"
          />
        </div>
        <div class="task-list-column task-list-due-date">
          <div v-show="isWorkflowOpen" class="input-wrapper" :class="{ ['bold-red']: isPastDue }">
            <base-tooltip
              :active="isAfterWorkflowDueDate"
              label="Task's due date is over workflow's due date"
            >
              <input
                v-datepicker
                v-mask:date
                class="form-control foreclosure-input"
                :value="form.dueDate"
                :disabled="isFormComponentDisabled"
                @input="change('dueDate', $event.target.value)"
              />
            </base-tooltip>
          </div>
        </div>
        <div class="task-list-column task-list-actions">
          <task-action-button
            :model="task"
            :disabled="editDisabled"
            @dismiss="onDismiss"
            @reopen="onReopen"
            @duplicate="duplicate"
          />
        </div>
      </div>
      <div v-if="showTaskDetailsBadge">
        <div class="closed-details-badge-container">
          <detail-badge>
            <div class="closed-details-badge-text">
              {{ task.status }} by <span class="bold">{{ task.updatedBy }}</span> on <span class="bold">{{ task.updatedAt }}</span>
            </div>
          </detail-badge>
        </div>
      </div>
      <div v-show="visible && hasDescription" class="task-list-description">
        <task-description :value="task.description" />
      </div>
    </div>

    <div v-if="loadingDuplicate" class="task-list-item panel-bordered loading-task">
      <base-loading :show="true" :is-full-page="false" />
    </div>
  </div>
</template>
<script>
import UserEditableSelect from "./user_editable_select";
import TaskActionButton from "./task_action_button";
import foldableMixin from "mixins/foldable_mixin";
import AjaxService from "services/ajax_service";
import objectToSnakeCase from "utils/object_to_snake_case";
import debounce from "lodash/debounce";
import moment from "moment";
import TaskDescription from "./task_description";
import DetailBadge from "./detail_badge";

const DEBOUNCE_WAIT = 500;
const UPDATE_UI_MS_DELAY = 500;

export default {
  components: {
    UserEditableSelect,
    TaskActionButton,
    TaskDescription,
    DetailBadge,
  },
  mixins: [foldableMixin],
  props: {
    editDisabled: Boolean,
    task: Object,
    workflow: Object,
  },
  data() {
    return {
      loadingDuplicate: false,
      form: {
        status: this.task.status,
        dueDate: this.task.dueDate || "",
        assignedToId: this.task.assignedToId,
        id: this.task.id,
      },
    };
  },
  computed: {
    showTaskDetailsBadge() {
      return this.task.status !== "open";
    },
    isTaskComplete() {
      return this.form.status === "completed";
    },
    isTaskDismissed() {
      return this.form.status === "dismissed";
    },
    isOpen() {
      return this.form.status === "open";
    },
    isCheckboxEnabled() {
      return this.isOpen && !this.editDisabled;
    },
    isCheckboxDisabled() {
      return this.isOpen && this.editDisabled;
    },
    isFormComponentDisabled() {
      return !this.isOpen || this.editDisabled;
    },
    isWorkflowOpen() {
      return !this.workflow.closedAt;
    },
    styleTaskCheckbox() {
      return {
        "fa-check-circle": this.isTaskComplete,
        "fa-circle": this.isCheckboxDisabled,
        "fa-minus-circle": this.isTaskDismissed,
      };
    },
    workflowUsers() {
      return PSData.workflowUsers.map(u => ({ id: u.id, text: u.name }));
    },
    workflowDueDate() {
      return this.workflow.dueDate;
    },
    taskDueMoment() {
      return moment(this.form.dueDate, "L");
    },
    todayIsAfterDueDate() {
      const yesterdayMoment = moment().add(-1, "days");
      return yesterdayMoment.isAfter(this.taskDueMoment);
    },
    isAfterWorkflowDueDate() {
      const workflowDueMoment = moment(this.workflowDueDate, "L");
      return this.taskDueMoment.isAfter(workflowDueMoment);
    },
    isPastDue() {
      return this.todayIsAfterDueDate || this.isAfterWorkflowDueDate;
    },
    hasDescription() {
      return !!this.task.description;
    },
  },
  watch: {
    task(newVal) {
      this.form = {
        status: newVal.status,
        dueDate: newVal.dueDate || "",
        assignedToId: newVal.assignedToId,
        id: newVal.id,
      };
    },
  },
  methods: {
    change(fieldName, value) {
      if (this.form[fieldName] !== value) {
        this.$set(this.form, fieldName, value);
        this.onSaveDebounced();
      }
    },
    updateTask(data, messages = {}) {
      const url = this.task.routes.update;
      const params = objectToSnakeCase(data);
      return AjaxService.withNotifications({
        onSuccess: "Task was updated successfully",
        onError: "Failed to update a task",
        ...messages,
      }).patchJSON(url, params);
    },
    async updateTaskStatus(status, messages = {}) {
      try {
        this.form.status = status;
        const task = await this.updateTask(this.form, messages);
        setTimeout(() => {
          this.$emit("update-task", task.assetManagementTaskTemplateId, task);
        }, UPDATE_UI_MS_DELAY);
      } catch ({ response, data }) {
        if (response.status === 422) {
          this.errors = data.errors;
        }
      }
    },
    async duplicate() {
      const url = this.task.routes.duplicate;
      const params = objectToSnakeCase(this.task);
      const ajax = AjaxService.withNotifications({
        onSuccess: "Task was duplicated successfully",
        onError: "Failed to duplicate task",
      });
      this.loadingDuplicate = true;
      try {
        const newTask = await ajax.postJSON(url, params);
        this.$emit("add-duplicate-task", { duplicate: newTask, original: this.task });
      } catch ({ response, data }) {
        if (response.status === 422) {
          this.errors = data.errors;
        }
      }
      this.loadingDuplicate = false;
    },
    onComplete() {
      return this.updateTaskStatus("completed", {
        onSuccess: "Task was completed successfully",
        onError: "Failed to complete a task",
      });
    },
    onDismiss() {
      return this.updateTaskStatus("dismissed", {
        onSuccess: "Task was dismissed successfully",
        onError: "Failed to dismiss a task",
      });
    },
    onReopen() {
      return this.updateTaskStatus("open", {
        onSuccess: "Task was opened successfully",
        onError: "Failed to open a task",
      });
    },
    async onSave() {
      try {
        this.isSaving = true;
        const task = await this.updateTask(this.form);
        this.$emit("update-task", task);
      } catch ({ response, data }) {
        if (response.status === 422) {
          this.errors = data.errors;
        }
      } finally {
        this.isSaving = false;
      }
    },
    onSaveDebounced: debounce(function() {
      this.onSave();
    }, DEBOUNCE_WAIT),
  },
};
</script>

<style scoped>
.closed-details-badge-container {
  width: fit-content;
  margin-top: -8px;
  margin-left: 70px;
  margin-bottom: 5px;
}
.closed-details-badge-text {
  font-size: 10px;
  font-weight: lighter;
}
.bold {
  font-weight: 600;
}
.bold-red input {
  color: var(--color-red);
  font-weight: 600;
}
.loading-task {
  height: 57px;
  position: relative;
}
</style>
