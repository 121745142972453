<template>
  <div class="settlement-statement-details">
    <h4 class="section-title">Comments</h4>
    <div class="comments-container">

      <div v-if="editMode" class="comment-controls">
        <div class="new-comments">
          <div class="fields-wrapper" v-for="comment in newComments" :key="comment.key">
            <div class="date-picker-wrapper">
              <input

                v-model="comment.actionableDate"
                name="date"
                type="text"
                class="date-picker form-control"
                v-datepicker
                v-mask:date />
            </div>

            <div class="comment-edit">
              <select v-model="comment.selected" >
                <option></option>
                <option v-for="option in commentOptions" :key="option">{{option}}</option>
              </select>

              <div v-if="comment.selected === 'Other'" class="freeform-input">
                <textarea
                  class="form-control"
                  v-model="comment.comment"
                  :placeholder="customCommentPlaceholder"
                 />
              </div>
            </div>
          </div>
        </div>

        <div class="comment-add">
          <a href="#" @click.prevent="newComment">+ Add another comment</a>
        </div>

        <div class="fields-wrapper" v-for="comment in existingComments" :key="comment.key" v-show="!comment.delete">
          <div>
            <input
              v-model="comment.actionableDate"
              name="date"
              type="text"
              class="date-picker form-control"
              v-datepicker
              v-mask:date />
          </div>

          <div class="comment-edit">
            <select v-model="comment.selected" >
              <option></option>
              <option v-for="option in commentOptions" :key="option">{{option}}</option>
            </select>

            <div v-if="comment.selected === 'Other'" class="freeform-input">
              <textarea
                class="form-control"
                v-model="comment.comment"
                :placeholder="customCommentPlaceholder"
                />
            </div>
          </div>
          <a v-if="editMode" @click.prevent="deleteComment(comment)" href="#" class="comment-delete"></a>
        </div>
      </div>

      <div v-else class="fields-wrapper" v-for="comment in displayComments" :key="comment.key">
        <div>{{ comment.actionableDate }}</div>
        <div class="comment">{{comment.comment}}</div>
      </div>
      <p v-if="!hasComments">No Comment</p>
    </div>
  </div>
</template>
<script>
import moment from "moment";

const STANDARD_COMMENTS = [
  "Originator will collect next installment from borrower and retain full payment.",
  "PeerStreet will set up loan with servicer to collect the following payment from borrower.",
  "Next payment has been deducted from interest reserve.",
  "Rama/Athas will handle the assignment transfer.",
];
const FREEFORM_OPTION = "Other";
const COMMENT_PLACEHOLDER_TEXT = "Custom comment text.";
const DATE_FORMAT = "MM/DD/YYYY";

const formatDate = date => moment(date).format(DATE_FORMAT);

const commentObj = (key = "new-0") => ({
  key,
  comment: "",
  actionableDate: formatDate(Date.now()),
});

const formatComment = comment => ({
  ...comment,
  selected: setSelectedComment(comment),
  key: comment.id,
  actionableDate: formatDate(moment(comment.actionableDate)),
});

const setSelectedComment = comment => {
  if (!comment.comment) return;
  return STANDARD_COMMENTS.includes(comment.comment) ? comment.comment : FREEFORM_OPTION;
};

const prepareExistingComments = comments => comments.map(formatComment).sort((a, b) => a.id - b.id);

export default {
  name: "SettlementStatementComments",
  props: { comments: { type: Array, default: () => [] }, editMode: Boolean },
  data() {
    return {
      commentOptions: [...STANDARD_COMMENTS, FREEFORM_OPTION],
      newComments: [commentObj()],
      existingComments: prepareExistingComments(this.comments),
      customCommentPlaceholder: COMMENT_PLACEHOLDER_TEXT,
    };
  },
  watch: {
    editMode(newValue, old) {
      if (!newValue && old) this.newComments = [commentObj()];
    },
    comments(newValue, old) {
      this.existingComments = prepareExistingComments(newValue);
      this.newComments = [commentObj()];
    },
    newComments: {
      immediate: false,
      deep: true,
      handler: function() {
        this.updateComment();
      },
    },
    existingComments: {
      immediate: false,
      deep: true,
      handler: function() {
        this.updateComment();
      },
    },
  },
  computed: {
    displayComments() {
      return this.existingComments.map(comment => {
        return {
          ...comment,
          actionableDate: formatDate(comment.actionableDate),
        };
      });
    },
    hasComments() {
      return this.comments.length;
    },
  },
  methods: {
    newComment() {
      this.newComments.push(commentObj(`new-${this.newComments.length}`));
    },
    updateComment(e) {
      const nextComments = [...this.newComments, ...this.existingComments].map(comment => {
        if (comment.selected !== FREEFORM_OPTION) {
          return { ...comment, comment: comment.selected };
        }
        return comment;
      });

      this.$emit("update-comments", nextComments);
    },
    deleteComment(comment) {
      this.$set(comment, "delete", true);
    },
  },
};
</script>
