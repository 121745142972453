<template>
  <div>
    <time-series-comparison-chart class="home-index-chart" :chart="chart" :sources="sources">
      <template slot="header">
        <p>
          {{ sources.totalAmount | money }}
          <span>{{ sources.totalCount | pluralize("Loan") }}</span>
        </p>
      </template>
    </time-series-comparison-chart>
  </div>
</template>

<script>
import { format } from "d3";

import TimeSeriesComparisonChart from "components/market_data/time_series_comparison_chart";
import MarketDataChartMixin from "components/market_data/market_data_chart_mixins";

export default {
  name: "all-time-sales-chart",
  components: {
    TimeSeriesComparisonChart,
  },
  props: {
    sources: Object,
  },
  data() {
    let yTickCount = 5;
    let yMax = this.sources.totalAmount || 500000;
    let yTickValues = Array.apply(null, Array(yTickCount)).map(
      (v, i) => (i * yMax) / (yTickCount - 1)
    );

    return {
      chart: {
        dataType: "line",
        hideLegend: true,
        sizeHeight: 160,
        padding: {
          right: 30,
        },
        axisXTickCount: Math.min(this.sources.totalMonths, 4),
        axisYMin: 0,
        axisYMax: yMax,
        axisYTickCount: yTickCount,
        axisYTickValues: yTickValues,
        tickFormat: format("$,.2s"),
        tooltipFormat: format("$,.2f"),
        title: this.sources.name,
        columns: {
          sales: {
            color: "#66cc33",
            observed: {
              data: "Total",
              labels: "total-amount-observed",
            },
            predicted: {
              data: "Total (forecast)",
              labels: "total-amount-predicted",
            },
          },
        },
      },
    };
  },
};
</script>
