import MidasService from "services/midas_service";
import toPlainObject from "utils/to_plain_object";

const Command = PS.Models.Command;
const Events = PS.Models.Reports.Events;
const Analytics = PS.Models.MarketData.Analytics;
const Tables = PS.Models.MarketData.Tables;
const Charts = PS.Models.MarketData.Charts;
const Common = PS.Models.Reports.Commands.Common;
const LocationService = PS.Services.LocationService;
const SegmentService = PS.Services.SegmentService;

class RequestReportLoading extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.MsaReport.MsaReportRequested,
      PS.Services.MessageBusService.lenderBus,
      this.event.options
    );
  }
}

class RequestMidasDataChange extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.MsaReport.MidasDataChangeRequested,
      PS.Services.MessageBusService.autocompleteBus,
      this.event.options
    );
  }
}

class ClearReport extends Command {
  execute() {
    Object.assign(this.state, {
      midasData: undefined,
      midasError: undefined,

      reportIsLoading: false,

      analytics: {
        midasMsa: new Analytics.MidasMsa(),
        midasNational: new Analytics.MidasNational(),
      },

      tables: {
        msaAtGlance: new Tables.MsaAtGlance(),
      },

      charts: {
        homeIndex: new Charts.MsaHomeIndex(),
        unemploymentRate: new Charts.UnemploymentRate(),
        riskOfDecline: new Charts.RiskOfDecline(),
        jobGrowth: new Charts.JobGrowth(),
        housingStockValue: new Charts.HousingStockValue("msaCrossSection"),
        housingStockAge: new Charts.HousingStockAge("msaCrossSection"),
        housingStockType: new Charts.HousingStockType("msaCrossSection"),
        housingStockSize: new Charts.HousingStockSize("msaCrossSection"),
      },
    });

    return Promise.resolve();
  }
}

class StartLoading extends Command {
  execute() {
    this.state.reportIsLoading = true;

    return Promise.resolve();
  }
}

class EndLoading extends Command {
  execute() {
    this.state.reportIsLoading = false;

    return Promise.resolve();
  }
}

class LoadMidasData extends Command {
  execute() {
    const payload = {
      source: this.event.source,
      code: this.event.geoId,
      type: this.event.geoType,
    };

    return MidasService.msaReport(payload.type, payload.code).then(
      ({ data }) => {
        this.state.midasData = data;
      },
      result => {
        this.state.midasError = result;

        Bugsnag.notify(new Error("Unable to get lender searched midas data"), {
          metaData: { custom: { response: toPlainObject(result), payload: payload } },
        });
      }
    );
  }
}

class AddMidasDataToAnalytics extends Command {
  execute() {
    const { midasData, midasError } = this.state;
    const analytics = this.state.analytics;

    if (midasError) {
      const message = "Unable to get House Canary data";

      analytics.midasMsa.fail(message);
      analytics.midasNational.fail(message);
    } else if (midasData.error) {
      const message = midasData.error[0];

      analytics.midasMsa.fail(message);
      analytics.midasNational.fail(message);
    } else {
      analytics.midasMsa.success(midasData.msaCrossSection, midasData.msaTimeSeriesSummary);
      analytics.midasNational.success(
        midasData.nationalCrossSection,
        midasData.nationalTimeSeriesSummary
      );
    }

    return Promise.resolve();
  }
}

class SetMsaInput extends Command {
  execute() {
    const node = this.event.midasData.msaCrossSection;
    const suggestions = this.state.dataSource.prepareSuggestions([node]);

    this.state.dataSource.simulateAutocompleteValidation(suggestions, 0);

    return Promise.resolve();
  }
}

class TrackMsaReportLink extends Command {
  execute() {
    const data = {
      zip: this.event.zip,
      source: PS.Services.LocationService.href,
    };

    SegmentService.track("searchMsaReportLink", data);

    return Promise.resolve();
  }
}

class TrackMsaReportBanner extends Command {
  execute() {
    const data = {
      zip: this.event.zip,
      source: PS.Services.LocationService.href,
    };

    SegmentService.track("searchMsaReportBanner", data);

    return Promise.resolve();
  }
}

class TrackReport extends Command {
  execute() {
    const type = this.event.geoType;
    const data = {
      source: this.event.source,
    };

    if (type === "zip") {
      data["zip"] = this.event.geoId;
    } else {
      data["msa"] = this.event.geoId;
    }

    SegmentService.track("searchMsaReport", data);

    return Promise.resolve();
  }
}

PS.Models.Reports.Commands.MsaReport = {
  RequestReportLoading: RequestReportLoading,
  RequestMidasDataChange: RequestMidasDataChange,
  ClearReport: ClearReport,
  LoadMidasData: LoadMidasData,
  AddMidasDataToAnalytics: AddMidasDataToAnalytics,
  AddMidasDataToCharts: Common.AddMidasDataToCharts,
  StartLoading: StartLoading,
  EndLoading: EndLoading,
  AddMidasDataToTables: Common.AddMidasDataToTables,
  SetMsaInput: SetMsaInput,
  TrackReport: TrackReport,
  TrackMsaReportLink: TrackMsaReportLink,
  TrackMsaReportBanner: TrackMsaReportBanner,
};
