<template>
  <div class="legend">
    <slot name="legend-prefix-content"></slot>

    <div
      v-for="(column, key) in columns"
      class="legend-group"
      v-show="display[key]">

      <span
        class="legend-item"
        :key="key"
        :class="classes[key]"
        @mouseover="onMouseOver(key)"
        @mouseout="onMouseOut(key)"
        @click="onClick(key)">

        <span :style="{ backgroundColor: column.color }"></span>
        {{column.label}}
      </span>

      <div v-if="subtext">
        <span class="totals">{{subtext[key] | money}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: Object,
    classes: Object,
    subtext: Object,
    display: Object,
  },
  methods: {
    onMouseOver(key) {
      this.$emit("legend-mouse-over", key);
    },
    onMouseOut(key) {
      this.$emit("legend-mouse-out", key);
    },
    onClick(key) {
      this.$emit("legend-click", key);
    },
  },
};
</script>
