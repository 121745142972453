<template>
  <li
    class="eligible-buyers-input-checkbox"
    :class="{
      'eligible-buyers-input-checkbox-checked': isCheckedBuyer,
      'eligible-buyers-input-checkbox-disabled': isDisabledBuyer,
      'eligible-buyers-input-checkbox-edit-mode': editMode,
      'eligible': isEligible,
    }"
    @click="toggle"
  >
    <span class="eligible-buyers-input-checkbox-name">{{ buyer.shortName }}</span>
    <div class="eligible-buyers-input-checkbox-details">
      <h4>{{ buyer.label }}</h4>
      <div v-if="hasIssues" class="eligible-buyers-input-checkbox-issues">
        <div
          v-for="error in buyer.errors"
          :key="buyer.name + error"
          class="eligible-buyers-input-checkbox-details-item error"
        >
          {{ error }}
        </div>
        <div
          v-for="warning in buyer.warnings"
          :key="buyer.name + warning"
          class="eligible-buyers-input-checkbox-details-item warning"
        >
          {{ warning }}
        </div>
      </div>
    </div>
  </li>
</template>
<script>
export default {
  props: {
    buyer: {
      type: Object,
      default: function() {
        return {
          name: "?",
          shortName: "?",
        };
      },
    },
    includedBuyers: {
      type: Array,
      default: function() {
        return [];
      },
    },
    disabledBuyers: {
      type: Array,
      default: function() {
        return [];
      },
    },
    editMode: Boolean,
  },
  computed: {
    isCheckedBuyer() {
      return !this.isDisabledBuyer && this.includedBuyers.includes(this.buyer.name);
    },
    isDisabledBuyer() {
      return !this.buyer.active || this.disabledBuyers.includes(this.buyer.name);
    },
    isEligible() {
      return this.buyer.eligible;
    },
    hasErrors() {
      return Array.isArray(this.buyer.errors) && this.buyer.errors.length > 0;
    },
    hasWarnings() {
      return Array.isArray(this.buyer.warnings) && this.buyer.warnings.length > 0;
    },
    hasIssues() {
      return this.hasErrors || this.hasWarnings;
    },
  },
  methods: {
    toggle() {
      if (this.isDisabledBuyer) {
        return;
      }
      const checked = !this.isCheckedBuyer;
      this.$emit("toggle-buyer", this.buyer, checked);
    },
  },
};
</script>

<style lang="scss" scoped>
$color-grey-86: #2b3b44;
$color-grey-06: #f1f2f2;
$color-white: #fff;

.eligible-buyers-input {
  &-checkbox {
    border: 1px $color-grey-86 solid;
    font-size: 10px;
    font-weight: 600;
    height: 25px;
    list-style: none;
    margin-bottom: 4px;
    margin-right: 4px;
    padding: 4px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: top;
    width: 25px;

    &-checked {
      background-color: $color-grey-86;
      color: $color-white;
    }

    &-disabled {
      background-color: $color-grey-06;
      border: 1px $color-grey-06 solid;
      color: $color-white;
    }

    &-edit-mode {
      cursor: pointer;
    }

    &-details {
      --details-width: 300px;

      background-color: var(--color-white);
      box-shadow: 0 5px 10px 0 var(--color-grey);
      color: var(--color-grey-86);
      display: none;
      min-width: var(--details-width);
      padding: var(--space-unit) var(--space-unit-md);
      position: absolute;
      text-align: left;
      top: -1;
      z-index: 100;

      h4 {
        font-size: 12px;
        font-weight: 800;
        padding-top: 10px;
      }

      &-item {
        --bullet-size: 14px;

        font-size: 10px;
        font-weight: 400;
        padding: var(--space-unit) 0 var(--space-unit) calc(var(--bullet-size) + var(--space-unit));
        position: relative;

        &.error::before {
          background-color: var(--color-red);
        }

        &.warning::before {
          background-color: var(--color-yellow);
        }
      }

      &-item::before {
        border-radius: 50%;
        content: "";
        height: var(--bullet-size);
        left: 0;
        margin-top: calc(var(--bullet-size) / -2);
        position: absolute;
        top: 50%;
        width: var(--bullet-size);
      }
    }
  }

  &-checkbox:hover > &-checkbox-details {
    display: block;
  }

  &-checkbox-disabled:hover > &-checkbox-details > &-checkbox-issues {
    display: none;
  }
}
</style>
