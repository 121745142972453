<template>
  <base-modal-form
    :save-disabled="isSaving"
    :show="show"
    :title="modalTitle"
    @save="onSave"
    @close="onCancel"
  >
    <base-text-field
      v-model="task.name"
      :disabled="!isNewTask()"
      :errors="errors.name"
      placeholder="new_task_name"
      label="name"
    />
    <base-text-field
      v-model="task.label"
      placeholder="Task Label"
      label="label"
      :errors="errors.label"
    />
    <base-dropdown
      v-model="task.milestone"
      label="milestone"
    >
      <base-dropdown-item
        :value="null"
        :class="task.milestone == null ? 'is-active' : ''"
      >
        None
      </base-dropdown-item>
      <base-dropdown-item
        v-for="milestone in workflowMilestones"
        :key="milestone.apiName"
        :value="milestone.apiName"
      >
        {{ milestone.title }}
      </base-dropdown-item>
    </base-dropdown>
    <base-text-field v-model="task.description" label="description" type="textarea" />
    <base-button size="small" @click="showPreview = !showPreview">
      Toggle Preview
    </base-button>
    <div class="p-1">
      <markdown-view v-if="showPreview" :value="task.description" />
    </div>
    <base-number-field v-model="task.sequence" label="sequence" :errors="errors.sequence" />
    <base-checkbox v-model="task.enabled" label="enabled" />
  </base-modal-form>
</template>

<script>
import Ajax from "services/ajax_service";
import MarkdownView from "components/shared/markdown_view";
import objectToSnakeCase from "utils/object_to_snake_case";

export default {
  components: { MarkdownView },
  props: {
    task: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      modalTitle: "",
      activeTab: 0,
      showPreview: false,
      workflowMilestones: PSData.workflowMilestones,
      errors: {},
    };
  },
  watch: {
    task() {
      this.modalTitle = this.task.label;
    },
  },
  methods: {
    onSave() {
      this.isSaving = true;
      const messages = {
        onError: ({ response }) => {
          if (response.status === 403) {
            return { message: "Unauthorized", status: "error" };
          } else {
            return { message: "Invalid Parameters", status: "error" };
          }
        },
      };
      if (this.isNewTask()) {
        Ajax.withNotifications(messages)
          .postJSON("/manage/asset_management_task_templates", objectToSnakeCase(this.task))
          .then(data => {
            this.$emit("new-task-template-saved", data);
            this.onCancel();
          })
          .catch(({ data }) => {
            this.errors = data.errors;
          })
          .finally(() => {
            this.isSaving = false;
          });
      } else {
        Ajax.withNotifications(messages)
          .patchJSON(
            `/manage/asset_management_task_templates/${this.task.id}`,
            objectToSnakeCase(this.task)
          )
          .then(data => {
            this.$emit("task-template-updated", data);
            this.onCancel();
          })
          .catch(({ data }) => {
            this.errors = data.errors;
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
    },
    isNewTask() {
      return this.task.id === null;
    },
    onCancel() {
      this.$emit("close");
      this.errors = {};
    },
  },
};
</script>

<style>
.markdown-content {
  word-break: break-word;
}
</style>
