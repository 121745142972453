<template>
  <div>
    <h3 class="mt-1 mb-4">
      Filters
    </h3>

    <loan-id-filter
      name="loanId"
      :value="loanId"
      :readonly="readonly"
      @filter="handleFilter"
    />

    <transaction-type-filter
      name="transactionType"
      :value="transactionType"
      label="Transaction"
      :readonly="readonly"
      @filter="handleFilter"
    />

    <date-filter
      name="fromDate"
      :value="fromDate"
      label="From"
      :readonly="readonly"
      @filter="handleFilter"
    />

    <date-filter
      name="toDate"
      :value="toDate"
      label="To"
      :readonly="readonly"
      @filter="handleFilter"
    />

    <legal-owners-filter
      v-if="!readonly"
      name="legalOwnerNames"
      :value="legalOwnerNames"
      label="Owners"
      @filter="handleFilter"
    />
  </div>
</template>

<script>
import TransactionTypeFilter from "./transaction_type_filter";
import LegalOwnersFilter from "./legal_owners_filter";
import LoanIdFilter from "../../shared/filters/loan_id_filter";
import DateFilter from "../../shared/filters/date_filter";

export default {
  components: {
    DateFilter,
    TransactionTypeFilter,
    LegalOwnersFilter,
    LoanIdFilter,
  },
  props: {
    transactionType: {
      type: String,
      default: "all",
    },
    fromDate: {
      type: String,
      default: "",
    },
    toDate: {
      type: String,
      default: "",
    },
    loanId: {
      type: String,
      default: "",
    },
    legalOwnerNames: {
      type: Array,
      default: () => [],
    },
    transactionId: {
      type: String,
      default: "",
    },
    ownerName: {
      type: String,
      default: "",
    },
    readonly: Boolean,
  },
  computed: {
    filters() {
      const {
        transactionType,
        fromDate,
        toDate,
        legalOwnerNames,
        loanId,
        transactionId,
        ownerName,
      } = this;
      return {
        transactionType,
        fromDate,
        toDate,
        legalOwnerNames,
        loanId,
        transactionId,
        ownerName,
      };
    },
  },
  mounted() {
    this.$emit("filter", this.filters);
  },
  methods: {
    handleFilter(name, value) {
      this.$emit("filter", { ...this.filters, [name]: value });
    },
  },
};
</script>
