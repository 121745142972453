const API_VERSION = "3";
const API_LIBS = "geometry,places";

let promise;

export default function requireGoogleMap(callback) {
  if (window.__ps_test_mode) {
    return;
  }

  if (!window.google) {
    Bugsnag.notify(new Error("Google API wasn't loaded properly"));
  }

  const API_KEY = PSData.global.apiGoogleMapsKey;
  if (!API_KEY) {
    Bugsnag.notify(new Error("Google API key is missing"));
  }

  if (!promise) {
    promise = new Promise(resolve => {
      google.load("maps", API_VERSION, {
        other_params: `key=${API_KEY}&libraries=${API_LIBS}`,
        callback: resolve,
      });
    });
  }

  return promise.then(callback);
}
