<template>
  <div class="credit-box-success">
    <span class="check"></span>
    <p>Fits Preliminary Credit Box</p>
    <LpsExplain :context="{ groupId }" :enabled="viewLpsResults" />
  </div>
</template>

<script>
import LpsExplain from "components/lps_explain";

export default {
  name: "credit-box-success",
  components: {
    LpsExplain,
  },
  props: {
    viewLpsResults: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: [Number, String],
      require: true,
    },
  },
};
</script>
