<template>
  <diligence-document-field :document="document" :field="field">
    <div class="col-5">
      <input
        v-if="field.mask.type == 'decimal'"
        :id="field.id"
        v-model="field.value"
        v-mask:decimal="field.mask.options"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @blur="saveValueImmediately"
      />
      <input
        v-else=""
        :id="field.id"
        v-model="field.value"
        v-mask:number="field.mask.options"
        class="form-control"
        type="text"
        :disabled="disabled"
        :readonly="field.hasAutocalculation()"
        @blur="saveValueImmediately"
      />
    </div>
  </diligence-document-field>
</template>

<script>
import TextField from "./base/text_field";

const NumberField = TextField.extend({});

export default Vue.component("diligence-document-number-field", NumberField);
</script>
