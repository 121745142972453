import { defineStore } from "pinia";
import pick from "lodash/pick";
import Notification from "components/loan_scenarios/utils/notification";
import useCriteriaStore from "./criteria";
import useLtvFicoGridStore from "./ltv_fico_grid";
import useRuleStore from "./rule";
import locale from "../locales/en";
import UPDATE_METHOD from "../consts/update_methods";

const usePreviewStore = defineStore("preview", {
  state: () => ({
    id: null,
    adjustments: [],
    isPreviewLoading: false,
    showPreviewAlert: false,
    assignRoute: false,
  }),
  getters: {
    hasAdjustments() {
      return this.adjustments.length > 0;
    },
    createPreviewParams() {
      const criteria = useCriteriaStore();
      const ltvFicoGrid = useLtvFicoGridStore();
      const rule = useRuleStore();

      const params = {
        criteria: pick(criteria, [
          "underwritingStatuses",
          "programTypes",
          "rateChangeType",
          "flatRateAdjustment",
          "updateMethod",
        ]),
      };
      if (criteria.isGrid) {
        params.ltvFicoGrid = pick(ltvFicoGrid, ["columns", "rows", "adjustments"]);
      }
      if (rule.hasAdjustments) {
        params.rule = pick(rule, ["adjustments"]);
      }

      return { pricingToolPreview: params };
    },
    isNewPreview() {
      return this.id === "new";
    },
  },
  actions: {
    assignState(params) {
      const { id, items, settings } = params;
      const criteria = useCriteriaStore();
      const ltvFicoGrid = useLtvFicoGridStore();
      const rule = useRuleStore();
      const uploadedCsvFile = criteria.uploadedCsvFile;
      if (this.id !== id) {
        this.assignRoute = true;
      }
      this.id = id;
      this.adjustments = items;

      if (settings.criteria) {
        criteria.$state = settings.criteria;
        criteria.uploadedCsvFile = uploadedCsvFile;
        criteria.isAssignedState = true;
      }
      if (settings.ltvFicoGrid) {
        ltvFicoGrid.$state = settings.ltvFicoGrid;
      }
      if (settings.rule) {
        rule.$state = settings.rule;
      }

      if (this.assignRoute) {
        this.assignRoute = false;
        const path = `/run_batch/${this.id}`;
        if (this.$router.resolve(params).route.fullPath !== path) {
          this.$router.push(path);
        }
      }
    },
    async applyPreviewOperation(operation) {
      this.isPreviewLoading = true;

      try {
        const result = await operation();
        this.assignState(result.data);
      } catch ({ response, data }) {
        Notification.error();
      } finally {
        this.isPreviewLoading = false;
      }
    },
    async initializePreview() {
      const id = this.id;
      useCriteriaStore().$reset();
      useLtvFicoGridStore().$reset();
      useRuleStore().$reset();
      this.$reset();
      this.id = id;

      if (!this.isNewPreview) {
        this.loadPreview();
      }
    },
    async loadPreview() {
      const operation = async _ => await this.$api.previews.read(this.id);

      await this.applyPreviewOperation(operation);
    },
    async generatePreview() {
      this.hideAlert();
      const operation = async _ => {
        let result;
        const criteria = useCriteriaStore();

        if (this.isNewPreview) {
          result = await this.$api.previews.create(this.createPreviewParams);
        } else {
          result = await this.$api.previews.update(this.id, this.createPreviewParams);
        }

        if (criteria.updateMethod === UPDATE_METHOD.CSV_UPLOAD) {
          result = await this.$api.previews.import(result.data.id, criteria.uploadedCsvFile);
        }

        return result;
      };

      return await this.applyPreviewOperation(operation);
    },
    async applyAdjustments() {
      try {
        await this.$api.applications.create({});
        await this.$router.push({ name: "history" });
        useCriteriaStore().$reset();
        useLtvFicoGridStore().$reset();
        useRuleStore().$reset();
        usePreviewStore().$reset();
        Notification.success(locale.notification.updateScheduled);
      } catch ({ response, data }) {
        Notification.error();
      }
    },
    downloadCsv(previewId) {
      const previewUrl = PSData.routes.previewUrl;

      if (previewId && previewUrl) {
        const downloadUrl = [previewUrl, previewId, "download"].join("/");
        window.location.href = downloadUrl;
      }
    },
    async revert(previewId) {
      const operation = async _ => await this.$api.previews.revert(previewId);

      try {
        await this.applyPreviewOperation(operation);
        Notification.success(locale.notification.revertScheduled);
      } catch ({ response, data }) {
        Notification.error();
      }
    },
    hideAlert() {
      this.showPreviewAlert = false;
    },
    showAlert() {
      if (this.isPreviewLoading || this.showPreviewAlert || !this.hasAdjustments) return;

      this.showPreviewAlert = true && !this.isNewPreview;
    },
  },
});

export default usePreviewStore;
