<template>
  <base-modal-form
    :show="show"
    :save-disabled="isDisabled"
    :cancel-options="cancelOptions"
    @close="onClose"
    @save="onSave"
    :title="title"
    :save-button-name="bttnName"
    class="rule-modal"
  >
    <form>
      <p>To add or remove percentages to this batch, please set additional constraints below.</p>
      <div class="columns">
        <base-select-field
          v-model="rule.attr"
          label="Select Rule"
          :options="ruleTypes"
          showEmpty
          class="column is-full"
        />
      </div>
      <div v-if="rule.attr === 'loan_amount'" class="columns" >
        <base-select-field
          v-model="rule.condition"
          label="Is"
          :options="operators"
          showEmpty
          rules="required"
          class="column is-half"
        />
        <base-currency-field
          label=" "
          v-model="rule.value"
          rules="required|min_number_value:0"
          :positive="true"
          class="column is-half"
        />
      </div>
      <div v-if="rule.attr === 'state'" class="columns">
        <state-selector
          :rule-states="rule.value"
          @state-added="onStateAdded"
          @state-removed="onStateRemoved"
        />
      </div>

      <div v-if="rule.attr" class="column is-full">
        <label>Adjust Rate</label>
        <base-tooltip
          :label="locale.tooltip.adjustRate"
          position="is-top"
          multilined
        >
          <base-icon icon="question-circle" type="hint" />
        </base-tooltip>
        <base-percent-field
          v-model="rule.adjustment"
          :positive="false"
          rules="required|between:-100,100"
        />
      </div>
    </form>
  </base-modal-form>
</template>

<script>
import useRuleStore from "./stores/rule";
import StateSelector from "./state_selector";
import { RULES, OPERATORS } from "./utils/rule";
import { cloneDeep, flattenDeep } from "lodash";

export default {
  name: "rule-modal",
  inject: ["locale"],
  components: {
    StateSelector,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    rawRule: Object,
    ruleIndex: Number,
  },
  data() {
    const ruleStore = useRuleStore();
    return {
      ruleStore,
      ruleTypes: RULES,
      operators: OPERATORS,
      rule: {},
      openOnFocus: false,
      cancelOptions: ["escape", "x"],
    };
  },
  watch: {
    show(isShow) {
      if (isShow) {
        this.reset();
      }
    },
    "rule.attr": {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === "state" && oldVal === "loan_amount") {
          this.rule.value = [];
          this.rule.condition = "in";
          this.rule.adjustment = null;
        }

        if (newVal === "loan_amount" && oldVal === "state") {
          this.rule.value = null;
          this.rule.condition = null;
          this.rule.adjustment = null;
        }
      },
    },
  },
  computed: {
    title() {
      return this.ruleIndex === null ? `Add Rule` : `Update Rule`;
    },
    bttnName() {
      return this.title;
    },
    isDisabled() {
      if (Object.keys(this.rule).length < 4) {
        return true;
      }

      const arr = flattenDeep(Object.values(this.rule));
      return arr.some(e => {
        return e === null || e === undefined || e === "";
      });
    },
  },
  methods: {
    reset() {
      this.rule = cloneDeep(this.rawRule);
    },
    onClose() {
      this.rule = {};
      this.$emit("close");
    },
    onSave() {
      this.save();
      this.rule = {};
      this.$emit("preview-alert");
      this.$emit("close");
    },
    save() {
      if (this.ruleIndex === null) {
        this.ruleStore.addRule(this.rule);
      } else {
        this.ruleStore.updateRule(this.ruleIndex, this.rule);
      }
    },
    onStateAdded(tag) {
      if (!this.rule.value?.length) {
        this.rule.value = [];
      }
      if (!this.rule.condition?.length) {
        this.rule.condition = "in";
      }

      this.rule.value.push(tag);
    },
    onStateRemoved(tag) {
      const index = this.rule.value.findIndex(t => t === tag);
      if (index !== -1) {
        this.rule.value.splice(index, 1);
      }
    },
  },
};
</script>
<style>
.rule-modal .modal-card-body {
  padding-bottom: 24px;
}
</style>
