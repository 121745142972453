const IDLE_SESSION_CHECK = 30 * 1000; // Check every 30 seconds if session is "idle"
const IDLE_SESSION_KEY = "peerstreet.session.expires_at";
const DEFAULT_IDLE_SESSION_MIN_TTL = 30;
import LocalStorageService from "services/local_storage_service";

export default class IdleSession {
  constructor() {
    this.storage = new LocalStorageService();
  }

  ttl() {
    return DEFAULT_IDLE_SESSION_MIN_TTL * 60 * 1000;
  }

  userSignedIn() {
    return PSData.user && PSData.user.signedIn === "yes";
  }

  setExpiresAt() {
    const expiresAt = new Date(Date.now() + this.ttl());

    this.storage.setItem(IDLE_SESSION_KEY, expiresAt);
  }

  scheduleIdleCheck() {
    this.intervalId = setInterval(this.ping.bind(this), IDLE_SESSION_CHECK);
  }

  reloadPage() {
    document.location.reload();
  }

  ping() {
    const expiresAt = this.storage.getItem(IDLE_SESSION_KEY);

    if (expiresAt && new Date(expiresAt) <= new Date()) {
      this.reloadPage();
    }
  }

  handleAjaxNotification(e, notification) {
    if (notification && notification.status === "success") {
      this.refreshIdleSessionWindow();
    }
  }

  run() {
    if (this.userSignedIn()) {
      this.setExpiresAt();
      this.scheduleIdleCheck();
      $(window.PS).on("ajaxNotification.add", this.handleAjaxNotification.bind(this));
    }
  }

  refreshIdleSessionWindow() {
    if (this.userSignedIn()) {
      this.setExpiresAt();
    }
  }

  teardown() {
    clearInterval(this.invervalId);
    $(window.PS).off("ajaxNotification.add", this.handleAjaxNotification.bind(this));
  }
}
