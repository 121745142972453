<template>
  <div v-if="batch">
    <div>
      <b>Batch Status: </b>
      <span v-if="isCompleted">Completed</span>
      <span v-else-if="isFailed">
        Failed  {{ failedPercent | percent(0, true) }}
        <copy-to-clipboard :value="failedLoanIds" title="Copy the failed Loan IDs to clipboard" />
      </span>
      <span v-else>Running {{ runningPercent | percent(0, true) }}</span>
    </div>

    <div>
      <b>Retail Deposit Status: </b>
      <span> {{ batch.depositStatus }} </span>
    </div>
  </div>
</template>

<script>
import CopyToClipboard from "components/copy_to_clipboard";

export default {
  name: "LoanSaleBatchStatus",
  components: {
    CopyToClipboard,
  },
  props: {
    batch: {
      type: Object,
      default: null,
    },
  },
  computed: {
    runningPercent() {
      return this.percentByStatus("complete");
    },
    failedPercent() {
      return this.percentByStatus("failed");
    },
    isCompleted() {
      return this.batch.loanSales.every(sale => this.overallStatus(sale) === "complete");
    },
    isFailed() {
      return this.failedSales.length > 0;
    },
    failedSales() {
      return this.salesByStatus("failed");
    },
    failedLoanIds() {
      return this.failedSales.map(sale => sale.loan.id).join("\n");
    },
  },
  methods: {
    percentByStatus(status) {
      const filteredSalesCount = this.salesByStatus(status).length;
      const totalSalesCount = this.batch.loanSales.length;

      return (filteredSalesCount / totalSalesCount) * 100;
    },
    salesByStatus(status) {
      return this.batch.loanSales.filter(sale => this.overallStatus(sale) === status);
    },
    overallStatus(loanSale) {
      const { status, retailStatus } = loanSale;

      if (status === "failed" || retailStatus === "failed") {
        return "failed";
      }

      if (status === "pending" || retailStatus === "pending") {
        return "pending";
      }

      return "complete";
    },
  },
};
</script>
