<template>
  <div v-if="showBanner" class="disclosure-banner">
    <base-message customHeader @close="onClose">
      <template v-slot:header>
        New Feature: Share Tasks with your Borrower(s) today! <base-tag type="success">new</base-tag>
      </template>

      <p>
        Speed up the closing process by inviting borrower(s) to complete their tasks directly.
        Only borrower tasks with your branding will be shared and you will be able to track their progress.
      </p>

      <div class="controls">
<!--        <base-button @click="openHelp">Learn More</base-button>-->
        <share-tasks-button type="primary" :peersuite-subdomain-is-set="peersuiteSubdomainIsSet" />
      </div>
    </base-message>
  </div>
</template>

<script>
import LocalStorageService from "services/local_storage_service";
import ShareTasksButton from "components/loans/share_tasks_button";

// TODO add Learn More button back, once article is ready
// const HELP_LINK = "https://peerstreet.zendesk.com/hc/en-us/articles/360055350452";
const HIDE_BANNER = "ps.share_tasks_banner.hide";

export default {
  name: "share-tasks-banner",
  components: { ShareTasksButton },
  props: {
    loanId: {
      type: String,
      required: true,
    },
    peersuiteSubdomainIsSet: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const storage = new LocalStorageService();

    return {
      storage,
      settings: storage.getItem(HIDE_BANNER) || [],
    };
  },
  computed: {
    showBanner() {
      return !this.settings.find(el => el === this.loanId);
    },
  },
  methods: {
    openHelp() {
      window.open(HELP_LINK, "_blank");
    },
    onClose() {
      this.settings.push(this.loanId);
      this.storage.setItem(HIDE_BANNER, this.settings);
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.disclosure-banner {
  margin: 24px 0;
}

.disclosure-banner .base-tag {
  margin: 0;
  position: relative;
  top: -2px;
}

.disclosure-banner .controls {
  padding: 16px 0 0;
}

.disclosure-banner .controls .button {
  margin-right: 16px;
}
.disclosure-banner >>> .base-button.is-normal:not(.is-primary) {
  color: var(--color-grey-72);
}
</style>
