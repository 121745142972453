<template>
  <div>
    <slot>
      <div ref="entryPoint" />
    </slot>
  </div>
</template>

<script>
/**
 * This connector is an alternative to micro_frontend_connect.vue.
 * In contrast, this connector also loads CSS and expects a manifest file with an object of the kind similar to this:
 * {
 *   "entrypoints": {
 *     "applicationName": {
 *       "js": ["js_file_url", ...]
 *       "css": ["css_file_url", ...]
 *     }
 *   }
 * }
 */
function camelize(str) {
  const camelCase = str.replace(/[_-](\w)/g, (_, char) => char.toUpperCase());
  return `${camelCase[0].toUpperCase()}${camelCase.slice(1)}`;
}
export default {
  props: {
    serviceUrl: { type: String, required: true },
    applicationName: { type: String, required: true },
    props: { type: Object, default: () => ({}) },
  },
  emits: ["ready"],
  data: () => ({
    manifest: {},
    linkElements: [],
  }),
  computed: {
    manifestUrl() {
      // Convention: use webpacker defaults for manifest path and name
      return `${this.serviceUrl}/packs/manifest.json`;
    },
    entrypoint() {
      return this.manifest["entrypoints"][this.applicationName];
    },
    jsUrls() {
      return this.entrypoint["js"].map(path => `${this.serviceUrl}${path}`);
    },
    cssUrls() {
      // Entrypoint can have no external styles at all
      const urls = this.entrypoint["css"] || [];
      return urls.map(path => `${this.serviceUrl}${path}`);
    },
    application() {
      // Convention:
      // <MfeConnect application-name="tenant_onboarding" /> expects JS entrypoint to expose
      // window.MFEBorrowerTenantOnboarding micro-frontend with mount() and unmount() functions.
      return window[`MFE${camelize(this.applicationName)}`];
    },
  },
  async mounted() {
    if (!this.applicationName || !this.$refs.entryPoint) return;
    await this.loadManifest();
    await this.loadApplication();
    this.mountApplication();
    this.$emit("ready");
  },
  beforeUnmount() {
    this.unmountApplication();
    this.unloadApplication();
  },
  methods: {
    async loadManifest() {
      const response = await fetch(this.manifestUrl, { cache: "no-store", credentials: "include" });
      if (!response.ok) {
        throw new Error(`Failed to load ${this.manifestUrl}: ${response.status}`);
      }
      this.manifest = await response.json();
    },
    async loadApplication() {
      const cssLoaders = this.cssUrls.map(this.loadApplicationCss);
      const jsLoaders = this.jsUrls.map(this.loadApplicationJs);
      await Promise.all(cssLoaders.concat(jsLoaders));
    },
    unloadApplication() {
      this.unloadApplicationCss();
    },
    mountApplication() {
      this.application.mount({ element: this.$refs.entryPoint, ...this.props });
    },
    unmountApplication() {
      // Allow entrypoints with no unmount
      if (!this.application.unmount) return;
      this.application.unmount();
    },
    loadApplicationCss(url) {
      return new Promise((resolve, reject) => {
        const link = document.createElement("link");
        Object.assign(link, {
          href: url,
          rel: "stylesheet",
          media: "all",
          onerror: reject,
          onload() {
            this.onload = null;
            resolve();
          },
        });
        document.head.appendChild(link);
        this.linkElements.push(link);
      });
    },
    unloadApplicationCss() {
      this.linkElements.forEach(element => element.remove());
    },
    loadApplicationJs(url) {
      return import(/* webpackIgnore: true */ url);
    },
  },
};
</script>
