import { timeFormat } from "d3";
import c3 from "c3";

class AreaChart {
  constructor(container) {
    this.container = container;
    this.options = this.getChartOptions();
    this.options.bindto = this.container;
  }

  render(points) {
    if (this.chart) {
      this.chart.destroy();
    }

    if (points.length > 0) {
      this.options.data = this.prepareData(points);
      this.chart = c3.generate(this.options);
    }
  }

  getChartOptions() {
    return {
      axis: {
        x: {
          type: "timeseries",
          tick: {
            count: 10,
            format: "%b %Y",
          },
        },
      },
      point: {
        r: 0,
        focus: {
          expand: {
            r: 5,
          },
        },
      },
      area: {
        zerobased: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        position: (data, width, height, element) => {
          var x = parseInt(element.getAttribute("width")) - width;
          return { top: 0, left: x };
        },
        contents: (d, defaultTitleFormat, defaultValueFormat, color) => {
          return `<div id="tooltip">Date: <b>${timeFormat("%b %Y")(d[0].x)}</b> Value: <b>${
            d[0].value
          }</b></div>`;
        },
      },
    };
  }

  prepareData(points) {
    var observed = this.getPoints(points, false);
    var predicted = this.getPoints(points, true);

    return {
      xs: {
        observed: "x-observed",
        predicted: "x-predicted",
      },
      columns: [
        ["x-observed"].concat(observed.labels),
        ["x-predicted"].concat(predicted.labels),
        ["observed"].concat(observed.data),
        ["predicted"].concat(predicted.data),
      ],
      type: "area-spline",
      labels: false,
      colors: {
        observed: "rgba(102, 204, 51, 0.90)",
        predicted: "rgba(102, 204, 51, 0.50)",
      },
    };
  }

  getPoints(points, predicted) {
    var filtered = points.filter(point => point.prediction == predicted);

    return this.convertPoints(filtered);
  }

  convertPoints(points) {
    return {
      labels: points.map(point => point.effectiveAt),
      data: points.map(point => point.value),
    };
  }
}

class PointsFilter {
  constructor(data) {
    this.points = data;
  }

  hdi() {
    return this.filterPoints(this.points, "hdis");
  }

  reo() {
    return this.filterPoints(this.points, "reos");
  }

  filterPoints(points, type) {
    return points.filter(point => point.type == type);
  }
}

window.PS.MarketData = {};
PS.MarketData.AreaChart = AreaChart;
PS.MarketData.PointsFilter = PointsFilter;
