import AssetManagement from "./asset_management_root";
import { createPinia } from "pinia";

$(() => {
  new Vue({
    el: document.querySelector(".v-asset-management-controller"),
    render: h => h(AssetManagement),
    pinia: createPinia(),
  });
});
