<template>
  <div class="pricing-estimator">
    <pricing-results
      v-if="showResults"
      :pricing="pricing"
      :viewLpsResults="viewLpsResults"
      :display-for="displayFor"
      :program-classification="programClassification"
    />

    <p v-else>Complete the form to receive a pricing estimate based on your loan characteristics.</p>
  </div>
</template>

<script>
import PricingResults from "components/pricing_estimator/results";
import isEmpty from "lodash/isEmpty";

export default {
  name: "pricing-guide",
  components: {
    PricingResults,
  },
  props: {
    pricing: Object,
    viewLpsResults: {
      type: Boolean,
      default: false,
    },
    isEstimating: {
      type: Boolean,
      default: false,
    },
    programClassification: String,
    displayFor: String,
  },
  computed: {
    showResults() {
      return !isEmpty(this.pricing);
    },
  },
};
</script>
