<template>
  <div v-cloak>
    <div class="settings-list edit-permitted clearfix">
      <div class="settings-header-wrapper sticky-element" :data-offset="151">
        <div class="clearfix settings-row settings-header">
          <div class="item-name">Lender</div>
          <template v-for="(category, key) in options.categories">
            <div :key="key" class="category">
              {{ category.name }}
            </div>
          </template>
          <email-subscriptions-actions
            :has-changes="hasChanges"
            :updating="updating"
          ></email-subscriptions-actions>
        </div>
      </div>
      <div class="clearfix settings-row">
        <div class="item-name">
          {{ globalLender.name }}
        </div>
        <template v-for="(subscription, key) in globalLender.subscriptions">
          <div :key="key" class="category">
            <email-subscriptions-all-item
              :subscription="subscription"
            ></email-subscriptions-all-item>
          </div>
        </template>
      </div>
      <template v-for="(lender, key) in sortedLenders">
        <div :key="key" class="clearfix settings-row">
          <div class="item-name">
            {{ lender.name }}
          </div>
          <template v-for="(subscription, key) in lender.subscriptions">
            <div :key="key" class="category">
              <email-subscriptions-item :subscription="subscription"></email-subscriptions-item>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const Commands = PS.Models.EmailSubscription.Commands;
const Event = PS.Models.Event;

export default Vue.component("email-subscriptions-table", {
  data: function() {
    return {
      options: PSData.options,
      subscriptions: PSData.subscriptions,
      globalLender: {},
      lenders: [],
      changes: new PS.Models.SettingChanges(),
      updating: false,
      rulebook: undefined,
      messageBus: PS.Services.MessageBusService.settingsBus,
    };
  },
  created: function() {
    this.rulebook = new PS.Models.EmailSubscription.TableRule(this);
    this.messageBus.attachRulebook(this.rulebook);
  },
  mounted: function() {
    new Commands.Table.RequestLendersSetup(new Event(), {}).execute();
  },
  beforeDestroy: function() {
    this.messageBus.detachRulebook(this.rulebook);
  },
  computed: {
    sortedLenders: function() {
      return this.lenders.sort((a, b) => a.name.localeCompare(b.name));
    },
    hasChanges: function() {
      return this.changes.hasChanges();
    },
  },
});
</script>
