<template>
  <div class="lender-legal-entity">
    <breadcrumb-navigation :items="breadcrumbItems()"></breadcrumb-navigation>
    <h3 class="lender-entities-header">History</h3>
    <versions-table :versions="versions"></versions-table>
  </div>
</template>

<script>
import BreadcrumbNavigation from "components/shared/breadcrumb_navigation";
import LegalEntity from "models/lender_legal_entities/entity";
import VersionsTable from "components/shared/versions_table";

export default Vue.component("lender-legal-entity-history", {
  components: {
    BreadcrumbNavigation,
    VersionsTable,
  },
  data: function() {
    return {
      entity: new LegalEntity(PSData.lenderLegalEntity, PSData.lenderLegalEntityOptions),
      routes: PSData.routes || {},
      versions: PSData.versions,
    };
  },
  methods: {
    breadcrumbItems() {
      return [
        {
          name: "Entities",
          link: this.routes.lenderLegalEntities,
        },
        {
          name: this.entity.name,
          link: this.routes.lenderLegalEntity,
        },
        {
          name: "History",
        },
      ];
    },
  },
});
</script>
