export default class CrudApi {
  constructor({ client, path }) {
    this.path = path;
    this.client = client;
  }

  async list() {
    let response = await this.client.get(this.path);
    return response.data;
  }

  async create(payload) {
    let response = await this.client.post(this.path, payload);
    return response.data;
  }

  async read(id) {
    let response = await this.client.get(`${this.path}/${id}`);
    return response.data;
  }

  async update(id, payload) {
    let response = await this.client.put(`${this.path}/${id}`, payload);
    return response.data;
  }

  async delete(id) {
    let response = await this.client.delete(`${this.path}/${id}`);
    return response.data;
  }
}
