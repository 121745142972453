<template>
  <div class="row loan-details-view-panel-row">
    <div class="col-6 loan-details-view-panel-column">
      <details-list :data="summaryList" />
    </div>
  </div>
</template>

<script>
import DetailsList from "components/loan_details/list";

export default {
  components: {
    DetailsList,
  },
  props: {
    records: {
      type: Array,
      required: true,
    },
  },
  computed: {
    summaryList() {
      return [
        {
          label: "Total track record",
          value: this.records.length,
          show: true,
        },
        {
          label: "Loan strategy - Fix and Flip",
          value: this.recordsByLoanStrategy("fix_n_flip"),
          show: true,
        },
        {
          label: "Loan strategy - Fix to Rent",
          value: this.recordsByLoanStrategy("fix_to_rent"),
          show: true,
        },
        {
          label: "Loan strategy - Rental",
          value: this.recordsByLoanStrategy("rental"),
          show: true,
        },
        {
          label: "Loan strategy - Flip",
          value: this.recordsByLoanStrategy("flip"),
          show: true,
        },
      ];
    },
  },
  methods: {
    recordsByLoanStrategy(strategy) {
      return (this.records || []).filter(record => record.loanStrategy === strategy).length;
    },
  },
};
</script>
