<template>
  <view-panel class="loan-details-view-panel">
    <template slot="header">
      Roles
      <div class="float-right">
        <button class="btn btn-cancel-modal" @click="$emit('on-cancel')">
          Cancel
        </button>
        <button class="btn btn-action-modal" @click="updateRoles">
          Update
        </button>
      </div>
    </template>
    <div class="row loan-details-view-panel-row">
      <div class="col-6 loan-details-view-panel-column">
        <form>
          <form-row
            v-for="(role, index) in party.roles"
            :key="role.id"
            :role="role"
            :index="index"
            :borrowers="borrowers"
            :role-definitions="roleDefinitions"
            :errors="getRoleErrors(index)"
            :is-entity="party.isEntity"
            @remove-role="removeRole"
          />
          <a v-if="canAddMoreRoles" href="#" @click.prevent="addNewRole">+ Add Another Role</a>
        </form>
      </div>
    </div>
  </view-panel>
</template>

<script>
import ViewPanel from "components/view_panel.vue";
import FormRow from "../responsible_roles/form_row";
import ResponsibleParty from "models/responsible_party";
import AjaxService from "services/ajax_service";
import get from "lodash/get";

export default {
  name: "responsible-roles-details-form",
  components: { FormRow, ViewPanel },
  props: {
    rawParty: {
      type: Object,
      required: true,
    },
  },
  data() {
    const party = ResponsibleParty.deserialize(this.rawParty);
    const borrowers = PSData.borrowers;
    const roleDefinitions = PSData.responsibleRoleDefinitions;
    const routes = PSData.routes;
    const errors = {};

    return {
      party,
      errors,
      routes,
      borrowers,
      roleDefinitions,
    };
  },
  computed: {
    canAddMoreRoles() {
      return this.party.canAddMoreRoles;
    },
  },
  watch: {
    party: {
      deep: true,
      handler() {
        this.resetErrors();
      },
    },
  },
  methods: {
    addNewRole() {
      this.party.addNewRole();
    },
    removeRole(role) {
      this.party.removeRole(role);
    },
    getRoleErrors(index) {
      return get(this.errors, "responsible_roles", [])[index];
    },
    updateRoles() {
      const params = { responsible_party: this.party.toPlainObject({ toSnakeCase: true }) };

      AjaxService.withNotifications()
        .patchJSON(this.routes.updateDetails, params)
        .then(data => this.$emit("on-update", data))
        .catch(({ response, data }) => {
          if (response.status === 422) {
            this.errors = data.errors;
          } else {
            Bugsnag.notify(new Error(`Unable to update responsible party: ${response.statusText}`));
          }
        });
    },
    resetErrors() {
      this.errors = {};
    },
  },
};
</script>
