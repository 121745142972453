<template>
  <div class="loan-task-view">
    <div class="loan-task-view-header">
      <div class="loan-task-view-header-back" @click="onBackButton">
        <img
          class="loan-task-view-header-back-icon"
          :src="require('images/icons/icon_left_arrow_task.svg')" />
        Back
      </div>
      <div v-if="task.isOpen" class="loan-task-buttons-container custom-loan-task-buttons">
        <i v-if="editable" class="fas fa-pencil-alt fa-lg" @click="showEditForm"></i>
      </div>
      <div
        v-if="!task.isOpen"
        class="loan-task-buttons-container custom-loan-task-buttons-completed">
        <i class="fas fa-check"></i>
        Completed
      </div>
    </div>
    <div v-if="shouldShowEditForm" class="form-task-section edit-task-section">
      <p>{{ task.title }}</p>
      <sla-task-form
        :task="task"
        :task-type="task.definition.taskType"
        @canceled="hideEditForm"
        @form-submitted="updateTask"
      >
        <template slot="submit-text">
          <span>Update</span>
        </template>
      </sla-task-form>
    </div>

    <div v-else class="loan-task-events">
      <div class="loan-task-content">
        <div class="loan-task-view-section">
          <div class="loan-task-view-title">
            {{ task.title }}
          </div>
          <div class="loan-task-view-section-content">
            <loan-task-field-container label="Started At">
              {{ task.startedAt }}
            </loan-task-field-container>
            <loan-task-field-container v-if="task.isOpen" label="Expected At">
              {{ task.expectedAt }}
            </loan-task-field-container>
            <loan-task-field-container v-if="!task.isOpen" label="Completed At">
              {{ task.completedAt }}
            </loan-task-field-container>
          </div>
        </div>
        <div v-show="isLoading" class="loan-task-loader">
          <div class="ps-loader ps-loader-small" />
        </div>
        <comments-list :comments="comments" />
      </div>

      <div class="messages-widget">
        <internal-comments-form :task="task" @created="onNewComment" />
      </div>
    </div>
  </div>
</template>
<script>
import DetailsMixin from "components/activity_feed_events/loan_tasks/details_mixin";
import SlaTaskForm from "components/activity_feed_events/manage/loan_tasks/sla/form";
import InternalCommentsForm from "components/activity_feed_events/loan_tasks/comments/manage/form";
import LoanTaskFieldContainer from "components/activity_feed_events/manage/loan_tasks/field_container";

export default {
  name: "loan-tasks-sla-details",
  components: {
    SlaTaskForm,
    InternalCommentsForm,
    LoanTaskFieldContainer,
  },
  mixins: [DetailsMixin],
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    shouldShowEditForm: false,
  }),
  methods: {
    showEditForm() {
      this.shouldShowEditForm = true;
    },
    hideEditForm() {
      this.shouldShowEditForm = false;
    },
  },
};
</script>
