<template>
  <view-panel class="loan-details-view-panel">
    <div class="view-panel-header loan-details-valuation-header">
      {{ valuation.reportTypeText }} Report
    </div>
    <div class="row loan-details-view-panel-row">
      <div class="col-6 loan-details-view-panel-column">
        <details-list :data="columnOneRows" />
      </div>
      <div class="col-6 loan-details-view-panel-column">
        <details-list :data="columnTwoRows" />
      </div>
    </div>
  </view-panel>
</template>

<script>
import ViewPanel from "components/view_panel.vue";
import DetailsList from "components/loan_details/list";
import PropertyValuation from "models/property_valuation";

export default {
  name: "loan-valuation-details",
  components: {
    ViewPanel,
    DetailsList,
  },
  props: {
    valuation: {
      type: PropertyValuation,
      required: true,
    },
  },
  data: function() {
    return {
      valuationMethods: PSData.valuationOptions.valuationMethods,
      appraisers: PSData.valuationOptions.appraisers,
      commissioners: PSData.valuationOptions.commissioners,
    };
  },
  methods: {
    findOptionValue(name, options) {
      var option = options.filter(option => option.name === name)[0] || {};
      return option.value;
    },
  },
  computed: {
    columnOneRows() {
      return [
        {
          label: "Type of Valuation",
          value: this.findOptionValue(this.valuation.valuationMethod, this.valuationMethods),
          show: true,
        },
        {
          label: "Party Completing Valuation",
          value: this.findOptionValue(this.valuation.appraiser, this.appraisers),
          show: true,
        },
        {
          label: "Commissioner",
          value: this.findOptionValue(this.valuation.commissioner, this.commissioners),
          show: true,
        },
        { label: "Date of Valuation", value: this.valuation.date, show: true },
        { label: "As-Is Value($)", value: this.valuation.asIsAmount, show: true },
        { label: "ARV($)", value: this.valuation.arvAmount, show: true },
        { label: "Monthly Market Rent($)", value: this.valuation.monthlyMarketRent, show: true },
      ];
    },
    columnTwoRows() {
      return [
        {
          label: "Deferred Maintenance",
          value: this.valuation.deferredMaintenanceAmount,
          show: true,
        },
        { label: "Appraiser Name", value: this.valuation.appraiserName, show: true },
        { label: "Appraiser Company", value: this.valuation.appraiserCompany, show: true },
        { label: "License Number", value: this.valuation.licenseNumber, show: true },
        { label: "License State", value: this.valuation.licenseState, show: true },
      ];
    },
  },
};
</script>
