import Command from "models/command";
import MessageBusService from "services/message_bus_service";
import {
  DocumentsUpdated,
  NewDocumentsUploaded,
  DocumentDeleted,
  OneDocumentUpdated,
} from "models/loan/events/document_events";
import DocumentService from "services/document_service";
import TaskAttachmentsService from "services/task_attachments_service";

export class RequestDocumentsUpdate extends Command {
  async execute() {
    return Command.broadcastEvent(DocumentsUpdated, MessageBusService.loanBus, this.event.options);
  }
}

export class RequestAddNewDocuments extends Command {
  async execute() {
    return Command.broadcastEvent(
      NewDocumentsUploaded,
      MessageBusService.loanBus,
      this.event.options
    );
  }
}

export class RequestDocumentRemoval extends Command {
  async execute() {
    return Command.broadcastEvent(DocumentDeleted, MessageBusService.loanBus, this.event.options);
  }
}

export class RequestOneDocumentUpdate extends Command {
  async execute() {
    return DocumentService.update(this.event.document)
      .then(document => {
        Command.broadcastEvent(OneDocumentUpdated, MessageBusService.loanBus, { document });
      })
      .catch(response => {
        const document = response.data.document;
        if (document) {
          Command.broadcastEvent(OneDocumentUpdated, MessageBusService.loanBus, { document });
        }
      });
  }
}

export class AttachTaskToDocument extends Command {
  async execute() {
    return TaskAttachmentsService.create(this.event.document.id, this.event.taskId).then(
      document => {
        Command.broadcastEvent(OneDocumentUpdated, MessageBusService.loanBus, { document });
      }
    );
  }
}

export class DetachTaskFromDocument extends Command {
  async execute() {
    return TaskAttachmentsService.destroy(this.event.document.id, this.event.taskAttachmentId).then(
      document => {
        Command.broadcastEvent(OneDocumentUpdated, MessageBusService.loanBus, { document });
      }
    );
  }
}

export class UpdateDocuments extends Command {
  async execute() {
    this.state.documents = this.event.documents;
  }
}

export class AddDocuments extends Command {
  async execute() {
    this.state.documents = this.state.documents.concat(this.event.documents);
  }
}

export class RemoveDocument extends Command {
  async execute() {
    this.state.documents = this.state.documents.filter(document => {
      return document.id !== this.event.document.id;
    });
  }
}

export class UpdateOneDocument extends Command {
  async execute() {
    const newDocument = this.event.document;
    const updatedDocuments = this.state.documents.filter(document => {
      return document.id !== newDocument.id;
    });

    updatedDocuments.push(newDocument);

    this.state.documents = updatedDocuments;
  }
}
