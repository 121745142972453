<template>
  <a
    @click.stop.prevent="openPreview"
    :href="loanUrl"
    title="Open quick preview"
    class="open-quick-preview"
    :class="{ 'has-activity': hasActivity }">
  </a>
</template>

<script>
import GlobalBusService from "services/global_bus_service";

export default {
  name: "open-loan-quick-preview",
  props: {
    loanId: {
      type: String,
      default: null,
    },
    loanIdsWithTaskActivity: Array,
    loanIdsWithMessageActivity: Array,
  },
  data() {
    return {
      routes: PSData.routes,
    };
  },
  computed: {
    hasActivity() {
      return (
        this.loanIdsWithTaskActivity.includes(this.loanId) ||
        this.loanIdsWithMessageActivity.includes(this.loanId)
      );
    },
    loanUrl() {
      return `${this.routes.loans}/${this.loanId}/preview`;
    },
  },
  methods: {
    openPreview(loan) {
      GlobalBusService.$emit("open-quick-preview", this.loanId);
    },
  },
};
</script>
