import { defineStore } from "pinia";
import { compact, flattenDeep } from "lodash";

const useLtvFicoGridStore = defineStore("ltvFicoGrid", {
  state: () => ({
    columns: [
      { attr: "residency", condition: "==", value: "foreign_national" },
      { attr: "fico", condition: "in", value: [650, 679] },
      { attr: "fico", condition: "in", value: [680, 699] },
      { attr: "fico", condition: "in", value: [700, 719] },
      { attr: "fico", condition: "in", value: [720, 739] },
      { attr: "fico", condition: "in", value: [740, 759] },
      { attr: "fico", condition: "in", value: [760, 999] },
    ],
    rows: [
      { attr: "ltv", condition: "in", value: [75, 80] },
      { attr: "ltv", condition: "in", value: [70, 75] },
      { attr: "ltv", condition: "in", value: [65, 70] },
      { attr: "ltv", condition: "in", value: [60, 65] },
      { attr: "ltv", condition: "in", value: [0, 60] },
    ],
    adjustments: [],
  }),
  actions: {
    initializeAdjustments() {
      const rows = this.rows.length;
      const cols = this.columns.length;
      this.adjustments = [...Array(rows)].map(_ => Array(cols).fill(""));
    },
    saveGrid(grid) {
      const { ficos, ltvs, rates } = grid;
      this.columns = [...ficos];
      this.rows = [...ltvs];
      this.adjustments = [...rates];
    },
    resetState() {
      this.$reset();
    },
  },
  getters: {
    hasAdjustments(state) {
      return compact(flattenDeep(state.adjustments)).length > 0;
    },
    getColumns(state) {
      return { ficos: state.columns };
    },
    getRows(state) {
      return { ltvs: state.rows };
    },
    getAdjustments(state) {
      if (state.adjustments.length === 0) {
        this.initializeAdjustments();
      }
      return { rates: state.adjustments };
    },
    hasFicoCondition() {
      return this.adjustments.some(row => {
        return row.some((adjustment, col) => {
          return this.columns[col].attr === "fico" && adjustment !== null && adjustment !== "";
        });
      });
    },
    hasLtvCondition() {
      return this.adjustments.some(row => {
        return row.some(adjustment => {
          return adjustment !== null && adjustment !== "";
        });
      });
    },
  },
});

export default useLtvFicoGridStore;
