const Charts = PS.Models.MarketData.Charts;
const MarketDataSource = PS.Models.MarketData.Source;
const MultiSource = PS.Models.MarketData.Charts.MultiSource;
const Calculator = PS.Models.MarketData.Charts.Calculator;

const POINTS_IN_YEAR = 52;

class ListingStatsSource extends MarketDataSource {
  constructor(valueColumn) {
    super();

    this.listingStats = [];
    this.valueColumn = valueColumn;
  }

  get name() {
    return "Listing Stats";
  }

  success(data) {
    this.listingStats = data.listingStats;

    super.success();
  }

  get sources() {
    return [new Charts.Midas("hcMarketIndex", this.listingStats, "date", this.valueColumn)];
  }
}

class ListingStats extends MultiSource {
  constructor(name, valueColumn, legend) {
    super();

    this._sources = {
      midas: new ListingStatsSource(valueColumn),
    };
    this._name = name;
    this._legend = legend;
  }

  get name() {
    return this._name;
  }

  get legend() {
    return this._legend || this._name;
  }

  get mostRecent() {
    return Calculator.mostRecent(this, "hcMarketIndex");
  }

  get oneYearAgo() {
    return Calculator.valueFromEnd(this, "hcMarketIndex", POINTS_IN_YEAR);
  }
}

class MarketActionMedian extends ListingStats {
  constructor() {
    super();

    this._sources = {
      midas: new ListingStatsSource("marketActionMedian"),
    };
  }

  get name() {
    return "Single Family ZIP Code Index";
  }

  get whatThisMeans() {
    if (this.isSuccessful && this.mostRecent !== undefined) {
      switch (true) {
        case this.mostRecent >= 80.01:
          return "Strong Seller's Market";

        case this.mostRecent >= 57.01:
          return "Seller's Market";

        case this.mostRecent >= 43.01:
          return "Neutral Market";

        case this.mostRecent >= 20.01:
          return "Buyer's Market";

        default:
          return "Strong Buyer's Market";
      }
    }
  }
}

PS.Models.MarketData.Charts.MarketActionMedian = MarketActionMedian;
PS.Models.MarketData.Charts.ListingStats = ListingStats;
