<template>
  <div class="lender-users-list">
    <header class="table-header clearfix">
      <span>{{ name }}</span>
      <button v-if="canAdd && canEditUsers" class="btn btn-action-cta-sm float-right" @click="startUserInvite">
        + Add user
      </button>
    </header>
    <table class="table">
      <thead>
        <tr>
          <th class="lender-user-name">Name</th>
          <th class="lender-user-email">Email</th>
          <th class="lender-user-status">Status</th>
          <th class="lender-user-phone">Phone Number</th>
          <th class="lender-user-role">Role</th>
          <th class="lender-user-actions">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <td class="lender-user-name">{{ user.name }}</td>
          <td class="lender-user-email">{{ user.email }}</td>
          <td class="lender-user-status">
            <span v-if="user.isActive">
              Active
            </span>
            <span v-else-if="user.isArchived">
              Archived
            </span>
            <template v-else="">
              <span>Invited</span>
              <a
                v-if="canEditUsers"
                v-tooltip
                title="Resend Invite"
                class="user-action user-action-visible fas fa-sync"
                data-method="put"
                data-confirm="Are you sure you want to resend the invitation to this user? A new email will be sent."
                rel="nofollow"
                :href="resendInviteLink(user)"
              />
            </template>
          </td>
          <td class="lender-user-phone">{{ user.phone | phoneNumber }}</td>
          <td class="lender-user-role">{{ user.jobPosition }}</td>
          <td class="lender-user-actions">
            <span v-if="currentUser.isManager && canEditUsers">
              <a
                v-tooltip
                title="Edit"
                class="user-action fas fa-pencil-alt"
                @click="startUserEdit(user)"
              />
              <a
                v-if="user.isArchived"
                v-tooltip
                title="Restore User"
                class="user-action fas fa-history"
                data-method="put"
                data-confirm="Are you sure you want to restore this archived user?"
                rel="nofollow"
                :href="restoreLink(user)"
              />
              <a
                v-else
                v-tooltip
                title="Archive"
                class="user-action fas fa-trash-alt"
                @click="deleteUser(user)"
              />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <user-edit-modal
      ref="modal"
      :user="processedUser"
      @user-add="inviteUser($event)"
      @user-update="updateUser($event)"
    />
  </div>
</template>

<script>
import UsersService from "services/users_service";
import UserEditModal from "components/lender_users/user_edit_modal";
import User from "models/lenders/user";

export default {
  name: "lender-users-table",
  components: { UserEditModal },
  props: {
    name: {
      type: String,
      default: "",
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
    users: {
      type: Array,
      default: () => [],
    },
    canEditUsers: {
      type: Boolean,
      required: true,
    },
  },
  data: function() {
    return {
      lender: PSData.lender,
      currentUser: PSData.user,
      processedUser: new User(),
    };
  },
  methods: {
    resendInviteLink: function(user) {
      return UsersService.resendInviteLink(this.lender, user);
    },
    restoreLink: function(user) {
      return UsersService.restoreLink(this.lender, user);
    },
    deleteUser: function(user) {
      var confirmMessage = `Are you sure you want to delete ${user.name}?`;

      if (window.confirm(confirmMessage)) {
        UsersService.destroy(this.lender, user).then(this.handleUserUpdate);
      }
    },
    inviteUser: function(user) {
      UsersService.invite(this.lender, user)
        .then(this.handleUserUpdate)
        .finally(this.$refs.modal.hide);
    },
    updateUser: function(user) {
      UsersService.update(this.lender, user)
        .then(this.handleUserUpdate)
        .finally(this.$refs.modal.hide);
    },
    startUserEdit: function(user) {
      this.processedUser = user;
      this.$refs.modal.show();
    },
    startUserInvite: function() {
      this.processedUser = new User();
      this.$refs.modal.show();
    },
    handleUserUpdate(user) {
      this.$emit("update", user);
    },
  },
};
</script>
