<template>
  <modal-view :show="show" :on-close="onClose" class="base-modal wire-instruction-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          {{ title }}
          <a class="modal-close-icon" @click.prevent="onClose" href="#close">&times;</a>
        </h4>
      </div>
      <div class="modal-body">
        <form @keypress.enter="submit">
          <h5>Beneficiary Information</h5>
          <div class="row" v-if="isTemplateForm">
            <div class="col-12 form-group">
              <with-errors on="bankWireTemplateId" :errors="errors">
                <label>Template Name</label>
                <select class="form-control" v-model="amendedWireInstruction.bankWireTemplateId">
                  <option disabled value="">Select Wire Template</option>
                  <option v-for="option in wireTemplates" :value="option.id" :key="option.id">{{option.name}}</option>
                  <option value="manual">Enter bank information manually...</option>
                </select>
              </with-errors>
            </div>
          </div>
          <div class="row" v-if="!isTemplateForm">
            <div class="col-12 form-group">
              <with-errors on="beneficiaryName" :errors="errors">
                <label>Beneficiary Name</label>
                <input v-model="amendedWireInstruction.beneficiaryName" type="text" class="form-control">
              </with-errors>
            </div>
          </div>
          <div class="row" v-if="!isTemplateForm">
            <div class="col-6 form-group">
              <with-errors on="accountNumberToken" :errors="errors">
                <label>Account Number</label>
                <input v-model="amendedWireInstruction.accountNumberToken" v-mask:digits type="text" class="form-control">
              </with-errors>
            </div>
            <div class="col-6 form-group">
              <with-errors on="routingNumberToken" :errors="errors">
                <label>Routing Number</label>
                <input v-model="amendedWireInstruction.routingNumberToken" v-mask:routing-number type="text" class="form-control">
              </with-errors>
            </div>
          </div>
          <h5>Memo</h5>
          <div class="row">
            <div class="col-6 control-wrapper">
              <div class="row">
                <div class="col-12 form-group">
                  <with-errors on="memo1" :errors="errors">
                    <label>Loan ID#</label>
                    <input v-model="amendedWireInstruction.loanId" type="text" class="form-control" disabled>
                  </with-errors>
                </div>
              </div>
              <div class="row">
                <div class="col-12 form-group">
                  <with-errors on="memo2" :errors="errors">
                    <label>Loan Name</label>
                    <input v-model="amendedWireInstruction.loanName" type="text" class="form-control" disabled>
                  </with-errors>
                </div>
              </div>
              <div class="row">
                <div class="col-12 form-group">
                  <with-errors on="escrowFileNumber" :errors="errors">
                    <label>File Number (optional)</label>
                    <input v-model="amendedWireInstruction.escrowFileNumber" type="text" class="form-control">
                  </with-errors>
                </div>
              </div>
              <div class="row">
                <div class="col-12 form-group">
                  <with-errors on="borrowerName" :errors="errors">
                    <label>Borrower's Name (optional)</label>
                    <input v-model="amendedWireInstruction.borrowerName" type="text" class="form-control">
                  </with-errors>
                </div>
              </div>
              <div class="row">
                <div class="col-12 form-group">
                  <with-errors on="escrowTitleOfficer" :errors="errors">
                    <label>Title Officer (optional)</label>
                    <input v-model="amendedWireInstruction.escrowTitleOfficer" type="text" class="form-control">
                  </with-errors>
                </div>
              </div>
              <div class="row">
                <div class="col-12 form-group">
                  <with-errors on="optionalMemo" :errors="errors">
                    <label>Memo (optional)</label>
                    <input v-model="amendedWireInstruction.memo" type="text" maxlength="35" class="form-control">
                  </with-errors>
                </div>
              </div>
            </div>
            <div class="col-6 control-wrapper">
              <div class="wire-instruction-modal_memo">
                <label>Memo Preview</label>
                <div class="wire-instruction-modal_memo-preview">{{memoPreview}}</div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-cancel-cta" @click.prevent="onClose">
          Cancel
        </button>
        <button class="btn btn-action-cta" :disabled="isSubmitDisabled" @click.prevent="submit">
          Submit
        </button>
      </div>
    </div>
  </modal-view>
</template>

<script>
import AjaxService from "services/ajax_service";
import ModalView from "components/modal_view";
import WithErrors from "components/shared/with_errors";
import WireInstruction from "models/bank/wire_instruction";
import isEmpty from "lodash/isEmpty";
import isValidRoutingNumber from "utils/is_valid_routing_number";

function findDefaultWireTemplate(wireTemplates) {
  if (wireTemplates.length === 1) {
    return wireTemplates[0];
  } else {
    return wireTemplates.find(template => template.defaultTemplate);
  }
}

export default {
  name: "wire-instruction-modal",
  components: { ModalView, WithErrors },
  props: {
    reserveDrawRequest: Object,
    wireInstruction: Object,
    show: Boolean,
    wireType: String,
  },
  data() {
    const wireTemplates = PSData.wireTemplates;
    const defaultWireTemplate = findDefaultWireTemplate(wireTemplates);
    const wireInstructionParams = { drawWireType: this.wireType };
    const defaultTemplateSettable =
      this.wireType === "fee" || this.reserveDrawRequest.reimburseLenderDrawAmount;
    if (defaultTemplateSettable && isEmpty(this.wireInstruction) && defaultWireTemplate) {
      wireInstructionParams.bankWireTemplateId = defaultWireTemplate.id;
    }

    const amendedWireInstruction = new WireInstruction(
      Object.assign(
        {},
        WireInstruction.paramsFromLoan(PSData.loan),
        wireInstructionParams,
        this.wireInstruction
      )
    );

    return {
      wireTemplates: PSData.wireTemplates,
      amendedWireInstruction: amendedWireInstruction,
      errors: {
        bank_wire_template_id:
          wireTemplates.length === 0 ? ["No active wire templates"] : undefined,
      },
      isLoading: false,
      isManualSelected: false,
    };
  },
  methods: {
    onClose() {
      this.$emit("close", this.wireType);
    },

    submit() {
      if (this.isSubmitDisabled || !this.validateRoutingNumber()) {
        return;
      }

      this.isLoading = true;
      const host = AjaxService.secureHost();
      const url = `${host}/manage/reserve_draw_requests/${
        this.reserveDrawRequest.id
      }/wire_instruction`;

      AjaxService.withNotifications({ onSuccess: "Wire instruction updated." })
        .patch(url, { wire_instruction: this.amendedWireInstruction.toHash() })
        .then(this.onSuccess)
        .catch(({ data }) => {
          this.errors = data.errors;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    onSuccess({ wireInstruction }) {
      this.errors = {};
      this.onClose();
      this.$emit("updated", wireInstruction, this.wireType);
    },

    validateRoutingNumber() {
      if (this.amendedWireInstruction.routingNumberToken) {
        if (!isValidRoutingNumber(this.amendedWireInstruction.routingNumberToken.trim())) {
          Vue.set(this.errors, "routing_number_token", Array("Invalid routing number"));
          return false;
        }
      }

      return true;
    },
  },
  computed: {
    isSubmitDisabled() {
      return this.isLoading || (this.isTemplateForm && this.wireTemplates.length === 0);
    },

    memoPreview() {
      return this.amendedWireInstruction.memoPreview();
    },

    isTemplateForm() {
      if (isEmpty(this.wireInstruction)) {
        return this.isTemplateFormForCreate;
      }

      return !!this.amendedWireInstruction.bankWireTemplateId;
    },
    isTemplateFormForCreate() {
      if (this.wireType === "fee" || this.reserveDrawRequest.reimburseLenderDrawAmount) {
        return !this.isManualSelected;
      }

      return false;
    },
    title() {
      if (this.wireType === "draw") {
        return "Draw Wire Instructions";
      } else {
        return "Reimbursement Wire Instructions";
      }
    },
  },
  watch: {
    "amendedWireInstruction.bankWireTemplateId": function(newVal) {
      if (newVal === "manual") {
        this.isManualSelected = true;
        this.amendedWireInstruction.bankWireTemplateId = "";
      }
    },
  },
};
</script>
