<template>
  <table class="table allocation-table">
    <thead>
      <tr>
        <th class="allocation-header-loan-name">
          Loan
        </th>
        <th class="allocation-header-loan-amount">
          Loan Amount
        </th>
        <th class="allocation-header-purchase-rate">
          Purchase Rate
        </th>
        <th class="allocation-header-eligible-buyers">
          Eligible Buyers
        </th>
        <th class="allocation-header-override">
          Allocation Override
        </th>
        <th>
          Old Allocation
        </th>
        <th>
          New Allocation
        </th>
        <th v-if="editMode"></th>
      </tr>
    </thead>
    <tbody>
      <loans-row
        v-for="batchLoan in batchLoans"
        :key="batchLoan.loan.psId"
        :batch-loan="batchLoan"
        :edit-mode="editMode"
        :buyers="buyers"
        :show-mode="showMode"
        v-on="$listeners"
      >
      </loans-row>
      <tr v-if="noLoans">
        <td>No Loans</td>
      </tr>
      <tr v-if="editMode">
        <td><a href="#" @click="$emit('open-reallocate-modal')" :disabled="isValidating">+ Add loans to reallocate</a></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import LoansRow from "./loans_row";

export default {
  name: "loans-table",
  components: {
    LoansRow,
  },
  props: {
    batchLoans: Array,
    buyers: Array,
    editMode: Boolean,
    isValidating: Boolean,
    showMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    noLoans() {
      return this.batchLoans.length === 0;
    },
  },
};
</script>
