import DDAsyncCheck from "models/diligence_document/dd_async_check";

const Command = PS.Models.Command;
const Events = PS.Models.Diligence.Events;

class RequestLoading extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Document.LoadingRequested,
      PS.Services.MessageBusService.diligenceBus,
      {
        documentType: this.state.documentType,
      }
    );
  }
}

class SetDiligenceMode extends Command {
  execute() {
    this.state.mode = "diligence";

    return Promise.resolve();
  }
}

class SetReportingMode extends Command {
  execute() {
    this.state.mode = "reporting";

    return Promise.resolve();
  }
}

class LoadDocumentInfo extends Command {
  execute() {
    const url = `/manage/loans/${this.state.loan.toParam}/documents/documents_info`;

    return new Promise((resolve, reject) => {
      const sameDocument = DDAsyncCheck.generate();

      PS.Services.AjaxService.getJSON(url).then(documentsInfo => {
        if (sameDocument()) {
          this.state.documentsInfo = documentsInfo;
          resolve();
        } else {
          reject();
        }
      });
    });
  }
}

class UpdateChecklistInfo extends Command {
  execute() {
    let newState = this.event.documentInfo;
    let oldState = this.state.documentsInfo.find(doc => {
      return doc.documentType === newState.documentType;
    });

    if (oldState) {
      Vue.delete(this.state.documentsInfo, this.state.documentsInfo.indexOf(oldState));
    }
    this.state.documentsInfo.push(newState);

    return Promise.resolve();
  }
}

class GenerateReport extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Document.ReportGenerationRequested,
      PS.Services.MessageBusService.diligenceBus,
      {}
    );
  }
}

class LoadReport extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Report.LoadingRequested,
      PS.Services.MessageBusService.diligenceBus,
      {}
    );
  }
}

class RequestDeselectDocument extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Checklist.DeselectDocumentTypeRequested,
      PS.Services.MessageBusService.diligenceBus,
      {}
    );
  }
}

class RequestSelectDocument extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Checklist.SelectDocumentTypeRequested,
      PS.Services.MessageBusService.diligenceBus,
      {
        documentType: this.state.documentType,
      }
    );
  }
}

PS.Models.Diligence.Commands.Document = {
  RequestLoading: RequestLoading,
  SetDiligenceMode: SetDiligenceMode,
  SetReportingMode: SetReportingMode,
  LoadDocumentInfo: LoadDocumentInfo,
  UpdateChecklistInfo: UpdateChecklistInfo,
  GenerateReport: GenerateReport,
  LoadReport: LoadReport,
  RequestDeselectDocument: RequestDeselectDocument,
  RequestSelectDocument: RequestSelectDocument,
};
