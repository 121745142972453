<template>
  <modal-form
    :show="show"
    :save-disabled="saveDisabled"
    title="Add a State"
    save-button-name="Add State"
    class="lender-legal-entity-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <form>
      <div class="row">
        <with-errors on="state" :errors="errors" class="col-12 form-group">
          <label>Name</label>
          <select class="form-control" v-model="lenderLegalEntityState.state">
            <option disabled value="">Select a state</option>
            <option v-for="(label, value) in statesOptions" :key="value" :value="value">{{ label }}</option>
          </select>
        </with-errors>
      </div>
    </form>
  </modal-form>
</template>

<script>
import LegalEntityState from "models/lender_legal_entities/state";
import AjaxService, { SUCCESS_STATUS } from "services/ajax_service";
import ModalForm from "components/modal_form.vue";
import WithErrors from "components/shared/with_errors";

export default {
  name: "lender-legal-entity-state-form-modal",
  components: {
    ModalForm,
    WithErrors,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    statesOptions: {
      required: true,
      type: Object | null,
    },
  },
  data() {
    return {
      errors: {},
      routes: PSData.routes || {},
      saveDisabled: false,
      lenderLegalEntityState: new LegalEntityState(),
    };
  },
  methods: {
    onClose() {
      this.errors = {};
      this.$emit("close");
      this.lenderLegalEntityState.reset();
    },
    onSave() {
      this.save();
    },
    save() {
      this.saveDisabled = true;
      const messages = {
        onError: ({ response, data }) => {
          if (response.status === 403) {
            return { message: "Unauthorized", status: "error" };
          } else {
            this.errors = data.errors;
            return null;
          }
        },
      };

      return AjaxService.withNotifications(messages)
        .post(this.routes.lenderLegalEntityStates, this.lenderLegalEntityState.objectToSave)
        .then(data => {
          this.lenderLegalEntityState = new LegalEntityState(data);
          this.$emit("lender-legal-entity-state-saved", data);
          this.onClose();
        })
        .finally(() => {
          this.saveDisabled = false;
        });
    },
  },
};
</script>
