import DDAsyncCheck from "models/diligence_document/dd_async_check";

const Command = PS.Models.Command;
const Event = PS.Models.Event;
const Events = PS.Models.Diligence.Events;

class RequestFieldsetCreation extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Fieldset.CreateRequested,
      PS.Services.MessageBusService.diligenceBus,
      {
        fieldset: this.state.fieldset,
      }
    );
  }
}

class CreateFieldset extends Command {
  execute() {
    const url = `/manage/diligence_documents/${this.state.form.document.id}/fieldsets`;

    return new Promise((resolve, reject) => {
      const sameDocument = DDAsyncCheck.generate();

      PS.Services.AjaxService.withNotifications({ onSuccess: "New fieldset created." })
        .post(url, { name: this.event.fieldset.name })
        .then(newFieldset => {
          if (sameDocument()) {
            const fieldset = this.state.form.addField(newFieldset);
            new RequestFieldsetCreationAnnouncement(new Event(), { fieldset: fieldset }).execute();
            resolve();
          } else {
            reject();
          }
        });
    });
  }
}

class RequestFieldsetDeletion extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Fieldset.DeleteRequested,
      PS.Services.MessageBusService.diligenceBus,
      {
        fieldset: this.state.fieldset,
      }
    );
  }
}

class RequestFieldsetClear extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Fieldset.ClearRequested,
      PS.Services.MessageBusService.diligenceBus,
      {
        fieldset: this.state.fieldset,
      }
    );
  }
}

class RequestFieldsetValidate extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Fieldset.ValidateRequested,
      PS.Services.MessageBusService.diligenceBus,
      {
        fieldset: this.state.fieldset,
      }
    );
  }
}

class ClearFieldset extends Command {
  execute() {
    const fieldset = this.event.fieldset;
    const url = `/manage/diligence_fieldsets/${fieldset.id}/clear`;

    return new Promise((resolve, reject) => {
      const sameDocument = DDAsyncCheck.generate();

      PS.Services.AjaxService.patch(url).then(() => {
        if (sameDocument()) {
          fieldset.fields.forEach(field => {
            field.value = "";
            field.validated = null;
          });
          resolve();
        } else {
          reject();
        }
      });
    });
  }
}

class ValidateFieldset extends Command {
  execute() {
    const fieldset = this.event.fieldset;
    const url = `/manage/diligence_fieldsets/${fieldset.id}/validate`;

    return new Promise((resolve, reject) => {
      const sameDocument = DDAsyncCheck.generate();

      PS.Services.AjaxService.patch(url).then(() => {
        if (sameDocument()) {
          fieldset.fields.forEach(field => {
            field.validated = true;
          });
          resolve();
        } else {
          reject();
        }
      });
    });
  }
}

class RequestFieldsetCreationAnnouncement extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.Fieldset.FieldsetCreationAnnounced,
      PS.Services.MessageBusService.diligenceBus,
      {
        fieldset: this.state.fieldset,
      }
    );
  }
}

class DeleteFieldset extends Command {
  execute() {
    const url = `/manage/diligence_fieldsets/${this.event.fieldset.id}`;

    return new Promise((resolve, reject) => {
      const sameDocument = DDAsyncCheck.generate();

      PS.Services.AjaxService.withNotifications({ onSuccess: "Fieldset removed." })
        .delete(url)
        .then(() => {
          if (sameDocument()) {
            this.state.form.removeField(this.event.fieldset);
            resolve();
          } else {
            reject();
          }
        });
    });
  }
}

class RequestFormulaInvalidation {
  execute() {
    return Command.broadcastEvent(
      Events.Fieldset.FormulaInvalidationRequested,
      PS.Services.MessageBusService.diligenceBus
    );
  }
}

class EnableFieldset extends Command {
  execute() {
    if (this.event.fieldsetNames.indexOf(this.state.fieldset.name) > -1) {
      this.state.fieldset.enable();
    }

    return Promise.resolve();
  }
}

class DisableFieldset extends Command {
  execute() {
    if (this.event.fieldsetNames.indexOf(this.state.fieldset.name) > -1) {
      this.state.fieldset.disable();
    }

    return Promise.resolve();
  }
}

PS.Models.Diligence.Commands.Fieldset = {
  RequestFieldsetCreation: RequestFieldsetCreation,
  CreateFieldset: CreateFieldset,
  RequestFieldsetDeletion: RequestFieldsetDeletion,
  DeleteFieldset: DeleteFieldset,
  RequestFieldsetClear: RequestFieldsetClear,
  RequestFieldsetValidate: RequestFieldsetValidate,
  ClearFieldset: ClearFieldset,
  ValidateFieldset: ValidateFieldset,
  RequestFormulaInvalidation: RequestFormulaInvalidation,
  EnableFieldset: EnableFieldset,
  DisableFieldset: DisableFieldset,
};
