const Command = PS.Models.Command;
const Commands = PS.Models.EmailSubscription.Commands;
const Events = PS.Models.EmailSubscription.Events;
const Lender = PS.Models.EmailSubscription.Lender;

class RequestLendersSetup extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.LendersSetupRequested,
      PS.Services.MessageBusService.settingsBus,
      {}
    );
  }
}

class RequestResetChanges extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.ResetChangesRequested,
      PS.Services.MessageBusService.settingsBus,
      {}
    );
  }
}

class RequestSaveChanges extends Command {
  execute() {
    return Command.broadcastEvent(
      Events.SaveChangesRequested,
      PS.Services.MessageBusService.settingsBus,
      {}
    );
  }
}

class PrepareLenders extends Command {
  execute() {
    this.state.globalLender = new Lender(
      this.state.options.globalLender,
      this.state.options.categories,
      this.state.subscriptions
    );

    this.state.lenders = this.state.options.lenders.map(lender => {
      return new Lender(lender, this.state.options.categories, this.state.subscriptions);
    });

    return Promise.resolve();
  }
}

class AddChange extends Command {
  execute() {
    let subscription = this.event.subscription;

    this.state.changes.add({
      item: subscription.lenderId,
      action: subscription.enabled ? "add" : "remove",
      value: subscription.category,
    });

    return Promise.resolve();
  }
}

class ResetChanges extends Command {
  execute() {
    this.state.changes.reset();

    return Promise.resolve();
  }
}

class ResetLenders extends Command {
  execute() {
    this.state.globalLender.reset();
    this.state.lenders.forEach(lender => lender.reset());

    return Promise.resolve();
  }
}

class StartUpdating extends Command {
  execute() {
    this.state.updating = true;
    this.state.subscriptions = [];

    return Promise.resolve();
  }
}

class FinishUpdating extends Command {
  execute() {
    this.state.updating = false;

    return Promise.resolve();
  }
}

class SendToServer extends Command {
  execute() {
    let payload = this.preparePayload(this.state.changes);
    let url = `/manage/user/email_subscriptions`;
    let data = { subscription_changes: JSON.stringify(payload) };
    return PS.Services.AjaxService.withNotifications()
      .post(url, data)
      .then(data => {
        this.state.subscriptions = data.subscriptions;
      });
  }

  preparePayload(changes) {
    let commands = changes.commands;
    let payload = [];
    Object.keys(commands).forEach(item => {
      let itemChange = { lender_id: item, changes: [] };

      Object.keys(commands[item]).forEach(value => {
        if (commands[item][value] !== undefined) {
          itemChange.changes.push({ action: commands[item][value], category: value });
        }
      });

      if (itemChange.changes.length > 0) payload.push(itemChange);
    });

    return payload;
  }
}

PS.Models.EmailSubscription.Commands.Table = {
  AddChange: AddChange,
  FinishUpdating: FinishUpdating,
  PrepareLenders: PrepareLenders,
  RequestLendersSetup: RequestLendersSetup,
  RequestResetChanges: RequestResetChanges,
  RequestSaveChanges: RequestSaveChanges,
  ResetChanges: ResetChanges,
  ResetLenders: ResetLenders,
  SendToServer: SendToServer,
  StartUpdating: StartUpdating,
};
