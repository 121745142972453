<template>
  <div class="todolist">
    <div class="header">
      <h3 class="worklist-title">
        To Do List
      </h3>
    </div>
    <div class="filter">
      <todolist-filter
        :loading="loading"
        :filter-query="searchQueryParams"
        @filter-update="onFilterUpdate"
      />
    </div>
    <div class="items">
      <b-loading v-model="loading">
        <b-icon
          pack="fas"
          icon="sync-alt"
          size="is-large"
          custom-class="fa-spin"
        />
      </b-loading>
      <todolist-loans
        :loans="loans"
        :loans-per-page="loansPerPage"
        :offset="offset"
        @offset-change="onPaginationOffsetChange"
      />
    </div>
  </div>
</template>

<script>
import TodolistLoans from "components/reports/todolist_loans";
import TodolistFilter from "components/reports/todolist_filter";
import LocationService from "services/location_service.js";
import AjaxService from "services/ajax_service";

export default {
  components: { TodolistLoans, TodolistFilter },
  data() {
    const searchParams = new URLSearchParams(LocationService.search);
    const loansPerPage = PSData.loansPerPage || 30;

    return {
      loans: PSData.loans || [],
      offset: searchParams.get("offset") / loansPerPage + 1,
      loansPerPage: loansPerPage,
      loading: false,
      searchQueryParams: searchParams.toString(),
    };
  },
  watch: {
    searchQueryParams(newSearchParams) {
      let newRelativePathQuery = window.location.pathname;
      const searchParams = new URLSearchParams(newSearchParams);

      if (this.offset === 0) {
        searchParams.delete("offset");
      }

      if (searchParams.toString().length > 0) {
        newRelativePathQuery = newRelativePathQuery.concat("?", searchParams.toString());
      }
      newRelativePathQuery = newRelativePathQuery.concat(window.location.hash);

      history.pushState(null, "", newRelativePathQuery);
      this.loading = true;
      AjaxService.getJSON(newRelativePathQuery)
        .then(data => {
          const loansPerPage = data.loans_per_page || 30;

          this.loans = data.loans;
          this.loansPerPage = loansPerPage;
          this.offset = data.offset / loansPerPage + 1;
          window.scrollTo(0, 0);
        })
        .finally(() => (this.loading = false));
    },
  },
  methods: {
    onPaginationOffsetChange(offset) {
      const searchParams = new URLSearchParams(this.searchQueryParams);

      searchParams.set("offset", (offset - 1) * this.loansPerPage);
      this.searchQueryParams = searchParams.toString();
    },
    onFilterUpdate(filterQuery) {
      this.offset = 0;
      this.searchQueryParams = filterQuery;
    },
  },
};
</script>
<style scoped>
.todolist {
  display: grid;
  grid-template-columns: 15% 85%;
  grid-template-rows: auto auto;
  grid-column-gap: 10px;
}

.header {
  grid-area: 1 / 1 / 2 / 3;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  border-bottom: 1px solid #ccc;
}

.filter {
  background-color: var(--color-grey-06);
  grid-area: 2 / 1 / 3 / 2;
  justify-self: start;
  padding-left: 15px;
  padding-right: 15px;
}
.items {
  align-self: start;
  grid-area: 2 / 2 / 3 / 3;
  padding-right: 30px;
}
</style>
