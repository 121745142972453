<template>
  <tr>
    <td>{{ account.name }}</td>
    <td></td>
    <td class="account-group-amount">{{ account.balance | accounting }}</td>
    <td class="account-group-amount">{{ account.capacity | accounting }}</td>
    <td class="account-group-amount">
      <div v-if="account.funded">
        <a href="#" @click.prevent="openFundedModal">{{ account.funded | accounting }}</a>
        <series-details-modal
          type="funded"
          v-if="fundedModal"
          :show="fundedModal"
          :account="account"
          @close="closeFundedModal"
        ></series-details-modal>
      </div>
      <div v-else>
        {{ account.funded | accounting }}
      </div>
    </td>
    <td class="account-group-amount">{{ account.dryPowder | accounting }}</td>
    <td class="account-group-amount">
      <div v-if="account.inTransit">
        <a href="#" @click.prevent="openInTransitModal">{{ account.inTransit | accounting }}</a>
        <in-transit-details-modal
          v-if="inTransitModal"
          :show="inTransitModal"
          :account="account"
          @close="closeInTransitModal"
        ></in-transit-details-modal>
      </div>
      <div v-else>
        {{ account.inTransit | accounting }}
      </div>
    </td>
    <td class="account-group-amount">
      <div v-if="account.earmarked">
        <a href="#" @click.prevent="openEarmarkedModal">{{ account.earmarked | accounting }}</a>
        <series-details-modal
          type="earmarked"
          v-if="earmarkedModal"
          :show="earmarkedModal"
          :account="account"
          @close="closeEarmarkedModal"
        ></series-details-modal>
      </div>
      <div v-else>
        {{ account.earmarked | accounting }}
      </div>
    </td>
    <td class="account-group-amount">
      {{ account.available | accounting }}
    </td>
    <td class="account-group-amount">
      <div v-if="account.dailyActivity">
        <a href="#" @click.prevent="openDailyActivityModal">{{ account.dailyActivity | accounting }}</a>
        <daily-activity-details-modal
          type="dailyActivity"
          v-if="dailyActivityModal"
          :show="dailyActivityModal"
          :account="account"
          @close="closeDailyActivityModal"
        ></daily-activity-details-modal>
      </div>
      <div v-else>
        {{ account.dailyActivity | accounting }}
      </div>
    </td>
    <td class="account-group-amount">
      <div v-if="account.throughput">
        <a href="#" @click.prevent="openDailyActivityModal">{{ account.throughput | accounting }}</a>
      </div>
      <div v-else>
        {{ account.throughput | accounting }}
      </div>
    </td>
  </tr>
</template>
<script>
import SeriesDetailsModal from "./series_details_modal";
import InTransitDetailsModal from "./in_transit_details_modal";
import DailyActivityDetailsModal from "./daily_activity_details_modal";

export default {
  components: {
    SeriesDetailsModal,
    InTransitDetailsModal,
    DailyActivityDetailsModal,
  },
  props: {
    account: Object,
  },
  data: function() {
    return {
      fundedModal: false,
      inTransitModal: false,
      earmarkedModal: false,
      dailyActivityModal: false,
    };
  },
  methods: {
    openFundedModal() {
      this.fundedModal = true;
    },
    closeFundedModal() {
      this.fundedModal = false;
    },
    openInTransitModal() {
      this.inTransitModal = true;
    },
    closeInTransitModal() {
      this.inTransitModal = false;
    },
    openEarmarkedModal() {
      this.earmarkedModal = true;
    },
    closeEarmarkedModal() {
      this.earmarkedModal = false;
    },
    openDailyActivityModal() {
      this.dailyActivityModal = true;
    },
    closeDailyActivityModal() {
      this.dailyActivityModal = false;
    },
  },
};
</script>
