<template>
  <modal-form
    :confirm="showConfirmation"
    :save-disabled="saveDisabled"
    :show="show"
    :title="modalTitle"
    class="lender-legal-entity-modal"
    @on-close="onClose"
    @on-save="onSave"
  >
    <form>
      <div class="row">
        <with-errors on="name" :errors="errors" class="col-12 form-group">
          <label>Entity Legal Name</label>
          <input
            class="form-control"
            v-model="entity.name"
            name="name" />
        </with-errors>
      </div>
      <div class="row">
        <with-errors on="entityType" :errors="errors" class="col-6 form-group">
          <label>Entity Type</label>
          <select class="form-control" v-model="entity.entityType">
            <option disabled value="">Select Entity Type</option>
            <option v-for="(label, value) in entity.entityTypes" :key="value" :value="value">{{ label }}</option>
          </select>
        </with-errors>
        <with-errors on="state" :errors="errors" class="col-6 form-group" v-if="showState">
          <label>Formation State</label>
          <select class="form-control" v-model="entity.state">
            <option disabled value="">Select Formation State</option>
            <option v-for="(label, value) in entity.states" :key="value" :value="value">{{ label }}</option>
          </select>
        </with-errors>
      </div>
      <div class="row" v-if="isEditForm">
        <with-errors on="vestingClause" :errors="errors" class="col-12 form-group">
          <label>Vesting Clause</label>
          <input
            class="form-control"
            v-model="entity.vestingClause"
            name="vestingClause" />
        </with-errors>
      </div>
      <div class="row" v-if="isEditForm">
        <with-errors on="fullName" :errors="errors" class="col-12 form-group">
          <label>Full Legal name</label>
          <input
            class="form-control"
            v-model="entity.fullName"
            name="fullName" />
        </with-errors>
      </div>
      <div class="row" v-if="isEditForm">
        <with-errors on="notes" :errors="errors" class="col-12 form-group">
          <label>Notes</label>
          <textarea
            class="form-control"
            v-model="entity.notes"
            name="notes">
          </textarea>
        </with-errors>
      </div>
    </form>
  </modal-form>
</template>

<script>
import LegalEntity from "models/lender_legal_entities/entity";
import AjaxService from "services/ajax_service";
import ModalForm from "components/modal_form.vue";
import WithErrors from "components/shared/with_errors";
import MessageBusService from "services/message_bus_service";

export const FORM_TYPES = {
  create: "create",
  edit: "edit",
};

export default {
  name: "lender-legal-entity-information-form-modal",
  components: {
    ModalForm,
    WithErrors,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    type: {
      type: String,
      validator(value) {
        return !!FORM_TYPES[value];
      },
    },
    entity: {
      required: true,
      type: LegalEntity,
    },
  },
  data() {
    return {
      errors: {},
      routes: PSData.routes || {},
      saveDisabled: false,
      bus: MessageBusService.entityBus,
    };
  },
  computed: {
    isCreateForm() {
      return this.type === FORM_TYPES.create;
    },
    isEditForm() {
      return this.type === FORM_TYPES.edit;
    },
    modalTitle() {
      return this.isCreateForm ? "Add New Legal Entity" : "Edit Legal Entity Information";
    },
    showState() {
      return this.entity.entityType && !this.entity.isEntityTypeIndividual;
    },
    showConfirmation() {
      return this.entity.changed;
    },
  },
  methods: {
    onClose() {
      this.errors = {};
      this.$emit("close");
    },
    onSave() {
      this.save();
    },
    save() {
      const messages = { onSuccess: "Lender legal entity is saved", onError: this.onError };

      this.saveDisabled = true;

      return this.saveLenderLegalEntity(messages, this.entity)
        .then(data => {
          this.bus.broadcast("entity-updated");
          this.$emit("lender-legal-entity-saved", data);
          this.onClose();
        })
        .finally(() => {
          this.saveDisabled = false;
        });
    },
    saveLenderLegalEntity(messages, entity) {
      if (this.isCreateForm) {
        return AjaxService.withNotifications(messages).post(
          this.routes.lenderLegalEntity,
          entity.objectToSaveOnCreate
        );
      } else {
        return AjaxService.withNotifications(messages).patch(
          this.routes.lenderLegalEntity,
          entity.objectToSaveOnUpdate
        );
      }
    },
    onError({ response, data }) {
      if (response.status === 403) {
        return { message: "Unauthorized", status: "error" };
      } else {
        this.errors = data.errors || {};
        return null;
      }
    },
  },
};
</script>
