import { defineStore } from "pinia";
import AjaxService, { ERROR_STATUS, ERROR_MESSAGE } from "services/ajax_service";

const useAssetManagementStore = defineStore("assetManagement", {
  state: () => ({
    workflows: PSData.workflows || [],
    workflowTemplates: PSData.workflowTemplates || [],
    permissions: { canUpdateWorkflows: PSData.canAssignWorkflows || false },
  }),
  getters: {
    canUpdateWorkflows: state => state.permissions.canUpdateWorkflows,
  },
  actions: {
    addWorkflow({ workflow }) {
      this.workflows = [workflow, ...this.workflows];
    },
    updateWorkflow({ id, data }) {
      this.workflows = this.workflows.map(wf => (wf.id === id ? data : wf));
    },
    async refreshWorkflowItem({ workflow }) {
      try {
        const updatedWorkflow = await AjaxService.getJSON(workflow.routes.show);
        this.updateWorkflow({ id: workflow.id, data: updatedWorkflow });
      } catch ({ response, data }) {
        if (data && data.errors) {
          AjaxService.sendNotification(data.errors.join("\n"), ERROR_STATUS);
        } else {
          AjaxService.sendNotification(ERROR_MESSAGE, ERROR_STATUS);
        }
      }
    },
  },
});
export default useAssetManagementStore;
