<template>
  <notification-group
    v-if="showResults"
    variant="warning"
    title="Missing Onboarding Items"
    :message-groups="orderedMessages"
  />
</template>

<script>
import { prepareRules, uniqResults } from "models/credit_box/credit_box_rules";
import NotificationGroup from "components/shared/notification_group";

const CATEGORIES = {
  entity_related_docs: "Entity Related Docs",
  registration: "Registration",
  license: "License",
  loan_docs: "Loan Docs",
  agreement: "Master Agreement",
};

const CATEGORIZED_RULES = {
  na: ["entity_not_selected", "entity_state_match"],
  entity_related_docs: ["other_documents_check"],
  registration: ["registration_check"],
  license: ["sfr_license_check", "cre_license_check"],
  loan_docs: [
    "sfr_loan_docs_check",
    "sfr_const_loan_docs_dutch_check",
    "sfr_const_loan_docs_non_dutch_check",
    "cre_loan_docs_check",
    "cre_const_dutch_check",
    "cre_const_non_dutch_check",
    "mfr_loan_docs_check",
    "mfr_const_dutch_check",
    "mfr_const_non_dutch_check",
  ],
  agreement: [
    "sfr_mlsa_funded",
    "sfr_mlsa_table_funding",
    "sfr_mloa",
    "mf_cre_mlsa_funded",
    "mf_cre_mlsa_table_funding",
    "mf_cre_mloa",
    "pari_passu_amount",
    "fio_broker",
    "sfr_mf_cre_mlsa_v2",
    "sfr_mf_cre_mloa_v2",
  ],
};

export default {
  name: "legal-purchasing-alert",
  components: {
    NotificationGroup,
  },
  props: {
    rules: Array,
  },
  computed: {
    results() {
      return uniqResults(prepareRules(this.rules));
    },
    showResults() {
      return this.results.length > 0;
    },
    orderedMessages() {
      let results = this.results;
      let array = [];

      Object.entries(CATEGORIZED_RULES).forEach(([key, val]) => {
        let msg = results.filter(r => val.includes(r.name));

        if (msg) {
          msg.forEach(m => {
            array.push({
              subtitle: CATEGORIES[key],
              description: m.explanation,
            });
          });
        }

        return array;
      });

      return [{ messages: array, title: this.title() }];
    },
  },
  methods: {
    title() {
      return this.results[0].entity.link;
    },
  },
};
</script>
