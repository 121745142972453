import sortBy from "lodash/sortBy";

export default class LegalEntityState {
  static initStates(states) {
    return sortBy((states || []).map(state => new LegalEntityState(state)), ["stateLabel"]);
  }

  constructor(stateData = {}) {
    this._raw = stateData;
    this._id = stateData.id;
    this._state = stateData.state;
    this._stateName = stateData.stateName;
    this._entityName = stateData.lenderLegalEntity && stateData.lenderLegalEntity.name;
  }

  get id() {
    return this._id;
  }

  get state() {
    return this._state || "";
  }

  set state(state) {
    this._state = state;
  }

  get stateLabel() {
    return this._stateName;
  }

  get entityName() {
    return this._entityName;
  }

  get objectToSave() {
    return {
      state: this.state,
    };
  }

  reset() {
    this._state = this._raw.state;
  }
}
