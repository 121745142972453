class DiligenceReportService {
  static get(loan) {
    var url = "/manage/loans/" + loan.toParam + "/diligence_report/new";

    return PS.Services.AjaxService.get(url);
  }
  static update(loan, params) {
    var url = "/manage/loans/" + loan.toParam + "/diligence_report";
    var messages = { onSuccess: "Master Loan updated." };
    var data = {
      resource: params,
    };

    return PS.Services.AjaxService.withNotifications(messages).patch(url, data);
  }
}

PS.Services.DiligenceReportService = DiligenceReportService;
