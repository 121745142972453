<template>
  <div>
    <base-field label="Task Type" class="py-1">
      {{ formattedTaskType }}
    </base-field>
    <base-hr class="task-mapping-preview-hr" />
    <base-header size="small" class="pb-2">
      Creation
    </base-header>
    <base-field label="Trigger" class="pb-2">
      <loan-status-tag :status="taskMapping.triggerStatus" size="medium" />
    </base-field>
    <base-field v-if="taskMapping.hasConditions" label="Conditions" class="pb-2">
      <rules-preview :rules="taskMapping.conditions" class="description-preview-rules" disabled />
    </base-field>
  </div>
</template>
<script>
import LoanStatusTag from "components/shared/loan_status_tag";
import LoanTaskDefiniton from "models/loan_task_definition";
import RulesPreview from "../rules/preview";
import startCase from "lodash/startCase";

export default {
  components: {
    LoanStatusTag,
    RulesPreview,
  },
  props: {
    taskMapping: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    definition() {
      return new LoanTaskDefiniton(this.taskMapping && this.taskMapping.taskDefinition);
    },
    formattedTaskType() {
      return startCase(this.definition.taskType);
    },
  },
};
</script>
<style scoped>
.task-mapping-preview .task-mapping-preview-hr {
  margin: calc(var(--space-unit) * 2) calc(var(--space-unit) * -3);
}
</style>
