<template>
  <div>
    <div class="message-type">
      <span
        class="message-type-toggle internal"
        @click="visibleFor('public')"
        :class="publicClass"
        v-if="canPickType && canUpdateExternalComments"
      >Lender</span>
      <span
        class="message-type-toggle"
        @click="visibleFor('internal')"
        :class="internalClass"
        v-if="canUpdateInternalComments"
      >Internal</span>
    </div>
    <div v-if="canUpdateComments" class="form-group loan-task-comments-form">
      <autosize-textarea
        class="form-control"
        :placeholder="textareaPlaceholder"
        :class="visibility"
        :disabled="uiIsBlocked"
        v-model="content"
      ></autosize-textarea>

      <input
        class="btn-action-cta-sm btn-add-comment"
        type="button"
        :value="submitCaption"
        :disabled="submitIsDisabled"
        @click="sendComment"
      >
    </div>
  </div>
</template>
<script>
import AutosizeTextarea from "components/shared/autosize_textarea";
import Ajax from "services/ajax_service";

export default {
  name: "internal-loan-tasks-comments-form",
  components: { AutosizeTextarea },
  props: {
    task: Object,
    canPickType: {
      type: Boolean,
      default: false,
    },
    canUpdateExternalComments: {
      type: Boolean,
      required: true,
    },
    canUpdateInternalComments: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      content: "",
      uiIsBlocked: false,
      visibility: "internal",
    };
  },
  methods: {
    sendComment() {
      this.uiIsBlocked = true;

      return Ajax.post(this.task.commentsPath, this.payload).then(data => {
        this.uiIsBlocked = false;

        if (data.error) {
          Ajax.sendNotification(data.error, "error");
        } else {
          this.content = "";
          this.$emit("created", data.comment);
        }
      });
    },
    visibleFor(scope) {
      this.visibility = scope;
    },
  },
  computed: {
    canUpdateComments() {
      return this.canUpdateExternalComments || this.canUpdateInternalComments;
    },
    submitIsDisabled() {
      return this.uiIsBlocked || this.content.trim().length === 0;
    },
    submitCaption() {
      if (this.uiIsBlocked) {
        return "Commenting...";
      } else {
        return "Comment";
      }
    },
    payload() {
      return { content: this.content, visibility: this.visibility };
    },
    internalClass() {
      return this.visibility === "internal" ? "active" : undefined;
    },
    publicClass() {
      return this.visibility === "public" ? "active" : undefined;
    },
    textareaPlaceholder() {
      return this.visibility === "public"
        ? "Write comment here..."
        : "Write internal comment here...";
    },
  },
};
</script>
