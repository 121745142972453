<template>
  <div>
    <div v-if="data">
      <state-provider v-slot="slotProps" :initial-cash-receipts="data.dailyPendingCashReceipts">
        <div class="cash-receipts-subheader">
          <h3>
            Daily Unreconciled (<span>{{ slotProps.cashReceipts.length }}</span>)
          </h3>

          <dl class="summary">
            <dt>
              Daily Wired total
            </dt>
            <dd>
              {{ data.dailyWiredTotal | money }}
            </dd>
          </dl>
        </div>

        <data-table v-bind="slotProps" />
      </state-provider>

      <state-provider v-slot="slotProps" :initial-cash-receipts="data.pastPendingCashReceipts">
        <div class="cash-receipts-subheader">
          <h3>
            Past Unreconciled (<span>{{ slotProps.cashReceipts.length }}</span>) of {{ data.pastPendingCashReceiptsCount }}
          </h3>
        </div>

        <filters v-bind="filters" @filter="$listeners.filter" />
        <data-table v-bind="slotProps" />
      </state-provider>
    </div>
    <div v-else>
      <div class="ps-loader" />
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import debounce from "lodash/debounce";

import StateProvider from "./state_provider";
import Filters from "./filters";
import DataTable from "./table";
import AjaxService, { formatParams } from "services/ajax_service";
import { fromInput, toAPI } from "../shared/date_helper";

const DEBOUNCE_DELAY = 500;

const formatDate = date => {
  return toAPI(fromInput(date));
};

export default {
  components: {
    StateProvider,
    DataTable,
    Filters,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      data: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    filters: {
      deep: true,
      handler: debounce(function() {
        this.fetchData();
      }, DEBOUNCE_DELAY),
    },
  },
  computed: {
    params() {
      const { fromDate, toDate } = this.filters;
      const params = {};

      if (fromDate) {
        params.from = formatDate(fromDate);
      }

      if (toDate) {
        params.to = formatDate(toDate);
      }

      return params;
    },
  },
  methods: {
    async fetchData() {
      this.data = await AjaxService.getJSON(
        `/manage/cash_receipts.json?${formatParams(this.params)}`
      );
    },
  },
};
</script>
