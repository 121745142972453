import { Toast } from "peerstreet-design-library";

export default {
  success(message) {
    Toast.open({ message, type: "success" });
  },

  error(message = "Something went wrong") {
    Toast.open({ message, type: "danger" });
  },
};
