<template>
  <editable-input
    :value="value"
    :label="label"
    :editing="editing"
    :tooltip="tooltip"
  >
    <template #edit>
      <select
        class="form-control foreclosure-input"
        :value="value"
        :disabled="disabled"
        :name="name"
        @input="$listeners.input"
      >
        <option v-if="includeBlankOption" value="" />
        <option
          v-for="{ id, text, optionDisabled } in options"
          :key="id"
          :value="id"
          :disabled="optionDisabled"
        >
          {{ text }}
        </option>
      </select>
    </template>
    <template #show>
      <span :class="valueClass"> {{ getText(value) }}</span>
    </template>
  </editable-input>
</template>

<script>
import EditableInput from "./editable_input";

export default {
  name: "editable-select",
  components: {
    EditableInput,
  },
  props: {
    ...EditableInput.props,
    options: {
      type: Array,
      required: true,
    },
    noStatusLabel: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: null,
    },
    includeBlankOption: {
      type: Boolean,
      default: true,
    },
    includeValueClass: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    valueClass() {
      if (!this.includeValueClass) return;

      return { "no-status-state": !this.value };
    },
  },
  methods: {
    getText(id) {
      const selectedElem = this.options.find(t => t.id == id);
      return selectedElem ? selectedElem.text : this.noStatusLabel;
    },
  },
};
</script>
