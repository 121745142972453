import WithdrawnDeclinedPage from "components/withdrawn_declined";

$(() => {
  new Vue({
    el: document.querySelector(".v-withdrawn-declined-loans-controller"),
    components: {
      WithdrawnDeclinedPage,
    },
  });
});
