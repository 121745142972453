<template>
  <notification
    v-if="!isEmpty"
    v-bind="$props"
    class="notificaton-group"
    v-on="$listeners"
  >
    <template v-for="(messageGroup, index) in messageGroups">
      <p
        v-if="isSimpleMessage(messageGroup)"
        :key="messageGroup"
        class="notification-group-simple-message"
        v-html="messageGroup"
      ></p>

      <message-group
        v-else
        :key="'message-group-' + index"
        :title="messageGroup.title"
        :messages="messageGroup.messages"
      />
    </template>
  </notification>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";

import Notification from "components/shared/notification";
import MessageGroup from "./message_group";

export default {
  name: "notification-group",
  components: {
    Notification,
    MessageGroup,
  },
  props: {
    ...Notification.props,
    messageGroups: {
      type: Array,
      default: null,
    },
  },
  computed: {
    isEmpty() {
      return isEmpty(this.messageGroups);
    },
  },
  methods: {
    isSimpleMessage(message) {
      return isString(message);
    },
  },
};
</script>
