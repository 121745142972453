<template>
  <div class="details-row row">
    <div class="col-12">
      <h4>Principal</h4>
    </div>
    <div class="col-4">
      <editable-field
        label="PeerStreet Loan Amount"
        :value="statement.principalAtPurchaseAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div class="col-4">
      <editable-field
        label="Unfunded Commitment"
        :value="statement.unfundedCommitmentAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div class="col-4" />
    <div class="col-4">
      <editable-field
        label="Principal Payments"
        :value="statement.principalPaymentsAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div class="col-4">
      <editable-field
        label="Principal Adjustments"
        :value="statement.principalAdjustmentsAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div class="col-4" />
    <div class="col-4">
      <editable-field
        label="Current Principal Balance"
        :value="statement.unpaidPrincipalAmount"
        :editing="isEditable"
        currency
      />
    </div>
    <div v-if="enableCorrectedUnpaidPrincipalAmount" class="col-4">
      <editable-currency-field
        label="Corrected Principal Balance"
        :value="statement.correctedUnpaidPrincipalAmount"
        :callback="updateEditableField('correctedUnpaidPrincipalAmount', statement)"
        :disabled="disableCorrectedUnpaidPrincipalAmount"
        :allow-null="true"
        :show-tooltip="disableCorrectedUnpaidPrincipalAmount"
        tooltip-content="The principal balance cannot be corrected
         on this loan because it has principal payments or non-dutch 
         construction draws that occurred after the paid-to date"
      />
    </div>
    <div class="col-4">
      <div class="editable-field-container">
        <waived-input
          label="Waived Principal"
          :value="statement.waivedUnpaidPrincipalAmount"
          :amount="statement.unpaidPrincipalAmount"
          :data-change="updateEditableField('waivedUnpaidPrincipalAmount', statement)"
        />
      </div>
    </div>
    <div v-if="isThirdPartySale" class="col-4" />
    <div v-if="isThirdPartySale" class="col-4">
      <div class="editable-field-container">
        <waived-input
          label="Price Discount/Premium"
          :value="statement.priceDiscountPremiumAmount"
          :amount="statement.unpaidPrincipalAmount"
          :data-change="updateEditableField('priceDiscountPremiumAmount', statement)"
        />
      </div>
    </div>
    <div v-if="isThirdPartySale" class="col-4" />
  </div>
</template>

<script>
import WaivedInput from "components/manage/payoff_statement/waived_input";
import EditableField from "components/shared/editable_field.vue";
import EditableCurrencyField from "components/shared/editable_currency_field.vue";
import EditPartialMixin from "components/manage/payoff_statement/edit/edit_partial_mixin";

export default {
  name: "resale-statement-edit-principal",
  components: {
    EditableField,
    EditableCurrencyField,
    WaivedInput,
  },
  mixins: [EditPartialMixin],
  props: ["isEditable", "statement"],
  computed: {
    isThirdPartySale() {
      return this.statement.statementType == "third_party_sale";
    },
    enableCorrectedUnpaidPrincipalAmount() {
      return PSData.enableCorrectedUnpaidPrincipalAmount;
    },
    disableCorrectedUnpaidPrincipalAmount() {
      return PSData.statement.principalAdjustmentAfterPaidToDate;
    },
  },
};
</script>
