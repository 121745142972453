<template>
<div class="lender-loans-header">
  <div class="loans-link">
    <a :href="returnPath">
      <i class="fas fa-angle-left fa-lg" />
      <span>All Loans</span>
    </a>
  </div>
  <div v-if="loan.fullAddress">
    <h2>{{loan.fullAddress}}</h2>
  </div>
  <div class="lender-loans-header-details">
    <div class="loan-status" :class="purchasedGroup">
      {{loan.groupName}}
    </div>

    <div class="lender-loans-header-details-item" v-if="loan.toParam">
      <div>
        {{loan.toParam}}
      </div>
      <span class="item-bullet">
        &#8226;
      </span>
    </div>

    <div class="lender-loans-header-details-item" v-if="loan.programTypeTitle">
      <div>
        {{loan.programTypeTitle}}
      </div>
      <span class="item-bullet">
        &#8226;
      </span>
    </div>

    <div class="lender-loans-header-details-item">
      <a :href="mapLink">Map</a>
    </div>

    <div v-if="loan.docGenUrl">
      <span class="item-bullet">
        &#8226;
      </span>
      <a :href="loan.docGenUrl" target="_blank">DocGen</a>
    </div>
  </div>
</div>
</template>
<script>
export default {
  props: {
    loan: {
      type: Object,
      default: () => {
        return {};
      },
    },
    returnPath: String,
  },
  computed: {
    mapLink() {
      return `https://maps.google.com/?q=${encodeURI(this.loan.fullAddress)}`;
    },
    purchasedGroup() {
      return `loan-${this.loan.purchasedGroup}`;
    },
  },
};
</script>
