import PayoffStatement from "models/payoff_statement";
import Ajax from "services/ajax_service";
import get from "lodash/get";
import uniqueId from "lodash/uniqueId";
import { success, error } from "services/notification";
import Location from "services/location_service";

const JSON_HEADER = { "Content-Type": "application/json" };

export const STATES = {
  show: "show",
  duplicate: "duplicate",
  new: "new",
  edit: "edit",
};

export default {
  props: {
    state: {
      type: String,
      default: () => STATES.new,
    },
  },
  computed: {
    isEdit() {
      return this.currentState === STATES.edit;
    },
    routes() {
      return {
        servicing: get(PSData, "routes.servicing"),
        show: this.statement.routes.show,
        saveDraft: this.statement.routes.saveDraft,
        submit: this.statement.routes.save,
        calculateInterest: this.statement.routes.calculateInterest,
        update: this.statement.routes.update,
        transition: this.statement.routes.transition,
        refresh: this.statement.routes.refresh,
        fetchFees: this.statement.routes.fetchFees,
      };
    },
    isPersisted() {
      return this.statement.id !== undefined && this.statement.id !== null;
    },
    statusClass() {
      return ["payoff-statement-status", `payoff-statement-status-${this.statement.status}`];
    },
  },
  methods: {
    addComment() {
      this.statement.comments.push({ id: uniqueId(), comment: null });
    },
    addFee() {
      this.statement.fees.push({
        id: uniqueId(),
        feeType: null,
        amount: 0,
        waivedAmount: 0,
      });
    },
    removeFee(fee) {
      const fees = this.statement.fees.filter(record => record.id != fee.id);

      this.statement.update({ fees });
      this.syncInterest();
    },
    removeComment(comment) {
      const comments = this.statement.comments.filter(record => record.id != comment.id);
      this.statement.update({ comments });
    },
    async syncInterest() {
      const result = await this.makeRequest(
        this.routes.calculateInterest,
        "post",
        this.statement.toHash(),
        () => {}
      );
      this.statement.update(result.interestData);
    },
    async makeRequest(
      url,
      method = "post",
      resource = this.statement.toHash(),
      postCallback = this.updateCreatedStatement
    ) {
      try {
        this.isLoading = true;

        var result = await Ajax[method](url, { resource }, JSON_HEADER);

        if (result.errors !== undefined) {
          this.errors = result.errors;
        }
        this.errorType = result.errorType;
        postCallback(result);

        return result;
      } catch (err) {
        this.errors = [err.message];
        return {};
      } finally {
        this.isLoading = false;
      }
    },
    updateCreatedStatement(result) {
      if (!this.errors) {
        success(this.notification(`submit.${this.currentState}`));
        this.created = true;
        Location.href = result.statement.routes.show;
      } else {
        error(this.notification("submit.onError"));
      }
    },
    goBackToShowPage() {
      Location.href = this.statement.routes.show;
    },
    setStatement(result) {
      if (result.statement) {
        this.statement = new PayoffStatement(result.statement);
      }
    },
    persist() {
      this.makeRequest(this.routes.update, "patch");
    },
    cancel() {
      success(this.notification("cancelled"));
      Location.href = this.routes.servicing;
    },
  },
};
