import { PROGRAM_CLASSIFICATION } from "./program";
import { FIELDS, InputType } from "./fields";
import NumberFormatter from "models/formatters/number_formatter";

const DEFAULT_FIELD_OPTIONS = {
  disabled: false,
  isValid: false,
  required: true,
};

function hasData(value) {
  return value !== undefined && value !== null;
}

export default class Field {
  static createFields(rawData) {
    const fields = {};

    Object.entries(FIELDS[PROGRAM_CLASSIFICATION.RESIDENTIAL_BRIDGE]).forEach(([name, options]) => {
      options.name = name;
      fields[name] = new Field(rawData[name], options);
    });

    return fields;
  }

  static convertValue(inputType, value) {
    switch (inputType) {
      case InputType.Select:
        return value || "";
      case InputType.Number:
      case InputType.PositiveNumber:
      case InputType.SelectNumber:
        const number = NumberFormatter.unmaskNumber(value);
        return hasData(number) && !Number.isNaN(number) ? number : undefined;
      case InputType.YesNo:
        return hasData(value) ? value.toString() : value;
      case InputType.Text:
      default:
        return hasData(value) ? value.toString() : "";
    }
  }

  constructor(value, options) {
    options = Object.assign({}, DEFAULT_FIELD_OPTIONS, options);

    this.value = Field.convertValue(options.inputType, value);
    this._disabled = options.disabled;
    this.section = options.section;
    this.inputType = options.inputType;
    this.isValid = options.isValid;
    this.required = options.required;
    this.name = options.name;
  }

  set disabled(value) {
    this._disabled = value;
    if (value === true) {
      this.value = undefined;
    }
  }

  get disabled() {
    return this._disabled;
  }

  get isComplete() {
    if (this.required === false) {
      return true;
    }

    switch (this.inputType) {
      case InputType.PositiveNumber:
        return hasData(this.convertedValue) ? this.convertedValue > 0 : false;
      case InputType.Number:
      case InputType.SelectNumber:
        return hasData(this.convertedValue) ? this.convertedValue >= 0 : false;
      case InputType.YesNo:
        return hasData(this.convertedValue);
      case InputType.Select:
      case InputType.Text:
        return this.convertedValue.trim().length > 0;
      default:
        return false;
    }
  }

  get convertedValue() {
    return Field.convertValue(this.inputType, this.value);
  }
}
