<template>
  <div class="apply-flow">
    <template v-if="showForm">
      <base-form
        title="Join our Nationwide Lender Network"
        save-button-name="Create My Account"
        :show-cancel="false"
        :save-disabled="isSaving"
        @save="onSave"
      >
        <div class="columns">
          <base-text-field
            id="first-name"
            label="First Name"
            v-model="lender.first_name"
            :errors="errors.first_name"
            rules="required"
            class="column is-half"
          />
          <base-text-field
            id="last-name"
            label="Last Name"
            v-model="lender.last_name"
            :errors="errors.last_name"
            rules="required"
            class="column is-half"
          />
        </div>
        <div class="columns">
          <base-text-field
            id="company-name"
            label="Company Name"
            v-model="lender.name"
            :errors="errors.name"
            rules="required"
            class="column"
          />
        </div>
        <div class="columns">
          <base-text-field
            id="email"
            label="Email Address"
            v-model="lender.email"
            :errors="errors.email"
            rules="required|email"
            class="column"
          />
        </div>

        <p class="grecaptcha-disclaimer">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a>
          and
          <a href="https://policies.google.com/terms">Terms of Service</a>
          apply.
        </p>

        <div
          class="g-recaptcha"
          :data-sitekey="recaptchaKey"
          data-callback="_psRecaptchaSubmit"
          data-size="invisible"
          data-badge="bottomleft"
        >
        </div>
      </base-form>

      <p class="text-center">Already have an account? <a :href="signInUrl">Sign In</a></p>
      <p class="text-center reset"><a :href="resetUrl">Forgot password?</a></p>
    </template>

    <div v-else class="apply-success">
      <h3>A verification link has been sent<br>to your email account</h3>
      <p>Please click on the link that has just been sent to your<br>email account to verify your email and complete<br>your account creation.</p>
    </div>
  </div>
</template>

<script>
import AjaxService from "services/ajax_service";
import { error, success } from "services/notification";
import toPlainObject from "utils/to_plain_object";

export default {
  data() {
    return {
      routes: PSData.routes,
      errors: {},
      isSaving: false,
      lender: {},
      showForm: true,
      isSuccess: false,
      recaptchaKey: PSData.global.recaptchaSiteKey,
    };
  },
  mounted() {
    window._psRecaptchaSubmit = token => {
      this.save(token);
    };

    const script = document.createElement("script");
    script.setAttribute("async", "true");
    script.setAttribute("src", "https://www.google.com/recaptcha/api.js");
    this.$el.appendChild(script);
  },
  methods: {
    onSave() {
      this.isSaving = true;
      window.grecaptcha.execute();
    },
    save(token) {
      const payload = {
        lender: this.lender,
        "g-recaptcha-response": token,
      };

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json; charset=utf-8",
      };

      AjaxService.post(this.routes.lenderApply, payload, headers)
        .then(response => {
          if (response.status === 201) {
            success("Account has been created.");
            this.isSuccess = true;
          }
        })
        .catch(({ response, data }) => {
          if (response.status === 422) {
            this.errors = data.errors;
          } else if (response.status === 403) {
            error(data.errors);
          } else {
            error("Sorry, something went wrong.");
            Bugsnag.notify(
              { name: "Request Error", message: "Error new lender apply flow" },
              { metaData: { custom: { response: toPlainObject(response), payload } } }
            );
          }
        })
        .finally(() => {
          this.isSaving = false;
          this.showForm = !this.isSuccess;
        });
    },
  },
  computed: {
    signInUrl() {
      return this.routes.signIn;
    },
    resetUrl() {
      return this.routes.reset;
    },
  },
};
</script>

<style scoped>
.apply-flow {
  background-color: var(--color-white);
  color: var(--color-grey-86);
  font-size: var(--font-size);
  margin: 60px auto 0;
  padding: 24px 40px 24px;
  width: 500px;
}

.apply-flow >>> .base-form-head-title {
  font-size: 24px;
  line-height: 32px;
  margin: 0 auto 10px;
  text-align: center;
  width: 250px;
}

.apply-flow >>> .base-form-footer {
  margin: 20px 0 30px;
}

.apply-flow >>> .base-button.button {
  background: var(--color-primary-green);
  width: 100%;
}

.apply-success {
  padding: 32px 0 45px;
  text-align: center;
}

.apply-success h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 24px;
}

.apply-success p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.apply-flow .reset {
  margin-top: 8px;
}
</style>
