const Events = PS.Models.LocationMap.Events;
const Commands = PS.Models.LocationMap.Commands;
const Event = PS.Models.Event;
const Rule = PS.Models.Rule;

class LocationMapRule extends Rule {
  check(event) {
    switch (event.constructor) {
      case Events.LocationMapSetupRequested:
        return this.whenLocationMapSetupRequested(event);
    }
    return super.check(event);
  }

  whenLocationMapSetupRequested(event) {
    return [
      new Commands.WaitForGoogleAPILoading(event, this.state),
      new Commands.FindLatLong(event, this.state),
      new Commands.SetupLocationMap(event, this.state),
    ];
  }
}

PS.Models.LocationMap.Rule = LocationMapRule;
