<template>
  <div class="document-form-content">
    <div v-if="formLoading" class="ps-loader" />
    <template v-if="form">
      <div class="form-header">
        <div class="header-items">
          <h2>{{ form.document.name }}</h2>
          <p v-if="form.fieldsCount() > 0">
            Complete {{ form.validatedCount() }}/{{ form.fieldsCount() }} ({{
              form.completedPercentage() | percent(0)
            }})
          </p>
        </div>
        <base-button
          v-if="form.document.syncable"
          class="dd-sync-button"
          type="text"
          icon-left="sync"
          :disabled="formDisabled"
          @click="syncToLP"
        >
          Sync to LP
        </base-button>
      </div>
      <div v-if="documentUploads.length" class="upload-banners">
        <upload-banner
          v-for="(upload, index) in documentUploads"
          :key="'upload-' + index"
          :upload="upload"
        />
      </div>
      <sync-errors
        v-if="syncErrors"
        :sync-errors="syncErrors"
        type="danger"
        @close="clearSyncErrors"
      />
      <div v-for="field in form.fields" :key="field.id" class="document-field">
        <template v-if="!field.isFieldset()">
          <component
            :is="field.toComponent()"
            :document="form.document"
            :field="field"
            :parentDisabled="formDisabled"
          />
        </template>
        <template v-if="field.isFieldset()">
          <diligence-document-fieldset
            :document="form.document"
            :fieldset="field"
            :disabled="formDisabled"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
const Commands = PS.Models.Diligence.Commands;
const Event = PS.Models.Event;

import SyncErrors from "./sync_errors";
import UploadBanner from "./upload_banner";

export default Vue.component("diligence-document-form", {
  components: { SyncErrors, UploadBanner },
  props: {
    loan: Object,
  },
  data: function() {
    return {
      form: undefined,
      formDisabled: false,
      formLoading: false,
      documentUploads: [],
      ruleBook: undefined,
      messageBus: PS.Services.MessageBusService.diligenceBus,
      syncErrors: undefined,
    };
  },
  mounted: function() {
    this.ruleBook = new PS.Models.Diligence.FormRule(this);
    this.messageBus.attachRulebook(this.ruleBook);
  },
  methods: {
    clearSyncErrors() {
      this.syncErrors = undefined;
    },
    syncToLP() {
      new Commands.Form.SyncForm(new Event(), this).execute();
    },
  },
  beforeDestroy: function() {
    this.messageBus.detachRulebook(this.ruleBook);
  },
});
</script>
